import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type Theme = 'light' | 'dark';

interface ThemeState {
  mode: Theme;
  primaryColor: string;
  secondaryColor: string;
  sidebarCollapsed: boolean;
  toggleTheme: () => void;
  setPrimaryColor: (color: string) => void;
  setSecondaryColor: (color: string) => void;
  toggleSidebar: () => void;
}

export const useThemeStore = create<
  ThemeState,
  [['zustand/persist', ThemeState]]
>(
  persist(
    (set) => ({
      mode: 'light',
      primaryColor: '#ffffff',
      secondaryColor: '#000000',
      sidebarCollapsed: false,
      toggleTheme: () =>
        set((state) => {
          const newMode = state.mode === 'dark' ? 'light' : 'dark';
          if (newMode === 'dark') {
            document.documentElement.classList.add('dark');
          } else {
            document.documentElement.classList.remove('dark');
          }
          return { mode: newMode };
        }),
      setPrimaryColor: (color: string) => set(() => ({ primaryColor: color })),
      setSecondaryColor: (color: string) =>
        set(() => ({ secondaryColor: color })),
      toggleSidebar: () =>
        set((state) => ({ sidebarCollapsed: !state.sidebarCollapsed })),
    }),
    {
      name: 'theme-storage',
      getStorage: () => localStorage,
    }
  )
);
