import { useState, useEffect, useRef } from 'react';
import { Card } from '@tremor/react';
import {
  getCoreRowModel,
  useReactTable,
  flexRender,
  ColumnDef,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
} from '@tanstack/react-table';
import Icon from '../../../assets/icons/SvgComponent';
import { useThemeStore } from '../../../store/useThemeStore';
import NoDataError from '../../NoDataError';
import NoDataErrorRetry from '../../NoDataRetry';
type Props = {
  data: any;
  columns: ColumnDef<Record<string, any>, any>[];
  totalPages?: any;
  loading?: any;
  setSelectedRow?: any;
  status?: any;
  totalDocs?: any;
  sortByColumnId?: string;
  pagination?: boolean;
  cardTable?: boolean;
  error?: string;
  highlightRow?: boolean;
  selectedClient?: any;
  retryMethod?: () => void;
};

const AnalyticsTable: React.FC<Props> = ({
  data,
  columns,
  loading,
  totalDocs,
  sortByColumnId,
  pagination,
  cardTable,
  error,
  highlightRow = false,
  selectedClient,
  retryMethod,
}) => {
  const { mode } = useThemeStore((state: any) => state);
  const iconColor = mode === 'dark' ? 'white' : 'black';
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: sortByColumnId,
      desc: true,
    },
  ]);
  const [page, setPage] = useState(1);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
  });

  const pageCount = table?.getPageCount();
  const handlePreviousPage = () => {
    if (page > 1) {
      table.previousPage();
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (page < pageCount) {
      table.nextPage();
      setPage(page + 1);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const showEllipsisStart = page > 4;
    const showEllipsisEnd = page < pageCount - 3;

    if (showEllipsisStart) {
      pageNumbers.push(
        <div
          key={1}
          className={`p-4 mx-1 text-sm text-center flex items-center justify-center rounded-full select-none h-8 w-8 text-blue-900 border border-gray-300 ${
            page === 1
              ? 'bg-gray-200 cursor-not-allowed text-gray-800'
              : 'cursor-pointer text-gray-600'
          } hover:bg-blue-100 hover:text-blue-700 dark.border-gray-700 dark.bg-gray-700 dark.text-white cursor-pointer`}
          onClick={() => {
            setPage(1);
            table.setPageIndex(0);
          }}
        >
          {1}
        </div>,
        <div key="ellipsis-start" className="mx-1 dark:text-[#fff]">
          ...
        </div>
      );
    }

    for (let i = page - 2; i <= page + 2; i++) {
      if (i > 0 && i <= pageCount) {
        pageNumbers.push(
          <div
            key={i}
            className={`p-4 mx-1 text-sm text-center flex items-center justify-center rounded-full select-none h-8 w-8 text-blue-900 border border-gray-300 ${
              page === i
                ? 'bg-gray-200 cursor-not-allowed text-gray-800'
                : 'cursor-pointer text-gray-600'
            } hover:bg-blue-100 hover:text-blue-700 dark.border-gray-700 dark.bg-gray-700 dark.text-white cursor-pointer`}
            onClick={() => {
              setPage(i);
              table.setPageIndex(i - 1);
            }}
          >
            {i}
          </div>
        );
      }
    }

    if (showEllipsisEnd) {
      pageNumbers.push(
        <div key="ellipsis-end" className="mx-1 dark:text-[#fff]">
          ...
        </div>
      );
    }

    for (let i = pageCount - 1; i <= pageCount; i++) {
      if (i > page + 2) {
        pageNumbers.push(
          <div
            key={i}
            className={`p-4 mx-1 text-sm text-center flex items-center justify-center rounded-full select-none h-8 w-8 text-blue-900 border border-gray-300 ${
              page === i
                ? 'bg-gray-200 cursor-not-allowed text-gray-800'
                : 'cursor-pointer text-gray-600'
            } hover:bg-blue-100 hover:text-blue-700 dark.border-gray-700 dark.bg-gray-700 dark.text-white cursor-pointer`}
            onClick={() => {
              setPage(i);
              table.setPageIndex(i - 1);
            }}
          >
            {i}
          </div>
        );
      }
    }

    return pageNumbers;
  };

  const tableRef = useRef(null);
  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }
  }, [page]);

  return (
    <div className="rounded-md">
      <div
        className={`relative overflow-auto bg-white dark:bg-[#29292A] sm:rounded-lg scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8 max-h-[350px] ${
          data?.length === 0 ? 'h-[500px]' : ''
        }`}
        ref={tableRef}
      >
        {loading ? (
          <div>
            {table.getHeaderGroups().map((headerGroup) => (
              <ul key={headerGroup.id} className="flex">
                {headerGroup.headers.map((header) => (
                  <li
                    className="py-2 text-center bg-[#fff] border-b border-dashed dark:bg-gray-700 dark:text-gray-400 flex"
                    key={header.id}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none flex justify-between items-center w-fit text-sm text-[#808DA1]'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            ))}
            <div className="px-2">
              <Card className="h-[250px] w-full flex flex-col justify-between mt-4 animate-pulse shadow">
                {[...Array(7)].map((_, index) => (
                  <div
                    key={index}
                    className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"
                  ></div>
                ))}
              </Card>
            </div>
          </div>
        ) : (
          <>
            {table?.getRowModel()?.rows?.length === 0 ? (
              <>
                {cardTable && !error ? (
                  <div className="flex flex-col items-center justify-center w-full items-center h-full">
                    <NoDataError error={error} />
                  </div>
                ) : (
                  <div className="px-2">
                    <div className="h-[320px] w-full flex flex-col justify-between mt-4 ">
                      <div className="flex flex-col items-center justify-center w-full my-8">
                        <div className="w-fit">
                          <NoDataErrorRetry
                            error={error}
                            retry={
                              error &&
                              error !== '' &&
                              typeof retryMethod === 'function'
                            }
                            handleRetry={() => retryMethod()}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div>
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 font-inter">
                  <thead className="w-full text-sm text-gray-600  bold">
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header, index) => (
                          <th
                            className={`${index === 0 ? 'sticky left-0 z-[10] ' : 'z-[9]'} sticky top-0 z-10 py-2 text-center bg-[#fff] border-b border-dashed dark:bg-gray-700 dark:text-gray-400`}
                            key={header.id}
                            onClick={() => setPage(1)}
                          >
                            {header.isPlaceholder ? null : (
                              <div
                                {...{
                                  className: header.column.getCanSort()
                                    ? 'cursor-pointer select-none flex justify-between items-center w-fit text-sm text-[#808DA1]'
                                    : '',
                                  onClick:
                                    header.column.getToggleSortingHandler(),
                                }}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                              </div>
                            )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table?.getRowModel()?.rows?.map((row) => {
                      let isSameDomain = false;
                      if (highlightRow) {
                        isSameDomain =
                          row?.original?.Domain === selectedClient?.domain;
                      }
                      return (
                        <tr key={row.id}>
                          {row?.getVisibleCells()?.map((cell, index) => (
                            <td
                              className={`${index === 0 ? 'sticky left-0 z-[3]  dark:border-white  border-b border-dashed rounded-tl-[8px] rounded-bl-[8px]' : 'z-10 '} ${index === row?.getVisibleCells()?.length - 1 && 'rounded-br-[8px] rounded-tr-[8px]'} ${cell.column.columnDef.meta ?? ''} py-3 min-w-fit  border-b border-dashed ${isSameDomain ? 'bg-[#D0EDFF]' : ' border-b border-dashed  dark:bg-gray-800'}`}
                              key={cell.id}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </>
        )}
      </div>
      {table?.getRowModel()?.rows?.length !== 0 && (
        <>
          {!pagination ? (
            <div className="relative h-fit">
              <div className="sticky bottom-0 z-10 py-2 text-center  dark:bg-gray-700 dark:text-gray-400">
                <div className="flex justify-between items-center px-4 mt-2 text-xs">
                  <div className="dark:text-white">Results: {totalDocs}</div>
                  <div className="flex justify-between gap-4 max-w-[630px]">
                    <button
                      className="flex items-center justify-center select-none px-4 h-8 text-xs font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      onClick={handlePreviousPage}
                      disabled={!table.getCanPreviousPage()}
                    >
                      {`<<`}
                    </button>
                    <button
                      className="flex items-center justify-center px-4 h-8 select-none text-xs font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      onClick={handleNextPage}
                      disabled={!table.getCanNextPage()}
                    >
                      {`>>`}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="relative h-fit">
              <div className="sticky bottom-0 z-10 py-2 text-center  dark:bg-gray-700 dark:text-gray-400">
                <div className="flex justify-between items-center px-4 mt-2">
                  <div className="dark:text-white text-sm">
                    Results: {totalDocs}
                  </div>
                  <div className="flex justify-between gap-4 max-w-[630px]">
                    <button
                      className="flex items-center justify-center select-none px-4 h-10 text-base font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      onClick={handlePreviousPage}
                      disabled={!table.getCanPreviousPage()}
                    >
                      <Icon
                        name="ArrowLeft"
                        color={mode === 'dark' ? '#fff' : iconColor}
                      />
                      <span className="ml-2 select-none">Previous</span>
                    </button>
                    <div className="flex items-center">
                      {renderPageNumbers()}
                    </div>
                    <button
                      className="flex items-center justify-center px-4 h-10 select-none text-base font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      onClick={handleNextPage}
                      disabled={!table.getCanNextPage()}
                    >
                      <span className="mr-2 select-none">Next</span>
                      <Icon
                        name="ArrowRight"
                        color={mode === 'dark' ? '#fff' : iconColor}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AnalyticsTable;
