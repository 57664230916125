import React from 'react';

interface IProps {
  color?: string;
  size?: number;
}

const OmgUsers: React.FC<IProps> = ({ color = '#405573', size = 20 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.1669 10.0823L15.8401 10.0587V9.98815C15.8401 8.39012 15.2057 6.93292 14.1482 5.87535L11.6569 8.29601L9.94127 9.96463V9.94126L8.2256 8.27265L5.75788 5.87535C4.74737 6.90939 4.1128 8.31954 4.06574 9.87068V9.98815C4.06574 10.5757 4.15985 11.1398 4.32438 11.6803C4.34791 11.7744 4.39479 11.8919 4.41832 11.9858C4.67679 12.6674 5.05289 13.302 5.52294 13.866C5.57 13.9364 5.64041 14.007 5.711 14.0776C6.76856 15.182 8.27265 15.8872 9.94127 15.8872C11.6099 15.8872 13.114 15.2056 14.1951 14.0776C14.2186 14.0541 14.2421 14.0305 14.2656 14.007H14.1246L9.96479 13.9364V11.7744L11.8683 11.7979L15.3467 11.8448H15.5582L17.9554 11.8919C17.8144 12.5264 17.5793 13.1375 17.2738 13.725C17.2268 13.8425 17.1563 13.9364 17.1093 14.0541C16.7802 14.6651 16.3573 15.2056 15.8872 15.6991C14.3831 17.2503 12.2915 18.2139 9.98832 18.2139C7.6616 18.2139 5.59336 17.2503 4.08927 15.7227C3.54881 15.182 3.10229 14.5475 2.72618 13.866C2.70265 13.819 2.67929 13.7954 2.67929 13.7485C2.35024 13.114 2.09161 12.4088 1.95061 11.6803C1.83314 11.1398 1.78608 10.5757 1.78608 10.0117V9.84716C1.83314 7.68497 2.70265 5.71083 4.1128 4.2538C4.67679 3.66628 5.33489 3.19623 6.04005 2.7966L7.8026 4.51227L9.96479 6.60387L12.127 4.51227L13.8895 2.7966C14.5947 3.19623 15.2526 3.68981 15.8168 4.2538C17.2503 5.73436 18.1433 7.75555 18.1433 9.98815L18.1669 10.0823ZM17.1328 3.00818C16.5688 2.42066 15.9343 1.92708 15.2526 1.50409C13.725 0.540465 11.9154 0 9.98832 0C8.06124 0 6.25146 0.540465 4.72385 1.50409C4.04238 1.92708 3.40781 2.44419 2.84365 3.00818C1.12815 4.77073 0.0470545 7.16803 0 9.80027V9.98815C0 10.5523 0.0470555 11.0928 0.140999 11.6334C0.258469 12.3854 0.470049 13.114 0.752044 13.819C2.25613 17.4383 5.82847 20 9.98832 20C12.8084 20 15.3703 18.825 17.1799 16.9448C17.7439 16.3573 18.2374 15.7227 18.6369 15.0175C18.825 14.712 18.966 14.4065 19.1305 14.0776C19.436 13.396 19.6709 12.6674 19.8119 11.9154C19.9296 11.3279 20 10.7168 20 10.1058V9.98815C19.9765 7.2855 18.8954 4.81779 17.1328 3.00818Z"
      fill={color}
    />
  </svg>
);

export default OmgUsers;