

const checkSubmission = function(changeValues, client) {

    console.log('Change: ', changeValues, 'Client: ', client)

    /////////////////////////////////////////////////////////////////
    /////////////////// CATCH NO SERVICE LINES SELECTED
    if(changeValues.changeType !=='reallocate staff'){
        if(changeValues.services.length === 0){
            return {
                failed: true,
                message: "No Service Lines Were Selected" ,
            } 
        }
    }else{
        if(changeValues.services.length === 0){
            return {
                failed: true,
                message: "No Departments Were Selected" ,
            } 
        }
    }

    /////////////////////////////////////////////////////////////////
    /////////////////// CATCH RESTARTING ALREADY LIVE SERVICES
    if(changeValues.services.includes('GOOGLE SEO')){
        if(changeValues.changeType === 'restart' && client.seo_google.status === 'active'){
            return {
                failed: true,
                message: "SEO GOOGLE is already live",
            } 
        }
    }
    if(changeValues.services.includes('GOOGLE ADS')){
        if(changeValues.changeType === 'restart' && client.ppc_google.status === 'active'){
            return {
                failed: true,
                message: "PPC GOOGLE is already live",
            } 
        }
    }
    if(changeValues.services.includes('MICROSOFT SEO')){
        if(changeValues.changeType === 'restart' && client.seo_bing.status === 'active'){
            return {
                failed: true,
                message: "Microsoft SEO is already live",
            } 
        }
    }
    if(changeValues.services.includes('MICROSOFT ADS')){
        if(changeValues.changeType === 'restart' && client.social_bing.status === 'active'){
            return {
                failed: true,
                message: "Microsoft Ads is already live",
            } 
        }
    }
    if(changeValues.services.includes('REMARKETING')){
        if(changeValues.changeType === 'restart' && client.display.status === 'active'){
            return {
                failed: true,
                message: "REMARKETING is already live",
            } 
        }
    }
    if(changeValues.services.includes('META')){
        if(changeValues.changeType === 'restart' && client.social_meta.status === 'active'){
            return {
                failed: true,
                message: "META is already live",
            } 
        }
    }
    if(changeValues.services.includes('PINTEREST')){
        if(changeValues.changeType === 'restart' && client.social_pinterest.status === 'active'){
            return {
                failed: true,
                message: "PINTEREST is already live",
            } 
        }
    }
    if(changeValues.services.includes('TIKTOK')){
        if(changeValues.changeType === 'restart' && client.social_tiktok.status === 'active'){
            return {
                failed: true,
                message: "TIKTOK is already live",
            } 
        }
    }
    if(changeValues.services.includes('PROGRAMMATIC')){
        if(changeValues.changeType === 'restart' && client.social_programmatic.status === 'active'){
            return {
                failed: true,
                message: "PROGRAMMATIC is already live",
            } 
        }
    }
    if(changeValues.services.includes('LINKEDIN')){
        if(changeValues.changeType === 'restart' && client.social_linkedin.status === 'active'){
            return {
                failed: true,
                message: "LINKEDIN is already live",
            } 
        }
    }
    if(changeValues.services.includes('CREATIVE')){
        if(changeValues.changeType === 'restart' && client.social_creative.status === 'active'){
            return {
                failed: true,
                message: "CREATIVE is already live",
            } 
        }
    }
    if(changeValues.services.includes('TACTICAL')){
        if(changeValues.changeType === 'restart' && client.social_tactical.status === 'active'){
            return {
                failed: true,
                message: "TACTICAL is already live",
            } 
        }
    }
    if(changeValues.services.includes('WEB HOSTING')){
        if(changeValues.changeType === 'restart' && client.web_hosting.status === 'active'){
            return {
                failed: true,
                message: "WEB HOSTING is already live",
            } 
        }
    }    
    if(changeValues.services.includes('LANDING PAGES')){
        if(changeValues.changeType === 'restart' && client.web_landing.status === 'active'){
            return {
                failed: true,
                message: "LANDING PAGE is already live",
            } 
        }
    }    
    if(changeValues.services.includes('WEB MAINTENANCE')){
        if(changeValues.changeType === 'restart' && client.web_maintenance.status === 'active'){
            return {
                failed: true,
                message: "WEB MAINTENANCE is already live",
            } 
        }
    }    
    if(changeValues.services.includes('PROJECT')){
        if(changeValues.changeType === 'restart' && client.web_project.status === 'active'){
            return {
                failed: true,
                message: "WEB PROJECT is already live",
            } 
        }
    }    

    /////////////////////////////////////////////////////////////////
    /////////////////// CATCH EMPTY REQUIRED FIELDS
    /////////////////////////////////////////////////////////////////

    /////////////////////////////////////////////////////////////////
    /////////////////// REALLOCATIONS
    if(changeValues.changeType ==='reallocate staff'){
        if(changeValues.services.includes('am')){
            if(
            changeValues.am.length === 0 || changeValues.am.proposedStaff === "" || changeValues.am.proposedStaff === "none" || 
            changeValues.am.handoverCompleted === "" || changeValues.am.handoverCompleted === "none" 
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('GOOGLE SEO')){
            if(
            changeValues.seo_google.length === 0 || changeValues.seo_google.proposedStaff === "" || changeValues.seo_google.proposedStaff === "none" || 
            changeValues.seo_google.handoverCompleted === "" || changeValues.seo_google.handoverCompleted === "none" 
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('GOOGLE ADS')){
            if(
            changeValues.ppc_google.length === 0 || changeValues.ppc_google.proposedStaff === "" || changeValues.ppc_google.proposedStaff === "none" || 
            changeValues.ppc_google.handoverCompleted === "" || changeValues.ppc_google.handoverCompleted === "none" 
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
    }

    /////////////////////////////////////////////////////////////////
    /////////////////// PAUSES
    if(changeValues.changeType ==='pause'){
        if(changeValues.services.includes('GOOGLE SEO')){
            if(
            changeValues.seo_google.pauseCategory === "" || changeValues.seo_google.pauseClientComments === "" || changeValues.seo_google.pauseEffectDate === "" ||
            changeValues.seo_google.pauseReason === "" || changeValues.seo_google.pauseRecommenceDate === "" || changeValues.seo_google.pauseRequestDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('GOOGLE ADS')){
            if(
            changeValues.ppc_google.pauseCategory === "" || changeValues.ppc_google.pauseClientComments === "" || changeValues.ppc_google.pauseEffectDate === "" ||
            changeValues.ppc_google.pauseReason === "" || changeValues.ppc_google.pauseRecommenceDate === "" || changeValues.ppc_google.pauseRequestDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('MICROSOFT SEO')){
            if(
            changeValues.seo_bing.pauseCategory === "" || changeValues.seo_bing.pauseClientComments === "" || changeValues.seo_bing.pauseEffectDate === "" ||
            changeValues.seo_bing.pauseReason === "" || changeValues.seo_bing.pauseRecommenceDate === "" || changeValues.seo_bing.pauseRequestDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('MICROSOFT ADS')){
            if(
            changeValues.social_bing.pauseCategory === "" || changeValues.social_bing.pauseClientComments === "" || changeValues.social_bing.pauseEffectDate === "" ||
            changeValues.social_bing.pauseReason === "" || changeValues.social_bing.pauseRecommenceDate === "" || changeValues.social_bing.pauseRequestDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('REMARKETING')){
            if(
            changeValues.display.pauseCategory === "" || changeValues.display.pauseClientComments === "" || changeValues.display.pauseEffectDate === "" ||
            changeValues.display.pauseReason === "" || changeValues.display.pauseRecommenceDate === "" || changeValues.display.pauseRequestDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('META')){
            if(
            changeValues.social_meta.pauseCategory === "" || changeValues.social_meta.pauseClientComments === "" || changeValues.social_meta.pauseEffectDate === "" ||
            changeValues.social_meta.pauseReason === "" || changeValues.social_meta.pauseRecommenceDate === "" || changeValues.social_meta.pauseRequestDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('PINTEREST')){
            if(
            changeValues.social_pinterest.pauseCategory === "" || changeValues.social_pinterest.pauseClientComments === "" || changeValues.social_pinterest.pauseEffectDate === "" ||
            changeValues.social_pinterest.pauseReason === "" || changeValues.social_pinterest.pauseRecommenceDate === "" || changeValues.social_pinterest.pauseRequestDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('TIKTOK')){
            if(
            changeValues.social_tiktok.pauseCategory === "" || changeValues.social_tiktok.pauseClientComments === "" || changeValues.social_tiktok.pauseEffectDate === "" ||
            changeValues.social_tiktok.pauseReason === "" || changeValues.social_tiktok.pauseRecommenceDate === "" || changeValues.social_tiktok.pauseRequestDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('PROGRAMMATIC')){
            if(
            changeValues.social_programmatic.pauseCategory === "" || changeValues.social_programmatic.pauseClientComments === "" || changeValues.social_programmatic.pauseEffectDate === "" ||
            changeValues.social_programmatic.pauseReason === "" || changeValues.social_programmatic.pauseRecommenceDate === "" || changeValues.social_programmatic.pauseRequestDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('LINKEDIN')){
            if(
            changeValues.social_linkedin.pauseCategory === "" || changeValues.social_linkedin.pauseClientComments === "" || changeValues.social_linkedin.pauseEffectDate === "" ||
            changeValues.social_linkedin.pauseReason === "" || changeValues.social_linkedin.pauseRecommenceDate === "" || changeValues.social_linkedin.pauseRequestDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('TACTICAL')){
            if(
            changeValues.social_tactical.pauseCategory === "" || changeValues.social_tactical.pauseClientComments === "" || changeValues.social_tactical.pauseEffectDate === "" ||
            changeValues.social_tactical.pauseReason === "" || changeValues.social_tactical.pauseRecommenceDate === "" || changeValues.social_tactical.pauseRequestDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('CREATIVE')){
            if(
            changeValues.social_creative.pauseCategory === "" || changeValues.social_creative.pauseClientComments === "" || changeValues.social_creative.pauseEffectDate === "" ||
            changeValues.social_creative.pauseReason === "" || changeValues.social_creative.pauseRecommenceDate === "" || changeValues.social_creative.pauseRequestDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('WEB HOSTING')){
            if(
            changeValues.web_hosting.pauseCategory === "" || changeValues.web_hosting.pauseClientComments === "" || changeValues.web_hosting.pauseEffectDate === "" ||
            changeValues.web_hosting.pauseReason === "" || changeValues.web_hosting.pauseRecommenceDate === "" || changeValues.web_hosting.pauseRequestDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('WEB MAINTENANCE')){
            if(
            changeValues.web_maintenance.pauseCategory === "" || changeValues.web_maintenance.pauseClientComments === "" || changeValues.web_maintenance.pauseEffectDate === "" ||
            changeValues.web_maintenance.pauseReason === "" || changeValues.web_maintenance.pauseRecommenceDate === "" || changeValues.web_maintenance.pauseRequestDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('LANDING')){
            if(
            changeValues.web_landing.pauseCategory === "" || changeValues.web_landing.pauseClientComments === "" || changeValues.web_landing.pauseEffectDate === "" ||
            changeValues.web_landing.pauseReason === "" || changeValues.web_landing.pauseRecommenceDate === "" || changeValues.web_landing.pauseRequestDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('PROJECT')){
            if(
            changeValues.web_project.pauseCategory === "" || changeValues.web_project.pauseClientComments === "" || changeValues.web_project.pauseEffectDate === "" ||
            changeValues.web_project.pauseReason === "" || changeValues.web_project.pauseRecommenceDate === "" || changeValues.web_project.pauseRequestDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
    }

    /////////////////////////////////////////////////////////////////
    /////////////////// CANCELS
    if(changeValues.changeType ==='cancel'){
        if(changeValues.services.includes('GOOGLE SEO')){
            if(changeValues.seo_google.cancelAmComments === "" || changeValues.seo_google.cancelClientComments === "" || changeValues.seo_google.cancelDoneBetterComments === "" ||
            changeValues.seo_google.cancelEffectDate === "" || changeValues.seo_google.cancelNoteComments === "" || changeValues.seo_google.cancelReason === "" ||
            changeValues.seo_google.cancelRequestDate === "" || changeValues.seo_google.cancelSaveComments === "" || changeValues.seo_google.cancelStartDate === "" ||
            changeValues.seo_google.cancelReactivate === "" || changeValues.seo_google.cancelReactivate === "none" || changeValues.seo_google.contractFinishDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('GOOGLE ADS')){
            if(
            changeValues.ppc_google.cancelAmComments === "" || changeValues.ppc_google.cancelClientComments === "" || changeValues.ppc_google.cancelDoneBetterComments === "" ||
            changeValues.ppc_google.cancelEffectDate === "" || changeValues.ppc_google.cancelNoteComments === "" || changeValues.ppc_google.cancelReason === "" ||
            changeValues.ppc_google.cancelRequestDate === "" || changeValues.ppc_google.cancelSaveComments === "" || changeValues.ppc_google.cancelStartDate === "" ||
            changeValues.ppc_google.cancelReactivate === "" || changeValues.ppc_google.cancelReactivate === "none" || changeValues.ppc_google.contractFinishDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('MICROSOFT SEO')){
            if(changeValues.seo_bing.cancelAmComments === "" || changeValues.seo_bing.cancelClientComments === "" || changeValues.seo_bing.cancelDoneBetterComments === "" ||
            changeValues.seo_bing.cancelEffectDate === "" || changeValues.seo_bing.cancelNoteComments === "" || changeValues.seo_bing.cancelReason === "" ||
            changeValues.seo_bing.cancelRequestDate === "" || changeValues.seo_bing.cancelSaveComments === "" || changeValues.seo_bing.cancelStartDate === "" ||
            changeValues.seo_bing.cancelReactivate === "" || changeValues.seo_bing.cancelReactivate === "none" || changeValues.seo_bing.contractFinishDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('MICROSOFT ADS')){
            if(
            changeValues.social_bing.cancelAmComments === "" || changeValues.social_bing.cancelClientComments === "" || changeValues.social_bing.cancelDoneBetterComments === "" ||
            changeValues.social_bing.cancelEffectDate === "" || changeValues.social_bing.cancelNoteComments === "" || changeValues.social_bing.cancelReason === "" ||
            changeValues.social_bing.cancelRequestDate === "" || changeValues.social_bing.cancelSaveComments === "" || changeValues.social_bing.cancelStartDate === "" ||
            changeValues.social_bing.cancelReactivate === "" || changeValues.social_bing.cancelReactivate === "none" || changeValues.social_bing.contractFinishDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('REMARKETING')){
            if(
            changeValues.display.cancelAmComments === "" || changeValues.display.cancelClientComments === "" || changeValues.display.cancelDoneBetterComments === "" ||
            changeValues.display.cancelEffectDate === "" || changeValues.display.cancelNoteComments === "" || changeValues.display.cancelReason === "" ||
            changeValues.display.cancelRequestDate === "" || changeValues.display.cancelSaveComments === "" || changeValues.display.cancelStartDate === "" ||
            changeValues.display.cancelReactivate === "" || changeValues.display.cancelReactivate === "none" || changeValues.display.contractFinishDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('META')){
            if(
            changeValues.social_meta.cancelAmComments === "" || changeValues.social_meta.cancelClientComments === "" || changeValues.social_meta.cancelDoneBetterComments === "" ||
            changeValues.social_meta.cancelEffectDate === "" || changeValues.social_meta.cancelNoteComments === "" || changeValues.social_meta.cancelReason === "" ||
            changeValues.social_meta.cancelRequestDate === "" || changeValues.social_meta.cancelSaveComments === "" || changeValues.social_meta.cancelStartDate === "" ||
            changeValues.social_meta.cancelReactivate === "" || changeValues.social_meta.cancelReactivate === "none" || changeValues.social_meta.contractFinishDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('PINTEREST')){
            if(
            changeValues.social_pinterest.cancelAmComments === "" || changeValues.social_pinterest.cancelClientComments === "" || changeValues.social_pinterest.cancelDoneBetterComments === "" ||
            changeValues.social_pinterest.cancelEffectDate === "" || changeValues.social_pinterest.cancelNoteComments === "" || changeValues.social_pinterest.cancelReason === "" ||
            changeValues.social_pinterest.cancelRequestDate === "" || changeValues.social_pinterest.cancelSaveComments === "" || changeValues.social_pinterest.cancelStartDate === "" ||
            changeValues.social_pinterest.cancelReactivate === "" || changeValues.social_pinterest.cancelReactivate === "none" || changeValues.social_pinterest.contractFinishDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('TIKTOK')){
            if(
            changeValues.social_tiktok.cancelAmComments === "" || changeValues.social_tiktok.cancelClientComments === "" || changeValues.social_tiktok.cancelDoneBetterComments === "" ||
            changeValues.social_tiktok.cancelEffectDate === "" || changeValues.social_tiktok.cancelNoteComments === "" || changeValues.social_tiktok.cancelReason === "" ||
            changeValues.social_tiktok.cancelRequestDate === "" || changeValues.social_tiktok.cancelSaveComments === "" || changeValues.social_tiktok.cancelStartDate === "" ||
            changeValues.social_tiktok.cancelReactivate === "" || changeValues.social_tiktok.cancelReactivate === "none" || changeValues.social_tiktok.contractFinishDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('PROGRAMMATIC')){
            if(
            changeValues.social_programmatic.cancelAmComments === "" || changeValues.social_programmatic.cancelClientComments === "" || changeValues.social_programmatic.cancelDoneBetterComments === "" ||
            changeValues.social_programmatic.cancelEffectDate === "" || changeValues.social_programmatic.cancelNoteComments === "" || changeValues.social_programmatic.cancelReason === "" ||
            changeValues.social_programmatic.cancelRequestDate === "" || changeValues.social_programmatic.cancelSaveComments === "" || changeValues.social_programmatic.cancelStartDate === "" ||
            changeValues.social_programmatic.cancelReactivate === "" || changeValues.social_programmatic.cancelReactivate === "none" || changeValues.social_programmatic.contractFinishDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('LINKEDIN')){
            if(
            changeValues.social_linkedin.cancelAmComments === "" || changeValues.social_linkedin.cancelClientComments === "" || changeValues.social_linkedin.cancelDoneBetterComments === "" ||
            changeValues.social_linkedin.cancelEffectDate === "" || changeValues.social_linkedin.cancelNoteComments === "" || changeValues.social_linkedin.cancelReason === "" ||
            changeValues.social_linkedin.cancelRequestDate === "" || changeValues.social_linkedin.cancelSaveComments === "" || changeValues.social_linkedin.cancelStartDate === "" ||
            changeValues.social_linkedin.cancelReactivate === "" || changeValues.social_linkedin.cancelReactivate === "none" || changeValues.social_linkedin.contractFinishDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('TACTICAL')){
            if(
            changeValues.social_tactical.cancelAmComments === "" || changeValues.social_tactical.cancelClientComments === "" || changeValues.social_tactical.cancelDoneBetterComments === "" ||
            changeValues.social_tactical.cancelEffectDate === "" || changeValues.social_tactical.cancelNoteComments === "" || changeValues.social_tactical.cancelReason === "" ||
            changeValues.social_tactical.cancelRequestDate === "" || changeValues.social_tactical.cancelSaveComments === "" || changeValues.social_tactical.cancelStartDate === "" ||
            changeValues.social_tactical.cancelReactivate === "" || changeValues.social_tactical.cancelReactivate === "none" || changeValues.social_tactical.contractFinishDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('CREATIVE')){
            if(
            changeValues.social_creative.cancelAmComments === "" || changeValues.social_creative.cancelClientComments === "" || changeValues.social_creative.cancelDoneBetterComments === "" ||
            changeValues.social_creative.cancelEffectDate === "" || changeValues.social_creative.cancelNoteComments === "" || changeValues.social_creative.cancelReason === "" ||
            changeValues.social_creative.cancelRequestDate === "" || changeValues.social_creative.cancelSaveComments === "" || changeValues.social_creative.cancelStartDate === "" ||
            changeValues.social_creative.cancelReactivate === "" || changeValues.social_creative.cancelReactivate === "none" || changeValues.social_creative.contractFinishDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('WEB HOSTING')){
            if(
            changeValues.web_hosting.cancelHostingDate === "" || changeValues.web_hosting.cancelHostingEffectDate === "" || changeValues.web_hosting.cancelAmComments === "" || 
            changeValues.web_hosting.cancelClientComments === "" || changeValues.web_hosting.cancelDoneBetterComments === "" ||
            changeValues.web_hosting.cancelNoteComments === "" || changeValues.web_hosting.cancelReason === "" || changeValues.web_hosting.cancelReactivate === "none"  ||
            changeValues.web_hosting.cancelSaveComments === "" || changeValues.web_hosting.cancelStartDate === "" || changeValues.web_hosting.cancelReactivate === "" || changeValues.web_hosting.contractFinishDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.changeSubType.includes('WEB MAINTENANCE')){
            if(
            changeValues.web_maintenance.cancelHostingDate === "" || changeValues.web_maintenance.cancelHostingEffectDate === "" || changeValues.web_maintenance.cancelAmComments === "" || 
            changeValues.web_maintenance.cancelClientComments === "" || changeValues.web_maintenance.cancelDoneBetterComments === "" ||
            changeValues.web_maintenance.cancelNoteComments === "" || changeValues.web_maintenance.cancelReason === "" || changeValues.web_maintenance.cancelReactivate === "none"  ||
            changeValues.web_maintenance.cancelSaveComments === "" || changeValues.web_maintenance.cancelStartDate === "" || changeValues.web_maintenance.cancelReactivate === "" || changeValues.web_maintenance.contractFinishDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.changeSubType.includes('LANDING')){
            if(
            changeValues.web_landing.cancelHostingDate === "" || changeValues.web_landing.cancelHostingEffectDate === "" || changeValues.web_landing.cancelAmComments === "" || 
            changeValues.web_landing.cancelClientComments === "" || changeValues.web_landing.cancelDoneBetterComments === "" ||
            changeValues.web_landing.cancelNoteComments === "" || changeValues.web_landing.cancelReason === "" || changeValues.web_landing.cancelReactivate === "none"  ||
            changeValues.web_landing.cancelSaveComments === "" || changeValues.web_landing.cancelStartDate === "" || changeValues.web_landing.cancelReactivate === "" || changeValues.web_landing.contractFinishDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.changeSubType.includes('PROJECT')){
            if(
            changeValues.web_project.cancelHostingDate === "" || changeValues.web_project.cancelHostingEffectDate === "" || changeValues.web_project.cancelAmComments === "" || 
            changeValues.web_project.cancelClientComments === "" || changeValues.web_project.cancelDoneBetterComments === "" ||
            changeValues.web_project.cancelNoteComments === "" || changeValues.web_project.cancelReason === "" || changeValues.web_project.cancelReactivate === "none"  ||
            changeValues.web_project.cancelSaveComments === "" || changeValues.web_project.cancelStartDate === "" || changeValues.web_project.cancelReactivate === "" ||  changeValues.web_project.contractFinishDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
    }

    /////////////////////////////////////////////////////////////////
    /////////////////// STOP WORK
    if(changeValues.changeType ==='stop'){
        if(changeValues.services.includes('GOOGLE SEO')){
            if(
            changeValues.seo_google.stopWorkComments === "" || changeValues.seo_google.stopWorkContractValue === "" || 
            changeValues.seo_google.stopWorkRequestDate === "" || changeValues.seo_google.stopWorkStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('GOOGLE ADS')){
            if(
            changeValues.ppc_google.stopWorkComments === "" || changeValues.ppc_google.stopWorkContractValue === "" || 
            changeValues.ppc_google.stopWorkRequestDate === "" || changeValues.ppc_google.stopWorkStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('MICROSOFT SEO')){
            if(
            changeValues.seo_bing.stopWorkComments === "" || changeValues.seo_bing.stopWorkContractValue === "" || 
            changeValues.seo_bing.stopWorkRequestDate === "" || changeValues.seo_bing.stopWorkStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('MICROSOFT ADS')){
            if(
            changeValues.social_bing.stopWorkComments === "" || changeValues.social_bing.stopWorkContractValue === "" || 
            changeValues.social_bing.stopWorkRequestDate === "" || changeValues.social_bing.stopWorkStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('REMARKETING')){
            if(
            changeValues.display.stopWorkComments === "" || changeValues.display.stopWorkContractValue === "" || 
            changeValues.display.stopWorkRequestDate === "" || changeValues.display.stopWorkStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('META')){
            if(
            changeValues.social_meta.stopWorkComments === "" || changeValues.social_meta.stopWorkContractValue === "" || 
            changeValues.social_meta.stopWorkRequestDate === "" || changeValues.social_meta.stopWorkStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('PINTEREST')){
            if(
            changeValues.social_pinterest.stopWorkComments === "" || changeValues.social_pinterest.stopWorkContractValue === "" || 
            changeValues.social_pinterest.stopWorkRequestDate === "" || changeValues.social_pinterest.stopWorkStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('TIKTOK')){
            if(
            changeValues.social_tiktok.stopWorkComments === "" || changeValues.social_tiktok.stopWorkContractValue === "" || 
            changeValues.social_tiktok.stopWorkRequestDate === "" || changeValues.social_tiktok.stopWorkStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('PROGRAMMATIC')){
            if(
            changeValues.social_programmatic.stopWorkComments === "" || changeValues.social_programmatic.stopWorkContractValue === "" || 
            changeValues.social_programmatic.stopWorkRequestDate === "" || changeValues.social_programmatic.stopWorkStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('LINKEDIN')){
            if(
            changeValues.social_linkedin.stopWorkComments === "" || changeValues.social_linkedin.stopWorkContractValue === "" || 
            changeValues.social_linkedin.stopWorkRequestDate === "" || changeValues.social_linkedin.stopWorkStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('TACTICAL')){
            if(
            changeValues.social_tactical.stopWorkComments === "" || changeValues.social_tactical.stopWorkContractValue === "" || 
            changeValues.social_tactical.stopWorkRequestDate === "" || changeValues.social_tactical.stopWorkStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('CREATIVE')){
            if(
            changeValues.social_creative.stopWorkComments === "" || changeValues.social_creative.stopWorkContractValue === "" || 
            changeValues.social_creative.stopWorkRequestDate === "" || changeValues.social_creative.stopWorkStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('WEB HOSTING')){
            if(
            changeValues.web_hosting.stopWorkComments === "" || changeValues.web_hosting.stopWorkContractValue === "" || 
            changeValues.web_hosting.stopWorkRequestDate === "" || changeValues.web_hosting.stopWorkStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('WEB MAINTENANCE')){
            if(
            changeValues.web_maintenance.stopWorkComments === "" || changeValues.web_maintenance.stopWorkContractValue === "" || 
            changeValues.web_maintenance.stopWorkRequestDate === "" || changeValues.web_maintenance.stopWorkStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('LANDING')){
            if(
            changeValues.web_landing.stopWorkComments === "" || changeValues.web_landing.stopWorkContractValue === "" || 
            changeValues.web_landing.stopWorkRequestDate === "" || changeValues.web_landing.stopWorkStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('PROJECT')){
            if(
            changeValues.web_project.stopWorkComments === "" || changeValues.web_project.stopWorkContractValue === "" || 
            changeValues.web_project.stopWorkRequestDate === "" || changeValues.web_project.stopWorkStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
    }
    
    /////////////////////////////////////////////////////////////////
    /////////////////// DELAY BILLING
    if(changeValues.changeType ==='delay'){
        if(changeValues.services.includes('GOOGLE SEO')){
            if(
            changeValues.seo_google.delayBillingComments === "" || changeValues.seo_google.delayBillingReason === "" || 
            changeValues.seo_google.delayBillingRequestDate === "" || changeValues.seo_google.delayBillingStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('GOOGLE ADS')){
            if(
            changeValues.ppc_google.delayBillingComments === "" || changeValues.ppc_google.delayBillingReason === "" || 
            changeValues.ppc_google.delayBillingRequestDate === "" || changeValues.ppc_google.delayBillingStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('MICROSOFT SEO')){
            if(
            changeValues.seo_bing.delayBillingComments === "" || changeValues.seo_bing.delayBillingReason === "" || 
            changeValues.seo_bing.delayBillingRequestDate === "" || changeValues.seo_bing.delayBillingStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('MICROSOFT ADS')){
            if(
            changeValues.social_bing.delayBillingComments === "" || changeValues.social_bing.delayBillingReason === "" || 
            changeValues.social_bing.delayBillingRequestDate === "" || changeValues.social_bing.delayBillingStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('REMARKETING')){
            if(
            changeValues.display.delayBillingComments === "" || changeValues.display.delayBillingReason === "" || 
            changeValues.display.delayBillingRequestDate === "" || changeValues.display.delayBillingStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('META')){
            if(
            changeValues.social_meta.delayBillingComments === "" || changeValues.social_meta.delayBillingReason === "" || 
            changeValues.social_meta.delayBillingRequestDate === "" || changeValues.social_meta.delayBillingStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('PINTEREST')){
            if(
            changeValues.social_pinterest.delayBillingComments === "" || changeValues.social_pinterest.delayBillingReason === "" || 
            changeValues.social_pinterest.delayBillingRequestDate === "" || changeValues.social_pinterest.delayBillingStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('TIKTOK')){
            if(
            changeValues.social_tiktok.delayBillingComments === "" || changeValues.social_tiktok.delayBillingReason === "" || 
            changeValues.social_tiktok.delayBillingRequestDate === "" || changeValues.social_tiktok.delayBillingStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('PROGRAMMATIC')){
            if(
            changeValues.social_programmatic.delayBillingComments === "" || changeValues.social_programmatic.delayBillingReason === "" || 
            changeValues.social_programmatic.delayBillingRequestDate === "" || changeValues.social_programmatic.delayBillingStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('LINKEDIN')){
            if(
            changeValues.social_linkedin.delayBillingComments === "" || changeValues.social_linkedin.delayBillingReason === "" || 
            changeValues.social_linkedin.delayBillingRequestDate === "" || changeValues.social_linkedin.delayBillingStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('TACTICAL')){
            if(
            changeValues.social_tactical.delayBillingComments === "" || changeValues.social_tactical.delayBillingReason === "" || 
            changeValues.social_tactical.delayBillingRequestDate === "" || changeValues.social_tactical.delayBillingStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('CREATIVE')){
            if(
            changeValues.social_creative.delayBillingComments === "" || changeValues.social_creative.delayBillingReason === "" || 
            changeValues.social_creative.delayBillingRequestDate === "" || changeValues.social_creative.delayBillingStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('WEB HOSTING')){
            if(
            changeValues.web_hosting.delayBillingComments === "" || changeValues.web_hosting.delayBillingReason === "" || 
            changeValues.web_hosting.delayBillingRequestDate === "" || changeValues.web_hosting.delayBillingStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('WEB MAINTENANCE')){
            if(
            changeValues.web_maintenance.delayBillingComments === "" || changeValues.web_maintenance.delayBillingReason === "" || 
            changeValues.web_maintenance.delayBillingRequestDate === "" || changeValues.web_maintenance.delayBillingStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('LANDING')){
            if(
            changeValues.web_landing.delayBillingComments === "" || changeValues.web_landing.delayBillingReason === "" || 
            changeValues.web_landing.delayBillingRequestDate === "" || changeValues.web_landing.delayBillingStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('PROJECT')){
            if(
            changeValues.web_project.delayBillingComments === "" || changeValues.web_project.delayBillingReason === "" || 
            changeValues.web_project.delayBillingRequestDate === "" || changeValues.web_project.delayBillingStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
    }

    /////////////////////////////////////////////////////////////////
    /////////////////// REDUCE BILLING
    if(changeValues.changeType ==='reduce'){
        if(changeValues.services.includes('GOOGLE SEO')){
            if(
            changeValues.seo_google.reduceBillingNewBudget === "" || changeValues.seo_google.reduceBillingReason === "" || 
            changeValues.seo_google.reduceBillingStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('GOOGLE ADS')){
            if(
            changeValues.ppc_google.reduceBillingNewBudget === "" || changeValues.ppc_google.reduceBillingReason === "" || 
            changeValues.ppc_google.reduceBillingStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('MICROSOFT SEO')){
            if(
            changeValues.seo_bing.reduceBillingNewBudget === "" || changeValues.seo_bing.reduceBillingReason === "" || 
            changeValues.seo_bing.reduceBillingStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('MICROSOFT ADS')){
            if(
            changeValues.social_bing.reduceBillingNewBudget === "" || changeValues.social_bing.reduceBillingReason === "" || 
            changeValues.social_bing.reduceBillingStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('REMARKETING')){
            if(
            changeValues.display.reduceBillingNewBudget === "" || changeValues.display.reduceBillingReason === "" || 
            changeValues.display.reduceBillingStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('META')){
            if(
            changeValues.social_meta.reduceBillingNewBudget === "" || changeValues.social_meta.reduceBillingReason === "" || 
            changeValues.social_meta.reduceBillingStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('PINTEREST')){
            if(
            changeValues.social_pinterest.reduceBillingNewBudget === "" || changeValues.social_pinterest.reduceBillingReason === "" || 
            changeValues.social_pinterest.reduceBillingStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('TIKTOK')){
            if(
            changeValues.social_tiktok.reduceBillingNewBudget === "" || changeValues.social_tiktok.reduceBillingReason === "" || 
            changeValues.social_tiktok.reduceBillingStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('PROGRAMMATIC')){
            if(
            changeValues.social_programmatic.reduceBillingNewBudget === "" || changeValues.social_programmatic.reduceBillingReason === "" || 
            changeValues.social_programmatic.reduceBillingStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('LINKEDIN')){
            if(
            changeValues.social_linkedin.reduceBillingNewBudget === "" || changeValues.social_linkedin.reduceBillingReason === "" || 
            changeValues.social_linkedin.reduceBillingStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('TACTICAL')){
            if(
            changeValues.social_tactical.reduceBillingNewBudget === "" || changeValues.social_tactical.reduceBillingReason === "" || 
            changeValues.social_tactical.reduceBillingStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('CREATIVE')){
            if(
            changeValues.social_creative.reduceBillingNewBudget === "" || changeValues.social_creative.reduceBillingReason === "" || 
            changeValues.social_creative.reduceBillingStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('WEB HOSTING')){
            if(
            changeValues.web_hosting.reduceBillingNewBudget === "" || changeValues.web_hosting.reduceBillingReason === "" || 
            changeValues.web_hosting.reduceBillingStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('WEB MAINTENANCE')){
            if(
            changeValues.web_maintenance.reduceBillingNewBudget === "" || changeValues.web_maintenance.reduceBillingReason === "" || 
            changeValues.web_maintenance.reduceBillingStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('LANDING')){
            if(
            changeValues.web_landing.reduceBillingNewBudget === "" || changeValues.web_landing.reduceBillingReason === "" || 
            changeValues.web_landing.reduceBillingStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('PROJECT')){
            if(
            changeValues.web_project.reduceBillingNewBudget === "" || changeValues.web_project.reduceBillingReason === "" || 
            changeValues.web_project.reduceBillingStartDate === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
    }    

    /////////////////////////////////////////////////////////////////
    /////////////////// REFUNDS
    if(changeValues.changeType ==='refund'){
        if(changeValues.services.includes('GOOGLE SEO')){
            if(
            changeValues.seo_google.refundAmount === "" || changeValues.seo_google.refundReason === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('GOOGLE ADS')){
            if(
            changeValues.ppc_google.refundAmount === "" || changeValues.ppc_google.refundReason === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('MICROSOFT SEO')){
            if(
            changeValues.seo_bing.refundAmount === "" || changeValues.seo_bing.refundReason === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('MICROSOFT ADS')){
            if(
            changeValues.social_bing.refundAmount === "" || changeValues.social_bing.refundReason === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('REMARKETING')){
            if(
            changeValues.display.refundAmount === "" || changeValues.display.refundReason === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('META')){
            if(
            changeValues.social_meta.refundAmount === "" || changeValues.social_meta.refundReason === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('PINTEREST')){
            if(
            changeValues.social_pinterest.refundAmount === "" || changeValues.social_pinterest.refundReason === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('TIKTOK')){
            if(
            changeValues.social_tiktok.refundAmount === "" || changeValues.social_tiktok.refundReason === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('PROGRAMMATIC')){
            if(
            changeValues.social_programmatic.refundAmount === "" || changeValues.social_programmatic.refundReason === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('LINKEDIN')){
            if(
            changeValues.social_linkedin.refundAmount === "" || changeValues.social_linkedin.refundReason === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('TACTICAL')){
            if(
            changeValues.social_tactical.refundAmount === "" || changeValues.social_tactical.refundReason === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('CREATIVE')){
            if(
            changeValues.social_tactical.refundAmount === "" || changeValues.social_tactical.refundReason === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('WEB HOSTING')){
            if(
            changeValues.web_hosting.refundAmount === "" || changeValues.web_hosting.refundReason === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('WEB MAINTENANCE')){
            if(
            changeValues.web_maintenance.refundAmount === "" || changeValues.web_maintenance.refundReason === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('LANDING')){
            if(
            changeValues.web_landing.refundAmount === "" || changeValues.web_landing.refundReason === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('PROJECT')){
            if(
            changeValues.web_project.refundAmount === "" || changeValues.web_project.refundReason === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
    }        

    /////////////////////////////////////////////////////////////////
    /////////////////// APPLY CREDIT
    if(changeValues.changeType ==='apply'){
        if(changeValues.services.includes('GOOGLE SEO')){
            if(
            changeValues.seo_google.applyCreditAmount === "" || changeValues.seo_google.applyCreditReason === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('GOOGLE ADS')){
            if(
            changeValues.ppc_google.applyCreditAmount === "" || changeValues.ppc_google.applyCreditReason === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('MICROSOFT SEO')){
            if(
            changeValues.seo_bing.applyCreditAmount === "" || changeValues.seo_bing.applyCreditReason === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('MICROSOFT ADS')){
            if(
            changeValues.social_bing.applyCreditAmount === "" || changeValues.social_bing.applyCreditReason === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('REMARKETING')){
            if(
            changeValues.display.applyCreditAmount === "" || changeValues.display.applyCreditReason === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('META')){
            if(
            changeValues.social_meta.applyCreditAmount === "" || changeValues.social_meta.applyCreditReason === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('PINTEREST')){
            if(
            changeValues.social_pinterest.applyCreditAmount === "" || changeValues.social_pinterest.applyCreditReason === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('TIKTOK')){
            if(
            changeValues.social_tiktok.applyCreditAmount === "" || changeValues.social_tiktok.applyCreditReason === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('PROGRAMMATIC')){
            if(
            changeValues.social_programmatic.applyCreditAmount === "" || changeValues.social_programmatic.applyCreditReason === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('LINKEDIN')){
            if(
            changeValues.social_linkedin.applyCreditAmount === "" || changeValues.social_linkedin.applyCreditReason === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('TACTICAL')){
            if(
            changeValues.social_tactical.applyCreditAmount === "" || changeValues.social_tactical.applyCreditReason === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('CREATIVE')){
            if(
            changeValues.social_creative.applyCreditAmount === "" || changeValues.social_creative.applyCreditReason === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('WEB HOSTING')){
            if(
            changeValues.web_hosting.applyCreditAmount === "" || changeValues.web_hosting.applyCreditReason === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('WEB MAINTENANCE')){
            if(
            changeValues.web_maintenance.applyCreditAmount === "" || changeValues.web_maintenance.applyCreditReason === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('LANDING')){
            if(
            changeValues.web_landing.applyCreditAmount === "" || changeValues.web_landing.applyCreditReason === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
        if(changeValues.services.includes('PROJECT')){
            if(
            changeValues.web_project.applyCreditAmount === "" || changeValues.web_project.applyCreditReason === ""
            ){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            }
        }
    }

    /////////////////////////////////////////////////////////////////
    /////////////////// FLAGGED CLIENTS
    if(changeValues.changeType ==='flagged'){
        if(changeValues.services.includes('GOOGLE SEO')){
            if(changeValues.seo_google.flaggedReason === '' || changeValues.seo_google.flaggedReason === 'none'){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            } 
            if(changeValues.seo_google.flaggedReason === 'Traffic Drop'){
                if(changeValues.seo_google.flaggedDeficit === "" || changeValues.seo_google.flaggedTimePeriod === "" || changeValues.seo_google.flaggedPages === "" ||
                    changeValues.seo_google.flaggedFrom === "none" || changeValues.seo_google.flaggedFrom === ""){
                        return {
                            failed: true,
                            message: "All fields are required to be filled",
                        } 
                }
            } 
            if(changeValues.seo_google.flaggedReason === 'Ranking Drop'){
                if(changeValues.seo_google.flaggedKeywords === "" || changeValues.seo_google.flaggedFrom === "none" || changeValues.seo_google.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            } 
            if(changeValues.seo_google.flaggedReason === 'Work Quality'){
                if(changeValues.seo_google.flaggedDocuments === "" || changeValues.seo_google.flaggedQuality === "" || changeValues.seo_google.flaggedFrom === "none" || changeValues.seo_google.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            } 
            if(changeValues.seo_google.flaggedReason === 'Perfomance'){
                if(changeValues.seo_google.flaggedNoteComments === "" || changeValues.seo_google.flaggedFrom === "none" || changeValues.seo_google.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            }
        }
        if(changeValues.services.includes('GOOGLE ADS')){
            if(changeValues.ppc_google.flaggedReason === '' || changeValues.ppc_google.flaggedReason === 'none'){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            } 
            if(changeValues.ppc_google.flaggedReason === 'Traffic Drop'){
                if(changeValues.ppc_google.flaggedDeficit === "" || changeValues.ppc_google.flaggedTimePeriod === "" || changeValues.ppc_google.flaggedPages === "" ||
                    changeValues.ppc_google.flaggedFrom === "none" || changeValues.ppc_google.flaggedFrom === ""){
                        return {
                            failed: true,
                            message: "All fields are required to be filled",
                        } 
                }
            } 
            if(changeValues.ppc_google.flaggedReason === 'Ranking Drop'){
                if(changeValues.ppc_google.flaggedKeywords === "" || changeValues.ppc_google.flaggedFrom === "none" || changeValues.ppc_google.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            } 
            if(changeValues.ppc_google.flaggedReason === 'Work Quality'){
                if(changeValues.ppc_google.flaggedDocuments === "" || changeValues.ppc_google.flaggedQuality === "" || changeValues.ppc_google.flaggedFrom === "none" || changeValues.ppc_google.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            } 
            if(changeValues.ppc_google.flaggedReason === 'Perfomance'){
                if(changeValues.ppc_google.flaggedNoteComments === "" || changeValues.ppc_google.flaggedFrom === "none" || changeValues.ppc_google.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            }
        }
        if(changeValues.services.includes('MICROSOFT SEO')){
            if(changeValues.seo_bing.flaggedReason === '' || changeValues.seo_bing.flaggedReason === 'none'){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            } 
            if(changeValues.seo_bing.flaggedReason === 'Traffic Drop'){
                if(changeValues.seo_bing.flaggedDeficit === "" || changeValues.seo_bing.flaggedTimePeriod === "" || changeValues.seo_bing.flaggedPages === "" ||
                    changeValues.seo_bing.flaggedFrom === "none" || changeValues.seo_bing.flaggedFrom === ""){
                        return {
                            failed: true,
                            message: "All fields are required to be filled",
                        } 
                }
            } 
            if(changeValues.seo_bing.flaggedReason === 'Ranking Drop'){
                if(changeValues.seo_bing.flaggedKeywords === "" || changeValues.seo_bing.flaggedFrom === "none" || changeValues.seo_bing.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            } 
            if(changeValues.seo_bing.flaggedReason === 'Work Quality'){
                if(changeValues.seo_bing.flaggedDocuments === "" || changeValues.seo_bing.flaggedQuality === "" || changeValues.seo_bing.flaggedFrom === "none" || changeValues.seo_bing.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            } 
            if(changeValues.seo_bing.flaggedReason === 'Perfomance'){
                if(changeValues.seo_bing.flaggedNoteComments === "" || changeValues.seo_bing.flaggedFrom === "none" || changeValues.seo_bing.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            }
        }
        if(changeValues.services.includes('MICROSOFT ADS')){
            if(changeValues.social_bing.flaggedReason === '' || changeValues.social_bing.flaggedReason === 'none'){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            } 
            if(changeValues.social_bing.flaggedReason === 'Traffic Drop'){
                if(changeValues.social_bing.flaggedDeficit === "" || changeValues.social_bing.flaggedTimePeriod === "" || changeValues.social_bing.flaggedPages === "" ||
                    changeValues.social_bing.flaggedFrom === "none" || changeValues.social_bing.flaggedFrom === ""){
                        return {
                            failed: true,
                            message: "All fields are required to be filled",
                        } 
                }
            } 
            if(changeValues.social_bing.flaggedReason === 'Ranking Drop'){
                if(changeValues.social_bing.flaggedKeywords === "" || changeValues.social_bing.flaggedFrom === "none" || changeValues.social_bing.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            } 
            if(changeValues.social_bing.flaggedReason === 'Work Quality'){
                if(changeValues.social_bing.flaggedDocuments === "" || changeValues.social_bing.flaggedQuality === "" || changeValues.social_bing.flaggedFrom === "none" || changeValues.social_bing.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            } 
            if(changeValues.social_bing.flaggedReason === 'Perfomance'){
                if(changeValues.social_bing.flaggedNoteComments === "" || changeValues.social_bing.flaggedFrom === "none" || changeValues.social_bing.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            }
        }
        if(changeValues.services.includes('REMARKETING')){
            if(changeValues.display.flaggedReason === '' || changeValues.display.flaggedReason === 'none'){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            } 
            if(changeValues.display.flaggedReason === 'Traffic Drop'){
                if(changeValues.display.flaggedDeficit === "" || changeValues.display.flaggedTimePeriod === "" || changeValues.display.flaggedPages === "" ||
                    changeValues.display.flaggedFrom === "none" || changeValues.display.flaggedFrom === ""){
                        return {
                            failed: true,
                            message: "All fields are required to be filled",
                        } 
                }
            } 
            if(changeValues.display.flaggedReason === 'Ranking Drop'){
                if(changeValues.display.flaggedKeywords === "" || changeValues.display.flaggedFrom === "none" || changeValues.display.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            } 
            if(changeValues.display.flaggedReason === 'Work Quality'){
                if(changeValues.display.flaggedDocuments === "" || changeValues.display.flaggedQuality === "" || changeValues.display.flaggedFrom === "none" || changeValues.display.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            } 
            if(changeValues.display.flaggedReason === 'Perfomance'){
                if(changeValues.display.flaggedNoteComments === "" || changeValues.display.flaggedFrom === "none" || changeValues.display.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            }
        }
        if(changeValues.services.includes('META')){
            if(changeValues.social_meta.flaggedReason === '' || changeValues.social_meta.flaggedReason === 'none'){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            } 
            if(changeValues.social_meta.flaggedReason === 'Traffic Drop'){
                if(changeValues.social_meta.flaggedDeficit === "" || changeValues.social_meta.flaggedTimePeriod === "" || changeValues.social_meta.flaggedPages === "" ||
                    changeValues.social_meta.flaggedFrom === "none" || changeValues.social_meta.flaggedFrom === ""){
                        return {
                            failed: true,
                            message: "All fields are required to be filled",
                        } 
                }
            } 
            if(changeValues.social_meta.flaggedReason === 'Ranking Drop'){
                if(changeValues.social_meta.flaggedKeywords === "" || changeValues.social_meta.flaggedFrom === "none" || changeValues.social_meta.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            } 
            if(changeValues.social_meta.flaggedReason === 'Work Quality'){
                if(changeValues.social_meta.flaggedDocuments === "" || changeValues.social_meta.flaggedQuality === "" || changeValues.social_meta.flaggedFrom === "none" || changeValues.social_meta.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            } 
            if(changeValues.social_meta.flaggedReason === 'Perfomance'){
                if(changeValues.social_meta.flaggedNoteComments === "" || changeValues.social_meta.flaggedFrom === "none" || changeValues.social_meta.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            }
        }
        if(changeValues.services.includes('PINTEREST')){
            if(changeValues.social_pinterest.flaggedReason === '' || changeValues.social_pinterest.flaggedReason === 'none'){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            } 
            if(changeValues.social_pinterest.flaggedReason === 'Traffic Drop'){
                if(changeValues.social_pinterest.flaggedDeficit === "" || changeValues.social_pinterest.flaggedTimePeriod === "" || changeValues.social_pinterest.flaggedPages === "" ||
                    changeValues.social_pinterest.flaggedFrom === "none" || changeValues.social_pinterest.flaggedFrom === ""){
                        return {
                            failed: true,
                            message: "All fields are required to be filled",
                        } 
                }
            } 
            if(changeValues.social_pinterest.flaggedReason === 'Ranking Drop'){
                if(changeValues.social_pinterest.flaggedKeywords === "" || changeValues.social_pinterest.flaggedFrom === "none" || changeValues.social_pinterest.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            } 
            if(changeValues.social_pinterest.flaggedReason === 'Work Quality'){
                if(changeValues.social_pinterest.flaggedDocuments === "" || changeValues.social_pinterest.flaggedQuality === "" || changeValues.social_pinterest.flaggedFrom === "none" || changeValues.social_pinterest.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            } 
            if(changeValues.social_pinterest.flaggedReason === 'Perfomance'){
                if(changeValues.social_pinterest.flaggedNoteComments === "" || changeValues.social_pinterest.flaggedFrom === "none" || changeValues.social_pinterest.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            }
        }
        if(changeValues.services.includes('TIKTOK')){
            if(changeValues.social_tiktok.flaggedReason === '' || changeValues.social_tiktok.flaggedReason === 'none'){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            } 
            if(changeValues.social_tiktok.flaggedReason === 'Traffic Drop'){
                if(changeValues.social_tiktok.flaggedDeficit === "" || changeValues.social_tiktok.flaggedTimePeriod === "" || changeValues.social_tiktok.flaggedPages === "" ||
                    changeValues.social_tiktok.flaggedFrom === "none" || changeValues.social_tiktok.flaggedFrom === ""){
                        return {
                            failed: true,
                            message: "All fields are required to be filled",
                        } 
                }
            } 
            if(changeValues.social_tiktok.flaggedReason === 'Ranking Drop'){
                if(changeValues.social_tiktok.flaggedKeywords === "" || changeValues.social_tiktok.flaggedFrom === "none" || changeValues.social_tiktok.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            } 
            if(changeValues.social_tiktok.flaggedReason === 'Work Quality'){
                if(changeValues.social_tiktok.flaggedDocuments === "" || changeValues.social_tiktok.flaggedQuality === "" || changeValues.social_tiktok.flaggedFrom === "none" || changeValues.social_tiktok.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            } 
            if(changeValues.social_tiktok.flaggedReason === 'Perfomance'){
                if(changeValues.social_tiktok.flaggedNoteComments === "" || changeValues.social_tiktok.flaggedFrom === "none" || changeValues.social_tiktok.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            }
        }
        if(changeValues.services.includes('PROGRAMMATIC')){
            if(changeValues.social_programmatic.flaggedReason === '' || changeValues.social_programmatic.flaggedReason === 'none'){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            } 
            if(changeValues.social_programmatic.flaggedReason === 'Traffic Drop'){
                if(changeValues.social_programmatic.flaggedDeficit === "" || changeValues.social_programmatic.flaggedTimePeriod === "" || changeValues.social_programmatic.flaggedPages === "" ||
                    changeValues.social_programmatic.flaggedFrom === "none" || changeValues.social_programmatic.flaggedFrom === ""){
                        return {
                            failed: true,
                            message: "All fields are required to be filled",
                        } 
                }
            } 
            if(changeValues.social_programmatic.flaggedReason === 'Ranking Drop'){
                if(changeValues.social_programmatic.flaggedKeywords === "" || changeValues.social_programmatic.flaggedFrom === "none" || changeValues.social_programmatic.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            } 
            if(changeValues.social_programmatic.flaggedReason === 'Work Quality'){
                if(changeValues.social_programmatic.flaggedDocuments === "" || changeValues.social_programmatic.flaggedQuality === "" || changeValues.social_programmatic.flaggedFrom === "none" || changeValues.social_programmatic.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            } 
            if(changeValues.social_programmatic.flaggedReason === 'Perfomance'){
                if(changeValues.social_programmatic.flaggedNoteComments === "" || changeValues.social_programmatic.flaggedFrom === "none" || changeValues.social_programmatic.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            }
        }
        if(changeValues.services.includes('LINKEDIN')){
            if(changeValues.social_linkedin.flaggedReason === '' || changeValues.social_linkedin.flaggedReason === 'none'){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            } 
            if(changeValues.social_linkedin.flaggedReason === 'Traffic Drop'){
                if(changeValues.social_linkedin.flaggedDeficit === "" || changeValues.social_linkedin.flaggedTimePeriod === "" || changeValues.social_linkedin.flaggedPages === "" ||
                    changeValues.social_linkedin.flaggedFrom === "none" || changeValues.social_linkedin.flaggedFrom === ""){
                        return {
                            failed: true,
                            message: "All fields are required to be filled",
                        } 
                }
            } 
            if(changeValues.social_linkedin.flaggedReason === 'Ranking Drop'){
                if(changeValues.social_linkedin.flaggedKeywords === "" || changeValues.social_linkedin.flaggedFrom === "none" || changeValues.social_linkedin.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            } 
            if(changeValues.social_linkedin.flaggedReason === 'Work Quality'){
                if(changeValues.social_linkedin.flaggedDocuments === "" || changeValues.social_linkedin.flaggedQuality === "" || changeValues.social_linkedin.flaggedFrom === "none" || changeValues.social_linkedin.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            } 
            if(changeValues.social_linkedin.flaggedReason === 'Perfomance'){
                if(changeValues.social_linkedin.flaggedNoteComments === "" || changeValues.social_linkedin.flaggedFrom === "none" || changeValues.social_linkedin.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            }
        }
        if(changeValues.services.includes('TACTICAL')){
            if(changeValues.social_tactical.flaggedReason === '' || changeValues.social_tactical.flaggedReason === 'none'){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            } 
            if(changeValues.social_tactical.flaggedReason === 'Traffic Drop'){
                if(changeValues.social_tactical.flaggedDeficit === "" || changeValues.social_tactical.flaggedTimePeriod === "" || changeValues.social_tactical.flaggedPages === "" ||
                    changeValues.social_tactical.flaggedFrom === "none" || changeValues.social_tactical.flaggedFrom === ""){
                        return {
                            failed: true,
                            message: "All fields are required to be filled",
                        } 
                }
            } 
            if(changeValues.social_tactical.flaggedReason === 'Ranking Drop'){
                if(changeValues.social_tactical.flaggedKeywords === "" || changeValues.social_tactical.flaggedFrom === "none" || changeValues.social_tactical.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            } 
            if(changeValues.social_tactical.flaggedReason === 'Work Quality'){
                if(changeValues.social_tactical.flaggedDocuments === "" || changeValues.social_tactical.flaggedQuality === "" || changeValues.social_tactical.flaggedFrom === "none" || changeValues.social_tactical.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            } 
            if(changeValues.social_tactical.flaggedReason === 'Perfomance'){
                if(changeValues.social_tactical.flaggedNoteComments === "" || changeValues.social_tactical.flaggedFrom === "none" || changeValues.social_tactical.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            }
        }
        if(changeValues.services.includes('CREATIVE')){
            if(changeValues.social_creative.flaggedReason === '' || changeValues.social_creative.flaggedReason === 'none'){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            } 
            if(changeValues.social_creative.flaggedReason === 'Traffic Drop'){
                if(changeValues.social_creative.flaggedDeficit === "" || changeValues.social_creative.flaggedTimePeriod === "" || changeValues.social_creative.flaggedPages === "" ||
                    changeValues.social_creative.flaggedFrom === "none" || changeValues.social_creative.flaggedFrom === ""){
                        return {
                            failed: true,
                            message: "All fields are required to be filled",
                        } 
                }
            } 
            if(changeValues.social_creative.flaggedReason === 'Ranking Drop'){
                if(changeValues.social_creative.flaggedKeywords === "" || changeValues.social_creative.flaggedFrom === "none" || changeValues.social_creative.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            } 
            if(changeValues.social_creative.flaggedReason === 'Work Quality'){
                if(changeValues.social_creative.flaggedDocuments === "" || changeValues.social_creative.flaggedQuality === "" || changeValues.social_creative.flaggedFrom === "none" || changeValues.social_creative.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            } 
            if(changeValues.social_creative.flaggedReason === 'Perfomance'){
                if(changeValues.social_creative.flaggedNoteComments === "" || changeValues.social_creative.flaggedFrom === "none" || changeValues.social_creative.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            }
        }
        if(changeValues.services.includes('WEB HOSTING')){
            if(changeValues.web_hosting.flaggedReason === '' || changeValues.web_hosting.flaggedReason === 'none'){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            } 
            if(changeValues.web_hosting.flaggedReason === 'Traffic Drop'){
                if(changeValues.web_hosting.flaggedDeficit === "" || changeValues.web_hosting.flaggedTimePeriod === "" || changeValues.web_hosting.flaggedPages === "" ||
                    changeValues.web_hosting.flaggedFrom === "none" || changeValues.web_hosting.flaggedFrom === ""){
                        return {
                            failed: true,
                            message: "All fields are required to be filled",
                        } 
                }
            } 
            if(changeValues.web_hosting.flaggedReason === 'Ranking Drop'){
                if(changeValues.web_hosting.flaggedKeywords === "" || changeValues.web_hosting.flaggedFrom === "none" || changeValues.web_hosting.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            } 
            if(changeValues.web_hosting.flaggedReason === 'Work Quality'){
                if(changeValues.web_hosting.flaggedDocuments === "" || changeValues.web_hosting.flaggedQuality === "" || changeValues.web_hosting.flaggedFrom === "none" || changeValues.web_hosting.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            } 
            if(changeValues.web_hosting.flaggedReason === 'Perfomance'){
                if(changeValues.web_hosting.flaggedNoteComments === "" || changeValues.web_hosting.flaggedFrom === "none" || changeValues.web_hosting.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            }
        }
        if(changeValues.services.includes('WEB MAINTENANCE')){
            if(changeValues.web_maintenance.flaggedReason === '' || changeValues.web_maintenance.flaggedReason === 'none'){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            } 
            if(changeValues.web_maintenance.flaggedReason === 'Traffic Drop'){
                if(changeValues.web_maintenance.flaggedDeficit === "" || changeValues.web_maintenance.flaggedTimePeriod === "" || changeValues.web_maintenance.flaggedPages === "" ||
                    changeValues.web_maintenance.flaggedFrom === "none" || changeValues.web_maintenance.flaggedFrom === ""){
                        return {
                            failed: true,
                            message: "All fields are required to be filled",
                        } 
                }
            } 
            if(changeValues.web_maintenance.flaggedReason === 'Ranking Drop'){
                if(changeValues.web_maintenance.flaggedKeywords === "" || changeValues.web_maintenance.flaggedFrom === "none" || changeValues.web_maintenance.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            } 
            if(changeValues.web_maintenance.flaggedReason === 'Work Quality'){
                if(changeValues.web_maintenance.flaggedDocuments === "" || changeValues.web_maintenance.flaggedQuality === "" || changeValues.web_maintenance.flaggedFrom === "none" || changeValues.web_maintenance.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            } 
            if(changeValues.web_maintenance.flaggedReason === 'Perfomance'){
                if(changeValues.web_maintenance.flaggedNoteComments === "" || changeValues.web_maintenance.flaggedFrom === "none" || changeValues.web_maintenance.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            }
        }
        if(changeValues.services.includes('LANDING')){
            if(changeValues.web_landing.flaggedReason === '' || changeValues.web_landing.flaggedReason === 'none'){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            } 
            if(changeValues.web_landing.flaggedReason === 'Traffic Drop'){
                if(changeValues.web_landing.flaggedDeficit === "" || changeValues.web_landing.flaggedTimePeriod === "" || changeValues.web_landing.flaggedPages === "" ||
                    changeValues.web_landing.flaggedFrom === "none" || changeValues.web_landing.flaggedFrom === ""){
                        return {
                            failed: true,
                            message: "All fields are required to be filled",
                        } 
                }
            } 
            if(changeValues.web_landing.flaggedReason === 'Ranking Drop'){
                if(changeValues.web_landing.flaggedKeywords === "" || changeValues.web_landing.flaggedFrom === "none" || changeValues.web_landing.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            } 
            if(changeValues.web_landing.flaggedReason === 'Work Quality'){
                if(changeValues.web_landing.flaggedDocuments === "" || changeValues.web_landing.flaggedQuality === "" || changeValues.web_landing.flaggedFrom === "none" || changeValues.web_landing.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            } 
            if(changeValues.web_landing.flaggedReason === 'Perfomance'){
                if(changeValues.web_landing.flaggedNoteComments === "" || changeValues.web_landing.flaggedFrom === "none" || changeValues.web_landing.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            }
        }
        if(changeValues.services.includes('PROJECT')){
            if(changeValues.web_project.flaggedReason === '' || changeValues.web_project.flaggedReason === 'none'){
                return {
                    failed: true,
                    message: "All fields are required to be filled",
                } 
            } 
            if(changeValues.web_project.flaggedReason === 'Traffic Drop'){
                if(changeValues.web_project.flaggedDeficit === "" || changeValues.web_project.flaggedTimePeriod === "" || changeValues.web_project.flaggedPages === "" ||
                    changeValues.web_project.flaggedFrom === "none" || changeValues.web_project.flaggedFrom === ""){
                        return {
                            failed: true,
                            message: "All fields are required to be filled",
                        } 
                }
            } 
            if(changeValues.web_project.flaggedReason === 'Ranking Drop'){
                if(changeValues.web_project.flaggedKeywords === "" || changeValues.web_project.flaggedFrom === "none" || changeValues.web_project.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            } 
            if(changeValues.web_project.flaggedReason === 'Work Quality'){
                if(changeValues.web_project.flaggedDocuments === "" || changeValues.web_project.flaggedQuality === "" || changeValues.web_project.flaggedFrom === "none" || changeValues.web_project.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            } 
            if(changeValues.web_project.flaggedReason === 'Perfomance'){
                if(changeValues.web_project.flaggedNoteComments === "" || changeValues.web_project.flaggedFrom === "none" || changeValues.web_project.flaggedFrom === ""){
                    return {
                        failed: true,
                        message: "All fields are required to be filled",
                    } 
                }
            }
        }
    }

    /////////////////////////////////////////////////////////////////
    /////////////////// CATCH NO LINE MANAGER 
    if(changeValues.changeType !=='reallocate staff'){
        if(changeValues.lineManager ==='' || changeValues.lineManager ==='none'){
            return {
                failed: true,
                message: "You must choose a Line Manager",
            } 
        }
    }

    /////////////////////////////////////////////////////////////////
    /////////////////// PASSED ALL CHECKS
    return {
        failed: false,
        message: '',
    } 
}
    
export default checkSubmission;