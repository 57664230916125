import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const GoalsIcon: React.FC<IconProps> = ({ color = 'black', size = 80 }) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 8.34778V5.34778L19 2.34778L20 4.34778L22 5.34778L19 8.34778H16ZM16 8.34778L12 12.3477M22 12.3478C22 17.8706 17.5228 22.3478 12 22.3478C6.47715 22.3478 2 17.8706 2 12.3478C2 6.82493 6.47715 2.34778 12 2.34778M17 12.3478C17 15.1092 14.7614 17.3478 12 17.3478C9.23858 17.3478 7 15.1092 7 12.3478C7 9.58635 9.23858 7.34778 12 7.34778"
      stroke={color || '#334969'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default GoalsIcon;
