import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const Globe03: React.FC<IconProps> = ({ color = '#80AECF', size = 16 }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1562_10276)">
      <path
        d="M6.99984 1.16699C8.74984 2.33366 9.28811 4.83734 9.33317 7.00033C9.28811 9.16331 8.74984 11.667 6.99984 12.8337M6.99984 1.16699C5.24984 2.33366 4.71156 4.83735 4.6665 7.00033C4.71157 9.16331 5.24984 11.667 6.99984 12.8337M6.99984 1.16699C3.77818 1.16699 1.1665 3.77866 1.1665 7.00033M6.99984 1.16699C10.2215 1.16699 12.8332 3.77866 12.8332 7.00033M6.99984 12.8337C10.2215 12.8337 12.8332 10.222 12.8332 7.00033M6.99984 12.8337C3.77818 12.8337 1.1665 10.222 1.1665 7.00033M12.8332 7.00033C11.6665 8.75033 9.16282 9.2886 6.99984 9.33366C4.83686 9.2886 2.33317 8.75033 1.1665 7.00033M12.8332 7.00033C11.6665 5.25033 9.16282 4.71205 6.99984 4.66699C4.83686 4.71205 2.33317 5.25033 1.1665 7.00033"
        stroke={color || '#000'}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1562_10276">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Globe03;
