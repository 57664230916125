import { Card } from '@tremor/react';
import React, { useMemo, useState } from 'react';
import PageHeader from '../../components/Dashboard/PageHeader';
import MyDetails from '../../components/Settings/myDetails';
import Icon from '../../assets/icons/SvgComponent';
import { mode } from '../../utils/settings';
import ReactSelect from '../../components/ReactSelect';
import TanStackTable from '../../components/TanStackTable';
import IndeterminateCheckbox from '../../components/IndeterminateCheckbox';
import useUserStore from '../../store/useUserStore';
import useAuthStore from '../../store/useAuthStore';

const ProfileSettings = () => {
  const { user, users, updateUser, loading } = useUserStore((state) => state);
  const { currentUser } = useAuthStore((state) => state);
  const iconColor = mode === 'dark' ? 'white' : 'black';
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [selectedRow, setSelectedRow] = useState([]);
  const [rowState, setRowState] = useState(true);

  return (
    <div className="flex flex-col px-2 pt-20 sm:pt-0 sm:p-8 bg-[#D2EEFF] gap-y-5 dark:bg-[#1D1D1D]">
      <MyDetails />
      {/* <Card className='flex flex-col gap-4'>
        <span className='text-lg font-semibold text-[#001C44] dark:text-white'>My Clients</span>
        <div className='flex items-center gap-2 p-3  dark:bg-[#262627] sticky top-0 z-50'>
          <div className='relative'>
            <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
              <Icon name='SearchRefractionIcon' color={mode === 'dark' ? '#fff' : iconColor} />
            </div>
            <input
              type='text'
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              // onKeyDown={(e) => {
              //   if (e.key === 'Enter') {
              //     handleFetchUsers(1);
              //     setPage(1);
              //   }
              // }}
              className='block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-white focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
              placeholder='Search for anything'
            />
            <div className='absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none'>
              <Icon name='KeywordsRanking' color={mode === 'dark' ? '#fff' : iconColor} />
            </div>
          </div>
        </div>
        <TanStackTable
          data={!formattedDataMemo ? [] : formattedDataMemo}
          columns={columns}
          totalPages={10}
          page={page}
          setPage={setPage}
          loading={loading}
          setSelectedRow={setSelectedRow}
          status={status}
          rowState={rowState}
          setRowState={setRowState}
          role={role}
          tableHeight='70dvh'
        />
      </Card> */}
    </div>
  );
};

export default ProfileSettings;
