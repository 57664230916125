import Pagination from '../Pagination';
import { StyledTable, TableContainer } from './styles';

const Table = ({
  headers,
  content,
  totalPages,
  page,
  setPage,
  loading,
  total_docs,
  ...other
}) => {
  const noMinHeight = total_docs === 0;
  return (
    <TableContainer noMinHeight={noMinHeight}>
      <StyledTable>
        <thead>
          <tr>
            {headers.map((header, i) => (
              <th key={i}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>{content}</tbody>
      </StyledTable>
      {!loading && (
        <Pagination totalPages={totalPages} page={page} setPage={setPage} />
      )}
    </TableContainer>
  );
};
export default Table;
