import { Auth } from './auth';
import { Client } from './client';
import { User } from './user';
import { ChangeRequest, NotifierSettings } from './changeRequest';
import { Analytics } from './analytics';
import { Report } from './report';
import { Feedback } from './feedback';
import { Activities } from './activities';
import { Logs } from './logs';
import { Performance } from './performance';
import { Meta } from './meta';
import { ThirdParty } from './thirdParty';
import { HubSpot } from './hubspot';
import { Bing } from './bing';
import { Kpis } from './kpi';
import { Crawler } from './crawler';
import { LinkBrief } from './linkBrief';
import { Goal } from './goal';
import { Xero } from './xero';
import { Positions } from './positions';

export default {
  Auth,
  Client,
  User,
  ChangeRequest,
  NotifierSettings,
  Analytics,
  HubSpot,
  Report,
  Feedback,
  Activities,
  Logs,
  Performance,
  Meta,
  ThirdParty,
  Bing,
  Kpis,
  Crawler,
  LinkBrief,
  Goal,
  Xero,
  Positions
};
