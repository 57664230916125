import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const FeedbackIcon: React.FC<IconProps> = ({ color = 'black', size = 80 }) => (
  <svg
    width={size}
    height={size + 1}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 21.3478V19.3478C22 17.4839 20.7252 15.9178 19 15.4738M15.5 3.63854C16.9659 4.23192 18 5.66909 18 7.34778C18 9.02647 16.9659 10.4636 15.5 11.057M17 21.3478C17 19.484 17 18.5521 16.6955 17.817C16.2895 16.8369 15.5108 16.0582 14.5307 15.6523C13.7956 15.3478 12.8638 15.3478 11 15.3478H8C6.13623 15.3478 5.20435 15.3478 4.46927 15.6523C3.48915 16.0582 2.71046 16.8369 2.30448 17.817C2 18.5521 2 19.484 2 21.3478M13.5 7.34778C13.5 9.55692 11.7091 11.3478 9.5 11.3478C7.29086 11.3478 5.5 9.55692 5.5 7.34778C5.5 5.13864 7.29086 3.34778 9.5 3.34778C11.7091 3.34778 13.5 5.13864 13.5 7.34778Z"
      stroke={color || '#334969'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FeedbackIcon;
