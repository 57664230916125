import Icon from '../../assets/icons/SvgComponent';
import NoData from '../../assets/images/NoData.png';
import Tooltip from '../HelpTooltip';

interface IProps {
  error?: string;
  noAccessSubtitle?: string;
}

const NoDataError = ({ error, noAccessSubtitle }: IProps) => {
  return (
    <div className="container w-fit">
      {!error ? (
        <div className="flex flex-col items-center justify-center w-full no-error">
          <div className="mb-6 icon-container">
            <img src={NoData} alt="No Data" width={80} />
          </div>
          <p className="font-inter font-[600] text-sm text-center text-[#001C44] dark:text-white mb-4">
            No visibility data to display at the moment.
          </p>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center w-full error">
          <div className="mb-2 icon-container">
            <Icon name="AlertTriangle" size={70} />
          </div>
          <p className="font-inter font-[500] text-sm text-center text-[#001C44] dark:text-white mb-4">
            Something went wrong
          </p>
        </div>
      )}
      <div className="w-full tooltip-container">
        <Tooltip
          content={
            error && error !== ''
              ? error
              : `${
                  noAccessSubtitle
                    ? noAccessSubtitle
                    : `Enhance your market presence by staying informed on the latest
                visibility strategies and insights. Our dedicated section offers
                crucial updates and expert advice designed to help you elevate
                your brand's visibility and recognition.`
                }`
          }
          position="top"
        >
          <div
            className={`flex justify-center w-full ${
              error
                ? 'text-white bg-[#CC002F] p-2 rounded-md text-xs text-center font-inter font-[600] '
                : 'text-center text-xs text-[#001C44] dark:text-white'
            }`}
          >
            <p
              className={`${error ? 'text-ellipsis line-clamp-3 tooltip-text word-wrap ' : ''}  w-fit`}
            >
              {error && error !== ''
                ? error
                : `${
                    noAccessSubtitle
                      ? noAccessSubtitle
                      : `Enhance your market presence by staying informed on the latest
                visibility strategies and insights. Our dedicated section offers
                crucial updates and expert advice designed to help you elevate
                your brand's visibility and recognition.`
                  }`}
            </p>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default NoDataError;
