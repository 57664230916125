import Icon from '../../assets/icons/SvgComponent';
import { Badge } from '@tremor/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useWeeklyReportStore from '../../store/useWeeklyStore';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperButtonNext from '../SwiperButton/swiper';
import { Pagination } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useThemeStore } from '../../store/useThemeStore';

const WeeklyStatusCount = () => {
  const { mode } = useThemeStore((state) => state);
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const urlStatus = searchParams.get('status');
  const { reportStatus, summaryLoading, summary } = useWeeklyReportStore(
    (state) => state
  );
  const defalutStatus = urlStatus || reportStatus;
  const [activeWeeklyStatus, setActiveWeeklyStatus] = useState(
    defalutStatus || 'all'
  );
  // weekly report status
  const statusOptions = [
    { id: 'all', label: 'All Status', bgColor: 'slate', count: summary.all },
    { id: 'ready', label: 'Ready', bgColor: 'emerald', count: summary.ready },
    {
      id: 'flagged',
      label: 'Flagged',
      bgColor: 'orange',
      count: summary.flagged,
    },
    { id: 'sent', label: 'Sent', bgColor: 'blue', count: summary.sent },
    {
      id: 'canceled',
      label: 'Canceled',
      bgColor: 'rose',
      count: summary.canceled,
    },
  ];
  const handleReportTypes = (status: string) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('status', status);
    setActiveWeeklyStatus(status);
    const updatedSearch = searchParams?.toString();
    location.search = updatedSearch;
    navigate(location);
  };

  return (
    <>
      <div className="hidden sm:flex cursor-pointer gap-2  items-center overflow-x-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8">
        {statusOptions?.map((report: any, index: number) => (
          <div
            key={index}
            className={`flex flex-col gap-1 p-1 items-center w-20 font-inter font-[600] rounded-md ${`${
              activeWeeklyStatus === report.id
                ? `bg-${report.bgColor}-100 text-black dark:bg-gray-800 dark:text-white shadow`
                : 'bg-white text-[#7C7E7F] dark:bg-transparent dark:text-gray-500 '
            } `}`}
            onClick={() => handleReportTypes(report.id)}
          >
            <div className="dark:text-white text-xs ">{report.label}</div>
            {summaryLoading ? (
              <div className="w-5 h-[25px]">
                <Icon name="loading" size={20} />
              </div>
            ) : (
              <Badge
                className={` cursor-pointer h-[24px]`}
                color={report.bgColor}
              >
                {report?.count}
              </Badge>
            )}
          </div>
        ))}
      </div>
      <div className="relative block w-full sm:hidden">
        <Swiper
          className="h-full flex justify-between mySwiper"
          modules={[Pagination]}
          spaceBetween={2}
          slidesPerView={3}
        >
          <div className="bg-white">
            <SwiperButtonNext next={true}>
              <Icon
                name="ChevronRight"
                size={30}
                color={mode === 'dark' ? '#FFF' : '#000'}
              />
            </SwiperButtonNext>
          </div>
          {statusOptions?.map((report: any, index: number) => (
            <SwiperSlide
              key={index}
              className={`font-inter font-[600] rounded-md ${
                activeWeeklyStatus === report.id
                  ? `bg-${report.bgColor}-100 text-black dark:bg-gray-800 dark:text-white shadow`
                  : 'bg-white text-[#7C7E7F] dark:bg-transparent dark:text-gray-500 '
              }`}
              onClick={() => handleReportTypes(report.id)}
            >
              <div className="w-full text-center">{report.label}</div>
            </SwiperSlide>
          ))}
          <div className="bg-white">
            <SwiperButtonNext prev={true}>
              <Icon
                name="ChevronLeft"
                size={30}
                color={mode === 'dark' ? '#FFF' : '#000'}
              />
            </SwiperButtonNext>
          </div>
        </Swiper>
      </div>
    </>
  );
};

export default WeeklyStatusCount;
