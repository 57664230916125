import { Card } from '@tremor/react';
import Icon from '../../assets/icons/SvgComponent';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperButtonNext from './swiper';
import BookMeetingIcon from '../../assets/images/bookMeeting.png';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Pagination } from 'swiper/modules';
import Tooltip from '../HelpTooltip';
import moment from 'moment';

interface IProps {
  upcomingMeeting: any;
  upcomingMeetingLoading: boolean;
  setOpenModal: (arg: boolean) => void;
}

const UpcomingMeetingsCard = ({
  upcomingMeetingLoading,
  upcomingMeeting,
  setOpenModal,
}: IProps) => {
  return (
    <div className="h-fit lg:min-h-[273px] md:w-full">
      {upcomingMeetingLoading ? (
        <Card className="flex flex-col gap-y-4 w-full bg-[#009EFF] dark:bg-[#222732] relative pr-10 h-fit sm:min-h-[273px] md:w-full ">
          <div role="status" className="max-w-sm animate-pulse">
            <div className="h-2.5 bg-white rounded-full dark:bg-gray-700 w-48 mb-4" />
            <div className="h-2 bg-white rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5" />
            <div className="h-2 bg-white rounded-full dark:bg-gray-700 mb-2.5" />
            <div className="h-2 bg-white rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5" />
            <div className="h-2 bg-white rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5" />
            <div className="h-2 bg-white rounded-full dark:bg-gray-700 max-w-[360px]" />
            <div className="h-2 bg-white rounded-full dark:bg-gray-700 my-2.5" />
            <div className="h-2 bg-white rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5" />
            <div className="h-2 bg-white rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5" />
            <div className="h-2 bg-white rounded-full dark:bg-gray-700 max-w-[360px]" />
            <span className="sr-only">Loading...</span>
          </div>
        </Card>
      ) : (
        <>
          {!upcomingMeetingLoading &&
            upcomingMeeting?.data?.length > 0 &&
            !upcomingMeeting.loading && (
              <Swiper
                modules={[Pagination]}
                pagination={{ clickable: true }}
                spaceBetween={20}
                slidesPerView={1}
                className="w-full h-[225px] lg:h-[273px] rounded-lg mySwiper"
              >
                <div className="hidden sm:block">
                  <SwiperButtonNext next={true}>
                    <Icon name="SwiperNext" size={70} />
                  </SwiperButtonNext>
                </div>
                {upcomingMeeting?.data?.map((meeting, index) => {
                  const meetingStartTime = moment(
                    meeting.properties.hs_meeting_start_time
                  );
                  const currentTime = moment();
                  const duration = moment.duration(
                    meetingStartTime.diff(currentTime)
                  );

                  let formattedDuration;
                  if (duration.asHours() <= 24) {
                    formattedDuration = duration.humanize();
                  } else {
                    formattedDuration = Math.round(duration.asDays()) + ' days';
                  }

                  return (
                    <SwiperSlide key={index}>
                      <Card className="flex flex-col gap-y-4 w-full bg-[#009EFF] dark:bg-[#222732] h-[225px] sm:min-h-[273px] p-3  lg:p-6">
                        <div className="flex justify-between w-full sm:px-10">
                          <div className="flex flex-col gap-y-2 text-gray-100 text-sm font-light">
                            <span className=" text-white font-bold font-inter text-xs md:text-2xl">
                              Upcoming Meeting
                            </span>
                            <Tooltip
                              content={meeting.properties?.hs_meeting_title}
                              position="right"
                              fit={true}
                            >
                              <div className="text-sm lg:text-3xl font-semibold text-white  mt-2 text-ellipsis overflow-hidden whitespace-nowrap w-fit max-w-[180px]  sm:max-w-[300px] ">
                                {meeting.properties?.hs_meeting_title}
                              </div>
                            </Tooltip>
                            <div className="flex items-center gap-x-2 mt-1">
                              <Icon name="time" />
                              <span>
                                {moment(
                                  meeting?.properties?.hs_meeting_start_time
                                ).format('dddd DD MMMM')}
                              </span>
                            </div>
                            <div className="flex items-center gap-x-2">
                              <Icon name="date" />
                              <span>
                                {moment(
                                  meeting.properties?.hs_meeting_start_time
                                ).format('h:mmA')}
                                -{' '}
                                {moment(
                                  meeting.properties?.hs_meeting_end_time
                                ).format('h:mmA')}
                              </span>
                            </div>
                            <div className="flex gap-x-2">
                              <Icon name="location" />
                              <div className="w-[140px] lg:w-[300px] truncate overflow-hidden">
                                Microsoft teams meeting <br />
                                Meeting ID:{' '}
                                {meeting.properties?.hs_object_id
                                  ? meeting.properties?.hs_object_id
                                  : 'N/A'}
                                <br />
                                Meeting Url:{' '}
                                <a
                                  href={
                                    meeting.properties?.hs_meeting_external_url
                                  }
                                >
                                  {meeting.properties?.hs_meeting_external_url
                                    ? meeting.properties
                                        ?.hs_meeting_external_url
                                    : 'N/A'}
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col items-center gap-12 sm:gap-12 mt-10 sm:mt-14">
                            <div className="text-center p-2 rounded-md text-white shadow-lg lg:w-38 font-medium  bg-[#0029FF]">
                              <div className="text-sm lg:text-3xl ">
                                {formattedDuration}
                              </div>
                              <span className="text-[8px] lg:text-xs">
                                UNTIL MEETING
                              </span>
                            </div>
                            <button
                              className="text-[#001C44] rounded-full text-xs lg:text-sm font-semibold p-2 px-2 bg-amber-300 hover:bg-amber-400 md:px-2 lg:px-4"
                              onClick={() => setOpenModal(true)}
                            >
                              Book another meeting
                            </button>
                          </div>
                        </div>
                      </Card>
                    </SwiperSlide>
                  );
                })}
                <div className="hidden sm:block">
                  <SwiperButtonNext prev={true}>
                    <Icon name="SwiperPrev" size={70} />
                  </SwiperButtonNext>
                </div>
              </Swiper>
            )}{' '}
          {!upcomingMeetingLoading &&
            upcomingMeeting?.data?.length === 0 &&
            !upcomingMeeting.loading && (
              <Card className="relative overflow-hidden flex flex-col lg:gap-4 w-full justify-center items-center bg-[#009EFF] dark:bg-[#222732] relative h-fit lg:min-h-[273px] md:w-full">
                <div className="absolute top-[-35px] lg:top-[-60px]">
                  <img src={BookMeetingIcon} alt="" width={380} />
                </div>
                <div className=" text-white font-bold font-archivo text-sm sm:text-xl mt-24">
                  No Upcoming Meetings Yet!
                </div>
                <div className=" text-white font-[500] text-xs sm:text-base text-center">
                  Your schedule meetings will appear here once set. <br />
                  Ready to organize your first one?
                </div>
                <div className="flex flex-col z-[20]">
                  <button
                    className="text-[#001C44] rounded-full text-xs sm:text-sm font-semibold p-2 bg-amber-300 hover:bg-amber-400 px-4 mt-4 lg:mt-0"
                    onClick={() => setOpenModal(true)}
                  >
                    Book a meeting
                  </button>
                </div>
              </Card>
            )}
        </>
      )}
    </div>
  );
};

export default UpcomingMeetingsCard;
