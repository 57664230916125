import React, { useEffect, useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import Icon from '../../../assets/icons/SvgComponent';
import { useThemeStore } from '../../../store/useThemeStore';
import { PageName, getPageTabs, getPageTitle } from '../../../utils/layout';
import OutsideAlerter from '../../Common/OutsideAlerter';
import useReportStore from '../../../store/useReportStore';
import useVisibilityStore from '../../../store/useVisibilityStore';
import StatusCounter from '../../Report/StatusCounter';
import WeeklyStatusCounter from '../../Report/WeeklyStatusCounter';
import PerformanceCounter from '../../Report/PerformanceCounter';
import AnalyticsTab from '../../AnalyticsSections/AnalyticsTabs';
import useUtilityStore from '../../../store/useUtilityStore';
import useAuthStore from '../../../store/useAuthStore';
import api from '../../../api';


interface Tab {
  label: string;
  path: string;
}


interface UtilityStoreState {
  isHeaderHidden: boolean;
}



const PublicHeader: React.FC = () => {

  const { isHeaderHidden } = useUtilityStore() as UtilityStoreState;
  const location = useLocation();
  
  // const { loading } = useReportStore((state) => state);
  // const { openVisibility, setOpenVisibility } = useVisibilityStore();
  // let activePage = getPageTitle(location.pathname);

  // const servicesParams = new URLSearchParams(location.search);
  // const service = servicesParams.get('service');
  // const tabs: Tab[] = getPageTabs(activePage as PageName, service as string);
  // const active =
  //   'bg-[#F3F7FA] rounded-lg hover:bg-[#F3F7FA] dark:bg-[#F3F7FA] dark:text-black dark:hover:bg-white  dark:border dark:border-[#313233]';

  // const isMonthly = activePage === 'client reporting';
  // const isWeekly = activePage === 'weekly reporting';
  // const isPerformance = activePage === 'performance';
  // const isClientDetails = location.pathname.includes('/client/details/');
  // const isInternalAnalytics = activePage === 'client analytics';
  // const isAnalytics = activePage === 'analytics';


  return (
    <>
      <div
        className={`flex items-center justify-between w-full border-b h-12 sm:h-16 sm:px-6 sticky top-0 bg-[#E3F4FF] z-[96] dark:bg-[#0C111D] shadow-sm transition-opacity duration-500 ease-in-out ${isHeaderHidden ? 'opacity-0 sm:opacity-100' : 'opacity-100'}`}
      >
        <div className="flex items-center w-full">
          <div className="flex justify-between items-center w-full">
            <div className="flex items-center h-16">
              <span className="ml-5  lg:mx-5  lg:text-[18px] hidden md:block font-bold  font-inter text-[#001C44] dark:text-white">
                Analytics:
              </span>
            </div>
            <div className="w-full flex sm:ml-2">
              <AnalyticsTab isInternalAnalytics={false} isPublic={true} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PublicHeader;
