import React, { useState, useEffect, Fragment } from 'react';

// SEO REDUCE BILLING EXPORT
export const SEOReduceBilling = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.seo_google.end_date !== '') {
    var timeleft = Date.parse(client.seo_google.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.seo_google.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [reduceBillingValues, setValues] = useState({
    type: 'reduce billing',
    service: 'seo_google',
    reduceBillingNewBudget: '',
    reduceBillingStartDate: '',
    reduceBillingReason: '',
    reduceBillingBudget: client.seo_google.revenue,
    reduceTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    reduceBillingNewBudget,
    reduceBillingStartDate,
    reduceBillingReason,
    reduceBillingBudget,
  } = reduceBillingValues;

  var contractFinish = new Date(parseInt(client.seo_google.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...reduceBillingValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(reduceBillingValues);
  }, [reduceBillingValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        SEO GOOGLE Reduce Billing Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the new budget?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('reduceBillingNewBudget')}
              value={reduceBillingNewBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Effective date for reduced budget?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('reduceBillingStartDate')}
              value={reduceBillingStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the reduction(Be Detailed)?
          </p>
          <div>
          <textarea
              onInput={handleChange('reduceBillingReason')}
              value={reduceBillingReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={reduceBillingBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// PPC REDUCE BILLING EXPORT
export const PPCReduceBilling = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.ppc_google.end_date !== '') {
    var timeleft = Date.parse(client.ppc_google.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.ppc_google.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [reduceBillingValues, setValues] = useState({
    type: 'reduce billing',
    service: 'ppc_google',
    reduceBillingNewBudget: '',
    reduceBillingStartDate: '',
    reduceBillingReason: '',
    reduceBillingBudget: client.ppc_google.revenue,
    reduceTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    reduceBillingNewBudget,
    reduceBillingStartDate,
    reduceBillingReason,
    reduceBillingBudget,
  } = reduceBillingValues;

  var contractFinish = new Date(parseInt(client.ppc_google.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...reduceBillingValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(reduceBillingValues);
  }, [reduceBillingValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        PPC GOOGLE Reduce Billing Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the new budget?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('reduceBillingNewBudget')}
              value={reduceBillingNewBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Effective date for reduced budget?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('reduceBillingStartDate')}
              value={reduceBillingStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the reduction(Be Detailed)?
          </p>
          <div>
          <textarea
              onInput={handleChange('reduceBillingReason')}
              value={reduceBillingReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={reduceBillingBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// SEO REDUCE BILLING EXPORT
export const SEOBINGReduceBilling = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.seo_bing.end_date !== '') {
    var timeleft = Date.parse(client.seo_bing.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.seo_bing.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [reduceBillingValues, setValues] = useState({
    type: 'reduce billing',
    service: 'seo_bing',
    reduceBillingNewBudget: '',
    reduceBillingStartDate: '',
    reduceBillingReason: '',
    reduceBillingBudget: client.seo_bing.revenue,
    reduceTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    reduceBillingNewBudget,
    reduceBillingStartDate,
    reduceBillingReason,
    reduceBillingBudget,
  } = reduceBillingValues;

  var contractFinish = new Date(parseInt(client.seo_bing.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...reduceBillingValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(reduceBillingValues);
  }, [reduceBillingValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Microsoft SEO Reduce Billing Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the new budget?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('reduceBillingNewBudget')}
              value={reduceBillingNewBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Effective date for reduced budget?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('reduceBillingStartDate')}
              value={reduceBillingStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the reduction(Be Detailed)?
          </p>
          <div>
          <textarea
              onInput={handleChange('reduceBillingReason')}
              value={reduceBillingReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={reduceBillingBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// PPC REDUCE BILLING EXPORT
export const PPCBINGReduceBilling = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_bing.end_date !== '') {
    var timeleft = Date.parse(client.social_bing.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_bing.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [reduceBillingValues, setValues] = useState({
    type: 'reduce billing',
    service: 'social_bing',
    reduceBillingNewBudget: '',
    reduceBillingStartDate: '',
    reduceBillingReason: '',
    reduceBillingBudget: client.social_bing.revenue,
    reduceTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    reduceBillingNewBudget,
    reduceBillingStartDate,
    reduceBillingReason,
    reduceBillingBudget,
  } = reduceBillingValues;

  var contractFinish = new Date(parseInt(client.social_bing.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...reduceBillingValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(reduceBillingValues);
  }, [reduceBillingValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Microsoft Ads Reduce Billing Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the new budget?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('reduceBillingNewBudget')}
              value={reduceBillingNewBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Effective date for reduced budget?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('reduceBillingStartDate')}
              value={reduceBillingStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the reduction(Be Detailed)?
          </p>
          <div>
          <textarea
              onInput={handleChange('reduceBillingReason')}
              value={reduceBillingReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={reduceBillingBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// REM REDUCE BILLING EXPORT
export const REMReduceBilling = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.display.end_date !== '') {
    var timeleft = Date.parse(client.display.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.display.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [reduceBillingValues, setValues] = useState({
    type: 'reduce billing',
    service: 'display',
    reduceBillingNewBudget: '',
    reduceBillingStartDate: '',
    reduceBillingReason: '',
    reduceBillingBudget: client.display.revenue,
    reduceTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    reduceBillingNewBudget,
    reduceBillingStartDate,
    reduceBillingReason,
    reduceBillingBudget,
  } = reduceBillingValues;

  var contractFinish = new Date(parseInt(client.display.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...reduceBillingValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(reduceBillingValues);
  }, [reduceBillingValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Remarketing Reduce Billing Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the new budget?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('reduceBillingNewBudget')}
              value={reduceBillingNewBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Effective date for reduced budget?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('reduceBillingStartDate')}
              value={reduceBillingStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the reduction(Be Detailed)?
          </p>
          <div>
          <textarea
              onInput={handleChange('reduceBillingReason')}
              value={reduceBillingReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={reduceBillingBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// META REDUCE BILLING EXPORT
export const METAReduceBilling = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_meta.end_date !== '') {
    var timeleft = Date.parse(client.social_meta.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_meta.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [reduceBillingValues, setValues] = useState({
    type: 'reduce billing',
    service: 'social_meta',
    reduceBillingNewBudget: '',
    reduceBillingStartDate: '',
    reduceBillingReason: '',
    reduceBillingBudget: client.social_meta.revenue,
    reduceTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    reduceBillingNewBudget,
    reduceBillingStartDate,
    reduceBillingReason,
    reduceBillingBudget,
  } = reduceBillingValues;

  var contractFinish = new Date(parseInt(client.social_meta.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...reduceBillingValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(reduceBillingValues);
  }, [reduceBillingValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        META Reduce Billing Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the new budget?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('reduceBillingNewBudget')}
              value={reduceBillingNewBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Effective date for reduced budget?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('reduceBillingStartDate')}
              value={reduceBillingStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the reduction(Be Detailed)?
          </p>
          <div>
          <textarea
              onInput={handleChange('reduceBillingReason')}
              value={reduceBillingReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={reduceBillingBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// PINTEREST REDUCE BILLING EXPORT
export const PINTERESTReduceBilling = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_pinterest.end_date !== '') {
    var timeleft = Date.parse(client.social_pinterest.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_pinterest.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [reduceBillingValues, setValues] = useState({
    type: 'reduce billing',
    service: 'social_pinterest',
    reduceBillingNewBudget: '',
    reduceBillingStartDate: '',
    reduceBillingReason: '',
    reduceBillingBudget: client.social_pinterest.revenue,
    reduceTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    reduceBillingNewBudget,
    reduceBillingStartDate,
    reduceBillingReason,
    reduceBillingBudget,
  } = reduceBillingValues;

  var contractFinish = new Date(parseInt(client.social_pinterest.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...reduceBillingValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(reduceBillingValues);
  }, [reduceBillingValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        PINTEREST Reduce Billing Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the new budget?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('reduceBillingNewBudget')}
              value={reduceBillingNewBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Effective date for reduced budget?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('reduceBillingStartDate')}
              value={reduceBillingStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the reduction(Be Detailed)?
          </p>
          <div>
          <textarea
              onInput={handleChange('reduceBillingReason')}
              value={reduceBillingReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={reduceBillingBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// TIKTOK REDUCE BILLING EXPORT
export const TIKTOKReduceBilling = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_tiktok.end_date !== '') {
    var timeleft = Date.parse(client.social_tiktok.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_tiktok.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [reduceBillingValues, setValues] = useState({
    type: 'reduce billing',
    service: 'social_tiktok',
    reduceBillingNewBudget: '',
    reduceBillingStartDate: '',
    reduceBillingReason: '',
    reduceBillingBudget: client.social_tiktok.revenue,
    reduceTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    reduceBillingNewBudget,
    reduceBillingStartDate,
    reduceBillingReason,
    reduceBillingBudget,
  } = reduceBillingValues;

  var contractFinish = new Date(parseInt(client.social_tiktok.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...reduceBillingValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(reduceBillingValues);
  }, [reduceBillingValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        TIKTOK Reduce Billing Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the new budget?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('reduceBillingNewBudget')}
              value={reduceBillingNewBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Effective date for reduced budget?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('reduceBillingStartDate')}
              value={reduceBillingStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the reduction(Be Detailed)?
          </p>
          <div>
          <textarea
              onInput={handleChange('reduceBillingReason')}
              value={reduceBillingReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={reduceBillingBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// LINKEDIN REDUCE BILLING EXPORT
export const LINKEDINReduceBilling = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_linkedin.end_date !== '') {
    var timeleft = Date.parse(client.social_linkedin.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_linkedin.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [reduceBillingValues, setValues] = useState({
    type: 'reduce billing',
    service: 'social_linkedin',
    reduceBillingNewBudget: '',
    reduceBillingStartDate: '',
    reduceBillingReason: '',
    reduceBillingBudget: client.social_linkedin.revenue,
    reduceTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    reduceBillingNewBudget,
    reduceBillingStartDate,
    reduceBillingReason,
    reduceBillingBudget,
  } = reduceBillingValues;

  var contractFinish = new Date(parseInt(client.social_linkedin.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...reduceBillingValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(reduceBillingValues);
  }, [reduceBillingValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        LINKEDIN Reduce Billing Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the new budget?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('reduceBillingNewBudget')}
              value={reduceBillingNewBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Effective date for reduced budget?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('reduceBillingStartDate')}
              value={reduceBillingStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the reduction(Be Detailed)?
          </p>
          <div>
          <textarea
              onInput={handleChange('reduceBillingReason')}
              value={reduceBillingReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={reduceBillingBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// TACTICAL REDUCE BILLING EXPORT
export const TACTICALReduceBilling = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_tactical.end_date !== '') {
    var timeleft = Date.parse(client.social_tactical.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_tactical.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [reduceBillingValues, setValues] = useState({
    type: 'reduce billing',
    service: 'social_tactical',
    reduceBillingNewBudget: '',
    reduceBillingStartDate: '',
    reduceBillingReason: '',
    reduceBillingBudget: client.social_tactical.revenue,
    reduceTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    reduceBillingNewBudget,
    reduceBillingStartDate,
    reduceBillingReason,
    reduceBillingBudget,
  } = reduceBillingValues;

  var contractFinish = new Date(parseInt(client.social_tactical.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...reduceBillingValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(reduceBillingValues);
  }, [reduceBillingValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        TACTICAL Reduce Billing Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the new budget?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('reduceBillingNewBudget')}
              value={reduceBillingNewBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Effective date for reduced budget?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('reduceBillingStartDate')}
              value={reduceBillingStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the reduction(Be Detailed)?
          </p>
          <div>
          <textarea
              onInput={handleChange('reduceBillingReason')}
              value={reduceBillingReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={reduceBillingBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// PROGRAMMATIC REDUCE BILLING EXPORT
export const PROGRAMMATICReduceBilling = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_programmatic.end_date !== '') {
    var timeleft = Date.parse(client.social_programmatic.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_programmatic.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [reduceBillingValues, setValues] = useState({
    type: 'reduce billing',
    service: 'social_programmatic',
    reduceBillingNewBudget: '',
    reduceBillingStartDate: '',
    reduceBillingReason: '',
    reduceBillingBudget: client.social_programmatic.revenue,
    reduceTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    reduceBillingNewBudget,
    reduceBillingStartDate,
    reduceBillingReason,
    reduceBillingBudget,
  } = reduceBillingValues;

  var contractFinish = new Date(parseInt(client.social_programmatic.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...reduceBillingValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(reduceBillingValues);
  }, [reduceBillingValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        PROGRAMMATIC Reduce Billing Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the new budget?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('reduceBillingNewBudget')}
              value={reduceBillingNewBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Effective date for reduced budget?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('reduceBillingStartDate')}
              value={reduceBillingStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the reduction(Be Detailed)?
          </p>
          <div>
          <textarea
              onInput={handleChange('reduceBillingReason')}
              value={reduceBillingReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={reduceBillingBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// CREATIVE REDUCE BILLING EXPORT
export const CREATIVEReduceBilling = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_creative.end_date !== '') {
    var timeleft = Date.parse(client.social_creative.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_creative.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [reduceBillingValues, setValues] = useState({
    type: 'reduce billing',
    service: 'social_creative',
    reduceBillingNewBudget: '',
    reduceBillingStartDate: '',
    reduceBillingReason: '',
    reduceBillingBudget: client.social_creative.revenue,
    reduceTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    reduceBillingNewBudget,
    reduceBillingStartDate,
    reduceBillingReason,
    reduceBillingBudget,
  } = reduceBillingValues;

  var contractFinish = new Date(parseInt(client.social_creative.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...reduceBillingValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(reduceBillingValues);
  }, [reduceBillingValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        CREATIVE Reduce Billing Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the new budget?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('reduceBillingNewBudget')}
              value={reduceBillingNewBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Effective date for reduced budget?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('reduceBillingStartDate')}
              value={reduceBillingStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the reduction(Be Detailed)?
          </p>
          <div>
          <textarea
              onInput={handleChange('reduceBillingReason')}
              value={reduceBillingReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={reduceBillingBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// WEBDEV REDUCE BILLING EXPORT
export const WEBDEVReduceBilling = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.web_maintenance.end_date !== '') {
    var timeleft = Date.parse(client.web_maintenance.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.web_maintenance.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [reduceBillingValues, setValues] = useState({
    type: 'reduce billing',
    service: 'web_maintenance',
    reduceBillingNewBudget: '',
    reduceBillingStartDate: '',
    reduceBillingReason: '',
    reduceBillingBudget: client.web_maintenance.revenue,
    reduceTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    reduceBillingNewBudget,
    reduceBillingStartDate,
    reduceBillingReason,
    reduceBillingBudget,
  } = reduceBillingValues;

  var contractFinish = new Date(parseInt(client.web_maintenance.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...reduceBillingValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(reduceBillingValues);
  }, [reduceBillingValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Web Maintenance Reduce Billing Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the new budget?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('reduceBillingNewBudget')}
              value={reduceBillingNewBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Effective date for reduced budget?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('reduceBillingStartDate')}
              value={reduceBillingStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the reduction(Be Detailed)?
          </p>
          <div>
          <textarea
              onInput={handleChange('reduceBillingReason')}
              value={reduceBillingReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={reduceBillingBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// WEBHOST REDUCE BILLING EXPORT
export const WEBHOSTReduceBilling = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.web_hosting.end_date !== '') {
    var timeleft = Date.parse(client.web_hosting.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.web_hosting.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [reduceBillingValues, setValues] = useState({
    type: 'reduce billing',
    service: 'web_hosting',
    reduceBillingNewBudget: '',
    reduceBillingStartDate: '',
    reduceBillingReason: '',
    reduceBillingBudget: client.web_hosting.revenue,
    reduceTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    reduceBillingNewBudget,
    reduceBillingStartDate,
    reduceBillingReason,
    reduceBillingBudget,
  } = reduceBillingValues;

  var contractFinish = new Date(parseInt(client.web_hosting.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...reduceBillingValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(reduceBillingValues);
  }, [reduceBillingValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Web Hosting Reduce Billing Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the new budget?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('reduceBillingNewBudget')}
              value={reduceBillingNewBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Effective date for reduced budget?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('reduceBillingStartDate')}
              value={reduceBillingStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the reduction(Be Detailed)?
          </p>
          <div>
          <textarea
              onInput={handleChange('reduceBillingReason')}
              value={reduceBillingReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={reduceBillingBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// WEBHOST REDUCE BILLING EXPORT
export const WEBLANDINGReduceBilling = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.web_landing.end_date !== '') {
    var timeleft = Date.parse(client.web_landing.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.web_landing.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [reduceBillingValues, setValues] = useState({
    type: 'reduce billing',
    service: 'web_landing',
    reduceBillingNewBudget: '',
    reduceBillingStartDate: '',
    reduceBillingReason: '',
    reduceBillingBudget: client.web_landing.revenue,
    reduceTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    reduceBillingNewBudget,
    reduceBillingStartDate,
    reduceBillingReason,
    reduceBillingBudget,
  } = reduceBillingValues;

  var contractFinish = new Date(parseInt(client.web_landing.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...reduceBillingValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(reduceBillingValues);
  }, [reduceBillingValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Web Landing Reduce Billing Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the new budget?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('reduceBillingNewBudget')}
              value={reduceBillingNewBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Effective date for reduced budget?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('reduceBillingStartDate')}
              value={reduceBillingStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the reduction(Be Detailed)?
          </p>
          <div>
          <textarea
              onInput={handleChange('reduceBillingReason')}
              value={reduceBillingReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={reduceBillingBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// WEBHOST REDUCE BILLING EXPORT
export const WEBPROJECTReduceBilling = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.web_project.end_date !== '') {
    var timeleft = Date.parse(client.web_project.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.web_project.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [reduceBillingValues, setValues] = useState({
    type: 'reduce billing',
    service: 'web_project',
    reduceBillingNewBudget: '',
    reduceBillingStartDate: '',
    reduceBillingReason: '',
    reduceBillingBudget: client.web_project.revenue,
    reduceTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    reduceBillingNewBudget,
    reduceBillingStartDate,
    reduceBillingReason,
    reduceBillingBudget,
  } = reduceBillingValues;

  var contractFinish = new Date(parseInt(client.web_project.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...reduceBillingValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(reduceBillingValues);
  }, [reduceBillingValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Web Project Reduce Billing Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the new budget?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('reduceBillingNewBudget')}
              value={reduceBillingNewBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Effective date for reduced budget?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('reduceBillingStartDate')}
              value={reduceBillingStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the reduction(Be Detailed)?
          </p>
          <div>
          <textarea
              onInput={handleChange('reduceBillingReason')}
              value={reduceBillingReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={reduceBillingBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
