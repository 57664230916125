import React, { CSSProperties } from 'react';
import Select, { Props as SelectProps, StylesConfig } from 'react-select';
export interface OptionProps {
  [key: string]: any;
}

export interface SearchDropdownProps {
  options: OptionProps[];
  labelKey: string;
  valueKey: string;
  defaultValue?: string;
  placeholder?: string;
  customStyle?: CSSProperties;
  onSelect: (selectedOption: OptionProps) => void;
  disableSearch?: boolean;
  showLabelValue?: boolean;
}

const SearchDropdown: React.FC<SearchDropdownProps> = ({
  options = [],
  labelKey = 'label',
  valueKey = 'value',
  disableSearch = false,
  defaultValue = '',
  placeholder = '',
  showLabelValue = false,
  customStyle = {},
  onSelect,
}) => {
  const selectOptions = options.map((option) => ({
    ...option,
    label: showLabelValue
      ? `${option?.[labelKey]} - ${option?.[valueKey]} ${option.isDisabled ? '(Unverified)' : ''}`
      : `${option?.[labelKey]} ${option.isDisabled ? '(Unverified)' : ''}`,
    value: option?.[valueKey],
  }));

  const defaultOption =
    typeof defaultValue === 'string' && defaultValue?.length
      ? { label: defaultValue }
      : null;

  const handleChange = (selectedOption: any) => {
    onSelect(selectedOption);
  };

  const customStyles: StylesConfig<any, boolean, any> = {
    control: (provided) => ({
      ...provided,
      ...customStyle,
      ...{
        borderRadius: '8px',
        border: '1px solid #808EA2',
        cursor: 'pointer',
        fontSize: '14px',
        scrollbarWidth: 'thin',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? 'rgba(255, 0, 0, 0.5)' : '',
      fontSize: '14px',
      cursor: 'pointer',
    }),
    menuPortal: (provided) => ({ ...provided, zIndex: 1000 }),
  };

  return (
    <Select
      className="my-react-select-container  dark:text-white"
      classNamePrefix="my-react-select"
      options={selectOptions}
      value={defaultOption}
      placeholder={placeholder}
      isSearchable={!disableSearch}
      isDisabled={disableSearch}
      menuPortalTarget={document.body}
      onChange={handleChange}
      styles={customStyles}
    />
  );
};

export default SearchDropdown;
