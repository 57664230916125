import React from 'react';
import MetricsChart from '../Modals/Performance/metricsChart';
import MetricView from './MetricView';
import Tooltip from '../HelpTooltip';
import Icon from '../../assets/icons/SvgComponent';

const MetricDisplay = ({
  getValue,
  category,
  deltaType,
  color,
  legendName,
}) => {
  const value = getValue();

  return (
    <div className="flex flex-col justify-center items-center gap-1 text-xs font-inter font-[600]">
      {!value?.message ? (
        <>
          <MetricsChart
            tooltip={value?.dailyMetrics}
            category={category}
            legendName={legendName}
            clientName={value?.clientName}
            position="right"
          >
            <div className="cursor-pointer flex gap-1 justify-center items-center h-[19px] text-sm p-[6px] border-0 bg-gray-100 rounded">
              <Icon name={deltaType} size={14} color={color} />
              <div style={{ color }}>{value?.current}</div>
            </div>
          </MetricsChart>
          <MetricView color={color} previous={value?.previous} />
        </>
      ) : (
        <Tooltip content={value?.current} position="right">
          <div className="p-2 cursor-pointer flex gap-1 items-center h-[19px] text-sm max-w-[160px] sm:max-w-[12vw] border-0 bg-gray-100 rounded truncate">
            <div style={{ color }}>Error</div>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default MetricDisplay;
