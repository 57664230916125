import React, { useState, useEffect, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import usePositionStore from '../../store/usePositionStore';
import ReactSelect from '../../components/ReactSelect';
import Tooltip from '../../components/HelpTooltip';
import SearchBar from '../../components/Search';

const Positions = () => {
  const { fetchPositions, loading } = usePositionStore((state) => state);
  const [error, setError] = useState('');
  const [payload, setPayload] = useState({
    role: '',
    data: [],
    loading: true,
    keyword: '',
    excluded:'',
    domain: '',
    database: '',
    databaseLabel: '',
    exclusions:''
  });

  const normalizeURL = (url) => {
    try {
      if (!/^https?:\/\//i.test(url)) {
        url = `https://${url}`;
      }
  
      const parsedUrl = new URL(url);
      const baseDomain = parsedUrl.hostname.replace(/^www\./, '');
  
      if (!/^[a-z0-9.-]+\.[a-z]{2,}$/i.test(baseDomain)) {
        return false
      }
  
      return baseDomain;
    } catch (error) {
      return false
    }
  };

  const { data, domain, database, databaseLabel, excluded } = payload;

  const dbOptions = [
    { label: 'United States', value: 'us' },
    { label: 'United Kingdom', value: 'uk' },
    { label: 'United Arab Emirates', value: 'uae' },
    { label: 'Australia', value: 'au' },
    { label: 'Canada', value: 'ca' },
    { label: 'India', value: 'in' },
  ];

  const handleFetchingPositions = () => {
    if(domain == ''){
      setError('Domain cannot be empty');
      return;
    }
    if(database == ''){
      setError('Please select a region to analyze');
      return;
    }
    if(normalizeURL(domain) === false){
      setError('The domain entered doesn\'t appear to be valid. Please check your entries');
      return;
    }
    setError('');
    setPayload(prevPayload => ({
      ...prevPayload,
      loading: true,
    }));
    fetchPositions(payload)
    .then(data => {
      console.log('RETURNED: ', data)
      if(data.success === false){
        setError(data.message);
        setPayload(prevPayload => ({
          ...prevPayload,
          loading: false,
        }));
      }else{
        setPayload(prevPayload => ({
          ...prevPayload,
          data: data.data,
          loading: false,
        }));
      }
    });
  }

  const onInput = (e) => {
    if(e.target.name === 'excluded'){
      setPayload(prevPayload => ({
        ...prevPayload,
        excluded: e.target.value,
      }));
    }else{
      setPayload(prevPayload => ({
        ...prevPayload,
        domain: e.target.value,
      }));
    }
    
  }

  const diffColor = (diff) => {
    diff = parseInt(diff)
    var color = 'red'

    if(diff <= 40){
      color = '#00d800';
    }
    if(diff >= 41 && diff <= 75){
      color = '#ff870a';
    }
    if(diff >=76 ){
      color = '#ff0a0a';
    }

    return <span style={{color:color}}>{diff}</span>;
  }

  const opCalculator = (vol, pos, calc) => {
    const addPercentage = (value, percentage) => {
      const result = value * (1 + percentage / 100);
      return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(result);
    }
    vol = parseInt(vol)
    pos = parseInt(pos)
    calc = parseInt(calc)
    var tally = 0;

    //(100 * 27.6) / vol

    if(pos <= 10 && calc === 10){
      return 'N/A';
    }
    if(pos >= 11 && calc === 10){
      return addPercentage(vol, 2.4)
    }
    if(pos <=5  && calc === 5){
      return 'N/A';
    }
    if(pos >=6  && calc === 5){
      return addPercentage(vol, 6.3)
    }
    if(calc === 3){
      return addPercentage(vol, 11)
    }
    if(calc === 1){
      return addPercentage(vol, 27.6)
    }
  }

  useEffect(() => {
    
  }, []);

  useEffect(() => {

  }, [payload]);

  return (
    <div className="p-4 kpis">
      <h1 className="lg:text-[18px] hidden md:block font-bold font-inter text-[#001C44] dark:text-white whitespace-nowrap mb-2">
        Position Opportunity
      </h1>
      <div className="flex items-center flex-wrap gap-2 mb-4">
        <input
          value={domain}
          name={'domain'}
          onChange={(e) => onInput(e)}
          placeholder="Enter Domain"
          width="w-[220px] sm:w-[300px]"
          className="block p-2 px-3 text-sm text-gray-900 border border-gray-300 rounded-lg w-[220px] sm:w-[300px] bg-white focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
        <input
          value={excluded}
          name={'excluded'}
          onChange={(e) => onInput(e)}
          placeholder="Enter Exclusions (Comma Separated)"
          width="w-[220px] sm:w-[300px]"
          className="block p-2 px-3 text-sm text-gray-900 border border-gray-300 rounded-lg w-[220px] sm:w-[300px] bg-white focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
        <div>
          <ReactSelect
            options={dbOptions}
            value={database}
            placeholder={databaseLabel ? databaseLabel : 'Select Region'}
            handleOnChange={(selectedOption: any) =>
              setPayload(prevPayload => ({
                ...prevPayload,
                database: selectedOption.value,
                databaseLabel: selectedOption.label,
              }))
            }
          />
        </div>
        <button
          className="rounded-lg text-white text-sm border border-[#005C9F] bg-[#005C9F] shadow-xs px-4 py-2 dark:text-[#E6EFF5]"
          onClick={() => handleFetchingPositions()}
        >
          Fetch
        </button>
      </div>
      {error ? <div className="mb-2 text-red-500">{error}</div> : null}
      {data.length ? 
      <>
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 font-inter">
        <thead>
          <tr>
            <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
              Keyword
            </th>
            <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
              Current Position
            </th>
            <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
              Search Volume
            </th>
            <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
              Difficulty
            </th>
            <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
              Position 10
            </th>
            <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
              Position 5
            </th>
            <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
              Position 3
            </th>
            <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
              Position 1
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 w-fit max-w-[300px] z-40">
              <td className='z-10  p-2 min-w-fit'>{item.keyword}</td>
              <td className='z-10  p-2 min-w-fit'>{item.position}</td>
              <td className='z-10  p-2 min-w-fit'>{item.searchVolume}</td>
              <td className='z-10  p-2 min-w-fit'>{diffColor(item.kwDifficulty)}</td>
              <td className='z-10  p-2 min-w-fit'>{opCalculator(item.searchVolume, item.position, 10)}</td>
              <td className='z-10  p-2 min-w-fit'>{opCalculator(item.searchVolume, item.position, 5)}</td>
              <td className='z-10  p-2 min-w-fit'>{opCalculator(item.searchVolume, item.position, 3)}</td>
              <td className='z-10  p-2 min-w-fit'>{opCalculator(item.searchVolume, item.position, 1)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </>
      : ''}
    </div>
  );
};

export default Positions;


