import { create } from 'zustand';
// import Api from '../api';
import { devtools } from 'zustand/middleware';
import api from '../api';

interface AccessStore {
  page: number;
  limit: number;
  loading: boolean;
  access: any;
  fetchAccess: (filter: {}) => Promise<any>;
}

export const useAccessStore = create<AccessStore>(
  devtools((set) => ({
    page: 1,
    limit: 1,
    loading: false,
    access: [],
    fetchAccess: async (filter: {}) => {
      set({ loading: true });
      const response = await api.Meta.getTokens(filter);
      set({ access: response, loading: false });
      return response;
    },
  })) as any
);
