import React from 'react';

interface TrashProps {
  size?: number;
}

const Trash: React.FC<TrashProps> = ({ size = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 20 21"
    fill="none"
    className="cursor-pointer"
  >
    <path
      d="M7.5 3.45001H12.5M2.5 5.95001H17.5M15.8333 5.95001L15.2489 14.7161C15.1612 16.0313 15.1174 16.6889 14.8333 17.1875C14.5833 17.6265 14.206 17.9794 13.7514 18.1997C13.235 18.45 12.5759 18.45 11.2578 18.45H8.74221C7.42409 18.45 6.76503 18.45 6.24861 18.1997C5.79396 17.9794 5.41674 17.6265 5.16665 17.1875C4.88259 16.6889 4.83875 16.0313 4.75107 14.7161L4.16667 5.95001"
      stroke="#7C7E7F"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Trash;
