import { Card } from '@tremor/react';
import Icon from '../../../../assets/icons/SvgComponent';
import AnalyticsTable from '../../AnalyticsTable';

interface IProps {
  campaignsReport: any;
  impressionShareReportData: any;
}

const MobileView = ({ impressionShareReportData, campaignsReport }: IProps) => {
  return (
    <>
      {impressionShareReportData?.data?.length === 0 &&
      !campaignsReport.loading ? (
        <Card className="card-item px-3 sm:px-6 shadow-none lg:shadow ring-0 lg:ring-1">
          <div className="flex flex-col items-center justify-center w-full my-8 h-[148px]">
            <div className="mb-8">
              <Icon name="NoDataAdGroup" />
            </div>
            <p className="font-inter font-[600] text-[20px] text-[#001C44] dark:text-white">
              No impression shares to report as of now.
            </p>
            <p className="text-center w-[70vw] text-[20px] text-[#001C44] dark:text-white">
              Stay ahead with the latest insights and trends affecting your
              impression share. Our specialized section keeps you informed with
              valuable updates and strategies for maximizing visibility and
              impact.
            </p>
          </div>
        </Card>
      ) : (
        <AnalyticsTable
          data={impressionShareReportData?.data}
          columns={impressionShareReportData?.tableHeader}
          loading={campaignsReport.loading}
          totalDocs={
            impressionShareReportData?.data
              ? impressionShareReportData?.data.length
              : 'N/A'
          }
          sortByColumnId="Impression Share (%)"
          pagination={false}
        />
      )}
    </>
  );
};

export default MobileView;
