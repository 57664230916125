import { Card, Grid, Col } from '@tremor/react';
import use3rdPartyStore from '../../../store/use3rdPartyStore';
import {
  formatNumberWithCommas,
  formatToCurrency,
  processData,
} from '../../../utils/common';
import moment from 'moment';
import useClientStore from '../../../store/useClientStore';
import useGoogleStore from '../../../store/useGoogleStore';
import AreaCardChart from '../AreaCardChart';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { isLedGenClient } from '../../../utils/client';
import LineChartCard from '../LineChartCard';

interface IProps {
  retry?: () => void;
  retryGS?: () => void;
}

const MobileView = ({ retry, retryGS }: IProps) => {
  const { ga4SeoData, googleSearchConsole } = useGoogleStore((state) => state);
  const { topKeywords } = use3rdPartyStore((state) => state);
  const { selectedClient } = useClientStore((state) => state);
  const isLeadGen = isLedGenClient(selectedClient);
  const isTopKeywordsLoading = topKeywords?.loading || selectedClient?.loading;
  const topKeywordsData = topKeywords?.data?.map((item) => {
    return {
      date: moment(item.date).format('MMM YY'),
      'Top 3': item.top3,
      '4-10': item.top4_10,
      '11-Plus': item.top11_plus,
    };
  });

  const clicks = processData(googleSearchConsole, 'clicks');
  const organic_sessions = processData(ga4SeoData, 'organic_sessions');
  const organic_totalRevenue = processData(
    ga4SeoData,
    'organic_totalRevenue',
    'Organic Revenue'
  );
  const total_revenue = processData(ga4SeoData, 'totalRevenue');
  const organic_conversions = processData(ga4SeoData, 'organic_conversions');
  const lastMonthOrganicRevenue =
    Array.isArray(organic_totalRevenue) && organic_totalRevenue.length > 0
      ? organic_totalRevenue[organic_totalRevenue.length - 1]?.value
      : undefined;

  const lastMonthClicks =
    Array.isArray(clicks) && clicks.length > 0
      ? clicks[clicks.length - 1]?.value
      : undefined;

  const lastMonthConversions =
    Array.isArray(organic_conversions) && organic_conversions.length > 0
      ? organic_conversions[organic_conversions.length - 1]?.value
      : undefined;

  const lastMonthSessions =
    Array.isArray(organic_sessions) && organic_sessions.length > 0
      ? organic_sessions[organic_sessions.length - 1]?.value
      : undefined;

  const lastMonthTotalRevenue =
    Array.isArray(total_revenue) && total_revenue.length > 0
      ? total_revenue[total_revenue.length - 1]?.value
      : undefined;
  return (
    <div className="block sm:hidden">
      <div className="flex flex-col gap-4">
        <div className="p-0">
          <Swiper pagination={true} modules={[Pagination]} className="p-1">
            <SwiperSlide>
              <AreaCardChart
                title={'Organic Sessions '}
                chatData={organic_sessions}
                category={['Organic Sessions']}
                info={
                  ga4SeoData?.organic_sessions?.info
                    ? ga4SeoData?.organic_sessions?.info
                    : 'Organic Sessions'
                }
                prevPeriod={ga4SeoData?.organic_sessions?.previous_period}
                previousYear={ga4SeoData?.organic_sessions?.previous_year}
                isLoading={ga4SeoData.loading}
                totalValue={formatNumberWithCommas(lastMonthSessions)}
                error={ga4SeoData.error}
                icon="googleAnalytics"
                retryMethod={retry}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title={'Clicks'}
                chatData={clicks}
                category={['Clicks']}
                info={
                  googleSearchConsole?.clicks?.info
                    ? googleSearchConsole?.clicks?.info
                    : 'Clicks'
                }
                prevPeriod={googleSearchConsole?.clicks?.previous_period}
                previousYear={googleSearchConsole?.clicks?.previous_year}
                isLoading={googleSearchConsole.loading}
                totalValue={formatNumberWithCommas(lastMonthClicks)}
                error={googleSearchConsole.error}
                icon="googleSearch"
                retryMethod={retryGS}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title={'Organic Conversions'}
                chatData={organic_conversions}
                category={['Organic Conversions']}
                info={
                  ga4SeoData?.organic_conversions?.info
                    ? ga4SeoData?.organic_conversions?.info
                    : 'Organic Conversions'
                }
                prevPeriod={ga4SeoData?.organic_conversions?.previous_period}
                previousYear={ga4SeoData?.organic_conversions?.previous_year}
                isLoading={ga4SeoData.loading}
                totalValue={formatNumberWithCommas(lastMonthConversions)}
                error={ga4SeoData.error}
                icon="googleAnalytics"
                retryMethod={retry}
              />
            </SwiperSlide>
            {!isLeadGen && (
              <SwiperSlide>
                <AreaCardChart
                  title={'Organic Revenue'}
                  chatData={organic_totalRevenue}
                  category={['Organic Revenue']}
                  info={
                    ga4SeoData?.organic_totalRevenue?.info
                      ? ga4SeoData?.organic_totalRevenue?.info
                      : 'Organic Total Revenue'
                  }
                  prevPeriod={ga4SeoData?.organic_totalRevenue?.previous_period}
                  previousYear={ga4SeoData?.organic_totalRevenue?.previous_year}
                  isLoading={ga4SeoData.loading}
                  totalValue={formatToCurrency(lastMonthOrganicRevenue)}
                  error={ga4SeoData.error}
                  hasDollar
                  icon="googleAnalytics"
                  retryMethod={retry}
                />
              </SwiperSlide>
            )}
            {!isLeadGen && (
              <SwiperSlide>
                <AreaCardChart
                  title={'Total Revenue'}
                  chatData={total_revenue}
                  category={['Total Revenue']}
                  info={
                    ga4SeoData?.totalRevenue?.info
                      ? ga4SeoData?.totalRevenue?.info
                      : 'Organic Revenue'
                  }
                  prevPeriod={ga4SeoData?.totalRevenue?.previous_period}
                  previousYear={ga4SeoData?.totalRevenue?.previous_year}
                  isLoading={ga4SeoData.loading}
                  totalValue={formatToCurrency(lastMonthTotalRevenue)}
                  error={ga4SeoData.error}
                  hasDollar
                  icon="googleAnalytics"
                  retryMethod={retry}
                />
              </SwiperSlide>
            )}
          </Swiper>
        </div>
        <Card className="ring-0">
          <LineChartCard
            data={topKeywordsData ? topKeywordsData : []}
            categories={['Top 3', '4-10', '11-Plus']}
            name="Organic Keywords Trend"
            loading={isTopKeywordsLoading}
            info={
              topKeywords.info ? topKeywords.info : 'Organic Keywords Trend'
            }
            color={['#FFC265', '#7DCEFF', '#019AA3', '#00199D', '#002943']}
            error={topKeywords.error}
            icon="ahref"
          />
        </Card>
      </div>
    </div>
  );
};

export default MobileView;
