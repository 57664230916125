import React, { useState, ChangeEvent, FormEvent } from 'react';
import { RiMailAddFill } from 'react-icons/ri';
import Icon from '../../../assets/icons/SvgComponent';
import useWeeklyReportStore from '../../../store/useWeeklyStore';
import { useThemeStore } from '../../../store/useThemeStore';
interface ReportForm {
  report_type?: string;
  report?: {
    dashthis_url?: string;
    outreach_url?: string;
    rank_ranger_url?: string;
    strategy?: string;
  };
  recipients?: string[];
  id?: string;
}

interface SettingsProps {
  setReportForm: (form: ReportForm) => void;
  reportForm: ReportForm;
  fetchReports: () => void;
}

const Settings: React.FC<SettingsProps> = ({
  setReportForm,
  reportForm,
  fetchReports,
}) => {
  const { mode } = useThemeStore((state: any) => state);
  const iconColor = mode === 'dark' ? 'white' : 'black';
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [successMsg, setSuccessMsg] = useState<string>('');
  const { updateReport, reports } = useWeeklyReportStore();
  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleDeleteRecipient = (emailToDelete: string) => {
    const filteredReci =
      reportForm?.recipients?.filter(
        (recipient) => recipient !== emailToDelete
      ) ?? [];

    setReportForm({
      ...reportForm,
      recipients: filteredReci,
    });
  };

  const handleAddRecipient = (event: FormEvent) => {
    event.preventDefault();
    if (reportForm.recipients?.includes(email)) {
      setError(true);
      return;
    } else if (isValidEmail(email)) {
      setReportForm({
        ...reportForm,
        recipients: [email, ...(reportForm.recipients ?? [])],
      });
      setEmail('');
      setError(false);
    }
  };

  const handleSaveRecipients = () => {
    let data = {
      recipients: reportForm.recipients,
    };
    updateReport(reportForm.id, data)
      .catch((e: any) => setError(e.message))
      .finally(() => {
        fetchReports();
        setSuccessMsg('Updated recipients');
        setTimeout(() => {
          setSuccessMsg('');
        }, 500);
      });
  };

  return (
    <div className="mt-4 ">
      <form
        className="flex justify-center flex-col px-4 relative w-full bg-[#F3F7FA] pb-[20px] dark:bg-gray-800 rounded-lg"
        onSubmit={handleAddRecipient}
      >
        <div className="flex justify-between py-3">
          <span
            style={{ fontWeight: 600, margin: '10px 0' }}
            className="dark:text-white"
          >
            Recipients
          </span>
          <button
            onClick={handleSaveRecipients}
            className="bg-[#B5DAF8] px-4 rounded-lg my-2"
          >
            Save
          </button>
        </div>
        <div className="relative">
          <input
            className="block w-full p-2 ps-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mt-4"
            type="email"
            name="email"
            value={email}
            placeholder="Enter recipient's email address"
            onChange={(e) => setEmail(e.target.value)}
          />
          <button
            className={
              isValidEmail(email)
                ? `absolute right-0 top-[17px] cursor-pointer bg-[#005C9F] py-[8px] px-[20px] rounded-[5px] z-[99]`
                : 'absolute right-0 top-[17px] cursor-pointer bg-[#B5CEE9] py-[8px] px-[20px] rounded-[5px] z-[99]'
            }
            onClick={(e) => isValidEmail(email) && handleAddRecipient(e)}
          >
            <RiMailAddFill size={20} color="#fff" />
          </button>
        </div>
        <div className="border border-[#000] dark:border-[#fff] rounded-lg flex flex-wrap gap-2 px-4 py-2 h-[100px] overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8 mt-4">
          {reportForm.recipients?.map((recipient) => (
            <div
              className="flex items-center gap-2 p-[6px] border border-[#000] dark:border-[#fff] text-xs font-semibold rounded-lg h-fit dark:text-gray-400"
              key={recipient}
            >
              <p>{recipient} </p>
              <div
                className="cursor-pointer"
                onClick={() => handleDeleteRecipient(recipient)}
              >
                <Icon
                  name="Xclose"
                  size={8}
                  color={mode === 'dark' ? '#fff' : iconColor}
                />
              </div>
            </div>
          ))}
        </div>

        {error && (
          <div className="text-[#FF0000] font-[600] text-sm uppercase my-[20px] ">
            Duplicated Email
          </div>
        )}
      </form>
    </div>
  );
};

export default Settings;
