import { Card } from '@tremor/react';
import Icon from '../../../../assets/icons/SvgComponent';
import AnalyticsTable from '../../AnalyticsTable';

interface IProps {
  campaignsReport: any;
  keywordsReportData: any;
}

const MobileView = ({ campaignsReport, keywordsReportData }: IProps) => {
  return (
    <>
      {keywordsReportData?.data?.length === 0 && !campaignsReport.loading ? (
        <Card className="card-item px-3 sm:px-6 shadow-none lg:shadow ring-0 lg:ring-1">
          <div className="flex flex-col items-center justify-center w-full my-8 h-[148px]">
            <div className="mb-8">
              <Icon name="NoDataAdGroup" />
            </div>
            <p className="font-inter font-[600] text-[20px] text-[#001C44] dark:text-white">
              No keywords to display right now.
            </p>
            <p className="text-center w-[70vw] text-[20px] text-[#001C44] dark:text-white">
              Stay connected with the latest in keyword strategies and market
              insights. Our dedicated area provides the updates and analysis
              crucial for optimizing your campaigns.
            </p>
          </div>
        </Card>
      ) : (
        <AnalyticsTable
          data={keywordsReportData?.data}
          columns={keywordsReportData?.tableHeader}
          loading={campaignsReport.loading}
          totalDocs={
            keywordsReportData?.data ? keywordsReportData?.data.length : 'N/A'
          }
          sortByColumnId="Cost"
          pagination={false}
        />
      )}
    </>
  );
};

export default MobileView;
