import { Trash01, Xclose } from '../../../assets/icons';

interface ModalProps {
  linkIndex?: number;
  onClose?: () => void;
  onDelete?: any;
}
const ConfirmationModal: React.FC<ModalProps> = ({
  onClose,
  onDelete,
  linkIndex,
}) => {
  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div
        onClick={(e) => e.stopPropagation()}
        className="flex flex-col items-start w-[30%] h-[300px] p-6 spac-y-4 z-100 bg-white dark:bg-[#262627]  dark:shadow-bs-card border-2 border-[#FFFFFF] rounded-md"
      >
        <div className="flex items-start justify-between w-full">
          <div className=" p-3 rounded-full bg-[#FEE4E2] border-8 border-[#FEF3F2] cursor-pointer">
            <Trash01 />
          </div>
          <div className="cursor-pointer" onClick={onClose}>
            <Xclose />
          </div>
        </div>
        <span className="font-inter text-[#101828] text-2xl font-semibold mt-4 dark:text-[#E6EFF5]">
          Delete
        </span>
        <span className="font-inter w-[90%] text-[#475467] text-lg  mt-1 dark:text-[#E6EFF5]">
          Are you sure you want to delete this? This action cannot be undone.
        </span>

        <div className="flex items-center justify-center gap-4 mt-8 w-full">
          <button
            onClick={onClose}
            className="btn w-full border border-[#808EA2] bg-[#FFF] text-[#001C44] text-lg py-2.5 px-4 rounded-lg font-semibold"
          >
            Cancel
          </button>
          <button
            onClick={() => onDelete(linkIndex)}
            className="btn w-full border border-[#D92D20] bg-[#D92D20] text-[#FFF] text-lg py-2.5 px-4 rounded-lg font-semibold"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
