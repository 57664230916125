import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const UserCheck: React.FC<IconProps> = ({ color = 'black', size = 16 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99992 10.3333H4.99992C4.06954 10.3333 3.60435 10.3333 3.22582 10.4482C2.37356 10.7067 1.70661 11.3736 1.44808 12.2259C1.33325 12.6044 1.33325 13.0696 1.33325 14M10.6666 12L11.9999 13.3333L14.6666 10.6667M9.66659 5C9.66659 6.65685 8.32344 8 6.66659 8C5.00973 8 3.66659 6.65685 3.66659 5C3.66659 3.34315 5.00973 2 6.66659 2C8.32344 2 9.66659 3.34315 9.66659 5Z"
      stroke={color || '#334969'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UserCheck;
