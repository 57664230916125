import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const AM: React.FC<IconProps> = ({ color = 'black', size = 16 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="user-edit">
      <path
        id="Icon"
        d="M6 10.3333H5C4.06962 10.3333 3.60444 10.3333 3.2259 10.4482C2.37364 10.7067 1.70669 11.3736 1.44816 12.2259C1.33333 12.6044 1.33333 13.0696 1.33333 14M9.66667 5C9.66667 6.65685 8.32352 8 6.66667 8C5.00981 8 3.66667 6.65685 3.66667 5C3.66667 3.34315 5.00981 2 6.66667 2C8.32352 2 9.66667 3.34315 9.66667 5ZM7.33333 14L9.4009 13.4093C9.49991 13.381 9.54942 13.3668 9.59559 13.3456C9.63659 13.3268 9.67557 13.3039 9.71194 13.2772C9.75289 13.2471 9.7893 13.2107 9.86211 13.1379L14.1667 8.83336C14.627 8.37311 14.6269 7.62689 14.1667 7.16665C13.7064 6.70642 12.9602 6.70642 12.5 7.16666L8.19544 11.4712C8.12263 11.544 8.08623 11.5804 8.05616 11.6214C8.02946 11.6578 8.00652 11.6967 7.9877 11.7377C7.9665 11.7839 7.95235 11.8334 7.92407 11.9324L7.33333 14Z"
        stroke={color || '#001C44'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default AM;
