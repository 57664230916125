import { useState, useEffect, useRef } from 'react';
import {
  getCoreRowModel,
  useReactTable,
  flexRender,
  ColumnDef,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
} from '@tanstack/react-table';
import Icon from '../../assets/icons/SvgComponent';
import { useThemeStore } from '../../store/useThemeStore';
import OutsideAlerter from '../Common/OutsideAlerter';
import useVisibilityStore from '../../store/useVisibilityStore';
import { getColumnName } from '../../utils';

import Pagination from '../ReportTanStackTable/pagination';

type Props = {
  data: any;
  columns: ColumnDef<Record<string, any>, any>[];
  totalPages?: any;
  page?: any;
  setPage?: any;
  loading?: any;
  setSelectedRow?: any;
  status?: any;
  role?: any;
  rowState?: boolean;
  setRowState?: any;
  tableHeight?: string;
  openVisibility?: any;
  setOpenVisibility?: any;
  totalDocs?: any;
  confirmationModal?: any;
  sortData?: any;
  stickyColumn?: string;
  pageWHeader?: boolean;
  pagination?: boolean;
};

const TanStackTable: React.FC<Props> = ({
  data,
  columns,
  totalPages,
  page,
  setPage,
  loading,
  setSelectedRow,
  status,
  rowState,
  setRowState,
  role,
  tableHeight,
  totalDocs,
  sortData,
  stickyColumn,
  pageWHeader,
  pagination = true,
}) => {
  const { mode } = useThemeStore((state: any) => state);
  const iconColor = mode === 'dark' ? 'white' : 'black';
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = useState({});
  const {
    openVisibility,
    setOpenVisibility,
    setVisibilityOption,
    monthlyVisibilityOption,
    weeklyVisibilityOption,
    performanceVisibilityOption,
  } = useVisibilityStore();

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection: rowSelection,
      sorting,
      globalFilter,
      pagination: page,
      columnVisibility: {
        ...weeklyVisibilityOption,
        ...monthlyVisibilityOption,
        ...performanceVisibilityOption,
      },
    },
    onPaginationChange: setPage,
    manualPagination: true,
    enableRowSelection: true,
    enableHiding: true,
    onColumnVisibilityChange: setVisibilityOption,
    enableSorting: true,
    enableMultiSort: true,
    enableSortingRemoval: true,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    debugTable: true,
  });

  useEffect(() => {
    setVisibilityOption((prevVisibility) => {
      const newVisibility = table.getState().columnVisibility;
      if (newVisibility !== prevVisibility) {
        return newVisibility;
      }
      return prevVisibility;
    });
    setOpenVisibility(false);
  }, [setVisibilityOption, table]);

  useEffect(() => {
    setRowSelection({});
    if (rowState === false) {
      table.resetRowSelection();
      setRowState(true);
    }
  }, [page, status, role, rowState, table, setRowState]);

  useEffect(() => {
    if (table.getSelectedRowModel() && setSelectedRow) {
      setSelectedRow(
        table.getSelectedRowModel().rows.map((row) => row.original)
      );
    }
  }, [rowSelection]);

  const handleCheckboxClick = (columnId) => {
    const isMonthlyOption = Object.keys(monthlyVisibilityOption).includes(
      columnId
    );

    setVisibilityOption({
      [columnId]: !isMonthlyOption
        ? !weeklyVisibilityOption[columnId]
        : !monthlyVisibilityOption[columnId],
      performanceVisibilityOption: !performanceVisibilityOption,
    });
  };

  const handleToggleColumnVisibility = () => {
    setOpenVisibility((prevOpenVisibility) => !prevOpenVisibility);
  };

  const tableRef = useRef(null);
  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }
  }, [page]);

  return (
    <div className="shadow-md rounded-md">
      {openVisibility && (
        <OutsideAlerter toggle={handleToggleColumnVisibility}>
          <div className="inline-block border border-[#EAECF0] shadow rounded w-60 absolute right-6 top-38 bg-white z-[999999] dark:bg-[#1F2937] ">
            <div className=" border-b border-[#EAECF0] font-semibold py-3 px-4">
              <label className="text-[#001C44] text-sm dark:text-white">
                Manage Columns
              </label>
            </div>
            <div className="max-h-[65dvh] overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8">
              {columns.map((column) => {
                return (
                  <div
                    key={column.id}
                    className={
                      column.id === 'Select' ||
                      column.id === 'Action' ||
                      column.id === 'Status' ||
                      column.id === 'Client' ||
                      column.id === 'report_clients' ||
                      column.id === 'name'
                        ? 'hidden'
                        : 'p-2.5 py-3.5 flex items-center justify-between dark:text-white'
                    }
                  >
                    <div className="flex gap-2 items-center">
                      <Icon
                        name={column.id}
                        size={20}
                        color={mode === 'dark' ? '#fff' : iconColor}
                      />
                      <label className="dark:text-white text-sm">
                        {getColumnName(column?.id)}
                      </label>
                    </div>

                    <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        {...{
                          type: 'checkbox',
                          checked:
                            table.getState().columnVisibility[`${column.id}`],
                          onClick: () => handleCheckboxClick(column.id),
                        }}
                        className="sr-only peer"
                      />
                      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#005C9F]" />
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </OutsideAlerter>
      )}
      <div
        ref={tableRef}
        className={
          tableHeight
            ? `relative overflow-y-auto bg-white dark:bg-gray-800  ${tableHeight} sm:rounded-lg scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8`
            : `relative overflow-auto bg-white dark:bg-gray-800 ${pageWHeader ? 'tableHeightWHeader' : 'tableHeight'}  tableHeight sm:rounded-lg scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8`
        }
      >
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 font-inter ">
          <thead className="w-full text-sm text-gray-600  bold">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header, id) => {
                  return (
                    <th
                      className={`font-inter font-[600] ${header.id === stickyColumn ? 'sticky left-0 z-[10] pl-4' : 'z-[9]'} ${header.column.columnDef.meta ?? ''} sticky top-0 z-[11] py-2 text-center bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit `}
                      onClick={
                        sortData
                          ? () => {
                              if (header.id === 'Select') {
                                return;
                              } else {
                                sortData(
                                  header.id,
                                  `${header.column.getAutoSortDir()}`
                                );
                              }
                            }
                          : null
                      }
                      key={header.id}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {loading ? (
              <tr
                className={
                  tableHeight
                    ? `h-[${tableHeight}] absolute top-[50%] left-[48%] overflow-hidden`
                    : `tableHeight absolute top-[50%] left-[48%] overflow-hidden`
                }
              >
                <td>
                  <Icon name="Loading" />
                </td>
              </tr>
            ) : table.getRowModel().rows.length ? (
              table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 w-fit max-w-[300px] z-40"
                >
                  {row.getVisibleCells().map((cell, id) => {
                    return (
                      <td
                        className={`${cell.id.includes(`${stickyColumn}`) ? 'sticky left-0 z-10 pl-4 bg-white dark:bg-gray-800 dark:border-gray-700' : 'z-10'} ${cell.column.columnDef.meta ?? ''} py-2 min-w-fit`}
                        key={cell.id}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))
            ) : (
              <tr className="absolute top-40 left-[20%] sm:left-[40%]">
                <td className="relative flex flex-col justify-center items-center">
                  <p className="mb-4 dark:text-white">No records found</p>
                  <Icon name="NoData" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {pagination && (
        <Pagination
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          totalDocs={totalDocs}
        />
      )}
    </div>
  );
};

export default TanStackTable;
