import React from 'react';

interface Props {
  color?: string;
  size?: number;
}

const TableView: React.FC<Props> = ({ color = '#001C44', size = 14 }) => (
  <svg
    width={size - 1}
    height={size}
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.75 1.5C1.25781 1.5 0.875 1.91016 0.875 2.375V4.125H13.125V2.375C13.125 1.91016 12.7148 1.5 12.25 1.5H1.75ZM0.875 5V8.0625H6.5625V5H0.875ZM7.4375 5V8.0625H13.125V5H7.4375ZM6.5625 8.9375H0.875V11.125C0.875 11.6172 1.25781 12 1.75 12H6.5625V8.9375ZM7.4375 12H12.25C12.7148 12 13.125 11.6172 13.125 11.125V8.9375H7.4375V12ZM0 2.375C0 1.41797 0.765625 0.625 1.75 0.625H12.25C13.207 0.625 14 1.41797 14 2.375V11.125C14 12.1094 13.207 12.875 12.25 12.875H1.75C0.765625 12.875 0 12.1094 0 11.125V2.375Z"
      fill={color}
    />
  </svg>
);

export default TableView;
