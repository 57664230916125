import React from 'react';

interface Props {
  color?: string;
  size?: number;
}

const Email: React.FC<Props> = ({ color = 'black', size = 80 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="13"
    viewBox="0 0 15 13"
    fill="none"
  >
    <path
      d="M14 10.3333L9.57143 6.33333M5.7619 6.33333L1.33336 10.3333M1 3L6.44328 6.8103C6.88406 7.11884 7.10445 7.27312 7.34418 7.33287C7.55593 7.38566 7.7774 7.38566 7.98916 7.33287C8.22888 7.27312 8.44927 7.11884 8.89005 6.8103L14.3333 3M4.2 11.6667H11.1333C12.2534 11.6667 12.8135 11.6667 13.2413 11.4487C13.6176 11.2569 13.9236 10.951 14.1153 10.5746C14.3333 10.1468 14.3333 9.58677 14.3333 8.46667V4.2C14.3333 3.0799 14.3333 2.51984 14.1153 2.09202C13.9236 1.71569 13.6176 1.40973 13.2413 1.21799C12.8135 1 12.2534 1 11.1333 1H4.2C3.07989 1 2.51984 1 2.09202 1.21799C1.71569 1.40973 1.40973 1.71569 1.21799 2.09202C1 2.51984 1 3.0799 1 4.2V8.46667C1 9.58677 1 10.1468 1.21799 10.5746C1.40973 10.951 1.71569 11.2569 2.09202 11.4487C2.51984 11.6667 3.07989 11.6667 4.2 11.6667Z"
      stroke={color}
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Email;
