import { Config } from '../config';

const facebookAppId = Config.FB_APP_ID;
export function initFacebookSdk() {
  return new Promise((resolve) => {
    // wait for facebook sdk to initialize before starting the react app
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: facebookAppId,
        cookie: true,
        xfbml: true,
        version: 'v17.0',
      });
    };

    // load facebook sdk script
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
    resolve();
  });
}

export function isConnected() {
  return new Promise((resolve) => {
    // check api if already logged in with facebook
    window.FB.getLoginStatus(({ status }) => {
      // console.log(status, "status");

      resolve(status);
    });
  });
}

export function getAuthData() {
  return new Promise((resolve, reject) => {
    // return the FB auth object or null
    window.FB.getLoginStatus((authResponse) => {
      if (authResponse) {
        resolve(authResponse);
      } else {
        reject(null);
      }
    });
  }, true);
}

export function login() {
  return new Promise((resolve, reject) => {
    window.FB.login(
      function (response) {
        if (response) {
          resolve(response);
        } else {
          reject(null);
        }
      },
      { scope: 'business_management,ads_read,email' }
    );
  });
}

export function logout() {
  // revoke app permissions to logout completely because FB.logout() doesn't remove FB cookie
  window.FB.api('/me/permissions', 'delete', null, () => window.FB.logout());
  /*   stopAuthenticateTimer();
    accountSubject.next(null);
    history.push('/login'); */
}
