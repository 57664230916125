import { useEffect, useState, useRef } from 'react';
import ClientHeader from '../../components/ClientDetails/header';
import User from './user';
import Teams from './team';
import Reporting from './reporting';
import Integrations from './integrations';
import Services from './services';
import Billing from './billing';
import useClientStore from '../../store/useClientStore';
import useUserStore from '../../store/useUserStore';
import { useLocation, useParams } from 'react-router-dom';
import ChangeRequest from './changeRequest';
export default function ClientDetails() {
  const { fetchClient, selectedClient, loading, client } = useClientStore(
    (state) => state
  );
  const {
    fetchAllUsers,
    fetchUsers,
    allUsers,
    users,
    updateUserStatus,
    apiStatus,
  } = useUserStore((state) => state);
  const location = useLocation();
  const client_id = location.pathname.split('/')[4];
  const currentClient = selectedClient || location.state;
  const handleFetchUsers = () => {
    fetchAllUsers();
  };

  useEffect(() => {
    handleFetchUsers();
  }, [apiStatus]);

  useEffect(() => {
    fetchClientFunction();
  }, [client, updateUserStatus, apiStatus]);

  const fetchClientFunction = () => {
    fetchClient(client_id);
  };

  const scrollRef = useRef<any>(null);
  const scrollToTop = () => {
    const headerHeight = '64px';
    scrollRef.current.style.scrollMargin = headerHeight;
    return scrollRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollToTop();
    }
  }, [location.pathname]);

  return (
    <div
      className="px-2 py-4 sm:px-[32px] sm:py-6 w-full  font-inter bg-[#D2EEFF] h-full relative dark:bg-[#191919] "
      ref={scrollRef}
    >
      <div>
        <ClientHeader
          selectedClient={currentClient}
          handleSyncSuccess={fetchClientFunction}
          isLoading={location.state !== null ? false : loading}
        />
        {/* {isLoading && (
          <div className='w-full h-screen flex justify-center absolute'>
            <Icon name='loading' />
          </div>
        )} */}
        {location.pathname.includes('client/details/users') && (
          <User
            selectedClient={
              location.state !== null ? location.state : selectedClient
            }
            updateUserStatus={updateUserStatus}
            isLoading={location.state !== null ? false : loading}
          />
        )}
        {location.pathname.includes('client/details/teams') && (
          <Teams
            selectedClient={
              location.state !== null ? location.state : selectedClient
            }
            updateUserStatus={updateUserStatus}
            isLoading={location.state !== null ? false : loading}
          />
        )}
        {location.pathname.includes('client/details/changes') && (
          <ChangeRequest
            selectedClient={
              location.state !== null ? location.state : selectedClient
            }
            allStaff={allUsers}
          />
        )}
        {location.pathname.includes('client/details/reporting') && (
          <Reporting
            selectedClient={
              location.state !== null ? location.state : selectedClient
            }
            loading={loading}
          />
        )}
        {location.pathname.includes('client/details/integrations') && (
          <Integrations
            selectedClient={
              location.state !== null ? location.state : selectedClient
            }
          />
        )}
        {location.pathname.includes('client/details/services') && (
          <Services
            selectedClient={
              location.state !== null ? location.state : selectedClient
            }
          />
        )}
        {location.pathname.includes('client/details/billing') && <Billing />}
      </div>
    </div>
  );
}
