import React from 'react';

interface IProps {
  color?: string;
  size?: number;
}

const ChangeApprovals: React.FC<IProps> = ({
  color = '#405573',
  size = 16,
}) => (
  <svg
    width={size + 4}
    height={size}
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 1.5C6.09375 1.5 5.28125 2 4.8125 2.75C4.375 3.53125 4.375 4.5 4.8125 5.25C5.28125 6.03125 6.09375 6.5 7 6.5C7.875 6.5 8.6875 6.03125 9.15625 5.25C9.59375 4.5 9.59375 3.53125 9.15625 2.75C8.6875 2 7.875 1.5 7 1.5ZM7 8C5.5625 8 4.25 7.25 3.53125 6C2.8125 4.78125 2.8125 3.25 3.53125 2C4.25 0.78125 5.5625 0 7 0C8.40625 0 9.71875 0.78125 10.4375 2C11.1562 3.25 11.1562 4.78125 10.4375 6C9.71875 7.25 8.40625 8 7 8ZM5.5625 11C3.5 11 1.8125 12.5312 1.53125 14.5H12.4375C12.1562 12.5312 10.4688 11 8.40625 11H5.5625ZM5.5625 9.5H8.40625C11.5 9.5 14 12 14 15.0938C14 15.5938 13.5625 16 13.0625 16H0.90625C0.40625 16 0 15.5938 0 15.0938C0 12 2.46875 9.5 5.5625 9.5ZM19.5312 5.53125L15.5312 9.53125C15.2188 9.84375 14.75 9.84375 14.4688 9.53125L12.4688 7.53125C12.1562 7.25 12.1562 6.78125 12.4688 6.5C12.75 6.1875 13.2188 6.1875 13.5312 6.5L15 7.96875L18.4688 4.5C18.75 4.1875 19.2188 4.1875 19.5312 4.5C19.8125 4.78125 19.8125 5.25 19.5312 5.53125Z"
      fill={color}
    />
  </svg>
);

export default ChangeApprovals;