import { useState, useEffect } from 'react';
import Icon from '../../../assets/icons/SvgComponent';
import API from '../../../api';
import { FadeLoader } from 'react-spinners';

interface ModalProps {
  change?: any;
  type?: any;
  icon?: any;
  onClose?: () => void;
  onDelete?: any;
  loading?: boolean;
}



const ConfirmationModal: React.FC<ModalProps> = ({
  change,
  onClose,
  onDelete,
  type,
  icon,
  loading,
}) => {
  const [isProcessing, setProcessing] = useState(false);
  const [processValues, setProcessValues] = useState({
    declineReason: '',
    declineError:'',
    changeValues: change
  });

  const handleApprove = async (chreq, type) => {
    setProcessValues({...processValues, declineError: ''})
    if (type === 'approve') {
      setProcessing(true);  
      const response = await API.ChangeRequest.approveChange({ chreq });
      if (response[0]?.error) {
        setProcessing(false);
      } else {
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      }
    } else {
      if(processValues.declineReason === ''){
        setProcessValues({...processValues, declineError: 'You must add a reason for declining'})
      }else{
        setProcessing(true);  
        const response = await API.ChangeRequest.removeChange({ processValues });
        if (response[0]?.error) {
          setProcessing(false);
        } else {
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        }
      }
    }
  };
  const handleDeclineReason = async (e) => {
    let reason = e.target.value
    setProcessValues({...processValues, declineReason: reason})
  }

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div
        onClick={(e) => e.stopPropagation()}
        className="flex flex-col items-start w-[30%] h-[300px] p-6 spac-y-4 z-100 bg-white dark:bg-[#262627]  dark:shadow-bs-card border-2 border-[#FFFFFF] rounded-md"
      >
        <div className="flex items-start justify-between w-full">
          <div
            className={
              type === 'approve'
                ? 'p-3 rounded-full bg-[#DCFAE6] border-8 border-[#FEF3F2] cursor-pointer'
                : 'p-3 rounded-full bg-[#FEE4E2] border-8 border-[#FEF3F2] cursor-pointer'
            }
          >
            <Icon name={icon} color={type === 'approve' ? '#079455' : ''} />
          </div>
          <div className="cursor-pointer" onClick={onClose}>
            <Icon name="Xclose" />
          </div>
        </div>
        <span className="font-inter text-[#101828] text-2xl font-semibold mt-4 dark:text-[#E6EFF5] capitalize">
          {type}
        </span>
        <span className="font-inter w-[90%] text-[#475467] text-lg  mt-1 dark:text-[#E6EFF5]">
          Are you sure you want to {type} this change request? This action
          cannot be undone.
        </span>
        {type === 'decline' ?
        <input className="decline-input" onChange={(e) => handleDeclineReason(e)} placeholder="Reason for decline..." />
        :
        ''}
        {processValues.declineError === '' ? '' : <span className="text-mini-danger">{processValues.declineError}</span>}
        <div className="flex items-center justify-center gap-4 mt-8 w-full">
        {isProcessing ? (
          <>         
            <button
              disabled
              className={
                type === 'approve'
                  ? 'btn w-full border border-[#005C9F] bg-[#005C9F] text-[#FFF] text-lg py-2.5 px-4 rounded-lg font-semibold capitalize'
                  : 'btn w-full border border-[#D92D20] bg-[#D92D20] text-[#FFF] text-lg py-2.5 px-4 rounded-lg font-semibold capitalize'
              }
            >
              Loading...
            </button>
            </>  
          ) : (
            <>
            <button
              onClick={() => handleApprove(change, type)}
              className={
                type === 'approve'
                  ? 'btn w-full border border-[#005C9F] bg-[#005C9F] text-[#FFF] text-lg py-2.5 px-4 rounded-lg font-semibold capitalize'
                  : 'btn w-full border border-[#D92D20] bg-[#D92D20] text-[#FFF] text-lg py-2.5 px-4 rounded-lg font-semibold capitalize'
              }
            >
              {type}
            </button>
            <button
              onClick={onClose}
              className="btn w-full border border-[#808EA2] bg-[#FFF] text-[#001C44] text-lg py-2.5 px-4 rounded-lg font-semibold"
            >
              Cancel
            </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
