import React from 'react';

interface ArrowupProps {
  color?: string;
  size?: string;
}

const Arrowup: React.FC<ArrowupProps> = ({ size='16' , color = '#7C7E7F' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99992 12.6663V3.33301M7.99992 3.33301L12.6666 7.99967M7.99992 3.33301L3.33325 7.99967"
      stroke={color}
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Arrowup;
