import React from 'react';

interface Props {
  color?: string;
  size?: number;
}

const Target: React.FC<Props> = ({ color = 'currentColor', size = 25 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size - 1}
    height={size}
    viewBox="0 0 24 25"
    fill="none"
  >
    <path
      d="M16 8.03711V5.03711L19 2.03711L20 4.03711L22 5.03711L19 8.03711H16ZM16 8.03711L12 12.0371M22 12.0371C22 17.56 17.5228 22.0371 12 22.0371C6.47715 22.0371 2 17.56 2 12.0371C2 6.51426 6.47715 2.03711 12 2.03711M17 12.0371C17 14.7985 14.7614 17.0371 12 17.0371C9.23858 17.0371 7 14.7985 7 12.0371C7 9.27569 9.23858 7.03711 12 7.03711"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Target;
