import React, { useState } from 'react';
import ChangeHistory from './Changes';
import Settings from './Settings';
import Metrics from './Metrics';
import useWeeklyReportStore from '../../../store/useWeeklyStore';
interface WeeklyProps {
  selectedReport: any;
  closeModal: () => void;
  fetchReports: () => void;
}
const ApproveModal: React.FC<WeeklyProps> = ({
  selectedReport,
  closeModal,
  fetchReports,
}) => {
  const [comment, setComment] = useState('');
  const [saveError, setSaveError] = useState(false);
  const { bulkReportUpdate, reports } = useWeeklyReportStore();

  const handleApprove = () => {
    let data;

    if (selectedReport.id) {
      data = [
        {
          _id: selectedReport.id,
          status: 'ready',
          comment: comment,
        },
      ];
    } else {
      data = selectedReport?.map((report) => ({
        _id: report?.client?.id,
        status: 'ready',
        comment: comment,
      }));
    }

    bulkReportUpdate(data)
      .catch((e: any) => setSaveError(e.message))
      .finally(() => {
        fetchReports();
        closeModal();
      });
  };
  return (
    <div className="flex w-[100%] h-[100%] justify-center items-center pt-[10%]">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="flex flex-col h-[350px] w-[500px] rounded-lg bg-white p-5"
      >
        <div className="flex justify-between items-center h-[20px] mb-4">
          <span></span>
          <span onClick={closeModal} className="cursor-pointer text-lg">
            X
          </span>
        </div>
        {saveError && <span className="text-red-500">{saveError}</span>}
        <span className="text-xl font-inter mb-4">Leave your notes</span>
        <textarea
          onChange={(e) => setComment(e.target.value)}
          value={comment}
          className="w-full h-[200px] p-2 border mt-3 border-[#D0D5DD] rounded-md"
        ></textarea>
        <div className="flex justify-around mt-4">
          <button
            onClick={closeModal}
            className="py-2 px-4 mt-4 border-[#808EA2] border-2 text-[#001C44] rounded-md w-[40%]"
          >
            Cancel
          </button>
          <button
            onClick={handleApprove}
            className="py-2 px-4 mt-4 bg-[green] text-white rounded-md w-[40%]"
          >
            Approve
          </button>
        </div>
      </div>
    </div>
  );
};

export default ApproveModal;
