import { FC, useEffect, useState } from 'react';
import Icon from '../../../assets/icons/SvgComponent';
import { useThemeStore } from '../../../store/useThemeStore';
import { ToastContainer, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchDropdown, { OptionProps } from '../../SearchDropdown';
import useBingStore from '../../../store/useBingStore';
import useClientStore from '../../../store/useClientStore';
interface BingAdsProps {
  onClose?: () => void;
  data: any;
}

const BingAds: FC<BingAdsProps> = ({ onClose, data }) => {
  const {
    fetchBingAdsUserAccount,
    fetchBingAdsClientsUser,
    bingAds,
    bingAdsClientUser,
    selectedDashboard,
  } = useBingStore((state) => state);
  const { selectedClient, fetchClient, updateClient } = useClientStore(
    (state) => state
  );

  const { mode } = useThemeStore((state: any) => state);
  const iconColor = mode === 'dark' ? 'white' : 'black';

  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    label: selectedClient?.bing_ads?.account_email || '',
    value: selectedClient?.bing_ads?.account_email || '',
    id: '',
  });

  const [bingSelectedClient, setBingSelectedClient] = useState({
    label: selectedClient?.bing_ads?.Name || '',
    value: selectedClient?.bing_ads?.Number || '',
    id: selectedClient?.bing_ads?.Id || '',
  });

  useEffect(() => {
    fetchBingAdsUserAccount();
    if (selectedUser?.label || selectedUser?.value) {
      fetchBingAdsClientsUser(selectedUser?.label || selectedUser?.value);
    }
  }, [selectedUser]);

  const handleSave = (e: any) => {
    setIsLoading(true);
    const data = {
      bing_ads: {
        Id: bingSelectedClient?.id,
        Name: bingSelectedClient?.label,
        Number:
          bingAdsClientUser?.data?.filter(
            (i) => i.Id === bingSelectedClient?.id
          )[0]?.Number || '',
        AccountLifeCycleStatus: 'Active',
        account_email: selectedUser?.label,
        status: 'active',
      },
    };

    updateClient(selectedClient?.id, data).then((res: any) => {
      setIsLoading(false);
      onClose && onClose();
    });
  };

  const formatBingAds =
    bingAds?.data?.length > 0
      ? bingAds?.data.map((item) => {
          return {
            label: item._id,
            id: item._id,
          };
        })
      : [];

  const formatBingClients =
    bingAdsClientUser?.data?.length > 0
      ? bingAdsClientUser?.data.map((item) => {
          return {
            label: item.Name,
            value: item.Name,
            id: item.Id,
          };
        })
      : [];

  return (
    <div
      className="fixed top-0 right-0 bg-white h-screen w-screen sm:w-[32vw] p-[24px] flex flex-col z-100 dark:bg-[#262627] dark:border dark:border-[#313233] dark:shadow-bs-card"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex justify-between items-center">
        <div className="flex gap-[8px] items-center">
          <div className="bg-gray-100 p-2 rounded-lg">
            <img src={data.image} alt="i" width={36} height={36} />
          </div>
          <div className="text-[24px] dark:text-white">Microsoft Ads</div>
        </div>
        {isLoading && (
          <div className="absolute right-16 top-5">
            <Icon name="loading" />
          </div>
        )}
        <div className="cursor-pointer" onClick={onClose}>
          <Icon
            name="Xclose"
            size={20}
            color={mode === 'dark' ? '#fff' : iconColor}
          />
        </div>
      </div>
      <div
        className="hidden sm:flex absolute top-[50%] left-[-50px]  bg-[#E6EFF5]  border border-[#E6EFF5] rounded-[8px] py-[8px] px-[14px] transform rotate-[270deg] text-[14px]  text-[#005C9F] gap-[8px] cursor-pointer"
        onClick={onClose}
      >
        <div>Close</div>
        <Icon name="Xclose" size={20} color="#005C9F" />
      </div>
      <div className="flex flex-col justify-between h-[98%]">
        <div>
          <div className="mt-[24px] pt-[24px] border-t mb-[16px]">
            <p className="mb-[6px] text-sm text-[#001C44] dark:text-white">
              User Account
            </p>
            <SearchDropdown
              labelKey="label"
              valueKey="id"
              placeholder="Select User Account"
              options={formatBingAds}
              defaultValue={selectedUser?.label}
              onSelect={(e: any) => setSelectedUser(e)}
            />
          </div>
          <div className="">
            <p className="mt-[6px] text-sm text-[#001C44] dark:text-white">
              Client User
            </p>
            <SearchDropdown
              labelKey="label"
              valueKey="id"
              placeholder="Select Client User"
              options={formatBingClients}
              defaultValue={bingSelectedClient?.label}
              onSelect={(e: any) => {
                setBingSelectedClient(e);
              }}
            />
          </div>
        </div>
        <div>
          <button
            onClick={(e) => handleSave(e)}
            disabled={isLoading}
            className="text-white bg-[#005C9F] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm w-full py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          >
            Save
          </button>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={30000}
        hideProgressBar
        newestOnTop={false}
        transition={Flip}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default BingAds;
