import React from 'react';
import { Card, Grid, Col } from '@tremor/react';
import { TaskCompletedCard } from '../TaskCompletedCard';
import { TopGoals } from '../TopGoals';

export const TaskGoals = () => {
  return (
    <div className="border-none px-1">
      <Grid
        numItems={1}
        numItemsSm={1}
        numItemsMd={1}
        numItemsLg={2}
        className="lg:gap-4 gap-4"
      >
        <TaskCompletedCard />
        <TopGoals />
      </Grid>
    </div>
  );
};
