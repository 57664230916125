// WeeklyReport.tsx
import React, { useState } from 'react';
import Icon from '../../../assets/icons/SvgComponent';
import { BarChart, Card, Title } from '@tremor/react';
import { useLocation, useNavigate } from 'react-router-dom';

interface PieChartProps {
  title?: string;
  data?: any;
  loading?: boolean;
}

const PieChart: React.FC<PieChartProps> = ({
  title,
  data,
  loading,
}) => {

  let serviceLines = []
  let serviceCount = []
  //console.log('Data Coming In: ', data)
  for (const property in data) {
    if(data[property].length){
      serviceLines.push(property)
      serviceCount.push(
        {
          service: property,
          [property]: data[property].length,
        }
      )
    }
  }

  const lastElemet = data?.length ? data[data?.length - 1] : 0;
  //display no record if no data
  const valueFormatter = (number: number) =>
    Intl.NumberFormat('us').format(number).toString();

  const location = useLocation();
  const [value, setValue] = useState(null);
  const handleNavigate = (val: any) => {
    const searchParams = new URLSearchParams(location.search);

    searchParams.set('date', val.Month);
    searchParams.set('healthStatus', val.categoryClicked.toLowerCase());
    const updatedSearch = searchParams?.toString();
    location.search = updatedSearch;
    const newUrl = `/client-reporting?${updatedSearch}&status=all&all=true`;

    window.open(newUrl, '_blank');
  };

  return (
    <Card className="md:max-w-[49.5%]">
      <Title>{title}</Title>

      {loading ? (
        <div className="w-full h-80 flex flex-col justify-center items-center">
          <Icon name="Loading" />
        </div>
      ) : (
        <>
          {serviceCount?.length ? (
            <BarChart
              className="opacity-70"
              data={serviceCount}
              index="service"
              categories={serviceLines}
              colors={['emerald', 'amber', 'red', 'blue', 'yellow', 'sky', 'slate', 'green', 'orange', 'purple', 'teal' ]}
              valueFormatter={valueFormatter}
              yAxisWidth={48}
              stack={true}
              onValueChange={(v) => handleNavigate(v)}
            />
          ) : (
            <div className="w-full h-80 flex flex-col justify-center items-center">
              <p className="mb-4">No records found</p>
              <Icon name="NoData" />
            </div>
          )}
        </>
      )}
    </Card>
  );
};

export default PieChart;
