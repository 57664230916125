import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from '@tremor/react';
import useClientStore from '../../../store/useClientStore';
import CustomModal from '../../CustomModal';
import RequestModal from '../../Dashboard/RequestModal';
import useGoogleStore from '../../../store/useGoogleStore';
import useMetaStore from '../../../store/useMetaStore';
import useBingStore from '../../../store/useBingStore';
import { processData, processData2 } from '../../../utils/common';
import { useThemeStore } from '../../../store/useThemeStore';
import Icon from '../../../assets/icons/SvgComponent';
import moment from 'moment';
import { isLedGenClient } from '../../../utils/client';
import GoogleImg from '../../../assets/images/services/Google.png';
import GoogleAdsImg from '../../../assets/images/services/GoogleADS.png';
import MetaImg from '../../../assets/images/services/MetaADS.png';
import { useRetryFetchGA4Analytics } from '../Hooks';
import KPISeo from './KPISeo';
import KPIGa4 from './KPIGa4';
import KPIBingAds from './KPIBingAds';
import KPIMeta from './KPIMeta';
import MobileView from './mobileView';

interface Props {
  isLoading?: boolean;
  data?: any;
  title?: string;
  defaultChart?: string;
  dollarItems?: string[];
  percentageItems?: string[];
}

interface SelectedService {
  id?: string | null;
  title?: string | null;
  image?: any;
  status?: string | null;
  icon?: string | null;
  startDate?: string;
  revenue?: string;
  serviceId?: string;
  contractDue?: string;
}

const ChannelKPI: React.FC<Props> = () => {
  const {
    fetchTopPerformanceSeo,
    topPerformanceSeo,
    fetchTopPerformanceAds,
    topPerformanceAds,
    resetGoogleStore,
  } = useGoogleStore((state) => state);
  const { fetchTopPerformanceMeta, topPerformanceMeta } = useMetaStore(
    (state) => state
  );
  const { fetchTopPerformanceBing, topPerformanceBing } = useBingStore(
    (state) => state
  );
  const { mode } = useThemeStore((state) => state);

  const { selectedClient } = useClientStore((state) => state);
  const isLeadGen = isLedGenClient(selectedClient);
  const [activeTabIndex, setActiveTabIndex] = useState(1);
  const [isRequestModal, setRequestModall] = useState(false);
  const [selectedService, setSelectedService] =
    useState<SelectedService | null>(null);
  const [service, setService] = useState<{
    label: string;
    value: string;
  }>({
    label: 'Google SEO',
    value: 'seo',
  });

  const handleFetchTopPerfomanceSeo = async () => {
    if (selectedClient && selectedClient?.google_analytics?.ga4?.property_id) {
      const filters = {
        clientId: selectedClient?.id,
        reportType: 'seo',
        account_email: selectedClient?.google_analytics?.ga4?.account_email,
        propertyId: selectedClient?.google_analytics?.ga4?.property_id,
        startDate: moment().subtract(1, 'year').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      };
      try {
        await fetchTopPerformanceSeo(filters);
      } catch (error) {
        console.error('Error fetching top performance SEO:', error);
      }
    }
  };

  const handleFetchTopPerfomanceAds = async () => {
    if (selectedClient && selectedClient?.google_ads?.status === 'activated') {
      const filters = {
        clientId: selectedClient?.id,
        reportType: 'ppc',
        startDate: moment().subtract(1, 'year').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      };
      try {
        await fetchTopPerformanceAds(filters);
      } catch (error) {
        console.error('Error fetching top performance Ads:', error);
      }
    }
  };

  const handleFetchTopPerfomanceBing = async () => {
    if (selectedClient && selectedClient?.id 
      && (selectedClient?.bing_ads?.status === "active" || selectedClient?.bing_ads?.status === "activated")
    ) {
      const filters = {
        clientId: selectedClient?.id,
        account: selectedClient?.bing_ads?.account_email?.trim() || 'devops@onlinemarketinggurus.com.au',
        startDate: moment().subtract(1, 'year').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      };

      try {
        await fetchTopPerformanceBing(filters);
      } catch (error) {
        console.error('Error fetching top performance Ads:', error);
      }
    }
  };

  const handleFetchTopPerfomanceMeta = async () => {
    if (
      selectedClient &&
      (selectedClient?.social_meta?.status === 'active' ||
        selectedClient?.social_meta?.status === 'activated')
    ) {
      const filters = {
        clientId: selectedClient?.id,
        startDate: moment().subtract(1, 'year').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      };
      try {
        await fetchTopPerformanceMeta(filters);
      } catch (error) {
        console.error('Error fetching top performance Meta:', error);
      }
    }
  };

  const retryFetchGA4Analytics = useRetryFetchGA4Analytics();

  useEffect(() => {
    resetGoogleStore(['topPerformanceAds']);
    resetGoogleStore(['topPerformanceSeo']);
  }, []);

  useEffect(() => {
    handleFetchTopPerfomanceSeo();
    handleFetchTopPerfomanceAds();
    handleFetchTopPerfomanceMeta();
    handleFetchTopPerfomanceBing();
    // fetchGA4Analytics();
  }, [selectedClient]);

  const organic_conversions = processData(
    topPerformanceSeo,
    'organic_conversions'
  );
  const organic_totalRevenue = processData(
    topPerformanceSeo,
    'organic_totalRevenue',
    'Organic Revenue'
  );
  const organic_sessions = processData(topPerformanceSeo, 'organic_sessions');
  const clicks = processData(topPerformanceSeo, 'clicks');

  const organic_conversions_breakdown = processData2({
    dates: topPerformanceSeo?.organic_conversions?.dates,
    values: topPerformanceSeo?.organic_conversions?.values,
    totalKeyName: 'Total',
  });

  const lastMonthTotalOrganicConversionsBreakdown =
    Array.isArray(organic_conversions_breakdown) &&
    organic_conversions_breakdown.length >= 2
      ? organic_conversions_breakdown[
          organic_conversions_breakdown.length - 1
        ]?.['Total']
      : undefined;

  const lastMonthOrganicRevenue =
    Array.isArray(organic_totalRevenue) && organic_totalRevenue.length > 0
      ? organic_totalRevenue[organic_totalRevenue.length - 1]?.[
          'Organic Revenue'
        ]
      : undefined;

  const lastMonthOrganicSessions =
    Array.isArray(organic_sessions) && organic_sessions.length >= 2
      ? organic_sessions[organic_sessions.length - 1]?.value
      : undefined;

  const lastMonthClicks =
    Array.isArray(clicks) && clicks.length > 0
      ? clicks[clicks.length - 1]?.value
      : undefined;

  const transformedData = {};

  // Iterate over the original data object
  for (const key in topPerformanceAds) {
    const entry = topPerformanceAds[key];

    // Iterate over each property in the entry
    for (const prop in entry) {
      // Initialize an array for the property if it doesn't exist
      if (!transformedData[prop]) {
        transformedData[prop] = {
          dates: [],
          values: [],
        };
      }

      // Push the date and value to the respective arrays
      if (prop !== 'month') {
        transformedData[prop].dates.push(entry.month);
        transformedData[prop].values.push(parseFloat(entry[prop])); // Convert string values to numbers
      }
    }
  }

  const adsClicks = processData(topPerformanceAds, 'clicks');
  const adsConversions = processData2({
    dates: topPerformanceAds?.conversionActions?.['overall']?.dates,
    values: topPerformanceAds?.conversionActions?.['overall']?.values,
    totalKeyName: 'Conversions',
  });

  const adsCostMicros = processData(topPerformanceAds, 'cost_micros', 'Spend');
  const adsCostPerConversion = processData(
    topPerformanceAds,
    'cost_per_conversion'
  );

  const adsRoas = processData(topPerformanceAds, 'roas');
  const adsRevenue = processData(topPerformanceAds, 'revenue');

  const lastMonthAdsClicks =
    Array.isArray(adsClicks) && adsClicks.length > 0
      ? adsClicks[adsClicks.length - 1]?.value
      : undefined;

  const lastMonthAdsConversions =
    Array.isArray(adsConversions) && adsConversions.length > 0
      ? adsConversions[adsConversions.length - 1]?.Conversions
      : undefined;

  const lastMonthAdsCostMicros =
    Array.isArray(adsCostMicros) && adsCostMicros.length > 0
      ? adsCostMicros[adsCostMicros.length - 1]?.value
      : undefined;

  const lastMonthAdsCostPerConversion =
    Array.isArray(adsCostPerConversion) && adsCostPerConversion.length > 0
      ? adsCostPerConversion[adsCostPerConversion.length - 1]?.value
      : undefined;

  const lastMonthAdsRoas =
    Array.isArray(adsRoas) && adsRoas.length > 0
      ? adsRoas[adsRoas.length - 1]?.value
      : undefined;

  const lastMonthAdsRevenue =
    Array.isArray(adsRevenue) && adsRevenue.length > 0
      ? adsRevenue[adsRevenue.length - 1]?.value
      : undefined;

  const spend = processData(topPerformanceMeta, 'spend');
  const roas = processData(topPerformanceMeta, 'roas');
  const revenue = processData(topPerformanceMeta, 'revenue');
  const metaClicks = processData(topPerformanceMeta, 'clicks');
  // const form_leads = processData(topPerformanceMeta, 'form_leads');
  // const website_leads = processData(topPerformanceMeta, 'website_leads');
  const metaConversions = processData(topPerformanceMeta, 'conversions');
  const metaCostPerConversion = processData(topPerformanceMeta, 'cpc');

  const lastMonthMetaSpend =
    Array.isArray(spend) && spend.length > 0
      ? spend[spend.length - 1]?.value
      : undefined;

  const lastMonthMetaROAS =
    Array.isArray(roas) && roas.length > 0
      ? roas[roas.length - 1]?.value
      : undefined;

  const lastMonthMetaRevenue =
    Array.isArray(revenue) && revenue.length > 0
      ? revenue[revenue.length - 1]?.value
      : undefined;

  // const lastMonthMetaWebLeads =
  //   Array.isArray(website_leads) && website_leads.length > 0
  //     ? website_leads[website_leads.length - 1]?.value
  //     : undefined;
  // const lastMonthMetaFormLeads =
  //   Array.isArray(form_leads) && form_leads.length > 0
  //     ? form_leads[form_leads.length - 1]?.value
  //     : undefined;

  const lastMonthMetaClicks =
    Array.isArray(metaClicks) && metaClicks.length > 0
      ? metaClicks[metaClicks.length - 1]?.value
      : undefined;

  const lastMonthMetaConversions =
    Array.isArray(metaConversions) && metaConversions.length > 0
      ? metaConversions[metaConversions.length - 1]?.value
      : undefined;

  const lastMonthMetaCostPerConversion =
    Array.isArray(metaCostPerConversion) && metaCostPerConversion.length > 0
      ? metaCostPerConversion[metaCostPerConversion.length - 1]?.value
      : undefined;

  const renderHeading = () => {
    return (
      <>
        <div className="flex justify-between w-full items-center mb-4 sm:mb-8">
          <div className="text-white font-archivo font-[800] text-[18px] sm:text-[24px]">
            Top Channel KPIs
          </div>
          <div></div>
        </div>
      </>
    );
  };

  const isGoogleSeo =
    selectedClient?.seo_google?.status === 'inactive' ? false : true;

  const isGoogleAds =
    selectedClient?.ppc_google?.status === 'inactive' ? false : true;

  const isMicrosoftAds =
    selectedClient?.ppc_bing?.status === 'inactive' ? false : true;

  const isMeta =
    selectedClient?.social_meta?.status === 'inactive' ? false : true;

  const requestGoogleSEOAccess = () => {
    setSelectedService({
      id: selectedClient?.id || null,
      image: GoogleImg,
      icon: 'OutlineGoogleSEO',
      title: 'Google Seo',
      status: 'inactive',
      startDate: null,
      revenue: null,
      serviceId: null,
    });
    setRequestModall(true);
  };

  const requestGoogleAdsAccess = () => {
    setSelectedService({
      id: selectedClient?.id || null,
      image: GoogleAdsImg,
      icon: 'OutlineGoogleAds',
      title: 'Google Ads',
      status: 'inactive',
      startDate: null,
      revenue: null,
      serviceId: null,
    });
    setRequestModall(true);
  };

  const requestMetaAccess = () => {
    setSelectedService({
      id: selectedClient?.id || null,
      image: MetaImg,
      icon: MetaImg,
      title: 'Meta',
      status: 'inactive',
      startDate: null,
      revenue: null,
      serviceId: null,
    });
    setRequestModall(true);
  };

  const tabs = [
    {
      tabName: 'Google SEO',
      tabIndex: 1,
      status:
        selectedClient?.seo_google?.status !== 'inactive' &&
        selectedClient?.seo_google?.status !== 'deactivated'
          ? true
          : false,
      icon: 'GoogleSEO',
      name: 'seo_google',
    },
    {
      tabName: 'Google Ads',
      tabIndex: 2,
      status:
        selectedClient?.ppc_google?.status !== 'inactive' &&
        selectedClient?.ppc_google?.status !== 'deactivated'
          ? false
          : true,
      icon: 'GoogleAdsAnalyticTab',
      name: 'ppc_google',
    },
    {
      tabName: 'Microsoft Ads',
      tabIndex: 3,
      status:
        selectedClient?.ppc_bing?.status !== 'inactive' &&
        selectedClient?.ppc_bing?.status !== 'deactivated'
          ? false
          : true,
      icon: 'BingAnalyticsTab',
      name: 'ppc_bing',
    },
    {
      tabName: 'Meta',
      tabIndex: 4,
      status:
        selectedClient?.social_meta?.status !== 'inactive' &&
        selectedClient?.social_meta?.status !== 'deactivated'
          ? true
          : false,
      icon: 'MetaAnalyticsTab',
      name: 'social_meta',
    },
  ];

  // seo_google
  // ppc_google
  // social_meta
  const services = selectedClient?.service || [];
  const displayedTabs =
    services.length > 0 &&
    tabs.sort((a, b) => {
      const aIndex = services.indexOf(a.name);
      const bIndex = services.indexOf(b.name);
      if (aIndex === -1 && bIndex === -1) {
        return 0;
      }
      if (aIndex === -1) {
        return 1;
      }
      if (bIndex === -1) {
        return -1;
      }
      return aIndex - bIndex;
    });
  displayedTabs?.length &&
    displayedTabs?.forEach((tab, index) => {
      tab.tabIndex = index + 1;
    });

  const tabPanelMapping = {
    seo_google: (
      <TabPanel key="seo_google">
        <KPISeo
          isLeadGen={isLeadGen}
          isGoogleSeo={isGoogleSeo}
          topPerformanceSeo={topPerformanceSeo}
          requestGoogleSEOAccess={requestGoogleSEOAccess}
          selectedClient={selectedClient}
          retryFetchGA4Analytics={retryFetchGA4Analytics}
        />
      </TabPanel>
    ),
    ppc_google: (
      <TabPanel key="ppc_google">
        <KPIGa4
          isLeadGen={isLeadGen}
          isGoogleAds={isGoogleAds}
          selectedClient={selectedClient}
          topPerformanceAds={topPerformanceAds}
          requestGoogleAdsAccess={requestGoogleAdsAccess}
        />
      </TabPanel>
    ),
    ppc_bing: (
      <TabPanel key="ppc_bing">
        <KPIBingAds
          isLeadGen={isLeadGen}
          isMicrosoftAds={isMicrosoftAds}
          selectedClient={selectedClient}
          topPerformanceBing={topPerformanceBing}
          requestBingAdsAccess={requestGoogleAdsAccess}
        />
      </TabPanel>
    ),
    social_meta: (
      <TabPanel key="social_meta">
        <KPIMeta
          isLeadGen={isLeadGen}
          isMeta={isMeta}
          topPerformanceMeta={topPerformanceMeta}
          requestMetaAccess={requestMetaAccess}
          spend={spend}
          lastMonthMetaSpend={lastMonthMetaSpend}
          metaClicks={metaClicks}
          lastMonthMetaClicks={lastMonthMetaClicks}
          metaConversions={metaConversions}
          lastMonthMetaConversions={lastMonthMetaConversions}
          metaCostPerConversion={metaCostPerConversion}
          lastMonthMetaCostPerConversion={lastMonthMetaCostPerConversion}
          revenue={revenue}
          lastMonthMetaRevenue={lastMonthMetaRevenue}
          roas={roas}
          lastMonthMetaROAS={lastMonthMetaROAS}
        />
      </TabPanel>
    ),
  };

  const sortedTabPanels =
    displayedTabs?.length &&
    displayedTabs?.map((tab) => tabPanelMapping[tab.name]);

  const googleSeoNoAccessSub = `Enhance your SEO strategy by keeping a pulse on visitor traffic, conversions to measure goal achievements, clicks to assess engagement, and keep an eye on keyword trends to optimize content and stay ahead in search rankings.`;
  const googleAdsNoAccessSub = `Maximize your advertising impact with Google Ads. Keep track and measure ad engagement, analyse conversions for tracking customer actions, and evaluate the effectiveness of your ads in turning clicks into results. These metrics are key to refining your campaigns and improving ROI.`;
  const googleMetaNoAccessSub = `Fine-tune your advertising strategy on Meta platforms. Scrutinise spend for budget allocation efficiency, zero in on your customers to understand their engagement from your ads, and track the volume of potential customers your campaigns are attracting. Use these insights to optimise ad spend and campaign tactics.`;
  const googleMicrosoftAccessSub = `Enhance your advertising strategy by monitoring visitor traffic, tracking conversions to measure goal success, analyzing clicks to gauge engagement, and staying updated on keyword trends to optimize your campaigns and maximize your ad performance.`;

  return (
    <div className="px-1 font-inter">
      <Card className="card-item bg-[#009EFF] dark:bg-[#222732] p-3 sm:px-6 w-full">
        {renderHeading()}

        {/* This is for the mobile view */}
        <MobileView
          service={service}
          setService={setService}
          isLeadGen={isLeadGen}
          isGoogleSeo={isGoogleSeo}
          isMicrosoftAds={isMicrosoftAds}
          topPerformanceBing={topPerformanceBing}
          organic_sessions={organic_sessions}
          topPerformanceSeo={topPerformanceSeo}
          requestGoogleSEOAccess={requestGoogleSEOAccess}
          selectedClient={selectedClient}
          organic_totalRevenue={organic_totalRevenue}
          lastMonthOrganicSession={lastMonthOrganicSessions}
          organic_conversions_breakdown={organic_conversions_breakdown}
          lastMonthTotalOrganicConversionsBreakdown={
            lastMonthTotalOrganicConversionsBreakdown
          }
          clicks={clicks}
          lastMonthClicks={lastMonthClicks}
          retryFetchGA4Analytics={retryFetchGA4Analytics}
          isGoogleAds={isGoogleAds}
          adsCostMicros={adsCostMicros}
          topPerformanceAds={topPerformanceAds}
          lastMonthAdsCostMicros={lastMonthAdsCostMicros}
          adsClicks={adsClicks}
          lastMonthAdsClicks={lastMonthAdsClicks}
          adsConversions={adsConversions}
          lastMonthAdsConversions={lastMonthAdsConversions}
          requestGoogleAdsAccess={requestGoogleAdsAccess}
          adsCostPerConversion={adsCostPerConversion}
          lastMonthAdsCostPerConversion={lastMonthAdsCostPerConversion}
          adsRevenue={adsRevenue}
          lastMonthAdsRevenue={lastMonthAdsRevenue}
          adsRoas={adsRoas}
          lastMonthAdsRoas={lastMonthAdsRoas}
          isMeta={isMeta}
          topPerformanceMeta={topPerformanceMeta}
          requestMetaAccess={requestMetaAccess}
          spend={spend}
          lastMonthMetaSpend={lastMonthMetaSpend}
          metaClicks={metaClicks}
          lastMonthMetaClicks={lastMonthMetaClicks}
          metaConversions={metaConversions}
          lastMonthMetaConversions={lastMonthMetaConversions}
          metaCostPerConversion={metaCostPerConversion}
          lastMonthMetaCostPerConversion={lastMonthMetaCostPerConversion}
          revenue={revenue}
          lastMonthMetaRevenue={lastMonthMetaRevenue}
          roas={roas}
          lastMonthMetaROAS={lastMonthMetaROAS}
        />
        {/* End for mobile view */}

        <TabGroup className="hidden sm:block">
          <TabList
            className="mt-4 hidden sm:block w-full bg-[#009EFF] dark:bg-[#222732] block sm:flex gap-4 text-slate-100"
            variant="solid"
            color="000"
          >
            {(typeof displayedTabs !== 'boolean' ? displayedTabs : tabs).map(
              (tab) => (
                <Tab
                  tabIndex={tab.tabIndex}
                  key={tab.tabIndex}
                  className={
                    tab.tabIndex === activeTabIndex && mode === 'dark'
                      ? 'text-[#000] font-[800] text-[17px] w-full px-0 mx-0 sm:w-1/5 flex justify-center py-2 border-none bg-gradient-to-b from-gray-700 to-transparent bg-opacity-70'
                      : tab.tabIndex === activeTabIndex
                        ? 'text-[#000] font-[800] text-[17px] w-full px-0 mx-0 sm:w-1/5 flex justify-center py-2 border-none bg-gradient-to-b from-white via-white to-[#009EFF]'
                        : mode === 'dark'
                          ? 'text-[#CCCCCC] font-[800] text-[17px] w-full px-0 mx-0 sm:w-1/5 flex justify-center py-2 border-none bg-gradient-to-b from-gray-700 via-gray-700 to-transparent bg-opacity-16'
                          : 'text-[#001C44] font-[800] text-[17px] w-full px-0 mx-0 sm:w-1/5 flex justify-center py-2 bg-gradient-to-b from-white bg-opacity-2 to-white/2 border-none'
                  }
                  onClick={() => setActiveTabIndex(tab.tabIndex)}
                >
                  <div className="flex gap-2 items-center">
                    <Icon
                      name={tab.icon}
                      size={18}
                      color={mode === 'dark' ? '#fff' : '#000'}
                    />
                    {tab.tabName}
                  </div>
                </Tab>
              )
            )}
          </TabList>

          <div className="flex justify-between w-full items-center">
            <div className="text-[#FFF] text-sm py-2 sm:py-8  w-full sm:w-[60%] font-inter font-[300]">
              {activeTabIndex === 1
                ? googleSeoNoAccessSub
                : activeTabIndex === 2
                  ? googleAdsNoAccessSub
                  : activeTabIndex === 3
                    ? googleMetaNoAccessSub
                      : activeTabIndex === 4
                        ? googleMicrosoftAccessSub
                        : ''}
            </div>
            <Link
              to={
                activeTabIndex === 1
                  ? '/analytics/seo-ga4'
                  : activeTabIndex === 2
                    ? '/analytics/paid-search'
                    : activeTabIndex === 3
                      ? '/analytics/fb-ads'
                      : activeTabIndex === 4 
                        ? '/analytics/microsoft-ads'
                        : ''
              }
              className="text-white flex gap-2 items-center"
            >
              <div>View More</div>
              <Icon name="arrowRight" color="#fff" />
            </Link>
          </div>

          <TabPanels>
            {sortedTabPanels}
            {/* <TabPanel>
              <KPISeo
                isLeadGen={isLeadGen}
                isGoogleSeo={isGoogleSeo}
                topPerformanceSeo={topPerformanceSeo}
                requestGoogleSEOAccess={requestGoogleSEOAccess}
                selectedClient={selectedClient}
                retryFetchGA4Analytics={retryFetchGA4Analytics}
              />
            </TabPanel>
            <TabPanel>
              <KPIGa4
                isLeadGen={isLeadGen}
                isGoogleAds={isGoogleAds}
                selectedClient={selectedClient}
                topPerformanceAds={topPerformanceAds}
                requestGoogleAdsAccess={requestGoogleAdsAccess}
              />
            </TabPanel>
            <TabPanel>
              <KPIMeta
                isLeadGen={isLeadGen}
                isMeta={isMeta}
                topPerformanceMeta={topPerformanceMeta}
                requestMetaAccess={requestMetaAccess}
                spend={spend}
                lastMonthMetaSpend={lastMonthMetaSpend}
                metaClicks={metaClicks}
                lastMonthMetaClicks={lastMonthMetaClicks}
                metaConversions={metaConversions}
                lastMonthMetaConversions={lastMonthMetaConversions}
                metaCostPerConversion={metaCostPerConversion}
                lastMonthMetaCostPerConversion={lastMonthMetaCostPerConversion}
                revenue={revenue}
                lastMonthMetaRevenue={lastMonthMetaRevenue}
                roas={roas}
                lastMonthMetaROAS={lastMonthMetaROAS}
              />
            </TabPanel> */}
          </TabPanels>
        </TabGroup>
      </Card>
      <CustomModal
        open={isRequestModal}
        onClose={() => setRequestModall(false)}
      >
        <RequestModal {...selectedService} />
      </CustomModal>
    </div>
  );
};

export default ChannelKPI;
