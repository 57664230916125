import React from 'react';

interface IconProps {
  color?: string;
}

const ZapLine: React.FC<IconProps> = ({ color }) => (
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.49935 12.8465H2.91602M5.41602 8.26318H1.66602M7.49935 3.67985H3.33268M14.166 0.763184L8.66899 8.45902C8.42568 8.79965 8.30403 8.96996 8.3093 9.11196C8.31388 9.23558 8.37316 9.35078 8.4711 9.42636C8.58358 9.51318 8.79288 9.51318 9.21147 9.51318H13.3327L12.4993 15.7632L17.9964 8.06734C18.2397 7.72672 18.3613 7.55641 18.3561 7.41441C18.3515 7.29078 18.2922 7.17559 18.1943 7.1C18.0818 7.01318 17.8725 7.01318 17.4539 7.01318H13.3327L14.166 0.763184Z"
      stroke={color || '#001C44'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ZapLine;
