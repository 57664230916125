import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const RoundOrangeX: React.FC<IconProps> = ({
  color = '#428B6E',
  size = 16,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 14.75C5.48438 14.75 3.1875 13.4375 1.92969 11.25C0.671875 9.08984 0.671875 6.4375 1.92969 4.25C3.1875 2.08984 5.48438 0.75 8 0.75C10.4883 0.75 12.7852 2.08984 14.043 4.25C15.3008 6.4375 15.3008 9.08984 14.043 11.25C12.7852 13.4375 10.4883 14.75 8 14.75ZM5.78516 5.53516C5.51172 5.80859 5.51172 6.21875 5.78516 6.46484L7.07031 7.75L5.78516 9.03516C5.51172 9.30859 5.51172 9.71875 5.78516 9.96484C6.03125 10.2383 6.44141 10.2383 6.6875 9.96484L7.97266 8.67969L9.25781 9.96484C9.53125 10.2383 9.94141 10.2383 10.1875 9.96484C10.4609 9.71875 10.4609 9.30859 10.1875 9.03516L8.90234 7.75L10.1875 6.46484C10.4609 6.21875 10.4609 5.80859 10.1875 5.53516C9.94141 5.28906 9.53125 5.28906 9.25781 5.53516L7.97266 6.82031L6.6875 5.53516C6.44141 5.28906 6.03125 5.28906 5.78516 5.53516Z"
      fill="#FFB545"
    />
  </svg>
);

export default RoundOrangeX;
