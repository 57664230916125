import React from 'react';
import useUtilityStore from '../../../store/useUtilityStore';
import DateRangeComp from '../../Calendar';
import useAuthStore from '../../../store/useAuthStore';
import { useLocation, Link } from 'react-router-dom';
import useClientStore from '../../../store/useClientStore';
import ClientSearchBar from '../../ClinetsSearchBar';
import ReactSelect from '../../ReactSelect';
import Icon from '../../../assets/icons/SvgComponent';
import ToolTipV2 from '../../TooltipV2';
import Tooltip from '../../HelpTooltip';
import DomainDropdown from '../../DomainDropdown';
import Tabs from '../InternalPageHeader/tabs';

interface PageHeaderProps {
  title?: string;
  subtitle?: string;
  hideDate?: boolean;
  strategy?: boolean;
  customClass?: string;
  type?: string;
  handleScroll: (id: string) => void;
  handleResetCache?: () => void;
  activeTab: {
    index: string;
    id: string;
    label: string;
  };
  setActiveTab: (selectedOption: any) => void;
}

interface UtilityStoreState {
  campaignReport: {
    label: string;
    id: number;
  };
  setCampaignReport: (selectedOption: any) => void;
  isHeaderHidden: boolean;
}

interface Domain {
  id: string;
  name: string;
}

const PublicInternalPageHeader: React.FC<PageHeaderProps> = ({
  title,
  handleResetCache,
  handleScroll,
  activeTab,
  setActiveTab,
  customClass,
  type,
}) => {
  const { campaignReport, setCampaignReport, isHeaderHidden } =
    useUtilityStore() as UtilityStoreState;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const activepublicTab = searchParams.get('ppage');

  const { selectedClient } = useClientStore((state) => state);
  const clientCategory = selectedClient?.category_type || '';
  // Define a type for each object in the data array
  const { selectedDomain, setDomain, user } = useAuthStore(
    (state) => state
  );

  // const isAdminLogin = user.role?.level === 'omg';
  const currentUser = user;
  const transformedData = currentUser?.clients?.map((item: Domain) => ({
    label: item.name,
    id: item.id,
  }));
  const clientName = selectedClient?.name;
  const defaultTitle = clientName + ' | ' + clientCategory;

  return (
    <>
      <div
        id="overview"
        className={`relative block lg:flex items-start justify-between  bg-[#D2EEFF] px-3 py-1 sm:p-3  sm:pt-4 ${customClass} font-inter dark:bg-[#0C111D] transition-opacity duration-500 ease-in-out ${isHeaderHidden ? 'opacity-0 sm:opacity-100' : 'opacity-100'}`}
      >
        <div className="w-full md:w-[16vw] lg:w-[30vw] mt-1 sm:mt-0">
          <div className="flex justify-between items-center sm:justify-start sm:gap-4 text-[26px] sm:text-3xl mb-2 sm:mb-4 font-[800] text-[#001C44] dark:text-white">
            <div>
              <Tooltip content={title || defaultTitle} position="right">
                <div className="max-w-[80vw] sm:max-w-[65vw] md:max-w-[15vw] lg:max-w-[40vw] truncate cursor-pointer ">
                  {title || defaultTitle}
                </div>
              </Tooltip>
            </div>
          </div>

        </div>
        <div className="w-full lg:w-2/3">
          <div
            className={`relative flex justify-between sm:flex-wrap lg:flex-nowrap  gap-2  sm:gap-0 lg:gap-3 w-full sm:justify-end mb-1 sm:mb-4`}
          >         
            {activepublicTab === 'paid-search' && (
              <div className="relative hidden sm:block">
                <div className="flex w-full justify-end">
                  <div className="w-[200px]">
                    <ReactSelect
                      options={[
                        {
                          label: 'Google Ads (Overall)',
                          value: 0,
                          id: 'overall',
                        },
                        {
                          label: 'Google Ads (Search)',
                          value: 2,
                          id: 'search',
                        },
                        {
                          label: 'Google Ads (Performance Max)',
                          value: 10,
                          id: 'performance_max',
                        },
                        {
                          label: 'Google Ads (Shopping)',
                          value: 4,
                          id: 'shopping',
                        },
                        {
                          label: 'Google Ads (Video)',
                          value: 6,
                          id: 'video',
                        },
                      ]}
                      value={campaignReport}
                      placeholder="All Roles"
                      handleOnChange={(selectedOption: any) => {
                        setCampaignReport(selectedOption);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            <div
              className={`${transformedData && transformedData.length > 1 ? '' : 'flex gap-[4px] sm:block'}`}
            >
              <DateRangeComp
                disabled={false}
                style={{
                  position: 'initial',
                  width: '100%',
                  minWidth: '120px',
                }}
              />
              {transformedData &&
                transformedData.length === 1 &&
                activepublicTab === 'paid-search' && (
                  <div className="block sm:hidden">
                    <div className="flex w-full justify-end">
                      <div className="w-full">
                        <ReactSelect
                          options={[
                            {
                              label: 'Google Ads (Overall)',
                              value: 0,
                              id: 'overall',
                            },
                            {
                              label: 'Google Ads (Search)',
                              value: 2,
                              id: 'search',
                            },
                            {
                              label: 'Google Ads (Performance Max)',
                              value: 10,
                              id: 'performance_max',
                            },
                            {
                              label: 'Google Ads (Shopping)',
                              value: 4,
                              id: 'shopping',
                            },
                            {
                              label: 'Google Ads (Video)',
                              value: 6,
                              id: 'video',
                            },
                          ]}
                          value={campaignReport}
                          placeholder="All Roles"
                          handleOnChange={(selectedOption: any) => {
                            setCampaignReport(selectedOption);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
            </div>
            <div
              onClick={handleResetCache}
              className="min-w-[120px] font-inter bg-[#0029FF] text-sm flex items-center justify-center gap-1 rounded-lg py-1.5 px-2 text-cyan-50 cursor-pointer"
            >
              <Icon name="Reset" size={16} color="#FFF" />
              Reset Cache
            </div>
          </div>
          {transformedData && transformedData.length > 1 && (
            <>
              {activepublicTab === 'paid-search' && (
                <div className="block sm:hidden">
                  <div className="flex w-full justify-end">
                    <div className="w-full">
                      <ReactSelect
                        options={[
                          {
                            label: 'Google Ads (Overall)',
                            value: 0,
                            id: 'overall',
                          },
                          {
                            label: 'Google Ads (Search)',
                            value: 2,
                            id: 'search',
                          },
                          {
                            label: 'Google Ads (Performance Max)',
                            value: 10,
                            id: 'performance_max',
                          },
                          {
                            label: 'Google Ads (Shopping)',
                            value: 4,
                            id: 'shopping',
                          },
                          {
                            label: 'Google Ads (Video)',
                            value: 6,
                            id: 'video',
                          },
                        ]}
                        value={campaignReport}
                        placeholder="All Roles"
                        handleOnChange={(selectedOption: any) => {
                          setCampaignReport(selectedOption);
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      
      <Tabs
        handleScroll={handleScroll}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
    </>
  );
};

export default PublicInternalPageHeader;
