import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const DashboardIcon: React.FC<IconProps> = ({ color = 'black', size = 80 }) => (
  <svg
    width={size}
    height={size + 5}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="home-line">
      <path
        id="Icon"
        d="M8 17.038H16M11.0177 2.80202L4.23539 8.07715C3.78202 8.42977 3.55534 8.60608 3.39203 8.82688C3.24737 9.02247 3.1396 9.24281 3.07403 9.47708C3 9.74154 3 10.0287 3 10.6031V17.838C3 18.9581 3 19.5182 3.21799 19.946C3.40973 20.3223 3.71569 20.6283 4.09202 20.82C4.51984 21.038 5.07989 21.038 6.2 21.038H17.8C18.9201 21.038 19.4802 21.038 19.908 20.82C20.2843 20.6283 20.5903 20.3223 20.782 19.946C21 19.5182 21 18.9581 21 17.838V10.6031C21 10.0287 21 9.74154 20.926 9.47708C20.8604 9.24281 20.7526 9.02247 20.608 8.82688C20.4447 8.60608 20.218 8.42977 19.7646 8.07715L12.9823 2.80202C12.631 2.52877 12.4553 2.39214 12.2613 2.33963C12.0902 2.29329 11.9098 2.29329 11.7387 2.33963C11.5447 2.39214 11.369 2.52877 11.0177 2.80202Z"
        stroke={color || '#334969'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default DashboardIcon;
