import { create } from 'zustand';
import Api from '../api';
import { devtools } from 'zustand/middleware';
const useFeedbackStore = create(
  devtools((set) => ({
    feedbacks: [],
    message: '',
    error: false,
    loading: false,
    errorMessages: '',
    fetchFeedbacks: async (selectedOption) => {
      set(() => ({ loading: true }));
      const response = await Api.Feedback.getFeedbacks({
        type: selectedOption,
      });
      set(() => ({ feedbacks: response, loading: false }));
    },

    createFeedback: async (data) => {
      set(() => ({
        loading: true,
        error: false,
        errorMessages: '',
      }));
      const response = await Api.Feedback.create(data);
      if (response[0]?.error) {
        set(() => ({
          loading: false,
          error: true,
          errorMessage: response[0]?.error,
        }));
        throw new Error(response[0]?.error);
      } else {
        set(() => ({
          message: 'test feedback',
          loading: false,
          error: false,
          errorMessages: '',
        }));
      }
    },
  }))
);

export default useFeedbackStore;
