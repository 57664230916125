import React from 'react';

interface TrashProps {
  color?: string;
}

const Trash04: React.FC<TrashProps> = ({ color = '#428B6E' }) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 3.4502H12.5M2.5 5.9502H17.5M15.8333 5.9502L15.2489 14.7163C15.1612 16.0315 15.1174 16.6891 14.8333 17.1877C14.5833 17.6267 14.206 17.9796 13.7514 18.1999C13.235 18.4502 12.5759 18.4502 11.2578 18.4502H8.74221C7.42409 18.4502 6.76503 18.4502 6.24861 18.1999C5.79396 17.9796 5.41674 17.6267 5.16665 17.1877C4.88259 16.6891 4.83875 16.0315 4.75107 14.7163L4.16667 5.9502"
      stroke="#7C7E7F"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Trash04;
