import { useSwiper } from 'swiper/react';

interface IProps {
  prev?: boolean;
  next?: boolean;
  children?: React.ReactNode;
}

const SwiperButtonNext = ({ prev, next, children }: IProps) => {
  const swiper = useSwiper();
  return (
    <button
      className={`${next ? 'absolute top-1/2 right-[-18px] lg:right-[-8px] z-[99] transform -translate-y-1/2' : 'absolute top-1/2 left-[-18px] lg:left-[-8px] z-[99] transform -translate-y-1/2'}`}
      onClick={() => {
        if (next) swiper.slideNext();
        if (prev) swiper.slidePrev();
      }}
    >
      {children}
    </button>
  );
};

export default SwiperButtonNext;
