import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@tremor/react';
import DatePicker from 'react-datepicker';
import AsyncSelect from 'react-select/async';
import { getStatusBadgeColor } from '../../../utils/common';
import { toast } from 'react-toastify';
import Alert from '../../Alert';

import ReactSelect from '../../ReactSelect';
import { useThemeStore } from '../../../store/useThemeStore';
import Icon from '../../../assets/icons/SvgComponent';
import useClientStore from '../../../store/useClientStore';
import useGoalStore from '../../../store/useGoalStore';
import { StylesConfig } from 'react-select';
import moment from 'moment';
import { Goal } from '../../../api/goal';
import 'react-datepicker/dist/react-datepicker.css';
import {
  capitalized,
  frequency,
  getStatusMetric,
  metricSourceOptions,
  getMetricSourceOptions,
} from '../../../pages/Goals/constants';
import CustomModal from '../../CustomModal';
import ConfirmationArchiveModal from './ConfirmationArchive';

interface IProps {
  selectedGoal?: any;
  onClose?: any;
  setGoal?: () => void;
  handleFilterChange?: () => void;
}

const CreateGoalsModal: React.FC<IProps> = ({
  selectedGoal,
  onClose,
  setGoal,
  handleFilterChange,
}) => {
  const currentDate = moment();
  const currYear = currentDate.year();
  // const { selectedDomain, setDomain, user } = useAuthStore(
  //   (state) => state
  // );

  const { archiveGoal, syncGoal, syncLoading } = useGoalStore((state) => state);
  const [openArchiveModal, setOpenArchiveModal] = useState(false);
  const { mode } = useThemeStore((state: any) => state);
  const { fetchClients, clients } = useClientStore((state) => state);

  const today = new Date();
  // const [date, setDate] = useState(today);
  // const [formData, setFormData] = useState<any>({});
  const [currentYear, setCurrentYear] = useState<any>(currYear);

  const iconColor = mode === 'dark' ? 'white' : 'black';
  // const [ data, setData ] = useState({})
  const [goalStartDate, setGoalStartDate] = useState(today);
  const [goalEndDate, setGoalEndDate] = useState<any>(
    moment(today).add(7, 'days')
  );
  const [goalName, setGoalName] = useState(null);
  const [goalValue, setGoalValue] = useState(null);
  const [selectedMetric, setSelectedMetric] = useState<{
    label: string;
    value: string;
  }>({
    label: 'Select Metric',
    value: '',
  });
  const [selectedSourceMetric, setSelectedSourceMetric] = useState<{
    label: string;
    value: string;
  }>({
    label: 'Select Source',
    value: '',
  });
  const [frequencyType, setFrequencyType] = useState<{
    label: string;
    value: string;
  }>({
    label: 'Select Range',
    value: '',
  });
  const [basicFrequencyType, setBasicFrequencyType] = useState([]);
  const [advancedFrequencyType, setAdvancedFrequencyType] = useState('weekly');
  const [advancedFrequencyValues, setAdvancedFrequencyValues] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [alertGoalReached, setAlertGoalReached] = useState(false);
  const [alertGoalNotHit, setAlertGoalNotHit] = useState(false);
  const [showtoClient, setShowtoClient] = useState(false);

  const [client_id, setClientId] = useState('');
  const debounceTimer = useRef<NodeJS.Timeout | null>(null);
  const [lastQuery, setLastQuery] = useState('');

  const handleSyncGoal = async (data) => {
    const payload = {
      frequencyIds: data,
    };
    await syncGoal(payload);
  };

  const loadOptions = useCallback(
    (inputValue: string, callback: (options: any) => void) => {
      if (inputValue.length > 2 && inputValue !== lastQuery) {
        setLastQuery(inputValue);

        const filters = {
          status: 'all',
          search: inputValue,
          page: 1,
          limit: 100,
        };

        if (debounceTimer.current) {
          clearTimeout(debounceTimer.current);
        }

        debounceTimer.current = setTimeout(() => {
          fetchClients(filters)
            .then((response: any) => {
              const options =
                response?.data?.map((item: any) => ({
                  label: item.name,
                  value: item.id,
                  id: item.id,
                  cycle: item.cycle,
                })) || [];
              callback(options);
            })
            .catch((error: any) => {
              console.error('Error fetching clients:', error);
              callback([]);
            });
        }, 500); // 500ms debounce time
      } else {
        callback([]);
      }
    },
    [lastQuery, fetchClients]
  );

  const customStyles: StylesConfig<any, boolean, any> = {
    control: (provided) => ({
      ...provided,
      ...{
        borderRadius: '8px',
        border: '1px solid #D1D5DB',
        opacity: 1,
        cursor: 'pointer',
        fontSize: '14px',
        scrollbarWidth: 'thin',
      },
    }),
    option: (provided, state) => {
      return {
        ...provided,
        color: state.data.isDisabled ? '#6D8080' : '',
        fontSize: '14px',
        cursor: 'pointer',
      };
    },
    menuPortal: (provided) => ({ ...provided, zIndex: 1000 }),
  };

  const calculateWeeks = (start, end, value?: number | string) => {
    const weeksArray = [];
    const totalDays = Math.ceil((end - start) / (1000 * 60 * 60 * 24));
    const totalWeeks = Math.ceil(totalDays / 7);

    for (let i = 0; i < totalWeeks; i++) {
      const currentWeekStart = new Date(start);
      currentWeekStart.setDate(start.getDate() + i * 7);

      const currentWeekEnd = new Date(currentWeekStart);
      currentWeekEnd.setDate(currentWeekStart.getDate() + 6);
      const weekLabel = `W${(i + 1).toString().padStart(2, '0')}`;
      weeksArray.push({
        label: weekLabel,
        type: 'weekly',
        goalValue: value
          ? value
          : selectedGoal?.frequencyDateType === 'advanced_setup'
            ? selectedGoal?.frequency?.filter((f) => f.label === weekLabel)[0]
                ?.goalValue
            : '0',
        currentValue:
          selectedGoal?.frequency?.filter((f) => f.label === weekLabel)[0]
            ?.currentValue || '0',
        targetDate: moment(new Date(currentWeekEnd)).format('YYYY-MM-DD'),
        status: 'not-started',
      });
    }
    return weeksArray;
  };

  const calculateMonths = (startDate, endDate, value?: number | string) => {
    const monthsArray = [];
    const startMonth = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      1
    );
    const endMonth = new Date(endDate.getFullYear(), endDate.getMonth(), 1);

    const totalMonths =
      (endMonth.getFullYear() - startMonth.getFullYear()) * 12 +
      (endMonth.getMonth() - startMonth.getMonth());

    for (let i = 0; i <= totalMonths; i++) {
      const currentDate = new Date(startMonth);
      currentDate.setMonth(startMonth.getMonth() + i);

      monthsArray.push({
        label: `${moment(currentDate).format('MMMM')} ${moment(currentDate).year()}`,
        type: 'monthly',
        goalValue: value ? value : '',
        currentValue: '0',
        targetDate: moment(currentDate).endOf('month').format('YYYY-MM-DD'),
        status: 'not-started',
      });
    }
    return monthsArray;
  };

  const calculateQuarters = (startDate, endDate, value?: number | string) => {
    const quartersArray = [];
    const startMonth = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      1
    );
    const endMonth = new Date(endDate.getFullYear(), endDate.getMonth(), 1);

    const totalMonths =
      (endMonth.getFullYear() - startMonth.getFullYear()) * 12 +
      (endMonth.getMonth() - startMonth.getMonth());

    let previousQuarter = null;

    for (let i = 0; i <= totalMonths; i++) {
      const currentDate = new Date(startMonth);
      currentDate.setMonth(startMonth.getMonth() + i);

      const month = currentDate.getMonth() + 1;
      const year = currentDate.getFullYear();
      const currentQuarter = getQuarter(month);

      if (currentQuarter !== previousQuarter) {
        quartersArray.push({
          label: `${currentQuarter} ${year}`,
          type: 'quarterly',
          goalValue: value ? value : '',
          currentValue: '0',
          targetDate: moment(currentDate).endOf('quarter').format('YYYY-MM-DD'),
          status: 'not-started',
        });
        previousQuarter = currentQuarter;
      }
    }

    return quartersArray;
  };

  const adjustForNewStartEndDate = (
    dataArray,
    newStartDate,
    newEndDate,
    type
  ) => {
    const startDate = moment(newStartDate);
    const endDate = moment(newEndDate);

    // Filter out intervals that are before the new start date or after the new end date
    let updatedData = dataArray.filter((entry) =>
      moment(entry.targetDate).isBetween(startDate, endDate, null, '[]')
    );

    // Get the last date of the month
    const getLastDayOfMonth = (year, month) =>
      moment().year(year).month(month).endOf('month');

    // Get first and last date of the quarter
    const getQuarterRange = (date) => {
      const quarterStartMonth = Math.floor(date.month() / 3) * 3;
      const quarterStart = moment(date).startOf('quarter');
      const quarterEnd = moment(date).endOf('quarter');
      return { start: quarterStart, end: quarterEnd };
    };

    // Generate missing intervals based on the type
    const generatedIntervals = [];

    if (type === 'monthly') {
      // Generate months between start and end dates
      for (
        let currentDate = moment(startDate);
        currentDate.isSameOrBefore(endDate);
        currentDate.add(1, 'month')
      ) {
        const year = currentDate.year();
        const month = currentDate.month();
        const monthLabel = currentDate.format('MMMM YYYY');

        // Check if this month already exists
        const existingMonth = updatedData.find((entry) =>
          entry.label.includes(monthLabel)
        );
        if (!existingMonth) {
          const newMonthEntry = {
            label: monthLabel,
            type: 'monthly',
            goalValue:
              selectedGoal?.frequencyDateType === 'basic_setup'
                ? goalValue
                : '0',
            currentValue: '0',
            targetDate: getLastDayOfMonth(year, month).format('YYYY-MM-DD'),
            status: 'not-started',
          };
          generatedIntervals.push(newMonthEntry);
        }
      }
    } else if (type === 'quarterly') {
      // Generate quarters between start and end dates
      for (
        let currentDate = moment(startDate);
        currentDate.isSameOrBefore(endDate);

      ) {
        const { start: quarterStart, end: quarterEnd } =
          getQuarterRange(currentDate);
        const quarterLabel = `Q${Math.floor(currentDate.month() / 3) + 1} ${currentDate.year()}`;

        // Check if this quarter already exists
        const existingQuarter = updatedData.find((entry) =>
          entry.label.includes(quarterLabel)
        );
        if (!existingQuarter) {
          const newQuarterEntry = {
            label: quarterLabel,
            type: 'quarterly',
            goalValue:
              selectedGoal?.frequencyDateType === 'basic_setup'
                ? goalValue
                : '0',
            currentValue: '0',
            targetDate: quarterEnd.format('YYYY-MM-DD'),
            status: 'not-started',
          };
          generatedIntervals.push(newQuarterEntry);
        }
        currentDate = quarterEnd.add(1, 'days');
      }
    }
    const combinedData = [...updatedData, ...generatedIntervals];
    // sort base on date
    combinedData.sort((a, b) =>
      moment(a.targetDate).diff(moment(b.targetDate))
    );

    return combinedData;
  };

  useEffect(() => {
    if (!selectedGoal?.id && frequencyType.value === 'advanced_setup') {
      if (advancedFrequencyType === 'weekly') {
        setAdvancedFrequencyValues(calculateWeeks(goalStartDate, goalEndDate));
      }
      if (!selectedGoal?.id && advancedFrequencyType === 'monthly') {
        setAdvancedFrequencyValues(
          calculateMonths(new Date(goalStartDate), new Date(goalEndDate))
        );
      }
      if (!selectedGoal?.id && advancedFrequencyType === 'quarterly') {
        setAdvancedFrequencyValues(
          calculateQuarters(new Date(goalStartDate), new Date(goalEndDate))
        );
      }
    }
  }, [
    goalStartDate,
    goalEndDate,
    advancedFrequencyType,
    frequencyType,
    selectedGoal,
  ]);

  useEffect(() => {
    if (!selectedGoal?.id && frequencyType.value !== 'advanced_setup') {
      if (frequencyType.value === 'weekly') {
        setBasicFrequencyType(
          calculateWeeks(goalStartDate, goalEndDate, goalValue)
        );
      }
      if (frequencyType.value === 'monthly') {
        setBasicFrequencyType(
          calculateMonths(
            new Date(goalStartDate),
            new Date(goalEndDate),
            goalValue
          )
        );
      }
      if (frequencyType.value === 'quarterly') {
        setBasicFrequencyType(
          calculateQuarters(
            new Date(goalStartDate),
            new Date(goalEndDate),
            goalValue
          )
        );
      }
    }
  }, [goalStartDate, goalEndDate, goalValue, frequencyType?.value]);

  const handleAdvancedFrequencyValues = (index, newValue) => {
    // Remove commas from the newValue
    const formattedValue = newValue.replace(/,/g, '');

    // Convert to a number
    const numericValue = parseFloat(formattedValue);

    // Check if the conversion was successful
    if (!isNaN(numericValue)) {
      const updatedValues = advancedFrequencyValues.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            goalValue: numericValue,
          };
        }
        return item;
      });
      setAdvancedFrequencyValues(updatedValues);
    } else {
      const updatedValues = advancedFrequencyValues.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            goalValue: '',
          };
        }
        return item;
      });
      setAdvancedFrequencyValues(updatedValues);
      console.error('Invalid number format:', newValue);
    }
  };

  // Function to handle change and format the value
  const handleGoalValueChange = (e) => {
    // Remove commas from the input value
    const cleanedValue = e.target.value.replace(/,/g, '');

    // Check if the cleaned value is a valid number
    if (!isNaN(cleanedValue) && cleanedValue.trim() !== '') {
      if (selectedGoal?.id) {
        setGoalValue(cleanedValue);
        if (selectedGoal?.frequencyType === 'weekly') {
          setBasicFrequencyType(
            calculateWeeks(goalStartDate, goalEndDate, cleanedValue)
          );
        }
        if (selectedGoal?.frequencyType === 'monthly') {
          setBasicFrequencyType(
            calculateMonths(
              new Date(goalStartDate),
              new Date(goalEndDate),
              cleanedValue
            )
          );
        }
        if (selectedGoal?.frequencyType === 'quarterly') {
          setBasicFrequencyType(
            calculateQuarters(
              new Date(goalStartDate),
              new Date(goalEndDate),
              cleanedValue
            )
          );
        }
      } else {
        setGoalValue(cleanedValue);
      }
    } else {
      // Handle invalid input if necessary
      // You might want to clear the field or show an error
      setGoalValue('');
    }
  };

  // Function to format the value with commas for display
  const formatGoalValue = (value) => {
    if (!value) return '';
    // Convert value to number and format with commas
    return Number(value).toLocaleString();
  };

  useEffect(() => {
    if (selectedGoal?.id) {
      // source
      setSelectedSourceMetric({
        label: metricSourceOptions.filter(
          (s) => s.value === selectedGoal?.source
        )[0]?.label,
        value: selectedGoal?.source,
      });
      // metric
      setSelectedMetric({
        label: getStatusMetric(selectedGoal?.metric),
        value: selectedGoal?.metric,
      });
      // name
      setGoalName(selectedGoal?.name || '');
      // start date
      setGoalStartDate(new Date(selectedGoal?.goalStartDate) || today);
      setGoalEndDate(new Date(selectedGoal?.goalEndDate) || today);

      setAlertGoalReached(selectedGoal?.alertGoalReached);
      setAlertGoalNotHit(selectedGoal?.alertGoalNotHit);
      setShowtoClient(selectedGoal?.showtoClient);

      setClientId(selectedGoal?.client_id);

      if (selectedGoal?.frequencyDateType !== 'advanced_setup') {
        setAdvancedFrequencyType(selectedGoal?.frequency[0]?.type);
        setGoalValue(selectedGoal?.frequency[0]?.goalValue);
        setFrequencyType({
          label: capitalized(selectedGoal?.frequency[0]?.type),
          value: selectedGoal?.frequency[0]?.type,
        });
        setBasicFrequencyType(selectedGoal?.frequency);
      } else {
        setFrequencyType({
          label: '',
          value: 'advanced_setup',
        });
        setAdvancedFrequencyType(selectedGoal?.frequencyType);
        setAdvancedFrequencyValues(selectedGoal?.frequency);
      }
    }
  }, [selectedGoal]);

  const createGoal = async () => {
    setSubmit(true);

    // Check if all required fields are filled
    const hasGoalValue = advancedFrequencyValues.every(
      (item) => item.goalValue !== '' && Number(item.goalValue) > 0
    );
    const checkValues =
      frequencyType.value === 'advanced_setup'
        ? advancedFrequencyValues
        : basicFrequencyType;

    if (
      goalName &&
      client_id &&
      selectedMetric?.value &&
      frequencyType.value &&
      goalStartDate &&
      goalEndDate &&
      checkValues?.length > 0 &&
      ((frequencyType.value !== 'advanced_setup' &&
        basicFrequencyType[0]?.goalValue &&
        basicFrequencyType[0]?.goalValue > 0) ||
        (frequencyType.value === 'advanced_setup' && hasGoalValue))
    ) {
      const data = {
        name: goalName,
        client_id,
        goalStartDate: moment(goalStartDate).format('YYYY-MM-DD'),
        goalEndDate:
          frequencyType.value === 'advanced_setup'
            ? advancedFrequencyValues[advancedFrequencyValues.length - 1]
                ?.targetDate
            : moment(goalEndDate).format('YYYY-MM-DD'),
        metric: selectedMetric?.value,
        source: selectedSourceMetric?.value,
        frequencyDateType:
          frequencyType.value === 'advanced_setup'
            ? 'advanced_setup'
            : 'basic_setup',
        frequencyType:
          frequencyType.value === 'advanced_setup'
            ? advancedFrequencyType
            : frequencyType.value,
        frequency:
          frequencyType.value === 'advanced_setup'
            ? advancedFrequencyValues
            : basicFrequencyType,
        alertGoalReached,
        alertGoalNotHit,
        showtoClient,
      };
      console.log(data, 'data');
      try {
        if (selectedGoal?.id) {
          delete data.client_id;
          delete data.frequencyDateType;
          const update = await Goal.update(selectedGoal?.id, data);
          if (update) {
            setGoal();
            handleFilterChange();
            toast.success(
              <Alert
                icon=""
                title="Goal Update"
                message="Goal has been updated successfully"
              />,
              {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }
        } else {
          const result = await Goal.create(data);
          if (result) {
            setGoal();
            handleFilterChange();
            toast.success(
              <Alert
                icon=""
                title="Goal Created"
                message="Goal has been created successfully"
              />,
              {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }
        }
      } catch (error) {
        toast.error(
          <Alert
            icon=""
            title="Error"
            message="An error occurred while creating or updating the goal."
          />,
          {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    } else {
      toast.error(
        <Alert
          icon=""
          title="Form Incomplete"
          message="Please fill in all required fields."
        />,
        {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  // Utility function to get quarter
  const getQuarter = (month) => {
    // const month = moment(date).month();
    // return Math.floor(month / 3) + 1;
    if (month >= 1 && month <= 3) return 'Q1';
    if (month >= 4 && month <= 6) return 'Q2';
    if (month >= 7 && month <= 9) return 'Q3';
    return 'Q4';
  };

  const handleArchiveGoal = async () => {
    try {
      await archiveGoal(selectedGoal?.id);
      setOpenArchiveModal(false);
      onClose();
      handleFilterChange();
      toast.success(
        <Alert
          icon=""
          title="Archive"
          message="Goal has been moved to archived successfully"
        />,
        {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } catch (error) {
      toast.error(<Alert icon="" title="Error" message={error} />, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // Function to set the start date to the nearest Sunday
  const getNearestSunday = (date) => {
    const selectedDate = new Date(date);
    const dayOfWeek = selectedDate.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday

    // Calculate days to add to get to the next Sunday
    const daysToAdd = (7 - dayOfWeek) % 7;

    // If the selected date is already Sunday, return it
    if (daysToAdd === 0) {
      return selectedDate;
    }

    // Set the date to the next Sunday
    selectedDate.setDate(selectedDate.getDate() + daysToAdd);
    return selectedDate;
  };

  // Function to set the end date to the nearest Saturday
  const getNearestSaturday = (date) => {
    const selectedDate = new Date(date);
    selectedDate.setDate(selectedDate.getDate() + 6); // Add 6 days to Sunday to get Saturday
    return selectedDate;
  };

  const dateFormat =
    frequencyType.value === 'quarterly' ? 'yyyy, QQQ' : 'MM/yyyy';
  const dateFormatAdvance =
    advancedFrequencyType === 'quarterly' ? 'yyyy, QQQ' : 'MM/yyyy';

  return (
    <div className="h-screen w-screen flex justify-end">
      <div
        className="bg-white dark:bg-gray-900 relative w-screen sm:w-[40dvw] p-4 sm:p-6  text-[#001C44] flex flex-col justify-start"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center border-b pb-3">
          <h1 className="text-[24px] font-[700] dark:text-white">
            {selectedGoal?.id ? 'Edit Goal' : 'Goals Setup'}
          </h1>
          <div className="block sm:hidden cursor-pointer" onClick={onClose}>
            <Icon name="Xclose" />
          </div>
        </div>
        <TabGroup className="">
          {selectedGoal?.id ? (
            <div className="flex justify-between items-center w-full items-center mb-4 mt-4">
              <TabList variant="solid">
                <Tab>Details</Tab>
                <Tab disabled={selectedGoal?.id ? false : true}>History</Tab>
              </TabList>
              <div
                className="pr-4 cursor-pointer"
                onClick={() => setOpenArchiveModal(true)}
              >
                <Icon name="Archive" />
              </div>
            </div>
          ) : null}
          <TabPanels>
            <TabPanel>
              <div className="p-0 sm:p-2 h-full flex flex-col justify-between">
                <div className="max-h-[80dvh] sm:max-h-[73dvh] overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8">
                  <div className="relative">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Client Name
                    </label>
                    <AsyncSelect
                      className="my-react-select-container"
                      classNamePrefix="my-react-select"
                      placeholder={
                        selectedGoal?.client_id?.name ?? 'Search client'
                      }
                      isDisabled={selectedGoal?.id ? true : false}
                      cacheOptions
                      loadOptions={loadOptions}
                      menuPortalTarget={document.body}
                      styles={customStyles}
                      defaultOptions
                      onChange={(e) => setClientId(e.id)}
                      noOptionsMessage={() => 'Type 3+ letters to search'}
                    />
                    {submit && !client_id && (
                      <span className="text-sm text-red-500">
                        Client is required
                      </span>
                    )}
                  </div>
                  <div className="my-6">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Goal Title
                    </label>
                    <input
                      type="text"
                      value={goalName}
                      onChange={(e) => setGoalName(e.target.value)}
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                    {submit && !goalName && (
                      <span className="text-sm text-red-500">
                        Goal title is required
                      </span>
                    )}
                  </div>
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Set Metric
                    </label>
                    <div className="flex w-full gap-2 items-center mb-2">
                      <div className="w-full">
                        <ReactSelect
                          options={metricSourceOptions}
                          value={selectedSourceMetric}
                          isDisabled={selectedGoal?.id ? true : false}
                          handleOnChange={(selectedOption: any) => {
                            setSelectedSourceMetric(selectedOption);
                            setSelectedMetric(null);
                          }}
                        />
                      </div>
                      <div className="w-full">
                        <ReactSelect
                          options={getMetricSourceOptions(
                            selectedSourceMetric?.value
                          )}
                          value={selectedMetric}
                          isDisabled={selectedGoal?.id ? true : false}
                          handleOnChange={(selectedOption: any) => {
                            setSelectedMetric(selectedOption);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {frequencyType.value === 'advanced_setup' ? (
                    <>
                      <div className="border border-[#808EA2] rounded-md py-2 px-4 flex flex-row justify-between items-center">
                        <span className="text-sm text-[#334969]">
                          Set Goal Values
                        </span>
                        <div>
                          <select
                            defaultValue={advancedFrequencyType}
                            onChange={(e) => {
                              setAdvancedFrequencyType(e.target.value);
                              if (e.target.value === 'weekly') {
                                const sundayDate = getNearestSunday(today);
                                setGoalStartDate(sundayDate);
                                setGoalEndDate(
                                  moment(sundayDate).add(6, 'days')
                                );
                              }
                              if (e.target.value === 'monthly') {
                                const start = moment(today)
                                  .startOf('month')
                                  .format('lll');
                                setGoalStartDate(new Date(start));
                                setGoalEndDate(moment(start).endOf('month'));
                              }
                            }}
                            disabled={selectedGoal?.id ? true : false}
                            className="rounded-md bg-[#D0EDFF] text-sm p-1"
                          >
                            <option value="weekly">Weekly</option>
                            <option value="monthly">Monthly</option>
                            <option value="quarterly">Quarterly</option>
                          </select>
                        </div>
                      </div>

                      <div className="my-6 flex flex-row justify-start items-center gap-4 w-full">
                        <div className="w-full sm:w-1/2">
                          <label className="block text-sm font-medium text-gray-900 dark:text-white">
                            Start Date
                          </label>
                          {advancedFrequencyType === 'weekly' ? (
                            <>
                              {/* Advance weekly */}
                              <DatePicker
                                selected={moment(
                                  goalStartDate,
                                  'YYYY-MM-DD'
                                ).toDate()}
                                minDate={today}
                                onChange={(date) => {
                                  if (date !== null) {
                                    setGoalStartDate(date);
                                    setAdvancedFrequencyValues(
                                      calculateWeeks(date, goalEndDate)
                                    );
                                  }
                                }}
                                filterDate={(date) => date.getDay() === 0}
                                className="w-full sm:w-[22.3vh] pl-2 react-datepicker__month-container text-left h-[38px] border border-[#D1D5DB] cursor-pointer dark:bg-[#374151] dark:text-white dark:border-none mt-2 font-inter rounded-lg text-[14px] text-[#525252]"
                              />
                            </>
                          ) : (
                            <>
                              {/* Advance monthly and quarterly */}
                              <DatePicker
                                selected={moment(
                                  goalStartDate,
                                  'YYYY-MM-DD'
                                ).toDate()}
                                minDate={today}
                                onChange={(date) => {
                                  if (date !== null) {
                                    if (selectedGoal?.id) {
                                      if (
                                        advancedFrequencyType === 'quarterly'
                                      ) {
                                        setAdvancedFrequencyValues(
                                          adjustForNewStartEndDate(
                                            advancedFrequencyValues,
                                            date,
                                            goalEndDate,
                                            'quarterly'
                                          )
                                        );
                                        setGoalStartDate(date);
                                      }
                                      if (advancedFrequencyType === 'monthly') {
                                        setAdvancedFrequencyValues(
                                          adjustForNewStartEndDate(
                                            advancedFrequencyValues,
                                            date,
                                            goalEndDate,
                                            'monthly'
                                          )
                                        );
                                        setGoalStartDate(date);
                                      }
                                    } else {
                                      setGoalStartDate(date);
                                    }
                                  }
                                }}
                                showMonthYearPicker={
                                  advancedFrequencyType === 'monthly'
                                }
                                showQuarterYearPicker={
                                  advancedFrequencyType === 'quarterly'
                                }
                                dateFormat={dateFormatAdvance}
                                className="w-full sm:w-[22.3vh] pl-2 react-datepicker__month-container text-left h-[38px] border border-[#D1D5DB] cursor-pointer dark:bg-[#374151] dark:text-white dark:border-none mt-2 font-inter rounded-lg text-[14px] text-[#525252]"
                              />
                            </>
                          )}
                        </div>
                        <div className="w-full sm:w-1/2">
                          <label className="block text-sm font-medium text-gray-900 dark:text-white">
                            End Date
                          </label>
                          {advancedFrequencyType === 'weekly' ? (
                            <>
                              {/* Advance end date weekly */}
                              <DatePicker
                                selected={moment(
                                  goalEndDate,
                                  'YYYY-MM-DD'
                                ).toDate()}
                                minDate={goalStartDate}
                                onChange={(date) => {
                                  if (date !== null) {
                                    setGoalEndDate(date);
                                    setAdvancedFrequencyValues(
                                      calculateWeeks(goalStartDate, date)
                                    );
                                  }
                                }}
                                filterDate={(date) => date.getDay() === 6}
                                className="w-full sm:w-[22.3vh] react-datepicker__month-container text-left pl-2 h-[38px] border border-[#D1D5DB] cursor-pointer  dark:bg-[#374151] dark:text-white dark:border-none mt-2 font-inter rounded-lg text-[14px] text-[#525252]"
                              />
                            </>
                          ) : (
                            <>
                              {/* Advance monthly and quarterly end date */}
                              <DatePicker
                                selected={moment(
                                  goalEndDate,
                                  'YYYY-MM-DD'
                                ).toDate()}
                                minDate={today}
                                startDate={goalStartDate}
                                onChange={(date) => {
                                  if (date !== null) {
                                    if (selectedGoal?.id) {
                                      if (
                                        advancedFrequencyType === 'quarterly'
                                      ) {
                                        setGoalEndDate(
                                          moment(date).endOf('quarter')
                                        );
                                        setAdvancedFrequencyValues(
                                          adjustForNewStartEndDate(
                                            advancedFrequencyValues,
                                            goalStartDate,
                                            moment(date).endOf('quarter'),
                                            'quarterly'
                                          )
                                        );
                                      }
                                      if (advancedFrequencyType === 'monthly') {
                                        setAdvancedFrequencyValues(
                                          adjustForNewStartEndDate(
                                            advancedFrequencyValues,
                                            goalStartDate,
                                            moment(date).endOf('month'),
                                            'monthly'
                                          )
                                        );
                                        setGoalEndDate(
                                          moment(date).endOf('month')
                                        );
                                      }
                                    } else {
                                      if (
                                        advancedFrequencyType === 'quarterly'
                                      ) {
                                        setGoalEndDate(
                                          moment(date).endOf('quarter')
                                        );
                                      }
                                      if (advancedFrequencyType === 'monthly') {
                                        setGoalEndDate(
                                          moment(date).endOf('month')
                                        );
                                      }
                                    }
                                  }
                                }}
                                showMonthYearPicker={
                                  advancedFrequencyType === 'monthly'
                                }
                                showQuarterYearPicker={
                                  advancedFrequencyType === 'quarterly'
                                }
                                dateFormat={dateFormatAdvance}
                                className="w-full sm:w-[22.3vh] react-datepicker__month-container text-left pl-2 h-[38px] border border-[#D1D5DB] cursor-pointer  dark:bg-[#374151] dark:text-white dark:border-none mt-2 font-inter rounded-lg text-[14px] text-[#525252]"
                              />
                            </>
                          )}
                        </div>
                        <div></div>
                      </div>

                      <div className="flex flex-col mt-6">
                        {advancedFrequencyValues?.length > 0 &&
                          advancedFrequencyValues.map((item, index) => {
                            return (
                              <div
                                key={item.label}
                                className="mb-4 flex flex-row justify-between items-center"
                              >
                                <div className="text-sm block w-full max-w-[120px] dark:text-white">
                                  {item.label}
                                </div>
                                <input
                                  type="text"
                                  placeholder={`Metric for ${item.label}`}
                                  value={item.goalValue}
                                  onChange={(e) =>
                                    handleAdvancedFrequencyValues(
                                      index,
                                      e.target.value
                                    )
                                  }
                                  className="w-full border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                />
                              </div>
                            );
                          })}
                        <div>
                          <button
                            onClick={() => {
                              setFrequencyType({
                                label: 'Weekly',
                                value: 'weekly',
                              });
                              setGoalEndDate(getNearestSaturday(goalStartDate));
                            }}
                            className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="">
                        <div className="flex w-full gap-2 items-center">
                          <div className="w-full">
                            <ReactSelect
                              options={[
                                ...frequency,
                                {
                                  label: 'Advanced Setup',
                                  value: 'advanced_setup',
                                },
                              ]}
                              value={frequencyType}
                              isDisabled={selectedGoal?.id ? true : false}
                              handleOnChange={(selectedOption: any) => {
                                if (selectedOption.value === 'quarterly') {
                                  setGoalEndDate(
                                    moment(goalStartDate).endOf('quarter')
                                  );
                                }
                                if (selectedOption.value === 'monthly') {
                                  setGoalEndDate(
                                    moment(goalStartDate).endOf('month')
                                  );
                                }
                                if (selectedOption.value === 'weekly') {
                                  const sundayDate = getNearestSunday(today);
                                  setGoalStartDate(sundayDate);
                                  setGoalEndDate(
                                    moment(sundayDate).add(6, 'days')
                                  );
                                }
                                if (selectedOption.value === 'advanced_setup') {
                                  const sundayDate = getNearestSunday(today);
                                  setGoalStartDate(sundayDate);
                                  setGoalEndDate(
                                    moment(sundayDate).add(6, 'days')
                                  );
                                }
                                setFrequencyType(selectedOption);
                              }}
                            />
                          </div>
                          <div className="w-full">
                            <input
                              type="text"
                              placeholder={`Metric for ${frequencyType?.value ? frequencyType?.value : '...'}`}
                              value={formatGoalValue(goalValue)}
                              onChange={handleGoalValueChange}
                              className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            />
                          </div>
                        </div>
                        {submit && !selectedMetric?.value && (
                          <span className="text-sm text-red-500">
                            Metric is required
                          </span>
                        )}
                      </div>
                      {/* Basic weekly */}
                      {frequencyType.value === 'weekly' && (
                        <div className="my-6 flex flex-row justify-start items-center gap-4 w-full">
                          <div className="w-full sm:w-1/2">
                            <label className="block text-sm font-medium text-gray-900 dark:text-white">
                              Start Date
                            </label>
                            <DatePicker
                              selected={moment(
                                goalStartDate,
                                'YYYY-MM-DD'
                              ).toDate()}
                              minDate={today}
                              onChange={(date) => {
                                if (date !== null) {
                                  if (selectedGoal?.id) {
                                    setBasicFrequencyType(
                                      adjustForNewStartEndDate(
                                        basicFrequencyType,
                                        date,
                                        goalEndDate,
                                        'weekly'
                                      )
                                    );
                                  }
                                  setGoalStartDate(date);
                                }
                              }}
                              filterDate={(date) => date.getDay() === 0}
                              className="w-full sm:w-[22.3vh] pl-2 react-datepicker__month-container text-left h-[38px] border border-[#D1D5DB] cursor-pointer dark:bg-[#374151] dark:text-white dark:border-none mt-2 font-inter rounded-lg text-[14px] text-[#525252]"
                            />
                          </div>
                          <div className="w-full sm:w-1/2">
                            <label className="block text-sm font-medium text-gray-900 dark:text-white">
                              End Date
                            </label>
                            <DatePicker
                              selected={moment(
                                goalEndDate,
                                'YYYY-MM-DD'
                              ).toDate()}
                              minDate={goalStartDate}
                              onChange={(date) => {
                                if (date !== null) {
                                  if (selectedGoal?.id) {
                                    setBasicFrequencyType(
                                      adjustForNewStartEndDate(
                                        basicFrequencyType,
                                        goalStartDate,
                                        moment(date).endOf('week'),
                                        'weekly'
                                      )
                                    );
                                  }
                                  setGoalEndDate(date);
                                }
                              }}
                              filterDate={(date) => date.getDay() === 6}
                              className="w-full sm:w-[22.3vh] react-datepicker__month-container text-left pl-2 h-[38px] border border-[#D1D5DB] cursor-pointer  dark:bg-[#374151] dark:text-white dark:border-none mt-2 font-inter rounded-lg text-[14px] text-[#525252]"
                            />
                          </div>
                          <div></div>
                        </div>
                      )}
                      {/* Basic monthly and quarterly */}
                      {frequencyType.value !== '' &&
                        frequencyType.value !== 'weekly' && (
                          <div className="my-6 flex flex-row justify-start items-center gap-4 w-full">
                            <div className="w-full sm:w-1/2">
                              <label className="block text-sm font-medium text-gray-900 dark:text-white">
                                Start Date
                              </label>
                              <DatePicker
                                selected={moment(
                                  goalStartDate,
                                  'YYYY-MM-DD'
                                ).toDate()}
                                minDate={today}
                                onChange={(date) => {
                                  if (date !== null) {
                                    if (selectedGoal?.id) {
                                      setGoalStartDate(date);
                                      if (frequencyType.value === 'quarterly') {
                                        setBasicFrequencyType(
                                          adjustForNewStartEndDate(
                                            basicFrequencyType,
                                            moment(date).endOf('quarter'),
                                            goalEndDate,
                                            'quarterly'
                                          )
                                        );
                                      }
                                      if (frequencyType.value === 'monthly') {
                                        setBasicFrequencyType(
                                          adjustForNewStartEndDate(
                                            basicFrequencyType,
                                            moment(date).endOf('month'),
                                            goalEndDate,
                                            'monthly'
                                          )
                                        );
                                      }
                                    } else {
                                      setGoalStartDate(date);
                                    }
                                  }
                                }}
                                className="w-full sm:w-[22.3vh] pl-2 react-datepicker__month-container text-left h-[38px] border border-[#D1D5DB] cursor-pointer dark:bg-[#374151] dark:text-white dark:border-none mt-2 font-inter rounded-lg text-[14px] text-[#525252]"
                                showMonthYearPicker={
                                  frequencyType.value === 'monthly'
                                }
                                showQuarterYearPicker={
                                  frequencyType.value === 'quarterly'
                                }
                                dateFormat={dateFormat}
                              />
                            </div>
                            <div className="w-full sm:w-1/2">
                              <label className="block text-sm font-medium text-gray-900 dark:text-white">
                                End Date
                              </label>
                              <DatePicker
                                selected={moment(
                                  goalEndDate,
                                  'YYYY-MM-DD'
                                ).toDate()}
                                minDate={today}
                                startDate={goalStartDate}
                                onChange={(date) => {
                                  if (date !== null) {
                                    if (selectedGoal?.id) {
                                      if (frequencyType.value === 'quarterly') {
                                        setGoalEndDate(
                                          moment(date).endOf('quarter')
                                        );
                                        setBasicFrequencyType(
                                          adjustForNewStartEndDate(
                                            basicFrequencyType,
                                            goalStartDate,
                                            moment(date).endOf('quarter'),
                                            'quarterly'
                                          )
                                        );
                                      }
                                      if (frequencyType.value === 'monthly') {
                                        setGoalEndDate(
                                          moment(date).endOf('month')
                                        );
                                        setBasicFrequencyType(
                                          adjustForNewStartEndDate(
                                            basicFrequencyType,
                                            goalStartDate,
                                            moment(date).endOf('month'),
                                            'monthly'
                                          )
                                        );
                                      }
                                    } else {
                                      if (frequencyType.value === 'quarterly') {
                                        setGoalEndDate(
                                          moment(date).endOf('quarter')
                                        );
                                      }
                                      if (frequencyType.value === 'monthly') {
                                        setGoalEndDate(
                                          moment(date).endOf('month')
                                        );
                                      }
                                    }
                                  }
                                }}
                                showQuarterYearPicker={
                                  frequencyType.value === 'quarterly'
                                }
                                className="w-full sm:w-[22.3vh] react-datepicker__month-container text-left pl-2 h-[38px] border border-[#D1D5DB] cursor-pointer  dark:bg-[#374151] dark:text-white dark:border-none mt-2 font-inter rounded-lg text-[14px] text-[#525252]"
                                showMonthYearPicker={
                                  frequencyType.value === 'monthly'
                                }
                                dateFormat={dateFormat}
                              />
                            </div>
                          </div>
                        )}
                    </>
                  )}

                  {/* <div className="my-6">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Goal Owner
                    </label>
                    <input
                      type="text"
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div> */}

                  <div className="my-6">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Update Settings :
                    </label>
                    <div className="flex items-center mb-2">
                      <input
                        type="checkbox"
                        checked={alertGoalReached}
                        disabled
                        onChange={() => setAlertGoalReached(!alertGoalReached)}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-0 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                        Alert me when my goal has been hit
                      </label>
                    </div>
                    <div className="flex items-center mb-2">
                      <input
                        type="checkbox"
                        name="alert"
                        checked={alertGoalNotHit}
                        disabled
                        onChange={() => setAlertGoalNotHit(!alertGoalNotHit)}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-0 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                        My goal probably won’t be hit (after 70% of the time)
                      </label>
                    </div>
                    <div className="flex items-center mb-2">
                      <input
                        type="checkbox"
                        checked={showtoClient}
                        disabled
                        onChange={() => setShowtoClient(!showtoClient)}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-0 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                        Display goal client side
                      </label>
                    </div>
                  </div>
                </div>
                <button
                  className="absolute bottom-4 right-0  sm:mr-4 w-full sm:w-[38vw] text-white bg-[#0029FF] text-[16px] font-[600] rounded-lg p-2"
                  onClick={createGoal}
                >
                  {selectedGoal?.id ? 'Save' : 'Create Goal'}
                </button>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="w-full mx-auto p-0">
                <p className="text-sm inline-block mb-2">
                  Last updated: {moment(selectedGoal?.updated_at).format('lll')}
                </p>
                {selectedGoal?.frequency?.length > 0 ? (
                  <table className="w-full">
                    <thead>
                      <tr className="text-xs font-semibold">
                        <th className="p-2 text-[#001C44] text-left">Label</th>
                        <th className="p-2 text-[#001C44] text-center">Type</th>
                        <th className="p-2 text-[#001C44] text-center">
                          Current / Goal
                        </th>
                        <th className="p-2 text-[#001C44] text-center">
                          Target Date
                        </th>
                        <th className="p-2 text-[#001C44] text-center">
                          Status
                        </th>
                        <th className="p-2 text-[#001C44] text-center"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedGoal?.frequency.map((item, index) => (
                        <tr key={index} className="border-b text-sm">
                          <td className="p-2 text-[#001C44]">{item.label}</td>
                          <td className="p-2 capitalize text-[#001C44] text-center">
                            {item.type}
                          </td>
                          <td className="p-2 text-[#001C44] text-center">
                            {syncLoading ? (
                              <Icon name="Loading" size={18} />
                            ) : (
                              `${Number(item.currentValue).toFixed()} / ${item.goalValue}`
                            )}
                          </td>
                          <td className="p-2 text-center">
                            {moment(item.targetDate).format('MMM D, YYYY')}
                          </td>
                          <td className="p-2">
                            <div
                              className={`text-sm flex justify-center w-full font-inter font-[500] ${getStatusBadgeColor(item?.status)?.color} dark:text-[#FFF] overflow-hidden px-4 py-1 rounded-full`}
                            >
                              {getStatusBadgeColor(item.status)?.text}
                            </div>
                          </td>
                          <td
                            className={`p-2 ${item.status === 'not-started' ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                            onClick={() => {
                              if (item.status !== 'not-started') {
                                handleSyncGoal(item?._id);
                              }
                            }}
                          >
                            <div>
                              <Icon name="RefreshIcon" size={18} />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : null}
              </div>
            </TabPanel>
          </TabPanels>
        </TabGroup>
        <div
          className="hidden sm:flex absolute items-center transform -rotate-90 top-[50dvh] left-[-42px] z-50 bg-[#D0EDFF] py-1 px-2 rounded-md cursor-pointer"
          onClick={onClose}
        >
          <p className="text-[#001C44] font-bold">Close</p>
          <Icon name="Xclose" color="#001C44" />
        </div>
      </div>
      <CustomModal
        open={openArchiveModal}
        onClose={() => setOpenArchiveModal(false)}
      >
        <ConfirmationArchiveModal
          onClose={() => setOpenArchiveModal(false)}
          archiveGoal={handleArchiveGoal}
        />
      </CustomModal>
    </div>
  );
};

export default CreateGoalsModal;
