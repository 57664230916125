import { Btn, Wrapper } from './styles';

const Button = ({ variant = 'blue', children, ...other }) => {
  return (
    <Wrapper>
      <Btn className="btn" variant={variant} {...other}>
        <span>{children} </span>
      </Btn>
    </Wrapper>
  );
};

export default Button;
