import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const click: React.FC<IconProps> = ({ color = 'black', size = 16 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_760_39536)">
      <path
        d="M5.99992 2.33301V1.33301M3.37369 3.37345L2.66659 2.66634M3.37369 8.66634L2.66659 9.37345M8.66659 3.37345L9.37369 2.66634M2.33325 5.99967H1.33325M10.5762 10.7927L8.91508 13.8777C8.7253 14.2301 8.63042 14.4063 8.51625 14.4509C8.41718 14.4896 8.3056 14.4787 8.2159 14.4215C8.11255 14.3557 8.05363 14.1644 7.93579 13.7818L5.63004 6.29651C5.53378 5.98401 5.48565 5.82776 5.52438 5.72223C5.55811 5.63031 5.63055 5.55787 5.72247 5.52414C5.82801 5.48541 5.98425 5.53354 6.29675 5.6298L13.7821 7.93557C14.1646 8.05341 14.3559 8.11233 14.4217 8.21569C14.4789 8.30538 14.4898 8.41696 14.4511 8.51604C14.4066 8.6302 14.2303 8.72509 13.8779 8.91486L10.793 10.576C10.7406 10.6042 10.7144 10.6183 10.6915 10.6364C10.6711 10.6525 10.6527 10.6709 10.6367 10.6912C10.6185 10.7142 10.6044 10.7404 10.5762 10.7927Z"
        stroke={color || '#334969'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_760_39536">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default click;
