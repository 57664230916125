// WeeklyReport.tsx
import React, { useState } from 'react';
import Icon from '../../../assets/icons/SvgComponent';
import { BarChart, Card, Title } from '@tremor/react';
import { useLocation, useNavigate } from 'react-router-dom';

interface PieChartProps {
  title?: string;
  data?: any;
  reportHSInsights?: any;
  loading?: boolean;
}

const PieChart: React.FC<PieChartProps> = ({
  title,
  data,
  reportHSInsights,
  loading,
}) => {
  const lastElemet = data?.length ? data[data?.length - 1] : 0;

  //display no record if no data
  const valueFormatter = (number: number) =>
    Intl.NumberFormat('us').format(number).toString();
  const greenData = reportHSInsights?.datasets?.find(
    (item: any) => item.datasetLabel === 'green'
  )?.data;
  const amberData = reportHSInsights?.datasets?.find(
    (item: any) => item.datasetLabel === 'amber'
  )?.data;
  const redData = reportHSInsights?.datasets?.find(
    (item: any) => item.datasetLabel === 'red'
  )?.data;

  const checkDataChart = !(
    greenData?.every((value: number) => value === 0) &&
    amberData?.every((value: number) => value === 0) &&
    redData?.every((value: number) => value === 0)
  );
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState(null);
  const handleNavigate = (val: any) => {
    const searchParams = new URLSearchParams(location.search);

    searchParams.set('date', val.Month);
    searchParams.set('healthStatus', val.categoryClicked.toLowerCase());
    const updatedSearch = searchParams?.toString();
    location.search = updatedSearch;
    const newUrl = `/client-reporting?${updatedSearch}&status=all&all=true`;

    window.open(newUrl, '_blank');
  };
  const monthsWithValue = data
    ?.filter(
      (item: any) => item.Green !== 0 && item.Amber !== 0 && item.Red !== 0
    )
    .map((item: any) => item.Month);

  return (
    <Card className="md:max-w-[49.5%]">
      <Title>{title}</Title>

      {loading ? (
        <div className="w-full h-80 flex flex-col justify-center items-center">
          <Icon name="Loading" />
        </div>
      ) : (
        <>
          {monthsWithValue?.length ? (
            <BarChart
              className="opacity-70"
              data={data}
              index="Month"
              categories={['Green', 'Amber', 'Red']}
              colors={['emerald', 'amber', 'red']}
              valueFormatter={valueFormatter}
              yAxisWidth={48}
              stack={true}
              onValueChange={(v) => handleNavigate(v)}
            />
          ) : (
            <div className="w-full h-80 flex flex-col justify-center items-center">
              <p className="mb-4">No records found</p>
              <Icon name="NoData" />
            </div>
          )}
        </>
      )}
      <div className="w-full flex justify-between  items-center py-2 border-b border-b-[#C6C9CB] dark:border-b-[#fff]">
        <div className="text-[#7C7E7F] text-sm dark:text-white">Green</div>
        {loading ? (
          <Icon name="Loading" size={20} />
        ) : (
          <div className="flex items-center justify-center gap-1 bg-[#E2FAF1] px-2 rounded-full">
            <span className="text-[#FF7200] text-sm">{lastElemet?.Green}</span>
          </div>
        )}
      </div>
      <div className="w-full flex justify-between  items-center py-2 border-b border-b-[#C6C9CB] dark:border-b-[#fff]">
        <div className="text-[#7C7E7F] text-sm dark:text-white">Amber</div>
        {loading ? (
          <Icon name="Loading" size={20} />
        ) : (
          <div className="flex items-center justify-center gap-1 bg-[#FFF2E8] px-2 rounded-full">
            <span className="text-[#428B6E] text-sm">{lastElemet?.Amber}</span>
          </div>
        )}
      </div>
      <div className="w-full flex justify-between  items-center py-2">
        <div className="text-[#7C7E7F] text-sm dark:text-white">Red</div>
        {loading ? (
          <Icon name="Loading" size={20} />
        ) : (
          <div className="flex items-center justify-center gap-1 bg-[#FCDADA] px-2 rounded-full">
            <span className="text-[#BF3636] text-sm">{lastElemet?.Red}</span>
          </div>
        )}
      </div>
    </Card>
  );
};

export default PieChart;
