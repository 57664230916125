import {
  Badge,
  Card,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  TextInput,
} from '@tremor/react';
import React, { useState } from 'react';
import PageHeader from '../../components/Dashboard/PageHeader';
const Syncing = () => {
  const [searchText, setSearchText] = useState('');
  const [isSwitchOn, setIsSwitchOn] = useState<boolean>(false);
  const data = [
    {
      id: 'HS-Sync-123456789',
      start: 'Nov 10, 2023',
      end: 'Nov 10, 2024',
      status: 'Success',
    },
    {
      id: 'HS-Sync-214356475',
      start: 'Nov 10, 2023',
      end: 'Nov 10, 2024',
      status: 'Failed',
    },
  ];

  const handleSwitchChange = (value: boolean) => {
    setIsSwitchOn(value);
  };

  return (
    <div className="flex flex-col h-full p-8 bg-[#F9FAFB] gap-y-5 dark:bg-[#1D1D1D]">
      <Card>
        <div className="flex justify-between border-b border-[#EAECF0] pb-5 ">
          <div className="flex flex-col gap-0.5 w-1/4">
            <span className="text-sm font-semibold text-[#001C44] dark:text-[#fff]">
              Hubspot Sync
            </span>
            <span className="text-sm text-[#475467] dark:text-gray-500">
              Lorem ipsum dolor sit amet consectetur. Ut quam rhoncus aliquam et
              hendrerit non. In dolor pretium morbi amet.
            </span>
          </div>
          <div className="w-2/3 border border-[#F3F7FA] shadow-sm rounded-lg dark:border-gray-600">
            <div className="flex items-center justify-between px-6 py-3 bg-[#F3F7FA] text-sm text-[#7C7E7F] dark:bg-gray-700 dark:text-gray-300">
              <span>User ID</span>
              <span>Sync</span>
            </div>
            <div className="flex items-center justify-between px-6 py-3">
              <TextInput className="w-11/12 dark:bg-gray-700" placeholder=" " />
              <Switch
                id="switch"
                name="switch"
                checked={isSwitchOn}
                onChange={handleSwitchChange}
              />
            </div>
          </div>
        </div>
        <Table className="border border-[#F3F7FA] shadow-sm rounded-lg mt-4 dark:border-gray-600">
          <TableHead className="bg-[#F3F7FA] text-sm text-[#7C7E7F] dark:bg-gray-700 ">
            <TableRow>
              <TableHeaderCell className="w-2/3 dark:text-gray-300">
                User ID
              </TableHeaderCell>
              <TableHeaderCell className="dark:text-gray-300">
                Start Date
              </TableHeaderCell>
              <TableHeaderCell className="dark:text-gray-300">
                End Date
              </TableHeaderCell>
              <TableHeaderCell className="dark:text-gray-300">
                Status
              </TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => (
              <TableRow key={item.id}>
                <TableCell className="dark:text-gray-300">{item.id}</TableCell>
                <TableCell className="dark:text-gray-300">
                  {item.start}
                </TableCell>
                <TableCell className="dark:text-gray-300">{item.end}</TableCell>
                <TableCell>
                  <Badge color="emerald">{item.status}</Badge>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </div>
  );
};

export default Syncing;
