import { Grid } from '@tremor/react';
import AreaCardChart from '../AreaCardChart';
import {
  formatToCurrency,
  formatNumber,
  processData,
  processData2,
} from '../../../utils/common';

interface IProps {
  isLeadGen?: boolean;
  isGoogleSeo?: boolean;
  topPerformanceSeo?: any;
  requestGoogleSEOAccess?: any;
  selectedClient?: any;
  retryFetchGA4Analytics?: any;
}

const KPISeo = ({
  isLeadGen,
  isGoogleSeo,
  topPerformanceSeo,
  requestGoogleSEOAccess,
  selectedClient,
  retryFetchGA4Analytics,
}: IProps) => {
  const organic_sessions = processData(topPerformanceSeo, 'organic_sessions');
  const clicks = processData(topPerformanceSeo, 'clicks');
  const organic_totalRevenue = processData(
    topPerformanceSeo,
    'organic_totalRevenue',
    'Organic Revenue'
  );
  const organic_conversions_breakdown = processData2({
    dates: topPerformanceSeo?.organic_conversions?.dates,
    values: topPerformanceSeo?.organic_conversions?.values,
    totalKeyName: 'Total',
  });

  const lastMonthOrganicRevenue =
    Array.isArray(organic_totalRevenue) && organic_totalRevenue.length > 0
      ? organic_totalRevenue[organic_totalRevenue.length - 1]?.[
          'Organic Revenue'
        ]
      : undefined;

  const lastMonthOrganicSessions =
    Array.isArray(organic_sessions) && organic_sessions.length >= 2
      ? organic_sessions[organic_sessions.length - 1]?.value
      : undefined;
  const lastMonthTotalOrganicConversionsBreakdown =
    Array.isArray(organic_conversions_breakdown) &&
    organic_conversions_breakdown.length >= 2
      ? organic_conversions_breakdown[
          organic_conversions_breakdown.length - 1
        ]?.['Total']
      : undefined;

  const lastMonthClicks =
    Array.isArray(clicks) && clicks.length > 0
      ? clicks[clicks.length - 1]?.value
      : undefined;
  return (
    <Grid
      numItems={1}
      numItemsSm={1}
      numItemsMd={2}
      numItemsLg={3}
      className="gap-4"
    >
      {isLeadGen ? (
        <AreaCardChart
          title={
            topPerformanceSeo?.organic_sessions?.label || 'Organic Sessions'
          }
          chatData={organic_sessions}
          status={isGoogleSeo}
          category={['Organic Sessions']}
          totalValue={formatNumber(lastMonthOrganicSessions)}
          requestAccess={requestGoogleSEOAccess}
          error={topPerformanceSeo.error}
          isLoading={
            !['activated', 'active'].includes(
              selectedClient?.google_analytics?.ga4?.status
            )
              ? false
              : topPerformanceSeo.loading
          }
          prevPeriod={topPerformanceSeo?.organic_sessions?.previous_period}
          previousYear={topPerformanceSeo?.organic_sessions?.previous_year}
          // noAccessSubtitle={googleSeoNoAccessSub}
          info={topPerformanceSeo?.organic_sessions?.info || 'Organic Sessions'}
          icon="googleAnalytics"
          showCurrentMonth={true}
        />
      ) : (
        <AreaCardChart
          title="Organic Revenue"
          chatData={organic_totalRevenue}
          status={isGoogleSeo}
          category={['Organic Revenue']}
          totalValue={formatToCurrency(lastMonthOrganicRevenue)}
          requestAccess={requestGoogleSEOAccess}
          error={topPerformanceSeo.error}
          isLoading={
            !['activated', 'active'].includes(
              selectedClient?.google_analytics?.ga4?.status
            )
              ? false
              : topPerformanceSeo.loading
          }
          prevPeriod={topPerformanceSeo?.organic_totalRevenue?.previous_period}
          previousYear={topPerformanceSeo?.organic_totalRevenue?.previous_year}
          hasDollar
          // noAccessSubtitle={googleSeoNoAccessSub}
          info={
            topPerformanceSeo?.organic_totalRevenue?.info || 'Organic Revenue'
          }
          icon="googleAnalytics"
          showCurrentMonth={true}
        />
      )}

      <AreaCardChart
        title={
          topPerformanceSeo?.organic_conversions?.label
            ? topPerformanceSeo?.organic_conversions?.label
            : 'Organic Conversions'
        }
        chatData={organic_conversions_breakdown}
        category={['Total']}
        info={
          topPerformanceSeo?.organic_conversions?.info
            ? topPerformanceSeo?.organic_conversions?.info
            : 'Organic Conversions'
        }
        prevPeriod={topPerformanceSeo?.organic_conversions?.previous_period}
        previousYear={topPerformanceSeo?.organic_conversions?.previous_year}
        isLoading={topPerformanceSeo.loading}
        totalValue={formatNumber(lastMonthTotalOrganicConversionsBreakdown)}
        error={topPerformanceSeo.error}
        customDataTooltip={true}
        icon="googleAnalytics"
        retryMethod={retryFetchGA4Analytics}
      />
      <AreaCardChart
        title={topPerformanceSeo?.clicks?.label || 'Clicks'}
        chatData={clicks}
        status={isGoogleSeo}
        category={['Clicks']}
        totalValue={formatNumber(lastMonthClicks)}
        requestAccess={requestGoogleSEOAccess}
        error={topPerformanceSeo.error}
        prevPeriod={topPerformanceSeo?.clicks?.previous_period}
        previousYear={topPerformanceSeo?.clicks?.previous_year}
        isLoading={
          !['activated', 'active'].includes(
            selectedClient?.google_analytics?.ga4?.status
          )
            ? false
            : topPerformanceSeo.loading
        }
        // noAccessSubtitle={googleSeoNoAccessSub}
        info={topPerformanceSeo?.clicks?.info}
        icon="googleSearch"
        showCurrentMonth={true}
      />
    </Grid>
  );
};

export default KPISeo;
