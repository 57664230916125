import moment from 'moment';

export const mergedArray = (
  array1: any[],
  array2: any[],
  key1: string,
  key2: string,
  hasDollar?: boolean,
  hasPercent?: boolean
) => {
  return array1?.length > 0
    ? (array1 || []).map((date: string, index: number) => ({
        [key1]: date,
        [key2]: array2[index],
      }))
    : [];
};

export const processData = (
  data: any,
  keyword: string,
  label?: string
): Array<{ date: any; [key: string]: any }> => {
  const filteredData = data?.hasOwnProperty(keyword) ? [data[keyword]] : [];

  // Utility to capitalize the first letter
  const formatKeyword = (key: string): string => {
    return key
      .replace(/_/g, ' ')
      .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before capital letters
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
      .join(' ');
  };

  const formattedKeyword = formatKeyword(keyword);

  // Utility to format date if it's in "YYYY-MM" or "YYYY-MM-DD" format
  const formatDate = (date: string): string => {
    if (date.includes('-') && date.includes(' - ')) {
      // Handle date range case
      const [startDate, endDate] = date.split(' - ');
      const momentStartDate = moment(startDate, 'YYYY-MM-DD', true);
      const momentEndDate = moment(endDate, 'MM-DD', true);

      if (momentStartDate.isValid() && momentEndDate.isValid()) {
        const formattedStartDate = momentStartDate.format('DD MMM');
        const formattedEndDate = momentEndDate.format('DD MMM YYYY');
        return `${formattedStartDate} - ${formattedEndDate}`;
      } else {
        return date;
      }
    } else {
      const hasDay = date.length > 7; // Check if the date string includes a day
      const format = hasDay ? 'DD MMM YYYY' : 'MMM YYYY';
      const momentDate = moment(date, hasDay ? 'YYYY-MM-DD' : 'YYYY-MM', true);
      return momentDate.isValid() ? momentDate.format(format) : date;
    }
  };

  return filteredData?.flatMap(
    (userData: { dates: any[]; values: number[] }) =>
      userData?.dates?.map((date, index) => ({
        date: formatDate(date),
        ...(label
          ? { [label]: userData?.values[index] }
          : { [formattedKeyword]: userData?.values[index] }),
        value: userData?.values[index],
      })) || []
  );
};

export const processData2 = (data: {
  dates: string[];
  values: Array<{ [key: string]: number }>;
  totalKeyName: string;
}): Array<{ date: string; [key: string]: any }> => {
  return data?.dates?.map((date, index) => {
    const formatDate = (date: string): string => {
      if (date.includes('-') && date.includes(' - ')) {
        // Handle date range case
        const [startDate, endDate] = date.split(' - ');
        const momentStartDate = moment(startDate, 'YYYY-MM-DD', true);
        const momentEndDate = moment(endDate, 'MM-DD', true);

        if (momentStartDate.isValid() && momentEndDate.isValid()) {
          const formattedStartDate = momentStartDate.format('DD MMM');
          const formattedEndDate = momentEndDate.format('DD MMM YYYY');
          return `${formattedStartDate} - ${formattedEndDate}`;
        } else {
          return date;
        }
      } else {
        const hasDay = date.length > 7; // Check if the date string includes a day
        const format = hasDay ? 'DD MMM YYYY' : 'MMM YYYY';
        const momentDate = moment(
          date,
          hasDay ? 'YYYY-MM-DD' : 'YYYY-MM',
          true
        );
        return momentDate.isValid() ? momentDate.format(format) : date;
      }
    };
    const values = data?.values[index];
    let total = 0;
    for (let key in values) {
      total += values[key];
    }
    return {
      date: formatDate(date),
      [data.totalKeyName]: total,
      ...values,
    };
  });
};

export const createChartData = (data: any[], key1: string, key2: string) => {
  return (
    (data || [])?.slice(0, 10).map((item: any) => {
      return {
        [key1]: item.pagePathPlusQueryString,
        [key2]: item.eventCount,
      };
    }) ?? []
  );
};

export const isValidNumber = (num: any) => {
  const parsed = parseFloat(num);
  return {
    isValid: !isNaN(parsed),
    value: isNaN(parsed) ? num : parsed,
  };
};

export const formatToCurrency = (
  num: number | string,
  decimalPlaces = 2,
  currency = 'USD'
) => {
  const { isValid, value } = isValidNumber(num);

  if (!isValid) return '0';

  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  }).format(value);
};

export const formatNumber = (
  num: number | string,
  hasPercent?: boolean,
  decimalPlaces?: number
) => {
  const decimal = decimalPlaces >= 0 ? decimalPlaces : 2;
  const { isValid, value } = isValidNumber(num);
  if (!isValid) return '0';

  const parsedNumber = typeof value === 'string' ? parseFloat(value) : value;
  const formattedNumber = parsedNumber.toFixed(decimal);

  return (
    new Intl.NumberFormat('en-US').format(formattedNumber) +
    `${hasPercent ? '%' : ''}`
  );
};

export const formatNumberWithCommas = (num: number): string => {
  const roundedNumber = Number.isNaN(num) ? 0 : Math.round(num * 100) / 100;
  return new Intl.NumberFormat('en-US').format(roundedNumber);
};

export const toQueryString = (params: { [key: string]: any }) =>
  Object.keys(params)
    .filter(
      (key) =>
        params[key] !== undefined &&
        params[key] !== null &&
        params[key] !== 'all'
    )
    .map((key, index) => `${index === 0 ? '?' : ''}${key}=${params[key]}`)
    .join('&');

export const parseDate = (date) => (date ? date.replace(/(^"|"$)/g, '') : null);

export const getBadgeColorAndText = (keyword) => {
  const keywordMappings = {
    own: { color: 'bg-[#009EFF] text-white', text: 'Owner' },
    owner: { color: 'bg-[#009EFF] text-white', text: 'Owner' },
    staff: { color: 'bg-[#ADF8FF] text-[#000]', text: 'Staff' },
    admin: { color: 'bg-[#FFB545] text-white', text: 'Admin' },
    developer: { color: 'bg-[#D0EDFF] text-[#000]', text: 'Developer' },
    executive: { color: 'bg-[#01F0FF]  text-[#000]', text: 'Executive' },
    am: {
      color: 'bg-[#E478FA] text-white',
      text: 'Account Manager',
    },
    account_manager: {
      color: 'bg-[#E478FA] text-white',
      text: 'Account Manager',
    },
    account_director: {
      color: 'bg-[#FD6F8E] text-white',
      text: 'Account Director',
    },
    seocon: { color: 'bg-[#A48AFB] text-white', text: 'SEO Consultant' },
    seo_manager: { color: 'bg-[#667EFF] text-white', text: 'SEO Manager' },
    ppccon: {
      color: 'bg-[#D9DFFF] text-[#000]',
      text: 'Paid Search Consultant',
    },
    paid_search_consultant: {
      color: 'bg-[#D9DFFF] text-[#000]',
      text: 'Paid Search Consultant',
    },
    soccon: {
      color: 'bg-[#8098F9] text-white',
      text: 'Social Consultant',
    },
    social_consultant: {
      color: 'bg-[#8098F9] text-white',
      text: 'Social Consultant',
    },
    pod_lead: { color: 'bg-[#86CB3C] text-white', text: 'Pod Lead' },
    omg_staff: { color: 'bg-[#3CCB7F] text-white', text: 'OMG Staff' },
    activations_officer: {
      color: 'bg-[#2ED3B7] text-white',
      text: 'Activations Officer',
    },
    finance_officer: {
      color: 'bg-[#FF692E] text-white',
      text: 'Finance Officer',
    },
  };

  return (
    keywordMappings[keyword] || {
      color: 'bg-gray-200 text-black',
      text: 'Unknown Role',
    }
  );
};

export const getStatusBadgeColor = (keyword) => {
  const keywordMappings = {
    'on track': {
      color: 'bg-[#F2F4FF] text-[#0029FF] dark:bg-[#3BE377]',
      border: ' border border-[#0029FF]',
      text: 'On Track',
      bullet: 'bg-[#428B6E] dark:bg-[#ccffcc]',
    },
    open: {
      color: 'bg-[#E2FAF1] text-[#428B6E] dark:bg-[#3BE377]',
      text: 'Open',
      bullet: 'bg-[#428B6E] dark:bg-[#ccffcc]',
    },
    delivered: {
      color: 'text-[#0029FF] bg-[#e6eaff]',
      border: ' border border-[#0029FF]',
      text: 'DELIVERED',
      bullet: 'bg-[#428B6E] dark:bg-[#ccffcc]',
    },
    Delivered: {
      color: 'bg-[#E2FAF1] text-[#428B6E] dark:bg-[#3BE377]',
      text: 'Delivered',
      bullet: 'bg-[#428B6E] dark:bg-[#ccffcc]',
    },
    overdue: {
      color: 'bg-[#FFF0EA] text-[#FF692E] dark:bg-[#B21928]',
      text: 'Overdue',
      bullet: 'bg-[#FF692E]',
    },
    achieved: {
      color: 'bg-[#E2FAF1] text-[#428B6E] dark:bg-[#3BE377]',
      border: ' border border-[#428B6E]',
      text: 'Achieved',
      bullet: 'bg-[#428B6E] dark:bg-[#ccffcc]',
    },
    'Not Delivered': {
      color: 'text-[#FF0000] border border-[#FF0000] bg-[#ffe6e6]',
      text: 'NOT DELIVERED',
      bullet: 'bg-[#428B6E] dark:bg-[#ccffcc]',
    },
    not_delivered: {
      color: 'text-[#ff0000] border border-[#ff0000] bg-[#ffe6e6]',
      text: 'NOT DELIVERED',
      bullet: 'bg-[#EF4444] dark:bg-[#fff0b3]',
    },
    tracked: {
      color: 'bg-[#F2F4FF] text-[#0029FF] dark:bg-[#3BE377]',
      border: ' border border-[#0029FF]',
      text: 'On Track',
      bullet: 'bg-[#428B6E] dark:bg-[#ccffcc]',
    },
    'at risk': {
      color: 'bg-[#FFEEEE] text-[#EF4444] dark:bg-[#EE8D05]',
      border: ' border border-[#EF4444]',
      text: 'At Risk',
      bullet: 'bg-[#EF4444] dark:bg-[#fff0b3]',
    },
    failed: {
      color: 'bg-[#FFEEEE] text-[#EF4444] dark:bg-[#EE8D05]',
      border: ' border border-[#EF4444]',
      text: 'Failed',
      bullet: 'bg-[#EF4444] dark:bg-[#fff0b3]',
    },
    behind: {
      color: 'bg-[#FFF0EA] text-[#FF692E] dark:bg-[#B21928]',
      text: 'Behind',
      bullet: 'bg-[#FF692E]',
    },
    'behind target': {
      color: 'bg-[#FFF0EA] text-[#FF692E] dark:bg-[#B21928]',
      text: 'Behind',
      bullet: 'bg-[#FF692E]',
    },
    started: {
      color: 'bg-[#F2F4FF] text-[#0029FF] dark:bg-[#3BE377]',
      border: ' border border-[#0029FF]',
      text: 'On Track',
      bullet: 'bg-[#428B6E] dark:bg-[#ccffcc]',
    },
    'not-started': {
      color: 'bg-gray-200 text-[#363F72]',
      border: ' border border-[#D5D9EB]',
      text: 'Not Started',
      bullet: 'bg-gray-200',
    },
  };
  const convertKeyword = keyword?.toLowerCase();
  return (
    keywordMappings[convertKeyword] || {
      color: 'bg-gray-200 text-black',
      text: 'Unknown Role',
    }
  );
};

export const getTrackerColor = (keyword) => {
  const keywordMappings = {
    'on track': {
      color: '#0029FF',
    },
    achieved: {
      color: '#3CCB7F',
    },
    tracked: {
      color: '#0029FF',
    },
    'at risk': {
      color: '#FF0000',
    },
    failed: {
      color: '#FF0000',
    },
    behind: {
      color: '#FFB545',
    },
    'behind target': {
      color: '#FFB545',
    },
    'not-started': {
      color: '#E3E4E5',
    },
  };
  const convertKeyword = keyword?.toLowerCase();
  return (
    keywordMappings[convertKeyword] || {
      color: '#E3E4E5',
    }
  );
};

export const getServiceLabel = (key) => {
  const keywordMappings = {
    ppc_google: {
      label: 'Google Ads',
    },
    seo_google: {
      label: 'Google SEO',
    },
    social_meta: {
      label: 'Meta',
    },
  };

  return (
    keywordMappings[key] || {
      label: 'Service Not Available',
    }
  );
};
