import { FC, useState } from 'react';
import { CgTimer } from 'react-icons/cg';
import useReportStore from '../../../store/useReportStore';
import moment from 'moment';
import Icon from '../../../assets/icons/SvgComponent';
import ImageIcon from '../../ImageIcon';
import TanStackTable from '../../TanStackTable';
import Tooltip from '../../HelpTooltip';

// Define the type for a comment
interface Comment {
  created_at: string;
  name: string;
  status: string;
  comment: string;
  [key: string]: any; // There might be other properties in the object
}

const Comments: FC = () => {
  const { comments } = useReportStore((state) => state);
  const [selectedRow, setSelectedRow] = useState([]);

  // Explicitly type comments as an array of Comment type
  const sortedComments = (comments as Comment[]).sort(
    (a, b) =>
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  );
  const columns = [
    {
      id: 'Date',
      accessorKey: 'updated_at',
      header: (props: any) => (
        <div className="text-sm flex justify-start w-full pl-6">
          <span>Date</span>
        </div>
      ),
      cell: (props: any) => (
        <div className="text-sm w-full flex justify-start pl-6">
          <span>{moment(props.getValue()).format('DD-MM-YYYY')}</span>
        </div>
      ),
    },
    {
      id: 'Author',
      header: () => (
        <div className="text-sm flex justify-start w-full pl-12">Author</div>
      ),
      accessorKey: 'name',
      cell: (props: any) => (
        <div className="text-sm flex justify-start items-center gap-2 w-full ">
          <ImageIcon data={props.getValue()} size={9} textSize={'xs'} />
          <span>{props.getValue()}</span>
        </div>
      ),
    },
    {
      id: 'Status',
      header: () => (
        <div className="text-sm flex justify-center w-full">Status</div>
      ),
      accessorKey: 'status',
      cell: (props: any) => {
        let color;
        let bg;
        if (props.getValue() === 'review') {
          color = '#CF5C00';
          bg = '#FFF2E8';
        } else if (
          props.getValue() === 'rejected' ||
          props.getValue() === 'canceled'
        ) {
          color = '#8F2929';
          bg = '#FCDADA';
        } else if (
          props.getValue() === 'validated'
        ) {
          color = '#00375F';
          bg = '#E6EFF5';
        } else if (props.getValue() === 'in_progress') {
          color = '#CCA200';
          bg = '#FFFAE6';
        } else if (props.getValue() === 'sent') {
          color = '#01895f';
          bg = '#d3fbee';
        } else if (
          props.getValue() === 'approved'
        ) {
          color = '#021293';
          bg = '#dedaf6';
        } 

        return (
          <div className="text-sm flex justify-center w-full">
            <span
              style={{
                padding: '2px 8px',
                borderRadius: 16,
                backgroundColor: `${bg}`,
                color: `${color}`,
                border: `1px solid ${color}`,
                fontSize: 12,
              }}
            >
              {props
                .getValue()
                .replace(/_/g, ' ')
                .replace(/\b\w/g, (char: string) => char.toUpperCase())}
            </span>
          </div>
        );
      },
    },
    {
      id: 'Comment',
      header: () => (
        <div className="text-sm flex justify-center  w-full px-2">Comment</div>
      ),
      accessorKey: 'comment',
      cell: (props: any) => (
        <Tooltip position="bottom" content={`${props?.getValue()}`}>
          <div className="flex justify-center w-full">
            <div className="text-sm truncate  max-w-[300px] overflow-hidden">
              {props?.getValue()}
            </div>
          </div>
        </Tooltip>
      ),
    },
  ];

  return (
    <div className="rounded-md h-[80%] px-2">
      <div>
        <TanStackTable
          data={sortedComments}
          columns={columns}
          totalPages={1}
          page={1}
          setPage={1}
          setSelectedRow={setSelectedRow}
          tableHeight="60dvh"
        />
      </div>
    </div>
  );
};

export default Comments;
