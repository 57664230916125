import React from 'react';

interface MicrosoftProps {
  color?: string;
}

const Microsoft: React.FC<MicrosoftProps> = ({ color = '#428B6E' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#clip0_467_172)">
      <path d="M11.4057 11.4057H0V0H11.4057V11.4057Z" fill="#F1511B" />
      <path
        d="M24.0001 11.4057H12.5935V0H23.9992V11.4057H24.0001Z"
        fill="#80CC28"
      />
      <path d="M11.4057 24.0038H0V12.5982H11.4057V24.0038Z" fill="#00ADEF" />
      <path
        d="M24.0001 24.0038H12.5935V12.5982H23.9992V24.0038H24.0001Z"
        fill="#FBBC09"
      />
    </g>
    <defs>
      <clipPath id="clip0_467_172">
        <rect width="24" height="24.0038" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Microsoft;
