import { Card } from '@tremor/react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Icon from '../../assets/icons/SvgComponent';
import { useThemeStore } from '../../store/useThemeStore';

const Post = () => {
  const { id } = useParams();
  const { mode } = useThemeStore((state: any) => state);
  const [post, setPost] = useState<any>(null);
  const [posts, setPosts] = useState<any>(null);
  
  const postsToShow = posts && posts?.slice(0, 4);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://www.onlinemarketinggurus.com.au/wp-json/wp/v2/posts?id=${id}`
        );
        const foundPost = response.data?.find(
          (post: any) => post.id === Number(id)
        );
        setPost(foundPost);
        setPosts(response.data);
      } catch (error) {
        console.error('There was an error fetching the data:', error);
      }
    };

    fetchData();
  }, [id]);

  return (
    <div className="flex flex-col items-center bg-white w-full h-full dark:bg-gray-800 ">
      {post ? (
        <div className="flex flex-col justify-center  gap-y-8 p-8 max-w-[1440px]">
          <div>
            <Link to="/post">
              <span className="text-sm text-[#0e7bf2] hover:underline">Blog</span>
            </Link>
            <Link to="/post">
              <span className="text-sm text-[#0e7bf2] font-semibold ml-2 hover:underline" dangerouslySetInnerHTML={{__html: post?.title.rendered}}>
                
              </span>
            </Link>
          </div>
          <Card className="flex">
            <div className="flex flex-col gap-y-4 w-1/2">
              <span className="text-sm w-fit p-2 bg-[#f7f7f6] rounded-full">
                DIGITAL MARKETING
              </span>
              <h1 className="text-3xl text-[#0e7bf2] font-semibold" dangerouslySetInnerHTML={{__html: post?.title.rendered}}>
              </h1>
              <div>
                <span className="font-bold dark:text-white">OMG Marketing</span>
                <span className="mx-2 dark:text-white">3 November 2023 </span>
                <span className="text-[#0e7bf2] ">3.5min read</span>
              </div>
            </div>
            <div className="w-1/2 ">
              <img
                src={post?.yoast_head_json.og_image[0].url}
                alt=""
                style={{ width: '100%', maxHeight: '300px' }}
              />
            </div>
          </Card>
          <Card className="flex gap-x-4">
            <div className="flex flex-col gap-y-8 w-1/2  2xl:w-full dark:text-gray-300">
              <span
                className="text-[#0e7bf2] text-2xl font-semibold"
                dangerouslySetInnerHTML={{ __html: post?.title?.rendered }}
              ></span>
              <div
                dangerouslySetInnerHTML={{ __html: post?.content?.rendered }}
              ></div>
            </div>
            <div className="flex flex-col gap-y-8 w-1/2">
              <span className="text-[#0e7bf2] text-2xl font-semibold">
                Related blog posts:
              </span>
              {postsToShow?.map((post: any, index: number) => (
                <div
                  key={index}
                  className="flex items-center gap-x-4 rounded-lg shadow-lg border border-white dark:bg-gray-700"
                >
                  <img
                    src={post?.yoast_head_json.og_image[0].url}
                    alt=""
                    className="rounded-l-lg"
                    style={{ width: '35%'}}
                  />
                  <a href={post?.id} target="_blank" rel="noreferrer" className='hover:underline text-sm text-[#0e7bf2] lg:text-lg font-semibold'>
                    <span
     
                      dangerouslySetInnerHTML={{
                        __html: post?.title?.rendered,
                      }}
                    ></span>
                  </a>
                </div>
              ))}
              {posts.length > 4 && (
                <a
                  href="https://www.onlinemarketinggurus.com.au/blog/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="flex items-center gap-x-2 mt-4 ml-4 font-semibold cursor-pointer dark:text-white">
                    View all blog posts
                    <Icon name="chevronRight" color={mode === 'dark' ? '#fff' : '#000'} size={16} />
                  </div>
                </a>
              )}
            </div>
          </Card>
        </div>
      ) : (
        <div className="flex w-full justify-center items-center h-full">
          <Icon name="Loading" />
        </div>
      )}
      
    </div>
  );
};

export default Post;
