import React from 'react';

interface FlagProps {
  color?: string;
}

const Flag: React.FC<FlagProps> = ({ color = '#334969' }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="flag-01">
      <g id="Icon">
        <path
          d="M4 7C2.5 7 2 7.5 2 7.5V1.5C2 1.5 2.5 1 4 1C5.5 1 6.5 2 8 2C9.5 2 10 1.5 10 1.5V7.5C10 7.5 9.5 8 8 8C6.5 8 5.5 7 4 7Z"
          fill={color}
        />
        <path
          d="M2 7.5C2 7.5 2.5 7 4 7C5.5 7 6.5 8 8 8C9.5 8 10 7.5 10 7.5V1.5C10 1.5 9.5 2 8 2C6.5 2 5.5 1 4 1C2.5 1 2 1.5 2 1.5L2 11"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);

export default Flag;
