import styled from 'styled-components';
export const Wrapper = styled.div(({ color }) => ({
  width: '100%',
}));

export const Btn = styled.div(({ color }) => ({
  height: 32,
  borderRadius: 8,
  display: 'flex',
  alignItems: 'center',
  backgroundColor: color,
  minWidth: 100,
  cursor: 'pointer',
  transition: 'all 1s',
  position: 'relative',
  padding: '8px 14px',
  color: '#fff',
}));
