import { Grid, Col } from '@tremor/react';
import DonutBarGroup from '../../DonutCardChart';
import useClientStore from '../../../../store/useClientStore';
import useGoogleStore from '../../../../store/useGoogleStore';
import MobileView from './mobileView';

const DeviceOverviewPPC = () => {
  const { selectedClient } = useClientStore((state) => state);
  const { campaignsReport } = useGoogleStore((state) => state);

  const devicesResponse = campaignsReport?.campaignDevices;

  const clicks = devicesResponse?.map((item) => ({
    name: item.device,
    value: item?.clicks,
  }));
  const conversions = devicesResponse?.map((item) => ({
    name: item.device,
    value: item?.conversions,
  }));
  const costs = devicesResponse?.map((item) => ({
    name: item.device,
    value: item?.cost,
  }));
  const impressions = devicesResponse?.map((item) => ({
    name: item.device,
    value: item?.impressions,
  }));

  return (
    <>
      <div className="block sm:hidden">
        <MobileView
          campaignsReport={campaignsReport}
          clicks={clicks}
          conversions={conversions}
          costs={costs}
          impressions={impressions}
        />
      </div>
      <div className="hidden sm:block">
        <h2 className="title-text text-base sm:text-[1.85rem] pb-1">
          Devices Overview
        </h2>

        <div className="w-full flex flex-col gap-8">
          <Grid numItemsMd={1} numItemsLg={2} className="gap-4 mt-4">
            <Col>
              <DonutBarGroup
                data={clicks}
                trendTxt={false}
                title={`Clicks`}
                variant="pie"
                category="value"
                index="name"
                showLegend={true}
                customClass="h-[312px]"
                categories={['DESKTOP', 'MOBILE', 'TABLET', 'OTHER']}
                legendCustomClass="max-w-[100px]"
                info={`Clicks per device`}
                loading={campaignsReport?.loading}
                icon="googleAd"
                propertyId={`Customer ID: ${selectedClient?.google_ads?.customer_id}`}
              />
            </Col>
            <Col>
              <DonutBarGroup
                data={conversions}
                trendTxt={false}
                title={`Conversions`}
                variant="pie"
                category="value"
                index="name"
                showLegend={true}
                customClass="h-[312px]"
                categories={['DESKTOP', 'MOBILE', 'TABLET', 'OTHER']}
                legendCustomClass="max-w-[100px]"
                info={`Conversions per device`}
                loading={campaignsReport?.loading}
                icon="googleAd"
                propertyId={`Customer ID: ${selectedClient?.google_ads?.customer_id}`}
              />
            </Col>
            <Col>
              <DonutBarGroup
                data={costs}
                trendTxt={false}
                title={`Cost`}
                variant="pie"
                category="value"
                index="name"
                showLegend={true}
                customClass="h-[312px]"
                categories={['DESKTOP', 'MOBILE', 'TABLET', 'OTHER']}
                legendCustomClass="max-w-[100px]"
                info={`Cost per device`}
                loading={campaignsReport?.loading}
                isDollar={true}
                icon="googleAd"
                propertyId={`Customer ID: ${selectedClient?.google_ads?.customer_id}`}
              />
            </Col>
            <Col>
              <DonutBarGroup
                data={impressions}
                trendTxt={false}
                title={`Impressions`}
                variant="pie"
                category="value"
                index="name"
                showLegend={true}
                customClass="h-[312px]"
                categories={['DESKTOP', 'MOBILE', 'TABLET', 'OTHER']}
                legendCustomClass="max-w-[100px]"
                info={`Impressions per device`}
                loading={campaignsReport?.loading}
                icon="googleAd"
                propertyId={`Customer ID: ${selectedClient?.google_ads?.customer_id}`}
              />
            </Col>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default DeviceOverviewPPC;
