import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const ChatIcon: React.FC<IconProps> = ({ color = 'black', size = 80 }) => (
  <svg
    width={size}
    height={size + 1}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.09436 11.5766C6.03221 11.176 5.99996 10.7656 5.99996 10.3478C5.99996 5.9295 9.60525 2.34778 14.0526 2.34778C18.4999 2.34778 22.1052 5.9295 22.1052 10.3478C22.1052 11.3458 21.9213 12.3012 21.5852 13.1823C21.5154 13.3653 21.4804 13.4568 21.4646 13.5282C21.4489 13.599 21.4428 13.6488 21.4411 13.7212C21.4394 13.7944 21.4493 13.875 21.4692 14.0361L21.8717 17.3063C21.9153 17.6603 21.9371 17.8373 21.8782 17.966C21.8266 18.0787 21.735 18.1683 21.6211 18.2173C21.4911 18.2732 21.3146 18.2473 20.9617 18.1956L17.7765 17.7287C17.6101 17.7043 17.527 17.6921 17.4512 17.6925C17.3763 17.693 17.3245 17.6985 17.2511 17.7139C17.177 17.7295 17.0823 17.765 16.893 17.8359C16.0097 18.1668 15.0524 18.3478 14.0526 18.3478C13.6344 18.3478 13.2237 18.3161 12.8227 18.2551M7.63158 22.3478C10.5965 22.3478 13 19.8853 13 16.8478C13 13.8102 10.5965 11.3478 7.63158 11.3478C4.66668 11.3478 2.26316 13.8102 2.26316 16.8478C2.26316 17.4584 2.36028 18.0457 2.53955 18.5945C2.61533 18.8265 2.65322 18.9425 2.66566 19.0217C2.67864 19.1044 2.68091 19.1509 2.67608 19.2345C2.67145 19.3146 2.65141 19.4051 2.61134 19.5861L2 22.3478L4.9948 21.9388C5.15827 21.9165 5.24 21.9053 5.31137 21.9058C5.38652 21.9063 5.42641 21.9104 5.50011 21.925C5.5701 21.939 5.67416 21.9757 5.88227 22.0492C6.43059 22.2427 7.01911 22.3478 7.63158 22.3478Z"
      stroke={color || '#334969'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ChatIcon;
