import React, { useState } from 'react';
import DomainDropdown from '../../DomainDropdown';
import DateRangeComp from '../../Calendar';
import useAuthStore from '../../../store/useAuthStore';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import Select from 'react-select';
import { Globe } from '../../../assets/icons';
import useClientStore from '../../../store/useClientStore';
import ReactSelect from '../../ReactSelect';
import Icon from '../../../assets/icons/SvgComponent';
import { useThemeStore } from '../../../store/useThemeStore';
import useUtilityStore from '../../../store/useUtilityStore';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import useReportStore from '../../../store/useReportStore';
import { getServices } from '../../../utils';

interface Domain {
  id: string;
  name: string;
}
interface PageHeaderProps {
  title?: string;
  subtitle?: string;
  domains?: Domain[];
  hideDate?: boolean;
  strategy?: boolean;
  customClass?: string;
  hideDomain?: boolean;
}

interface UtilityStoreState {
  isHeaderHidden: boolean;
}

interface ServicesOption {
  label: string;
  value: string;
}

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    minWidth: '120px',
    borderRadius: '8px',
    display: 'flex',
    borderColor: '#808EA2',
    paddingLeft: 30,
  }),
  placeholder: (defaultStyles: any) => {
    return {
      ...defaultStyles,
      color: '#334969',
    };
  },
};

const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  subtitle,
  domains,
  hideDate = false,
  customClass,
  hideDomain = false,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isHeaderHidden } = useUtilityStore() as UtilityStoreState;
  const { mode } = useThemeStore((state: any) => state);
  const iconColor = mode === 'dark' ? 'white' : 'black';
  const { selectedClient } = useClientStore((state) => state);
  const { reportDate } = useReportStore((state) => state.filters);
  const clientServices = selectedClient?.service;
  const clientCategory = selectedClient?.category_type || '';

  const searchParams = new URLSearchParams(location.search);
  const startDateParam = searchParams.get('startDate');
  const startDateDefault = moment().subtract(1, 'month').format('MM-yyyy');

  const services: ServicesOption[] =
    clientServices?.map((service: string) => ({
      label: getServices(service),
      value: service,
    })) || [];

  // Define a type for each object in the data array
  const { selectedDomain, setDomain } = useAuthStore((state) => state);
  const [selectedService, setSelectedService] = useState(
    new URLSearchParams(location.search).get('service') ?? 'seo_google'
  );
  const selectServiceValue = services.find(
    (item: ServicesOption) => item.value === selectedService
  );
  const clientName = selectedDomain.label || selectedClient?.name;
  const defaultTitle = clientName + ' | ' + clientCategory;
  // Transform the data array to have 'label' and 'id' properties
  const transformedData = domains?.map((item: Domain) => ({
    label: item.name,
    id: item.id,
  }));
  const handleServices = (selectedOption: any) => {
    const searchParams = new URLSearchParams(location.search);
    setSelectedService(selectedOption?.value);
    searchParams.set('service', selectedOption?.value);
    navigate({ search: searchParams?.toString() });
  };

  // handle date for reports
  const handleFiltersChange = (val: any) => {
    const date = moment(val).format('MM-YYYY');
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('startDate', date);
    const updatedSearch = searchParams?.toString();
    location.search = updatedSearch;
    navigate(location);
  };
  const today = moment().format('MM-yyyy');
  const maxDate = moment(today, 'MM-yyyy')
    .subtract(1, 'month')
    .endOf('month')
    .toDate();
  //handle settings logs

  // const handleSettings = (selectedOption: any) => {
  //   const searchParams = new URLSearchParams(location.search);
  //   setSettingsLogs(selectedOption?.value);
  //   searchParams.set('method', selectedOption?.value);
  //   navigate({ search: searchParams?.toString() });
  // };

  const getLink = (selectServiceValue) => {
    switch (selectServiceValue?.value) {
      case 'seo_google':
        return '/analytics/seo-ga4';
      case 'ppc_google':
        return '/analytics/paid-search';
      case 'meta':
        return '/analytics/fb-ads';
      default:
        return '';
    }
  };

  return (
    <div
      id="overview"
      className={`relative flex flex-wrap items-start justify-between bg-[#D2EEFF] py-4 sticky top-[110px] lg:top-[63px] dark:bg-[#0C111D] ${customClass} font-inter z-10 ${isHeaderHidden ? 'opacity-0 sm:opacity-100' : 'opacity-100'}`}
    >
      <div className="flex flex-col gap-1">
        <h1 className=" text-3xl font-semibold text-[#00375F] dark:text-white ">
          {title || defaultTitle}
        </h1>
        {/* {title === 'Logs' ? (
          <div className="flex  justify-between items-center bg-white  dark:bg-gray-800">
            <div className="flex items-center gap-4   dark:bg-gray-800 sticky top-0 z-50">
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <Icon
                    name="SearchRefractionIcon"
                    color={mode === 'dark' ? '#fff' : iconColor}
                  />
                </div>
                <input
                  type="text"
                  onChange={(e) => console.log(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                    }
                  }}
                  className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-white focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search for anything"
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                  <Icon
                    name="KeywordsRanking"
                    color={mode === 'dark' ? '#fff' : iconColor}
                  />
                </div>
              </div>
              <div>
                <ReactSelect
                 value={settingsLogsValue}
                  options={settingsOptions}
                  placeholder={settingsLogsValue.label ? settingsLogsValue.label : 'All Methods'}
                  handleOnChange={handleSettings}
                />
              </div>
              <div>
                <ReactSelect
                  options={[
                    { value: 'Report', label: 'Report' },
                    { value: 'Client', label: 'Client' },
                    { value: 'User', label: 'User' },
                  ]}
                  placeholder="Report"
                  handleOnChange={(selectedOption: any) =>
                    console.log(selectedOption)
                  }
                />
              </div>
            </div>
          </div>
        ) : null} */}
        {title === 'Security' ? (
          <div className="flex  justify-between items-center mt-2 bg-[#D2EEFF]  dark:bg-gray-800">
            <div className="flex items-center gap-4   dark:bg-gray-800 sticky top-0 z-50">
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <Icon
                    name="SearchRefractionIcon"
                    color={mode === 'dark' ? '#fff' : iconColor}
                  />
                </div>
                <input
                  type="text"
                  onChange={(e) => console.log(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                    }
                  }}
                  className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-white focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search for anything"
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                  <Icon
                    name="KeywordsRanking"
                    color={mode === 'dark' ? '#fff' : iconColor}
                  />
                </div>
              </div>
              <div>
                <ReactSelect
                  options={[
                    { value: 'meta', label: 'Meta' },
                    { value: 'google', label: 'Google' },
                  ]}
                  placeholder="Meta"
                  handleOnChange={(selectedOption: any) => {}}
                />
              </div>
            </div>
          </div>
        ) : null}
        {title !== 'Monthly Reports' && (
          <div>
            <span className="text-[#636466] text-base dark:text-[#ADB0B2]">
              {subtitle}
            </span>
            {!hideDomain && selectedClient?.domain && (
              <Link
                to={'https://' + selectedClient?.domain || ''}
                className="text-[#636466] text-[10px] font-inter dark:text-[#ADB0B2] border-2 p-1 ml-1 rounded-lg border-[#005C9F] hover:bg-[#005C9F] hover:text-white dark:border-[#005C9F] dark:hover:bg-[#005C9F] dark:hover:text-white"
                target="_blank"
              >
                Domain
              </Link>
            )}
          </div>
        )}
        {location.pathname === '/change-request' && (
          <div className="flex gap-x-8 items-center">
            <span className="text-[#475467]">
              <span className="text-[#005C9F] font-semibold">SEO</span>{' '}
              Pending:26 Revenue: $63,930
            </span>
            <span className="text-[#475467]">
              <span className="text-[#005C9F] font-semibold">PPC</span>{' '}
              Pending:26 Revenue: $63,930
            </span>
            <span className="text-[#475467]">
              <span className="text-[#005C9F] font-semibold">REM</span>{' '}
              Pending:0 Revenue: $0
            </span>
            <span className="text-[#475467]">
              <span className="text-[#005C9F] font-semibold">SMA</span>{' '}
              Pending:26 Revenue: $63,930
            </span>
            <span className="text-[#475467]">
              <span className="text-[#005C9F] font-semibold">Total</span>{' '}
              Pending:26 Revenue: $63,930
            </span>
          </div>
        )}
      </div>
      <div className="relative flex lg:gap-4 sm:gap-2 justify-center flex-wrap">
        {location.pathname === '/reports' && (
          <>
            <div className="relative">
              <Select
                options={services}
                value={selectServiceValue}
                onChange={handleServices}
                placeholder={
                  selectServiceValue ? selectServiceValue.label : 'Services'
                }
                isSearchable={false}
                styles={customStyles}
                defaultValue={selectServiceValue}
              />
              <div className="absolute left-3 top-[18px] -translate-y-1/2">
                <Globe />
              </div>
            </div>
            <div className="w-[100px] z-40 font-inter">
              <DatePicker
                selected={moment(
                  startDateParam ? startDateParam : startDateDefault,
                  'MM-yyyy'
                ).toDate()}
                onChange={(date) => {
                  if (date !== null) {
                    handleFiltersChange(date);
                  }
                }}
                dateFormat="MMM yyyy"
                maxDate={maxDate}
                showMonthYearPicker
                className="react-datepicker__month-container text-center h-[38px] border border-[#D1D5DB] cursor-pointer myDatePicker bg-blue dark:bg-[#374151] dark:text-white dark:border-none font-inter rounded-lg text-[14px] text-[#525252]"
              />
            </div>
            {transformedData && transformedData.length > 1 ? (
              <div>
                <DomainDropdown
                  customStyle={{ width: '100%', minWidth: '180px' }}
                  labelKey="label"
                  valueKey="id"
                  onSelect={(domain) => setDomain(domain)}
                  defaultValue={selectedDomain.label}
                  placeholder="Select Domain"
                  options={transformedData || []}
                />
              </div>
            ) : null}
            <Link
              to={getLink(selectServiceValue)}
              className="flex gap-2 items-center bg-white px-3 py-1 rounded-md h-[38px]"
            >
              <div className="flex justify-center items-center">View More</div>
              <Icon name="arrowRight" color="#000" />
            </Link>
          </>
        )}

        {!hideDate ? (
          <div>
            <DateRangeComp disabled={false} style={{ position: 'initial' }} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PageHeader;
