import { create } from 'zustand';
import Api from '../api';
import { devtools } from 'zustand/middleware';
import useAuthStore from './useAuthStore';
import {
  clientStatusObj,
  statusObj,
  clientsOptions,
  missingOptions,
  healthStatusObj,
} from '../components/ReportTanStackTable/staticData';
import {
  getUserReportType,
  isApprovalUser,
  getCurrentCycle,
} from '../utils/report';
import moment from 'moment';
const user = useAuthStore.getState();
const previousMonth = moment().subtract(1, 'month').format('MM-YYYY');
const initialStatus = {
  loading: true,
  errorMsg: '',
  isSuccessful: true,
};
const useWeeklyReportStore = create(
  devtools((set, get) => ({
    reports: [],
    report: {},
    error: false,
    loading: false,
    errorMessages: '',
    errorOptions: [],
    summary: [],
    healthHistory: [],
    comments: [],
    reportHSInsights: [],
    selectedReport: {},
    summaryLoading: false,
    filters: {
      selectedClientStatus: clientStatusObj['Current Client'],
      searchText: '',
      cycle: getCurrentCycle(),
      type: getUserReportType(user),
      reportStatus: statusObj['in_progress'],
      reportDate: previousMonth,
      all: isApprovalUser(user) ? clientsOptions[1] : clientsOptions[0],
      select: missingOptions[0],
      sortBy: '',
      healthStatus: healthStatusObj['all'],
      sortOrder: 'asc',
    },
    setFilters: (newFilters) => {
      set((state) => ({ filters: { ...state.filters, ...newFilters } }));
    },
    fetchReports: async (filters) => {
      set(() => ({ reports: initialStatus }));
      const response = await Api.Report.getReportsv2(filters);
      const resData = { ...response, loading: false };
      set(() => ({ reports: resData }));
    },
    fetchErrors: async (filters) => {
      set(() => ({ errorOptions: initialStatus }));
      const response = await Api.Report.getErrors(filters);
      const resData = { ...response, loading: false };
      set(() => ({ errorOptions: resData }));
    },
    fetchReportDetails: async (filters) => {
      set(() => ({ loading: true }));
      const response = await Api.Report.getReport(filters);
      set(() => ({ report: response }));
      set(() => ({ loading: false }));
    },
    fetchReportDetailsV2: async (filters) => {
      set(() => ({ selectedReport: initialStatus }));
      const response = await Api.Report.getReport(filters);
      const resData = { ...response, loading: false };

      set(() => ({ selectedReport: resData }));
    },
    fetchReportSummary: async (filters) => {
      set(() => ({ summary: {}, summaryLoading: true }));
      const response = await Api.Report.summary(filters);
      set(() => ({ summary: response, summaryLoading: false }));
    },
    fetchHealthHistory: async (filters) => {
      set(() => ({ loading: true }));
      const response = await Api.Report.healthStatusHistory(filters);
      set(() => ({ healthHistory: response }));
      set(() => ({ loading: false }));
    },
    fetchComments: async (filters) => {
      set(() => ({ loading: true }));
      const response = await Api.Report.comments(filters);
      set(() => ({ comments: response }));
      set(() => ({ loading: false }));
    },
    createReport: async (data) => {
      set(() => ({
        loading: true,
        error: false,
        errorMessages: '',
      }));
      const response = await Api.Report.create(data);
      //TODO this
      // let reports = get().reports;
      // let updatedReport = reports.data?.find(
      //   (item) =>
      //     item.client_id === response.client_id ||
      //     item.client_id === response?.client_id?._id
      // );
      // response.client_id = response?.client_id?._id || response.client_id;
      // response.id = response._id || response.id;
      // delete response._id
      // delete response.__v
      // updatedReport = response;
      // updatedReport.report = response.report;
      // const reportIndex = reports.data.findIndex(
      //   (item) => item.client_id === response.client_id
      // );
      // reports.data.splice(reportIndex, 1, updatedReport);
      if (response[0]?.error) {
        set(() => ({
          loading: false,
          error: true,
          errorMessage: response[0]?.error,
        }));
        throw new Error(response[0]?.error);
      } else {
        set(() => ({
          loading: false,
          error: false,
          errorMessages: '',
          // reports: reports,
        }));
      }
    },
    updateReport: async (id, data) => {
      set(() => ({
        loading: true,
        error: false,
        errorMessages: '',
      }));
      const response = await Api.Report.update(id, data);
      //TODO this
      // let reports = get().reports;
      // const updatedIndex = reports.data.findIndex(
      //   (item) => item.id === response._id
      // );

      // if (updatedIndex >= 0) {
      //   response.client_id = response?.client_id?._id || response.client_id;
      //   response.id = response._id || response.id;
      //   delete response._id
      //   delete response.__v
      //   reports.data[updatedIndex] = response;
      // }
      if (response[0]?.error) {
        set(() => ({
          loading: false,
          error: true,
          errorMessage: response[0].error,
        }));
        throw new Error(response[0]?.error);
      } else {
        set(() => ({
          loading: false,
          error: false,
          errorMessages: '',
        }));
      }
    },
    bulkReportUpdate: async (data) => {
      set(() => ({
        loading: true,
        error: false,
        errorMessages: '',
      }));
      const response = await Api.Report.bulkUpdate(data);
      if (response[0]?.error) {
        set(() => ({
          loading: false,
          error: true,
          errorMessage: response[0].error,
        }));
        throw new Error(response[0]?.error);
      } else {
        let tempReports = get()?.reports;
        const updatedOrigin = tempReports?.data?.slice()?.map((obj) => {
          const updatedObj = data.find(
            (upObj) => upObj?._id === obj.report?._id
          );
          if (updatedObj) {
            return {
              ...obj,
              report: {
                ...obj.report,
                ...updatedObj,
              },
            };
          }
          return obj;
        });
        tempReports.data = updatedOrigin;
        set(() => ({
          loading: false,
          error: false,
          errorMessages: '',
          reports: tempReports,
        }));
      }
    },
    sendReport: async (data) => {
      set(() => ({
        loading: true,
        error: false,
        errorMessages: '',
      }));
      const response = await Api.Report.send(data);
      if (response[0]?.error) {
        set(() => ({
          loading: false,
          error: true,
          errorMessage: response[0].error,
        }));
        throw new Error(response[0]?.error);
      } else {
        set(() => ({
          loading: false,
          error: false,
          errorMessages: '',
        }));
      }
    },
    uploadReportsFile: async (data) => {
      set(() => ({
        loading: true,
        error: false,
        errorMessages: '',
      }));
      const response = await Api.Report.uploadReports(data);
      if (response[0]?.error) {
        set(() => ({
          loading: false,
          error: true,
          errorMessage: response[0].error,
        }));
        throw new Error(response[0]?.error);
      } else {
        set(() => ({
          loading: false,
          error: false,
          errorMessages: '',
        }));
      }
      return response;
    },
    checkUploadStatus: async () => {
      const response = await Api.Report.getUploadStatus();
      return response?.length ? response : [{ status: false }];
    },
    getHSInsights: async (data) => {
      const response = await Api.Report.getHSInsights(data);
      set(() => ({ reportHSInsights: response }));
      return response?.length ? response : [{ status: false }];
    },
    generateReport: async (data) => {
      set(() => ({
        loading: true,
        error: false,
        errorMessages: '',
      }));
      const response = await Api.Report.generate(data);
      if (response[0]?.error) {
        set(() => ({
          loading: false,
          error: true,
          errorMessage: response[0].error,
        }));
        throw new Error(response[0]?.error);
      } else {
        set(() => ({
          loading: false,
          error: false,
          errorMessages: '',
        }));
      }
      return response;
    },
  }))
);

export default useWeeklyReportStore;
