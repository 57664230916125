import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const GoogleAnalytics: React.FC<IconProps> = ({
  color = '#428B6E',
  size = 35,
}) => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    version="1.1"
    id="Logo"
    xmlns="ns_extend"
    x="0px"
    y="0px"
    viewBox="0 0 67.8 82.6"
    xmlSpace="preserve"
  >
    <g transform="matrix(.363638 0 0 .363636 -3.272763 -2.909091)">
      <path
        d="M130 29v132c0 14.77 10.2 23 21 23 10 0 21-7 21-23V30c0-13.54-10-22-21-22s-21 9.33-21 21z"
        fill="#f9ab00"
      />
      <g fill="#e37400">
        <path d="M75 96v65c0 14.77 10.2 23 21 23 10 0 21-7 21-23V97c0-13.54-10-22-21-22s-21 9.33-21 21z" />
        <circle cx="41" cy="163" r="21" />
      </g>
    </g>
  </svg>
);

export default GoogleAnalytics;
