import React from 'react';

interface IconProps {
  color?: string;
}

const Sort: React.FC<IconProps> = ({ color = '#428B6E' }) => (
  <svg
    width="18"
    height="18"
    fill="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 8a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1Z"></path>
    <path d="M8 12a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1Z"></path>
    <path d="M11 15a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2h-2Z"></path>
  </svg>
);

export default Sort;
