/* eslint-disable array-callback-return */
/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/alt-text */
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
} from '@tremor/react';
import { motion } from 'framer-motion';
import { useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { typeObj } from '../../ReportTanStackTable/staticData';
import Icon from '../../../assets/icons/SvgComponent';

const ReportPreview = ({ clientReport, onClose }: any) => {
  const citations = clientReport?.report?.citations;

  const clientData = clientReport?.report;

  const strategy_url =
    clientReport?.report?.strategy_url || clientReport?.report?.strategy;
  const dashthis_url = clientReport?.report?.dashthis_url;
  const rank_ranger_url = clientReport?.report?.rank_ranger_url;
  const outreach_url = clientReport?.report?.outreach_url;
  const isSeo = clientReport?.report_type?.includes('seo');
  // const reportData = Object.keys(clientData)?.map((key) => {
  //   const excludeTitles = [
  //     'Dashthis Url',
  //     'Dashthis',
  //     'Outreach Url',
  //     'Rank Ranger Url',
  //     'Reporting Method',
  //     'Citations',
  //     'Links',
  //     'Weekly Report',
  //     'Weekly Report Method',
  //   ];
  //   const title = key
  //     ?.split('_')
  //     .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  //     .join(' ');
  //   if (excludeTitles.includes(title)) return;
  //   if (Array.isArray(clientData[key]) && clientData[key].length === 0) return;
  //   function createMarkup() {
  //     return { __html: clientData[key] };
  //   }
  //   console.log({ title });

  //   return (
  //     <div
  //       className="flex flex-col justify-start px-[60px] w-full py-4 gap-5 "
  //       key={key}
  //       onClick={(e) => e.stopPropagation()}
  //     >
  //       <span className="text-[#001C44] font-semibold dark:text-white">
  //         {title}
  //       </span>

  //       {title === 'Strategy' && strategy_url ? (
  //         <div className="flex flex-col w-[350px] pt-7">
  //           <div>
  //             <p className="text-sm">
  //               <a
  //                 href={strategy_url}
  //                 className="w-fit text-white text-center inline-block rounded-full text-xs bg-[#107af2] p-3 mb-8"
  //                 target="_blank"
  //                 rel="noreferrer"
  //               >
  //                 Click here for Strategy Docs
  //               </a>
  //             </p>
  //           </div>
  //         </div>
  //       ) : (
  //         <div
  //           className="content  w-full min-w-[300px] overflow-hidden  pt-2 text-sm text-[#636466] dark:text-gray-300"
  //           dangerouslySetInnerHTML={createMarkup()}
  //         ></div>
  //       )}
  //       {title !== 'Links' && (
  //         <div
  //           style={{
  //             position: 'relative',
  //             display: 'flex',
  //             alignItems: 'start',
  //             flexDirection: 'column',
  //             justifyContent: 'stcenterart',
  //           }}
  //         >
  //           {title === 'Overview' && dashthis_url && (
  //             <a
  //               href={dashthis_url}
  //               className="w-fit text-white text-center inline-block rounded-full text-xs bg-[#107af2] p-3 mb-8"
  //               target="_blank"
  //               rel="noreferrer"
  //             >
  //               Click Here for your Dashboard
  //             </a>
  //           )}
  //           {title === 'Keywords Ranking' && rank_ranger_url && (
  //             <a
  //               href={rank_ranger_url}
  //               className="w-fit text-white text-center inline-block rounded-full text-xs bg-[#107af2] p-3 mb-8"
  //               target="_blank"
  //               rel="noreferrer"
  //             >
  //               Click Here for your Ranking
  //             </a>
  //           )}
  //         </div>
  //       )}

  //       {title !== 'Links' && (
  //         <div
  //           style={{
  //             borderBottom: '2px solid #ccc',
  //             width: '75%',
  //             marginLeft: '10%',
  //           }}
  //         ></div>
  //       )}
  //     </div>
  //   );
  // });
  let reportData = [
    {
      title: 'Overview',
      key: 'overview',
      content: clientData?.report?.overview,
    },
    {
      title: 'Traffic and Conversion',
      key: 'traffic_and_conversion',
      content: clientData?.report?.traffic_and_conversion,
    },
    {
      title: 'Keywords Ranking',
      key: 'keywords_ranking',
      content: clientData?.report?.keywords_ranking,
    },
    {
      title: 'Opportunities',
      key: 'opportunities',
      content: clientData?.report?.opportunities,
    },
    {
      title: 'Work Completed',
      key: 'work_completed',
      content: clientData?.report?.work_completed,
    },
    {
      title: 'Work Planned',
      key: 'work_planned',
      content: clientData?.report?.work_planned,
    },
    {
      title: 'Strategy',
      key: 'strategy',
      content: clientData?.strategy,
    },
  ];
  if (!isSeo) {
    reportData = reportData
      .concat([
        {
          title: 'Performance',
          key: 'performance',
          content: clientData?.report?.performance,
        },
      ])
      .filter(
        (item) =>
          item.key !== 'traffic_and_conversion' &&
          item.key !== 'keywords_ranking'
      );
  }
  const content = reportData?.map((item, index) => {
    function createMarkup() {
      return { __html: clientData[item.key] };
    }
    return (
      <div
        className="flex flex-col justify-start px-4 sm:px-[60px] w-full py-4 gap-5 "
        key={index}
        onClick={(e) => e.stopPropagation()}
      >
        <span className="text-[#001C44] font-semibold dark:text-white">
          {item.title}
        </span>

        {item.title === 'Strategy' && strategy_url ? (
          <div className="flex flex-col w-[350px] pt-7">
            <div>
              <p className="text-sm">
                <a
                  href={strategy_url}
                  className="w-fit text-white text-center inline-block rounded-full text-xs bg-[#107af2] p-3 mb-8"
                  target="_blank"
                  rel="noreferrer"
                >
                  Click here for Strategy Docs
                </a>
              </p>
            </div>
          </div>
        ) : (
          <div
            className="content  w-full min-w-[300px] overflow-hidden  pt-2 text-sm text-[#636466] dark:text-gray-300"
            dangerouslySetInnerHTML={createMarkup()}
          ></div>
        )}
        {item.title !== 'Links' && (
          <div
            style={{
              position: 'relative',
              display: 'flex',
              alignItems: 'start',
              flexDirection: 'column',
              justifyContent: 'stcenterart',
            }}
          >
            {item.title === 'Overview' && dashthis_url && (
              <a
                href={dashthis_url}
                className="w-fit text-white text-center inline-block rounded-full text-xs bg-[#107af2] p-3 mb-8"
                target="_blank"
                rel="noreferrer"
              >
                Click Here for your Dashboard
              </a>
            )}
            {item.title === 'Keywords Ranking' && rank_ranger_url && (
              <a
                href={rank_ranger_url}
                className="w-fit text-white text-center inline-block rounded-full text-xs bg-[#107af2] p-3 mb-8"
                target="_blank"
                rel="noreferrer"
              >
                Click Here for your Ranking
              </a>
            )}
          </div>
        )}

        {item.title !== 'Links' && (
          <div
            style={{
              borderBottom: '2px solid #ccc',
              width: '75%',
              marginLeft: '10%',
            }}
          ></div>
        )}
      </div>
    );
  });

  const reportType = typeObj[clientReport?.report_type]?.label ?? 'Unknown';

  return (
    <motion.div
      className="flex flex-col items-center w-screen sm:w-[40%] h-full bg-white fixed right-0 top-0 z-50 pt-0 shadow overflow-y-auto dark:bg-gray-900 dark:text-white  scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8"
      initial={{ x: '100%' }}
      exit={{ x: '100%' }}
      animate={{ x: 0 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="absolute sm:hidden top-3 right-3" onClick={onClose}>
        <Icon name="Xclose" size={30} />
      </div>
      <div className="flex items-start w-full mt-12 sm:mt-0 min-h-[350px] shadow-lg bg-[url(http://cdn.mcauto-images-production.sendgrid.net/f4107d7db4be7637/78e18a94-79de-42bf-9fd1-eb0d438e146e/652x342.png)] bg-no-repeat bg-cover">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <a href="https://www.onlinemarketinggurus.com/" title="logo">
            <img
              width="300"
              src={
                'https://gurulytics-assets.s3.ap-southeast-2.amazonaws.com/email-icons/flat_light_omg.png'
              }
            />
          </a>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: 80,
              marginRight: 20,
            }}
          >
            <a
              href="https://www.facebook.com/TheOnlineMarketingGurus/"
              title="facebook"
            >
              <img
                width="20"
                src={
                  'https://gurulytics-assets.s3.ap-southeast-2.amazonaws.com/email-icons/flat-light-round-facebook.png'
                }
              />
            </a>
            <a
              href="https://www.linkedin.com/company/online-marketing-gurus/"
              title="logo"
            >
              <img
                width="20"
                src={
                  'https://gurulytics-assets.s3.ap-southeast-2.amazonaws.com/email-icons/flat-light-round-linkedin.png'
                }
              />
            </a>
            <a
              href="https://www.instagram.com/onlinemarketinggurus/"
              title="logo"
            >
              <img
                width="20"
                src={
                  'https://gurulytics-assets.s3.ap-southeast-2.amazonaws.com/email-icons/flat-light-round-instagram.png'
                }
              />
            </a>
          </div>
        </div>
      </div>
      <div className="flex items-center text-white text-lg justify-center py-4 w-full sticky top-0 mt-4 z-10 bg-[#107af2]">
        <h3>{reportType} - Account Overview.</h3>
      </div>
      <a
        href={`https://${clientReport.domain}`}
        title="logo"
        target="_blank"
        style={{ color: '#15c', padding: 30, fontSize: 18 }}
        rel="noreferrer"
      >
        <strong>{clientReport.domain}</strong>
      </a>
      {content}

      <div className="flex flex-col items-start justify-start w-full px-2 sm:px-[60px]">
        {clientData?.links?.length || citations?.length ? (
          <>
            <span style={{ fontWeight: 700, marginBottom: 15 }}>Links</span>
            <span
              style={{
                fontSize: 13,
                padding: 0,
                width: '94%',
                color: 'rgb(74, 72, 72)',
              }}
            >
              Here is a collection of the links we obtained for you in this
              cycle. Furthermore, we have created an outreach report (see link
              below the table) that showcases the significant efforts we
              undertook to reach out to different websites over the last 30
              days, all aimed at securing these links. If you are unable to view
              the report, please contact your Account Manager to arrange access.
            </span>
          </>
        ) : null}
        {clientData?.links?.length ? (
          <Table className=" w-full mt-5 text-sm mb-4 border border-[#aaa]">
            <TableHead>
              <TableRow className="border-t text-sm text-gray-600 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400">
                <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
                  Anchor
                </TableHeaderCell>
                <TableHeaderCell className="text-center text-tremor-content-strong dark:text-dark-tremor-content-strong">
                  Domain Ranking
                </TableHeaderCell>
                <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
                  Target
                </TableHeaderCell>
                <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
                  Link
                </TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clientData?.links?.map((item: any) => {
                return (
                  <TableRow
                    key={item.anchor}
                    className="even:bg-tremor-background-muted text-[#292929] dark:text-white  even:dark:bg-dark-tremor-background-muted"
                  >
                    <TableCell>{item.anchor}</TableCell>
                    <TableCell className="text-center">
                      {item.domain_ranking}
                    </TableCell>
                    <TableCell>
                      <a
                        href={item.target}
                        className="text-blue-600"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Target
                      </a>
                    </TableCell>
                    <TableCell>
                      <a
                        href={item.link}
                        className="text-blue-600"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Link
                      </a>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : null}
        {citations?.length ? (
          <>
            <div className="text-[#001C44] text-sm font-semibold dark:text-white mt-2">
              Citations
            </div>
            <table className="w-full text-sm text-left  border border-collapse rounded-lg my-4">
              <thead>
                <tr className="w-full text-sm text-gray-600  bold  px-6 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 ">
                  <th className="p-2">Link</th>
                </tr>
              </thead>
              <tbody>
                {citations?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <div
                          style={{ padding: 10 }}
                          className="link-text dark:text-white"
                        >
                          <a href={item} target="_blank">
                            {item}
                          </a>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        ) : null}

        {outreach_url?.length ? (
          <a
            className="w-fit text-white text-center inline-block rounded-full text-xs bg-[#107af2] p-3 mb-8 ml-10"
            href={outreach_url}
            target="_blank"
            rel="noreferrer"
          >
            Click Here for your Links
          </a>
        ) : null}
      </div>
    </motion.div>
  );
};

export default ReportPreview;
