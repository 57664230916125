interface IProps {
  hasDollar?: boolean;
  color?: string;
  previous?: any;
}

const MetricView = ({ hasDollar, color, previous }: IProps) => {
  return (
    <div className="flex gap-1 items-center font-inter font-[400] text-xs text-[#405573] dark:text-gray-300">
      Prev:
      <span className={`text-${color}-400`}>
        {hasDollar && '$'}
        {previous}
      </span>
    </div>
  );
};

export default MetricView;
