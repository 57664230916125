import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import {
  processData,
  formatNumberWithCommas,
  formatNumber,
} from '../../../utils/common';
import AreaCardChart from '../AreaCardChart';
import useGoogleStore from '../../../store/useGoogleStore';

interface IProps {
  retry?: () => void;
}


const MobileView = ({ retry }: IProps) => {
  const { ga4SeoData } = useGoogleStore((state) => state);
  const organic_conversions = processData(ga4SeoData, 'organic_conversions');
  const lastMonthOrganicConversion =
    Array.isArray(organic_conversions) && organic_conversions.length > 0
      ? organic_conversions[organic_conversions.length - 1]?.value
      : undefined;

  const organic_sessionConversionRate = processData(
    ga4SeoData,
    'organic_sessionConversionRate',
    'Organic Conversions Rate'
  );
  const lastMonthOrganicConversionRate =
    Array.isArray(organic_sessionConversionRate) &&
    organic_sessionConversionRate.length > 0
      ? organic_sessionConversionRate[organic_sessionConversionRate.length - 1]
          ?.value
      : undefined;

  const conversions = processData(
    ga4SeoData,
    'conversions',
    'Total Conversions'
  );
  const totalConversions = processData(
    ga4SeoData,
    'sessionConversionRate',
    'Total Conversions Rate'
  );
  const lastMonthAllConversions =
    Array.isArray(totalConversions) && totalConversions.length > 0
      ? totalConversions[totalConversions.length - 1]?.value
      : undefined;

  const lastMonthConversions =
    Array.isArray(conversions) && conversions.length > 0
      ? conversions[conversions.length - 1]?.value
      : undefined;

  return (
    <div className="block sm:hidden my-4">
      <div className="flex flex-col gap-4">
        <div className="p-0">
          <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
            <SwiperSlide>
              <AreaCardChart
                title={'Organic Conversions'}
                chatData={organic_conversions}
                category={['Organic Conversions']}
                info={
                  ga4SeoData?.organic_conversions?.info
                    ? ga4SeoData?.organic_conversions?.info
                    : 'Impressions'
                }
                prevPeriod={ga4SeoData?.organic_conversions?.previous_period}
                previousYear={ga4SeoData?.organic_conversions?.previous_year}
                isLoading={ga4SeoData.loading}
                totalValue={formatNumberWithCommas(lastMonthOrganicConversion)}
                error={ga4SeoData.error}
                icon="googleAnalytics"
                retryMethod={retry}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title={'Organic Conversions Rate'}
                chatData={organic_sessionConversionRate}
                category={['Organic Conversions Rate']}
                info={
                  ga4SeoData?.organic_sessionConversionRate?.info
                    ? ga4SeoData?.organic_sessionConversionRate?.info
                    : 'Impressions'
                }
                prevPeriod={
                  ga4SeoData?.organic_sessionConversionRate?.previous_period
                }
                previousYear={
                  ga4SeoData?.organic_sessionConversionRate?.previous_year
                }
                isLoading={ga4SeoData.loading}
                totalValue={formatNumberWithCommas(
                  lastMonthOrganicConversionRate
                )}
                error={ga4SeoData.error}
                icon="googleAnalytics"
                retryMethod={retry}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title={'Total Conversions'}
                chatData={conversions}
                category={['Total Conversions']}
                info={
                  ga4SeoData?.conversions?.info
                    ? ga4SeoData?.conversions?.info
                    : 'Impressions'
                }
                prevPeriod={ga4SeoData?.conversions?.previous_period}
                previousYear={ga4SeoData?.conversions?.previous_year}
                isLoading={ga4SeoData.loading}
                totalValue={formatNumberWithCommas(lastMonthConversions)}
                error={ga4SeoData.error}
                icon="googleAnalytics"
                retryMethod={retry}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title={'Total Conversions Rate'}
                chatData={totalConversions}
                category={['Total Conversions Rate']}
                info={'Total Conversions Rate'}
                prevPeriod={ga4SeoData?.sessionConversionRate?.previous_period}
                previousYear={ga4SeoData?.sessionConversionRate?.previous_year}
                isLoading={ga4SeoData.loading}
                totalValue={formatNumberWithCommas(lastMonthAllConversions)}
                error={ga4SeoData.error}
                icon="googleAnalytics"
                retryMethod={retry}
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default MobileView;
