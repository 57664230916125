import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const FeedbackIcon: React.FC<IconProps> = ({ color = 'black', size = 80 }) => (
  <svg
    width="220"
    height="21"
    viewBox="0 0 214 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group">
      <path
        id="Vector"
        d="M0.371826 5.87002C0.371826 2.93146 2.51727 0.851074 5.45583 0.851074C8.39439 0.851074 10.5468 2.93921 10.5468 5.87002C10.5468 8.80084 8.40911 10.8464 5.45583 10.8464C2.50255 10.8464 0.371826 8.80084 0.371826 5.87002ZM5.4628 9.05179C7.27907 9.05179 8.56556 7.76529 8.56556 5.86305C8.56556 3.97552 7.2783 2.65263 5.4628 2.65263C3.66047 2.65263 2.35926 3.97552 2.35926 5.86305C2.35926 7.76529 3.66744 9.05179 5.4628 9.05179Z"
        fill="#334969"
      />
      <path
        id="Vector_2"
        d="M13.05 10.7037V0.993408H14.802L20.1718 7.65745V0.993408H22.102V10.7037H20.3221L14.981 4.11088V10.7037H13.05Z"
        fill="#334969"
      />
      <path
        id="Vector_3"
        d="M32.4056 10.7037H25.2482V0.993408H27.1791V9.03689H32.4056V10.7037Z"
        fill="#334969"
      />
      <path
        id="Vector_4"
        d="M36.4486 10.7037H34.5177V0.993408H36.4486V10.7037Z"
        fill="#334969"
      />
      <path
        id="Vector_5"
        d="M39.5862 10.7037V0.993408H41.3382L46.708 7.65745V0.993408H48.6381V10.7037H46.8582L41.5171 4.11088V10.7037H39.5862Z"
        fill="#334969"
      />
      <path
        id="Vector_6"
        d="M51.7851 0.993408H58.6916V2.66639H53.7152V4.90478H58.034V6.57853H53.7152V9.03766H58.7984V10.7037H51.7843V0.993408H51.7851Z"
        fill="#334969"
      />
      <path
        id="Vector_7"
        d="M65.5949 0.993408H67.6397L70.7711 7.45685L73.8955 0.993408H75.9403V10.7037H74.0102V4.74755L71.393 10.0531H70.1414L67.525 4.74755V10.7037H65.5941V0.993408H65.5949Z"
        fill="#334969"
      />
      <path
        id="Vector_8"
        d="M77.8859 10.7037L81.8042 0.993408H84.0209L87.9462 10.7037H85.8867L85.1719 8.80143H80.6672L79.9453 10.7037H77.8859ZM84.6142 7.26477L82.9195 3.01028L81.2101 7.26477H84.6142Z"
        fill="#334969"
      />
      <path
        id="Vector_9"
        d="M89.8926 10.6967V0.993408H94.175C96.2848 0.993408 97.6503 2.25201 97.6503 4.11088C97.6503 5.3478 96.971 6.36321 95.841 6.84962L98.036 10.7037H95.92L93.9395 7.17105H91.8297V10.6967H89.8918H89.8926ZM91.8305 5.49806H94.0542C95.1191 5.49806 95.6698 4.84746 95.6698 4.11088C95.6698 3.30305 95.1191 2.66639 94.0542 2.66639H91.8305V5.49806Z"
        fill="#334969"
      />
      <path
        id="Vector_10"
        d="M100.334 10.7037V0.993408H102.264V5.39815L106.347 0.993408H108.821L105.081 4.9404L109.042 10.7029H106.697L103.709 6.2269L102.264 7.76434V10.7029L100.334 10.7037Z"
        fill="#334969"
      />
      <path
        id="Vector_11"
        d="M110.911 0.993408H117.818V2.66639H112.841V4.90478H117.16V6.57853H112.841V9.03766H117.925V10.7037H110.91L110.911 0.993408Z"
        fill="#334969"
      />
      <path
        id="Vector_12"
        d="M119.365 0.993408H127.359V2.70976H124.327V10.7037H122.404V2.70976H119.365V0.993408Z"
        fill="#334969"
      />
      <path
        id="Vector_13"
        d="M131.38 10.7037H129.45V0.993408H131.38V10.7037Z"
        fill="#334969"
      />
      <path
        id="Vector_14"
        d="M134.518 10.7037V0.993408H136.27L141.64 7.65745V0.993408H143.571V10.7037H141.79L136.449 4.11088V10.7037H134.519H134.518Z"
        fill="#334969"
      />
      <path
        id="Vector_15"
        d="M146.082 5.84887C146.082 3.01022 148.227 0.85083 151.066 0.85083C152.746 0.85083 154.297 1.65169 155.319 3.13182L153.797 4.21848C153.038 3.0745 152.052 2.63844 151.066 2.63844C149.3 2.63844 148.062 3.96831 148.062 5.84887C148.062 7.72942 149.349 9.05929 151.115 9.05929C152.731 9.05929 153.639 8.10817 153.861 7.07804H150.922V5.41203H155.812C155.841 5.6622 155.848 5.94801 155.848 6.19121C155.848 8.68674 154.089 10.8461 151.115 10.8461C148.284 10.8461 146.082 8.68751 146.082 5.84887Z"
        fill="#334969"
      />
      <path
        id="Vector_16"
        d="M161.949 5.84887C161.949 3.01022 164.094 0.85083 166.933 0.85083C168.613 0.85083 170.164 1.65169 171.186 3.13182L169.664 4.21848C168.905 3.0745 167.919 2.63844 166.933 2.63844C165.167 2.63844 163.929 3.96831 163.929 5.84887C163.929 7.72942 165.216 9.05929 166.982 9.05929C168.598 9.05929 169.506 8.10817 169.728 7.07804H166.789V5.41203H171.679C171.708 5.6622 171.715 5.94801 171.715 6.19121C171.715 8.68674 169.956 10.8461 166.982 10.8461C164.151 10.8461 161.949 8.68751 161.949 5.84887Z"
        fill="#334969"
      />
      <path
        id="Vector_17"
        d="M174.047 7.01382V0.993408H175.971V6.97044C175.971 8.28637 176.915 9.11589 178.201 9.11589C179.488 9.11589 180.433 8.28714 180.433 6.97044V0.993408H182.356V7.01382C182.356 9.34515 180.69 10.8462 178.201 10.8462C175.713 10.8462 174.047 9.34515 174.047 7.01382Z"
        fill="#334969"
      />
      <path
        id="Vector_18"
        d="M185.382 10.6967V0.993408H189.664C191.774 0.993408 193.14 2.25201 193.14 4.11088C193.14 5.3478 192.46 6.36321 191.33 6.84962L193.525 10.7037H191.409L189.429 7.17105H187.319V10.6967H185.382ZM187.32 5.49806H189.544C190.609 5.49806 191.159 4.84746 191.159 4.11088C191.159 3.30305 190.609 2.66639 189.544 2.66639H187.32V5.49806Z"
        fill="#334969"
      />
      <path
        id="Vector_19"
        d="M195.717 7.01382V0.993408H197.64V6.97044C197.64 8.28637 198.584 9.11589 199.87 9.11589C201.157 9.11589 202.102 8.28714 202.102 6.97044V0.993408H204.025V7.01382C204.025 9.34515 202.359 10.8462 199.87 10.8462C197.382 10.8462 195.717 9.34515 195.717 7.01382Z"
        fill="#334969"
      />
      <path
        id="Vector_20"
        d="M207.873 7.52183C208.33 8.45901 209.203 9.10264 210.368 9.10264C211.298 9.10264 212.034 8.65187 212.034 8.02218C212.034 7.37854 211.312 7.0362 210.154 6.67062C208.287 6.08508 206.764 5.5127 206.764 3.63911C206.764 1.8089 208.559 0.850037 210.203 0.850037C211.962 0.850037 213.177 1.88016 213.792 2.95985L212.305 4.01786C211.883 3.30994 211.183 2.59505 210.154 2.59505C209.402 2.59505 208.738 2.99548 208.738 3.63215C208.738 4.41132 209.446 4.52595 210.661 4.95504C212.484 5.59868 214 6.09205 214 8.00824C214 9.91048 212.078 10.8469 210.347 10.8469C208.473 10.8469 207.029 9.8671 206.45 8.55195L207.873 7.52183Z"
        fill="#334969"
      />
    </g>
  </svg>
);

export default FeedbackIcon;
