import { Grid, Col, Card, ScatterChart } from '@tremor/react';
import DonutCardGroup from '../DonutCardChart';
import BarChartCard from '../BarChartCard';
import useMetaStore from '../../../store/useMetaStore';
import InfoTooltip from '../AnalyticsInfoTooltip';
import Icon from '../../../assets/icons/SvgComponent';
import { processData } from '../../../utils/common';
import useClientStore from '../../../store/useClientStore';
import { useRetryFetchFbDemographics } from '../Hooks';

type CustomTooltipTypeScatter = {
  payload: any;
  active: boolean | undefined;
  label: any;
};

interface Demographics {
  age: Record<string, { revenue: number; total_leads: number }>;
  device: Record<string, { revenue: number; total_leads: number }>;
  platform: Record<string, { revenue: number; total_leads: number }>;
  gender: Record<string, { revenue: number; total_leads: number }>;
  loading: boolean;
  error?: string
}

interface HeroData {
  name: string;
  value: number;
}

const AudienceAnalysis = () => {
  const { demographics } = useMetaStore(
    (state): { demographics: Demographics } => state
  );

  const { selectedClient } = useClientStore((state) => state);
  const isLeadGen = selectedClient?.category_type === 'Lead Gen';
  const ageDemographic: { name: string; value: number }[] = Object.entries(
    demographics?.age ?? {}
  ).map(([age, { revenue, total_leads }]): { name: string; value: number } => ({
    name: age,
    value: !isLeadGen ? revenue : total_leads,
  }));

  const ageDemographicCategories = [
    '18-24',
    '25-34',
    '35-44',
    '45-54',
    '45-54', // Note: '45-54' is repeated in your array, it might be a typo
    '55-64',
    '65+',
    'Unknown',
  ];

  const genderDemographic: { name: string; value: number }[] = Object.entries(
    demographics?.gender ?? {}
  ).map(
    ([gender, { revenue, total_leads }]): { name: string; value: number } => ({
      name: gender,
      value: !isLeadGen ? revenue : total_leads,
    })
  );

  const platformDemographic: { name: string; value: number }[] = Object.entries(
    demographics?.platform ?? {}
  ).map(
    ([platform, { revenue, total_leads }]): {
      name: string;
      value: number;
    } => ({
      name: platform,
      value: !isLeadGen ? revenue : total_leads,
    })
  );

  const deviceDemographic: { name: string; value: number }[] = Object.entries(
    demographics?.device ?? {}
  ).map(
    ([device, { revenue, total_leads }]): { name: string; value: number } => ({
      name: device,
      value: !isLeadGen ? revenue : total_leads,
    })
  );
  
  const retryFetchFbDemographics = useRetryFetchFbDemographics()

  return (
    <div>
      <div className="flex justify-start items-center mb-4">
        <h2 className="title-text text-base sm:text-[1.85rem] pb-1 hidden sm:block">
          Audience Analysis
        </h2>
      </div>
      <Card className="px-0 sm:px-6 shadow-none lg:shadow ring-0 lg:ring-1">
        <Grid numItemsMd={1} numItemsLg={2} className="gap-4 mt-4">
          <Col>
            <DonutCardGroup
              data={ageDemographic}
              trendTxt={false}
              title={
                isLeadGen ? 'Leads grouped by Age' : 'Revenue grouped by Age'
              }
              variant="pie"
              category="value"
              index="name"
              showLegend={true}
              customClass="h-[312px]"
              categories={ageDemographicCategories}
              legendCustomClass="max-w-[100px]"
              info={`${demographics?.age?.info ? demographics?.age?.info : 'Revenue grouped by Age'}`}
              isDollar={!isLeadGen}
              icon="meta"
              error={demographics?.error}
              propertyId={`Ads ID: ${selectedClient?.facebook_ads?.ads_id}`}
              retryMethod={retryFetchFbDemographics}
            />
          </Col>
          <Col>
            <DonutCardGroup
              data={genderDemographic}
              trendTxt={false}
              title={
                isLeadGen
                  ? 'Leads grouped by Gender'
                  : 'Revenue grouped by Gender'
              }
              variant="pie"
              category="value"
              index="name"
              categories={['Female', 'Unknown', 'Male']}
              customClass="w-full flex justify-center h-[312px]"
              info={`${demographics?.gender?.info ? demographics?.gender?.info : 'Revenue grouped by Gender'}`}
              isDollar={!isLeadGen}
              icon="meta"
              error={demographics?.error}
              propertyId={`Ads ID: ${selectedClient?.facebook_ads?.ads_id}`}
              retryMethod={retryFetchFbDemographics}
            />
          </Col>
          <Col>
            <DonutCardGroup
              data={platformDemographic}
              trendTxt={false}
              title={
                isLeadGen
                  ? 'Total Leads grouped by Platform'
                  : 'Revenue grouped by Platform'
              }
              variant="pie"
              category="value"
              index="name"
              categories={['Facebook', 'Instagram']}
              customClass="w-full flex justify-center h-[312px]"
              info={`${demographics?.platform?.info ? demographics?.platform?.info : 'Revenue grouped by Platform'}`}
              isDollar={!isLeadGen}
              icon="meta"
              error={demographics?.error}
              propertyId={`Ads ID: ${selectedClient?.facebook_ads?.ads_id}`}
              retryMethod={retryFetchFbDemographics}
            />
          </Col>
          <Col>
            <DonutCardGroup
              data={deviceDemographic}
              trendTxt={false}
              title={
                isLeadGen
                  ? 'Leads grouped by Device'
                  : 'Revenue grouped by Device'
              }
              variant="pie"
              category="value"
              index="name"
              categories={['Desktop', 'Mobile']}
              customClass="w-full flex justify-center h-[312px]"
              info={`${demographics?.device?.info ? demographics?.device?.info : 'Revenue grouped by Device'}`}
              isDollar={!isLeadGen}
              icon="meta"
              error={demographics?.error}
              propertyId={`Ads ID: ${selectedClient?.facebook_ads?.ads_id}`}
              retryMethod={retryFetchFbDemographics}
            />
          </Col>
        </Grid>
      </Card>
    </div>
  );
};

export default AudienceAnalysis;
