import React from 'react';

interface Props {
  color?: string;
  size?: number;
}

const Phone: React.FC<Props> = ({ color = 'black', size = 80 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
  >
    <path
      d="M9.07998 3.33366C9.73114 3.4607 10.3296 3.77916 10.7987 4.24828C11.2678 4.7174 11.5863 5.31584 11.7133 5.96699M9.07998 0.666992C10.4328 0.817283 11.6944 1.42311 12.6575 2.385C13.6206 3.34689 14.228 4.60767 14.38 5.96032M6.5313 8.5757C5.73025 7.77465 5.09772 6.86889 4.63373 5.90248C4.59381 5.81935 4.57386 5.77779 4.55853 5.72519C4.50405 5.53829 4.54318 5.30879 4.65652 5.1505C4.68841 5.10596 4.72652 5.06785 4.80273 4.99165C5.0358 4.75857 5.15233 4.64204 5.22852 4.52485C5.51585 4.08293 5.51585 3.51321 5.22852 3.07128C5.15233 2.95409 5.0358 2.83756 4.80273 2.60449L4.67281 2.47458C4.31852 2.12028 4.14137 1.94313 3.95112 1.8469C3.57274 1.65552 3.1259 1.65552 2.74753 1.8469C2.55727 1.94313 2.38012 2.12028 2.02583 2.47458L1.92074 2.57967C1.56766 2.93275 1.39112 3.10929 1.25628 3.34931C1.10667 3.61565 0.999097 4.02931 1.00001 4.33479C1.00082 4.61009 1.05423 4.79824 1.16103 5.17453C1.73501 7.1968 2.81799 9.10504 4.40998 10.697C6.00197 12.289 7.91021 13.372 9.93247 13.946C10.3088 14.0528 10.4969 14.1062 10.7722 14.107C11.0777 14.1079 11.4914 14.0003 11.7577 13.8507C11.9977 13.7159 12.1743 13.5393 12.5273 13.1863L12.6324 13.0812C12.9867 12.7269 13.1639 12.5497 13.2601 12.3595C13.4515 11.9811 13.4515 11.5343 13.2601 11.1559C13.1639 10.9656 12.9867 10.7885 12.6324 10.4342L12.5025 10.3043C12.2694 10.0712 12.1529 9.95467 12.0357 9.87848C11.5938 9.59115 11.0241 9.59115 10.5822 9.87848C10.465 9.95467 10.3484 10.0712 10.1154 10.3043C10.0392 10.3805 10.001 10.4186 9.95651 10.4505C9.79822 10.5638 9.56871 10.603 9.38181 10.5485C9.32922 10.5331 9.28766 10.5132 9.20453 10.4733C8.23812 10.0093 7.33235 9.37676 6.5313 8.5757Z"
      stroke={color}
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Phone;
