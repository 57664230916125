import React from 'react';

interface LineChartUpProps {
  color?: string;
  size?: number;
}

const LineChartUp: React.FC<LineChartUpProps> = ({
  color = 'black',
  size = 80,
}) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 21.0381H4.6C4.03995 21.0381 3.75992 21.0381 3.54601 20.9291C3.35785 20.8332 3.20487 20.6802 3.10899 20.4921C3 20.2782 3 19.9981 3 19.4381V3.03809M21 7.03809L15.5657 12.4724C15.3677 12.6704 15.2687 12.7694 15.1545 12.8065C15.0541 12.8391 14.9459 12.8391 14.8455 12.8065C14.7313 12.7694 14.6323 12.6704 14.4343 12.4724L12.5657 10.6038C12.3677 10.4058 12.2687 10.3068 12.1545 10.2697C12.0541 10.237 11.9459 10.237 11.8455 10.2697C11.7313 10.3068 11.6323 10.4058 11.4343 10.6038L7 15.0381M21 7.03809H17M21 7.03809V11.0381"
      stroke={color || '#334969'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LineChartUp;
