import { Config } from '../config';
export const isDevelopmentEnv = () => {
  if (['local', 'dev', 'staging'].includes(Config.ENV)) return true;
  else return false;
};

export const getInitials = (name) => {
  let initials;
  const nameSplit = name?.split(' ');
  const nameLength = nameSplit?.length;
  if (nameLength > 1) {
    initials =
      nameSplit[0].substring(0, 1) + nameSplit[nameLength - 1].substring(0, 1);
  } else if (nameLength === 1) {
    initials = nameSplit[0].substring(0, 1);
  } else return;

  return initials.toUpperCase();
};

export const getOmgRoles = (role = 'admin') => {
  if (isAdminRole(role))
    return [
      { label: 'All', id: 'all' },
      { label: 'Admin', id: 'admin' },
      { label: 'Developer', id: 'dev' },
      { label: 'Executive', id: 'exec' },
      // { label: "Account Executive", id: "aexex" },
      { label: 'Account Manager', id: 'am' },
      { label: 'Account Director', id: 'ad' },
      { label: 'Group Account Director', id: 'gad' },
      { label: 'SEO Manager', id: 'seoman' },
      { label: 'Paid Search Manager', id: 'psm' },
      { label: 'Social Manager', id: 'socman' },
      { label: 'SEO Consultant', id: 'seocon' },
      { label: 'Paid Search Consultant', id: 'ppccon' },
      { label: 'Social Consultant', id: 'soccon' },
      { label: 'Pod Lead', id: 'pl' },
      { label: 'OMG Staff', id: 'omgstf' },
      { label: 'Activations Officer', id: 'ac' },
      { label: 'Finance Officer', id: 'fo' },
    ];
  else
    return [
      { label: 'Account Manager', id: 'am' },
      { label: 'SEO Manager', id: 'seoman' },
      { label: 'Paid Search Manager', id: 'adsman' },
      { label: 'Social Manager', id: 'socman' },
      { label: 'SEO Consultant', id: 'seocon' },
      { label: 'Paid Search Consultant', id: 'ppccon' },
      { label: 'Social Consultant', id: 'soccon' },
      { label: 'Pod Lead', id: 'pl' },
    ];
};

export const getRoleName = (id) => {
  const roles = [
    { label: 'All', id: 'all' },
    { label: 'Owner', id: 'own' },
    { label: 'Staff', id: 'stf' },
    { label: 'Admin', id: 'admin' },
    { label: 'Developer', id: 'dev' },
    { label: 'Executive', id: 'exec' },
    // { label: "Account Executive", id: "aexex" },
    { label: 'Account Manager', id: 'am' },
    { label: 'Account Director', id: 'ad' },
    { label: 'Group Account Director', id: 'gad' },
    { label: 'SEO Manager', id: 'seoman' },
    { label: 'Paid Search Manager', id: 'psm' },
    { label: 'Social Manager', id: 'socman' },
    { label: 'SEO Consultant', id: 'seocon' },
    { label: 'Paid Search Consultant', id: 'ppccon' },
    { label: 'Social Consultant', id: 'soccon' },
    { label: 'Pod Lead', id: 'pl' },
    { label: 'OMG Staff', id: 'omgstf' },
    { label: 'Activations Officer', id: 'ac' },
    { label: 'Finance Officer', id: 'fo' },
    { label: 'Account Manager', id: 'am' },
    { label: 'SEO Manager', id: 'seoman' },
    { label: 'Paid Search Manager', id: 'adsman' },
    { label: 'Social Manager', id: 'socman' },
    { label: 'SEO Consultant', id: 'seocon' },
    { label: 'Paid Search Consultant', id: 'ppccon' },
    { label: 'Social Consultant', id: 'soccon' },
    { label: 'Pod Lead', id: 'pl' },
  ];
  const role = roles.find((r) => r.id === id);
  return role ? role.label : '';
};

export const getColumnName = (id) => {
  const roles = [
    { label: 'HealthStatus', id: 'HealthStatus' },
    { label: 'AM', id: 'AM' },
    { label: 'Account Manager', id: 'Account Manager' },
    { label: 'Consultants', id: 'Consultants' },
    { label: 'Overview', id: 'Overview' },
    { label: 'Opportunities', id: 'Opportunities' },
    { label: 'Work Completed', id: 'WorkCompleted' },
    { label: 'Work Planned', id: 'WorkPlanned' },
    { label: 'Performance', id: 'Performance' },
    { label: 'Report Clients', id: 'report_clients' },
    { label: 'Account Manager', id: 'account_manager' },
    { label: 'Consultant', id: 'consultant' },
    { label: 'Impression', id: 'report.seven_days.impressions' },
    { label: 'Clicks', id: 'report.seven_days.clicks' },
    { label: 'Budget to Date', id: 'report.budget_to_date' },
    { label: 'Difference %', id: 'report.budget_difference' },
    { label: 'Budget', id: 'report.monthly_budget' },
    { label: 'Spend', id: 'report.seven_days.roas' },
    { label: 'Budget Spend', id: 'report.spend_to_date' },
    { label: 'Conversions', id: 'report.seven_days.conversions' },
    { label: 'Traffic Conversion', id: 'TrafficConversion' },
    { label: 'Keywords Ranking', id: 'KeywordsRanking' },
    { label: 'Retainer', id: 'revenue' },
    { label: 'GAD', id: 'gad' },
    { label: 'Performance', id: 'performance' },
    { label: 'Start Date', id: 'startDate' },
    { label: 'Organic Revenue', id: 'ga4.organic_revenue' },
    { label: 'Organic Conversions', id: 'ga4.organic_conversions' },
    { label: 'Organic Users', id: 'ga4.organic_new_users' },
    { label: 'New Users', id: 'ga4.new_users' },
    { label: 'Clicks', id: 'gsc.clicks' },
    { label: 'Impressions', id: 'gsc.impressions' },
    { label: 'Ahrefs Top Page', id: 'ahrefs.top_1_keywords_reach' },
    { label: 'Ahrefs 3 Spots', id: 'ahrefs.top_3_keywords_reach' },
    { label: 'Ahrefs Top 10', id: 'ahrefs.top_10_keywords_reach' },
    { label: 'Cost Per Lead', id: 'return.conversions' },
    { label: 'Ecommerce Return', id: 'return.ecommerce' },
    { label: 'Referring Domains', id: 'ahrefs.refdomains' },
    { label: 'HS AM', id: 'hs_Am' },
    { label: 'HS Cons', id: 'hs_cons' },
    { label: 'Weekly Spend', id: 'report.seven_days.cost_micros' },
    { label: 'Start Date', id: 'start_date' },
    { label: 'CTR', id: 'report.seven_days.ctr' },
    { label: 'Average CPC', id: 'report.seven_days.average_cpc' },
    { label: 'CPC', id: 'report.seven_days.cost_per_conversion' },
    { label: 'Conversion Rate', id: 'report.seven_days.conversion_rate' },
    { label: 'Conversion Value', id: 'report.seven_days.conversions_value' },
    { label: 'New Users', id: 'report.seven_days.newUsers' },
    { label: 'Organic Users', id: 'report.seven_days.organic_newUsers' },
    {
      label: 'Organic Sessions',
      id: 'report.seven_days.organic_sessions',
    },
    {
      label: 'Organic Engaged Sessions',
      id: 'report.seven_days.organic_engagedSessions',
    },
    {
      label: 'Organic Conversions',
      id: 'report.seven_days.organic_conversions',
    },
    {
      label: 'Organic Conversions Rate',
      id: 'report.seven_days.organic_conversions_rate',
    },
    {
      label: 'Top 10 Pages',
      id: 'report.seven_days.top_pages_10',
    },
    {
      label: 'Top Page',
      id: 'report.seven_days.top_page',
    },
    {
      label: 'Top 3',
      id: 'report.seven_days.top_3',
    },
    {
      label: 'Referring Domain',
      id: 'report.seven_days.refDomain',
    },
    {
      label: 'Domain Rating',
      id: 'report.seven_days.domain_rating',
    },
    {
      label: 'URL Rating',
      id: 'report.seven_days.url_rating',
    },
    {
      label: 'Clicks',
      id: 'googleAds.clicks',
    },
    {
      label: 'Conversion Rate',
      id: 'googleAds.conversion_rate',
    },
    {
      label: 'Conversions',
      id: 'googleAds.conversions',
    },
    {
      label: 'Conversion Value',
      id: 'googleAds.conversions_value',
    },
    {
      label: 'Cost Per Conversion',
      id: 'googleAds.costPerConversion',
    },
    {
      label: 'CPC',
      id: 'googleAds.cpc',
    },
    {
      label: 'CTR',
      id: 'googleAds.ctr',
    },
    {
      label: 'Impressions',
      id: 'googleAds.impressions',
    },
    {
      label: 'ROAS',
      id: 'googleAds.roas',
    },
    {
      label: 'Impressions',
      id: 'meta.impressions',
    },
    {
      label: 'Reach',
      id: 'meta.reach',
    },
    {
      label: 'Frequency',
      id: 'meta.frequency',
    },
    {
      label: 'CPM',
      id: 'meta.cpm',
    },
    {
      label: 'Link Clicks',
      id: 'meta.link_clicks',
    },
    {
      label: 'CTR',
      id: 'meta.ctr',
    },
    {
      label: 'Website Leads',
      id: 'meta.website_leads',
    },
    {
      label: 'CPL',
      id: 'meta.cpl',
    },
    {
      label: 'CPC',
      id: 'meta.cpc',
    },
    {
      label: 'CVR',
      id: 'meta.cvr',
    },
    {
      label: 'Revenue',
      id: 'meta.revenue',
    },
    {
      label: 'ROAS',
      id: 'meta.roas',
    },
  ];
  const role = roles.find((r) => r.id === id);
  return role ? role.label : '';
};

export const getColumnTitle = (id) => {
  const roles = [
    {
      title:
        'Measures the expense of acquiring a potential customer, calculated by dividing total marketing costs by the number of leads generated.',
      id: 'return.conversions',
    },
    {
      title: `The revenue generated vs. the client's spend on SEO Services. For every $1 spent on SEO the value below displays the return.
If the return below is less than $1, then client is achieving ROI on their SEO. If the value is over $1, the client isn't achieving ROI on their SEO`,
      id: 'return.ecommerce',
    },
    { title: '', id: 'HealthStatus' },
    { title: '', id: 'AM' },
    { title: '', id: 'Account Manager' },
    { title: '', id: 'Consultants' },
    { title: '', id: 'Overview' },
    { title: '', id: 'Opportunities' },
    { title: '', id: 'WorkCompleted' },
    { title: '', id: 'WorkPlanned' },
    { title: '', id: 'Performance' },
    { title: '', id: 'report_clients' },
    { title: '', id: 'account_manager' },
    { title: '', id: 'consultant' },
    { title: '', id: 'report.seven_days.impressions' },
    { title: '', id: 'report.seven_days.clicks' },
    { title: '', id: 'report.budget_to_date' },
    { title: '', id: 'report.budget_difference' },
    { title: '', id: 'report.monthly_budget' },
    { title: '', id: 'report.seven_days.roas' },
    { title: '', id: 'report.spend_to_date' },
    { title: '', id: 'report.seven_days.conversions' },
    { title: '', id: 'TrafficConversion' },
    { title: '', id: 'KeywordsRanking' },
    { title: '', id: 'revenue' },
    { title: '', id: 'gad' },
    { title: '', id: 'performance' },
    { title: '', id: 'startDate' },
    { title: '', id: 'ga4.organic_revenue' },
    { title: '', id: 'ga4.organic_conversions' },
    { title: '', id: 'ga4.organic_new_users' },
    { title: '', id: 'ga4.new_users' },
    { title: '', id: 'gsc.clicks' },
    { title: '', id: 'gsc.impressions' },
    { title: '', id: 'ahrefs.top_1_keywords_reach' },
    { title: '', id: 'ahrefs.top_3_keywords_reach' },
    { title: '', id: 'ahrefs.top_10_keywords_reach' },

    { title: '', id: 'ahrefs.refdomains' },
    { title: '', id: 'hs_Am' },
    { title: '', id: 'hs_cons' },
    { title: '', id: 'report.seven_days.cost_micros' },
    { title: '', id: 'start_date' },
    { title: '', id: 'report.seven_days.ctr' },
    { title: '', id: 'report.seven_days.average_cpc' },
    { title: '', id: 'report.seven_days.cost_per_conversion' },
    { title: '', id: 'report.seven_days.conversion_rate' },
    { title: '', id: 'report.seven_days.conversions_value' },
    { title: '', id: 'report.seven_days.newUsers' },
    { title: '', id: 'report.seven_days.organic_newUsers' },
    {
      title: '',
      id: 'report.seven_days.organic_sessions',
    },
    {
      title: '',
      id: 'report.seven_days.organic_engagedSessions',
    },
    {
      title: '',
      id: 'report.seven_days.organic_conversions',
    },
    {
      title: '',
      id: 'report.seven_days.organic_conversions_rate',
    },
    {
      title: '',
      id: 'report.seven_days.top_pages_10',
    },
    {
      title: '',
      id: 'report.seven_days.top_page',
    },
    {
      title: '',
      id: 'report.seven_days.top_3',
    },
    {
      title: '',
      id: 'report.seven_days.refDomain',
    },
    {
      title: '',
      id: 'report.seven_days.domain_rating',
    },
    {
      title: '',
      id: 'report.seven_days.url_rating',
    },
  ];
  const role = roles.find((r) => r.id === id);
  if (role?.title === '') {
    return;
  } else {
    return role ? role.title : '';
  }
};

export const getClientRoles = () => {
  return [
    { label: 'All', id: 'all' },
    { label: 'Owner', id: 'own' },
    { label: 'Staff', id: 'stf' },
  ];
};

export const monetaryValue = (value) => {
  return `$${value?.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
};

export const isBetween = (x, y, value) => {
  if (x === 0 && y === 0) return true;
  return value >= x && value <= y;
};

export const ordinalNumber = (n) => {
  var s = ['th', 'st', 'nd', 'rd'],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const isAdminRole = (roleId) => {
  if (['admin', 'dev', 'exec', 'fo', 'aexec', 'supadmin'].includes(roleId))
    return true;
  else return false;
};

export const isRoleCanEdit = (roleId, clientsIds, usersClients) => {
  const checkIncludes = clientsIds?.some((clientId) => {
    if (typeof clientId === 'object') {
      return usersClients?.some((userClient) => userClient.id === clientId.id);
    } else {
      return usersClients?.some((userClient) => userClient.id === clientId);
    }
  });
  if (
    [
      'admin',
      'dev',
      'exec',
      'aexex',
      'am',
      'supadmin',
      'own',
      'gad',
      'psm',
      'seoman',
      'socman',
      'ad',
    ].includes(roleId)
  )
    return true;
  else if (checkIncludes) {
    return true;
  } else return false;
};

export const getStatus = (status) => {
  if (status === 'activated' || status === 'activate' || status === 'active')
    return true;
  else return false;
};

export const getServices = (serviceId) => {
  const services = [
    { label: 'Google SEO', id: 'seo_google' },
    { label: 'Bing SEO', id: 'seo_bing' },
    { label: 'Google Ads', id: 'ppc_google' },
    { label: 'Microsoft Ads', id: 'ppc_bing' },
    { label: 'Meta', id: 'social_meta' },
    { label: 'LinkedIn', id: 'social_linkedin' },
    { label: 'Tiktok', id: 'social_tiktok' },
    { label: 'Pinterest ', id: 'social_pinterest' },
    { label: 'Remarketing', id: 'display' },
  ];
  const service = services.find((s) => s.id === serviceId);
  return service ? service.label : '';
};

export const selectStatus = (status) => {
  if (status === 'activated') return 'deactivated';
  else return 'activated';
};

export const isStaging = () => {
  return (
    Config.ENV === 'staging' ||
    Config.API_ROOT === 'https://staging-api.gurulytics.app'
  );
};
