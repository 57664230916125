import React, { useEffect, useState } from 'react';
import Icon from '../../../assets/icons/SvgComponent';
import ReactSelect from 'react-select';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import api from '../../../api';
interface WeeklyProps {
  selectDate?: any;
  closeModal: () => void;
}
const ExportModal: React.FC<WeeklyProps> = ({ selectDate, closeModal }) => {
  const [exportData, setExportData] = useState<any[]>([]);
  const [processedData, setProcessedData] = useState<any[]>([]);
  const [headers, setHeaders] = useState<any[]>([]);
  const [errorMessage, setErrorMessage] = useState('');

  function generateDateOptions(
    value?: string
  ): { value: string; label: string }[] {
    const startDate = moment('2023-10-19'); // Fixed start date
    const currentDate = moment(); // Test date set as Nov 10, 2023. You can replace it with moment() for actual current date.
    const options: { value: string; label: string }[] = [];

    let iteratingDate = startDate.clone();

    while (iteratingDate.isSameOrBefore(currentDate, 'day')) {
      const endOfWeek = iteratingDate.clone().endOf('isoWeek');

      if (endOfWeek.isSameOrBefore(currentDate, 'day')) {
        const startOfWeek = iteratingDate.clone().startOf('isoWeek');

        const monthName1 = startOfWeek.format('MMM');
        const monthName2 = endOfWeek.format('MMM');
        const yearName = startOfWeek.format('YYYY');

        const monthName1v = startOfWeek.format('MM');
        const monthName2v = endOfWeek.format('MM');

        const startDay = startOfWeek.format('DD');
        const endDay = endOfWeek.format('DD');

        const value = `${monthName1v}-${startDay}/${monthName2v}-${endDay}/${yearName}`;
        const label = `${monthName1}-${startDay} - ${monthName2} ${endDay}, ${yearName}`;

        // Check for duplicate entries and only push unique ones
        if (!options.some((option) => option.value === value)) {
          options.push({ value, label });
        }

        iteratingDate.add(7, 'days');
      } else {
        // Exit the loop if the end of the current period is in the future
        break;
      }
    }

    return options.reverse();
  }
  const dateOptions = generateDateOptions();
  const options = dateOptions;
  const statusOptions = [
    { value: 'all', label: 'All Status' },
    { value: 'ready', label: 'Ready' },
    { value: 'flagged', label: 'Flagged' },
    { value: 'sent', label: 'Sent' },
    { value: 'canceled', label: 'Canceled' },
  ];
  const [selectedWeek, setSelectedWeek] = useState<{
    value: string;
    label: string;
  } | null>(selectDate);
  const [status, setStatus] = useState<{
    value: string;
    label: string;
  } | null>({ value: 'flagged', label: 'Flagged' });

  const [loading, setLoading] = useState(false);
  const [downloadReady, setDownloadReady] = useState(false);



  useEffect(() => {
    const flattenedData = exportData.map(flattenObject); // Process each entry in exportData
    setProcessedData(flattenedData); // Assume you have a setState for processedData
  }, [exportData]);

  const fetchExportData = async () => {
    if (!loading) {
      setLoading(true);
      setDownloadReady(false);
      setErrorMessage('');

      const params = {
        period: selectedWeek?.value,
        type: 'ppc_google',
        status: status?.value,
        page: 1,
        limit: 10000,
      };

      try {
        const res = await api.Report.getReportsv2(params);
        if (!res.error && res.data.length > 0) {
          // Check if data is non-empty
          setExportData(res.data);
          setDownloadReady(true); // Enable download only if data is non-empty
        } else {
          // Handle no data or error case
          setDownloadReady(false); // Ensure download is disabled
          setErrorMessage('No data available to download. Please update the filters.');
        }
      } catch (error) {
        console.error('An error occurred:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  function flattenObject(obj: any) {
    const review =
      obj !== undefined && obj?.comments?.length > 0
        ? obj?.comments[0]?.comment
        : '';
    let flatObject = {
      name: obj?.name,
      domain: obj?.domain,
      report_period: obj?.report_period,
      comment: review,
      account_manager: obj?.account_manager,
      consultant: obj?.consultant,
      status: obj?.status,
      budget: obj?.report?.monthly_budget,
      spend_to_date: obj?.report?.spend_to_date,
      budget_to_date: obj?.report?.budget_to_date,
      budget_difference: obj?.report?.budget_difference,
      budget_spend: obj?.report?.budget_vs_spend,
      clicks: obj?.report?.current_month?.clicks,
      impressions: obj?.report?.current_month?.impressions,
      ctr: obj?.report?.current_month?.ctr,
      average_cpc: obj?.report?.current_month?.average_cpc,
      conversion_rate: obj?.report?.current_month?.conversion_rate,
      conversions: obj?.report?.current_month?.conversions,
      cost: obj?.report?.current_month?.cost_micros,
      cost_per_conversion: obj?.report?.current_month?.cost_per_conversion,

    
      
    };
    return flatObject;
  }

  useEffect(() => {
    const flattenedData = exportData.map(item => flattenObject(item)); // Correctly process each entry
    setProcessedData(flattenedData); // Update the state with processed data
  }, [exportData]);
  
  useEffect(() => {
    if (processedData.length > 0) {
      // Generate headers from the keys of the first object in processedData
      const headersFromData = Object.keys(processedData[0]).map(key => ({
        label: key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' '), // Optional: Format the key to capitalize the first letter
        key: key
      }));
      setHeaders(headersFromData);
    }
  }, [processedData]);
  return (
    <div className="flex w-[100%] h-[100%] justify-center pt-[10%]">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="flex flex-col h-[350px] w-[500px] rounded-lg bg-white p-5 dark:bg-gray-800"
      >
        <div className="flex justify-between items-center h-[20px] mb-4">
          <span>
            <Icon name="AlignLeft" />
          </span>
          <span onClick={closeModal} className="cursor-pointer text-lg">
            <Icon name="Xclose" />
          </span>
        </div>
          {selectDate && (
        <div className="flex justify-between">
          <div>
            <ReactSelect
              options={options}
              onChange={(e)=> {
                setSelectedWeek(e);
                setDownloadReady(false);
              }}
              value={selectedWeek}
              placeholder="Select Week"
            />
          </div>
          <div>
            <ReactSelect
              options={statusOptions}
              onChange={(e) => {
                setStatus(e);
                setDownloadReady(false);
              }}
              value={status}
              placeholder="Select Status"
            />
          </div>
        </div> )}

        <div className="flex justify-between gap-2 mt-4">
        {!downloadReady ? (
            <button
              className={`py-2 px-4 mt-4 bg-white hover:bg-blue-500 hover:text-white transition-opacity text-blue-500 border-blue-500 border rounded-md w-full text-center `}
              onClick={() => fetchExportData()}
            >
              {loading ? <Icon name="Loading" size={22} /> : 'Get Data'}
            </button>
          ) : (
            <CSVLink
            data={processedData}
            headers={headers}
            asyncOnClick={true}
            filename={`weekly-report-${selectedWeek?.value}.csv`}
            className="py-2 px-4 mt-4 bg-blue-500 hover:bg-blue-600 text-white rounded-md w-full text-center"
          >
            {loading ? <Icon name="Loading" size={22} /> : 'Export'}
          </CSVLink>
          )}
        </div>
        {errorMessage.length > 0 && (
          <p className="text-red-500 mt-4 text-center">{errorMessage}</p>
        )}
        
      </div>
    </div>
  );
};

export default ExportModal;
