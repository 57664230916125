import { useEffect, useState } from 'react';
import { formatToCurrency } from '../../utils/common';
import { useThemeStore } from '../../store/useThemeStore';
import Icon from '../../assets/icons/SvgComponent';
import GurulyticsTable from '../../components/GurulyticsTable';
import useXeroStore from '../../store/useXeroStore';
import useClientStore from '../../store/useClientStore';
import moment from 'moment';
import CustomModal from '../../components/CustomModal';
import PreviewModal from '../../components/Modals/Billing/PreviewModal';
import { ToastContainer, toast, Flip } from 'react-toastify';
import Alert from '../../components/Alert';
import ReactSelect from '../../components/ReactSelect';

const Billing: React.FC = () => {
  const { selectedClient } = useClientStore((state) => state);
  const {
    fetchInvoices,
    invoices,
    fetchInvoicesPDF,
    loading,
    invoicesLink,
    resetInvoiceLink,
  } = useXeroStore((state) => state);
  const [previewModal, setPreviewModal] = useState(false);
  const [pagination, setPagination] = useState(1);
  const [limit, setLimit] = useState(10);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    handleFetchInvoices();
  }, [selectedClient, fetchInvoices, pagination, status]);

  const handleFetchInvoices = () => {
    const filters = {
      contactId: selectedClient?.xero?.contact_id,
      page: pagination,
      pageSize: limit,
      status: status?.value,
    };
    fetchInvoices(filters);
  };

  const handleFetchInvoicePDF = async (contactId, status) => {
    try {
      resetInvoiceLink(); // Reset the state immediately

      if (status === 'PAID') {
        setPreviewModal(true);
        await fetchInvoicesPDF(contactId);
      } else {
        toast.info(
          <Alert
            icon=""
            title="REDIRECTING"
            message="Please wait for the invoice new tab"
          />,
          {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );

        // Fetch invoices and open in a new tab
        await fetchInvoicesPDF(contactId);

        // Access updated invoicesLink after fetch
        const updatedInvoicesLink = useXeroStore.getState().invoicesLink;

        if (updatedInvoicesLink?.onlineInvoices?.[0]?.onlineInvoiceUrl) {
          window.open(
            updatedInvoicesLink.onlineInvoices[0].onlineInvoiceUrl,
            '_blank'
          );
        }
      }
    } catch (error) {
      console.error('Failed to fetch Link:', error);
    }
  };

  const columns = [
    {
      id: 'Invoice',
      header: () => (
        <div className="w-[8vw] flex  justify-center font-inter">
          Invoice No.
        </div>
      ),
      accessorKey: 'invoiceNumber',
      cell: (props: any) => (
        <div className="text-sm flex justify-center items-center w-[8vw] font-inter">
          <span className="text-[#405573] text-[14px] font-[400]">
            {props.getValue()}
          </span>
        </div>
      ),
    },
    {
      id: 'Reference',
      header: () => (
        <div className="w-[12vw] flex  justify-center font-inter dark:text-white">
          Reference
        </div>
      ),
      accessorKey: 'reference',
      cell: (props: any) => (
        <div className="flex w-[12vw] justify-center cursor-pointer flex justify-center font-inter">
          <span className="text-[#001C44] text-[14px] font-[400] dark:text-white max-w-[12vw] truncate">
            {props.getValue() || 'N/A'}
          </span>
        </div>
      ),
    },
    {
      id: 'invoiceDate',
      header: () => (
        <div className="w-[10vw] flex  justify-center font-inter">
          Start Date
        </div>
      ),
      accessorKey: 'date',
      cell: (props: any) => (
        <div className="flex w-[10vw] justify-center cursor-pointer flex justify-center font-inter">
          <span className="text-[#001C44] text-[14px] font-[400] dark:text-white">
            {moment(props.getValue()).format('MMMM D, YYYY')}
          </span>
        </div>
      ),
    },
    {
      id: 'invoiceDueDate',
      header: () => (
        <div className="w-[10vw] flex  justify-center font-inter">Due Date</div>
      ),
      accessorKey: 'dueDate',
      cell: (props: any) => (
        <div className="flex w-[10vw] justify-center cursor-pointer flex justify-center font-inter">
          <span className="text-[#001C44] text-[14px] font-[400] dark:text-white">
            {moment(props.getValue()).format('MMMM D, YYYY')}
          </span>
        </div>
      ),
    },
    {
      id: 'Amount',
      header: () => (
        <div className="w-[10vw] flex  justify-center font-inter dark:text-white">
          Amount Paid
        </div>
      ),
      accessorKey: 'amountPaid',
      cell: (props: any) => {
        return (
          <div className="flex w-[10vw] justify-center cursor-pointer flex justify-center font-inter">
            <span className="text-[#001C44] text-[14px] font-[400]">
              {formatToCurrency(
                props.getValue(),
                2,
                props.row.original.currencyCode
              )}
            </span>
          </div>
        );
      },
    },
    {
      id: 'AmountDue',
      header: () => (
        <div className="w-[8vw] flex  justify-center font-inter dark:text-white">
          Amount Due
        </div>
      ),
      accessorKey: 'amountDue',
      cell: (props: any) => {
        return (
          <div className="flex w-[8vw] justify-center cursor-pointer flex justify-center font-inter">
            <span className="text-[#001C44] text-[14px] font-[400]">
              {formatToCurrency(
                props.getValue(),
                2,
                props.row.original.currencyCode
              )}
            </span>
          </div>
        );
      },
    },

    {
      id: 'Status',
      header: () => (
        <div className="w-[6vw] flex  justify-center font-inter">Status</div>
      ),
      accessorKey: 'status',
      cell: (props: any) => (
        <div className="w-[6vw] flex  justify-center font-inter">
          <div className="flex items-center gap-2 border-[#80AECF] font-inter">
            <div
              className={
                props.getValue() === 'Pending'
                  ? 'flex items-center justify-center gap-1 border-2  text-[#FFCA00] border-[#FFCA00] text-gray-800 text-xs font-light  px-1.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-300'
                  : 'flex items-center justify-center gap-1 border-2 text-[#067647]  border-[#067647] text-gray-800 text-xs font-light  px-1.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-300'
              }
            >
              <span
                className={
                  props.getValue() === 'Pending'
                    ? 'text-[#FFCA00]'
                    : 'text-[#067647]'
                }
              >
                {props.getValue()}
              </span>
            </div>
          </div>
        </div>
      ),
    },

    {
      id: 'Action',
      header: () => (
        <div className="w-[6vw] flex justify-center font-inter">Action</div>
      ),
      accessorKey: 'invoiceID',
      cell: (props: any) => (
        <div
          className="w-[6vw] flex justify-center cursor-pointer text-lg font-bold text-blue-700 flex justify-center font-inter dark:text-white"
          onClick={() => {
            handleFetchInvoicePDF(
              props?.getValue(),
              props?.row?.original?.status
            );
          }}
        >
          {props?.row?.original?.status !== 'PAID' ? (
            <Icon name="ArrowUpRight" size={20} color="#0029FF" />
          ) : (
            <Icon name="eye" />
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="w-full font-inter relative">
      <div className="absolute top-[-55px] right-0">
        <div className="w-[160px]">
          <ReactSelect
            value={status}
            options={[
              {
                label: 'All Status',
                value: '',
              },
              {
                label: 'PAID',
                value: 'PAID',
              },
              {
                label: 'AUTHORISED',
                value: 'AUTHORISED',
              },
              {
                label: 'VOIDED',
                value: 'VOIDED',
              },
              {
                label: 'DELETED',
                value: 'DELETED',
              },
            ]}
            placeholder="All Status"
            handleOnChange={(selectedOption: any) => setStatus(selectedOption)}
          />
        </div>
      </div>
      <div className="">
        <GurulyticsTable
          data={invoices?.invoices || []}
          columns={columns}
          totalPages={invoices?.pagination?.pageCount}
          limit={limit}
          setLimit={setLimit}
          loading={loading}
          page={pagination}
          setPage={setPagination}
          tableHeight="h-[70vh]"
          totalDocs={invoices?.pagination?.itemCount}
          errorMsg="Try adding contact in your Xero integrations"
        />
      </div>

      <CustomModal open={previewModal} onClose={() => setPreviewModal(false)}>
        <PreviewModal
          invoiceLink={
            invoicesLink?.onlineInvoices?.[0]?.onlineInvoiceUrl || ''
          }
          onClose={() => setPreviewModal(false)}
        />
      </CustomModal>
      <ToastContainer
        position="top-right"
        autoClose={30000}
        hideProgressBar
        newestOnTop={false}
        transition={Flip}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};
export default Billing;
