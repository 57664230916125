// Toggle.tsx
import React, { useState } from 'react';
import Icon from '../../assets/icons/SvgComponent';

type ToggleProps = {
  onToggle: (isChecked: boolean) => void;
  defaultChecked?: boolean;
  label: string;
};

const Toggle: React.FC<ToggleProps> = ({
  onToggle,
  defaultChecked = false,
  label,
}) => {
  const [isChecked, setIsChecked] = useState(defaultChecked);

  const handleToggle = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);
    onToggle(newCheckedState);
  };

  return (
    <label className="flex items-center cursor-pointer">
      <div className="relative">
        <input
          type="checkbox"
          className="sr-only"
          checked={isChecked}
          onChange={handleToggle}
        />
        <div
          className={`w-12 h-5 bg-[#005C9F] ${
            !isChecked ? 'bg-[#E6EFF5] dark:bg-[#bbc2c7]' : 'bg-[#005C9F] '
          } rounded-full shadow-inner `}
        ></div>
        <div
          className={`${
            isChecked
              ? 'translate-x-7 bg-white '
              : 'translate-x-0 bg-white dark:bg-[#262627]'
          } absolute top-0 left-0 h-4 w-4 mt-0.5 ml-0.5 rounded-full transition-transform ease-in-out duration-200 transform shadow-sm`}
        ></div>
        <div
          className={
            isChecked
              ? 'absolute top-0 left-0 translate-x-[4px]  translate-y-[2px]'
              : 'hidden'
          }
        >
          <Icon name="sun" />
        </div>
        <div
          className={
            !isChecked
              ? 'absolute top-0 right-0 translate-x-[-3px]  translate-y-[2px]'
              : 'hidden'
          }
        >
          <Icon name="moon" />
        </div>
      </div>
      <div className="ml-3 text-[#001C44] text-base font-semibold dark:text-white  scale-in-left">
        {label}
      </div>
    </label>
  );
};

export default Toggle;
