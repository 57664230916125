import { create } from 'zustand';
import Api from '../api';
interface Status {
  loading: boolean;
  error: boolean;
}

interface Tag {
  id: number;
  name: string;
  color: string;
  projectId: number;
}

interface Task {
  title: string;
  description: string;
  tags?: Tag[];
  created_on: string;
  due_date?: string;
  date?: string;
  completer_firstname?: string;
  completer_lastname?: string;
  completed_on?: string;
  duration?: string;
}

interface TasksData {
  total_completed_tasks: number;
  total_new_tasks: number;
  on_progress: Task[];
  completed: Task[];
  high_priority: Task[];
}

interface CommunicationData {
  outgoingCalls: number;
  incomingCalls: number;
  incomingEmails: number;
  outgoingEmails: number;
  meetings: number;
}
interface Logs {
  total_docs: number;
  limit?: number;
  total_pages: number;
  page: number;
  data: [];
}

interface Meeting {
  loading: boolean;
  error: boolean;
  properties: {
    hs_meeting_start_time?: string;
    hs_meeting_end_time?: string;
    hs_meeting_title?: string;
    hs_meeting_external_url?: string;
    hs_meeting_body?: string;
    hs_lastmodifieddate?: string;
    hs_createdate?: string;
    hs_object_id?: string;
    hs_internal_meeting_notes?: string;
  };
  data: any[];
}
interface Dates {
  loading: boolean;
  error: boolean;
  dates: string[];
  data: string[];
  response: {};
}

interface Time {
  loading: boolean;
  error: boolean;
  times: string[];
  data: string[];
  response: {
    0: {
      error: string;
    };
  };
}

const initialUpcomingMeeting: Meeting = {
  loading: true,
  error: false,
  properties: {},
  data: null,
};

const initialAvailableDates: Dates = {
  loading: true,
  error: false,
  dates: [],
  data: [],
  response: {},
};

const initialAvailableTime: Time = {
  loading: true,
  error: false,
  times: [],
  data: [],
  response: {
    0: {
      error: '',
    },
  },
};

interface Store {
  tasks: Status & Partial<TasksData>;
  communications: Status & Partial<CommunicationData>;
  logs: Status & Partial<Logs>;
  upcomingMeeting: Status & Partial<Meeting>;
  availableDates: Status & Partial<Dates>;
  availableTime: Status & Partial<Time>;
  fetchTeamworkTasks: (
    clientId: string,
    filter?: string
  ) => Promise<{ status: boolean }>;
  fetchCommunications: (filters: {
    companyId: string;
    country: string;
    startDate: string;
    endDate: string;
  }) => Promise<{ status: boolean }>;
  fetchLogs: (filters: {
    page: number;
    limit: number;
  }) => Promise<{ status: boolean }>;
  fetchUpcomingMeeting: (filters: {
    clientId: string;
    country: string;
  }) => Promise<{ status: boolean }>;
  fetchAvailableDates: (filters: {
    clientId: string;
    date: string;
    country: string;
  }) => Promise<{ status: boolean }>;
  fetchAvailableTime: (filters: {
    clientId: string;
    date: string;
    country: string;
  }) => Promise<{ status: boolean }>;
  createMeeting: (data: any) => Promise<any>;
  refreshMeeting: () => void;
  refreshAvailableTime: () => void;
}

const initialStatus: Status = {
  loading: true,
  error: false,
};

const useActivitiesStore = create<Store>((set, get) => ({
  tasks: initialStatus,
  communications: initialStatus,
  logs: initialStatus,
  upcomingMeeting: initialUpcomingMeeting,
  availableDates: initialAvailableDates,
  availableTime: initialAvailableTime,
  fetchTeamworkTasks: async (clientId: string, filter?: string) => {
    try {
      set(() => ({ tasks: initialStatus }));
      const response = await Api.Activities.getTasks({
        client_id: clientId,
        tag: filter,
      });
      const taskReponse = {
        ...response,
        loading: false,
        error: response.error,
      };
      if (taskReponse.error) {
        throw 'Something went wrong';
      } else {
        set(() => ({ tasks: taskReponse }));
        return { status: true };
      }
    } catch (error) {
      throw error;
    }
  },
  fetchCommunications: async (filters: {
    companyId: string;
    country: string;
    startDate: string;
    endDate: string;
  }) => {
    try {
      set(() => ({ communications: initialStatus }));
      const response = await Api.Activities.getCommunications(filters);
      const commResponse = {
        ...response,
        loading: false,
        error: response.error,
      };
      if (commResponse.error) {
        throw commResponse.error;
      } else {
        set(() => ({ communications: commResponse }));
        return { status: true };
      }
    } catch (error) {
      throw error;
    }
  },
  fetchLogs: async (filters: {
    page: number;
    limit: number;
    method: string;
    sort: string;
    model: string;
    search: string;
  }) => {
    try {
      set(() => ({ logs: initialStatus }));
      const response = await Api.Logs.getLogs(filters);
      const logResponse = {
        ...response,
        loading: false,
        error: response.error,
      };
      if (logResponse.error) {
        throw logResponse.error;
      } else {
        set(() => ({ logs: logResponse }));
        return { status: true };
      }
    } catch (error) {
      throw error;
    }
  },
  fetchUpcomingMeeting: async (filters: {
    clientId: string;
    country: string;
  }) => {
    try {
      set(() => ({ upcomingMeeting: initialUpcomingMeeting }));
      const response = await Api.HubSpot.getUpcomingMeeting(filters);
      const res = {
        ...response,
        loading: false,
        error: response.error,
      };

      if (res.error) {
        throw res.error;
      } else {
        set((state) => ({ ...state, upcomingMeeting: res }));
        return { status: true };
      }
    } catch (error) {
      throw error;
    }
  },

  fetchAvailableDates: async (filters: {
    clientId: string;
    date: string;
    country: string;
  }) => {
    try {
      set(() => ({ availableDates: initialAvailableDates }));
      const response = await Api.HubSpot.getAvailableDates(filters);

      const res = {
        loading: false,
        error: false,
        ...response,
      };
      if (res.error) {
        throw res.error;
      } else {
        set((state) => ({ ...state, availableDates: res }));
        return { status: true };
      }
    } catch (error) {
      throw error;
    }
  },

  fetchAvailableTime: async (filters: {
    clientId: string;
    date: string;
    country: string;
  }) => {
    try {
      set(() => ({ availableTime: initialAvailableTime }));
      const response = await Api.HubSpot.getAvailableTime(filters);

      const res = {
        loading: false,
        error: response.errors,
        ...response,
      };
      if (res.error) {
        throw res.error;
      } else {
        set((state) => ({ ...state, availableTime: res }));
        return { status: true };
      }
    } catch (error) {
      throw error;
    }
  },

  createMeeting: async (data) => {
    try {
      const response = await Api.HubSpot.create(data);
      const res = {
        loading: false,
        error: response.errors,
        ...response,
      };
      if (response[0]?.status === 500 || res[0]?.errors || res?.errors || res?.error) {
        throw res[0].errors[0]?.msg;
      } else {
        return { status: true };
      }
    } catch (error) {
      throw error;
    }
  },

  refreshMeeting: () => {
    set(() => ({
      upcomingMeeting: {
        loading: true,
        error: false,
        properties: null,
        data: [],
      },
    }));
  },

  refreshAvailableTime: () => {
    set(() => ({
      availableTime: {
        loading: false,
        error: false,
        times: [],
        data: [],
      },
    }));
  },
}));

export default useActivitiesStore;
