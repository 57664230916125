import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useUtilityStore = create(
  persist(
    (set) => ({
      // Initial state
      campaignReport: {
        label: 'Google Ads (Overall)',
        value: 0,
        id: 'overall',
      },
      isHeaderHidden: false,
      // Actions
      setCampaignReport: (selectedOption) =>
        set({ campaignReport: selectedOption }),
      setIsHeaderHidden: (hidden) => set({ isHeaderHidden: hidden }),
    }),
    {
      name: 'utility-store', // Persistent storage key
    }
  )
);

export default useUtilityStore;
