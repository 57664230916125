import { useState } from 'react';
import TanStackTable from '../../components/TanStackTable';
import DVisuals from '../../assets/images/3DVisuals.png';
import BestFitness from '../../assets/images/BestFitness.png';
import BoxTech from '../../assets/images/BoxTech.png';
import CoastMac from '../../assets/images/CoastMac.png';
import GoldenCrest from '../../assets/images/GoldenCrest.png';
import JujuGems from '../../assets/images/JujuGems.png';
import LoftHome from '../../assets/images/LoftHome.png';
import OCRCar from '../../assets/images/OCRCar.png';
import Piccolinagelateria from '../../assets/images/Piccolinagelateria.png';
import Resolve from '../../assets/images/Resolve.png';
import Icon from '../../assets/icons/SvgComponent';
import { Link } from 'react-router-dom';

export default function AllClients() {
  const [selectedRow, setSelectedRow] = useState({});

  const data = [
    {
      client: {
        name: '3D Visuals and XR Apps for Business',
        icon: DVisuals,
        email: 'http://3dwalkabout.com.au',
      },
      requestStatus: {
        name: 'Completed',
        icon: 'CheckVerified',
      },
      action: '/client-access/all-clients/3D Visuals and XR Apps for Business',
    },
    {
      client: {
        name: 'Best Fitness',
        icon: BestFitness,
        email: 'http://bestfitness.com.au',
      },
      requestStatus: {
        name: 'Completed',
        icon: 'CheckVerified',
      },
      action: '/client-access/all-clients/Best Fitness',
    },
    {
      client: {
        name: 'Box Tech - Business Cloud Specialist',
        icon: BoxTech,
        email: 'boxtech.com.au',
      },
      requestStatus: {
        name: 'Completed',
        icon: 'CheckVerified',
      },
      action: '/client-access/all-clients/Box Tech - Business Cloud Specialist',
    },
    {
      client: {
        name: 'Coast Mac - Built for long haul',
        icon: CoastMac,
        email: 'http://coastmactrailers.com.au',
      },
      requestStatus: {
        name: 'Completed',
        icon: 'CheckVerified',
      },
      action: '/client-access/all-clients/Coast Mac - Built for long haul',
    },
    {
      client: {
        name: 'Golden Crest Manors',
        icon: GoldenCrest,
        email: 'http://goldencrestmanors.com',
      },
      requestStatus: {
        name: 'Completed',
        icon: 'CheckVerified',
      },
      action: '/client-access/all-clients/Golden Crest Manors',
    },
    {
      client: {
        name: 'Juju Gems Jewelry - Amulets & Heirlooms',
        icon: JujuGems,
        email: 'https://jujugemsjewellery.com',
      },
      requestStatus: {
        name: 'Completed',
        icon: 'CheckVerified',
      },
      action:
        '/client-access/all-clients/Juju Gems Jewelry - Amulets & Heirlooms',
    },
    {
      client: {
        name: 'Loft Home Furniture - Honest Price, Direct To Your Door',
        icon: LoftHome,
        email: 'http://lofthome.com',
      },
      requestStatus: {
        name: 'Completed',
        icon: 'CheckVerified',
      },
      action:
        '/client-access/all-clients/Loft Home Furniture - Honest Price, Direct To Your Door',
    },
    {
      client: {
        name: 'OCR Car Rentals - Car hire in Surfers Paradise Gold Coast & Maudsland',
        icon: OCRCar,
        email: 'http://ocr-australia.com',
      },
      requestStatus: {
        name: 'Completed',
        icon: 'CheckVerified',
      },
      action:
        '/client-access/all-clients/OCR Car Rentals - Car hire in Surfers Paradise Gold Coast & Maudsland',
    },
    {
      client: {
        name: 'Piccolinagelateria - Best Gelato Shop in Australia',
        icon: Piccolinagelateria,
        email: 'https://www.piccolinagelateria.com.au',
      },
      requestStatus: {
        name: 'Completed',
        icon: 'CheckVerified',
      },
      action:
        '/client-access/all-clients/Piccolinagelateria - Best Gelato Shop in Australia',
    },
    {
      client: {
        name: 'Resolve - Business sold simply',
        icon: Resolve,
        email: 'http://resolve.com.au',
      },
      requestStatus: {
        name: 'Completed',
        icon: 'CheckVerified',
      },
      action: '/client-access/all-clients/Resolve - Business sold simply',
    },
    {
      client: {
        name: 'OCR Car Rentals - Car hire in Surfers Paradise Gold Coast & Maudsland',
        icon: OCRCar,
        email: 'http://ocr-australia.com',
      },
      requestStatus: {
        name: 'Completed',
        icon: 'CheckVerified',
      },
      action:
        '/client-access/all-clients/OCR Car Rentals - Car hire in Surfers Paradise Gold Coast & Maudsland',
    },
    {
      client: {
        name: 'Loft Home Furniture - Honest Price, Direct To Your Door',
        icon: LoftHome,
        email: 'http://lofthome.com',
      },
      requestStatus: {
        name: 'Completed',
        icon: 'CheckVerified',
      },
      action:
        '/client-access/all-clients/Loft Home Furniture - Honest Price, Direct To Your Door',
    },
  ];

  const column = [
    {
      id: 'Client',
      header: () => (
        <div className="flex justify-start w-full">Client Name</div>
      ),
      accessorKey: 'client',
      cell: (props: any) => {
        return (
          <div className="flex gap-x-2 w-[80%]">
            <div>
              <img src={props.getValue()?.icon} alt={props.getValue()?.name} />
            </div>
            <div>
              <div className="flex justify-start font-[600]">
                {props.getValue()?.name}
              </div>
              <div className="flex justify-start text-xs">
                {props.getValue()?.email}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      id: 'Request Status',
      header: 'Request Status',
      accessorKey: 'requestStatus',
      cell: (props: any) => {
        return (
          <div>
            {props.getValue()?.name === 'Send' ? (
              <div className="w-full flex justify-center">
                <div className="flex gap-1 items-center border border-[#80AECF] bg-[#E6EFF5] text-[#005C9F] rounded-full py-1 px-2  w-fit">
                  <Icon name={props.getValue()?.icon} color="#005C9F" />
                  {props.getValue()?.name}
                </div>
              </div>
            ) : props.getValue()?.name === 'Completed' ? (
              <div className="w-full flex justify-center">
                <div className="flex gap-1 items-center border border-[#A8F1D4] bg-[#E2FAF1] text-[#58B992] rounded-full py-1 px-2  w-fit">
                  <Icon name={props.getValue()?.icon} />
                  {props.getValue()?.name}
                </div>
              </div>
            ) : props.getValue()?.name === 'Pending' ? (
              <div className="w-full flex justify-center">
                <div className="flex gap-1 items-center border border-[#FDE594] bg-[#FEF6DB] text-[#CAA93E] rounded-full py-1 px-2  w-fit">
                  <Icon name={props.getValue()?.icon} color="#CAA93E" />
                  {props.getValue()?.name}
                </div>
              </div>
            ) : null}
          </div>
        );
      },
    },
    {
      id: 'Action',
      header: 'Action',
      accessorKey: 'action',
      cell: (props: any) => {
        return (
          <div className="w-full flex justify-center">
            {props.getValue() === true ? (
              <Link to={props.getValue()}>
                <div className="border border-[#F58F8F] rounded-lg text-[#BF3636] w-fit p-2 font-[600]">
                  Cancel
                </div>
              </Link>
            ) : (
              <Link to={props.getValue()}>
                <div className="border border-[#808EA2] rounded-lg text-[#001C44] w-fit p-2 font-[600]">
                  Restore
                </div>
              </Link>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className="p-[32px] pb-14">
      <TanStackTable
        data={data}
        columns={column}
        page={1}
        setPage={1}
        totalPages={200}
        setSelectedRow={setSelectedRow}
        totalDocs={13}
      />
    </div>
  );
}
