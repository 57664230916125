import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const NoDataCampaign: React.FC<IconProps> = ({
  color = 'black',
  size = 229,
}) => (
  <svg
    width="38"
    height="44"
    viewBox="0 0 38 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="g153">
      <g id="g155">
        <g id="g157">
          <g id="g163">
            <path
              id="path165"
              d="M6.08689 16.1724C5.63359 17.3134 5.57403 18.573 5.91764 19.7516C6.26125 20.9303 6.98838 21.9606 7.98381 22.6793"
              stroke="#001C44"
              strokeWidth="1.32889"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <g id="g167">
            <path
              id="path169"
              d="M7.53223 14.12C8.08224 13.5813 8.73898 13.1636 9.46018 12.894C10.1814 12.6244 10.951 12.5088 11.7196 12.5547C12.4882 12.6006 13.2386 12.8069 13.9226 13.1604C14.6066 13.5139 15.209 14.0067 15.691 14.607C16.1731 15.2074 16.524 15.9021 16.7213 16.6463C16.9185 17.3906 16.9578 18.1679 16.8365 18.9282C16.7151 19.6885 16.4359 20.415 16.0168 21.0609C15.5977 21.7068 15.048 22.2577 14.4031 22.6783"
              stroke="#001C44"
              strokeWidth="1.32889"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <g id="g171">
            <path
              id="path173"
              d="M21.359 42.9608H1.0332V39.0125C1.0332 38.7335 1.08833 38.4573 1.19544 38.1997C1.30254 37.942 1.4595 37.7081 1.6573 37.5113C1.85509 37.3146 2.08983 37.1588 2.34801 37.0531C2.60619 36.9473 2.88273 36.8936 3.16173 36.895H14.7418"
              stroke="#001C44"
              strokeWidth="1.32889"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <g id="g175">
            <path
              id="path177"
              d="M3.16406 36.8943V34.1592H16.3984"
              stroke="#001C44"
              strokeWidth="1.32889"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <g id="g179">
            <path
              id="path181"
              d="M5.1377 24.6748C5.1377 24.1512 5.34568 23.6491 5.7159 23.2789C6.08612 22.9087 6.58825 22.7007 7.11182 22.7007H15.273C15.7966 22.7007 16.2987 22.9087 16.6689 23.2789C17.0391 23.6491 17.2471 24.1512 17.2471 24.6748C17.2471 25.1984 17.0391 25.7005 16.6689 26.0707C16.2987 26.4409 15.7966 26.6489 15.273 26.6489H7.11182C6.58825 26.6489 6.08612 26.4409 5.7159 26.0707C5.34568 25.7005 5.1377 25.1984 5.1377 24.6748Z"
              stroke="#001C44"
              strokeWidth="1.32889"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <g id="g183">
            <path
              id="path185"
              d="M7.11231 26.6255C7.11231 29.0187 6.605 32.5589 3.16406 34.158"
              stroke="#001C44"
              strokeWidth="1.32889"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <g id="g187">
            <path
              id="path189"
              d="M15.275 26.6255C15.1534 28.7846 15.8152 30.915 17.1388 32.6251"
              stroke="#001C44"
              strokeWidth="1.32889"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <g id="g191">
            <path
              id="path193"
              d="M34.3771 36.1786H16.4115C15.7797 36.1786 15.1738 36.4296 14.727 36.8764C14.2803 37.3231 14.0293 37.929 14.0293 38.5608V42.9723H36.8034V38.5608C36.8035 38.2442 36.7404 37.9308 36.618 37.6389C36.4955 37.347 36.3161 37.0824 36.0902 36.8606C35.8643 36.6388 35.5964 36.4642 35.3023 36.3471C35.0082 36.2301 34.6936 36.1728 34.3771 36.1786Z"
              stroke="#001C44"
              strokeWidth="1.32889"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <g id="g195">
            <path
              id="path197"
              d="M16.4102 36.1788V33.1128H34.3758V36.1788"
              stroke="#001C44"
              strokeWidth="1.32889"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <g id="g199">
            <path
              id="path201"
              d="M20.8284 17.7031C20.8284 20.3721 20.255 31.3235 16.417 33.1432"
              stroke="#001C44"
              strokeWidth="1.32889"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <g id="g203">
            <path
              id="path205"
              d="M34.376 33.1105C33.6888 32.7536 33.1156 32.211 32.7217 31.5444"
              stroke="#001C44"
              strokeWidth="1.32889"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <g id="g207">
            <path
              id="path209"
              d="M29.9482 17.7031C29.9525 21.6205 30.5208 25.5168 31.6356 29.2722"
              stroke="#001C44"
              strokeWidth="1.32889"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <g id="g211">
            <path
              id="path213"
              d="M18.6143 15.488C18.6143 14.903 18.8466 14.3419 19.2603 13.9283C19.674 13.5146 20.235 13.2822 20.82 13.2822H29.9407C30.5257 13.2822 31.0867 13.5146 31.5003 13.9283C31.914 14.3419 32.1464 14.903 32.1464 15.488C32.1464 16.0729 31.914 16.634 31.5003 17.0476C31.0867 17.4613 30.5257 17.6937 29.9407 17.6937H20.831C20.5404 17.6951 20.2524 17.6392 19.9835 17.529C19.7146 17.4188 19.4702 17.2565 19.2642 17.0515C19.0582 16.8466 18.8947 16.6029 18.7832 16.3346C18.6717 16.0663 18.6143 15.7785 18.6143 15.488Z"
              stroke="#001C44"
              strokeWidth="1.32889"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <g id="g215">
            <path
              id="path217"
              d="M34.7714 1.05127L33.9553 10.0506C33.8756 10.9322 33.4695 11.7522 32.8167 12.35C32.1638 12.9477 31.3113 13.2801 30.4261 13.282H20.357C19.4718 13.2801 18.6193 12.9477 17.9665 12.35C17.3136 11.7522 16.9076 10.9322 16.8278 10.0506L16.0117 1.05127H19.1549L19.6843 4.99952C19.7298 5.35039 19.9016 5.67265 20.1675 5.90607C20.4334 6.13948 20.7752 6.26808 21.129 6.26781C21.5065 6.25649 21.8652 6.09997 22.1302 5.83084C22.3952 5.56172 22.5462 5.20073 22.5517 4.82306V1.05127H28.2314V4.82306C28.2438 5.18597 28.3904 5.53139 28.6427 5.79248C28.8951 6.05357 29.2353 6.21178 29.5976 6.23648C29.9599 6.26118 30.3185 6.15062 30.6039 5.9262C30.8894 5.70178 31.0815 5.37945 31.143 5.02158L31.6724 1.07333L34.7714 1.05127Z"
              stroke="#001C44"
              strokeWidth="1.32889"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default NoDataCampaign;
