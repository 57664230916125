import { Grid, Col } from '@tremor/react';
import {
  formatNumberWithCommas,
  formatToCurrency,
  formatNumber,
  processData,
  processData2,
} from '../../../../utils/common';
import useClientStore from '../../../../store/useClientStore';
import AreaCardChart from '../../AreaCardChart';
import MobileView from './mobileView';
import { useRetryFetchBingCampaign } from '../../Hooks';
import useBingStore from '../../../../store/useBingStore';
import { isLedGenClient } from '../../../../utils/client';

const MicrosoftAdsOverview = () => {
  const { selectedClient } = useClientStore((state) => state);
  const { campaignsReport } = useBingStore((state) => state);
  const isLeadGen = isLedGenClient(selectedClient);

  const campainChartData = campaignsReport?.chartsData || {};

  const clicks = processData(campainChartData, 'Clicks');
  const conversionRate = processData(campainChartData, 'ConversionRate');
  const conversions = processData2({
    dates: campaignsReport?.conversionActions?.dates,
    values: campaignsReport?.conversionActions?.values,
    totalKeyName: 'Conversions',
  });
  const costPerConversion = processData(campainChartData, 'CostPerConversion');
  const ctr = processData(campainChartData, 'Ctr', 'Click-Through Rate');
  const impressions = processData(campainChartData, 'Impressions');
  const revenue = processData(campainChartData, 'Revenue');
  const spend = processData(campainChartData, 'Spend');
  const totalRoas = processData(
    campainChartData,
    'ReturnOnAdSpend',
    'Return on Ad Spend'
  );

  const lastMonthClicks =
    Array.isArray(clicks) && clicks.length > 0
      ? clicks[clicks.length - 1]?.value
      : undefined;

  const lastMonthConversionRate =
    Array.isArray(conversionRate) && conversionRate.length > 0
      ? conversionRate[conversionRate.length - 1]?.value
      : undefined;

  const lastMonthConversions =
    Array.isArray(conversions) && conversions.length > 0
      ? conversions[conversions.length - 1]?.Conversions
      : undefined;

  const lastMonthImpressions =
    Array.isArray(impressions) && impressions.length > 0
      ? impressions[impressions.length - 1]?.value
      : undefined;

  const lastMonthRevenue =
    Array.isArray(revenue) && revenue.length > 0
      ? revenue[revenue.length - 1]?.value
      : undefined;

  const lastMonthCostPerConversion =
    Array.isArray(costPerConversion) && costPerConversion.length > 0
      ? costPerConversion[costPerConversion.length - 1]?.value
      : undefined;

  const lastMonthSpend =
    Array.isArray(spend) && spend.length > 0
      ? spend[spend.length - 1]?.value
      : undefined;

  const lastMonthCtr =
    Array.isArray(ctr) && ctr.length > 0
      ? ctr[ctr.length - 1]?.value
      : undefined;

  const lastMonthTotalRoas =
    Array.isArray(totalRoas) && totalRoas.length > 0
      ? totalRoas[totalRoas.length - 1]?.value
      : undefined;

  const retryFetchBingCampaign = useRetryFetchBingCampaign();

  return (
    <>
      <div className="block sm:hidden">
        <MobileView
          campaignsReport={campaignsReport}
          campainChartData={campainChartData}
          selectedClient={selectedClient}
          clicks={clicks}
          lastMonthClicks={lastMonthClicks}
          conversionRate={conversionRate}
          lastMonthConversionRate={lastMonthConversionRate}
          conversions={conversions}
          lastMonthConversions={lastMonthConversions}
          costPerConversion={costPerConversion}
          lastMonthCostPerConversion={lastMonthCostPerConversion}
          ctr={ctr}
          lastMonthCtr={lastMonthCtr}
          impressions={impressions}
          lastMonthImpressions={lastMonthImpressions}
          revenue={revenue}
          lastMonthRevenue={lastMonthRevenue}
          spend={spend}
          lastMonthSpend={lastMonthSpend}
          totalRoas={totalRoas}
          lastMonthTotalRoas={lastMonthTotalRoas}
          retryFetchBingCampaign={retryFetchBingCampaign}
        />
      </div>
      <div className="hidden sm:block">
        <div className="flex justify-start items-center mb-4">
          <h2 className="title-text text-base sm:text-[1.85rem] pb-1 hidden sm:block">
            Microsoft Ads
          </h2>
        </div>
        <Grid
          numItems={1}
          numItemsSm={1}
          numItemsMd={2}
          numItemsLg={4}
          className="gap-4"
        >
          <Col>
            <AreaCardChart
              title={
                campainChartData?.Clicks?.label
                  ? campainChartData?.Clicks?.label
                  : 'Clicks'
              }
              chatData={clicks}
              category={['Clicks']}
              info={
                campainChartData?.Clicks?.info
                  ? campainChartData?.Clicks?.info
                  : 'Clicks'
              }
              prevPeriod={campainChartData?.Clicks?.previous_period}
              previousYear={campainChartData?.Clicks?.previous_year}
              isLoading={campaignsReport?.loading || selectedClient?.loading}
              totalValue={formatNumberWithCommas(lastMonthClicks)}
              error={campaignsReport.error}
              icon="BingAnalyticsTab"
              retryMethod={retryFetchBingCampaign}
            />
          </Col>
          <Col>
            <AreaCardChart
              title="Conversion Rate"
              chatData={conversionRate}
              category={['Conversion Rate']}
              info={
                campainChartData?.ConversionRate?.info
                  ? campainChartData?.ConversionRate?.info
                  : 'Conversion Rate'
              }
              prevPeriod={`${campainChartData?.ConversionRate?.previous_period}`}
              previousYear={`${campainChartData?.ConversionRate?.previous_year}`}
              isLoading={campaignsReport.loading}
              totalValue={formatNumber(lastMonthConversionRate, true)}
              hasPercent={true}
              error={campaignsReport.error}
              icon="BingAnalyticsTab"
              propertyId={`Client ID: ${selectedClient?.id}`}
              retryMethod={retryFetchBingCampaign}
            />
          </Col>
          <Col>
            <AreaCardChart
              title="Conversions"
              chatData={conversions}
              category={['Conversions']}
              info={
                campainChartData?.Conversions?.info
                  ? campainChartData?.Conversions?.info
                  : 'Conversions'
              }
              prevPeriod={`${campainChartData?.Conversions?.previous_period}`}
              previousYear={`${campainChartData?.Conversions?.previous_year}`}
              isLoading={campaignsReport.loading}
              totalValue={formatNumber(lastMonthConversions)}
              error={campaignsReport.error}
              icon="BingAnalyticsTab"
              propertyId={`Client ID: ${selectedClient?.id}`}
              retryMethod={retryFetchBingCampaign}
              customDataTooltip={true}
            />
          </Col>
          <Col>
            <AreaCardChart
              title="Cost Per Conversions"
              chatData={costPerConversion}
              category={['Cost Per Conversion']}
              info={
                campainChartData?.CostPerConversion?.info
                  ? campainChartData?.CostPerConversion?.info
                  : 'Cost Per Conversions'
              }
              prevPeriod={`${campainChartData?.CostPerConversion?.previous_period}`}
              previousYear={`${campainChartData?.CostPerConversion?.previous_year}`}
              isLoading={campaignsReport.loading}
              totalValue={formatToCurrency(lastMonthCostPerConversion)}
              hasDollar={true}
              error={campaignsReport.error}
              icon="BingAnalyticsTab"
              propertyId={`Client ID: ${selectedClient?.id}`}
              retryMethod={retryFetchBingCampaign}
            />
          </Col>
          <Col>
            <AreaCardChart
              title="Click-Through Rate"
              chatData={ctr}
              category={['Click-Through Rate']}
              info={
                campainChartData?.Ctr?.info
                  ? campainChartData?.Ctr?.info
                  : 'Click-Through Rate'
              }
              prevPeriod={`${campainChartData?.Ctr?.previous_period}`}
              previousYear={`${campainChartData?.Ctr?.previous_year}`}
              isLoading={campaignsReport.loading}
              totalValue={formatNumber(lastMonthCtr, true)}
              hasPercent={true}
              error={campaignsReport.error}
              icon="BingAnalyticsTab"
              propertyId={`Client ID: ${selectedClient?.id}`}
              retryMethod={retryFetchBingCampaign}
            />
          </Col>
          <Col>
            <AreaCardChart
              title={
                campainChartData?.Impressions?.label
                  ? campainChartData?.Impressions?.label
                  : 'Impressions'
              }
              chatData={impressions}
              category={['Impressions']}
              info={
                campainChartData?.Impressions?.info
                  ? campainChartData?.Impressions?.info
                  : 'Impressions'
              }
              prevPeriod={campainChartData?.Impressions?.previous_period}
              previousYear={campainChartData?.Impressions?.previous_year}
              isLoading={campaignsReport.loading}
              totalValue={formatNumberWithCommas(lastMonthImpressions)}
              error={campaignsReport.error}
              icon="BingAnalyticsTab"
              propertyId={selectedClient?.google_search_console?.site_url}
              retryMethod={retryFetchBingCampaign}
            />
          </Col>
          <Col>
            <AreaCardChart
              title={
                campainChartData?.Revenue?.label
                  ? campainChartData?.Revenue?.label
                  : 'Revenue'
              }
              chatData={revenue}
              category={['Revenue']}
              info={
                campainChartData?.Revenue?.info
                  ? campainChartData?.Revenue?.info
                  : 'Revenue'
              }
              prevPeriod={campainChartData?.Revenue?.previous_period}
              previousYear={campainChartData?.Revenue?.previous_year}
              isLoading={campaignsReport.loading}
              totalValue={formatNumber(lastMonthRevenue)}
              error={campaignsReport.error}
              customDataTooltip={true}
              icon="BingAnalyticsTab"
              retryMethod={retryFetchBingCampaign}
            />
          </Col>
          <Col>
            <AreaCardChart
              title={
                campainChartData?.Spend?.label
                  ? campainChartData?.Spend?.label
                  : 'Spend'
              }
              chatData={spend}
              category={['Spend']}
              info={
                campainChartData?.Spend?.info
                  ? campainChartData?.Spend?.info
                  : 'Spend'
              }
              prevPeriod={campainChartData?.Spend?.previous_period}
              previousYear={campainChartData?.Spend?.previous_year}
              isLoading={campaignsReport.loading}
              totalValue={formatToCurrency(lastMonthSpend)}
              error={campaignsReport.error}
              hasDollar
              icon="BingAnalyticsTab"
              retryMethod={retryFetchBingCampaign}
            />
          </Col>
          {!isLeadGen && (
            <Col>
              <AreaCardChart
                title="Return on Ad Spend"
                chatData={totalRoas}
                category={['Return on Ad Spend']}
                info={
                  campainChartData?.ReturnOnAdSpend?.info
                    ? campainChartData?.ReturnOnAdSpend?.info
                    : 'Return on Ad Spend'
                }
                prevPeriod={`${campainChartData?.ReturnOnAdSpend?.previous_period}`}
                previousYear={`${campainChartData?.ReturnOnAdSpend?.previous_year}`}
                isLoading={campaignsReport.loading}
                totalValue={formatNumber(lastMonthTotalRoas)}
                error={campaignsReport.error}
                icon="BingAnalyticsTab"
                propertyId={`Client ID: ${selectedClient?.id}`}
                retryMethod={retryFetchBingCampaign}
              />{' '}
            </Col>
          )}
        </Grid>
      </div>
    </>
  );
};

export default MicrosoftAdsOverview;
