import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const TrendDown: React.FC<IconProps> = ({ color = 'black', size = 40 }) => (
  <svg
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.20432 4.00232C1.40944 3.7972 1.74201 3.7972 1.94714 4.00232L4.72724 6.78243L6.45685 5.05283C6.66197 4.8477 6.99454 4.8477 7.19966 5.05283L10.505 8.35819V5.42424C10.505 5.13415 10.7402 4.89899 11.0303 4.89899C11.3204 4.89899 11.5555 5.13415 11.5555 5.42424V9.62626C11.5555 9.91635 11.3204 10.1515 11.0303 10.1515H6.82826C6.53817 10.1515 6.303 9.91635 6.303 9.62626C6.303 9.33617 6.53817 9.10101 6.82826 9.10101H9.7622L6.82826 6.16706L5.09865 7.89666C4.89353 8.10178 4.56096 8.10178 4.35583 7.89666L1.20432 4.74514C0.999195 4.54002 0.999195 4.20745 1.20432 4.00232Z"
      fill="#EF4444"
    />
  </svg>
);

export default TrendDown;
