import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const RequestedBell: React.FC<IconProps> = ({
  color = '#313233',
  size = 20,
}) => (
  <svg
    width="16"
    height="19"
    viewBox="0 0 16 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99206 15.0124C10.3494 16.3461 9.55796 17.7169 8.22429 18.0743C6.89063 18.4317 5.51979 17.6402 5.16243 16.3065M7.07584 4.78416C7.32935 4.32808 7.41285 3.77683 7.26713 3.233C6.96934 2.12161 5.82697 1.46206 4.71558 1.75986C3.60419 2.05765 2.94465 3.20002 3.24244 4.31141C3.38816 4.85524 3.73611 5.29088 4.18369 5.55911M11.5295 7.87119C11.232 6.76108 10.4379 5.83278 9.32187 5.29051C8.20581 4.74823 6.85921 4.6364 5.57831 4.97961C4.29742 5.32283 3.18714 6.09298 2.49174 7.12063C1.79634 8.14829 1.57278 9.34927 1.87023 10.4594C2.36238 12.2961 2.26681 13.7614 1.95602 14.8749C1.6018 16.1439 1.42469 16.7784 1.47254 16.9058C1.52728 17.0516 1.56688 17.0916 1.71207 17.1479C1.83898 17.1971 2.37212 17.0542 3.43841 16.7685L13.326 14.1191C14.3922 13.8334 14.9254 13.6906 15.0107 13.5845C15.1083 13.4632 15.1226 13.4088 15.0971 13.2551C15.0749 13.1209 14.6042 12.6599 13.6629 11.738C12.8371 10.9291 12.0216 9.70791 11.5295 7.87119Z"
      stroke="#005C9F"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RequestedBell;
