import { useAccessStore } from '../../store/useAccessStore';
import { Button, Card, Grid, Col } from '@tremor/react';
import Icon from '../../assets/icons/SvgComponent';
import ImageIcon from '../../components/ImageIcon';
import CustomModal from '../../components/CustomModal';
import { useThemeStore } from '../../store/useThemeStore';
import ViewAccessModal from '../../components/Modals/OMGSettings/viewAccessModal';
import ToolTipV2 from '../../components/TooltipV2';
import Tooltip from '../../components/HelpTooltip';
import moment from 'moment';

import { useEffect, useState } from 'react';

const Security = () => {
  const { fetchAccess, access, loading } = useAccessStore();
  const [accessItem, setAccessItem] = useState({});
  const [openViewAccessModal, setOpenAccessModal] = useState(false);
  const { mode } = useThemeStore((state: any) => state);
  const iconColor = mode === 'dark' ? 'white' : 'black';

  useEffect(() => {
    const filter = {
      page: 1,
      limit: 100,
    };
    fetchAccess(filter);
  }, []);

  return (
    <div className="flex  p-8  gap-y-5 bg-[#D2EEFF] dark:bg-[#1D1D1D]">
      {loading ? (
        <Card className="w-full relative h-[155px]">
          <div className={`absolute top-[40%] left-[48%] overflow-hidden`}>
            <Icon name="Loading" />
          </div>
        </Card>
      ) : (
        <>
          {access?.data ? (
            <Grid numItems={3} className="gap-4 w-full">
              {access?.data?.map((item: any, index: number) => {
                const clientsData = item?.clients;
                const firstFiveClients = item?.clients?.slice(0, 5);
                let tooltip = '';

                const clientNames = item?.clients.map(
                  (client: any) => client.name
                );

                if (clientNames && clientNames.length > 10) {
                  const displayedNames = clientNames.slice(0, 10).join('\n');
                  const remainingCount = clientNames.length - 10;
                  tooltip = `${displayedNames}\n... +${remainingCount}`;
                } else if (clientNames) {
                  tooltip = clientNames.join('\n');
                }

                const isExpired = moment().isAfter(moment(item.tokenExpiry));
                return (
                  <Col key={index}>
                    <Card className="h-[250px] flex flex-col justify-between">
                      <div className="flex justify-between ">
                        {item?.userId ? (
                          <div className="flex items-start gap-2 pb-2">
                            <ImageIcon
                              data={`${item?.userId?.first_name} ${item?.userId?.last_name}`}
                              size={11}
                              textSize={'xl'}
                            />
                          </div>
                        ) : (
                          <div className="flex items-start gap-2 pb-2">
                            <ImageIcon data={`N U`} size={11} textSize={'xl'} />
                          </div>
                        )}

                        {isExpired ? (
                          <span className="text-xs font-bold bg-red-500 text-[#fff] w-fit p-1 rounded h-fit">
                            Expired
                          </span>
                        ) : (
                          <span className="text-xs font-bold bg-green-500 text-[#fff] w-fit p-1 rounded h-fit">
                            Active
                          </span>
                        )}
                      </div>
                      <div className="flex flex-col ">
                        {item?.userId ? (
                          <span className="font-semibold text-[#081765] dark:text-[#fff]">{`${item?.userId?.first_name} ${item?.userId?.last_name}`}</span>
                        ) : (
                          <span className="font-semibold text-[#081765] dark:text-[#fff]">
                            No username
                          </span>
                        )}
                        <span className="text-sm text-[#475467]">
                          Token expiration{' '}
                          {moment(item.tokenExpiry).format('DD MMM YYYY')}
                        </span>
                      </div>
                      <div className="flex w-full justify-between items-center">
                        <div className="flex items-center gap-8">
                          <Button
                            size="xs"
                            variant="light"
                            onClick={() => {
                              setAccessItem(item);
                              setOpenAccessModal(true);
                            }}
                          >
                            View
                          </Button>
                        </div>
                        <div className="flex space-x-[-6px]">
                          {firstFiveClients?.map((client, index) => {
                            return (
                              <Tooltip
                                content={client.name}
                                position="left"
                                key={index}
                                customBgColor="text-center w-fit bg-neutral-900 text-white"
                              >
                                <div className="w-fit rounded-full border border-white">
                                  <ImageIcon
                                    data={client?.name}
                                    size={8}
                                    textSize={'xs'}
                                  />
                                </div>
                              </Tooltip>
                            );
                          })}
                          <ToolTipV2 tooltip={tooltip}>
                            <div
                              className={
                                clientsData.length <= 5
                                  ? 'hidden'
                                  : 'w-9 h-9 rounded-full overflow-hidden relative border-white border-2 font-inter'
                              }
                            >
                              <div
                                className={
                                  clientsData.length <= 5
                                    ? 'hidden'
                                    : 'bg-orange-400 text-white flex items-center justify-center text-xs w-full h-full uppercase'
                                }
                              >
                                {clientsData.length <= 5
                                  ? ''
                                  : `+${clientsData.length - 5}`}
                              </div>
                            </div>
                          </ToolTipV2>
                        </div>
                      </div>
                    </Card>
                  </Col>
                );
              })}
            </Grid>
          ) : (
            <div className="w-full flex justify-center">
              <div className="flex flex-col items-center">
                <Icon name="NoData" />
                <div className="mt-4">No Access Data</div>
              </div>
            </div>
          )}
        </>
      )}
      <CustomModal
        open={openViewAccessModal}
        onClose={() => setOpenAccessModal(false)}
      >
        <ViewAccessModal
          onClose={() => setOpenAccessModal(false)}
          user={accessItem}
        />
      </CustomModal>
    </div>
  );
};

export default Security;
