import { ChangeEvent } from 'react';

type InputProps = {
  label?: string;
  type?: string;
  name?: string;
  value?: any;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  width?: string;
  errorMessage?: string;
  placeholder?: string;
  disabled?: boolean;
};

export const Input: React.FC<InputProps> = ({
  label,
  type,
  name,
  value,
  onChange,
  width = 'w-full',
  errorMessage,
  placeholder,
  disabled = false,
}) => (
  <div className="relative w-full flex flex-col">
    <label className="text-sm text-[#001C44]  font-inter dark:text-white">
      {label}
    </label>
    <input
      type={type}
      name={name}
      disabled={disabled}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      className={`w-${width} rounded-lg p-2 text-[#001129] border border-[#808EA2] mt-1 dark:bg-[#191919] dark:text-white `}
    />
    {errorMessage && !value && (
      <div className="absolute bottom-[-20px] text-red-500 text-sm mt-1">
        {errorMessage}
      </div>
    )}
  </div>
);
