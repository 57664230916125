import { Grid, Col } from '@tremor/react';
import GoogleRankings from '../GoogleRankings';
import use3rdPartyStore from '../../../store/use3rdPartyStore';
import Rankings from '../Rankings';
import useClientStore from '../../../store/useClientStore';

const Ranking = () => {
  const { rankRangerData, googleRanking } = use3rdPartyStore((state) => state);
  const { selectedClient } = useClientStore((state) => state);
  const isRankRangerLoading =
    rankRangerData?.loading || selectedClient?.loading;
  const isGoogleRankingLoading =
    googleRanking?.loading || selectedClient?.loading;

  return (
    <Grid numItemsSm={1} numItemsLg={1} className="lg:gap-4 gap-4 mt-4">
      {/* <Col>
        <GoogleRankings
          name="Google Rankings"
          loading={isGoogleRankingLoading}
          data={googleRanking}
          info={googleRanking?.info ? googleRanking?.info : 'Google Ranking'}
        />
      </Col> */}
      <Col>
        <Rankings isLoading={isRankRangerLoading} data={rankRangerData} error={rankRangerData.error} />
      </Col>
    </Grid>
  );
};

export default Ranking;
