import { BtnWrapper, DateWrapper } from './styles';

import { useState } from 'react';
import useReportStore from '../../../store/useReportStore';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addDays, startOfToday, setHours, format } from 'date-fns';
import styled from 'styled-components';
import Tooltip from '../../HelpTooltip';
import Icon from '../../../assets/icons/SvgComponent';
import { ToastContainer, toast } from 'react-toastify';
import Alert from '../../Alert';
interface ConfirmReportProps {
  onClose: (value: boolean) => void;
  confirmationType: string;
  selectedItems: any[];
  setSelectedItems: (items: any[]) => void;
}

const ConfrimReport: React.FC<ConfirmReportProps> = ({
  onClose,
  confirmationType,
  selectedItems,
  setSelectedItems,
}) => {
  const [saveError, setSaveError] = useState<string | boolean>(false);
  const [updateComment, setUpdateComment] = useState<string>('');

  const hideCheckBox = ['reject', 'revoke', 'invalidate', 'cancel'].includes(
    confirmationType
  );
  const { bulkReportUpdate, sendReport, fetchReportSummary } = useReportStore(
    (state) => state
  );
  const { cycle, type, reportDate, all, select } = useReportStore(
    (state) => state.filters
  );
  const [selectedDate, setSelectedDate] = useState<Date>(
    addDays(new Date(), 1)
  );

  const itemswithoutEmails =
    confirmationType === 'validate' &&
    selectedItems.filter(
      (obj) =>
        !obj.recipients?.some(
          (email: string) => !email.includes('onlinemarketinggurus')
        ) || !obj.recipients.length
    );

  const itemswithoutHSEmails =
    confirmationType === 'review'
      ? selectedItems.filter((item) => !(item.health_status as any[]).length)
      : (confirmationType === 'validate' &&
          (((itemswithoutEmails as any[])?.length && itemswithoutEmails) ||
            selectedItems.filter(
              (item) => (item.health_status as any[]).length !== 2
            ))) ||
        [];
  const handleStatusChange = async () => {
    try {
      let data: any = confirmationType === 'send' ? {} : [];
      for (let item of selectedItems) {
        switch (confirmationType) {
          case 'send':
            data.reports = selectedItems.map((item) => item.id);
            data.comment = updateComment || 'Report sent';
            break;
          case 'revoke':
            data.push({
              _id: item.id,
              status: 'in_progress',
              comment: updateComment || 'Revoked submission',
            });
            break;
          case 'validate':
            data.push({
              _id: item.id,
              status: 'validated',
              comment: updateComment || 'Report validated',
            });
            break;
          case 'approve':
            data.push({
              _id: item.id,
              status: 'approved',
              comment: updateComment || 'Report approved',
            });
            break;
          case 'review':
            data.push({
              _id: item.id,
              status: 'review',
              comment: updateComment || 'Submitted for review',
            });
            break;
          case 'invalidate':
            data.push({
              _id: item.id,
              status: 'review',
              comment: updateComment || 'invalidated',
            });
            break;
          case 'schedule':
            data.push({
              _id: item.id,
              scheduled_at: format(selectedDate, 'dd/MM/yyyy'),
              comment: updateComment || 'Report scheduled',
            });
            break;
          case 'reject':
            data.push({
              _id: item.id,
              status: 'rejected',
              comment: updateComment || 'Report rejected',
            });
            break;
          case 'cancel':
            data.push({
              _id: item.id,
              status: 'canceled',
              comment: updateComment || 'Report canceled',
            });
            break;
          default:
            throw new Error(`Invalid confirmation type: ${confirmationType}`);
        }
      }

      if (Array.isArray(data) && data.length && confirmationType !== 'send') {
        bulkReportUpdate(data)
          .then(() => onClose(true))
          .catch((e: any) => {
            setSaveError(e.message);
            toast.error(
              <Alert
                icon=""
                title={`ERROR MESSAGE!`}
                message={`${e.message}`}
              />,
              {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                containerId: 'confirm-container',
              }
            );
          });
      } else if (data.reports && confirmationType === 'send') {
        sendReport(data)
          .catch((e: any) => setSaveError(e.message))
          .finally(() => onClose(true));
      }
    } catch (e: any) {
      setSaveError(e.message);
    }
    setTimeout(() => {
      getSummaries();
    }, 300);
  };
  const getSummaries = () => {
    const filters = {
      date: reportDate,
      type: type.id,
      cycle: cycle.id,
      all_users: all.id,
      missing_field: select.id,
      report_basis: 'monthly',
    };
    fetchReportSummary(filters);
  };

  const isCommentValid = () => {
    const trimmedComment = updateComment.replace(/\s+/g, '');
    return trimmedComment.length >= 5;
  };

  const renderUpdateCommentInput = () => {
    return (
      <div className="font-inter mx-[24px]">
        <label className="block mb-2 font-medium text-gray-900 dark:text-white text-[14px] font-[400]">
          Leave your comment <span className="text-[#BF3636]">*</span>
        </label>
        <textarea
          className="block p-2.5 w-full min-h-[129px] text-sm text-gray-900 rounded-lg border border-[#808EA2] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          value={updateComment}
          onChange={(e) => setUpdateComment(e.target.value)}
        />
      </div>
    );
  };
  const renderCalendarInput = () => {
    if (confirmationType === 'schedule') {
      let minDate = setHours(startOfToday(), 10);
      if (startOfToday().getHours() >= 22) {
        minDate = addDays(minDate, 1);
      }
      return (
        <div className="relative ml-6 mb-2">
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date as Date)}
            minDate={minDate}
            className=" border border-gray-400 w-[110px] rounded-lg h-[38px] px-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
      );
    }
  };

  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const handleDeleteRecipient = (client: any) => {
    const filteredItems = selectedItems?.filter(
      (recipient) => recipient !== client
    );
    setSelectedItems(filteredItems);
  };

  //   <Text style={{ color: 'white' }}>{saveError}</Text>

  return (
    <div
      className="fixed top-0 right-0 bg-white h-screen min-h-[720px] w-screen sm:w-[26rem] font-inter flex flex-col z-100 dark:bg-[#262627] dark:border dark:border-[#313233] dark:shadow-bs-card"
      onClick={(e) => e.stopPropagation()}
    >
      <ToastContainer containerId="confirm-container" />
      <div className="flex justify-between items-center min-h-[10vh] p-[24px]">
        <div className="capitalize font-inter text-[#001129] text-xl font-[600] dark:text-gray-400">
          {confirmationType} Report
        </div>
        <div className="cursor-pointer" onClick={() => onClose(false)}>
          <Icon size={30} name="Xclose" />
        </div>
      </div>
      <div className="border  border-b mb-8" />
      <div className="relative max-h-[76vh] min-h-[76vh] overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8">
        <div className="px-[24px] font-inter text-[#001129] text-md font-[300] dark:text-gray-400">
          You are about to {confirmationType} the report for the following
          client:
        </div>
        <div className="mx-[24px] border border-[#808EA2] rounded-lg mt-[16px] mb-[8px]">
          <div className="flex flex-wrap gap-2 p-[14px]">
            {selectedItems?.map((item, i) => {
              const truncate = (str, maxLength) => {
                return str.length > maxLength
                  ? `${str.substring(0, maxLength)}...`
                  : str;
              };
              return (
                <Tooltip
                  key={i}
                  content={`
                  ${item.name}
                  ${
                    item?.recipients.length > 0
                      ? `
                      Recipients: 
                      ${item.recipients.map((recipient: any) => truncate(recipient, 20)).join(', ')}`
                      : `No Recipients`
                  } `}
                  position="bottom"
                >
                  <span
                    className="flex items-center gap-2 border rounded-md border-[#808EA2] px-2 text-[#001C44] font-[400] text-[14px]"
                    key={i}
                  >
                    <p className="dark:text-gray-400">{item.name}</p>
                    <div onClick={() => handleDeleteRecipient(item)}>
                      <Icon name="Close" size={12} />
                    </div>
                  </span>
                </Tooltip>
              );
            })}
          </div>
        </div>
        {!itemswithoutHSEmails.length ? (
          <>
            {hideCheckBox ? null : (
              <div className="checkbox-wrapper flex items-baseline gap-2 mx-[24px] mb-2 mt-4 pl-2 ">
                <input
                  className="checkbox"
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />{' '}
                <span className="note text-sm text-[#7C7E7F] dark:text-gray-400">
                  Confirm the accuracy of all report recipients by hovering over
                  the client name above
                </span>
              </div>
            )}
            {renderCalendarInput()}
            {renderUpdateCommentInput()}
          </>
        ) : null}

        {(itemswithoutHSEmails?.length && !hideCheckBox) ||
        (!selectedItems[0]?.recipients?.length && !hideCheckBox) ? (
          <div className="mx-[24px] mt-8">
            <div className="text-[#CC002F]  font-[400] text-[14px]  bg-[#FFE8ED] border-l-[#CC002F] border-l-[4px] rounded-lg py-[12px] px-[16px] dark:bg-gray-900">
              <div className="uppercase font-[600] flex gap-[6px] mb-[8px]">
                <Icon name="AlertCircle" />
                <div>Warning</div>
              </div>
              <div>
                Please review the following clients where the health
                status/recipients are incomplete. Ensure that the recipients
                include client email addresses.
              </div>
            </div>
          </div>
        ) : null}
        <div className="flex gap-2 flex-wrap px-[24px] py-[16px]">
          {itemswithoutHSEmails?.map((item, i) => {
            return (
              <ul key={i}>
                <li className="font-[400] text-[#BF3636] border border-[#F58F8F] py-[2px] px-[5px] rounded-lg text-[14px] ">
                  {item.name}
                </li>
              </ul>
            );
          })}
        </div>
      </div>
      <div className="flex justify-center items-center min-h-[10vh] p-[24px] border border-t-[#E6EBF0] dark:border-none">
        <div className="flex justify-between w-full mb-5">
          {hideCheckBox ? (
            <button
              disabled={!selectedItems.length || !isCommentValid()}
              onClick={() =>
                selectedItems.length ? handleStatusChange() : null
              }
              style={{
                width: '100%',
                height: 40,
                background: selectedItems?.length ? '#cf0101' : '#39a342',
                opacity: isCommentValid() ? 1 : 0.5,
                color: 'white',
                border: 'none',
                borderRadius: 5,
                cursor: isCommentValid() ? 'pointer' : 'not-allowed',
              }}
            >
              {confirmationType.charAt(0).toUpperCase() +
                confirmationType.slice(1)}
            </button>
          ) : (
            <button
              disabled={!isChecked || !selectedItems.length}
              onClick={() =>
                isChecked && selectedItems.length ? handleStatusChange() : null
              }
              style={{
                width: '100%',
                height: 40,
                background: '#005C9F',
                opacity: isChecked ? 0.8 : 0.2,
                color: 'white',
                border: 'none',
                borderRadius: 5,
                cursor: isChecked ? 'pointer' : 'not-allowed',
              }}
            >
              {confirmationType.charAt(0).toUpperCase() +
                confirmationType.slice(1)}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfrimReport;
