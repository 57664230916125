import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import {
  processData,
  formatNumber,
  formatNumberWithCommas,
} from '../../../utils/common';
import AreaCardChart from '../AreaCardChart';

import useGoogleStore from '../../../store/useGoogleStore';

interface IProps {
  retry?: () => void;
}


const MobileView = ({ retry }: IProps) => {

  const { googleSearchConsole } = useGoogleStore((state) => state);
  const clicks = processData(googleSearchConsole, 'clicks');
  const lastMonthClicks =
    Array.isArray(clicks) && clicks.length > 0
      ? clicks[clicks.length - 1]?.value
      : undefined;

  const impressions = processData(googleSearchConsole, 'impressions');
  const lastMonthImpressions =
    Array.isArray(impressions) && impressions.length > 0
      ? impressions[impressions.length - 1]?.value
      : undefined;

  const ctr = processData(googleSearchConsole, 'ctr');
  const lastMonthCtr =
    Array.isArray(ctr) && ctr.length > 0
      ? ctr[ctr.length - 1]?.value
      : undefined;

  const position = processData(googleSearchConsole, 'position');
  const lastMonthPosition =
    Array.isArray(position) && position.length > 0
      ? position[position.length - 1]?.value
      : undefined;
  return (
    <div className="block sm:hidden my-4">
      <div className="flex flex-col gap-4">
        <div className="p-0">
          <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
            <SwiperSlide>
              <AreaCardChart
                title={'Clicks'}
                chatData={clicks}
                category={['Clicks']}
                info={
                  googleSearchConsole?.clicks?.info
                    ? googleSearchConsole?.clicks?.info
                    : 'Clicks'
                }
                prevPeriod={googleSearchConsole?.clicks?.previous_period}
                previousYear={googleSearchConsole?.clicks?.previous_year}
                isLoading={googleSearchConsole.loading}
                totalValue={formatNumberWithCommas(lastMonthClicks)}
                error={googleSearchConsole.error}
                icon="googleSearch"
                retryMethod={retry}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title={'Impressions'}
                chatData={impressions}
                category={['Impressions']}
                info={
                  googleSearchConsole?.impressions?.info
                    ? googleSearchConsole?.impressions?.info
                    : 'Impressions'
                }
                prevPeriod={googleSearchConsole?.impressions?.previous_period}
                previousYear={googleSearchConsole?.impressions?.previous_year}
                isLoading={googleSearchConsole.loading}
                totalValue={formatNumberWithCommas(lastMonthImpressions)}
                error={googleSearchConsole.error}
                icon="googleSearch"
                retryMethod={retry}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title={'CTR'}
                chatData={ctr}
                category={['Ctr']}
                info={
                  googleSearchConsole?.ctr?.info
                    ? googleSearchConsole?.ctr?.info
                    : 'CTR'
                }
                prevPeriod={googleSearchConsole?.ctr?.previous_period}
                previousYear={googleSearchConsole?.ctr?.previous_year}
                isLoading={googleSearchConsole.loading}
                totalValue={formatNumber(lastMonthCtr, true)}
                error={googleSearchConsole.error}
                hasPercent
                icon="googleSearch"
                retryMethod={retry}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title={'Positions'}
                chatData={position}
                category={['Position']}
                info={
                  googleSearchConsole?.position?.info
                    ? googleSearchConsole?.position?.info
                    : 'Positions'
                }
                prevPeriod={googleSearchConsole?.position?.previous_period}
                previousYear={googleSearchConsole?.position?.previous_year}
                isLoading={googleSearchConsole.loading}
                totalValue={formatNumberWithCommas(lastMonthPosition)}
                error={googleSearchConsole.error}
                icon="googleSearch"
                retryMethod={retry}
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default MobileView;
