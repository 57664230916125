import React from 'react';

interface MailProps {
  color?: string;
  size?: number;
}

const Mail: React.FC<MailProps> = ({ color = '#428B6E', size = 28}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 28 28"
    fill={color}
  >
    <path
      d="M2.33331 8.16666L11.8591 14.8347C12.6304 15.3746 13.0161 15.6446 13.4356 15.7492C13.8062 15.8416 14.1938 15.8416 14.5643 15.7492C14.9839 15.6446 15.3695 15.3746 16.1409 14.8347L25.6666 8.16666M7.93331 23.3333H20.0666C22.0268 23.3333 23.0069 23.3333 23.7556 22.9518C24.4142 22.6163 24.9496 22.0809 25.2852 21.4223C25.6666 20.6736 25.6666 19.6935 25.6666 17.7333V10.2667C25.6666 8.30647 25.6666 7.32638 25.2852 6.57769C24.9496 5.91912 24.4142 5.38369 23.7556 5.04813C23.0069 4.66666 22.0268 4.66666 20.0666 4.66666H7.93331C5.97313 4.66666 4.99304 4.66666 4.24435 5.04813C3.58578 5.38369 3.05035 5.91912 2.71479 6.57769C2.33331 7.32638 2.33331 8.30647 2.33331 10.2667V17.7333C2.33331 19.6935 2.33331 20.6736 2.71479 21.4223C3.05035 22.0809 3.58578 22.6163 4.24435 22.9518C4.99304 23.3333 5.97313 23.3333 7.93331 23.3333Z"
      stroke={'#001C44'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Mail;
