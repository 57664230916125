import React, { useEffect } from 'react';
import Icon from '../../assets/icons/SvgComponent';
import { useThemeStore } from '../../store/useThemeStore';

interface PaginationProps {
  totalPages: number;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  totalDocs?: any;
}

const Pagination: React.FC<PaginationProps> = ({
  totalPages,
  page,
  setPage,
  totalDocs,
}) => {
  const { mode } = useThemeStore((state) => state);
  const iconColor = mode === 'dark' ? 'white' : 'black';
  useEffect(() => {
    // Your useEffect logic here (if needed)
  }, [totalPages, page]);

  const pages = [...Array(totalPages).keys()].map((num) => num + 1);

  const handlePreviousPage = () => {
    setPage((prevPage) => {
      if (prevPage === 1) {
        return prevPage;
      }
      return prevPage - 1;
    });
  };

  const handleNextPage = () => {
    if (page >= totalPages) {
      return;
    }
    setPage((prevPage) => prevPage + 1);
  };

  const handleLastPage = () => {
    setPage(totalPages);
  };

  if (totalPages <= 1) return null;

  return (
    <>
      <div className="w-full hidden sm:flex justify-between items-center bg-white dark:bg-gray-800 shadow-md py-2 px-4  rounded-b-md   border-t border-gray-300 ">
        <div className="font-inter text-sm font-[400] dark:text-white">
          Results : {totalDocs}
        </div>
        <div className="flex items-center justify-between w-[590px] ">
          <div>
            <button
              onClick={handlePreviousPage}
              className="flex items-center justify-center select-none px-4 h-10 text-base font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >
              <Icon
                name="ArrowLeft"
                color={mode === 'dark' ? '#fff' : iconColor}
              />
              <span className="ml-2 select-none">Previous</span>
            </button>
          </div>
          <div>
            <div className="container mx-auto px-4">
              <div className="flex items-center">
                {page > 3 && totalPages !== 7 && (
                  <div className="flex dark:text-[#fff]">
                    <div
                      className={`p-4 mr-1 text-sm text-center flex items-center justify-center rounded-full select-none h-8 w-8 text-gray-500 border border-gray-300 hover.bg-blue-100 hover.text-blue-700 dark.border-gray-700 dark.bg-gray-700 dark.text-white cursor-pointer`}
                      onClick={() => setPage(1)}
                    >
                      1
                    </div>
                    ...
                  </div>
                )}
                {page > 2 && (
                  <div
                    className={`p-4 ml-1 text-sm text-center flex items-center justify-center rounded-full select-none h-8 w-8 text-gray-500 border border-gray-300 hover.bg-blue-100 hover.text-blue-700 dark.border-gray-700 dark.bg-gray-700 dark.text-white cursor-pointer`}
                    onClick={() => setPage(page - 2)}
                  >
                    {page - 2}
                  </div>
                )}
                {page > 1 && (
                  <div
                    className={`p-4 ml-1 text-sm text-center flex items-center justify-center rounded-full select-none h-8 w-8 text-gray-500 border border-gray-300 hover.bg-blue-100 hover.text-blue-700 dark.border-gray-700 dark.bg-gray-700 dark.text-white cursor-pointer`}
                    onClick={() => setPage(page - 1)}
                  >
                    {page - 1}
                  </div>
                )}
                <div
                  className={`p-4 mx-1 text-sm text-center flex items-center justify-center rounded-full select-none h-8 w-8 text-blue-600 border border-gray-300 ${
                    page === page
                      ? 'bg-gray-200 cursor-not-allowed text-gray-900'
                      : 'cursor-pointer text-gray-600'
                  } hover:bg-blue-100 hover:text-blue-700 dark.border-gray-700 dark.bg-gray-700 dark.text-white cursor-pointer`}
                  onClick={() => setPage(page)}
                >
                  {page}
                </div>
                {page < totalPages && (
                  <div
                    className={`p-4 ml-1 text-sm text-center flex items-center justify-center rounded-full select-none h-8 w-8 text-gray-500 border border-gray-300 hover.bg-blue-100 hover.text-blue-700 dark.border-gray-700 dark.bg-gray-700 dark.text-white cursor-pointer`}
                    onClick={() => setPage(page + 1)}
                  >
                    {page + 1}
                  </div>
                )}
                {page < totalPages - 1 && (
                  <div
                    className={`p-4 ml-1 text-sm text-center flex items-center justify-center rounded-full select-none h-8 w-8 text-gray-500 border border-gray-300 hover.bg-blue-100 hover.text-blue-700 dark.border-gray-700 dark.bg-gray-700 dark.text-white cursor-pointer`}
                    onClick={() => setPage(page + 2)}
                  >
                    {page + 2}
                  </div>
                )}
                {page < totalPages - 2 && totalPages !== 3 && (
                  <div className="flex">
                    <div className="ml-1 dark:text-[#fff]">...</div>
                    <div
                      className={`p-4 ml-1 text-sm text-center flex items-center justify-center rounded-full select-none h-8 w-8 text-gray-500 border border-gray-300 hover.bg-blue-100 hover.text-blue-700 dark.border-gray-700 dark.bg-gray-700 dark.text-white cursor-pointer`}
                      onClick={() => setPage(totalPages)}
                    >
                      {totalPages}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            <button
              onClick={handleNextPage}
              className="flex items-center justify-center px-4 h-10 select-none text-base font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >
              <span className="mr-2 select-none">Next</span>
              <Icon
                name="ArrowRight"
                color={mode === 'dark' ? '#fff' : iconColor}
              />
            </button>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-between sm:hidden  bg-white dark:bg-gray-800 shadow-md py-2 px-4  rounded-b-md border-t border-gray-300 ">
        <button
          onClick={handlePreviousPage}
          className="flex items-center justify-center select-none px-4 h-10 text-base font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
        >
          <Icon name="ArrowLeft" color={mode === 'dark' ? '#fff' : iconColor} />
          <span className="ml-2 select-none">Previous</span>
        </button>
        <button
          onClick={handleNextPage}
          className="flex items-center justify-center px-4 h-10 select-none text-base font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
        >
          <span className="mr-2 select-none">Next</span>
          <Icon
            name="ArrowRight"
            color={mode === 'dark' ? '#fff' : iconColor}
          />
        </button>
      </div>
    </>
  );
};

export default Pagination;
