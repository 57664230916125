import React, { useState, useCallback, useRef } from 'react';
import AsyncSelect from 'react-select/async';
import { StylesConfig } from 'react-select';
import useClientStore from '../../store/useClientStore';
import useAuthStore from '../../store/useAuthStore';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
interface IProps {
  value?: any;
  onChange?: any;
  placeholder?: string;
}

const ClientSearchBar = ({ placeholder = 'Search by name' }: IProps) => {
  const customStyles: StylesConfig<any, boolean, any> = {
    control: (provided) => ({
      ...provided,
      ...{
        borderRadius: '8px',
        border: '1px solid #D1D5DB',
        opacity: 1,
        cursor: 'pointer',
        fontSize: '14px',
        scrollbarWidth: 'thin',
      },
    }),
    option: (provided, state) => {
      return {
        ...provided,
        color: state.data.isDisabled ? '#6D8080' : '',
        fontSize: '14px',
        cursor: 'pointer',
      };
    },
    menuPortal: (provided) => ({ ...provided, zIndex: 1000 }),
  };
  const { fetchClients } = useClientStore((state) => state);
  const { setDomain } = useAuthStore((state) => state);
  const [lastQuery, setLastQuery] = useState('');
  const debounceTimer = useRef<NodeJS.Timeout | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const loadOptions = useCallback(
    (inputValue: string, callback: (options: any) => void) => {
      if (inputValue.length > 2 && inputValue !== lastQuery) {
        setLastQuery(inputValue);

        const filters = {
          status: 'all',
          search: inputValue,
          page: 1,
          limit: 100,
        };

        if (debounceTimer.current) {
          clearTimeout(debounceTimer.current);
        }

        debounceTimer.current = setTimeout(() => {
          fetchClients(filters)
            .then((response: any) => {
              const options =
                response?.data?.map((item: any) => ({
                  label: item.name,
                  value: item.id,
                  id: item.id,
                  cycle: item.cycle,
                })) || [];
              callback(options);
            })
            .catch((error: any) => {
              console.error('Error fetching clients:', error);
              callback([]);
            });
        }, 500); // 500ms debounce time
      } else {
        callback([]);
      }
    },
    [lastQuery, fetchClients]
  );
  const handleDomainChange = (e: any) => {
    const searchParams = new URLSearchParams(location.search);
    const startDate = searchParams.get('startDate');
    const day = moment(startDate).date();
    const cycle2 = e.cycle === 'Cycle 2';
    const isChangeCycle = day === 1 && cycle2 ? true : false;
    // if(isChangeCycle){
    //   searchParams.set('startDate', moment(startDate).add(14, 'days').format('YYYY-MM-DD'));
    //   searchParams.set('endDate', moment(startDate).add(1, 'month').add(13, 'day').format('YYYY-MM-DD'));
    // }
    searchParams.set('id', e.id);
    const updatedSearch = searchParams?.toString();
    location.search = updatedSearch;
    navigate(location);
    setDomain(e);
  };
  return (
    <div className="w-[280px]  sm:w-56">
      <AsyncSelect
        className="my-react-select-container"
        classNamePrefix="my-react-select"
        placeholder={placeholder}
        cacheOptions
        loadOptions={loadOptions}
        menuPortalTarget={document.body}
        styles={customStyles}
        defaultOptions
        onChange={(e) => handleDomainChange(e)}
        noOptionsMessage={() => 'Type 3+ letters to search'}
        // Add more props if necessary
      />
    </div>
  );
};

export default ClientSearchBar;
