import Icon from '../../assets/icons/SvgComponent';
import ReactSelect from '../../components/ReactSelect';
import moment from 'moment';
import TanStackTable from '../../components/TanStackTable';
import DatePicker from 'react-datepicker';
import ImageIcon from '../../components/ImageIcon';
import Tooltip from '../../components/HelpTooltip';
import { useEffect, useState } from 'react';
import useActivitiesStore from '../../store/useActivitiesStore';
import { useThemeStore } from '../../store/useThemeStore';
import SearchBar from '../../components/Search';
import CustomModal from '../../components/CustomModal';
import LogsModal from '../../components/Modals/OMGSettings/Log';

interface ServicesOption {
  label: string;
  value: string;
}

const SettingsOptions: ServicesOption[] = [
  { value: 'all', label: 'All Methods' },
  { value: 'PATCH', label: 'Patch' },
  { value: 'POST', label: 'Post' },
  { value: 'DELETE', label: 'Delete' },
];

const ReportOptions: ServicesOption[] = [
  { value: 'all', label: 'All Models' },
  { value: 'report', label: 'Report' },
  { value: 'client', label: 'Client' },
  { value: 'integration', label: 'Client Integrations' },
  { value: 'user', label: 'User' },
];

const LogsSettings = () => {
  const { fetchLogs, logs } = useActivitiesStore((state) => state);
  const { mode } = useThemeStore((state: any) => state);
  const iconColor = mode === 'dark' ? 'white' : 'black';
  const [selectedRow, setSelectedRow] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [method, setMethod] = useState<ServicesOption | null>({
    value: 'PATCH',
    label: 'Patch',
  });
  const [report, setReport] = useState<ServicesOption | null>({
    value: 'all',
    label: 'All Models',
  });
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [selectedLog, setSelectedLog] = useState(null);

  const handleFetchLogs = (currentPage: number) => {
    const filter = {
      page: page,
      limit: 20,
      method:
        method?.value === 'all' ? '' : method?.value ? method?.value : 'PATCH',
      sort: 'desc',
      model:
        report?.value === 'all' ? '' : report?.value ? report?.value : 'user',
      search: searchText,
    };

    fetchLogs(filter);
    if (currentPage) {
      setPage(currentPage);
    }
  };

  useEffect(() => {
    handleFetchLogs(page);
  }, [page, method, report]);

  useEffect(() => {
    setPage(1);
  }, [method, report]);

  useEffect(() => {
    if (searchText?.length === 0) {
      handleFetchLogs(page);
    }
  }, [searchText]);

  const handleSearch = () => {
    try {
      handleFetchLogs(1);
      setPage(1);
    } catch (error) {
      console.log(error);
    }
  };
  const baseColumns = [
    {
      id: 'User and Email',
      header: () => (
        <div className="flex justify-start w-[16vw] text-sm font-normal font-inter ">
          User and Email
        </div>
      ),
      accessorKey: 'user',
      cell: (props) => {
        return (
          <div className="flex justify-start cursor-pointer gap-2  truncate w-[16vw] ">
            <ImageIcon
              data={`${props.getValue()?.first_name} ${props.getValue()?.last_name}`}
              size={8}
            />
            <div>
              <div className="truncate text-left max-w-[13vw]">{`${props.getValue()?.first_name} ${props.getValue()?.last_name}`}</div>
              <div className="truncate text-left max-w-[13vw]">
                {props.getValue()?.email}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      id: 'Role',
      header: () => (
        <div className="text-center w-28 text-sm font-normal font-inter">
          Role
        </div>
      ),
      accessorKey: 'user.role',
      cell: (props) => {
        return (
          <div className="flex justify-center w-28 uppercase">
            <span className="text-xs rounded-full border max-w-fit bg-[#D9D6FE] px-2 py-1 uppercase dark:bg-[#fff] dark:text-[#000] font-inter">
              {props.getValue()}
            </span>
          </div>
        );
      },
    },
    {
      id: 'Route',
      header: () => (
        <div className="text-center w-[18vw] text-sm font-normal font-inter">
          Route
        </div>
      ),
      accessorKey: 'route',
      cell: (props: any) => (
        <div className="text-center max-w-[18vw] truncate">
          <span>{props.getValue()}</span>
        </div>
      ),
    },
    {
      id: 'Model',
      header: () => (
        <div className="text-center  w-[12vw] text-sm font-normal font-inter">
          Model
        </div>
      ),
      accessorKey: 'model',
      cell: (props: any) => (
        <div className="text-center w-[12vw]">
          <span>
            {props.getValue() === null
              ? 'N/A'
              : props.getValue()?.toUpperCase()}
          </span>
        </div>
      ),
    },
    {
      id: 'Method',
      header: () => (
        <div className="text-center w-28 text-sm font-normal font-inter">
          Method
        </div>
      ),
      accessorKey: 'method',
      cell: (props: any) => (
        <div className="text-center w-28">
          <span>{props.getValue()}</span>
        </div>
      ),
    },
    {
      id: 'Time',
      header: () => (
        <div className="text-center w-44 text-sm font-normal font-inter pr-2">
          Time
        </div>
      ),
      accessorKey: 'timestamp',
      cell: (props) => (
        <div className="text-center w-44 pr-2">
          {moment(`${props.getValue()}`)?.format('DD MMM YYYY h:mm A')}
        </div>
      ),
    },
  ];

  const mergedColumns =
    method?.value === 'DELETE'
      ? [
          ...baseColumns.slice(0, -1), // Exclude last column
          {
            id: 'Request Params',
            header: () => (
              <div className="text-center w-28 text-sm font-normal font-inter">
                Request Params
              </div>
            ),
            accessorKey: 'request_params',
            cell: (props) => {
              let formattedOutput = 'N/A';

              try {
                const jsonData = JSON.parse(JSON.stringify(props.getValue()));

                formattedOutput = Object.entries(jsonData)
                  .map(
                    ([key, value]) =>
                      `${key.charAt(0).toUpperCase() + key.slice(1)}: ${value !== undefined ? value : 'N/A'}`
                  )
                  .join('\n');
              } catch (error) {
                console.error('Error parsing JSON:', error.message);
              }
              return (
                <div className="w-28 flex justify-center">
                  <Tooltip content={formattedOutput} position="left">
                    <Icon name="Eye" />
                  </Tooltip>
                </div>
              );
            },
          },
          baseColumns.slice(-1)[0], // Last column
        ]
      : [
          ...baseColumns.slice(0, -1),
          {
            id: 'Request Body',
            header: () => (
              <div className="text-center w-28 text-sm font-normal font-inter">
                Request Body
              </div>
            ),
            accessorKey: 'request_body',
            cell: (props: any) => {
              let formattedOutput = 'N/A';

              try {
                const jsonData = JSON.parse(JSON.stringify(props?.getValue()));

                formattedOutput = Object.entries(jsonData)
                  .map(
                    ([key, value]) =>
                      `${key.charAt(0).toUpperCase() + key.slice(1)}: ${value !== undefined ? value : 'N/A'}`
                  )
                  .join('\n');
              } catch (error) {
                console.error('Error parsing JSON:', error.message);
              }
              return (
                <div
                  className="w-28  flex justify-center items-center"
                  onClick={() => {
                    setSelectedLog(props.row.original);
                    setOpenModal(true);
                  }}
                >
                  {/* <Tooltip content={formattedOutput} position="left"> */}
                  <Icon name="Eye" />
                  {/* </Tooltip> */}
                </div>
              );
            },
          },
          baseColumns.slice(-1)[0],
        ];

  return (
    <div className="p-3 py-6 bg-[#D2EEFF] dark:bg-[#0C111D]">
      <div>
        <div className="flex justify-between items-center py-6 px-4 bg-white  dark:bg-gray-800">
          <div className="flex items-center gap-4   dark:bg-gray-800 sticky top-0 z-50">
            <SearchBar
              value={searchText}
              onChange={setSearchText}
              onSearch={handleSearch}
              placeholder="Search clients by name or domain "
            />
            <div>
              <ReactSelect
                value={method}
                options={SettingsOptions}
                placeholder={method.label}
                handleOnChange={(value: any) => setMethod(value)}
              />
            </div>
            <div>
              <ReactSelect
                options={ReportOptions}
                placeholder={report.label}
                handleOnChange={(selectedOption: any) =>
                  setReport(selectedOption)
                }
              />
            </div>
          </div>
        </div>
        <TanStackTable
          data={logs?.data || []}
          columns={mergedColumns}
          page={page}
          setPage={setPage}
          loading={logs?.loading}
          totalPages={logs?.total_pages}
          setSelectedRow={setSelectedRow}
          totalDocs={logs?.total_docs}
          pageWHeader={true}
          stickyColumn="User and Email"
        />
      </div>
      <CustomModal open={openModal} onClose={() => setOpenModal(false)}>
        <LogsModal
          onClose={() => setOpenModal(false)}
          selectedLog={selectedLog}
        />
      </CustomModal>
    </div>
  );
};

export default LogsSettings;
