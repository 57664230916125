import { useEffect } from 'react';

interface ModalProps {
  user?: any;
  type?: string;
  onClose?: () => void;
  action?: () => void;
  setRowState?: any;
  onRemoveUser?: any;
}
const BulkActionModal: React.FC<ModalProps> = ({
  user,
  type,
  onClose,
  action,
  onRemoveUser,
  setRowState,
}) => {
  const getUserInitials = (name) => {
    const nameParts = name?.split(' ');
    let initials = '';

    if (nameParts.length > 0) {
      initials += nameParts[0][0];

      if (nameParts.length > 1) {
        if (nameParts[1].includes('(')) {
          initials += nameParts[1][1];
        } else if (nameParts[1].includes('undefined')) {
          initials = nameParts[0][0];
        } else {
          initials += nameParts[1][0];
        }
      }
    }

    return initials;
  };

  useEffect(() => {
    if (user.length === 0) {
      onClose();
      setRowState(false);
    }
  }, [user]);

  return (
    <div className="relative  rounded-lg shadow flex justify-center  items-center h-screen font-inter">
      <div className="w-full sm:w-[450px]" onClick={(e) => e.stopPropagation()}>
        {/* Modal header */}
        <div className="flex items-center justify-between bg-white dark:bg-gray-700 p-4 md:p-5 border-b rounded-t dark:border-gray-600 capitalize">
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
            {type} Users
          </h3>
          <button
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            onClick={onClose}
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        {/* Modal body */}
        <div className="p-4 md:p-5 bg-white dark:bg-gray-700">
          <p className="text-sm font-normal text-gray-500 dark:text-gray-400 min-w-[450px]">
            List of users that you want to {type}.
          </p>
          <ul className="my-4 space-y-3 overflow-auto max-h-[400px] overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8">
            {user.map((user: any, index: number) => (
              <li key={index}>
                {user.fullName ? (
                  <div className="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                    <div className="w-8 h-8 rounded-full overflow-hidden">
                      {user.image ? (
                        <img
                          src={user.image}
                          alt={user.fullName}
                          className="object-cover"
                        />
                      ) : (
                        <div className="bg-[#345578] text-white flex items-center justify-center w-full h-full text-xs uppercase ">
                          {getUserInitials(
                            `${user?.fullName?.first_name} ${user?.fullName?.last_name}`
                          )}
                        </div>
                      )}
                    </div>
                    <span className="flex-1 ms-3 whitespace-nowrap max-w-[280px] truncate overflow-hidden">
                      {`${user?.fullName?.first_name} ${user?.fullName?.last_name}`}
                    </span>
                    <span
                      className="inline-flex items-center justify-center px-2 py-0.5 ms-3 text-sm font-medium text-[#B42318] bg-gray-200 rounded dark:bg-gray-700 dark:text-gray-400 cursor-pointer"
                      onClick={() =>
                        user && onRemoveUser && onRemoveUser(user.id)
                      }
                    >
                      Remove
                    </span>
                  </div>
                ) : (
                  <div className="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                    <div className="w-8 h-8 rounded-full overflow-hidden">
                      {user.image ? (
                        <img
                          src={user.image}
                          alt={user.customerDetails}
                          className="object-cover"
                        />
                      ) : (
                        <div className="bg-[#345578] text-white flex items-center justify-center w-full h-full text-xs uppercase">
                          {getUserInitials(user.customerDetails)}
                        </div>
                      )}
                    </div>
                    <span className="flex-1 ms-3 whitespace-nowrap max-w-[280px] truncate overflow-hidden">
                      {user.customerDetails}
                    </span>
                    <span
                      className="inline-flex items-center justify-center px-2 py-0.5 ms-3 text-sm font-medium text-[#B42318] bg-gray-200 rounded dark:bg-gray-700 dark:text-gray-400 cursor-pointer"
                      onClick={() =>
                        user && onRemoveUser && onRemoveUser(user.id)
                      }
                    >
                      Remove
                    </span>
                  </div>
                )}
              </li>
            ))}
          </ul>
          <div className="w-full px-2 flex  justify-center items-center gap-2">
            <button
              onClick={onClose}
              className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
            >
              Cancel
            </button>
            <button
              onClick={action}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 capitalize"
            >
              {type}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkActionModal;
