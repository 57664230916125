import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const Health: React.FC<IconProps> = ({ color = 'black', size = 16 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.66674 1.33301L2.72904 8.45824C2.4965 8.73729 2.38023 8.87681 2.37846 8.99465C2.37691 9.09709 2.42256 9.19454 2.50224 9.25894C2.5939 9.33301 2.77552 9.33301 3.13876 9.33301H8.00007L7.3334 14.6663L13.2711 7.54111C13.5036 7.26206 13.6199 7.12254 13.6217 7.0047C13.6232 6.90226 13.5776 6.8048 13.4979 6.74041C13.4062 6.66634 13.2246 6.66634 12.8614 6.66634H8.00007L8.66674 1.33301Z"
      stroke={color || '#334969'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Health;
