import { useLocation } from 'react-router-dom';

interface IProps {
  handleScroll: (id: string) => void;
  activeTab: {
    index: string;
    id: string;
    label: string;
  };
  setActiveTab: (selectedOption: any) => void;
}

interface TabsProps {
  index: string;
  id: string;
  label: string;
}

const Tabs = ({ handleScroll, activeTab, setActiveTab }: IProps) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const activepublicTab = searchParams.get('ppage');

  const allTabs: { [key: string]: TabsProps[] } = {
    'seo-ga4': [
      { index: '1', id: 'key-performance', label: 'Key Performance Indicator' },
      { index: '2', id: 'leading-indicators', label: 'Leading Indicators' },
      { index: '3', id: 'traffic', label: 'Traffic Insights' },
      { index: '4', id: 'conversions', label: 'Conversions' },
      { index: '5', id: 'keyword-analysis', label: 'Keyword Analysis' },
      {
        index: '6',
        id: 'search-console-analysis',
        label: 'Search Console Analysis',
      },
      { index: '7', id: 'backlinks', label: 'Backlinks' },
      { index: '8', id: 'competitors', label: 'Competitors Analysis' },
    ],
    'paid-search': [
      { index: '1', id: 'overall', label: 'Overall' },
      {
        index: '2',
        id: 'campaign',
        label: 'Campaign Overview',
      },
      { index: '3', id: 'adGroup', label: 'Ad Group Overview' },
      { index: '4', id: 'keyword', label: 'Keyword Overview' },
      { index: '5', id: 'topPerformingAd', label: 'Top Performing Ads' },
      { index: '6', id: 'qualityScore', label: 'Quality Score Overview' },
      { index: '7', id: 'impressionShare', label: 'Impression Share Overview' },
      { index: '8', id: 'devices', label: 'Devices Overview' },
    ],
    'microsoft-ads': [
      { index: '1', id: 'microsoftOverview', label: 'Microsoft Ads Overview' },
      { index: '2', id: 'microsoftCampaign', label: 'Campaign Overview' },
      { index: '3', id: 'microsoftAdGroup', label: 'Ad Group Overview' },
      { index: '4', id: 'microsoftKeyword', label: 'Keyword Overview' },
      {
        index: '5',
        id: 'microsoftTopPerformingAd',
        label: 'Top Performing Ads',
      },
      {
        index: '6',
        id: 'microsoftQualityScore',
        label: 'Quality Score Overview',
      },
      // {
      //   index: '7',
      //   id: 'microsoftImpressionShare',
      //   label: 'Impression Share Overview',
      // },
      { index: '8', id: 'microsoftDevices', label: 'Devices Overview' },
    ],
    'fb-ads': [
      { index: '1', id: 'general', label: 'General Overview' },
      { index: '2', id: 'acquisition', label: 'Acquisition Overview' },
      { index: '3', id: 'acquisitionDetails', label: 'Acquisition Details' },
      {
        index: '5',
        id: 'performanceBreakdown',
        label: 'Performance Breakdown',
      },
      { index: '6', id: 'creativeAnalysis', label: 'Creative Analysis' },
      { index: '7', id: 'audienceAnalysis', label: 'Audience Analysis' },
    ],
  };

  const tabKey = Object.keys(allTabs).find(
    (key) => location.pathname.includes(key) || activepublicTab === key
  );

  const currentTabs = tabKey ? allTabs[tabKey] : [];

  return (
    <>
      {tabKey && (
        <div className="sticky sm:top-[190px] lg:top-[152px] bg-[#D2EEFF] z-[11] px-[2.5rem] dark:bg-[#0C111D]">
          <div className="hidden sm:block text-xs font-medium text-center text-gray-500 border-b border-[#fff] dark:text-gray-400 dark:border-gray-700">
            <ul className="flex flex-wrap -mb-px">
              {currentTabs.map((tab, index) => (
                <li className="me-2" key={index}>
                  <a
                    href={`#${tab.id}`}
                    onClick={() => {
                      handleScroll(tab.id);
                      setActiveTab(tab);
                    }}
                    className={
                      activeTab.label === tab.label
                        ? 'inline-block p-2 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500'
                        : 'inline-block p-2 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                    }
                  >
                    {tab.label}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default Tabs;
