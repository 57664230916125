import React from 'react';

interface IProps {
  color?: string;
  size?: number;
}

const ChangeReports: React.FC<IProps> = ({ color = '#405573', size = 18 }) => (
  <svg
    width={size + 3}
    height={size}
    viewBox="0 0 21 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.5 5C4.5 5.90625 4.96875 6.71875 5.75 7.1875C6.5 7.625 7.46875 7.625 8.25 7.1875C9 6.71875 9.5 5.90625 9.5 5C9.5 4.125 9 3.3125 8.25 2.84375C7.46875 2.40625 6.5 2.40625 5.75 2.84375C4.96875 3.3125 4.5 4.125 4.5 5ZM11 5C11 6.4375 10.2188 7.75 9 8.46875C7.75 9.1875 6.21875 9.1875 5 8.46875C3.75 7.75 3 6.4375 3 5C3 3.59375 3.75 2.28125 5 1.5625C6.21875 0.84375 7.75 0.84375 9 1.5625C10.2188 2.28125 11 3.59375 11 5ZM1.53125 15.5H10.1875L10.0312 16.1562C9.96875 16.4375 9.96875 16.75 10.0625 17H0.90625C0.40625 17 0 16.5938 0 16.0938C0 13 2.46875 10.5 5.5625 10.5H8.40625C9.90625 10.5 11.2812 11.125 12.2812 12.0938L11.2188 13.125C10.5 12.4375 9.5 12 8.40625 12H5.5625C3.5 12 1.8125 13.5312 1.53125 15.5ZM19.1562 8.375L19.625 8.84375C20.0938 9.3125 20.0938 10.125 19.625 10.5938L18.6875 11.5312L16.4688 9.3125L17.4062 8.375C17.875 7.90625 18.6875 7.90625 19.1562 8.375ZM11.7188 14.0312L15.7812 10L18 12.2188L13.9375 16.25C13.8125 16.4062 13.6562 16.4688 13.5 16.5312L11.5938 17C11.4375 17.0312 11.25 17 11.125 16.875C11 16.75 10.9688 16.5625 11 16.375L11.4688 14.5C11.5 14.3438 11.5938 14.1875 11.7188 14.0312Z"
      fill={color}
    />
  </svg>
);

export default ChangeReports;