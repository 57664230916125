import React from 'react';

interface GroupProps {
  color?: string;
}

const Group: React.FC<GroupProps> = ({ color = '#428B6E' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29"
    height="20"
    viewBox="0 0 29 20"
    fill="none"
  >
    <path
      d="M3.35799 12.764C3.35799 13.833 3.59234 14.6532 3.89881 15.1508C4.30082 15.8016 4.90112 16.0774 5.51225 16.0774C6.30004 16.0774 7.02294 15.8809 8.41284 13.9592C9.52693 12.4179 10.8393 10.2546 11.7227 8.89892L13.2189 6.60044C14.2573 5.00503 15.4597 3.23114 16.8388 2.02872C17.9637 1.04804 19.1787 0.501808 20.401 0.501808C22.4525 0.501808 24.4067 1.68981 25.9011 3.91978C27.538 6.36248 28.333 9.43794 28.333 12.6107C28.333 14.4982 27.9616 15.8845 27.3289 16.9788C26.7178 18.0388 25.5262 19.097 23.5215 19.097V16.0774C25.2377 16.0774 25.6668 14.5 25.6668 12.6955C25.6668 10.123 25.0665 7.26926 23.7451 5.22857C22.8076 3.78097 21.5926 2.89764 20.255 2.89764C18.8092 2.89764 17.6464 3.98829 16.3376 5.93163C15.6418 6.9646 14.9279 8.2229 14.1275 9.64345L13.246 11.2064C11.4739 14.3486 11.025 15.0643 10.1399 16.245C8.58771 18.3128 7.2609 19.097 5.51586 19.097C3.44452 19.097 2.13574 18.201 1.32451 16.849C0.657499 15.7475 0.333008 14.3017 0.333008 12.6558L3.35799 12.764Z"
      fill="white"
    />
    <path
      d="M2.71777 4.1325C4.10407 1.99627 6.1051 0.5 8.39998 0.5C9.72859 0.5 11.05 0.892997 12.4291 2.0197C13.938 3.25097 15.546 5.27904 17.5524 8.6213L18.2717 9.82011C20.0096 12.7135 20.9975 14.2026 21.5761 14.9038C22.3207 15.8052 22.8416 16.0738 23.5177 16.0738C25.2339 16.0738 25.6629 14.4964 25.6629 12.6919L28.331 12.6089C28.331 14.4964 27.9596 15.8827 27.3268 16.977C26.7157 18.037 25.5241 19.0952 23.5195 19.0952C22.2738 19.0952 21.1687 18.8247 19.9483 17.6728C19.009 16.7895 17.913 15.2175 17.0675 13.806L14.5563 9.6128C13.2962 7.50721 12.1406 5.93884 11.4718 5.22676C10.7525 4.4624 9.82774 3.5394 8.3513 3.5394C7.15609 3.5394 6.14296 4.37767 5.29387 5.65941L2.71777 4.1325Z"
      fill="white"
    />
    <path
      d="M8.35155 3.5412C7.15634 3.5412 6.14321 4.37948 5.29412 5.66122C4.0935 7.47296 3.35799 10.1716 3.35799 12.764C3.35799 13.833 3.59234 14.6532 3.89881 15.1508L1.3209 16.849C0.657499 15.7475 0.333008 14.3017 0.333008 12.6558C0.333008 9.66147 1.15505 6.54095 2.71802 4.1325C4.10432 1.99627 6.10535 0.5 8.40022 0.5L8.35155 3.5412Z"
      fill="white"
    />
  </svg>
);

export default Group;
