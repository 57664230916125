// SearchBar.tsx

import React, { ChangeEvent } from 'react';
import Icon from '../../assets/icons/SvgComponent';
import { useThemeStore } from '../../store/useThemeStore';

interface SearchBarProps {
  value?: string;
  onChange?: (query: string) => void;
  onSearch?: () => void;
  placeholder?: string;
  width?: string;
}

const SearchBar: React.FC<SearchBarProps> = ({
  value,
  onChange,
  onSearch,
  placeholder = 'Search for anything',
  width,
}) => {
  const { mode } = useThemeStore((state: any) => state);
  const iconColor = mode === 'dark' ? 'white' : '#334969';
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
    if (event.target.value === '') {
      onSearch();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearch();
    } else if (e.key === 'Escape') {
      onClear();
    }
  };

  const onClear = () => {
    onChange('');
  };

  return (
    <div className="relative">
      {/* <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none z-[9]">
        <Icon
          name="SearchRefractionIcon"
          color={mode === 'dark' ? '#fff' : iconColor}
        />
      </div> */}
      <input
        type="text"
        value={value}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        className={`block p-2 px-3 text-sm text-gray-900 border border-gray-300 rounded-lg ${width} bg-white focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
        placeholder={placeholder}
      />
      {value === '' ? (
        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none z-[9]">
          {/* <Icon
            name="KeywordsRanking"
            color={mode === 'dark' ? '#fff' : iconColor}
          /> */}
          <Icon
            name="SearchRefractionIcon"
            color={mode === 'dark' ? '#fff' : iconColor}
          />
        </div>
      ) : (
        <div
          className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer z-[9]"
          onClick={onClear}
        >
          <Icon name="Xclose" color={mode === 'dark' ? '#fff' : iconColor} />
        </div>
      )}
    </div>
  );
};

export default SearchBar;
