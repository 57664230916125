import React from 'react';

interface Props {
  color?: string;
  size?: number;
}

const Date: React.FC<Props> = ({ color = 'black', size = 80 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
  >
    <path
      d="M13 6.66634H1M9.66667 1.33301V3.99967M4.33333 1.33301V3.99967M4.2 14.6663H9.8C10.9201 14.6663 11.4802 14.6663 11.908 14.4484C12.2843 14.2566 12.5903 13.9506 12.782 13.5743C13 13.1465 13 12.5864 13 11.4663V5.86634C13 4.74624 13 4.18618 12.782 3.75836C12.5903 3.38204 12.2843 3.07607 11.908 2.88433C11.4802 2.66634 10.9201 2.66634 9.8 2.66634H4.2C3.0799 2.66634 2.51984 2.66634 2.09202 2.88433C1.71569 3.07607 1.40973 3.38204 1.21799 3.75836C1 4.18618 1 4.74624 1 5.86634V11.4663C1 12.5864 1 13.1465 1.21799 13.5743C1.40973 13.9506 1.71569 14.2566 2.09202 14.4484C2.51984 14.6663 3.0799 14.6663 4.2 14.6663Z"
      stroke="white"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Date;
