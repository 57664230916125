import { requests } from './ApiService';
import { toQueryString } from '../utils/common';

export const Analytics = {
  getAuthorizedAccounts: () => requests.get(`/api/v1/google/accounts`),
  getAnalyticAccounts: (account_email: string, refresh_cache: boolean) =>
    requests.get(
      `/api/v1/google/analytics/${account_email}/accounts?refreshCache=${refresh_cache}`
    ),
  getGA4Accounts: (account_email, account_id) =>
    requests.get(
      `/api/v1/google/analytics/${account_email}/ga4-properties/${account_id}`
    ),
  getGSearchSiteUrls: (data) =>
    requests.get(`/api/v1/google/search-console/sites/${data?.account_email}`),
  getGA4AnalyticsData: (data) =>
    requests.get(
      `/api/v1/google/analytics/${data.account_email}/ga4-report${toQueryString(
        {
          propertyId: data.propertyId,
          startDate: data.startDate,
          endDate: data.endDate,
          reportType: data.reportType,
          refreshCache: data.resetCache,
        }
      )}`
    ),
  getGSearchData: (data) =>
    requests.get(
      `/api/v1/google/search-console/${data.account_email}${toQueryString({
        siteUrl: data.siteUrl,
        startDate: data.startDate,
        endDate: data.endDate,
        refreshCache: data.resetCache,
      })}`
    ),
  updateAnalyticsAccount: (data, id) =>
    requests.patch(`/api/v1/domain/${id}`, data),
  getAdsClients: () => requests.get(`/api/v1/google/campaigns/clients`),
  getCampaignsReport: (filters) =>
    requests.get(
      `/api/v1/google/campaigns/stats/${toQueryString({
        customerId: filters.customerId,
        startDate: filters.startDate,
        endDate: filters.endDate,
        limit: 5000,
        refreshCache: filters.resetCache,
      })}`
    ),

  getTeamworkProjects: () => requests.get(`/api/v1/teamwork/projects`),
  getTopPerformance: (data) =>
    requests.get(
      `/api/v1/google/top-performance/${data.account_email}/${data.clientId}/${toQueryString(
        {
          reportType: data.reportType,
          propertyId: data.propertyId,
          startDate: data.startDate,
          endDate: data.endDate,
          refreshCache: data.resetCache,
        }
      )}`
    ),
  getLandingPages: (data) =>
    requests.get(
      `/api/v1/google/analytics/${data.account_email}/ga4-report/landingPage${toQueryString(
        {
          reportType: data.reportType,
          propertyId: data.propertyId,
          startDate: data.startDate,
          endDate: data.endDate,
          refreshCache: data.resetCache,
        }
      )}`
    ),
};
