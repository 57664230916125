import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const ReferralsIcon: React.FC<IconProps> = ({ color = 'black', size = 80 }) => (
  <svg
    width={size}
    height={size + 1}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 14.3478L5.57465 20.6464C5.61893 20.8235 5.64107 20.9121 5.66727 20.9894C5.92317 21.7449 6.60352 22.2761 7.39852 22.3411C7.4799 22.3478 7.5712 22.3478 7.75379 22.3478C7.98244 22.3478 8.09677 22.3478 8.19308 22.3384C9.145 22.2461 9.89834 21.4928 9.99066 20.5409C10 20.4445 10 20.3302 10 20.1016V5.84778M18.5 13.8478C20.433 13.8478 22 12.2808 22 10.3478C22 8.41478 20.433 6.84778 18.5 6.84778M10.25 5.84778H6.5C4.01472 5.84778 2 7.8625 2 10.3478C2 12.8331 4.01472 14.8478 6.5 14.8478H10.25C12.0164 14.8478 14.1772 15.7947 15.8443 16.7034C16.8168 17.2336 17.3031 17.4987 17.6216 17.4597C17.9169 17.4235 18.1402 17.2909 18.3133 17.0489C18.5 16.7879 18.5 16.2658 18.5 15.2215V5.47407C18.5 4.42978 18.5 3.90763 18.3133 3.64667C18.1402 3.40468 17.9169 3.27208 17.6216 3.23591C17.3031 3.1969 16.8168 3.46198 15.8443 3.99214C14.1772 4.90089 12.0164 5.84778 10.25 5.84778Z"
      stroke={color || '#334969'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ReferralsIcon;
