import React from 'react';
import { Grid, Col } from '@tremor/react';
import DonutBarGroup from '../../DonutCardChart';

interface IProps {
  campaignsReport?: any;
  clicks?: any;
  conversions?: any;
  costs?: any;
  impressions?: any;
}

const MobileView = ({
  campaignsReport,
  clicks,
  conversions,
  costs,
  impressions,
}: IProps) => {
  return (
    <div className="w-full flex flex-col gap-8">
      <Grid numItemsMd={1} numItemsLg={2} className="gap-4 mt-4">
        <Col>
          <DonutBarGroup
            data={clicks}
            trendTxt={false}
            title={`Clicks`}
            variant="pie"
            category="value"
            index="name"
            showLegend={true}
            customClass="h-[312px]"
            categories={['DESKTOP', 'MOBILE', 'TABLET', 'OTHER']}
            legendCustomClass="max-w-[100px]"
            info={`Clicks per device`}
            loading={campaignsReport?.loading}
            icon="googleAd"
          />
        </Col>
        <Col>
          <DonutBarGroup
            data={conversions}
            trendTxt={false}
            title={`Conversions`}
            variant="pie"
            category="value"
            index="name"
            showLegend={true}
            customClass="h-[312px]"
            categories={['DESKTOP', 'MOBILE', 'TABLET', 'OTHER']}
            legendCustomClass="max-w-[100px]"
            info={`Conversions per device`}
            loading={campaignsReport?.loading}
            icon="googleAd"
          />
        </Col>
        <Col>
          <DonutBarGroup
            data={costs}
            trendTxt={false}
            title={`Cost`}
            variant="pie"
            category="value"
            index="name"
            showLegend={true}
            customClass="h-[312px]"
            categories={['DESKTOP', 'MOBILE', 'TABLET', 'OTHER']}
            legendCustomClass="max-w-[100px]"
            info={`Cost per device`}
            loading={campaignsReport?.loading}
            isDollar={true}
            icon="googleAd"
          />
        </Col>
        <Col>
          <DonutBarGroup
            data={impressions}
            trendTxt={false}
            title={`Impressions`}
            variant="pie"
            category="value"
            index="name"
            showLegend={true}
            customClass="h-[312px]"
            categories={['DESKTOP', 'MOBILE', 'TABLET', 'OTHER']}
            legendCustomClass="max-w-[100px]"
            info={`Impressions per device`}
            loading={campaignsReport?.loading}
            icon="googleAd"
          />
        </Col>
      </Grid>
    </div>
  );
};

export default MobileView;
