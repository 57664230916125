import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const ChevronsLeftIcon: React.FC<IconProps> = ({
  color = 'black',
  size = 80,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color || '#808EA2'}
    strokeWidth={1}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-chevrons-left"
  >
    <polyline points="11 17 6 12 11 7" />
    <polyline points="18 17 13 12 18 7" />
  </svg>
);

export default ChevronsLeftIcon;
