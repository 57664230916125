import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import API from '../../api';
import { ArrowLeft, Key, Mail } from '../../assets/icons';
import { FadeLoader } from 'react-spinners';
import { useThemeStore } from '../../store/useThemeStore';

type EmailProps = {
  email?: string;
};
const CheckYourEmail: React.FC<EmailProps> = ({ email }) => {
  const { mode } = useThemeStore((state) => state);
  return (
    <div className="flex items-start justify-center w-full h-screen font-inter">
      <div className=" flex flex-col items-center justify-center gap-y-8 max-w-[390px] mt-[96px]">
        <div className="flex flex-col items-center gap-y-6">
          <div className=" p-3.5 rounded-lg border border-[#E6EBF0] bg-white shadow-xs">
            <Mail />
          </div>
          <div className="flex flex-col gap-y-3">
            <span className="text-[#001129] text-center font-inter text-2xl not-italic font-semibold dark:text-white">
              Check your email
            </span>
            <span className="px-[14px] text-[#001C44] text-center font-inter text-base font-light leading-6 dark:text-blue-300">
              We sent a password reset link to {email}
            </span>
          </div>
        </div>
        <a
          href="mailto:"
          className="btn w-full text-center py-2.5 px-4 bg-[#005C9F] border border-[#005C9F] shadow-xs rounded-lg "
        >
          <span className="text-white font-inter"> Open email app</span>
        </a>
        <div className="flex flex-row items-center space-x-2 justify-center w-[96%] mt-5 z-10 pt-[32] font-inter">
          <span className="text-[#001C44] dark:text-blue-300">
            Didn’t receive the email?
          </span>
          <Link
            to="/"
            className="text-[#005C9F] cursor-pointer font-inter text-base leading-5 dark:text-white"
          >
            Click to resend
          </Link>
        </div>
        <div className="flex flex-row justify-center cursor-pointer font-inter gap-x-2 ">
          <ArrowLeft color={mode === 'dark' ? '#fff' : '#000'} />
          <Link
            to="/"
            className="text-[#001c44] font-inter text-base leading-5 dark:text-white"
          >
            Back to login
          </Link>
        </div>
      </div>
    </div>
  );
};

const ForgotPassword: React.FC = () => {
  const { mode } = useThemeStore((state) => state);
  const [email, setEmail] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [resetLinkClicked, setResetLinkClicked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const passwordResetClicked = async () => {
    if (!email) {
      setErrorMessage('Email is required');
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
      return;
    }

    setIsLoading(true);
    const response = await API.Auth.forgotPassword({ email });
    if (response[0]?.error) {
      setErrorMessage(response[0]?.error);
      setIsLoading(false);
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    } else {
      setIsLoading(false);
      setResetLinkClicked(true);
      setErrorMessage('');
    }
  };

  if (resetLinkClicked) {
    return <CheckYourEmail email={email} />;
  } else {
    return (
      <div className="flex items-start justify-center w-full h-screen ">
        <div className=" flex flex-col items-center justify-center gap-y-8 max-w-[390px] mt-[96px]">
          <div className="flex flex-col items-center gap-y-6">
            <div className=" p-3.5 rounded-lg border border-[#E6EBF0] bg-white shadow-xs">
              <Key />
            </div>
            <div className="flex flex-col items-center gap-y-3">
              <span className="text-[#001129] text-center font-inter text-2xl not-italic font-semibold dark:text-white">
                Forgot password?
              </span>
              <span className="px-[14px] text-[#001C44] text-center font-inter text-base font-light leading-6 dark:text-blue-300">
                No worries, we’ll send you reset instructions.
              </span>
            </div>
          </div>

          <div className="flex flex-col w-full">
            <label className="font-inter text-[#001C44] dark:text-blue-300">
              Email
            </label>
            <input
              value={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
              placeholder="Enter your email"
              type="email"
              name="email"
              className="w-full rounded-lg py-2.5 px-3.5 text-[#001129] border border-[#808EA2] mt-1.5 dark:bg-[#191919] dark:text-white"
            />
            {errorMessage && (
              <span className="mt-4 font-inter text-base leading-relaxed font-normal text-center  text-[#B93815] bg-[#FEF6EE] p-2 w-full">
                {errorMessage}
              </span>
            )}

            <div className="relative">
              <button
                className="btn mt-6 py-2.5 px-4 bg-[#005C9F] border border-[#005C9F] shadow-xs rounded-lg w-full"
                onClick={passwordResetClicked}
              >
                <span className="text-white font-inter"> Reset password</span>
              </button>
              {isLoading ? (
                <div className="absolute top-2 right-24  w-full h-full flex items-center justify-center">
                  <FadeLoader
                    color="#fff"
                    style={{ transform: 'scale(0.6)' }}
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div className="flex flex-row justify-center font-inter gap-x-2 ">
            <ArrowLeft color={mode === 'dark' ? '#fff' : '#000'} />
            <Link
              to="/"
              className="text-[#001c44]cursor-pointer font-inter text-base leading-5 dark:text-white"
            >
              Back to login
            </Link>
          </div>
        </div>
      </div>
    );
  }
};

export default ForgotPassword;
