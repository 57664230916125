import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const OutlineBingAds: React.FC<IconProps> = ({
  color = 'black',
  size = 80,
}) => (
  <svg
    width="51"
    height="51"
    viewBox="0 0 51 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g filter="url(#filter0_d_160_25759)">
      <path
        d="M42.1709 22.5722H26.4423C26.1856 22.5722 25.9802 22.3634 25.9802 22.1101V6.38495C25.9802 6.12823 26.189 5.92285 26.4423 5.92285H42.1709C42.4277 5.92285 42.633 6.13165 42.633 6.38495V22.1136C42.633 22.3703 42.4242 22.5757 42.1709 22.5757V22.5722ZM26.9079 21.648H41.7088V6.84706H26.9079V21.648Z"
        fill="#001C44"
      />
      <path
        d="M42.1709 41.0766H26.4423C26.1856 41.0766 25.9802 40.8678 25.9802 40.6145V24.8859C25.9802 24.6292 26.189 24.4238 26.4423 24.4238H42.1709C42.4277 24.4238 42.633 24.6292 42.633 24.8859V40.6145C42.633 40.8713 42.4242 41.0766 42.1709 41.0766ZM26.9079 40.1524H41.7088V25.3515H26.9079V40.1524Z"
        fill="#001C44"
      />
      <path
        d="M23.6663 22.5722H7.94111C7.68438 22.5722 7.479 22.3634 7.479 22.1101V6.38495C7.479 6.12823 7.68781 5.92285 7.94111 5.92285H23.6697C23.9264 5.92285 24.1318 6.13165 24.1318 6.38495V22.1136C24.1318 22.3703 23.923 22.5757 23.6697 22.5757L23.6663 22.5722ZM8.40321 21.648H23.2042V6.84706H8.40321V21.648Z"
        fill="#001C44"
      />
      <path
        d="M23.6663 41.0766H7.94111C7.68438 41.0766 7.479 40.8678 7.479 40.6145V24.8859C7.479 24.6292 7.68781 24.4238 7.94111 24.4238H23.6697C23.9264 24.4238 24.1318 24.6292 24.1318 24.8859V40.6145C24.1318 40.8713 23.923 41.0766 23.6697 41.0766H23.6663ZM8.40321 40.1524H23.2042V25.3515H8.40321V40.1524Z"
        fill="#001C44"
      />
    </g>
  </svg>
);

export default OutlineBingAds;
