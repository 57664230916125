import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const LightCheck: React.FC<IconProps> = ({ color = 'black', size = 16 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    className="cursor-pointer"
  >
    <path
      d="M17.1667 5L8.00004 14.1667L3.83337 10"
      stroke="#7C7E7F"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LightCheck;
