import { requests } from './ApiService';

export const Xero = {
  getContacts: (filters) =>
    requests.get(
      `/api/v1/xero/contacts${toQueryString({
        searchTerm: filters.searchTerm,
        refreshCache: true,
      })}`
    ),
  getInvoices: (filters) =>
    requests.get(
      `/api/v1/xero/invoices/${filters.contactId}${toQueryString({
        page: filters.page,
        pageSize: filters.pageSize,
        status: filters.status,
      })}`
    ),
  getInvoicesLink: (contactId) =>
    requests.get(`/api/v1/xero/invoice/${contactId}/online-url`),
};

// performance unique to format users and clients
const toQueryString = (params: { [key: string]: any }) =>
  Object.keys(params)
    .filter(
      (key) =>
        params[key] !== undefined &&
        params[key] !== null &&
        params[key] !== 'all'
    )
    .map((key, index) => {
      if (key === 'status') {
        if (Array.isArray(params[key])) {
          return params[key]
            .map(
              (value) =>
                `${index === 0 ? '?' : '&'}${key}[]=${encodeURIComponent(value)}`
            )
            .join('&');
        }
        return `${index === 0 ? '?' : '&'}${key}[]=${encodeURIComponent(params[key])}`;
      }
      return `${index === 0 ? '?' : '&'}${key}=${encodeURIComponent(params[key])}`;
    })
    .join('');
