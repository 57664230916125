import moment from 'moment';
import Icon from '../../assets/icons/SvgComponent';
import ImageIcon from '../../components/ImageIcon';
import Tooltip from '../../components/HelpTooltip';
import ToolTipV2 from '../../components/TooltipV2';
import MetricDisplay from '../../components/Performance/MetricDisplay';
import { isStaging } from '../../utils';
import { getDeltaTypeAndColor } from '../../utils/performance';
import { formatNumber } from '../../utils/common';

// Define the props for the generateColumns function
interface GenerateColumnsProps {
  sortingColumn: any;
  sortingDirection: any;
  performanceVisibilityOption: any;
  editClientPerformance: (data) => void;
  handleRegeneratePerformance: (ids, type) => void;
  regenerating: boolean;
  generatedClientId: string;
  integration?: any;
}

// Function to generate columns
export const generatePpcColumns = ({
  sortingColumn,
  sortingDirection,
  editClientPerformance,
  handleRegeneratePerformance,
  regenerating,
  generatedClientId,
  integration,
}: GenerateColumnsProps) => [
  // name column
  {
    id: 'name',
    header: () => (
      <div className="w-[180px] sm:w-[15vw] border-r dark:border-gray-700 h-[40px] flex items-center">
        <div className="flex w-[180px] sm:w-[15vw] justify-start items-center gap-2 cursor-pointer">
          <div className="text-left text-sm font-inter w-fit">Client</div>
          <div>
            {sortingColumn === 'name' ? (
              sortingDirection === 'asc' ? (
                <Icon name="Arrowup" />
              ) : sortingDirection === 'desc' ? (
                <Icon name="ArrowDown" />
              ) : (
                <Icon name="Sort" />
              )
            ) : (
              <Icon name="Sort" />
            )}
          </div>
        </div>
      </div>
    ),
    accessorKey: 'client',
    cell: (props: any) => {
      return (
        <div
          className="w-[180px] sm:w-[15vw] flex justify-start items-center cursor-pointer border-r dark:border-gray-700 h-[70px]"
          onClick={() => {
            if (props?.getValue() !== undefined) {
              editClientPerformance(props?.row?.original);
            }
          }}
        >
          <div>
            <Tooltip content={props.getValue()?.name} position="right">
              <div className="truncate overflow-hidden text-sm max-w-[170px] sm:max-w-[15vw] font-inter font-[500] text-[#001C44] dark:text-[#FFF]">
                {props.getValue()?.name}
              </div>
            </Tooltip>
            <div
              className={`text-left text-xs font-inter font-light text-[#405573] dark:text-gray-300 w-fit`}
            >
              {props.getValue()?.category_type === null
                ? 'N/A'
                : props.getValue()?.category_type}
            </div>
            {props.getValue()?.googleAds?.message && (
              <div
                className={`text-left text-xs font-inter font-light text-[#FF0000] max-w-[170px] sm:max-w-[15vw] truncate`}
              >
                {props.getValue()?.googleAds?.message}
              </div>
            )}
            {generatedClientId === props?.row?.original?.client.client?.id &&
              regenerating && (
                <div className="text-left text-xs font-inter font-light text-[#405573] blink-1">
                  Generating...
                </div>
              )}
          </div>
        </div>
      );
    },
  },
  // revenue column
  {
    id: 'revenue',
    header: () => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex w-[160px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Retainer</div>
          {sortingColumn === 'revenue' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'revenue',
    cell: (props: any) => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex justify-center font-inter font-[400] text-sm text-[#001C44] dark:text-gray-300">
          {props.getValue()}
        </div>
      </div>
    ),
  },
  // account manager column
  {
    id: 'account_manager',
    header: () => (
      <div className="w-[100px] sm:w-[5vw] ">
        <div className="flex w-[100px] sm:w-[5vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">AM</div>
          {sortingColumn === 'account_manager' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'account_manager',
    cell: (props: any) => {
      const tooltip = `Name: ${props.getValue().name}\nHsName: ${props.getValue().hsName}`;

      return (
        <div className="flex items-center gap-x-1 justify-center w-[100px] sm:w-[5vw] text-sm font-[500] font-inter">
          <ToolTipV2
            tooltip={`${props.getValue().name === undefined ? '' : tooltip}`}
          >
            <div className="relative cursor-default">
              <ImageIcon
                data={props.getValue().name || ''}
                size={7}
                textSize={'xs'}
                customStyle="bg-[#F3F7FA] text-[#7C7E7F] font-inter font-[600] border border-gray-200 rounded-full"
              />
              {props.getValue().name !== 'N/A' ? (
                <div
                  className={`absolute bottom-0 right-0 w-2.5 h-2.5 rounded-lg border border-white
                      bg-${props.getValue().color}-500
                    `}
                />
              ) : null}
            </div>
          </ToolTipV2>
        </div>
      );
    },
  },
  // consultant column
  {
    id: 'consultant',
    header: () => (
      <div className="w-[100px] sm:w-[5vw] ">
        <div className="flex w-[100px] sm:w-[5vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Cons</div>
          {sortingColumn === 'consultant' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'consultant',
    cell: (props: any) => {
      const tooltip = `Name: ${props.getValue().name}\nHsName: ${props.getValue().hsName}`;

      return (
        <div className="flex items-center gap-x-1 justify-center w-[100px] sm:w-[5vw] text-sm font-[500] font-inter">
          <ToolTipV2
            tooltip={`${props.getValue().name === undefined ? '' : tooltip}`}
          >
            <div className="relative cursor-default">
              <ImageIcon
                data={props.getValue().name || ''}
                size={7}
                textSize={'xs'}
                customStyle="bg-[#F3F7FA] text-[#7C7E7F] font-inter font-[600] border border-gray-200 rounded-full"
              />
              {props.getValue().name !== 'N/A' ? (
                <div
                  className={`absolute bottom-0 right-0 w-2.5 h-2.5 rounded-lg border border-white
                    bg-${props.getValue().color}-500
                  `}
                />
              ) : null}
            </div>
          </ToolTipV2>
        </div>
      );
    },
  },
  // start date column
  {
    id: 'start_date',
    header: () => (
      <div className="w-[160px] sm:w-[8vw] ">
        <div className="flex w-[160px] sm:w-[8vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Start Date </div>
          {sortingColumn === 'start_date' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'startDate',
    cell: (props: any) => (
      <div className="w-[160px] sm:w-[8vw] ">
        <div className="text-sm flex justify-center font-inter font-[400] text-[#001C44] dark:text-gray-300">
          {moment(props?.getValue())?.format('MMM-YYYY')}
        </div>
      </div>
    ),
  },
  // performance column
  {
    id: 'performance',
    header: () => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex w-[160px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Performance</div>
          {sortingColumn === 'performance' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'performance',
    cell: (props: any) => {
      const displayPercentage = props.getValue().value;

      let arrowColor;

      const handleDeltaType = () => {
        if (Number(displayPercentage) >= 75) {
          arrowColor = '#3CCB7F';
          return 'ArrowUpLeft';
        }
        if (Number(displayPercentage) >= 50) {
          arrowColor = '#FF692E';
          return 'ArrowRight';
        } else {
          arrowColor = '#FF0000';
          return 'ArrowDownLeft';
        }
      };

      return (
        <div className="flex flex-col items-center justify-center font-inter font-[600] w-[160px] sm:w-[12vw] ">
          <div
            className={`cursor-pointer flex gap-1 items-center h-[19px] text-sm p-[6px] border-0 bg-gray-100 rounded`}
          >
            <Icon name={handleDeltaType()} size={14} color={arrowColor} />
            <div className={`text-[${arrowColor}]`}>
              {formatNumber(displayPercentage)}%
            </div>
          </div>
          <ToolTipV2 tooltip={props.getValue().tooltip}>
            <div className="text-xs mt-1 cursor-pointer font-light text-[#405573] dark:text-gray-300">
              {props.getValue().numberOfpositive} out of{' '}
              {props.getValue().outOf}
            </div>
          </ToolTipV2>
        </div>
      );
    },
  },
  // clicks column
  {
    id: 'googleAds.clicks',
    header: () => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex w-[160px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Clicks</div>
          {sortingColumn === 'googleAds.clicks' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'clicks',
    cell: (props: any) => {
      const category = ['Clicks'];
      const { deltaType, color } = getDeltaTypeAndColor(
        props.getValue()?.current,
        props.getValue()?.previous
      );

      return (
        <div className="w-[160px] sm:w-[12vw]">
          <MetricDisplay
            getValue={props.getValue}
            legendName={'Clicks'}
            category={category}
            deltaType={deltaType}
            color={color}
          />
        </div>
      );
    },
  },

  // conversion_rate column
  {
    id: 'googleAds.conversion_rate',
    header: () => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex w-[160px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Conversion Rate</div>
          {sortingColumn === 'googleAds.conversion_rate' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'conversionRate',
    cell: (props: any) => {
      const category = ['Conversions Rate'];
      const { deltaType, color } = getDeltaTypeAndColor(
        props.getValue()?.current,
        props.getValue()?.previous
      );

      return (
        <div className="w-[160px] sm:w-[12vw]">
          <MetricDisplay
            getValue={props.getValue}
            legendName={'Conversions Rate'}
            category={category}
            deltaType={deltaType}
            color={color}
          />
        </div>
      );
    },
  },

  // conversions column
  {
    id: 'googleAds.conversions',
    header: () => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex w-[160px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Conversions</div>
          {sortingColumn === 'googleAds.conversions' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'conversions',
    cell: (props: any) => {
      const category = ['Conversions'];
      const { deltaType, color } = getDeltaTypeAndColor(
        props.getValue()?.current,
        props.getValue()?.previous
      );
      return (
        <div className="w-[160px] sm:w-[12vw]">
          <MetricDisplay
            getValue={props.getValue}
            legendName={'Conversions'}
            category={category}
            deltaType={deltaType}
            color={color}
          />
        </div>
      );
    },
  },

  // conversions_value column
  {
    id: 'googleAds.conversions_value',
    header: () => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex w-[160px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Conversions Value</div>
          {sortingColumn === 'googleAds.conversions_value' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'conversions_value',
    cell: (props: any) => {
      const category = ['Conversions Value'];
      const { deltaType, color } = getDeltaTypeAndColor(
        props.getValue()?.current,
        props.getValue()?.previous
      );
      return (
        <div className="w-[160px] sm:w-[12vw]">
          <MetricDisplay
            getValue={props.getValue}
            legendName={'Conversions Value'}
            category={category}
            deltaType={deltaType}
            color={color}
          />
        </div>
      );
    },
  },

  // costPerConversions column
  {
    id: 'googleAds.costPerConversion',
    header: () => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex w-[160px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Cost Per Conversion</div>
          {sortingColumn === 'googleAds.costPerConversion' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'costPerConversion',
    cell: (props: any) => {
      const category = ['Cost Per Conversion'];
      const { deltaType, color } = getDeltaTypeAndColor(
        props.getValue()?.previous,
        props.getValue()?.current
      );
      return (
        <div className="w-[160px] sm:w-[12vw]">
          <MetricDisplay
            getValue={props.getValue}
            legendName={'Cost Per Conversion'}
            category={category}
            deltaType={deltaType}
            color={color}
          />
        </div>
      );
    },
  },

  // cpc column
  {
    id: 'googleAds.cpc',
    header: () => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex w-[160px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">CPC</div>
          {sortingColumn === 'googleAds.cpc' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'cpc',
    cell: (props: any) => {
      const category = ['CPC'];
      const { deltaType, color } = getDeltaTypeAndColor(
        props.getValue()?.current,
        props.getValue()?.previous
      );
      return (
        <div className="w-[160px] sm:w-[12vw]">
          <MetricDisplay
            getValue={props.getValue}
            legendName={'CPC'}
            category={category}
            deltaType={deltaType}
            color={color}
          />
        </div>
      );
    },
  },

  // ctr column
  {
    id: 'googleAds.ctr',
    header: () => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex w-[160px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">CTR</div>
          {sortingColumn === 'googleAds.ctr' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'ctr',
    cell: (props: any) => {
      const category = ['CTR'];
      const { deltaType, color } = getDeltaTypeAndColor(
        props.getValue()?.current,
        props.getValue()?.previous
      );
      return (
        <div className="w-[160px] sm:w-[12vw]">
          <MetricDisplay
            getValue={props.getValue}
            legendName={'CTR'}
            category={category}
            deltaType={deltaType}
            color={color}
          />
        </div>
      );
    },
  },

  // impressions column
  {
    id: 'googleAds.impressions',
    header: () => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex w-[160px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Impressions</div>
          {sortingColumn === 'googleAds.impressions' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'impressions',
    cell: (props: any) => {
      const category = ['Impressions'];
      const { deltaType, color } = getDeltaTypeAndColor(
        props.getValue()?.current,
        props.getValue()?.previous
      );
      return (
        <div className="w-[160px] sm:w-[12vw]">
          <MetricDisplay
            getValue={props.getValue}
            legendName={'Impressions'}
            category={category}
            deltaType={deltaType}
            color={color}
          />
        </div>
      );
    },
  },

  // roas column
  {
    id: 'googleAds.roas',
    header: () => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex w-[160px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Roas</div>
          {sortingColumn === 'googleAds.roas' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'roas',
    cell: (props: any) => {
      const category = ['ROAS'];
      const { deltaType, color } = getDeltaTypeAndColor(
        props.getValue()?.current,
        props.getValue()?.previous
      );
      return (
        <div className="w-[160px] sm:w-[12vw]">
          <MetricDisplay
            getValue={props.getValue}
            legendName={'ROAS'}
            category={category}
            deltaType={deltaType}
            color={color}
          />
        </div>
      );
    },
  },

  // Actions column
  {
    id: 'Action',
    header: () => (
      <div className="flex justify-center w-[100px] sm:w-[8vw] text-sm font-inter">
        Action
      </div>
    ),
    cell: (cell: any) => {
      return (
        <div className="flex space-x-3 items-center  w-[100px] sm:w-[8vw] justify-center ">
          <div
            className="cursor-pointer"
            onClick={() => {
              editClientPerformance(cell?.row.original);
            }}
          >
            <Icon name="edit" />
          </div>
          {integration?.value !== 'missing_integration' && (
            <div
              className="cursor-pointer"
              onClick={() => {
                handleRegeneratePerformance(
                  cell?.row?.original?.client,
                  'ppc_google'
                );
              }}
              title="Generate Performance"
            >
              <Icon name="RefreshIcon" size={28} color="#7C7E7F" />
            </div>
          )}
        </div>
      );
    },
  },
];
