import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const Semrush: React.FC<IconProps> = ({ color = 'black', size = 32 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.975 16.7501C26.975 17.3106 26.6896 17.4014 25.9804 17.4014C25.2263 17.4014 25.0887 17.276 25.0083 16.7043C24.8596 15.2513 23.8762 14.0163 22.2295 13.8788C21.7037 13.8338 21.5774 13.6392 21.5774 12.9871C21.5774 12.3817 21.6691 12.0954 22.1378 12.0954C24.9971 12.1067 26.9759 14.4046 26.9759 16.7501H26.975ZM31.1385 16.7501C31.1385 12.3921 28.1884 7.7832 21.3716 7.7832H7.83037C7.55534 7.7832 7.3841 7.92072 7.3841 8.17239C7.3841 8.30904 7.48702 8.43531 7.57869 8.50364C8.06994 8.89282 8.80248 9.32699 9.76248 9.80785C10.7009 10.2766 11.4325 10.5854 12.1642 10.8829C12.473 11.0083 12.588 11.1458 12.588 11.317C12.588 11.5575 12.4159 11.7062 12.0846 11.7062H1.33437C1.0135 11.7062 0.864746 11.9121 0.864746 12.1179C0.864746 12.2891 0.921827 12.4379 1.07058 12.5867C1.93977 13.5017 3.3348 14.6001 5.36031 15.8697C7.20956 17.0177 9.11809 18.0674 11.0779 19.0143C11.3754 19.1519 11.4671 19.3119 11.4671 19.4831C11.4559 19.6777 11.3071 19.8377 10.9638 19.8377H6.03404C5.75988 19.8377 5.59988 19.9864 5.59988 20.2156C5.59988 20.341 5.7028 20.501 5.84031 20.6273C6.96031 21.6453 8.75664 22.7549 11.1471 23.772C14.3385 25.1333 17.5739 25.9454 21.2116 25.9454C28.108 25.9454 31.1385 20.7873 31.1385 16.7501V16.7501ZM22.1378 23.189C18.6152 23.189 15.6643 20.3177 15.6643 16.7389C15.6643 13.2154 18.604 10.3795 22.1378 10.3795C25.7521 10.3795 28.588 13.2042 28.588 16.7389C28.5767 20.3186 25.7512 23.189 22.1378 23.189V23.189Z"
      fill="#FF642D"
    />
  </svg>
);

export default Semrush;
