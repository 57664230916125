import React from 'react';
import { SparkAreaChart, Card } from '@tremor/react';
import InfoTooltip from '../AnalyticsInfoTooltip';
import Icon from '../../../assets/icons/SvgComponent';
import Tooltip from '../../HelpTooltip';
interface Props {
  name?: string;
  data?: any;
  loading?: boolean;
  info?: string;
  icon?: string;
  propertyId?: string;
}

const SparkAreaChartCard: React.FC<Props> = ({
  name,
  data,
  loading,
  info,
  icon,
  propertyId,
}) => {
  // const chartdata = [
  //   {
  //     month: 'Jan 21',
  //     Performance: 0,
  //   },
  //   {
  //     month: 'Feb 21',
  //     Performance: 3000,
  //   },
  //   {
  //     month: 'Mar 21',
  //     Performance: 2000,
  //   },
  //   {
  //     month: 'Apr 21',
  //     Performance: 5080,
  //   },
  // ];

  if (loading) {
    return (
      <div
        role="status"
        className="w-full border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700"
      >
        <div className="flex items-center justify-between">
          <div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-4"></div>
            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
          </div>
        </div>
        <span className="sr-only">Loading...</span>
      </div>
    );
  } else {
    return (
      <Card className="p-2 px-4 w-full">
        <div className="flex items-center justify-between ">
          <div className="flex gap-1 items-center ">
            <h2 className="text-[14px] text-[#001C44] font-semibold dark:text-white">
              {name}
            </h2>
            <InfoTooltip content={info} position="top">
              <Icon name="InfoCircle" size={14} />
            </InfoTooltip>
          </div>
          <Tooltip
            content={propertyId ? propertyId : 'N/A'}
            position="top"
            textAlign="text-center"
          >
            <div className="pt-1">
              <Icon name={`${icon}`} size={25} />
            </div>
          </Tooltip>
        </div>

        <div className="flex w-full items-center flex-wrap justify-between">
          <div className="flex items-center ">
            <p className="text-tremor-content-strong dark:text-dark-tremor-content-strong font-bold text-[30px]">
              {data}
            </p>
          </div>
          {/* <div className="flex justify-between items-center ">
            <div className="flex items-center mr-2 pt-2">
              <span className="font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                +10%
              </span>
              <span className="rounded  pl-2 text-xs text-tremor-default font-light text-[#808DA1]">
                vs last month
              </span>
            </div>
  
            <SparkAreaChart
              data={chartdata}
              categories={['Performance']}
              index={'month'}
              curveType="natural"
              colors={['emerald']}
              className="w-8 h-6"
            />
          </div> */}
        </div>
      </Card>
    );
  }
};

export default SparkAreaChartCard;
