import React, { useState } from 'react';
import ChangeHistory from './Changes';
import Settings from './Settings';
import Metrics from './Metrics';
import Icon from '../../../assets/icons/SvgComponent';
import useWeeklyReportStore from '../../../store/useWeeklyStore';
import { Link } from 'react-router-dom';
interface WeeklyProps {
  selectedReport: any;
  fetchReports: () => void;
  selectedWeek: any;
  report_type: string;
  onClose: () => void;
}
const WeeklyReportingForm: React.FC<WeeklyProps> = ({
  selectedReport,
  fetchReports,
  selectedWeek,
  report_type,
  onClose,
}) => {
  const [activeTab, setActiveTab] = useState<string>('Metrics');
  const [reportForm, setReportForm] = useState(selectedReport);
  const googleAdsUrl =
    'https://ads.google.com/aw/overview?ocid=111344966&euid=124794395&__u=1230916355&uscid=111344966&__c=8638506134&authuser=3&workspaceId=0&subid=ph-en-awhp-g-aw-c-home-signin%21o2-adshp-hv-q4-22&ascid=111344966';
  const handleTabChanges = (
    tab: string,
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    setActiveTab(tab);
  };

  return (
    <div
      onClick={(e: any) => {
        e.stopPropagation();
      }}
      className="bg-white p-4 sm:p-6  shadow-md h-full z-20 fixed right-0 w-screen sm:w-[800px] font-inter  border-b-8 border-[#005C9F] dark:bg-gray-900"
    >
      <div
        className="absolute top-4 right-4 sm:top-6 sm:right-6"
        onClick={onClose}
      >
        <Icon name="Xclose" size={30} />
      </div>
      <h2 className="text-lg mb-6 dark:text-white">
        Weekly Reporting {report_type === 'ppc_google' ? '(PPC)' : '(SEO)'}
      </h2>
      <h1 className="text-xl font-semibold dark:text-white">
        {selectedReport?.name}
      </h1>
      <div className="flex flex-col ml-1 mb-5 dark:text-white">
        {report_type === 'ppc_google' && (
          <Link to={googleAdsUrl} target="_blank">
            <span className="text-sm font-inter font-medium">
              Account ID: {selectedReport?.google_ads_id}
            </span>
          </Link>
        )}
        <span className="text-sm font-inter font-medium">
          {selectedReport?.domain}
        </span>
      </div>
      <div className="flex space-x-4 bg-[#F3F7FA] rounded-lg dark:bg-gray-800">
        <button
          className={`py-2 px-4 ${
            activeTab === 'Metrics'
              ? 'text-[#001C44] dark:text-white'
              : 'text-[#7C7E7F]'
          }`}
          onClick={(e: any) => handleTabChanges('Metrics', e)}
        >
          Preview
        </button>
        <button
          className={`py-2 px-4 ${
            activeTab === 'ChangeHistory'
              ? 'text-[#001C44] dark:text-white'
              : 'text-[#7C7E7F]'
          }`}
          onClick={(e: any) => handleTabChanges('ChangeHistory', e)}
        >
          Change History
        </button>
        <button
          className={`py-2 px-4 ${
            activeTab === 'Settings'
              ? 'text-[#001C44] dark:text-white'
              : 'text-[#7C7E7F]'
          }`}
          onClick={(e: any) => handleTabChanges('Settings', e)}
        >
          Recipients
        </button>
      </div>
      {activeTab === 'Metrics' ? (
        <Metrics
          selectedWeek={selectedWeek}
          selectedReport={selectedReport}
          report_type={report_type}
        />
      ) : null}
      {activeTab === 'ChangeHistory' ? (
        <ChangeHistory selectedReport={selectedReport} />
      ) : null}
      {activeTab === 'Settings' ? (
        <Settings
          setReportForm={setReportForm}
          reportForm={reportForm}
          fetchReports={fetchReports}
        />
      ) : null}
    </div>
  );
};

export default WeeklyReportingForm;
