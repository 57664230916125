import { AreaChart } from '@tremor/react';
import moment from 'moment';
import React, {
  ReactNode,
  useRef,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { formatNumber, formatToCurrency } from '../../../utils/common';
import Icon from '../../../assets/icons/SvgComponent';
import { useThemeStore } from '../../../store/useThemeStore';

interface TooltipItem {
  dates: string[];
  values: number[];
}

interface Props {
  children: ReactNode;
  tooltip?: TooltipItem;
  totalValue?: string;
  hasDollar?: boolean;
  hasPercent?: boolean;
  legendName?: string;
  category?: string[];
  clientName?: string;
  position?: 'top' | 'bottom' | 'left' | 'right';
  customBgColor?: string;
  textAlign?: string;
}

const MetricsChart: React.FC<Props> = ({
  position = 'right', // Default position
  children,
  tooltip,
  hasDollar,
  hasPercent,
  totalValue,
  legendName,
  customBgColor,
  textAlign,
  category,
  clientName,
}): JSX.Element => {
  const tooltipRef = useRef<HTMLSpanElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [adjustedPosition, setAdjustedPosition] = useState(position);
  const { mode } = useThemeStore();

  useEffect(() => {
    const handleScroll = () => {
      if (tooltipRef.current && containerRef.current) {
        const tooltipRect = tooltipRef.current.getBoundingClientRect();
        const containerRect = containerRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;

        let newPosition = position;

        if (
          containerRect.bottom > viewportHeight - 160 &&
          containerRect.top > tooltipRect.height
        ) {
          newPosition = 'top';
        } else if (
          containerRect.top < 300 &&
          containerRect.bottom < viewportHeight - tooltipRect.height
        ) {
          newPosition = 'bottom';
        } else {
          newPosition = 'right';
        }

        setAdjustedPosition(newPosition);
      }
    };

    if (containerRef.current) {
      containerRef.current.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleScroll);
    }

    handleScroll(); // Initial check

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('scroll', handleScroll);
      }
      window.removeEventListener('resize', handleScroll);
    };
  }, [position]);

  const getTooltipClass = () => {
    let tooltipClass = `absolute hidden group-hover:block text-xs p-2 whitespace-pre-line rounded min-w-[180px] max-w-[800px] dark:bg-gray-900 max-h-[400px] overflow-auto z-[9999] scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8 font-inter ${
      customBgColor
        ? customBgColor
        : 'bg-[#FFF] text-[#000] rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] dark:border-none'
    } ${textAlign ? textAlign : ''}`;

    if (adjustedPosition === 'top') {
      tooltipClass += ' left-1/2 -translate-x-1/2 bottom-[calc(100%+5px)]';
    } else if (adjustedPosition === 'bottom') {
      tooltipClass += ' left-1/2 -translate-x-1/2 top-[calc(100%+5px)]';
    } else if (adjustedPosition === 'left') {
      tooltipClass +=
        ' top-1/2 -translate-y-1/2 right-[calc(100%+5px)] text-center';
    } else if (adjustedPosition === 'right') {
      tooltipClass +=
        ' top-1/2 -translate-y-1/2 left-[calc(100%+5px)] text-center';
    }

    return tooltipClass;
  };

  const getArrowClass = () => {
    let arrowClass = 'absolute hidden group-hover:block border-[6px] ';

    if (adjustedPosition === 'top') {
      arrowClass +=
        ' left-1/2 -translate-x-1/2 bottom-full border-l-transparent border-r-transparent border-b-0 border-t-gray-300 dark:border-t-gray-900';
    } else if (adjustedPosition === 'bottom') {
      arrowClass +=
        ' left-1/2 -translate-x-1/2 top-full border-l-transparent border-r-transparent border-t-0 border-b-gray-300 dark:border-b-gray-900';
    } else if (adjustedPosition === 'left') {
      arrowClass +=
        ' top-1/2 -translate-y-1/2 right-full border-t-transparent border-b-transparent border-r-0 border-l-gray-300 dark:border-l-gray-900';
    } else if (adjustedPosition === 'right') {
      arrowClass +=
        ' top-1/2 -translate-y-1/2 left-full border-t-transparent border-b-transparent border-l-0 border-r-gray-300 dark:border-r-gray-900';
    }

    return arrowClass;
  };

  const chartData = tooltip?.dates?.map((date: string, index: number) => ({
    date: moment(date).format('MMM DD'),
    [legendName]: tooltip?.values[index],
  }));

  const noData = tooltip?.values?.every((value) => value === 0);

  const dataFormatter = useCallback(
    (number: number | string, hasDollar?: boolean, hasPercent?: boolean) => {
      return hasDollar
        ? formatToCurrency(number)
        : formatNumber(number, hasPercent);
    },
    []
  );

  return (
    <div className="relative cursor-pointer group" ref={containerRef}>
      <div>{children}</div>
      <span className={getArrowClass()} />
      <span className={getTooltipClass()} ref={tooltipRef}>
        {!noData ? (
          <div className="relative p-2">
            <div className="absolute top-5 left-4 text-black font-inter font-[700]">
              <div className="max-w-[180px] text-[18px] font-[700] truncate text-[#000] dark:text-white h-[20px]">
                {clientName}
              </div>
            </div>
            <AreaChart
              className="h-72 w-[400px]"
              data={chartData}
              index="date"
              startEndOnly
              yAxisWidth={80}
              categories={category}
              colors={mode === 'light' ? ['#0029FF'] : ['#fff']}
              valueFormatter={(value) =>
                dataFormatter(value, hasDollar, hasPercent)
              }
            />
          </div>
        ) : (
          <div className="w-full flex flex-col justify-center items-center p-1">
            <p className="mb-2 text-xs text-[#000] dark:text-white">
              No records found
            </p>
            <Icon name="NoData" size={50} />
          </div>
        )}
      </span>
    </div>
  );
};

export default MetricsChart;
