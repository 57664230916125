import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import useGoogleStore from '../../../store/useGoogleStore';
import useUtilityStore from '../../../store/useUtilityStore';
import AreaCardChart from '../AreaCardChart';
import { Grid, Col } from '@tremor/react';
import {
  processData,
  processData2,
  formatNumber,
  formatToCurrency,
  parseDate,
} from '../../../utils/common';
import useClientStore from '../../../store/useClientStore';
import { isLedGenClient } from '../../../utils/client';
import MobileView from './MobileView';
import { useRetryFetchGoogleAds } from '../Hooks';
interface UtilityStoreState {
  campaignReport: {
    label: string;
    value: number;
    id: string;
  };
}

const CampaignReport = () => {
  const { campaignReport } = useUtilityStore() as UtilityStoreState;
  const { campaignsReport } = useGoogleStore((state) => state);
  const { selectedClient } = useClientStore((state) => state);
  const isLeadGen = isLedGenClient(selectedClient);

  const location = useLocation();
  const [sliceData, setSliceData] = useState(false);

  const startDate = parseDate(localStorage.getItem('startDate'));
  const endDate = parseDate(localStorage.getItem('endDate'));

  useEffect(() => {
    const currentMoment = moment();

    const isValidAndCurrentMonth = (date) => {
      const momentDate = date ? moment(date) : null;
      return (
        momentDate &&
        momentDate.isValid() &&
        momentDate.isSame(currentMoment, 'month') &&
        momentDate.isSame(currentMoment, 'year')
      );
    };

    const isCurrentMonth =
      (startDate && isValidAndCurrentMonth(startDate)) ||
      (endDate && isValidAndCurrentMonth(endDate));

    setSliceData(isCurrentMonth);
  }, [startDate, endDate, selectedClient, location.pathname]);
  // Get the label from campaignReport or use a default value if it doesn't exist
  const label = campaignReport?.id || 'overall';

  // Retrieve the ad data based on the label
  const adData = campaignsReport?.chartsData?.[label] || {};

  const clicks = processData(adData, 'clicks');
  const conversionRate = processData(adData, 'conversionRate');
  const conversions = processData2({
    dates: campaignsReport?.conversionActions?.[label]?.dates,
    values: campaignsReport?.conversionActions?.[label]?.values,
    totalKeyName: 'Conversions',
  });
  const conversionsValue = processData(adData, 'conversionsValue');
  const cost = processData(adData, 'cost');
  const cpc = processData(adData, 'cpc', 'Cost Per Click');
  const costPerConversion = processData(adData, 'costPerConversion');
  const ctr = processData(adData, 'ctr', 'Click-Through Rate');
  const impressions = processData(adData, 'impressions');
  const totalRoas = processData(adData, 'totalRoas', 'Return on Ad Spend');

  const lastMonthClicks =
    Array.isArray(clicks) && clicks.length > 0
      ? clicks[clicks.length - 1]?.value
      : undefined;

  const lastMonthConversionRate =
    Array.isArray(conversionRate) && conversionRate.length > 0
      ? conversionRate[conversionRate.length - 1]?.value
      : undefined;

  const lastMonthConversions =
    Array.isArray(conversions) && conversions.length > 0
      ? conversions[conversions.length - 1]?.Conversions
      : undefined;

  const lastMonthConversionsValue =
    Array.isArray(conversionsValue) && conversionsValue.length > 0
      ? conversionsValue[conversionsValue.length - 1]?.value
      : undefined;

  const lastMonthCost =
    Array.isArray(cost) && cost.length > 0
      ? cost[cost.length - 1]?.value
      : undefined;

  const lastMonthCostPerConversion =
    Array.isArray(costPerConversion) && costPerConversion.length > 0
      ? costPerConversion[costPerConversion.length - 1]?.value
      : undefined;

  const lastMonthCpc =
    Array.isArray(cpc) && cpc.length > 0
      ? cpc[cpc.length - 1]?.value
      : undefined;

  const lastMonthCtr =
    Array.isArray(ctr) && ctr.length > 0
      ? ctr[ctr.length - 1]?.value
      : undefined;

  const lastMonthImpressions =
    Array.isArray(impressions) && impressions.length > 0
      ? impressions[impressions.length - 1]?.value
      : undefined;

  const lastMonthTotalRoas =
    Array.isArray(totalRoas) && totalRoas.length > 0
      ? totalRoas[totalRoas.length - 1]?.value
      : undefined;

  const retryFetchGoogleAds = useRetryFetchGoogleAds();

  return (
    <div>
      <div className="flex justify-between items-center">
        <h2 className="title-text text-base sm:text-[1.85rem] pb-1 hidden sm:block">
          {campaignReport.label}
        </h2>
      </div>
      <MobileView
        retry={retryFetchGoogleAds}
        isLeadGen={isLeadGen}
        adData={adData}
        clicks={clicks}
        conversionRate={conversionRate}
        conversions={conversions}
        conversionsValue={conversionsValue}
        cost={cost}
        cpc={cpc}
        costPerConversion={costPerConversion}
        ctr={ctr}
        impressions={impressions}
        totalRoas={totalRoas}
      />
      <div className="hidden sm:block">
        <Grid
          numItems={1}
          numItemsSm={1}
          numItemsMd={2}
          numItemsLg={4}
          className="gap-4 mt-4"
        >
          <Col>
            <AreaCardChart
              title="Clicks"
              chatData={clicks}
              category={['Clicks']}
              info={adData?.clicks?.info ? adData?.clicks?.info : 'Clicks'}
              prevPeriod={`${adData?.clicks?.prevPeriod}`}
              previousYear={`${adData?.clicks?.previousYear}`}
              isLoading={campaignsReport.loading}
              totalValue={formatNumber(lastMonthClicks)}
              error={campaignsReport.error}
              icon="googleAd"
              propertyId={`Customer ID: ${selectedClient?.google_ads?.customer_id}`}
              retryMethod={retryFetchGoogleAds}
            />
          </Col>
          <Col>
            <AreaCardChart
              title="Conversion Rate"
              chatData={conversionRate}
              category={['Conversion Rate']}
              info={
                adData?.conversionRate?.info
                  ? adData?.conversionRate?.info
                  : 'Conversion Rate'
              }
              prevPeriod={`${adData?.conversionRate?.prevPeriod}`}
              previousYear={`${adData?.conversionRate?.previousYear}`}
              isLoading={campaignsReport.loading}
              totalValue={formatNumber(lastMonthConversionRate, true)}
              hasPercent={true}
              error={campaignsReport.error}
              icon="googleAd"
              propertyId={`Customer ID: ${selectedClient?.google_ads?.customer_id}`}
              retryMethod={retryFetchGoogleAds}
            />
          </Col>
          <Col>
            <AreaCardChart
              title="Conversions"
              chatData={conversions}
              category={['Conversions']}
              info={
                adData?.conversions?.info
                  ? adData?.conversions?.info
                  : 'Conversions'
              }
              prevPeriod={`${adData?.conversions?.prevPeriod}`}
              previousYear={`${adData?.conversions?.previousYear}`}
              isLoading={campaignsReport.loading}
              totalValue={formatNumber(lastMonthConversions)}
              error={campaignsReport.error}
              icon="googleAd"
              propertyId={`Customer ID: ${selectedClient?.google_ads?.customer_id}`}
              retryMethod={retryFetchGoogleAds}
              customDataTooltip={true}
            />
          </Col>
          <Col>
            <AreaCardChart
              title="Conversion Value"
              chatData={conversionsValue}
              category={['Conversions Value']}
              info={
                adData?.conversionsValue?.info
                  ? adData?.conversionsValue?.info
                  : 'Conversion Value'
              }
              prevPeriod={`${adData?.conversionsValue?.prevPeriod}`}
              previousYear={`${adData?.conversionsValue?.previousYear}`}
              isLoading={campaignsReport.loading}
              totalValue={formatToCurrency(lastMonthConversionsValue)}
              hasDollar={true}
              error={campaignsReport.error}
              icon="googleAd"
              propertyId={`Customer ID: ${selectedClient?.google_ads?.customer_id}`}
              retryMethod={retryFetchGoogleAds}
            />
          </Col>
          <Col>
            <AreaCardChart
              title="Cost"
              chatData={cost}
              category={['Cost']}
              info={adData?.cost?.info ? adData?.cost?.info : 'Cost'}
              prevPeriod={`${adData?.cost?.prevPeriod}`}
              previousYear={`${adData?.cost?.previousYear}`}
              isLoading={campaignsReport.loading}
              totalValue={formatToCurrency(lastMonthCost)}
              hasDollar={true}
              error={campaignsReport.error}
              icon="googleAd"
              propertyId={`Customer ID: ${selectedClient?.google_ads?.customer_id}`}
              retryMethod={retryFetchGoogleAds}
            />
          </Col>
          <Col>
            <AreaCardChart
              title="Cost Per Click"
              chatData={cpc}
              category={['Cost Per Click']}
              info={adData?.cpc?.info ? adData?.cpc?.info : 'Cost Per Click'}
              // prevPeriod={`${adData?.cpc?.prevPeriod}`}
              // previousYear={`${adData?.cpc?.previousYear}`}
              prevPeriod={`${-adData?.cpc?.prevPeriod}`}
              previousYear={`${-adData?.cpc?.previousYear}`}
              isLoading={campaignsReport.loading}
              totalValue={formatToCurrency(lastMonthCpc)}
              hasDollar={true}
              error={campaignsReport.error}
              icon="googleAd"
              propertyId={`Customer ID: ${selectedClient?.google_ads?.customer_id}`}
              retryMethod={retryFetchGoogleAds}
            />
          </Col>
          <Col>
            <AreaCardChart
              title="Cost Per Conversions"
              chatData={costPerConversion}
              category={['Cost Per Conversion']}
              info={
                adData?.costPerConversion?.info
                  ? adData?.costPerConversion?.info
                  : 'Cost Per Conversions'
              }
              prevPeriod={`${-adData?.costPerConversion?.prevPeriod}`}
              previousYear={`${-adData?.costPerConversion?.previousYear}`}
              isLoading={campaignsReport.loading}
              totalValue={formatToCurrency(lastMonthCostPerConversion)}
              hasDollar={true}
              error={campaignsReport.error}
              icon="googleAd"
              propertyId={`Customer ID: ${selectedClient?.google_ads?.customer_id}`}
              retryMethod={retryFetchGoogleAds}
            />
          </Col>
          <Col>
            <AreaCardChart
              title="Click-Through Rate"
              chatData={ctr}
              category={['Click-Through Rate']}
              info={
                adData?.ctr?.info ? adData?.ctr?.info : 'Click-Through Rate'
              }
              prevPeriod={`${adData?.ctr?.prevPeriod}`}
              previousYear={`${adData?.ctr?.previousYear}`}
              isLoading={campaignsReport.loading}
              totalValue={formatNumber(lastMonthCtr, true)}
              hasPercent={true}
              error={campaignsReport.error}
              icon="googleAd"
              propertyId={`Customer ID: ${selectedClient?.google_ads?.customer_id}`}
              retryMethod={retryFetchGoogleAds}
            />
          </Col>
          <Col>
            <AreaCardChart
              title="Impressions"
              chatData={impressions}
              category={['Impressions']}
              info={
                adData?.impressions?.info
                  ? adData?.impressions?.info
                  : 'Impressions'
              }
              prevPeriod={`${adData?.impressions?.prevPeriod}`}
              previousYear={`${adData?.impressions?.previousYear}`}
              isLoading={campaignsReport.loading}
              totalValue={formatNumber(lastMonthImpressions)}
              error={campaignsReport.error}
              icon="googleAd"
              propertyId={`Customer ID: ${selectedClient?.google_ads?.customer_id}`}
              retryMethod={retryFetchGoogleAds}
            />
          </Col>
          {!isLeadGen && (
            <Col>
              <AreaCardChart
                title="Return on Ad Spend"
                chatData={totalRoas}
                category={['Return on Ad Spend']}
                info={
                  adData?.totalRoas?.info
                    ? adData?.totalRoas?.info
                    : 'Return on Ad Spend'
                }
                prevPeriod={`${adData?.totalRoas?.prevPeriod}`}
                previousYear={`${adData?.totalRoas?.previousYear}`}
                isLoading={campaignsReport.loading}
                totalValue={formatNumber(lastMonthTotalRoas)}
                error={campaignsReport.error}
                icon="googleAd"
                propertyId={`Customer ID: ${selectedClient?.google_ads?.customer_id}`}
                retryMethod={retryFetchGoogleAds}
              />{' '}
            </Col>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default CampaignReport;
