import AllClients from './allClients';
import SendAccessRequest from './SendAccessRequest';

export default function ClientAccess() {
  return (
    <div className="bg-gray-100  flex  flex-col">
      <AllClients />
      {/* <SendAccessRequest /> */}
    </div>
  );
}
