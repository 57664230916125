import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const Line03: React.FC<IconProps> = ({ color = 'black', size = 80 }) => (
  <svg
    width="1"
    height="16"
    viewBox="0 0 1 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line x1="0.5" y1="2.18557e-08" x2="0.499999" y2="16" stroke="#ADB0B2" />
  </svg>
);

export default Line03;
