import React from 'react';

interface TooltipProps {
  position: 'top' | 'bottom' | 'left' | 'right';
  content: any;
  children: any;
  customBgColor?: string;
  fit?: boolean;
  textAlign?: string;
}

const Tooltip: React.FC<TooltipProps> = ({
  position,
  content,
  children,
  customBgColor,
  fit,
  textAlign,
}) => {
  if (!content) {
    // If content is empty, undefined, or null, return only the children without the tooltip
    return <div>{children}</div>;
  }

  let tooltipClass = `absolute hidden group-hover:block text-xs p-2 whitespace-pre-line rounded min-w-[180px] max-w-[200px] dark:bg-gray-700 max-h-[400px] overflow-auto z-[99] scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8 font-inter ${customBgColor ? customBgColor : 'bg-neutral-900 text-white'} ${textAlign ? textAlign : ''}`;
  let arrowClass = 'absolute hidden group-hover:block border-[6px] ';

  if (position === 'top') {
    tooltipClass += ' left-1/2 -translate-x-1/2 bottom-[calc(100%+5px)]';
    arrowClass +=
      ' left-1/2 -translate-x-1/2 bottom-full border-l-transparent border-r-transparent border-b-0 border-t-gray-800 dark:border-t-gray-700';
  } else if (position === 'bottom') {
    tooltipClass += ' left-1/2 -translate-x-1/2 top-[calc(100%+5px)]';
    arrowClass +=
      ' left-1/2 -translate-x-1/2 top-full border-l-transparent border-r-transparent border-t-0 border-b-gray-800 dark:border-b-gray-700';
  } else if (position === 'left') {
    tooltipClass +=
      ' top-1/2 -translate-y-1/2 right-[calc(100%+5px)] text-center';
    arrowClass +=
      ' top-1/2 -translate-y-1/2 right-full border-t-transparent border-b-transparent border-r-0 border-l-gray-800 dark:border-l-gray-700';
  } else if (position === 'right') {
    tooltipClass +=
      ' top-1/2 -translate-y-1/2 left-[calc(100%+5px)] text-center';
    arrowClass +=
      ' top-1/2 -translate-y-1/2 left-full border-t-transparent border-b-transparent border-l-0 border-r-gray-800 dark:border-r-gray-700';
  }

  return (
    <div className={`relative cursor-pointer group`}>
      <div>{children}</div>
      <span className={tooltipClass}>{content}</span>
      <span className={arrowClass}></span>
    </div>
  );
};

export default Tooltip;
