import React from 'react';

interface GoogleProps {
  color?: string;
}

const Google: React.FC<GoogleProps> = ({ color = '#428B6E' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
  >
    <path
      d="M30.0014 16.8109C30.0014 15.6598 29.9061 14.8198 29.6998 13.9487H16.2871V19.1442H24.1601C24.0014 20.4354 23.1442 22.3798 21.2394 23.6864L21.2127 23.8604L25.4536 27.08L25.7474 27.1087C28.4458 24.6665 30.0014 21.0731 30.0014 16.8109Z"
      fill="white"
    />
    <path
      d="M16.2863 30.5C20.1434 30.5 23.3814 29.2555 25.7466 27.1089L21.2386 23.6865C20.0323 24.511 18.4132 25.0865 16.2863 25.0865C12.5086 25.0865 9.30225 22.6444 8.15929 19.2688L7.99176 19.2827L3.58208 22.6272L3.52441 22.7843C5.87359 27.3577 10.699 30.5 16.2863 30.5Z"
      fill="white"
    />
    <path
      d="M8.16013 19.2689C7.85855 18.3978 7.68401 17.4644 7.68401 16.5C7.68401 15.5355 7.85855 14.6022 8.14426 13.7311L8.13627 13.5455L3.67132 10.1474L3.52524 10.2155C2.55703 12.1133 2.00146 14.2444 2.00146 16.5C2.00146 18.7555 2.55703 20.8866 3.52524 22.7844L8.16013 19.2689Z"
      fill="white"
    />
    <path
      d="M16.2864 7.9133C18.9689 7.9133 20.7784 9.04885 21.8102 9.9978L25.8419 6.14C23.3658 3.88445 20.1435 2.5 16.2864 2.5C10.699 2.5 5.8736 5.6422 3.52441 10.2155L8.14345 13.7311C9.30229 10.3555 12.5086 7.9133 16.2864 7.9133Z"
      fill="white"
    />
  </svg>
);

export default Google;
