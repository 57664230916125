import React, { useState } from 'react';
import { Card, Dialog, DialogPanel, Button, Textarea } from '@tremor/react';
import HeaderTitle from '../../HeaderTitle';
import Icon from '../../../assets/icons/SvgComponent';
import { Input } from '../../Modals/UserModal/myDetails';
import ReactSelect from '../../ReactSelect';
import { motion } from 'framer-motion';
import BgGoogle from '../../../assets/imgs/BgGoogle.png';
import useAuthStore from '../../../store/useAuthStore';
import api from '../../../api';
import { Flip, ToastContainer, toast } from 'react-toastify';
import Alert from '../../Alert';
interface Props {
  offersData: any;
}

export const SpecialOffers: React.FC<Props> = ({ offersData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [greetingModal, setGreetingModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user, selectedDomain } = useAuthStore();
  const [currentData, setCurrentData] = useState({
    id: 0,
    title: '',
    offer: '',
    greeting: '',
    img: '',
    description: '',
  });
  const [formData, setFormData] = useState({
    company_name: '',
    company_website: '',
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    additional_information: '',
  });

  const [selectedType, setSelectedType] = useState<{
    label: string;
    value: string;
  }>({
    label: 'All Services',
    value: '',
  });
  const [referral, setReferral] = useState<{
    label: string;
    value: string;
  }>({
    label: 'Select..',
    value: '',
  });
  const [mentioned, setMentioned] = useState<{
    label: string;
    value: string;
  }>({
    label: 'Select..',
    value: '',
  });

  const data = [
    {
      id: 1,
      title: '50% Off SEO Link Booster Package',
      offer: 'Unleash the Power of Accelerated SEO: Save $1,000 Today!',
      content: `Transform your digital presence with our exclusive offer, now at an irresistible price of just $1,000 – down from the regular $2,000! Elevate your SEO game and see results faster than ever before. <br/><br/> Boost your visibility with 3 extra guest posts on high-quality domains (DR 30+). Flexible targeting - switch focus or intensify current efforts monthly. <br/><br/> Minimum Domain Rating of 30+ for your chosen category or keyword to speed up. <br/><br/> Change your targets any month or simply boost your existing focus areas for faster results`,
      image: 'LinkBooster',
      modalTitle: 'Are you interested in our SEO Link Booster Package?',
      greeting: `Thank you for your interest in our SEO Link Booster Package, your Account Manager will be in touch with you shortly.`,
      color: 'blue',
      type: 'SEO',
    },
    {
      id: 2,
      title: 'Unlock 3 Months of Complimentary Web Hosting!',
      offer: 'Looking for a new home for your website? Look no further!',
      content: `At OMG, we're not just offering web hosting; we're offering a launchpad for your online presence. Our hosting plans begin at just $30 a month, designed to grow with you and handle any level of traffic you throw at us. <br/><br/> Switch to OMG for your web hosting needs, and we'll cover the first three months. Absolutely free. Whether you're just starting out or scaling up, make the smart move to OMG and enjoy seamless hosting that meets your every need. Let's set your site up for success.<br/><br/> Claim your three free months now!`,
      image: 'WebHosting',
      modalTitle:
        'Are you interested in our 3 Month Complimentary Web Hosting Offer?',
      greeting: `Thank you for your interest in our 3 Month Complimentary Web Hosting Offer, your Account Manager will be in touch with you shortly.`,
      color: 'green',
      type: 'HOSTING',
    },
    {
      id: 3,
      title: 'Earn Big with Our Refer & Earn Program!',
      offer: 'Introducing a golden opportunity from OMG:',
      content: `Simply refer a friend, and if they sign up with us, you'll receive $500 in cold, hard cash! There's no limit to how many people you can refer, so start spreading the word and watch the rewards roll in.<br/><br/> Make connections, share the love, and get rewarded handsomely for each successful signup.<br/><br/> Join the OMG Refer & Earn program today and turn your recommendations into real rewards!`,
      image: 'ReferAndEarn',
      color: 'red',
      type: 'Refer & Earn',
    },
  ];

  const openModal = (item: any) => {
    if (item.id !== 3) {
      setCurrentData({
        id: item.id,
        title: item.title,
        offer: item.offer,
        greeting: item.greeting,
        img: item.image,
        description: item.content,
      });
      setIsOpen(true);
      setGreetingModal(false);
    } else {
      setCurrentData({
        id: item.id,
        title: item.modalTitle,
        offer: item.offer,
        greeting: item.greeting,
        img: item.image,
        description: item.content,
      });
      setIsOpen(true);
    }
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (event: any, type?: string) => {
    event.preventDefault();
    setLoading(true);
    if (type === 'SEO') {
      const payload = {
        first_name: user?.first_name,
        last_name: user?.last_name,
        email_address: user?.email,
        phone_number: '+639458443172',
        clientId: selectedDomain?.id,
        special_offer_type: 'web-hosting',
      };
      const response = await api.HubSpot.specialOffers(payload);

      if (response?.length && response[0]?.errors) {
        // alert(response[0]?.errors[0]?.message || 'Something went wrong!');
        toast.error(
          <Alert
            icon=""
            title={`ERROR MESSAGE!`}
            message={response[0]?.errors[0]?.message || 'Something went wrong!'}
          />,
          {
            position: 'top-center',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setLoading(false);
      } else {
        setGreetingModal(true);
        setLoading(false);
      }
      return;
    } else if (type === 'hosting') {
      const payload = {
        first_name: user?.first_name,
        last_name: user?.last_name,
        email_address: user?.email,
        phone_number: '+639458443172',
        clientId: selectedDomain?.id,
        special_offer_type: 'web-hosting',
      };
      const response = await api.HubSpot.specialOffers(payload);

      if (response?.length && response[0]?.errors) {
        // alert(response[0]?.errors[0]?.message || 'Something went wrong!');
        toast.error(
          <Alert
            icon=""
            title={`ERROR MESSAGE!`}
            message={response[0]?.errors[0]?.message || 'Something went wrong!'}
          />,
          {
            position: 'top-center',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setLoading(false);
      } else {
        setGreetingModal(true);
        setLoading(false);
      }
      return;
    } else {
      const payload = {
        company_name: formData?.company_name,
        company_website: formData?.company_website,
        first_name: formData?.first_name,
        last_name: formData?.last_name,
        email_address: formData?.email,
        phone_number: formData?.phone_number,
        service: selectedType?.value,
        share: referral.value ? true : false,
        mentioned: mentioned.value ? true : false,
        comment: formData?.additional_information,
      };
      const response = await api.HubSpot.specialOffers(payload);
      if (response?.length && response[0]?.errors) {
        // alert(response[0]?.errors[0]?.message || 'Something went wrong!');
        toast.error(
          <Alert
            icon=""
            title={`ERROR MESSAGE!`}
            message={response[0]?.errors[0]?.message || 'Something went wrong!'}
          />,
          {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setLoading(false);
      } else {
        toast.success(
          <Alert
            icon=""
            title={`SUCCESS MESSAGE!`}
            message={`Thank you for your interest in our special offers. We will get back to you soon!`}
          />,
          {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      return;
    }
  };

  if (data.length === 0) {
    return (
      <Card className="flex flex-col justify-center items-center dark:bg-[#1F242F] ">
        <div className="flex w-full items-center mb-8">
          <HeaderTitle
            text="My Special Offers"
            fontSize="font-archivo text-lg sm:text-[24px]"
          />
        </div>
        <div className="flex flex-col items-center justify-center gap-y-4">
          <Icon name="noOffer" size={30} />
          <span className="text-[#001C44] font-semibold dark:text-white">
            There are no offers available at the moment.
          </span>
          <span className="text-[#001C44] text-center font-light w-[80%] dark:text-white">
            Unlock exclusive deals and special offers tailored just for you.
            <br /> Keep an eye on this space for personalized promotions
            designed to enhance your experience..
          </span>
        </div>
      </Card>
    );
  }
  return (
    <div className="px-1">
      <Card className="dark:bg-[#222732] px-2 sm:px-6">
        <div className="flex w-full items-center mb-3 sm:mb-8">
          <HeaderTitle
            text="My Special Offers"
            fontSize="font-archivo text-lg sm:text-[24px]"
          />
        </div>
        <div className="flex flex-wrap  justify-center lg:justify-start xl:justify-between gap-4">
          {data?.map((data, index) => (
            <Card
              key={index}
              className="flex flex-col justify-between items-center w-full  lg:h-fit md:w-[48%] lg:w-[32%] xl:w-[30%] relative p-0 "
            >
              <div className="w-full">
                <Icon name={data.image} />
              </div>
              <Card className="relative flex flex-col items-start justify-between gap-y-1 w-10/12 mt-[-20px]">
                <div
                  color={data.color}
                  className={`flex items-center py-0.5 px-3 text-xs text-${data.color}-500 border border-${data.color}-500 rounded-lg gap-x-1 absolute top-[-13px] right-2.5 bg-${data.color}-100`}
                >
                  <Icon name="dot" color={data.color} />
                  {data.type}
                </div>
                <h1 className="text-lg font-semibold my-3 dark:text-white ">
                  {data.title}
                </h1>
                <span className="text-sm font-medium dark:text-white">
                  {data.offer}
                </span>
                <span
                  className="text-sm dark:text-white my-2 text-gray-600"
                  style={{
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 3,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  dangerouslySetInnerHTML={{ __html: data.content }}
                ></span>
              </Card>
              <button
                className="flex items-center justify-center gap-x-2 w-11/12 my-4 p-2 rounded-lg text-sm  font-semibold bg-[#F2F4FF]  text-[#001C44]"
                onClick={() => openModal(data)}
              >
                WHAT’S INCLUDED IN THE OFFER
                <Icon name="plus" size={18} />
              </button>

              <button
                className=" w-11/12  p-2 rounded-lg mb-8 text-sm font-semibold bg-[#0029FF]  text-white  dark:text-black dark:bg-[#FFB545]  "
                onClick={() => openModal(data)}
              >
                INTERESTED
              </button>
            </Card>
          ))}

          {/* modal */}
          <Dialog
            open={isOpen}
            onClose={(val) => setIsOpen(val)}
            static={true}
            className="z-[100]"
          >
            {currentData.id === 3 ? (
              <DialogPanel className="absolute right-0 w-screen lg:w-1/2 h-full overflow-y-auto pb-0">
                <div className="flex justify-between">
                  <div className="text-lg font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    Client Details
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() => setIsOpen(false)}
                  >
                    <Icon name="Xclose" />
                  </div>
                </div>
                {loading && (
                  <div className="absolute top-10 left-0 right-0 flex items-center justify-center">
                    <Icon name="loading" size={24} />
                  </div>
                )}
                <form className="flex flex-col gap-2 mt-4">
                  <Input
                    label="Company Name"
                    type="text"
                    name="company_name"
                    value={formData.company_name}
                    onChange={(e) => handleChange(e)}
                  />
                  <Input
                    label="Company Website"
                    type="text"
                    name="company_website"
                    value={formData.company_website}
                    onChange={(e) => handleChange(e)}
                  />
                  <Input
                    label="First Name"
                    type="text"
                    name="first_name"
                    value={formData?.first_name}
                    onChange={(e) => handleChange(e)}
                  />
                  <Input
                    label="Last Name"
                    type="text"
                    name="last_name"
                    value={formData?.last_name}
                    onChange={(e) => handleChange(e)}
                  />
                  <Input
                    label="Email Address"
                    type="email"
                    name="email"
                    value={formData?.email}
                    onChange={(e) => handleChange(e)}
                  />
                  <Input
                    label="Phone Number"
                    type="number"
                    name="phone_number"
                    value={formData?.phone_number}
                    onChange={(e) => handleChange(e)}
                  />

                  <div className="flex flex-col gap-2 mt-4">
                    <span className="text-lg font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                      Referral Information
                    </span>
                    <div className="flex flex-col gap-2">
                      <span>
                        What OMG services and products do you believe the
                        referral is most interested in?
                      </span>
                      <div className="w-1/2">
                        <ReactSelect
                          value={selectedType}
                          options={[
                            { value: 'seo_google', label: 'Google SEO' },
                            { value: 'google_ads', label: 'Google Ads' },
                            { value: 'seo_bing', label: 'Bing SEO' },
                            { value: 'ppc_bing', label: 'Microsoft Ads' },
                            { value: 'meta', label: 'Meta/Fb' },
                            { value: 'linked', label: 'LinkedIn' },
                            { value: 'tiktok', label: 'Tiktok' },
                            { value: 'pinterest', label: 'Pinterest' },
                            { value: 'display', label: 'Remarketing' },
                          ]}
                          handleOnChange={(selectedOption: any) => {
                            setSelectedType(selectedOption);
                          }}
                          placeholder="All Services"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-2">
                      <span>
                        Do you have the referral's permission to share their
                        contact information with us?
                      </span>
                      <div className="w-1/2">
                        <ReactSelect
                          value={referral}
                          options={[
                            { value: 'yes', label: 'YES' },
                            { value: 'no', label: 'NO' },
                          ]}
                          handleOnChange={(selectedOption: any) => {
                            setReferral(selectedOption);
                          }}
                          placeholder="All Services"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-2">
                      <span>
                        Would you like us to mention your name when we contact
                        the person you are referring?
                      </span>
                      <div className="w-1/2">
                        <ReactSelect
                          value={mentioned}
                          options={[
                            { value: 'yes', label: 'YES' },
                            { value: 'no', label: 'NO' },
                          ]}
                          handleOnChange={(selectedOption: any) => {
                            setMentioned(selectedOption);
                          }}
                          placeholder="All Services"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-2">
                      <span>
                        Is there any additional information we should know
                        before contacting the person you are referring?
                      </span>
                      <Textarea
                        placeholder="Type here..."
                        className="h-24"
                        name="additional_information"
                        value={formData?.additional_information}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                  <div className="mt-8 sticky bottom-0 bg-white pb-4 dark:bg-[#262627]">
                    <Button
                      className="w-full bg-[#0029FF] "
                      onClick={handleSubmit}
                    >
                      Request
                    </Button>
                  </div>
                </form>
              </DialogPanel>
            ) : currentData.id === 1 ? (
              <DialogPanel
                className={`lg:mt-[-5rem] absolute h-screen w-screen lg:h-fit  flex flex-col justify-between `}
              >
                <div className="flex items-start justify-between">
                  <div className={`w-full mb-2 bg-${BgGoogle} `}>
                    <Icon name="bgGoogle" />
                  </div>
                  <button onClick={() => closeModal()}>
                    <Icon name="close" size={24} />
                  </button>
                </div>
                {loading && (
                  <div className="absolute top-10 left-0 right-0 flex items-center justify-center">
                    <Icon name="loading" size={24} />
                  </div>
                )}
                {greetingModal ? (
                  <motion.div
                    initial={{ x: '100%' }}
                    animate={{ x: 0 }}
                    transition={{ duration: 0 }}
                  >
                    <span className="text-center font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                      {currentData.greeting}
                    </span>
                    <Button
                      className="w-full mt-8 "
                      onClick={() => closeModal()}
                    >
                      Got it!
                    </Button>
                  </motion.div>
                ) : (
                  <div>
                    <h3 className="text-lg text-center lg:text-left font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong my-4 ">
                      {currentData.title}
                    </h3>
                    {/* <span
                      className="text-sm  text-tremor-content-strong dark:text-dark-tremor-content-strong "
                      dangerouslySetInnerHTML={{
                        __html: currentData.description,
                      }}
                    ></span> */}
                    <div className="flex flex-col gap-y-4">
                      <div className="flex items-start gap-x-4">
                        <Icon name="checkOffers" size={20} />
                        <span className="text-sm text-[#001C44] dark:text-dark-tremor-content-strong w-[95%]">
                          Boost your visibility with 3 extra guest posts on
                          high-quality domains (DR 30+). Flexible targeting -
                          switch focus or intensify current efforts monthly.
                        </span>
                      </div>
                      <div className="flex items-start gap-x-4">
                        <Icon name="checkOffers" size={20} />
                        <span className="text-sm text-[#001C44] dark:text-dark-tremor-content-strong w-[95%]">
                          Minimum Domain Rating of 30+ for your chosen category
                          or keyword to speed up.
                        </span>
                      </div>

                      <div className="flex items-start gap-x-4">
                        <Icon name="checkOffers" size={20} />
                        <span className="text-sm text-[#001C44] dark:text-dark-tremor-content-strong w-[95%]">
                          Change your targets any month or simply boost your
                          existing focus areas for faster results.
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {!greetingModal && (
                  <div className="flex gap-2 mt-4">
                    <Button
                      className="w-1/2"
                      onClick={(e) => handleSubmit(e, 'SEO')}
                    >
                      YES
                    </Button>
                    <Button
                      color="rose"
                      className="w-1/2"
                      onClick={() => setIsOpen(false)}
                    >
                      NO
                    </Button>
                  </div>
                )}
              </DialogPanel>
            ) : (
              <DialogPanel
                className={`lg:mt-[-5rem] absolute h-screen w-screen lg:h-fit  flex flex-col justify-between `}
              >
                <div className="flex items-start justify-between">
                  <div className={`w-full mb-2 bg-${BgGoogle} `}>
                    <Icon name="bgGoogle" />
                  </div>
                  <button onClick={() => closeModal()}>
                    <Icon name="close" size={24} />
                  </button>
                  {loading && (
                    <div className="absolute top-10 left-0 right-0 flex items-center justify-center">
                      <Icon name="loading" size={24} />
                    </div>
                  )}
                </div>
                {greetingModal ? (
                  <motion.div
                    initial={{ x: '100%' }}
                    exit={{ x: '100%' }}
                    animate={{ x: 0 }}
                    transition={{ duration: 0, ease: 'easeInOut' }}
                  >
                    <span className="text-center font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                      {currentData.greeting}
                    </span>
                    <Button
                      className="w-full mt-8 "
                      onClick={() => closeModal()}
                    >
                      Got it!
                    </Button>
                  </motion.div>
                ) : (
                  <div>
                    <h3 className="text-lg font-semibold text-center lg:text-left text-tremor-content-strong dark:text-dark-tremor-content-strong my-4 ">
                      {currentData.title}
                    </h3>
                    {/* <span
                      className="text-sm  text-tremor-content-strong dark:text-dark-tremor-content-strong "
                      dangerouslySetInnerHTML={{
                        __html: currentData.description,
                      }}
                    ></span> */}
                    <div className="flex flex-col gap-y-4">
                      <div className="flex items-start gap-x-4">
                        <Icon name="checkOffers" size={20} />
                        <span className="text-sm text-[#001C44] dark:text-dark-tremor-content-strong w-[95%]">
                          At OMG, we're not just offering web hosting; we're
                          offering a launchpad for your online presence.
                        </span>
                      </div>
                      <div className="flex items-start gap-x-4">
                        <Icon name="checkOffers" size={20} />
                        <span className="text-sm text-[#001C44] dark:text-dark-tremor-content-strong w-[95%]">
                          Our hosting plans begin at just $30 a month, designed
                          to grow with you and handle any level of traffic you
                          throw at us.
                        </span>
                      </div>

                      <div className="flex items-start gap-x-4">
                        <Icon name="checkOffers" size={20} />
                        <span className="text-sm text-[#001C44] dark:text-dark-tremor-content-strong w-[95%]">
                          We'll cover the first three months. Absolutely free.
                          Whether you're just starting out or scaling up, make
                          the smart move to OMG and enjoy seamless hosting that
                          meets your every need. Let's set your site up for
                          success.
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {!greetingModal && (
                  <div className="flex gap-2 mt-4">
                    <Button
                      className="w-1/2"
                      onClick={(e) => handleSubmit(e, 'hosting')}
                    >
                      YES
                    </Button>
                    <Button
                      color="rose"
                      className="w-1/2"
                      onClick={() => setIsOpen(false)}
                    >
                      NO
                    </Button>
                  </div>
                )}
              </DialogPanel>
            )}
            <ToastContainer
              position="top-right"
              autoClose={30000}
              hideProgressBar
              newestOnTop={false}
              transition={Flip}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
          </Dialog>
        </div>
      </Card>
    </div>
  );
};
