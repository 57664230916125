import styled from 'styled-components';
import { colors } from '../../utils/settings';

export const Wrapper = styled.div({
  margin: 30,
  paddingBottom: 50,
});

export const Container = styled.div({
  display: 'flex',
  justifyContent: 'center',
});

export const Link = styled.a(({ active, inactive }) => ({
  display: 'inline-block',
  color: active ? colors.white : colors.offBlack,
  float: 'left',
  padding: '8px 16px',
  textDecoration: 'none',
  transition: 'background-color .3s',
  border: ' 1px solid #ddd',
  margin: '0 4px;',
  backgroundColor: active ? colors.primary : 'transparent',
  borderRadius: 15,
  opacity: active ? 0.7 : 1,
  cursor: inactive ? 'not-allowed' : 'pointer',
  ':hover': {
    backgroundColor: '#ddd',
    background: inactive ? colors.lightGray : colors.secondary,
    opacity: 0.7,
    transition: '1.5s',
  },
}));
