import AreaCardChart from '../../AreaCardChart';
import { formatNumber, formatToCurrency } from '../../../../utils/common';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

interface IProps {
  spend?: any;
  form_leads?: any;
  website_leads?: any;
  revenue?: any;
  roas?: any;
  dataInfo?: any;
  isLeadGen?: boolean;
  retryMethod?: () => void;
}

const MobileView = ({
  spend,
  revenue,
  roas,
  dataInfo,
  form_leads,
  website_leads,
  isLeadGen,
  retryMethod
}: IProps) => {
  const lastMonthSpend =
    Array.isArray(spend) && spend.length > 0
      ? spend[spend.length - 1]?.value
      : undefined;
  const lastMonthRevenue =
    Array.isArray(revenue) && revenue.length > 0
      ? revenue[revenue.length - 1]?.value
      : undefined;
  const lastMonthRoas =
    Array.isArray(roas) && roas.length > 0
      ? roas[roas.length - 1]?.value
      : undefined;
  const lastFormLeads =
    Array.isArray(form_leads) && form_leads.length > 0
      ? form_leads[form_leads.length - 1]?.value
      : undefined;
  const lastWebsiteLeads =
    Array.isArray(website_leads) && website_leads.length > 0
      ? website_leads[website_leads.length - 1]?.value
      : undefined;
  return (
    <div className="block sm:hidden mb-4">
      <div className="flex flex-col gap-4">
        <div className="p-0">
          <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
            <SwiperSlide>
              <AreaCardChart
                title="Ad Spend"
                chatData={spend}
                category={['Ad Spend']}
                info={dataInfo?.spend?.info}
                prevPeriod={dataInfo?.spend?.previous_period}
                previousYear={dataInfo?.spend?.previous_year}
                totalValue={formatToCurrency(lastMonthSpend)}
                isLoading={dataInfo.loading}
                hasDollar
                error={dataInfo.error}
                icon="meta"
                accountInsights={true}
                retryMethod={retryMethod}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title={!isLeadGen ? 'Revenue' : 'Form Leads'}
                chatData={revenue ? revenue : form_leads}
                category={[`${!isLeadGen ? 'Revenue' : 'Form Leads'}`]}
                info={
                  isLeadGen
                    ? dataInfo?.form_leads?.info
                      ? dataInfo?.form_leads?.info
                      : 'Form Leads'
                    : dataInfo?.revenue?.info
                      ? dataInfo?.revenue?.info
                      : 'Revenue'
                }
                prevPeriod={
                  revenue
                    ? dataInfo?.revenue?.previous_period
                    : dataInfo?.form_leads?.previous_period
                }
                previousYear={
                  revenue
                    ? dataInfo?.revenue?.previous_year
                    : dataInfo?.form_leads?.previous_year
                }
                totalValue={
                  revenue
                    ? formatToCurrency(lastMonthRevenue)
                    : formatNumber(lastFormLeads)
                }
                isLoading={dataInfo.loading}
                hasDollar={revenue ? true : false}
                error={dataInfo.error}
                icon="meta"
                accountInsights={true}
                retryMethod={retryMethod}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title={!isLeadGen ? 'ROAS' : 'Website Leads'}
                chatData={roas ? roas : website_leads}
                category={[`${!isLeadGen ? 'Roas' : 'Website Leads'}`]}
                info={
                  roas ? dataInfo?.roas?.info : dataInfo?.website_leads?.info
                }
                prevPeriod={
                  roas
                    ? dataInfo?.roas?.previous_period
                    : dataInfo?.website_leads?.previous_period
                }
                previousYear={
                  roas
                    ? dataInfo?.roas?.previous_year
                    : dataInfo?.website_leads?.previous_year
                }
                isLoading={dataInfo.loading}
                totalValue={
                  roas
                    ? formatNumber(lastMonthRoas)
                    : formatNumber(lastWebsiteLeads)
                }
                error={dataInfo.error}
                icon="meta"
                accountInsights={true}
                retryMethod={retryMethod}
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default MobileView;
