import { Card, Grid, Col } from '@tremor/react';
import InfoTooltip from '../AnalyticsInfoTooltip';
import Tooltip from '../../HelpTooltip';
import Icon from '../../../assets/icons/SvgComponent';
import useMetaStore from '../../../store/useMetaStore';
import { formatNumber, formatToCurrency } from '../../../utils/common';
import useClientStore from '../../../store/useClientStore';
import NoDataErrorRetry from '../../NoDataRetry';
import { useRetryFetchFbAdInsights } from '../Hooks';

const CreativeAnalysis = () => {
  const { selectedClient } = useClientStore((state) => state);
  const { adInsights } = useMetaStore((state) => state);

  const clientCategory = selectedClient?.category_type || '';
  const isLeadGen = clientCategory === 'Lead Gen';

  const formatData = Object?.values(adInsights?.data || {})?.map(
    (item: any) => {
      return {
        image: item?.creatives ? item.creatives[0]?.thumbnail_url : 'NA',
        title: item?.ad_name,
        adSpend: formatToCurrency(item?.spend) || 0,
        revenue: isLeadGen
          ? formatNumber(item?.form_leads)
          : formatToCurrency(item?.revenue) || 0,
        roas: isLeadGen
          ? formatNumber(item?.website_leads)
          : formatNumber(item?.roas, true),
        impressions: formatNumber(item?.impressions) || 0,
        linkClicks: formatNumber(item?.link_clicks) || 0,
        ctr: formatNumber(item?.ctr) || 0,
        cpm: formatToCurrency(item?.cpm),
        cpc: formatToCurrency(item?.cpc),
      };
    }
  );

  const sortedByRoas = formatData
    .sort((a, b) => (Number(b.roas) || 0) - (Number(a.roas) || 0))
    .slice(0, 5);

  const sortedByCtr = formatData
    .sort((a, b) => (Number(b.ctr) || 0) - (Number(a.ctr) || 0))
    .slice(0, 5);

  const LoadingSkeletonTableCard = () => (
    <div
      role="status"
      className="w-full space-y-4  divide-y divide-gray-200 rounded  animate-pulse dark:divide-gray-700  dark:border-gray-700 mt-8"
    >
      <Card className="flex justify-center items-center">
        <Icon name="DefaultImage" size={180} />
      </Card>
      <div className="flex items-center justify-between">
        <div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
          <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
          <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
          <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
          <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
          <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
      </div>
      <span className="sr-only">Loading...</span>
    </div>
  );

  const retryFetchFbAdInsights = useRetryFetchFbAdInsights();

  return (
    <div>
      <div className="flex justify-start items-center mb-4">
        <h2 className="title-text text-base sm:text-[1.85rem] pb-1 hidden sm:block">
          Creative Analysis
        </h2>
      </div>
      <Card className="px-3 sm:px-6 shadow-none lg:shadow ring-0 lg:ring-1">
        <div className="flex justify-between">
          <div className="flex gap-2 items-center mb-8">
            <h2 className="text-sm text-[#001C44] dark:text-white font-bold">
              {`${isLeadGen ? 'Top Performing Creatives, by Form Leads' : 'Top Performing Creatives, by ROAS'}`}
            </h2>
            <InfoTooltip
              content={`Top Performing Creatives, by ROAS`}
              position="top"
            >
              <Icon name="InfoCircle" size={14} />
            </InfoTooltip>
          </div>
          <Tooltip
            content={`Ads ID: ${selectedClient?.facebook_ads?.ads_id}`}
            position="top"
            textAlign="text-center"
          >
            <div className="pt-1">
              <Icon name={`meta`} size={25} />
            </div>
          </Tooltip>
        </div>

        {adInsights.loading ? (
          <Grid
            numItemsSm={1}
            numItemsMd={3}
            numItemsLg={5}
            className="gap-8 overflow-y-visible"
          >
            <Col>
              <LoadingSkeletonTableCard />
            </Col>
            <Col>
              <LoadingSkeletonTableCard />
            </Col>
            <Col>
              <LoadingSkeletonTableCard />
            </Col>
            <Col>
              <LoadingSkeletonTableCard />
            </Col>
            <Col>
              <LoadingSkeletonTableCard />
            </Col>
          </Grid>
        ) : (
          <Grid
            numItemsSm={1}
            numItemsMd={3}
            numItemsLg={5}
            className="gap-8 overflow-y-visible"
          >
            {sortedByRoas?.length === 0 || !sortedByRoas ? (
              <Col numColSpan={5}>
                <Card className="mt-8 px-0 shadow-none lg:shadow ring-0 lg:ring-1">
                  <div className="flex flex-col items-center justify-center w-full items-center my-8 h-64">
                    <div className="w-fit">
                      {/* <NoDataError error={adInsights.error} /> */}
                      <NoDataErrorRetry
                        error={adInsights.error}
                        retry={adInsights.error && adInsights.error !== ''}
                        handleRetry={() => retryFetchFbAdInsights()}
                      />
                    </div>
                  </div>
                </Card>
              </Col>
            ) : (
              <>
                {sortedByRoas.map((data, index) => {
                  return (
                    <Col key={index}>
                      <p className="dark:text-white">{index + 1}</p>
                      {data.image && data.image !== 'NA' ? (
                        <img
                          src={data.image}
                          alt="ad"
                          width="100%"
                          className="object-contain rounded-xl mt-2"
                        />
                      ) : (
                        <Card className="flex justify-center items-center mt-2">
                          <Icon name="DefaultImage" size={180} />
                        </Card>
                      )}
                      {data?.title && (
                        <p className="pb-2 pt-4 text-sm truncate dark:text-white">
                          {data.title}
                        </p>
                      )}
                      {data?.adSpend && (
                        <div className="flex items-center justify-between py-2 text-[#405573] text-sm dark:text-white">
                          <p>Ad Spend</p>
                          <p>{data.adSpend}</p>
                        </div>
                      )}
                      {data?.revenue && (
                        <div className="flex items-center justify-between py-2 text-[#405573] text-sm dark:text-white">
                          <p>{`${isLeadGen ? 'Form Leads' : 'Revenue'} `}</p>
                          <p>{data.revenue}</p>
                        </div>
                      )}
                      {data?.roas && (
                        <div className="flex items-center justify-between py-2 text-[#405573] text-sm dark:text-white">
                          <p>{`${isLeadGen ? 'Website Leads' : 'ROAS'} `}</p>
                          <p>{data.roas}</p>
                        </div>
                      )}
                      {data?.impressions && (
                        <div className="flex items-center justify-between py-2 text-[#405573] text-sm dark:text-white">
                          <p>Impressions</p>
                          <p>{data.impressions}</p>
                        </div>
                      )}
                      {data?.linkClicks && (
                        <div className="flex items-center justify-between py-2 text-[#405573] text-sm dark:text-white">
                          <p>Link Clicks</p>
                          <p>{data.linkClicks}</p>
                        </div>
                      )}
                      {data?.ctr && (
                        <div className="flex items-center justify-between py-2 text-[#405573] text-sm dark:text-white">
                          <p>CTR</p>
                          <p>{data.ctr}%</p>
                        </div>
                      )}
                      {data?.cpm && (
                        <div className="flex items-center justify-between py-2 text-[#405573] text-sm dark:text-white">
                          <p>CPM</p>
                          <p>{data.cpm}</p>
                        </div>
                      )}
                      {data?.cpc && (
                        <div className="flex items-center justify-between py-2 text-[#405573] text-sm dark:text-white">
                          <p>CPC</p>
                          <p>{data.cpc}</p>
                        </div>
                      )}
                    </Col>
                  );
                })}
              </>
            )}
          </Grid>
        )}
      </Card>
      <Card className="mt-4 px-3 sm:px-6 shadow-none lg:shadow ring-0 lg:ring-1">
        <div className="flex justify-between">
          <div className="flex gap-2 items-center mb-8">
            <h2 className="text-sm text-[#001C44] dark:text-white font-bold">
              Top Performing Creatives, by CTR
            </h2>
            <InfoTooltip
              content={`Top Performing Creatives, by CTR`}
              position="top"
            >
              <Icon name="InfoCircle" size={14} />
            </InfoTooltip>
          </div>
          <Tooltip
            content={`Ads ID: ${selectedClient?.facebook_ads?.ads_id}`}
            position="top"
            textAlign="text-center"
          >
            <div className="pt-1">
              <Icon name={`meta`} size={25} />
            </div>
          </Tooltip>
        </div>

        {adInsights.loading ? (
          <Grid
            numItemsSm={1}
            numItemsMd={3}
            numItemsLg={5}
            className="gap-8 overflow-y-visible"
          >
            <Col>
              <LoadingSkeletonTableCard />
            </Col>
            <Col>
              <LoadingSkeletonTableCard />
            </Col>
            <Col>
              <LoadingSkeletonTableCard />
            </Col>
            <Col>
              <LoadingSkeletonTableCard />
            </Col>
            <Col>
              <LoadingSkeletonTableCard />
            </Col>
          </Grid>
        ) : (
          <Grid numItemsSm={1} numItemsMd={3} numItemsLg={5} className="gap-8">
            {sortedByCtr?.length === 0 || !sortedByCtr ? (
              <Col numColSpan={5}>
                <Card className="mt-8 px-0 shadow-none lg:shadow ring-0 lg:ring-1">
                  <div className="flex flex-col items-center justify-center w-full items-center my-8 h-64">
                    <div className="w-fit">
                      {/* <NoDataError error={adInsights.error} /> */}
                      <NoDataErrorRetry
                        error={adInsights.error}
                        retry={adInsights.error && adInsights.error !== ''}
                        handleRetry={() => retryFetchFbAdInsights()}
                      />
                    </div>
                  </div>
                </Card>
              </Col>
            ) : (
              <>
                {sortedByCtr.map((data, index) => (
                  <Col key={index}>
                    <p className="dark:text-white">{index + 1}</p>
                    {data.image && data.image !== 'NA' ? (
                      <img
                        src={data.image}
                        alt="ad"
                        width="100%"
                        className="object-contain rounded-xl mt-2"
                      />
                    ) : (
                      <Card className="flex justify-center items-center mt-2">
                        <Icon name="DefaultImage" size={180} />
                      </Card>
                    )}
                    <p className="pb-2 pt-4 text-sm truncate  dark:text-white">
                      {data.title}
                    </p>
                    <div className="flex items-center justify-between py-2 text-[#405573] text-sm dark:text-white">
                      <p>Ad Spend</p>
                      <p>{data.adSpend}</p>
                    </div>
                    {data.revenue && data.revenue !== '0' && (
                      <div className="flex items-center justify-between py-2 text-[#405573] text-sm dark:text-white">
                        <p>Revenue</p>
                        <p>{data.revenue}</p>
                      </div>
                    )}
                    <div className="flex items-center justify-between py-2 text-[#405573] text-sm dark:text-white">
                      <p>Impressions</p>
                      <p>{data.impressions}</p>
                    </div>
                    <div className="flex items-center justify-between py-2 text-[#405573] text-sm dark:text-white">
                      <p>Link Clicks</p>
                      <p>{data.linkClicks}</p>
                    </div>
                    <div className="flex items-center justify-between py-2 text-[#405573] text-sm dark:text-white">
                      <p>CTR</p>
                      <p>{data.ctr}</p>
                    </div>
                    <div className="flex items-center justify-between py-2 text-[#405573] text-sm dark:text-white">
                      <p>CPM</p>
                      <p>{data.cpm}</p>
                    </div>
                    <div className="flex items-center justify-between py-2 text-[#405573] text-sm dark:text-white">
                      <p>CPC</p>
                      <p>{data.cpc}</p>
                    </div>
                  </Col>
                ))}
              </>
            )}
          </Grid>
        )}
      </Card>
    </div>
  );
};

export default CreativeAnalysis;
