import React, { useEffect, useState } from 'react';
import Icon from '../../../assets/icons/SvgComponent';
import ReactSelect from 'react-select';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import Api from '../../../api';
interface WeeklyProps {
  closeModal: () => void;
  link?: any;
  status?: any;
  mismatch?: any;
  linkOptions?: any;
  statusOptions?: any;
  mismatchOptions?: any;
}

const ExportModal: React.FC<WeeklyProps> = ({
  closeModal,
  link,
  mismatch,
  status,
  linkOptions,
  statusOptions,
  mismatchOptions,
}) => {
  const [exportData, setExportData] = useState<any[]>([]);
  const [processedData, setProcessedData] = useState<any[]>([]);
  const [headers, setHeaders] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [downloadReady, setDownloadReady] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedStatus, setSelectedStatus] = useState<{
    label: string;
    value: string;
  } | null>(status ? status : statusOptions[0]);

  const [selectedLink, setSelectedLink] = useState<{
    label: string;
    value: string;
  } | null>(link ? link : linkOptions[0]);
  const [selectedMismatch, setSelectedMismatch] = useState<{
    label: string;
    value: string;
  } | null>(mismatch ? mismatch : mismatchOptions[0]);

  useEffect(() => {
    const flattenedData = exportData?.map(flattenObject); // Process each entry in exportData
    setProcessedData(flattenedData); // Assume you have a setState for processedData
  }, [exportData]);

  useEffect(() => {
    const flattenedData = exportData?.map((item) => flattenObject(item)); // Correctly process each entry
    setProcessedData(flattenedData); // Update the state with processed data
  }, [exportData]);

  useEffect(() => {
    if (processedData.length > 0) {
      // Generate headers from the keys of the first object in processedData
      const headersFromData = Object.keys(processedData[0]).map((key) => ({
        label: key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' '), // Optional: Format the key to capitalize the first letter
        key: key,
      }));
      setHeaders(headersFromData);
    }
  }, [processedData]);

  const fetchExportData = async () => {
    if (!loading) {
      setLoading(true);
      setDownloadReady(false);
      setErrorMessage('');

      const params = {
        status: selectedStatus?.value,
        brief_id: selectedLink?.value,
        mismatch: selectedMismatch?.value,
        page: 1,
        limit: 10000,
      };

      try {
        const res = await Api.LinkBrief.getSearchLinkItems(params);
        if (!res.error && res.data.length > 0) {
          setExportData(res.data);
          setDownloadReady(true);
        } else {
          setDownloadReady(false);
          setErrorMessage(
            'No data available to download. Please update the filters.'
          );
        }
      } catch (error) {
        console.error('An error occurred:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  function flattenObject(obj: any) {
    let flatObject = {
      domain: obj?.link_brief_item?.domain,
      am: obj?.link_brief_item?.account_manager,
      cons: obj?.link_brief_item?.seo_consultant,
      requestedAnchorText: obj?.requested_anchor_text1,
      requestedDeliveredText: obj?.delivered_anchor_text1,
      deliveredDr: obj?.delivered_dr,
      requestedDr: obj?.requested_dr,
      target: obj?.requested_backlink1,
      link: obj?.delivered_link,
      status: obj?.status,
    };
    return flatObject;
  }
  return (
    <div className="flex w-full h-full justify-center items-center">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="flex flex-col w-full h-[300px] sm:h-[350px] sm:w-[800px] rounded-lg bg-white p-5 dark:bg-gray-800"
      >
        <div className="flex justify-between items-center h-[20px] mb-4">
          <span>
            <Icon name="AlignLeft" />
          </span>
          <span onClick={closeModal} className="cursor-pointer text-lg">
            <Icon name="Xclose" />
          </span>
        </div>

        <div className="flex sm:justify-between flex-wrap sm:flex-nowrap gap-2">
          <div className="w-full">
            <ReactSelect
              options={Array.isArray(linkOptions) ? linkOptions : []}
              value={selectedLink}
              placeholder="All Links"
              onChange={(e) => {
                setSelectedLink(e);
                setDownloadReady(false);
              }}
              className="w-full"
            />
          </div>
          <div className="w-full">
            <ReactSelect
              options={Array.isArray(statusOptions) ? statusOptions : []}
              value={selectedStatus}
              placeholder="All Status"
              onChange={(e) => {
                setSelectedStatus(e);
                setDownloadReady(false);
              }}
              className="w-full"
            />
          </div>
          <div className="w-full">
            <ReactSelect
              options={Array.isArray(mismatchOptions) ? mismatchOptions : []}
              placeholder="All Services"
              value={selectedMismatch}
              onChange={(e) => {
                setSelectedMismatch(e);
                setDownloadReady(false);
              }}
              className="w-full"
            />
          </div>
        </div>

        <div className="flex gap-2 mt-4">
          {!downloadReady ? (
            <button
              className={`py-2 px-4 mt-4 bg-white hover:bg-blue-500 hover:text-white transition-opacity text-blue-500 border-blue-500 border rounded-md w-full text-center `}
              onClick={() => fetchExportData()}
            >
              {loading ? <Icon name="Loading" size={22} /> : 'Get Data'}
            </button>
          ) : (
            <CSVLink
              data={processedData}
              headers={headers}
              asyncOnClick={true}
              filename={`client-${moment(new Date()).format('YYYY-MM')}.csv`}
              className="py-2 px-4 mt-4 bg-blue-500 hover:bg-blue-600 text-white rounded-md w-full text-center"
            >
              {loading ? <Icon name="Loading" size={22} /> : 'Export'}
            </CSVLink>
          )}
        </div>
        {errorMessage.length > 0 && (
          <p className="text-red-500 mt-4 text-center">{errorMessage}</p>
        )}
      </div>
    </div>
  );
};

export default ExportModal;
