import React from 'react';

interface IProps {
  color?: string;
  size?: number;
}

const ChangeHistory: React.FC<IProps> = ({ color = '#405573', size = 18 }) => (
  <svg
    width={size + 2}
    height={size}
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.5 5C9.5 4.125 9 3.3125 8.25 2.84375C7.46875 2.40625 6.5 2.40625 5.75 2.84375C4.96875 3.3125 4.5 4.125 4.5 5C4.5 5.90625 4.96875 6.71875 5.75 7.1875C6.5 7.625 7.46875 7.625 8.25 7.1875C9 6.71875 9.5 5.90625 9.5 5ZM3 5C3 3.59375 3.75 2.28125 5 1.5625C6.21875 0.84375 7.75 0.84375 9 1.5625C10.2188 2.28125 11 3.59375 11 5C11 6.4375 10.2188 7.75 9 8.46875C7.75 9.1875 6.21875 9.1875 5 8.46875C3.75 7.75 3 6.4375 3 5ZM1.53125 15.5H10.875C11.25 16.0938 11.75 16.625 12.3125 17H0.90625C0.40625 17 0 16.5938 0 16.0938C0 13 2.46875 10.5 5.5625 10.5H8.40625C9.0625 10.5 9.6875 10.625 10.25 10.8125C10.0938 11.3125 10 11.8125 10 12.3438C9.5 12.125 8.96875 12 8.40625 12H5.5625C3.5 12 1.8125 13.5312 1.53125 15.5ZM11 12.5C11 10.9062 11.8438 9.4375 13.25 8.625C14.625 7.8125 16.3438 7.8125 17.75 8.625C19.125 9.4375 20 10.9062 20 12.5C20 14.125 19.125 15.5938 17.75 16.4062C16.3438 17.2188 14.625 17.2188 13.25 16.4062C11.8438 15.5938 11 14.125 11 12.5ZM15.5 10C15.2188 10 15 10.25 15 10.5V12.5C15 12.7812 15.2188 13 15.5 13H17C17.25 13 17.5 12.7812 17.5 12.5C17.5 12.25 17.25 12 17 12H16V10.5C16 10.25 15.75 10 15.5 10Z"
      fill={color}
    />
  </svg>
);

export default ChangeHistory;