import React, { useState, useEffect } from 'react';
import { LineChart } from '@tremor/react';
import InfoTooltip from '../AnalyticsInfoTooltip';
import Icon from '../../../assets/icons/SvgComponent';
import TrendTxt from '../../Common/TrendTxt';
import TrendTooltip from '../TrendTooltip';
import { formatNumber, formatToCurrency } from '../../../utils/common';
import NoDataError from '../../NoDataError';
import Tooltip from '../../HelpTooltip';
import NoDataErrorRetry from '../../NoDataRetry';

interface Props {
  name?: string;
  data?: any;
  categories?: string[];
  showLegend?: boolean;
  color?: string[];
  info?: string;
  prevPeriod?: string;
  preYear?: string;
  hasDollar?: boolean;
  hasPercent?: boolean;
  loading?: boolean;
  error?: string;
  icon?: string;
  propertyId?: string;
  retryMethod?: () => void;
}

const LineChartCard: React.FC<Props> = ({
  name,
  data,
  categories,
  showLegend,
  color,
  info,
  prevPeriod,
  preYear,
  hasDollar,
  hasPercent,
  loading,
  error,
  icon,
  propertyId,
  retryMethod,
}) => {
  const dataFormatter = (
    number: number | string,
    hasDollar?: boolean,
    hasPercent?: boolean
  ) => {
    return hasDollar
      ? formatToCurrency(number)
      : formatNumber(number, hasPercent);
  };

  // Initialize checkedCategories from local storage or default to all categories checked
  const [checkedCategories, setCheckedCategories] = useState(() => {
    const savedCategories = localStorage.getItem('checkedCategories');
    if (savedCategories) {
      return JSON.parse(savedCategories);
    }
    return categories.reduce((acc, category) => {
      acc[category] = true;
      return acc;
    }, {});
  });

  // Update local storage whenever checkedCategories changes
  useEffect(() => {
    localStorage.setItem(
      'checkedCategories',
      JSON.stringify(checkedCategories)
    );
  }, [checkedCategories]);

  const handleCheckboxChange = (category) => {
    setCheckedCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  const filteredCategories = categories.filter(
    (category) => checkedCategories[category]
  );

  const filteredColors = color
    ? filteredCategories.map((category) => color[categories.indexOf(category)])
    : ['#FFC265', '#7DCEFF', '#019AA3', '#00199D', '#002943'];

  if (loading) {
    return (
      <div
        role="status"
        className="w-full p-4  rounded  animate-pulse dark:border-gray-700"
      >
        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2.5"></div>
        <div className="w-48 h-2 mb-10 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        <div className="flex items-baseline mt-4">
          <div className="w-full bg-gray-200 rounded-t-lg h-44 dark:bg-gray-700"></div>
          <div className="w-full h-40 ms-6 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
          <div className="w-full bg-gray-200 rounded-t-lg h-44 ms-6 dark:bg-gray-700"></div>
          <div className="w-full h-44 ms-6 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
          <div className="w-full bg-gray-200 rounded-t-lg h-48 ms-6 dark:bg-gray-700"></div>
          <div className="w-full bg-gray-200 rounded-t-lg h-44 ms-6 dark:bg-gray-700"></div>
          <div className="w-full bg-gray-200 rounded-t-lg h-48 ms-6 dark:bg-gray-700"></div>
        </div>
        <span className="sr-only">Loading...</span>
      </div>
    );
  } else {
    return (
      <div className="w-full">
        <div className="flex items-center justify-between">
          <div className="flex gap-2 items-center mb-1 w-full">
            <h2 className="text-[14px] text-[#001C44] font-semibold dark:text-white">
              {name}
            </h2>
            <InfoTooltip content={info} position="top">
              <Icon name="InfoCircle" size={14} />
            </InfoTooltip>
          </div>
          <Tooltip
            content={propertyId ? propertyId : 'N/A'}
            position="top"
            textAlign="text-center"
          >
            <div className="pt-1">
              <Icon name={`${icon}`} size={15} />
            </div>
          </Tooltip>
        </div>
        {data?.length === 0 || !data ? (
          <div className="flex flex-col items-center justify-center w-full my-8 h-64">
            {/* <NoDataError /> */}
            <NoDataErrorRetry
              error={error}
              retry={error && error !== '' && typeof retryMethod === 'function'}
              handleRetry={() => retryMethod()}
            />
          </div>
        ) : (
          <>
            {prevPeriod && preYear && (
              <div className="flex gap-4  dark:border-dark-tremor-border w-full my-2">
                <div className="">
                  <TrendTooltip percentage={prevPeriod}>
                    <TrendTxt
                      type="period"
                      title="Prev Period"
                      size="small"
                      value={prevPeriod}
                    />
                  </TrendTooltip>
                </div>
                <div className="">
                  <TrendTooltip percentage={preYear}>
                    <TrendTxt
                      type="year"
                      title="Prev Year"
                      size="small"
                      value={preYear}
                    />
                  </TrendTooltip>
                </div>
              </div>
            )}

            <div className="flex gap-4 justify-end items-center my-2">
              {categories.map((category, index) => (
                <label
                  key={category}
                  className="flex items-center gap-2 text-xs font-bold dark:text-white"
                >
                  <input
                    type="checkbox"
                    checked={checkedCategories[category]}
                    onChange={() => handleCheckboxChange(category)}
                    className="styled-checkbox"
                    style={{
                      backgroundColor: checkedCategories[category]
                        ? filteredColors[categories.indexOf(category)]
                        : 'transparent',
                    }}
                  />
                  <span>{category}</span>
                </label>
              ))}
            </div>

            <div className="h-full mt-10">
              <LineChart
                className="h-[17rem]"
                data={data ? data : []}
                index="date"
                categories={filteredCategories}
                colors={filteredColors}
                showLegend={false}
                valueFormatter={(value) =>
                  dataFormatter(value, hasDollar, hasPercent)
                }
                showAnimation={true}
                yAxisWidth={35}
              />
            </div>
          </>
        )}
      </div>
    );
  }
};

export default LineChartCard;
