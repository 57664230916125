import Icon from '../../assets/icons/SvgComponent';
import { Link } from 'react-router-dom';
import Tooltip from '../HelpTooltip';

interface IProps {
  selectedClient: any;
  requestGoogleSEOAccess: () => void;
  requestGoogleAdsAccess: () => void;
  requestMetaAccess: () => void;
}

const StrategyDocumentCard: React.FC<IProps> = ({
  selectedClient,
  requestGoogleSEOAccess,
  requestGoogleAdsAccess,
  requestMetaAccess,
}) => {
  const googleSeoLink =
    selectedClient?.seo_google_report_config?.strategy ||
    selectedClient?.seo_report_config?.strategy;

  const googleAdsLink = undefined;
  const metaLink = undefined;

  const isGoogleSeo =
    selectedClient?.seo_google?.status === 'inactive' ? false : true;

  const isGoogleAds =
    selectedClient?.ppc_google?.status === 'inactive' ? false : true;

  const isMeta =
    selectedClient?.social_meta?.status === 'inactive' ? false : true;

  return (
    <div className="rounded-xl bg-[#001C44] dark:bg-[#222732] block sm:flex flex-col justify-start gap-2 lg:gap-4 p-3 sm:px-6 py-3 w-full">
      <div className="mb-4 sm:my-0 sm:flex flex-col gap-[7px]">
        <h2 className="text-sm sm:text-2xl font-bold font-archivo mb-2 lg:mb-1 sm:mb-0 text-white">
          Your Current OMG Strategy Document
        </h2>
        <p className="text-gray-200 text-xs sm:text-sm">
          Access Your Strategy Documents Below: Click to View
        </p>
      </div>
      {/* naviga to a blank page */}
      <div className="flex justify-between gap-[8px] sm:gap-2 items-center py-2">
        {!googleSeoLink ? (
          <div className="w-full">
            <Tooltip
              content={
                isGoogleSeo
                  ? `No document has been uploaded as of now.`
                  : `You don't have this service. Click to request.`
              }
              position="top"
              customBgColor="bg-[#fff] text-[#000]"
            >
              <button
                className={`flex justify-between sm:my-0 bg-[#FFB545] text-sm lg:text-md px-1 lg:px-4   py-1  sm:mb-0 sm:py-2 font-[700] w-full rounded ${googleSeoLink ? 'cursor-pointer opacity-100' : 'opacity-50'}`}
                onClick={() => {
                  if (!isGoogleSeo) {
                    requestGoogleSEOAccess();
                  } else {
                    return;
                  }
                }}
              >
                <div className={`text-[10px] sm:text-sm`}>Google SEO</div>
                <div className="border-l border-[#001C44] pl-1 lg:pl-4 flex justify-center items-center">
                  <Icon name="Eye" color="#000" />
                </div>
              </button>
            </Tooltip>
          </div>
        ) : (
          <div className="w-full">
            <Link
              target="_blank"
              to={googleSeoLink}
              className={`flex justify-between sm:my-0 bg-[#FFB545] text-sm lg:text-md px-1 lg:px-4   py-1  sm:mb-0 sm:py-2 font-[700] w-full rounded ${googleSeoLink ? 'cursor-pointer opacity-100' : 'opacity-50'}`}
            >
              <div className={`text-[10px] sm:text-sm`}>Google SEO</div>
              <div className="border-l border-[#001C44] pl-1 lg:pl-4 flex justify-center items-center">
                <Icon name="Eye" color="#000" />
              </div>
            </Link>
          </div>
        )}
        {!googleAdsLink ? (
          <div className="w-full">
            <Tooltip
              content={
                isGoogleAds
                  ? `No document has been uploaded as of now.`
                  : `You don't have this service. Click to request.`
              }
              position="top"
              customBgColor="bg-[#fff] text-[#000]"
            >
              <button
                className={`flex justify-between sm:my-0 bg-[#FFB545] text-sm lg:text-md px-1 lg:px-4   py-1  sm:mb-0 sm:py-2 font-[700] w-full rounded ${googleAdsLink ? 'cursor-pointer opacity-100' : 'opacity-50'}`}
                onClick={() => {
                  if (!isGoogleAds) {
                    requestGoogleAdsAccess();
                  } else {
                    return;
                  }
                }}
              >
                <div className={`text-[10px] sm:text-sm`}>Google Ads</div>
                <div className="border-l border-[#001C44] pl-1 lg:pl-4 flex justify-center items-center">
                  <Icon name="Eye" color="#000" />
                </div>
              </button>
            </Tooltip>
          </div>
        ) : (
          <div className="w-full">
            <Link
              target="_blank"
              to={googleAdsLink}
              className={`flex justify-between sm:my-0 bg-[#FFB545] text-sm lg:text-md px-1 lg:px-4   py-1  sm:mb-0 sm:py-2 font-[700] w-full rounded ${googleAdsLink ? 'cursor-pointer opacity-100' : 'cursor-not-allowed opacity-50'}`}
            >
              <div className={`text-[10px] sm:text-sm`}>Google Ads</div>
              <div className="border-l border-[#001C44] pl-1 lg:pl-4 flex justify-center items-center">
                <Icon name="Eye" color="#000" />
              </div>
            </Link>
          </div>
        )}
        {!metaLink ? (
          <div className="w-full">
            <Tooltip
              content={
                isMeta
                  ? `No document has been uploaded as of now.`
                  : `You don't have this service. Click to request.`
              }
              position="top"
              customBgColor="bg-[#fff] text-[#000]"
            >
              <div
                className={`flex justify-between items-center sm:my-0 bg-[#FFB545] text-sm lg:text-md px-1 lg:px-4  py-1  sm:mb-0 sm:py-2 font-[700] w-full rounded ${metaLink ? 'cursor-pointer opacity-100' : 'opacity-50'}`}
                onClick={() => {
                  if (!isMeta) {
                    requestMetaAccess();
                  } else {
                    return;
                  }
                }}
              >
                <div className={`text-[10px] sm:text-sm`}>Meta</div>
                <div className="border-l border-[#001C44] pl-1 lg:pl-4 flex justify-center items-center">
                  <Icon name="Eye" color="#000" />
                </div>
              </div>
            </Tooltip>
          </div>
        ) : (
          <Link
            target="_blank"
            to={metaLink}
            className={`flex justify-between sm:my-0 bg-[#FFB545] text-sm lg:text-md px-1 lg:px-4   py-1  sm:mb-0 sm:py-2 font-[700] w-full rounded ${metaLink ? 'cursor-pointer opacity-100' : ' opacity-50'}`}
          >
            <div className={`text-[10px] sm:text-sm`}>Meta</div>
            <div className="border-l border-[#001C44] pl-1 lg:pl-4 flex justify-center items-center">
              <Icon name="Eye" color="#000" />
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};

export default StrategyDocumentCard;
