import React from 'react';

interface Props {
  color?: string;
  size?: number;
}

const Location: React.FC<Props> = ({ color = 'black', size = 80 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="15"
    viewBox="0 0 13 15"
    fill="none"
  >
    <path
      d="M6.33333 7.99935C7.4379 7.99935 8.33333 7.10392 8.33333 5.99935C8.33333 4.89478 7.4379 3.99935 6.33333 3.99935C5.22876 3.99935 4.33333 4.89478 4.33333 5.99935C4.33333 7.10392 5.22876 7.99935 6.33333 7.99935Z"
      stroke="white"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.33333 13.9993C9 11.3327 11.6667 8.94487 11.6667 5.99935C11.6667 3.05383 9.27885 0.666016 6.33333 0.666016C3.38781 0.666016 1 3.05383 1 5.99935C1 8.94487 3.66667 11.3327 6.33333 13.9993Z"
      stroke="white"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Location;
