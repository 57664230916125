import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const OutlineGoogleSEO: React.FC<IconProps> = ({
  color = 'black',
  size = 80,
}) => (
  <svg
    width="53"
    height="53"
    viewBox="0 0 53 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g filter="url(#filter0_d_155_24731)">
      <mask
        id="mask0_155_24731"
        maskUnits="userSpaceOnUse"
        x="4"
        y="2"
        width="45"
        height="45"
      >
        <path
          d="M44.4157 16.8312H40.6094C40.4688 16.8312 40.3469 16.8594 40.2157 16.8687L42.2125 14.8812C43.0844 14 43.5625 12.8375 43.5532 11.5906C43.5344 10.3531 43.0375 9.19063 42.1469 8.32812C37.9282 4.25 32.3782 2 26.5 2C23.0875 2 19.8063 2.75 16.7407 4.22187C16.3 4.4375 15.9063 4.7375 15.5688 5.075C15.1 5.1875 14.6407 5.375 14.2188 5.64688C7.84378 9.80938 4.01878 16.8406 4.00003 24.4531C3.98128 36.8 14.0032 46.9156 26.3688 47H26.5C37.3282 47 46.6282 39.2844 48.6157 28.6625C48.8782 27.3031 49 25.9062 49 24.5V21.425C49 18.8937 46.9469 16.8312 44.4157 16.8312Z"
          fill="white"
        />
      </mask>
      <path
        d="M26.5 43.2499H26.3688C16.0844 43.1749 7.73128 34.7468 7.75003 24.4531C7.76878 18.1062 10.9469 12.2562 16.2719 8.77805C16.525 8.6093 16.8719 8.6843 17.0313 8.93743C17.2 9.19055 17.125 9.52805 16.8719 9.6968C11.8657 12.9687 8.86566 18.4812 8.85628 24.4531C8.83753 34.1374 16.6938 42.0781 26.3875 42.1437H26.5094C35.0125 42.1437 42.2969 36.1156 43.8625 27.7624C44.0594 26.6937 44.1625 25.5968 44.1625 24.4906V21.6687H40.6094C40.3094 21.6687 40.0563 21.4249 40.0563 21.1156C40.0563 20.8062 40.3 20.5624 40.6094 20.5624H44.4157C44.8844 20.5624 45.2594 20.9374 45.2594 21.4062V24.4812C45.2594 25.6531 45.1469 26.8156 44.9407 27.9499C43.2813 36.8187 35.5375 43.2312 26.5094 43.2312L26.5 43.2499Z"
        fill="#001C44"
      />
      <path
        d="M26.4997 36.5086C23.8654 36.5086 21.3622 35.6742 19.2716 34.0898C19.0279 33.9023 18.981 33.5648 19.1685 33.3211C19.3466 33.0773 19.6935 33.0305 19.9372 33.218C21.8404 34.6523 24.1091 35.4211 26.5091 35.4211C31.0841 35.4211 35.0872 32.6367 36.6997 28.4273H26.7997C26.331 28.4273 25.956 28.0523 25.956 27.5836V21.4336C25.956 20.9648 26.331 20.5898 26.7997 20.5898H38.2279C38.5279 20.5898 38.781 20.8336 38.781 21.143C38.781 21.4523 38.5372 21.6961 38.2279 21.6961H27.0529V27.3398H37.4591C37.6372 27.3398 37.7966 27.4242 37.8997 27.5648C38.0029 27.7055 38.031 27.8836 37.9841 28.0523C36.4279 33.1242 31.8154 36.5367 26.4997 36.5367V36.5086Z"
        fill="#001C44"
      />
      <path
        d="M17.8833 32.6078C17.7333 32.6078 17.5833 32.5422 17.4708 32.4203C15.5301 30.2078 14.4708 27.3672 14.4895 24.4141C14.5364 17.8328 19.927 12.4891 26.4989 12.4891C29.5083 12.4891 32.377 13.6047 34.5989 15.6297L38.5926 11.6359C35.302 8.54219 31.027 6.84531 26.5083 6.84531C23.827 6.84531 21.2489 7.42656 18.8583 8.58906C18.5864 8.72031 18.2583 8.60781 18.127 8.33594C17.9958 8.06406 18.1083 7.73594 18.3801 7.60469C20.9301 6.37656 23.6676 5.75781 26.5083 5.75781C31.402 5.75781 36.0333 7.63281 39.5489 11.0359C39.7176 11.1953 39.802 11.4109 39.8114 11.6359C39.8114 11.8609 39.727 12.0766 39.5676 12.2359L35.2176 16.5859C34.8895 16.9141 34.3739 16.9141 34.0458 16.6047C32.0114 14.6641 29.3395 13.5953 26.527 13.5953C20.5458 13.5953 15.652 18.4609 15.6145 24.4328C15.5958 27.1141 16.5614 29.6922 18.3239 31.7078C18.5208 31.9328 18.502 32.2797 18.277 32.4859C18.1739 32.5797 18.0426 32.6266 17.9114 32.6266L17.8833 32.6078Z"
        fill="#001C44"
      />
      <path
        d="M16.0283 20.2068C15.9158 20.2068 15.8033 20.1693 15.7095 20.1037L10.272 16.1756C10.0283 15.9974 9.97204 15.6506 10.1502 15.4068C10.3283 15.1631 10.6752 15.1068 10.9189 15.2849L16.3564 19.2131C16.6002 19.3912 16.6564 19.7381 16.4783 19.9818C16.3752 20.1318 16.2064 20.2068 16.0283 20.2068Z"
        fill="#001C44"
      />
      <path
        d="M10.2331 33.1814C10.055 33.1814 9.88627 33.097 9.78314 32.947C9.61439 32.6939 9.68002 32.3564 9.92377 32.1877L15.5113 28.3533C15.7644 28.1845 16.1019 28.2408 16.2706 28.4939C16.4394 28.747 16.3738 29.0845 16.13 29.2533L10.5425 33.0877C10.4488 33.1533 10.3363 33.1814 10.2331 33.1814Z"
        fill="#001C44"
      />
      <path
        d="M39.8318 37.4C39.7193 37.4 39.6068 37.3625 39.513 37.2968L34.0286 33.3406C33.7849 33.1625 33.7286 32.8156 33.9068 32.5718C34.0849 32.3281 34.4318 32.2718 34.6755 32.45L40.1599 36.4062C40.4036 36.5843 40.4599 36.9312 40.2818 37.175C40.1786 37.325 40.0099 37.4 39.8318 37.4Z"
        fill="#001C44"
      />
    </g>
  </svg>
);

export default OutlineGoogleSEO;
