import React from 'react';
import { BarChart } from '@tremor/react';
import InfoTooltip from '../AnalyticsInfoTooltip';
import Icon from '../../../assets/icons/SvgComponent';
import TrendTxt from '../../Common/TrendTxt';
import TrendTooltip from '../TrendTooltip';
import { formatNumber, formatToCurrency } from '../../../utils/common';
import NoDataError from '../../NoDataError';
import Tooltip from '../../HelpTooltip';
import NoDataErrorRetry from '../../NoDataRetry';

interface Props {
  name?: string;
  data?: any;
  categories?: string[];
  showLegend?: boolean;
  color?: string[];
  info?: string;
  prevPeriod?: string;
  preYear?: string;
  hasDollar?: boolean;
  hasPercent?: boolean;
  loading?: boolean;
  error?: string;
  noAccessSubtitle?: string;
  icon?: string;
  propertyId?: string;
  retryMethod?: () => void;
}
type CustomTooltipTypeBar = {
  payload: any;
  active: boolean | undefined;
  label: any;
};

const BarChartCard: React.FC<Props> = ({
  name,
  data,
  categories,
  showLegend,
  color,
  info,
  prevPeriod,
  preYear,
  hasDollar,
  hasPercent,
  loading,
  error,
  noAccessSubtitle,
  icon,
  propertyId,
  retryMethod
}) => {
  const dataFormatter = (
    number: number | string,
    hasDollar?: boolean,
    hasPercent?: boolean
  ) => {
    return hasDollar
      ? formatToCurrency(number)
      : formatNumber(number, hasPercent);
  };

  const customTooltip = (props: CustomTooltipTypeBar) => {
    const { payload, active, label } = props;
    if (!active || !payload) return null;
    return (
      <div className="w-fit rounded-tremor-default border border-tremor-border bg-tremor-background p-2 text-tremor-default shadow-tremor-dropdown">
        <div className="flex flex-1 space-x-2.5">
          <div className="w-full">
            <p className="mb-2 font-medium text-tremor-content-emphasis">
              {label}
            </p>
            {payload.map((payloadItem: any, index: number) => {
              return (
                <div
                  key={index}
                  className="flex items-center justify-between space-x-6"
                >
                  <span className="text-tremor-content flex gap-2 items-center">
                    {index === 0 && (
                      <div className="bg-[#92D6FF] w-2 h-2 rounded-full" />
                    )}
                    {index === 1 && (
                      <div className="bg-[#0084D5] w-2 h-2 rounded-full" />
                    )}
                    {index === 2 && (
                      <div className="bg-[#0029FF] w-2 h-2 rounded-full" />
                    )}
                    {index === 3 && (
                      <div className="bg-[#01F0FF] w-2 h-2 rounded-full" />
                    )}
                    {index === 4 && (
                      <div className="bg-[#E478FF] w-2 h-2 rounded-full" />
                    )}

                    {payloadItem.name}
                  </span>
                  {payloadItem.name === 'Spend' ||
                  payloadItem.name === 'Revenue' ||
                  payloadItem.name === 'Ad Spend' ||
                  payloadItem.name === 'CPL Website Leads' ? (
                    <span className="font-medium tabular-nums text-tremor-content-emphasis">
                      {isNaN(payloadItem.value)
                        ? 0
                        : formatToCurrency(payloadItem.value)}
                    </span>
                  ) : (
                    <span className="font-medium tabular-nums text-tremor-content-emphasis">
                      {isNaN(payloadItem.value)
                        ? 0
                        : formatNumber(payloadItem.value, hasPercent)}
                    </span>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div
        role="status"
        className="w-full p-4  rounded  animate-pulse dark:border-gray-700"
      >
        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2.5"></div>
        <div className="w-48 h-2 mb-10 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        <div className="flex items-baseline mt-4">
          <div className="w-full bg-gray-200 rounded-t-lg h-44 dark:bg-gray-700"></div>
          <div className="w-full h-40 ms-6 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
          <div className="w-full bg-gray-200 rounded-t-lg h-44 ms-6 dark:bg-gray-700"></div>
          <div className="w-full h-44 ms-6 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
          <div className="w-full bg-gray-200 rounded-t-lg h-48 ms-6 dark:bg-gray-700"></div>
          <div className="w-full bg-gray-200 rounded-t-lg h-44 ms-6 dark:bg-gray-700"></div>
          <div className="w-full bg-gray-200 rounded-t-lg h-48 ms-6 dark:bg-gray-700"></div>
        </div>
        <span className="sr-only">Loading...</span>
      </div>
    );
  } else {
    return (
      <div className="w-full">
        <div className="flex justify-between gap-8">
          <div className="flex sm:gap-2 items-center mb-1 w-full">
            <h2 className="text-[14px] text-[#001C44] font-semibold dark:text-white">
              {name}
            </h2>
            <InfoTooltip content={info} position="top">
              <Icon name="InfoCircle" size={14} />
            </InfoTooltip>
          </div>
          <Tooltip
            content={propertyId ? propertyId : 'N/A'}
            position="top"
            textAlign="text-center"
          >
            <div className="pt-1">
              <Icon name={`${icon}`} size={25} />
            </div>
          </Tooltip>
        </div>
        {data?.length === 0 || !data ? (
          <div className="flex flex-col items-center justify-center w-full my-8 h-64">
            <NoDataErrorRetry 
              error={error}
              retry={error && error !== '' && typeof retryMethod === 'function'}
              handleRetry={() => retryMethod()}
              />
          </div>
        ) : (
          <>
            {prevPeriod && preYear && (
              <div className="flex gap-4  dark:border-dark-tremor-border w-full my-2">
                <div className="">
                  <TrendTooltip percentage={prevPeriod}>
                    <TrendTxt
                      type="period"
                      title="Prev Period"
                      size="small"
                      value={prevPeriod}
                    />
                  </TrendTooltip>
                </div>
                <div className="">
                  <TrendTooltip percentage={preYear}>
                    <TrendTxt
                      type="year"
                      title="Prev Year"
                      size="small"
                      value={preYear}
                    />
                  </TrendTooltip>
                </div>
              </div>
            )}
            <div className="h-full mt-10">
              <BarChart
                className="h-[17rem]"
                data={data ? data : []}
                stack={true}
                showLegend={showLegend}
                index="date"
                categories={
                  categories
                    ? categories
                    : ['Top 3', '4-10', '11-20', '21-50', 'SERP Features']
                }
                colors={
                  color
                    ? color
                    : ['#FFC265', '#7DCEFF', '#019AA3', '#00199D', '#002943']
                }
                yAxisWidth={60}
                valueFormatter={(value) =>
                  dataFormatter(value, hasDollar, hasPercent)
                }
                customTooltip={customTooltip}
              />
            </div>
          </>
        )}
      </div>
    );
  }
};

export default BarChartCard;
