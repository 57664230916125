import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const OutlineRemarketing: React.FC<IconProps> = ({
  color = 'black',
  size = 80,
}) => (
  <svg
    width="53"
    height="48"
    viewBox="0 0 53 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g filter="url(#filter0_d_163_25819)">
      <path
        d="M41.002 11.4611C42.577 11.4611 43.8595 10.1786 43.8595 8.60359C43.8595 7.02859 42.577 5.74609 41.002 5.74609C39.427 5.74609 38.1445 7.02859 38.1445 8.60359C38.1445 10.1786 39.427 11.4611 41.002 11.4611ZM41.002 6.84859C41.9695 6.84859 42.7608 7.63609 42.7608 8.60734C42.7608 9.57859 41.9733 10.3661 41.002 10.3661C40.0308 10.3661 39.2433 9.57859 39.2433 8.60734C39.2433 7.63609 40.0308 6.84859 41.002 6.84859Z"
        fill="black"
      />
      <path
        d="M40.998 12.2186H40.9642C38.6505 12.2186 36.7717 14.1011 36.7717 16.4111V16.7373C36.7717 17.7723 37.6117 18.6123 38.6467 18.6123H43.3192C44.3655 18.6123 45.2505 17.7348 45.2505 16.6961V16.4036C45.2505 14.0936 43.3455 12.2148 41.0017 12.2148L40.998 12.2186ZM44.148 16.6998C44.148 17.1273 43.7505 17.5173 43.3155 17.5173H38.643C38.2155 17.5173 37.8667 17.1686 37.8667 16.7411V16.4148C37.8667 14.7086 39.2542 13.3211 40.9605 13.3211H40.9942C42.7305 13.3211 44.1442 14.7048 44.1442 16.4111V16.7036L44.148 16.6998Z"
        fill="black"
      />
      <path
        d="M43.8559 27.9425C43.8559 26.3675 42.5734 25.085 40.9984 25.085C39.4234 25.085 38.1409 26.3675 38.1409 27.9425C38.1409 29.5175 39.4234 30.8 40.9984 30.8C42.5734 30.8 43.8559 29.5175 43.8559 27.9425ZM39.2434 27.9425C39.2434 26.975 40.0309 26.1837 41.0021 26.1837C41.9734 26.1837 42.7609 26.9712 42.7609 27.9425C42.7609 28.9137 41.9734 29.7012 41.0021 29.7012C40.0309 29.7012 39.2434 28.9137 39.2434 27.9425Z"
        fill="black"
      />
      <path
        d="M40.998 31.5526H40.9642C38.6505 31.5526 36.7717 33.4351 36.7717 35.7451V36.0713C36.7717 37.1063 37.6117 37.9463 38.6467 37.9463H43.3192C44.3655 37.9463 45.2505 37.0688 45.2505 36.0301V35.7376C45.2505 33.4276 43.3455 31.5488 41.0017 31.5488L40.998 31.5526ZM44.148 36.0338C44.148 36.4613 43.7505 36.8513 43.3155 36.8513H38.643C38.2155 36.8513 37.8667 36.5026 37.8667 36.0751V35.7488C37.8667 34.0426 39.2542 32.6551 40.9605 32.6551H40.9942C42.7305 32.6551 44.1442 34.0388 44.1442 35.7451V36.0376L44.148 36.0338Z"
        fill="black"
      />
      <path
        d="M28.9533 7.96875H21.6296C17.9283 7.96875 14.4408 9.41625 11.8158 12.0412C9.19457 14.6662 7.75082 18.1462 7.74707 21.8475C7.74707 21.8475 7.74707 21.8475 7.74707 21.855C7.74707 25.8975 9.55457 29.745 12.7046 32.4037C12.9333 32.5987 13.2821 32.5725 13.4771 32.34C13.6721 32.1075 13.6421 31.7625 13.4133 31.5675C10.6533 29.235 9.01457 25.92 8.86082 22.4025H15.0971C15.3783 25.7625 18.1983 28.41 21.6296 28.41H23.9358V34.635H21.7458C19.5521 34.635 17.3771 34.065 15.4533 32.985C15.1871 32.835 14.8533 32.9287 14.7071 33.195C14.5571 33.4612 14.6508 33.795 14.9171 33.9412C17.0058 35.115 19.3646 35.7337 21.7458 35.7337H28.9533C29.2571 35.7337 29.5008 35.4862 29.5008 35.1862V27.8625C29.5008 27.5588 29.2533 27.315 28.9533 27.315H21.6296C18.6221 27.315 16.1733 24.8662 16.1733 21.8587C16.1733 20.4975 16.6796 19.1925 17.5946 18.1837C17.7971 17.9587 17.7821 17.6137 17.5571 17.4075C17.3321 17.205 16.9871 17.22 16.7808 17.445C15.8021 18.5175 15.2171 19.875 15.0971 21.3075H8.86082C8.99582 18.105 10.3083 15.1125 12.5958 12.825C15.0146 10.4063 18.2208 9.075 21.6296 9.075H23.9358V15.3H21.6296C20.7446 15.3 19.8858 15.4725 19.0796 15.8137C18.8021 15.9337 18.6708 16.2525 18.7871 16.5337C18.9071 16.815 19.2258 16.9425 19.5071 16.8262C20.1783 16.5412 20.8946 16.3987 21.6296 16.3987H28.9533C29.2571 16.3987 29.5008 16.1512 29.5008 15.8512V8.5275C29.5008 8.22375 29.2533 7.98 28.9533 7.98V7.96875ZM25.0346 28.4025H28.4021V34.6275H25.0346V28.4025ZM28.4021 15.2925H25.0346V9.0675H28.4021V15.2925Z"
        fill="black"
      />
      <path
        d="M31.0758 9.06961H35.0321C35.3358 9.06961 35.5796 8.82211 35.5796 8.52211C35.5796 8.22211 35.3321 7.97461 35.0321 7.97461H31.0758C30.7721 7.97461 30.5283 8.22211 30.5283 8.52211C30.5283 8.82211 30.7758 9.06961 31.0758 9.06961Z"
        fill="black"
      />
      <path
        d="M30.6743 15.8434C30.6743 16.1471 30.9218 16.3909 31.2218 16.3909H34.2968C34.6006 16.3909 34.8443 16.1434 34.8443 15.8434C34.8443 15.5434 34.5968 15.2959 34.2968 15.2959H31.2218C30.9181 15.2959 30.6743 15.5434 30.6743 15.8434Z"
        fill="black"
      />
      <path
        d="M34.7395 12.1813C34.7395 12.485 34.987 12.7288 35.287 12.7288H36.862C37.1658 12.7288 37.4095 12.4813 37.4095 12.1813C37.4095 11.8813 37.162 11.6338 36.862 11.6338H35.287C34.9833 11.6338 34.7395 11.8813 34.7395 12.1813Z"
        fill="black"
      />
      <path
        d="M32.9433 12.7317C33.247 12.7317 33.4908 12.4842 33.4908 12.1842C33.4908 11.8842 33.2433 11.6367 32.9433 11.6367H31.5145C31.2108 11.6367 30.967 11.8842 30.967 12.1842C30.967 12.4842 31.2145 12.7317 31.5145 12.7317H32.9433Z"
        fill="black"
      />
      <path
        d="M35.0321 34.6328H31.0758C30.7721 34.6328 30.5283 34.8803 30.5283 35.1803C30.5283 35.4803 30.7758 35.7278 31.0758 35.7278H35.0321C35.3358 35.7278 35.5796 35.4803 35.5796 35.1803C35.5796 34.8803 35.3321 34.6328 35.0321 34.6328Z"
        fill="black"
      />
      <path
        d="M34.848 27.8551C34.848 27.5514 34.6005 27.3076 34.3005 27.3076H31.2255C30.9217 27.3076 30.678 27.5551 30.678 27.8551C30.678 28.1551 30.9255 28.4026 31.2255 28.4026H34.3005C34.6042 28.4026 34.848 28.1551 34.848 27.8551Z"
        fill="black"
      />
      <path
        d="M34.5558 32.0667H36.8621C37.1658 32.0667 37.4096 31.8192 37.4096 31.5192C37.4096 31.2192 37.1621 30.9717 36.8621 30.9717H34.5558C34.2521 30.9717 34.0083 31.2192 34.0083 31.5192C34.0083 31.8192 34.2558 32.0667 34.5558 32.0667Z"
        fill="black"
      />
      <path
        d="M31.5145 30.9688C31.2108 30.9688 30.967 31.2162 30.967 31.5162C30.967 31.8162 31.2145 32.0637 31.5145 32.0637H32.212C32.5158 32.0637 32.7595 31.8162 32.7595 31.5162C32.7595 31.2162 32.512 30.9688 32.212 30.9688H31.5145Z"
        fill="black"
      />
    </g>
  </svg>
);

export default OutlineRemarketing;
