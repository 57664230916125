import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const Facebook: React.FC<IconProps> = ({ color = 'black', size = 20}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1937_36985)">
      <path
        d="M20 10.3193C20 4.79648 15.5229 0.319336 10 0.319336C4.47715 0.319336 0 4.79648 0 10.3193C0 15.3105 3.65684 19.4477 8.4375 20.1979V13.21H5.89844V10.3193H8.4375V8.11621C8.4375 5.60996 9.93047 4.22559 12.2146 4.22559C13.3084 4.22559 14.4531 4.4209 14.4531 4.4209V6.88184H13.1922C11.95 6.88184 11.5625 7.65273 11.5625 8.44434V10.3193H14.3359L13.8926 13.21H11.5625V20.1979C16.3432 19.4477 20 15.3105 20 10.3193Z"
        fill="#ADB0B2"
      />
    </g>
    <defs>
      <clipPath id="clip0_1937_36985">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0 0.319336)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Facebook;
