import React from 'react';
import { Grid, Col } from '@tremor/react';
import AreaCardChart from '../AreaCardChart';
import use3rdPartyStore from '../../../store/use3rdPartyStore';
import useGoogleStore from '../../../store/useGoogleStore';
import { formatNumberWithCommas, processData } from '../../../utils/common';
import MobileView from './MobileView';

const BacklinksChart = () => {
  const { googleSearchConsole } = useGoogleStore((state) => state);
  const { refdomains, domainRatingHistory, urlRating } = use3rdPartyStore(
    (state) => state
  );
  const ahrefsData = {
    refdomains: refdomains?.refdomains || {},
    domain_rating: domainRatingHistory?.data?.domain_rating || {},
    urlRating: urlRating?.data?.url_rating,
  };

  const domainReferring = processData(
    ahrefsData,
    'refdomains',
    'Referring Domains'
  );
  const domainRating = processData(ahrefsData, 'domain_rating');
  const urlRatingData = processData(ahrefsData, 'urlRating', 'URL Rating');

  const lastMonthDomainReferring =
    Array.isArray(domainReferring) && domainReferring.length > 0
      ? domainReferring[domainReferring.length - 1]?.value
      : undefined;

  const lastMonthDomainRating =
    Array.isArray(domainRating) && domainRating.length > 0
      ? domainRating[domainRating.length - 1]?.value
      : undefined;
  const lastMonthurlRating =
    Array.isArray(urlRatingData) && urlRatingData.length > 0
      ? urlRatingData[urlRatingData.length - 1]?.value
      : undefined;
  return (
    <>
      <MobileView />
      <div className="hidden m:block">
        <Grid
          numItems={1}
          numItemsSm={1}
          numItemsMd={3}
          numItemsLg={3}
          className="gap-4 mb-4"
        >
          <Col>
            <AreaCardChart
              title={'Referring Domains'}
              chatData={domainReferring}
              category={['Referring Domains']}
              info={
                ahrefsData?.refdomains?.info
                  ? ahrefsData?.refdomains?.info
                  : 'Referring Domains'
              }
              prevPeriod={ahrefsData?.refdomains?.previous_period}
              previousYear={ahrefsData?.refdomains?.previous_year}
              isLoading={googleSearchConsole.loading}
              totalValue={formatNumberWithCommas(lastMonthDomainReferring)}
              error={googleSearchConsole.error}
              icon="ahref"
            />
          </Col>
          <Col>
            <AreaCardChart
              title={'Domain Rating'}
              chatData={domainRating}
              category={['Domain Rating']}
              info={
                ahrefsData?.domain_rating?.info
                  ? ahrefsData?.domain_rating?.info
                  : 'Domain Rating'
              }
              prevPeriod={ahrefsData?.domain_rating?.previous_period}
              previousYear={ahrefsData?.domain_rating?.previous_year}
              isLoading={googleSearchConsole.loading}
              totalValue={formatNumberWithCommas(lastMonthDomainRating)}
              error={googleSearchConsole.error}
              icon="ahref"
            />
          </Col>
          <Col>
            <AreaCardChart
              title={'URL Rating'}
              chatData={urlRatingData}
              category={['URL Rating']}
              info={
                ahrefsData?.urlRating?.info
                  ? ahrefsData?.urlRating?.info
                  : 'URL Rating'
              }
              prevPeriod={ahrefsData?.urlRating?.previous_period}
              previousYear={ahrefsData?.urlRating?.previous_year}
              isLoading={urlRating.loading}
              totalValue={formatNumberWithCommas(lastMonthurlRating)}
              error={urlRating.error}
              icon="ahref"
            />
          </Col>
        </Grid>
      </div>
    </>
  );
};

export default BacklinksChart;
