import styled from 'styled-components';
import { colors } from '../../utils/settings';

const buttonVariants = {
  default: {
    width: '100%',
    background: 'transparent',
    border: `1px solid ${colors.secondary}`,
    ':before': {
      position: 'absolute',
      content: '""',
      backgroundColor: 'transparent',
      borderRadius: 25,
      transition: '0.3s ease-out',
    },
    ':hover': {
      backgroundColor: colors.secondary,

      ':before': {
        transition: '0.7s',
        backgroundColor: colors.primary,
      },
      span: {
        color: colors.primary,
      },
    },
  },
  blue: {
    backgroundImage: colors.blueGradient,
    background: colors.white,
    border: `1px solid ${colors.primary}`,
    ':before': {
      position: 'absolute',
      content: '""',
      backgroundColor: colors.primary,
      borderRadius: 25,
      transition: '0.3s ease-out',
    },
    ':hover': {
      ':before': {
        transition: '0.7s',
        backgroundColor: 'transparent',
      },
      span: {
        color: colors.primary,
      },
    },
  },
  lightBlue: {
    width: '100%',
    background: colors.white,
    border: `1px solid ${colors.primary}`,
    ':before': {
      position: 'absolute',
      content: '""',
      backgroundColor: colors.primary,
      borderRadius: 25,
      transition: '0.3s ease-out',
    },
    ':hover': {
      backgroundColor: colors.white,
      ':before': {
        transition: '0.7s',
        backgroundColor: 'transparent',
      },
      span: {
        color: colors.primary,
      },
    },
  },
  lightYellow: {
    width: '100%',
    background: colors.white,
    border: `1px solid ${colors.secondary}`,
    ':before': {
      position: 'absolute',
      content: '""',
      backgroundColor: colors.secondary,
      borderRadius: 25,
      transition: '0.3s ease-out',
    },
    ':hover': {
      backgroundColor: colors.white,
      ':before': {
        transition: '0.7s',
        backgroundColor: 'transparent',
      },
      span: {
        color: colors.secondary,
      },
    },
  },
  yellow: {
    backgroundImage: colors.yellowGradient,
    background: colors.primary,
    border: `1px solid ${colors.secondary}`,
    ':before': {
      backgroundColor: colors.secondary,
      borderRadius: 25,
      transition: '0.3s ease-out',
    },
    ':hover': {
      transition: '0.2s 0.1s',
      opacity: 0.8,
      span: {
        color: colors.secondary,
      },
    },
  },
  pink: {
    backgroundImage: colors.pinkGradient,
    color: colors.black,
  },
  darkblue: {
    background: colors.secondary,
    border: `1px solid ${colors.secondary}`,
    marginBottom: 50,
    ':before': {
      backgroundColor: 'rgba(40,57,101,1)',
      borderRadius: 25,
      transition: '0.3s ease-out',
    },
    ':hover': {
      ':before': {
        transition: '0.7s',
        backgroundColor: 'transparent',
      },
      span: {
        color: 'rgba(40,57,101,1)',
      },
    },
  },
  white: {
    position: 'absolute',
    right: 10,
    top: 5,
    height: 30,
    width: 120,
    marginRight: 10,
    fontSize: 14,
    background: colors.primary,
    border: `1px solid ${colors.primary}`,
    ':before': {
      backgroundColor: colors.white,
      transition: '0.3s ease-out',
      borderRadius: 25,
    },
    ':hover': {
      opacity: 0.8,
      ':before': {
        transition: '0.7s',
        backgroundColor: 'transparent',
      },
      span: {
        color: colors.white,
      },
    },
  },
  gray: {
    width: 150,
    backgroundColor: 'aliceblue',
    border: `1px solid #505662`,
    ':before': {
      backgroundColor: 'aliceblue',
      borderRadius: 25,
      transition: '0.3s ease-out',
    },
    ':hover': {
      background: '#505662',
      span: {
        color: colors.white,
      },
      ':before': {
        transition: '0.7s',
        backgroundColor: 'transparent',
      },
    },
  },
  red: {
    width: 130,
    height: 30,
    fontSize: 14,
    marginLeft: 10,
    backgroundColor: colors.white,
    border: `1px solid #b00101`,
    ':before': {
      backgroundColor: colors.white,
      borderRadius: 25,
      transition: '0.3s ease-out',
    },
    ':hover': {
      background: '#b00101',
      opacity: 0.8,
      span: {
        color: colors.white,
      },
      ':before': {
        transition: '0.7s',
        backgroundColor: 'transparent',
      },
    },
  },
  green: {
    width: 130,
    height: 30,
    fontSize: 14,
    backgroundColor: 'aliceblue',
    border: `1px solid #39a342`,
    ':before': {
      backgroundColor: 'aliceblue',
      borderRadius: 25,
      transition: '0.3s ease-out',
    },
    ':hover': {
      background: '#39a342',
      opacity: 0.8,
      span: {
        color: colors.white,
      },
      ':before': {
        transition: '0.7s',
        backgroundColor: 'transparent',
      },
    },
  },
  nohover: {
    background: colors.secondary,
    boxShadow: '0 7px #888',
    width: '100%',
    ':before': {
      content: '""',
      backgroundColor: 'transparent',
      borderRadius: 25,
      transition: '0.3s ease-out',
      height: '100%',
    },
    ':hover': {
      transition: '0.2s 0.1s',
      span: {
        color: colors.primary,
      },
    },
    ':active': {
      boxShadow: '0 2px #666',
      transform: 'translateY(6px)',
    },
  },
};
const spanVariants = {
  blue: {
    color: colors.white,
  },
  yellow: {
    color: colors.primary,
  },
  pink: {
    color: colors.black,
  },
  darkblue: {
    color: colors.secondary,
  },
  white: {
    color: colors.primary,
  },
  gray: {
    color: '#505662',
  },
  red: {
    color: '#b00101',
  },
  green: {
    color: '#39a342',
  },
  nohover: {
    color: colors.primary,
  },
  lightBlue: {
    color: colors.white,
  },
  lightYellow: {
    color: colors.white,
  },
};
export const Wrapper = styled.div(({ variant }) => ({
  width: '100%',
  display: 'contents',
  '.btn': {
    ':before': {
      position: 'absolute',
      content: '""',
      top: 0,
      left: 0,
      right: 0,
      width: '100%',
      height: '100%',
    },
    ':hover': {
      ':before': {
        height: '0%',
      },
    },
  },
}));

export const Btn = styled.button(({ variant }) => ({
  height: 50,
  borderRadius: 30,
  fontWeight: 700,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: colors.yellowGradient,
  width: '80%',
  cursor: 'pointer',
  transition: 'all 1s',
  position: 'relative',
  border: 'none',
  background: colors.secondary,
  padding: 15,
  ':before': {
    position: 'absolute',
    content: '""',
    backgroundColor: 'rgba(40,57,101,1)',
    borderRadius: 20,
    transition: '0.3s ease-out',
  },
  span: {
    position: 'relative',
    color: colors.secondary,
    transition: '0.2s 0.1s',
    ...spanVariants[variant],
  },
  ':hover': {
    transition: '0.2s 0.1s',
    span: {
      color: colors.primary,
    },
  },
  ...buttonVariants[variant],
}));
