import React from 'react';

interface DotProps {
  color?: string;
  size?: number;
}

const Menu: React.FC<DotProps> = ({ color = '#334969', size = 30 }) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width={size}
      height={size}
      rx="8"
      fill={color === '#FFF' ? '#222732' : '#FFF'}
    />
    <path
      d="M11 20H23M11 14H29M11 26H29"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Menu;
