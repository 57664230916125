import { useState, useRef, useEffect, ReactNode } from 'react';
import { Card } from '@tremor/react';
import Icon from '../../assets/icons/SvgComponent';
import { useThemeStore } from '../../store/useThemeStore';

interface IProps {
  title?: string;
  className?: string;
  icon?: string;
  children: React.ReactNode;
}

const Accordion = ({ title, children, icon, className = '' }: IProps) => {
  const { mode } = useThemeStore((state) => state);
  const [isOpen, setIsOpen] = useState(true);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [isOpen, children]);

  useEffect(() => {
    const handleResize = () => {
      if (contentRef.current) {
        setContentHeight(contentRef.current.scrollHeight);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Card
      className={`accordion rounded-[8px] ${className} ${!isOpen ? 'bg-[#001C44] text-white dark:bg-[#161B26] px-[12px] py-[8px]' : ''}`}
    >
      <div
        className="accordion-header flex justify-between items-center cursor-pointer px-[12px] py-[8px]"
        onClick={toggleAccordion}
      >
        <h2
          className={`font-inter ${!isOpen ? 'bg-[#001C44] text-[12px] font-[600] text-white dark:bg-[#161B26] uppercase' : 'text-[#001C44] text-[18px] font-[800] dark:text-white'}`}
        >
          {title}
        </h2>
        <div className="accordion-icon flex gap-2 items-center">
          {icon && <Icon name={icon} size={20} />}
          {isOpen ? (
            <Icon name="ChevronUp" color={mode === 'dark' ? '#FFF' : '#000'} />
          ) : (
            <Icon name="ChevronDown" color="#FFF" />
          )}
        </div>
      </div>
      <div
        className="accordion-content overflow-hidden transition-all duration-300 ease-in-out"
        style={{
          maxHeight: isOpen ? `${contentHeight}px` : '0',
          opacity: isOpen ? '1' : '0',
        }}
        ref={contentRef}
      >
        {children}
      </div>
    </Card>
  );
};

export default Accordion;
