import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const BreadcrumbIcon: React.FC<IconProps> = ({
  color = 'black',
  size = 80,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 6.5V21.5M10.5 6.5H17.5C18.9001 6.5 19.6002 6.5 20.135 6.77248C20.6054 7.01217 20.9878 7.39462 21.2275 7.86502C21.5 8.3998 21.5 9.09987 21.5 10.5V17.5C21.5 18.9001 21.5 19.6002 21.2275 20.135C20.9878 20.6054 20.6054 20.9878 20.135 21.2275C19.6002 21.5 18.9001 21.5 17.5 21.5H10.5C9.09987 21.5 8.3998 21.5 7.86502 21.2275C7.39462 20.9878 7.01217 20.6054 6.77248 20.135C6.5 19.6002 6.5 18.9001 6.5 17.5V10.5C6.5 9.09987 6.5 8.3998 6.77248 7.86502C7.01217 7.39462 7.39462 7.01217 7.86502 6.77248C8.3998 6.5 9.09987 6.5 10.5 6.5Z"
      stroke={color || '#334969'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BreadcrumbIcon;
