import Icon from '../../assets/icons/SvgComponent';
import Tooltip from '../../components/HelpTooltip';
import { getStatusBadgeColor } from '../../utils/common';
import ImageIcon from '../../components/ImageIcon';
import { Link } from 'react-router-dom';

// Define the props for the generateColumns function
interface GenerateColumnsProps {
  sortingColumn: any;
  sortingDirection: any;
  handleOpenEditModal?: any;
  setLinkItem?: any;
}

// Function to generate columns
export const generateColumns = ({
  sortingColumn,
  sortingDirection,
  handleOpenEditModal,
  setLinkItem,
}: GenerateColumnsProps) => [
  // name column
  {
    id: 'name',
    header: () => (
      <div className="w-[180px] sm:w-[15vw] border-r dark:border-gray-700 h-[40px] flex items-center">
        <div className="flex w-[180px] sm:w-[15vw] justify-start items-center gap-2 cursor-pointer">
          <div className="text-left text-sm font-inter w-fit">Client Name</div>
          <div>
            {sortingColumn === 'name' ? (
              sortingDirection === 'asc' ? (
                <Icon name="Arrowup" />
              ) : sortingDirection === 'desc' ? (
                <Icon name="ArrowDown" />
              ) : (
                <Icon name="Sort" />
              )
            ) : (
              <Icon name="Sort" />
            )}
          </div>
        </div>
      </div>
    ),
    accessorKey: 'client',
    cell: (props: any) => {
      return (
        <div className="w-[180px] sm:w-[15vw] flex justify-start items-center cursor-pointer border-r dark:border-gray-700 h-[70px]">
          <div>
            <Tooltip content={props.getValue()?.name} position="right">
              <div className="truncate overflow-hidden text-sm max-w-[170px] sm:max-w-[12vw] font-inter font-[500] text-[#001C44] dark:text-[#FFF]">
                {props.getValue()?.name}
              </div>
            </Tooltip>
            <div className="text-xs max-w-[170px] sm:max-w-[12vw] truncate dark:text-white">
              {props.getValue()?.domain}
            </div>
          </div>
        </div>
      );
    },
  },
  // account_manager column
  {
    id: 'amAndCons',
    header: () => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex w-[160px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">AM & Cons</div>
          {sortingColumn === 'amAndCons' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'amAndCons',
    cell: (props: any) => (
      <div className="flex justify-center w-[160px] sm:w-[12vw] ">
        <div>
          <Tooltip
            position="top"
            content={`AM: ${props.getValue()?.am}`}
            textAlign="text-center"
          >
            <div
              className={`border-white border rounded-full dark:border-gray-700`}
            >
              <ImageIcon data={props.getValue()?.am} size={8} textSize={'xs'} />
            </div>
          </Tooltip>
        </div>
        <div className="ml-[-6px]">
          <Tooltip
            position="top"
            content={`Consultant: ${props.getValue()?.cons}`}
            textAlign="text-center"
          >
            <div
              className={`border-white border rounded-full dark:border-gray-700`}
            >
              <ImageIcon
                data={props.getValue()?.cons}
                size={8}
                textSize={'xs'}
              />
            </div>
          </Tooltip>
        </div>
      </div>
    ),
  },

  {
    id: 'briefName',
    header: () => (
      <div className="w-[160px] sm:w-[10vw]">
        <div className="flex w-[160px] sm:w-[10vw] justify-start items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Brief Name</div>
          {sortingColumn === 'briefName' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'client',
    cell: (props: any) => (
      <>
        <div className="w-[160px] sm:w-[10vw] flex items-center gap-1 mb-2">
          <div className="w-[160px] sm:w-[10vw] flex items-center">
            <div className="text-sm justify-start sm:max-w-[10vw] font-inter font-[400]">
              {props?.getValue()?.brief_name}
            </div>
          </div>
        </div>
      </>
    ),
  },

  // anchor text column
  {
    id: 'anchorText',
    header: () => (
      <div className="w-[160px] sm:w-[16vw] ">
        <div className="flex w-[160px] sm:w-[16vw] justify-start items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Anchor Text</div>
          {sortingColumn === 'anchorText' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'anchorText',
    cell: (props: any) => (
      <div className="w-[160px] sm:w-[16vw] ">
        <div className="flex justify-start items-center font-inter font-[500] text-[14px] text-[#001C44] dark:text-gray-300 text-xs gap-1 mb-2">
          {props.getValue()?.requested !== '-' ? (
            <>
              <div>
                <Icon name="RoundGreenCheck" />
              </div>
              <div className="text-[14px] font-[500] text-[#001C44] dark:text-white">
                {props.getValue()?.requested}
              </div>
            </>
          ) : (
            <>
              <div className="w-4 flex justify-center items-center">
                <div className="w-[14px] h-[14px] border border-[#808DA1] rounded-full" />
              </div>
              <div className="w-[16px]">-</div>
            </>
          )}
        </div>
        <div className="flex justify-start items-center font-inter font-[500] text-[14px] text-[#001C44] dark:text-gray-300 text-xs gap-1">
          {props.getValue()?.delivered !== '-' ? (
            <>
              {props.getValue()?.delivered === props.getValue()?.requested ? (
                <div>
                  <Icon name="RoundGreenCheck" />
                </div>
              ) : (
                <div>
                  <Icon name="RoundOrangeX" />
                </div>
              )}
              <div className="text-[14px] font-[500] text-[#001C44] dark:text-white">
                {props.getValue()?.delivered}
              </div>
            </>
          ) : (
            <>
              <div className="w-4 flex justify-center items-center">
                <div className="w-[14px] h-[14px] border border-[#808DA1] rounded-full" />
              </div>
              <div className="w-[16px]">-</div>
            </>
          )}
        </div>
      </div>
    ),
  },
  // dr text column
  {
    id: 'DR',
    header: () => (
      <div className="w-[160px] sm:w-[10vw] ">
        <div className="flex w-[160px] sm:w-[10vw] justify-start items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Domain Rating</div>
          {sortingColumn === 'DR' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'dr',
    cell: (props: any) => (
      <div className="w-[160px] sm:w-[10vw] ">
        <div className="flex flex-col gap-1 justify-start items-start font-inter font-[500] text-[10px] text-[#001C44] dark:text-gray-300">
          <div className="flex items-center justify-start gap-1">
            {props.getValue()?.requested !== '-' ? (
              <div>
                <Icon name="RoundGreenCheck" />
              </div>
            ) : (
              <div className="w-4 flex justify-center items-center">
                <div className="w-[14px] h-[14px] border border-[#808DA1] rounded-full" />
              </div>
            )}

            <div className="font-[500] w-[85px] text-[14px]">Requested:</div>
            <div className="bg-[#F2F4FF] px-1 rounded text-[#405573] font-[500] text-[14px] ">
              {props.getValue()?.requested}
            </div>
          </div>
          <div className="flex items-center justify-start gap-1">
            {props.getValue()?.delivered !== '-' ? (
              <>
                {props.getValue()?.delivered > props.getValue()?.requested ? (
                  <div>
                    <Icon name="RoundGreenCheck" />
                  </div>
                ) : (
                  <div>
                    <Icon name="RoundOrangeX" />
                  </div>
                )}
                <div className="font-[500] w-[85px] text-[14px] ">
                  Delivered:
                </div>
                <div className="bg-[#F2F4FF] px-1 rounded text-[#0029FF] font-[500] text-[14px] ">
                  {props.getValue()?.delivered}
                </div>
              </>
            ) : (
              <>
                <div className="w-4 flex justify-center items-center">
                  <div className="w-[14px] h-[14px] border border-[#808DA1] rounded-full" />
                </div>
                <div className="font-[500] w-[90px] text-[14px] ">
                  Delivered:
                </div>
                <div className="px-1">-</div>
              </>
            )}
          </div>
        </div>
      </div>
    ),
  },
  // target column
  {
    id: 'links',
    header: () => (
      <div className="w-[160px] sm:w-[16vw]">
        <div className="flex w-[160px] sm:w-[16vw] justify-start items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Links</div>
          {sortingColumn === 'links' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'links',
    cell: (props: any) => (
      <>
        {!props?.getValue()?.target || props?.getValue()?.target === '-' ? (
          <div className="w-[160px] sm:w-[16vw] flex justify-start items-center text-xs gap-1 mb-2">
            <div className="w-4 flex justify-center items-center">
              <div className="w-[14px] h-[14px] border border-[#808DA1] rounded-full" />
            </div>
            <div className="w-[60px] text-[14px] dark:text-white">Target:</div>
            <div className="dark:text-white">-</div>
          </div>
        ) : (
          <div className="w-[160px] sm:w-[16vw] flex items-center gap-1 mb-2">
            <div>
              <Icon name="RoundGreenCheck" />
            </div>
            <div className="text-[14px] font-[400] w-[60px] dark:text-white">
              Target:
            </div>
            <div className="w-[160px] sm:w-[11vw] flex items-center">
              <Link
                to={props?.getValue()?.target}
                className="text-[14px] justify-start sm:max-w-[11vw] truncate font-inter font-[400] text-[#0029FF]"
                target="blank"
              >
                {props?.getValue()?.target}
              </Link>
              {/* <div>
                <Icon name="ArrowUpRight" size={16} color="#0029FF" />
              </div> */}
            </div>
          </div>
        )}
        {!props?.getValue()?.link || props?.getValue()?.link === '-' ? (
          <div className="w-[160px] sm:w-[16vw] flex justify-start items-center text-xs gap-1">
            <div className="w-4 flex justify-center items-center">
              <div className="w-[14px] h-[14px] border border-[#808DA1] rounded-full" />
            </div>
            <div className="w-[60px] text-[14px] dark:text-white">Link:</div>
            <div className="dark:text-white">-</div>
          </div>
        ) : (
          <div className="w-[160px] sm:w-[16vw] flex items-center gap-1">
            <div>
              <Icon name="RoundGreenCheck" />
            </div>
            <div className="text-[14px] font-[400] w-[60px] dark:text-white">
              Link:
            </div>
            <div className="w-[160px] sm:w-[11vw] flex items-center">
              <Link
                to={props?.getValue()?.link}
                className="text-[14px] justify-start sm:max-w-[11vw] truncate font-inter font-[400] text-[#0029FF]"
                target="blank"
              >
                {props?.getValue()?.link}
              </Link>
              {/* <div>
                <Icon name="ArrowUpRight" size={16} color="#0029FF" />
              </div> */}
            </div>
          </div>
        )}
      </>
    ),
  },

  // status column
  {
    id: 'status',
    header: () => (
      <div className="w-[160px] sm:w-[10vw] ">
        <div className="flex w-[160px] sm:w-[10vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Status</div>
          {sortingColumn === 'status' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'status',
    cell: (props: any) => {
      console.log(props.getValue());
      return (
        <div className="w-[160px] sm:w-[10vw] flex justify-center">
          <div
            className={`text-xs ${
              getStatusBadgeColor(props.getValue())?.color
            } p-1 px-2 rounded-full flex gap-2 items-center cursor-pointer w-fit text-[12px] font-[500]`}
          >
            {getStatusBadgeColor(props.getValue())?.text}
          </div>
        </div>
      );
    },
  },

  // Actions column
  {
    id: 'Action',
    header: () => (
      <div className="flex justify-center w-[100px] sm:w-[10vw] text-sm font-inter">
        Action
      </div>
    ),
    accessorKey: 'view',
    cell: (props: any) => (
      <div className="flex space-x-3 items-center  w-[100px] sm:w-[10vw] justify-center ">
        <div
          className="cursor-pointer"
          onClick={() => {
            setLinkItem(props.getValue());
            handleOpenEditModal();
          }}
        >
          <Icon name="Edit05" />
        </div>
      </div>
    ),
  },
];
