import React from 'react';

interface User01Props {
  color?: string;
  size?: number;
}

const User01: React.FC<User01Props> = ({ color = 'black', size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 21.0381C20 19.6425 20 18.9447 19.8278 18.3769C19.44 17.0985 18.4395 16.0981 17.1611 15.7103C16.5933 15.5381 15.8956 15.5381 14.5 15.5381H9.5C8.10444 15.5381 7.40665 15.5381 6.83886 15.7103C5.56045 16.0981 4.56004 17.0985 4.17224 18.3769C4 18.9447 4 19.6425 4 21.0381M16.5 7.53809C16.5 10.0234 14.4853 12.0381 12 12.0381C9.51472 12.0381 7.5 10.0234 7.5 7.53809C7.5 5.0528 9.51472 3.03809 12 3.03809C14.4853 3.03809 16.5 5.0528 16.5 7.53809Z"
      stroke={color || '#334969'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default User01;
