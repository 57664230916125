import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
  arrowSize?: number;
}

const SwiperPrev: React.FC<IconProps> = ({
  color = 'black',
  size = 80,
  arrowSize = 40,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 80 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_2262_443)">
      <rect
        x="20"
        y="20.5"
        width={arrowSize}
        height={arrowSize}
        rx="20"
        fill="#0029FF"
        shape-rendering="crispEdges"
      />
      <path
        d="M32.429 39.9876L47.0967 33.2179C47.3303 33.1118 47.609 33.175 47.7714 33.3758C47.935 33.5767 47.9407 33.8621 47.785 34.0686L42.9616 40.4998L47.785 46.931C47.9407 47.1375 47.935 47.4241 47.7726 47.6238C47.6631 47.7603 47.4995 47.8337 47.3337 47.8337C47.2536 47.8337 47.1735 47.8167 47.0979 47.7818L32.4302 41.012C32.2293 40.9195 32.1018 40.7198 32.1018 40.4998C32.1018 40.2798 32.2293 40.0801 32.429 39.9876Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2262_443"
        x="0"
        y="0.5"
        width={size}
        height={size}
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="10" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2262_443"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2262_443"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default SwiperPrev;
