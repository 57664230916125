import { MdVerified } from 'react-icons/md';
import { getInitials, getRoleName } from '../../utils';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import useUserStore from '../../store/useUserStore';
import useAuthStore from '../../store/useAuthStore';
import Icon from '../../assets/icons/SvgComponent';
import useClientStore from '../../store/useClientStore';
import { Link, useLocation } from 'react-router-dom';

type FormState = {
  first_name: string;
  last_name: string;
  email: string;
  role: string;
  hubspot_role?: string;
};
type PasswordForm = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
  showPassword: boolean;
};
const customStyles = {
  control: (provided: any) => ({
    ...provided,
    width: '100%',
    borderRadius: '0.5rem',
    padding: '2px',
    borderColor: '#808EA2',
    marginTop: '0.25rem',
  }),
};

type InputProps = {
  label?: string;
  type?: string;
  name?: string;
  value?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  width?: string;
  errorMessage?: string;
  placeholder?: string;
  disabled?: boolean;
};

export const Input: React.FC<InputProps> = ({
  label,
  type,
  name,
  value,
  onChange,
  width = 'w-full',
  errorMessage,
  placeholder,
  disabled = false,
}) => (
  <div className="relative w-full flex flex-col">
    <label className="text-sm text-[#001C44]  font-inter dark:text-white">
      {label}
    </label>
    <input
      type={type}
      name={name}
      disabled={disabled}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      className={`w-[${width}] rounded-lg p-2 text-[#001129] border border-[#808EA2] mt-1 dark:bg-[#191919] dark:text-white `}
    />
    {errorMessage && !value && (
      <div className="absolute bottom-[-20px] text-red-500 text-sm mt-1">
        {errorMessage}
      </div>
    )}
  </div>
);

const MyDetails: React.FC = () => {
  const { user, updateUser } = useUserStore((state) => state);
  const { fetchClients, clients, setClient } = useClientStore(
    (state: any) => state
  );
  const { resetPassword, currentUser } = useAuthStore((state) => state);

  const handleFetchClients = ({ currentPage }: any) => {
    let filters = {
      status: 'all',
      search: '',
      page: 1,
      limit: 5,
      category_type: '',
      service: '',
    };

    fetchClients(filters);
  };

  useEffect(() => {
    handleFetchClients(1);
  }, []);

  const navToClientDetails = ({ client }: any) => {
    setClient(client);
  };

  const pic = user?.profile_pic || currentUser?.profile_pic;
  const name = `${currentUser?.first_name}  ${currentUser?.last_name}`;
  const initialState: FormState = {
    first_name: currentUser?.first_name || currentUser?.first_name,
    last_name: currentUser?.last_name || currentUser?.last_name,
    email: currentUser?.email || currentUser?.email,
    role: currentUser?.gurulytics_role?.name || currentUser?.role.name,
    hubspot_role: currentUser?.role.name,
  };
  const [formData, setFormData] = useState<FormState>(initialState);
  const [passwordData, setPasswordData] = useState<PasswordForm>({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
    showPassword: false,
  });
  const [passwordErrors, setPasswordErrors] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [formError, setFormError] = useState<string>('');

  const [infoUpdateMessage, setInfoUpdateMessage] = useState('');
  const [infoUpdateError, setInfoUpdateError] = useState<string>('');

  useEffect(() => {
    setFormData(initialState);
  }, [user]);
  const validateForm = (): boolean => {
    if (!formData.first_name || !formData.last_name) {
      setInfoUpdateError('All fields are required!');
      return false;
    }
    setInfoUpdateError(''); // Clear any previous error messages
    return true;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPasswordData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setInfoUpdateError('');
    setInfoUpdateMessage('');
    if (validateForm()) {
      updateUser(user?.id, formData);
      setInfoUpdateMessage('Info updated successfully!');
      setTimeout(() => {
        setInfoUpdateMessage('');
      }, 3000);
    }
  };
  const handlePasswordSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSuccessMessage('');
    setFormError('');
    if (validatePassword()) {
      const data = {
        oldPassword: passwordData.currentPassword,
        password: passwordData.newPassword,
      };
      resetPassword(data)
        .then((res: any) => {
          setSuccessMessage('Password updated successfully!');
          setPasswordData({
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            showPassword: false,
          });
          setTimeout(() => {
            setSuccessMessage('');
          }, 3000);
        })
        .catch((err: any) => {
          // console.log(err);
          setFormError(err?.message);
        });
    }
  };

  //hanlde password error
  const validatePassword = () => {
    let isValid = true;

    if (passwordData.currentPassword.length <= 5) {
      isValid = false;
      setFormError('Current password must be at least 6 characters');
    } else if (passwordData.newPassword.length <= 7) {
      isValid = false;
      setFormError('Passwords do not match');
    } else if (passwordData.newPassword !== passwordData.confirmPassword) {
      isValid = false;
      setFormError('Passwords do not match');
    }
    return isValid;
  };
  const location = useLocation();
  const scrollRef = useRef<any>(null);
  const scrollToTop = () => {
    const headerHeight = '200px';
    scrollRef.current.style.scrollMargin = headerHeight;
    return scrollRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollToTop();
    }
  }, [location.pathname]);
  return (
    <div
      ref={scrollRef}
      className="flex flex-col w-full h-full gap-8  bg-[#D2EEFF] dark:bg-[#0C111D]"
    >
      <div className="flex flex-col gap-2 w-full p-10 rounded-xl bg-white dark:bg-[#222732]">
        <div className="flex items-start gap-4">
          <div className="relative">
            {pic ? (
              <img alt={pic} src={pic} height={45} width={50} />
            ) : (
              <div className="flex items-center justify-center w-12 h-12 rounded-full bg-[#005C9F] border-2 border-[#FFFFFF] relative text-white px-6">
                {getInitials(name)}
              </div>
            )}

            <MdVerified
              size={12}
              color="#0788F5"
              className="absolute right-0 bottom-0"
            />
          </div>
          <div className="flex flex-col  ">
            <span className="text-sm font-semibold dark:text-white">
              Personal Information
            </span>
            <span className="text-xs text-[#475467] font-normal w-[90%] dark:text-[#ADB0B2] ">
              Update your personal details here.
            </span>
          </div>
        </div>
        <form onSubmit={handleSubmit} className="relative flex flex-col gap-8">
          <div className="grid grid-cols-1 mt-4 sm:mt-0 sm:grid-cols-3 gap-8 w-full">
            <Input
              label="First Name"
              type="text"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
            />
            <Input
              label="Last Name"
              type="text"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
            />
            <Input
              label="Email address"
              type="email"
              disabled
              name="email"
              value={formData?.email}
              onChange={() => {}}
            />
            <Input
              label="Role"
              type="text"
              name="role"
              disabled
              value={formData?.role}
              onChange={() => {}}
            />
            <Input
              label="Hubspot Role"
              type="role"
              name="hubspot_role"
              disabled
              value={formData?.hubspot_role}
              onChange={(e) => handleChange(e)}
            />
            <div className="flex justify-end">
              <button className="btn w-fit self-end border border-[#005C9F] bg-[#005C9F] text-white text-sm py-2 px-4 rounded-lg font-semibold">
                Save
              </button>
            </div>
          </div>
          {infoUpdateMessage && (
            <span className="text-green-500 absolute bottom-11 font-inter left-1 text-sm">
              {infoUpdateMessage}
            </span>
          )}
          {infoUpdateError && (
            <div className="text-red-500 absolute bottom-11 left-1 text-sm font-inter">
              {infoUpdateError}
            </div>
          )}
        </form>
      </div>
      <div className="flex flex-wrap gap-10 w-full">
        <form
          onSubmit={handlePasswordSubmit}
          className="flex flex-1 flex-col gap-8 p-8 sm:p-10 rounded-xl bg-white dark:bg-[#222732] "
        >
          <span className="text-lg font-semibold text-[#001C44] dark:text-white">
            Password
          </span>
          <Input
            label="Current password"
            type="password"
            name="currentPassword"
            value={passwordData?.currentPassword}
            onChange={handlePasswordChange}
            errorMessage={passwordErrors.currentPassword}
          />
          <div className="flex flex-wrap sm:flex-nowrap w-full gap-8 sm:gap-0 relative">
            <div className="sm:mr-4 w-full">
              <Input
                label="New password"
                type="password"
                name="newPassword"
                value={passwordData?.newPassword}
                onChange={handlePasswordChange}
                errorMessage={passwordErrors.newPassword}
              />
            </div>
            <Input
              label="Confirm new password"
              type="password"
              name="confirmPassword"
              value={passwordData?.confirmPassword}
              onChange={handlePasswordChange}
              errorMessage={passwordErrors.confirmPassword}
            />
            <div>
              {successMessage && (
                <span className="text-green-500 absolute bottom-[-45px] left-1 text-sm font-inter">
                  {successMessage}
                </span>
              )}
              {formError && (
                <div className="text-red-500 absolute bottom-[-45px] left-1 text-sm font-inter w-[80%]">
                  {formError}
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-end gap-4 border-t border-[#E6EBF0] w-full mt-4 pt-4">
            <button className="border border-[#808EA2] text-black text-sm py-2 px-4 rounded-lg font-semibold dark:text-white">
              Cancel
            </button>
            <button className="btn border border-[#005C9F] bg-[#005C9F] text-white text-sm py-2 px-4 rounded-lg font-semibold">
              Update password
            </button>
          </div>
        </form>
        <div className="flex flex-col gap-[16px] w-full lg:w-1/3 p-4 sm:p-10  rounded-xl bg-white dark:bg-[#222732]">
          <div className="flex flex-col gap-4 ">
            <span className="text-lg font-semibold text-[#001129] dark:text-white">
              Clients
            </span>
          </div>
          <div>
            <div className="w-full bg-[#F3F7FA] text-sm text-[#7C7E7F] px-4 sm:px-[24px] py-[12px] dark:bg-gray-900 dark:text-white">
              Full Name
            </div>
            {clients?.data?.map((client, index) => (
              <div
                key={index}
                className="flex justify-between px-4 sm:px-[24px] py-[12px] border-b border-gray-100 w-full"
              >
                <div className="text-sm font-normal truncate max-w-[250px] min-w-[250px] overflow-hidden dark:text-white">
                  {client.name}
                </div>
                <div>
                  <Link
                    onClick={() => {
                      navToClientDetails(client?.id);
                    }}
                    to={`/client/details/users/${client?.id}`}
                  >
                    <Icon name="Login" color="#005C9F" />
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyDetails;
