import styled from 'styled-components';
import { colors } from '../../utils/settings';

export const TableContainer = styled.table((props) => ({
  overflowY: 'auto',
  maxHeight: '90vh',
  minHeight: props.noMinHeight ? 0 : '70vh',
  zIndex: 1,
  '::-webkit-scrollbar': {
    width: 8,
    height: 8,
    borderRadius: 4,
  },
  '::-webkit-scrollbar-thumb': {
    background: '#666666',
    borderRadius: 4,
  },
  '::-webkit-scrollbar-track': {
    borderRadius: 4,
  },
}));

export const StyledTable = styled.table((props) => ({
  width: '100%',
  borderCollapse: 'collapse',
  overflowY: 'scroll',
  th: {
    zIndex: 1,
    position: 'sticky',
    top: 0,
    textAlign: 'left',
    padding: '8px',
    height: 35,
    color: '#000',
    fontWeight: '600',
    backgroundColor: colors.white,
    '::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: 3,
      backgroundColor: '#dddddd',
    },
  },
  td: {
    borderBottom: '2px solid #dddddd',
    textAlign: 'left',
    padding: '5px',
    minHeight: 45,
  },
  'tr:nth-child(even)': {
    backgroundColor: '#f7f3f2',
  },
  p: {
    width: 200,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  ol: {
    display: 'none',
  },
  li: {
    display: 'none',
  },
}));
