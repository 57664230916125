import React from 'react';

interface IProps {
  color?: string;
  size?: number;
}

const ClientProfile: React.FC<IProps> = ({ color = '#405573', size = 17 }) => (
  <svg
    width={size - 1}
    height={size}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 5.5C11 4.4375 10.4062 3.46875 9.5 2.90625C8.5625 2.375 7.40625 2.375 6.5 2.90625C5.5625 3.46875 5 4.4375 5 5.5C5 6.59375 5.5625 7.5625 6.5 8.125C7.40625 8.65625 8.5625 8.65625 9.5 8.125C10.4062 7.5625 11 6.59375 11 5.5ZM3.5 5.5C3.5 3.90625 4.34375 2.4375 5.75 1.625C7.125 0.8125 8.84375 0.8125 10.25 1.625C11.625 2.4375 12.5 3.90625 12.5 5.5C12.5 7.125 11.625 8.59375 10.25 9.40625C8.84375 10.2188 7.125 10.2188 5.75 9.40625C4.34375 8.59375 3.5 7.125 3.5 5.5ZM1.53125 15.5H14.4375C14.1875 13.8125 12.7188 12.5 10.9375 12.5H5.03125C3.25 12.5 1.78125 13.8125 1.53125 15.5ZM0 16.0625C0 13.2812 2.25 11 5.03125 11H10.9375C13.7188 11 16 13.2812 16 16.0625C16 16.5938 15.5625 17 15.0312 17H0.9375C0.40625 17 0 16.5938 0 16.0625Z"
      fill={color}
    />
  </svg>
);

export default ClientProfile;