import { useState, useEffect, useRef } from 'react';
import {
  getCoreRowModel,
  useReactTable,
  flexRender,
  ColumnDef,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
} from '@tanstack/react-table';
import Icon from '../../assets/icons/SvgComponent';
import { useThemeStore } from '../../store/useThemeStore';
import OutsideAlerter from '../Common/OutsideAlerter';
import useVisibilityStore from '../../store/useVisibilityStore';
import { getColumnName, getColumnTitle } from '../../utils';
import PaginationV2 from '../PaginationV2';

type Props = {
  data: any;
  columns: ColumnDef<Record<string, any>, any>[];
  totalPages?: any;
  page?: any;
  setPage?: any;
  limit?: any;
  setLimit?: any;
  loading?: any;
  setSelectedRow?: any;
  status?: any;
  role?: any;
  rowState?: boolean;
  setRowState?: any;
  tableHeight?: string;
  openVisibility?: any;
  setOpenVisibility?: any;
  totalDocs?: any;
  confirmationModal?: any;
  sortData?: any;
  stickyColumn?: string;
  pageWHeader?: boolean;
  pagination?: boolean;
  errorMsg?: string;
};

const GurulyticsTable: React.FC<Props> = ({
  data,
  columns,
  totalPages,
  page,
  setPage,
  loading,
  setSelectedRow,
  status,
  rowState,
  setRowState,
  role,
  tableHeight,
  totalDocs,
  sortData,
  stickyColumn,
  pageWHeader,
  // pagination = true,
  limit,
  setLimit,
  errorMsg,
}) => {
  const { mode } = useThemeStore((state: any) => state);
  const iconColor = mode === 'dark' ? 'white' : 'black';
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = useState({});
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: limit,
  });

  const {
    openVisibility,
    setOpenVisibility,
    setVisibilityOption,
    monthlyVisibilityOption,
    weeklyVisibilityOption,
    performanceVisibilityOption,
  } = useVisibilityStore();

  const table = useReactTable({
    columns,
    data,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    onPaginationChange: setPagination,
    manualPagination: true,
    enableRowSelection: true,
    enableHiding: true,
    onColumnVisibilityChange: setVisibilityOption,
    enableSorting: true,
    enableMultiSort: true,
    enableSortingRemoval: true,
    debugTable: true,
    state: {
      pagination,
      rowSelection: rowSelection,
      sorting,
      globalFilter,
      columnVisibility: {
        ...weeklyVisibilityOption,
        ...monthlyVisibilityOption,
        ...performanceVisibilityOption,
      },
    },
  });

  useEffect(() => {
    setVisibilityOption((prevVisibility) => {
      const newVisibility = table.getState().columnVisibility;
      if (newVisibility !== prevVisibility) {
        return newVisibility;
      }
      return prevVisibility;
    });
    setOpenVisibility(false);
  }, [setVisibilityOption, table]);

  useEffect(() => {
    setRowSelection({});
    if (rowState === false) {
      table.resetRowSelection();
      setRowState(true);
    }
  }, [page, status, role, rowState, table, setRowState]);

  useEffect(() => {
    if (table.getSelectedRowModel() && setSelectedRow) {
      setSelectedRow(
        table.getSelectedRowModel().rows.map((row) => row.original)
      );
    }
  }, [rowSelection]);

  const handleCheckboxClick = (columnId) => {
    const isMonthlyOption = Object.keys(monthlyVisibilityOption).includes(
      columnId
    );

    setVisibilityOption({
      [columnId]: !isMonthlyOption
        ? !weeklyVisibilityOption[columnId]
        : !monthlyVisibilityOption[columnId],
      performanceVisibilityOption: !performanceVisibilityOption,
    });
  };

  const handleToggleColumnVisibility = () => {
    setOpenVisibility((prevOpenVisibility) => !prevOpenVisibility);
  };

  const tableRef = useRef(null);
  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }
  }, [page]);

  return (
    <div className="bg-white dark:bg-gray-800">
      {openVisibility && (
        <OutsideAlerter toggle={handleToggleColumnVisibility}>
          <div className="inline-block border border-[#EAECF0] shadow rounded w-60 absolute right-6 top-38 bg-white z-[999999] dark:bg-[#1F2937] ">
            <div className=" border-b border-[#EAECF0] font-semibold py-3 px-4">
              <label className="text-[#001C44] text-sm dark:text-white">
                Manage Columns
              </label>
            </div>
            <div className="max-h-[65dvh] overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8">
              {columns.map((column) => {
                return (
                  <div
                    key={column.id}
                    className={
                      column.id === 'Select' ||
                      column.id === 'Action' ||
                      column.id === 'Status' ||
                      column.id === 'Client' ||
                      column.id === 'report_clients' ||
                      column.id === 'name'
                        ? 'hidden'
                        : 'p-2.5 py-3.5 flex items-center justify-between dark:text-white'
                    }
                  >
                    <div className="flex gap-2 items-center">
                      <Icon
                        name={column.id}
                        size={20}
                        color={mode === 'dark' ? '#fff' : iconColor}
                      />
                      <label className="dark:text-white">
                        {getColumnName(column?.id)}
                      </label>
                    </div>

                    <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        {...{
                          type: 'checkbox',
                          checked:
                            table.getState().columnVisibility[`${column.id}`],
                          onClick: () => handleCheckboxClick(column.id),
                        }}
                        className="sr-only peer"
                      />
                      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#005C9F]" />
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </OutsideAlerter>
      )}
      <div
        ref={tableRef}
        className={
          tableHeight
            ? `relative overflow-y-auto bg-white dark:bg-[#222732]  ${tableHeight} sm:rounded-lg scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8`
            : `relative overflow-auto bg-white dark:bg-[#222732] ${pageWHeader ? 'tableHeightWHeader' : 'tableHeight'}  tableHeight  scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8`
        }
      >
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      colSpan={header.colSpan}
                      onClick={
                        sortData
                          ? () => {
                              if (header.id === 'Select') {
                                return;
                              } else {
                                sortData(
                                  header.id,
                                  `${header.column.getAutoSortDir()}`
                                );
                              }
                            }
                          : null
                      }
                      key={header.id}
                      className={`${header.id === stickyColumn ? 'sticky left-0 z-[10]' : 'z-[9]'} sticky top-0 z-[11] bg-[#F2F4FF] dark:bg-[#1F2937] dark:text-gray-400 w-full`}
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          className={
                            header.column.getCanSort()
                              ? 'cursor-pointer select-none h-full text-sm text-[#001C44] font-[500] px-4 py-3'
                              : 'h-full text-sm text-[#001C44] font-[500] px-4'
                          }
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {loading ? (
              <tr className={` absolute top-[40%] left-[50%] overflow-hidden`}>
                <td>
                  <Icon name="Loading" />
                </td>
              </tr>
            ) : table.getRowModel().rows.length ? (
              <>
                {table
                  .getRowModel()
                  .rows.slice(0, 10)
                  .map((row) => {
                    return (
                      <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => {
                          return (
                            <td
                              key={cell.id}
                              className={`${cell.id.includes(`${stickyColumn}`) ? 'sticky left-0 z-10  bg-white dark:bg-gray-800 dark:border-gray-700 ' : 'z-9 dark:bg-gray-800 dark:border-gray-700 '} ${cell.column.columnDef.meta ?? ''}  min-w-fit px-4 py-3`}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
              </>
            ) : (
              <tr className="absolute top-[30%] left-[36%] rounded-bg">
                <td className="relative flex flex-col justify-center items-center mt-[5rem]">
                  <Icon name="NoData" size={60} />
                  <p className="mb-4 font-semibold text-[#001C44] dark:text-white">
                    No records found
                  </p>
                  <p className="text-center text-[#001C44] w-[320px] text-sm dark:text-white">
                    {errorMsg ||
                      'Try adjusting your search filters or add new data to get started.'}
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="px-4">
        <PaginationV2
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          totalDocs={totalDocs}
          limit={limit}
          setLimit={setLimit}
        />
      </div>
    </div>
  );
};

export default GurulyticsTable;
