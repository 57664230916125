import React, { useState, useEffect } from 'react';
import Icon from '../../../assets/icons/SvgComponent';
import ReactSelect from '../../ReactSelect';
import { useThemeStore } from '../../../store/useThemeStore';
import useClientStore from '../../../store/useClientStore';
import use3rdPartyStore from '../../../store/use3rdPartyStore';
import { FadeLoader } from 'react-spinners';

interface TeamworkModalProps {
  onClose?: () => void;
  data: any;
}

const TeamworkModal: React.FC<TeamworkModalProps> = ({ onClose }) => {
  const { mode } = useThemeStore((state: any) => state);
  const iconColor = mode === 'dark' ? 'white' : 'black';
  const { selectedClient, updateClient, fetchClient } = useClientStore(
    (state) => state
  );
  const { teamworkProjects, fetchTeamworkProjects } = use3rdPartyStore(
    (state) => state
  );
  const [isLoading, setIsLoading] = useState(false);
  const [ids, setIds] = useState(selectedClient?.teamwork?.projects_ids);
  const uniqueIds = ids?.filter((elem: any, index: any, self: any) => {
    return index === self.indexOf(elem);
  });
  const projectsNames = Array.isArray(teamworkProjects)
    ? teamworkProjects
        .slice()
        .filter((item: any) => uniqueIds?.includes(item?.project_id))
    : [];

  const formattedProjects =
    teamworkProjects && Array.isArray(teamworkProjects)
      ? teamworkProjects.map((item: any) => ({
          value: item?.project_id,
          label: item?.name,
        }))
      : [];

  useEffect(() => {
    setIsLoading(true);
    fetchTeamworkProjects().then((res: any) => {
      setIsLoading(false);
    });
  }, []);

  const handleSave = () => {
    const data = {
      teamwork: {
        ...selectedClient?.teamwork,
        projects_ids: uniqueIds,
        company_id: projectsNames[0]?.company_id || '',
        status: 'activated',
      },
    };
    setIsLoading(true);
    updateClient(selectedClient?.id, data).then((res: any) => {
      setIsLoading(false);
      fetchClient(selectedClient?.id);
      onClose && onClose();
    });
  };

  const handleProjectSelect = (value: any) => {
    const newIds = Array.isArray(ids) ? [...ids, value.value] : [value.value];
    setIds(newIds);
  };

  const deleteProject = (id: any) => {
    const updateProjects = ids.filter((c: any) => c !== id);
    setIds(updateProjects);
  };

  return (
    <div
      className="fixed top-0 right-0 bg-white h-screen w-screen sm:w-[32vw] p-[24px] flex flex-col z-100 dark:bg-[#262627] dark:border dark:border-[#313233] dark:shadow-bs-card"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex justify-between items-center">
        <div className="flex gap-[8px] items-center">
          <div className="bg-gray-200 rounded-lg p-2">
            <Icon
              name="Teamwork"
              color={mode === 'dark' ? '#fff' : iconColor}
            />
          </div>
          <div className="text-[24px] dark:text-white">Teamwork</div>
        </div>
        {isLoading && (
          <div className="absolute right-16 top-5">
            <Icon name="loading" />
          </div>
        )}
        <div className="cursor-pointer" onClick={onClose}>
          <Icon
            name="Xclose"
            size={20}
            color={mode === 'dark' ? '#fff' : iconColor}
          />
        </div>
      </div>
      <div
        className="hidden sm:flex absolute top-[50%] left-[-50px]  bg-[#E6EFF5]  border border-[#E6EFF5] rounded-[8px] py-[8px] px-[14px] transform rotate-[270deg] text-[14px]  text-[#005C9F] flex gap-[8px] cursor-pointer"
        onClick={onClose}
      >
        <div>Close</div>
        <Icon name="Xclose" size={20} color="#005C9F" />
      </div>
      <div className="flex flex-col justify-between h-[98%]">
        <div>
          <div className="mt-[24px] pt-[24px] border-t mb-[16px]">
            <p className="mb-[6px] text-sm text-[#001C44] dark:text-white">
              Projects Ids
            </p>
            <ReactSelect
              options={formattedProjects}
              placeholder="Select Projects"
              handleOnChange={handleProjectSelect}
            />
          </div>
          <div className="mb-[16px]">
            {projectsNames?.length > 0 && (
              <p className="mb-[6px] text-sm text-[#001C44] dark:text-white">
                Selected Projects
              </p>
            )}
            <div className="flex flex-wrap gap-[16px] border-b pb-12">
              {projectsNames?.length > 0 ? (
                <>
                  {projectsNames?.map((item: any) => (
                    <div
                      key={item?.project_id}
                      className="flex items-center justify-between w-[calc(50%-16px)] bg-[#E6EFF5] border border-[#E6EFF5] rounded-[8px] py-[8px] px-[14px] text-[14px] text-[#005C9F] cursor-pointer"
                    >
                      <div>{item?.name}</div>
                      <div onClick={() => deleteProject(item?.project_id)}>
                        <Icon name="Xclose" size={20} color="#005C9F" />
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="w-full text-center text-[#005C9F]">
                  No Projects Found
                </div>
              )}
            </div>
          </div>
        </div>

        <div>
          <button
            onClick={handleSave}
            className="text-white bg-[#005C9F] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm w-full py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default TeamworkModal;
