import React from 'react';

interface ArrowLeftProps {
  color?: string;
  size?: string;
}

const Ahref: React.FC<ArrowLeftProps> = ({ color = 'black', size = '10' }) => (
  <svg
    width={size}
    version="1.1"
    id="Logo"
    xmlns="ns_extend"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 67.8 82.6"
    xmlSpace="preserve"
  >
    <style type="text/css">{`.st0{fill:#FF8800;}`}</style>
    <metadata></metadata>
    <g>
      <path
        className="st0"
        d="M7.1,17.9h40.8v11.5l-15.3,1.2C7.8,32.3,0,38.9,0,57.3V61c0,13.5,9.4,21.6,23.9,21.6c11.5,0,18-2.7,26.1-11
  h1.3v8.8h16.3V0H7.1V17.9L7.1,17.9z M47.9,56.6c-5.1,5.1-12.6,8.4-18.9,8.4c-6.7,0-9.6-2.5-9.4-8.1c0.2-7.8,3-9.6,16-10.8L47.9,45
  C47.9,45,47.9,56.6,47.9,56.6z"
      />
    </g>
  </svg>
);

export default Ahref;
