// performance.tsx
import React, { useState, useEffect, useMemo, useRef } from 'react';
import moment from 'moment';
import TansStackTableV2 from '../../components/TansStackTableV2';
import ReactSelect from '../../components/ReactSelect';
import Icon from '../../assets/icons/SvgComponent';
import { useThemeStore } from '../../store/useThemeStore';
import useVisibilityStore from '../../store/useVisibilityStore';
import { formatToCurrency, formatNumber } from '../../utils/common';
import CustomModal from '../../components/CustomModal';
import { useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import PerformanceInsightsModal from '../../components/Modals/Performance/insightsModal';
import usePerformanceStore from '../../store/usePerformanceStore';
import PerformanceModal from '../../components/Modals/Performance';
import ClientUserSearchBar from '../../components/ClientUserSearch';
import 'react-datepicker/dist/react-datepicker.css';
import ExportModal from '../../components/Modals/Performance/Export';
import { generateSeoColumns } from './seoColumn';
import { generatePpcColumns } from './ppcColumns';
import { generateMetaColumns } from './metaColumns';
import {
  calculatePerformancePercentage,
  seoMetrics,
  ppcMetrics,
  metaMetrics,
  findHiddenMetrics,
} from '../../utils/performance';
import Alert from '../../components/Alert';
import { toast, ToastContainer, Flip } from 'react-toastify';

const clientsOptions = [
  { value: 'true', label: 'All Clients' },
  { value: 'false', label: 'My Client' },
];

const campaignOptions = [
  { value: 'all', label: 'All Campaigns' },
  { value: 'Lead Gen', label: 'Lead Gen' },
  { value: 'Ecommerce', label: 'Ecommerce' },
];

const cycleOptions = [
  { value: 'all', label: 'All Cycles' },
  { value: 'Cycle 1', label: 'Cycle 1' },
  { value: 'Cycle 2', label: 'Cycle 2' },
];

const statusOptions = [
  { value: 'red', label: 'Red' },
  { value: 'amber', label: 'Amber' },
  { value: 'green', label: 'Green' },
];

const integrationOptions = [
  { value: 'all', label: 'Integrated' },
  { value: 'missing_performance', label: 'Missing Performance' },
  { value: 'missing_integration', label: 'Missing Integration' },
  // { value: 'integrated', label: 'Integrated' },
];

interface PerformanceFilterStorage {
  type: string;
  options: any[]; // Adjust this type according to your actual data structure
}

const serviceTypeOptions = [
  { value: 'seo_google', label: 'Google SEO' },
  { value: 'ppc_google', label: 'Google Ads' },
  { value: 'social_meta', label: 'Meta' },
];

const Performance: React.FC = () => {
  const [page, setPage] = useState(1);
  const { mode } = useThemeStore((state: any) => state);
  const iconColor = mode === 'dark' ? 'white' : 'black';
  const [openInsightModal, setOpenInsightModal] = useState(false);
  const {
    performance,
    fetchPerformance,
    fetchSummary,
    regenerating,
    setGeneratedClientId,
    generatedClientId,
    generatePerformance,
  } = usePerformanceStore();
  const [searchText, setSearchText] = useState('');
  const [rowState, setRowState] = useState(true);
  const [mount, setMount] = useState(false);
  const [limit, setLimit] = useState(10);

  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const {
    openVisibility,
    setOpenVisibility,
    performanceVisibilityOption,
    resetPerfromanceVisibility,
  } = useVisibilityStore();
  const [sortingDirection, setSortingDirection] = useState('');
  const [sortingColumn, setSortingColumn] = useState('');
  const [selectedPerformance, setSelectedPerformance] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const lastMonth = moment().subtract(1, 'month').startOf('month').toDate();
  const maxDate = moment(lastMonth, 'MM-yyyy').endOf('month').toDate();
  const today = new Date();
  const [date, setDate] = useState(lastMonth);
  const [, setPerformanceFilter] = useState(null);

  const filterModalRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        filterModalRef.current &&
        !filterModalRef.current.contains(event.target)
      ) {
        setOpenFilterModal(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [performanceFilterStorage, setPerformanceFilterStorage] =
    useState<PerformanceFilterStorage>({
      type: '',
      options: [],
    });

  today.setMonth(today.getMonth() - 1);

  const [type, setType] = useState<{
    label: string;
    value: string;
  } | null>({ label: 'Google SEO', value: 'seo_google' });

  const [, setSelectedType] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const [cycle, setCycle] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const [status, setStatus] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [integration, setIntegration] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const [campaignType, setCampaignType] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const [clients, setClients] = useState<{
    label: string;
    value: string;
  } | null>(null);

  console.log(integration);

  const [resetFilters, setResetFilters] = useState(false);

  const location = useLocation();
  const [hiddenColumns, setHiddenColumns] = useState(0);
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const metricsMap = {
      seo_google: seoMetrics,
      ppc_google: ppcMetrics,
      social_meta: metaMetrics,
    };

    const selectedMetrics = metricsMap[type?.value];
    const hiddenMetrics = findHiddenMetrics(
      selectedMetrics,
      performanceVisibilityOption
    );
    setHiddenColumns(hiddenMetrics?.length);
  }, [performanceVisibilityOption, type]);

  const handleVisibilityClick = () => {
    setOpenVisibility(!openVisibility);
  };

  useEffect(() => {
    const key = 'isPerformanceFilterStorageUpdated';
    const storedValue = localStorage.getItem(key);
    if (!storedValue) {
      // If not found, create it and set a default value
      // setPerfromanceVisibility()
      resetPerfromanceVisibility();
      localStorage.setItem(key, 'true');
      window.location.reload();
    }

    // Sync initial state
    const usersStorage = localStorage.getItem('performance-filter-storage');
    setPerformanceFilterStorage(JSON.parse(usersStorage));

    const handleStorage = (event) => {
      const getPerformanceFilter = JSON.parse(
        event?.target?.localStorage?.getItem('performance-filter-storage')
      );

      setPerformanceFilterStorage(getPerformanceFilter);
    };

    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, []);

  useEffect(() => {
    if (mount) {
      handlePerformanceFetch(false, false); // Fetch performance on sort change
    }
  }, [sortingDirection, sortingColumn]);

  useEffect(() => {
    if (mount) {
      handlePerformanceFetch(true, true); // Fetch summary on mount, clear the page
    }
  }, [date, page, cycle, status, limit, type, performanceFilterStorage]);

  const handleApplyFilter = () => {
    handlePerformanceFetch(true, true);
  };

  const handleResetAllFilter = () => {
    setType({ label: 'Google SEO', value: 'seo_google' });
    setCycle(null);
    setStatus(null);
    setIntegration(null);
    setCampaignType(null);
    setClients(null);
    setResetFilters(true);
  };

  useEffect(() => {
    if (resetFilters) {
      handlePerformanceFetch(true, true);
      setResetFilters(false);
    }
  }, [resetFilters]);

  useEffect(() => {
    const dateFromUrl = queryParams.get('date');
    // const sortDirectionFromURL = queryParams.get('sortDirection');
    // const sortColumnFromURL = queryParams.get('sortColumn');
    const pageUrl = queryParams.get('page');
    const cycleFromUrl = queryParams.get('cycle');
    const integrationFromUrl = queryParams.get('integration');
    const clientsFromUrl = queryParams.get('all');
    const campaignFromUrl = queryParams.get('campaign_type');

    const statusFromUrl = queryParams.get('status');
    const serviceFromUrl = queryParams.get('service');
    const serviceTypeOption = serviceTypeOptions.find(
      (option) => option.value === serviceFromUrl
    );
    const cycleOption = cycleOptions.find(
      (option) => option.value === cycleFromUrl
    );

    const campaignOption = campaignOptions.find(
      (option) => option.value === campaignFromUrl
    );
    const statusOption = statusOptions.find(
      (option) => option.value === statusFromUrl
    );
    const integrationOption = integrationOptions.find(
      (option) => option.value === integrationFromUrl
    );
    const clientOption = clientsOptions.find(
      (option) => option.value === clientsFromUrl
    );
    const dateUrl = dateFromUrl ? new Date(dateFromUrl) : date;

    setMount(true);
    // setSortingColumn(sortColumnFromURL);
    // setSortingDirection(sortDirectionFromURL);
    setPage(Number(pageUrl));
    setCycle(cycleOption);
    setType(
      serviceTypeOption
        ? serviceTypeOption
        : { label: 'Google SEO', value: 'seo_google' }
    );
    setCampaignType(campaignOption);
    setIntegration(integrationOption);
    setClients(clientOption);
    setDate(dateUrl);
    setStatus(statusOption);
  }, [location]);

  useEffect(() => {
    if (searchText === '' && mount) {
      handlePerformanceFetch();
    }
  }, [searchText]);

  const handlePerformanceFetch = (isFetchSummary?: boolean, clear = false) => {
    let formattedPerformanceClientFilter = [];
    let formattedPerformanceUserFilter = [];

    if (performanceFilterStorage?.type === 'clients') {
      formattedPerformanceClientFilter = performanceFilterStorage?.options
        ? performanceFilterStorage.options.map((option) => option.id)
        : [];
      formattedPerformanceUserFilter = []; // Ensure users is empty
    } else {
      formattedPerformanceUserFilter = performanceFilterStorage?.options
        ? performanceFilterStorage.options.map((option) => option.id)
        : [];
      formattedPerformanceClientFilter = []; // Ensure clients is empty
    }
    const payload = {
      search: searchText,
      page: page,
      limit: limit,
      all: clients?.value === 'true' || clients?.value == null ? true : false,
      service: type?.value ? type?.value : 'seo_google',
      campaign_type: campaignType?.value,
      report_date: moment(date).format('YYYY-MM'),
      sortBy: sortingColumn || ' ',
      sortOrder: sortingDirection || ' ',
      cycle: cycle?.value || '',
      integration: integration?.value,
      role: '',
      status: status?.value === 'all' ? '' : status?.value,
      exclude: [],
      clients: formattedPerformanceClientFilter,
      users: formattedPerformanceUserFilter,
    };

    setPerformanceFilter(payload);

    if (isFetchSummary) {
      fetchSummary(payload);
    }

    fetchPerformance(payload);
    setRowState(false);
  };

  const handleRegeneratePerformance = (client: any, type: string) => {
    let start_date, end_date;
    // check the client?.cycle if Cycle 1 or Cycle 2
    // if cycle 1, start_date is the first day of the month of the report_date and the end_date is the last day of the month of the report_date
    // if Cycle 2 the start_date is 15th of the month of the report_date and the end_date is the 14th of the next month of the report_date
    if (client?.cycle === 'Cycle 1') {
      start_date = moment(client?.report_date)
        .startOf('month')
        .format('YYYY-MM-DD');
      end_date = moment(client?.report_date)
        .endOf('month')
        .format('YYYY-MM-DD');
    } else {
      start_date = moment(client?.report_date).date(15).format('YYYY-MM-DD');
      end_date = moment(client?.report_date)
        .add(1, 'month')
        .date(14)
        .format('YYYY-MM-DD');
    }

    const payload = {
      service: type,
      clientIds: [client?.client?.id],
      start_date,
      end_date,
    };

    setGeneratedClientId(client?.client?.id);

    const showSuccessMessage = () => {
      toast.success(
        <Alert
          icon=""
          title="Generation In Progress"
          message="The performance generation is currently in progress. You will receive an email notification once it has been successfully completed."
        />,
        {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    };

    const showErrorMessage = (error: any) => {
      toast.error(
        <Alert icon="" title="ERROR MESSAGE!" message={error.message} />,
        {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    };

    console.log(payload);

    setGeneratedClientId(client?.client?.id);
    generatePerformance(payload)
      .then(() => showSuccessMessage())
      .catch((error) => showErrorMessage(error));
  };

  const handleSearch = () => {
    try {
      handlePerformanceFetch(true);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSortTable = (sortColumn, sortDirection) => {
    let newSortDirection = '';

    if (sortingDirection === '') {
      if (sortDirection === 'desc') {
        newSortDirection = 'asc';
      } else {
        newSortDirection = 'desc';
      }
    } else if (sortingDirection === 'asc') {
      newSortDirection = 'desc';
    } else if (sortingDirection === 'desc') {
      newSortDirection = null;
    } else if (sortingDirection === null) {
      newSortDirection = 'asc';
    }

    if (sortingColumn !== sortColumn) {
      newSortDirection = 'asc';
    } else if (sortingDirection === 'asc') {
      newSortDirection = 'desc';
    } else if (sortingDirection === 'desc') {
      newSortDirection = null;
    } else if (sortingDirection === null) {
      newSortDirection = 'asc';
    }

    if (sortDirection === null) {
      setSortingColumn('');
    }

    setSortingDirection(newSortDirection);
    setSortingColumn(sortColumn);
  };

  const formattedDataMemo = useMemo(() => {
    const formatData = (data) => {
      if (type?.value === 'ppc_google') {
        const tableData = data?.map((item) => {
          const currentPeriod = {
            'googleAds.clicks': item?.googleAds?.clicks || 0,
            'googleAds.conversion_rate': item?.googleAds?.conversion_rate || 0,
            'googleAds.conversions': item?.googleAds?.conversions || 0,
            'googleAds.conversions_value':
              item?.googleAds?.conversions_value || 0,
            'googleAds.costPerConversion':
              item?.googleAds?.costPerConversion || 0,
            'googleAds.cpc': item?.googleAds?.cpc || 0,
            'googleAds.ctr': item?.googleAds?.ctr || 0,
            'googleAds.impressions': item?.googleAds?.impressions || 0,
            'googleAds.roas': item?.googleAds?.roas || 0,
          };

          // item?.previous_month?.

          const previousPeriod = {
            'googleAds.clicks': item?.previous_month?.googleAds?.clicks || 0,
            'googleAds.conversion_rate':
              item?.previous_month?.googleAds?.conversion_rate || 0,
            'googleAds.conversions':
              item?.previous_month?.googleAds?.conversions || 0,
            'googleAds.conversions_value':
              item?.previous_month?.googleAds?.conversions_value || 0,
            'googleAds.costPerConversion':
              item?.previous_month?.googleAds?.costPerConversion || 0,
            'googleAds.cpc': item?.previous_month?.googleAds?.cpc || 0,
            'googleAds.ctr': item?.previous_month?.googleAds?.ctr || 0,
            'googleAds.impressions':
              item?.previous_month?.googleAds?.impressions || 0,
            'googleAds.roas': item?.previous_month?.googleAds?.roas || 0,
          };

          const { performancePercentage, outOf, tooltip, positiveCount } =
            calculatePerformancePercentage(
              currentPeriod,
              previousPeriod,
              item?.category_type,
              performanceVisibilityOption,
              'ppc_google'
            );

          return {
            client: item,
            generating: regenerating,
            revenue: formatToCurrency(item?.revenue || 0),
            consultant: {
              name: item?.consultant || 'N/A',
              color:
                item?.health_status?.find((obj: any) =>
                  obj?.role.includes('con')
                )?.val || 'gray',
              hsName:
                item?.health_status.find((obj: any) =>
                  obj?.role.includes('con')
                )?.name || 'N/A',
            },
            account_manager: {
              name: item?.account_manager || 'N/A',
              color:
                item?.health_status?.find((obj: any) => obj?.role === 'am')
                  ?.val || 'gray',
              hsName:
                item?.health_status.find((obj: any) => obj?.role === 'am')
                  ?.name || 'N/A',
            },
            startDate: item?.start_date || 'N/A',
            performance_date: item?.report_date,
            cycle: item?.cycle,
            service: item?.service,
            performance: {
              value: performancePercentage,
              calculated: item?.calculated_fields,
              type: item?.category_type,
              numberOfPositive: positiveCount,
              outOf: outOf,
              tooltip: tooltip,
            },
            clicks: {
              current: item?.googleAds?.clicks
                ? formatNumber(item?.googleAds?.clicks || 0, false, 5)
                : 0,
              message: item?.googleAds?.message,
              previous: item?.previous_month?.googleAds?.clicks
                ? formatNumber(item?.previous_month?.googleAds?.clicks || 0)
                : '0',
              dailyMetrics: item?.googleAds?.dailyMetrics?.clicks,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            conversionRate: {
              current: item?.googleAds?.conversion_rate
                ? formatNumber(item?.googleAds?.conversion_rate || 0, false, 5)
                : 0,
              message: item?.googleAds?.message,
              previous: item?.previous_month?.googleAds?.conversion_rate
                ? formatNumber(
                    item?.previous_month?.googleAds?.conversion_rate || 0,
                    false,
                    5
                  )
                : '0',
              dailyMetrics: item?.googleAds?.dailyMetrics?.conversionRate,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            conversions: {
              current: item?.googleAds?.conversions
                ? formatNumber(item?.googleAds?.conversions || 0, false, 5)
                : 0,
              message: item?.googleAds?.message,
              previous: item?.previous_month?.googleAds?.conversions
                ? formatNumber(
                    item?.previous_month?.googleAds?.conversions || 0,
                    false,
                    5
                  )
                : '0',
              dailyMetrics: item?.googleAds?.dailyMetrics?.conversions,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            conversions_value: {
              current: item?.googleAds?.conversions_value
                ? formatNumber(
                    item?.googleAds?.conversions_value || 0,
                    false,
                    5
                  )
                : 0,
              message: item?.googleAds?.message,
              previous: item?.previous_month?.googleAds?.conversions_value
                ? formatNumber(
                    item?.previous_month?.googleAds?.conversions_value || 0,
                    false,
                    5
                  )
                : '0',
              dailyMetrics: item?.googleAds?.dailyMetrics?.conversions_value,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            costPerConversion: {
              current: item?.googleAds?.costPerConversion
                ? formatNumber(
                    item?.googleAds?.costPerConversion || 0,
                    false,
                    5
                  )
                : 0,
              message: item?.googleAds?.message,
              previous: item?.previous_month?.googleAds?.costPerConversion
                ? formatNumber(
                    item?.previous_month?.googleAds?.costPerConversion || 0,
                    false,
                    5
                  )
                : '0',
              dailyMetrics: item?.googleAds?.dailyMetrics?.costPerConversion,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            cpc: {
              current: item?.googleAds?.cpc
                ? formatNumber(item?.googleAds?.cpc || 0, false, 5)
                : 0,
              message: item?.googleAds?.message,
              previous: item?.previous_month?.googleAds?.cpc
                ? formatNumber(
                    item?.previous_month?.googleAds?.cpc || 0,
                    false,
                    5
                  )
                : '0',
              dailyMetrics: item?.googleAds?.dailyMetrics?.cpc,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            ctr: {
              current: item?.googleAds?.ctr
                ? formatNumber(item?.googleAds?.ctr || 0, false, 5)
                : 0,
              message: item?.googleAds?.message,
              previous: item?.previous_month?.googleAds?.ctr
                ? formatNumber(
                    item?.previous_month?.googleAds?.ctr || 0,
                    false,
                    5
                  )
                : '0',
              dailyMetrics: item?.googleAds?.dailyMetrics?.ctr,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            impressions: {
              current: item?.googleAds?.impressions
                ? formatNumber(item?.googleAds?.impressions || 0, false, 5)
                : 0,
              message: item?.googleAds?.message,
              previous: item?.previous_month?.googleAds?.impressions
                ? formatNumber(
                    item?.previous_month?.googleAds?.impressions || 0,
                    false,
                    5
                  )
                : '0',
              dailyMetrics: item?.googleAds?.dailyMetrics?.impressions,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            roas: {
              current: item?.googleAds?.roas
                ? formatNumber(item?.googleAds?.roas || 0, false, 5)
                : 0,
              message: item?.googleAds?.message,
              previous: item?.previous_month?.googleAds?.roas
                ? formatNumber(
                    item?.previous_month?.googleAds?.roas || 0,
                    false,
                    5
                  )
                : '0',
              dailyMetrics: item?.googleAds?.dailyMetrics?.roas,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
          };
        });
        return tableData;
      }
      if (type?.value === 'social_meta') {
        const tableData = data?.map((item) => {
          const currentPeriod = {
            'meta.impressions': item?.meta?.impressions || 0,
            'meta.reach': item?.meta?.reach || 0,
            'meta.frequency': item?.meta?.frequency || 0,
            'meta.cpm': item?.meta?.cpm || 0,
            'meta.link_clicks': item?.meta?.link_clicks || 0,
            'meta.cpc': item?.meta?.cpc || 0,
            'meta.ctr': item?.meta?.ctr || 0,
            'meta.website_leads': item?.meta?.website_leads || 0,
            'meta.cpl': item?.meta?.cpl || 0,
            'meta.cvr': item?.meta?.cvr || 0,
            'meta.revenue': item?.meta?.revenue || 0,
            'meta.roas': item?.meta?.roas || 0,
          };

          // item?.previous_month?.

          const previousPeriod = {
            'meta.impressions': item?.previous_month?.meta?.impressions || 0,
            'meta.reach': item?.previous_month?.meta?.reach || 0,
            'meta.frequency': item?.previous_month?.meta?.frequency || 0,
            'meta.cpm': item?.previous_month?.meta?.cpm || 0,
            'meta.link_clicks': item?.previous_month?.meta?.link_clicks || 0,
            'meta.cpc': item?.previous_month?.meta?.cpc || 0,
            'meta.ctr': item?.previous_month?.meta?.ctr || 0,
            'meta.website_leads':
              item?.previous_month?.meta?.website_leads || 0,
            'meta.cpl': item?.previous_month?.meta?.cpl || 0,
            'meta.cvr': item?.previous_month?.meta?.cvr || 0,
            'meta.revenue': item?.previous_month?.meta?.revenue || 0,
            'meta.roas': item?.previous_month?.meta?.roas || 0,
          };

          const { performancePercentage, outOf, tooltip, positiveCount } =
            calculatePerformancePercentage(
              currentPeriod,
              previousPeriod,
              item?.category_type,
              performanceVisibilityOption,
              'social_meta'
            );
          return {
            client: item,
            generating: regenerating,
            revenue: formatToCurrency(item?.revenue || 0),
            consultant: {
              name: item?.consultant || 'N/A',
              color:
                item?.health_status?.find((obj: any) =>
                  obj?.role.includes('con')
                )?.val || 'gray',
              hsName:
                item?.health_status.find((obj: any) =>
                  obj?.role.includes('con')
                )?.name || 'N/A',
            },
            account_manager: {
              name: item?.account_manager || 'N/A',
              color:
                item?.health_status?.find((obj: any) => obj?.role === 'am')
                  ?.val || 'gray',
              hsName:
                item?.health_status.find((obj: any) => obj?.role === 'am')
                  ?.name || 'N/A',
            },
            startDate: item?.start_date || 'N/A',
            performance_date: item?.report_date,
            cycle: item?.cycle,
            service: item?.service,
            performance: {
              value: performancePercentage,
              calculated: item?.calculated_fields,
              type: item?.category_type,
              numberOfPositive: positiveCount,
              outOf: outOf,
              tooltip: tooltip,
            },
            impressions: {
              current: item?.meta?.impressions
                ? formatNumber(item?.meta?.impressions || 0)
                : 0,
              message: item?.meta?.message,
              previous: item?.previous_month?.meta?.impressions
                ? formatNumber(item?.previous_month?.meta?.impressions || 0)
                : '0',
              dailyMetrics: item?.meta?.dailyMetrics?.impressions,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            reach: {
              current: item?.meta?.reach
                ? formatNumber(item?.meta?.reach || 0)
                : 0,
              message: item?.meta?.message,
              previous: item?.previous_month?.meta?.reach
                ? formatNumber(item?.previous_month?.meta?.reach || 0)
                : '0',
              dailyMetrics: item?.meta?.dailyMetrics?.reach,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            frequency: {
              current: item?.meta?.frequency
                ? formatNumber(item?.meta?.frequency || 0, false, 5)
                : 0,
              message: item?.meta?.message,
              previous: item?.previous_month?.meta?.frequency
                ? formatNumber(
                    item?.previous_month?.meta?.frequency || 0,
                    false,
                    5
                  )
                : '0',
              dailyMetrics: item?.meta?.dailyMetrics?.frequency,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            cpm: {
              current: item?.meta?.cpm ? formatNumber(item?.meta?.cpm || 0) : 0,
              message: item?.meta?.message,
              previous: item?.previous_month?.meta?.cpm
                ? formatNumber(item?.previous_month?.meta?.cpm || 0)
                : '0',
              dailyMetrics: item?.meta?.dailyMetrics?.cpm,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            cpc: {
              current: item?.meta?.cpc ? formatNumber(item?.meta?.cpc || 0) : 0,
              message: item?.meta?.message,
              previous: item?.previous_month?.meta?.cpc
                ? formatNumber(item?.previous_month?.meta?.cpc || 0)
                : '0',
              dailyMetrics: item?.meta?.dailyMetrics?.cpc,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            link_clicks: {
              current: item?.meta?.link_clicks
                ? formatNumber(item?.meta?.link_clicks || 0)
                : 0,
              message: item?.meta?.message,
              previous: item?.previous_month?.meta?.link_clicks
                ? formatNumber(item?.previous_month?.meta?.link_clicks || 0)
                : '0',
              dailyMetrics: item?.meta?.dailyMetrics?.link_clicks,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            ctr: {
              current: item?.meta?.ctr ? formatNumber(item?.meta?.ctr || 0) : 0,
              message: item?.meta?.message,
              previous: item?.previous_month?.meta?.ctr
                ? formatNumber(item?.previous_month?.meta?.ctr || 0)
                : '0',
              dailyMetrics: item?.meta?.dailyMetrics?.ctr,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            website_leads: {
              current: item?.meta?.website_leads
                ? formatNumber(item?.meta?.website_leads || 0)
                : 0,
              message: item?.meta?.message,
              previous: item?.previous_month?.meta?.website_leads
                ? formatNumber(item?.previous_month?.meta?.website_leads || 0)
                : '0',
              dailyMetrics: item?.meta?.dailyMetrics?.website_leads,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            cpl: {
              current: item?.meta?.cpl ? formatNumber(item?.meta?.cpl || 0) : 0,
              message: item?.meta?.message,
              previous: item?.previous_month?.meta?.cpl
                ? formatNumber(item?.previous_month?.meta?.cpl || 0)
                : '0',
              dailyMetrics: item?.meta?.dailyMetrics?.cpl,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            cvr: {
              current: item?.meta?.cvr ? formatNumber(item?.meta?.cvr || 0) : 0,
              message: item?.meta?.message,
              previous: item?.previous_month?.meta?.cvr
                ? formatNumber(item?.previous_month?.meta?.cvr || 0)
                : '0',
              dailyMetrics: item?.meta?.dailyMetrics?.cvr,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            revenueMeta: {
              current: item?.meta?.revenue
                ? formatNumber(item?.meta?.revenue || 0)
                : 0,
              message: item?.meta?.message,
              previous: item?.previous_month?.meta?.revenue
                ? formatNumber(item?.previous_month?.meta?.revenue || 0)
                : '0',
              dailyMetrics: item?.meta?.dailyMetrics?.revenue,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            roas: {
              current: item?.meta?.roas
                ? formatNumber(item?.meta?.roas || 0, false, 5)
                : 0,
              message: item?.meta?.message,
              previous: item?.previous_month?.meta?.roas
                ? formatNumber(item?.previous_month?.meta?.roas || 0, false, 5)
                : '0',
              dailyMetrics: item?.meta?.dailyMetrics?.roas,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
          };
        });

        return tableData;
      } else {
        const tableData = data?.map((item) => {
          const currentPeriod = {
            'ga4.new_users': item?.ga4?.new_users || 0,
            'ga4.organic_new_users': item?.ga4?.organic_new_users || 0,
            'ga4.organic_revenue': item?.ga4?.organic_revenue || 0,
            'ga4.organic_conversions': item?.ga4?.organic_conversions || 0,
            'gsc.clicks': item?.gsc?.clicks || 0,
            'gsc.impressions': item?.gsc?.impressions || 0,
            'ahrefs.top_3_keywords_reach':
              item?.ahrefs?.top_3_keywords_reach || 0,
            'ahrefs.top_10_keywords_reach':
              item?.ahrefs?.top_10_keywords_reach || 0,
            'ahrefs.top_1_keywords_reach':
              item?.ahrefs?.top_1_keywords_reach || 0,
            'ahrefs.refdomains': item?.ahrefs?.refdomains || 0,
            'return.conversions': item?.return?.conversions || 0,
            'return.ecommerce': item?.return?.ecommerce || 0,
          };

          const previousPeriod = {
            'ga4.new_users': item?.previous_month?.ga4?.new_users || 0,
            'ga4.organic_new_users':
              item?.previous_month?.ga4?.organic_new_users || 0,
            'ga4.organic_revenue':
              item?.previous_month?.ga4?.organic_revenue || 0,
            'ga4.organic_conversions':
              item?.previous_month?.ga4?.organic_conversions || 0,
            'gsc.clicks': item?.previous_month?.gsc?.clicks || 0,
            'gsc.impressions': item?.previous_month?.gsc?.impressions || 0,
            'ahrefs.top_3_keywords_reach':
              item?.previous_month?.ahrefs?.top_3_keywords_reach || 0,
            'ahrefs.top_10_keywords_reach':
              item?.previous_month?.ahrefs?.top_10_keywords_reach || 0,
            'ahrefs.top_1_keywords_reach':
              item?.previous_month?.ahrefs?.top_1_keywords_reach || 0,
            'ahrefs.refdomains': item?.previous_month?.ahrefs?.refdomains || 0,
            'return.conversions':
              item?.previous_month?.return?.conversions || 0,
            'return.ecommerce': item?.previous_month?.return?.ecommerce || 0,
          };

          const { performancePercentage, outOf, tooltip, positiveCount } =
            calculatePerformancePercentage(
              currentPeriod,
              previousPeriod,
              item?.category_type,
              performanceVisibilityOption,
              'seo_google'
            );
          return {
            client: item,
            generating: item,
            revenue: formatToCurrency(item?.revenue || 0),
            consultant: {
              name: item?.consultant || 'N/A',
              color:
                item?.health_status?.find((obj: any) =>
                  obj?.role.includes('con')
                )?.val || 'gray',
              hsName:
                item?.health_status.find((obj: any) =>
                  obj?.role.includes('con')
                )?.name || 'N/A',
            },
            account_manager: {
              name: item?.account_manager || 'N/A',
              color:
                item?.health_status?.find((obj: any) => obj?.role === 'am')
                  ?.val || 'gray',
              hsName:
                item?.health_status.find((obj: any) => obj?.role === 'am')
                  ?.name || 'N/A',
            },
            startDate: item?.start_date || 'N/A',
            performance_date: item?.report_date,
            cycle: item?.cycle,
            service: item?.service,
            performance: {
              value: performancePercentage,
              calculated: item?.calculated_fields,
              type: item?.category_type,
              numberOfpositive: positiveCount,
              outOf: outOf,
              tooltip: tooltip,
            },

            organicRevenue: {
              current: item?.ga4?.organic_revenue
                ? formatNumber(item?.ga4?.organic_revenue || 0)
                : 0,
              message: item?.notes?.ga4,
              previous: item?.previous_month?.ga4?.organic_revenue
                ? formatNumber(item?.previous_month?.ga4?.organic_revenue || 0)
                : '0',
              dailyMetrics: item?.ga4?.dailyMetrics?.organic_revenue,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            organicConversions: {
              current: item?.ga4?.organic_conversions
                ? formatNumber(item?.ga4?.organic_conversions || 0)
                : 0,
              message: item?.notes?.ga4,
              previous: item?.previous_month?.ga4?.organic_conversions
                ? formatNumber(
                    item?.previous_month?.ga4?.organic_conversions || 0
                  )
                : '0',
              dailyMetrics: item?.ga4?.dailyMetrics?.organic_conversions,
              clientName: item?.client?.name,
            },
            organicUsers: {
              current: item?.ga4?.organic_new_users
                ? formatNumber(item?.ga4?.organic_new_users || 0)
                : 0,
              message: item?.notes?.ga4,
              previous: item?.previous_month?.ga4?.organic_new_users
                ? formatNumber(
                    item?.previous_month?.ga4?.organic_new_users || 0
                  )
                : '0',
              dailyMetrics: item?.ga4?.dailyMetrics?.organic_new_users,
              clientName: item?.client?.name,
            },
            newUsers: {
              current: item?.ga4?.new_users
                ? formatNumber(item?.ga4?.new_users || 0)
                : 0,
              message: item?.notes?.ga4,
              previous: item?.previous_month?.ga4?.new_users
                ? formatNumber(item?.previous_month?.ga4?.new_users || 0)
                : '0',
              dailyMetrics: item?.ga4?.dailyMetrics?.all_new_users,
              clientName: item?.client?.name,
            },
            clicks: {
              current: item?.gsc?.clicks
                ? formatNumber(item?.gsc?.clicks || 0)
                : 0,
              message: item?.notes?.gsc,
              previous: item?.previous_month?.gsc?.clicks
                ? formatNumber(item?.previous_month?.gsc?.clicks || 0)
                : '0',
              dailyMetrics: item?.gsc?.dailyMetrics?.clicks,
              clientName: item?.client?.name,
            },
            impressions: {
              current: item?.gsc?.impressions
                ? formatNumber(item?.gsc?.impressions || 0)
                : 0,
              message: item?.notes?.gsc,
              previous: item?.previous_month?.gsc?.impressions
                ? formatNumber(item?.previous_month?.gsc?.impressions || 0)
                : '0',
              dailyMetrics: item?.gsc?.dailyMetrics?.impressions,
              clientName: item?.client?.name,
            },
            ahrefs_top_01: {
              current: item?.ahrefs?.top_1_keywords_reach
                ? formatNumber(item?.ahrefs?.top_1_keywords_reach || 0)
                : 0,
              message: item?.notes?.ahrefs,
              previous: formatNumber(
                item?.previous_month?.ahrefs?.top_1_keywords_reach || 0
              ),
            },
            ahrefsTop03: {
              current: item?.ahrefs?.top_3_keywords_reach
                ? formatNumber(item?.ahrefs?.top_3_keywords_reach || 0)
                : 0,
              message: item?.notes?.ahrefs,
              previous: formatNumber(
                item?.previous_month?.ahrefs?.top_3_keywords_reach || 0
              ),
            },
            ahrefs_top_10: {
              current: item?.ahrefs?.top_10_keywords_reach
                ? formatNumber(item?.ahrefs?.top_10_keywords_reach || 0)
                : 0,
              message: item?.notes?.ahrefs,
              previous: formatNumber(
                item?.previous_month?.ahrefs?.top_10_keywords_reach || 0
              ),
            },
            conversions_return: {
              current: item?.return?.conversions
                ? formatNumber(item?.return?.conversions || 0, false, 4)
                : 0,
              message: item?.notes?.return,
              previous: item?.previous_month?.return?.conversions
                ? formatNumber(
                    item?.previous_month?.return?.conversions || 0,
                    false,
                    4
                  )
                : '0',
            },
            ecommerce_return: {
              current: item?.return?.ecommerce
                ? formatNumber(item?.return?.ecommerce || 0, false, 4)
                : 0,
              message: item?.notes?.return,
              previous: item?.previous_month?.return?.ecommerce
                ? formatNumber(
                    item?.previous_month?.return?.ecommerce || 0,
                    false,
                    4
                  )
                : '0',
              type: item?.category_type,
            },

            referring_domain: {
              current: item?.ahrefs?.refdomains
                ? formatNumber(item?.ahrefs?.refdomains || 0)
                : 0,
              message: item?.notes?.ahrefs,
              previous: item?.previous_month?.ahrefs?.refdomains
                ? formatNumber(item?.previous_month?.ahrefs?.refdomains || 0)
                : '0',
            },
          };
        });

        return tableData;
      }
    };
    const formattedData = formatData(performance.data);
    return formattedData;
  }, [performance.data, performanceVisibilityOption, type, generatedClientId]);

  const editClientPerformance = (item: any) => {
    setSelectedPerformance(item);
    setOpenEditModal(true);
  };

  const generateColumns = (type, options) => {
    if (type === 'seo_google') {
      return generateSeoColumns(options);
    } else if (type === 'social_meta') {
      return generateMetaColumns(options);
    } else {
      return generatePpcColumns(options);
    }
  };

  const columns = useMemo(
    () =>
      generateColumns(type?.value ? type?.value : 'seo_google', {
        sortingColumn,
        sortingDirection,
        editClientPerformance,
        handleRegeneratePerformance,
        regenerating,
        generatedClientId,
        integration,
      }),
    [
      sortingColumn,
      sortingDirection,
      editClientPerformance,
      generatedClientId,
      integration,
    ]
  );

  const handleFilterChange = (selectedOption: any, actionMeta: string) => {
    const newUrl = new URL(window.location.href);
    if (actionMeta === 'status') {
      // setStatus(selectedOption);
      newUrl.searchParams.set('status', selectedOption.value);
    }
    // if (actionMeta === 'role') {
    //   setRole(selectedOption);
    //   newUrl.searchParams.set('role', selectedOption.value);
    // }
    if (actionMeta === 'type') {
      setSelectedType(selectedOption);
      newUrl.searchParams.set('type', selectedOption.value);
    }
    if (actionMeta === 'date') {
      setDate(selectedOption);
      newUrl.searchParams.set('date', moment(selectedOption).format('YYYY-MM'));
    }
    if (actionMeta === 'cycle') {
      setCycle(selectedOption);
      newUrl.searchParams.set('cycle', selectedOption.value);
    }
    if (actionMeta === 'client') {
      setClients(selectedOption);
      newUrl.searchParams.set(
        'all',
        selectedOption.value === 'true' ? 'true' : 'false'
      );
    }
    if (actionMeta === 'users') {
      setClients(selectedOption);
      newUrl.searchParams.set('client', selectedOption.value);
    }
    if (actionMeta === 'integration') {
      setIntegration(selectedOption);
      newUrl.searchParams.set('integration', selectedOption.value);
    }

    if (actionMeta === 'campaign') {
      setCampaignType(selectedOption);
      newUrl.searchParams.set('campaign_type', selectedOption.value);
    }

    if (actionMeta === 'service') {
      setType(selectedOption);
      newUrl.searchParams.set('service', selectedOption.value);
      console.log(selectedOption.value, 'value');
      if (selectedOption.value !== 'seo_google') {
        setCycle({ value: 'all', label: 'All Cycles' });
        newUrl.searchParams.set('cycle', '');
      }
    }

    window.history.pushState({}, '', newUrl);
  };

  const getActiveFilterCount = () => {
    let count = 0;
    if (campaignType) count += 1;
    if (clients) count += 1;
    if (integration) count += 1;
    return count;
  };

  return (
    <div className="bg-[#D2EEFF] mt-12 px-2 sm:mt-0 sm:py-3 sm:px-[32px] overflow-hidden min-h-[93dvh] rounded-md dark:bg-[#191919] font-inter scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8 rounded-lg">
      <div className="bg-[#D2EEFF] pt-2 sm:pt-0 rounded-lg dark:bg-[#0C111D]">
        <div className="flex items-center justify-between flex-wrap mt-4 px-2 py-4 sm:mt-0 sm:pt-14 lg:p-4 bg-white dark:bg-gray-800 lg:sticky top-0 z-[13] rounded-t-lg">
          <div className="flex flex-wrap items-center gap-[16px] relative">
            <div
              className={`flex ${performanceFilterStorage?.options?.length > 0 ? 'justify-start' : 'justify-center text-[#525252]'} items-center gap-x-1 p-1 z-20 cursor-pointer`}
            >
              <div className="z-20">
                <ClientUserSearchBar
                  roleOptions={[
                    { value: 'clients', label: 'Clients' },
                    { value: 'users', label: 'Users' },
                  ]}
                  useFilterStorage={performanceFilterStorage}
                  storageKey="performance-filter-storage"
                />
              </div>
            </div>
            <div className="font-inter w-[150px]">
              <DatePicker
                selected={moment(date, 'YYYY-MM').toDate()}
                onChange={(date) => {
                  if (date !== null) {
                    handleFilterChange(date, 'date');
                  }
                }}
                dateFormat="MMM yyyy"
                maxDate={maxDate}
                showMonthYearPicker
                className="react-datepicker__month-container text-center h-[38.5px] border border-[#808EA2] cursor-pointer myDatePicker dark:bg-[#374151] dark:text-white dark:border-none mt-2 font-inter rounded-lg text-[14px] text-[#525252]"
              />
            </div>
            <div className="max-w-[300px]">
              <ReactSelect
                options={serviceTypeOptions}
                value={type}
                handleOnChange={(selectedOption: any) =>
                  handleFilterChange(selectedOption, 'service')
                }
                placeholder="All Services"
              />
            </div>
            {type?.value === 'seo_google' && (
              <div>
                <ReactSelect
                  options={cycleOptions}
                  value={cycle}
                  placeholder="All Cycles"
                  handleOnChange={(selectedOption: any) =>
                    handleFilterChange(selectedOption, 'cycle')
                  }
                />
              </div>
            )}
            <div className="relative" ref={filterModalRef}>
              <div
                className={`${openFilterModal ? 'hidden' : 'flex gap-3 items-center justify-between border border-[#808EA2] dark:border-0 rounded-[8px] py-[6px] px-[14px] cursor-pointer  w-[185px] dark:bg-[#374151]'} `}
                onClick={() => setOpenFilterModal(true)}
              >
                <div className="text-sm text-[#001C44] dark:text-gray-300">
                  More Filters
                </div>
                <div className="w-[10px] font-bold text-[10px] text-center bg-[#FF0000] text-white rounded">
                  {getActiveFilterCount() > 0 && `${getActiveFilterCount()}`}
                </div>
                <div>
                  <Icon name="ChevronDown" />
                </div>
              </div>
              {openFilterModal && (
                <div className="absolute top-[-20px] border border-[#808EA2]  rounded-[8px] bg-white dark:bg-gray-900">
                  <div
                    className="flex gap-3 items-center justify-between border-b  py-[6px] px-[14px] cursor-pointer w-[185px]"
                    onClick={() => setOpenFilterModal(false)}
                  >
                    <div className="text-sm text-[#001C44] dark:text-gray-400">
                      More Filters
                    </div>
                    <div>
                      <Icon name="ChevronUp" />
                    </div>
                  </div>
                  <div className="p-[10px] flex flex-col gap-3">
                    <div>
                      <ReactSelect
                        options={campaignOptions}
                        value={campaignType}
                        placeholder="All Campaign"
                        handleOnChange={(selectedOption: any) =>
                          handleFilterChange(selectedOption, 'campaign')
                        }
                      />
                    </div>
                    <div>
                      <ReactSelect
                        options={clientsOptions}
                        value={clients}
                        placeholder="All Clients"
                        handleOnChange={(selectedOption: any) =>
                          handleFilterChange(selectedOption, 'client')
                        }
                      />
                    </div>
                    <div>
                      <ReactSelect
                        options={integrationOptions}
                        value={integration}
                        placeholder="Integrated"
                        handleOnChange={(selectedOption: any) =>
                          handleFilterChange(selectedOption, 'integration')
                        }
                      />
                    </div>
                  </div>
                  <div
                    className="flex gap-3 items-center justify-between border-t  p-[14px] cursor-pointer w-[185px]"
                    onClick={() => setOpenFilterModal(false)}
                  >
                    <div
                      className="text-xs text-[#001C44] dark:text-gray-400"
                      onClick={handleResetAllFilter}
                    >
                      Reset All
                    </div>
                    <div
                      className="text-xs text-[#0029FF] font-[600]"
                      onClick={handleApplyFilter}
                    >
                      Apply Filter
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="hidden sm:flex items-center gap-1">
            <div className="flex gap-x-3 items-center">
              <button
                className="p-2 rounded-md border border-[#808EA2]"
                onClick={() => setShowExportModal(true)}
              >
                <Icon
                  name="Download"
                  size={16}
                  color={mode === 'dark' ? '#fff' : iconColor}
                />
              </button>
              <button
                className="flex items-center p-2 gap-1 rounded-lg border border-[#808EA2]"
                onClick={handleVisibilityClick}
              >
                <span className="text-[#001C44] text-sm font-semibold dark:text-white">
                  Columns
                </span>
                <div className="w-[10px] font-bold text-[8px] bg-[#FF0000] text-white rounded">
                  {hiddenColumns > 0 && hiddenColumns}
                </div>
                <Icon
                  name="offEye"
                  color={mode === 'dark' ? '#fff' : iconColor}
                />
              </button>
            </div>
          </div>
        </div>
        <TansStackTableV2
          data={!formattedDataMemo ? [] : formattedDataMemo}
          columns={columns}
          totalPages={performance?.total_pages}
          page={page}
          setPage={setPage}
          loading={performance?.loading}
          rowState={rowState}
          setRowState={setRowState}
          totalDocs={performance?.total_docs}
          stickyColumn="name"
          sortData={handleSortTable}
          limit={limit}
          setLimit={setLimit}
        />
      </div>

      <CustomModal
        open={openInsightModal}
        onClose={() => setOpenInsightModal(false)}
      >
        <PerformanceInsightsModal onClose={() => setOpenInsightModal(false)} />
      </CustomModal>
      <CustomModal open={openEditModal} onClose={() => setOpenEditModal(false)}>
        <PerformanceModal
          onClose={() => setOpenEditModal(false)}
          clientPerformance={selectedPerformance}
          handleFetchReports={handlePerformanceFetch}
          type={type?.value}
        />
      </CustomModal>
      <CustomModal
        open={showExportModal}
        onClose={() => {
          setRowState(false);
          setShowExportModal(false);
        }}
      >
        <ExportModal
          closeModal={() => {
            setRowState(false);
            setShowExportModal(false);
          }}
          selectDate={date}
          campaign={campaignType}
          clients={clients}
          integration={integration}
        />
      </CustomModal>
      <ToastContainer
        position="top-right"
        autoClose={30000}
        hideProgressBar
        newestOnTop={false}
        transition={Flip}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default Performance;
