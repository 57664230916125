import React, { useState, useEffect, ChangeEvent, KeyboardEvent } from 'react';
import { useNavigate } from 'react-router';
import useAuthStore from '../../store/useAuthStore';
import { loginRequest } from '../../config';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from '@azure/msal-browser';
import { GoogleLogin } from '@react-oauth/google';
import { Logo, Microsoft, Star } from '../../assets/icons';
import sMockup from '../../assets/imgs/FocalPoint.png';
import { Link } from 'react-router-dom';
import { FadeLoader } from 'react-spinners';
import { Flip, ToastContainer, toast } from 'react-toastify';
import { useThemeStore } from '../../store/useThemeStore';

interface FormData {
  email: string;
  password: string;
}
interface GoogleLoginResponse {
  credential?: string;
}
export default function Login(): JSX.Element {
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [formData, setForm] = useState<FormData>({ email: '', password: '' });
  const {
    token,
    login,
    googleLogin,
    microsoftLogin,
    user,
    googleLoginError,
    microsoftLoginError,
    error: authError,
  } = useAuthStore((state) => state);
  const [errorMessage, setErrorMsg] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const { mode } = useThemeStore((state) => state);

  const navigate = useNavigate();
  const loginClicked = () => {
    if (validate()) {
      setIsLoading(true);
      login(formData).catch((err: { message: string }) => {
        setErrorMsg(err?.message);
        setIsLoading(false);
      });
    }
  };
  useEffect(() => {
    if (token) {
      navigate('users');
      setIsLoading(false);
    }
  }, [token]);

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({ ...formData, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    if (isAuthenticated) {
      loginWithMicrosoft();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (authError) {
      if (googleLoginError !== '') {
        toast.error(googleLoginError);
      }
      if (microsoftLoginError !== '') {
        toast.error(microsoftLoginError);
      }
    }
  }, [googleLoginError, microsoftLoginError, authError]);

  const validate = () => {
    if (formData.email?.length === 0) {
      setErrorMsg('Email is required.');
      return false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email?.trim())) {
      setErrorMsg('Email is not valid!');
      return false;
    } else if (formData.password.length === 0) {
      setErrorMsg('Password is required.');
      return false;
    } else if (formData.password.length < 6) {
      setErrorMsg('Password Must be at least 6 characters');
      return false;
    } else {
      setErrorMsg('');
      return true;
    }
  };

  const handleLogin = async (googleData: GoogleLoginResponse) => {
    if (googleData.credential) {
      googleLogin({
        idToken: googleData.credential,
      });
    }
    if (token && user) {
      navigate('/home');
    }
  };

  const loginWithMicrosoft = () => {
    console.log(InteractionStatus.None, inProgress);

    const accessTokenRequest = {
      scopes: ['api://636dd677-5f05-4ea8-9f63-c85b0f2ffeed/User.Read'],
      account: accounts[0],
    };
    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        // Acquire token silent success
        let idToken = accessTokenResponse.accessToken;
        // Call your API with token
        microsoftLogin({ idToken });
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance
            .acquireTokenPopup(accessTokenRequest)
            .then(function (accessTokenResponse) {
              // Acquire token interactive success
              let idToken = accessTokenResponse.accessToken;
              // Call your API with token
              // console.log({ idToken });
              microsoftLogin({ idToken });
            })
            .catch(function (error) {
              // Acquire token interactive failure
              console.log(error);
              setErrorMsg(error);
            });
        }
        console.log(error);
      });
  };

  const handleKeyDown = (event: globalThis.KeyboardEvent) => {
    if (event.key === 'Enter') {
      loginClicked();
    }
  };
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [formData]);

  // define sizes for logo breakpoint
  const [size, setSize] = useState(50);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        setSize(30);
      } else if (window.innerWidth < 768) {
        setSize(45);
      } else {
        setSize(50);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial size

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="flex flex-col w-screen h-screen lg:flex-row overflow-hidden  max-w-[1920px] font-inter">
      <div className="flex flex-col items-center justify-center bg-white w-full h-full  lg:w-1/2 dark:bg-[#0C111D]">
        <div className="w-full lg:w-2/3 h-full mt-[6%] px-8 ">
          <div className="flex items-center w-full justify-start">
            <Logo size={size} color="#0029FF" />
          </div>
          <h1 className="text-[36px] mt-7 mb-3 md:text-4xl font-semibold dark:text-white font-archivo">
            Log in
          </h1>
          <span className="text-[#475467] text-[16px]  font-light font-inter dark:text-blue-200">
            Welcome back! Please enter your details.
          </span>
          <div className="flex flex-col items-start mt-8 gap-5">
            <div className="w-full">
              <span className="text-[#001C44] text-sm font-inter dark:text-blue-300">
                Email
              </span>
              <input
                type="text"
                name="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={(e) => handleInput(e)}
                className="w-full border border-[#808EA2] rounded-lg px-3 py-2 mt-2"
              />
            </div>
            <div className="w-full relative">
              <span className="text-[#001C44] text-sm font-inter dark:text-blue-300">
                Password
              </span>
              <input
                type="password"
                name="password"
                placeholder="••••••••"
                value={formData.password}
                onChange={(e) => handleInput(e)}
                className="w-full border border-[#808EA2] rounded-lg px-3 py-2 mt-2"
              />
              {errorMessage && (
                <div className="text-red-500 my-2 text-sm ml-1 font-inter">
                  {errorMessage}
                </div>
              )}
              <div className="flex items-center justify-between my-6">
                <div className="flex items-center gap-2 w-fit">
                  <input type="checkbox" />
                  <span className="text-[#001C44] text-sm font-inter dark:text-blue-300">
                    Remember for 30 days
                  </span>
                </div>
                <Link
                  to="/forgot-password"
                  className="text-[#0029FF] text-sm font-semibold cursor-pointer dark:text-white"
                >
                  Forgot password
                </Link>
              </div>
              <div className="relative">
                <button
                  className="btn-primary w-full px-4 py-2 bg-[#0029FF] text-white border border-[#0029FF] rounded-lg font-semibold font-inter"
                  onClick={loginClicked}
                >
                  Sign in
                </button>
                {isLoading ? (
                  <div className="absolute bottom-1 right-14  w-full h-full flex items-center justify-center">
                    <FadeLoader
                      height={8}
                      width={3}
                      color="#fff"
                      style={{ transform: 'scale(0.6)' }}
                    />
                  </div>
                ) : null}
              </div>
              <div className="flex flex-col gap-3 mt-5 font-inter">
                <GoogleLogin
                  logo_alignment="center"
                  text="signin_with"
                  type="standard"
                  shape="rectangular"
                  width={'100%'}
                  onSuccess={handleLogin}
                  onError={
                    ((err: any) => {
                      console.log('Login Failed: ' + err);
                    }) as any
                  }
                  useOneTap
                />
                <div
                  className="flex h-[38px] items-center justify-center w-full gap-3 px-4 py-2 border border-[#E6EBF0] rounded-lg cursor-pointer"
                  onClick={() =>
                    instance.loginRedirect(loginRequest).catch((e) => {
                      console.log(e?.message || 'Something wrong!');
                    })
                  }
                >
                  <Microsoft />
                  <span className="text-[#001C44] text-sm font-inter dark:text-white">
                    Sign in with Microsoft
                  </span>
                </div>
              </div>
              <div className="w-full mt-10 ">
                <span className="text-[#475467] font-inter">
                  © Gurulytics 2024
                </span>
              </div>
              {/* <div className="flex items-center justify-center gap-1 mt-8">
                <span className="text-[#475467]">Don’t have an account?</span>
                <span className="text-[#005C9F] font-semibold">Sign up</span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:flex flex-col justify-between w-full h-full bg-[#E8F6FF] pt-24 lg:w-1/2 overflow-hidden dark:bg-[#1F2937]">
        <div className="px-20">
          <h3 className="text-[30px] text-[#001C44] font-normal font-inter dark:text-white">
            We have used OMG with multiple businesses, and they have always over
            delivered their results and smashed the KPI’s. Highly recommended.
          </h3>
          <div className="flex items-center justify-between mt-6">
            <span className="text-[18px] text-[#001C44] font-semibold dark:text-[#009EFF]">
              — Ali Olmez
            </span>
            <div className="flex items-center gap-[2px]">
              <Star color={mode === 'dark' ? '#009EFF' : '#FFB545'} />
              <Star color={mode === 'dark' ? '#009EFF' : '#FFB545'} />
              <Star color={mode === 'dark' ? '#009EFF' : '#FFB545'} />
              <Star color={mode === 'dark' ? '#009EFF' : '#FFB545'} />
              <Star color={mode === 'dark' ? '#009EFF' : '#FFB545'} />
            </div>
          </div>
          <span className="text-[16px] text-[#001C44] dark:text-gray-400">
            CEO, Jim's Cleaning
          </span>
        </div>
        <div className="w-full pl-16 mt-10">
          <img src={sMockup} alt="sMockup" className="" />
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={30000}
        hideProgressBar
        newestOnTop={false}
        transition={Flip}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}
