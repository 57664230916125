import moment from 'moment';
import Icon from '../../assets/icons/SvgComponent';
import {
  formatNumber,
  getStatusBadgeColor,
  getTrackerColor,
} from '../../utils/common';
import { ProgressCircle } from '@tremor/react';
import { getMetricSourceIcon } from './constants';
import Tracker from '../../components/Tracker';
import ImageIcon from '../../components/ImageIcon';
import ProfileTooltip from '../../components/ProfileTooltip';
// Define the props for the generateColumns function
interface GenerateColumnsProps {
  sortingColumn: any;
  sortingDirection: any;
  editGoal: (data) => void;
  mode: string;
  handleSyncGoal: (data) => void;
  syncLoading: boolean;
  isExpanded: any;
  setIsExpanded: any;
  setSelectedGoal: any;
  selectedGoal: any;
}
// Function to generate columns
export const generateColumns = ({
  sortingColumn,
  sortingDirection,
  editGoal,
  mode,
  handleSyncGoal,
  syncLoading,
  isExpanded,
  setIsExpanded,
  setSelectedGoal,
  selectedGoal,
}: GenerateColumnsProps) => [
  //goal name
  {
    id: 'Goal Name',
    header: () => (
      <div className="w-[200px] sm:w-[14vw] h-[30px] flex items-center pl-4">
        <div className="flex w-[200px] sm:w-[14vw] justify-start items-center gap-2 cursor-pointer">
          <div className="text-left text-sm font-inter w-fit">Goal Name</div>
          <div>
            {sortingColumn === 'Goal Name' ? (
              sortingDirection === 'asc' ? (
                <Icon name="Arrowup" />
              ) : sortingDirection === 'desc' ? (
                <Icon name="ArrowDown" />
              ) : (
                <Icon name="Sort" />
              )
            ) : (
              <Icon name="Sort" />
            )}
          </div>
        </div>
      </div>
    ),
    accessorKey: 'goal',
    cell: (props: any) => {
      return (
        <div className="w-[200px] sm:w-[14vw] flex flex-col justify-center items-start cursor-pointer h-[70px] pl-4 gap-2">
          <div className="text-[14px] max-w-[200px] sm:max-w-[14vw] font-inter font-[600] text-[#001C44] dark:text-[#FFF] truncate">
            {props.getValue()?.name}
          </div>
          <div className="flex gap-2 justify-center items-center">
            <div className="w-[20px] flex justify-center items-center">
              <Icon
                name={getMetricSourceIcon(props.getValue()?.source)?.value}
                size={props.getValue()?.source === 'ahrefs' ? 11 : 18}
              />
            </div>
            <div className="text-[12px] max-w-[200px] px-[8px] rounded sm:max-w-[14vw] font-inter font-[500] text-[#001C44] dark:text-[#FFF] bg-[#F2F4FF]">
              {props.getValue()?.metricDisplay}
            </div>
          </div>
        </div>
      );
    },
  },
  // name column
  {
    id: 'client',
    header: () => (
      <div className="w-[180px] sm:w-[8vw] h-[30px] flex items-center">
        <div className="flex w-[180px] sm:w-[8vw] justify-start items-center gap-2 cursor-pointer">
          <div className="text-left text-sm font-inter w-fit">Client</div>
          <div>
            {sortingColumn === 'client' ? (
              sortingDirection === 'asc' ? (
                <Icon name="Arrowup" />
              ) : sortingDirection === 'desc' ? (
                <Icon name="ArrowDown" />
              ) : (
                <Icon name="Sort" />
              )
            ) : (
              <Icon name="Sort" />
            )}
          </div>
        </div>
      </div>
    ),
    accessorKey: 'client',
    cell: (props: any) => {
      return (
        <div className="w-[180px] sm:w-[8vw] flex item-start">
          <div className="flex justify-start items-start cursor-pointer gap-2">
            <div className="text-sm max-w-[180px] sm:max-w-[8vw] text-left font-inter font-[500] text-[#001C44] dark:text-[#FFF] truncate">
              {props.getValue()}
            </div>
          </div>
        </div>
      );
    },
  },

  //metric
  // {
  //   id: 'Metric',
  //   header: () => (
  //     <div className="w-full h-[30px] flex items-center px-4">
  //       <div className="flex justify-start items-center gap-2 cursor-pointer">
  //         <div className="text-left text-sm font-inter w-fit">Metric</div>
  //         <div>
  //           {sortingColumn === 'name' ? (
  //             sortingDirection === 'asc' ? (
  //               <Icon name="Arrowup" />
  //             ) : sortingDirection === 'desc' ? (
  //               <Icon name="ArrowDown" />
  //             ) : (
  //               <Icon name="Sort" />
  //             )
  //           ) : (
  //             <Icon name="Sort" />
  //           )}
  //         </div>
  //       </div>
  //     </div>
  //   ),
  //   accessorKey: 'metricDisplay',
  //   cell: (props: any) => {
  //     return (
  //       <div className="w-full flex justify-start items-center cursor-pointer px-4 h-[70px]">
  //         <div className="text-sm text-left max-w-[200px] font-inter font-[500] text-[#001C44] dark:text-[#FFF] overflow-hidden line-clamp-2 capitalize">
  //           {props.getValue()}
  //         </div>
  //       </div>
  //     );
  //   },
  // },
  // {
  //   id: 'Start Date',
  //   header: () => (
  //     <div className="w-[200px] sm:w-[9vw] h-[30px] flex items-center px-4">
  //       <div className="flex w-[200px] sm:w-[9vw] justify-start items-center gap-2 cursor-pointer">
  //         <div className="text-left text-sm font-inter w-fit">Start Date</div>
  //         <div>
  //           {sortingColumn === 'Start Date' ? (
  //             sortingDirection === 'asc' ? (
  //               <Icon name="Arrowup" />
  //             ) : sortingDirection === 'desc' ? (
  //               <Icon name="ArrowDown" />
  //             ) : (
  //               <Icon name="Sort" />
  //             )
  //           ) : (
  //             <Icon name="Sort" />
  //           )}
  //         </div>
  //       </div>
  //     </div>
  //   ),
  //   accessorKey: 'goalLimit',
  //   cell: (props: any) => {
  //     return (
  //       <div className="w-[200px] sm:w-[9vw] flex justify-start items-center cursor-pointer px-4 h-[100px] text-sm dark:text-white">
  //         {moment(props.getValue()?.startDate).format('MMMM D, YYYY')}
  //       </div>
  //     );
  //   },
  // },

  {
    id: 'frequency',
    header: () => (
      <div className="w-[200px] sm:w-[22vw] h-[30px] flex items-center">
        <div className="flex w-[200px] sm:w-[22vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-left text-sm font-inter w-fit">
            Overall Progress
          </div>
          <div>
            {sortingColumn === 'frequency' ? (
              sortingDirection === 'asc' ? (
                <Icon name="Arrowup" />
              ) : sortingDirection === 'desc' ? (
                <Icon name="ArrowDown" />
              ) : (
                <Icon name="Sort" />
              )
            ) : (
              <Icon name="Sort" />
            )}
          </div>
        </div>
      </div>
    ),
    accessorKey: 'frequency',
    cell: (props: any) => {
      const barsData = props?.getValue().map((item) => ({
        color: getTrackerColor(item?.status)?.color,
        label: getStatusBadgeColor(item?.status).text,
        item: item,
      }));

      return (
        <>
          <Tracker bars={barsData} customTooltipGoals={true} />
        </>
        // <div className="w-[250px] sm:w-[20vw] flex flex-col justify-center items-center cursor-pointer px-4 h-[100px]">
        //   <div className="flex items-center justify-center gap-x-5">
        //     <ProgressCircle
        //       value={percentage}
        //       color={mode === 'dark' ? 'green' : 'blue'}
        //     >
        //       <span className="text-xs font-bold text-gray-900 dark:text-gray-50">
        //         {parseFloat(percentage.toFixed(2)) > 100
        //           ? 100
        //           : percentage.toFixed(2)}
        //         %
        //       </span>
        //     </ProgressCircle>
        //     <div>
        //       <div className="text-sm w-[130px] sm:max-w-[10vw] font-inter font-[500] text-[#001C44] dark:text-[#FFF] overflow-hidden line-clamp-2">
        //         {`${daysLeft < 0 ? '' : daysLeft + ' day/s left'}`}
        //       </div>
        //       <div className="text-sm w-[120px] sm:max-w-[8vw] font-inter font-[500] text-[#001C44] dark:text-gray-400 overflow-hidden line-clamp-2">
        //         {daysLeft < 0
        //           ? `Goal has ended last ${moment(props.getValue()?.targetDate).format('MMM DD YYYY')}`
        //           : `until ${moment(props.getValue()?.targetDate).format('MMM DD YYYY')}`}
        //       </div>
        //     </div>
        //   </div>
        // </div>
      );
    },
  },

  {
    id: 'Current Period',
    header: () => (
      <div className="w-[250px] sm:w-[10vw] h-[30px] flex items-center">
        <div className="flex w-[250px] sm:w-[10vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-left text-sm font-inter w-fit">
            Current Period
          </div>
          <div>
            {sortingColumn === 'Current Period' ? (
              sortingDirection === 'asc' ? (
                <Icon name="Arrowup" />
              ) : sortingDirection === 'desc' ? (
                <Icon name="ArrowDown" />
              ) : (
                <Icon name="Sort" />
              )
            ) : (
              <Icon name="Sort" />
            )}
          </div>
        </div>
      </div>
    ),
    accessorKey: 'number',
    cell: (props: any) => {
      return (
        <div className="w-[200px] sm:w-[10vw] flex justify-center items-center cursor-pointer h-[70px]">
          <div className="text-sm max-w-[200px] sm:max-w-[10vw] font-inter font-[500] text-[#001C44] dark:text-[#FFF] overflow-hidden line-clamp-2">
            {syncLoading 
              ? <Icon name="Loading" size={18} />
              : <>
                  <span
                    className={`${getStatusBadgeColor(props.getValue()?.status)?.color} px-[10px] py-[3px] rounded`}
                  >
                    {formatNumber(props.getValue()?.currentValue)}
                  </span>
                  {` `}/{` `}
                  {formatNumber(props.getValue()?.goalValue)}
                </>
              }
          </div>
        </div>
      );
    },
  },

  {
    id: 'Status',
    header: () => (
      <div className="w-[150px] sm:w-[8vw] h-[30px] flex items-center">
        <div className="flex w-[150px] sm:w-[8vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-left text-sm font-inter w-fit">Status</div>
          <div>
            {sortingColumn === 'name' ? (
              sortingDirection === 'asc' ? (
                <Icon name="Arrowup" />
              ) : sortingDirection === 'desc' ? (
                <Icon name="ArrowDown" />
              ) : (
                <Icon name="Sort" />
              )
            ) : (
              <Icon name="Sort" />
            )}
          </div>
        </div>
      </div>
    ),
    accessorKey: 'status',
    cell: (props: any) => {
      return (
        <div className="w-[150px] sm:w-[8vw] flex justify-center items-center cursor-pointer h-[70px]">
          <div
            className={`text-xs max-w-[150px] sm:max-w-[8vw] font-inter font-[500] ${getStatusBadgeColor(props.getValue())?.color} ${getStatusBadgeColor(props.getValue())?.border} dark:text-[#FFF] overflow-hidden px-4 py-1 rounded-full`}
          >
            {getStatusBadgeColor(props.getValue())?.text}
          </div>
        </div>
      );
    },
  },

  {
    id: 'owner',
    header: () => (
      <div className="w-[150px] sm:w-[6vw] h-[30px] flex items-center">
        <div className="flex w-[150px] sm:w-[6vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-left text-sm font-inter w-fit">Owner</div>
          <div>
            {sortingColumn === 'owner' ? (
              sortingDirection === 'asc' ? (
                <Icon name="Arrowup" />
              ) : sortingDirection === 'desc' ? (
                <Icon name="ArrowDown" />
              ) : (
                <Icon name="Sort" />
              )
            ) : (
              <Icon name="Sort" />
            )}
          </div>
        </div>
      </div>
    ),
    accessorKey: 'owner',
    cell: (props: any) => {
      return (
        <div className="w-[150px] sm:w-[6vw] flex justify-center items-center cursor-pointer h-fit">
          <ProfileTooltip
            name={`${props?.getValue()?.first_name} ${props?.getValue()?.last_name}`}
            profile_pic={props?.getValue()?.profile_pic}
            role={props?.getValue()?.gurulytics_role}
            email={props?.getValue()?.email}
            lastSeen={
              moment(props.getValue()?.last_activity).fromNow() ===
              'Invalid date'
                ? 'N/A'
                : moment(props.getValue()?.last_activity).fromNow()
            }
            numberOfClients={204}
          >
            <ImageIcon data={props?.getValue()} size={8} />
          </ProfileTooltip>
        </div>
      );
    },
  },
  // Actions column
  {
    id: 'Action',
    header: () => (
      <div className="flex justify-center w-[100px] sm:w-[6vw] text-sm font-inter">
        Action
      </div>
    ),
    accessorKey: 'actions',
    cell: (cell: any) => {
      const goal = cell?.row.original;
      return (
        <div className="relative">
          <div className="flex space-x-3 items-center w-[100px] sm:w-[6vw] justify-center">
            {/* More Icon */}
            <div
              className="cursor-pointer"
              onClick={() => {
                setSelectedGoal(cell?.row.original);
                setIsExpanded((prev) => !prev);
              }}
            >
              <Icon name="More" />
            </div>
          </div>
          {/* Conditionally render the collapsed content based on isExpanded */}
          <div
            className={`transition-all duration-300 ${
              selectedGoal === goal && isExpanded
                ? 'absolute -bottom-[70px] h-auto bg-white z-50 p-2 shadow rounded w-[160px] left-[-90px]' // Adjusted padding and width
                : 'hidden h-0'
            }`}
          >
            {/* Edit Option */}
            <div
              className="cursor-pointer flex items-center gap-2 px-2 py-1 hover:bg-gray-100 rounded"
              onClick={() => {
                editGoal(cell?.row.original);
              }}
            >
              <div className="w-4">
                {' '}
                {/* Simplified width for icon */}
                <Icon name="Edit05" size={16} color="#001C44" />
              </div>
              <div className="text-sm text-[#001C44]">Edit</div>{' '}
              {/* Text size adjusted for better fit */}
            </div>

            {/* Sync Option */}
            <div
              className="cursor-pointer flex items-center gap-2 px-2 py-1 hover:bg-gray-100 rounded"
              onClick={() => {
                handleSyncGoal(cell?.row.original?.id);
              }}
            >
              <div className="w-4">
                {' '}
                {/* Consistent icon size */}
                <Icon name="RefreshIcon" size={17} color="#001C44" />
              </div>
              <div className="text-sm text-[#001C44]">Sync</div>{' '}
              {/* Text size adjusted for consistency */}
            </div>
          </div>
        </div>
      );
    },
  },
];
