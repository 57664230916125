import React, { useState, useMemo, useEffect } from 'react';
import GoalsImg from '../../assets/images/goals.png';
import Icon from '../../assets/icons/SvgComponent';
import CustomModal from '../../components/CustomModal';
import CreateGoalsModal from '../../components/Modals/Goals';
import SearchBar from '../../components/Search';
import ReactSelect from '../../components/ReactSelect';
import TansStackTableV2 from '../../components/TansStackTableV2';
import GoalCard from '../../components/Goals/GoalCard';
import { generateColumns } from './column';
import moment from 'moment';
import { ToastContainer, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useThemeStore } from '../../store/useThemeStore';

import { Card, Grid, Col } from '@tremor/react';
import useGoalStore from '../../store/useGoalStore';
import { useLocation } from 'react-router-dom';
import {
  frequency,
  getStatusMetric,
  metrics,
  statuses,
  metricSourceOptions,
  getMetricSourceOptions,
  getCurrentPeriodTarget,
} from './constants';
import GurulyticsTable from '../../components/GurulyticsTable';

const Goals: React.FC = () => {
  const location = useLocation();
  const { syncLoading, fetchGoalsGraph, goalsGraph, fetchGoals, goals, loading, syncGoal } =
    useGoalStore((state) => state);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [mount, setMount] = useState(false);
  const [searchText, setSearchText] = useState('');
  const { mode } = useThemeStore((state) => state);

  const [openCreateGoals, setOpenCreateGoals] = useState(false);
  // const { selectedClient } = useClientStore((state) => state);
  const [goal, setGoal] = useState(false);
  const [gridView, setGridView] = useState(false);
  const [sortingDirection, setSortingDirection] = useState('');
  const [sortingColumn, setSortingColumn] = useState('');
  const [filterGoal, setFilterGoal] = useState<any>({});
  const [selectedGoal, setSelectedGoal] = useState<any>({});
  const [isExpanded, setIsExpanded] = useState(false);
  // console.log(selectedClient,'selectedClient')

  const editGoal = (item: any) => {
    setSelectedGoal(item);
    setOpenCreateGoals(true);
  };

  const handleSyncGoal = async (data) => {
    const payload = {
      goalIds: data,
    };
    await syncGoal(payload);
    handleFilterChange();
  };

  const columns = useMemo(
    () =>
      generateColumns({
        sortingColumn,
        sortingDirection,
        editGoal,
        setSelectedGoal,
        selectedGoal,
        mode,
        handleSyncGoal,
        syncLoading,
        isExpanded,
        setIsExpanded,
      }),
    [sortingColumn, sortingDirection, editGoal]
  );

  const handleSearch = () => {
    handleFilterChange();
  };

  const handleFilterChange = async (filter?: any) => {
    const payload = {
      limit,
      page,
      search: searchText,
      source: filter?.source,
      metric: filterGoal?.metric?.value || filter?.metric?.value,
      frequencyType: filterGoal?.frequencyType || filter?.frequencyType,
      status: filterGoal?.status || filter?.status,
      client_id: filter?.client_id,
    };
    fetchGoals(payload);
    fetchGoalsGraph(payload);

    // Update URL with filter values
    const params = new URLSearchParams();
    params.set('page', String(page));
    params.set('limit', String(limit));
    if (searchText) params.set('search', searchText);
    if (filter?.source) params.set('source', filter?.source);
    if (filterGoal?.metric?.value)
      params.set('metric', filterGoal?.metric?.value);
    if (filterGoal?.frequencyType)
      params.set('frequencyType', filterGoal?.frequencyType);
    if (filterGoal?.status) params.set('status', filterGoal?.status);
    window.history.pushState({}, '', `?${params.toString()}`);
  };

  useEffect(() => {
    if (searchText === '' && mount) {
      handleFilterChange();
    }
  }, [searchText]);

  useEffect(() => {
    if (filterGoal) handleFilterChange(filterGoal);
  }, [filterGoal]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const pageUrl = queryParams.get('page');
    const limitUrl = queryParams.get('limit');
    const search = queryParams.get('search');
    const source = queryParams.get('source');
    const metric = queryParams.get('metric');
    const frequencyType = queryParams.get('frequencyType');
    const status = queryParams.get('status');

    setMount(true);
    setPage(Number(pageUrl) || 1);
    setLimit(Number(limitUrl) || 10);
    setSearchText(search || '');

    setFilterGoal({
      source: source || '',
      metric: source
        ? getMetricSourceOptions(source).find(
            (option) => option.value === metric
          )
        : null,
      frequencyType: frequencyType || '',
      status: status || '',
    });
  }, [location]);

  // useEffect(() => {
  //   handleFilterChange()
  // },[]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const pageUrl = queryParams.get('page');

    setMount(true);
    setPage(Number(pageUrl) === 0 ? 1 : Number(pageUrl));
  }, [location]);

  useEffect(() => {
    if (mount) {
      handleFilterChange();
    }
  }, [page, limit]);

  const formattedDataMemo = useMemo(() => {
    const formatData = (data) => {
      const currentDate = moment(new Date());

      const tableData = data?.map((item) => {
        const currentData = getCurrentPeriodTarget(item?.frequency);
        const baseDate: any = moment(currentData?.targetDate, 'DD-MM-YYYY');
        // console.log(item);
        return {
          ...item,
          number: {
            goalValue: currentData?.goalValue,
            currentValue: currentData?.currentValue,
            status: currentData?.status,
          },
          goalLimit: {
            startDate: item?.goalStartDate,
            endDate: item?.goalEndDate,
            goalValue: currentData?.goalValue,
            currentValue: currentData?.currentValue,
            targetDate: currentData?.targetDate,
          },
          frequency: item?.frequency,
          client: item?.client_id?.name || '',
          goalSetValue: currentData?.goalValue,
          achieveGoalBy: moment(baseDate).format('MMMM YYYY'),
          dateUntilTarget: baseDate.diff(currentDate, 'days') || 0,
          metricDisplay: getStatusMetric(item?.metric),
          status: currentData?.status,
          goal: {
            name: item?.name,
            metricDisplay: getStatusMetric(item?.metric),
            source: item?.source,
          },
          owner: item?.owner,
        };
      });
      return tableData;
    };
    const formattedData = goals?.data?.length > 0 && formatData(goals?.data);
    return formattedData;
  }, [goals]);

  return (
    <>
      {goals?.loading ? (
        <div className="px-2 sm:px-10 py-4">
          <Card className="h-[86dvh]">
            <div className="flex items-center justify-center gap-[8rem] h-full">
              <div className="text-[#001C44] flex flex-col gap-4">
                <div>
                  <Icon name="target" />
                </div>
                <h1 className="text-[34px] font-bold">Set and Track Goal</h1>
                <p className="max-w-[500px]">
                  Have trouble setting and tracking goals for your business,
                  your team or yourself? Set time-bound, numeric goals and track
                  progress automatically.
                </p>
                <div className="font-bold">
                  <button
                    className="rounded-full bg-[#D0EDFF] py-2 px-4 text-[16px] mr-2"
                    onClick={() => setOpenCreateGoals(true)}
                  >
                    Create your first goal
                  </button>
                  <button className="rounded-full border border-1 py-2 px-4 text-[16px] hover:bg-[#D0EDFF]">
                    Explore Knowledge Articles
                  </button>
                </div>
              </div>
              <div>
                <img src={GoalsImg} alt="Goals" />
              </div>
            </div>
          </Card>
        </div>
      ) : (
        <div className="px-2 mt-[70px] sm:mt-0 sm:p-[32px] py-2 rounded-lg">
          <div
            className={`bg-white dark:bg-gray-800 flex flex-wrap lg:flex-nowrap justify-between gap-2 py-4 px-2 sm:px-6 ${!gridView ? 'rounded-t-[12px]' : 'rounded-[12px]'}`}
          >
            <div className="flex gap-3 flex-wrap lg:flex-nowrap">
              <SearchBar
                width="w-[470px]"
                placeholder="Search goal"
                value={searchText}
                onChange={setSearchText}
                onSearch={handleSearch}
              />
              <div className="w-[140px]">
                <ReactSelect
                  options={metricSourceOptions}
                  placeholder="Sources"
                  value={metricSourceOptions.find(
                    (option) => option.value === filterGoal?.source
                  )}
                  handleOnChange={(selectedOption: any) => {
                    setFilterGoal((prev) => {
                      return {
                        ...prev,
                        source: selectedOption.value,
                        metric: '',
                      };
                    });
                  }}
                />
              </div>

              <div className="w-[140px]">
                <ReactSelect
                  options={getMetricSourceOptions(filterGoal?.source)}
                  placeholder="Metrics"
                  value={
                    filterGoal?.metric
                      ? {
                          value: filterGoal?.metric?.value,
                          label: filterGoal?.metric?.label,
                        }
                      : null
                  }
                  handleOnChange={(selectedOption: any) => {
                    setFilterGoal((prev) => {
                      return {
                        ...prev,
                        metric: {
                          label: selectedOption.label,
                          value: selectedOption.value,
                        },
                      };
                    });
                  }}
                />
              </div>

              <div className="w-[140px]">
                <ReactSelect
                  options={[
                    {
                      label: 'All',
                      value: '',
                    },
                    ...frequency,
                  ]}
                  placeholder="Date Range"
                  value={frequency.find(
                    (option) => option.value === filterGoal?.frequencyType
                  )}
                  handleOnChange={(selectedOption: any) => {
                    setFilterGoal((prev) => {
                      return {
                        ...prev,
                        frequencyType: selectedOption.value,
                      };
                    });
                  }}
                />
              </div>
              <div className="w-fit sm:w-[140px]">
                <ReactSelect
                  options={[
                    {
                      label: 'All',
                      value: '',
                    },
                    ...statuses,
                  ]}
                  placeholder="Status"
                  value={statuses.find(
                    (option) => option.value === filterGoal?.status
                  )}
                  handleOnChange={(selectedOption: any) => {
                    setFilterGoal((prev) => {
                      return {
                        ...prev,
                        status: selectedOption.value,
                      };
                    });
                  }}
                />
              </div>
              <div className="flex sm:hidden gap-2">
                <button
                  className="text-white text-sm bg-[#005C9F] px-2 rounded-lg"
                  onClick={() => setOpenCreateGoals(true)}
                >
                  Create Goal
                </button>
                <div
                  onClick={() => setGridView(false)}
                  className={`cursor-pointer p-[10px] border border-gray-300 rounded-md h-fit ${!gridView && 'bg-[#808EA280]'}`}
                >
                  <Icon name="TableView" size={16} />
                </div>
                <div
                  onClick={() => setGridView(true)}
                  className={`cursor-pointer p-[10px] border border-gray-300 rounded-md h-fit ${gridView && 'bg-[#808EA280]'}`}
                >
                  <Icon name="GridView" size={16} />
                </div>
              </div>
            </div>
            <div className="hidden sm:flex gap-3">
              <button
                className="text-white text-sm bg-[#0029FF] px-[14px]  rounded-lg"
                onClick={() => setOpenCreateGoals(true)}
              >
                Create Goal
              </button>
              <div
                onClick={() => setGridView(false)}
                className={`cursor-pointer p-[10px] border border-gray-300 rounded-md h-fit ${!gridView && 'bg-[#808EA280]'}`}
              >
                <Icon
                  name="TableView"
                  size={16}
                  color={mode === 'dark' ? '#fff' : '#000'}
                />
              </div>
              <div
                onClick={() => setGridView(true)}
                className={`cursor-pointer p-[10px] border border-gray-300 rounded-md h-fit ${gridView && 'bg-[#808EA280]'}`}
              >
                <Icon
                  name="GridView"
                  size={16}
                  color={mode === 'dark' ? '#fff' : '#000'}
                />
              </div>
            </div>
          </div>
          {gridView ? (
            <Grid
              numItems={1}
              numItemsSm={1}
              numItemsMd={2}
              numItemsLg={3}
              className="mt-8 gap-4"
            >
              {goalsGraph?.map((item, index) => (
                <Col key={index}>
                  <GoalCard data={item} chartdata={goalsGraph} index={index} />
                </Col>
              ))}
            </Grid>
          ) : (
            <GurulyticsTable
              data={!formattedDataMemo ? [] : formattedDataMemo}
              columns={columns}
              totalPages={goals?.total_pages}
              page={page}
              setPage={setPage}
              loading={loading}
              totalDocs={goals?.total_docs}
              limit={limit}
              setLimit={setLimit}
              stickyColumn="Goal Name"
            />
          )}
        </div>
      )}

      <CustomModal
        open={openCreateGoals}
        onClose={() => {
          setSelectedGoal({});
          setOpenCreateGoals(false);
        }}
      >
        <CreateGoalsModal
          selectedGoal={selectedGoal}
          onClose={() => {
            setSelectedGoal({});
            setOpenCreateGoals(false);
          }}
          handleFilterChange={() => handleFilterChange()}
          setGoal={() => {
            setGoal(true);
            setOpenCreateGoals(false);
          }}
        />
      </CustomModal>
      <ToastContainer
        position="top-right"
        autoClose={30000}
        hideProgressBar
        newestOnTop={false}
        transition={Flip}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default Goals;
