import Icon from '../../assets/icons/SvgComponent';
import { Badge, Flex } from '@tremor/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import usePerformanceStore from '../../store/usePerformanceStore';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperButtonNext from '../SwiperButton/swiper';
import { Pagination } from 'swiper/modules';
import { useThemeStore } from '../../store/useThemeStore';

const PerformanceCounter = () => {
  const { mode } = useThemeStore((state) => state);
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const urlStatus = searchParams.get('status');
  const isMissingPerformance =
    searchParams.get('integration') === 'missing_performance';
  const { summary } = usePerformanceStore((state) => state);
  const defalutStatus = urlStatus || 'all';
  const [activeWeeklyStatus, setActiveWeeklyStatus] = useState(
    defalutStatus || 'all'
  );

  const statusOptions = [
    {
      id: 'all',
      label: 'All',
      bgColor: '#B2BBC71A',
      hexColor: '#405573',
      count: isMissingPerformance
        ? 0
        : summary?.red + summary?.amber + summary?.green || 0,
    },
    {
      id: 'red',
      label: 'Red',
      bgColor: '#FF00000D',
      hexColor: '#FF0000',
      count: isMissingPerformance ? 0 : summary?.red || 0,
    },
    {
      id: 'amber',
      label: 'Amber',
      bgColor: '#FF692E1A',
      hexColor: '#FF692E',
      count: isMissingPerformance ? 0 : summary?.amber || 0,
    },
    {
      id: 'green',
      label: 'Green',
      bgColor: '#3CCB7F1A',
      hexColor: '#3CCB7F',
      count: isMissingPerformance ? 0 : summary?.green || 0,
    },
  ];

  const handleReportTypes = (status: string) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('status', status);
    searchParams.set('page', '1');
    setActiveWeeklyStatus(status);
    const updatedSearch = searchParams?.toString();
    location.search = updatedSearch;
    navigate(location);
  };

  useEffect(() => {
    handleReportTypes(activeWeeklyStatus);
  }, []);

  return (
    <>
      <div className="cursor-pointer w-screen sm:w-fit gap-2 hidden sm:flex justify-center sm:justify-start items-center overflow-x-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8">
        {statusOptions?.map((option, index) => (
          <div
            key={index}
            className={`flex flex-col gap-1 p-1 items-center justify-center w-fit font-inter font-[600] rounded-md ${
              activeWeeklyStatus === option.id &&
              'dark:bg-gray-800 dark:text-white'
            }`}
            onClick={() => handleReportTypes(option.id)}
          >
            {/* <div className="dark:text-white text-xs ">{option.label}</div> */}

            <div
              className={`cursor-pointer h-[26px] text-sm rounded-[8px] flex items-center justify-center`}
              style={{
                color: option?.hexColor,
                background: option?.bgColor,
                border: `${activeWeeklyStatus === option.id ? `2px solid ${option.hexColor}` : ''}`,
              }}
            >
              <div className="flex items-center justify-center gap-[8px] px-[8px] py-[5px]">
                {activeWeeklyStatus === option.id && (
                  <div
                    className="w-[6px] h-[6px] rounded-full"
                    style={{ backgroundColor: option.hexColor }}
                  />
                )}
                <div className="text-sm font-[500]">{option.label}</div>
                <div className="w-[20px] flex justify-center items-center">
                  {summary?.loading ? (
                    <div className="w-fit flex justify-center items-center">
                      <Icon name="loading" size={14} />
                    </div>
                  ) : (
                    <div className="text-sm font-[600]">{option.count}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="relative block w-full sm:hidden">
        <Swiper
          className="h-full flex justify-between mySwiper"
          modules={[Pagination]}
          spaceBetween={2}
          slidesPerView={3}
        >
          <div className="bg-white">
            <SwiperButtonNext next={true}>
              <Icon
                name="ChevronRight"
                size={30}
                color={mode === 'dark' ? '#FFF' : '#000'}
              />
            </SwiperButtonNext>
          </div>
          {statusOptions?.map((option: any, index: number) => (
            <SwiperSlide
              key={index}
              className={`flex flex-col gap-1 p-1 items-center w-fit font-inter font-[600] rounded-md ${
                activeWeeklyStatus === option.id &&
                'bg-gray-200 dark:bg-gray-800 dark:text-white'
              }`}
              onClick={() => handleReportTypes(option.id)}
            >
              <div className={`w-full text-center`}>{option.label}</div>
            </SwiperSlide>
          ))}
          <div className="bg-white">
            <SwiperButtonNext prev={true}>
              <Icon
                name="ChevronLeft"
                size={30}
                color={mode === 'dark' ? '#FFF' : '#000'}
              />
            </SwiperButtonNext>
          </div>
        </Swiper>
      </div>
    </>
  );
};

export default PerformanceCounter;
