import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const KeywordsRanking: React.FC<IconProps> = ({
  color = 'black',
  size = 80,
}) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1274_13410)">
      <path
        d="M6.99964 9.00012L13.9996 2.00012M7.08469 9.21883L8.83677 13.7242C8.99112 14.1211 9.06829 14.3195 9.17949 14.3774C9.27589 14.4277 9.39073 14.4277 9.48719 14.3776C9.59846 14.3198 9.67586 14.1215 9.83068 13.7247L14.2242 2.46625C14.364 2.10813 14.4339 1.92907 14.3956 1.81465C14.3625 1.71528 14.2845 1.6373 14.1851 1.60411C14.0707 1.56588 13.8916 1.63576 13.5335 1.77552L2.27501 6.16908C1.8783 6.32389 1.67994 6.4013 1.62213 6.51257C1.57202 6.60903 1.57209 6.72386 1.62231 6.82026C1.68025 6.93146 1.8787 7.00864 2.27559 7.16299L6.78093 8.91506C6.8615 8.94639 6.90178 8.96206 6.9357 8.98625C6.96576 9.0077 6.99206 9.03399 7.0135 9.06406C7.0377 9.09798 7.05336 9.13826 7.08469 9.21883Z"
        stroke={color || '#334969'}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1274_13410">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default KeywordsRanking;
