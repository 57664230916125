import Icon from '../../assets/icons/SvgComponent';
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  format,
  getDay,
  isEqual,
  isSameDay,
  isSameMonth,
  isToday,
  parse,
  startOfToday,
} from 'date-fns';
import { useState, useEffect } from 'react';

interface IProps {
  availableDates?: any[];
  today?: Date;
  selectedDay?: Date | null | undefined;
  setSelectedDay?: (arg: Date) => void;
  setSelectedMonth?: (arg: string) => void;
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function BookMeetingCalendar({
  availableDates,
  today,
  selectedDay,
  setSelectedDay,
  setSelectedMonth,
}: IProps) {
  let [currentMonth, setCurrentMonth] = useState(format(today, 'MMM-yyyy'));
  let firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date());

  const formattedDates = availableDates.map(
    (dateString) => new Date(dateString)
  );

  let days = eachDayOfInterval({
    start: firstDayCurrentMonth,
    end: endOfMonth(firstDayCurrentMonth),
  });

  useEffect(() => {
    setSelectedMonth(currentMonth);
  }, [currentMonth]);

  function previousMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 });
    setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'));
  }

  function nextMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
    setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'));
  }

  return (
    <div className="pt-16 font-inter">
      <div className="px-4 w-screen lg:w-[24dvw]">
        <div className="md:grid md:grid-cols-1 md:divide-x md:divide-gray-200">
          <div>
            <div className="flex justify-center items-center gap-4">
              <button
                type="button"
                onClick={previousMonth}
                className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-white hover:text-gray-500"
              >
                <span className="sr-only">Previous month</span>
                <Icon name="ArrowLeft" color="#fff" />
              </button>
              <h2 className=" font-semibold text-white">
                {format(firstDayCurrentMonth, 'MMMM yyyy')}
              </h2>
              <button
                onClick={nextMonth}
                type="button"
                className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-white hover:text-gray-500"
              >
                <span className="sr-only">Next month</span>
                <Icon name="ArrowRight" color="#fff" />
              </button>
            </div>
            <div className="grid grid-cols-7 mt-10 text-md leading-6 text-center text-white">
              <div>Sun</div>
              <div>Mon</div>
              <div>Tue</div>
              <div>Wed</div>
              <div>Thu</div>
              <div>Fri</div>
              <div>Sat</div>
            </div>
            <div className="grid grid-cols-7 mt-2 text-sm">
              {days.map((day, dayIdx) => (
                <div
                  key={day.toString()}
                  className={classNames(
                    dayIdx === 0 && colStartClasses[getDay(day)],
                    'py-1.5'
                  )}
                >
                  <button
                    type="button"
                    onClick={() => setSelectedDay(day)}
                    disabled={!formattedDates.some((d) => isSameDay(day, d))}
                    className={classNames(
                      formattedDates.some((d) => isSameDay(day, d)) &&
                        'text-[#009EFF] font-semibold', // if included in availableDates array, set text white
                      !formattedDates.some((d) => isSameDay(day, d)) &&
                        `text-[#fff] text-opacity-50`, // if not included in availableDates array, set text
                      isEqual(day, selectedDay) && 'text-[#009EFF]', // selected day
                      isToday(day) && 'text-[#fff] text-opacity-50',
                      !isEqual(day, selectedDay) &&
                        !isToday(day) &&
                        isSameMonth(day, firstDayCurrentMonth) &&
                        'text-gray-100', // current month
                      !isEqual(day, selectedDay) &&
                        isToday(day) &&
                        !isSameMonth(day, firstDayCurrentMonth) &&
                        'text-gray-100', // other month
                      isEqual(day, selectedDay) && isToday(day) && 'bg-white', // selected and today
                      isEqual(day, selectedDay) && !isToday(day) && 'bg-white', // selected but not today
                      formattedDates.some((d) => isSameDay(day, d)) &&
                        'hover:bg-white hover:text-[#009EFF]', // hover effect for available dates
                      isEqual(day, selectedDay) && 'font-semibold', // font weight for selected or today
                      `mx-auto flex h-8 w-8 items-center justify-center rounded-full ${day}`
                    )}
                  >
                    <time dateTime={format(day, 'yyyy-MM-dd')}>
                      {format(day, 'd')}
                    </time>
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

let colStartClasses = [
  '',
  'col-start-2',
  'col-start-3',
  'col-start-4',
  'col-start-5',
  'col-start-6',
  'col-start-7',
];
