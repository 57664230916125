import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
  arrowSize?: number;
}

const SwiperNext: React.FC<IconProps> = ({
  color = 'black',
  size = 80,
  arrowSize = 40,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 80 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_2262_480)">
      <rect
        x="20"
        y="20.5"
        width={arrowSize}
        height={arrowSize}
        rx="20"
        fill="#0029FF"
        shape-rendering="crispEdges"
      />
      <path
        d="M47.571 39.9876L32.9033 33.2179C32.6697 33.1118 32.391 33.175 32.2286 33.3758C32.065 33.5767 32.0593 33.8621 32.215 34.0686L37.0384 40.4998L32.215 46.931C32.0593 47.1375 32.065 47.4241 32.2274 47.6238C32.3369 47.7603 32.5005 47.8337 32.6663 47.8337C32.7464 47.8337 32.8265 47.8167 32.9021 47.7818L47.5698 41.012C47.7707 40.9195 47.8982 40.7198 47.8982 40.4998C47.8982 40.2798 47.7707 40.0801 47.571 39.9876Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2262_480"
        x="0"
        y="0.5"
        width={size}
        height={size}
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="10" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2262_480"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2262_480"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default SwiperNext;
