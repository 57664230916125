import React from 'react';

interface Props {
  color?: string;
}

const LineCircle: React.FC<Props> = ({ color = '#428B6E' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="96"
    height="97"
    viewBox="0 0 96 97"
    fill="none"
  >
    <circle cx="48" cy="48.5" r="47.5" stroke="#EAECF0" />
  </svg>
);

export default LineCircle;
