import { create } from 'zustand';
import Api from '../api';
import { persist } from 'zustand/middleware';
const useAuthStore = create(
  persist(
    (set) => ({
      token: '',
      user: {},
      loginAsUser: null,
      currentUser: null,
      error: false,
      errorMessages: '',
      status: '',
      loading: false,
      fbAuth: '',
      message: '',
      googleLoginError: '',
      microsoftLoginError: '',
            selectedDomain: {},
      login: async (data) => {
        set(() => ({
          loading: true,
          googleLoginError: '',
          error: false,
          errorMessages: '',
        }));
        const response = await Api.Auth.login(data);
        if (response.user) {
          set(() => ({
            token: response.accessToken,
            user: response.user,
            currentUser: response.user,
            loading: false,
            error: false,
            errorMessages: '',
            selectedDomain: {
              label: response.user?.clients[0]?.name || 'No Domain',
              id: response.user?.clients[0]?.id || 'No Domain',
            },
          }));
        } else {
          set(() => ({
            error: true,
            loading: false,
          }));
          throw new Error(response[0]?.error);
        }
      },
      googleLogin: async (data) => {
        set(() => ({
          loading: true,
          error: false,
          errorMessages: '',
          googleLoginError: '',
        }));
        const response = await Api.Auth.googleLogin(data);
        if (response.user) {
          set(() => ({
            token: response.accessToken,
            user: response.user,
            currentUser: response.user,
            loading: false,
            error: false,
            errorMessages: '',
            selectedDomain: {
              label: response.user?.clients[0]?.name || 'No Domain',
              id: response.user?.clients[0]?.id || 'No Domain',
            },
          }));
        } else {
          set(() => ({
            error: true,
            loading: false,
            googleLoginError: response[0]?.error,
          }));
        }
      },
      microsoftLogin: async (data) => {
        set(() => ({
          loading: true,
          error: false,
          errorMessages: '',
          googleLoginError: '',
        }));
        const response = await Api.Auth.microsoftLogin(data);
        if (response.user) {
          set(() => ({
            token: response.accessToken,
            user: response.user,
            currentUser: response.user,
            loading: false,
            error: false,
            errorMessages: '',
            selectedDomain: {
              label: response.user?.clients[0]?.name || 'No Domain',
              id: response.user?.clients[0]?.id || 'No Domain',
            },
          }));
        } else {
          set(() => ({
            error: true,
            loading: false,
            microsoftLoginError: response[0]?.error,
          }));
        }
      },
      signup: async (data) => {
        // TODO: Add error handling
        await Api.Auth.signup(data);
      },
      checkDomain: async (domain) => {
        // TODO: Add error handling
        await Api.Auth.checkDomain(domain);
      },
      logout: () => {
        Api.Auth.logout();
        localStorage.clear();
        set(() => ({ token: '', user: {} }));
      },
      loginAsClient: (user) => {
        set((prev) => ({
          loginAsUser: user,
          currentUser: user,
          selectedDomain: {
            label: user?.clients[0].name,
            value: user?.clients[0].id,
            id: user?.clients[0].id,
          },
        }));
      },
      logoutAsClient: () => {
        set((prevState) => ({
          loginAsUser: null,
          currentUser: prevState.user,
        }));
      },
      setDomain: (domain) => {
        set(() => ({ selectedDomain: domain }));
      },
      setFBAuth: (authRes) => {
        set(() => ({ fbAuth: authRes }));
      },
      resetPassword: async (data) => {
        try {
          const response = await Api.Auth.resetPassword(data);
          if (response[0]?.error) {
            throw new Error(response[0].error || 'Something went wrong');
          }
          return response;
        } catch (e) {
          console.log(e);
          throw new Error(e.message || 'Something went wrong');
        }
      },
      reAuthFbToken: async (data) => {
        try {
          const response = await Api.Auth.fbReAuthenticate(data);
          if (response[0]?.error) {
            throw new Error(response[0].error || 'Something went wrong');
          }
          return response;
        } catch (e) {
          console.log(e);
          throw new Error(e.message || 'Something went wrong');
        }
      },
    }),
    {
      name: 'auth-storage', // unique name
    }
  )
);

export default useAuthStore;
