import { useEffect, useRef } from 'react';
import PageHeader from '../../components/Dashboard/PageHeader';
import Customers from '../../components/Home/Customers';
import RevenueManager from '../../components/Home/RevenueUnderManager';
import TeamworkTasks from '../../components/Home/TeamworkTasks';
import ComingSoon from '../../components/ComingSoon';
import { useLocation } from 'react-router-dom';
export default function Home() {
  const tasks = {
    completed: [
      {
        title: 'Adhoc Client Requests',
        description: '',
        completer_firstname: 'Jayden',
        completer_lastname: 'Raso',
        creator_firstname: 'Jayden',
        creator_lastname: 'Raso',
        date: '2023-10-31T06:19:09Z',
        duration: '4months',
        created_on: '2023-07-06T04:25:17Z',
        tags: [{ id: 48257, name: 'PPC', color: '#2f8de4', projectId: 0 }],
      },
      {
        title: 'Monthly Meeting Call Prep & Call',
        description: 'All data for call and meeting',
        completer_firstname: 'Sophie',
        completer_lastname: 'Chugg',
        creator_firstname: 'Kas',
        creator_lastname: 'Robinson',
        date: '2023-10-11T05:09:14Z',
        duration: '5months',
        created_on: '2023-05-19T05:22:57Z',
        tags: [{ id: 44902, name: 'Social', color: '#2f8de4', projectId: 0 }],
      },
      {
        title: 'Budget Pacing & Optimisations 5',
        description:
          'Optimise budgets at campaign level; budget pacing. Review Ad sets, optimise locations, placements, ads, copy and creative',
        completer_firstname: 'Sophie',
        completer_lastname: 'Chugg',
        creator_firstname: 'Kas',
        creator_lastname: 'Robinson',
        date: '2023-10-09T04:46:01Z',
        duration: '5months',
        created_on: '2023-05-19T05:22:55Z',
        tags: [{ id: 44902, name: 'Social', color: '#2f8de4', projectId: 0 }],
      },
      {
        title: 'Performance Review 2',
        description:
          'Check campaigns are running, conversions are tracked, no disapproved ads and leave a results update for the Account Manager at least twice a month',
        completer_firstname: 'Sophie',
        completer_lastname: 'Chugg',
        creator_firstname: 'Kas',
        creator_lastname: 'Robinson',
        date: '2023-09-27T05:14:36Z',
        duration: '4months',
        created_on: '2023-09-27T05:14:36Z',
        tags: [{ id: 44902, name: 'Social', color: '#2f8de4', projectId: 0 }],
      },
      {
        title: 'Adhoc Client Requests',
        description: '',
        completer_firstname: 'Jayden',
        completer_lastname: 'Raso',
        creator_firstname: 'Jayden',
        creator_lastname: 'Raso',
        date: '2023-10-31T06:19:09Z',
        duration: '4months',
        created_on: '2023-07-06T04:25:17Z',
        tags: [{ id: 48257, name: 'PPC', color: '#2f8de4', projectId: 0 }],
      },
    ],
    error: undefined,
    high_priority: [],
    loading: false,
    on_progress: [
      {
        title: 'Archive Project',
        description: '',
        completer_firstname: 'Projects',
        completer_lastname: 'Admin',
        creator_firstname: 'Projects',
        creator_lastname: 'Admin',
        due_date: '20231025',
        duration: '4months',
        created_on: '2023-08-14T11:40:41Z',
        tags: [
          { id: 18996, name: 'Cancelled', color: '#d84640', projectId: 0 },
        ],
      },
      {
        title: 'Archive Project',
        description: '',
        completer_firstname: 'Projects',
        completer_lastname: 'Admin',
        creator_firstname: 'Projects',
        creator_lastname: 'Admin',
        due_date: '20231025',
        duration: '4months',
        created_on: '2023-08-14T11:40:41Z',
        tags: [
          { id: 18996, name: 'Cancelled', color: '#d84640', projectId: 0 },
        ],
      },
      {
        title: 'Archive Project',
        description: '',
        completer_firstname: 'Projects',
        completer_lastname: 'Admin',
        creator_firstname: 'Projects',
        creator_lastname: 'Admin',
        due_date: '20231025',
        duration: '4months',
        created_on: '2023-08-14T11:40:41Z',
        tags: [
          { id: 18996, name: 'Cancelled', color: '#d84640', projectId: 0 },
        ],
      },
      {
        title: 'Archive Project',
        description: '',
        completer_firstname: 'Projects',
        completer_lastname: 'Admin',
        creator_firstname: 'Projects',
        creator_lastname: 'Admin',
        due_date: '20231025',
        duration: '4months',
        created_on: '2023-08-14T11:40:41Z',
        tags: [
          { id: 18996, name: 'Cancelled', color: '#d84640', projectId: 0 },
        ],
      },
      {
        title: 'Archive Project',
        description: '',
        completer_firstname: 'Projects',
        completer_lastname: 'Admin',
        creator_firstname: 'Projects',
        creator_lastname: 'Admin',
        due_date: '20231025',
        duration: '4months',
        created_on: '2023-08-14T11:40:41Z',
        tags: [
          { id: 18996, name: 'Cancelled', color: '#d84640', projectId: 0 },
        ],
      },
    ],
    total_completed_tasks: 1502,
    total_new_tasks: 10,
  };
  const handleTaskFilter = (filter: string) => {
    if (filter === 'View All') {
      // fetchTeamworkTasks(selectedDomain?.id);
    } else {
      // fetchTeamworkTasks(selectedDomain?.id, filter);
    }
  };

  const location = useLocation();
  const scrollRef = useRef<any>(null);
  const scrollToTop = () => {
    const headerHeight = '64px';
    scrollRef.current.style.scrollMargin = headerHeight;
    return scrollRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollToTop();
    }
  }, [location.pathname]);
  return (
    <div
      ref={scrollRef}
      className="flex flex-col gap-y-8 dark:bg-[#1D1D1D] font-inter"
    >
      {/* <PageHeader
        title="Welcome back, Olivia"
        subtitle="Your current sales summary and activity."
        hideDate
        hideDomain
      />
      <div className="flex flex-col px-8 gap-y-5">
        <TeamworkTasks changeFilter={handleTaskFilter} tasks={tasks} />
        <RevenueManager />
        <Customers />
      </div> */}
      <ComingSoon />
    </div>
  );
}
