import { useState } from 'react';
import { Wrapper, DropDownList } from './styles';
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from 'react-icons/md';
import { getInitials } from '../../utils';
const DropdownHS = ({ styles, items, index, onSelect, disabled, value }) => {
  const type = index === 0 ? 'AM' : 'CO';
  const name = value?.name ? getInitials(value?.name) : '';
  const color = value?.name ? value?.val : '';
  const circleColor = {
    gray: 'gray',
    green: 'green',
    amber: '#FFBF00',
    red: 'red',
  };
  const [open, setOpen] = useState(false);
  const selectItem = (value) => {
    setOpen(false);
    onSelect(value);
  };
  return (
    <Wrapper
      disabled={disabled}
      onClick={() => setOpen(!open)}
      styles={styles}
      tabIndex={-1}
      onBlur={() => setOpen(false)}
    >
      <span
        style={{
          fontSize: 10,
          marginRight: 2,
          color: circleColor[value.val],
        }}
      >
        ({type})
      </span>
      <span style={{ fontSize: 11, color: circleColor[value.val] }}>
        {name + ' - ' + color}{' '}
      </span>
      {!open ? (
        <MdOutlineKeyboardArrowDown color="#a0a0a0" size={18} />
      ) : (
        <MdOutlineKeyboardArrowUp color="#a0a0a0" size={18} />
      )}

      {open && (
        <DropDownList styles={styles} role="listbox">
          {items.map((item, idx) => (
            <span role="option" key={idx} onClick={() => selectItem(item)}>
              {item.label}
            </span>
          ))}
        </DropDownList>
      )}
    </Wrapper>
  );
};
export default DropdownHS;
