import React from 'react';

interface GoogleAdsProps {
  color?: string;
}

const GoogleAds: React.FC<GoogleAdsProps> = ({ color = '#428B6E' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="23"
    viewBox="0 0 25 23"
    fill="none"
  >
    <path
      d="M16.0519 6.41714C17.1322 4.5306 16.4911 2.11829 14.62 1.0291C12.7489 -0.0600967 10.3563 0.586279 9.27605 2.47282L1.19762 16.5806C0.11734 18.4671 0.758426 20.8794 2.62952 21.9686C4.50062 23.0578 6.89317 22.4114 7.97345 20.5249L16.0519 6.41714Z"
      fill="white"
    />
    <path
      d="M8.49325 18.5546C8.49325 20.7336 6.74128 22.5 4.58012 22.5C2.41896 22.5 0.666992 20.7336 0.666992 18.5546C0.666992 16.3756 2.41896 14.6091 4.58012 14.6091C6.74128 14.6091 8.49325 16.3756 8.49325 18.5546Z"
      fill="white"
    />
    <path
      d="M9.28844 6.41714C8.20816 4.5306 8.84925 2.11829 10.7203 1.0291C12.5914 -0.0600967 14.984 0.586279 16.0643 2.47282L24.1427 16.5806C25.223 18.4671 24.5819 20.8794 22.7108 21.9686C20.8397 23.0578 18.4471 22.4114 17.3669 20.5249L9.28844 6.41714Z"
      fill="white"
    />
  </svg>
);

export default GoogleAds;
