import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const weeklyReport: React.FC<IconProps> = ({ 
  color = '#405573',
  size = 16,
}) => (
  <svg
    width={size - 2}
    height={size}
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 0C4.40625 0 4.75 0.34375 4.75 0.75V2H9.25V0.75C9.25 0.34375 9.5625 0 10 0C10.4062 0 10.75 0.34375 10.75 0.75V2H12C13.0938 2 14 2.90625 14 4V4.5V6V14C14 15.125 13.0938 16 12 16H2C0.875 16 0 15.125 0 14V6V4.5V4C0 2.90625 0.875 2 2 2H3.25V0.75C3.25 0.34375 3.5625 0 4 0ZM12.5 6H1.5V14C1.5 14.2812 1.71875 14.5 2 14.5H12C12.25 14.5 12.5 14.2812 12.5 14V6ZM3.5 8H10.5C10.75 8 11 8.25 11 8.5V10.5C11 10.7812 10.75 11 10.5 11H3.5C3.21875 11 3 10.7812 3 10.5V8.5C3 8.25 3.21875 8 3.5 8Z"
      fill={color}
    />
  </svg>
);

export default weeklyReport;
