import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Icon from '../../../assets/icons/SvgComponent';
import { Input } from './myDetails';
import useClientStore from '../../../store/useClientStore';
import { FadeLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import Alert from '../../Alert';
import ReactSelect from '../../ReactSelect';
import moment from 'moment';
import { useThemeStore } from '../../../store/useThemeStore';

interface EditModalProps {
  user?: any;
  onClose?: any;
}

type FormState = {
  name: string;
  domain: string;
  category_type: string;
  owner: {
    first_name: string;
    last_name: string;
    email: string;
    birthdate: Date;
    phone: string;
  };
  role: { name: string };
};

const EditModal: React.FC<EditModalProps> = ({ user, onClose }) => {
  const { mode } = useThemeStore((state: any) => state);
  const iconColor = mode === 'dark' ? 'white' : 'black';
  const { updateClient } = useClientStore((state) => state);
  const [errorMessage, setErrorMessage] = useState('');
  const [formData, setFormData] = useState<FormState>(user);
  const [isLoading, setIsLoading] = useState(false);
  const [campaignType, setCampaignType] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const toggleContent = () => {
    let data = {
      name: formData.name,
      domain: formData.domain,
      category_type: campaignType?.value,
      owner: {
        first_name: formData?.owner?.first_name,
        last_name: formData?.owner?.last_name,
        email: formData?.owner?.email,
        main_owner: true,
        birthdate:
          moment(formData?.owner?.birthdate)?.format('YYYY-MM-DD') || '',
        phone: formData?.owner?.phone,
      },
    };
    if (user.id) {
      setIsLoading(true);
      updateClient(user.id, data)
        .then((res: any) => {
          setIsLoading(false);
          toast.success(
            <Alert
              icon=""
              title={`CUSTOMER UPDATE`}
              message={`Customer has been updated successfully`}
            />,
            {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        })
        .catch((e: any) => {
          setErrorMessage(e.message);
          toast.error(
            <Alert icon="" title={`ERROR MESSAGE!`} message={`${e.message}`} />,
            {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          setIsLoading(false);
        });
    }
  };

  const handleChange = (event: any) => {
    if (['name', 'domain'].includes(event.target.name)) {
      setFormData({ ...formData, [event.target.name]: event.target.value });
    } else {
      setFormData({
        ...formData,
        owner: { ...formData.owner, [event.target.name]: event.target.value },
      });
    }
  };

  return (
    <div
      className="fixed top-0 right-0 bg-white h-screen w-[26rem] flex flex-col z-100 dark:bg-[#262627] dark:border dark:border-[#313233] dark:shadow-bs-card"
      style={{ minHeight: '800px' }}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="relative h-screen overflow-y-auto flex flex-col justify-between">
        <div className="max-h-[100dvh] overflow-y-hidden">
          <div className="px-6 flex  items-start gap-2  border-b border-tertiary-colours-neutral-cool-100 flex justify-between">
            <h2 className="font-inter text-[#001129] text-xl font-semibold my-6  dark:text-[#E6EFF5]">
              Edit Client
            </h2>
            <div className="my-4 overflow-hidden flex justify-center">
              {isLoading && <Icon name="Loading" />}
            </div>
            <div
              className="w-[90px] h-full flex justify-end my-6 cursor-pointer"
              onClick={onClose}
            >
              {' '}
              <Icon
                name="Xclose"
                color={mode === 'dark' ? '#fff' : iconColor}
              />
            </div>
          </div>
          <div className="flex flex-col justify-between h-1/2 mt-8 min-h-[75vh] overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-full">
            <form className="relative flex flex-col space-y-8  pb-8  px-6  ">
              <Input
                label="Client"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
              <Input
                label="Domain"
                type="text"
                name="domain"
                value={formData.domain}
                onChange={handleChange}
                disabled
              />
              <div>
                <div className="dark:text-white font-[300] text-sm mb-2">
                  Campaign Type
                </div>
                <ReactSelect
                  options={[
                    { value: 'Lead Gen', label: 'Lead Gen' },
                    { value: 'Ecommerce', label: 'E-commerce' },
                  ]}
                  value={campaignType}
                  placeholder={
                    formData?.category_type
                      ? `${formData?.category_type}`
                      : 'N/A'
                  }
                  handleOnChange={(selectedOption: any) =>
                    setCampaignType(selectedOption)
                  }
                />
              </div>
              <Input
                label="First Name"
                type="text"
                name="user"
                value={formData.owner?.first_name}
                onChange={handleChange}
              />
              <Input
                label="Last Name"
                type="text"
                name="user"
                value={formData.owner?.last_name}
                onChange={handleChange}
              />
              <Input
                label="Email"
                type="email"
                name="email"
                value={formData.owner?.email}
                onChange={handleChange}
              />
              <Input
                label="Birthdate"
                type="date"
                name="birthdate"
                value={formData.owner?.birthdate}
                onChange={handleChange}
              />
              <Input
                label="Phone Number"
                type="text"
                name="phone"
                value={formData.owner?.phone}
                onChange={handleChange}
              />
            </form>
          </div>
        </div>
        <div className="px-6 py-4 w-full flex justify-center items-center border-t border-tertiary-colours-neutral-cool-100">
          <button
            type="button"
            onClick={toggleContent}
            className="w-full rounded-lg text-white border border-[#005C9F] bg-[#005C9F] shadow-xs px-4 py-2 dark:text-[#E6EFF5]"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditModal;
