import { useMemo } from 'react';
import { Card, Grid, Col } from '@tremor/react';
import AreaCardChart from '../AreaCardChart';
import AnalyticsTable from '../AnalyticsTable';
import InfoTooltip from '../AnalyticsInfoTooltip';
import moment from 'moment';
import {
  formatNumberWithCommas,
  formatNumber,
  processData,
  processData2,
  parseDate,
} from '../../../utils/common';
import useGoogleStore from '../../../store/useGoogleStore';
import MobileView from './MobileView';
import useClientStore from '../../../store/useClientStore';
import { useRetryFetchGA4Analytics } from '../Hooks';

interface IProps {
  sliceData?: boolean;
}

const Conversions = ({ sliceData }: IProps) => {
  const { ga4SeoData } = useGoogleStore((state) => state);
  const { selectedClient } = useClientStore((state) => state);
  const propertyId = selectedClient?.google_analytics?.ga4?.property_id;
  const organic_conversions = processData(ga4SeoData, 'organic_conversions');

  const startDate = parseDate(localStorage.getItem('seoStartDate'));
  const endDate = parseDate(localStorage.getItem('seoEndDate'));

  const lastMonthOrganicConversion =
    Array.isArray(organic_conversions) && organic_conversions.length > 0
      ? organic_conversions[organic_conversions.length - 1]?.value
      : undefined;

  const organic_sessionConversionRate = processData(
    ga4SeoData,
    'organic_sessionConversionRate',
    'Organic Conversions Rate'
  );
  const lastMonthOrganicConversionRate =
    Array.isArray(organic_sessionConversionRate) &&
    organic_sessionConversionRate.length > 0
      ? organic_sessionConversionRate[organic_sessionConversionRate.length - 1]
          ?.value
      : undefined;

  const conversions = processData2({
    dates: ga4SeoData?.conversions_breakdown?.dates,
    values: ga4SeoData?.conversions_breakdown?.values,
    totalKeyName: 'Total Conversions',
  });

  const organic_conversions_breakdown = processData2({
    dates: ga4SeoData?.organic_conversions_breakdown?.dates,
    values: ga4SeoData?.organic_conversions_breakdown?.values,
    totalKeyName: 'Total',
  });

  const lastMonthTotalOrganicConversionsBreakdown =
    Array.isArray(organic_conversions_breakdown) &&
    organic_conversions_breakdown.length >= 2
      ? organic_conversions_breakdown[
          organic_conversions_breakdown.length - 1
        ]?.['Total']
      : undefined;

  const totalConversions = processData(
    ga4SeoData,
    'sessionConversionRate',
    'Session Conversion Rate'
  );
  const lastMonthAllConversions =
    Array.isArray(totalConversions) && totalConversions.length > 0
      ? totalConversions[totalConversions.length - 1]?.value
      : undefined;

  const lastMonthConversions =
    Array.isArray(conversions) && conversions.length >= 2
      ? conversions[conversions.length - 1]?.['Total Conversions']
      : undefined;

  const retryFetchGA4Analytics = useRetryFetchGA4Analytics();

  return (
    <>
      <div className="flex justify-start items-center mb-4">
        <h2 className="title-text text-base sm:text-[1.85rem] pb-1 hidden sm:block">
          Conversions
        </h2>
      </div>

      <MobileView retry={retryFetchGA4Analytics} />
      <Grid
        numItems={1}
        numItemsSm={1}
        numItemsMd={2}
        numItemsLg={4}
        className="gap-4 hidden sm:grid mb-4"
      >
        <Col>
          <AreaCardChart
            title={'Total Conversions'}
            chatData={conversions}
            category={['Total Conversions']}
            info={
              ga4SeoData?.conversions_breakdown?.info
                ? ga4SeoData?.conversions_breakdown?.info
                : 'Conversions'
            }
            prevPeriod={ga4SeoData?.conversions?.previous_period}
            previousYear={ga4SeoData?.conversions?.previous_year}
            isLoading={ga4SeoData.loading}
            totalValue={formatNumberWithCommas(lastMonthConversions)}
            error={ga4SeoData.error}
            customDataTooltip={true}
            icon="googleAnalytics"
            propertyId={`Property ID: ${propertyId}`}
            retryMethod={retryFetchGA4Analytics}
          />
        </Col>
        <Col>
          <AreaCardChart
            title={
              ga4SeoData?.sessionConversionRate?.label
                ? ga4SeoData?.sessionConversionRate?.label
                : 'Session Conversion Rate'
            }
            chatData={totalConversions}
            category={['Session Conversion Rate']}
            info={
              ga4SeoData?.sessionConversionRate?.info
                ? ga4SeoData?.sessionConversionRate?.info
                : 'Session Conversion Rate'
            }
            prevPeriod={ga4SeoData?.sessionConversionRate?.previous_period}
            previousYear={ga4SeoData?.sessionConversionRate?.previous_year}
            isLoading={ga4SeoData.loading}
            totalValue={formatNumber(lastMonthAllConversions, true)}
            hasPercent
            error={ga4SeoData.error}
            icon="googleAnalytics"
            propertyId={`Property ID: ${propertyId}`}
            retryMethod={retryFetchGA4Analytics}
          />
        </Col>
        <Col>
          <AreaCardChart
            title={
              ga4SeoData?.organic_conversions_breakdown?.label
                ? ga4SeoData?.organic_conversions_breakdown?.label
                : 'Organic Conversions'
            }
            chatData={organic_conversions_breakdown}
            category={['Total']}
            info={
              ga4SeoData?.organic_conversions_breakdown?.info
                ? ga4SeoData?.organic_conversions_breakdown?.info
                : 'Organic Conversions'
            }
            prevPeriod={
              ga4SeoData?.organic_conversions_breakdown?.previous_period
            }
            previousYear={
              ga4SeoData?.organic_conversions_breakdown?.previous_year
            }
            isLoading={ga4SeoData.loading}
            totalValue={formatNumber(lastMonthTotalOrganicConversionsBreakdown)}
            error={ga4SeoData.error}
            customDataTooltip={true}
            icon="googleAnalytics"
            propertyId={`Property ID: ${propertyId}`}
            retryMethod={retryFetchGA4Analytics}
          />
        </Col>
        <Col>
          <AreaCardChart
            title={'Organic Conversions Rate'}
            chatData={organic_sessionConversionRate}
            category={['Organic Conversions Rate']}
            info={
              ga4SeoData?.organic_sessionConversionRate?.info
                ? ga4SeoData?.organic_sessionConversionRate?.info
                : 'Organic Conversions Rate'
            }
            prevPeriod={
              ga4SeoData?.organic_sessionConversionRate?.previous_period
            }
            previousYear={
              ga4SeoData?.organic_sessionConversionRate?.previous_year
            }
            isLoading={ga4SeoData.loading}
            totalValue={formatNumber(lastMonthOrganicConversionRate, true)}
            error={ga4SeoData.error}
            hasPercent
            icon="googleAnalytics"
            propertyId={`Property ID: ${propertyId}`}
            retryMethod={retryFetchGA4Analytics}
          />
        </Col>
        {/* <Col>
          <AreaCardChart
            title={'Top Events'}
            chatData={topEvents}
            category={['Total']}
            info={
              ga4SeoData?.topEvents?.info
                ? ga4SeoData?.topEvents?.info
                : 'Top Events'
            }
            prevPeriod={ga4SeoData?.topEvents?.previous_period}
            previousYear={ga4SeoData?.topEvents?.previous_year}
            isLoading={ga4SeoData.loading}
            totalValue={formatNumberWithCommas(lastMonthTotalTopEvents)}
            error={ga4SeoData.error}
            customDataTooltip={true}
            customTooltipAlignRight={true}
            icon="googleAnalytics"
          />
        </Col> */}
      </Grid>
      {/* <Card className="dark:bg-[#222732] dark:border dark:border-white px-3 sm:px-6 shadow-none lg:shadow ring-0 lg:ring-1">
        <div className="flex justify-between">
          <div className="flex gap-2 items-center mb-2 lg:mb-4">
            <h2 className="text-sm text-[#001C44] dark:text-white font-bold ">
              Top Events
            </h2>
            <InfoTooltip content={'Top Events'} position="top">
              <Icon name="InfoCircle" size={14} />
            </InfoTooltip>
          </div>
          <Icon name="googleAnalytics" size={22} />
        </div>
        <AnalyticsTable
          data={topEventsData?.data ? topEventsData?.data : []}
          columns={topEventsData?.tableHeader}
          loading={ga4SeoData.loading}
          totalDocs={topEventsData?.data ? topEventsData?.data.length : 'N/A'}
          sortByColumnId="Conversions"
          pagination={false}
          error={ga4SeoData?.error}
        />
      </Card> */}
    </>
  );
};

export default Conversions;
