import React from 'react';
import { Card } from '@tremor/react';
import HeaderTitle from '../HeaderTitle';
import moment from 'moment';
import { Link } from 'react-router-dom';

interface Props {
  industryData: any;
}

export const IndustryUpdates: React.FC<Props> = ({ industryData }) => {
  const data = industryData?.map((data: any) => {
    return {
      title: data.title.rendered,
      type: data.status,
      interval: moment(data.modified, 'YYYYMMDD').fromNow(),
      image: data.yoast_head_json.og_image[0].url,
      link: data.link,
      description: data.excerpt.rendered,
    };
  });

  const formatedData = data?.slice(0, 4);

  return (
    <div className="px-1">
      <Card className="dark:bg-[#222732] px-2 sm:px-6">
        <div className="flex justify-between w-full items-center mb-3 sm:mb-8">
          <HeaderTitle
            text="Industry Updates"
            fontSize="font-archivo text-lg sm:text-[24px]"
          />
        </div>
        <div className="flex flex-wrap  justify-center lg:justify-start xl:justify-between gap-4 font-inter">
          {formatedData?.map((data, index) => (
            <Card
              key={index}
              className="w-full md:w-[48%] lg:w-[32%] xl:w-[23%]  relative p-0 "
            >
              <div className="w-full">
                <img src={data.image} alt="" className="rounded-t-lg" />
              </div>
              <div className="flex flex-col items-start justify-between  p-4">
                <h1
                  className="text-xl h-14 font-semibold my-3 dark:text-white"
                  style={{
                    display: '-webkit-box',
                    WebkitLineClamp: '2',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  dangerouslySetInnerHTML={{ __html: data.title }}
                ></h1>
                <span
                  className="text-sm dark:text-white"
                  style={{
                    display: '-webkit-box',
                    WebkitLineClamp: '3',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  dangerouslySetInnerHTML={{ __html: data.description }}
                ></span>
                <div className=" font-semibold xl:text-md text-black hover:text-[#0029FF] hover:underline mt-3 dark:text-white">
                  <Link to={data.link} target="_blank">
                    Continue reading
                  </Link>
                </div>
              </div>
              {/* <div className='flex flex-col items-start  p-4'>
              <div className="flex items-center justify-between w-full">
                <div className='text-[#009EFF]'>
                  {data.type}
                </div>
                <div className='text-sm font-[300]'>
                  {data.interval}
                </div>
              </div>
              <span className='text-xl mt-4 ' dangerouslySetInnerHTML={{__html: data.title}}></span >

              </div> */}
            </Card>
          ))}
        </div>
      </Card>
    </div>
  );
};
