import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const Rank: React.FC<IconProps> = ({ color = 'black', size = 16 }) => (
  <svg
    version="1.2"
    baseProfile="tiny-ps"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1609 1713"
    width={size - 2}
    height={size - 2}
    fill='none'
  >

    <path
      id="Layer"
      className="shp0"
      fill="#f4732a"
      d="M1481.26 339.59C1502.47 372.82 1521.23 407.56 1537.39 443.53C1553.55 479.49 1567.07 516.59 1577.83 554.52C1588.6 592.46 1596.58 631.12 1601.72 670.22C1606.86 709.31 1609.15 748.73 1608.55 788.16C1608.02 827.58 1604.61 866.92 1598.36 905.85C1592.11 944.78 1583.04 983.2 1571.21 1020.81C1559.38 1058.42 1544.82 1095.12 1527.66 1130.61C1510.5 1166.11 1490.78 1200.31 1468.65 1232.93C1453.58 1255.06 1437.43 1276.42 1420.25 1296.95C1403.06 1317.47 1384.88 1337.13 1365.76 1355.86C1346.63 1374.59 1326.59 1392.35 1305.71 1409.1C1284.83 1425.84 1263.14 1441.55 1240.71 1456.15C1223.94 1466.39 1214.34 1463.53 1209.9 1459.73C1201.24 1452.35 1215.06 1427.05 1215.06 1427.05L1214.77 1427.05C1247.01 1362.35 1265.92 1270.34 1265.92 1183.42C1265.92 1159.56 1264.35 1135.98 1261.77 1112.91C1251.02 941.86 1120.72 803.85 919.78 648.36C838.12 585.01 775.08 529.19 730.66 475.45C720.13 462.98 711.03 450.66 702.65 438.19L720.13 461.12C683.74 412.96 662.04 352.49 662.04 286.7C662.04 128.77 787.18 0.79 941.84 0.08C954.03 0.15 966.21 0.68 978.36 1.65C990.5 2.62 1002.61 4.03 1014.65 5.89C1026.7 7.75 1038.67 10.05 1050.54 12.78C1062.42 15.52 1074.19 18.69 1085.83 22.29L1128.1 36.62C1270.86 93.52 1395.3 205.52 1481.26 339.59L1481.26 339.59Z"
    />
    <path
      id="Layer"
      fill='#092540'
      d="M871.64 924.02C891.39 939.26 909.31 956.73 925.07 976.07C940.83 995.41 954.31 1016.5 965.25 1038.92C976.19 1061.34 984.52 1084.94 990.07 1109.27C995.62 1133.59 998.35 1158.47 998.22 1183.42C998.4 1215.34 993.87 1247.11 984.78 1277.71C975.69 1308.31 962.14 1337.41 944.58 1364.05C927.01 1390.7 905.61 1414.62 881.07 1435.02C856.53 1455.43 829.12 1472.11 799.72 1484.52L799.5 1484.52C799.5 1484.52 561.1 1592.22 283.16 1387.57L283.37 1387.57C269.47 1377.53 255.99 1366.93 242.95 1355.79C229.91 1344.65 217.34 1332.99 205.25 1320.82C193.17 1308.66 181.59 1296 170.53 1282.89C159.48 1269.78 148.97 1256.22 139.03 1242.25C114.55 1205.94 93.05 1167.71 74.73 1127.93C56.42 1088.16 41.36 1046.96 29.68 1004.75C18.01 962.54 9.78 919.46 5.05 875.92C0.33 832.39 -0.86 788.54 1.49 744.81C5.08 675.97 17.45 607.88 38.3 542.18C59.15 476.48 88.31 413.72 125.07 355.41C161.83 297.11 205.89 243.75 256.18 196.62C306.47 149.5 362.58 109 423.13 76.1C463.68 56.83 445.05 95.88 445.05 95.88L445.05 96.17C435.45 118.31 427.07 140.95 420.27 164.24C345.12 420.13 482.23 627.65 745.85 820.05L871.71 923.95L871.64 924.02Z"
    />
  </svg>
);

export default Rank;
