import { create } from 'zustand';
import Api from '../api';
import { devtools } from 'zustand/middleware';
const initialStatus = {
  loading: true,
  errorMsg: '',
  isSuccessful: true,
};
const useCrawlerStore = create(
  devtools((set, get) => ({
    crawl: [],
    crawlHistory: [],
    crawlHistoryStatus: initialStatus,
    selectedCrawl: initialStatus,
    summary: initialStatus,
    error: false,
    apiStatus: '',
    loading: false,
    errorMessages: '',
    fetchHistory: async (filters) => {
      set(() => ({ loading: true }));
      const response = await Api.Crawler.getPreviousCrawls(filters);
      set(() => ({ crawlHistory: response }));
      set(() => ({ loading: false }));
      return response;
    },
    fetchCrawl: async (id, noLoading = false) => {
      try {
        if (!noLoading) {
          set(() => ({ selectedCrawl: initialStatus }));
        }
        set(() => ({ loading: true }));
        const response = await Api.Crawler.getCrawl(id);
        const resData = { ...response, loading: false };
        if (response[0]?.error) {
          throw response[0]?.error;
        } else {
          set(() => ({ selectedCrawl: resData, loading: false }));
          return { status: true };
        }
      } catch (error) {
        throw error;
      }
    },
    setCrawl: async (crawl) => {
      set(() => ({ selectedCrawl: crawl }));
    },
    addCrawl: async (data) => {
      set(() => ({
        loading: true,
        error: false,
        errorMessages: '',
        apiStatus: '',
      }));
      const response = await Api.Crawler.addCrawl(data);
      if (response[0]?.error) {
        set(() => ({
          apiStatus: 'error',
          loading: false,
          error: true,
          errorMessage: response[0]?.error,
        }));
        throw new Error(response[0]?.error);
      } else {
        set(() => ({
          apiStatus: 'success',
          loading: false,
          error: false,
          errorMessages: '',
          message: response
        }));
        return response;
      }
    }
  }))
);

export default useCrawlerStore;
