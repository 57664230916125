import { Card } from '@tremor/react';
import Icon from '../../assets/icons/SvgComponent';
import ImageIcon from '../ImageIcon';
import HeaderTitle from '../HeaderTitle';
import useClientStore from '../../store/useClientStore';
import useActivitiesStore from '../../store/useActivitiesStore';
import moment from 'moment';
import Tooltip from '../HelpTooltip';
import NoData from '../../assets/images/NoData.png';

type TaskCompletedProps = {
  title: string;
  type: string;
  dateCompleted: string;
  user: string;
};

export const TaskCompletedCard = () => {
  const { selectedClient } = useClientStore((state) => state);
  const { tasks } = useActivitiesStore((state) => state);
  const total_tasks = tasks?.total_completed_tasks || 0;

  const taskCompleted2: TaskCompletedProps[] = tasks?.completed?.map((task) => {
    return {
      title: task.title,
      type: task.tags?.[0].name,
      dateCompleted: task?.completed_on,
      user: task?.completer_firstname + ' ' + task?.completer_lastname,
    };
  });

  const downloadLink = selectedClient?.seo_google_report_config?.strategy;

  return (
    <div className="flex flex-col justify-between h-full font-inter">
      <Card className="h-full dark:bg-[#222732]">
        {total_tasks === 0 ? (
          <>
            <div className="mb-4">
              <HeaderTitle
                text={`Tasks Completed`}
                fontSize="text-[18px] sm:text-[24px]"
              />
            </div>
            <div className="flex flex-col justify-center items-center text-center gap-2 min-h-60">
              <img src={NoData} alt="" className="h-[70px]" />
              <div className="flex flex-col justify-center items-center text-center">
                <div className="text-sm sm:text-[16px] font-inter font-[700] my-2 dark:text-white">
                  There are currently no completed tasks
                </div>
                <span className="text-xs sm:text-sm text-[#001C44] font-[300] dark:text-white">
                  Your completed tasks will show up here, marking your progress
                  and achievements. Start ticking off your tasks to see them
                  populate in this space.
                </span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="flex justify-between w-full items-center mb-6">
              <HeaderTitle
                text={`Over ${total_tasks} Tasks Completed!`}
                fontSize="text-[18px] sm:text-[24px]"
              />
            </div>
            <div className="relative overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8">
              <table className="w-full">
                <thead>
                  <tr className="border-b text-xs pb-2">
                    <th className="w-[160px] sm:w-[25vw] lg:w-[12vw] dark:text-white bg-white dark:bg-gray-800 sticky left-0 z-[10] ">
                      Task{' '}
                    </th>
                    <th className="w-[80px] sm:w-[25vw] lg:w-[12vw] flex justify-center  dark:text-white z-[9]">
                      Service
                    </th>
                    <th className="w-[150px] sm:w-[25vw] lg:w-[8vw] text-center text-[#001C44] font-[500] dark:text-white z-[9] whitespace-nowrap">
                      Date Completed
                    </th>
                    <th className="w-[120px] sm:w-[25vw] lg:w-[8vw] flex justify-center dark:text-white z-[9]">
                      Completed By
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {taskCompleted2?.map((task, index) => (
                    <tr
                      key={index}
                      className="border-b bg-white border-b border-dashed dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600  z-20"
                    >
                      <td className="w-[160px] sm:w-[25vw] lg:w-[12vw] sticky left-0 z-[10] bg-white dark:bg-gray-800">
                        <div className="flex items-center gap-2 w-[160px] sm:w-[25vw] lg:w-[12vw]">
                          <div>
                            <Icon name="CheckVerified" size={16} />
                          </div>
                          <Tooltip content={task?.title} position="top">
                            <p className="w-full text-left text-xs sm:text-[16px] max-w-[140px] sm:max-w-[15vw] sm:max-w-[10vw] font-[600] truncate dark:text-white">
                              {task?.title}
                            </p>
                          </Tooltip>
                        </div>
                      </td>
                      <td className="w-[80px] sm:w-[25vw] lg:w-[12vw]">
                        <p className="max-w-[80px] sm:max-w-[25vw] lg:max-w-[12vw] text-center text-[32px] text-[#009EFF] font-palmer">
                          •{task?.type}
                        </p>
                      </td>
                      <td className="w-[150px] sm:w-[25vw] lg:w-[8vw] text-center text-sm text-[#001C44] font-[500] dark:text-white">
                        {moment(task?.dateCompleted).format('MMMM DD')}
                      </td>
                      <td className="w-[120px] sm:w-[25vw] lg:w-[8vw] flex justify-center pt-2">
                        <ImageIcon data={task?.user} size={8} textSize={'xs'} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </Card>
    </div>
  );
};
