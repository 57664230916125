import { Grid, Col } from '@tremor/react';
import AreaCardChart from '../AreaCardChart';
import useGoogleStore from '../../../store/useGoogleStore';
import {
  processData,
  formatNumber,
  formatNumberWithCommas,
} from '../../../utils/common';
import Keywords from '../Keywords';
import Pages from '../Pages';
import MobileView from './MobileView';
import useClientStore from '../../../store/useClientStore';
import { useRetryFetchGA4Analytics, useRetryFetchGS4Analytics } from '../Hooks';

interface IProps {
  sliceData?: boolean;
}

const SearchConsoleAnalysis = ({ sliceData }: IProps) => {
  const { googleSearchConsole } = useGoogleStore((state) => state);
  const { selectedClient } = useClientStore((state) => state);

  const clicks = processData(googleSearchConsole, 'clicks');
  const lastMonthClicks =
    Array.isArray(clicks) && clicks.length > 0
      ? clicks[clicks.length - 1]?.value
      : undefined;

  const impressions = processData(googleSearchConsole, 'impressions');
  const lastMonthImpressions =
    Array.isArray(impressions) && impressions.length > 0
      ? impressions[impressions.length - 1]?.value
      : undefined;

  const ctr = processData(googleSearchConsole, 'ctr');
  const lastMonthCtr =
    Array.isArray(ctr) && ctr.length > 0
      ? ctr[ctr.length - 1]?.value
      : undefined;

  const position = processData(googleSearchConsole, 'position');
  const lastMonthPosition =
    Array.isArray(position) && position.length > 0
      ? position[position.length - 1]?.value
      : undefined;

  const retryFetchGAConsole = useRetryFetchGS4Analytics();

  return (
    <>
      <div className="flex justify-start items-center mb-4">
        <h2 className="title-text text-base sm:text-[1.85rem] pb-1 hidden sm:block">
          Search Console Analysis
        </h2>
      </div>
      <MobileView retry={retryFetchGAConsole} />
      <div className="hidden sm:block">
        <Grid
          numItems={1}
          numItemsSm={1}
          numItemsMd={2}
          numItemsLg={4}
          className="gap-4"
        >
          <Col>
            <AreaCardChart
              title={
                googleSearchConsole?.clicks?.label
                  ? googleSearchConsole?.clicks?.label
                  : 'Clicks'
              }
              chatData={clicks}
              category={['Clicks']}
              info={
                googleSearchConsole?.clicks?.info
                  ? googleSearchConsole?.clicks?.info
                  : 'Clicks'
              }
              prevPeriod={googleSearchConsole?.clicks?.previous_period}
              previousYear={googleSearchConsole?.clicks?.previous_year}
              isLoading={googleSearchConsole.loading}
              totalValue={formatNumberWithCommas(lastMonthClicks)}
              error={googleSearchConsole.error}
              icon="googleSearch"
              propertyId={selectedClient?.google_search_console?.site_url}
              retryMethod={retryFetchGAConsole}
            />
          </Col>
          <Col>
            <AreaCardChart
              title={
                googleSearchConsole?.impressions?.label
                  ? googleSearchConsole?.impressions?.label
                  : 'Impressions'
              }
              chatData={impressions}
              category={['Impressions']}
              info={
                googleSearchConsole?.impressions?.info
                  ? googleSearchConsole?.impressions?.info
                  : 'Impressions'
              }
              prevPeriod={googleSearchConsole?.impressions?.previous_period}
              previousYear={googleSearchConsole?.impressions?.previous_year}
              isLoading={googleSearchConsole.loading}
              totalValue={formatNumberWithCommas(lastMonthImpressions)}
              error={googleSearchConsole.error}
              icon="googleSearch"
              propertyId={selectedClient?.google_search_console?.site_url}
              retryMethod={retryFetchGAConsole}
            />
          </Col>
          <Col>
            <AreaCardChart
              title={
                googleSearchConsole?.ctr?.label
                  ? googleSearchConsole?.ctr?.label
                  : 'CTR'
              }
              chatData={ctr}
              category={['Ctr']}
              info={
                googleSearchConsole?.ctr?.info
                  ? googleSearchConsole?.ctr?.info
                  : 'CTR'
              }
              prevPeriod={googleSearchConsole?.ctr?.previous_period}
              previousYear={googleSearchConsole?.ctr?.previous_year}
              isLoading={googleSearchConsole.loading}
              totalValue={formatNumber(lastMonthCtr, true)}
              error={googleSearchConsole.error}
              hasPercent
              icon="googleSearch"
              propertyId={selectedClient?.google_search_console?.site_url}
              retryMethod={retryFetchGAConsole}
            />
          </Col>
          <Col>
            <AreaCardChart
              title={
                googleSearchConsole?.position?.label
                  ? googleSearchConsole?.position?.label
                  : 'Positions'
              }
              chatData={position}
              category={['Position']}
              info={
                googleSearchConsole?.position?.info
                  ? googleSearchConsole?.position?.info
                  : 'Positions'
              }
              prevPeriod={googleSearchConsole?.position?.previous_period}
              previousYear={googleSearchConsole?.position?.previous_year}
              isLoading={googleSearchConsole.loading}
              totalValue={lastMonthPosition?.toFixed()}
              error={googleSearchConsole.error}
              icon="googleSearch"
              propertyId={selectedClient?.google_search_console?.site_url}
              retryMethod={retryFetchGAConsole}
            />
          </Col>
        </Grid>
      </div>
      <Keywords
        isLoading={googleSearchConsole.loading}
        data={googleSearchConsole || {}}
        error={googleSearchConsole.error}
        propertyId={selectedClient?.google_search_console?.site_url}
        retryMethod={retryFetchGAConsole}
      />
      <Pages
        isLoading={googleSearchConsole.loading}
        data={googleSearchConsole || {}}
        error={googleSearchConsole.error}
        propertyId={selectedClient?.google_search_console?.site_url}
        retryMethod={retryFetchGAConsole}
      />
    </>
  );
};

export default SearchConsoleAnalysis;
