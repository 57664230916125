import { requests } from "./ApiService";

interface ILogin {
  email: string;
  password: string;
}

export const Auth = {
  login: (data: ILogin) => requests.post('/api/v1/auth/login', data),
  googleLogin: (data) => requests.post('/api/v1/auth/google', data),
  microsoftLogin: (data) => requests.post('/api/v1/auth/microsoft', data),
  signup: (data) => requests.post('/api/v1/auth/signup', data),
  checkDomain: (domain) => requests.get(`/api/v1/check-domain/${domain}`),
  forgotPassword: (data) => requests.post(`/api/v1/auth/forgot_password`, data),
  resetPassword: (data) => requests.post(`/api/v1/auth/reset_password`, data),
  fbReAuthenticate: (data) => requests.post(`/api/v1/fb/re-authenticate`, data),
  logout: () => requests.post('/api/v1/auth/logout'),
};