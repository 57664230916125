import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import ImageIcon from '../ImageIcon';
import { getBadgeColorAndText } from '../../utils/common';
import Icon from '../../assets/icons/SvgComponent';

// Portal Component to render outside of the DOM hierarchy
const Portal = ({ children }) => {
  return ReactDOM.createPortal(children, document.body);
};

const ProfileTooltip = ({
  children,
  name,
  profile_pic,
  email,
  role,
  lastSeen,
  numberOfClients,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [tooltipStyle, setTooltipStyle] = useState({});
  const tooltipRef = useRef(null);
  const triggerRef = useRef(null);

  // Adjust the position of the tooltip
  const adjustTooltipPosition = () => {
    if (tooltipRef.current && triggerRef.current) {
      const triggerRect = triggerRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();

      let top = triggerRect.bottom + window.scrollY;
      let left = triggerRect.left + window.scrollX;

      // Adjust if tooltip goes beyond the screen's right edge
      if (left + tooltipRect.width > window.innerWidth) {
        left = window.innerWidth - tooltipRect.width - 160;
      }

      // Adjust if tooltip goes beyond the screen's bottom edge
      if (top + tooltipRect.height > window.innerHeight) {
        top = triggerRect.top + window.scrollY - tooltipRect.height;
      }

      setTooltipStyle({ top, left });
    }
  };

  useEffect(() => {
    if (isVisible) {
      adjustTooltipPosition();
      window.addEventListener('resize', adjustTooltipPosition);
    }
    return () => {
      window.removeEventListener('resize', adjustTooltipPosition);
    };
  }, [isVisible]);

  return (
    <div
      className="relative inline-block"
      ref={triggerRef}
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      {isVisible && (
        <Portal>
          <div
            ref={tooltipRef}
            style={{ position: 'absolute', ...tooltipStyle }}
            className="bg-white text-sm rounded p-[16px] shadow-lg z-50 w-[273px] h-[135px]"
          >
            <div className="flex items-center gap-2 border-b pb-2 mb-2">
              <div>
                <ImageIcon
                  data={{
                    name,
                    profile_pic
                  }}
                  sizeInPixels="w-[67px] h-[67px]"
                  textSize={'2xl'}
                />
              </div>
              <div className="flex flex-col items-between justify-between h-[67px]">
                <div className="text-sm font-semibold text-[#001C44] max-w-[170px] truncate">
                  {name}
                </div>
                <div className="text-sm font-normal text-[#405573] max-w-[170px] truncate">
                  {email}
                </div>
                <div
                  className={`${getBadgeColorAndText(role)?.color} uppercase w-fit text-xs px-[6px] py-[2px] rounded-[4px] font-semibold`}
                >
                  {role}
                </div>
              </div>
            </div>
            <div>
              <div className="text-[#001C44] text-sm font-normal mb-[8px] flex gap-2 items-center">
                <div className="w-[20px]">
                  <Icon name="Clock" />
                </div>
                <div>Last seen {lastSeen}</div>
              </div>
              {/* <div className="flex justify-between">
                <div className="text-[#001C44] text-sm font-normal flex gap-2 items-center">
                  <div>
                    <Icon name="NumberOfClient" />
                  </div>
                  Number of Clients:
                </div>
                <div className="text-[#001C44] text-[14px] font-[400]">
                  {numberOfClients}
                </div>
              </div> */}
            </div>
          </div>
        </Portal>
      )}
    </div>
  );
};

export default ProfileTooltip;