import { Outlet } from 'react-router-dom';

const Layout = () => {
  return (
    <div className="flex w-full h-full">
      <div className="flex flex-col w-full h-full items-center">
        <Outlet />
      </div>
    </div>
  );
};
export default Layout;
