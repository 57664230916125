import { useState } from 'react';

const DropdownUser = ({ styles, items, selected, onSelect }) => {
  const [open, setOpen] = useState(false);
  const selectItem = (value) => {
    setOpen(false);
    onSelect(value);
  };
  return (
    <div className="flex justify-between items-center w-full h-8 rounded-10 p-0 relative cursor-pointer outline-none border-none text-base z-1">
      <span>{selected} </span>
      <div className="flex flex-col w-full h-[160px] mt-[140px] rounded-10 bg-white dark:bg-gray-800 rounded-md border border-black-500 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8">
        {items?.map((item, idx) => (
          <span
            className="cursor-pointer border-b border-gray-300 pb-2 pt-2 px-5 text-base text-gray-700 dark:text-gray-300 transition duration-200 opacity-80 last:border-none hover:text-gray-500"
            key={idx}
            onClick={() => selectItem(item)}
          >
            {item.name}
          </span>
        ))}
      </div>
    </div>
  );
};
export default DropdownUser;
