import { motion } from 'framer-motion';

interface AnalyticsTooltipProps {
  position: 'top' | 'bottom' | 'left' | 'right';
  content: any;
  children: any;
}

const InfoTooltip: React.FC<AnalyticsTooltipProps> = ({
  position,
  content,
  children,
}) => {
  let tooltipClass =
    'absolute hidden group-hover:inline-block bg-[#fff] border shadow-md border-[#D9DFFF] text-[#000] text-xs p-2 py-2 px-4 whitespace-pre-line rounded-lg w-[180px] dark:bg-gray-700 max-h-[150px] overflow-auto z-[98] scrollbar-none';
  let arrowClass = 'absolute hidden group-hover:inline-block  ';

  if (position === 'top') {
    tooltipClass +=
      ' left-[-120px]  -translate-x-[-100px] bottom-[calc(100%+9px)]';
    arrowClass +=
      ' absolute left-[7px] bottom-[23px] transform -translate-x-1/2 translate-y-1/2 rotate-45 w-3 h-3 bg-white border-r border-b border-[#D9DFFF] dark:bg-gray-700 z-[99]';
  }

  return (
    <div className="relative cursor-pointer group">
      <div>{children}</div>
      <motion.span className={`${tooltipClass} dark:text-white`}>
        {content}
      </motion.span>
      <motion.span
        className={`${position === 'left' || position === 'right' ? '' : arrowClass} `}
      ></motion.span>
    </div>
  );
};

export default InfoTooltip;
