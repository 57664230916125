import { useState, useEffect } from 'react';
import AccountManagerCard from './accountManager';
import UpcomingMeetingsCard from './upcomingMeetings';
import StrategyDocumentCard from './strategyDocument';
import useActivitiesStore from '../../store/useActivitiesStore';
import CustomModal from '../CustomModal';
import RequestModal from '../Dashboard/RequestModal';
import BookMeetingModal from '../Modals/BookMeeting';
import { Grid, Col } from '@tremor/react';
import 'swiper/css';
import 'swiper/css/pagination';

interface Props {
  selectedClient?: any;
}

interface SelectedService {
  id?: string | null;
  title?: string | null;
  status?: string | null;
  icon?: string | null;
  startDate?: string;
  revenue?: string;
  serviceId?: string;
  contractDue?: string;
}

const AccountManager: React.FC<Props> = ({ selectedClient }) => {
  const [openModal, setOpenModal] = useState(false);
  const { fetchUpcomingMeeting, upcomingMeeting } = useActivitiesStore(
    (state) => state
  );
  const [selectedService, setSelectedService] =
    useState<SelectedService | null>(null);
  const [isRequestModal, setRequestModal] = useState(false);
  const [upcomingMeetingLoading, setUpcomingMeetingLoading] = useState(true);

  const handleFetchMeeting = async () => {
    const filters = {
      clientId: selectedClient?.id,
      country: 'AU',
    };
    try {
      if (selectedClient?.id) {
        await fetchUpcomingMeeting(filters);
        await setUpcomingMeetingLoading(false);
      }
    } catch (error) {
      console.error('Error fetching upcoming meeting:', error);
    }
  };

  useEffect(() => {
    handleFetchMeeting()
      .then(() => {
        setUpcomingMeetingLoading(true);
        handleFetchMeeting();
      })
      .catch((error) => {
        console.log(error);
      });
  }, [selectedClient]);

  const requestGoogleSEOAccess = () => {
    setSelectedService({
      id: selectedClient?.id || null,
      icon: 'OutlineGoogleSEO',
      title: 'Google Seo',
      status: 'inactive',
      startDate: null,
      revenue: null,
      serviceId: null,
    });
    setRequestModal(true);
  };

  const requestGoogleAdsAccess = () => {
    setSelectedService({
      id: selectedClient?.id || null,
      icon: 'OutlineGoogleAds',
      title: 'Google Ads',
      status: 'inactive',
      startDate: null,
      revenue: null,
      serviceId: null,
    });
    setRequestModal(true);
  };

  const requestMetaAccess = () => {
    setSelectedService({
      id: selectedClient?.id || null,
      icon: 'OutlineMetaAds',
      title: 'Meta',
      status: 'inactive',
      startDate: null,
      revenue: null,
      serviceId: null,
    });
    setRequestModal(true);
  };

  return (
    <div className="flex flex-col gap-4 lg:gap-y-8 px-1">
      <Grid
        numItems={1}
        numItemsSm={1}
        numItemsMd={1}
        numItemsLg={2}
        className="gap-4 lg:gap-x-8"
      >
        <Col>
          <AccountManagerCard selectedClient={selectedClient} />
        </Col>
        <Col>
          <div className="flex flex-col gap-4 md:w-full">
            <div className="w-full">
              <UpcomingMeetingsCard
                upcomingMeeting={upcomingMeeting}
                upcomingMeetingLoading={upcomingMeetingLoading}
                setOpenModal={setOpenModal}
              />
            </div>
            <div className="w-full">
              <StrategyDocumentCard
                selectedClient={selectedClient}
                requestGoogleAdsAccess={requestGoogleAdsAccess}
                requestGoogleSEOAccess={requestGoogleSEOAccess}
                requestMetaAccess={requestMetaAccess}
              />
            </div>
          </div>
        </Col>
      </Grid>
      <CustomModal open={openModal} onClose={() => setOpenModal(false)}>
        <BookMeetingModal
          onClose={() => {
            setOpenModal(false);
          }}
        />
      </CustomModal>
      <CustomModal
        open={isRequestModal}
        onClose={() => {
          setRequestModal(false);
        }}
      >
        <RequestModal {...selectedService} />
      </CustomModal>
    </div>
  );
};

export default AccountManager;
