import { CgTimer } from 'react-icons/cg';
import useReportStore from '../../../store/useReportStore';
import moment from 'moment';
interface Props {
  selectedReport: any;
}

const ChangeHistory: React.FC<Props> = ({ selectedReport }) => {
  const { comments } = selectedReport;
  const sortedComments = comments.sort(
    (a: any, b: any) =>
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  );
  const data = [
    {
      date: '2023-01-01',
      name: 'John Doe',
      status: 'scheduled',
      comment: 'This is a comment',
    },
    {
      date: '2023-01-02',
      name: 'Jane Smith',
      status: 'in_progress',
      comment: 'This is another comment',
    },
  ];

  return (
    <div className="border-t pt-4 mt-8">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-5 rounded-tl-[8px]  rounded-tr-[8px]">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 rounded-tl-[8px]  rounded-tr-[8px]">
          <tr className="rounded-tl-[8px]  rounded-tr-[8px]">
            <th className="text-left px-6 py-3 rounded-tl-[8px]">Date</th>
            <th className="text-center px-6 py-3">Author</th>
            <th className="text-center px-6 py-3">Status</th>
            <th className="text-left px-6 py-3  rounded-tr-[8px]">Comment</th>
          </tr>
        </thead>
        <tbody>
          {sortedComments?.map((item: any, i: number) => {
            return (
              <>
                <tr
                  className="bg-white border border-[#EAECF0] dark:bg-gray-800 dark:border-gray-700"
                  key={i}
                >
                  <td className=" text-left px-6 py-4 w-[20%]">
                    {moment(item?.created_at).format('DD-MM-YYYY')}
                  </td>
                  <td className=" text-center px-6 py-4 w-[8vw]">
                    {item?.name}
                  </td>
                  <td className=" px-6 py-4  uppercase">
                    <div
                      className={
                        item.status === 'ready'
                          ? 'text-[#0B996D] bg-[#E7F8F2] border border-[#0B996D] rounded-full text-sm px-2.5 py-1 text-center  dark:bg-blue-600 dark:text-white'
                          : item.status === 'flagged'
                            ? 'text-[#EA580C] bg-[#FEEBEF] border border-[#EA580C] rounded-full text-sm px-2.5 py-1 text-center  dark:bg-red-600 dark:text-white'
                            : item.status === 'canceled'
                              ? 'text-[#8F2929] bg-[#FEEBEF] border border-[#8F2929] rounded-full text-sm px-2.5 py-1 text-center  dark:bg-red-600 dark:text-white'
                              : 'text-[#005C9F] bg-[#E6EFF5] border border-[#80AECF] rounded-full text-sm px-2.5 py-1 text-center  dark:bg-blue-600 dark:text-white'
                      }
                    >
                      {item.status}
                    </div>
                  </td>
                  <td className=" text-left px-6 py-4">{item?.comment}</td>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
      {!data.length && <span className="ml-[38%]">No Data found</span>}
    </div>
  );
};

export default ChangeHistory;
