import { useEffect, useMemo, useState } from 'react';
import TanStackTable from '../../components/TanStackTable';
import Icon from '../../assets/icons/SvgComponent';
import useUserStore from '../../store/useUserStore';
import useAuthStore from '../../store/useAuthStore';
import IndeterminateCheckbox from '../../components/IndeterminateCheckbox';
import ReactSelect from '../../components/ReactSelect';
import { useLocation, useNavigate } from 'react-router-dom';
import ToolTipV2 from '../../components/TooltipV2';
import Tooltip from '../../components/HelpTooltip';
import CustomModal from '../../components/CustomModal';
import ConfirmationModal from '../../components/Modals/UserModal/confirmationModal';
import EditUserModal from '../../components/Modals/UserModal/editUserModal';
import AddUserModal from '../../components/Modals/UserModal/addUserModal';
import BulkActionModal from '../../components/Modals/UserModal/bulkActionModal';
import ConfirmStatus from '../../components/Modals/UserModal/confirmationStatus';
import Alert from '../../components/Alert';
import ImageIcon from '../../components/ImageIcon';
import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CopyToClipboardButton from '../../components/CopyButton';
import moment from 'moment';
import SearchBar from '../../components/Search';
import { getRoleName } from '../../utils';

type Props = {
  type: string;
};

const Users: React.FC<Props> = ({ type }) => {
  const {
    fetchUsers,
    users,
    updateUserStatus,
    loading,
    deleteUsers,
    updateUser,
    apiStatus,
  } = useUserStore((state) => state);
  const [status, setStatus] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [searchText, setSearchText] = useState('');
  const [role, setRole] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [limit] = useState({ label: '20', id: '20' });
  const { loginAsUser, loginAsClient, user } = useAuthStore((state) => state);
  const [page, setPage] = useState(1);
  const location = useLocation();
  const client_id = location.pathname.split('/')[3];
  const [, setShowTooltip] = useState(false);
  const [confirmationStatus, setConfirmationStatus] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState({});
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [rowState, setRowState] = useState(true);
  const [bulkActionModal, setBulkActionModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [urlFilter, setUrlFilter] = useState(false);
  const navigate = useNavigate();
  const handleFetchUsers = (currentPage: number) => {
    const clientId = client_id ? client_id : loginAsUser?.clients[0]?.id || '';
    const userType = type === 'users' ? 'client' : type;
    const filters = {
      level: userType,
      status: status ? status.value : 'all',
      role: role ? role.value : 'all',
      page: currentPage || page,
      limit: limit.id,
      search: searchText,
      client_id: clientId,
    };
    fetchUsers(filters);
    if (currentPage) {
      setPage(currentPage);
    }
  };

  const statusOptions = [
    { value: 'all', label: 'All Status' },
    { value: 'active', label: 'Enabled' },
    { value: 'inactive', label: 'Disabled' },
  ];

  const roleOptions = [
    { value: 'all', label: 'All Roles' },
    { value: 'own', label: 'Owner' },
    { value: 'stf', label: 'Staff' },
  ];

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const statusFromUrl = queryParams.get('status');
    const roleFromUrl = queryParams.get('role');

    const foundStatusOption = statusOptions.find(
      (option) => option.value === statusFromUrl
    );
    const foundRoleOption = roleOptions.find(
      (option) => option.value === roleFromUrl
    );
    setStatus(foundStatusOption);
    setRole(foundRoleOption);
    setUrlFilter(true);
  }, [location]);

  useEffect(() => {
    if (urlFilter) handleFetchUsers(page);
  }, [page, status, role, apiStatus]);

  const formattedDataMemo = useMemo(() => {
    const formatData = (data: any) => {
      if (!data || !Array.isArray(data)) return null;
      const userData = data.map((user: any) => {
        return {
          id: user.id,
          fullName: user,
          clients: user,
          lastActivity: user?.last_activity,
          status: user,
          role: user.gurulytics_role,
          userDetails: user,
        };
      });
      return userData;
    };
    const formattedData = formatData(users.data);
    return formattedData;
  }, [users.data]);

  const handleDeleteSelectedUser = (user: any) => {
    const userID = [user.id];
    setOpenDeleteModal(true);
    setSelectedUser(user);
    deleteUsers(userID);
  };

  const handleEditSelectedUser = (user: any) => {
    setOpenEditModal(true);
    setSelectedUser(user);
  };

  const handleBulkEnable = () => {
    const result: string[] = selectedRow.map((user: any) => {
      return user.id;
    });
    updateUserStatus({ ids: result, status: 'active' });
    setRowState(false);
    toast.success(
      <Alert
        icon=""
        title={`STATUS UPDATE`}
        message={`User's status has been set to "active" successfully`}
      />,
      {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
    setTimeout(() => {
      handleFetchUsers(1);
    }, 500);
    setSelectedRow([]);
    setBulkActionModal(false);
    setModalType('');
  };

  const loginAs = (data: { role: { role: string } }) => {
    try {
      if (user.role.level === 'omg') {
        loginAsClient(data);
        navigate(`/dashboard`);
      } else {
        // Handle the case where user.role.level is not 'omg'
        alert('Invalid user role level');
      }
    } catch (error) {
      toast.error(
        <Alert
          icon=""
          title={`Client Error`}
          message={`Sorry this user doesn't have any clients. Add a client to proceed`}
        />,
        {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  const handleBulkDisable = () => {
    const result: string[] = selectedRow.map((user: any) => {
      return user.id;
    });
    updateUserStatus({ ids: result, status: 'inactive' });
    setRowState(false);
    toast.success(
      <Alert
        icon=""
        title={`STATUS UPDATE`}
        message={`User's status has been set to "inactive" successfully`}
      />,
      {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
    setTimeout(() => {
      handleFetchUsers(1);
    }, 1000);
    setSelectedRow([]);
    setBulkActionModal(false);
    setModalType('');
  };

  const handleBulkDelete = () => {
    const result: string[] = selectedRow.map((user: any) => {
      return user.id;
    });
    deleteUsers({ ids: result });
    setRowState(false);
    toast.success(
      <Alert
        icon=""
        title={`USERS DELETED`}
        message={`User's has been deleted successfully`}
      />,
      {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
    setTimeout(() => {
      handleFetchUsers(1);
    }, 1000);
    setSelectedRow([]);
    setBulkActionModal(false);
    setModalType('');
  };

  const removeUser = (userId: string) => {
    const updatedSelectedRow = selectedRow.filter(
      (user: any) => user.id !== userId
    );
    setSelectedRow(updatedSelectedRow);
  };

  const handleToggleStatus = (id: string, currentStatus: string) => {
    const newStatus = currentStatus === 'active' ? 'inactive' : 'active';
    updateUserStatus({ ids: id, status: newStatus });
    toast.success(
      <Alert
        icon=""
        title={`CLIENT USER STATUS`}
        message={`Client User's status been changed successfully`}
      />,
      {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
    setConfirmationStatus(false);
    setConfirmStatus({});
  };

  const columns = [
    {
      id: 'Select',
      header: ({ table }: any) => {
        return (
          <div className="flex items-center justify-center w-fit ml-3">
            <IndeterminateCheckbox
              {...{
                checked: table.getIsAllPageRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
          </div>
        );
      },
      cell: ({ row }: any) => {
        return (
          <div className="flex items-center justify-center w-fit ml-3">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        );
      },
    },
    {
      id: 'Client Name',
      header: () => (
        <div className="text-left w-[220px] sm:w-[20vw] pl-3 text-sm font-[600] font-inter">
          User Name
        </div>
      ),
      accessorKey: 'fullName',
      cell: (props: any) => {
        const data = {
          name: `${props.getValue()?.first_name} ${props.getValue()?.last_name}`,
        };
        return (
          <div className="flex gap-2 items-center font-inter w-[220px] sm: pl-3">
            {props.getValue()?.profile_pic ? (
              <div className="w-[36px]">
                <img
                  src={props.getValue()?.profile_pic}
                  alt="profile_pic"
                  className="w-full"
                />
              </div>
            ) : (
              <div>
                <ImageIcon data={data} size={8} textSize={'xs'} />
              </div>
            )}
            <div className="text-sm font-semibold text-gray-500 truncate w-[220px] sm:w-[20vw] overflow-hidden font-inter">
              <div className="truncate   overflow-hidden dark:text-white">
                {props.getValue()?.first_name} {props.getValue()?.last_name}
              </div>
              <div className="flex justify-between">
                <div className="font-[400] truncate overflow-hidden dark:text-white">
                  {props.getValue()?.email}
                </div>
                <CopyToClipboardButton
                  textToCopy={props.getValue()?.email}
                  size={12}
                />
              </div>
            </div>
          </div>
        );
      },
    },
    {
      id: 'Clients',
      header: () => (
        <div className="text-center w-[180px] sm:w-[20vw] text-sm font-[600] font-inter">
          Clients
        </div>
      ),
      accessorKey: 'clients',
      cell: (clients: any) => {
        const clientsData = clients?.getValue()?.clients;

        const firstFiveClients = clientsData.slice(0, 5); // Get the first 5 clients

        const clientNames = clients
          .getValue()
          ?.clients.map((client: any) => client.name);

        let tooltip = '';

        if (clientNames && clientNames.length > 10) {
          const displayedNames = clientNames.slice(0, 10).join('\n');
          const remainingCount = clientNames.length - 10;
          tooltip = `${displayedNames}\n... +${remainingCount}`;
        } else if (clientNames) {
          tooltip = clientNames.join('\n');
        }

        return (
          <div className="flex justify-center w-[180px] sm:w-[20vw]">
            {firstFiveClients.length > 0 ? (
              firstFiveClients.map((client: any) => (
                <div
                  key={client.id}
                  className="flex flex-col justify-center items-center font-inter"
                >
                  <Tooltip content={client.name} position="right">
                    <div
                      className={`rounded-full overflow-hidden ml-[-6px] border-white border`}
                    >
                      <ImageIcon data={client} size={8} textSize={'xs'} />
                    </div>
                  </Tooltip>
                </div>
              ))
            ) : (
              <div
                className="flex flex-col justify-center items-center font-inter"
                onClick={() => handleEditSelectedUser(clients.getValue())}
              >
                <p className="text-[#4A4B4C] dark:text-[#fff]">
                  No Clients Added Yet
                </p>
                <span className="text-[#005C9F] text-xs cursor-pointer">
                  Click here to add.
                </span>
              </div>
            )}
            <ToolTipV2 tooltip={tooltip}>
              <div
                className={
                  clientsData.length <= 5
                    ? 'hidden'
                    : 'w-9 h-9 rounded-full overflow-hidden relative ml-[-6px] border-white border-2 font-inter'
                }
                onMouseOver={() => setShowTooltip(true)}
                onMouseOut={() => setShowTooltip(false)}
              >
                <div
                  className={
                    clientsData.length <= 5
                      ? 'hidden'
                      : 'bg-orange-400 text-[#000] flex items-center justify-center text-xs w-[20vw] h-full uppercase'
                  }
                >
                  {clientsData.length <= 5 ? '' : `+${clientsData.length - 5}`}
                </div>
              </div>
            </ToolTipV2>
          </div>
        );
      },
    },
    {
      id: 'Last Seen',
      header: () => (
        <div className="flex justify-center w-[120px] sm:w-[15vw] text-sm font-[600] font-inter">
          Last Seen
        </div>
      ),
      accessorKey: 'lastActivity',
      cell: (props: any) => (
        <div className="flex justify-center font-inter w-[120px] sm:w-[15vw] text-sm font-[600]">
          {props.getValue() ? (
            <div className="font-light text-sm truncate overflow-hidden dark:text-white">
              {moment(props.getValue()).fromNow()}
            </div>
          ) : (
            <div className="dark:text-white">N/A</div>
          )}
        </div>
      ),
    },
    {
      id: 'Role',
      header: () => (
        <div className="flex justify-center w-[120px] sm:w-[15vw] text-sm font-[600] font-inter">
          Role
        </div>
      ),
      accessorKey: 'role',
      cell: (props: any) => (
        <div className="flex justify-center font-inter w-[120px] sm:w-[15vw]">
          <div className="text-xs rounded-full border bg-[#D9D6FE] px-2 py-1 uppercase dark:bg-[#fff] dark:text-[#000]">
            {getRoleName(props.getValue())}
          </div>
        </div>
      ),
    },
    {
      id: 'isEnabled',
      header: () => (
        <div className="text-left w-[60px] sm:w-[7vw] text-sm font-[600] font-inter">
          Status
        </div>
      ),
      accessorKey: 'status',
      cell: (props: any) => (
        <label className="relative inline-flex items-center cursor-pointer font-inter w-[60px] sm:w-[7vw]">
          <input
            type="checkbox"
            className="sr-only peer"
            defaultChecked={props.getValue()?.status === 'active'}
            value={props.getValue()?.status}
            onClick={() => {
              setConfirmStatus(props.getValue());
              setConfirmationStatus(true);
            }}
          />
          <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-[#005C9F]"></div>
        </label>
      ),
    },
    {
      id: 'Action',
      header: () => (
        <div className="text-center w-[120px] sm:w-[5vw] text-sm font-[600] font-inter">
          Action
        </div>
      ),
      accessorKey: 'userDetails',
      cell: (props: any) => (
        <>
          <div className="flex gap-2 justify-center cursor-pointer font-inter  w-[120px] sm:w-[5vw]">
            <button onClick={() => handleEditSelectedUser(props.getValue())}>
              <Icon name="UserEditIcon" color="#7C7E7F" />
            </button>
            <button onClick={() => loginAs(props.getValue())}>
              <Icon name="Login" />
            </button>
          </div>
        </>
      ),
    },
  ];

  const newUser = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    gurulytics_role: '',
    clients: [],
  };

  const handleSearch = () => {
    try {
      handleFetchUsers(page);
      setPage(1);
      setRowState(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleStatusChange = (selectedOption: any, actionMeta: string) => {
    const newUrl = new URL(window.location.href);
    if (actionMeta === 'status') {
      setStatus(selectedOption);
      newUrl.searchParams.set('status', selectedOption.value);
    }
    if (actionMeta === 'role') {
      setRole(selectedOption);
      newUrl.searchParams.set('role', selectedOption.value);
    }

    window.history.pushState({}, '', newUrl);
  };

  return (
    <div className="bg-[#D2EEFF] mt-12 px-2 sm:mt-0 sm:py-3 sm:px-[32px] lg:overflow-hidden min-h-[60dvh] lg:min-h-[93dvh] dark:bg-[#0C111D] font-inter rounded-lg">
      <div className="bg-[#D2EEFF] pt-2 sm:pt-0 rounded-lg dark:bg-[#0C111D]">
        <div className="flex items-center justify-between mt-12 px-2 py-4 sm:mt-0 sm:pt-14 lg:p-4 bg-white dark:bg-gray-800 lg:sticky top-0 z-10 rounded-t-lg">
          <div className="flex items-center flex-wrap gap-2">
            <SearchBar
              value={searchText}
              onChange={setSearchText}
              onSearch={handleSearch}
              placeholder="Search for anything"
              width="w-[220px] sm:w-[300px]"
            />
            <div>
              <ReactSelect
                options={statusOptions}
                value={status}
                placeholder="All Status"
                handleOnChange={(selectedOption: any) =>
                  handleStatusChange(selectedOption, 'status')
                }
              />
            </div>
            <div>
              <ReactSelect
                options={roleOptions}
                value={role}
                placeholder="All Roles"
                handleOnChange={(selectedOption: any) =>
                  handleStatusChange(selectedOption, 'role')
                }
              />
            </div>
            <div className="block sm:hidden">
              {selectedRow.length === 0 ? (
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      setOpenAddModal(true);
                    }}
                    className="w-full rounded-lg text-white text-sm border border-[#005C9F] bg-[#005C9F] shadow-xs px-4 py-2 dark:text-[#E6EFF5]"
                  >
                    Add New
                  </button>
                </div>
              ) : (
                <div className="flex gap-2 justify-center  items-center">
                  <div>
                    <button
                      type="button"
                      onClick={() => {
                        setRowState(true);
                        setBulkActionModal(true);
                        setModalType('Enable');
                      }}
                      className="rounded-8 text-sm font-inter flex items-center gap-2 bg-[#005c9f] py-[8px] px-[14px] rounded-md min-w-100px cursor-pointer text-white"
                    >
                      Enable
                      <Icon name="Submit" />
                    </button>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={() => {
                        setRowState(true);
                        setBulkActionModal(true);
                        setModalType('Disable');
                      }}
                      className="rounded-8 text-sm font-inter flex items-center gap-2 bg-[#d92d20] py-[8px] px-[14px] rounded-md min-w-100px cursor-pointer text-white"
                    >
                      Disable
                      <Icon name="Cancel" />
                    </button>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={() => {
                        setRowState(true);
                        setBulkActionModal(true);
                        setModalType('Delete');
                      }}
                      className="rounded-8 text-sm font-inter flex items-center gap-2 bg-[#d92d20] py-[8px] px-[14px] rounded-md min-w-100px cursor-pointer text-white"
                    >
                      Delete
                      <Icon name="Cancel" />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="hidden sm:block">
            {selectedRow.length === 0 ? (
              <div>
                <button
                  type="button"
                  onClick={() => {
                    setOpenAddModal(true);
                  }}
                  className="w-full rounded-lg text-white text-sm border border-[#005C9F] bg-[#005C9F] shadow-xs px-4 py-2 dark:text-[#E6EFF5]"
                >
                  Add New
                </button>
              </div>
            ) : (
              <div className="flex gap-2 justify-center  items-center">
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      setRowState(true);
                      setBulkActionModal(true);
                      setModalType('Enable');
                    }}
                    className="rounded-8 text-sm font-inter flex items-center gap-2 bg-[#005c9f] py-[8px] px-[14px] rounded-md min-w-100px cursor-pointer text-white"
                  >
                    Enable
                    <Icon name="Submit" />
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      setRowState(true);
                      setBulkActionModal(true);
                      setModalType('Disable');
                    }}
                    className="rounded-8 text-sm font-inter flex items-center gap-2 bg-[#d92d20] py-[8px] px-[14px] rounded-md min-w-100px cursor-pointer text-white"
                  >
                    Disable
                    <Icon name="Cancel" />
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      setRowState(true);
                      setBulkActionModal(true);
                      setModalType('Delete');
                    }}
                    className="rounded-8 text-sm font-inter flex items-center gap-2 bg-[#d92d20] py-[8px] px-[14px] rounded-md min-w-100px cursor-pointer text-white"
                  >
                    Delete
                    <Icon name="Cancel" />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <TanStackTable
          data={!formattedDataMemo ? [] : formattedDataMemo}
          columns={columns}
          totalPages={!users.total_pages ? 0 : users.total_pages}
          page={page}
          setPage={setPage}
          loading={loading}
          setSelectedRow={setSelectedRow}
          status={status}
          rowState={rowState}
          setRowState={setRowState}
          tableHeight="h-[60dvh] sm:h-[69.8dvh]"
          role={role}
          totalDocs={users?.total_docs}
        />

        {modalType === 'Enable' ? (
          <CustomModal
            open={bulkActionModal}
            onClose={() => setBulkActionModal(false)}
          >
            <BulkActionModal
              type="enable"
              user={selectedRow}
              onClose={() => setBulkActionModal(false)}
              action={handleBulkEnable}
              onRemoveUser={removeUser}
            />
          </CustomModal>
        ) : modalType === 'Disable' ? (
          <CustomModal
            open={bulkActionModal}
            onClose={() => setBulkActionModal(false)}
          >
            <BulkActionModal
              type="disable"
              user={selectedRow}
              onClose={() => setBulkActionModal(false)}
              action={handleBulkDisable}
              onRemoveUser={removeUser}
            />
          </CustomModal>
        ) : modalType === 'Delete' ? (
          <CustomModal
            open={bulkActionModal}
            onClose={() => setBulkActionModal(false)}
          >
            <BulkActionModal
              type="delete"
              user={selectedRow}
              onClose={() => setBulkActionModal(false)}
              action={handleBulkDelete}
              onRemoveUser={removeUser}
            />
          </CustomModal>
        ) : null}

        <CustomModal open={openAddModal} onClose={() => setOpenAddModal(false)}>
          <AddUserModal
            user={newUser}
            type={type}
            updateUser={updateUser}
            onClose={() => setOpenAddModal(false)}
          />
        </CustomModal>
        <CustomModal
          open={confirmationStatus}
          onClose={() => setConfirmationStatus(false)}
        >
          <ConfirmStatus
            user={confirmStatus}
            onClose={() => setConfirmationStatus(false)}
            action={handleToggleStatus}
          />
        </CustomModal>
        <CustomModal
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
        >
          <ConfirmationModal
            user={selectedUser}
            onClose={() => setOpenDeleteModal(false)}
            onDelete={handleDeleteSelectedUser}
          />
        </CustomModal>
        <CustomModal
          open={openEditModal}
          onClose={() => setOpenEditModal(false)}
        >
          <EditUserModal
            user={selectedUser}
            type={type}
            manageClients={true}
            onClose={() => setOpenEditModal(false)}
          />
        </CustomModal>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={30000}
        hideProgressBar
        newestOnTop={false}
        transition={Flip}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default Users;
