import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const RefreshIcon: React.FC<IconProps> = ({ color = 'black', size = 13 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2265 6.49615C10.0876 7.80109 9.34819 9.02396 8.1247 9.73034C6.09196 10.9039 3.4927 10.2075 2.31909 8.17473L2.19409 7.95823M1.77306 5.60312C1.91197 4.29818 2.65136 3.07531 3.87485 2.36893C5.9076 1.19532 8.50686 1.89179 9.68046 3.92454L9.80546 4.14104M1.74658 9.08265L2.11261 7.71663L3.47863 8.08265M8.5212 4.01663L9.88722 4.38265L10.2532 3.01663"
      stroke={color || '#334969'}
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RefreshIcon;
