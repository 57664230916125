import useClientStore from '../../../store/useClientStore';
import { Card } from '@tremor/react';
import GeneralOverview from '../../AnalyticsSections/GeneralOverview';
import AcquisitionOverview from '../../AnalyticsSections/AcquisitionOverview';
import AcquisitionDetails from '../../AnalyticsSections/AcquisitionDetails';
import PerformanceBreakdown from '../../AnalyticsSections/PerformanceBreakdown';
import CreativeAnalysis from '../../AnalyticsSections/CreativeAnalysis';
import AudienceAnalysis from '../../AnalyticsSections/AudienceAnalysis';
import ServiceNotAvailable from '../../Common/ServiceNotAvailable';
import useMetaStore from '../../../store/useMetaStore';
import Accordion from '../../Accordion';

const AnalyticsFB = () => {
  const { selectedClient } = useClientStore((state) => state);
  const { accountInsights } = useMetaStore((state) => state);

  const isPublicDashboard = window.location.pathname ===  '/client/dashboard';
  const noServiceAvailable = selectedClient?.social_meta?.status === 'active' ? true : false;
  const integrationAvailable =
    selectedClient?.facebook_ads?.ads_id &&
    ['activated', 'active'].includes(selectedClient?.facebook_ads?.status);
  
  // to do handle the case when the client is not active
  
  if (!selectedClient?.loading && !accountInsights?.loading) {
    if(!noServiceAvailable) return <ServiceNotAvailable title={isPublicDashboard ? "No Dashboard Available" : undefined} serviceType="meta" hideRequestAccess={isPublicDashboard} />;
    if(!integrationAvailable) return <ServiceNotAvailable title={isPublicDashboard ? "No Dashboard Available" : "Integration Not Set Up"} serviceType="meta" hideRequestAccess={isPublicDashboard}/>;
  }

  return (
    <div className="sm:pt-4 flex flex-col gap-2 sm:gap-4 ">
      <Accordion
        title="General Overview"
        className="block sm:hidden dark:bg-[#222732] px-2 py-2 mt-0"
      >
        <GeneralOverview />
      </Accordion>
      <Accordion
        title="Acquisition Overview"
        className="block sm:hidden dark:bg-[#222732] px-2 py-2 mt-0"
      >
        <AcquisitionOverview />
      </Accordion>
      <Accordion
        title="Acquisition Details"
        className="block sm:hidden dark:bg-[#222732] px-2 py-2 mt-0"
      >
        <AcquisitionDetails />
      </Accordion>
      <Accordion
        title="Performance Breakdown"
        className="block sm:hidden dark:bg-[#222732] px-2 py-2 mt-0"
      >
        <PerformanceBreakdown />
      </Accordion>
      <Accordion
        title="Creative Analysis"
        className="block sm:hidden dark:bg-[#222732] px-2 py-2 mt-0"
      >
        <CreativeAnalysis />
      </Accordion>
      <Accordion
        title="Audience Analysis"
        className="block sm:hidden dark:bg-[#222732] px-2 py-2 mt-0"
      >
        <AudienceAnalysis />
      </Accordion>
      <Card
        id="general"
        className="hidden sm:block dark:bg-[#222732] px-2 sm:px-6 mt-0 sm:mt-2"
      >
        <GeneralOverview />
      </Card>
      <Card
        id="acquisition"
        className="hidden sm:block dark:bg-[#222732] px-2 sm:px-6 mt-0 sm:mt-2"
      >
        <AcquisitionOverview />
      </Card>
      <Card
        id="acquisitionDetails"
        className="hidden sm:block dark:bg-[#222732] px-2 sm:px-6 mt-0 sm:mt-2"
      >
        <AcquisitionDetails />
      </Card>
      {/* <Card id="performanceOverTime">
        <PerformanceOverTime />
      </Card> */}
      <Card
        id="performanceBreakdown"
        className="hidden sm:block dark:bg-[#222732] px-2 sm:px-6 mt-0 sm:mt-2"
      >
        <PerformanceBreakdown />
      </Card>
      <Card
        id="creativeAnalysis"
        className="hidden sm:block dark:bg-[#222732] px-2 sm:px-6 mt-0 sm:mt-2"
      >
        <CreativeAnalysis />
      </Card>
      <Card
        id="audienceAnalysis"
        className="hidden sm:block dark:bg-[#222732] px-2 sm:px-6 mt-0 sm:mt-2"
      >
        <AudienceAnalysis />
      </Card>
    </div>
  );
};

export default AnalyticsFB;
