import React, { FC, useEffect, useState } from 'react';
// import StackedBarChart from "../../../components/AnalyticsCharts/StackedBarChart";
import Icon from '../../../assets/icons/SvgComponent';

import { motion } from 'framer-motion';
import { FadeLoader } from 'react-spinners';
import SearchDropdown from '../../SearchDropdown';
import {
  cycleOptions,
  typeOptions,
  roleOptions,
} from '../../ReportTanStackTable/staticData';
import useReportStore from '../../../store/useReportStore';
import { colors } from '../../../utils/settings';
import { BarChart, Card } from '@tremor/react';
import StackedBarChart from './barChart';

interface ReportInsightProps {
  onClose: () => void;
}

const ReportInsightV2: FC<ReportInsightProps> = ({ onClose }) => {
  const { cycle, type, reportDate } = useReportStore((state) => state.filters);
  const { getHSInsights, reportHSInsights } = useReportStore((state) => state);
  const [reportType, setReportType] = useState<any>(type);
  const [insightCycle, setInsightCycle] = useState<any>(cycle);
  const [insightRole, setInsightRole] = useState<any>(roleOptions[0]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchInsights = async (data: any) => {
    setLoading(true);
    getHSInsights(data)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    // console.log({ reportType, insightRole, insightCycle });
    const data = {
      date: '06-2023',
      type: reportType?.id,
      role: insightRole?.id,
      cycle: insightCycle?.id,
    };
    fetchInsights(data);
  }, [reportType, insightRole, insightCycle]);

  const datasets = [
    {
      datasetLabel: 'Green',
      data: reportHSInsights.datasets?.filter(
        (item: any) => item.datasetLabel === 'green'
      )[0].data,
    },
    {
      datasetLabel: 'Amber',
      data: reportHSInsights.datasets?.filter(
        (item: any) => item.datasetLabel === 'amber'
      )[0].data,
    },
    {
      datasetLabel: 'Red',
      data: reportHSInsights.datasets?.filter(
        (item: any) => item.datasetLabel === 'red'
      )[0].data,
    },
  ];

  const navigateToReports = (datasetLabel: string, label: string) => {
    const currentUrl = window.location.href;
    const newUrl = new URL(currentUrl);
    newUrl.searchParams.set('healthStatus', datasetLabel.toLowerCase());
    newUrl.searchParams.set('status', 'all');
    newUrl.searchParams.set('select', 'all');
    newUrl.searchParams.set('all', 'true');
    newUrl.searchParams.set('type', reportType.id);
    newUrl.searchParams.set('cycle', insightCycle.id);
    newUrl.searchParams.set('date', label);

    // Open new tab
    window.open(newUrl?.toString(), '_blank');
  };

  // const barChartData = reportHSInsights?.dates?.map((date: any, index: number) => ({
  //   date,
  //   barColors: [
  //     reportHSInsights?.datasets[2]?.data[index], // green
  //     reportHSInsights?.datasets[1]?.data[index], // amber
  //     reportHSInsights?.datasets[0]?.data[index], // red
  //   ],

  // }));
  const barChartData = reportHSInsights?.dates?.map(
    (date: any, index: number) => {
      const greenData = reportHSInsights?.datasets?.find(
        (item: any) => item.datasetLabel === 'green'
      )?.data[index];
      const amberData = reportHSInsights?.datasets?.find(
        (item: any) => item.datasetLabel === 'amber'
      )?.data[index];
      const redData = reportHSInsights?.datasets?.find(
        (item: any) => item.datasetLabel === 'red'
      )?.data[index];

      return {
        Month: date,
        Green: greenData || 0,
        Amber: amberData || 0,
        Red: redData || 0,
      };
    }
  );

  //display no record if no data
  const valueFormatter = (number: number) =>
    Intl.NumberFormat('us').format(number).toString();
  const greenData = reportHSInsights?.datasets?.find(
    (item: any) => item.datasetLabel === 'green'
  )?.data;
  const amberData = reportHSInsights?.datasets?.find(
    (item: any) => item.datasetLabel === 'amber'
  )?.data;
  const redData = reportHSInsights?.datasets?.find(
    (item: any) => item.datasetLabel === 'red'
  )?.data;

  const checkDataChart = !(
    greenData?.every((value: number) => value === 0) &&
    amberData?.every((value: number) => value === 0) &&
    redData?.every((value: number) => value === 0)
  );

  return (
    <motion.div
      className="fixed top-1/4 left-1/4 flex flex-col items-center py-6 px-8 space-y-6 w-1/2 h-fit rounded-md border border-[#E6EBF0] bg-white dark:bg-dark-tremor-background  "
      initial={{ y: '-50%', opacity: 0 }}
      exit={{ y: '-50%', opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="flex items-start w-full justify-between ">
        <div className="flex items-center gap-x-4 ">
          <SearchDropdown
            customStyle={{ width: 150 }}
            labelKey="label"
            valueKey="id"
            onSelect={(option) => setReportType(option)}
            defaultValue={reportType.label}
            placeholder="Select Report Type"
            options={typeOptions || []}
          />
          <SearchDropdown
            customStyle={{ width: 'fit-content' }}
            labelKey="label"
            valueKey="id"
            onSelect={(option) => setInsightCycle(option)}
            defaultValue={insightCycle.label}
            placeholder="Select Cycle"
            options={cycleOptions || []}
          />
          <SearchDropdown
            customStyle={{ width: 190 }}
            labelKey="label"
            valueKey="id"
            onSelect={(option) => setInsightRole(option)}
            defaultValue={insightRole.label}
            placeholder="Select Role"
            options={roleOptions || []}
          />
        </div>
        <button onClick={onClose} className="close-btn">
          <Icon name="xclose" size={30} />
        </button>
      </div>

      <Card className="relative">
        {loading ? (
          <div className="absolute top-1/3 left-1/2 z-10">
            <Icon name="Loading" />
          </div>
        ) : null}
        {checkDataChart ? (
          <BarChart
            className="opacity-70"
            data={barChartData}
            index="Month"
            categories={['Green', 'Amber', 'Red']}
            colors={['emerald', 'amber', 'red']}
            valueFormatter={valueFormatter}
            yAxisWidth={48}
            stack={true}
          />
        ) : (
          <div className="w-full h-80 flex flex-col justify-center items-center">
            <p className="mb-4">No records found</p>
            <Icon name="NoData" />
          </div>
        )}
      </Card>

      {/* <StackedBarChart
        variant="Organic Sessions"
        labels={reportHSInsights?.dates || []}
        datasets={datasets}
        colors={["#005C9F"]}
        width={"100%"}
        height={350}
        onLabelClicked={(datasetLabel, label) =>
          navigateToReports(datasetLabel, label)
        }
      /> */}
    </motion.div>
  );
};

export default ReportInsightV2;
