import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const Flag01: React.FC<IconProps> = ({ color = '#7C7E7F', size = 16 }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="flag-01">
      <path
        id="Icon"
        d="M3.33301 12.5003C3.33301 12.5003 4.16634 11.667 6.66634 11.667C9.16634 11.667 10.833 13.3337 13.333 13.3337C15.833 13.3337 16.6663 12.5003 16.6663 12.5003V2.50033C16.6663 2.50033 15.833 3.33366 13.333 3.33366C10.833 3.33366 9.16634 1.66699 6.66634 1.66699C4.16634 1.66699 3.33301 2.50033 3.33301 2.50033L3.33301 18.3337"
        stroke={color || '#7C7E7F'}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default Flag01;
