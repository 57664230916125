import React, { useState, useEffect } from 'react';
import Icon from '../../../assets/icons/SvgComponent';
import ReactSelect from '../../ReactSelect';
import { useThemeStore } from '../../../store/useThemeStore';
import { ToastContainer, toast, Flip } from 'react-toastify';
import useClientStore from '../../../store/useClientStore';
import useGoogleStore from '../../../store/useGoogleStore';
import Alert from '../../Alert';

interface GoogleAdsModalProps {
  onClose?: () => void;
  data: any;
}

const GoogleAdsModal: React.FC<GoogleAdsModalProps> = ({ onClose, data }) => {
  const { mode } = useThemeStore((state: any) => state);
  const iconColor = mode === 'dark' ? 'white' : 'black';
  const { selectedClient, fetchClient, updateClient } = useClientStore(
    (state) => state
  );
  const { adsClients, fetchAdsClients, loading } = useGoogleStore(
    (state) => state
  );
  const [companyId, setCompanyId] = useState({
    label: data.data?.company_id || '',
    value: data.data?.company_id || '',
  });

  const defaultStatus =
    selectedClient?.hubspot?.status === 'active' ? true : false;
  const [status, setStatus] = useState<boolean>(defaultStatus);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetchAdsClients().then((res: any) => {
      setIsLoading(false);
    });
  }, []);
  const handleSaveChnages = (e: any) => {
    e.preventDefault();
    const id = companyId?.value?.replace('companies/', '');
    if (id) {
      const data = {
        hubspot: {
          company_id: id,
          status: status ? 'active' : 'inactive',
        },
      };
      updateClient(selectedClient?.id, data);
      fetchClient(selectedClient?.id)
        .then((res: any) => {
          setIsLoading(false);
          toast.success(
            <Alert
              icon=""
              title={`HUBSPOT UPDATE`}
              message={`Account Updated`}
            />,
            {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          setTimeout(() => {
            onClose && onClose();
          }, 4000);
        })
        .catch((e: any) => {
          toast.error(
            <Alert icon="" title={`ERROR MESSAGE!`} message={`${e}`} />,
            {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          setIsLoading(false);
        });
    }
  };

  return (
    <div
      className="fixed top-0 right-0 bg-white h-screen w-screen sm:w-[32vw] p-[24px] flex flex-col z-100 dark:bg-[#262627] dark:border dark:border-[#313233] dark:shadow-bs-card"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex justify-between items-center relative">
        <div className="flex gap-[8px] items-center">
          <div className="bg-gray-200 rounded-lg p-2">
            <Icon name="Hubspot" color={mode === 'dark' ? '#fff' : iconColor} />
          </div>
          <div className="text-[24px] dark:text-white">HubSpot</div>
        </div>
        {isLoading && (
          <div className="absolute left-1/2 right-1/2 top-0 bottom-0">
            <Icon name="loading" />
          </div>
        )}
        <div className="cursor-pointer" onClick={onClose}>
          <Icon
            name="Xclose"
            size={20}
            color={mode === 'dark' ? '#fff' : iconColor}
          />
        </div>
      </div>
      <div
        className="hidden sm:flex absolute top-[50%] left-[-50px]  bg-[#E6EFF5]  border border-[#E6EFF5] rounded-[8px] py-[8px] px-[14px] transform rotate-[270deg] text-[14px]  text-[#005C9F] flex gap-[8px] cursor-pointer"
        onClick={onClose}
      >
        <div>Close</div>
        <Icon name="Xclose" size={20} color="#005C9F" />
      </div>
      <div className="flex flex-col justify-between h-[98%]">
        <div>
          <div className="mt-[24px] pt-[24px] border-t mb-[16px] ">
            <p className="mb-[6px] text-sm text-[#001C44] dark:text-white">
              Hubspot ID
            </p>
            <div className="relative">
              <input
                type="text"
                className={`w-full rounded-lg p-2 text-[#001129] border border-[#808EA2] mt-1 dark:bg-[#191919] dark:text-white `}
                placeholder="Company ID"
                value={companyId.label}
                onChange={(e) =>
                  setCompanyId({ label: e.target.value, value: e.target.value })
                }
              />
              {companyId.value && (
                <div
                  className="absolute right-4 top-4 cursor-pointer"
                  onClick={() => setCompanyId({ label: '', value: '' })}
                >
                  <Icon name="Xclose" size={18} />
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-between mt-12 border-t pt-12">
            <div className="dark:text-white">Integration status</div>
            <div>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  defaultChecked={status}
                  checked={status}
                  onChange={(e) => setStatus(e.target.checked)}
                />
                <div className="w-28 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-[5.5rem] rtl:peer-checked:after:-translate-x-[5.5rem] peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#005C9F]" />
                <div
                  className={`w-28 h-6 absolute top-1 left-[30%] text-xs ${status ? 'text-white' : ''}`}
                >
                  {status ? 'Enabled' : 'Disabled'}
                </div>
              </label>
            </div>
          </div>
        </div>
        <div>
          <button
            onClick={handleSaveChnages}
            className="text-white bg-[#005C9F] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm w-full py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          >
            Save
          </button>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={30000}
        hideProgressBar
        newestOnTop={false}
        transition={Flip}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default GoogleAdsModal;
