import React from 'react';
import { Card, ProgressCircle } from '@tremor/react';
import TrendTxt from '../Common/TrendTxt';
import Icon from '../../assets/icons/SvgComponent';
import Tooltip from '../HelpTooltip';
import HeaderTitle from '../HeaderTitle';
import { useThemeStore } from '../../store/useThemeStore';
import NoGoalsTracked from '../../assets/images/NoGoalsTracked.png';

export const TopGoals = () => {
  const { mode } = useThemeStore((state) => state);
  const iconColor = mode === 'dark' ? '#FFF' : '#000';
  const topGoals = [
    {
      taskProgress: 80,
      title: 'Sessions',
      info: 'Lorem ipsum dolor sit amet consectetur.',
      trend: 2.08,
    },
    {
      taskProgress: 30,
      title: 'Completions',
      info: 'Lorem ipsum dolor sit amet consectetur.',
      trend: 2.08,
    },
    {
      taskProgress: 50,
      title: 'Total Revenue',
      info: 'Lorem ipsum dolor sit amet consectetur.',
      trend: 2.08,
    },
  ];

  const noData = topGoals.length === 3;

  return (
    <Card className="dark:bg-[#222732] font-inter">
      <div className="flex justify-between w-full items-center mb-1 xl:mb-6">
        {noData ? (
          <HeaderTitle
            text="No goals tracked yet."
            fontSize=" text-lg sm:text-[24px]"
          />
        ) : (
          <HeaderTitle text="Top Goals" fontSize=" text-lg sm:text-[48px]" />
        )}
      </div>
      {noData ? (
        <div className="flex flex-col justify-center items-center text-center min-h-60">
          <img src={NoGoalsTracked} alt="" className="h-[70px]" />
          <div className="text-sm sm:text-[16px] my-2 font-inter font-[700] dark:text-white">
            No goals tracked yet.
          </div>
          <span className="text-xs sm:text-sm text-[#001C44] font-[300] dark:text-white">
            Start setting and pursuing your goals to activate this dashboard.
            Watch as your progress indicators come to life, mirroring your
            achievmenets and milestones.
          </span>
        </div>
      ) : (
        <div className="flex justify-between">
          {topGoals.map((goal, index) => (
            <div
              key={index}
              className="flex flex-col items-center justify-center gap-2"
            >
              <ProgressCircle
                value={goal.taskProgress}
                size="xl"
                strokeWidth={16}
                tooltip=""
              >
                <span className="text-[20px]">{goal.taskProgress}%</span>
              </ProgressCircle>
              <div>
                <TrendTxt
                  type="year"
                  title=""
                  size="small"
                  value={`${goal.trend}`}
                />
              </div>
              <div className="flex gap-2 items-center">
                <p>{goal.title}</p>
                <Tooltip content={goal.info} position="top">
                  <Icon name="InfoCircle" size={14} color={iconColor} />
                </Tooltip>
              </div>
            </div>
          ))}
        </div>
      )}
      {!noData && (
        <>
          <div className="text-sm mt-4 text-[#001C44] font-[300] line-clamp-3 dark:text-white">
            Once you start your activities, the metrics for Sessions,
            Completions, and Total Revenue will be displayed here. This
            dashboard is your go-to for monitoring the essential goals that
            align with your project's success. Initiate your plans and watch the
            progress indicators reflect your efforts.
          </div>
          <div className="mt-4 text-[#808DA1] text-sm font-semibold">
            READ MORE
          </div>
        </>
      )}
    </Card>
  );
};
