import React, { useEffect, useState, useRef } from 'react';
import Icon from '../../../assets/icons/SvgComponent';
import Comments from './comments';
import useAuthStore from '../../../store/useAuthStore';
import { motion } from 'framer-motion';
import moment from 'moment';
import { ToastContainer, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Badge, Card } from '@tremor/react';
import MetricsCards from './metrics';
import Charts from './charts';
import { formatNumber } from '../../../utils/common';
import { isStaging } from '../../../utils';

type Props = {
  onClose: () => void;
  clientPerformance?: any;
  type?: string;
  handleFetchReports: () => void;
};
interface Comment {
  id: number;
  text: string;
}

const PerformanceModal: React.FC<Props> = ({
  onClose,
  clientPerformance,
  type,
  handleFetchReports,
}) => {
  const [activeBtn, setActiveBtn] = useState<string>('Metrics');
  // const [reportForm, setReportForm] = useState(clientPerformance);
  const [comments, setComments] = useState<Comment[]>([]);
  const [commentText, setCommentText] = useState<string>('');
  const [isOpen, setIsOpen] = React.useState(false);

  const tabs = [
    {
      label: 'Metrics',
      content: (
        <MetricsCards
          performance={clientPerformance}
          type={type ? type : 'seo_google'}
        />
      ),
    },
    {
      label: 'Charts',
      content: (
        <Charts
          performance={clientPerformance}
          type={type ? type : 'seo_google'}
        />
      ),
    },
    { label: 'Comments', content: <Comments /> },
  ];

  // use ref to restore the scroll bar at top
  const scrollRef = useRef<any>(null);
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  }, [activeBtn]);

  // Function to handle adding a new comment
  const handleAddComment = () => {
    const newComment: Comment = {
      id: Date.now(), // Using the current timestamp as a simple unique ID
      text: commentText.trim(),
    };

    // Add the new comment to the existing list of comments
    setComments([...comments, newComment]);
    // Clear the input field
    setCommentText('');
    setIsOpen(false);
  };

  return (
    <motion.div
      initial={{ x: '100%' }}
      exit={{ x: '100%' }}
      animate={{ x: 0 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      style={{ display: 'flex', justifyContent: 'flex-end', height: '100%' }}
    >
      <div
        className="flex flex-col relative w-[850px] h-full shadow-sm overflow-y-auto bg-white px-2 sm:px-6 dark:bg-gray-900 font-inter font-[500]"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          className="absolute top-3 sm:top-6 right-3 sm:right-6 sm:top-6 z-10 cursor-pointer"
          onClick={onClose}
        >
          <Icon name="close" size={20} />
        </div>
        <div className="sticky top-0 mt-4 sm:mt-0">
          <div className="flex flex-col gap-y-4 w-full bg-white py-4 dark:bg-gray-900">
            <div className="flex flex-wrap sm:flex-nowrap  items-center gap-x-3">
              <h1 className="w-[90px] truncate sm:w-fit sm:max-w-[30dvw] text-[24px] font-[700] dark:text-white">
                {clientPerformance?.client?.name}
              </h1>
              <div
                className={`uppercase text-xs text-[#FF692E] bg-[#FFF4F0] rounded-full font-inter font-[400] py-1 px-2`}
              >
                {clientPerformance?.service?.charAt(0)?.toUpperCase() +
                  clientPerformance?.service?.slice(1)?.replace(/_/g, ' ')}
              </div>
              <div
                className={`uppercase text-xs text-[#FF692E] bg-[#FFF4F0] rounded-full font-inter font-[400] py-1 px-2`}
              >
                {clientPerformance?.cycle}
              </div>
              <div
                className={`uppercase text-xs text-[#FF692E] bg-[#FFF4F0] rounded-full font-inter font-[400] py-1 px-2`}
              >
                {clientPerformance?.client?.category_type === null
                  ? 'N/A'
                  : clientPerformance?.client?.category_type}
              </div>
            </div>
            <div className="flex flex-wrap sm:flex-nowrap justify-between items-center">
              <div className="flex flex-wrap gap-2 sm:flex-nowrap items-center text-sm">
                <div className="text-gray-400 text-sm border-r pr-3">
                  <div className="text-black dark:text-white text-sm font-inter font-[600]">
                    {clientPerformance?.startDate
                      ? moment(clientPerformance?.startDate).format('MMM YY')
                      : 'N/A'}
                  </div>
                  <div className="text-[10px] uppercase text-[#636466] font-inter font-[400]">
                    Client Since
                  </div>
                </div>
                <div className="text-gray-400 text-sm px-3 border-r">
                  <span
                    className={`text-[#000] text-sm font-inter font-[600] dark:text-white`}
                  >
                    {clientPerformance?.revenue}
                  </span>
                  <div className="text-[10px] uppercase text-[#636466] font-inter font-[400]">
                    Retainer
                  </div>
                </div>
                <div className="text-gray-400 text-sm px-3 border-r">
                  <div
                    className={`text-[#000] text-sm font-inter font-[600] dark:text-white`}
                  >
                    {clientPerformance?.performance_date
                      ? moment(clientPerformance?.performance_date).format(
                          'MMM YY'
                        )
                      : 'N/A'}
                  </div>
                  <div className="text-[10px] uppercase text-[#636466] font-inter font-[400]">
                    Performance Date
                  </div>
                </div>

                <div className="text-gray-400 text-sm px-0 sm:px-3 border-r">
                  <div
                    className={`flex items-center gap-1 text-sm font-inter font-[600] cursor-pointer ${clientPerformance?.performance?.value >= 70 ? 'text-[#3CCB7F]' : clientPerformance?.performance?.value >= 50 ? 'text-[#636466]' : 'text-[#FF0000]'}`}
                  >
                    {formatNumber(clientPerformance?.performance?.value, true)}
                    <div>
                      <Icon
                        name={
                          clientPerformance?.performance?.value >= 70
                            ? 'Arrowup'
                            : clientPerformance?.performance?.value >= 50
                              ? 'ArrowRight'
                              : 'ArrowDown'
                        }
                        size={16}
                        color={
                          clientPerformance?.performance?.value >= 70
                            ? '#3CCB7F'
                            : clientPerformance?.performance?.value >= 50
                              ? '#636466'
                              : '#FF0000'
                        }
                      />
                    </div>
                  </div>
                  <div className="text-[10px] uppercase text-[#636466] font-inter font-[400]">
                    Performance
                  </div>
                </div>
                {isStaging() ? (
                  <div
                    className="cursor-pointer sm:hidden"
                    onClick={() => {
                      console.log('Regenerate');
                    }}
                  >
                    <Icon name="RefreshIcon" size={28} color="#7C7E7F" />
                  </div>
                ) : null}
              </div>
              {isStaging() ? (
                <div
                  className="cursor-pointer hidden sm:block"
                  onClick={() => {
                    console.log('Regenerate');
                  }}
                >
                  <Icon name="RefreshIcon" size={28} color="#7C7E7F" />
                </div>
              ) : null}
            </div>
            <Card className="flex flex-wrap sm:flex-nowrap p-4 gap-x-4 bg-[#F8F8F8] border-none shadow-none ring-0">
              <div className="w-full sm:w-1/2 mb-2 sm:mb-0  border-b sm:border-b-0 pb-3 sm:pb-0 sm:border-r border-[#ADB0B2] flex gap-3">
                <div className="flex items-start flex-col gap-2">
                  <div className="text-[#7C7E7F] font-inter font-[400] text-sm">
                    Account Manager:
                  </div>
                  <div className="text-[#7C7E7F] font-inter font-[400] text-sm">
                    HS Updated by:
                  </div>
                  <div className="text-[#7C7E7F] font-inter font-[400] text-sm">
                    Status
                  </div>
                </div>
                <div className="flex items-start flex-col gap-2">
                  <div className="text-black dark:text-white ml-1  text-sm">
                    {clientPerformance?.account_manager?.name}
                  </div>
                  <div className="text-black dark:text-white ml-1 text-sm">
                    {clientPerformance?.account_manager?.hsName}
                  </div>
                  <div className="flex items-end justify-start">
                    <Badge
                      className="h-[20px] rounded-full ring-0 uppercase"
                      color={clientPerformance?.account_manager?.color}
                    >
                      {clientPerformance?.account_manager?.color === 'gray'
                        ? 'N/A'
                        : clientPerformance?.account_manager?.color
                            .charAt(0)
                            .toUpperCase() +
                          clientPerformance?.account_manager?.color.slice(1)}
                    </Badge>
                  </div>
                </div>
              </div>
              <div className="w-full sm:w-1/2 flex gap-3">
                <div className="flex items-start flex-col gap-2">
                  <div className="text-[#7C7E7F] font-inter font-[400] text-sm">
                    Consultant:
                  </div>
                  <div className="text-[#7C7E7F] font-inter font-[400] text-sm">
                    HS Updated by:
                  </div>
                  <div className="text-[#7C7E7F] font-inter font-[400] text-sm">
                    Status
                  </div>
                </div>
                <div className="flex items-start flex-col gap-2">
                  <div className="text-black dark:text-white ml-1 text-sm">
                    {clientPerformance?.consultant?.name}
                  </div>
                  <div className="text-black dark:text-white ml-1 text-sm">
                    {clientPerformance?.consultant?.hsName}
                  </div>
                  <div className="">
                    <Badge
                      className="h-[20px] uppercase"
                      color={clientPerformance?.consultant?.color}
                    >
                      {clientPerformance?.consultant?.color === 'gray'
                        ? 'N/A'
                        : clientPerformance?.consultant?.color
                            .charAt(0)
                            .toUpperCase() +
                          clientPerformance?.consultant?.color.slice(1)}
                    </Badge>
                  </div>
                </div>
              </div>
            </Card>
            <div className="w-full flex items-center justify-between bg-[#F8F8F8] py-2 px-4 rounded-lg dark:border border-gray-600 dark:bg-gray-700">
              <div className="flex  gap-x-6">
                {tabs?.map(
                  (
                    value: { label: string; content: JSX.Element },
                    index: number
                  ) => {
                    return (
                      <div key={index}>
                        <button
                          className={` text-sm ${
                            activeBtn === value.label
                              ? 'text-[#001C44] font-[600] text-sm dark:text-white uppercase'
                              : ' text-[#7C7E7F] font-[600] text-sm uppercase'
                          } `}
                          onClick={() => setActiveBtn(value.label)}
                        >
                          {value.label}
                        </button>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
            <div className="w-full h-0.5 bg-[#E6EBF0]" />
          </div>
        </div>
        <div
          ref={scrollRef}
          className="w-full  overflow-y-auto dark:bg-gray-900  scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8 flex flex-col items-center mb-[50px]"
        >
          {tabs.map((tab, index) =>
            activeBtn === tab.label ? (
              <div key={index} className="w-full h-[100%]">
                {tab.content}
              </div>
            ) : null
          )}
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={30000}
        hideProgressBar
        newestOnTop={false}
        transition={Flip}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </motion.div>
  );
};

export default PerformanceModal;
