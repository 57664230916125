import React from 'react';
import SparkAreaChartCard from '../SparkAreaChartCard';
import AreaCardChart from '../AreaCardChart';
import useClientStore from '../../../store/useClientStore';
import use3rdPartyStore from '../../../store/use3rdPartyStore';
import {
  formatNumberWithCommas,
  formatToCurrency,
  formatNumber,
  processData,
} from '../../../utils/common';
import useGoogleStore from '../../../store/useGoogleStore';
import { isLedGenClient } from '../../../utils/client';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

interface IProps {
  retryGA?: () => void;
  retryRefDomain?: () => void;
  retryRefDomainHistory?: () => void;
}

const MobileView = ({
  retryGA,
  retryRefDomain,
  retryRefDomainHistory
}: IProps) => {
  const { ga4SeoData, googleSearchConsole } = useGoogleStore((state) => state);
  const { selectedClient } = useClientStore((state) => state);
  const isLeadGen = isLedGenClient(selectedClient);
  const { orgDomainOverview, refdomains, domainRatingHistory } =
    use3rdPartyStore((state) => state);

  const ahrefsData = {
    transactions: ga4SeoData?.transactions,
    refdomains: refdomains?.refdomains || {},
    domain_rating: domainRatingHistory?.data?.domain_rating || {},
  };

  const isGoogleConsoleActive =
    selectedClient.google_search_console?.status === 'activated';
  const isDomainOverviewLoading =
    orgDomainOverview?.loading || selectedClient?.loading;

  const keywordCount =
    orgDomainOverview?.data && orgDomainOverview?.data[0]?.['Organic Keywords']
      ? formatNumberWithCommas(orgDomainOverview?.data[0]?.['Organic Keywords'])
      : 0;
  const traffic =
    orgDomainOverview?.data && orgDomainOverview?.data[0]?.['Organic Traffic']
      ? formatNumberWithCommas(orgDomainOverview?.data[0]?.['Organic Traffic'])
      : 0;
  const trafficCost =
    orgDomainOverview?.data && orgDomainOverview?.data[0]?.['Organic Cost']
      ? formatToCurrency(orgDomainOverview?.data[0]?.['Organic Cost'])
      : 0;
  // const rank =
  //   orgDomainOverview?.data && orgDomainOverview?.data[0]?.['Rank']
  //     ? formatNumberWithCommas(orgDomainOverview?.data[0]?.['Rank'])
  //     : 0;

  const clicks = processData(googleSearchConsole, 'clicks');
  const impressions = processData(googleSearchConsole, 'impressions');
  const ctr = processData(googleSearchConsole, 'ctr');
  // const position = processData(googleSearchConsole, 'position');
  const transactions = processData(ahrefsData, 'transactions');
  const domainReferring = processData(
    ahrefsData,
    'refdomains',
    'Referring Domains'
  );
  const domainRating = processData(
    ahrefsData,
    'domain_rating'
    // 'Referring Domains'
  );

  const lastMonthClicks =
    Array.isArray(clicks) && clicks.length > 0
      ? clicks[clicks.length - 1]?.value
      : undefined;

  const lastMonthImpressions =
    Array.isArray(impressions) && impressions.length > 0
      ? impressions[impressions.length - 1]?.value
      : undefined;

  const lastMonthCtr =
    Array.isArray(ctr) && ctr.length > 0
      ? ctr[ctr.length - 1]?.value
      : undefined;

  // const lastMonthPositions =
  //   Array.isArray(position) && position.length > 0
  //     ? position[position.length - 1]?.value
  //     : undefined;

  const lastMonthTransactions =
    Array.isArray(transactions) && transactions.length > 0
      ? transactions[transactions.length - 1]?.value
      : undefined;

  const lastMonthDomainReferring =
    Array.isArray(domainReferring) && domainReferring.length > 0
      ? domainReferring[domainReferring.length - 1]?.value
      : undefined;

  const lastMonthDomainRating =
    Array.isArray(domainRating) && domainRating.length > 0
      ? domainRating[domainRating.length - 1]?.value
      : undefined;

  return (
    <div className="block sm:hidden mt-4">
      <div className="flex flex-col gap-4">
        <div className="p-0">
          <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
            {isGoogleConsoleActive && (
              <SwiperSlide>
                <AreaCardChart
                  title={'Clicks'}
                  chatData={clicks}
                  category={['Clicks']}
                  info={
                    googleSearchConsole?.clicks?.info
                      ? googleSearchConsole?.clicks?.info
                      : 'Clicks'
                  }
                  prevPeriod={googleSearchConsole?.clicks?.previous_period}
                  previousYear={googleSearchConsole?.clicks?.previous_year}
                  isLoading={googleSearchConsole.loading}
                  totalValue={formatNumberWithCommas(lastMonthClicks)}
                  error={googleSearchConsole.error}
                  icon="googleSearch"
                  retryMethod={retryGA}
                />
              </SwiperSlide>
            )}
            {isGoogleConsoleActive && (
              <SwiperSlide>
                <AreaCardChart
                  title={'Impressions'}
                  chatData={impressions}
                  category={['Impressions']}
                  info={
                    googleSearchConsole?.impressions?.info
                      ? googleSearchConsole?.impressions?.info
                      : 'Impressions'
                  }
                  prevPeriod={googleSearchConsole?.impressions?.previous_period}
                  previousYear={googleSearchConsole?.impressions?.previous_year}
                  isLoading={googleSearchConsole.loading}
                  totalValue={formatNumberWithCommas(lastMonthImpressions)}
                  error={googleSearchConsole.error}
                  icon="googleSearch"
                  retryMethod={retryGA}
                />
              </SwiperSlide>
            )}
            {isGoogleConsoleActive && (
              <SwiperSlide>
                <AreaCardChart
                  title={'CTR'}
                  chatData={ctr}
                  category={['Ctr']}
                  info={
                    googleSearchConsole?.ctr?.info
                      ? googleSearchConsole?.ctr?.info
                      : 'CTR'
                  }
                  prevPeriod={googleSearchConsole?.ctr?.previous_period}
                  previousYear={googleSearchConsole?.ctr?.previous_year}
                  isLoading={googleSearchConsole.loading}
                  totalValue={formatNumber(lastMonthCtr, true)}
                  hasPercent={true}
                  error={googleSearchConsole.error}
                  icon="googleSearch"
                  retryMethod={retryGA}
                />
              </SwiperSlide>
            )}
            {!isLeadGen && (
              <SwiperSlide>
                <AreaCardChart
                  title={'Transactions'}
                  chatData={transactions}
                  category={['Transactions']}
                  info={
                    ahrefsData?.transactions?.info
                      ? ahrefsData?.transactions?.info
                      : 'Transactions'
                  }
                  prevPeriod={ahrefsData?.transactions?.previous_period}
                  previousYear={ahrefsData?.transactions?.previous_year}
                  isLoading={googleSearchConsole.loading}
                  totalValue={formatNumberWithCommas(lastMonthTransactions)}
                  error={googleSearchConsole.error}
                  icon="googleAnalytics"
                  retryMethod={retryGA}
                />
              </SwiperSlide>
            )}
            <SwiperSlide>
              <AreaCardChart
                title={'Referring Domains'}
                chatData={domainReferring}
                category={['Referring Domains']}
                info={
                  ahrefsData?.refdomains?.info
                    ? ahrefsData?.refdomains?.info
                    : 'Referring Domains'
                }
                prevPeriod={ahrefsData?.refdomains?.previous_period}
                previousYear={ahrefsData?.refdomains?.previous_year}
                isLoading={refdomains.loading}
                totalValue={formatNumberWithCommas(lastMonthDomainReferring)}
                error={refdomains.error}
                icon="ahref"
                retryMethod={retryRefDomain}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title={'Domain Rating'}
                chatData={domainRating}
                category={['Domain Rating']}
                info={
                  ahrefsData?.domain_rating?.info
                    ? ahrefsData?.domain_rating?.info
                    : 'Domain Rating'
                }
                prevPeriod={ahrefsData?.domain_rating?.previous_period}
                previousYear={ahrefsData?.domain_rating?.previous_year}
                isLoading={domainRatingHistory.loading}
                totalValue={formatNumberWithCommas(lastMonthDomainRating)}
                error={domainRatingHistory.error}
                icon="ahref"
                retryMethod={retryRefDomainHistory}
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default MobileView;
