import React, { useEffect, useState } from 'react';
import ReactSelect from '../../ReactSelect';
import moment from 'moment';
import { Card, DatePicker } from '@tremor/react';
import { useThemeStore } from '../../../store/useThemeStore';
import Api from '../../../api/';
import useClientStore from '../../../store/useClientStore';
import useActivitiesStore from '../../../store/useActivitiesStore';
import { Flip, ToastContainer, toast } from 'react-toastify';
import Alert from '../../Alert';
import BookMeetingCalendar from '../../BookMeetingCalendar';
import { startOfToday } from 'date-fns';
import Icon from '../../../assets/icons/SvgComponent';
import HeaderTitle from '../../HeaderTitle';
import BookMeetingIcon from '../../../assets/images/bookMeeting.png';

interface BookMeetingModalProps {
  onClose?: () => void;
}
const meetingOptions = [
  { label: 'Discovery Call', value: 'discovery_call' },
  { label: 'Post Discovery', value: 'post_discovery' },
  { label: 'Unable to connect', value: 'unable_to_connect' },
  { label: 'Strategy Session', value: 'strategy_session' },
  { label: 'Post Strategy Call', value: 'post_strategy_call' },
  { label: 'Post Strategy Meeting', value: 'post_strategy_meeting' },
];

const BookMeetingModal: React.FC<BookMeetingModalProps> = ({ onClose }) => {
  const { mode } = useThemeStore((state: any) => state);
  const iconColor = mode === 'dark' ? 'white' : 'black';
  const { selectedClient } = useClientStore((state) => state);
  const {
    createMeeting,
    fetchUpcomingMeeting,
    fetchAvailableDates,
    fetchAvailableTime,
    availableDates,
    availableTime,
    refreshAvailableTime,
  } = useActivitiesStore((state) => state);
  const [meetingType, setMeetingType] = useState<{
    label: string;
    value: string;
  }>(meetingOptions[0]);
  const [currentModal, setCurrentModal] = useState(false);
  const [selectedStartTime, setSelectedStartTime] = useState(null);
  const today = startOfToday();
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [createMeetingLoading, setCreateMeetingLoading] = useState(false);
  const [refreshMeeting, setRefreshMeeting] = useState(false);

  // State to hold form values
  const [formData, setFormData] = useState<any>({});
  const [errorMessage, setErrorMessage] = useState('');
  const [meetingLength, setMeetingLength] = useState(15);

  const handleFetchAvailableDates = async () => {
    const parsedMonth = await moment(selectedMonth, 'MMM-YYYY');
    try {
      if (selectedClient?.id && parsedMonth) {
        const filters = {
          clientId: selectedClient?.id,
          date: parsedMonth.format('YYYY-MM'),
          country: 'AU',
        };
        fetchAvailableDates(filters);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFetchAvailableTime = () => {
    try {
      if (selectedClient?.id) {
        const filters = {
          clientId: selectedClient?.id,
          date: `${moment(selectedDay).format('YYYY-MM-DD')}`,
          country: 'AU',
        };
        fetchAvailableTime(filters);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFetchMeeting = async () => {
    const filters = {
      clientId: selectedClient?.id,
      country: 'AU',
    };
    try {
      if (selectedClient?.id) {
        await fetchUpcomingMeeting(filters);
      }
    } catch (error) {
      console.error('Error fetching upcoming meeting:', error);
    }
  };

  useEffect(() => {
    if (selectedMonth !== null) {
      handleFetchAvailableDates();
    }
  }, [selectedMonth]);

  useEffect(() => {
    setSelectedDay(selectedDay);
    if (selectedDay !== null) {
      handleFetchAvailableTime();
    } else {
      refreshAvailableTime();
    }
  }, [selectedDay]);

  const formattedDate =
    selectedDay === null
      ? 'mm-dd-yyy'
      : moment(selectedDay).format('MMMM DD, YYYY');

  // Function to handle input changes
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // Format date
  // Format date
  const meetingDate = selectedDay
    ? selectedDay.toLocaleString('en-US', {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
      })
    : '';

  // Format time
  const hours = selectedStartTime
    ? parseInt(selectedStartTime.split(':')[0], 10)
    : 0;
  const minutes = selectedStartTime
    ? parseInt(selectedStartTime.split(':')[1], 10)
    : 0;
  const ampm = hours >= 12 ? 'pm' : 'am';
  const formattedTime = selectedStartTime
    ? `${hours % 12 || 12}:${minutes.toString().padStart(2, '0')} ${ampm}`
    : '';

  // const handleSave = async () => {
  //   setCreateMeetingLoading(true);
  //   // Check if title is empty
  //   // if (!formData?.title?.trim()) {
  //   //   setErrorMessage('Please enter a title');
  //   //   toast.error(
  //   //     <Alert
  //   //       icon=""
  //   //       title={`ERROR MESSAGE!`}
  //   //       message={`Please enter a title`}
  //   //     />,
  //   //     {
  //   //       position: 'top-center',
  //   //       autoClose: 3000,
  //   //       hideProgressBar: true,
  //   //       closeOnClick: true,
  //   //       pauseOnHover: true,
  //   //       draggable: true,
  //   //       progress: undefined,
  //   //     }
  //   //   );
  //   //   setCreateMeetingLoading(false);
  //   //   return;
  //   // }

  //   // Check if client is selected
  //   if (!selectedClient?.id) {
  //     setErrorMessage('Please select a client');
  //     toast.error(
  //       <Alert
  //         icon=""
  //         title={`ERROR MESSAGE!`}
  //         message={`Please select a client`}
  //       />,
  //       {
  //         position: 'top-center',
  //         autoClose: 3000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       }
  //     );
  //     setCreateMeetingLoading(false);
  //     return;
  //   }

  //   // If all validations pass, proceed to create the meeting
  //   const startDateTime = moment(selectedDay)
  //     .set('hour', parseInt(selectedStartTime?.split(':')[0]))
  //     .set('minute', parseInt(selectedStartTime?.split(':')[1]));

  //   const endDateTime = startDateTime.clone().add(meetingLength, 'minutes');

  //   // Add more validation for other fields if necessary

  //   const data = {
  //     id: selectedClient?.id,
  //     start_time: startDateTime.toISOString(),
  //     end_time: endDateTime.toISOString(),
  //     country: 'AU',
  //   };

  //   createMeeting(data)
  //     .then(() => {
  //       toast.success(
  //         <Alert
  //           icon=""
  //           title={`Book a Meeting!`}
  //           message={`Meeting has been created successfully`}
  //         />,
  //         {
  //           position: 'top-center',
  //           autoClose: 2000,
  //           hideProgressBar: true,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //         }
  //       );

  //       setTimeout(() => {
  //         setCreateMeetingLoading(false);
  //         setRefreshMeeting(true);
  //         onClose();
  //       }, 2500);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       toast.error(
  //         <Alert icon="" title={`ERROR MESSAGE!`} message={`${error}`} />,
  //         {
  //           position: 'top-center',
  //           autoClose: 2000,
  //           hideProgressBar: true,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //         }
  //       );
  //       setCreateMeetingLoading(false);
  //     });
  // };

  const handleSave = async () => {
    setCreateMeetingLoading(true);

    if (!selectedClient?.id) {
      setErrorMessage('Please select a client');
      toast.error(
        <Alert
          icon=""
          title={`ERROR MESSAGE!`}
          message={`Please select a client`}
        />,
        {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setCreateMeetingLoading(false);
      return;
    }

    const startDateTime = moment(selectedDay)
      .set('hour', parseInt(selectedStartTime?.split(':')[0]))
      .set('minute', parseInt(selectedStartTime?.split(':')[1]));

    const endDateTime = startDateTime.clone().add(meetingLength, 'minutes');

    const data = {
      id: selectedClient?.id,
      start_time: startDateTime.toISOString(),
      end_time: endDateTime.toISOString(),
      country: 'AU',
    };

    try {
      await createMeeting(data);
      toast.success(
        <Alert
          icon=""
          title={`Book a Meeting!`}
          message={`Meeting has been created successfully`}
        />,
        {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );

      setCreateMeetingLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(
        <Alert icon="" title={`ERROR MESSAGE!`} message={`${error}`} />,
        {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setCreateMeetingLoading(false);
    }
  };

  const handleTextAreaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const am = selectedClient?.users?.filter(
    (user: any) => user?.role === 'am'
  )[0];

  const name = am?.first_name + ' ' + am?.last_name;
  const pic = am?.profile_pic;

  const meetingLengthTabs = [
    {
      length: '15 mins',
      value: 15,
    },
    {
      length: '30 mins',
      value: 30,
    },
    {
      length: '1 hour',
      value: 60,
    },
  ];

  const handleMeetingLengthChange = (length) => {
    setMeetingLength(length);
  };

  // const handleBook = async (e) => {
  //   e.preventDefault(); // Prevents the default form submission behavior
  //   await handleSave(); // Perform the booking action
  //   await handleFetchMeeting();
  //   if (refreshMeeting) {
  //     await setTimeout(() => {
  //       setRefreshMeeting(false);
  //     }, 1000);
  //   }
  // };

  const handleBook = async (e: React.FormEvent) => {
    e.preventDefault(); // Prevents the default form submission behavior
    await handleSave()
      .then(() => {
        setCreateMeetingLoading(false);
        onClose();
      })
      .then(() => handleFetchMeeting())
      .catch((error) => console.error(error));
  };

  const simonImg =
    'https://gurulytics-assets.s3.ap-southeast-2.amazonaws.com/users/simon@onlinemarketinggurus.com.au.png';

  return (
    <div className="w-full h-screen flex justify-center items-center ">
      <div
        onClick={(e) => e.stopPropagation()}
        className="flex flex-col justify-center items-center w-fit lg:p-6 lg:space-y-4 z-100  rounded-lg"
      >
        {!currentModal ? (
          <div className="flex flex-col max-h-screen max-w-screen  overflow-auto lg:overflow-hidden lg:flex-row lg:justify-center lg:items-center lg:w-fit shadow dark:bg-gray-700">
            <div className="h-screen w-screen lg:h-[580px] lg:w-[24dvw] bg-[#009EFF] dark:bg-[#222732]">
              <div className="relative">
                <div
                  className="absolute top-4 right-4 sm:hidden cursor-pointer"
                  onClick={onClose}
                >
                  <Icon name="Xclose" color="#FFF" />
                </div>
                <div className="w-full h-screen lg:h-fit flex flex-col justify-center items-center">
                  {am && pic ? (
                    <div className="border rounded-full p-2 px-2 bg-white mt-8">
                      <img
                        src={pic}
                        alt=""
                        width={70}
                        height={70}
                        className="border rounded-full p-2 px-3 bg-transparent"
                      />
                    </div>
                  ) : (
                    <div className="border rounded-full p-2 px-2 bg-white mt-8">
                      {am ? (
                        <Icon
                          name="NoAccountManagerImage"
                          color={iconColor}
                          size={60}
                        />
                      ) : (
                        <img
                          src={simonImg} // Show SimonImg if there's no pic but there is am
                          alt=""
                          width={70}
                          height={70}
                          className="border rounded-full p-2 px-3 bg-transparent"
                        />
                      )}
                    </div>
                  )}

                  <div className="text-white mt-2 text-2xl font-inter font-400">
                    Meet with {am ? name : 'Simon Hancock'}
                  </div>
                  <div>
                    <BookMeetingCalendar
                      today={today}
                      selectedDay={selectedDay}
                      setSelectedDay={setSelectedDay}
                      setSelectedMonth={setSelectedMonth}
                      availableDates={availableDates?.dates}
                    />
                  </div>
                  {selectedDay !== null && (
                    <div className="text-white text-xl mt-8 lg:hidden pulse">
                      Scroll Down to Continue
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="relative h-screen w-screen lg:h-[580px] lg:w-[24dvw] bg-white font-inter">
              <div
                className="absolute top-4 right-4 cursor-pointer"
                onClick={onClose}
              >
                <Icon name="Xclose" color={iconColor} />
              </div>
              <div className="p-8">
                <div className="text-md font-semibold">
                  How long do you need?
                </div>

                <div className="mt-4 flex justify-between gap-1 items-center  rounded-lg">
                  {meetingLengthTabs.map((meeting, index) => (
                    <div
                      key={index}
                      className={`w-full cursor-pointer border text-sm ${meetingLength === meeting.value ? 'bg-[#009EFF] text-white rounded-lg' : 'rounded-lg'}`}
                      onClick={() => handleMeetingLengthChange(meeting.value)}
                    >
                      <div className="text-center p-2">{meeting.length}</div>
                    </div>
                  ))}
                </div>

                <div className="mt-4 h-[388px] ">
                  {availableTime.loading ? (
                    <div className="flex justify-center items-center h-[36dvh]">
                      <Icon name="Loading" />
                    </div>
                  ) : (
                    <>
                      {availableTime?.times?.length === 0 ||
                      availableTime?.times === undefined ? (
                        <div className="flex justify-center items-center h-[300px] text-md text-[#0029FF] text-center">
                          Select available date to view available time
                        </div>
                      ) : (
                        <div className="flex flex-col gap-4 ">
                          <div className="text-md mt-2">
                            <p className="font-semibold">
                              What time work best?
                            </p>
                            <p className="mt-2">
                              Showing time for{' '}
                              <span className="font-semibold">
                                {formattedDate}
                              </span>
                            </p>
                          </div>
                          <div className="overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8 h-[292px]">
                            {availableTime?.times?.length === 0 &&
                            !availableTime?.loading ? (
                              <div className="flex items-center justify-center text-sm text-[#0029FF] font-inter py-2 cursor-pointer hover:bg-[#009EFF] hover:text-white">
                                <div>
                                  There is no available time. Please select
                                  other date
                                </div>
                              </div>
                            ) : (
                              <>
                                {availableTime?.times?.map((time, index) => (
                                  <div
                                    key={index}
                                    onClick={() => {
                                      setSelectedStartTime(time);
                                    }}
                                    className={
                                      selectedStartTime === time
                                        ? 'border text-center text-sm font-inter py-2 cursor-pointer bg-[#009EFF] text-white my-3'
                                        : 'border text-center text-sm text-[#0029FF] font-inter py-2 cursor-pointer hover:bg-[#009EFF] hover:text-white my-3'
                                    }
                                  >
                                    <div>
                                      {time} {time > '11:59' ? 'pm' : 'am'}
                                    </div>
                                  </div>
                                ))}
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className="w-full flex justify-end">
                  <button
                    disabled={selectedStartTime === null}
                    className={`text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 ${selectedStartTime === null && 'opacity-50'}`}
                    onClick={() => setCurrentModal(true)}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Card className="flex flex-col justify-between items-center  h-screen lg:h-[500px] w-full lg:w-[800px]">
            <div className="flex flex-col justify-between items-center h-full w-full">
              <div className=" flex justify-between items-center w-full">
                <HeaderTitle
                  text="Confirm Your Meeting"
                  textColor="dark:text-white text-xl"
                />
                <div className="cursor-pointer" onClick={onClose}>
                  <Icon name="Xclose" color={iconColor} size={25} />
                </div>
              </div>
              <div className="flex flex-col justify-center items-center gap-8 lg:px-10 relative">
                <div className="absolute top-[-140px] lg:top-[-110px]">
                  <img src={BookMeetingIcon} alt="Meeting Icon" />
                </div>
                <div className="text-base text-center dark:text-white font-inter font-[600] lg:mt-8">
                  Thank you for scheduling your upcoming meeting on
                  <br />
                  {meetingDate} - {formattedTime}
                  <br />
                </div>
                <div className="text-base dark:text-white text-center">
                  Shall we proceed to formalize this meeting with a confirmed
                  booking?
                </div>
              </div>
              <div className="flex items-center justify-between w-full pb-4 gap-4">
                <button
                  type="submit"
                  className="w-full dark:text-white border border-[#000] hover:border-[#fff]  hover:text-white  hover:bg-red-900  focus:outline-none focus:ring-blue-300 font-semibold rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  onClick={() => setCurrentModal(false)}
                >
                  Previous
                </button>
                <button
                  type="submit"
                  className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-semibold rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  onClick={handleBook}
                >
                  Confirm
                </button>
              </div>
            </div>
          </Card>
          // <div className="relative flex justify-center items-center w-fit shadow dark:bg-gray-700">
          //   <div className="absolute top-4 left-[12dvw]">
          //     <Icon name="OmgWhiteLogo" />
          //   </div>
          //   <div className="h-[580px] w-[28dvw] bg-[#009EFF] p-6 flex flex-col justify-between rounded">
          //     <div className="text-white mt-16 font-inter whitespace-normal text-center">
          //       OMG is committed to protecting and respecting your privacy, and
          //       we’ll only use your personal information to administer your
          //       account and to provide the products and services you requested
          //       from us. From time to time, we would like to contact you about
          //       our products and services, as well as other content that may be
          //       of interest to you. If you consent to us contacting you for this
          //       purpose, please tick below to say how you would like us to
          //       contact you.
          //     </div>
          //     <button
          //       className=" bg-white text-[#009EFF] w-fit font-medium rounded-lg text-sm px-5 py-2.5 text-center "
          //       onClick={() => setCurrentModal(false)}
          //     >
          //       Previous
          //     </button>
          //   </div>
          //   <div className="h-[580px] w-[28dvw] bg-white font-inter rounded">
          //     <div className="relative bg-white rounded-lg  shadow dark:bg-gray-700">
          //       <div className="flex items-center justify-between p-2 md:py-2 md:px-8 border-b rounded-t dark:border-gray-600 w-[28dvw]">
          //         <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
          //           Schedule
          //         </h3>
          //         <button
          //           type="button"
          //           className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
          //           onClick={onClose}
          //         >
          //           <svg
          //             className="w-3 h-3"
          //             aria-hidden="true"
          //             xmlns="http://www.w3.org/2000/svg"
          //             fill="none"
          //             viewBox="0 0 14 14"
          //           >
          //             <path
          //               stroke="currentColor"
          //               strokeLinecap="round"
          //               strokeLinejoin="round"
          //               strokeWidth={2}
          //               d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
          //             />
          //           </svg>
          //           <span className="sr-only">Close modal</span>
          //         </button>
          //       </div>
          //       <div className="p-4 md:py-6 md:px-8 ">
          //         {createMeetingLoading ? (
          //           <div className="flex flex-col justify-center items-center h-[482px]">
          //             <Icon name="Loading" size={80} />
          //             <div className="mt-4">Booking your meeting</div>
          //             <div>Please wait ...</div>
          //           </div>
          //         ) : (
          //           <form className="space-y-4">
          //             {/* Title */}
          //             <div>
          //               <label
          //                 htmlFor="title"
          //                 className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          //               >
          //                 Title
          //               </label>
          //               <input
          //                 type="text"
          //                 name="title"
          //                 onChange={handleInputChange}
          //                 className="bg-gray-50 border-b border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
          //                 required
          //               />
          //             </div>
          //             {/* Body */}
          //             <div>
          //               <label
          //                 htmlFor="title"
          //                 className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          //               >
          //                 Body
          //               </label>
          //               <input
          //                 type="text"
          //                 name="body"
          //                 onChange={handleInputChange}
          //                 className="bg-gray-50 border-b border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
          //               />
          //             </div>

          //             {/* Meeting Type */}
          //             <div>
          //               <label
          //                 htmlFor="meetingType"
          //                 className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          //               >
          //                 Select Meeting Type
          //               </label>
          //               <ReactSelect
          //                 options={meetingOptions}
          //                 value={meetingType}
          //                 handleOnChange={(option: any) =>
          //                   setMeetingType(option)
          //                 }
          //                 placeholder="Select..."
          //               />
          //             </div>
          //             {/* external URL */}
          //             <div>
          //               <label
          //                 htmlFor="url"
          //                 className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          //               >
          //                 URL
          //               </label>
          //               <input
          //                 type="text"
          //                 name="url"
          //                 onChange={handleInputChange}
          //                 className="bg-gray-50 border-b border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
          //               />
          //             </div>
          //             {/* meeting notes */}
          //             <div>
          //               <label
          //                 htmlFor="notes"
          //                 className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          //               >
          //                 Notes
          //               </label>
          //               <textarea
          //                 name="notes"
          //                 rows={4}
          //                 onChange={handleTextAreaChange}
          //                 className="bg-gray-50 border-b border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full h-[64px] p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
          //               />
          //             </div>
          //             <div className="flex justify-end">
          //               <button
          //                 type="submit"
          //                 className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          //                 onClick={handleBook}
          //               >
          //                 Book
          //               </button>
          //             </div>
          //           </form>
          //         )}
          //       </div>
          //       {/* Modal footer */}
          //       <div className="">
          //         {/* <div className="flex items-center">
          //         <input
          //           id="default-checkbox"
          //           type="checkbox"
          //           defaultValue=""
          //           className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          //         />
          //         <label
          //           htmlFor="default-checkbox"
          //           className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          //         >
          //           Add to office 365 Calendar
          //         </label>
          //       </div> */}
          //       </div>
          //     </div>
          //   </div>
          // </div>
        )}
      </div>
      <ToastContainer
        position="top-center"
        autoClose={30000}
        hideProgressBar
        newestOnTop={false}
        transition={Flip}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default BookMeetingModal;
