import { useState, useRef, useEffect, ReactNode } from 'react';
import { Card } from '@tremor/react';
import Icon from '../../assets/icons/SvgComponent';
import { useLocation } from 'react-router-dom';
import { useThemeStore } from '../../store/useThemeStore';
import { getPageTitle } from '../../utils/layout';

interface IProps {
  title?: string;
  titleIcon?: string;
  titleIconColor?: string;
  titlePadding?: string;
  cardClass?: string;
  className?: string;
  icon?: string;
  textClass?: string;
  children: React.ReactNode;
}

const Accordion = ({
  title,
  children,
  titleIcon,
  titleIconColor,
  titlePadding,
  icon,
  className = '',
  textClass,
  cardClass,
}: IProps) => {
  const location = useLocation();
  let activePage = getPageTitle(location.pathname);
  const isAnalytics = activePage === 'analytics';
  const { mode } = useThemeStore((state) => state);
  const [isOpen, setIsOpen] = useState(isAnalytics ? true : false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [isOpen, children]);

  useEffect(() => {
    const handleResize = () => {
      if (contentRef.current) {
        setContentHeight(contentRef.current.scrollHeight);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className={`accordion bg-white dark:bg-[#222732] py-2 ${cardClass ? cardClass : `rounded-[8px] ${className ? className : ` ${!isOpen ? '' : ''}`}`}`}
    >
      <div
        className={`accordion-header flex justify-between items-center cursor-pointer ${titlePadding ? titlePadding : `px-[12px] py-[8px]`} `}
        onClick={toggleAccordion}
      >
        <div className="flex items-center gap-2">
          <Icon name={titleIcon} color={titleIconColor} size={24} />
          <h2
            className={`font-inter ${textClass ? textClass : 'text-[12px] font-[600] text-[#fff] dark:bg-[#161B26] uppercase'}`}
          >
            {title}
          </h2>
        </div>
        <div className="accordion-icon flex gap-2 items-center">
          {icon && <Icon name={icon} size={20} />}
          {isOpen ? (
            <Icon
              name="ChevronUp"
              color={mode === 'dark' ? '#FFF' : '#c0c0c0'}
            />

          ) : (
            <Icon name="ChevronDown" color="#c0c0c0" />
          )}
        </div>
      </div>
      <div
        className="accordion-content overflow-hidden transition-all duration-300 ease-in-out"
        style={{
          maxHeight: isOpen ? `${contentHeight}px` : '0',
          opacity: isOpen ? '1' : '0',
        }}
        ref={contentRef}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
