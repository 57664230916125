import { Select, SelectItem } from '@tremor/react';
import Icon from '../../../assets/icons/SvgComponent';
import PeterLogo from '../../../assets/images/peters-logo.png';
import { useLocation } from 'react-router-dom';
import HeaderTitle from '../../HeaderTitle';
import DomainDropdown from '../../DomainDropdown';
import useAuthStore from '../../../store/useAuthStore';
import useClientStore from '../../../store/useClientStore';
import { useThemeStore } from '../../../store/useThemeStore';
import { useState, useEffect } from 'react';
import OutsideAlerter from '../../Common/OutsideAlerter';
import { DropDownMenu } from '../../Layout/Header';
import useActivitiesStore from '../../../store/useActivitiesStore';
interface Domain {
  id: string;
  name: string;
}

interface Props {
  name?: string;
}
const HomeHeader: React.FC<Props> = ({ name }) => {
  const [openDropDown, setOpenDropDown] = useState<boolean>(false);
  const { mode } = useThemeStore();
  const iconColor = mode === 'dark' ? 'white' : '';
  const location = useLocation();
  const { selectedClient } = useClientStore((state) => state);
  const { selectedDomain, setDomain, user, loginAsUser } = useAuthStore(
    (state) => state
  );

  const closeDropdown = () => {
    setOpenDropDown(false);
  };
  const isAdminLogin = user.role.level === 'omg';
  const currentUser = isAdminLogin ? loginAsUser : user;
  const transformedData = currentUser?.clients?.map((item: Domain) => ({
    label: item.name,
    id: item.id,
  }));

  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour >= 5 && hour < 12) {
      return 'Good morning';
    } else if (hour >= 12 && hour < 18) {
      return 'Good afternoon';
    } else {
      return 'Good evening';
    }
  };

  return (
    <div
      className={
        !location.pathname.includes('dashboard')
          ? 'w-full block md:flex justify-between pt-20 sm:pt-[36px] px-2 sm:px-0 sm:pb-2 sm:sticky top-16 z-30 bg-[#D0EDFF] dark:bg-[#0C111D]'
          : 'w-full block md:flex justify-between pt-20 sm:pt-[36px] px-2 sm:px-0 sm:pb-2 sm:sticky top-0 z-30 bg-[#D0EDFF] dark:bg-[#0C111D]'
      }
    >
      <div className="flex flex-col gap-[6px] sm:gap-[16px]  sm:gap-[32px]  dark:bg-[#0C111D]">
        <div className="flex justify-between items-center sm:block">
          <HeaderTitle
            text={`${getGreeting()} ${name ? name + '!' : 'N/A!'}`}
            lineColorLight="bg-[#ADF8FF]"
            textColor="text-[#001C44]"
            archivo={true}
            fontSize=" text-[20px] md:text-xl lg:text-[48px]"
          />
        </div>
        <div
          className={`md:block ${transformedData && transformedData.length > 1 ? `pb-6` : `pb-6`}  sm:py-0`}
        >
          <div className="font-inter  h-[20px] font-[600] text-gray-500  md:mt-0 dark:text-gray-200 text-sm sm:text-[16px] max-w-[980px] md:truncate">
            <span className="text-[#001C44] font-inter font-[700] dark:text-white text-xs sm:text-[16px]">
              Welcome! Dive into Real-Time Insights and Performance Metrics.
            </span>
          </div>
        </div>
      </div>
      <div className="sm:mt-4 md:mt-0 flex flex-row-reverse justify-center sm:justify-between py-2 sm:py-0 lg:flex-col gap-y-2">
        <div className="hidden sm:flex justify-end w-full">
          <div className="relative flex w-20 justify-between gap-[3px] md:gap-2 lg:pr-2">
            <div
              className="cursor-pointer bg-[#E3F4FF] rounded-lg"
              onClick={() => setOpenDropDown(!openDropDown)}
            >
              <Icon color={iconColor} name="info" size={40} />
            </div>
            <div className="cursor-pointer bg-[#E3F4FF] rounded-lg">
              <Icon color={iconColor} name="bell" size={40} />
            </div>
            {openDropDown && (
              <OutsideAlerter toggle={setOpenDropDown}>
                <DropDownMenu closeDropdown={closeDropdown} />
              </OutsideAlerter>
            )}
          </div>
        </div>

        <div className="pt-3 sm:pt-0">
          <DomainDropdown
            customStyle={{
              width: '100%',
              minWidth: '240px',
              padding: 0,
            }}
            labelKey="label"
            valueKey="id"
            onSelect={(domain) => {
              setDomain(domain);
            }}
            defaultValue={selectedDomain.label || selectedClient?.name}
            placeholder="Select Domain"
            options={transformedData || []}
            disableSearch={transformedData?.length === 1}
          />
        </div>
        <div className="flex justify-between items-center hidden sm:w-44">
          {/* <Icon name="logo" color='black' size={40} />
        <Icon name="heart" size={30} />
        <img src={PeterLogo} alt="logo" className='w-20' /> */}
        </div>
      </div>
    </div>
  );
};

export default HomeHeader;
