import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const ImagePlus: React.FC<IconProps> = ({ color = '#475467', size = 16 }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6667 4.00033H10.4C8.15979 4.00033 7.03969 4.00033 6.18404 4.4363C5.43139 4.81979 4.81947 5.43172 4.43597 6.18436C4 7.04001 4 8.16012 4 10.4003V21.6003C4 23.8405 4 24.9606 4.43597 25.8163C4.81947 26.5689 5.43139 27.1809 6.18404 27.5644C7.03969 28.0003 8.15979 28.0003 10.4 28.0003H22.6667C23.9066 28.0003 24.5266 28.0003 25.0353 27.864C26.4156 27.4942 27.4938 26.416 27.8637 25.0356C28 24.5269 28 23.907 28 22.667M25.3333 10.667V2.66699M21.3333 6.66699H29.3333M14 11.3337C14 12.8064 12.8061 14.0003 11.3333 14.0003C9.86057 14.0003 8.66667 12.8064 8.66667 11.3337C8.66667 9.8609 9.86057 8.66699 11.3333 8.66699C12.8061 8.66699 14 9.8609 14 11.3337ZM19.9867 15.8912L8.7082 26.1444C8.07382 26.7211 7.75663 27.0095 7.72857 27.2593C7.70425 27.4758 7.78727 27.6905 7.95091 27.8344C8.13971 28.0003 8.56837 28.0003 9.42571 28.0003H21.9413C23.8602 28.0003 24.8196 28.0003 25.5732 27.678C26.5193 27.2733 27.2729 26.5196 27.6776 25.5736C28 24.82 28 23.8605 28 21.9416C28 21.296 28 20.9732 27.9294 20.6725C27.8407 20.2947 27.6706 19.9408 27.431 19.6355C27.2403 19.3926 26.9883 19.1909 26.4841 18.7876L22.7544 15.8039C22.2499 15.4002 21.9976 15.1984 21.7197 15.1271C21.4748 15.0644 21.2172 15.0725 20.9767 15.1506C20.7039 15.2392 20.4648 15.4565 19.9867 15.8912Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ImagePlus;
