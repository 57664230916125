import { requests } from "./ApiService";
import { toQueryString } from "../utils/common";

export const Bing = {
  getCampaignReports: (data) => requests.get(`/api/v1/bingads/campaign/${data.account}${toQueryString({
    clientId: data.clientId,
    startDate: data.startDate,
    endDate: data.endDate,
    refreshCache: data.resetCache
  })}`),
  getBingAdsUserAccount: () =>
    requests.get(`/api/v1/bingads/users`),
  getBingAdsClientUser: (email: string) =>
    requests.get(`/api/v1/bingads/accounts/${email}`),
  getBingTopPerformance: (data) =>
    requests.get(
      `/api/v1/bingads/campaign/${data.account}/top-performance${toQueryString(
        {
          clientId: data.clientId,
          startDate: data.startDate,
          endDate: data.endDate
        }
      )}`
    ),
};