import React, { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import Icon from '../../../assets/icons/SvgComponent';
import { useThemeStore } from '../../../store/useThemeStore';
import useClientStore from '../../../store/useClientStore';
import useXeroStore from '../../../store/useXeroStore';

interface XeroModalProps {
  data?: any;
  onClose?: () => void;
}

interface Contact {
  contactID?: string;
  name?: string;
}

const Xero: React.FC<XeroModalProps> = ({ data, onClose }) => {
  const { mode } = useThemeStore((state: any) => state);
  const { contacts, fetchContacts, loading } = useXeroStore(
    (state: any) => state
  );
  const { selectedClient, fetchClient, updateClient } = useClientStore(
    (state) => state
  );
  const iconColor = mode === 'dark' ? 'white' : 'black';
  const [contactId, setContactId] = useState('');
  const [contact, setContact] = useState<Contact>({});
  const [filteredContacts, setFilteredContacts] = useState<Contact[]>([]);
  const defaultStatus = selectedClient?.xero?.contact_id ? true : false;
  const [status, setStatus] = useState<boolean>(defaultStatus);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  // Debounced function to fetch contacts
  const debouncedFetchContacts = useCallback(
    debounce((searchTerm: string) => {
      fetchContacts({ searchTerm });
    }, 500),
    []
  );

  useEffect(() => {
    if (contactId.length > 2) {
      debouncedFetchContacts(contactId);
    } else {
      setFilteredContacts([]);
      setContact({});
    }

    return () => {
      debouncedFetchContacts.cancel();
    };
  }, [contactId, debouncedFetchContacts]);

  useEffect(() => {
    if (contactId.length > 2) {
      const filtered = contacts.filter((contact: any) =>
        contact?.name?.toLowerCase()?.includes(contactId.toLowerCase())
      );
      setFilteredContacts(filtered);
    }
  }, [contacts, contactId]);

  const handleContactSelect = (contact: any) => {
    setContactId(contact.name);
    setContact(contact);
    setFilteredContacts([]); // Clear filtered contacts after selection
  };

  const handleSaveChanges = (e: React.FormEvent) => {
    e.preventDefault();
    if (contactId) {
      const data = {
        xero: { contact_id: contact?.contactID },
      };
      setIsLoading(true);
      updateClient(selectedClient?.id, data)
        .then(() => {
          setIsLoading(false);
          fetchClient(selectedClient?.id);
          onClose && onClose();
        })
        .catch(() => setIsLoading(false));
    } else {
      setError(true);
    }
  };

  return (
    <div
      className="fixed top-0 right-0 bg-white h-screen w-screen sm:w-[32vw] p-[24px] flex flex-col z-100 dark:bg-[#262627] dark:border dark:border-[#313233] dark:shadow-bs-card"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex justify-between items-center">
        <div className="flex gap-[8px] items-center">
          <div className="w-[34px] h-[34px]">
            <Icon name="Xero" color={iconColor} />
          </div>
          <div className="text-[24px] dark:text-white">Xero</div>
        </div>
        {isLoading && (
          <div className="absolute right-16 top-5">
            <Icon name="loading" />
          </div>
        )}
        <div className="cursor-pointer" onClick={onClose}>
          <Icon name="Xclose" size={20} color={iconColor} />
        </div>
      </div>
      <div className="flex flex-col justify-between h-[98%]">
        <div>
          <div className="mt-[24px] pt-[24px] border-t mb-[16px] relative">
            <p className="mb-[6px] text-sm text-[#001C44] dark:text-white">
              Account
            </p>
            <input
              type="text"
              value={contactId}
              onChange={(e) => setContactId(e.target.value)}
              onClick={() => setContact({})}
              placeholder="Select Account"
              className="border rounded w-full p-2"
            />
            {(filteredContacts.length > 0 || contactId.length > 0) &&
              !contact.contactID && (
                <div className="absolute top-[90px] w-full z-50 border rounded mt-2 max-h-40 overflow-y-auto bg-white dark:bg-[#262627]">
                  {loading ? (
                    <div className="flex justify-center items-center w-full h-40">
                      <Icon name="loading" />
                    </div>
                  ) : (
                    filteredContacts.map((contact: any) => (
                      <div
                        key={contact.contactID}
                        onClick={() => handleContactSelect(contact)}
                        className="p-2 hover:bg-gray-200 dark:hover:bg-gray-700 cursor-pointer"
                      >
                        {contact.name}
                      </div>
                    ))
                  )}
                </div>
              )}
          </div>
          <div className="flex justify-between mt-[200px] border-t pt-12">
            <div className="dark:text-white">Integration Status</div>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={status}
                onChange={(e) => setStatus(e.target.checked)}
              />
              <div className="w-28 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 dark:bg-gray-700 peer-checked:bg-[#005C9F]" />
              <span
                className={`w-28 h-6 absolute top-1 left-[30%] text-xs ${
                  status ? 'text-white' : ''
                }`}
              >
                {status ? 'Enabled' : 'Disabled'}
              </span>
            </label>
          </div>
        </div>
        <button
          onClick={handleSaveChanges}
          className="text-white bg-[#005C9F] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm w-full py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default Xero;
