import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const addCircle: React.FC<IconProps> = ({ color, size = 80 }) => (
  <svg
    width="60"
    height="60"
    fill="none"
    stroke={color || 'currentColor'}
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="0.5"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M21 12c0-4.969-4.031-9-9-9s-9 4.031-9 9 4.031 9 9 9 9-4.031 9-9Z"></path>
    <path d="M12 8.25v7.5"></path>
    <path d="M15.75 12h-7.5"></path>
  </svg>
);

export default addCircle;
