import { Card } from '@tremor/react';
import Icon from '../../../../assets/icons/SvgComponent';
import AnalyticsTable from '../../AnalyticsTable';

interface IProps {
  campaignsReport: any;
  qualityScoreReportData: any;
}

const MobileView = ({ qualityScoreReportData, campaignsReport }: IProps) => {
  return (
    <>
      {qualityScoreReportData?.data?.length === 0 &&
      !campaignsReport.loading ? (
        <Card className="card-item px-3 sm:px-6 shadow-none lg:shadow ring-0 lg:ring-1">
          <div className="flex flex-col justify-center w-full items-center my-8 h-[148px]">
            <div className="mb-8">
              <Icon name="NoDataAdGroup" />
            </div>
            <p className="font-inter font-[600] text-[20px] text-[#001C44] dark:text-white">
              No quality scores available at this moment.
            </p>
            <p className="text-center w-[70vw] text-[20px] text-[#001C44] dark:text-white">
              Stay informed with ongoing trends and insights in ad quality. Our
              dedicated section ensures you're updated with crucial developments
              and strategies for maintaining high-quality scores.
            </p>
          </div>
        </Card>
      ) : (
        <AnalyticsTable
          data={qualityScoreReportData?.data}
          columns={qualityScoreReportData?.tableHeader}
          loading={campaignsReport.loading}
          totalDocs={
            qualityScoreReportData?.data
              ? qualityScoreReportData?.data.length
              : 'N/A'
          }
          sortByColumnId="Creative"
          pagination={false}
        />
      )}
    </>
  );
};

export default MobileView;
