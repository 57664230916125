import { FC, ReactNode, useRef } from 'react';

interface Props {
  children: ReactNode;
  tooltip?: string;
}

const ToolTipV2: FC<Props> = ({ children, tooltip }): JSX.Element => {
  const tooltipRef = useRef<HTMLSpanElement>(null);
  const container = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={container}
      onMouseEnter={({ clientX, clientY }) => {
        if (!tooltipRef.current || !container.current) return;
        const { left, top, height } = container.current.getBoundingClientRect();
        const tooltipHeight = tooltipRef.current.clientHeight;
        const windowHeight = window.innerHeight;

        const leftPosition = clientX - left + 'px';
        let topPosition = clientY - top + 'px';

        // Check if there's not enough space below the tooltip, then display it above
        if (windowHeight - (clientY + height) < tooltipHeight) {
          topPosition = clientY - tooltipHeight - top + 'px';
        }

        tooltipRef.current.style.left = leftPosition;
        tooltipRef.current.style.top = topPosition;
      }}
      className="group relative inline-block w-inherit"
    >
      {children}
      {tooltip ? (
        <span
          ref={tooltipRef}
          className="tooltip-animation hidden group-hover:block transition bg-black/80 font-medium text-xs text-white text-left p-1 rounded absolute top-full mt-2 whitespace-pre-line z-[9999] overflow-y-auto max-h-[300px] min-w-[140px]  scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100"
        >
          {tooltip}
        </span>
      ) : null}
    </div>
  );
};

export default ToolTipV2;
