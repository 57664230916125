import React from 'react';

interface DotProps {
  color?: string;
  size?: number;
}

const Globe06: React.FC<DotProps> = ({ color = '#313233', size = 20 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.90554 13.0377L4.49571 12.1197C4.58184 12.07 4.68292 12.0527 4.78067 12.071L7.90942 12.6566C8.16655 12.7047 8.4039 12.5067 8.40274 12.2452L8.39055 9.50379C8.39022 9.42931 8.40986 9.3561 8.44743 9.29179L10.0264 6.58859C10.1085 6.44791 10.1012 6.27223 10.0075 6.13892L7.34902 2.35482M16.5001 4.04929C11.9168 6.25012 14.4166 9.16678 15.2501 9.58345C16.8143 10.3654 18.9896 10.4167 18.9896 10.4167C18.9964 10.2787 18.9999 10.1398 18.9999 10.0001C18.9999 5.39771 15.269 1.66675 10.6666 1.66675C6.06421 1.66675 2.33325 5.39771 2.33325 10.0001C2.33325 14.6025 6.06421 18.3334 10.6666 18.3334C10.8063 18.3334 10.9452 18.33 11.0833 18.3232M14.6314 18.2832L11.9924 11.3259L18.9497 13.9649L15.8646 15.1981L14.6314 18.2832Z"
      stroke={color}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Globe06;
