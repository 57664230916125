import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const AM: React.FC<IconProps> = ({ color = 'black', size = 16 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="user-check-01">
      <path
        id="Icon"
        d="M8 10.3333H5C4.06962 10.3333 3.60444 10.3333 3.2259 10.4482C2.37364 10.7067 1.70669 11.3736 1.44816 12.2259C1.33333 12.6044 1.33333 13.0696 1.33333 14M10.6667 12L12 13.3333L14.6667 10.6667M9.66667 5C9.66667 6.65685 8.32352 8 6.66667 8C5.00981 8 3.66667 6.65685 3.66667 5C3.66667 3.34315 5.00981 2 6.66667 2C8.32352 2 9.66667 3.34315 9.66667 5Z"
        stroke={color || '#001C44'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default AM;
