import AreaCardChart from '../../AreaCardChart';
import {
  formatNumberWithCommas,
  formatToCurrency,
  formatNumber,
} from '../../../../utils/common';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

interface IProps {
  purchase?: any;
  aov?: any;
  cps?: any;
  isLeadGen?: boolean;
  website_leads?: any;
  cpl?: any;
  cvr?: any;
  dataInfo?: any;
  retryMethod?: () => void
}

const MobileView = ({
  purchase,
  aov,
  cps,
  dataInfo,
  isLeadGen,
  website_leads,
  cpl,
  cvr,
  retryMethod
}: IProps) => {
  const lastMonthPurchase =
    Array.isArray(purchase) && purchase.length > 0
      ? purchase[purchase.length - 1]?.value
      : undefined;
  const lastMonthAOV =
    Array.isArray(aov) && aov.length > 0
      ? aov[aov.length - 1]?.value
      : undefined;

  const lastMonthCps =
    Array.isArray(cps) && cps.length > 0
      ? cps[cps.length - 1]?.value
      : undefined;

  const lastWebsiteLeads =
    Array.isArray(website_leads) && website_leads.length > 0
      ? website_leads[website_leads.length - 1]?.value
      : undefined;

  const lastMonthCpl =
    Array.isArray(cpl) && cpl.length > 0
      ? cpl[cpl.length - 1]?.value
      : undefined;

  const lastMonthCvr =
    Array.isArray(cvr) && cvr.length > 0
      ? cvr[cvr.length - 1]?.value
      : undefined;
  
  return (
    <div className="block sm:hidden mb-4">
      <div className="flex flex-col gap-4">
        <div className="p-0">
          {isLeadGen ? (
            <Swiper
              pagination={true}
              modules={[Pagination]}
              className="mySwiper"
            >
              <SwiperSlide>
                <AreaCardChart
                  title="Website Leads"
                  chatData={website_leads}
                  category={['Website Leads']}
                  info={dataInfo?.website_leads?.info}
                  prevPeriod={dataInfo?.website_leads?.previous_period}
                  previousYear={dataInfo?.website_leads?.previous_year}
                  totalValue={formatNumberWithCommas(lastWebsiteLeads)}
                  isLoading={dataInfo.loading}
                  error={dataInfo.error}
                  icon="meta"
                  accountInsights={true}
                  retryMethod={retryMethod}
                />
              </SwiperSlide>
              <SwiperSlide>
                <AreaCardChart
                  title="CPL"
                  chatData={cpl}
                  category={['Cpl']}
                  info={dataInfo?.cpl?.info}
                  prevPeriod={dataInfo?.cpl?.previous_period}
                  previousYear={dataInfo?.cpl?.previous_year}
                  totalValue={formatToCurrency(lastMonthCpl)}
                  isLoading={dataInfo.loading}
                  hasDollar
                  error={dataInfo.error}
                  icon="meta"
                  accountInsights={true}
                  retryMethod={retryMethod}
                />
              </SwiperSlide>
              <SwiperSlide>
                <AreaCardChart
                  title="CVR"
                  chatData={cvr}
                  category={['Cvr']}
                  info={dataInfo?.cvr?.info}
                  prevPeriod={dataInfo?.cvr?.previous_period}
                  previousYear={dataInfo?.cvr?.previous_year}
                  totalValue={formatNumber(lastMonthCvr, true)}
                  isLoading={dataInfo.loading}
                  hasPercent
                  error={dataInfo.error}
                  icon="meta"
                  accountInsights={true}
                  retryMethod={retryMethod}
                />
              </SwiperSlide>
            </Swiper>
          ) : (
            <Swiper
              pagination={true}
              modules={[Pagination]}
              className="mySwiper"
            >
              <SwiperSlide>
                <AreaCardChart
                  title="Purchases"
                  chatData={purchase}
                  category={['Purchases']}
                  info={dataInfo?.website_purchases?.info}
                  prevPeriod={dataInfo?.website_purchases?.previous_period}
                  previousYear={dataInfo?.website_purchases?.previous_year}
                  totalValue={formatNumberWithCommas(lastMonthPurchase)}
                  isLoading={dataInfo.loading}
                  error={dataInfo.error}
                  icon="meta"
                  accountInsights={true}
                  retryMethod={retryMethod}
                />
              </SwiperSlide>
              <SwiperSlide>
                <AreaCardChart
                  title="Average Order Value (AOV)"
                  chatData={aov}
                  category={['Average Order Value (AOV)']}
                  info={dataInfo?.aov?.info}
                  prevPeriod={dataInfo?.aov?.previous_period}
                  previousYear={dataInfo?.aov?.previous_year}
                  totalValue={formatToCurrency(lastMonthAOV)}
                  isLoading={dataInfo.loading}
                  hasDollar
                  error={dataInfo.error}
                  icon="meta"
                  accountInsights={true}
                  retryMethod={retryMethod}
                />
              </SwiperSlide>
              <SwiperSlide>
                <AreaCardChart
                  title="Cost Per Sale (CPS)"
                  chatData={cps}
                  category={['Cost Per Sale (CPS)']}
                  info={dataInfo?.cps?.info}
                  prevPeriod={-dataInfo?.cps?.previous_period}
                  previousYear={-dataInfo?.cps?.previous_year}
                  totalValue={formatToCurrency(lastMonthCps)}
                  isLoading={dataInfo.loading}
                  hasDollar
                  error={dataInfo.error}
                  icon="meta"
                  accountInsights={true}
                  retryMethod={retryMethod}
                />
              </SwiperSlide>
            </Swiper>
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileView;
