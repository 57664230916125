import React from 'react';

interface Props {
  color?: string;
}

const PencilLine: React.FC<Props> = ({ color = '#428B6E' }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4997 17.5001H10.833M2.08301 17.9167L6.7074 16.1381C7.00318 16.0243 7.15107 15.9675 7.28944 15.8932C7.41234 15.8272 7.52951 15.7511 7.63971 15.6656C7.76378 15.5693 7.87582 15.4572 8.09991 15.2332L17.4997 5.83338C18.4202 4.91291 18.4202 3.42052 17.4997 2.50005C16.5792 1.57957 15.0868 1.57957 14.1664 2.50005L4.76658 11.8998C4.54249 12.1239 4.43044 12.2359 4.33417 12.36C4.24865 12.4702 4.17251 12.5874 4.10654 12.7103C4.03226 12.8487 3.97538 12.9965 3.86162 13.2923L2.08301 17.9167ZM2.08301 17.9167L3.79811 13.4575C3.92084 13.1384 3.9822 12.9789 4.08746 12.9058C4.17944 12.8419 4.29326 12.8178 4.40325 12.8388C4.52912 12.8628 4.64999 12.9837 4.89174 13.2254L6.77432 15.108C7.01607 15.3498 7.13694 15.4706 7.16098 15.5965C7.18198 15.7065 7.15783 15.8203 7.09396 15.9123C7.02088 16.0175 6.86133 16.0789 6.54223 16.2016L2.08301 17.9167Z"
      stroke="#7C7E7F"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PencilLine;
