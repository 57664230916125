import React from 'react';

interface Props {
  size?: string;
  color?: string;
}

const ArrowUpRight: React.FC<Props> = ({ size = '16', color = '#7C7E7F' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-arrow-up-right"
  >
    <line x1={7} y1={17} x2={17} y2={7} />
    <polyline points="7 7 17 7 17 17" />
  </svg>
);

export default ArrowUpRight;
