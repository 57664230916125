import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const AnalyticsIcon: React.FC<IconProps> = ({ color = 'black', size = 80 }) => (
  <svg
    width={size}
    height={size + 1}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 15.3478V17.3478M12 11.3478V17.3478M16 7.34778V17.3478M7.8 21.3478H16.2C17.8802 21.3478 18.7202 21.3478 19.362 21.0208C19.9265 20.7332 20.3854 20.2742 20.673 19.7097C21 19.068 21 18.2279 21 16.5478V8.14778C21 6.46762 21 5.62754 20.673 4.98581C20.3854 4.42132 19.9265 3.96238 19.362 3.67476C18.7202 3.34778 17.8802 3.34778 16.2 3.34778H7.8C6.11984 3.34778 5.27976 3.34778 4.63803 3.67476C4.07354 3.96238 3.6146 4.42132 3.32698 4.98581C3 5.62754 3 6.46762 3 8.14778V16.5478C3 18.2279 3 19.068 3.32698 19.7097C3.6146 20.2742 4.07354 20.7332 4.63803 21.0208C5.27976 21.3478 6.11984 21.3478 7.8 21.3478Z"
      stroke={color || '#334969'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default AnalyticsIcon;
