import { useState, useEffect } from 'react';
import Table from '../../components/Table';
import useFeedbackStore from '../../store/useFeedbackStore';
import useAuthStore from '../../store/useAuthStore';
import { ActionWrapper, Container, FilterWrapper } from './styles';
import { MdFeed } from 'react-icons/md';
import { colors } from '../../utils/settings';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import DropDown from '../../components/Dropdown';
import UserNotFound from '../../components/UserNotFound';
export default function ClientsFeedback() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [selectedFeedback, setSelectedFeedback] = useState({ name: '', id: 0 });
  const { user } = useAuthStore((state) => state);
  const [openModal, setOpenModal] = useState(false);
  const isAM = ['am'].includes(user?.role?.id);
  const { fetchFeedbacks, feedbacks, loading } = useFeedbackStore(
    (state) => state
  );
  const headers = ['Name', 'Email', 'Date', 'Messages', 'Actions'];
  const [feedbackOptions, setFeedbackOptions] = useState([
    { label: 'Account', id: 'account' },
    { label: 'App', id: 'app' },
  ]);
  const [selectedOption, setSelectedOption] = useState({
    label: 'Account',
    id: 'account',
  });
  useEffect(() => {
    if (
      !['admin', 'dev', 'exec', 'fo', 'aexec', 'supadmin', 'am'].includes(
        user?.role?.id
      )
    )
      navigate('/');
    if (isAM) setFeedbackOptions({ label: 'Account', id: 'account' });
  }, []);
  useEffect(() => {
    fetchFeedbacks(selectedOption.id);
  }, [selectedOption]);

  // reset the selected feedback when the modal is closed
  useEffect(() => {
    if (!openModal) {
      setSelectedFeedback({ name: '' });
    }
  }, [openModal]);

  // show feedback modal
  const showFeedback = (feedback) => {
    setSelectedFeedback(feedback);
    setOpenModal(true);
  };
  const renderContent = () => {
    return feedbacks?.data?.map((feedback, i) => (
      <tr key={i}>
        <td>{`${feedback?.created_by?.first_name} ${feedback?.created_by?.last_name}`}</td>
        <td>{feedback?.created_by?.email}</td>
        <td>{moment(feedback?.created_at).format('MMM D, YYYY')}</td>
        <td dangerouslySetInnerHTML={{ __html: feedback?.message }}></td>
        <td>
          <ActionWrapper>
            <MdFeed
              onClick={() => showFeedback(feedback)}
              color={colors.secondary}
              size={30}
            />
          </ActionWrapper>
        </td>
      </tr>
    ));
  };

  return (
    <Container>
      {!isAM && (
        <FilterWrapper>
          <DropDown
            selected={selectedOption.label}
            onSelect={(value) => setSelectedOption(value)}
            items={feedbackOptions}
            styles={{ width: 150, height: 120 }}
          />
        </FilterWrapper>
      )}

      <Table
        headers={headers}
        content={renderContent()}
        totalPages={feedbacks.total_pages}
        total_docs={feedbacks.total_docs}
        page={page}
        setPage={setPage}
        loading={loading}
      />
      {feedbacks?.data?.length === 0 && <UserNotFound />}
    </Container>
  );
}
