interface ModalProps {
  user?: any;
  onClose?: any;
  action?: any;
}
const ConfirmStatus: React.FC<ModalProps> = ({ user, onClose, action }) => {
  const getUserInitials = (name: string) => {
    const nameParts = name?.split(' ');
    let initials = '';

    if (nameParts.length > 0) {
      initials += nameParts[0][0];

      if (nameParts.length > 1) {
        initials += nameParts[1][0];
      }
    }

    return initials;
  };

  const fullname = `${user?.first_name} ${user?.last_name}`;

  return (
    <div className="relative  rounded-lg shadow flex justify-center  items-center h-screen">
      <div className="w-[450px]" onClick={(e) => e.stopPropagation()}>
        {/* Modal header */}
        <div className="flex items-center justify-between bg-white dark:bg-gray-700 p-4 md:p-5 border-b rounded-t dark:border-gray-600">
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
            {user?.status === 'active' ? 'Disable' : 'Enable'} User
          </h3>
          <button
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            onClick={onClose}
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        {/* Modal body */}
        <div className="p-4 md:p-5 bg-white dark:bg-gray-700">
          <p className="text-sm font-normal text-gray-500 dark:text-gray-400 min-w-[450px]">
            Are you sure you want to{' '}
            {user?.status === 'active' ? 'disable' : 'enable'} this user?
          </p>
          <ul className="my-4 space-y-3 overflow-auto max-h-[400px] overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8">
            <li>
              <div className="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                <div className="w-8 h-8 rounded-full overflow-hidden">
                  {user.image ? (
                    <img
                      src={user.image}
                      alt={user.fullName}
                      className="object-cover"
                    />
                  ) : (
                    <div className="bg-[#345578] text-white flex items-center justify-center w-full h-full text-xs uppercase">
                      {fullname === 'undefined undefined'
                        ? getUserInitials(`${user.name}`)
                        : getUserInitials(`${fullname}`)}
                    </div>
                  )}
                </div>
                <span className="flex-1 ms-3 whitespace-nowrap capitalize">
                  {user?.first_name === undefined
                    ? user.name
                    : `${user?.first_name} ${user?.last_name}`}
                </span>
              </div>
            </li>
          </ul>
          <div className="w-full px-2 flex  justify-center items-center gap-2">
            <button
              onClick={onClose}
              className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
            >
              Cancel
            </button>
            <button
              onClick={() => action(user?.id, user?.status)}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            >
              {user?.status === 'active' ? 'Disable' : 'Enable'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmStatus;
