import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const Twitter: React.FC<IconProps> = ({ color = 'black', size = 20 }) => (
  <svg width={size} height={size} viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M6.2918 18.444C13.8371 18.444 17.9652 12.1912 17.9652 6.77052C17.9652 6.59474 17.9613 6.41505 17.9535 6.23927C18.7566 5.65852 19.4496 4.93918 20 4.11505C19.2521 4.4478 18.458 4.66512 17.6449 4.75958C18.5011 4.24639 19.1421 3.4402 19.4492 2.49044C18.6438 2.96777 17.763 3.30448 16.8445 3.48615C16.2257 2.8286 15.4075 2.39323 14.5164 2.24735C13.6253 2.10146 12.711 2.25318 11.9148 2.67905C11.1186 3.10492 10.4848 3.78122 10.1115 4.6034C9.73825 5.42557 9.64619 6.34782 9.84961 7.22755C8.21874 7.14571 6.62328 6.72206 5.16665 5.98405C3.71002 5.24605 2.42474 4.21017 1.39414 2.94357C0.870333 3.84667 0.710047 4.91534 0.945859 5.93238C1.18167 6.94942 1.79589 7.83851 2.66367 8.41896C2.01219 8.39827 1.37498 8.22287 0.804688 7.90724V7.95802C0.804104 8.90576 1.13175 9.82446 1.73192 10.5579C2.3321 11.2914 3.16777 11.7944 4.09687 11.9815C3.49338 12.1466 2.85999 12.1706 2.2457 12.0518C2.50788 12.8668 3.01798 13.5797 3.70481 14.0909C4.39164 14.6021 5.22093 14.8861 6.07695 14.9033C4.62369 16.0449 2.82848 16.6641 0.980469 16.6611C0.652739 16.6606 0.325333 16.6406 0 16.601C1.87738 17.8054 4.06128 18.4451 6.2918 18.444Z'
      fill='#ADB0B2'
    />
  </svg>
);

export default Twitter;