import React from 'react';

interface DotProps {
  color?: string;
  size?: number;
}

const Globe05: React.FC<DotProps> = ({ color = '#313233', size = 20 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8334 2.04801C12.0442 1.80013 11.2044 1.6665 10.3334 1.6665C5.73102 1.6665 2.00006 5.39746 2.00006 9.99984C2.00006 14.6022 5.73102 18.3332 10.3334 18.3332C14.9358 18.3332 18.6667 14.6022 18.6667 9.99984C18.6667 8.5704 18.3068 7.22502 17.6727 6.04938M14.5001 4.7915H14.5042M9.08344 18.2401L9.08353 16.4039C9.08353 16.3045 9.11911 16.2083 9.18383 16.1328L11.2553 13.716C11.4256 13.5174 11.3728 13.2129 11.1457 13.0831L8.76551 11.723C8.70084 11.686 8.64726 11.6324 8.61034 11.5677L7.05878 8.84869C6.97803 8.70719 6.82221 8.62573 6.65992 8.64018L2.05355 9.05046M17.8334 4.99984C17.8334 6.84079 16.1667 8.33317 14.5001 9.99984C12.8334 8.33317 11.1667 6.84079 11.1667 4.99984C11.1667 3.15889 12.6591 1.6665 14.5001 1.6665C16.341 1.6665 17.8334 3.15889 17.8334 4.99984ZM14.7084 4.7915C14.7084 4.90656 14.6151 4.99984 14.5001 4.99984C14.385 4.99984 14.2917 4.90656 14.2917 4.7915C14.2917 4.67644 14.385 4.58317 14.5001 4.58317C14.6151 4.58317 14.7084 4.67644 14.7084 4.7915Z"
      stroke={color}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Globe05;
