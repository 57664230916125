import React from 'react';

interface GlobeProps {
  color?: string;
}

const Globe: React.FC<GlobeProps> = ({ color = '#428B6E' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clipPath="url(#clip0_207_3961)">
      <path
        d="M2.23904 13.0376L3.8292 12.1196C3.91533 12.0699 4.01641 12.0526 4.11417 12.0709L7.24292 12.6565C7.50004 12.7046 7.7374 12.5067 7.73623 12.2451L7.72405 9.50371C7.72372 9.42923 7.74336 9.35602 7.78092 9.29171L9.35986 6.58851C9.44204 6.44783 9.43467 6.27215 9.34101 6.13884L6.68252 2.35474M15.8336 4.04921C11.2503 6.25004 13.7501 9.1667 14.5836 9.58337C16.1478 10.3653 18.3231 10.4167 18.3231 10.4167C18.3299 10.2786 18.3334 10.1397 18.3334 10C18.3334 5.39763 14.6025 1.66667 10.0001 1.66667C5.39771 1.66667 1.66675 5.39763 1.66675 10C1.66675 14.6024 5.39771 18.3333 10.0001 18.3333C10.1398 18.3333 10.2787 18.3299 10.4167 18.3231M13.9649 18.2832L11.3259 11.3258L18.2832 13.9648L15.1981 15.198L13.9649 18.2832Z"
        stroke="#001C44"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_207_3961">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Globe;
