import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const Mail04: React.FC<IconProps> = ({ color = 'black', size = 80 }) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1937_36979)">
      <path
        d="M9.16329 2.07497L14.182 5.33712C14.3593 5.45238 14.448 5.51002 14.5122 5.5869C14.5691 5.65495 14.6118 5.73367 14.6379 5.81843C14.6673 5.91419 14.6673 6.01995 14.6673 6.23146V11.1193C14.6673 12.2394 14.6673 12.7994 14.4493 13.2272C14.2576 13.6036 13.9516 13.9095 13.5753 14.1013C13.1475 14.3193 12.5874 14.3193 11.4673 14.3193H4.53398C3.41388 14.3193 2.85383 14.3193 2.426 14.1013C2.04968 13.9095 1.74372 13.6036 1.55197 13.2272C1.33398 12.7994 1.33398 12.2394 1.33398 11.1193V6.23146C1.33398 6.01995 1.33398 5.91419 1.36343 5.81843C1.3895 5.73367 1.43222 5.65495 1.48908 5.5869C1.55332 5.51002 1.64199 5.45238 1.81933 5.33712L6.83801 2.07498M9.16329 2.07497C8.74246 1.80144 8.53205 1.66467 8.30534 1.61145C8.10494 1.56441 7.89637 1.56441 7.69597 1.61145C7.46925 1.66467 7.25884 1.80144 6.83801 2.07498M9.16329 2.07497L13.9794 5.20542C14.2086 5.35445 14.3233 5.42897 14.363 5.52347C14.3977 5.60605 14.3977 5.69913 14.363 5.78171C14.3233 5.87621 14.2086 5.95073 13.9794 6.09976L9.16329 9.23021C8.74247 9.50374 8.53205 9.64051 8.30534 9.69373C8.10494 9.74077 7.89637 9.74077 7.69597 9.69373C7.46925 9.64051 7.25884 9.50374 6.83801 9.23021L2.02194 6.09976C1.79266 5.95073 1.67801 5.87621 1.63831 5.78171C1.60361 5.69913 1.60361 5.60605 1.63831 5.52347C1.67801 5.42897 1.79266 5.35445 2.02194 5.20542L6.83801 2.07498"
        stroke="#344054"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1937_36979">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.319336)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Mail04;
