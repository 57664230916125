import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Icon from '../../../assets/icons/SvgComponent';
import { useThemeStore } from '../../../store/useThemeStore';
import Toggle from '../../ToggleV1';
import { useNavigate } from 'react-router';
import useAuthStore from '../../../store/useAuthStore';
import useClientStore from '../../../store/useClientStore';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { msalConfig } from '../../../config';
import ImageIcon from '../../ImageIcon';
import { motion } from 'framer-motion';
import useUtilityStore from '../../../store/useUtilityStore';
import Accordion from '../../Accordion/nav';

import OutsideAlerter from '../../Common/OutsideAlerter';
import { DropDownMenu } from '../Header';
interface SubItem {
  label: string;
  path: string;
  icon: string;
}

interface Item {
  icon: string;
  label: string;
  path: string;
  rightIcon?: string;
  subItems?: SubItem[];
}

interface SidebarProps {
  items?: any;
}

interface UtilityStoreState {
  isHeaderHidden: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ items }) => {
  const { isHeaderHidden } = useUtilityStore() as UtilityStoreState;
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const { logout, loginAsUser, logoutAsClient, currentUser } = useAuthStore(
    (state) => state
  );
  const [openDropDown, setOpenDropDown] = useState<boolean>(false);
  const { setClient } = useClientStore((state) => state);
  const { toggleTheme, mode } = useThemeStore((state) => state);

  const [activeItems, setActiveItems] = useState<string[]>([]);
  const iconColor = mode === 'dark' ? '#FFF' : '';
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  const sidebarRef = useRef(null);

  const closeDropdown = () => {
    setOpenDropDown(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node)
      ) {
        // Clicked outside the sidebar, close the subItems
        setActiveSubitem(null);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  // const active =
  //   'bg-[#F3F7FA] rounded-lg  hover:bg-[#F3F7FA] dark:bg-[#F3F7FA] dark:text-black dark:hover:bg-white  dark:border dark:border-[#313233]';
  const active =
    'text-[#0029FF]  hover:bg-[#F3F7FA]  bg-[#ADF8FF] shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px] dark:bg-[#1F242F] dark:text-white';

  const mobileActiveTab =
    'text-[#000]  hover:bg-[#F3F7FA]  bg-[#FFF] dark:bg-[#1F242F] dark:text-white';

  const logUserOut = () => {
    if (loginAsUser) {
      logoutAsClient();
      setClient({});
      localStorage.removeItem('seoStartDate');
      localStorage.removeItem('seoEndDate');
      navigate('/clients-users');
    } else {
      // logout microsoft from sdk
      if (isAuthenticated) {
        instance.logoutRedirect({
          postLogoutRedirectUri: msalConfig.auth.redirectUri,
        });
      }
      logout();
    }
  };
  const handleItemClick = (itemLabel: string) => {
    setActiveItems((prevItems) => {
      if (prevItems.includes(itemLabel)) {
        // If the item is already active, deactivate it
        return prevItems.filter((item) => item !== itemLabel);
      } else {
        // If the item is not active, activate it
        return [...prevItems, itemLabel];
      }
    });
  };

  const [activeSubitem, setActiveSubitem] = useState<string | null>(null);

  const toggleItemAnimation = (itemLabel: string) => {
    if (!activeItems.includes(itemLabel)) {
      setActiveSubitem(itemLabel);
    } else {
      setActiveSubitem(null);
    }
  };

  const hoverTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleItemEnter = (itemLabel: string) => {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
      hoverTimeout.current = null;
    }
    setActiveSubitem(itemLabel);
  };

  const handleItemLeave = () => {
    hoverTimeout.current = setTimeout(() => {
      setActiveSubitem(null);
    }, 200);
  };

  useEffect(() => {
    return () => {
      if (hoverTimeout.current) {
        clearTimeout(hoverTimeout.current);
      }
    };
  }, []);

  const location = useLocation();
  const actualPath = location.pathname;

  const toggleMobileMenu = () => {
    setOpenMobileMenu(!openMobileMenu);
  };

  const variants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: '-100%' },
  };

  return (
    <>
      <div
        className={`absolute sm:hidden top-0 w-[100vw] z-50 transition-opacity duration-500 ease-in-out ${isHeaderHidden ? 'opacity-0 sm:opacity-100' : 'opacity-100'}`}
      >
        <nav className="relative px-4 h-[64px] flex justify-between items-center bg-white dark:bg-[#1F242F]">
          <Link to={`/dashboard`}>
            <div className="relative flex items-center justify-center h-8 overflow-x-visible">
              <Icon
                name="logo"
                color={mode === 'dark' ? '#FFF' : '#0029FF'}
                size={30}
              />
            </div>
          </Link>
          <div className="flex gap-1 items-center">
            <div className="relative flex w-20  justify-between gap-1 mt-[10px]">
              <div
                onClick={(e) => setOpenDropDown(!openDropDown)}
                className="cursor-pointer bg-[#E3F4FF] rounded-lg"
              >
                <Icon color={iconColor} name="info" size={40} />
              </div>
              <div className="cursor-pointer bg-[#E3F4FF] rounded-lg">
                <Icon color={iconColor} name="bell" size={40} />
              </div>
              {openDropDown && (
                <OutsideAlerter toggle={setOpenDropDown}>
                  <DropDownMenu closeDropdown={closeDropdown} />
                </OutsideAlerter>
              )}
            </div>
            <button
              className="navbar-burger flex items-center text-blue-600"
              onClick={toggleMobileMenu}
            >
              <Icon color={mode === 'dark' ? '#FFF' : '#000'} name="Menu" />
            </button>
          </div>
        </nav>
      </div>
      {openMobileMenu && (
        <motion.div
          className="absolute sm:hidden top-0 w-[100vw] h-[100vh] z-50 bg-white dark:bg-[#222732]"
          initial="closed"
          animate="open"
          variants={variants}
        >
          <div className="h-[100dvh]">
            <nav className="relative px-4 py-3 flex justify-between items-center bg-white dark:bg-[#1F242F] border-b">
              <motion.div
                className="relative flex items-center justify-center h-8 overflow-x-visible"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <Icon
                  name="logo"
                  color={mode === 'dark' ? '#FFF' : '#0029FF'}
                  size={40}
                />
              </motion.div>
              <div>
                <button
                  className="navbar-burger flex items-center text-blue-600 p-3"
                  onClick={toggleMobileMenu}
                >
                  <Icon name="Close" size={24} />
                </button>
              </div>
            </nav>
            <div className="p-3">
              <ul className="mt-2 h-[72dvh] overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
                {items.map((item: Item, index: number) => {
                  return (
                    <li
                      key={index}
                      className={`mb-4 px-2 rounded-lg  overflow-x-visible ${item.path === actualPath ? mobileActiveTab : 'text-[#808DA1]'}`}
                    >
                      {item.path ? (
                        <Link
                          to={item.path}
                          className="flex items-center space-x-3 cursor-pointer"
                          onClick={() => {
                            setActiveSubitem(null);
                            setOpenMobileMenu(false);
                          }}
                        >
                          <div className="sm:w-20 lg:w-[102px] h-fit flex justify-center items-center py-[6px] gap-2 rounded-md">
                            <Icon
                              name={item.icon}
                              color={
                                item.path === actualPath && mode === 'light'
                                  ? '#000' // Default color
                                  : item.path === actualPath && mode === 'dark'
                                    ? '#FFF' // Dark mode color
                                    : '#808DA1' // Default color
                              }
                              size={24}
                            />
                            <div className="text-xl font-[500]">
                              {item.label}
                            </div>
                          </div>
                        </Link>
                      ) : (
                        <Accordion
                          title={item?.label}
                          titleIcon={item?.icon}
                          titleIconColor={
                            item.path === actualPath && mode === 'light'
                              ? '#000' // Default color
                              : item.path === actualPath && mode === 'dark'
                                ? '#FFF' // Dark mode color
                                : '#808DA1' // Default color
                          }
                          titlePadding="px-0"
                          cardClass="bg-white border-none shadow-none"
                          textClass="text-xl text-[#808DA1]"
                        >
                          {item.subItems.map((subItem, subIndex) => (
                            <Link
                              to={subItem.path}
                              className="flex items-center justify-start gap-2 cursor-pointer w-full pl-4"
                              key={subIndex}
                              onClick={() => {
                                setOpenMobileMenu(false);
                              }}
                            >
                              {subItem?.icon && (
                                <div className="w-6 flex justify-center items-center">
                                  <Icon
                                    name={subItem?.icon}
                                    color="#808DA1"
                                    size={20}
                                  />
                                </div>
                              )}
                              <div
                                className={`text-left font-inter leading-6 font-[500] text-[16px] py-4 `}
                              >
                                {subItem.label}
                              </div>
                            </Link>
                          ))}
                        </Accordion>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="flex justify-between items-center border-t-2 border-gray-200 w-full p-3">
              <div className="flex flex-row pt-4 relative ">
                <div>
                  {currentUser?.profile_pic ? (
                    <img className="w-10 h-10" src={currentUser?.profile_pic} />
                  ) : (
                    <>
                      <ImageIcon
                        data={
                          currentUser?.first_name + ' ' + currentUser?.last_name
                        }
                        size={8}
                        textSize={'sm'}
                      />
                    </>
                  )}
                </div>
                <div className="ml-2 flex flex-col">
                  <span className="text-xs font-inter font-bold text-black dark:text-white truncate block scale-in-left truncate max-w-[200px]">
                    {currentUser?.first_name + ' ' + currentUser?.last_name}
                  </span>
                  <span
                    className="truncate max-w-[230px] block text-xs font-inter text-gray-500 mt-1 dark:text-white scale-in-left"
                    title={currentUser?.email}
                  >
                    {currentUser?.email || 'undefined'}
                  </span>
                  <span
                    className="truncate max-w-[230px] block text-xs font-inter text-[#005C9F] border border-[#80AECF] bg-[#E6EFF5] font-[400] mt-1  w-fit py-[2px] px-[8px] rounded-full scale-in-left"
                    title={currentUser?.gurulytics_role}
                  >
                    {currentUser?.gurulytics_role?.name ||
                      currentUser?.role?.name}
                  </span>
                </div>
                <div
                  onClick={logUserOut}
                  className={`cursor-pointer ml-2 pt-2`}
                >
                  <Icon name="logout" color={iconColor} size={24} />
                </div>
              </div>

              <div className="flex ml-2">
                {' '}
                <Toggle
                  label={``}
                  defaultChecked={mode === 'light'}
                  onToggle={() => toggleTheme()}
                />
              </div>
            </div>
          </div>
        </motion.div>
      )}

      <div
        ref={sidebarRef}
        className={`hidden sm:block relative transition-all duration-500 ease-in-out ] max-h-screen overflow-visible bg-white dark:bg-[#222732] sm:w-20 lg:w-[105px] `}
      >
        <div
          className={`flex flex-col h-screen  justify-between  overflow-x-visible`}
        >
          <div className="w-full overflow-x-visible">
            <Link to="/home">
              <div className="relative flex items-center justify-center h-16 overflow-x-visible">
                <Icon
                  name="logo"
                  color={mode === 'dark' ? '#FFF' : '#0029FF'}
                  size={40}
                />
              </div>
            </Link>
            <ul className="sm:px-0 mt-2 flex flex-col items-center overflow-x-visible scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 ">
              {items.map((item: Item, index: number) => {
                return (
                  <li
                    key={index}
                    className={`mb-2 px-2 rounded-lg hover:bg-[#F3F7FA] dark:hover:bg-[#29292a] overflow-x-visible ${item.path === actualPath ? active : 'text-[#808DA1]'}`}
                  >
                    {item.path ? (
                      <Link
                        to={item.path}
                        className="flex items-center space-x-3 cursor-pointer overflow-x-visible"
                        onClick={() => setActiveSubitem(null)}
                      >
                        <div className="sm:w-20  h-fit flex flex-col justify-center items-center py-[6px] gap-1 rounded-md ">
                          <Icon
                            name={item.icon}
                            color={
                              item.path === actualPath
                                ? '#0029FF' // Default color
                                : mode === 'dark'
                                  ? '#FFF' // Dark mode color
                                  : '#808DA1' // Light mode coloritem.path === actualPath ? '#0029FF' : '#808DA1'
                            }
                            size={24}
                          />
                          <div className="text-[12px] font-[600]">
                            {item.label}
                          </div>
                        </div>
                      </Link>
                    ) : (
                      <div
                        onMouseEnter={() => handleItemEnter(item.label)}
                        onMouseLeave={handleItemLeave}
                        className={`flex items-center space-x-3 cursor-pointer `}
                      >
                        <div
                          className={`sm:w-24 h-fit flex flex-col justify-center items-center py-[6px]  gap-1 rounded-md ${
                            item.path === actualPath ||
                            item.subItems.some((subItem) =>
                              subItem.path.includes(actualPath)
                            )
                              ? active
                              : 'text-[#808DA1]'
                          }`}
                        >
                          <Icon
                            name={item.icon}
                            color={
                              item.path === actualPath ||
                              item.subItems.some((subItem) =>
                                subItem.path.includes(actualPath)
                              )
                                ? '#0029FF'
                                : mode === 'dark'
                                  ? '#FFF' // Dark mode color
                                  : '#808DA1'
                            }
                            size={24}
                          />
                          <div className="text-[12px] font-[600]">
                            {item.label}
                          </div>
                        </div>
                        {item.subItems && activeSubitem === item.label && (
                          <div
                            className={`absolute top-0 z-50 bg-white dark:bg-gray-900 h-screen  left-[90px] w-[300px]`}
                          >
                            <div className="flex flex-col justify-between h-full">
                              <div>
                                <h1 className="ml-[2.95rem]  my-[1.15rem]  lg:text-[18px] hidden md:block font-bold  font-inter text-[#001C44] dark:text-white">
                                  {item.label}
                                </h1>
                                <ul className="ml-[1.5rem] p-2 z-50">
                                  {item.subItems.map((subItem, subIndex) => (
                                    <li
                                      className={`w-full flex justify-start items-center hover:bg-gray-200 rounded-md text-[#405573] ${
                                        subIndex !== item.subItems.length - 1
                                          ? `mb-2`
                                          : ''
                                      } ${
                                        subItem.path.includes(actualPath)
                                          ? `text-[#808DA1] bg-[#F2F4FF] rounded-md`
                                          : ''
                                      }`}
                                      key={subIndex}
                                    >
                                      <Link
                                        to={subItem.path}
                                        className={`flex items-center justify-start gap-2 cursor-pointer w-full pl-4`}
                                      >
                                        {subItem?.icon && (
                                          <div className="w-5">
                                            <Icon name={subItem?.icon} />
                                          </div>
                                        )}
                                        <div
                                          className={`text-left font-inter leading-6 font-[500] text-[13px] py-2 `}
                                        >
                                          {subItem.label}
                                        </div>
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                              <div className="flex justify-between items-center">
                                <div className="pl-[2.95rem] pb-3 flex flex-col">
                                  <span className="pb-7 text-sm text-[#001C44] dark:text-white capitalize">
                                    {mode} mode
                                  </span>
                                  <span className="text-sm font-inter font-bold text-black dark:text-white truncate block max-w-[122px] scale-in-left">
                                    {currentUser?.first_name +
                                      ' ' +
                                      currentUser?.last_name}
                                  </span>
                                  <span
                                    className="truncate block max-w-xs text-sm font-inter text-gray-500 mt-1 dark:text-white w-[200px] scale-in-left"
                                    title={currentUser?.email}
                                  >
                                    {currentUser?.email || 'undefined'}
                                  </span>
                                </div>
                                <div
                                  onClick={logUserOut}
                                  className={`static right-8 bottom-[68px] cursor-pointer pt-6 pr-4`}
                                >
                                  <Icon
                                    name="logout"
                                    color={iconColor}
                                    size={20}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
          <div
            className={`flex flex-col relative h-30 items-center gap-8 w-full sm:p-2 lg:p-4 justify-end `}
          >
            {/* {sidebarCollapsed && (
              <>
                <div className="flex">
                  {' '}
                  <Toggle
                    label={`${mode.charAt(0).toUpperCase()}${mode.slice(1)} Mode`}
                    defaultChecked={mode === 'light'}
                    onToggle={() => toggleTheme()}
                  />
                </div>
                <div className="flex flex-row pt-4 mt-4 relative border-t-2 border-gray-200">
                  <div>
                    {currentUser?.profile_pic ? (
                      <img
                        className="w-10 h-10"
                        src={currentUser?.profile_pic}
                      />
                    ) : (
                      <>
                        <ImageIcon
                          data={
                            currentUser?.first_name +
                            ' ' +
                            currentUser?.last_name
                          }
                          size={8}
                          textSize={'sm'}
                        />
                      </>
                    )}
                  </div>
                  <div className="ml-2 flex flex-col">
                    <span className="text-sm font-inter font-bold text-black dark:text-white truncate block max-w-[122px] scale-in-left">
                      {currentUser?.first_name + ' ' + currentUser?.last_name}
                    </span>
                    <span
                      className="truncate block max-w-xs text-sm font-inter text-gray-500 mt-1 dark:text-white w-40 scale-in-left"
                      title={currentUser?.email}
                    >
                      {currentUser?.email || 'undefined'}
                    </span>
                    <span
                      className="truncate block max-w-xs text-[12px] font-inter text-[#005C9F] border border-[#80AECF] bg-[#E6EFF5] font-[400] mt-1  w-fit py-[2px] px-[8px] rounded-full scale-in-left"
                      title={currentUser?.gurulytics_role}
                    >
                      {currentUser?.gurulytics_role?.name ||
                        currentUser?.role?.name}
                    </span>
                  </div>
                </div>
              </>
            )} */}
            <div className="flex ml-2">
              {' '}
              <Toggle
                label={``}
                defaultChecked={mode === 'light'}
                onToggle={() => toggleTheme()}
              />
            </div>

            <div
              className={`static flex gap-2 items-center right-8 bottom-[68px] cursor-pointer `}
            >
              {/* <Tooltip
                content={currentUser?.first_name + ' ' + currentUser?.last_name}
                position="top"
              > */}
              <div>
                {currentUser?.profile_pic ? (
                  <img className="w-10 h-10" src={currentUser?.profile_pic} />
                ) : (
                  <>
                    <ImageIcon
                      data={
                        currentUser?.first_name + ' ' + currentUser?.last_name
                      }
                      size={8}
                      textSize={'sm'}
                    />
                  </>
                )}
              </div>
              {/* </Tooltip> */}
              {activeSubitem === null && (
                <div
                  onClick={logUserOut}
                  className={`static right-8 bottom-[68px] cursor-pointer `}
                >
                  <Icon name="logout" color={iconColor} size={20} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
