import React from 'react';
import * as Icons from './';
import { IconProps } from './svgs/types';

interface SvgComponentProps extends IconProps {
  name: string;
}

const SvgComponent: React.FC<SvgComponentProps> = ({
  name,
  color,
  size,
  ...props
}) => {
  // Get the correct icon component based on the name
  const capitalize = name?.charAt(0)?.toUpperCase() + name?.slice(1);
  const IconComponent = (Icons as any)[capitalize];

  // If icon doesn't exist, return null or some default SVG
  if (!IconComponent) {
    console.warn(`Icon "${name}" does not exist.`);
    return null; // or return some default SVG component
  }

  // Render the icon component with the provided props
  return <IconComponent color={color} size={size} {...props} />;
};

export default SvgComponent;
