import { Grid } from '@tremor/react';
import AreaCardChart from '../AreaCardChart';
import {
  formatToCurrency,
  formatNumber,
  processData,
  processData2,
} from '../../../utils/common';

interface IProps {
  isLeadGen?: boolean;
  isGoogleAds?: boolean;
  selectedClient?: any;
  topPerformanceAds?: any;
  requestGoogleAdsAccess?: any;
}

const KPIGa4 = ({
  isLeadGen,
  isGoogleAds,
  selectedClient,
  topPerformanceAds,
  requestGoogleAdsAccess,
}: IProps) => {
  const adsCostMicros = processData(topPerformanceAds, 'cost_micros', 'Spend');
  const lastMonthAdsCostMicros =
    Array.isArray(adsCostMicros) && adsCostMicros.length > 0
      ? adsCostMicros[adsCostMicros.length - 1]?.value
      : undefined;
  const adsClicks = processData(topPerformanceAds, 'clicks');
  const lastMonthAdsClicks =
    Array.isArray(adsClicks) && adsClicks.length > 0
      ? adsClicks[adsClicks.length - 1]?.value
      : undefined;
  const adsConversions = processData2({
    dates: topPerformanceAds?.conversionActions?.['overall']?.dates,
    values: topPerformanceAds?.conversionActions?.['overall']?.values,
    totalKeyName: 'Conversions',
  });
  const lastMonthAdsConversions =
    Array.isArray(adsConversions) && adsConversions.length > 0
      ? adsConversions[adsConversions.length - 1]?.Conversions
      : undefined;
  const adsCostPerConversion = processData(
    topPerformanceAds,
    'cost_per_conversion'
  );
  const lastMonthAdsCostPerConversion =
    Array.isArray(adsCostPerConversion) && adsCostPerConversion.length > 0
      ? adsCostPerConversion[adsCostPerConversion.length - 1]?.value
      : undefined;
  const adsRevenue = processData(topPerformanceAds, 'revenue');
  const lastMonthAdsRevenue =
    Array.isArray(adsRevenue) && adsRevenue.length > 0
      ? adsRevenue[adsRevenue.length - 1]?.value
      : undefined;
  const adsRoas = processData(topPerformanceAds, 'roas');
  const lastMonthAdsRoas =
    Array.isArray(adsRoas) && adsRoas.length > 0
      ? adsRoas[adsRoas.length - 1]?.value
      : undefined;
  return (
    <Grid
      numItems={1}
      numItemsSm={1}
      numItemsMd={2}
      numItemsLg={4}
      className="gap-4"
    >
      <AreaCardChart
        title={topPerformanceAds?.cost_micros?.label || 'Spend'}
        chatData={adsCostMicros}
        status={isGoogleAds}
        category={['Spend']}
        totalValue={formatToCurrency(lastMonthAdsCostMicros)}
        error={topPerformanceAds.error}
        prevPeriod={topPerformanceAds?.cost_micros?.previous_period}
        previousYear={topPerformanceAds?.cost_micros?.previous_year}
        isLoading={
          !['activated', 'active'].includes(
            selectedClient?.google_ads?.ga4?.status
          )
            ? false
            : topPerformanceAds.loading
        }
        requestAccess={requestGoogleAdsAccess}
        // noAccessSubtitle={googleAdsNoAccessSub}
        info={topPerformanceAds?.cost_micros?.info || 'Spend'}
        icon="googleAd"
        showCurrentMonth={true}
        hasDollar
      />
      <AreaCardChart
        title={topPerformanceAds?.clicks?.label || 'Clicks'}
        chatData={adsClicks}
        status={isGoogleAds}
        category={['Clicks']}
        totalValue={formatNumber(lastMonthAdsClicks)}
        error={topPerformanceAds.error}
        prevPeriod={topPerformanceAds?.clicks?.previous_period}
        previousYear={topPerformanceAds?.clicks?.previous_year}
        isLoading={
          !['activated', 'active'].includes(
            selectedClient?.google_ads?.ga4?.status
          )
            ? false
            : topPerformanceAds.loading
        }
        requestAccess={requestGoogleAdsAccess}
        // noAccessSubtitle={googleAdsNoAccessSub}
        info={topPerformanceAds?.clicks?.info || 'Clicks'}
        icon="googleAd"
        showCurrentMonth={true}
      />
      {isLeadGen ? (
        <>
          <AreaCardChart
            title={topPerformanceAds?.conversions?.label || 'Conversions'}
            chatData={adsConversions}
            status={isGoogleAds}
            category={['Conversions']}
            totalValue={formatNumber(lastMonthAdsConversions)}
            error={topPerformanceAds.error}
            prevPeriod={topPerformanceAds?.conversions?.previous_period}
            previousYear={topPerformanceAds?.conversions?.previous_year}
            isLoading={topPerformanceAds?.loading}
            requestAccess={requestGoogleAdsAccess}
            // noAccessSubtitle={googleAdsNoAccessSub}
            customDataTooltip={true}
            info={topPerformanceAds?.overall?.info || 'Conversions'}
            icon="googleAd"
            showCurrentMonth={true}
          />
          <AreaCardChart
            title={`${topPerformanceAds?.cost_per_conversion?.label || 'Cost Per Conversion'}`}
            chatData={adsCostPerConversion}
            status={isGoogleAds}
            category={['Cost Per Conversion']}
            totalValue={formatToCurrency(lastMonthAdsCostPerConversion)}
            prevPeriod={
              -topPerformanceAds?.cost_per_conversion?.previous_period
            }
            previousYear={
              -topPerformanceAds?.cost_per_conversion?.previous_year
            }
            isLoading={
              !['activated', 'active'].includes(
                selectedClient?.google_ads?.ga4?.status
              )
                ? false
                : topPerformanceAds.loading
            }
            error={topPerformanceAds.error}
            hasDollar={true}
            requestAccess={requestGoogleAdsAccess}
            // noAccessSubtitle={googleAdsNoAccessSub}
            info={
              topPerformanceAds?.cost_per_conversion?.info ||
              'Cost Per Conversion'
            }
            icon="googleAd"
            showCurrentMonth={true}
          />
        </>
      ) : (
        <>
          <AreaCardChart
            title={topPerformanceAds?.revenue?.label || 'Revenue'}
            chatData={adsRevenue}
            category={['Revenue']}
            status={isGoogleAds}
            prevPeriod={topPerformanceAds?.revenue?.previous_period}
            previousYear={topPerformanceAds?.revenue?.previous_year}
            totalValue={formatToCurrency(lastMonthAdsRevenue)}
            isLoading={!isGoogleAds ? false : topPerformanceAds.loading}
            error={topPerformanceAds.error}
            hasDollar={true}
            requestAccess={requestGoogleAdsAccess}
            // noAccessSubtitle={googleMetaNoAccessSub}
            info={topPerformanceAds?.revenue?.info || 'Revenue'}
            icon="googleAd"
            showCurrentMonth={true}
          />
          <AreaCardChart
            title={`${topPerformanceAds?.roas?.label || 'ROAS'}`}
            chatData={adsRoas}
            category={['Roas']}
            status={isGoogleAds}
            prevPeriod={topPerformanceAds?.roas?.previous_period}
            previousYear={topPerformanceAds?.roas?.previous_year}
            totalValue={formatNumber(lastMonthAdsRoas)}
            isLoading={!isGoogleAds ? false : topPerformanceAds.loading}
            error={topPerformanceAds.error}
            // hasDollar={true}
            requestAccess={requestGoogleAdsAccess}
            // noAccessSubtitle={googleMetaNoAccessSub}
            info={topPerformanceAds?.roas?.info || 'ROAS'}
            icon="googleAd"
            showCurrentMonth={true}
          />
        </>
      )}
    </Grid>
  );
};

export default KPIGa4;
