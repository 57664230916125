import React from 'react';

interface IProps {
  color?: string;
  size?: number;
}

const ClearFilter: React.FC<IProps> = ({ color = '#808DA1', size = 24 }) => (
  <svg
    fill={color}
    width={size}
    height={size}
    viewBox="0 0 32 32"
    id="icon"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <style
        dangerouslySetInnerHTML={{
          __html: '\n      .cls-1 {\n        fill: none;\n      }\n    ',
        }}
      />
    </defs>
    <path d="M22.5,9A7.4522,7.4522,0,0,0,16,12.792V8H14v8h8V14H17.6167A5.4941,5.4941,0,1,1,22.5,22H22v2h.5a7.5,7.5,0,0,0,0-15Z" />
    <path d="M26,6H4V9.171l7.4142,7.4143L12,17.171V26h4V24h2v2a2,2,0,0,1-2,2H12a2,2,0,0,1-2-2V18L2.5858,10.5853A2,2,0,0,1,2,9.171V6A2,2,0,0,1,4,4H26Z" />
    <rect
      id="_Transparent_Rectangle_"
      data-name="<Transparent Rectangle>"
      className="cls-1"
      width={32}
      height={32}
    />
  </svg>
);

export default ClearFilter;
