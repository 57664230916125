import React, { useState, useCallback, useEffect } from 'react';
import { debounce } from 'lodash';
import usePerformanceStore from '../../../store/usePerformanceStore';
import Icon from '../../../assets/icons/SvgComponent';

interface ClientSearchProps {
  selectedClientId: string | null;
  setSelectedClientId: React.Dispatch<React.SetStateAction<string | null>>;
}

const ClientSearch: React.FC<ClientSearchProps> = ({ setSelectedClientId }) => {
  const { clientsOptions, fetchClients, clearOptions } = usePerformanceStore();
  const [searchTerm, setSearchTerm] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);

  const debouncedFetchClients = useCallback(
    debounce((term: string) => {
      fetchClients(term);
    }, 500),
    [fetchClients]
  );

  useEffect(() => {
    if (searchTerm.length >= 3) {
      clearOptions();
      debouncedFetchClients(searchTerm);
    }
    if (searchTerm.length === 0) {
      clearOptions();
    }
  }, [searchTerm, debouncedFetchClients, clearOptions]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value;
    setSearchTerm(term);
    setShowDropdown(true);
  };

  const handleOptionClick = (option: any) => {
    setSelectedClientId(option.id);
    setSearchTerm(option.name);
    setShowDropdown(false);
  };

  return (
    <div className="relative w-full">
      <div className="flex-grow">
        <div className="flex items-center w-full">
          <div className="relative w-full">
            <input
              type="search"
              className="block p-2 w-full z-50 text-sm text-gray-900 rounded-lg border border-gray-300 dark:bg-[#374151] dark:border-0 dark:text-white"
              placeholder="Search by client"
              value={searchTerm}
              onChange={(e) => {
                handleInputChange(e);
                if (e.target.value === '') {
                  setSelectedClientId(null);
                  setShowDropdown(false);
                }
              }}
              onFocus={() => setShowDropdown(true)}
            />
            {showDropdown && (
              <div className="absolute z-10 bg-white border border-gray-300 rounded-md mt-1 w-full">
                <div className="max-h-60 overflow-y-scroll">
                  {clientsOptions?.data?.map((option: any, index: number) => (
                    <div
                      key={index}
                      className="px-3 py-2 hover:bg-gray-100 text-[#000] cursor-pointer text-xs"
                      onClick={() => handleOptionClick(option)}
                    >
                      {option.name}
                    </div>
                  ))}
                </div>
                {clientsOptions.loading && (
                  <div className="w-full h-full flex items-center justify-center py-4">
                    <Icon name="Loading" size={20} />
                  </div>
                )}
                {!clientsOptions.loading &&
                  clientsOptions?.data?.length === 0 && (
                    <div className="text-center text-xs text-gray-400 py-4">
                      No clients found.
                    </div>
                  )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientSearch;
