import { Card } from '@tremor/react';
import Icon from '../../../../assets/icons/SvgComponent';
import AnalyticsTable from '../../AnalyticsTable';

interface IProps {
  campaignsReport: any;
  topAdsReportData: any;
}

const mobileView = ({ topAdsReportData, campaignsReport }: IProps) => {
  return (
    <>
      {topAdsReportData?.data?.length === 0 && !campaignsReport.loading ? (
        <Card className="card-item px-3 sm:px-6 shadow-none lg:shadow ring-0 lg:ring-1">
          <div className="flex flex-col items-center justify-center w-full  my-8 h-[148px]">
            <div className="mb-8">
              <Icon name="NoDataAdGroup" />
            </div>
            <p className="font-inter font-[600] text-[20px] text-[#001C44] dark:text-white">
              No top performing ads to showcase currently.
            </p>
            <p className="text-center w-[70vw] text-[20px] text-[#001C44] dark:text-white">
              Keep engaged with the forefront of advertising effectiveness. Our
              curated section offers insights and updates on high-impact
              strategies and trends, essential for driving your ad performance.
            </p>
          </div>
        </Card>
      ) : (
        <AnalyticsTable
          data={topAdsReportData?.data}
          columns={topAdsReportData?.tableHeader}
          loading={campaignsReport.loading}
          totalDocs={
            topAdsReportData?.data ? topAdsReportData?.data.length : 'N/A'
          }
          sortByColumnId="Cost"
          pagination={false}
        />
      )}
    </>
  );
};

export default mobileView;
