// Filename: ComingSoonComponent.tsx

import React from 'react';
import comingSoon from '../../assets/images/404.png';
import Featured from '../../assets/images/Featured-icon.png';

interface IProps {
  title?: string;
  message?: string;
}

const ServiceNotAvailable = ({
  title = 'Some amazing new features in the works',
  message = "Stay tuned for updates as we continue to enhance your experience with our app. We can't wait for you to try these new additions and take your experience to the next level. Keep an eye on your notifications for more details.",
}: IProps) => {
  return (
    <div className="flex justify-between flex-col md:flex-row w-full bg-[#FFF] dark:bg-[#0C111D] dark:border-transparent p-14 rounded-md ">
      <div className="flex justify-center flex-col w-full md:w-2/4 mt-6 md:mt-0 relative">
        <img src={Featured} width={56} alt="featured" />
        <h1 className="text-[34px] font-archivo font-bold text-[#001C44] dark:text-white mt-6 w-[350px]">
          {title}
        </h1>

        <p className="mt-2 w-4/5 font-inter text-base md:text-lg text-[#001C44] dark:text-white">
          {message}
        </p>
      </div>
      <div className="w-9/12 md:w-2/4 mt-6 md:mt-0">
        <img src={comingSoon} alt="not-available" />
      </div>
    </div>
  );
};

export default ServiceNotAvailable;
