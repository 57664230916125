import React from 'react';

interface DotProps {
  color?: string;
}

const Trend: React.FC<DotProps> = ({ color = '#428B6E' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
  >
    <g clipPath="url(#clip0_671_758)">
      <path
        d="M11.5 3.5L7.56568 7.43432C7.36768 7.63232 7.26867 7.73133 7.15451 7.76842C7.05409 7.80105 6.94591 7.80105 6.84549 7.76842C6.73133 7.73133 6.63232 7.63232 6.43431 7.43431L5.06568 6.06568C4.86768 5.86768 4.76867 5.76867 4.65451 5.73158C4.55409 5.69895 4.44591 5.69895 4.34549 5.73158C4.23133 5.76867 4.13232 5.86768 3.93431 6.06569L1.5 8.5M11.5 3.5H8M11.5 3.5V7"
        stroke="#428B6E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_671_758">
        <rect width="12" height="12" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default Trend;
