import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PageHeader from '../../components/Dashboard/PageHeader';
import TanStackTable from '../../components/TanStackTable';
import CustomModal from '../../components/CustomModal';
import ChangeRequestModal from '../../components/Modals/ChangeRequest';
import ConfirmationModal from '../../components/Modals/ChangeRequest/confirmationModal';
import Icon from '../../assets/icons/SvgComponent';
import { useThemeStore } from '../../store/useThemeStore';
import { FaRedo } from 'react-icons/fa';
import useAuthStore from '../../store/useAuthStore';
import useChangeStore from '../../store/useChangeStore';
import ChangeCounter from '../../components/Report/ChangeCounter';
import SearchBar from '../../components/Search';

export default function ChangeRequest() {
  const { fetchReports, fetchChanges, changeRequests, selectedChange, loading } = useChangeStore((state) => state);
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useAuthStore((state) => state);
  const [reportData, setReportData] = useState({});
  const [sortingDirection, setSortingDirection] = useState('');
  const [sortingColumn, setSortingColumn] = useState('');
  const location = useLocation();
  const isReportPage = false
  const dataFormatter = (data):any => {
    if(data.data && data.data.length){
      var changeObj = {
        apply: [],
        cancel: [],
        nonstart: [],
        pause: [],
        stop: [],
        delay: [],
        reduce: [],
        refund: [],
        restart: [],
        flagged: [],
      }

      data.data.map(ch => {
        switch(ch.changeType){
          case 'apply' :
            changeObj.apply.push(ch)
          break;
          case 'cancel' :
            changeObj.cancel.push(ch)
          break;
          case 'nonstart' :
            changeObj.nonstart.push(ch)
          break;
          case 'pause' :
            changeObj.pause.push(ch)
          break;
          case 'stop' :
            changeObj.stop.push(ch)
          break;
          case 'delay' :
            changeObj.delay.push(ch)
          break;
          case 'reduce' :
            changeObj.reduce.push(ch)
          break;
          case 'refund' :
            changeObj.refund.push(ch)
          break;
          case 'restart' :
            changeObj.restart.push(ch)
          break;
          case 'flagged' :
            changeObj.flagged.push(ch)
          break;
        }
    })
    console.log(changeObj)
      return changeObj
    }else{
      return
    }
  }
  const [limit, setLimit] = useState({ label: '150', id: '150' });
  const [page, setPage] = useState(1);
  const [filteredChange, setFilteredChange] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [selectedAccount, setSelectedAccount] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [searchText, setSearchText] = useState('');

  const fetchChangeFunction = (filters) => {
    setIsLoading(true);
    fetchChanges(filters).then((res: any) => {
      setIsLoading(false);
    });
  };

  const handleFetchChanges = ({ currentPage }: any) => {
    const queryParams = new URLSearchParams(location.search);
    let filters = {
      search: searchText,
      page: currentPage || page,
      limit: limit.id,
      sortBy: queryParams.get('sortColumn'),
      sortOrder: queryParams.get('sortDirection'),
      perms: currentUser.gurulytics_role.id
    };

    fetchChanges(filters);
    if (currentPage) {
      setPage(currentPage);
    }
  };

  const handleSearch = () => {
    if(searchText?.length > 2){
      try {
        handleFetchChanges(1);
        setPage(1);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (searchText?.length === 0){
      const filters = {
        search: '',
        page: 1,
        limit: limit.id,
        sortBy: queryParams.get('sortColumn'),
        sortOrder: queryParams.get('sortDirection'),
        perms: currentUser.gurulytics_role.id
      };
      fetchChangeFunction(filters);
      fetchReports({search:'', page:1}).then((res: any) => {
        let formattedChanges = dataFormatter(res)
        setReportData(formattedChanges);
      });
    }else{
      const filters = {
        search: '',
        page: 1,
        limit: limit.id,
        sortBy: queryParams.get('sortColumn'),
        sortOrder: queryParams.get('sortDirection'),
      };
      fetchChangeFunction(filters);
      fetchReports({search:'', page:1}).then((res: any) => {
        let formattedChanges = dataFormatter(res)
        setReportData(formattedChanges);
      });
    }
  }, [fetchChanges, selectedChange, location.search]);

  const handleSortTable = (sortColumn, sortDirection) => {
    let newSortDirection = '';
    let newSortColumn = sortColumn;

    if (sortingDirection === '') {
      if (sortDirection === 'desc') {
        newSortDirection = 'asc';
      } else {
        newSortDirection = 'desc';
      }
    } else if (sortingDirection === 'asc') {
      newSortDirection = 'desc';
    } else if (sortingDirection === 'desc') {
      newSortDirection = null;
    } else if (sortingDirection === null) {
      newSortDirection = 'asc';
    }

    if (sortingColumn !== newSortColumn) {
      newSortDirection = 'asc';
    } else if (sortingDirection === 'asc') {
      newSortDirection = 'desc';
    } else if (sortingDirection === 'desc') {
      newSortDirection = null;
    } else if (sortingDirection === null) {
      newSortDirection = 'asc';
    }

    if (sortDirection === null) {
      setSortingColumn('');
    }
    if(newSortDirection === null){
      newSortColumn= null
    }

    console.log('Setting Direction: ', newSortDirection)
    console.log('Setting Column: ', newSortColumn)
    setSortingDirection(newSortDirection);
    setSortingColumn(newSortColumn);

    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set('sortDirection', newSortDirection);
    newUrl.searchParams.set('sortColumn', sortColumn);
    window.history.pushState({}, '', newUrl);
    
    let filters = {
      search: searchText,
      page: page,
      limit: limit.id,
      sortBy: newSortColumn,
      sortOrder: newSortDirection,
      perms: currentUser.gurulytics_role.id
    };

    fetchChanges(filters);
  };

  const { mode } = useThemeStore((state: any) => state);
  const iconColor = mode === 'dark' ? 'white' : 'black';
  const [confirmationModalDecline, setConfirmationModalDecline] =
    useState(false);
  const [confirmationModalApprove, setConfirmationModalApprove] =
    useState(false);

  const [searchValue, setSearchValue] = useState('');

  const sendRequest = (change: any) => {
    setSelectedAccount(change);
    setOpenModal(true);
  };

  const reSendRequest = (reSend: any) => {
    console.log(reSend)
  };

  const columns = [
    {
      id: 'domain',
      accessorKey: 'domain',
      header: () => (
      <div>
        {sortingColumn === 'domain' ? (
          <div className="flex w-[10vw] justify-center gap-2 cursor-pointer">
            <div className="text-left text-sm font-inter w-fit">Client</div>
            <div>
              {sortingDirection === 'asc' && <Icon name="Arrowup" />}
              {sortingDirection === 'desc' && <Icon name="ArrowDown" />}
              {sortingDirection === null && <Icon name="Sort" />}
            </div>
          </div>
        ) : (
          <div className="flex justify-center gap-2 w-[10vw] text-sm font-inter cursor-pointer">
            <div>Client</div>
            <Icon name="Sort" />
          </div>
        )}
      </div>
      ),
      cell: (props: any) => (
        <div className="text-center pl-6 py-4">
          <span className="">{props.getValue()}</span>
        </div>
      ),
    },
    {
      id: 'changeType',
      header: () => (
        <div>
        {sortingColumn === 'changeType' ? (
          <div className="flex w-[10vw] justify-center gap-2 cursor-pointer">
            <div className="text-left text-sm font-inter w-fit">Request</div>
            <div>
              {sortingDirection === 'asc' && <Icon name="Arrowup" />}
              {sortingDirection === 'desc' && <Icon name="ArrowDown" />}
              {sortingDirection === null && <Icon name="Sort" />}
            </div>
          </div>
        ) : (
          <div className="flex justify-center gap-2 w-[10vw] text-sm font-inter cursor-pointer">
            <div>Request</div>
            <Icon name="Sort" />
          </div>
        )}
      </div>
      ),
      accessorKey: 'changeDetails',
      // header: () => (
      //   <div className='text-sm flex justify-center w-full pl-6'>
      //     <span>Client </span>
      //   </div>
      // ),
      cell: (props: any) => (
        <div className="flex justify-center w-full px-4">
          <span>{props.getValue()?.type.toUpperCase()}</span>
        </div>
      ),
    },
    {
      id: 'budget',
      header: () => (
        <div>
        {sortingColumn === 'budget' ? (
          <div className="flex w-[10vw] justify-center gap-2 cursor-pointer">
            <div className="text-left text-sm font-inter w-fit">Revenue</div>
            <div>
              {sortingDirection === 'asc' && <Icon name="Arrowup" />}
              {sortingDirection === 'desc' && <Icon name="ArrowDown" />}
              {sortingDirection === null && <Icon name="Sort" />}
            </div>
          </div>
        ) : (
          <div className="flex justify-center gap-2 w-[10vw] text-sm font-inter cursor-pointer">
            <div>Revenue</div>
            <Icon name="Sort" />
          </div>
        )}
      </div>
      ),
      accessorKey: 'changeDetails',
      // header: () => (
      //   <div className='text-sm flex justify-center w-full pl-6'>
      //     <span>Client </span>
      //   </div>
      // ),
      cell: (props: any) => (
        <div className="flex justify-center w-full">
          <span>
            {props.getValue()?.type === 'refund' ? ('$'+props.getValue()?.refundBudget) : ''}
            {props.getValue()?.type === 'apply credit' ? ('$'+props.getValue()?.applyCreditBudget) : ''}
            {props.getValue()?.type === 'cancel' ? ('$'+props.getValue()?.cancelBudget) : ''}
            {props.getValue()?.type === 'pause' ? ('$'+props.getValue()?.pauseClientBudget) : ''}
            {props.getValue()?.type === 'stop work' ? ('$'+props.getValue()?.stopWorkBudget) : ''}
            {props.getValue()?.type === 'delay billing' ? ('$'+props.getValue()?.delayBillingBudget) : ''}
            {props.getValue()?.type === 'reduce billing' ? ('$'+props.getValue()?.reduceBillingBudget) : ''}
            {props.getValue()?.type === 'flagged' ? 'N/A' : ''}
            {props.getValue()?.type === 'restart' ? ('$'+props.getValue()?.restartBudget) : ''}
            {props.getValue()?.type === 'nonstart' ? ('$'+props.getValue()?.nonStartBudget) : ''}
          </span>
        </div>
      ),
    },
    {
      id: 'service',
      header: () => (
        <div className="flex justify-center w-full">Service</div>
      ),
      accessorKey: 'serviceLine',
      // header: () => (
      //   <div className='text-sm flex justify-center w-full pl-6'>
      //     <span>Client </span>
      //   </div>
      // ),
      cell: (props: any) => (
        <div className="flex justify-center w-full px-4">
            <span>
              {props.getValue() === 'seo_google' ? 'Google SEO' : ''}
              {props.getValue() === 'ppc_google' ? 'Google Ads' : ''}
              {props.getValue() === 'seo_bing' ? 'Microsoft SEO' : ''}
              {props.getValue() === 'social_bing' ? 'Microsoft Ads' : ''}
              {props.getValue() === 'display' ? 'Remarketing' : ''}
              {props.getValue() === 'social_meta' ? 'Meta Ads' : ''}
              {props.getValue() === 'meta' ? 'Meta Ads' : ''}
              {props.getValue() === 'social' ? 'Meta Ads' : ''}
              {props.getValue() === 'social_tiktok' ? 'Tiktok Ads' : ''}
              {props.getValue() === 'tiktok' ? 'Tiktok Ads' : ''}
              {props.getValue() === 'social_linkedin' ? 'Linkedin Ads' : ''}
              {props.getValue() === 'social_pinterest' ? 'Pinterest Ads' : ''}
              {props.getValue() === 'pinterest' ? 'Pinterest Ads' : ''}
              {props.getValue() === 'social_programmatic' ? 'Programmatic Ads' : ''}
              {props.getValue() === 'social_tactical' ? 'Tatical Ads' : ''}
              {props.getValue() === 'social_creative' ? 'Creative Ads' : ''}
              {props.getValue() === 'creative' ? 'Creative Ads' : ''}
              {props.getValue() === 'web_hosting' ? 'Web Hosting' : ''}
              {props.getValue() === 'web_maintenance' ? 'Web Maintenance' : ''}
              {props.getValue() === 'web_landing' ? 'Landing Pages' : ''}
              {props.getValue() === 'web_projects' ? 'Web Project' : ''}
              {props.getValue() === undefined ? 'N/A' : ''}
            </span>
        </div>
      ),
    },
    {
      id: 'submitter',
      header: () => (
        <div>
        {sortingColumn === 'submitter' ? (
          <div className="flex w-[10vw] justify-center gap-2 cursor-pointer">
            <div className="text-left text-sm font-inter w-fit">Submitted By</div>
            <div>
              {sortingDirection === 'asc' && <Icon name="Arrowup" />}
              {sortingDirection === 'desc' && <Icon name="ArrowDown" />}
              {sortingDirection === null && <Icon name="Sort" />}
            </div>
          </div>
        ) : (
          <div className="flex justify-center gap-2 w-[10vw] text-sm font-inter cursor-pointer">
            <div>Submitted By</div>
            <Icon name="Sort" />
          </div>
        )}
      </div>
      ),
      accessorKey: 'submitter',
      // header: () => (
      //   <div className='text-sm flex justify-center w-full pl-6'>
      //     <span>Client </span>
      //   </div>
      // ),
      cell: (props: any) => (
        <div className="flex justify-center w-full px-4">
          <span className="">{props.getValue()}</span>
        </div>
      ),
    },
    {
      id: 'approvedBy',
      header: () => (
        <div>
        {sortingColumn === 'approvedBy' ? (
          <div className="flex w-[10vw] justify-center gap-2 cursor-pointer">
            <div className="text-left text-sm font-inter w-fit">Assigned To</div>
            <div>
              {sortingDirection === 'asc' && <Icon name="Arrowup" />}
              {sortingDirection === 'desc' && <Icon name="ArrowDown" />}
              {sortingDirection === null && <Icon name="Sort" />}
            </div>
          </div>
        ) : (
          <div className="flex justify-center gap-2 w-[10vw] text-sm font-inter cursor-pointer">
            <div>Assigned To</div>
            <Icon name="Sort" />
          </div>
        )}
      </div>
      ),
      accessorKey: '',
      // header: () => (
      //   <div className='text-sm flex justify-center w-full pl-6'>
      //     <span>Client </span>
      //   </div>
      // ),
      cell: (props: any) => (
        <div className="flex justify-center w-full px-4">
          <span>{props.row.original.approvedBy ? props.row.original.approvedBy : props.row.original.gadApprover}</span>
        </div>
      ),
    },
    {
      id: 'hasManagerApproval',
      header: () => (
        <div>
        {sortingColumn === 'hasManagerApproval' ? (
          <div className="flex w-[10vw] justify-center gap-2 cursor-pointer">
            <div className="text-left text-sm font-inter w-fit">GAD Approval</div>
            <div>
              {sortingDirection === 'asc' && <Icon name="Arrowup" />}
              {sortingDirection === 'desc' && <Icon name="ArrowDown" />}
              {sortingDirection === null && <Icon name="Sort" />}
            </div>
          </div>
        ) : (
          <div className="flex justify-center gap-2 w-[10vw] text-sm font-inter cursor-pointer">
            <div>GAD Approval</div>
            <Icon name="Sort" />
          </div>
        )}
      </div>
      ),
      accessorKey: 'hasManagerApproval',
      // header: () => (
      //   <div className='text-sm flex justify-center w-full pl-6'>
      //     <span>Client </span>
      //   </div>
      // ),
      cell: (props: any) => (
        <div className="flex justify-center w-full px-4">
          {props.getValue() === true ? 
            <span style={{  color:'#059669'}}>Yes</span> : 
            <span style={{  color:'#e11d48'}}>No</span>
          }
        </div>
      ),
    },
    {
      id: 'financeApproval',
      header: () => (
        <div className="flex justify-center w-full">Finance Approval</div>
      ),
      accessorKey: 'hasFinanceApproval',
      // header: () => (
      //   <div className='text-sm flex justify-center w-full pl-6'>
      //     <span>Client </span>
      //   </div>
      // ),
      cell: (props: any) => (
        <div className="flex justify-center w-full px-4">
          {props.getValue() === true ? 
            <span style={{  color:'#059669'}}>Yes</span> : 
            <span style={{  color:'#e11d48'}}>No</span>
          }
        </div>
      ),
    },
    {
      id: 'effect_date',
      header: () => (
        <div>
        {sortingColumn === 'budget' ? (
          <div className="flex w-[10vw] justify-center gap-2 cursor-pointer">
            <div className="text-left text-sm font-inter w-fit">Effect Date</div>
            <div>
              {sortingDirection === 'asc' && <Icon name="Arrowup" />}
              {sortingDirection === 'desc' && <Icon name="ArrowDown" />}
              {sortingDirection === null && <Icon name="Sort" />}
            </div>
          </div>
        ) : (
          <div className="flex justify-center gap-2 w-[10vw] text-sm font-inter cursor-pointer">
            <div>Effect Date</div>
            <Icon name="Sort" />
          </div>
        )}
      </div>
      ),
      accessorKey: 'changeDetails',
      // header: () => (
      //   <div className='text-sm flex justify-center w-full pl-6'>
      //     <span>Client </span>
      //   </div>
      // ),
      cell: (props: any) => (
        <div className="flex justify-center w-full">
          <span>
            {props.getValue()?.type === 'refund' ? 'Immediate' : ''}
            {props.getValue()?.type === 'apply credit' ? 'Immediate': ''}
            {props.getValue()?.type === 'cancel' ? (props.getValue()?.cancelEffectDate) : ''}
            {props.getValue()?.type === 'pause' ? (props.getValue()?.pauseEffectDate) : ''}
            {props.getValue()?.type === 'stop work' ? 'Immediate' : ''}
            {props.getValue()?.type === 'delay billing' ? (props.getValue()?.delayBillingStartDate) : ''}
            {props.getValue()?.type === 'reduce billing' ? (props.getValue()?.reduceBillingStartDate) : ''}
            {props.getValue()?.type === 'flagged' ? 'Immediate' : ''}
            {props.getValue()?.type === 'restart' ? 'Immediate' : ''}
            {props.getValue()?.type === 'nonstart' ? (props.getValue()?.nonStartEffectDate) : ''}
          </span>
        </div>
      ),
    },
    {
      id: 'createdAt',
      header: () => (
        <div>
        {sortingColumn === 'createdAt' ? (
          <div className="flex w-[10vw] justify-center gap-2 cursor-pointer">
            <div className="text-left text-sm font-inter w-fit">Date Submitted</div>
            <div>
              {sortingDirection === 'asc' && <Icon name="Arrowup" />}
              {sortingDirection === 'desc' && <Icon name="ArrowDown" />}
              {sortingDirection === null && <Icon name="Sort" />}
            </div>
          </div>
        ) : (
          <div className="flex justify-center gap-2 w-[10vw] text-sm font-inter cursor-pointer">
            <div>Date Submitted</div>
            <Icon name="Sort" />
          </div>
        )}
      </div>
      ),
      accessorKey: 'createdAt',
      // header: () => (
      //   <div className='text-sm flex justify-center w-full pl-6'>
      //     <span>Client </span>
      //   </div>
      // ),
      cell: (props: any) => (
        <div className="flex justify-center w-full px-4">
          <span>{props.getValue().split('T')[0]}</span>
        </div>
      ),
    },
    {
      id: 'Action',
      header: 'Action',
      accessorKey: 'changeDetails',
      cell: (props: any) => (
        <div className="flex space-x-3 items-center w-full justify-center">
          <div
            className="cursor-pointer"
            onClick={() => {
              sendRequest(props.row.original);
            }}
          >
            <Icon name="edit" />
          </div>
          {/*}
          <FaRedo
            title="Resend"
            onClick={() => {reSendRequest(props.row.original);}}
            className="cursor-pointer"
            color="#0000E7"
            size={18}
          />
          */}
        </div>
      ),
    },
  ];

  return (
    <div className="flex flex-col dark:bg-[#1D1D1D]">
      <PageHeader
        title=" "
        subtitle="Enhance efficiency by quickly approving or declining change requests to maintain smooth operational flow and meet overall objectives."
        hideDate
        hideDomain
      />
      <div className="bg-white">
        <div className="w-full bg-white p-2 dark:bg-gray-800">
          <div className={`block ${isReportPage ? 'md:flex' : ''}  justify-between  bg-white px-4 text-mini dark:bg-dark-tremor-background rounded-lg mb-4`}>
            <ChangeCounter 
              dataTitle={'Current Pending'}
              loading={loading} 
              stats={reportData}
              initState={true}
            />
          </div>
        </div>
      </div>
      <div className="p-6">
        <div className="w-full bg-white p-2 dark:bg-gray-800">
          <div className="relative w-[300px]">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <Icon
                name="SearchRefractionIcon"
                color={mode === 'dark' ? '#fff' : iconColor}
              />
            </div>
            <span className="change-search">
              <SearchBar
                value={searchText}
                onChange={setSearchText}
                onSearch={handleSearch}
                placeholder="Search changes by domain"
              />
            </span>
          </div>
        </div>
        <TanStackTable
          data={!changeRequests.data ? [] : changeRequests.data}
          columns={columns}
          totalPages={!changeRequests.total_pages ? 0 : changeRequests.total_pages}
          page={page}
          setPage={setPage}
          loading={loading}
          sortData={handleSortTable}
          setSelectedRow={setSelectedRow}
          totalDocs={changeRequests?.total_docs}
        />
      </div>
      <CustomModal open={openModal} onClose={() => setOpenModal(false)}>
        <ChangeRequestModal
          onClose={() => setOpenModal(false)}
          change={selectedAccount}
          setConfirmationModalDecline={setConfirmationModalDecline}
          setConfirmationModalApprove={setConfirmationModalApprove}
        />
      </CustomModal>
      <CustomModal
        open={confirmationModalDecline}
        onClose={() => setConfirmationModalDecline(false)}
      >
      <ConfirmationModal
          change={selectedAccount}
          loading={loading}
          onClose={() => setConfirmationModalDecline(false)}
          type="decline"
          icon="Trash01"
        />
      </CustomModal>
      <CustomModal
        open={confirmationModalApprove}
        onClose={() => setConfirmationModalApprove(false)}
      >
      <ConfirmationModal
          change={selectedAccount}
          loading={loading}
          onClose={() => setConfirmationModalApprove(false)}
          type="approve"
          icon={'CheckCircle'}
        />
      </CustomModal>
    </div>
  );
}
