import { Card, ScatterChart } from '@tremor/react';
// import DonutBarGroup from '../DonutCardChart';
// import SparkAreaChartCard from '../SparkAreaChartCard';
import InfoTooltip from '../AnalyticsInfoTooltip';
import Icon from '../../../assets/icons/SvgComponent';
import useMetaStore from '../../../store/useMetaStore';
import { formatToCurrency, formatNumber } from '../../../utils/common';
import useClientStore from '../../../store/useClientStore';
import { useRetryFetchFbCampaign } from '../Hooks';
import NoDataErrorRetry from '../../NoDataRetry';

type CustomTooltipTypeScatter = {
  payload: any;
  active: boolean | undefined;
  label: any;
  hasPercent?: boolean;
};

const AcquisitionDetails = () => {
  const { campaignInsights } = useMetaStore((state) => state);
  const { selectedClient } = useClientStore((state) => state);

  const clientCategory = selectedClient?.category_type || '';
  const isLeadGen = clientCategory === 'Lead Gen';

  const scatterChartData = campaignInsights?.overview?.map((data: any) => {
    return {
      name: data.campaign_name,
      revenue: Number(data.revenue) || 0,
      roas: Number(data.roas) || 0,
      spend: Number(data.spend) || 0,
    };
  });

  const isLeadScatterChartData = campaignInsights?.overview?.map(
    (data: any) => {
      return {
        name: data.campaign_name,
        'Websites Leads and Form Leads': Number(data.leads) || 0,
        'CPL Websites Leads and Form Leads': Number(data.cpl) || 0,
      };
    }
  );

  const customTooltip = (props: CustomTooltipTypeScatter) => {
    const { payload, active, label, hasPercent } = props;
    if (!active || !payload) return null;
    return (
      <div className="w-fit rounded-tremor-default border border-tremor-border bg-tremor-background p-2 text-tremor-default shadow-tremor-dropdown">
        <div className="flex flex-1 space-x-2.5">
          <div
            className={`flex w-1.5 flex-col bg-${payload[0]?.color}-500 rounded`}
          />
          <div className="w-full">
            <p className="mb-2 font-medium text-tremor-content-emphasis">
              {label}
            </p>
            {payload.map((payloadItem: any, index: number) => (
              <div
                key={index}
                className="flex items-center justify-between space-x-6"
              >
                <span className="text-tremor-content">{payloadItem.name}</span>
                <span className="font-medium tabular-nums text-tremor-content-emphasis">
                  {payloadItem.name === 'spend' ||
                  payloadItem.name === 'revenue' ||
                  payloadItem.name === 'CPL Websites Leads and Form Leads' ? (
                    <span className="font-medium tabular-nums text-tremor-content-emphasis">
                      {hasPercent && payloadItem.name === 'roas'
                        ? `${formatNumber(payloadItem.value, true)}%`
                        : isNaN(payloadItem.value)
                          ? 0
                          : formatToCurrency(payloadItem.value)}
                    </span>
                  ) : (
                    <span className="font-medium tabular-nums text-tremor-content-emphasis">
                      {isNaN(payloadItem.value)
                        ? 0
                        : formatNumber(payloadItem.value)}
                    </span>
                  )}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const retryFetchFbCampaign = useRetryFetchFbCampaign();

  return (
    <div>
      <div className="flex justify-start items-center mb-4">
        <h2 className="title-text text-base sm:text-[1.85rem] pb-1 hidden sm:block">
          Acquisition Details
        </h2>
      </div>
      {/* <Grid numItems={2} className="gap-4">
        <Col className="h-full">
          <DonutBarGroup
            trendTxt={false}
            title="Ad Spend Distribution"
            customClass="h-[312px]"
          />
        </Col>
        <Col>
          <SparkAreaChartCard name="Ad Spend" />
          <Card className="mt-4">
            <div className="flex gap-2 items-center mb-1 w-full">
              <h2 className="text-[14px] text-[#001C44] font-semibold">
                Revenue by Campaign
              </h2>
              <InfoTooltip content={`This is a test`} position="top">
                <Icon name="InfoCircle" size={14} />
              </InfoTooltip>
            </div>
            <div className="px-2">
              <div className="text-xs flex justify-between items-center text-[#808DA1] py-2 border-b border-dashed">
                <div>Campaign Name</div>
                <div>Ad Spend</div>
              </div>
              {adSpendCampaigns.map((data, index) => {
                return (
                  <div
                    className="text-xs flex justify-between items-center text-[#808DA1] py-2"
                    key={index}
                  >
                    <div>{data.campaignName}</div>
                    <div>${data.adSpend}</div>
                  </div>
                );
              })}
            </div>
          </Card>
        </Col>
        <Col className="h-full">
          <DonutBarGroup
            trendTxt={false}
            title="Revenue Distribution"
            customClass="h-[312px]"
          />
        </Col>
        <Col>
          <SparkAreaChartCard name="Total Revenue" />
          <Card className="mt-4">
            <div className="flex gap-2 items-center mb-1 w-full">
              <h2 className="text-[14px] text-[#001C44] font-semibold">
                Revenue by Campaign
              </h2>
              <InfoTooltip content={`This is a test`} position="top">
                <Icon name="InfoCircle" size={14} />
              </InfoTooltip>
            </div>
            <div className="px-2">
              <div className="text-xs flex justify-between items-center text-[#808DA1] py-2 border-b border-dashed">
                <div>Campaign Name</div>
                <div>Ad Spend</div>
              </div>
              {adSpendCampaigns.map((data, index) => {
                return (
                  <div
                    className="text-xs flex justify-between items-center text-[#808DA1] py-2"
                    key={index}
                  >
                    <div>{data.campaignName}</div>
                    <div>${data.adSpend}</div>
                  </div>
                );
              })}
            </div>
          </Card>
        </Col>
      </Grid> */}
      {isLeadGen ? (
        <Card className="mt-4 px-3 sm:px-6 shadow-none border-none lg:shadow ring-0 lg:ring-1">
          <div className="flex gap-2 items-center mb-1 w-full">
            <h2 className="text-[14px] text-[#001C44] font-semibold dark:text-white">
              Total Leads vs CPL grouped by Campaign
            </h2>
            <InfoTooltip
              content={`Total Leads vs CPL grouped by Campaign`}
              position="top"
            >
              <Icon name="InfoCircle" size={14} />
            </InfoTooltip>
          </div>
          {campaignInsights.loading ? (
            <Card className="h-80 flex flex-col justify-between mt-4 animate-pulse shadow">
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
            </Card>
          ) : (
            <>
              {scatterChartData?.length === 0 || !scatterChartData ? (
                <Card className="mt-8 shadow-none border-none lg:shadow ring-0 lg:ring-1">
                  <div className="flex flex-col items-center justify-center w-full items-center my-8 h-64">
                    <div className="w-fit">
                      <NoDataErrorRetry 
                        error={campaignInsights.error}
                        retry={campaignInsights.error && campaignInsights.error !== '' }
                        handleRetry={retryFetchFbCampaign}
                        />
                    </div>
                  </div>
                </Card>
              ) : (
                <ScatterChart
                  className="-ml-2 mt-6 h-80"
                  yAxisWidth={30}
                  data={isLeadScatterChartData}
                  showTooltip={true}
                  category="name"
                  x="Websites Leads and Form Leads"
                  y="CPL Websites Leads and Form Leads"
                  showLegend={false}
                  customTooltip={customTooltip}
                />
              )}
            </>
          )}
        </Card>
      ) : (
        <Card className="mt-4 px-3 sm:px-6 shadow-none lg:shadow ring-0 lg:ring-1">
          <div className="flex justify-between">
            <div className="flex gap-2 items-center mb-1 w-full">
              <h2 className="text-[14px] text-[#001C44] font-semibold dark:text-white">
                Revenue Distribution
              </h2>
              <InfoTooltip content={`Revenue Distribution`} position="top">
                <Icon name="InfoCircle" size={14} />
              </InfoTooltip>
            </div>
            <Icon name="meta" size={25} />
          </div>
          {campaignInsights.loading ? (
            <Card className="h-80 flex flex-col justify-between mt-4 animate-pulse shadow">
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
            </Card>
          ) : (
            <>
              {scatterChartData?.length === 0 || !scatterChartData ? (
                <Card className="sm:mt-8 px-0 shadow-none lg:shadow ring-0 lg:ring-1">
                  <div className="flex flex-col items-center justify-center w-full items-center my-8 h-64">
                    <div className="w-fit">
                      <NoDataErrorRetry 
                        error={campaignInsights.error}
                        retry={campaignInsights.error && campaignInsights.error !== '' }
                        handleRetry={retryFetchFbCampaign}
                        />
                    </div>
                  </div>
                </Card>
              ) : (
                <ScatterChart
                  className="-ml-2 mt-6 h-80"
                  yAxisWidth={50}
                  data={scatterChartData}
                  showTooltip={true}
                  category="name"
                  x="revenue"
                  y="roas"
                  size="spend"
                  showLegend={false}
                  customTooltip={(props: any) =>
                    customTooltip({ ...props, hasPercent: true })
                  }
                />
              )}
            </>
          )}
        </Card>
      )}
    </div>
  );
};

export default AcquisitionDetails;
