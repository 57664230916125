import React from 'react';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../components/Dashboard/PageHeader';
import useAuthStore from '../../store/useAuthStore';
import { useEffect, useState } from 'react';
import useClientStore from '../../store/useClientStore';
import ComingSoon from '../../components/ComingSoon';

const Chat: React.FC = () => {
  const navigate = useNavigate();
  const { fetchClient } = useClientStore((state) => state);
  const { currentUser } = useAuthStore((state) => state);
  const [isMounted, setIsMounted] = useState(false);
  const isAdminLogin = currentUser?.role.level === 'omg';
  const [domain, setDomain] = useState({
    label: currentUser?.clients[0]?.name,
    id: currentUser?.clients[0]?.id,
  });

  // useEffect(() => {
  //   if (isAdminLogin) {
  //     navigate("/");
  //   }
  //   const item = localStorage.getItem("domain");
  //   const selectedDomain = item ? JSON.parse(item) : null;
  //   const clientIds = currentUser?.clients?.map((item: any) => item.id);
  //   if (clientIds?.includes(selectedDomain?.domain?.id)) {
  //     setDomain(selectedDomain.domain);
  //     fetchClient(selectedDomain?.domain?.id);
  //   } else {
  //     fetchClient(domain?.id);
  //   }
  //   setIsMounted(true);
  // }, []);

  // useEffect(() => {
  //   if (isMounted) {
  //     fetchClient(domain?.id);
  //   }
  // }, [domain]);

  return (
    <div className="flex flex-col m-5">
      {/* <PageHeader
      subtitle="Goals"
      domains={currentUser.clients}
      hideDate
    /> */}
      <ComingSoon />
    </div>
  );
};

export default Chat;
