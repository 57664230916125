import { Card, Grid, Col } from '@tremor/react';
import use3rdPartyStore from '../../../store/use3rdPartyStore';
import {
  formatNumberWithCommas,
  formatToCurrency,
  formatNumber,
  processData,
  processData2,
} from '../../../utils/common';
import moment from 'moment';
import useClientStore from '../../../store/useClientStore';
import useGoogleStore from '../../../store/useGoogleStore';
import AreaCardChart from '../AreaCardChart';
import { isLedGenClient } from '../../../utils/client';
import LineChartCard from '../LineChartCard';
import MobileView from './mobileView';
import {
  useRetryFetchGA4Analytics,
  useRetryFetchGS4Analytics,
  useRetryFetchTopKeywords,
} from '../Hooks';

interface IProps {
  sliceData?: boolean;
}

const OverallVisibility = ({ sliceData }: IProps) => {
  const { ga4SeoData, googleSearchConsole } = useGoogleStore((state) => state);

  const { topKeywords } = use3rdPartyStore((state) => state);
  const { selectedClient } = useClientStore((state) => state);
  const propertyId = selectedClient?.google_analytics?.ga4?.property_id;
  const isLeadGen = isLedGenClient(selectedClient);
  const isTopKeywordsLoading = topKeywords?.loading || selectedClient?.loading;
  const topKeywordsData = topKeywords?.data?.map((item) => {
    return {
      date: moment(item.date).format('MMM YY'),
      'Top 3': item.top3,
      '4-10': item.top4_10,
      '11-Plus': item.top11_plus,
    };
  });

  const clicks = processData(googleSearchConsole, 'clicks');
  const organic_sessions = processData(ga4SeoData, 'organic_sessions');
  const organic_totalRevenue = processData(
    ga4SeoData,
    'organic_totalRevenue',
    'Organic Revenue'
  );
  const total_revenue = processData(ga4SeoData, 'totalRevenue');
  const organic_conversions_breakdown = processData2({
    dates: ga4SeoData?.organic_conversions_breakdown?.dates,
    values: ga4SeoData?.organic_conversions_breakdown?.values,
    totalKeyName: 'Total',
  });
  const lastMonthOrganicRevenue =
    Array.isArray(organic_totalRevenue) && organic_totalRevenue.length > 0
      ? organic_totalRevenue[organic_totalRevenue.length - 1]?.value
      : undefined;

  const lastMonthClicks =
    Array.isArray(clicks) && clicks.length > 0
      ? clicks[clicks.length - 1]?.value
      : undefined;

  const lastMonthTotalOrganicConversionsBreakdown =
    Array.isArray(organic_conversions_breakdown) &&
    organic_conversions_breakdown.length >= 2
      ? organic_conversions_breakdown[
          organic_conversions_breakdown.length - 1
        ]?.['Total']
      : undefined;

  const lastMonthSessions =
    Array.isArray(organic_sessions) && organic_sessions.length > 0
      ? organic_sessions[organic_sessions.length - 1]?.value
      : undefined;

  const lastMonthTotalRevenue =
    Array.isArray(total_revenue) && total_revenue.length > 0
      ? total_revenue[total_revenue.length - 1]?.value
      : undefined;

  const retryFetchGA4Analytics = useRetryFetchGA4Analytics();
  const retryFetchGAConsole = useRetryFetchGS4Analytics();
  const retryFetchTopKeywords = useRetryFetchTopKeywords();

  return (
    <div>
      <div className="flex justify-start items-center mb-4">
        <h2 className="title-text text-base sm:text-[1.85rem] pb-1 hidden sm:block">
          Key Performance Indicator
        </h2>
      </div>
      <MobileView
        retry={retryFetchGA4Analytics}
        retryGS={retryFetchGAConsole}
      />

      <div className="flex flex-col gap-4 hidden sm:block">
        <Grid
          numItems={1}
          numItemsSm={1}
          numItemsMd={2}
          numItemsLg={isLeadGen ? 3 : 4}
          className="gap-4"
        >
          <Col>
            <AreaCardChart
              title={
                ga4SeoData?.organic_sessions?.label
                  ? ga4SeoData?.organic_sessions?.label
                  : 'Organic Sessions'
              }
              chatData={organic_sessions}
              category={['Organic Sessions']}
              info={
                ga4SeoData?.organic_sessions?.info
                  ? ga4SeoData?.organic_sessions?.info
                  : 'Organic Sessions'
              }
              prevPeriod={ga4SeoData?.organic_sessions?.previous_period}
              previousYear={ga4SeoData?.organic_sessions?.previous_year}
              isLoading={ga4SeoData?.loading || selectedClient?.loading}
              totalValue={formatNumberWithCommas(lastMonthSessions)}
              error={ga4SeoData.error}
              icon="googleAnalytics"
              propertyId={`Property ID: ${propertyId}`}
              retryMethod={retryFetchGA4Analytics}
            />
          </Col>
          <Col>
            <AreaCardChart
              title={
                googleSearchConsole?.clicks?.label
                  ? googleSearchConsole?.clicks?.label
                  : 'Clicks'
              }
              chatData={clicks}
              category={['Clicks']}
              info={
                googleSearchConsole?.clicks?.info
                  ? googleSearchConsole?.clicks?.info
                  : 'Clicks'
              }
              prevPeriod={googleSearchConsole?.clicks?.previous_period}
              previousYear={googleSearchConsole?.clicks?.previous_year}
              isLoading={googleSearchConsole.loading || selectedClient?.loading}
              totalValue={formatNumberWithCommas(lastMonthClicks)}
              error={googleSearchConsole.error}
              icon="googleSearch"
              propertyId={selectedClient?.google_search_console?.site_url}
              retryMethod={retryFetchGAConsole}
            />
          </Col>
          <Col>
            <AreaCardChart
              title={
                ga4SeoData?.organic_conversions_breakdown?.label
                  ? ga4SeoData?.organic_conversions_breakdown?.label
                  : 'Organic Conversions'
              }
              chatData={organic_conversions_breakdown}
              category={['Total']}
              info={
                ga4SeoData?.organic_conversions_breakdown?.info
                  ? ga4SeoData?.organic_conversions_breakdown?.info
                  : 'Organic Conversions'
              }
              prevPeriod={
                ga4SeoData?.organic_conversions_breakdown?.previous_period
              }
              previousYear={
                ga4SeoData?.organic_conversions_breakdown?.previous_year
              }
              isLoading={ga4SeoData.loading}
              totalValue={formatNumber(
                lastMonthTotalOrganicConversionsBreakdown
              )}
              error={ga4SeoData.error}
              customDataTooltip={true}
              icon="googleAnalytics"
              propertyId={`Property ID: ${propertyId}`}
              retryMethod={retryFetchGA4Analytics}
            />
          </Col>
          {!isLeadGen && (
            <Col>
              <AreaCardChart
                title={
                  ga4SeoData?.organic_totalRevenue?.label
                    ? ga4SeoData?.organic_totalRevenue?.label
                    : 'Organic Revenue'
                }
                chatData={organic_totalRevenue}
                category={['Organic Revenue']}
                info={
                  ga4SeoData?.organic_totalRevenue?.info
                    ? ga4SeoData?.organic_totalRevenue?.info
                    : 'Organic Total Revenue'
                }
                prevPeriod={ga4SeoData?.organic_totalRevenue?.previous_period}
                previousYear={ga4SeoData?.organic_totalRevenue?.previous_year}
                isLoading={ga4SeoData.loading}
                totalValue={formatToCurrency(lastMonthOrganicRevenue)}
                error={ga4SeoData.error}
                hasDollar
                icon="googleAnalytics"
                propertyId={`Property ID: ${propertyId}`}
                retryMethod={retryFetchGA4Analytics}
              />
            </Col>
          )}
        </Grid>
        <Grid
          numItems={1}
          numItemsSm={1}
          numItemsMd={1}
          numItemsLg={4}
          className="gap-4 mt-4"
        >
          {!isLeadGen && (
            <Col>
              <AreaCardChart
                title={
                  ga4SeoData?.totalRevenue?.label
                    ? ga4SeoData?.totalRevenue?.label
                    : 'Total Revenue'
                }
                chatData={total_revenue}
                category={['Total Revenue']}
                info={
                  ga4SeoData?.totalRevenue?.info
                    ? ga4SeoData?.totalRevenue?.info
                    : 'Total Revenue'
                }
                prevPeriod={ga4SeoData?.totalRevenue?.previous_period}
                previousYear={ga4SeoData?.totalRevenue?.previous_year}
                isLoading={ga4SeoData.loading}
                totalValue={formatToCurrency(lastMonthTotalRevenue)}
                error={ga4SeoData.error}
                hasDollar
                icon="googleAnalytics"
                propertyId={`Property ID: ${propertyId}`}
                retryMethod={retryFetchGA4Analytics}
              />
            </Col>
          )}
          <Col numColSpanLg={isLeadGen ? 4 : 3}>
            <Card>
              <LineChartCard
                data={topKeywordsData ? topKeywordsData : []}
                categories={['Top 3', '4-10', '11-Plus']}
                name={
                  topKeywords?.label
                    ? topKeywords?.label
                    : 'Organic Keywords Trend'
                }
                loading={isTopKeywordsLoading}
                info={
                  topKeywords.info ? topKeywords.info : 'Organic Keywords Trend'
                }
                color={['#FFC265', '#7DCEFF', '#019AA3', '#00199D', '#002943']}
                error={topKeywords.error}
                icon="ahref"
                propertyId={selectedClient?.domain}
                retryMethod={retryFetchTopKeywords}
              />
            </Card>
          </Col>
        </Grid>
      </div>
    </div>
  );
};

export default OverallVisibility;
