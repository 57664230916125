import React from 'react';
import useUtilityStore from '../../../store/useUtilityStore';
import DateRangeComp from '../../Calendar';
import useAuthStore from '../../../store/useAuthStore';
import { useLocation, Link } from 'react-router-dom';
import useClientStore from '../../../store/useClientStore';
import ClientSearchBar from '../../ClinetsSearchBar';
import ReactSelect from '../../ReactSelect';
import Icon from '../../../assets/icons/SvgComponent';
import ToolTipV2 from '../../TooltipV2';
import Tooltip from '../../HelpTooltip';
import DomainDropdown from '../../DomainDropdown';
import Tabs from './tabs';
interface PageHeaderProps {
  title?: string;
  subtitle?: string;
  hideDate?: boolean;
  strategy?: boolean;
  customClass?: string;
  type?: string;
  handleScroll: (id: string) => void;
  handleResetCache?: () => void;
  activeTab: {
    index: string;
    id: string;
    label: string;
  };
  setActiveTab: (selectedOption: any) => void;
}

interface UtilityStoreState {
  campaignReport: {
    label: string;
    id: number;
  };
  setCampaignReport: (selectedOption: any) => void;
  isHeaderHidden: boolean;
}

interface Domain {
  id: string;
  name: string;
}

const InternalPageHeader: React.FC<PageHeaderProps> = ({
  title,
  handleResetCache,
  handleScroll,
  activeTab,
  setActiveTab,
  customClass,
  type,
}) => {
  const { campaignReport, setCampaignReport, isHeaderHidden } =
    useUtilityStore() as UtilityStoreState;
  const location = useLocation();
  const { selectedClient } = useClientStore((state) => state);
  const clientCategory = selectedClient?.category_type || '';
  // Define a type for each object in the data array
  const { selectedDomain, setDomain, user, loginAsUser } = useAuthStore(
    (state) => state
  );
  const isInternalAnalytics = location.pathname.includes('/client/analytics');
  const isAdminLogin = user.role.level === 'omg';
  const currentUser = isAdminLogin ? loginAsUser : user;
  const transformedData = currentUser?.clients?.map((item: Domain) => ({
    label: item.name,
    id: item.id,
  }));
  const clientName = selectedDomain.label || selectedClient?.name;
  const defaultTitle = clientName;
  // const defaultTitle = clientName + ' | ' + clientCategory;

  return (
    <>
      <div
        id="overview"
        className={`relative block lg:flex items-start justify-between  bg-[#D2EEFF] px-3 py-1 sm:p-3  sm:pt-4 ${customClass} font-inter dark:bg-[#0C111D] transition-opacity duration-500 ease-in-out ${isHeaderHidden ? 'opacity-0 sm:opacity-100' : 'opacity-100'}`}
      >
        <div className="flex gap-2 items-center sm:block w-full md:w-[16vw] lg:w-[30vw] my-1 sm:my-0">
          <div className="flex justify-between items-center sm:justify-start sm:gap-2 text-[26px] sm:text-3xl  font-[800] text-[#001C44] dark:text-white">
            <div>
              <Tooltip content={title || defaultTitle} position="right">
                <div className="max-w-[80vw] sm:max-w-[65vw] md:max-w-[15vw] lg:max-w-[35vw] truncate cursor-pointer  text-[24px]">
                  {title || defaultTitle}
                </div>
              </Tooltip>
            </div>
            {isInternalAnalytics && (
              <>
                <div className="hidden sm:block">
                  <Link
                    to={`/client/details/integrations/${selectedClient?.id}`}
                    target="_blank"
                  >
                    <ToolTipV2 tooltip="Edit Client">
                      <Icon name="Edit" size={18} color="#0029FF" />
                    </ToolTipV2>
                  </Link>
                </div>
              </>
            )}
          </div>

          <div className="flex gap-2">
            {selectedClient?.loading && (
              <div className="bg-[#B2BBC740] w-[120px] h-[24px]"></div>
            )}
            {clientCategory && (
              <div className="uppercase text-xs font-normal bg-[#B2BBC740] py-1 px-2 rounded-md w-fit h-fit sm:h-full">
                {clientCategory}
              </div>
            )}
            {selectedClient?.cycle &&
              !location.pathname.includes('/client/analytics/paid-search') &&
              !location.pathname.includes('/client/analytics/fb-ads') && (
                <div className=" text-xs text-white rounded-md py-1 px-2 bg-[#009EFF] w-fit h-fit sm:h-full">
                  {selectedClient?.cycle}
                </div>
              )}
          </div>

          {/* <div className="flex flex-wrap">
          <span className="text-[#636466] text-base dark:text-[#ADB0B2]">
            {subtitle}
          </span>
          <Link
            to={`/client/details/integrations/${selectedClient?.id}`}
            className="text-[#636466] text-[10px] w-fit font-inter dark:text-[#ADB0B2] border-2 p-1 ml-1 rounded-lg border-[#005C9F] hover:bg-[#005C9F] hover:text-white dark:border-[#005C9F] dark:hover:bg-[#005C9F] dark:hover:text-white"
            target="_blank"
          >
            Edit Integration
          </Link>
          </div> */}
        </div>
        <div className="w-full sm:w-2/3">
          <div
            className={`relative flex  flex-wrap lg:flex-nowrap  gap-2  sm:gap-0 lg:gap-3 w-full sm:justify-end mb-1 sm:mb-4`}
          >
            {type !== 'client' ? (
              <div className="relative">
                <ClientSearchBar />
              </div>
            ) : (
              <>
                {transformedData && transformedData.length > 1 ? (
                  <div>
                    <DomainDropdown
                      customStyle={{
                        width: '100%',
                        minWidth: '140px',
                      }}
                      labelKey="label"
                      valueKey="id"
                      onSelect={(domain) => setDomain(domain)}
                      defaultValue={selectedDomain.label}
                      placeholder="Select Domain"
                      options={transformedData || []}
                    />
                  </div>
                ) : null}
              </>
            )}

            <div
              onClick={handleResetCache}
              className="min-w-[120px] flex sm:hidden font-inter bg-[#0029FF] text-sm  items-center justify-center gap-1 rounded-lg py-1.5 px-2 text-cyan-50 cursor-pointer"
            >
              <Icon name="Reset" size={16} color="#FFF" />
              Reset Cache
            </div>
            {/* <div className='relative'>
            <Select
              options={analyticsServices}
              value={selectedAnalyticsService}
              onChange={handleAnalyticsServices}
              placeholder={selectServiceValue ? selectServiceValue.label : 'Services'}
              isSearchable={false}
              defaultValue={selectedAnalyticsService}
            />
          </div> */}
            {location.pathname.includes('paid-search') && (
              <div className="relative hidden sm:block">
                <div className="flex w-full justify-end">
                  <div className="w-[200px]">
                    <ReactSelect
                      options={[
                        {
                          label: 'Google Ads (Overall)',
                          value: 0,
                          id: 'overall',
                        },
                        {
                          label: 'Google Ads (Search)',
                          value: 2,
                          id: 'search',
                        },
                        {
                          label: 'Google Ads (Performance Max)',
                          value: 10,
                          id: 'performance_max',
                        },
                        {
                          label: 'Google Ads (Shopping)',
                          value: 4,
                          id: 'shopping',
                        },
                        {
                          label: 'Google Ads (Video)',
                          value: 6,
                          id: 'video',
                        },
                      ]}
                      value={campaignReport}
                      placeholder="All Roles"
                      handleOnChange={(selectedOption: any) => {
                        setCampaignReport(selectedOption);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            <div
              className={`${transformedData && transformedData.length > 1 ? '' : 'flex gap-[4px] sm:block'}`}
            >
              <DateRangeComp
                disabled={false}
                style={{
                  position: 'initial',
                  width: '100%',
                  minWidth: '120px',
                }}
              />
              {transformedData &&
                transformedData.length === 1 &&
                location.pathname.includes('paid-search') && (
                  <div className="block sm:hidden">
                    <div className="flex w-full justify-end">
                      <div className="w-full">
                        <ReactSelect
                          options={[
                            {
                              label: 'Google Ads (Overall)',
                              value: 0,
                              id: 'overall',
                            },
                            {
                              label: 'Google Ads (Search)',
                              value: 2,
                              id: 'search',
                            },
                            {
                              label: 'Google Ads (Performance Max)',
                              value: 10,
                              id: 'performance_max',
                            },
                            {
                              label: 'Google Ads (Shopping)',
                              value: 4,
                              id: 'shopping',
                            },
                            {
                              label: 'Google Ads (Video)',
                              value: 6,
                              id: 'video',
                            },
                          ]}
                          value={campaignReport}
                          placeholder="All Roles"
                          handleOnChange={(selectedOption: any) => {
                            setCampaignReport(selectedOption);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
            </div>
            <div
              onClick={handleResetCache}
              className="min-w-[120px] hidden sm:flex font-inter bg-[#0029FF] text-sm  items-center justify-center gap-1 rounded-lg py-1.5 px-2 text-cyan-50 cursor-pointer"
            >
              <Icon name="Reset" size={16} color="#FFF" />
              Reset Cache
            </div>
          </div>
          {transformedData && transformedData.length > 1 && (
            <>
              {location.pathname.includes('paid-search') && (
                <div className="block sm:hidden">
                  <div className="flex w-full justify-end">
                    <div className="w-full">
                      <ReactSelect
                        options={[
                          {
                            label: 'Google Ads (Overall)',
                            value: 0,
                            id: 'overall',
                          },
                          {
                            label: 'Google Ads (Search)',
                            value: 2,
                            id: 'search',
                          },
                          {
                            label: 'Google Ads (Performance Max)',
                            value: 10,
                            id: 'performance_max',
                          },
                          {
                            label: 'Google Ads (Shopping)',
                            value: 4,
                            id: 'shopping',
                          },
                          {
                            label: 'Google Ads (Video)',
                            value: 6,
                            id: 'video',
                          },
                        ]}
                        value={campaignReport}
                        placeholder="All Roles"
                        handleOnChange={(selectedOption: any) => {
                          setCampaignReport(selectedOption);
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <Tabs
        handleScroll={handleScroll}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
    </>
  );
};

export default InternalPageHeader;
