import { requests } from "./ApiService";

export const HubSpot = {
  syncHubspotClients: (data) =>
    requests.get(
      `/api/v1/hubspot/sync?country=${data.country}&company_id=${data.company_id}`
    ),
  isSyncing: () => requests.get('/api/v1/hubspot/syncing'),
  bookMeeting: (data) =>
    requests.post(
      `/api/v1/hubspot/${data.clientId}/meetings?country=${data.country}`
    ),
  specialOffers: (data) =>
    requests.post(
      `/api/v1/hubspot/${data.clientId}/deal/special-offers?country=AU`,
      data
  ),
  getUpcomingMeeting: (data) =>
    requests.get(
      `/api/v1/hubspot/${data.clientId}/meetings/upcoming?country=${data.country}`
    ),
  create: (data) =>
    requests.post(
      `/api/v1/hubspot/${data.id}/meetings?country=${data.country}`,
      data
    ),
  getAvailableDates: (data) => {
    return requests.get(
      `/api/v1/hubspot/${data.clientId}/meetings/dates/${data.date}/?country=${data.country}`
    );
  },
  getAvailableTime: (data) => {
    return requests.get(
      `/api/v1/hubspot/${data.clientId}/meetings/dates/${data.date}/times/?country=${data.country}`
    );
  },
};