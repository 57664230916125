import { useEffect, useRef } from 'react';

interface IProps {
  toggle: (val: boolean) => void;
  children: React.ReactNode;
}

const OutsideAlerter = ({ toggle, children }: IProps) => {
  const wrapperRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    // eslint-disable-next-line
    function handleClickOutside(event: MouseEvent): void {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        toggle(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, toggle]);

  return <div ref={wrapperRef} className='bg-gray-200'>{children}</div>;
};

export default OutsideAlerter;
