const ProfileNotification: React.FC = () => {
  return (
    <div className="flex flex-col w-full h-full gap-8 p-8 bg-[#F9FAFB] dark:bg-[#191919]">
      <div className="flex flex-col gap-4 w-full p-10 rounded-xl bg-white dark:bg-[#262627] ">
        <div className="flex items-start gap-4 w-full">
          <div className="flex flex-col w-1/4 gap-1">
            <span className="text-sm font-semibold dark:text-white">
              Puplic profile
            </span>
            <span className="text-sm text-[#475467] font-normal w-[90%]">
              This will be display on your profile.
            </span>
          </div>
          <div className="w-full">
            <input
              type="text"
              placeholder="Gurulytics UI"
              value={''}
              onChange={(e) => console.log(e.target.value)}
              className="w-full rounded-lg p-2 border border-[#808EA2] dark:bg-[#191919] dark:text-white"
            />
            <div className="flex w-full mt-4">
              <span className="w-1/6 rounded-tl-lg rounded-bl-lg p-2 border border-r-0 border-[#808EA2] text-[#475467] dark:bg-[#191919] dark:text-white">
                omg.com.au/profile
              </span>
              <input
                type="text"
                placeholder="gurulytics"
                value={''}
                onChange={(e) => console.log(e.target.value)}
                className="w-full rounded-tr-lg rounded-br-lg p-2 border border-[#808EA2]  dark:bg-[#191919] dark:text-white "
              />
            </div>
          </div>
        </div>
        <div className="w-full h-[1px] bg-[#E6EBF0] my-2"></div>
        <div className="flex items-start gap-4 w-full">
          <div className="flex flex-col w-1/4 gap-1">
            <span className="text-sm font-semibold dark:text-white">
              Tagline
            </span>
            <span className="text-sm text-[#475467] font-normal w-[90%]">
              A quick snapshot of your company.
            </span>
          </div>
          <div className="flex flex-col items-start w-full gap-1">
            <textarea
              value={''}
              onChange={(e) => console.log(e.target.value)}
              className="w-full rounded-lg p-2 border border-[#808EA2] text-sm text-[#475467] dark:bg-[#191919] dark:text-white"
            >
              Gurulytics UI
            </textarea>
            <span className="text-xs text-[#475467] ">40 characters left.</span>
          </div>
        </div>
        <div className="w-full h-[1px] bg-[#E6EBF0] my-2"></div>
        <div className="flex items-start gap-4 w-full">
          <div className="flex flex-col w-1/4 ">
            <span className="text-sm font-semibold dark:text-white">
              Notification
            </span>
          </div>
          <div className="flex flex-col items-start w-full gap-2">
            <div className="flex items-baseline gap-2">
              <input type="checkbox" />
              <div className="flex flex-col gap-1">
                <span className="dark:text-white">Reports</span>
                <small className="text-[#475467] ">
                  Include my logo in summary reports
                </small>
              </div>
            </div>
            <div className="flex items-baseline gap-2">
              <input type="checkbox" />
              <div className="flex flex-col gap-1">
                <span className="dark:text-white">Emails</span>
                <small className="text-[#475467] ">
                  Include my logo in customer emails
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileNotification;
