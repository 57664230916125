import React from 'react';

interface ChevronDownProps {
  color?: string;
  size?: number;
}

const ChevronDown: React.FC<ChevronDownProps> = ({
  color = '#808DA1',
  size = 24,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color || '#808DA1'}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-chevron-down"
  >
    <polyline points="6 9 12 15 18 9"></polyline>
  </svg>
);

export default ChevronDown;
