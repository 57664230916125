import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useThemeStore } from '../../store/useThemeStore';
import GoogleAd from '../../assets/images/googleAd.png';
import GoogleSEO from '../../assets/images/googleSEO.png';
import BingSEO from '../../assets/images/bingSEO.png';
import BingAds from '../../assets/images/bingAds.png';
import MetaAds from '../../assets/images/facebookAds.png';
import TiktokAds from '../../assets/images/tiktokAds.png';
import LinkedInAds from '../../assets/images/linkedInAds.png';
import Pinterest from '../../assets/images/pinterest.png';
import Icon from '../../assets/icons/SvgComponent';

export default function AppTab() {
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const lastSegment = pathSegments[pathSegments.length - 1];
  const formattedTitle = lastSegment.replace(/%20/g, ' ');

  const { mode } = useThemeStore((state: any) => state);
  const iconColor = mode === 'dark' ? 'white' : '#001C44';

  const connectedApps = [
    {
      title: 'Google SEO',
      description:
        'Enhance website visibility on Google search results through optimization techniques.',
      icon: GoogleSEO,
      requested: true,
    },
    {
      title: 'Bing SEO',
      description:
        'Improve your websites ranking on Bings search engine results page.',
      icon: BingSEO,
      requested: true,
    },
    {
      title: 'Google Ads',
      description:
        'Create, manage, and track paid advertising campaigns on Googles platform.',
      icon: GoogleAd,
      requested: true,
    },
    {
      title: 'Bing Ads',
      description:
        'Develop and monitor targeted advertising campaigns on Bings network.',
      icon: BingAds,
      requested: false,
    },
    {
      title: 'Meta Ads',
      description:
        'Manage advertising campaigns effectively across Facebook and Instagram platforms',
      icon: MetaAds,
      requested: true,
    },
    {
      title: 'Tiktok Ads',
      description:
        'Launch and track advertising campaigns targeting TikToks youthful audience.',
      icon: TiktokAds,
      requested: false,
    },
    {
      title: 'LinkedIn Ads',
      description:
        'Develop targeted advertising campaigns for a professional audience on LinkedIn',
      icon: LinkedInAds,
      requested: false,
    },
    {
      title: 'Pinterest',
      description:
        'Create and analyze visual advertising campaigns on Pinterests platform.',
      icon: Pinterest,
      requested: true,
    },
  ];

  return (
    <div>
      <div className="flex justify-between bg-white p-6">
        <div>
          <h1 className="text-xl font-semibold mb-2 text-[#005C9F] dark:text-[#fff]">
            {formattedTitle}
          </h1>
          <p className="text-sm font-base text-gray-600 mb-2 dark:text-gray-300">
            http://3dwalkabout.com.au
          </p>
        </div>
        <div className="flex gap-2 items-center">
          <div>
            <input
              type="text"
              className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-4 p-2  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="eg. contact@client.com"
              required
            ></input>
          </div>
          <div>
            <button
              type="button"
              className=" flex gap-2 items-center py-2.5 px-5 text-sm font-medium text-white focus:outline-none bg-[#005C9F] rounded-lg border border-gray-200 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            >
              Send Access Request
              <Icon
                name="KeywordsRanking"
                color={mode === 'dark' ? '#fff' : '#fff'}
              />
            </button>
          </div>
        </div>
      </div>
      <div className="p-6">
        <div className="w-full bg-white p-[26px] dark:bg-gray-800">
          {connectedApps.map((app, index) => (
            <div
              className="flex justify-between items-center border-b border-gray-200 py-4"
              key={index}
            >
              <div className="flex gap-2 justify-between items-center">
                <div className="bg-gray-100 p-2 rounded-lg">
                  <img src={app.icon} alt="GoogleSEO" width={30} />
                </div>
                <div className="flex flex-col justify-center gap-1">
                  <h1 className="text-base font-semibold dark:text-white">
                    {app.title}
                  </h1>
                  <p className="text-xs font-base text-gray-600 dark:text-gray-400">
                    {app.description}
                  </p>
                </div>
              </div>
              <div className="flex gap-4 text-sm">
                Connect
                <label className="relative flex flex-row-reverse  items-center cursor-pointer">
                  <input type="checkbox" className="sr-only peer" />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
                </label>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="p-6  flex items-center justify-between ">
        <div className="flex flex-col  justify-center p-6 bg-white w-[48.5%]">
          <div className="flex flex-col justify-center items-center mb-4">
            <p className="text-center text-sm font-[600] mb-2">Website</p>
            <p className="text-center text-[#475467] text-xs w-[330px]">
              If you're using your own developers or Git Version Control, please
              use the options below this form.
            </p>
          </div>
          <div>
            <div className="mb-6 ">
              <label
                htmlFor="default-input"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                WEBSITE URL LOGIN
              </label>
              <input
                type="text"
                id="default-input"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
              <span className="text-xs text-[#7C7E7F]">
                This is a hint text to help user.
              </span>
            </div>
            <div className="mb-6 ">
              <label
                htmlFor="default-input"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                WEBSITE USERNAME
              </label>
              <input
                type="text"
                id="default-input"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
              <span className="text-xs text-[#7C7E7F]">
                This is a hint text to help user.
              </span>
            </div>
            <div className="mb-6 ">
              <label
                htmlFor="default-input"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                WEBSITE PASSWORD
              </label>
              <input
                type="text"
                id="default-input"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div className="pt-6">
              <div className="flex items-center mb-4  rounded-full">
                <input
                  type="checkbox"
                  value=""
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-full focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  I don’t wish to provide this access
                </label>
              </div>
              <div className="flex mb-4  ">
                <input
                  type="checkbox"
                  value=""
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-full focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  I don’t have this, I need help obtaining this
                </label>
              </div>
              <div className="flex ">
                <input
                  type="checkbox"
                  value=""
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-full focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  I use GITHUB/Version Control for this
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col  justify-center p-6 bg-white w-[48.5%]">
          <div className="flex flex-col justify-center items-center mb-4">
            <p className="text-center text-sm font-[600] mb-2">FTP / SERVER</p>
            <p className="text-center text-xs text-[#475467] w-[330px]">
              If you're providing Panel or an ISP login, the Port field is not
              necessary
            </p>
          </div>
          <div>
            <div className="mb-6 ">
              <label
                htmlFor="default-input"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                FTP HOST / SERVER URL
              </label>
              <input
                type="text"
                id="default-input"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
              <span className="text-xs text-[#7C7E7F]">
                This is a hint text to help user.
              </span>
            </div>
            <div className="mb-6 ">
              <label
                htmlFor="default-input"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                USERNAME
              </label>
              <input
                type="text"
                id="default-input"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
              <span className="text-xs text-[#7C7E7F]">
                This is a hint text to help user.
              </span>
            </div>
            <div className="mb-6 flex gap-4 w-full">
              <div className="w-full">
                <label
                  htmlFor="default-input"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  WEBSITE PASSWORD
                </label>
                <input
                  type="text"
                  id="default-input"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>

              <div className="w-full">
                <label
                  htmlFor="default-input"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  PORT (IF APPLICABLE)
                </label>
                <input
                  type="text"
                  id="default-input"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="pt-6 ">
              <div className="flex items-center mb-4  rounded-full">
                <input
                  type="checkbox"
                  value=""
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-full focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  I don’t wish to provide this access
                </label>
              </div>
              <div className="flex mb-4  ">
                <input
                  type="checkbox"
                  value=""
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-full focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  I don’t have this, I need help obtaining this
                </label>
              </div>
              <div className="flex ">
                <input
                  type="checkbox"
                  value=""
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-full focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  I use GITHUB/Version Control for this
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
