import React from 'react';

interface GitBranchProps {
  color?: string;
}

const GitBranch: React.FC<GitBranchProps> = ({ color = '#428B6E' }) => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="git-branch-01">
      <path
        id="Icon"
        d="M1.5 2V7.1C1.5 7.94008 1.5 8.36012 1.66349 8.68099C1.8073 8.96323 2.03677 9.1927 2.31901 9.33651C2.63988 9.5 3.05992 9.5 3.9 9.5H7.5M7.5 9.5C7.5 10.3284 8.17157 11 9 11C9.82843 11 10.5 10.3284 10.5 9.5C10.5 8.67157 9.82843 8 9 8C8.17157 8 7.5 8.67157 7.5 9.5ZM1.5 4.5L7.5 4.5M7.5 4.5C7.5 5.32843 8.17157 6 9 6C9.82843 6 10.5 5.32843 10.5 4.5C10.5 3.67157 9.82843 3 9 3C8.17157 3 7.5 3.67157 7.5 4.5Z"
        stroke="#334969"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default GitBranch;
