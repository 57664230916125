import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import Api from '../api';
import moment from 'moment';
const initialStatus = {
  loading: false,
  errorMsg: '',
  isSuccessful: true,
};
const initialStatus2 = {
  loading: true,
  errorMsg: '',
  isSuccessful: true,
};
const useMetaStore = create(
  devtools((set) => ({
    fbAdsAccounts: [],
    fbAdsInsights: [],
    accountInsights: initialStatus2,
    campaignInsights: initialStatus2,
    adInsights: initialStatus2,
    adsetInsights: initialStatus2,
    topPerformanceMeta: initialStatus2,
    demographics: initialStatus2,
    loadingFbInsights: false,
    fetchDemographics: async (data) => {
      try {
        set(() => ({ demographics: initialStatus2 }));
        const response = await Api.Meta.getDemographics(data);
        if (response[0]?.error) {
          const resData = {
            ...response,
            loading: false,
            error: response[0]?.error,
          };
          set(() => ({ demographics: resData }));
          throw 'An error occurred (Meta). Please attempt a different date or reach out to support for assistance.';
        } else {
          const resData = { ...response, loading: false };
          set(() => ({ demographics: resData }));
          return { status: true };
        }
      } catch (error) {
        throw error;
      }
    },
    fetchTopPerformanceMeta: async (data) => {
      try {
        set(() => ({ topPerformanceMeta: initialStatus2 }));
        const response = await Api.Meta.getTopPerformance(data);
        if (response[0]?.error) {
          const resData = {
            ...response,
            loading: false,
            error: response[0]?.error,
          };
          set(() => ({ topPerformanceMeta: resData }));
          throw 'An error occurred (Meta). Please attempt a different date or reach out to support for assistance.';
        } else {
          const resData = { ...response, loading: false };
          set(() => ({ topPerformanceMeta: resData }));
          return { status: true };
        }
      } catch (error) {
        throw error;
      }
    },
    fetchFbAdsAccounts: async (data) => {
      set(() => ({ loading: true, errorMessage: '' }));
      let response = await Api.Meta.getFbAdsAccounts(data);
      set(() => ({ fbAdsAccounts: response, loading: false }));
    },
    fetchAccountInsights: async (data) => {
      try {
        set(() => ({ accountInsights: initialStatus2 }));
        const response = await Api.Meta.getFbInsights({
          ...data,
          level: 'account',
          since: moment(data.since).subtract(1, 'year').format('YYYY-MM-DD'),
        });
        if (response[0]?.error) {
          const resData = {
            ...response,
            loading: false,
            error: response[0]?.error,
          };
          set(() => ({ accountInsights: resData }));
          throw (
            response[0]?.error ||
            'An error occurred. Please attempt a different date or reach out to support for assistance.'
          );
        } else {
          const resData = { ...response, loading: false };
          set(() => ({ accountInsights: resData }));
          return { status: true };
        }
      } catch (error) {
        throw error;
      }
    },
    fetchCampaignInsights: async (data) => {
      try {
        set(() => ({ campaignInsights: initialStatus2 }));
        const response = await Api.Meta.getFbInsights({
          ...data,
          level: 'campaign',
          since: moment(data.since).format('YYYY-MM-DD'),
        });
        if (response[0]?.error) {
          const resData = {
            ...response,
            loading: false,
            error: response[0]?.error,
          };
          set(() => ({ campaignInsights: resData }));
          throw (
            response[0]?.error ||
            'An error occurred. Please attempt a different date or reach out to support for assistance.'
          );
        } else {
          const resData = { ...response, loading: false };
          set(() => ({ campaignInsights: resData }));
          return { status: true };
        }
      } catch (error) {
        throw error;
      }
    },
    fetchAdInsights: async (data) => {
      try {
        set(() => ({ adInsights: initialStatus2 }));
        const response = await Api.Meta.getFbInsights({
          ...data,
          level: 'ad',
          since: moment(data.since).format('YYYY-MM-DD'),
        });
        if (response[0]?.error) {
          const resData = {
            ...response,
            loading: false,
            error: response[0]?.error,
          };
          set(() => ({ adInsights: resData }));
          throw (
            response[0]?.error ||
            'An error occurred. Please attempt a different date or reach out to support for assistance.'
          );
        } else {
          const resData = { ...response, loading: false };
          set(() => ({ adInsights: resData }));
          return { status: true };
        }
      } catch (error) {
        throw error;
      }
    },
    fetchAdsetInsights: async (data) => {
      try {
        set(() => ({ adsetInsights: initialStatus2 }));
        const response = await Api.Meta.getFbInsights({
          ...data,
          level: 'adset',
          since: moment(data.since).format('YYYY-MM-DD'),
        });
        if (response[0]?.error) {
          const resData = {
            ...response,
            loading: false,
            error: response[0]?.error,
          };
          set(() => ({ adsetInsights: resData }));
          throw (
            response[0]?.error ||
            'An error occurred. Please attempt a different date or reach out to support for assistance.'
          );
        } else {
          const resData = { ...response, loading: false };
          set(() => ({ adsetInsights: resData }));
          return { status: true };
        }
      } catch (error) {
        throw error;
      }
    },
    activateClientFbAcc: async (data) => {
      set(() => ({
        loading: true,
        errorMessage: '',
      }));
      // TODO handle errors
      await Api.Meta.activateClientAcc(data);
      set(() => ({
        loading: false,
        errorMessage: '',
      }));
    },
    resetMetaStore: (types) => {
      if (!Array.isArray(types)) {
        console.error('resetMetaStore Meta expects an array of types.');
        return;
      }
      set((state) =>
        types.reduce((acc, type) => {
          // Ensure the type exists in the state before trying to reset it
          if (state[type]) {
            acc[type] = { ...initialStatus }; // Spread to avoid mutation of initialStatus
          }
          return acc;
        }, {})
      );
    },
  }))
);

export default useMetaStore;
