import { requests } from "./ApiService";
import { toQueryString } from "../utils/common";


export const User = {
  getAllOMGUsers: () => requests.get(`/api/v1/omg-users`),
  getUsers: (filters) => requests.get(`/api/v1/users${toQueryString({
    search: filters.search,
    status: filters.status,
    page: filters.page,
    limit: filters.limit,
    category_type: filters.category_type,
    service: filters.service,
    integration: filters.integration,
    level: filters.level,
    role: filters.role,
    gurulytics_role:filters.role,
    client_id: filters.client_id,
  })}`),
  getUser: (id) => requests.get('/api/v1/user/' + id),
  create: (data) => requests.post('/api/v1/user', data),
  update: (id, data) => requests.patch(`/api/v1/user/${id}`, data),
  updateStatus: (data) => requests.patch('/api/v1/users/status', data),
  delete: (data) => requests.delete('/api/v1/users', data),
};