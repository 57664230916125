import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { processData, formatNumberWithCommas } from '../../../utils/common';
import AreaCardChart from '../AreaCardChart';
import use3rdPartyStore from '../../../store/use3rdPartyStore';
import useGoogleStore from '../../../store/useGoogleStore';

const MobileView = () => {
  const { googleSearchConsole } = useGoogleStore((state) => state);
  const { refdomains, domainRatingHistory, urlRating } = use3rdPartyStore(
    (state) => state
  );
  const ahrefsData = {
    refdomains: refdomains?.refdomains || {},
    domain_rating: domainRatingHistory?.data?.domain_rating || {},
    urlRating: urlRating?.data?.url_rating,
  };

  const domainReferring = processData(
    ahrefsData,
    'refdomains',
    'Referring Domains'
  );
  const domainRating = processData(ahrefsData, 'domain_rating');
  const urlRatingData = processData(ahrefsData, 'urlRating', 'URL Rating');

  const lastMonthDomainReferring =
    Array.isArray(domainReferring) && domainReferring.length > 0
      ? domainReferring[domainReferring.length - 1]?.value
      : undefined;

  const lastMonthDomainRating =
    Array.isArray(domainRating) && domainRating.length > 0
      ? domainRating[domainRating.length - 1]?.value
      : undefined;
  const lastMonthurlRating =
    Array.isArray(urlRatingData) && urlRatingData.length > 0
      ? urlRatingData[urlRatingData.length - 1]?.value
      : undefined;
  return (
    <div className="block sm:hidden my-4">
      <div className="flex flex-col gap-4">
        <div className="p-0">
          <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
            <SwiperSlide>
              <AreaCardChart
                title={'Referring Domains'}
                chatData={domainReferring}
                category={['Referring Domains']}
                info={
                  ahrefsData?.refdomains?.info
                    ? ahrefsData?.refdomains?.info
                    : 'Referring Domains'
                }
                prevPeriod={ahrefsData?.refdomains?.previous_period}
                previousYear={ahrefsData?.refdomains?.previous_year}
                isLoading={googleSearchConsole.loading}
                totalValue={formatNumberWithCommas(lastMonthDomainReferring)}
                error={googleSearchConsole.error}
                icon="ahref"
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title={'Domain Rating'}
                chatData={domainRating}
                category={['Domain Rating']}
                info={
                  ahrefsData?.domain_rating?.info
                    ? ahrefsData?.domain_rating?.info
                    : 'Domain Rating'
                }
                prevPeriod={ahrefsData?.domain_rating?.previous_period}
                previousYear={ahrefsData?.domain_rating?.previous_year}
                isLoading={googleSearchConsole.loading}
                totalValue={formatNumberWithCommas(lastMonthDomainRating)}
                error={googleSearchConsole.error}
                icon="ahref"
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title={'URL Rating'}
                chatData={urlRatingData}
                category={['URL Rating']}
                info={
                  ahrefsData?.urlRating?.info
                    ? ahrefsData?.urlRating?.info
                    : 'URL Rating'
                }
                prevPeriod={ahrefsData?.urlRating?.previous_period}
                previousYear={ahrefsData?.urlRating?.previous_year}
                isLoading={urlRating.loading}
                totalValue={formatNumberWithCommas(lastMonthurlRating)}
                error={urlRating.error}
                icon="ahref"
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default MobileView;
