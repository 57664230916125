import React from 'react';
import useAuthStore from '../../store/useAuthStore';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { ImCheckmark } from 'react-icons/im';
import Select, { StylesConfig } from 'react-select';
import useFeedbackStore from '../../store/useFeedbackStore';

interface OptionType {
  value: string;
  label: string;
}

const Feedback: React.FC = () => {
  const { user } = useAuthStore((state) => state);
  const { createFeedback } = useFeedbackStore((state) => state);
  const clientId = user?.clients[0]?.id || '';

  const [value, setValue] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const options: OptionType[] = [
    { value: 'app', label: 'App' },
    { value: 'account', label: 'Account' },
  ];
  const isOmgUser: boolean = user?.role?.level === 'omg';
  const initialSelected: OptionType = isOmgUser
    ? { value: 'app', label: 'App' }
    : { value: 'account', label: 'Account' };
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(
    initialSelected
  );

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
    }
  }, [success]);

  const handleChange = (e: any) => {
    setValue(e);
    setError(false);
  };

  const handleSelectChange = (option: any) => {
    if (option) {
      setSelectedOption(option);
    }
  };

  const handleSubmit = () => {
    value?.length <= 10 ? setError(true) : handleSaveButton(value);
  };

  const handleSaveButton = (feedbackData: string) => {
    // console.log(feedbackData);
    const data = {
      message: feedbackData,
      type: selectedOption?.value,
      client_id: clientId,
    };
    createFeedback(data).catch((e: any) => setError(e.message));
    setSuccess(true);
    setValue('');
  };
  const customStyles: StylesConfig<any, boolean, any> = {
    control: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      backgroundColor: '#005C9F',
      borderRadius: '8px',
      border: 'none',
      boxShadow: ' 0px 1px 2px 0px gba(16, 24, 40, 0.05)',
      color: 'white',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#FFFFFF',
      fontSize: 14,
      fontWeight: 600,
      padding: 0,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#FFFFFF',
      fontSize: 14,
      fontWeight: 600,
      padding: 0,
    }),
  };
  return (
    <div className="flex flex-col items-center justify-center  w-full bg-[#D2EEFF] h-[92%] dark:bg-[#0C111D] font-inter">
      {/* <PageHeader
        subtitle="Goals"
        domains={currentUser.clients}
        hideDate
      /> */}
      <div className="flex flex-col items-start p-8 justify-center gap-8 w-1/2 border rounded-xl bg-white  border-[#E6EBF0] dark:bg-[#222732] dark:border dark:border-[#313233] shadow-bs-card">
        <div className="flex items-center justify-between w-full">
          <h2 className="text-2xl text-[#005C9F] font-semibold dark:text-white">
            Send us your feedback!
          </h2>
          <Select
            value={selectedOption}
            onChange={handleSelectChange}
            options={options}
            placeholder={'Select an option'}
            styles={customStyles}
          />
        </div>
        {selectedOption?.value === 'account' ? (
          <>
            <p className="text-[#636466] dark:text-[#ADB0B2]">
              Have a concern or issue with your account?
              <br />
              Let us know and we'll forward it to your dedicated account
              manager.
            </p>
            <h3 className="text-[#636466] dark:text-[#ADB0B2]">
              How was your experience?
            </h3>
          </>
        ) : (
          <>
            <p className="text-[#636466] dark:text-[#ADB0B2]">
              Do you have a suggestion or found a bug?
              <br />
              Let us know in the field bellow.
            </p>
            <h3 className="text-[#636466] dark:text-[#ADB0B2]">
              How was your experience?
            </h3>
          </>
        )}

        <ReactQuill
          theme="snow"
          value={value}
          modules={{ toolbar: false }}
          onChange={(e) => handleChange(e)}
          className="min-h-[100px] w-full dark:text-white"
        />

        {error && (
          <div className="flex items-center justify-center text-red-500">
            {' '}
            {/* <p>{`${error} `}</p> */}
            <p>Your Feedback must be more than 10 letters</p>
          </div>
        )}
        {success && (
          <div className="flex items-center justify-center text-green-500">
            {' '}
            <ImCheckmark color="green" />
            <span>Thanks for your feedback</span>
          </div>
        )}
        <button
          className="btn w-full border text-white border-[#005C9F] bg-[#005C9F] py-2 px-4 rounded-lg font-semibold mt-4"
          onClick={handleSubmit}
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default Feedback;
