import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const OmgWhiteLogo: React.FC<IconProps> = ({
  color = '#001C44',
  size = 80,
}) => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M45.4172 25.2057L39.6004 25.1468V24.9704C39.6004 20.9753 38.0143 17.3323 35.3704 14.6884L29.1423 20.74L24.8532 24.9116V24.8532L20.564 20.6816L14.3947 14.6884C11.8684 17.2735 10.282 20.7989 10.1644 24.6767V24.9704C10.1644 26.4392 10.3996 27.8496 10.8109 29.2007C10.8698 29.436 10.987 29.7297 11.0458 29.9645C11.692 31.6686 12.6322 33.2551 13.8074 34.665C13.925 34.8411 14.101 35.0175 14.2775 35.194C16.9214 37.9551 20.6816 39.718 24.8532 39.718C29.0247 39.718 32.7849 38.0139 35.4877 35.194C35.5465 35.1352 35.6053 35.0763 35.6641 35.0175H35.3116L24.912 34.8411V29.436L29.6709 29.4948L38.3668 29.6121H38.8954L44.8886 29.7297C44.5361 31.3161 43.9484 32.8437 43.1846 34.3125C43.0669 34.6062 42.8909 34.8411 42.7732 35.1352C41.9506 36.6628 40.8931 38.0139 39.718 39.2479C35.9578 43.1257 30.7288 45.5348 24.9708 45.5348C19.154 45.5348 13.9834 43.1257 10.2232 39.3067C8.87202 37.9551 7.75572 36.3687 6.81545 34.665C6.75663 34.5474 6.69823 34.4886 6.69823 34.3714C5.87561 32.7849 5.22902 31.022 4.87652 29.2007C4.58285 27.8496 4.46521 26.4392 4.46521 25.0292V24.6179C4.58285 19.2124 6.75664 14.2771 10.282 10.6345C11.692 9.1657 13.3372 7.99057 15.1001 6.99149L19.5065 11.2807L24.912 16.5097L30.3175 11.2807L34.7239 6.99149C36.4867 7.99057 38.1316 9.22451 39.542 10.6345C43.1257 14.3359 45.3583 19.3889 45.3583 24.9704L45.4172 25.2057ZM42.8321 7.52044C41.4221 6.05164 39.8356 4.81771 38.1316 3.76022C34.3125 1.35116 29.7885 0 24.9708 0C20.1531 0 15.6287 1.35116 11.8096 3.76022C10.106 4.81771 8.51952 6.11046 7.10913 7.52044C2.82037 11.9268 0.117636 17.9201 0 24.5007V24.9704C0 26.3808 0.117639 27.7319 0.352497 29.0835C0.646174 30.9636 1.17512 32.7849 1.88011 34.5474C5.64033 43.5959 14.5712 50 24.9708 50C32.0211 50 38.4257 47.0624 42.9497 42.3619C44.3597 40.8931 45.5936 39.3067 46.5923 37.5438C47.0624 36.78 47.4149 36.0162 47.8262 35.194C48.59 33.4899 49.1774 31.6686 49.5299 29.7885C49.824 28.3197 50 26.7921 50 25.2645V24.9704C49.9412 18.2138 47.2384 12.0445 42.8321 7.52044Z"
      fill="white"
    />
  </svg>
);

export default OmgWhiteLogo;
