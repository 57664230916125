import { Grid, Col } from '@tremor/react';
import { Link } from 'react-router-dom';
import Icon from '../../../assets/icons/SvgComponent';
import ImageIcon from '../../ImageIcon';
import moment from 'moment';

interface IProps {
  link?: any;
  onClose?: () => void;
}

const ViewLinksModal = ({ link, onClose }: IProps) => {
  return (
    <div className="w-screen h-screen">
      <div
        className="absolute top-0 right-0 bg-white dark:bg-gray-800 h-full w-[55%] p-6"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="flex gap-2 items-center mb-4 pb-4 border-b">
          <div className="text-xl font-bold dark:text-white truncate max-w-[30vw]">
            {link?.link_brief_item?.name}
          </div>
        </div>
        <div className="border rounded-lg">
          <div className={`rounded`}>
            <div className="">
              <div className="flex justify-between items-center cursor-pointer p-4 rounded-lg">
                <div className="flex gap-2 items-center">
                  <div className="flex flex-wrap sm:flex-nowrap justify-between items-center">
                    <div className="flex flex-wrap gap-2 sm:flex-nowrap items-center text-sm">
                      <div className="text-gray-400 text-sm border-r pr-3">
                        <div className="text-black dark:text-white text-sm font-inter font-[600]">
                          {`N/A`}
                        </div>
                        <div className="text-[10px] uppercase text-[#636466] font-inter font-[400]">
                          Name
                        </div>
                      </div>
                      <div className="text-gray-400 text-sm border-r pr-3">
                        <div className="text-black dark:text-white text-sm font-inter font-[600] capitalize">
                          {`N/A`}
                        </div>
                        <div className="text-[10px] uppercase text-[#636466] font-inter font-[400]">
                          Type
                        </div>
                      </div>
                      <div className="text-gray-400 text-sm border-r pr-3">
                        <div className="text-black dark:text-white text-sm font-inter font-[600]">
                          {moment(link?.link_brief_item?.createdAt).format(
                            'MMMM D, YYYY h:mm A'
                          )}
                        </div>
                        <div className="text-[10px] uppercase text-[#636466] font-inter font-[400]">
                          Created At
                        </div>
                      </div>
                      <div className="text-gray-400 text-sm px-3 border-r">
                        <span
                          className={`text-[#000] text-sm font-inter font-[600] capitalize dark:text-white`}
                        >
                          {link?.link_brief_item?.status || 'N/A'}
                        </span>
                        <div className="text-[10px] uppercase text-[#636466] font-inter font-[400]">
                          Status
                        </div>
                      </div>
                      <div className="text-gray-400 text-sm px-3 border-r">
                        <div className="text-xs text-white bg-[#009EFF] px-3 py-1 rounded-full">
                          Cycle {link?.link_brief_item?.cycle}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`overflow-hidden transition-all duration-500 ease-in-out `}
              >
                <div className="px-4 h-[76dvh] ">
                  <div className="flex justify-between items-center border-t-2 border-b-2 border-dashed py-3">
                    <div className="bg-[#FFB545B2] rounded-md text-sm px-3 py-1 text-[#001C44] font-[500]">
                      Total Cost: {0}
                    </div>
                    <div className="flex gap-6">
                      <div className="flex items-center gap-2">
                        <div>
                          <div className="text-black dark:text-white text-xs font-semibold">
                            <span className="text-[#7C7E7F] font-inter font-[400] text-xs">
                              AM:
                            </span>{' '}
                            {link?.link_brief_item?.account_manager}
                          </div>
                        </div>
                        <div>
                          <ImageIcon
                            data={link?.link_brief_item?.account_manager}
                            size={8}
                            textSize={'xs'}
                            customStyle="bg-[#E6EbF0]"
                          />
                        </div>
                      </div>

                      <div className="flex items-center gap-2">
                        <div>
                          <div className="text-black dark:text-white text-xs font-semibold">
                            <span className="text-[#7C7E7F] font-inter font-[400] text-xs">
                              Consultant
                            </span>{' '}
                            {link?.link_brief_item?.seo_consultant}
                          </div>
                        </div>
                        <div>
                          <ImageIcon
                            data={link?.link_brief_item?.seo_consultant}
                            size={8}
                            textSize={'xs'}
                            customStyle="bg-[#E6EbF0]"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" border-b-2 border-dashed py-3 mb-3">
                    <div className="flex justify-between items-center bg-[#F3F7FA] dark:bg-gray-900 rounded-lg p-3">
                      <div className="font-bold text-[#001C44] text-[14px] dark:text-white">
                        {link?.link_item_type}
                      </div>
                      <div className="text-sm text-[#428B6E] uppercase bg-[#E2FAF1] dark:bg-gray-300 py-1 px-2 rounded-lg ">
                        {link?.link_brief_item?.status}
                      </div>
                    </div>
                  </div>
                  <Grid numItems={2} className="text-xs">
                    <Col className="border-r-2 border-dashed mr-2 pr-2">
                      <div className="font-semibold mb-2 text-[10px] text-[#001C44] mb-4 uppercase dark:text-white">
                        Requested
                      </div>
                      <div className="mb-4 flex flex-col gap-2">
                        <div className="flex gap-1 items-center">
                          <div className="text-[10px] text-[#636466] w-[100px] uppercase dark:text-white">
                            Anchor Texts 1:
                          </div>
                          <div className="truncate dark:text-white">
                            {link?.requested_anchor_text1 || ' -'}
                          </div>
                        </div>
                        <div className="flex gap-1 items-center">
                          <div className="text-[10px] text-[#636466] w-[100px] uppercase dark:text-white">
                            Anchor Texts 2:
                          </div>
                          <div className="truncate dark:text-white">
                            {link?.requested_anchor_text2 || ' -'}
                          </div>
                        </div>
                        <div className="flex gap-1 items-center">
                          <div className="text-[10px] text-[#636466] w-[100px] uppercase dark:text-white">
                            Anchor Texts 3:
                          </div>
                          <div className="truncate dark:text-white">
                            {link?.requested_anchor_text3 || ' -'}
                          </div>
                        </div>
                      </div>
                      <div className="mb-4 flex flex-col gap-2">
                        <div>
                          <div className="flex gap-1 items-center">
                            <div className="text-[10px] text-[#636466] w-[100px] uppercase dark:text-white">
                              Backlinks 1:
                            </div>
                            <div className="flex items-center">
                              <Link
                                to={link.requested_backlink1}
                                className="truncate text-[#0029FF] max-w-[260px] dark:text-[#009EFF]"
                                target="blank"
                              >
                                {link.requested_backlink1 || ' -'}
                              </Link>
                              {link?.requested_backlink1 && (
                                <div>
                                  <Icon
                                    name="ArrowUpRight"
                                    size={16}
                                    color="#0029FF"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="flex gap-1 items-center">
                            <div className="text-[10px] text-[#636466] w-[100px] uppercase dark:text-white">
                              Backlinks 2:
                            </div>
                            <div className="flex items-center">
                              <Link
                                to={link.requested_backlink2}
                                className="truncate text-[#0029FF] max-w-[260px] dark:text-[#009EFF]"
                                target="blank"
                              >
                                {link.requested_backlink2 || ' -'}
                              </Link>
                              {link?.requested_backlink2 && (
                                <div>
                                  <Icon
                                    name="ArrowUpRight"
                                    size={16}
                                    color="#0029FF"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="flex gap-1 items-center">
                            <div className="text-[10px] text-[#636466] w-[100px] uppercase dark:text-white">
                              Backlinks 3:
                            </div>
                            <div className="flex items-center">
                              <Link
                                to={link.requested_backlink3}
                                className="truncate text-[#0029FF] max-w-[260px] dark:text-[#009EFF]"
                                target="blank"
                              >
                                {link.requested_backlink3 || ' -'}
                              </Link>
                              {link?.requested_backlink3 && (
                                <div>
                                  <Icon
                                    name="ArrowUpRight"
                                    size={16}
                                    color="#0029FF"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-4 flex flex-col gap-2">
                        <div className="flex gap-1 items-center">
                          <div className="flex gap-1 items-center">
                            <div className="text-[10px] text-[#636466] w-[100px] uppercase dark:text-white">
                              Link:
                            </div>
                            <div className="flex items-center">
                              <Link
                                to={link.requested_link}
                                className="truncate text-[#0029FF] max-w-[260px] dark:text-[#009EFF]"
                                target="blank"
                              >
                                {link.requested_link || ' -'}
                              </Link>
                              {link?.requested_link && (
                                <div>
                                  <Icon
                                    name="ArrowUpRight"
                                    size={16}
                                    color="#0029FF"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="flex gap-2 items-center justify-between">
                          <div className="flex gap-1 items-center">
                            <div className="text-[10px] text-[#636466] w-[100px] uppercase dark:text-white">
                              DR:
                            </div>
                            <div className="truncate dark:text-white">
                              {link.requested_dr || ' N/A'}
                            </div>
                          </div>
                          <div className="text-[10px] text-[#636466] w-fit uppercase dark:text-white">
                            -
                          </div>
                          <div className="flex gap-1 items-center">
                            <div className="text-[10px] text-[#636466] w-[100px] uppercase dark:text-white">
                              ADJUSTED DR:
                            </div>
                            <div className="truncate dark:text-white min-w-[60px]">
                              {link.adjusted_dr || ' N/A'}
                            </div>
                          </div>
                        </div>
                        <div className="flex gap-1 items-center">
                          <div className="text-[10px] text-[#636466] w-[100px] uppercase dark:text-white">
                            Site Traffic:
                          </div>
                          <div className="truncate dark:text-white">
                            {link?.requested_site_traffic || ' -'}
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col className=" mr-2 pr-2">
                      <div className="font-semibold mb-2 text-[10px] text-[#001C44] mb-4 uppercase dark:text-white">
                        Delivered
                      </div>
                      <div className="mb-4 flex flex-col gap-2">
                        <div className="flex gap-1 items-center">
                          <div className="text-[10px] text-[#636466] w-[100px] uppercase dark:text-white">
                            Anchor Texts 1:
                          </div>
                          <div className="truncate dark:text-white">
                            {link?.delivered_anchor_text1 || ' -'}
                          </div>
                        </div>
                        <div className="flex gap-1 items-center">
                          <div className="text-[10px] text-[#636466] w-[100px] uppercase dark:text-white">
                            Anchor Texts 2:
                          </div>
                          <div className="truncate dark:text-white">
                            {link?.delivered_anchor_text2 || ' -'}
                          </div>
                        </div>
                        <div className="flex gap-1 items-center">
                          <div className="text-[10px] text-[#636466] w-[100px] uppercase dark:text-white">
                            Anchor Texts 3:
                          </div>
                          <div className="truncate dark:text-white">
                            {link?.delivered_anchor_text3 || ' -'}
                          </div>
                        </div>
                      </div>
                      <div className="mb-4 flex flex-col gap-2">
                        <div>
                          <div className="flex gap-1 items-center">
                            <div className="text-[10px] text-[#636466] w-[100px] uppercase dark:text-white">
                              Backlinks 1:
                            </div>
                            <div className="flex items-center">
                              <Link
                                to={link.delivered_backlink1}
                                className="truncate text-[#0029FF] max-w-[260px] dark:text-[#009EFF]"
                                target="blank"
                              >
                                {link.delivered_backlink1 || ' -'}
                              </Link>
                              {link?.delivered_backlink1 && (
                                <div>
                                  <Icon
                                    name="ArrowUpRight"
                                    size={16}
                                    color="#0029FF"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="flex gap-1 items-center">
                            <div className="text-[10px] text-[#636466] w-[100px] uppercase dark:text-white">
                              Backlinks 2:
                            </div>
                            <div className="flex items-center">
                              <Link
                                to={link.delivered_backlink2}
                                className="truncate text-[#0029FF] max-w-[260px] dark:text-[#009EFF]"
                                target="blank"
                              >
                                {link.delivered_backlink2 || ' -'}
                              </Link>
                              {link?.delivered_backlink2 && (
                                <div>
                                  <Icon
                                    name="ArrowUpRight"
                                    size={16}
                                    color="#0029FF"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="flex gap-1 items-center">
                            <div className="text-[10px] text-[#636466] w-[100px] uppercase dark:text-white">
                              Backlinks 3:
                            </div>
                            <div className="flex items-center">
                              <Link
                                to={link.delivered_backlink3}
                                className="truncate text-[#0029FF] max-w-[260px] dark:text-[#009EFF]"
                                target="blank"
                              >
                                {link.delivered_backlink3 || ' -'}
                              </Link>
                              {link?.delivered_backlink3 && (
                                <div>
                                  <Icon
                                    name="ArrowUpRight"
                                    size={16}
                                    color="#0029FF"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-4 flex flex-col gap-2">
                        <div className="flex gap-1 items-center">
                          <div className="flex gap-1 items-center">
                            <div className="text-[10px] text-[#636466] w-[100px] uppercase dark:text-white">
                              Link:
                            </div>
                            <div className="flex items-center">
                              <Link
                                to={link.delivered_link}
                                className="truncate text-[#0029FF] max-w-[260px] dark:text-[#009EFF]"
                                target="blank"
                              >
                                {link.delivered_link || ' -'}
                              </Link>
                              {link?.delivered_link && (
                                <div>
                                  <Icon
                                    name="ArrowUpRight"
                                    size={16}
                                    color="#0029FF"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="flex gap-1 items-center">
                          <div className="flex gap-1 items-center">
                            <div className="text-[10px] text-[#636466] w-[100px] uppercase dark:text-white">
                              DR:
                            </div>
                            <div className="truncate dark:text-white">
                              {link.delivered_dr || ' N/A'}
                            </div>
                          </div>
                        </div>
                        <div className="flex gap-1 items-center">
                          <div className="text-[10px] text-[#636466] w-[100px] uppercase dark:text-white">
                            Site Traffic:
                          </div>
                          <div className="truncate dark:text-white">
                            {link?.delivered_site_traffic || ' -'}
                          </div>
                        </div>

                        <div className="flex gap-1 items-center">
                          <div className="text-[10px] text-[#636466] w-[100px] uppercase dark:text-white">
                            Updated At:
                          </div>
                          <div className="text-[10px] truncate dark:text-white">
                            {moment(link?.updatedAt).format(
                              'MMMM D, YYYY h:mm A'
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Grid>
                  <div className=" border-t-2 py-2 border-dashed">
                    <div className=" p-2 rounded mb-2 bg-[#F2F4FF80]">
                      <div className="font-semibold mb-2 text-[10px] text-[#001C44] mb-4 uppercase dark:text-white">
                        Cost Information:
                      </div>
                      <Grid numItems={2} className="text-xs">
                        <Col className="border-r-2 border-dashed mr-2 pr-2">
                          <div className="flex gap-2 items-center justify-between">
                            <div className="flex gap-1 items-center">
                              <div className="text-[10px] text-[#636466] w-[140px] uppercase dark:text-white">
                                Requested Link Cost:
                              </div>
                              <div className="truncate dark:text-white">
                                {link?.requested_link_cost || ' -'}
                              </div>
                            </div>
                            <div className="flex gap-1 items-center">
                              <div className="text-[10px] text-[#636466] w-[100px] uppercase dark:text-white">
                                ADJUSTED COST:
                              </div>
                              <div className="truncate dark:text-white min-w-[60px]">
                                {link.adjusted_link_cost || ' N/A'}
                              </div>
                            </div>
                          </div>
                          <div className="flex gap-2 items-center justify-between">
                            <div className="flex gap-1 items-center">
                              <div className="text-[10px] text-[#636466] w-[140px] uppercase dark:text-white">
                                Requested Link Price:
                              </div>
                              <div className="truncate dark:text-white">
                                {link?.requested_link_price || ' -'}
                              </div>
                            </div>
                            <div className="flex gap-1 items-center">
                              <div className="text-[10px] text-[#636466] w-[100px] uppercase dark:text-white">
                                ADJUSTED PRICE:
                              </div>
                              <div className="truncate dark:text-white min-w-[60px]">
                                {link.adjusted_link_price || ' N/A'}
                              </div>
                            </div>
                          </div>
                          <div className="flex gap-1 items-center">
                            <div className="text-[10px] text-[#636466] w-[140px] uppercase dark:text-white">
                              Currency:
                            </div>
                            <div className="text-[10px] truncate dark:text-white">
                              {link?.requested_link_price_currency}
                            </div>
                          </div>
                        </Col>
                        <Col className=" mr-2 pr-2">
                          <div className="flex gap-1 items-center">
                            <div className="text-[10px] text-[#636466] w-[140px] uppercase dark:text-white">
                              Delivered Link Cost:
                            </div>
                            <div className="truncate dark:text-white">
                              {link?.delivered_link_cost || ' -'}
                            </div>
                          </div>
                          <div className="flex gap-1 items-center">
                            <div className="text-[10px] text-[#636466] w-[140px] uppercase dark:text-white">
                              Delivered Link Price:
                            </div>
                            <div className="truncate dark:text-white">
                              {link?.delivered_link_price || ' -'}
                            </div>
                          </div>
                          <div className="flex gap-1 items-center">
                            <div className="text-[10px] text-[#636466] w-[140px] uppercase dark:text-white">
                              PUBLISHER CODE:
                            </div>
                            <div className="text-[10px] truncate dark:text-white">
                              {link?.publisher_code}
                            </div>
                          </div>
                        </Col>
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="absolute top-6 right-6 cursor-pointer" onClick={onClose}>
        <Icon name="Xclose" />
      </div>
    </div>
  );
};

export default ViewLinksModal;
