import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const AlertCircle: React.FC<IconProps> = ({ color = '#CC002F', size = 20 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="alert-circle" clipPath="url(#clip0_2315_24160)">
      <path
        id="Icon"
        d="M10.0003 6.66699V10.0003M10.0003 13.3337H10.0087M18.3337 10.0003C18.3337 14.6027 14.6027 18.3337 10.0003 18.3337C5.39795 18.3337 1.66699 14.6027 1.66699 10.0003C1.66699 5.39795 5.39795 1.66699 10.0003 1.66699C14.6027 1.66699 18.3337 5.39795 18.3337 10.0003Z"
        stroke={color || '#CC002F'}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2315_24160">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AlertCircle;
