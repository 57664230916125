import { Card } from '@tremor/react';
import Icon from '../../../../assets/icons/SvgComponent';
import AnalyticsTable from '../../AnalyticsTable';

interface IProps {
  campaignsReport: any;
  campaignsReportData: any;
}

const MobileView = ({ campaignsReportData, campaignsReport }: IProps) => {
  return (
    <>
      {campaignsReportData?.data?.length === 0 && !campaignsReport.loading ? (
        <Card className="card-item px-3 sm:px-6 shadow-none lg:shadow ring-0 lg:ring-1">
          <div className="flex flex-col items-center justify-center w-full items-center my-8 h-[148px]">
            <div className="mb-8">
              <Icon name="NoDataCampaign" />
            </div>
            <p className="font-inter font-[600] text-[20px] text-[#001C44] dark:text-white">
              No campaign to display at the moment
            </p>
            <p className="text-center w-[70vw] text-[20px] text-[#001C44] dark:text-white">
              Keep ahead of the curve with the latest industry trends and news.
              Our specialized section is here to equip you with essential
              updates and insightful developments tailored to your interests.
            </p>
          </div>
        </Card>
      ) : (
        <AnalyticsTable
          data={campaignsReportData?.data ? campaignsReportData?.data : []}
          columns={campaignsReportData?.tableHeader}
          loading={campaignsReport.loading}
          totalDocs={
            campaignsReportData?.data ? campaignsReportData?.data.length : 'N/A'
          }
          sortByColumnId="Cost"
          pagination={false}
        />
      )}
    </>
  );
};

export default MobileView;
