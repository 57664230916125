import styled from 'styled-components';

export const Wrapper = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  zIndex: 1,
  h1: {
    marginTop: '15%',
  },
}));

export const Image = styled.img({
  width: 600,
  backgroundPosition: 'center',
});
