import AreaCardChart from '../../AreaCardChart';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { formatNumber, formatToCurrency } from '../../../../utils/common';

interface IProps {
  campaignsReport?: any;
  campainChartData?: any;
  selectedClient?: any;
  clicks?: any;
  lastMonthClicks?: any;
  conversionRate?: any;
  lastMonthConversionRate?: any;
  conversions?: any;
  lastMonthConversions?: any;
  costPerConversion?: any;
  lastMonthCostPerConversion?: any;
  ctr?: any;
  lastMonthCtr?: any;
  impressions?: any;
  lastMonthImpressions?: any;
  revenue?: any;
  lastMonthRevenue?: any;
  spend?: any;
  lastMonthSpend?: any;
  totalRoas?: any;
  lastMonthTotalRoas?: any;
  retryFetchBingCampaign?: () => void;
}

const MobileView = ({
  campaignsReport,
  campainChartData,
  selectedClient,
  clicks,
  lastMonthClicks,
  conversionRate,
  lastMonthConversionRate,
  conversions,
  lastMonthConversions,
  costPerConversion,
  lastMonthCostPerConversion,
  ctr,
  lastMonthCtr,
  impressions,
  lastMonthImpressions,
  revenue,
  lastMonthRevenue,
  spend,
  lastMonthSpend,
  totalRoas,
  lastMonthTotalRoas,
  retryFetchBingCampaign,
}: IProps) => {
  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="p-0">
          <Swiper pagination={true} modules={[Pagination]} className="p-1">
            <SwiperSlide>
              <AreaCardChart
                title={
                  campainChartData?.Clicks?.label
                    ? campainChartData?.Clicks?.label
                    : 'Clicks'
                }
                chatData={clicks}
                category={['Clicks']}
                info={
                  campainChartData?.Clicks?.info
                    ? campainChartData?.Clicks?.info
                    : 'Clicks'
                }
                prevPeriod={campainChartData?.Clicks?.previous_period}
                previousYear={campainChartData?.Clicks?.previous_year}
                isLoading={campaignsReport?.loading || selectedClient?.loading}
                totalValue={formatNumber(lastMonthClicks)}
                error={campaignsReport.error}
                icon="BingAnalyticsTab"
                retryMethod={retryFetchBingCampaign}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title="Conversion Rate"
                chatData={conversionRate}
                category={['Conversion Rate']}
                info={
                  campainChartData?.ConversionRate?.info
                    ? campainChartData?.ConversionRate?.info
                    : 'Conversion Rate'
                }
                prevPeriod={`${campainChartData?.ConversionRate?.previous_period}`}
                previousYear={`${campainChartData?.ConversionRate?.previous_year}`}
                isLoading={campaignsReport.loading}
                totalValue={formatNumber(lastMonthConversionRate, true)}
                hasPercent={true}
                error={campaignsReport.error}
                icon="BingAnalyticsTab"
                propertyId={`Client ID: ${selectedClient?.id}`}
                retryMethod={() => console.log('refetch')}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title="Conversions"
                chatData={conversions}
                category={['Conversions']}
                info={
                  campainChartData?.Conversions?.info
                    ? campainChartData?.Conversions?.info
                    : 'Conversions'
                }
                prevPeriod={`${campainChartData?.Conversions?.previous_period}`}
                previousYear={`${campainChartData?.Conversions?.previous_year}`}
                isLoading={campaignsReport.loading}
                totalValue={formatNumber(lastMonthConversions)}
                error={campaignsReport.error}
                icon="BingAnalyticsTab"
                propertyId={`Client ID: ${selectedClient?.id}`}
                retryMethod={() => console.log('refetch')}
                customDataTooltip={true}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title="Cost Per Conversions"
                chatData={costPerConversion}
                category={['Cost Per Conversion']}
                info={
                  campainChartData?.CostPerConversion?.info
                    ? campainChartData?.CostPerConversion?.info
                    : 'Cost Per Conversions'
                }
                prevPeriod={`${campainChartData?.CostPerConversion?.previous_period}`}
                previousYear={`${campainChartData?.CostPerConversion?.previous_year}`}
                isLoading={campaignsReport.loading}
                totalValue={formatToCurrency(lastMonthCostPerConversion)}
                hasDollar={true}
                error={campaignsReport.error}
                icon="BingAnalyticsTab"
                propertyId={`Client ID: ${selectedClient?.id}`}
                retryMethod={() => console.log('refetch')}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title="Click-Through Rate"
                chatData={ctr}
                category={['Click-Through Rate']}
                info={
                  campainChartData?.Ctr?.info
                    ? campainChartData?.Ctr?.info
                    : 'Click-Through Rate'
                }
                prevPeriod={`${campainChartData?.Ctr?.previous_period}`}
                previousYear={`${campainChartData?.Ctr?.previous_year}`}
                isLoading={campaignsReport.loading}
                totalValue={formatNumber(lastMonthCtr, true)}
                hasPercent={true}
                error={campaignsReport.error}
                icon="BingAnalyticsTab"
                propertyId={`Client ID: ${selectedClient?.id}`}
                retryMethod={() => console.log('refetch')}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title={
                  campainChartData?.Impressions?.label
                    ? campainChartData?.Impressions?.label
                    : 'Impressions'
                }
                chatData={impressions}
                category={['Impressions']}
                info={
                  campainChartData?.Impressions?.info
                    ? campainChartData?.Impressions?.info
                    : 'Impressions'
                }
                prevPeriod={campainChartData?.Impressions?.previous_period}
                previousYear={campainChartData?.Impressions?.previous_year}
                isLoading={campaignsReport.loading}
                totalValue={formatNumber(lastMonthImpressions)}
                error={campaignsReport.error}
                icon="BingAnalyticsTab"
                propertyId={selectedClient?.google_search_console?.site_url}
                retryMethod={retryFetchBingCampaign}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title={
                  campainChartData?.Revenue?.label
                    ? campainChartData?.Revenue?.label
                    : 'Revenue'
                }
                chatData={revenue}
                category={['Revenue']}
                info={
                  campainChartData?.Revenue?.info
                    ? campainChartData?.Revenue?.info
                    : 'Revenue'
                }
                prevPeriod={campainChartData?.Revenue?.previous_period}
                previousYear={campainChartData?.Revenue?.previous_year}
                isLoading={campaignsReport.loading}
                totalValue={formatNumber(lastMonthRevenue)}
                error={campaignsReport.error}
                customDataTooltip={true}
                icon="BingAnalyticsTab"
                retryMethod={retryFetchBingCampaign}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title={
                  campainChartData?.Spend?.label
                    ? campainChartData?.Spend?.label
                    : 'Spend'
                }
                chatData={spend}
                category={['Spend']}
                info={
                  campainChartData?.Spend?.info
                    ? campainChartData?.Spend?.info
                    : 'Spend'
                }
                prevPeriod={campainChartData?.Spend?.previous_period}
                previousYear={campainChartData?.Spend?.previous_year}
                isLoading={campaignsReport.loading}
                totalValue={formatToCurrency(lastMonthSpend)}
                error={campaignsReport.error}
                hasDollar
                icon="BingAnalyticsTab"
                retryMethod={retryFetchBingCampaign}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title="Return on Ad Spend"
                chatData={totalRoas}
                category={['Return on Ad Spend']}
                info={
                  campainChartData?.ReturnOnAdSpend?.info
                    ? campainChartData?.ReturnOnAdSpend?.info
                    : 'Return on Ad Spend'
                }
                prevPeriod={`${campainChartData?.ReturnOnAdSpend?.previous_period}`}
                previousYear={`${campainChartData?.ReturnOnAdSpend?.previous_year}`}
                isLoading={campaignsReport.loading}
                totalValue={formatNumber(lastMonthTotalRoas)}
                error={campaignsReport.error}
                icon="BingAnalyticsTab"
                propertyId={`Client ID: ${selectedClient?.id}`}
                retryMethod={() => console.log('refertch')}
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default MobileView;
