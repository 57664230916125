import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import AreaCardChart from '../../AreaCardChart';
import { formatToCurrency, formatNumber } from '../../../../utils/common';
interface IProps {
  isLeadGen?: boolean;
  isMicrosoftAds?: boolean;
  selectedClient?: any;
  topPerformanceBing?: any;
  requestBingAdsAccess?: any;
  bingSpend?: any;
  bingClicks?: any;
  bingConversions?: any;
  bingRevenue?: any;
  bingReturnOnAdSpend?: any;
  bingCostPerConversion?: any;
  lastMonthBingClicks?: any;
  lastMonthBingSpend?: any;
  lastMonthBingConversions?: any;
  lastMonthBingRevenue?: any;
  lastMonthBingReturnOnAdSpend?: any;
  lastMonthBingCostPerConversion?: any;
}

const KpiBingMobileView = ({
  isLeadGen,
  isMicrosoftAds,
  topPerformanceBing,
  requestBingAdsAccess,
  bingSpend,
  bingClicks,
  bingConversions,
  bingRevenue,
  bingReturnOnAdSpend,
  bingCostPerConversion,
  lastMonthBingConversions,
  lastMonthBingClicks,
  lastMonthBingSpend,
  lastMonthBingRevenue,
  lastMonthBingReturnOnAdSpend,
  lastMonthBingCostPerConversion,
}: IProps) => {
  return (
    <div className="block sm:hidden gap-4 mt-4">
      <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
        <SwiperSlide>
          <AreaCardChart
            title={topPerformanceBing?.Spend?.label || 'Spend'}
            chatData={bingSpend}
            status={isMicrosoftAds}
            category={['Spend']}
            totalValue={formatToCurrency(lastMonthBingSpend)}
            error={topPerformanceBing.error}
            prevPeriod={topPerformanceBing?.Spend?.previous_period}
            previousYear={topPerformanceBing?.Spend?.previous_year}
            isLoading={topPerformanceBing.loading}
            requestAccess={requestBingAdsAccess}
            // noAccessSubtitle={googleAdsNoAccessSub}
            info={topPerformanceBing?.Spend?.info || 'Spend'}
            icon="BingAnalyticsTab"
            hasDollar
            showCurrentMonth={true}
          />
        </SwiperSlide>
        <SwiperSlide>
          <AreaCardChart
            title={topPerformanceBing?.Clicks?.label || 'Clicks'}
            chatData={bingClicks}
            status={isMicrosoftAds}
            category={['Clicks']}
            totalValue={formatNumber(lastMonthBingClicks)}
            error={topPerformanceBing.error}
            prevPeriod={topPerformanceBing?.Clicks?.previous_period}
            previousYear={topPerformanceBing?.Clicks?.previous_year}
            isLoading={topPerformanceBing.loading}
            requestAccess={requestBingAdsAccess}
            // noAccessSubtitle={googleAdsNoAccessSub}
            info={topPerformanceBing?.Clicks?.info || 'Clicks'}
            icon="BingAnalyticsTab"
            showCurrentMonth={true}
          />
        </SwiperSlide>
        {isLeadGen ? (
          <>
            <SwiperSlide>
              <AreaCardChart
                title={topPerformanceBing?.Conversions?.label || 'Conversions'}
                chatData={bingConversions}
                status={isMicrosoftAds}
                category={['Conversions']}
                totalValue={formatNumber(lastMonthBingConversions)}
                error={topPerformanceBing.error}
                prevPeriod={topPerformanceBing?.Conversions?.previous_period}
                previousYear={topPerformanceBing?.Conversions?.previous_year}
                isLoading={topPerformanceBing.loading}
                requestAccess={requestBingAdsAccess}
                // noAccessSubtitle={googleAdsNoAccessSub}
                info={topPerformanceBing?.Conversions?.info || 'Conversions'}
                icon="BingAnalyticsTab"
                showCurrentMonth={true}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title={
                  topPerformanceBing?.CostPerConversion?.label ||
                  'Cost Per Conversion'
                }
                chatData={bingCostPerConversion}
                status={isMicrosoftAds}
                category={['Cost Per Conversion']}
                totalValue={formatNumber(lastMonthBingCostPerConversion)}
                error={topPerformanceBing.error}
                prevPeriod={
                  topPerformanceBing?.CostPerConversion?.previous_period
                }
                previousYear={
                  topPerformanceBing?.CostPerConversion?.previous_year
                }
                isLoading={topPerformanceBing.loading}
                requestAccess={requestBingAdsAccess}
                // noAccessSubtitle={googleAdsNoAccessSub}
                info={
                  topPerformanceBing?.CostPerConversion?.info ||
                  'CostPerConversion'
                }
                icon="BingAnalyticsTab"
                showCurrentMonth={true}
              />
            </SwiperSlide>
          </>
        ) : (
          <>
            <SwiperSlide>
              <AreaCardChart
                title={topPerformanceBing?.Revenue?.label || 'Revenue'}
                chatData={bingRevenue}
                status={isMicrosoftAds}
                category={['Revenue']}
                totalValue={formatNumber(lastMonthBingRevenue)}
                error={topPerformanceBing.error}
                prevPeriod={topPerformanceBing?.Revenue?.previous_period}
                previousYear={topPerformanceBing?.Revenue?.previous_year}
                isLoading={topPerformanceBing.loading}
                requestAccess={requestBingAdsAccess}
                // noAccessSubtitle={googleAdsNoAccessSub}
                info={topPerformanceBing?.Revenue?.info || 'Revenue'}
                icon="BingAnalyticsTab"
                showCurrentMonth={true}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title={
                  topPerformanceBing?.ReturnOnAdSpend?.label ||
                  'Return On Ad Spend'
                }
                chatData={bingReturnOnAdSpend}
                status={isMicrosoftAds}
                category={[`Return On Ad Spend`]}
                totalValue={formatToCurrency(lastMonthBingReturnOnAdSpend)}
                error={topPerformanceBing.error}
                prevPeriod={
                  topPerformanceBing?.ReturnOnAdSpend?.previous_period
                }
                previousYear={
                  topPerformanceBing?.ReturnOnAdSpend?.previous_year
                }
                isLoading={topPerformanceBing.loading}
                requestAccess={requestBingAdsAccess}
                // noAccessSubtitle={googleAdsNoAccessSub}
                info={
                  topPerformanceBing?.ReturnOnAdSpend?.info || 'ReturnOnAdSpend'
                }
                icon="BingAnalyticsTab"
                showCurrentMonth={true}
                hasDollar
              />
            </SwiperSlide>
          </>
        )}
      </Swiper>
    </div>
  );
};

export default KpiBingMobileView;
