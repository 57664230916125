import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import General from './General';
import KeywordDelivery from './KeywordsDelivery';
import KeywordsEngagement from './KeywordsEngagement';
import KeywordPurchases from './KeywordPurchases';
import { processData } from '../../../utils/common';
import useMetaStore from '../../../store/useMetaStore';
import useClientStore from '../../../store/useClientStore';

const GeneralOverview = () => {
  const { accountInsights } = useMetaStore((state) => state);
  const { selectedClient } = useClientStore((state) => state);

  const location = useLocation();
  const [sliceData, setSliceData] = useState(false);

  const parseDate = (date) => (date ? date.replace(/(^"|"$)/g, '') : null);

  const startDate = parseDate(localStorage.getItem('startDate'));
  const endDate = parseDate(localStorage.getItem('endDate'));

  useEffect(() => {
    const currentMoment = moment();

    const isValidAndCurrentMonth = (date) => {
      const momentDate = date ? moment(date) : null;
      return (
        momentDate &&
        momentDate.isValid() &&
        momentDate.isSame(currentMoment, 'month') &&
        momentDate.isSame(currentMoment, 'year')
      );
    };

    const isCurrentMonth =
      (startDate && isValidAndCurrentMonth(startDate)) ||
      (endDate && isValidAndCurrentMonth(endDate));

    setSliceData(isCurrentMonth);
  }, [startDate, endDate, selectedClient, location.pathname]);

  const clientCategory = selectedClient?.category_type || '';
  const isLeadGen = clientCategory === 'Lead Gen';

  const spend = processData(accountInsights, 'spend', 'Ad Spend');
  const revenue = processData(accountInsights, 'revenue');
  const roas = processData(accountInsights, 'roas');
  const impression = processData(accountInsights, 'impressions');
  const reach = processData(accountInsights, 'reach');
  const cpm = processData(accountInsights, 'cpm');
  const clicks = processData(accountInsights, 'unique_clicks', 'Link Clicks');
  const ctr = processData(accountInsights, 'ctr');
  const cpc = processData(accountInsights, 'cpc');
  const purchase = processData(
    accountInsights,
    'website_purchases',
    'Purchases'
  );
  const aov = processData(accountInsights, 'aov', 'Average Order Value (AOV)');
  const cps = processData(accountInsights, 'cps', 'Cost Per Sale (CPS)');
  const form_leads = processData(accountInsights, 'form_leads');
  const website_leads = processData(accountInsights, 'website_leads');
  const cpl = processData(accountInsights, 'cpl');
  const cvr = processData(accountInsights, 'cvr');

  return (
    <div className="flex flex-col gap-2 sm:gap-8">
      {isLeadGen ? (
        <General
          spend={spend}
          form_leads={form_leads}
          website_leads={website_leads}
          dataInfo={accountInsights}
          isLeadGen={true}
          sliceData={sliceData}
        />
      ) : (
        <General
          spend={spend}
          revenue={revenue}
          roas={roas}
          dataInfo={accountInsights}
          isLeadGen={false}
          sliceData={sliceData}
        />
      )}
      <KeywordDelivery
        impressions={impression}
        reach={reach}
        cpm={cpm}
        dataInfo={accountInsights}
      />
      <KeywordsEngagement
        clicks={clicks}
        ctr={ctr}
        cpc={cpc}
        dataInfo={accountInsights}
      />
      <KeywordPurchases
        website_leads={website_leads}
        cpl={cpl}
        purchase={purchase}
        aov={aov}
        cps={cps}
        cvr={cvr}
        dataInfo={accountInsights}
        isLeadGen={isLeadGen}
      />
      {/* <MonthlyTarget loading={accountInsights.loading} /> */}
    </div>
  );
};

export default GeneralOverview;
