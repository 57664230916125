import { motion } from 'framer-motion';
import { Card, BarChart } from '@tremor/react';
import Icon from '../../../assets/icons/SvgComponent';
import moment from 'moment';

interface PerformanceInsightsModalProps {
  onClose: () => void;
  selectedLog: any;
}

const LogsModal: React.FC<PerformanceInsightsModalProps> = ({
  onClose,
  selectedLog,
}) => {
  return (
    <motion.div
      className="fixed top-0 right-0 flex flex-col items-center py-6 px-8 space-y-6 w-[40vw] h-fit  bg-white dark:bg-[#222732] h-screen "
      initial={{ y: '-50%', opacity: 0 }}
      exit={{ y: '-50%', opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="w-full flex justify-between">
        <div>
          <div className="flex items-center gap-2">
            <p className="text-lg dark:text-white">{`${selectedLog?.user?.first_name} ${selectedLog?.user?.last_name}`}</p>
            <p className="uppercase bg-[#0029FF] text-white font-bold text-xs p-1 rounded-md">{`${selectedLog?.user?.role}`}</p>
          </div>
          <p className="text-xs dark:text-white">{`${selectedLog?.user?.email}`}</p>
        </div>
        <div onClick={onClose} className="cursor-pointer">
          <Icon name="Xclose" />
        </div>
      </div>
      <Card className="relative dark:bg-[#345578] max-h-[90dvh] overflow-y-auto">
        <div className="flex justify-between">
          <p className="dark:text-white">Method: {`${selectedLog?.method}`}</p>
          <p className="text-sm dark:text-white">
            {moment(`${selectedLog?.timestamp}`).format('DD MMM YYYY h:mm A')}
          </p>
        </div>
        <p className="mt-3 dark:text-white">
          Route: <span className="text-sm">{`${selectedLog?.route}`}</span>
        </p>
        {selectedLog?.request_body && (
          <>
            <p className="mt-3 dark:text-white">Request Body</p>
            <pre className="text-sm mt-3 dark:text-white">
              {JSON.stringify(selectedLog?.request_body, null, 2)}
            </pre>
          </>
        )}
        {selectedLog?.request_params && (
          <>
            <div className="flex justify-between items-center mt-3 dark:text-white">
              <p>Request Params</p>
            </div>
            <pre className="text-sm mt-3 dark:text-white whitespace-pre-wrap break-words">
              {JSON.stringify(selectedLog?.request_params, null, 2)}
            </pre>
          </>
        )}
      </Card>
    </motion.div>
  );
};

export default LogsModal;
