interface IconProps {
  color?: string;
  size?: number;
}

const Clock = ({ color = '#001C44', size = 18 }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.125 8.25C13.125 6.0625 11.9492 4.06641 10.0625 2.97266C8.14844 1.85156 5.82422 1.85156 3.9375 2.97266C2.02344 4.06641 0.875 6.0625 0.875 8.25C0.875 10.4648 2.02344 12.4609 3.9375 13.5547C5.82422 14.6758 8.14844 14.6758 10.0625 13.5547C11.9492 12.4609 13.125 10.4648 13.125 8.25ZM0 8.25C0 5.76172 1.3125 3.46484 3.5 2.20703C5.66016 0.949219 8.3125 0.949219 10.5 2.20703C12.6602 3.46484 14 5.76172 14 8.25C14 10.7656 12.6602 13.0625 10.5 14.3203C8.3125 15.5781 5.66016 15.5781 3.5 14.3203C1.3125 13.0625 0 10.7656 0 8.25ZM6.5625 4.3125C6.5625 4.09375 6.75391 3.875 7 3.875C7.21875 3.875 7.4375 4.09375 7.4375 4.3125V8.03125L9.84375 9.64453C10.0625 9.78125 10.1172 10.0547 9.98047 10.2461C9.84375 10.4648 9.57031 10.5195 9.37891 10.3828L6.75391 8.63281C6.61719 8.55078 6.5625 8.41406 6.5625 8.25V4.3125Z"
        fill={color}
      />
    </svg>
  );
};

export default Clock;