import BarChartCard from '../BarChartCard';
import { Grid, Col, Card } from '@tremor/react';
import InfoTooltip from '../AnalyticsInfoTooltip';
import Icon from '../../../assets/icons/SvgComponent';
import useMetaStore from '../../../store/useMetaStore';
import { formatNumber, formatToCurrency } from '../../../utils/common';
import { useMemo } from 'react';
import AnalyticsTable from '../AnalyticsTable';
import DonutBarGroup from '../DonutCardChart';
import useClientStore from '../../../store/useClientStore';
import Tooltip from '../../HelpTooltip';
import { useRetryFetchFbCampaign, useRetryFetchFbInsights } from '../Hooks';

const AcquisitionOverview = () => {
  const { 
    accountInsights, 
    campaignInsights 
  } = useMetaStore((state) => state);
  const { selectedClient } = useClientStore((state) => state);

  const clientCategory = selectedClient?.category_type || '';
  const isLeadGen = clientCategory === 'Lead Gen';

  const spendCVRData = accountInsights?.spend?.dates?.map((date, index) => ({
    date: date,
    'Ad Spend': accountInsights?.spend?.values[index],
    'CVR Website Leads': accountInsights?.cvr?.values[index] * 100,
  }));

  const spendCVRCategories = ['Ad Spend', 'CVR Website Leads'];

  const totalLeadsCPLData = accountInsights?.website_leads?.dates?.map(
    (date, index) => ({
      date: date,
      'Websites & Form Leads':
        accountInsights?.website_leads?.values[index] +
        accountInsights?.form_leads?.values[index],
      'CPL Website Leads': accountInsights?.cpl?.values[index],
    })
  );

  const totalLeadsCPLCategories = [
    'Websites & Form Leads',
    'CPL Website Leads',
  ];

  const spendRoasData = accountInsights?.spend?.dates?.map((date, index) => ({
    date: date,
    Spend: accountInsights?.spend?.values[index],
    ROAS: accountInsights?.roas?.values[index] * 100,
  }));

  const spendRoasCategories = ['Spend', 'ROAS'];

  const purchaseRevenueData = accountInsights?.website_purchases?.dates?.map(
    (date, index) => ({
      date: date,
      Purchase: accountInsights?.website_purchases?.values[index],
      Revenue: accountInsights?.revenue?.values[index],
    })
  );
  const purchaseRevenueCategories = ['Purchase', 'Revenue'];

  const campaignInsightsData = useMemo(() => {
    const spendTableHeader = [
      {
        id: 'Campaign Name',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Campaign Name' && sortDirection === false) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[160px] sm:w-[14vw] h-fit value">
                <div>Campaign Name</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Campaign Name' && sortDirection === true) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[160px] sm:w-[14vw] h-fit value">
                <div>Campaign Name</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Campaign Name' || headerId === 'Campaign Name') {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[160px] sm:w-[14vw] h-fit value">
                <div>Campaign Name</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'campaign_name',
        cell: (props: any) => (
          <div className="flex justify-start items-center text-xs gap-2 font-inter w-[160px] sm:w-[14vw] h-fit value">
            <div className="max-w-[150px] sm:max-w-[14vw] truncate ">
              {props.getValue()}
            </div>
          </div>
        ),
      },
      {
        id: 'Ad Spend',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Ad Spend' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
                <div>Ad Spend</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Ad Spend' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
                <div>Ad Spend</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Ad Spend' || headerId === 'Ad Spend') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
                <div>Ad Spend</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'spend',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[160px] sm:w-[8vw] h-fit">
            {formatToCurrency(props.getValue())}
          </div>
        ),
      },
    ];

    const revenueTableHeader = [
      {
        id: 'Campaign Name',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Campaign Name' && sortDirection === false) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[160px] sm:w-[14vw] h-fit value">
                <div>Campaign Name</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Campaign Name' && sortDirection === true) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[160px] sm:w-[14vw] h-fit value">
                <div>Campaign Name</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Campaign Name' || headerId === 'Campaign Name') {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[160px] sm:w-[14vw] h-fit value">
                <div>Campaign Name</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'campaign_name',
        cell: (props: any) => (
          <div className="flex justify-start items-center text-xs gap-2 font-inter w-[160px] sm:w-[14vw] h-fit value">
            <div className=" w-[150px] sm:max-w-[14vw] truncate ">
              {props.getValue()}
            </div>
          </div>
        ),
      },
      {
        id: 'Ad Revenue',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Ad Revenue' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
                <div>Ad Spend</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Ad Revenue' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
                <div>Ad Revenue</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Ad Revenue' || headerId === 'Ad Revenue') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
                <div>Ad Revenue</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'revenue',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[160px] sm:w-[8vw] h-fit">
            {formatToCurrency(props.getValue())}
          </div>
        ),
      },
    ];

    const totalLeadsTableHeader = [
      {
        id: 'Campaign Name',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Campaign Name' && sortDirection === false) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[14vw] h-fit value">
                <div>Campaign Name</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Campaign Name' && sortDirection === true) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[14vw] h-fit value">
                <div>Campaign Name</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Campaign Name' || headerId === 'Campaign Name') {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[14vw] h-fit value">
                <div>Campaign Name</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'campaign_name',
        cell: (props: any) => (
          <div className="flex justify-start items-center text-xs gap-2 font-inter w-[14vw] h-fit value">
            <div className=" max-w-[14vw] truncate ">{props.getValue()}</div>
          </div>
        ),
      },
      {
        id: 'Total Leads',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Total Leads' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[8vw] h-fit">
                <div>Total Leads</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Total Leads' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[8vw] h-fit">
                <div>Total Leads</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Total Leads' || headerId === 'Total Leads') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[8vw] h-fit">
                <div>Total Leads</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'leads',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[8vw] h-fit">
            {formatNumber(props.getValue())}
          </div>
        ),
      },
    ];

    const data = (campaignInsights?.overview || []).map((resource) => ({
      campaign_name: resource.campaign_name,
      spend: Number(resource.spend) || 0,
      revenue: Number(resource.revenue) || 0,
      leads: Number(resource.leads) || 0,
    }));

    return {
      spendTableHeader,
      revenueTableHeader,
      totalLeadsTableHeader,
      data,
    };
  }, [campaignInsights?.overview]);

  const spendBreakdownData = campaignInsights.overview?.map((resource) => ({
    campaign_name: resource.campaign_name,
    spend: Number(resource.spend),
  }));

  const spendBreakdownCategories = campaignInsights.overview?.map(
    (item) => item.campaign_name
  );

  // end of variables for static data

  const revenueEvolutionData = campaignInsights.overview?.map((resource) => ({
    campaign_name: resource.campaign_name,
    revenue: Number(resource.revenue),
  }));

  const leadGenEvolutionData = campaignInsights.overview?.map((resource) => ({
    campaign_name: resource.campaign_name,
    Leads: Number(resource.leads),
  }));

  const LoadingSkeletonTableCard = () => (
    <div
      role="status"
      className="w-full space-y-4  divide-y divide-gray-200 rounded  animate-pulse dark:divide-gray-700  dark:border-gray-700 mt-8"
    >
      <div className="flex items-center justify-between">
        <div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
          <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
          <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
          <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
          <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
          <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
      </div>
      <span className="sr-only">Loading...</span>
    </div>
  );

  const retryFetchFbInsights = useRetryFetchFbInsights();
  const retryFetchFbCampaign = useRetryFetchFbCampaign();

  return (
    <div>
      <div className="flex justify-start items-center mb-4">
        <h2 className="title-text text-base sm:text-[1.85rem] pb-1 hidden sm:block">
          Acquisition Overview
        </h2>
      </div>
      {isLeadGen ? (
        <>
          <Grid numItemsMd={1} numItemsLg={2} className="gap-4">
            <Col>
              <Card className="flex items-center gap-8 px-3 sm:px-6 shadow-none lg:shadow ring-0 lg:ring-1">
                <BarChartCard
                  name="Ad spend, CVR grouped by Auto time breakdown"
                  data={spendCVRData}
                  categories={spendCVRCategories}
                  showLegend={true}
                  loading={accountInsights.loading}
                  error={accountInsights.error}
                  info={
                    accountInsights?.website_leads?.info
                      ? accountInsights?.website_leads?.info
                      : 'Ad spend, CVR grouped by Auto time breakdown'
                  }
                  hasPercent={true}
                  icon="meta"
                  propertyId={`Ads ID: ${selectedClient?.facebook_ads?.ads_id}`}
                  retryMethod={retryFetchFbInsights}
                />
              </Card>
            </Col>
            <Col>
              <Card className="flex items-center gap-8 px-3 sm:px-6 shadow-none lg:shadow ring-0 lg:ring-1">
                <BarChartCard
                  name="Total Leads, CPL grouped by Auto time breakdown"
                  data={totalLeadsCPLData}
                  categories={totalLeadsCPLCategories}
                  showLegend={true}
                  loading={accountInsights.loading}
                  error={accountInsights.error}
                  info={
                    accountInsights?.roas?.info
                      ? accountInsights?.roas?.info
                      : 'Total Leads, CPL grouped by Auto time breakdown'
                  }
                  icon="meta"
                  propertyId={`Ads ID: ${selectedClient?.facebook_ads?.ads_id}`}
                  retryMethod={retryFetchFbInsights}
                />
              </Card>
            </Col>
          </Grid>
          {/* // Ad Spend by Campaign */}
          <div className="flex w-full gap-4 mt-4 flex-wrap">
            <Card className="md:w-full lg:w-[33%] px-3 sm:px-6 shadow-none lg:shadow ring-0 lg:ring-1">
              <div className="flex justify-between">
                <div className="flex gap-2 items-center mb-1 w-full">
                  <h2 className="text-[14px] text-[#001C44] font-semibold dark:text-white">
                    Ad spend by Campaign
                  </h2>
                  <InfoTooltip
                    content={
                      campaignInsights?.overview_info
                        ? campaignInsights?.overview_info
                        : 'Ad spend by Campaign'
                    }
                    position="top"
                  >
                    <Icon name="InfoCircle" size={14} />
                  </InfoTooltip>
                </div>
                <Tooltip
                  content={`Ads ID: ${selectedClient?.facebook_ads?.ads_id}`}
                  position="top"
                  textAlign="text-center"
                >
                  <div className="pt-1">
                    <Icon name={`meta`} size={25} />
                  </div>
                </Tooltip>
              </div>
              {campaignInsights.loading ? (
                <LoadingSkeletonTableCard />
              ) : (
                <div>
                  <AnalyticsTable
                    data={
                      campaignInsightsData.data ? campaignInsightsData.data : []
                    }
                    columns={campaignInsightsData.spendTableHeader}
                    totalDocs={campaignInsightsData.data.length}
                    sortByColumnId="Ad Spend"
                    cardTable={true}
                    error={campaignInsights.error}
                  />
                </div>
              )}
            </Card>
            <div className="sm:w-full md:w-full lg:w-[65.6%]">
              {/* <BarChartCard
            name="Ad spend Evolution"
            data={groupBreakdownData}
            categories={groupBreakdownCategories}
            showLegend={true}
          /> */}
              <DonutBarGroup
                data={spendBreakdownData}
                category="spend"
                categories={spendBreakdownCategories}
                title="Ad Spend Evolution"
                customClass="h-[391px]"
                index="campaign_name"
                loading={campaignInsights.loading}
                error={accountInsights.error}
                info={'Ad Spend Evolution'}
                icon="meta"
                propertyId={`Ads ID: ${selectedClient?.facebook_ads?.ads_id}`}
              />
            </div>
          </div>
          {/* // Revenue by Campaign */}
          <div className="flex w-full gap-4 mt-4 flex-wrap">
            <Card className="md:w-full lg:w-[33%] px-3 sm:px-6 shadow-none lg:shadow ring-0 lg:ring-1">
              <div className="flex justify-between">
                <div className="flex gap-2 items-center mb-1 w-full">
                  <h2 className="text-[14px] text-[#001C44] font-semibold dark:text-white">
                    Total Leads by Campaign
                  </h2>
                  <InfoTooltip content={`Revenue by Campaign`} position="top">
                    <Icon name="InfoCircle" size={14} />
                  </InfoTooltip>
                </div>
                <Tooltip
                  content={`Ads ID: ${selectedClient?.facebook_ads?.ads_id}`}
                  position="top"
                  textAlign="text-center"
                >
                  <div className="pt-1">
                    <Icon name={`meta`} size={25} />
                  </div>
                </Tooltip>
              </div>
              {campaignInsights.loading ? (
                <LoadingSkeletonTableCard />
              ) : (
                <div className="">
                  <AnalyticsTable
                    data={
                      campaignInsightsData.data ? campaignInsightsData.data : []
                    }
                    columns={campaignInsightsData.totalLeadsTableHeader}
                    totalDocs={campaignInsightsData.data.length}
                    sortByColumnId="Total Leads"
                    cardTable={true}
                    error={campaignInsights.error}
                  />
                </div>
              )}
            </Card>
            <div className="sm:w-full md:w-full lg:w-[65.6%]">
              <DonutBarGroup
                data={leadGenEvolutionData}
                category="Leads"
                categories={spendBreakdownCategories}
                title="Lead Generation Evolution - Total Leads"
                customClass="h-[391px]"
                index="campaign_name"
                loading={campaignInsights.loading}
                error={accountInsights.error}
                info={'Lead Generation Evolution - Total Leads'}
                icon="meta"
                propertyId={`Ads ID: ${selectedClient?.facebook_ads?.ads_id}`}
              />
              {/* 
          <BarChartCard
            name="Revenue Evolution"
            data={groupBreakdownData}
            categories={groupBreakdownCategories}
            showLegend={true}
          /> */}
            </div>
          </div>
        </>
      ) : (
        <>
          <Grid numItemsMd={1} numItemsLg={2} className="gap-4">
            <Col>
              <Card className="flex items-center gap-8 px-3 sm:px-6 shadow-none lg:shadow ring-0 lg:ring-1">
                <BarChartCard
                  name="Ad spend, ROAS grouped by Auto time breakdown"
                  data={spendRoasData}
                  categories={spendRoasCategories}
                  showLegend={true}
                  loading={accountInsights.loading}
                  error={accountInsights.error}
                  info={
                    accountInsights?.spend?.info
                      ? accountInsights?.spend?.info
                      : 'Ad spend, ROAS grouped by Auto time breakdown'
                  }
                  icon="meta"
                  propertyId={`Ads ID: ${selectedClient?.facebook_ads?.ads_id}`}
                  retryMethod={retryFetchFbInsights}
                />
              </Card>
            </Col>
            <Col>
              <Card className="flex items-center gap-8 px-3 sm:px-6 shadow-none lg:shadow ring-0 lg:ring-1">
                <BarChartCard
                  name="Purchases, Revenue grouped by Auto time breakdown"
                  data={purchaseRevenueData}
                  categories={purchaseRevenueCategories}
                  showLegend={true}
                  loading={accountInsights.loading}
                  error={accountInsights.error}
                  info={
                    accountInsights?.roas?.info
                      ? accountInsights?.roas?.info
                      : 'Purchases, Revenue grouped by Auto time breakdown'
                  }
                  icon="meta"
                  propertyId={`Ads ID: ${selectedClient?.facebook_ads?.ads_id}`}
                  retryMethod={retryFetchFbInsights}
                />
              </Card>
            </Col>
          </Grid>
          {/* // Ad Spend by Campaign */}
          <div className="flex w-full gap-4 mt-4 flex-wrap">
            <Card className="md:w-full lg:w-[33%] px-3 sm:px-6 shadow-none lg:shadow ring-0 lg:ring-1">
              <div className="flex justify-between">
                <div className="flex gap-2 items-center mb-1 w-full">
                  <h2 className="text-[14px] text-[#001C44] font-semibold dark:text-white">
                    Ad spend by Campaign
                  </h2>
                  <InfoTooltip
                    content={
                      campaignInsights?.overview_info
                        ? campaignInsights?.overview_info
                        : 'Ad spend by Campaign'
                    }
                    position="top"
                  >
                    <Icon name="InfoCircle" size={14} />
                  </InfoTooltip>
                </div>
                <Tooltip
                  content={`Ads ID: ${selectedClient?.facebook_ads?.ads_id}`}
                  position="top"
                  textAlign="text-center"
                >
                  <div className="pt-1">
                    <Icon name={`meta`} size={25} />
                  </div>
                </Tooltip>
              </div>
              {campaignInsights.loading ? (
                <LoadingSkeletonTableCard />
              ) : (
                <div>
                  <AnalyticsTable
                    data={
                      campaignInsightsData.data ? campaignInsightsData.data : []
                    }
                    columns={campaignInsightsData.spendTableHeader}
                    totalDocs={campaignInsightsData.data.length}
                    sortByColumnId="Ad Spend"
                    cardTable={true}
                    error={campaignInsights.error}
                    retryMethod={retryFetchFbCampaign}
                  />
                </div>
              )}
            </Card>
            <div className="w-full lg:w-[65.6%]">
              {/* <BarChartCard
            name="Ad spend Evolution"
            data={groupBreakdownData}
            categories={groupBreakdownCategories}
            showLegend={true}
          /> */}
              <DonutBarGroup
                data={spendBreakdownData}
                category="spend"
                categories={spendBreakdownCategories}
                title="Ad Spend Distribution"
                customClass="h-[391px]"
                index="campaign_name"
                loading={campaignInsights.loading}
                error={accountInsights.error}
                info={'Ad Spend Distribution'}
                icon="meta"
                propertyId={`Ads ID: ${selectedClient?.facebook_ads?.ads_id}`}
                retryMethod={retryFetchFbCampaign}
              />
            </div>
          </div>
          {/* // Revenue by Campaign */}
          <div className="flex w-full gap-4 mt-4 flex-wrap">
            <Card className="md:w-full lg:w-[33%] px-3 sm:px-6 shadow-none lg:shadow ring-0 lg:ring-1">
              <div className="flex justify-between">
                <div className="flex gap-2 items-center mb-1 w-full">
                  <h2 className="text-[14px] text-[#001C44] font-semibold dark:text-white">
                    Revenue by Campaign
                  </h2>
                  <InfoTooltip content={`Revenue by Campaign`} position="top">
                    <Icon name="InfoCircle" size={14} />
                  </InfoTooltip>
                </div>
                <Tooltip
                  content={`Ads ID: ${selectedClient?.facebook_ads?.ads_id}`}
                  position="top"
                  textAlign="text-center"
                >
                  <div className="pt-1">
                    <Icon name={`meta`} size={25} />
                  </div>
                </Tooltip>
              </div>
              {campaignInsights.loading ? (
                <LoadingSkeletonTableCard />
              ) : (
                <div className="">
                  <AnalyticsTable
                    data={
                      campaignInsightsData.data ? campaignInsightsData.data : []
                    }
                    columns={campaignInsightsData.revenueTableHeader}
                    totalDocs={campaignInsightsData.data.length}
                    sortByColumnId="Ad Revenue"
                    cardTable={true}
                    error={campaignInsights.error}
                    retryMethod={retryFetchFbCampaign}
                  />
                </div>
              )}
            </Card>
            <div className="w-full lg:w-[65.6%]">
              <DonutBarGroup
                data={revenueEvolutionData}
                category="revenue"
                categories={spendBreakdownCategories}
                title="Revenue Evolution"
                customClass="h-[391px]"
                index="campaign_name"
                loading={campaignInsights.loading}
                error={accountInsights.error}
                info={'Revenue Evolution'}
                icon="meta"
                propertyId={`Ads ID: ${selectedClient?.facebook_ads?.ads_id}`}
                retryMethod={retryFetchFbCampaign}
              />
              {/* 
          <BarChartCard
            name="Revenue Evolution"
            data={groupBreakdownData}
            categories={groupBreakdownCategories}
            showLegend={true}
          /> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AcquisitionOverview;
