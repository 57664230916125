import { Card } from '@tremor/react';
import Icon from '../../../../assets/icons/SvgComponent';
import AnalyticsTable from '../../AnalyticsTable';

interface IProps {
  campaignsReport: any;
  adGroupsReportData: any;
}

const MobileView = ({ adGroupsReportData, campaignsReport }: IProps) => {
  return (
    <>
      {adGroupsReportData?.data?.length === 0 && !campaignsReport.loading ? (
        <Card className="card-item px-3 sm:px-6 shadow-none lg:shadow ring-0 lg:ring-1">
          <div className="flex flex-col items-center justify-center w-full my-8 h-[148px]">
            <div className="mb-8">
              <Icon name="NoDataAdGroup" />
            </div>
            <p className="font-inter font-[600] text-[20px] text-[#001C44] dark:text-white">
              No Ad Groups currently available.
            </p>
            <p className="text-center w-[70vw] text-[20px] text-[#001C44] dark:text-white">
              Stay ahead by exploring the newest trends and updates in your
              field. Our focused section delivers key insights and significant
              advancements directly relevant to your ad strategies
            </p>
          </div>
        </Card>
      ) : (
        <AnalyticsTable
          data={adGroupsReportData?.data}
          columns={adGroupsReportData?.tableHeader}
          loading={campaignsReport.loading}
          totalDocs={
            adGroupsReportData?.data ? adGroupsReportData?.data.length : 'N/A'
          }
          sortByColumnId="Cost"
          pagination={false}
        />
      )}
    </>
  );
};

export default MobileView;
