import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const WorkCompleted: React.FC<IconProps> = ({ color = 'black', size = 16 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_760_39479)">
      <path
        d="M10.6666 5.33301V3.46634C10.6666 2.7196 10.6666 2.34624 10.5213 2.06102C10.3934 1.81014 10.1895 1.60616 9.93857 1.47833C9.65336 1.33301 9.27999 1.33301 8.53325 1.33301H3.46659C2.71985 1.33301 2.34648 1.33301 2.06126 1.47833C1.81038 1.60616 1.60641 1.81014 1.47858 2.06102C1.33325 2.34624 1.33325 2.7196 1.33325 3.46634V8.53301C1.33325 9.27974 1.33325 9.65311 1.47858 9.93833C1.60641 10.1892 1.81038 10.3932 2.06126 10.521C2.34648 10.6663 2.71985 10.6663 3.46659 10.6663H5.33325M7.99992 9.99967L9.33325 11.333L12.3333 8.33301M7.46659 14.6663H12.5333C13.28 14.6663 13.6534 14.6663 13.9386 14.521C14.1895 14.3932 14.3934 14.1892 14.5213 13.9383C14.6666 13.6531 14.6666 13.2797 14.6666 12.533V7.46634C14.6666 6.7196 14.6666 6.34624 14.5213 6.06102C14.3934 5.81014 14.1895 5.60616 13.9386 5.47833C13.6534 5.33301 13.28 5.33301 12.5333 5.33301H7.46659C6.71985 5.33301 6.34648 5.33301 6.06126 5.47833C5.81038 5.60616 5.60641 5.81014 5.47858 6.06102C5.33325 6.34624 5.33325 6.7196 5.33325 7.46634V12.533C5.33325 13.2797 5.33325 13.6531 5.47858 13.9383C5.60641 14.1892 5.81038 14.3932 6.06126 14.521C6.34648 14.6663 6.71985 14.6663 7.46659 14.6663Z"
        stroke={color || '#334969'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_760_39479">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default WorkCompleted;
