import React from 'react';
import { AreaChart, Card } from '@tremor/react';
import moment from 'moment';
import { formatNumber } from '../../../utils/common';

interface IProps {
  performance?: any;
  type?: any;
}

const Charts: React.FC<IProps> = ({ performance, type }) => {
  const metricMapping = {
    ppc_google: [
      { label: 'Clicks', key: 'clicks' },
      { label: 'Conversion Rate', key: 'conversionRate' },
      { label: 'Conversions', key: 'conversions' },
      { label: 'Conversions Value', key: 'conversions_value' },
      { label: 'Cost', key: 'cost' },
      { label: 'Cost Per Conversion', key: 'costPerConversion' },
      { label: 'CPC', key: 'cpc' },
      { label: 'CTR', key: 'ctr' },
      { label: 'Impressions', key: 'impressions' },
      { label: 'ROAS', key: 'roas' },
    ],
    seo_google: [
      { label: 'Organic Conversions', key: 'organic_conversions' },
      { label: 'Organic Revenue', key: 'organic_revenue' },
      { label: 'Organic Users', key: 'organic_new_users' },
      { label: 'New Users', key: 'new_users' },
    ],
    social_meta: [
      { label: 'Impressions', key: 'impressions' },
      { label: 'Reach', key: 'reach' },
      { label: 'Frequency', key: 'frequency' },
      { label: 'CPM', key: 'cpm' },
      { label: 'Link Clicks', key: 'link_clicks' },
      { label: 'CPC', key: 'cpc' },
      { label: 'CTR', key: 'ctr' },
      { label: 'Website Leads', key: 'website_leads' },
      { label: 'CPL', key: 'cpl' },
      { label: 'CVR', key: 'cvr' },
      { label: 'Revenue', key: 'revenue' },
      { label: 'ROAS', key: 'roas' },
    ],
  };

  const chartData = (metricKey: string) => {
    const metricsSource =
      type === 'ppc_google'
        ? performance?.client?.googleAds
        : type === 'seo_google'
          ? performance?.client?.ga4
          : performance?.client?.meta;

    return metricsSource?.dailyMetrics?.[metricKey]?.dates?.map(
      (date: string, index: number) => ({
        date: moment(date).format('MMM DD'),
        [metricMapping[type].find((m) => m.key === metricKey)?.label]:
          metricsSource?.dailyMetrics?.[metricKey]?.values[index],
      })
    );
  };

  const formatMetricValue = (key: string) => {
    const metricsSource =
      type === 'ppc_google'
        ? performance?.client?.googleAds
        : type === 'seo_google'
          ? performance?.client?.ga4
          : performance?.client?.meta;

    return formatNumber(metricsSource?.[key]);
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4 p-1">
      {metricMapping[type]?.map(({ label, key }) => {
        return (
          <Card key={key} className="relative p-4">
            <span className="dark:text-gray-400 absolute top-5 left-4 text-black">
              {formatMetricValue(key)}
            </span>
            <AreaChart
              className="h-72"
              data={chartData(key)}
              index="date"
              yAxisWidth={45}
              categories={[label]}
              colors={['indigo', 'cyan']}
              valueFormatter={(value) => formatNumber(value)}
            />
          </Card>
        );
      })}
    </div>
  );
};

export default Charts;
