import styled from 'styled-components';

export const Wrapper = styled.div(({ style }) => ({
  position: 'absolute',
  right: style ? style.right : 20,
  top: style ? style.top : 20,
  input: {
    padding: 3,
    width: 100,
    textAlign: 'center',
  },
  '.react-datepicker': {
    width: 'max-content',
    marginLeft: -108,
  },
  '.react-datepicker__triangle': {
    left: '60px !important',
    ':before': {
      top: '0 !important',
    },
    ':after': {
      top: '1px !important',
    },
  },
}));
