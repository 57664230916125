import React, { useRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Services from '../../components/Services';
import useAuthStore from '../../store/useAuthStore';
import useClientStore from '../../store/useClientStore';
import useActivitiesStore from '../../store/useActivitiesStore';
import HomeHeader from '../../components/Dashboard/HomePageHeader';
import AccountManager from '../../components/AccountManager';
import { TaskGoals } from '../../components/TaskGoals';
import { IndustryUpdates } from '../../components/IndustryUpdates';
import { SpecialOffers } from '../../components/AnalyticsSections/SpecialOffers';
import ChannelKPI from '../../components/AnalyticsSections/TopChannelKPI';
import axios from 'axios';
import { Card } from '@tremor/react';
import HeaderTitle from '../../components/HeaderTitle';
const Dashboard: React.FC = () => {
  const location = useLocation();
  const scrollRef = useRef<any>(null);
  const { selectedClient, fetchClient } = useClientStore((state) => state);
  const { fetchTeamworkTasks } = useActivitiesStore((state) => state);

  const { currentUser, selectedDomain } = useAuthStore((state) => state);
  const [isMounted, setIsMounted] = useState(false);
  const [industryData, setIndustryData] = useState<any>([]);
  const [offersData, setOffersData] = useState<any>([]);

  // fetch industry updates data
  useEffect(() => {
    const fetchIndustryData = async () => {
      try {
        const response = await axios.get(
          'https://www.onlinemarketinggurus.com.au/wp-json/wp/v2/posts'
        );
        setIndustryData(response.data);
      } catch (error) {
        console.error('There was an error fetching the data:', error);
      }
    };
    const fetchOffersData = async () => {
      try {
        const response = await axios.get(
          'https://www.onlinemarketinggurus.com.au/wp-json/wp/v2/posts?categories=231'
        );
        setOffersData(response.data);
      } catch (error) {
        console.error('There was an error fetching the data:', error);
      }
    };
    fetchOffersData();
    fetchIndustryData();
  }, []);

  useEffect(() => {
    if (selectedDomain?.id) {
      fetchClient(selectedDomain?.id);
    }
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted) {
      fetchClient(selectedDomain?.id);
    }
    if (selectedDomain?.id) {
      fetchTeamworkTasks(selectedDomain?.id);
    }
  }, [selectedDomain]);

  // use ref to restore the scroll bar at top
  const scrollToTop = () => {
    const headerHeight = '64px';
    scrollRef.current.style.scrollMargin = headerHeight;
    return scrollRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollToTop();
    }
  }, [location.pathname]);

  return (
    <div
      ref={scrollRef}
      className="flex flex-col gap-6 px-2 sm:px-[32px] pb-8  dark:bg-[#0C111D]"
    >
      {/* <ComingSoon /> */}
      <HomeHeader name={currentUser?.first_name} />
      <AccountManager selectedClient={selectedClient} />
      <ChannelKPI />
      <TaskGoals />
      {/* <RecentWins /> */}
      <IndustryUpdates industryData={industryData} />
      <SpecialOffers offersData={offersData} />
      <div className="px-1">
        <Card className="dark:bg-[#222732] p-3 sm:p-6">
          <div className="flex w-full items-center mb-4 lg:mb-8">
            <HeaderTitle
              text="OMG Products & Services"
              fontSize="font-archivo text-lg sm:text-[24px] px-0"
            />
          </div>
          <Services />
        </Card>
      </div>
      {/* <OMGProductsServices /> */}
      {/* <PageHeader
        title={`Welcome back, ${currentUser?.first_name}`}
        subtitle="Drive smarter campaigns with data at your fingertips."
        hideDate
        domains={currentUser?.clients}
        customClass="pb-0"
      /> */}
      {/* <Overview
        servicesCount={servicesCount}
        completedTasks={breakDown?.total_tasks || 0}
        totalCommunications={totalCommunications}
      />

      <div
        id="communication"
        className={`flex flex-wrap gap-8 ${location.hash === '#communication' ? 'pt-20' : ''}`}
      >
        <AMCard selectedClient={selectedClient} />
        <Communications communications={communications} />
      </div>

      <div className="hr-separator"></div>

      <TasksComplete changeFilter={handleTaskFilter} tasks={tasks} />

      <div className="flex flex-row">
        <Services showHeader />
      </div> */}
    </div>
  );
};

export default Dashboard;
