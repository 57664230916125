import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import AreaCardChart from '../../AreaCardChart';
import { formatNumber, formatToCurrency } from '../../../../utils/common';

interface IProps {
  clicks?: any;
  ctr?: any;
  cpc?: any;
  dataInfo?: any;
  retryMethod?: () => void
}

const MobileView = ({ clicks, ctr, cpc, dataInfo, retryMethod }: IProps) => {
  const clicksTotal = clicks[clicks.length - 1]?.value;
  const ctrTotal = ctr[ctr.length - 1]?.value;
  const cpcTotal = cpc[cpc.length - 1]?.value;
  return (
    <div className="block sm:hidden mb-4">
      <div className="flex flex-col gap-4">
        <div className="p-0">
          <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
            <SwiperSlide>
              <AreaCardChart
                title="Link Clicks"
                chatData={clicks}
                category={['Link Clicks']}
                info={dataInfo?.clicks?.info}
                prevPeriod={dataInfo?.clicks?.previous_period}
                previousYear={dataInfo?.clicks?.previous_year}
                totalValue={formatNumber(clicksTotal)}
                isLoading={dataInfo.loading}
                error={dataInfo.error}
                icon="meta"
                accountInsights={true}
                retryMethod={retryMethod}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title="CTR"
                chatData={ctr}
                category={['Ctr']}
                info={dataInfo?.ctr?.info}
                prevPeriod={dataInfo?.ctr?.previous_period}
                previousYear={dataInfo?.ctr?.previous_year}
                totalValue={formatNumber(ctrTotal, true)}
                hasPercent={true}
                isLoading={dataInfo.loading}
                error={dataInfo.error}
                icon="meta"
                accountInsights={true}
                retryMethod={retryMethod}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title="CPC"
                chatData={cpc}
                category={['Cpc']}
                info={dataInfo?.cpc?.info}
                prevPeriod={dataInfo?.cpc?.previous_period}
                previousYear={dataInfo?.cpc?.previous_year}
                totalValue={formatToCurrency(cpcTotal)}
                hasDollar={true}
                isLoading={dataInfo.loading}
                error={dataInfo.error}
                icon="meta"
                accountInsights={true}
                retryMethod={retryMethod}
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default MobileView;
