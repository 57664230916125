import { Wrapper, Image } from './styles';
import userNotFound from '../../assets/images/no-results-found.png';
import Button from '../Button';

const UserNotFound = ({ onClose }) => {
  return (
    <Wrapper>
      <h1>Sorry, no results found.</h1>
      {onClose && (
        <Button variant="gray" onClick={onClose}>
          <span>Search Again</span>
        </Button>
      )}
    </Wrapper>
  );
};
export default UserNotFound;
