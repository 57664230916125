import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const BellIcon: React.FC<IconProps> = ({ color = 'black', size = 40 }) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width={size}
      height={size}
      rx="6"
      fill={color ? '#191919' : 'white'}
    />
    <path
      d="M17.7952 27.5C18.3828 28.0186 19.1547 28.3333 20.0001 28.3333C20.8454 28.3333 21.6173 28.0186 22.2049 27.5M25.0001 16.6667C25.0001 15.3406 24.4733 14.0688 23.5356 13.1311C22.5979 12.1934 21.3261 11.6667 20.0001 11.6667C18.674 11.6667 17.4022 12.1934 16.4645 13.1311C15.5268 14.0688 15.0001 15.3406 15.0001 16.6667C15.0001 19.2418 14.3504 21.005 13.6248 22.1712C13.0127 23.1549 12.7066 23.6467 12.7178 23.784C12.7303 23.9359 12.7624 23.9938 12.8849 24.0846C12.9954 24.1667 13.4939 24.1667 14.4908 24.1667H25.5093C26.5062 24.1667 27.0047 24.1667 27.1152 24.0846C27.2377 23.9938 27.2699 23.9359 27.2823 23.784C27.2935 23.6467 26.9875 23.1549 26.3753 22.1712C25.6497 21.005 25.0001 19.2418 25.0001 16.6667Z"
      stroke={color || '#334969'}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BellIcon;
