import { useState, useEffect } from 'react';
import { Card, Grid, Col, LineChart } from '@tremor/react';
import ReactSelect from '../../ReactSelect';
import UserSearch from '../../PerformanceInsights/UserSearchbar';
import usePerformanceStore from '../../../store/usePerformanceStore';
import Icon from '../../../assets/icons/SvgComponent';
import MissingIntegrationCards from '../MissingIntegration';

type CustomTooltip = {
  payload: any;
  active: boolean | undefined;
  label: any;
  hasPercent?: boolean;
};

const dataLineFormatter = (number: number) =>
  Intl.NumberFormat('us').format(number).toString();

const today = new Date();
const previousMonth = new Date(today.setMonth(today.getMonth() - 1));
const month = previousMonth.toLocaleString('default', { month: 'long' });

const PerformanceInsightsStatus = () => {
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [insightsStatusService, setInsightsStatusService] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const { fetchInsightsStatus, insightsStatus } = usePerformanceStore(
    (state) => state
  );

  const handleFetchInsightstatus = () => {
    const payload = {
      userId: selectedUserId,
      service: insightsStatusService?.value,
    };
    fetchInsightsStatus(payload);
  };

  useEffect(() => {
    handleFetchInsightstatus();
  }, [selectedUserId, insightsStatusService]);

  const statusData = insightsStatus?.dates?.map((date, index) => {
    const { green = {}, amber = {}, red = {} } = insightsStatus.values[index];

    return {
      date,
      Green: (green['Lead Gen'] || 0) + (green['Ecommerce'] || 0),
      green: {
        'Lead Gen': green['Lead Gen'] || 0,
        Ecommerce: green['Ecommerce'] || 0,
      },
      Amber: (amber['Lead Gen'] || 0) + (amber['Ecommerce'] || 0),
      amber: {
        'Lead Gen': amber['Lead Gen'] || 0,
        Ecommerce: amber['Ecommerce'] || 0,
      },
      red: {
        'Lead Gen': red['Lead Gen'] || 0,
        Ecommerce: red['Ecommerce'] || 0,
      },
      Red: (red['Lead Gen'] || 0) + (red['Ecommerce'] || 0),
    };
  });

  const statusCustomTooltip = (props: CustomTooltip) => {
    const { payload, active, label } = props;
    if (!active || !payload || payload.length === 0) return null;

    const payloadItem = payload[0];
    const amberData = payloadItem.payload.amber;
    const greenData = payloadItem.payload.green;
    const redData = payloadItem.payload.red;

    return (
      <div className="w-fit rounded-tremor-default border border-tremor-border bg-tremor-background dark:bg-dark-tremor-background dark:ring-dark-tremor-ring dark:shadow-dark-tremor-card dark:border-gray-400 p-2 text-tremor-default shadow-tremor-dropdown">
        <div className="flex flex-1 space-x-2.5">
          <div className="w-full">
            <p className="mb-2 font-medium text-tremor-content-emphasis dark:text-white">
              {label}
            </p>
            <div className={`flex flex-col space-y-2 font-bold`}>
              <div className="flex items-center text-[10px]">
                <div className="w-2 h-2 rounded-full bg-[#22C55E]" />
                <div>
                  <span className="ml-1 rounded text-[14px] px-1 dark:text-white font-[500]">
                    <span className="border-b mr-3">Lead Gen</span>
                    {greenData?.['Lead Gen'] || 0}
                  </span>
                  <span className="ml-1 rounded  text-[14px] px-1 dark:text-white font-[500]">
                    <span className="border-b mr-3">Ecommerce</span>
                    {greenData?.Ecommerce || 0}
                  </span>
                </div>
              </div>
              <div className="flex items-center text-xs">
                <div className="w-2 h-2 rounded-full bg-[#F59E0B]" />
                <div>
                  <span className="ml-1 rounded text-[14px] px-1 dark:text-white font-[500]">
                    <span className="border-b mr-3">Lead Gen</span>
                    {amberData?.['Lead Gen'] || 0}
                  </span>
                  <span className="ml-1 rounded text-[14px] px-1 dark:text-white font-[500]">
                    <span className="border-b mr-3">Ecommerce</span>
                    {amberData?.Ecommerce || 0}
                  </span>
                </div>
              </div>
              <div className="flex items-center text-xs">
                <div className="w-2 h-2 rounded-full bg-[#F43F5E]" />
                <div>
                  <span className="ml-1 rounded text-[14px] px-1 dark:text-white font-[500]">
                    <span className="border-b mr-3">Lead Gen</span>
                    {redData?.['Lead Gen'] || 0}
                  </span>
                  <span className="ml-1 rounded text-[14px] px-1 dark:text-white font-[500]">
                    <span className="border-b mr-3">Ecommerce</span>
                    {redData?.Ecommerce || 0}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Card>
        <div className="font-[700] text-[18px] text-[#001C44] mb-4 dark:text-gray-300">
          Performance by Clients for {month}
        </div>
        <Grid
          className="gap-4 mb-4"
          numItems={1}
          numItemsSm={1}
          numItemsMd={3}
          numItemsLg={3}
        >
          <Col numColSpanLg={2}>
            <UserSearch
              setSelectedUserId={setSelectedUserId}
              selectedUserId={selectedUserId}
            />
          </Col>
          <Col numColSpanLg={1}>
            <ReactSelect
              value={insightsStatusService}
              options={[
                { value: '', label: 'All Service' },
                { value: 'seo_google', label: 'Google SEO' },
                { value: 'ppc_google', label: 'Google Ads' },
                { value: 'social_meta', label: 'Meta' },
              ]}
              handleOnChange={(selectedOption: any) => {
                setInsightsStatusService(selectedOption);
              }}
              placeholder="All Service"
            />
          </Col>
        </Grid>
        {insightsStatus.loading ? (
          <div className="w-full min-h-[320px] h-full flex justify-center items-center">
            <Icon name="Loading" size={60} />
          </div>
        ) : (
          <LineChart
            className="h-80"
            data={statusData}
            index="date"
            categories={['Green', 'Amber', 'Red']}
            colors={['green', 'amber', 'rose']}
            valueFormatter={dataLineFormatter}
            startEndOnly
            showAnimation
            yAxisWidth={30}
            customTooltip={statusCustomTooltip}
            onValueChange={(v) => console.log(v)}
          />
        )}
      </Card>
      <MissingIntegrationCards selectedUserId={selectedUserId} />
    </>
  );
};

export default PerformanceInsightsStatus;
