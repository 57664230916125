import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const SearchRefractionIcon: React.FC<IconProps> = ({
  color = '#80AECF',
  size = 80,
}) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.25 12.25L9.7125 9.7125M6.41667 3.5C8.0275 3.5 9.33333 4.80584 9.33333 6.41667M11.0833 6.41667C11.0833 8.994 8.994 11.0833 6.41667 11.0833C3.83934 11.0833 1.75 8.994 1.75 6.41667C1.75 3.83934 3.83934 1.75 6.41667 1.75C8.994 1.75 11.0833 3.83934 11.0833 6.41667Z"
      stroke={color || '#334969'}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SearchRefractionIcon;
