import { requests } from "./ApiService";

export const Crawler = {
  getCrawl: (filters) =>
    requests.get(
      `/api/v1/crawler?search=${filters.search}&page=${filters.page}&limit=${filters.limit}&perms=${filters.perms}&sort_by=${filters.sortBy}&sort_order=${filters.sortOrder}`
    ),
  getPreviousCrawls: (filters) =>
    requests.get(
      `/api/v1/crawler/history?search=${filters.search}&page=${filters.page}&limit=${filters.limit}&sort_by=${filters.sortBy}&sort_order=${filters.sortOrder}`
  ),
  addCrawl: (data) => requests.post('/api/v1/crawler/add', data),
};
