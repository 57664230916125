import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import useGoogleStore from '../../../store/useGoogleStore';
import AreaCardChart from '../AreaCardChart';
import { formatNumber, formatToCurrency } from '../../../utils/common';

interface UtilityStoreState {
  campaignReport: {
    label: string;
    value: number;
    id: string;
  };
}
interface IProps {
  retry?: () => void;
  isLeadGen?: boolean;
  adData?: any;
  clicks?: any;
  conversionRate?: any;
  conversions?: any;
  conversionsValue?: any;
  cost?: any;
  cpc?: any;
  costPerConversion?: any;
  ctr?: any;
  impressions?: any;
  totalRoas?: any;
}

const MobileView = ({
  retry,
  isLeadGen,
  adData,
  clicks,
  conversionRate,
  conversions,
  conversionsValue,
  cost,
  cpc,
  costPerConversion,
  ctr,
  impressions,
  totalRoas,
}: IProps) => {
  const { campaignsReport } = useGoogleStore((state) => state);

  const lastMonthClicks =
    Array.isArray(clicks) && clicks.length > 0
      ? clicks[clicks.length - 1]?.value
      : undefined;

  const lastMonthConversionRate =
    Array.isArray(conversionRate) && conversionRate.length > 0
      ? conversionRate[conversionRate.length - 1]?.value
      : undefined;

  const lastMonthConversions =
    Array.isArray(conversions) && conversions.length > 0
      ? conversions[conversions.length - 1]?.value
      : undefined;

  const lastMonthConversionsValue =
    Array.isArray(conversionsValue) && conversionsValue.length > 0
      ? conversionsValue[conversionsValue.length - 1]?.value
      : undefined;

  const lastMonthCost =
    Array.isArray(cost) && cost.length > 0
      ? cost[cost.length - 1]?.value
      : undefined;

  const lastMonthCostPerConversion =
    Array.isArray(costPerConversion) && costPerConversion.length > 0
      ? costPerConversion[costPerConversion.length - 1]?.value
      : undefined;

  const lastMonthCpc =
    Array.isArray(cpc) && cpc.length > 0
      ? cpc[cpc.length - 1]?.value
      : undefined;

  const lastMonthCtr =
    Array.isArray(ctr) && ctr.length > 0
      ? ctr[ctr.length - 1]?.value
      : undefined;

  const lastMonthImpressions =
    Array.isArray(impressions) && impressions.length > 0
      ? impressions[impressions.length - 1]?.value
      : undefined;

  const lastMonthTotalRoas =
    Array.isArray(totalRoas) && totalRoas.length > 0
      ? totalRoas[totalRoas.length - 1]?.value
      : undefined;

  return (
    <div className="block sm:hidden ">
      <div className="flex flex-col gap-4">
        <div className="p-0">
          <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
            <SwiperSlide className="h-[300px]">
              <AreaCardChart
                title="Clicks"
                chatData={clicks}
                category={['Clicks']}
                info={adData?.clicks?.info ? adData?.clicks?.info : 'Clicks'}
                prevPeriod={`${adData?.clicks?.prevPeriod}`}
                previousYear={`${adData?.clicks?.previousYear}`}
                isLoading={campaignsReport.loading}
                totalValue={formatNumber(lastMonthClicks)}
                error={campaignsReport.error}
                icon="googleAd"
                retryMethod={retry}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title="Conversion Rate"
                chatData={conversionRate}
                category={['Conversion Rate']}
                info={
                  adData?.conversionRate?.info
                    ? adData?.conversionRate?.info
                    : 'Conversion Rate'
                }
                prevPeriod={`${adData?.conversionRate?.prevPeriod}`}
                previousYear={`${adData?.conversionRate?.previousYear}`}
                isLoading={campaignsReport.loading}
                totalValue={formatNumber(lastMonthConversionRate, true)}
                hasPercent={true}
                error={campaignsReport.error}
                icon="googleAd"
                retryMethod={retry}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title="Conversions"
                chatData={conversions}
                category={['Conversions']}
                info={
                  adData?.conversions?.info
                    ? adData?.conversions?.info
                    : 'Conversions'
                }
                prevPeriod={`${adData?.conversions?.prevPeriod}`}
                previousYear={`${adData?.conversions?.previousYear}`}
                isLoading={campaignsReport.loading}
                totalValue={formatNumber(lastMonthConversions)}
                error={campaignsReport.error}
                icon="googleAd"
                retryMethod={retry}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title="Conversion Value"
                chatData={conversionsValue}
                category={['Conversions Value']}
                info={
                  adData?.conversionsValue?.info
                    ? adData?.conversionsValue?.info
                    : 'Conversion Value'
                }
                prevPeriod={`${adData?.conversionsValue?.prevPeriod}`}
                previousYear={`${adData?.conversionsValue?.previousYear}`}
                isLoading={campaignsReport.loading}
                totalValue={formatToCurrency(lastMonthConversionsValue)}
                hasDollar={true}
                error={campaignsReport.error}
                icon="googleAd"
                retryMethod={retry}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title="Cost"
                chatData={cost}
                category={['Cost']}
                info={adData?.cost?.info ? adData?.cost?.info : 'Cost'}
                prevPeriod={`${adData?.cost?.prevPeriod}`}
                previousYear={`${adData?.cost?.previousYear}`}
                isLoading={campaignsReport.loading}
                totalValue={formatToCurrency(lastMonthCost)}
                hasDollar={true}
                error={campaignsReport.error}
                icon="googleAd"
                retryMethod={retry}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title="Cost Per Click"
                chatData={cpc}
                category={['Cost Per Click']}
                info={adData?.cpc?.info ? adData?.cpc?.info : 'Cost Per Click'}
                // prevPeriod={`${adData?.cpc?.prevPeriod}`}
                // previousYear={`${adData?.cpc?.previousYear}`}
                prevPeriod={`${-adData?.cpc?.prevPeriod}`}
                previousYear={`${-adData?.cpc?.previousYear}`}
                isLoading={campaignsReport.loading}
                totalValue={formatToCurrency(lastMonthCpc)}
                hasDollar={true}
                error={campaignsReport.error}
                icon="googleAd"
                retryMethod={retry}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title="Cost Per Conversions"
                chatData={costPerConversion}
                category={['Cost Per Conversion']}
                info={
                  adData?.costPerConversion?.info
                    ? adData?.costPerConversion?.info
                    : 'Cost Per Conversions'
                }
                prevPeriod={`${-adData?.costPerConversion?.prevPeriod}`}
                previousYear={`${-adData?.costPerConversion?.previousYear}`}
                isLoading={campaignsReport.loading}
                totalValue={formatToCurrency(lastMonthCostPerConversion)}
                hasDollar={true}
                error={campaignsReport.error}
                icon="googleAd"
                retryMethod={retry}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title="Click-Through Rate"
                chatData={ctr}
                category={['Click-Through Rate']}
                info={
                  adData?.ctr?.info ? adData?.ctr?.info : 'Click-Through Rate'
                }
                prevPeriod={`${adData?.ctr?.prevPeriod}`}
                previousYear={`${adData?.ctr?.previousYear}`}
                isLoading={campaignsReport.loading}
                totalValue={formatNumber(lastMonthCtr, true)}
                hasPercent={true}
                error={campaignsReport.error}
                icon="googleAd"
                retryMethod={retry}
              />
            </SwiperSlide>
            <SwiperSlide>
              {' '}
              <AreaCardChart
                title="Impressions"
                chatData={impressions}
                category={['Impressions']}
                info={
                  adData?.impressions?.info
                    ? adData?.impressions?.info
                    : 'Impressions'
                }
                prevPeriod={`${adData?.impressions?.prevPeriod}`}
                previousYear={`${adData?.impressions?.previousYear}`}
                isLoading={campaignsReport.loading}
                totalValue={formatNumber(lastMonthImpressions)}
                error={campaignsReport.error}
                icon="googleAd"
                retryMethod={retry}
              />
            </SwiperSlide>
            {!isLeadGen && (
              <SwiperSlide>
                <AreaCardChart
                  title="Return on Ad Spend"
                  chatData={totalRoas}
                  category={['Return on Ad Spend']}
                  info={
                    adData?.totalRoas?.info
                      ? adData?.totalRoas?.info
                      : 'Return on Ad Spend'
                  }
                  prevPeriod={`${adData?.totalRoas?.prevPeriod}`}
                  previousYear={`${adData?.totalRoas?.previousYear}`}
                  isLoading={campaignsReport.loading}
                  totalValue={formatNumber(lastMonthTotalRoas)}
                  error={campaignsReport.error}
                  icon="googleAd"
                  retryMethod={retry}
                />
              </SwiperSlide>
            )}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default MobileView;
