import AreaCardChart from '../../AreaCardChart';
import {
  formatNumberWithCommas,
  formatToCurrency,
} from '../../../../utils/common';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

interface IProps {
  impressions?: any;
  reach?: any;
  cpm?: any;
  dataInfo?: any;
  retryMethod?: () => void;
}

const MobileView = ({ impressions, reach, cpm, dataInfo, retryMethod }: IProps) => {
  const lastMonthImp = impressions[impressions.length - 1]?.value;
  const lastMonthReach = reach[reach.length - 1]?.value;
  const lastMonthCPM = cpm[cpm.length - 1]?.value;

  return (
    <div className="block sm:hidden mb-4">
      <div className="flex flex-col gap-4">
        <div className="p-0">
          <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
            <SwiperSlide>
              <AreaCardChart
                title="Impressions"
                chatData={impressions}
                category={['Impressions']}
                info={dataInfo?.impressions?.info}
                prevPeriod={dataInfo?.impressions?.previous_period}
                previousYear={dataInfo?.impressions?.previous_year}
                totalValue={formatNumberWithCommas(lastMonthImp)}
                isLoading={dataInfo.loading}
                error={dataInfo.error}
                icon="meta"
                accountInsights={true}
                retryMethod={retryMethod}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title="Reach"
                chatData={reach}
                category={['Reach']}
                info={dataInfo?.reach?.info}
                prevPeriod={dataInfo?.reach?.previous_period}
                previousYear={dataInfo?.reach?.previous_year}
                totalValue={formatNumberWithCommas(lastMonthReach)}
                isLoading={dataInfo.loading}
                error={dataInfo.error}
                icon="meta"
                accountInsights={true}
                retryMethod={retryMethod}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title="CPM"
                chatData={cpm}
                category={['Cpm']}
                info={dataInfo?.cpm?.info}
                prevPeriod={dataInfo?.cpm?.previous_period}
                previousYear={dataInfo?.cpm?.previous_year}
                totalValue={formatToCurrency(lastMonthCPM)}
                isLoading={dataInfo.loading}
                hasDollar
                error={dataInfo.error}
                icon="meta"
                accountInsights={true}
                retryMethod={retryMethod}
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default MobileView;
