import styled from 'styled-components';
import { colors } from '../../utils/settings';
export const Wrapper = styled.div((props) => ({
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content',
  minWidth: 100,
  height: 35,
  background: '#f0eeee',
  borderRadius: 5,
  padding: '0 8px',
  marginLeft: props.styles?.marginLeft || 10,
  position: 'relative',
  cursor: 'pointer',
  outline: 'none',
  border: 'none',
  fontSize: 13,
  zIndex: 1,
  pointerEvents: props.disabled ? 'none' : '',
  opacity: props.disabled ? 0.7 : 1,
}));

export const DropDownList = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  width: '100%',
  // height:props.styles?.height || '100%',
  maxHeight: 200,
  top: 40,
  left: 0,
  borderRadius: 10,
  background: colors.white,
  boxShadow: '0 0 10px 2px rgba(0, 0, 0, 0.3)',
  overflowY: 'auto',
  span: {
    cursor: 'pointer',
    borderBottom: '1px solid #a0a0a0',
    padding: '8px 5px',
    fontSize: 14,
    color: '#666666',
    transition: 'background-color 0.2s ease',
    ':last-child': {
      borderBottom: 'none',
    },
    ':hover': {
      backgroundColor: '#c4c4c4',
    },
  },
  '::-webkit-scrollbar': {
    width: 8,
    height: 8,
    borderRadius: 4,
  },
  '::-webkit-scrollbar-thumb': {
    background: '#666666',
    borderRadius: 4,
  },
  '::-webkit-scrollbar-track': {
    borderRadius: 4,
  },
}));

export const Line = styled.div(() => ({
  height: 20,
  width: 1,
  background: '#000',
  margin: '0 8px 0 5px',
}));
