import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const LogoIcon: React.FC<IconProps> = ({ color = '#0029FF', size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 48 48"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    className="rounded-full"
  >
    <rect width="48" height="48" fill="#F5F5F5" />
    <g id="Light">
      <rect
        width="1512"
        height="982"
        transform="translate(-53 -40)"
        fill={color}
      />
      <g id="Side Navigation" filter="url(#filter0_d_554_3817)">
        <rect
          x="-29"
          y="-16"
          width="106"
          height="934"
          rx="12"
          fill={color === '#0029FF' ? '#FFF' : '#000'}
        />
        <path
          id="Logo"
          d="M43.6005 24.1974L38.0164 24.141V23.9716C38.0164 20.1363 36.4938 16.639 33.9556 14.1009L27.9766 19.9104L23.859 23.9151V23.859L19.7414 19.8544L13.8189 14.1009C11.3937 16.5825 9.87072 19.9669 9.75779 23.6896V23.9716C9.75779 25.3816 9.98365 26.7356 10.3785 28.0327C10.435 28.2586 10.5475 28.5405 10.604 28.766C11.2243 30.4019 12.1269 31.9248 13.2551 33.2784C13.368 33.4474 13.537 33.6168 13.7064 33.7862C16.2446 36.4369 19.8544 38.1293 23.859 38.1293C27.8637 38.1293 31.4735 36.4934 34.0681 33.7862C34.1246 33.7298 34.1811 33.6733 34.2375 33.6168H33.8991L23.9155 33.4474V28.2586L28.484 28.315L36.8322 28.4276H37.3396L43.0931 28.5405C42.7547 30.0635 42.1904 31.53 41.4572 32.94C41.3442 33.222 41.1752 33.4474 41.0623 33.7298C40.2726 35.1963 39.2574 36.4934 38.1293 37.678C34.5195 41.4007 29.4996 43.7134 23.972 43.7134C18.3879 43.7134 13.4241 41.4007 9.81425 37.7344C8.51714 36.4369 7.44549 34.9139 6.54283 33.2784C6.48637 33.1655 6.4303 33.109 6.4303 32.9965C5.64058 31.4735 5.01986 29.7811 4.68146 28.0327C4.39953 26.7356 4.2866 25.3816 4.2866 24.028V23.6332C4.39953 18.4439 6.48637 13.706 9.87072 10.2091C11.2243 8.79907 12.8037 7.67095 14.4961 6.71183L18.7262 10.8294L23.9155 15.8493L29.1048 10.8294L33.3349 6.71183C35.0273 7.67095 36.6063 8.85553 37.9603 10.2091C41.4007 13.7625 43.544 18.6133 43.544 23.9716L43.6005 24.1974ZM41.1188 7.21962C39.7652 5.80958 38.2422 4.625 36.6063 3.60981C32.94 1.29712 28.597 0 23.972 0C19.347 0 15.0035 1.29712 11.3372 3.60981C9.70172 4.625 8.17874 5.86604 6.82476 7.21962C2.70756 11.4498 0.112931 17.2033 0 23.5206V23.9716C0 25.3255 0.112933 26.6227 0.338397 27.9202C0.620327 29.7251 1.12812 31.4735 1.80491 33.1655C5.41472 41.852 13.9883 48 23.972 48C30.7403 48 36.8886 45.1799 41.2317 40.6674C42.5853 39.2574 43.7699 37.7344 44.7286 36.042C45.1799 35.3088 45.5183 34.5755 45.9132 33.7862C46.6464 32.1503 47.2103 30.4019 47.5487 28.597C47.831 27.1869 48 25.7204 48 24.2539V23.9716C47.9435 17.4852 45.3489 11.5627 41.1188 7.21962Z"
          fill={color}
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_554_3817"
        x="-49"
        y="-32"
        width="146"
        height="974"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="10" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0.617647 0 0 0 0 1 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_554_3817"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_554_3817"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default LogoIcon;
