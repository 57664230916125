import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const Action: React.FC<IconProps> = ({ color = '#313233', size = 20 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.49996 2.91675V1.66675M4.21718 4.2173L3.33329 3.33341M4.21718 10.8334L3.33329 11.7173M10.8333 4.2173L11.7172 3.33341M2.91663 7.50008H1.66663M13.2204 13.4914L11.1439 17.3476C10.9067 17.7881 10.7881 18.0084 10.6454 18.0641C10.5215 18.1125 10.3821 18.0988 10.2699 18.0274C10.1407 17.9451 10.0671 17.706 9.9198 17.2278L7.03762 7.87112C6.91729 7.4805 6.85713 7.28519 6.90554 7.15328C6.9477 7.03838 7.03825 6.94783 7.15315 6.90566C7.28507 6.85725 7.48038 6.91742 7.871 7.03774L17.2276 9.91996C17.7058 10.0673 17.9449 10.1409 18.0272 10.2701C18.0987 10.3822 18.1123 10.5217 18.064 10.6455C18.0083 10.7882 17.788 10.9068 17.3475 11.1441L13.4913 13.2205C13.4258 13.2557 13.3931 13.2734 13.3644 13.296C13.339 13.3161 13.316 13.3391 13.2959 13.3645C13.2732 13.3932 13.2556 13.4259 13.2204 13.4914Z"
      stroke={color}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Action;
