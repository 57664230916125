// @ts-ignore
import comingSoon from '../../assets/images/coming-soon.jpeg';

export default function Notification() {
  return (
    <div
      style={{
        display: 'flex',
        width: '90%',
        height: '100%',
        justifyContent: 'center',
        marginTop: '16%',
        zIndex: 1,
      }}
    >
      <img alt='coming-soon' width={400} height={200} src={comingSoon} />
    </div>
  );
}
