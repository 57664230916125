import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Icon from '../../../assets/icons/SvgComponent';

import Alert from '../../Alert';
import { useThemeStore } from '../../../store/useThemeStore';
import useReportStore from '../../../store/useReportStore';
import ButtonIcon from '../../ButtonIcon';

interface ImportCSVModalProps {
  onClose: (value: boolean) => void;
  reportDate?: any;
  tableData?: any;
  reportStatus?: any;
  handleFetchReports?: any;
  type?: any;
}

const ImportCSVModal: React.FC<ImportCSVModalProps> = ({
  onClose,
  reportDate,
  tableData,
  reportStatus,
  handleFetchReports,
  type,
}) => {
  const { mode } = useThemeStore((state: any) => state);
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const [saveError, setSaveError] = useState<string | boolean>(false);
  const [failedDomains, setFailedDomains] = useState<string[]>([]);
  const [status, setStatus] = useState<string | boolean>(false);
  const { uploadReportsFile, checkUploadStatus } = useReportStore(
    (state) => state
  );

  const iconColor = mode === 'dark' ? 'white' : 'black';

  const sendFileToUpload = () => {
    if (fileToUpload instanceof File) {
      const formData = new FormData();
      formData.append('csv_file', fileToUpload);
      formData.append('type', 'links');
      formData.append('date', reportDate);
      formData.append('report_type', type.id);

      // setStatus("Uploading your file, this may take a few moments...");
      setFailedDomains([]);

      uploadReportsFile(formData)
        .then((response: any) => {
          const message = `Successfully updated ${response?.created_and_updated?.length} domains`;
          if (response?.failed_domains?.length) {
            setFailedDomains(response.failed_domains);
            // setStatus(
            //   `Successfully updated ${response?.created_and_updated?.length} domains`
            // );
            toast.success(
              <Alert icon="" title={`Links Updated`} message={message} />,
              {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                containerId: 'modal-toast-container',
              }
            );
          } else {
            // setStatus(
            //   `Successfully updated ${response?.created_and_updated?.length} domains`
            // );
            toast.success(
              <Alert icon="" title={`Links Updated`} message={message} />,
              {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                containerId: 'modal-toast-container',
              }
            );
          }
          handleFetchReports('in_progress');
          setFileToUpload(null);
        })
        .catch((e: any) => {
          setSaveError(e.message);
          toast.error(
            <Alert icon="" title={`ERROR MESSAGE!`} message={`${e.message}`} />,
            {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              containerId: 'modal-toast-container',
            }
          );
          setStatus('');
          setFileToUpload(null);

          setTimeout(() => {
            setSaveError(false);
          }, 5000);
        });
    }
  };
  const importExcel = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file instanceof File) {
      setFileToUpload(file);
    }
  };

  return (
    <div
      className="fixed top-0 right-0 bg-white h-screen w-screen sm:w-[26rem] flex flex-col z-50 dark:bg-[#262627] dark:border dark:border-[#313233] dark:shadow-bs-card"
      style={{ minHeight: '800px' }}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="relative h-screen overflow-y-auto flex flex-col justify-between pb-4">
        <div className="max-h-[100dvh] overflow-y-hidden">
          <ToastContainer containerId="modal-toast-container" />
          <div className="px-6 flex  items-center  border-b border-tertiary-colours-neutral-cool-100 justify-between">
            <h2 className="font-inter text-[#001129] text-xl font-semibold my-6  dark:text-[#E6EFF5]">
              Import Links
            </h2>
            <div className="my-4 overflow-hidden flex justify-center">
              {/* {isLoading && <Icon name='Loading' />} */}
            </div>
            <button onClick={() => onClose(false)}>
              <Icon name="Xclose" color={iconColor} />
            </button>
          </div>
          <div className="flex flex-col p-6 gap-2">
            <span className="text-[#001C44] text-sm dark:text-gray-300">
              Import links for {reportDate}?
            </span>
            {!status && (
              <div className="flex flex-col items-center justify-center gap-2 py-4 px-14  border border-[#EAECF0] rounded-xl">
                <div className="p-2 border border-[#808EA2] rounded-lg">
                  <Icon
                    name="uploadCloud"
                    color={mode === 'dark' ? '#fff' : iconColor}
                  />
                </div>
                <input
                  style={{ display: 'none' }}
                  type="file"
                  name="file"
                  id="file"
                  accept=".csv"
                  onChange={importExcel}
                />
                <label
                  htmlFor="file"
                  className="text-[#475467]  text-sm dark:text-gray-300"
                >
                  <span className="text-[#005C9F] font-semibold cursor-pointer">
                    Click to upload
                  </span>{' '}
                  CSV
                </label>
                <span className="dark:text-white">
                  {fileToUpload instanceof File
                    ? fileToUpload.name
                    : 'No file selected'}
                </span>
              </div>
            )}
            {saveError && !fileToUpload && (
              <h3 style={{ fontSize: 12, color: 'red' }}>{saveError}</h3>
            )}
            {/* {status && (
               <Alert icon='' title={`ERROR MESSAGE!`} message={`${status}`} />
            )}
            {saveError && <Alert icon='' title={`ERROR MESSAGE!`} message={`${saveError}`} />} */}
            {failedDomains?.length ? (
              <div style={{ maxHeight: 200, overflow: 'auto', marginTop: 10 }}>
                <span style={{ fontWeight: '700', fontSize: 12, color: 'red' }}>
                  Below {failedDomains?.length} domains are not found, Please
                  double check the file and the domains:
                </span>
                <ul style={{ marginTop: 5 }}>
                  {failedDomains?.map((item, i) => {
                    return (
                      <li style={{ textAlign: 'left', marginLeft: 5 }} key={i}>
                        {item}
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
        <div className="px-6 py-4 w-full flex justify-center items-center border-t border-tertiary-colours-neutral-cool-100">
          <ButtonIcon
            disabled={!fileToUpload}
            color={'#005C9F'}
            onClick={sendFileToUpload}
            style={{
              width: '100%',
              justifyContent: 'center',
              padding: '20px',
              alingItems: 'center',
              opacity: fileToUpload ? 1 : 0.5,
              cursor: fileToUpload ? 'pointer' : 'not-allowed',
            }}
            variant="contained"
          >
            Upload
          </ButtonIcon>
        </div>
      </div>
    </div>
  );
};

export default ImportCSVModal;
