import React from 'react';

interface DotProps {
  color?: string;
}

const Dot: React.FC<DotProps> = ({ color = '#428B6E' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
  >
    <circle cx="4" cy="4" r="3" fill={color} />
  </svg>
);

export default Dot;
