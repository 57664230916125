import React from 'react';

interface OverviewProps {
  color?: string;
  size?: number;
}

const Overview: React.FC<OverviewProps> = ({ color = 'black', size = 16 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 8H10M2 4H14M2 12H14"
      stroke={color || '#334969'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Overview;
