import styled from 'styled-components';
import { device } from '../../utils/settings';
export const Container = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  margin: '0px 50px',
  marginTop: '30px',
  position: 'relative',
  backgroundImage: `url()`,
  backgroundPosition: 'center',
  backgroundAttachment: 'fixed',
  ':before': {
    inset: 0,
    content: '""',
    position: 'absolute',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
  },
  h1: {
    fontSize: '24px',
    fontWeight: 700,
    marginBottom: '20px',
  },
}));

export const ActionWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignContent: 'center',
  justifyContent: 'space-between',
  width: 80,
  paddingLeft: 10,
  svg: {
    cursor: 'pointer',
  },
});
export const FilterWrapper = styled.div({
  display: 'flex',
  position: 'sticky',
  top: 0,
  zIndex: 1,
  marginBottom: 15,
  [device.sm]: {
    zIndex: 2,
    width: 'fit-content',
  },
});
