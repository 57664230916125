import React from 'react';

interface DotProps {
  color?: string;
  size?: number;
}

const LinkExternal: React.FC<DotProps> = ({ color = '#313233', size = 20 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.8334 7.5L17.8334 2.5M17.8334 2.5H12.8334M17.8334 2.5L11.1667 9.16667M8.66671 4.16667H6.83337C5.43324 4.16667 4.73318 4.16667 4.1984 4.43915C3.72799 4.67883 3.34554 5.06129 3.10586 5.53169C2.83337 6.06647 2.83337 6.76654 2.83337 8.16667V13.5C2.83337 14.9001 2.83337 15.6002 3.10586 16.135C3.34554 16.6054 3.72799 16.9878 4.1984 17.2275C4.73318 17.5 5.43324 17.5 6.83337 17.5H12.1667C13.5668 17.5 14.2669 17.5 14.8017 17.2275C15.2721 16.9878 15.6545 16.6054 15.8942 16.135C16.1667 15.6002 16.1667 14.9001 16.1667 13.5V11.6667"
      stroke={color}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LinkExternal;
