import React from 'react';

interface PinterestProps {
  color?: string;
}

const Pinterest: React.FC<PinterestProps> = ({ color = '#428B6E' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
  >
    <path
      d="M14.333 28.5C22.065 28.5 28.333 22.232 28.333 14.5C28.333 6.76801 22.065 0.5 14.333 0.5C6.60102 0.5 0.333008 6.76801 0.333008 14.5C0.333008 20.1801 3.7157 25.0702 8.57648 27.2655C8.58301 27.1141 8.59035 26.9752 8.59664 26.8561C8.60522 26.6938 8.61186 26.5682 8.61186 26.4976C8.61186 26.0769 8.89791 23.9904 8.89791 23.9904L10.6479 16.8053C10.3787 16.3678 10.1768 15.4423 10.1768 14.7356C10.1768 11.4711 11.9941 10.7644 13.1046 10.7644C14.5181 10.7644 14.8378 12.2957 14.8378 13.4231C14.8378 14.0194 14.5285 14.9528 14.2102 15.9134C13.8728 16.9314 13.5253 17.9799 13.5253 18.6899C13.5253 20.0697 14.8883 20.7596 15.8306 20.7596C18.321 20.7596 20.6095 17.6298 20.6095 14.5C20.6095 11.3702 19.1455 7.58412 14.3498 7.58412C9.55416 7.58412 7.40032 11.2356 7.40032 14.0288C7.40032 15.9134 8.10705 17.2933 8.46041 17.5288C8.56138 17.6186 8.73301 17.8957 8.61186 18.286C8.4907 18.6764 8.26971 19.5481 8.17436 19.9351C8.16314 20.0248 8.05657 20.1774 7.72003 20.0697C7.29936 19.9351 4.62388 18.2524 4.62388 14.0288C4.62388 9.80528 7.93878 4.89182 14.3498 4.89182C20.7609 4.89182 24.0421 9.19951 24.0421 14.5C24.0421 19.8005 19.7513 23.1827 16.4869 23.1827C13.8753 23.1827 12.8522 22.016 12.6671 21.4327L11.6744 25.2187C11.4399 25.8468 11.0026 26.9757 10.4634 27.9583C11.6924 28.3111 12.9906 28.5 14.333 28.5Z"
      fill="#F1F2F3"
    />
  </svg>
);

export default Pinterest;
