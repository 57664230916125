import React, { useState, useEffect } from 'react';
import Icon from '../../../assets/icons/SvgComponent';
import ReactSelect from '../../ReactSelect';
import { useThemeStore } from '../../../store/useThemeStore';
import { ToastContainer, toast, Flip } from 'react-toastify';
import useClientStore from '../../../store/useClientStore';
import Alert from '../../Alert';

interface AhrefsModalProps {
  onClose?: () => void;
  data: any;
}

const options = [
  { value: 'activated', label: 'Activated' },
  { value: 'deactivated', label: 'Deactivated' },
];
const AhrefsModal: React.FC<AhrefsModalProps> = ({ onClose, data }) => {
  const { mode } = useThemeStore((state: any) => state);
  const iconColor = mode === 'dark' ? 'white' : 'black';
  const { selectedClient, fetchClient, updateClient } = useClientStore(
    (state) => state
  );
  const [isAccountActive, setIsAccountActive] = useState({
    value: data?.data?.status,
    label: data?.data?.status,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [domain, setDomain] = useState(selectedClient?.ahrefs?.domain);
  const [inputValue, setInputValue] = useState('');
  const [locations, setLocations] = useState(
    [...(data?.data?.locations || [])]
  );

  const handleSaveChnages = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    if (isAccountActive?.value) {
      const data = {
        ahrefs: {
          ...selectedClient.ahrefs,
          status: isAccountActive?.value,
          locations: locations || [],
          domain: domain,
        },
      };
      updateClient(selectedClient?.id, data);
      fetchClient(selectedClient?.id)
        .then((res: any) => {
          setIsLoading(false);
          toast.success(
            <Alert
              icon=""
              title={`AHREFS UPDATE`}
              message={`Account Updated`}
            />,
            {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          setTimeout(() => {
            onClose && onClose();
          }, 4000);
        })
        .catch((e: any) => {
          toast.error(
            <Alert icon="" title={`ERROR MESSAGE!`} message={`${e}`} />,
            {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          setIsLoading(false);
        });
    }
  };

  const handleAddLocation = (value: any) => {
    if (locations?.find((location: any) => location === value)) return;
    setLocations([...locations, value]);
    setInputValue('');
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleAddLocation(inputValue); // Call handleAddLocation on Enter key
    }
  };
  const deleteLocation = (value: any) => {
    setLocations(locations?.filter((location: any) => location !== value));
  };

  return (
    <div
      className="fixed top-0 right-0 bg-white h-screen w-screen sm:w-[32vw] p-[24px] flex flex-col z-100 dark:bg-[#262627] dark:border dark:border-[#313233] dark:shadow-bs-card"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex justify-between items-center">
        <div className="flex gap-[8px] items-center">
          <div className="bg-gray-200 rounded-lg p-2">
            <Icon name="Ahrefs" color={mode === 'dark' ? '#fff' : iconColor} />
          </div>
          <div className="text-[24px] dark:text-white">Ahrefs</div>
        </div>
        {isLoading && (
          <div className="absolute left-1/2 right-1/2 top-0 bottom-0">
            <Icon name="loading" />
          </div>
        )}
        <div className="cursor-pointer" onClick={onClose}>
          <Icon
            name="Xclose"
            size={20}
            color={mode === 'dark' ? '#fff' : iconColor}
          />
        </div>
      </div>
      <div
        className="hidden sm:flex absolute top-[50%] left-[-50px]  bg-[#E6EFF5]  border border-[#E6EFF5] rounded-[8px] py-[8px] px-[14px] transform rotate-[270deg] text-[14px]  text-[#005C9F] gap-[8px] cursor-pointer"
        onClick={onClose}
      >
        <div>Close</div>
        <Icon name="Xclose" size={20} color="#005C9F" />
      </div>
      <div className="flex flex-col justify-between h-[98%]">
        <div>
          <div className="mt-6 mb-4  pt-[24px] border-t ">
            <span className=" text-sm text-[#001C44] dark:text-white">
              Domain
            </span>
            <div className="relative">
              <input
                type="text"
                className="w-full border border-[#E6EFF5] rounded-[8px] mt-1.5 py-[8px] px-[14px] text-[14px]  text-[#005C9F] flex gap-[8px]"
                placeholder="Add Domain"
                value={domain}
                onChange={(e) => setDomain(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
          <div className="mt-[24px]mb-[16px]">
            <p className="mb-[6px] text-sm text-[#001C44] dark:text-white">
              Status
            </p>
            <ReactSelect
              options={options}
              placeholder="Select Account"
              value={isAccountActive}
              handleOnChange={setIsAccountActive}
            />
          </div>

          <div className="mt-6 mb-4">
            <span className=" text-sm text-[#001C44] dark:text-white">
              Add Location
            </span>
            <div className="relative">
              <input
                type="text"
                className="w-full border border-[#E6EFF5] rounded-[8px] mt-1.5 py-[8px] px-[14px] text-[14px]  text-[#005C9F] flex gap-[8px]"
                placeholder="Add Location"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              {inputValue && (
                <div
                  className="absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer"
                  onClick={() => handleAddLocation(inputValue)}
                >
                  <Icon name="plus" size={18} color="#005C9F" />
                </div>
              )}
            </div>
            <div className="my-4">
              {locations?.length > 0 && (
                <p className="mb-[6px] text-sm text-[#001C44] dark:text-white">
                  Locations
                </p>
              )}
              <div className="flex flex-wrap gap-[16px] border-b pb-12">
                {locations?.length > 0 ? (
                  <>
                    {locations?.map((location: any) => (
                      <div
                        key={location}
                        className="flex items-center justify-between gap-x-2 bg-[#E6EFF5] border border-[#E6EFF5] rounded-[8px] py-1 px-4 text-[#005C9F] cursor-pointer"
                      >
                        <div>{location}</div>
                        <div onClick={() => deleteLocation(location)}>
                          <Icon name="Xclose" size={18} color="#005C9F" />
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="w-full text-center text-[#005C9F]">
                    No locations Found
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          <button
            onClick={handleSaveChnages}
            className="text-white bg-[#005C9F] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm w-full py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          >
            Save
          </button>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={30000}
        hideProgressBar
        newestOnTop={false}
        transition={Flip}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default AhrefsModal;
