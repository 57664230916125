import React from 'react';
import Select, { Props as SelectProps } from 'react-select'; // Import the SelectProps type
import styled from 'styled-components';
interface StyledSelectProps extends SelectProps {
  width?: string;
}

export const StyledSelect = styled(Select)`
  svg {
    color: #a6a6a6;
    height: 22px;
    width: 22px;
  }
`;
