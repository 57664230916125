export const calculatePerformancePercentage = (
  currentPeriod: any,
  previousPeriod: any,
  type: any,
  performanceVisibilityOption: any,
  service: string
) => {
  // Define the metrics for each service
  const seoMetrics = [
    'ga4.new_users',
    'ga4.organic_new_users',
    'ga4.organic_revenue',
    'ga4.organic_conversions',
    'gsc.clicks',
    'gsc.impressions',
    'ahrefs.top_3_keywords_reach',
    'ahrefs.top_10_keywords_reach',
    'ahrefs.top_1_keywords_reach',
    'ahrefs.refdomains',
    'return.conversions',
    'return.ecommerce',
  ];

  const ppcMetrics = [
    'googleAds.clicks',
    'googleAds.conversion_rate',
    'googleAds.conversions',
    'googleAds.conversions_value',
    'googleAds.costPerConversion',
    'googleAds.cpc',
    'googleAds.ctr',
    'googleAds.impressions',
    'googleAds.roas',
  ];

  const metaMetrics = [
    'meta.impressions',
    'meta.reach',
    'meta.frequency',
    'meta.cpm',
    'meta.link_clicks',
    'meta.ctr',
    'meta.website_leads',
    'meta.cpl',
    'meta.cvr',
    'meta.revenue',
    'meta.roas',
  ];

  // Metric names mapping
  const metricNames = {
    'googleAds.clicks': 'Clicks',
    'googleAds.conversion_rate': 'Conversion Rate',
    'googleAds.conversions': 'Conversions',
    'googleAds.conversions_value': 'Conversion Value',
    'googleAds.costPerConversion': 'Cost per Conversion',
    'googleAds.cpc': 'CPC',
    'googleAds.ctr': 'CTR',
    'googleAds.impressions': 'Impressions',
    'googleAds.roas': 'ROAS',
    'ga4.new_users': 'New Users',
    'ga4.organic_new_users': 'Organic New Users',
    'ga4.organic_revenue': 'Organic Revenue',
    'ga4.organic_conversions': 'Organic Conversions',
    'gsc.clicks': 'Clicks',
    'gsc.impressions': 'Impressions',
    'ahrefs.top_3_keywords_reach': 'Top 3 Page',
    'ahrefs.top_10_keywords_reach': 'Top 10 Page',
    'ahrefs.top_1_keywords_reach': 'Top Page',
    'ahrefs.refdomains': 'Referring Domains',
    'return.conversions': 'Conversions',
    'return.ecommerce': 'Ecommerce',
    'meta.impressions': 'Impressions',
    'meta.reach': 'Reach',
    'meta.frequency': 'Frequency',
    'meta.cpm': 'CPM',
    'meta.link_clicks': 'Link Clicks',
    'meta.ctr': 'CTR',
    'meta.website_leads': 'Website Leads',
    'meta.cpl': 'CPL',
    'meta.cvr': 'CVR',
    'meta.revenue': 'Revenue',
    'meta.roas': 'ROAS',
  };

  // Select metrics based on service
  let allMetrics;
  if (service === 'seo_google') {
    allMetrics = seoMetrics;
  } else if (service === 'ppc_google') {
    allMetrics = ppcMetrics;
  } else if (service === 'social_meta') {
    allMetrics = metaMetrics;
  } else {
    allMetrics = [];
  }

  // Filter out the metrics based on type
  allMetrics =
    type === 'Lead Gen' && service === 'seo_google'
      ? allMetrics.filter(
          (field: string) =>
            field !== 'return.ecommerce' && field !== 'ga4.organic_revenue'
        )
      : allMetrics;

  // Filter the metrics to compare based on visibility options
  const metricsToCompare = allMetrics.filter(
    (metric) => performanceVisibilityOption[metric]
  );

  let positiveCount = 0;

  // Compare each metric in the filtered list with tolerance
  metricsToCompare.forEach((metric) => {
    const currentValue =
      currentPeriod[metric] !== undefined ? currentPeriod[metric] : 0;
    const previousValue =
      previousPeriod[metric] !== undefined ? previousPeriod[metric] : 0;

    // Ensure both values can be parsed to a float
    const currentParsed = !isNaN(parseFloat(currentValue))
      ? parseFloat(currentValue.toFixed(6))
      : 0;
    const previousParsed = !isNaN(parseFloat(previousValue))
      ? parseFloat(previousValue.toFixed(6))
      : 0;

    if (
      (metric === 'googleAds.costPerConversion' ||
        metric === 'return.conversions' ||
        metric === 'googleAds.cpc' ||
        metric === 'meta.cpl' ||
        metric === 'meta.cpm' ||
        metric === 'meta.cpc' ||
        metric === 'return.ecommerce') &&
      previousParsed > currentParsed
    ) {
      positiveCount += 1;
    } else if (
      currentParsed > previousParsed &&
      metric !== 'googleAds.costPerConversion' &&
      metric !== 'return.conversions' &&
      metric !== 'googleAds.cpc' &&
      metric !== 'meta.cpl' &&
      metric !== 'meta.cpm' &&
      metric !== 'meta.cpc' &&
      metric !== 'return.ecommerce'
    ) {
      positiveCount += 1;
    }
  });

  // Calculate the performance percentage
  const performancePercentage =
    metricsToCompare.length > 0
      ? (positiveCount / metricsToCompare.length) * 100
      : 0;

  // Return readable names of the metrics that were compared
  const comparedMetricNames = metricsToCompare.map(
    (metric) => metricNames[metric] || metric
  );

  const comparedMetricNamesString = comparedMetricNames.join('\n');

  return {
    performancePercentage,
    outOf: metricsToCompare.length,
    tooltip: comparedMetricNamesString,
    positiveCount, // Return names of the metrics that were compared
  };
};

export const getDeltaTypeAndColor = (current, previous) => {
  // Remove commas and convert to numbers
  const currentNumber = Number(current?.toString()?.replace(/,/g, ''));
  const previousNumber = Number(previous?.toString()?.replace(/,/g, ''));

  const deltaType =
    currentNumber > previousNumber
      ? 'ArrowUpLeft'
      : currentNumber === previousNumber
        ? 'ArrowRight'
        : 'ArrowDownLeft';

  const color =
    currentNumber > previousNumber
      ? '#3CCB7F'
      : currentNumber === previousNumber
        ? '#FF692E'
        : '#FF0000';

  return { deltaType, color };
};

export const seoMetrics = [
  'ga4.new_users',
  'ga4.organic_new_users',
  'ga4.organic_revenue',
  'ga4.organic_conversions',
  'gsc.clicks',
  'gsc.impressions',
  'ahrefs.top_3_keywords_reach',
  'ahrefs.top_10_keywords_reach',
  'ahrefs.top_1_keywords_reach',
  'ahrefs.refdomains',
  'return.conversions',
  'return.ecommerce',
];

export const ppcMetrics = [
  'googleAds.clicks',
  'googleAds.conversion_rate',
  'googleAds.conversions',
  'googleAds.conversions_value',
  'googleAds.costPerConversion',
  'googleAds.cpc',
  'googleAds.ctr',
  'googleAds.impressions',
  'googleAds.roas',
];

export const metaMetrics = [
  'meta.impressions',
  'meta.reach',
  'meta.frequency',
  'meta.cpm',
  'meta.link_clicks',
  'meta.ctr',
  'meta.website_leads',
  'meta.cpl',
  'meta.cvr',
  'meta.revenue',
  'meta.roas',
];

export const findHiddenMetrics = (metrics, storage) => {
  const state = storage || {};
  const hiddenMetrics = metrics?.filter((metric) => {
    const isHidden = state[metric] === false;
    return isHidden;
  });
  return hiddenMetrics;
};
