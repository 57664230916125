import { motion } from 'framer-motion';
import {  Card, BarChart } from '@tremor/react';
import Icon from '../../../assets/icons/SvgComponent'

interface PerformanceInsightsModalProps {
  onClose: () => void;
}

const PerformanceInsightsModal: React.FC<PerformanceInsightsModalProps> = ({ onClose }) => {

  const chartdata = [
    {
      Month: '01-2023', 
      Green: 191, 
      Amber: 32, 
      Red: 10
    },
    {
      Month: '02-2023', 
      Green: 172, 
      Amber: 40, 
      Red: 15
    },
    {
      Month: '03-2023', 
      Green: 182, 
      Amber: 33, 
      Red: 14
    },
    {
      Month: '04-2023', 
      Green: 113, 
      Amber: 56, 
      Red: 120
    },
    {
      Month: '05-2023', 
      Green: 181, 
      Amber: 61, 
      Red: 12
    },
    {
      Month: '06-2023', 
      Green: 120, 
      Amber: 58, 
      Red: 15
    },
    {
      Month: '07-2023', 
      Green: 191, 
      Amber: 32, 
      Red: 10
    },
    {
      Month: '08-2023', 
      Green: 172, 
      Amber: 40, 
      Red: 15
    },
    {
      Month: '09-2023', 
      Green: 182, 
      Amber: 33, 
      Red: 14
    },
    {
      Month: '10-2023', 
      Green: 113, 
      Amber: 56, 
      Red: 120
    },
    {
      Month: '11-2023', 
      Green: 181, 
      Amber: 61, 
      Red: 12
    },
    {
      Month: '12-2023', 
      Green: 120, 
      Amber: 58, 
      Red: 15
    },
  ];


  const dataFormatter = (number: number) =>
  Intl.NumberFormat('us').format(number).toString();




  return (
    <motion.div
      className="fixed top-1/4 left-1/4 flex flex-col items-center py-6 px-8 space-y-6 w-1/2 h-fit rounded-md border border-[#E6EBF0] bg-white dark:bg-dark-tremor-background  "
      initial={{ y: '-50%', opacity: 0 }}
      exit={{ y: '-50%', opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className='w-full flex justify-between items-center'>
        <p className='text-lg'>Performance Insight</p>
        <div onClick={onClose} className='cursor-pointer'>
          <Icon name='Xclose' />  
        </div>
      </div>
      <Card className="relative">
        <BarChart
          data={chartdata}
          index="Month"
          categories={['Green', 'Amber', 'Red']}
          colors={['emerald', 'amber', 'red']}
          valueFormatter={dataFormatter}
          yAxisWidth={48}
          stack={true}
          onValueChange={(v) => console.log(v)}
        />
      </Card>
    </motion.div>
  );
};

export default PerformanceInsightsModal;
