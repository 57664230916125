import React, { useState, useEffect, Fragment } from 'react';

// SEO DELAY BILLING EXPORT
export const SEODelayBilling = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.seo_google.end_date !== '') {
    var timeleft = Date.parse(client.seo_google.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.seo_google.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [delayBillingValues, setValues] = useState({
    type: 'delay billing',
    service: 'seo_google',
    delayBillingRequestDate: '',
    delayBillingStartDate: '',
    delayBillingReason: '',
    delayBillingComments: '',
    delayBillingBudget: client.seo_google.revenue,
    delayTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    delayBillingRequestDate,
    delayBillingStartDate,
    delayBillingReason,
    delayBillingComments,
    delayBillingBudget,
  } = delayBillingValues;

  var contractFinish = new Date(parseInt(client.seo_google.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...delayBillingValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(delayBillingValues);
  }, [delayBillingValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        SEO GOOGLE Delay Billing Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Planned launch date?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('delayBillingRequestDate')}
              value={delayBillingRequestDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            When should invoicing start?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('delayBillingStartDate')}
              value={delayBillingStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the delay?
          </p>
          <div>
            <textarea
              onInput={handleChange('delayBillingReason')}
              value={delayBillingReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Additional information?
          </p>
          <div>
          <textarea
              onInput={handleChange('delayBillingComments')}
              value={delayBillingComments}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={delayBillingBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// PPC DELAY BILLING EXPORT
export const PPCDelayBilling = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.ppc_google.end_date !== '') {
    var timeleft = Date.parse(client.ppc_google.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.ppc_google.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [delayBillingValues, setValues] = useState({
    type: 'delay billing',
    service: 'ppc_google',
    delayBillingRequestDate: '',
    delayBillingStartDate: '',
    delayBillingReason: '',
    delayBillingComments: '',
    delayBillingBudget: client.ppc_google.revenue,
    delayTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    delayBillingRequestDate,
    delayBillingStartDate,
    delayBillingReason,
    delayBillingComments,
    delayBillingBudget,
  } = delayBillingValues;

  var contractFinish = new Date(parseInt(client.ppc_google.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...delayBillingValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(delayBillingValues);
  }, [delayBillingValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        PPC GOOGLE Delay Billing Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Planned launch date?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('delayBillingRequestDate')}
              value={delayBillingRequestDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            When should invoicing start?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('delayBillingStartDate')}
              value={delayBillingStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the delay?
          </p>
          <div>
            <textarea
              onInput={handleChange('delayBillingReason')}
              value={delayBillingReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Additional information?
          </p>
          <div>
          <textarea
              onInput={handleChange('delayBillingComments')}
              value={delayBillingComments}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={delayBillingBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// SEO MICROSOFT DELAY BILLING EXPORT
export const SEOBINGDelayBilling = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.seo_bing.end_date !== '') {
    var timeleft = Date.parse(client.seo_bing.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.seo_bing.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [delayBillingValues, setValues] = useState({
    type: 'delay billing',
    service: 'seo_bing',
    delayBillingRequestDate: '',
    delayBillingStartDate: '',
    delayBillingReason: '',
    delayBillingComments: '',
    delayBillingBudget: client.seo_bing.revenue,
    delayTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    delayBillingRequestDate,
    delayBillingStartDate,
    delayBillingReason,
    delayBillingComments,
    delayBillingBudget,
  } = delayBillingValues;

  var contractFinish = new Date(parseInt(client.seo_bing.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...delayBillingValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(delayBillingValues);
  }, [delayBillingValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Microsoft SEO Delay Billing Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Planned launch date?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('delayBillingRequestDate')}
              value={delayBillingRequestDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            When should invoicing start?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('delayBillingStartDate')}
              value={delayBillingStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the delay?
          </p>
          <div>
            <textarea
              onInput={handleChange('delayBillingReason')}
              value={delayBillingReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Additional information?
          </p>
          <div>
          <textarea
              onInput={handleChange('delayBillingComments')}
              value={delayBillingComments}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={delayBillingBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// PPC DELAY BILLING EXPORT
export const PPCBINGDelayBilling = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_bing.end_date !== '') {
    var timeleft = Date.parse(client.social_bing.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_bing.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [delayBillingValues, setValues] = useState({
    type: 'delay billing',
    service: 'social_bing',
    delayBillingRequestDate: '',
    delayBillingStartDate: '',
    delayBillingReason: '',
    delayBillingComments: '',
    delayBillingBudget: client.social_bing.revenue,
    delayTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    delayBillingRequestDate,
    delayBillingStartDate,
    delayBillingReason,
    delayBillingComments,
    delayBillingBudget,
  } = delayBillingValues;

  var contractFinish = new Date(parseInt(client.social_bing.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...delayBillingValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(delayBillingValues);
  }, [delayBillingValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Microsoft Ads Delay Billing Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Planned launch date?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('delayBillingRequestDate')}
              value={delayBillingRequestDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            When should invoicing start?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('delayBillingStartDate')}
              value={delayBillingStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the delay?
          </p>
          <div>
            <textarea
              onInput={handleChange('delayBillingReason')}
              value={delayBillingReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Additional information?
          </p>
          <div>
          <textarea
              onInput={handleChange('delayBillingComments')}
              value={delayBillingComments}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={delayBillingBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// REM DELAY BILLING EXPORT
export const REMDelayBilling = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.display.end_date !== '') {
    var timeleft = Date.parse(client.display.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.display.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [delayBillingValues, setValues] = useState({
    type: 'delay billing',
    service: 'display',
    delayBillingRequestDate: '',
    delayBillingStartDate: '',
    delayBillingReason: '',
    delayBillingComments: '',
    delayBillingBudget: client.display.revenue,
    delayTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    delayBillingRequestDate,
    delayBillingStartDate,
    delayBillingReason,
    delayBillingComments,
    delayBillingBudget,
  } = delayBillingValues;

  var contractFinish = new Date(parseInt(client.display.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...delayBillingValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(delayBillingValues);
  }, [delayBillingValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Remarketing Delay Billing Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Planned launch date?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('delayBillingRequestDate')}
              value={delayBillingRequestDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            When should invoicing start?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('delayBillingStartDate')}
              value={delayBillingStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the delay?
          </p>
          <div>
          <textarea
              onInput={handleChange('delayBillingReason')}
              value={delayBillingReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Additional information?
          </p>
          <div>
             <textarea
              onInput={handleChange('delayBillingComments')}
              value={delayBillingComments}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={delayBillingBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// META DELAY BILLING EXPORT
export const METADelayBilling = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_meta.end_date !== '') {
    var timeleft = Date.parse(client.social_meta.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_meta.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [delayBillingValues, setValues] = useState({
    type: 'delay billing',
    service: 'social_meta',
    delayBillingRequestDate: '',
    delayBillingStartDate: '',
    delayBillingReason: '',
    delayBillingComments: '',
    delayBillingBudget: client.social_meta.revenue,
    delayTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    delayBillingRequestDate,
    delayBillingStartDate,
    delayBillingReason,
    delayBillingComments,
    delayBillingBudget,
  } = delayBillingValues;

  var contractFinish = new Date(parseInt(client.social_meta.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...delayBillingValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(delayBillingValues);
  }, [delayBillingValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        META Delay Billing Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Planned launch date?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('delayBillingRequestDate')}
              value={delayBillingRequestDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            When should invoicing start?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('delayBillingStartDate')}
              value={delayBillingStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the delay?
          </p>
          <div>
          <textarea
              onInput={handleChange('delayBillingReason')}
              value={delayBillingReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Additional information?
          </p>
          <div>
          <textarea
              onInput={handleChange('delayBillingComments')}
              value={delayBillingComments}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={delayBillingBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// PINTEREST DELAY BILLING EXPORT
export const PINTERESTDelayBilling = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_pinterest.end_date !== '') {
    var timeleft = Date.parse(client.social_pinterest.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_pinterest.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [delayBillingValues, setValues] = useState({
    type: 'delay billing',
    service: 'social_pinterest',
    delayBillingRequestDate: '',
    delayBillingStartDate: '',
    delayBillingReason: '',
    delayBillingComments: '',
    delayBillingBudget: client.social_pinterest.revenue,
    delayTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    delayBillingRequestDate,
    delayBillingStartDate,
    delayBillingReason,
    delayBillingComments,
    delayBillingBudget,
  } = delayBillingValues;

  var contractFinish = new Date(parseInt(client.social_pinterest.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...delayBillingValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(delayBillingValues);
  }, [delayBillingValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        PINTEREST Delay Billing Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Planned launch date?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('delayBillingRequestDate')}
              value={delayBillingRequestDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            When should invoicing start?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('delayBillingStartDate')}
              value={delayBillingStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the delay?
          </p>
          <div>
          <textarea
              onInput={handleChange('delayBillingReason')}
              value={delayBillingReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Additional information?
          </p>
          <div>
          <textarea
              onInput={handleChange('delayBillingComments')}
              value={delayBillingComments}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={delayBillingBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// TIKTOK DELAY BILLING EXPORT
export const TIKTOKDelayBilling = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_tiktok.end_date !== '') {
    var timeleft = Date.parse(client.social_tiktok.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_tiktok.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [delayBillingValues, setValues] = useState({
    type: 'delay billing',
    service: 'social_tiktok',
    delayBillingRequestDate: '',
    delayBillingStartDate: '',
    delayBillingReason: '',
    delayBillingComments: '',
    delayBillingBudget: client.social_tiktok.revenue,
    delayTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    delayBillingRequestDate,
    delayBillingStartDate,
    delayBillingReason,
    delayBillingComments,
    delayBillingBudget,
  } = delayBillingValues;

  var contractFinish = new Date(parseInt(client.social_tiktok.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...delayBillingValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(delayBillingValues);
  }, [delayBillingValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        TIKTOK Delay Billing Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Planned launch date?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('delayBillingRequestDate')}
              value={delayBillingRequestDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            When should invoicing start?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('delayBillingStartDate')}
              value={delayBillingStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the delay?
          </p>
          <div>
          <textarea
              onInput={handleChange('delayBillingReason')}
              value={delayBillingReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Additional information?
          </p>
          <div>
          <textarea
              onInput={handleChange('delayBillingComments')}
              value={delayBillingComments}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={delayBillingBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// LINKEDIN DELAY BILLING EXPORT
export const LINKEDINDelayBilling = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_linkedin.end_date !== '') {
    var timeleft = Date.parse(client.social_linkedin.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_linkedin.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [delayBillingValues, setValues] = useState({
    type: 'delay billing',
    service: 'social_linkedin',
    delayBillingRequestDate: '',
    delayBillingStartDate: '',
    delayBillingReason: '',
    delayBillingComments: '',
    delayBillingBudget: client.social_linkedin.revenue,
    delayTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    delayBillingRequestDate,
    delayBillingStartDate,
    delayBillingReason,
    delayBillingComments,
    delayBillingBudget,
  } = delayBillingValues;

  var contractFinish = new Date(parseInt(client.social_linkedin.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...delayBillingValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(delayBillingValues);
  }, [delayBillingValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        LINKEDIN Delay Billing Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Planned launch date?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('delayBillingRequestDate')}
              value={delayBillingRequestDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            When should invoicing start?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('delayBillingStartDate')}
              value={delayBillingStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the delay?
          </p>
          <div>
          <textarea
              onInput={handleChange('delayBillingReason')}
              value={delayBillingReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Additional information?
          </p>
          <div>
          <textarea
              onInput={handleChange('delayBillingComments')}
              value={delayBillingComments}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={delayBillingBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// PROGRAMMATIC DELAY BILLING EXPORT
export const PROGRAMMATICDelayBilling = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_programmatic.end_date !== '') {
    var timeleft = Date.parse(client.social_programmatic.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_programmatic.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [delayBillingValues, setValues] = useState({
    type: 'delay billing',
    service: 'social_programmatic',
    delayBillingRequestDate: '',
    delayBillingStartDate: '',
    delayBillingReason: '',
    delayBillingComments: '',
    delayBillingBudget: client.social_programmatic.revenue,
    delayTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    delayBillingRequestDate,
    delayBillingStartDate,
    delayBillingReason,
    delayBillingComments,
    delayBillingBudget,
  } = delayBillingValues;

  var contractFinish = new Date(parseInt(client.social_programmatic.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...delayBillingValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(delayBillingValues);
  }, [delayBillingValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        PROGRAMMATIC Delay Billing Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Planned launch date?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('delayBillingRequestDate')}
              value={delayBillingRequestDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            When should invoicing start?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('delayBillingStartDate')}
              value={delayBillingStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the delay?
          </p>
          <div>
          <textarea
              onInput={handleChange('delayBillingReason')}
              value={delayBillingReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Additional information?
          </p>
          <div>
          <textarea
              onInput={handleChange('delayBillingComments')}
              value={delayBillingComments}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={delayBillingBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// TACTICAL DELAY BILLING EXPORT
export const TACTICALDelayBilling = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_tactical.end_date !== '') {
    var timeleft = Date.parse(client.social_tactical.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_tactical.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [delayBillingValues, setValues] = useState({
    type: 'delay billing',
    service: 'social_tactical',
    delayBillingRequestDate: '',
    delayBillingStartDate: '',
    delayBillingReason: '',
    delayBillingComments: '',
    delayBillingBudget: client.social_tactical.revenue,
    delayTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    delayBillingRequestDate,
    delayBillingStartDate,
    delayBillingReason,
    delayBillingComments,
    delayBillingBudget,
  } = delayBillingValues;

  var contractFinish = new Date(parseInt(client.social_tactical.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...delayBillingValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(delayBillingValues);
  }, [delayBillingValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        TACTICAL Delay Billing Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Planned launch date?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('delayBillingRequestDate')}
              value={delayBillingRequestDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            When should invoicing start?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('delayBillingStartDate')}
              value={delayBillingStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the delay?
          </p>
          <div>
          <textarea
              onInput={handleChange('delayBillingReason')}
              value={delayBillingReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Additional information?
          </p>
          <div>
          <textarea
              onInput={handleChange('delayBillingComments')}
              value={delayBillingComments}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={delayBillingBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// CREATIVE DELAY BILLING EXPORT
export const CREATIVEDelayBilling = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_creative.end_date !== '') {
    var timeleft = Date.parse(client.social_creative.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_creative.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [delayBillingValues, setValues] = useState({
    type: 'delay billing',
    service: 'social_creative',
    delayBillingRequestDate: '',
    delayBillingStartDate: '',
    delayBillingReason: '',
    delayBillingComments: '',
    delayBillingBudget: client.social_creative.revenue,
    delayTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    delayBillingRequestDate,
    delayBillingStartDate,
    delayBillingReason,
    delayBillingComments,
    delayBillingBudget,
  } = delayBillingValues;

  var contractFinish = new Date(parseInt(client.social_creative.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...delayBillingValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(delayBillingValues);
  }, [delayBillingValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        CREATIVE Delay Billing Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Planned launch date?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('delayBillingRequestDate')}
              value={delayBillingRequestDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            When should invoicing start?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('delayBillingStartDate')}
              value={delayBillingStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the delay?
          </p>
          <div>
          <textarea
              onInput={handleChange('delayBillingReason')}
              value={delayBillingReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Additional information?
          </p>
          <div>
          <textarea
              onInput={handleChange('delayBillingComments')}
              value={delayBillingComments}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={delayBillingBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// WEBDEV DELAY BILLING EXPORT
export const WEBDEVDelayBilling = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.web_maintenance.end_date !== '') {
    var timeleft = Date.parse(client.web_maintenance.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.web_maintenance.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [delayBillingValues, setValues] = useState({
    type: 'delay billing',
    service: 'web_maintenance',
    delayBillingRequestDate: '',
    delayBillingStartDate: '',
    delayBillingReason: '',
    delayBillingComments: '',
    delayBillingBudget: client.web_maintenance.revenue,
    delayTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    delayBillingRequestDate,
    delayBillingStartDate,
    delayBillingReason,
    delayBillingComments,
    delayBillingBudget,
  } = delayBillingValues;

  var contractFinish = new Date(parseInt(client.web_maintenance.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...delayBillingValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(delayBillingValues);
  }, [delayBillingValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Web Maintenance Delay Billing Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Planned launch date?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('delayBillingRequestDate')}
              value={delayBillingRequestDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            When should invoicing start?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('delayBillingStartDate')}
              value={delayBillingStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the delay?
          </p>
          <div>
          <textarea
              onInput={handleChange('delayBillingReason')}
              value={delayBillingReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Additional information?
          </p>
          <div>
          <textarea
              onInput={handleChange('delayBillingComments')}
              value={delayBillingComments}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={delayBillingBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// WEBDEV DELAY BILLING EXPORT
export const WEBHOSTDelayBilling = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.web_hosting.end_date !== '') {
    var timeleft = Date.parse(client.web_hosting.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.web_hosting.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [delayBillingValues, setValues] = useState({
    type: 'delay billing',
    service: 'web_hosting',
    delayBillingRequestDate: '',
    delayBillingStartDate: '',
    delayBillingReason: '',
    delayBillingComments: '',
    delayBillingBudget: client.web_hosting.revenue,
    delayTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    delayBillingRequestDate,
    delayBillingStartDate,
    delayBillingReason,
    delayBillingComments,
    delayBillingBudget,
  } = delayBillingValues;

  var contractFinish = new Date(parseInt(client.web_hosting.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...delayBillingValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(delayBillingValues);
  }, [delayBillingValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Web Hosting Delay Billing Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Planned launch date?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('delayBillingRequestDate')}
              value={delayBillingRequestDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            When should invoicing start?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('delayBillingStartDate')}
              value={delayBillingStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the delay?
          </p>
          <div>
          <textarea
              onInput={handleChange('delayBillingReason')}
              value={delayBillingReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Additional information?
          </p>
          <div>
          <textarea
              onInput={handleChange('delayBillingComments')}
              value={delayBillingComments}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={delayBillingBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// WEB LANDING DELAY BILLING EXPORT
export const WEBLANDINGDelayBilling = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.web_landing.end_date !== '') {
    var timeleft = Date.parse(client.web_landing.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.web_landing.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [delayBillingValues, setValues] = useState({
    type: 'delay billing',
    service: 'web_landing',
    delayBillingRequestDate: '',
    delayBillingStartDate: '',
    delayBillingReason: '',
    delayBillingComments: '',
    delayBillingBudget: client.web_landing.revenue,
    delayTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    delayBillingRequestDate,
    delayBillingStartDate,
    delayBillingReason,
    delayBillingComments,
    delayBillingBudget,
  } = delayBillingValues;

  var contractFinish = new Date(parseInt(client.web_landing.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...delayBillingValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(delayBillingValues);
  }, [delayBillingValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Web Landing Delay Billing Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Planned launch date?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('delayBillingRequestDate')}
              value={delayBillingRequestDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            When should invoicing start?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('delayBillingStartDate')}
              value={delayBillingStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the delay?
          </p>
          <div>
          <textarea
              onInput={handleChange('delayBillingReason')}
              value={delayBillingReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Additional information?
          </p>
          <div>
          <textarea
              onInput={handleChange('delayBillingComments')}
              value={delayBillingComments}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={delayBillingBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// WEB LANDING DELAY BILLING EXPORT
export const WEBPROJECTDelayBilling = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.web_project.end_date !== '') {
    var timeleft = Date.parse(client.web_project.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.web_project.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [delayBillingValues, setValues] = useState({
    type: 'delay billing',
    service: 'web_project',
    delayBillingRequestDate: '',
    delayBillingStartDate: '',
    delayBillingReason: '',
    delayBillingComments: '',
    delayBillingBudget: client.web_project.revenue,
    delayTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    delayBillingRequestDate,
    delayBillingStartDate,
    delayBillingReason,
    delayBillingComments,
    delayBillingBudget,
  } = delayBillingValues;

  var contractFinish = new Date(parseInt(client.web_project.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...delayBillingValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(delayBillingValues);
  }, [delayBillingValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Web Project Delay Billing Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Planned launch date?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('delayBillingRequestDate')}
              value={delayBillingRequestDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            When should invoicing start?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('delayBillingStartDate')}
              value={delayBillingStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the delay?
          </p>
          <div>
          <textarea
              onInput={handleChange('delayBillingReason')}
              value={delayBillingReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Additional information?
          </p>
          <div>
          <textarea
              onInput={handleChange('delayBillingComments')}
              value={delayBillingComments}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={delayBillingBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              
            />
          </div>
        </div>
      </div>
    </div>
  );
};
