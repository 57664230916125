import { requests } from "./ApiService";
import { toQueryString } from "../utils/common";

export const Client = {
  getClients: (filters) => requests.get(`/api/v1/clients${toQueryString({
    search: filters.search,
    status: filters.status,
    page: filters.page,
    limit: filters.limit,
    category_type: filters.category_type,
    service: filters.service,
    integration: filters.integration,
  })}`),
  getClient: (id: string) => requests.get('/api/v1/client/' + id),
  // TODO: Add type for data
  create: (data) => requests.post('/api/v1/client', data),
  update: (id: string, data) => requests.patch(`/api/v1/client/${id}`, data),
  updateStatus: (data) => requests.patch('/api/v1/clients/status', data),
  delete: (data) => requests.delete('/api/v1/clients', data),
};