import React, { useState, useEffect, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import useKpiStore from '../../store/useKpiStore';
import moment from 'moment';
import Icon from '../../assets/icons/SvgComponent';

const KPIs = () => {
  const date = new Date();
  const { fetchKpis, kpis, selectedStaff, setStaff, autoStaff, loading } = useKpiStore((state) => state);
  const [monthYearPairs, setMonthYearPairs] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [currentDashboard, setCurrentDashboard] = useState('rum_dash');
  const [selectedServiceLines, setSelectedServiceLines] = useState([]);
  const [selectedChangeTypes, setSelectedChangeTypes] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [payload, setPayload] = useState({
    currentMonth: moment(date).format('MMMM'),
    previousMonth: moment(date).subtract(1, 'month').format('MMMM'),
    reportFrom: moment(date).subtract(1, 'month').startOf('day'),
    reportTo: moment(date).endOf('day'),
    role: selectedRoles,
    change_type: selectedChangeTypes,
    service_line: selectedServiceLines,
  });

  const [sortBy, setSortBy] = useState('full_name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [filters, setFilters] = useState({
    user: '',
    tags: [],
    year: '',
  });
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [checkedValues, setCheckedValues] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);

  type OptionType = {
    label: string;
    value: string;
  };
  
  const dashboard_options: OptionType[] = [
    { label: 'RuM Dashboard', value: 'rum_dash' },
    { label: 'Churn Dashboard', value: 'churn_dash' }
  ];

  const MultiSelect = ({ title, options, selectedOptions, setSelectedOptions }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const handleSelect = (value) => {
      if (!selectedOptions.includes(value)) {
        setSelectedOptions([...selectedOptions, value]);
      }
      setIsOpen(false);  // Close dropdown after selection
    };
  
    const handleRemove = (value) => {
      setSelectedOptions(selectedOptions.filter(option => option !== value));
    };
  
    const toggleDropdown = () => {
      setIsOpen(!isOpen);  // Toggle dropdown open/close
    };
  
    return (
      <div className="multi-select">
        <h6>{title}</h6>
        <div className={`select-box ${isOpen ? 'open' : ''}`} onClick={toggleDropdown}>
          <div className="tags">
            {selectedOptions.length > 0 ? (
              selectedOptions.map(option => (
                <span key={option} className="tag">
                  {options.find(o => o.value === option).label}
                  <button onClick={(e) => {
                    e.stopPropagation(); 
                    handleRemove(option);
                  }}>x</button>
                </span>
              ))
            ) : (
              <span className="placeholder">Select options...</span>
            )}
          </div>
          <div className="arrow">{isOpen ? '▲' : '▼'}</div>
        </div>
  
        {isOpen && (
          <div className="dropdown">
            {options.filter(option => !selectedOptions.includes(option.value)).map(option => (
              <div
                key={option.value}
                className="dropdown-item"
                onClick={() => handleSelect(option.value)}  // Close dropdown after selection
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const Modal = ({ isOpen, onClose }) => {

    const serviceLineOptions = [
      { value: 'seo_google', label: 'SEO GOOGLE' },
      { value: 'seo_bing', label: 'SEO BING' },
      { value: 'ppc_google', label: 'PPC GOOGLE' },
      { value: 'social_bing', label: 'PPC BING' },
      { value: 'display', label: 'DISPLAY' },
      { value: 'social_meta', label: 'META' },
      { value: 'social_pinterest', label: 'PINTEREST' },
      { value: 'social_tiktok', label: 'TIKTOK' },
      { value: 'social_linkedin', label: 'LINKEDIN' },
      { value: 'social_creative', label: 'CREATIVE' },
      { value: 'social_programmatic', label: 'PROGRAMMATIC' },
      { value: 'social_tactical', label: 'TACTICAL' }
    ];

    const changeTypeOptions = [
      { value: 'apply', label: 'APPLY CREDIT' },
      { value: 'refund', label: 'REFUND' },
      { value: 'cancel', label: 'CANCEL' },
      { value: 'reduce', label: 'REDUCE BILLING' },
      { value: 'delay', label: 'DELAY BILLING' }
    ];

    const roleOptions = [
      { value: 'gad', label: 'GAD' },
      { value: 'am', label: 'AM' },
      { value: 'seocon', label: 'SEO' },
      { value: 'ppccon', label: 'PPC' },
      { value: 'soccon', label: 'SOCIAL' },
      { value: 'exec', label: 'EXEC' }
    ];
    
    return (
      <div className={`kpi-modal-overlay ${isOpen ? 'open' : ''}`} onClick={onClose}>
        <div className="kpi-modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="kpi-modal-header">
            <h2 className="lg:text-[22px] hidden md:block font-bold font-inter text-[#001d44] dark:text-white" >Filters</h2>
            <button onClick={onClose} className="kpi-close-modal-button">
              x
            </button>
          </div>
          <hr style={{margin:'24px 0'}}/>
          <div className="modal-body">
            <MultiSelect
              title="Service Lines"
              options={serviceLineOptions}
              selectedOptions={selectedServiceLines}
              setSelectedOptions={setSelectedServiceLines}
            />
            {currentDashboard !== 'churn_dash' ? 
            <MultiSelect
              title="Change Types"
              options={changeTypeOptions}
              selectedOptions={selectedChangeTypes}
              setSelectedOptions={setSelectedChangeTypes}
            /> :
            <div className="multi-select" style={{pointerEvents: 'none'}}>
              <h6>Change Types</h6>
              <div className={`select-box ${''}`}>
                <div className="tags">
                  <span className="placeholder" style={{color:'red'}}>Cannot filter change types for Churn Dashboard</span>
                </div>
                <div className="arrow">{isOpen ? '▲' : '▼'}</div>
              </div>
            </div>
            }
            {filters.tags.length === 0 ? 
              <MultiSelect
                title="Roles"
                options={roleOptions}
                selectedOptions={selectedRoles}
                setSelectedOptions={setSelectedRoles}
              />
            : 
            <div className="multi-select" style={{pointerEvents: 'none'}}>
              <h6>Roles</h6>
              <div className={`select-box ${''}`}>
                <div className="tags">
                  <span className="placeholder" style={{color:'red'}}>Cannot filter roles when searching for individual users</span>
                </div>
                <div className="arrow">{isOpen ? '▲' : '▼'}</div>
              </div>
            </div>
            }

          </div>
        </div>
      </div>
    );
  };

  const handleNumbers = (value) => {
    if(value === undefined){
      return 0
    }else{
      return Number(value.toFixed(0));
    }
  }

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
  };

  const handleDashboardChange = (value) => {
    setCurrentDashboard(value.value)
    setPayload((prevPayload) => ({
      ...prevPayload,
      role: [],
      change_type: [],
      service_line: [],
    }));
  }

  const handleReportDateChange = (date, type) => {
    if (date) {
      if(type === 'from'){
        setPayload(prevPayload => ({
          ...prevPayload,
          reportFrom: moment(date).startOf('day'),
        }));
      }else{
        setPayload(prevPayload => ({
          ...prevPayload,
          reportTo: moment(date).endOf('day'),
        }));
      }
    }
  };

  const handleTagInput = (e) => {
    const input = e.target as HTMLInputElement;
    if (input.value.trim()) {
      setSuggestions(data.filter(item =>
        item.full_name.toLowerCase().includes(input.value.toLowerCase())
      ));
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  const selectTag = (name) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      tags: [...prevFilters.tags, name],
    }));
    setShowSuggestions(false);
  };

  const removeTag = (tag) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      tags: prevFilters.tags.filter(t => t !== tag),
    }));
  };

  function calculateUtilization(rum, rum_target, reductions) {
    let percentage = 100;
    if (rum_target > 0 && rum_target > 0) {
      percentage = ((rum - reductions) / rum_target) * 100;
    }
    return parseFloat(percentage.toFixed(2)); 
  }

  const getMonthYearPairs = (startDate, endDate) => {
    const months = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      const year = currentDate.getFullYear();
      const month = currentDate.toLocaleString('default', { month: 'long' });
      months.push({ month, year });
      currentDate.setMonth(currentDate.getMonth() + 1);
    }
    return months;
  };

  const filteredData = useMemo(() => {
    const basicFilteredData = data.filter((item) => {
      const fullNameMatch = item.full_name.toLowerCase().includes(filters.user.toLowerCase());
      const tagsMatch = filters.tags.length
        ? filters.tags.some(tag => item.full_name.toLowerCase().includes(tag.toLowerCase()))
        : true;
      const yearMatch = filters.year ? item.year === filters.year : true;
      return fullNameMatch && tagsMatch && yearMatch;
    });
    
    return basicFilteredData;
  }, [data, filters, selectedServiceLines, selectedChangeTypes]);

  const sortedData = useMemo(() => {
    return [...filteredData].sort((a, b) => {
      if (a[sortBy] < b[sortBy]) return sortOrder === 'asc' ? -1 : 1;
      if (a[sortBy] > b[sortBy]) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });
  }, [filteredData, sortBy, sortOrder]);

  useEffect(() => {
    setPayload((prevPayload) => ({
      ...prevPayload,
      role: selectedRoles,
      change_type: selectedChangeTypes,
      service_line: selectedServiceLines,
    }));
  }, [selectedServiceLines, selectedChangeTypes, selectedRoles]);

  useEffect(() => {
    setLoading(true)
    const startDate = new Date(payload.reportFrom.toISOString());
    const endDate = new Date(payload.reportTo.toISOString());
    const pairs = getMonthYearPairs(startDate, endDate);
    setMonthYearPairs(pairs);

    fetchKpis(payload)
    .then(data => {
        setData(data.data);
        setLoading(false)
    });
}, [payload]);

  return (
    <div className="p-4 kpis">
      <div className="mb-4 relative kpi-filters">
        <label><span>Filter Name</span>
          <input
            type="text"
            placeholder="Search and select a user"
            onChange={handleTagInput}
            className="p-2 border border-gray-300 rounded w-[250px]"
          />
        </label>
        {showSuggestions && (
          <div className="kpi-suggestions absolute bg-white border border-gray-300 mt-1 max-h-60 overflow-auto">
            {suggestions.map((item) => (
              <div
                key={item._id}
                className="p-2 cursor-pointer hover:bg-gray-100"
                onClick={() => selectTag(item.full_name)}
              >
                {item.full_name}
              </div>
            ))}
          </div>
        )}
        <label><span>Date From</span>
          <DatePicker
            selected={payload.reportFrom.toDate()}
            dateFormat={'dd/MM/yyyy'}
            onChange={(date) => {
              if (date !== null) {
                handleReportDateChange(date, 'from');
              }
            }}
            className="p-2 ml-2 border border-gray-300 rounded" 

          />
        </label>
        <label><span>Date To</span>
          <DatePicker
            selected={payload.reportTo.toDate()}
            dateFormat={'dd/MM/yyyy'}
            onChange={(date) => {
              if (date !== null) {
                handleReportDateChange(date, 'to');
              }
            }}
            className="p-2 ml-2 border border-gray-300 rounded" 
          />
        </label>
        <label><span>Dashboard</span>
          <Select
            className='p-2 rounded w-[250px]'
            options={dashboard_options}
            onChange={handleDashboardChange}
            defaultValue={dashboard_options[0]}
            components={{
              IndicatorSeparator: () => null
            }}
          />
        </label>
        <button
          className="p-2 ml-2 border border-gray-300 rounded w-[200px] text-left" 
          onClick={() => setModalOpen(true)}
        >
          Filters <Icon name="FilterLines" size={22} />
        </button>
      </div>

      <div className="mb-4">
        {filters.tags.length > 0 && (
          <div className="flex flex-wrap gap-2 mb-2">
            {filters.tags.map(tag => (
              <span key={tag} className="kpi-tags bg-blue-200 text-blue-800 px-2 py-1 rounded flex items-center">
                {tag}
                <button
                  onClick={() => removeTag(tag)}
                  className="ml-2"
                >
                  ×
                </button>
              </span>
            ))}
          </div>
        )}
      </div>
      <div className="mb-4" style={{background:'white', overflowX:'scroll', boxShadow: 'inset 0 0 10px #000'}}>
      {isLoading ? 
        <div className="right-16 top-5">
          <Icon name="loading" />
        </div>
       : ''}
      {!isLoading && currentDashboard === 'rum_dash' && ( 
        <table className="min-w-full bg-white border border-gray-300 kpi-table">
          <thead>
            <tr className="">
              <th className="flex w-[180px] sm:w-[12vw] justify-between items-center gap-2 cursor-pointer" onClick={() => handleSort('full_name')}>
                User Name 
                {sortOrder === 'asc' && <Icon name="Arrowup" />}
                {sortOrder === 'desc' && <Icon name="ArrowDown" />}
                {sortOrder === null && <Icon name="Sort" />}
              </th>
              <th className="p-2 cursor-pointer" onClick={() => handleSort('rum')}>
                Rum {sortBy === 'rum' ? (sortOrder === 'asc' ? '🔼' : '🔽') : ''}
              </th>
              <th className="p-2 cursor-pointer" onClick={() => handleSort('target')}>
                Rum Target {sortBy === 'target' ? (sortOrder === 'asc' ? '🔼' : '🔽') : ''}
              </th>
              <th className="p-2 cursor-pointer" onClick={() => handleSort('reductions')}>
                Reductions {sortBy === 'reductions' ? (sortOrder === 'asc' ? '🔼' : '🔽') : ''}
              </th>
              <th className="p-2 cursor-pointer" onClick={() => handleSort('utilisation')}>
                Rum Utilisation {sortBy === 'utilisation' ? (sortOrder === 'asc' ? '🔼' : '🔽') : ''}
              </th>
              <th className="p-2 cursor-pointer" onClick={() => handleSort('previousMonth')}>
                Previous Month {sortBy === 'previousMonth' ? (sortOrder === 'asc' ? '🔼' : '🔽') : ''}
              </th>
              <th className="p-2 cursor-pointer" onClick={() => handleSort('year')}>
                Year {sortBy === 'year' ? (sortOrder === 'asc' ? '🔼' : '🔽') : ''}
              </th>
              <th className="p-2 cursor-pointer" onClick={() => handleSort('year')}>
                Role {sortBy === 'year' ? (sortOrder === 'asc' ? '🔼' : '🔽') : ''}
              </th>
              {monthYearPairs.map((pair) => (
                <th className="p-2 cursor-pointer" onClick={() => handleSort('year')}>
                  {pair.month} {sortBy === 'year' ? (sortOrder === 'asc' ? '🔼' : '🔽') : ''}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedData.map((item) => {
              if(item.full_name !== ''){
                const currentMonth = new Date().toLocaleString('default', { month: 'long' });
                const currentMonthData = item.months[currentMonth];
                const previousMonthDate = new Date();
                previousMonthDate.setMonth(previousMonthDate.getMonth() - 1);
                const previousMonth = previousMonthDate.toLocaleString('default', { month: 'long' });
                const previousMonthData = item.months[previousMonth];

                return (
                <tr key={item._id}>
                  <td className="p-2 border border-gray-300">{item.full_name}</td>
                  <td className="p-2 border border-gray-300">
                  ${handleNumbers(currentMonthData.rum.total).toLocaleString('en-US')}
                  </td>
                  <td className="p-2 border border-gray-300">
                  ${handleNumbers(currentMonthData.rum_target).toLocaleString('en-US')}
                  </td>
                  <td className="p-2 border border-gray-300">
                    {
                    currentMonthData.change_amount > 0
                    ? <span className="text-red-500">${handleNumbers(currentMonthData.change_amount ).toLocaleString('en-US')}</span>
                    : <span className="text-green-500">$0</span>
                    }
                  </td>
                  <td className="p-2 border border-gray-300">
                    {
                    calculateUtilization(currentMonthData.rum.total, currentMonthData.rum_target, currentMonthData.change_amount) < 96 ?
                      <span className="text-red-500">{handleNumbers(calculateUtilization(currentMonthData.rum.total, currentMonthData.rum_target, currentMonthData.change_amount)).toLocaleString('en-US')}%</span>
                    : <span className="text-green-500">{handleNumbers(calculateUtilization(currentMonthData.rum.total, currentMonthData.rum_target, currentMonthData.change_amount)).toLocaleString('en-US')}%</span>
                    }
                  </td>
                  <td className="p-2 border border-gray-300">
                  {
                    calculateUtilization(previousMonthData.rum.total, previousMonthData.rum_target, previousMonthData.change_amount) < 96 ?
                      <span className="text-red-500">{handleNumbers(calculateUtilization(previousMonthData.rum.total, previousMonthData.rum_target, previousMonthData.change_amount)).toLocaleString('en-US')}%</span>
                    : <span className="text-green-500">{handleNumbers(calculateUtilization(previousMonthData.rum.total, previousMonthData.rum_target, previousMonthData.change_amount)).toLocaleString('en-US')}%</span>
                    }
                  </td>
                  <td className="p-2 border border-gray-300">{item.year}</td>
                  <td className="p-2 border border-gray-300">
                    {
                    item.role === 'seocon' ? 'SEO Consultant' : 
                    item.role === 'ppccon' ? 'PPC Consultant' : 
                    item.role === 'soccon' ? 'SOCIAL Consultant' : 
                    item.role === 'am' ? 'AM' : 
                    item.role === 'gad' ? 'GAD' : 
                    item.role === 'admin' ? 'ADMIN' : 
                    item.role === 'exec' ? 'Executive' : 'N/A'
                    }
                  </td>
                  {monthYearPairs.map((pair, index) => {
                    const dynamicMonthKey = `${pair.month}`;
                    return (
                      <td key={index} className="p-2 border border-gray-300">
                        ${handleNumbers(item.months[dynamicMonthKey].change_amount).toLocaleString('en-US')}
                      </td>
                    );
                  })}
                </tr>
              )}
            })}
          </tbody>
        </table> 
      )}
      {!isLoading && currentDashboard === 'churn_dash' && ( 
        <table className="min-w-full bg-white border border-gray-300 kpi-table">
          <thead>
            <tr className="">
              <th className="flex w-[180px] sm:w-[12vw] justify-between items-center gap-2 cursor-pointer" onClick={() => handleSort('full_name')}>
                User Name 
                {sortOrder === 'asc' && <Icon name="Arrowup" />}
                {sortOrder === 'desc' && <Icon name="ArrowDown" />}
                {sortOrder === null && <Icon name="Sort" />}
              </th>
              <th className="p-2 cursor-pointer" onClick={() => handleSort('rum')}>
                Service Lines {sortBy === 'rum' ? (sortOrder === 'asc' ? '🔼' : '🔽') : ''}
              </th>
              <th className="p-2 cursor-pointer" onClick={() => handleSort('reductions')}>
                Canceled Services {sortBy === 'reductions' ? (sortOrder === 'asc' ? '🔼' : '🔽') : ''}
              </th>
              <th className="p-2 cursor-pointer" onClick={() => handleSort('utilisation')}>
                Retention {sortBy === 'utilisation' ? (sortOrder === 'asc' ? '🔼' : '🔽') : ''}
              </th>
              <th className="p-2 cursor-pointer" onClick={() => handleSort('previousMonth')}>
                Previous Month Retention {sortBy === 'previousMonth' ? (sortOrder === 'asc' ? '🔼' : '🔽') : ''}
              </th>
              <th className="p-2 cursor-pointer" onClick={() => handleSort('year')}>
                Year {sortBy === 'year' ? (sortOrder === 'asc' ? '🔼' : '🔽') : ''}
              </th>
              <th className="p-2 cursor-pointer" onClick={() => handleSort('year')}>
                Role {sortBy === 'year' ? (sortOrder === 'asc' ? '🔼' : '🔽') : ''}
              </th>
              {monthYearPairs.map((pair) => (
                <th className="p-2 cursor-pointer" onClick={() => handleSort('year')}>
                  {pair.month} Cancels {sortBy === 'year' ? (sortOrder === 'asc' ? '🔼' : '🔽') : ''}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedData.map((item) => {
              const currentMonth = new Date().toLocaleString('default', { month: 'long' });
              const currentMonthData = item.months[currentMonth];
              const previousMonthDate = new Date();
              previousMonthDate.setMonth(previousMonthDate.getMonth() - 1);
              const previousMonth = previousMonthDate.toLocaleString('default', { month: 'long' });
              const previousMonthData = item.months[previousMonth];

              return (
              <tr key={item._id}>
                <td className="p-2 border border-gray-300">{item.full_name}</td>
                <td className="p-2 border border-gray-300">
                {handleNumbers(currentMonthData.counts.total)}
                </td>
                <td className="p-2 border border-gray-300">
                  {
                  currentMonthData.change_count > 0
                  ? <span className="text-red-500">{currentMonthData.lost_accounts}</span>
                  : <span className="text-green-500">0</span>
                  }
                </td>
                {currentMonthData.lost_accounts > 0 && currentMonthData.counts.total > 0 ?
                <td className="p-2 border border-gray-300">
                  {
                  (100 - (currentMonthData.lost_accounts / currentMonthData.counts.total) * 100) < 97 ?
                    <span className="text-red-500">{100 - handleNumbers( (currentMonthData.lost_accounts / currentMonthData.counts.total) * 100) }%</span>
                  : <span className="text-green-500">{100 - handleNumbers( (currentMonthData.lost_accounts / currentMonthData.counts.total) * 100) }%</span>
                  }
                </td>
                : <td className="p-2 border border-gray-300">0</td>
                }
                {previousMonthData.lost_accounts > 0 && previousMonthData.counts.total > 0 ?
                <td className="p-2 border border-gray-300">
                  {
                  (100 - (previousMonthData.lost_accounts / previousMonthData.counts.total) * 100) < 97 ?
                    <span className="text-red-500">{100 - handleNumbers( (previousMonthData.lost_accounts / previousMonthData.counts.total) * 100) }%</span>
                  : <span className="text-green-500">{100 - handleNumbers( (previousMonthData.lost_accounts / previousMonthData.counts.total) * 100) }%</span>
                  }
                </td>
                : <td className="p-2 border border-gray-300">0</td>
                }
                <td className="p-2 border border-gray-300">{item.year}</td>
                <td className="p-2 border border-gray-300">
                  {
                  item.role === 'seocon' ? 'SEO Consultant' : 
                  item.role === 'ppccon' ? 'PPC Consultant' : 
                  item.role === 'soccon' ? 'SOCIAL Consultant' : 
                  item.role === 'am' ? 'AM' : 
                  item.role === 'gad' ? 'GAD' : 
                  item.role === 'admin' ? 'ADMIN' : 
                  item.role === 'exec' ? 'Executive' : 'N/A'
                  }
                </td>
                {monthYearPairs.map((pair, index) => {
                  const dynamicMonthKey = `${pair.month}`;
                  return (
                    <td key={index} className="p-2 border border-gray-300">
                      {handleNumbers(item.months[dynamicMonthKey].change_amount) >  0 ?
                      <span className="text-red-500">{handleNumbers(item.months[dynamicMonthKey].lost_accounts)}</span>
                    :
                      <span className="text-green-500">{handleNumbers(item.months[dynamicMonthKey].lost_accounts)}</span>
                    }
                    </td>
                  );
                })}
              </tr>
            )})}
          </tbody>
        </table>
      )}
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </div>
  );
};

export default KPIs;


