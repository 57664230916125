// performance.tsx
import React, { useState, useEffect, useMemo } from 'react';
import TansStackTableV2 from '../../components/TansStackTableV2';
import ReactSelect from '../../components/ReactSelect';
import { useThemeStore } from '../../store/useThemeStore';
import { generateColumns } from './columns';
import LinksLookupSearch from '../../components/LinksLookupV2/LinksSearchbar';
import useLinkBriefStore from '../../store/useLinkBriefStore';
import CustomModal from '../../components/CustomModal';
import ViewLinksModal from '../../components/LinksLookupV2/ViewLinksModal';
import Icon from '../../assets/icons/SvgComponent';
import ExportModal from '../../components/Modals/LinksLookup/Export';

const statusOptions = [
  {
    label: 'All Status',
    value: '',
  },
  {
    label: 'Open',
    value: 'open',
  },
  {
    label: 'Delivered',
    value: 'delivered',
  },
  {
    label: 'Overdue',
    value: 'overdue',
  },
  {
    label: 'Not Delivered',
    value: 'not_delivered',
  },
];

const mismatchOptions = [
  {
    label: 'All Links',
    value: 'all',
  },
  {
    label: 'Mismatch DR',
    value: 'delivered_dr',
  },
  {
    label: 'Mismatch Backlink',
    value: 'delivered_backlink1',
  },
  {
    label: 'Mismatch Anchor',
    value: 'delivered_anchor_text1',
  },
];

const LinksLookupV2: React.FC = () => {
  const {
    fetchLinkBriefOptions,
    linkBriefOptions,
    fetchSearchedLinkItems,
    linkBrief,
  } = useLinkBriefStore((state) => state);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const { mode } = useThemeStore((state: any) => state);
  const [sortingDirection, setSortingDirection] = useState('');
  const [sortingColumn, setSortingColumn] = useState('');
  const [linkItem, setLinkItem] = useState('');
  const [showExportModal, setShowExportModal] = useState(false);
  const [status, setStatus] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [link, setLink] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const [mismatch, setMismatch] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const iconColor = mode === 'dark' ? 'white' : 'black';

  const [linkSearchType, setLinkSearchType] = useState('client');
  const [searchTerm, setSearchTerm] = useState('');

  // Retrieve the item from local storage
  const localStorageItem = localStorage.getItem('links-filter-storage');
  useEffect(() => {
    const parsedItem = localStorageItem ? JSON.parse(localStorageItem) : {};

    // Extract type and searchTerm, defaulting to 'client' and '' if not found
    const type = parsedItem.type || 'client';
    const term =
      parsedItem.type === 'client'
        ? parsedItem.hubspotId
        : parsedItem.searchTerm;

    // Update the state with the extracted values
    setLinkSearchType(type);
    setSearchTerm(term);
  }, [linkSearchType, searchTerm, localStorageItem]);

  useEffect(() => {
    const fetchOptions = async () => {
      await fetchLinkBriefOptions();
    };
    fetchOptions();
  }, [fetchLinkBriefOptions]);

  const linkOptions = [
    { label: 'All', value: 'all' },
    ...(linkBriefOptions?.link_briefs?.map((item) => ({
      label: item.brief_name,
      value: item._id,
    })) || []),
  ];

  const handleFetchLinkSearchItems = async () => {
    try {
      if (link?.value) {
        // Create the payload with the search term from local storage
        const payload = {
          page: page,
          limit: limit,
          client_id: linkSearchType === 'client' ? searchTerm : null,
          search:
            searchTerm !== '' && searchTerm && linkSearchType !== 'client'
              ? `${linkSearchType}:${searchTerm}`
              : null, // Use the search term from local storage
          brief_id: link?.value,
          status: status?.value,
          sort_by: 'name',
          sort_order: 'asc',
          mismatch: mismatch?.value,
        };

        fetchSearchedLinkItems(payload);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (linkBriefOptions) {
      const options = linkBriefOptions.link_briefs?.map((item) => ({
        label: item.brief_name,
        value: item._id,
      }));
      setLink(options?.[0] || null);
    }
  }, [linkBriefOptions]);

  useEffect(() => {
    handleFetchLinkSearchItems();
  }, [page, searchTerm, status, link, linkSearchType, mismatch]);

  const handleOpenEditModal = () => {
    setOpenModal(true);
  };

  const columns = useMemo(
    () =>
      generateColumns({
        sortingColumn,
        sortingDirection,
        handleOpenEditModal,
        setLinkItem,
      }),
    [sortingColumn, sortingDirection, linkBrief, linkBriefOptions]
  );

  const formattedDataMemo = useMemo(() => {
    const formatData = (data) => {
      const tableData = data?.map((item) => {
        return {
          client: {
            name: item?.link_brief_item?.name || 'N/A',
            domain: item?.link_brief_item?.domain || 'N/A',
            brief_name: item?.link_brief?.brief_name || link?.label,
          },

          amAndCons: {
            am: item?.link_brief_item?.account_manager || 'N/A',
            cons: item?.link_brief_item?.seo_consultant || 'N/A',
          },
          anchorText: {
            requested: item?.requested_anchor_text1 || '-',
            delivered: item?.delivered_anchor_text1 || '-',
          },
          dr: {
            delivered: item?.delivered_dr || '-',
            adjusted: item?.adjusted_dr || '-',
            requested: item?.requested_dr || '-',
          },
          links: {
            link: item?.delivered_link || '-',
            target: item?.requested_backlink1 || '-',
          },
          status: item?.status,
          view: item,
        };
      });
      return tableData;
    };
    const formattedData =
      linkBrief.data?.length > 0 && formatData(linkBrief?.data);
    return formattedData;
  }, [linkBrief]);

  return (
    <div className="bg-[#D2EEFF] mt-12 px-2 sm:mt-0 sm:py-3 sm:px-[32px] overflow-hidden min-h-[93dvh] dark:bg-[#191919] font-inter scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8 rounded-lg">
      <div className="bg-[#D2EEFF] pt-2 sm:pt-0 rounded-lg dark:bg-[#0C111D]">
        <div className="flex items-center justify-between mt-4 px-2 py-4 sm:mt-0 sm:pt-14 lg:p-4 bg-white dark:bg-gray-800 lg:sticky top-0 z-[13] rounded-t-lg">
          <div className="flex flex-wrap items-center gap-2 sm:gap-4 relative">
            <div className="w-fit">
              <LinksLookupSearch
                handleFetchLinkSearchItems={handleFetchLinkSearchItems}
              />
            </div>
            <div className="max-w-[140px]">
              <ReactSelect
                options={linkOptions}
                value={link}
                handleOnChange={(selectedOption: any) =>
                  setLink(selectedOption)
                }
              />
            </div>
            <div className="max-w-[140px]">
              <ReactSelect
                options={statusOptions}
                value={status}
                placeholder="All Status"
                handleOnChange={(selectedOption: any) =>
                  setStatus(selectedOption)
                }
              />
            </div>
            <div className="max-w-[140px]">
              <ReactSelect
                options={mismatchOptions}
                value={mismatch}
                placeholder="All Status"
                handleOnChange={(selectedOption: any) =>
                  setMismatch(selectedOption)
                }
              />
            </div>
          </div>
          <button
            className="p-2 rounded-md border"
            onClick={() => setShowExportModal(true)}
          >
            <Icon
              name="Download"
              size={16}
              color={mode === 'dark' ? '#fff' : iconColor}
            />
          </button>
        </div>
        <TansStackTableV2
          data={!formattedDataMemo ? [] : formattedDataMemo}
          columns={columns}
          totalPages={linkBrief?.total_pages}
          totalDocs={linkBrief?.total_docs}
          page={page}
          setPage={setPage}
          loading={linkBrief.loading}
          stickyColumn="name"
        />
      </div>
      <CustomModal
        open={showExportModal}
        onClose={() => {
          setShowExportModal(false);
        }}
      >
        <ExportModal
          closeModal={() => {
            setShowExportModal(false);
          }}
          link={link}
          status={status}
          mismatch={mismatch}
          linkOptions={linkOptions}
          statusOptions={statusOptions}
          mismatchOptions={mismatchOptions}
        />
      </CustomModal>
      <CustomModal open={openModal} onClose={() => setOpenModal(false)}>
        <ViewLinksModal onClose={() => setOpenModal(false)} link={linkItem} />
      </CustomModal>
    </div>
  );
};

export default LinksLookupV2;
