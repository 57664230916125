import React, { useState, useEffect, Fragment } from 'react';
import ReactSelect from '../../../components/ReactSelect';

// AM REALLOCATE EXPORT
export const AMReallocate = ({ parentCallback, client, staff }) => {
  let staffArray = [];
  staff.data.map((key, i) => {
    if (key.role._id === 'am') {
      staffArray.push({
        value: key.first_name + ' ' + key.last_name,
        label: key.first_name + ' ' + key.last_name,
      });
    }
  });
  const [reallocateValues, setValues] = useState({
    type: 'reallocate staff',
    service: 'am',
    currentStaff: client.accountManager,
    proposedStaff: '',
    handoverCompleted: '',
  });

  const handleChange = (name) => (event) => {
    setValues({ ...reallocateValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(reallocateValues);
  }, [reallocateValues]);

  return (
    <Fragment>
      <div className="mb-[40px]">
        <div className="mb-6 text-[16px] text-[#005C9F]">
          AM Staff Reallocation
        </div>
        <div className="flex justify-between">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Current AM
            </p>
            <div>
              <input
                type="text"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="BEN HUNTER"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Proposed AM
            </p>
            <div className="text-[#7C7E7F]">
              <ReactSelect
                handleOnChange={handleChange('proposedStaff')}
                options={staff.length ? staffArray : ''}
                placeholder="PLEASE SELECT"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Has handover been completed?
            </p>
            <div className="text-[#7C7E7F]">
              <ReactSelect
                handleOnChange={handleChange('handoverCompleted')}
                options={[
                  { value: 'Handover Completed', label: 'Handover Completed' },
                  { value: 'Handover Pending', label: 'Handover Pending' },
                ]}
                placeholder="PLEASE SELECT"
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// SEO REALLOCATE EXPORT
export const SEOReallocate = ({ parentCallback, client, staff }) => {
  let staffArray = [];
  staff.data.map((key, i) => {
    if (key.role._id === 'seocon') {
      staffArray.push({
        value: key.first_name + ' ' + key.last_name,
        label: key.first_name + ' ' + key.last_name,
      });
    }
  });
  const [reallocateValues, setValues] = useState({
    type: 'reallocate staff',
    service: 'seo',
    currentStaff: client.seoLead,
    proposedStaff: '',
    handoverCompleted: '',
  });

  const handleChange = (name) => (event) => {
    setValues({ ...reallocateValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(reallocateValues);
  }, [reallocateValues]);

  return (
    <Fragment>
      <div className="mb-[40px]">
        <div className="mb-6 text-[16px] text-[#005C9F]">
          SEO Staff Reallocation
        </div>
        <div className="flex justify-between">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Current Consultant
            </p>
            <div>
              <input
                type="text"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="BEN HUNTER"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Proposed Consultant
            </p>
            <div className="text-[#7C7E7F]">
              <ReactSelect
                handleOnChange={handleChange('proposedStaff')}
                options={staff.length ? staffArray : ''}
                placeholder="PLEASE SELECT"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Has handover been completed?
            </p>
            <div className="text-[#7C7E7F]">
              <ReactSelect
                handleOnChange={handleChange('handoverCompleted')}
                options={[
                  { value: 'Handover Completed', label: 'Handover Completed' },
                  { value: 'Handover Pending', label: 'Handover Pending' },
                ]}
                placeholder="PLEASE SELECT"
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// PPC REALLOCATE EXPORT
export const PPCReallocate = ({ parentCallback, client, staff }) => {
  let staffArray = [];
  staff.data.map((key, i) => {
    if (key.role._id === 'ppccon') {
      staffArray.push({
        value: key.first_name + ' ' + key.last_name,
        label: key.first_name + ' ' + key.last_name,
      });
    }
  });
  const [reallocateValues, setValues] = useState({
    type: 'reallocate staff',
    service: 'ppc',
    currentStaff: client.ppcLead,
    proposedStaff: '',
    handoverCompleted: '',
  });

  const handleChange = (name) => (event) => {
    setValues({ ...reallocateValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(reallocateValues);
  }, [reallocateValues]);

  return (
    <Fragment>
      <div className="mb-[40px]">
        <div className="mb-6 text-[16px] text-[#005C9F]">
          PPC Staff Reallocation
        </div>
        <div className="flex justify-between">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Current Consultant
            </p>
            <div>
              <input
                type="text"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="BEN HUNTER"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Proposed Consultant
            </p>
            <div className="text-[#7C7E7F]">
              <ReactSelect
                handleOnChange={handleChange('proposedStaff')}
                options={staff.length ? staffArray : ''}
                placeholder="PLEASE SELECT"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Has handover been completed?
            </p>
            <div className="text-[#7C7E7F]">
              <ReactSelect
                handleOnChange={handleChange('handoverCompleted')}
                options={[
                  { value: 'Handover Completed', label: 'Handover Completed' },
                  { value: 'Handover Pending', label: 'Handover Pending' },
                ]}
                placeholder="PLEASE SELECT"
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
