import React, { ReactNode } from 'react';
import { Wrapper, Btn } from './styles';
import Icon from '../../assets/icons/SvgComponent';

interface ButtonIconProps {
  color?: string;
  icon?: string;
  children: ReactNode;
  [key: string]: any;
}

const ButtonIcon: React.FC<ButtonIconProps> = ({
  color = 'blue',
  icon,
  children,
  ...other
}: ButtonIconProps) => {
  return (
    <Wrapper color={color}>
      <Btn className="btn" color={color} {...other}>
        <span className="mr-[8px] font-inter text-[14px] font-[600] ">
          {children}
        </span>
        <div>
          <Icon name={`${icon}`} />
        </div>
      </Btn>
    </Wrapper>
  );
};

export default ButtonIcon;
