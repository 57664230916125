import React from 'react';

interface CheckProps {
  color?: string;
  size?: number;
}

const CheckCircle: React.FC<CheckProps> = ({
  color = '#428B6E',
  size = 28,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 28 28"
    fill="none"
  >
    <path
      d="M8.74992 14L12.2499 17.5L19.2499 10.5M25.6666 14C25.6666 20.4433 20.4432 25.6667 13.9999 25.6667C7.5566 25.6667 2.33325 20.4433 2.33325 14C2.33325 7.55669 7.5566 2.33334 13.9999 2.33334C20.4432 2.33334 25.6666 7.55669 25.6666 14Z"
      stroke={color || '#001C44'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckCircle;
