import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const ToggleDash: React.FC<IconProps> = ({ color = '#334969', size = 80 }) => (
  <svg
    width="16"
    height="2"
    viewBox="0 0 16 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.3173 0.925049H0.682765"
      stroke={color || '#334969'}
      strokeWidth="1.3028"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ToggleDash;
