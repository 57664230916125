import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

interface ModalProps {
  open: boolean;
  onClose: (value: boolean) => void;
  children: React.ReactNode;
}

const CustomModal: React.FC<ModalProps> = ({ open, onClose, children }) => {
  return (
    <AnimatePresence>
      {open && (
        <motion.div
          className="fixed top-0 left-0 z-[101] w-full h-screen bg-black/50 backdrop-blur-sm"
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
          onClick={() => onClose(false)}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CustomModal;
