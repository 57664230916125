import React from 'react';
import Icon from '../../../assets/icons/SvgComponent';
import useClientStore from '../../../store/useClientStore';
import { isLedGenClient } from '../../../utils/client';
import { Card } from '@tremor/react';

interface MetricsCardsProps {
  metrics?: any; // Type this properly based on your clientReport structure
}

const MetricsCards: React.FC<MetricsCardsProps> = ({ metrics }) => {
  const { selectedClient } = useClientStore((state) => state);
  const isLeadGen = isLedGenClient(selectedClient);

  const hasValue = metrics.some((metric) => parseFloat(metric.value) !== 0);

  const filteredMetrics = React.useMemo(() => {
    if (isLeadGen) {
      return metrics?.filter(
        (metric) =>
          metric.label !== 'Total Revenue' && metric.label !== 'Organic Revenue'
      );
    }
    return metrics;
  }, [metrics, isLeadGen]);

  if (!hasValue) {
    return (
      <div className="h-full flex flex-col justify-center items-center border border-[#E6EBF0] rounded-lg py-8">
        <p className=" dark:text-white text-xs">No records found</p>
        <Icon name="NoData" size={100} />
      </div>
    );
  }

  return (
    <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-3 mt-5">
      {filteredMetrics.map((metric, index) => {
        const color =
          metric?.value > metric?.prevPeriod?.replace(/[$-]/g, '')
            ? 'text-green-500'
            : metric?.value === metric?.prevPeriod?.replace(/[$-]/g, '')
              ? 'text-orange-500'
              : 'text-red-500';

        return (
          <Card key={index}>
            <div className="">
              <p className=" text-sm text-[#7C7E7F] font-inter ">
                {metric?.label}
              </p>

              <p className="font-bold text-3xl overflow-hidden text-[#337DB2] my-2">
                {metric?.value}
              </p>
              <div className="flex w-full  ">
                <span className="mr-1 font-inter text-xs text-[#7C7E7F]">
                  Prev period:
                </span>
                <span className={`font-inter text-xs ${color} mr-1`}>
                  {metric?.prevPeriod}
                </span>
              </div>
            </div>

            {metric?.prevYear ? (
              <>
                {/* <div className="flex w-full px-4 mt-3 ml-1">
              <span className="mr-1 font-inter text-sm text-[#7C7E7F]">Prev year</span>
              <span className={`font-inter text-sm ${yearColor}`}>{metric?.value}</span>
            </div>  */}
              </>
            ) : null}
          </Card>
        );
      })}
    </div>
  );
};

export default MetricsCards;
