import AreaCardChart from '../AreaCardChart';
import { formatToCurrency, formatNumber } from '../../../utils/common';
import ReactSelect from '../../ReactSelect';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import KPIBingAds from './KPIBingAds';

interface IProps {
  service?: any;
  setService?: any;
  isLeadGen?: boolean;
  isGoogleSeo?: boolean;
  isMicrosoftAds?: boolean;
  topPerformanceBing?: any;
  organic_sessions?: any;
  topPerformanceSeo?: any;
  requestGoogleSEOAccess?: any;
  selectedClient?: any;
  organic_totalRevenue?: any;
  lastMonthOrganicSession?: any;
  lastMonthOrganicRevenue?: any;
  organic_conversions_breakdown?: any;
  lastMonthTotalOrganicConversionsBreakdown?: any;
  clicks?: any;
  lastMonthClicks?: any;
  retryFetchGA4Analytics?: any;
  isGoogleAds?: boolean;
  adsCostMicros?: any;
  topPerformanceAds?: any;
  lastMonthAdsCostMicros?: any;
  adsClicks?: any;
  lastMonthAdsClicks?: any;
  adsConversions?: any;
  lastMonthAdsConversions?: any;
  requestGoogleAdsAccess?: any;
  adsCostPerConversion?: any;
  lastMonthAdsCostPerConversion?: any;
  adsRevenue?: any;
  lastMonthAdsRevenue?: any;
  adsRoas?: any;
  lastMonthAdsRoas?: any;
  isMeta?: boolean;
  topPerformanceMeta?: any;
  requestMetaAccess?: any;
  spend?: any;
  lastMonthMetaSpend?: any;
  metaClicks?: any;
  lastMonthMetaClicks?: any;
  metaConversions?: any;
  lastMonthMetaConversions?: any;
  metaCostPerConversion?: any;
  lastMonthMetaCostPerConversion?: any;
  revenue?: any;
  lastMonthMetaRevenue?: any;
  roas?: any;
  lastMonthMetaROAS?: any;
}

const MobileView = ({
  service,
  setService,
  isLeadGen,
  isGoogleSeo,
  isMicrosoftAds,
  topPerformanceBing,
  organic_sessions,
  topPerformanceSeo,
  requestGoogleSEOAccess,
  selectedClient,
  organic_totalRevenue,
  lastMonthOrganicSession,
  lastMonthOrganicRevenue,
  organic_conversions_breakdown,
  lastMonthTotalOrganicConversionsBreakdown,
  clicks,
  lastMonthClicks,
  retryFetchGA4Analytics,
  isGoogleAds,
  adsCostMicros,
  topPerformanceAds,
  lastMonthAdsCostMicros,
  adsClicks,
  lastMonthAdsClicks,
  adsConversions,
  lastMonthAdsConversions,
  requestGoogleAdsAccess,
  adsCostPerConversion,
  lastMonthAdsCostPerConversion,
  adsRevenue,
  lastMonthAdsRevenue,
  adsRoas,
  lastMonthAdsRoas,
  isMeta,
  topPerformanceMeta,
  requestMetaAccess,
  spend,
  lastMonthMetaSpend,
  metaClicks,
  lastMonthMetaClicks,
  metaConversions,
  lastMonthMetaConversions,
  metaCostPerConversion,
  lastMonthMetaCostPerConversion,
  revenue,
  lastMonthMetaRevenue,
  roas,
  lastMonthMetaROAS,
}: IProps) => {
  return (
    <div className="block sm:hidden">
      <ReactSelect
        value={service}
        options={[
          { value: 'seo', label: 'Google SEO' },
          { value: 'ads', label: 'Google Ads' },
          { value: 'bing', label: 'Microsoft Ads' },
          { value: 'meta', label: 'Meta' },
        ]}
        handleOnChange={(selectedOption: any) => {
          setService(selectedOption);
        }}
        placeholder={'All Services'}
      />
      <p className="text-[#FFF] text-sm py-2 mt-4 w-full font-inter font-[300]">
        Optimise your website's visibility, elevating its rank in search results
        to attract more organic traffic and engagement.
      </p>
      {service.value === 'seo' && (
        <div className="gap-4 mt-4">
          <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
            <SwiperSlide>
              {isLeadGen ? (
                <AreaCardChart
                  title={
                    topPerformanceSeo?.organic_sessions?.label ||
                    'Organic Sessions'
                  }
                  chatData={organic_sessions?.slice(0, -1)}
                  status={isGoogleSeo}
                  category={['Organic Sessions']}
                  totalValue={formatNumber(lastMonthOrganicSession)}
                  requestAccess={requestGoogleSEOAccess}
                  error={topPerformanceSeo.error}
                  isLoading={
                    !['activated', 'active'].includes(
                      selectedClient?.google_analytics?.ga4?.status
                    )
                      ? false
                      : topPerformanceSeo.loading
                  }
                  prevPeriod={
                    topPerformanceSeo?.organic_sessions?.previous_period
                  }
                  previousYear={
                    topPerformanceSeo?.organic_sessions?.previous_year
                  }
                  // noAccessSubtitle={googleSeoNoAccessSub}
                  info={
                    topPerformanceSeo?.organic_sessions?.info ||
                    'Organic Sessions'
                  }
                  icon="googleAnalytics"
                  showCurrentMonth={true}
                />
              ) : (
                <AreaCardChart
                  title="Organic Revenue"
                  chatData={organic_totalRevenue?.slice(0, -1)}
                  status={isGoogleSeo}
                  category={['Organic Revenue']}
                  totalValue={formatToCurrency(lastMonthOrganicRevenue)}
                  requestAccess={requestGoogleSEOAccess}
                  error={topPerformanceSeo.error}
                  isLoading={
                    !['activated', 'active'].includes(
                      selectedClient?.google_analytics?.ga4?.status
                    )
                      ? false
                      : topPerformanceSeo.loading
                  }
                  prevPeriod={
                    topPerformanceSeo?.organic_totalRevenue?.previous_period
                  }
                  previousYear={
                    topPerformanceSeo?.organic_totalRevenue?.previous_year
                  }
                  hasDollar
                  // noAccessSubtitle={googleSeoNoAccessSub}
                  info={
                    topPerformanceSeo?.organic_totalRevenue?.info ||
                    'Organic Revenue'
                  }
                  icon="googleAnalytics"
                  showCurrentMonth={true}
                />
              )}
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title={
                  topPerformanceSeo?.organic_conversions?.label
                    ? topPerformanceSeo?.organic_conversions?.label
                    : 'Organic Conversions'
                }
                chatData={organic_conversions_breakdown?.slice(0, -1)}
                category={['Total']}
                info={
                  topPerformanceSeo?.organic_conversions?.info
                    ? topPerformanceSeo?.organic_conversions?.info
                    : 'Organic Conversions'
                }
                prevPeriod={
                  topPerformanceSeo?.organic_conversions?.previous_period
                }
                previousYear={
                  topPerformanceSeo?.organic_conversions?.previous_year
                }
                isLoading={topPerformanceSeo.loading}
                totalValue={formatNumber(
                  lastMonthTotalOrganicConversionsBreakdown
                )}
                error={topPerformanceSeo.error}
                customDataTooltip={true}
                icon="googleAnalytics"
                retryMethod={retryFetchGA4Analytics}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title={topPerformanceSeo?.clicks?.label || 'Clicks'}
                chatData={clicks?.slice(0, -1)}
                status={isGoogleSeo}
                category={['Clicks']}
                totalValue={formatNumber(lastMonthClicks)}
                requestAccess={requestGoogleSEOAccess}
                error={topPerformanceSeo.error}
                prevPeriod={topPerformanceSeo?.clicks?.previous_period}
                previousYear={topPerformanceSeo?.clicks?.previous_year}
                isLoading={
                  !['activated', 'active'].includes(
                    selectedClient?.google_analytics?.ga4?.status
                  )
                    ? false
                    : topPerformanceSeo.loading
                }
                // noAccessSubtitle={googleSeoNoAccessSub}
                info={topPerformanceSeo?.clicks?.info}
                icon="googleSearch"
                showCurrentMonth={true}
              />
            </SwiperSlide>
          </Swiper>
        </div>
      )}
      {service.value === 'ads' && (
        <div className="gap-4 mt-4">
          <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
            <SwiperSlide>
              <AreaCardChart
                title={topPerformanceAds?.cost_micros?.label || 'Spend'}
                chatData={adsCostMicros?.slice(0, -1)}
                status={isGoogleAds}
                category={['Spend']}
                totalValue={formatToCurrency(lastMonthAdsCostMicros)}
                error={topPerformanceAds.error}
                prevPeriod={topPerformanceAds?.cost_micros?.previous_period}
                previousYear={topPerformanceAds?.cost_micros?.previous_year}
                isLoading={
                  !['activated', 'active'].includes(
                    selectedClient?.google_ads?.ga4?.status
                  )
                    ? false
                    : topPerformanceAds.loading
                }
                requestAccess={requestGoogleAdsAccess}
                // noAccessSubtitle={googleAdsNoAccessSub}
                info={topPerformanceAds?.cost_micros?.info || 'Spend'}
                icon="googleAd"
                showCurrentMonth={true}
                hasDollar
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title={topPerformanceAds?.clicks?.label || 'Clicks'}
                chatData={adsClicks?.slice(0, -1)}
                status={isGoogleAds}
                category={['Clicks']}
                totalValue={formatNumber(lastMonthAdsClicks)}
                error={topPerformanceAds.error}
                prevPeriod={topPerformanceAds?.clicks?.previous_period}
                previousYear={topPerformanceAds?.clicks?.previous_year}
                isLoading={
                  !['activated', 'active'].includes(
                    selectedClient?.google_ads?.ga4?.status
                  )
                    ? false
                    : topPerformanceAds.loading
                }
                requestAccess={requestGoogleAdsAccess}
                // noAccessSubtitle={googleAdsNoAccessSub}
                info={topPerformanceAds?.clicks?.info || 'Clicks'}
                icon="googleAd"
                showCurrentMonth={true}
              />
            </SwiperSlide>
            {isLeadGen ? (
              <>
                <SwiperSlide>
                  <AreaCardChart
                    title={
                      topPerformanceAds?.conversions?.label || 'Conversions'
                    }
                    chatData={adsConversions?.slice(0, -1)}
                    status={isGoogleAds}
                    category={['Conversions']}
                    totalValue={formatNumber(lastMonthAdsConversions)}
                    error={topPerformanceAds.error}
                    prevPeriod={topPerformanceAds?.conversions?.previous_period}
                    previousYear={topPerformanceAds?.conversions?.previous_year}
                    isLoading={topPerformanceAds?.loading}
                    requestAccess={requestGoogleAdsAccess}
                    // noAccessSubtitle={googleAdsNoAccessSub}
                    customDataTooltip={true}
                    info={topPerformanceAds?.overall?.info || 'Conversions'}
                    icon="googleAd"
                    showCurrentMonth={true}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <AreaCardChart
                    title={`${topPerformanceAds?.cost_per_conversion?.label || 'Cost Per Conversion'}`}
                    chatData={adsCostPerConversion?.slice(0, -1)}
                    status={isGoogleAds}
                    category={['Cost Per Conversion']}
                    totalValue={formatToCurrency(lastMonthAdsCostPerConversion)}
                    prevPeriod={
                      -topPerformanceAds?.cost_per_conversion?.previous_period
                    }
                    previousYear={
                      -topPerformanceAds?.cost_per_conversion?.previous_year
                    }
                    isLoading={
                      !['activated', 'active'].includes(
                        selectedClient?.google_ads?.ga4?.status
                      )
                        ? false
                        : topPerformanceAds.loading
                    }
                    error={topPerformanceAds.error}
                    hasDollar={true}
                    requestAccess={requestGoogleAdsAccess}
                    // noAccessSubtitle={googleAdsNoAccessSub}
                    info={
                      topPerformanceAds?.cost_per_conversion?.info ||
                      'Cost Per Conversion'
                    }
                    icon="googleAd"
                    showCurrentMonth={true}
                  />
                </SwiperSlide>
              </>
            ) : (
              <>
                <SwiperSlide>
                  <AreaCardChart
                    title={topPerformanceAds?.revenue?.label || 'Revenue'}
                    chatData={adsRevenue?.slice(0, -1)}
                    category={['Revenue']}
                    status={isGoogleAds}
                    prevPeriod={topPerformanceAds?.revenue?.previous_period}
                    previousYear={topPerformanceAds?.revenue?.previous_year}
                    totalValue={formatToCurrency(lastMonthAdsRevenue)}
                    isLoading={!isGoogleAds ? false : topPerformanceAds.loading}
                    error={topPerformanceAds.error}
                    hasDollar={true}
                    requestAccess={requestGoogleAdsAccess}
                    // noAccessSubtitle={googleMetaNoAccessSub}
                    info={topPerformanceAds?.revenue?.info || 'Revenue'}
                    icon="googleAd"
                    showCurrentMonth={true}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <AreaCardChart
                    title={`${topPerformanceAds?.roas?.label || 'ROAS'}`}
                    chatData={adsRoas?.slice(0, -1)}
                    category={['Roas']}
                    status={isGoogleAds}
                    prevPeriod={topPerformanceAds?.roas?.previous_period}
                    previousYear={topPerformanceAds?.roas?.previous_year}
                    totalValue={formatNumber(lastMonthAdsRoas)}
                    isLoading={!isGoogleAds ? false : topPerformanceAds.loading}
                    error={topPerformanceAds.error}
                    // hasDollar={true}
                    requestAccess={requestGoogleAdsAccess}
                    // noAccessSubtitle={googleMetaNoAccessSub}
                    info={topPerformanceAds?.roas?.info || 'ROAS'}
                    icon="googleAd"
                    showCurrentMonth={true}
                  />
                </SwiperSlide>
              </>
            )}
          </Swiper>
        </div>
      )}
      {service.value === 'bing' && (
        <KPIBingAds
          isLeadGen={isLeadGen}
          isMicrosoftAds={isMicrosoftAds}
          selectedClient={selectedClient}
          topPerformanceBing={topPerformanceBing}
          requestBingAdsAccess={requestGoogleAdsAccess}
        />
      )}
      {service.value === 'meta' && (
        <div className="gap-4 mt-4">
          <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
            <SwiperSlide>
              <AreaCardChart
                title={`${topPerformanceMeta?.spend?.label || 'Spend'}`}
                chatData={spend?.slice(0, -1)}
                category={['Spend']}
                status={isMeta}
                prevPeriod={topPerformanceMeta?.spend?.previous_period}
                previousYear={topPerformanceMeta?.spend?.previous_year}
                totalValue={formatToCurrency(lastMonthMetaSpend)}
                isLoading={!isMeta ? false : topPerformanceMeta.loading}
                error={topPerformanceMeta.error}
                hasDollar={true}
                requestAccess={requestMetaAccess}
                info={topPerformanceMeta?.spend?.info || 'Spend'}
                icon="meta"
                showCurrentMonth={true}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title={`${topPerformanceMeta?.clicks?.label || 'Clicks'}`}
                chatData={metaClicks?.slice(0, -1)}
                category={['Clicks']}
                status={isMeta}
                prevPeriod={topPerformanceMeta?.clicks?.previous_period}
                previousYear={topPerformanceMeta?.clicks?.previous_year}
                totalValue={formatNumber(lastMonthMetaClicks)}
                isLoading={!isMeta ? false : topPerformanceMeta.loading}
                error={topPerformanceMeta.error}
                hasDollar={true}
                requestAccess={requestMetaAccess}
                info={topPerformanceMeta?.clicks?.info || 'Clicks'}
                icon="meta"
                showCurrentMonth={true}
              />
            </SwiperSlide>
            {isLeadGen ? (
              <>
                <SwiperSlide>
                  <AreaCardChart
                    title={
                      topPerformanceMeta?.conversions?.label || 'Conversions'
                    }
                    chatData={metaConversions?.slice(0, -1)}
                    category={['Conversions']}
                    status={isMeta}
                    prevPeriod={
                      topPerformanceMeta?.conversions?.previous_period
                    }
                    previousYear={
                      topPerformanceMeta?.conversions?.previous_year
                    }
                    totalValue={formatNumber(lastMonthMetaConversions)}
                    isLoading={!isMeta ? false : topPerformanceMeta.loading}
                    error={topPerformanceMeta.error}
                    requestAccess={requestMetaAccess}
                    info={
                      topPerformanceMeta?.conversions?.info || 'Conversions'
                    }
                    icon="meta"
                    showCurrentMonth={true}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <AreaCardChart
                    title={
                      topPerformanceMeta?.cpc?.label || 'Cost Per Conversion'
                    }
                    chatData={metaCostPerConversion?.slice(0, -1)}
                    category={['Cpc']}
                    status={isMeta}
                    prevPeriod={topPerformanceMeta?.cpc?.previous_period}
                    previousYear={topPerformanceMeta?.cpc?.previous_year}
                    totalValue={formatToCurrency(
                      lastMonthMetaCostPerConversion
                    )}
                    isLoading={!isMeta ? false : topPerformanceMeta.loading}
                    error={topPerformanceMeta.error}
                    requestAccess={requestMetaAccess}
                    hasDollar={true}
                    info={topPerformanceMeta?.cpc?.info}
                    icon="meta"
                    showCurrentMonth={true}
                  />
                </SwiperSlide>
              </>
            ) : (
              <>
                <SwiperSlide>
                  <AreaCardChart
                    title={topPerformanceMeta?.revenue?.label || 'Revenue'}
                    chatData={revenue?.slice(0, -1)}
                    category={['Revenue']}
                    status={isMeta}
                    prevPeriod={topPerformanceMeta?.revenue?.previous_period}
                    previousYear={topPerformanceMeta?.revenue?.previous_year}
                    totalValue={formatToCurrency(lastMonthMetaRevenue)}
                    isLoading={!isMeta ? false : topPerformanceMeta.loading}
                    error={topPerformanceMeta.error}
                    hasDollar={true}
                    requestAccess={requestMetaAccess}
                    info={topPerformanceMeta?.revenue?.info || 'Revenue'}
                    icon="meta"
                    showCurrentMonth={true}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <AreaCardChart
                    title="ROAS"
                    chatData={roas?.slice(0, -1)}
                    category={['Roas']}
                    status={isMeta}
                    prevPeriod={topPerformanceMeta?.roas?.previous_period}
                    previousYear={topPerformanceMeta?.roas?.previous_year}
                    totalValue={formatNumber(lastMonthMetaROAS)}
                    isLoading={!isMeta ? false : topPerformanceMeta.loading}
                    error={topPerformanceMeta.error}
                    requestAccess={requestMetaAccess}
                    info={topPerformanceMeta?.roas?.info}
                    icon="meta"
                    showCurrentMonth={true}
                  />
                </SwiperSlide>
              </>
            )}
          </Swiper>
        </div>
      )}
    </div>
  );
};

export default MobileView;
