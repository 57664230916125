import Icon from '../../../assets/icons/SvgComponent';

interface TrendTooltipProps {
  percentage: any;
  trendIcon?: boolean;
  children: any;
}

const TrendTooltip: React.FC<TrendTooltipProps> = ({
  percentage,
  trendIcon,
  children,
}) => {
  let tooltipClass =
    'absolute right-[-3vw]  top-[-150px] hidden group-hover:inline-block bg-gradient-to-b from-blue-900 via-blue-900 to-blue-600 text-white text-xs p-2 whitespace-pre-line rounded w-[161px] h-[95px] z-[99]  scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8';
  let arrowClass =
    'absolute hidden group-hover:inline-block  left-1/2 -translate-x-1/2 bottom-full';

  return (
    <div className="relative cursor-pointer group">
      <div>{children}</div>
      {/* <span className={tooltipClass}>
        <div className="relative h-full w-full flex flex-col justify-center items-center p-2 gap-4">
          <div className="w-full flex justify-center items-center">
            <span className="text-[25px]">{percentage}</span>
            {percentage !== '0' && (
              <>
                {trendIcon ? (
                  <Icon name="Arrowup" color="#fff" size={20} />
                ) : (
                  <Icon name="arrowDown" color="#fff" size={20} />
                )}
              </>
            )}
          </div>
          <div className="text-[18px] w-full text-center font-[400]">
            Traffic Growth
          </div>
        </div>
      </span>
      <span className={arrowClass}>
        <Icon name="arrowDown" />
      </span> */}
    </div>
  );
};

export default TrendTooltip;
