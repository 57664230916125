import React, { CSSProperties } from 'react';
import Select, { Props as SelectProps, StylesConfig } from 'react-select';
import { StyledSelect } from './styles';
export interface OptionProps {
  [key: string]: any;
}

export interface DomainDropdownProps {
  options: OptionProps[];
  labelKey: string;
  valueKey: string;
  defaultValue?: string;
  placeholder?: string;
  customStyle?: CSSProperties;
  onSelect: (selectedOption: OptionProps) => void;
  disableSearch?: boolean;
  showLabelValue?: boolean;
}

const DomainDropdown: React.FC<DomainDropdownProps> = ({
  options = [],
  labelKey = 'label',
  valueKey = 'value',
  disableSearch = false,
  defaultValue = '',
  placeholder = '',
  showLabelValue = false,
  customStyle = {},
  onSelect,
}) => {
  const selectOptions = options.map((option) => ({
    ...option,
    label: showLabelValue
      ? `${option?.[labelKey]} - ${option?.[valueKey]}`
      : option?.[labelKey],
    value: option?.[valueKey],
  }));

  const defaultOption =
    typeof defaultValue === 'string' && defaultValue?.length
      ? { label: defaultValue }
      : null;

  const handleChange = (selectedOption: any) => {
    onSelect(selectedOption);
  };

  const customStyles: StylesConfig<any, boolean, any> = {
    control: (provided) => ({
      ...provided,
      ...customStyle,
      ...{ width: customStyle.width || '100%' },
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      backgroundColor: '#FFF',
      borderRadius: '8px',
      border: 'none',
      boxShadow: ' 0px 1px 2px 0px gba(16, 24, 40, 0.05)',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#000',
      fontSize: 14,
      fontWeight: 600,
      padding: 0,
    }),
    // indicatorSeparator: () => ({
    //   display: 'none',
    // }),

    singleValue: (provided) => ({
      ...provided,
      color: '#000',
      fontSize: 14,
      padding: 0,
    }),
  };

  return (
    <StyledSelect
      options={selectOptions}
      value={defaultOption}
      placeholder={placeholder}
      isSearchable={!disableSearch}
      isDisabled={disableSearch}
      onChange={handleChange}
      styles={customStyles}
    />
  );
};

export default DomainDropdown;
