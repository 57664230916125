import React from 'react';

interface GoogleChromeProps {
  color?: string;
}

const GoogleChrome: React.FC<GoogleChromeProps> = ({ color = '#428B6E' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="29"
    viewBox="0 0 30 29"
    fill="none"
  >
    <path
      d="M14.9998 9.16669C12.0543 9.16669 9.6665 11.5545 9.6665 14.5C9.6665 17.4455 12.0543 19.8334 14.9998 19.8334C17.9454 19.8334 20.3332 17.4455 20.3332 14.5C20.3332 11.5545 17.9454 9.16669 14.9998 9.16669ZM14.9998 9.16669H27.2265M4.2665 6.58002L10.3865 17.1667M13.5065 27.7534L19.6132 17.1667M28.3332 14.5C28.3332 21.8638 22.3636 27.8334 14.9998 27.8334C7.63604 27.8334 1.6665 21.8638 1.6665 14.5C1.6665 7.13622 7.63604 1.16669 14.9998 1.16669C22.3636 1.16669 28.3332 7.13622 28.3332 14.5Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default GoogleChrome;
