import React from 'react';

interface Props {
  size?: string;
  color?: string;
}

const Arrowup: React.FC<Props> = ({ size = '16' ,color = '#7C7E7F' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M7.99992 3.33301V12.6663M7.99992 12.6663L12.6666 7.99967M7.99992 12.6663L3.33325 7.99967"
      stroke={color}
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Arrowup;
