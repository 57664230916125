import { create } from 'zustand';
import Api from '../api';
import { devtools } from 'zustand/middleware';
const initialStatus = {
  loading: true,
  errorMsg: '',
  isSuccessful: true,
};
const useChangeStore = create(
  devtools((set, get) => ({
    kpis: [],
    autoStaff:[],
    kpiStatus: initialStatus,
    selectedStaff: initialStatus,
    error: false,
    apiStatus: '',
    loading: false,
    errorMessages: '',
    fetchKpis: async (filters) => {
      set(() => ({ loading: true }));
      const response = await Api.Kpis.getKpis(filters);
      set(() => ({ kpis: response }));
      set(() => ({ loading: false }));
      return response;
    },
    setStaff: async (staff) => {
      set(() => ({ loading: true }));
      const response = await Api.Kpis.getKpiStaff(staff);
      set(() => ({ autoStaff: response }));
    }
  }))
);

export default useChangeStore;
