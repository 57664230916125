import React from 'react';

interface IconProps {
  color?: string;
}

const AlignLeft: React.FC<IconProps> = ({ color = '#344054' }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="align-left">
      <path
        id="Icon"
        d="M16 10.5H3M20 6.5H3M20 14.5H3M16 18.5H3"
        stroke={color || '#344054'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default AlignLeft;
