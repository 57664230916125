import { requests } from "./ApiService";
import { toQueryString } from "../utils/common";

export const Activities = {
  getTasks: (filters) => requests.get(`/api/v1/teamwork/tasks/${filters.client_id}${toQueryString({
    tag: filters.tag,
  })}`),
  getCommunications: (filters) => requests.get(`/api/v1/hubspot/engagements${toQueryString({
    companyId: filters.companyId,
    country: filters.country,
    startDate: filters.startDate,
    endDate: filters.endDate,
  })}`)
};