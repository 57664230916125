import { useState } from 'react';
import Icon from '../../assets/icons/SvgComponent';
import { useThemeStore } from '../../store/useThemeStore';

const CopyToClipboardButton = ({ textToCopy, size, disabled=false }) => {
  const { mode } = useThemeStore((state: any) => state);
  const iconColor = mode === 'dark' ? 'white' : 'black';
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(textToCopy);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  return (
    <div className="flex items-center space-x-2">
      <button 
        disabled={disabled}
        onClick={copyToClipboard} className="rounded">
        <Icon name="Copy" color={iconColor} size={size} />
      </button>
      {isCopied && (
        <div className="absolute bottom-12 left-[40%] p-2 font-[400] text-md text-white bg-black rounded-full opacity-100 transition-opacity duration-500">
          Copied to clipboard!
        </div>
      )}
    </div>
  );
};

export default CopyToClipboardButton;
