import React from 'react';
import { Badge, Flex } from '@tremor/react';
import Icon from '../../assets/icons/SvgComponent';
import useReportStore from '../../store/useReportStore';
import { useLocation, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperButtonNext from '../SwiperButton/swiper';
import { Pagination } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useThemeStore } from '../../store/useThemeStore';

interface StatusCounterProp {
  clickable?: boolean;
  loading?: boolean;
}

const StatusCounter: React.FC<StatusCounterProp> = ({ clickable, loading }) => {
  const { mode } = useThemeStore((state) => state);
  const { summary } = useReportStore((state) => state);
  const { reportStatus } = useReportStore((state) => state.filters);
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const urlStatus = searchParams.get('status');
  const defalutStatus = urlStatus || reportStatus?.id;
  const [activeStatus, setActiveStatus] = React.useState(
    defalutStatus || 'in_progress'
  );

  const statusCounts = [
    {
      label: 'Draft',
      id: 'draft',
      count: summary?.data?.draft,
      bgColor: 'slate',
    },
    {
      label: 'Progress',
      id: 'in_progress',
      count: summary?.data?.in_progress,
      bgColor: 'orange',
    },
    {
      label: 'Review',
      id: 'review',
      count: summary?.data?.review,
      bgColor: 'emerald',
    },
    {
      label: 'Rejected',
      id: 'rejected',
      count: summary?.data?.rejected,
      bgColor: 'rose',
    },
    {
      label: 'Validated',
      id: 'validated',
      count: summary?.data?.validated,
      bgColor: 'teal',
    },
    {
      label: 'Approved',
      id: 'approved',
      count: summary?.data?.approved,
      bgColor: 'sky',
    },
    {
      label: 'Scheduled',
      id: 'scheduled',
      count: summary?.data?.scheduled,
      bgColor: 'orange',
    },
    { label: 'Sent', id: 'sent', count: summary?.data?.sent, bgColor: 'blue' },
    {
      label: 'Canceled',
      id: 'canceled',
      count: summary?.data?.canceled,
      bgColor: 'rose',
    },
    { label: 'All', id: 'all', count: summary?.data?.all, bgColor: 'neutral' },
  ];

  const handleReportTypes = (status: string) => {
    if (clickable) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('status', status);
      setActiveStatus(status);
      const updatedSearch = searchParams?.toString();
      location.search = updatedSearch;
      navigate(location);
    }
  };

  return (
    <>
      <Flex
        justifyContent={`${clickable ? 'start' : 'between'}`}
        className={`${clickable ? 'gap-x-3 ' : 'flex-wrap lg:flex-nowrap '} hidden sm:flex`}
      >
        {statusCounts?.map((report: any, index: number) => (
          <div
            key={index}
            className={`flex flex-col gap-1 p-1 items-center w-20 font-inter font-[600] rounded-md ${
              clickable
                ? `cursor-pointer  ${
                    activeStatus === report.id
                      ? `bg-${report.bgColor}-100 text-black dark:bg-gray-800 dark:text-white shadow`
                      : 'bg-white text-[#7C7E7F] dark:bg-transparent dark:text-gray-500 '
                  }`
                : ' cursor-default '
            } `}
            onClick={() => handleReportTypes(report.id)}
          >
            <div className="dark:text-white text-xs ">{report.label}</div>
            {loading ? (
              <div className="w-5 h-[25px]">
                <Icon name="Loading" size={20} />
              </div>
            ) : (
              <Badge
                className={`  ${clickable ? 'cursor-pointer h-[24px]' : 'cursor-default'}`}
                color={report.bgColor}
              >
                {report?.count}
              </Badge>
            )}
          </div>
        ))}
      </Flex>

      <div className="relative block w-full sm:hidden">
        <Swiper
          className="h-full flex justify-between mySwiper"
          modules={[Pagination]}
          spaceBetween={2}
          slidesPerView={3}
        >
          <div className="bg-white">
            <SwiperButtonNext next={true}>
              <Icon
                name="ChevronRight"
                size={30}
                color={mode === 'dark' ? '#FFF' : '#000'}
              />
            </SwiperButtonNext>
          </div>
          {statusCounts?.map((report: any, index: number) => (
            <SwiperSlide
              key={index}
              className={`font-inter font-[600] rounded-md ${
                clickable
                  ? `cursor-pointer  ${
                      activeStatus === report.id
                        ? `bg-${report.bgColor}-100 text-black dark:bg-gray-800 dark:text-white shadow`
                        : 'bg-white text-[#7C7E7F] dark:bg-transparent dark:text-gray-500 '
                    }`
                  : ' cursor-default '
              }`}
              onClick={() => handleReportTypes(report.id)}
            >
              <div className="w-full text-center">{report.label}</div>
            </SwiperSlide>
          ))}
          <div className="bg-white">
            <SwiperButtonNext prev={true}>
              <Icon
                name="ChevronLeft"
                size={30}
                color={mode === 'dark' ? '#FFF' : '#000'}
              />
            </SwiperButtonNext>
          </div>
        </Swiper>
      </div>
    </>
  );
};

export default StatusCounter;
