import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const InfoIcon: React.FC<IconProps> = ({ color = 'black', size = 40 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width={size}
      height={size}
      rx="6"
      fill={color ? '#191919' : 'white'}
    />
    <path
      d="M18.7497 17.5019C18.8966 17.0845 19.1864 16.7325 19.5678 16.5083C19.9493 16.2841 20.3978 16.2022 20.8339 16.277C21.27 16.3518 21.6656 16.5785 21.9505 16.917C22.2355 17.2555 22.3914 17.6839 22.3908 18.1264C22.3908 19.3755 20.5172 20 20.5172 20M20.5413 22.5H20.5496M20.4164 26.6667C24.3284 26.6667 27.4997 23.4953 27.4997 19.5833C27.4997 15.6713 24.3284 12.5 20.4164 12.5C16.5044 12.5 13.3331 15.6713 13.3331 19.5833C13.3331 20.375 13.4629 21.1363 13.7025 21.8472C13.7927 22.1147 13.8378 22.2484 13.8459 22.3512C13.8539 22.4527 13.8479 22.5238 13.8228 22.6225C13.7974 22.7223 13.7412 22.8262 13.629 23.034L12.2659 25.557C12.0715 25.9168 11.9743 26.0968 11.996 26.2356C12.015 26.3566 12.0862 26.4631 12.1907 26.5269C12.3107 26.6001 12.5141 26.579 12.921 26.537L17.1885 26.0958C17.3178 26.0825 17.3824 26.0758 17.4413 26.0781C17.4992 26.0803 17.5401 26.0857 17.5966 26.0987C17.654 26.112 17.7262 26.1398 17.8707 26.1954C18.6607 26.4998 19.5191 26.6667 20.4164 26.6667Z"
      stroke={color || '#334969'}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default InfoIcon;
