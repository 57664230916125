import React from 'react';

interface DotProps {
  color?: string;
  size?: number;
}

const BadgeStatus: React.FC<DotProps> = ({ color = '#428B6E', size = 16 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_831_1891)">
      <path
        d="M8.85716 10.0002C6.64802 10.0002 4.85716 8.2093 4.85716 6.00016V2.29646C4.85716 2.02056 4.85716 1.88261 4.89736 1.77215C4.96476 1.58697 5.11063 1.4411 5.29581 1.3737C5.40627 1.3335 5.54422 1.3335 5.82012 1.3335H11.8942C12.1701 1.3335 12.308 1.3335 12.4185 1.3737C12.6037 1.4411 12.7496 1.58697 12.817 1.77215C12.8572 1.88261 12.8572 2.02056 12.8572 2.29646V6.00016C12.8572 8.2093 11.0663 10.0002 8.85716 10.0002ZM8.85716 10.0002V12.0002M12.8572 2.66683H14.5238C14.8345 2.66683 14.9898 2.66683 15.1123 2.71758C15.2756 2.78524 15.4054 2.91502 15.4731 3.07837C15.5238 3.20089 15.5238 3.3562 15.5238 3.66683V4.00016C15.5238 4.62014 15.5238 4.93013 15.4557 5.18447C15.2707 5.87465 14.7316 6.41375 14.0415 6.59868C13.7871 6.66683 13.4771 6.66683 12.8572 6.66683M4.85716 2.66683H3.19049C2.87986 2.66683 2.72455 2.66683 2.60204 2.71758C2.43868 2.78524 2.3089 2.91502 2.24124 3.07837C2.19049 3.20089 2.19049 3.3562 2.19049 3.66683V4.00016C2.19049 4.62014 2.19049 4.93013 2.25864 5.18447C2.44357 5.87465 2.98267 6.41375 3.67285 6.59868C3.92719 6.66683 4.23718 6.66683 4.85716 6.66683M5.82012 14.6668H11.8942C12.0578 14.6668 12.1905 14.5342 12.1905 14.3705C12.1905 13.0614 11.1292 12.0002 9.82012 12.0002H7.89419C6.58508 12.0002 5.52382 13.0614 5.52382 14.3705C5.52382 14.5342 5.65648 14.6668 5.82012 14.6668Z"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_831_1891">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0.857178)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default BadgeStatus;
