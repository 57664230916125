import React from 'react';

interface ChevronUpProps {
  color?: string;
  size?: number;
}

const ChevronUp: React.FC<ChevronUpProps> = ({
  color = '#808DA1',
  size = 24,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color || '#808DA1'}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-chevron-up"
  >
    <polyline points="18 15 12 9 6 15"></polyline>
  </svg>
);

export default ChevronUp;
