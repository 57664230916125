import useGoogleStore from '../../../store/useGoogleStore';
import ServiceNotAvailable from '../../Common/ServiceNotAvailable';
import useClientStore from '../../../store/useClientStore';
import ErrorTxt from '../../Common/ErrorTxt';
import useUtilityStore from '../../../store/useUtilityStore';
import CampaignOverviewPPC from '../../AnalyticsSections/PpcSections/CampaignOverviewPPC';
import AdGroupOverviewPPC from '../../AnalyticsSections/PpcSections/AdGroupOverviewPPC';
import KeywordOverviewPPC from '../../AnalyticsSections/PpcSections/KeywordOverviewPPC';
import TopPerformingAdOverviewPPC from '../../AnalyticsSections/PpcSections/TopPerformingAdOverviewPPC';
import QualityScoreOverviewPPC from '../../AnalyticsSections/PpcSections/QualityScoreOverviewPPC';
import ImpressionShareOverviewPPC from '../../AnalyticsSections/PpcSections/ImpressionShareOverviewPPC';
import DeviceOverviewPPC from '../../AnalyticsSections/PpcSections/DeviceOverviewPPC';
import { useState, useEffect } from 'react';
import { Card, Grid, Col } from '@tremor/react';
import CampaignReport from '../../AnalyticsSections/CampaignReport';
import Accordion from '../../Accordion';
interface IProps {
  isClientActivated?: boolean;
  clientLoading: boolean;
}

interface UtilityStoreState {
  campaignReport: {
    label: string;
    value: number;
  };
}

const AnalyticsPPC = ({ clientLoading = false }: IProps) => {
  const { campaignReport } = useUtilityStore() as UtilityStoreState;
  const { campaignsReport } = useGoogleStore((state) => state);
  const { selectedClient } = useClientStore((state) => state);

  // charts data
  const overallAds = campaignsReport?.chartsData?.overall || {};
  const perfomanceMaxAds = campaignsReport?.chartsData?.performance_max || {};
  const searchAds = campaignsReport?.chartsData?.search || {};
  const shoppingAds = campaignsReport?.chartsData?.shopping || {};
  const videoAds = campaignsReport?.chartsData?.video || {};

  // table data
  const campaignResponse = campaignsReport?.campaigns;
  const [filteredCampaigns, setFilteredCampaigns] = useState(campaignResponse);

  delete overallAds?.dates;
  delete perfomanceMaxAds?.dates;
  delete searchAds?.dates;
  delete shoppingAds?.dates;
  delete videoAds?.dates;

  const filterByChannelType = (channelType) => {
    const filtered = campaignResponse?.filter(
      (item) => item.advertising_channel_type === channelType
    );

    setFilteredCampaigns(filtered);
  };

  const isPublicDashboard = window.location.pathname === '/client/dashboard';
  const noServiceAvailable = ['activated', 'active'].includes(
    selectedClient?.ppc_google?.status
  )
    ? true
    : false;
  const noIntegrationAvailable =
    selectedClient?.google_ads?.customer_id &&
    selectedClient?.google_ads?.status === 'activated'
      ? true
      : false;

  useEffect(() => {
    if (campaignReport.value === 0) {
      setFilteredCampaigns(campaignResponse ? campaignResponse : []);
    } else {
      filterByChannelType(campaignReport.value);
    }
  }, [campaignReport, campaignResponse]);

  if (!clientLoading) {
    if (!noServiceAvailable)
      return (
        <ServiceNotAvailable
          title={isPublicDashboard ? 'No Dashboard Available' : undefined}
          serviceType="ppc"
          hideRequestAccess={isPublicDashboard}
        />
      );
    if (!noIntegrationAvailable)
      return (
        <ServiceNotAvailable
          title={
            isPublicDashboard
              ? 'No Dashboard Available'
              : 'Integration Not Set Up'
          }
          serviceType="ppc"
          hideRequestAccess={isPublicDashboard}
        />
      );
  }

  return (
    <div className="sm:pt-4 flex flex-col gap-2 sm:gap-4">
      {campaignsReport.error && !campaignsReport.loading && (
        <ErrorTxt message={campaignsReport.error} title="Google Ads" />
      )}
      {/* mobileView */}
      <Accordion
        title={campaignReport.label}
        className="block sm:hidden dark:bg-[#222732] px-2 pt-2 pb-4 sm:p-2 mt-0"
      >
        <CampaignReport />
      </Accordion>
      <Accordion
        title="Campaign Overview"
        className="block sm:hidden dark:bg-[#222732] px-2 py-2 mt-0"
        icon="googleAd"
      >
        <CampaignOverviewPPC filteredCampaigns={filteredCampaigns} />
      </Accordion>
      <Accordion
        title="Ad Group Overview"
        className="block sm:hidden dark:bg-[#222732] px-2 py-2 mt-0"
        icon="googleAd"
      >
        <AdGroupOverviewPPC />
        {/*  */}
      </Accordion>
      <Accordion
        title="Keyword Overview"
        className="block sm:hidden dark:bg-[#222732] px-2 py-2 mt-0"
        icon="googleAd"
      >
        <KeywordOverviewPPC />
      </Accordion>
      <Accordion
        title="Top Performing Ads"
        className="block sm:hidden dark:bg-[#222732] px-2 py-2 mt-0"
        icon="googleAd"
      >
        <TopPerformingAdOverviewPPC />
      </Accordion>
      <Accordion
        title="Quality Score Overview"
        className="block sm:hidden dark:bg-[#222732] px-2 py-2 mt-0"
        icon="googleAd"
      >
        <QualityScoreOverviewPPC />
      </Accordion>
      <Accordion
        title="Impression Share Overview"
        className="block sm:hidden dark:bg-[#222732] px-2 py-2 mt-0"
        icon="googleAd"
      >
        <ImpressionShareOverviewPPC />
      </Accordion>
      <Accordion
        title="Devices Overview"
        className="block sm:hidden dark:bg-[#222732] px-2 py-2 mt-0"
      >
        <DeviceOverviewPPC />
      </Accordion>
      {/* mobileView */}
      {/* Google ads overall data  */}
      <Card
        id="overall"
        className="hidden sm:block dark:bg-[#222732] px-2 sm:px-6 mt-0 sm:mt-2"
      >
        <CampaignReport />
      </Card>
      <Card
        id="campaign"
        className="hidden sm:block dark:bg-[#222732] px-2 sm:px-6 mt-0 sm:mt-2"
      >
        <CampaignOverviewPPC filteredCampaigns={filteredCampaigns} />
      </Card>
      <Card
        id="adGroup"
        className="hidden sm:block dark:bg-[#222732] px-2 sm:px-6 mt-0 sm:mt-2"
      >
        <AdGroupOverviewPPC />
      </Card>
      <Card
        id="keyword"
        className="hidden sm:block dark:bg-[#222732] px-2 sm:px-6 mt-0 sm:mt-2"
      >
        <KeywordOverviewPPC />
      </Card>
      <Card
        id="topPerformingAd"
        className="hidden sm:block dark:bg-[#222732] px-2 sm:px-6 mt-0 sm:mt-2"
      >
        <TopPerformingAdOverviewPPC />
      </Card>
      <Card
        id="qualityScore"
        className="hidden sm:block dark:bg-[#222732] px-2 sm:px-6 mt-0 sm:mt-2"
      >
        <QualityScoreOverviewPPC />
      </Card>
      <Card
        id="impressionShare"
        className="hidden sm:block dark:bg-[#222732] px-2 sm:px-6 mt-0 sm:mt-2"
      >
        <ImpressionShareOverviewPPC />
      </Card>
      <Card
        id="devices"
        className="hidden sm:block dark:bg-[#222732] px-2 sm:px-6 mt-0 sm:mt-2"
      >
        <DeviceOverviewPPC />
      </Card>
    </div>
  );
};

export default AnalyticsPPC;
