import Icon from '../../assets/icons/SvgComponent';

interface AlertProps {
  icon?: string;
  title?: string;
  message?: string;
  action?: string;
}
const Alert: React.FC<AlertProps> = ({ icon, title, message, action }) => {
  return (
    <div className="flex gap-2">
      <Icon name={!icon ? '' : icon} />
      <div>
        <h1 className="text-xs font-semibold text-[#001129] mb-1">{title}</h1>
        <p className="text-xs font-light">{message}</p>
      </div>
    </div>
  );
};

export default Alert;
