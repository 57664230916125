import { Grid, Col } from '@tremor/react';
import AreaCardChart from '../../AreaCardChart';
import {
  formatToCurrency,
  formatNumber,
  processData,
} from '../../../../utils/common';
import KpiBingMobileView from './kpiBingMobileView';

interface IProps {
  isLeadGen?: boolean;
  isMicrosoftAds?: boolean;
  selectedClient?: any;
  topPerformanceBing?: any;
  requestBingAdsAccess?: any;
}

const KPIBingAds = ({
  isLeadGen,
  isMicrosoftAds,
  selectedClient,
  topPerformanceBing,
  requestBingAdsAccess,
}: IProps) => {

  if(topPerformanceBing?.error?.statusCode === 500) return;

  const bingChartData = topPerformanceBing?.chartsData || {};

  const bingSpend = processData(bingChartData, 'Spend');
  const bingClicks = processData(bingChartData, 'Clicks');
  const bingConversions = processData(bingChartData, 'Conversions');
  const bingRevenue = processData(bingChartData, 'Revenue');
  const bingReturnOnAdSpend = processData(
    bingChartData,
    'ReturnOnAdSpend'
  );
  const bingCostPerConversion = processData(
    bingChartData,
    'CostPerConversion'
  );
  
  const lastMonthBingClicks =
    Array.isArray(bingClicks) && bingClicks.length > 0
      ? bingClicks[bingClicks.length - 1]?.value
      : undefined;
  const lastMonthBingSpend =
    Array.isArray(bingSpend) && bingSpend.length > 0
      ? bingSpend[bingSpend.length - 1]?.value
      : undefined;
  const lastMonthBingConversions =
    Array.isArray(bingConversions) && bingConversions.length > 0
      ? bingConversions[bingConversions.length - 1]?.value
      : undefined;
  const lastMonthBingRevenue =
    Array.isArray(bingRevenue) && bingRevenue.length > 0
      ? bingRevenue[bingRevenue.length - 1]?.value
      : undefined;

  const lastMonthBingReturnOnAdSpend =
    Array.isArray(bingReturnOnAdSpend) && bingReturnOnAdSpend.length > 0
      ? bingReturnOnAdSpend[bingReturnOnAdSpend.length - 1]?.value
      : undefined;
  const lastMonthBingCostPerConversion =
    Array.isArray(bingCostPerConversion) && bingCostPerConversion.length > 0
      ? bingCostPerConversion[bingCostPerConversion.length - 1]?.value
      : undefined;
  return (
    <>
      <KpiBingMobileView
        isLeadGen={isLeadGen}
        isMicrosoftAds={isMicrosoftAds}
        topPerformanceBing={topPerformanceBing}
        requestBingAdsAccess={requestBingAdsAccess}
        bingSpend={bingSpend}
        bingClicks={bingClicks}
        bingConversions={bingConversions}
        bingRevenue={bingRevenue}
        bingReturnOnAdSpend={bingReturnOnAdSpend}
        bingCostPerConversion={bingCostPerConversion}
        lastMonthBingConversions={lastMonthBingConversions}
        lastMonthBingClicks={lastMonthBingClicks}
        lastMonthBingSpend={lastMonthBingSpend}
        lastMonthBingRevenue={lastMonthBingRevenue}
        lastMonthBingReturnOnAdSpend={lastMonthBingReturnOnAdSpend}
        lastMonthBingCostPerConversion={lastMonthBingCostPerConversion}
      />
      <Grid
        numItems={1}
        numItemsSm={1}
        numItemsMd={2}
        numItemsLg={4}
        className="hidden sm:grid gap-4"
      >
        <AreaCardChart
          title={bingChartData?.Spend?.label || 'Spend'}
          chatData={bingSpend}
          status={isMicrosoftAds}
          category={['Spend']}
          totalValue={formatToCurrency(lastMonthBingSpend)}
          error={topPerformanceBing.error}
          prevPeriod={bingChartData?.Spend?.previous_period}
          previousYear={bingChartData?.Spend?.previous_year}
          isLoading={topPerformanceBing.loading}
          requestAccess={requestBingAdsAccess}
          // noAccessSubtitle={googleAdsNoAccessSub}
          info={bingChartData?.Spend?.info || 'Spend'}
          icon="BingAnalyticsTab"
          hasDollar
          showCurrentMonth={true}
        />
        <AreaCardChart
          title={bingChartData?.Clicks?.label || 'Clicks'}
          chatData={bingClicks}
          status={isMicrosoftAds}
          category={['Clicks']}
          totalValue={formatNumber(lastMonthBingClicks)}
          error={topPerformanceBing.error}
          prevPeriod={bingChartData?.Clicks?.previous_period}
          previousYear={bingChartData?.Clicks?.previous_year}
          isLoading={topPerformanceBing.loading}
          requestAccess={requestBingAdsAccess}
          // noAccessSubtitle={googleAdsNoAccessSub}
          info={bingChartData?.Clicks?.info || 'Clicks'}
          icon="BingAnalyticsTab"
          showCurrentMonth={true}
        />
        {isLeadGen ? (
          <>
            <AreaCardChart
              title={bingChartData?.Conversions?.label || 'Conversions'}
              chatData={bingConversions}
              status={isMicrosoftAds}
              category={['Conversions']}
              totalValue={formatNumber(lastMonthBingConversions)}
              error={bingChartData.error}
              prevPeriod={bingChartData?.Conversions?.previous_period}
              previousYear={bingChartData?.Conversions?.previous_year}
              isLoading={topPerformanceBing.loading}
              requestAccess={requestBingAdsAccess}
              // noAccessSubtitle={googleAdsNoAccessSub}
              info={bingChartData?.Conversions?.info || 'Conversions'}
              icon="BingAnalyticsTab"
              showCurrentMonth={true}
            />
            <AreaCardChart
              title={
                bingChartData?.CostPerConversion?.label ||
                'Cost Per Conversion'
              }
              chatData={bingCostPerConversion}
              status={isMicrosoftAds}
              category={['Cost Per Conversion']}
              totalValue={formatNumber(lastMonthBingCostPerConversion)}
              error={topPerformanceBing.error}
              prevPeriod={
                bingChartData?.CostPerConversion?.previous_period
              }
              previousYear={
                bingChartData?.CostPerConversion?.previous_year
              }
              isLoading={topPerformanceBing.loading}
              requestAccess={requestBingAdsAccess}
              // noAccessSubtitle={googleAdsNoAccessSub}
              info={
                bingChartData?.CostPerConversion?.info ||
                'CostPerConversion'
              }
              icon="BingAnalyticsTab"
              showCurrentMonth={true}
            />
          </>
        ) : (
          <>
            <AreaCardChart
              title={bingChartData?.Revenue?.label || 'Revenue'}
              chatData={bingRevenue}
              status={isMicrosoftAds}
              category={['Revenue']}
              totalValue={formatNumber(lastMonthBingRevenue)}
              error={topPerformanceBing.error}
              prevPeriod={bingChartData?.Revenue?.previous_period}
              previousYear={bingChartData?.Revenue?.previous_year}
              isLoading={bingChartData.loading}
              requestAccess={requestBingAdsAccess}
              // noAccessSubtitle={googleAdsNoAccessSub}
              info={bingChartData?.Revenue?.info || 'Revenue'}
              icon="BingAnalyticsTab"
              showCurrentMonth={true}
            />
            <AreaCardChart
              title={
                bingChartData?.ReturnOnAdSpend?.label ||
                'Return On Ad Spend'
              }
              chatData={bingReturnOnAdSpend}
              status={isMicrosoftAds}
              category={[`Return On Ad Spend`]}
              totalValue={formatToCurrency(lastMonthBingReturnOnAdSpend)}
              error={topPerformanceBing.error}
              prevPeriod={bingChartData?.ReturnOnAdSpend?.previous_period}
              previousYear={bingChartData?.ReturnOnAdSpend?.previous_year}
              isLoading={topPerformanceBing.loading}
              requestAccess={requestBingAdsAccess}
              // noAccessSubtitle={googleAdsNoAccessSub}
              info={
                bingChartData?.ReturnOnAdSpend?.info || 'ReturnOnAdSpend'
              }
              icon="BingAnalyticsTab"
              showCurrentMonth={true}
              hasDollar
            />
          </>
        )}
      </Grid>
    </>
  );
};

export default KPIBingAds;
