import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const OutlineLinkedInAds: React.FC<IconProps> = ({
  color = 'black',
  size = 80,
}) => (
  <svg
    width="40"
    height="38"
    viewBox="0 0 40 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_5_8)">
      <path
        d="M31.6541 36.2501H31.2716C31.0166 36.2501 30.8104 36.0439 30.8104 35.7889V22.9874C30.8104 17.9779 28.0619 17.9779 27.1582 17.9779C25.2684 17.9779 23.0185 18.8478 23.0185 22.9874V35.7889C23.0185 36.0439 22.8123 36.2501 22.5573 36.2501H15.3167C15.0617 36.2501 14.8555 36.0439 14.8555 35.7889V11.4009C14.8555 11.1459 15.0617 10.9397 15.3167 10.9397H22.5573C22.8123 10.9397 23.0185 11.1459 23.0185 11.4009V13.122L24.0797 12.2446C25.7221 10.8835 27.6869 10.1973 29.9142 10.1973C32.674 10.1973 34.995 11.0747 36.6187 12.732C38.3398 14.4907 39.2509 17.1229 39.2509 20.3477V35.7926C39.2509 36.0476 39.0447 36.2538 38.7897 36.2538H31.6578L31.6541 36.2501ZM15.7779 35.3314H22.0961V22.9874C22.0961 19.2153 23.941 17.0554 27.1582 17.0554C29.2468 17.0554 31.7328 18.0866 31.7328 22.9874V35.3277H38.3248V20.3439C38.3248 14.4794 35.2575 11.1159 29.9142 11.1159C25.1484 11.1159 23.056 14.7419 22.966 14.8994C22.8835 15.0494 22.7298 15.1431 22.5611 15.1431C22.5273 15.1431 22.4861 15.1356 22.4373 15.1243C22.2386 15.0756 22.0961 14.8919 22.0961 14.6856V11.8621H15.7779V35.3277V35.3314Z"
        fill="black"
      />
    </g>
    <path
      d="M1.46121 37.3067C1.20623 37.3067 1 37.1005 1 36.8455V12.4612C1 12.2062 1.20623 12 1.46121 12H9.01308C9.26806 12 9.47429 12.2062 9.47429 12.4612V36.8455C9.47429 37.1005 9.26806 37.3067 9.01308 37.3067H1.46121ZM1.92242 36.388H8.55187V12.9224H1.92242V36.388Z"
      fill="black"
    />
    <path
      d="M4.92709 9.91419C2.20857 9.91419 0 7.69061 0 4.95708C0 2.22356 2.21232 0 4.92709 0C7.64186 0 9.8504 2.22356 9.8504 4.96083C9.8504 7.6981 7.64186 9.91789 4.92709 9.91789V9.91419ZM4.92709 0.914924C2.71852 0.914924 0.92242 2.72602 0.92242 4.95708C0.92242 7.18815 2.71852 8.9955 4.92709 8.9955C7.13566 8.9955 8.93176 7.1844 8.93176 4.95708C8.93176 2.72977 7.13566 0.914924 4.92709 0.914924Z"
      fill="black"
    />
    <defs>
      <clipPath id="clip0_5_8">
        <rect
          width="26"
          height="27"
          fill="white"
          transform="translate(14 10)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default OutlineLinkedInAds;
