import React from 'react';

interface Props {
  size?: string;
  color?: string;
}

const ArrowDownLeft: React.FC<Props> = ({ size = '16', color = '#7C7E7F' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-arrow-down-left"
  >
    <line x1={17} y1={7} x2={7} y2={17} />
    <polyline points="17 17 7 17 7 7" />
  </svg>
);

export default ArrowDownLeft;
