import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const monthlyReport: React.FC<IconProps> = ({ 
  color = '#405573',
  size = 16,
 }) => (
  <svg
    width={size - 2}
    height={size}
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.75 0.75V2H9.25V0.75C9.25 0.34375 9.5625 0 10 0C10.4062 0 10.75 0.34375 10.75 0.75V2H12C13.0938 2 14 2.90625 14 4V4.5V6V14C14 15.125 13.0938 16 12 16H2C0.875 16 0 15.125 0 14V6V4.5V4C0 2.90625 0.875 2 2 2H3.25V0.75C3.25 0.34375 3.5625 0 4 0C4.40625 0 4.75 0.34375 4.75 0.75ZM1.5 6V7.75H4V6H1.5ZM1.5 9.25V11.25H4V9.25H1.5ZM5.5 9.25V11.25H8.5V9.25H5.5ZM10 9.25V11.25H12.5V9.25H10ZM12.5 7.75V6H10V7.75H12.5ZM12.5 12.75H10V14.5H12C12.25 14.5 12.5 14.2812 12.5 14V12.75ZM8.5 12.75H5.5V14.5H8.5V12.75ZM4 12.75H1.5V14C1.5 14.2812 1.71875 14.5 2 14.5H4V12.75ZM8.5 7.75V6H5.5V7.75H8.5Z"
      fill={color}
    />
  </svg>
);

export default monthlyReport;
