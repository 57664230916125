import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const TrafficConversion: React.FC<IconProps> = ({
  color = 'black',
  size = 16,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.99992 10.667V4.80033C1.99992 4.05359 1.99992 3.68022 2.14524 3.395C2.27307 3.14412 2.47705 2.94015 2.72793 2.81232C3.01315 2.66699 3.38652 2.66699 4.13325 2.66699H11.8666C12.6133 2.66699 12.9867 2.66699 13.2719 2.81232C13.5228 2.94015 13.7268 3.14412 13.8546 3.395C13.9999 3.68022 13.9999 4.05359 13.9999 4.80033V10.667H10.4417C10.2787 10.667 10.1972 10.667 10.1204 10.6854C10.0524 10.7017 9.98738 10.7287 9.92773 10.7652C9.86045 10.8065 9.8028 10.8641 9.6875 10.9794L9.64567 11.0212C9.53037 11.1365 9.47272 11.1942 9.40544 11.2354C9.34579 11.272 9.28076 11.2989 9.21274 11.3152C9.13602 11.3337 9.05448 11.3337 8.89142 11.3337H7.10841C6.94535 11.3337 6.86382 11.3337 6.7871 11.3152C6.71907 11.2989 6.65404 11.272 6.5944 11.2354C6.52712 11.1942 6.46947 11.1365 6.35417 11.0212L6.31234 10.9794C6.19704 10.8641 6.13939 10.8065 6.07211 10.7652C6.01246 10.7287 5.94743 10.7017 5.87941 10.6854C5.80268 10.667 5.72115 10.667 5.55809 10.667H1.99992ZM1.99992 10.667C1.63173 10.667 1.33325 10.9655 1.33325 11.3337V11.5559C1.33325 11.9692 1.33325 12.1759 1.37868 12.3454C1.50197 12.8055 1.86137 13.1649 2.32149 13.2882C2.49105 13.3337 2.69771 13.3337 3.11103 13.3337H12.8888C13.3021 13.3337 13.5088 13.3337 13.6783 13.2882C14.1385 13.1649 14.4979 12.8055 14.6212 12.3454C14.6666 12.1759 14.6666 11.9692 14.6666 11.5559C14.6666 11.3492 14.6666 11.2459 14.6439 11.1611C14.5822 10.9311 14.4025 10.7514 14.1725 10.6897C14.0877 10.667 13.9844 10.667 13.7777 10.667H13.3333"
      stroke={color || '#334969'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TrafficConversion;
