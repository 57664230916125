import { Trash01, Xclose } from '../../../assets/icons';
import Icon from '../../../assets/icons/SvgComponent';

interface ModalProps {
  onClose: (value: boolean) => void;
  onSave?: () => void;
}
const PendingChangesModal: React.FC<ModalProps> = ({ onClose, onSave }) => {
  return (
    <div className="w-screen h-screen flex justify-end items-center pr-[10rem]">
      <div
        // onClick={(e) => e.stopPropagation()}
        className="flex flex-col items-start w-[30%] h-[340px] p-6 spac-y-4 z-100 bg-white dark:bg-[#262627]  dark:shadow-bs-card border-2 border-[#FFFFFF] rounded-md"
      >
        <div className="flex items-start justify-between w-full">
          <div className=" p-3 rounded-full bg-[#FEE4E2] border-8 border-[#FEF3F2] cursor-pointer">
            <Icon name="AlertCircle" />
          </div>
          <div className="cursor-pointer" onClick={() => onClose(false)}>
            <Xclose />
          </div>
        </div>
        <span className="font-inter text-[#101828] text-2xl font-semibold mt-4 dark:text-[#E6EFF5]">
          Unsaved changes
        </span>
        <span className="font-inter w-[90%] text-[#475467] text-lg  mt-1 dark:text-[#E6EFF5]">
          You are about to leave this section without saving. Do you really want
          to leave without saving?
        </span>

        <div className="flex items-center justify-center gap-4 mt-8 w-full">
          <button
            onClick={() => onClose(false)}
            className="btn w-full border border-[#808EA2] bg-[#FFF] text-[#001C44] text-lg py-2.5 px-4 rounded-lg font-semibold"
          >
            Leave
          </button>
          <button
            onClick={() => onSave()}
            className="btn w-full text-white bg-[#005C9F] border border-[#005C9F] dark:bg-[#005C9F] text-lg py-2.5 px-4 rounded-lg font-semibold"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default PendingChangesModal;
