import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const NoData: React.FC<IconProps> = ({ color = 'black', size = 229 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 229 165"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="117" cy="80" r="80" fill="#EAECF0" />
    <circle cx="33" cy="20" r="8" fill="#F2F4F7" />
    <circle cx="205" cy="126" r="6" fill="#F2F4F7" />
    <circle cx="32" cy="138" r="10" fill="#F2F4F7" />
    <circle cx="217" cy="38" r="10" fill="#F2F4F7" />
    <circle cx="198" cy="11" r="7" fill="#F2F4F7" />
    <g filter="url(#filter0_dd_1199_5813)">
      <path
        d="M63.0783 124.214L115.53 99.7552C118.163 98.5272 119.302 95.3969 118.074 92.7634L87.0988 26.3361L66.1234 18.7017L23.2087 38.7132C20.5753 39.9412 19.4359 43.0715 20.6639 45.7049L56.0865 121.669C57.3145 124.302 60.4448 125.442 63.0783 124.214Z"
        fill="url(#paint0_linear_1199_5813)"
      />
      <path
        d="M66.123 18.7017L87.0984 26.3361L72.7935 33.0066L66.123 18.7017Z"
        fill="#808EA2"
      />
    </g>
    <g filter="url(#filter1_dd_1199_5813)">
      <path
        d="M88.1514 106.339H146.025C148.931 106.339 151.286 103.984 151.286 101.078V27.7837L135.502 12H88.1514C85.2457 12 82.8901 14.3555 82.8901 17.2612V101.078C82.8901 103.984 85.2457 106.339 88.1514 106.339Z"
        fill="url(#paint1_linear_1199_5813)"
      />
      <path d="M135.502 12L151.286 27.7837H135.502V12Z" fill="#808EA2" />
    </g>
    <g filter="url(#filter2_dd_1199_5813)">
      <path
        d="M117.911 99.724L170.362 124.182C172.996 125.41 176.126 124.271 177.354 121.638L208.33 55.2103L200.695 34.2349L157.781 14.2235C155.147 12.9955 152.017 14.1348 150.789 16.7683L115.366 92.7322C114.138 95.3657 115.278 98.496 117.911 99.724Z"
        fill="url(#paint2_linear_1199_5813)"
      />
      <path
        d="M200.695 34.2349L208.33 55.2102L194.025 48.5398L200.695 34.2349Z"
        fill="#808EA2"
      />
    </g>
    <g filter="url(#filter3_b_1199_5813)">
      <rect
        x="107.201"
        y="71.7072"
        width="56"
        height="56"
        rx="12"
        transform="rotate(15 107.201 71.7072)"
        fill="#005C9F"
      />
      <g filter="url(#filter4_b_1199_5813)">
        <rect
          x="89"
          y="88"
          width="56"
          height="56"
          rx="12"
          fill="white"
          fillOpacity="0.6"
        />
        <path
          d="M126.333 114.25V109.933C126.333 107.973 126.333 106.993 125.952 106.244C125.616 105.586 125.081 105.05 124.422 104.715C123.673 104.333 122.693 104.333 120.733 104.333H113.267C111.306 104.333 110.326 104.333 109.578 104.715C108.919 105.05 108.384 105.586 108.048 106.244C107.667 106.993 107.667 107.973 107.667 109.933V122.067C107.667 124.027 107.667 125.007 108.048 125.756C108.384 126.414 108.919 126.95 109.578 127.285C110.326 127.667 111.306 127.667 113.267 127.667H117M119.333 114.833H112.333M114.667 119.5H112.333M121.667 110.167H112.333M124 126.5V119.5M120.5 123H127.5"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="89.5"
          y="88.5"
          width="55"
          height="55"
          rx="11.5"
          stroke="white"
          strokeOpacity="0.6"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_dd_1199_5813"
        x="-1.55957"
        y="12.0312"
        width="141.857"
        height="154.406"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="4"
          operator="erode"
          in="SourceAlpha"
          result="effect1_dropShadow_1199_5813"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1199_5813"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="4"
          operator="erode"
          in="SourceAlpha"
          result="effect2_dropShadow_1199_5813"
        />
        <feOffset dy="20" />
        <feGaussianBlur stdDeviation="12" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_1199_5813"
          result="effect2_dropShadow_1199_5813"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_1199_5813"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_dd_1199_5813"
        x="62.8901"
        y="12"
        width="108.396"
        height="134.339"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="4"
          operator="erode"
          in="SourceAlpha"
          result="effect1_dropShadow_1199_5813"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1199_5813"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="4"
          operator="erode"
          in="SourceAlpha"
          result="effect2_dropShadow_1199_5813"
        />
        <feOffset dy="20" />
        <feGaussianBlur stdDeviation="12" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_1199_5813"
          result="effect2_dropShadow_1199_5813"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_1199_5813"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_dd_1199_5813"
        x="93.1426"
        y="12"
        width="141.857"
        height="154.406"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="4"
          operator="erode"
          in="SourceAlpha"
          result="effect1_dropShadow_1199_5813"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1199_5813"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="4"
          operator="erode"
          in="SourceAlpha"
          result="effect2_dropShadow_1199_5813"
        />
        <feOffset dy="20" />
        <feGaussianBlur stdDeviation="12" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_1199_5813"
          result="effect2_dropShadow_1199_5813"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_1199_5813"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_b_1199_5813"
        x="81"
        y="63.7072"
        width="88.293"
        height="88.2928"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_1199_5813"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1199_5813"
          result="shape"
        />
      </filter>
      <filter
        id="filter4_b_1199_5813"
        x="73"
        y="72"
        width="88"
        height="88"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="8" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_1199_5813"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1199_5813"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1199_5813"
        x1="59.9968"
        y1="124.418"
        x2="18.1972"
        y2="49.0089"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E4E7EC" />
        <stop offset="1" stopColor="#F9FAFB" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1199_5813"
        x1="85.272"
        y1="105.223"
        x2="79.2583"
        y2="19.2131"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E4E7EC" />
        <stop offset="1" stopColor="#F9FAFB" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1199_5813"
        x1="115.773"
        y1="97.4951"
        x2="146.672"
        y2="17.0024"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E4E7EC" />
        <stop offset="1" stopColor="#F9FAFB" />
      </linearGradient>
    </defs>
  </svg>
);

export default NoData;
