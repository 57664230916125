import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const ChevronLeftIcon: React.FC<IconProps> = ({
  color = 'black',
  size = 80,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="chevron-left">
      <path
        id="Icon"
        d="M10 12L6 8L10 4"
        stroke={color || '#808EA2'}
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default ChevronLeftIcon;
