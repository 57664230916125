import { useMemo } from 'react';
import { formatToCurrency, formatNumber } from '../../../../utils/common';
import useClientStore from '../../../../store/useClientStore';
import { Card } from '@tremor/react';
import Icon from '../../../../assets/icons/SvgComponent';
import useBingStore from '../../../../store/useBingStore';
import AnalyticsTable from '../../AnalyticsTable';
import Tooltip from '../../../HelpTooltip';
import { useRetryFetchBingCampaign } from '../../Hooks';

interface IProps {
  topAdsResponse: any;
}

const BingTopPerformingAd = ({ topAdsResponse }: IProps) => {
  const { selectedClient } = useClientStore((state) => state);
  const { campaignsReport } = useBingStore((state) => state);

  const topAdsReportData = useMemo(() => {
    const tableHeader = [
      {
        id: 'Ad Preview',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'AD Preview' && sortDirection === false) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[200px] sm:w-[20vw] h-fit pl-1 sm:pl-4">
                <div>AD Preview</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'AD Preview' && sortDirection === true) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[200px] sm:w-[20vw] h-fit pl-1 sm:pl-4">
                <div>AD Preview</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'AD Preview' || headerId === 'AD Preview') {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[200px] sm:w-[20vw] h-fit pl-1 sm:pl-4">
                <div>AD Preview</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'adPreview',
        cell: (props: any) => (
          <Card className="flex flex-col w-[200px] sm:w-[20vw] ml-1 sm:ml-4 p-3 h-fit">
            <div className="border-b my-1 pb-2">
              <span className="my-1 text-[#0029FF] truncate">
                {props.getValue().name}
              </span>
            </div>

            <div className="my-1 text-xs">{props.getValue().id}</div>
          </Card>
        ),
      },
      {
        id: 'Campaign',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Campaign' && sortDirection === false) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[180px] sm:w-[12vw] h-fit pl-4 ">
                <div>Campaign</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Campaign' && sortDirection === true) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[180px] sm:w-[12vw] h-fit pl-4 ">
                <div>Campaign</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Campaign' || headerId === 'Campaign') {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[180px] sm:w-[12vw] h-fit pl-4 ">
                <div>Campaign</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'campaign_name',
        cell: (props: any) => (
          <div className="flex justify-start font-inter w-[180px] sm:w-[12vw] pl-4 h-fit">
            <Tooltip content={props.getValue()} position="right">
              <div className="truncate w-[150px] sm:max-w-[12vw] overflow-hidden">
                {props.getValue()}
              </div>
            </Tooltip>
          </div>
        ),
      },
      {
        id: 'Ad Group',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Ad Group' && sortDirection === false) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[180px] sm:w-[10vw] h-fit">
                <div>Ad Group</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Ad Group' && sortDirection === true) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[180px] sm:w-[10vw] h-fit">
                <div>Ad Group</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Ad Group' || headerId === 'Ad Group') {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[180px] sm:w-[10vw] h-fit">
                <div>Ad Group</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'name',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[180px] sm:w-[10vw]  h-fit">
            <Tooltip content={props.getValue()} position="right">
              <div className="truncate w-[180px] sm:max-w-[10vw] overflow-hidden">
                {props.getValue()}
              </div>
            </Tooltip>
          </div>
        ),
      },
      {
        id: 'Cost',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Cost' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[8vw] h-fit">
                <div>Cost</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Cost' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[8vw] h-fit">
                <div>Cost</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Cost' || headerId === 'Cost') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[8vw] h-fit">
                <div>Cost</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'cost',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[180px] sm:w-[8vw] h-fit">
            {formatToCurrency(props.getValue())}
          </div>
        ),
      },
      {
        id: 'Impression',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Impression' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[8vw] h-fit">
                <div>Impression</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Impression' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[8vw] h-fit">
                <div>Impression</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Impression' || headerId === 'Impression') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[8vw] h-fit">
                <div>Impression</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'impressions',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[180px] sm:w-[8vw] h-fit">
            {formatNumber(props.getValue())}
          </div>
        ),
      },
      {
        id: 'Clicks',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Clicks' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>Clicks</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Clicks' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>Clicks</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Clicks' || headerId === 'Clicks') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>Clicks</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'clicks',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[5vw] h-fit">
            {formatNumber(props.getValue())}
          </div>
        ),
      },
      {
        id: 'CPC',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'CPC' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>CPC</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'CPC' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>CPC</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'CPC' || headerId === 'CPC') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>CPC</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'cpc',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[5vw] h-fit">
            ${formatNumber(props.getValue())}
          </div>
        ),
      },
      {
        id: 'Conversions',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Conversions' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[8vw] h-fit">
                <div>Conversions</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Conversions' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[8vw] h-fit">
                <div>Conversions</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Conversions' || headerId === 'Conversions') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[8vw] h-fit">
                <div>Conversions</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'conversions',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[180px] sm:w-[8vw] h-fit">
            {formatNumber(props.getValue())}
          </div>
        ),
      },
      {
        id: 'Conversions Rate',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Conversions Rate' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[10vw] h-fit">
                <div>Conversions Rate</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Conversions Rate' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[10vw] h-fit">
                <div>Conversions</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (
            headerId !== 'Conversions Rate' ||
            headerId === 'Conversions Rate'
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[10vw] h-fit">
                <div>Conversions Rate</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'conversion_rate',
        cell: (props: any) => (
          <div className="flex justify-center items-center font-inter w-[180px] sm:w-[10vw] h-fit">
            <span className="text-center">
              {formatNumber(props.getValue())} %
            </span>
          </div>
        ),
      },
      {
        id: 'CTR',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'CTR' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>CTR</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'CTR' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>CTR</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'CTR' || headerId === 'CTR') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>CTR</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'ctr',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[5vw] h-fit">
            {formatNumber(props.getValue())} %
          </div>
        ),
      },
      {
        id: 'ROAS',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'ROAS' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[8vw] h-fit">
                <div>ROAS</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'ROAS' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[8vw] h-fit">
                <div>ROAS</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'ROAS' || headerId === 'ROAS') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[8vw] h-fit">
                <div>ROAS</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'roas',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[8vw] h-fit">
            {formatNumber(props.getValue())}
          </div>
        ),
      },
    ];

    let loading = true;
    const data = !topAdsResponse?.error && (topAdsResponse || []).map((resource: any) => {
      return {
        adPreview: {
          name: resource?.AdType,
          id: resource?.AdId,
        },
        name: resource?.AdGroupName,
        clicks: Number(resource?.Clicks),
        cost: Number(resource?.Spend),
        conversions: Number(resource?.Conversions) ?? 0,
        conversion_rate: Number(resource?.CostPerConversion) ?? 0,
        impressions: Number(resource?.Impressions),
        campaign_name: resource?.CampaignName,
        resource: resource,
        cpc: Number(resource?.CostPerConversion) ?? 0,
        roas: Number(resource?.ReturnOnAdSpend) ?? 0,
        ctr: Number(resource?.Ctr) ?? 0,
      };
    });
    if (topAdsResponse) {
      loading = false;
    }
    return { tableHeader, data, loading };
  }, [topAdsResponse]);

  const retryFetchBingCampaign = useRetryFetchBingCampaign();

  return (
    <>
      <div className="hidden sm:flex justify-between items-center">
        <h2 className="title-text text-base sm:text-[1.85rem] pb-1">
          Top Performing Ads
        </h2>
        <div className="sm:pr-4">
          <Tooltip
            content={
              selectedClient?.google_ads?.customer_id
                ? `Customer ID: ${selectedClient?.google_ads?.customer_id}`
                : 'N/A'
            }
            position="top"
            textAlign="text-center"
          >
            <div className="pt-1">
              <Icon name="BingAnalyticsTab" size={20} />
            </div>
          </Tooltip>
        </div>
      </div>

      <div className="mt-8">
        {(topAdsReportData?.data?.length === 0 && !campaignsReport.loading) || topAdsResponse?.error ? (
          <Card className="card-item px-1 sm:px-6">
            <div className="flex flex-col items-center justify-center w-full  my-8 h-[148px]">
              <div className="mb-8">
                <Icon name="NoDataAdGroup" />
              </div>
              <p className="font-inter font-[600] text-[20px] text-[#001C44] dark:text-white">
                {typeof topAdsResponse?.error === 'string' 
                  ? topAdsResponse?.error
                  : "No top performing ads to showcase currently."
                }
              </p>
              <p className="text-center w-[60vw] text-sm mt-4 text-[#001C44] dark:text-white">
                Keep engaged with the forefront of advertising effectiveness.
                Our curated section offers insights and updates on high-impact
                strategies and trends, essential for driving your ad
                performance.
              </p>

              <div
                className="mt-4 py-2 px-5 font-bold text-sm cursor-pointer underline dark:text-white"
                onClick={retryFetchBingCampaign}
              >
                Retry
              </div>
              
            </div>
          </Card>
        ) : (
          <AnalyticsTable
            data={topAdsReportData?.data}
            columns={topAdsReportData?.tableHeader}
            loading={campaignsReport.loading}
            totalDocs={
              topAdsReportData?.data ? topAdsReportData?.data.length : 'N/A'
            }
            sortByColumnId="Cost"
            error={topAdsResponse?.error}
            pagination={true}
          />
        )}
      </div>
    </>
  );
};

export default BingTopPerformingAd;
