import React from 'react';

interface Props {
  color?: string;
  size?: number;
}

const GridView: React.FC<Props> = ({ color = '#001C44', size = 14 }) => (
  <svg
    width={size - 1}
    height={size}
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.1875 1.5C1.94141 1.5 1.75 1.71875 1.75 1.9375V4.5625C1.75 4.80859 1.94141 5 2.1875 5H4.8125C5.03125 5 5.25 4.80859 5.25 4.5625V1.9375C5.25 1.71875 5.03125 1.5 4.8125 1.5H2.1875ZM0.875 1.9375C0.875 1.22656 1.44922 0.625 2.1875 0.625H4.8125C5.52344 0.625 6.125 1.22656 6.125 1.9375V4.5625C6.125 5.30078 5.52344 5.875 4.8125 5.875H2.1875C1.44922 5.875 0.875 5.30078 0.875 4.5625V1.9375ZM2.1875 8.5C1.94141 8.5 1.75 8.71875 1.75 8.9375V11.5625C1.75 11.8086 1.94141 12 2.1875 12H4.8125C5.03125 12 5.25 11.8086 5.25 11.5625V8.9375C5.25 8.71875 5.03125 8.5 4.8125 8.5H2.1875ZM0.875 8.9375C0.875 8.22656 1.44922 7.625 2.1875 7.625H4.8125C5.52344 7.625 6.125 8.22656 6.125 8.9375V11.5625C6.125 12.3008 5.52344 12.875 4.8125 12.875H2.1875C1.44922 12.875 0.875 12.3008 0.875 11.5625V8.9375ZM11.8125 1.5H9.1875C8.94141 1.5 8.75 1.71875 8.75 1.9375V4.5625C8.75 4.80859 8.94141 5 9.1875 5H11.8125C12.0312 5 12.25 4.80859 12.25 4.5625V1.9375C12.25 1.71875 12.0312 1.5 11.8125 1.5ZM9.1875 0.625H11.8125C12.5234 0.625 13.125 1.22656 13.125 1.9375V4.5625C13.125 5.30078 12.5234 5.875 11.8125 5.875H9.1875C8.44922 5.875 7.875 5.30078 7.875 4.5625V1.9375C7.875 1.22656 8.44922 0.625 9.1875 0.625ZM9.1875 8.5C8.94141 8.5 8.75 8.71875 8.75 8.9375V11.5625C8.75 11.8086 8.94141 12 9.1875 12H11.8125C12.0312 12 12.25 11.8086 12.25 11.5625V8.9375C12.25 8.71875 12.0312 8.5 11.8125 8.5H9.1875ZM7.875 8.9375C7.875 8.22656 8.44922 7.625 9.1875 7.625H11.8125C12.5234 7.625 13.125 8.22656 13.125 8.9375V11.5625C13.125 12.3008 12.5234 12.875 11.8125 12.875H9.1875C8.44922 12.875 7.875 12.3008 7.875 11.5625V8.9375Z"
      fill={color}
    />
  </svg>
);

export default GridView;
