import { Card } from '@tremor/react';

interface IProps {
  title: string;
  message?: string;
}

const ErrorTxt = ({
  title,
  message = 'Something went wrong... Please check the integration and refresh the page or contact administrator',
}: IProps) => {
  return (
    <Card className="card-item">
      <div className="flex flex-col justify-start w-full items-start mb-8">
        <h2 className="title-text">{title}</h2>
        <p className="text-[white] bg-[#CC002F] text-center font-inter font-[600] mt-4 text-sm border border-[#f5c6cb] p-4 rounded-md w-full text-md">
          {message}
        </p>
      </div>
    </Card>
  );
};

export default ErrorTxt;
