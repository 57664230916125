import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const Linkedin01: React.FC<IconProps> = ({ color = 'black', size = 20 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1937_36987)">
      <path
        d="M18.5195 0.319336H1.47656C0.660156 0.319336 0 0.963867 0 1.76074V18.874C0 19.6709 0.660156 20.3193 1.47656 20.3193H18.5195C19.3359 20.3193 20 19.6709 20 18.8779V1.76074C20 0.963867 19.3359 0.319336 18.5195 0.319336ZM5.93359 17.3623H2.96484V7.81543H5.93359V17.3623ZM4.44922 6.51465C3.49609 6.51465 2.72656 5.74512 2.72656 4.7959C2.72656 3.84668 3.49609 3.07715 4.44922 3.07715C5.39844 3.07715 6.16797 3.84668 6.16797 4.7959C6.16797 5.74121 5.39844 6.51465 4.44922 6.51465ZM17.043 17.3623H14.0781V12.7217C14.0781 11.6162 14.0586 10.1904 12.5352 10.1904C10.9922 10.1904 10.7578 11.3975 10.7578 12.6436V17.3623H7.79688V7.81543H10.6406V9.12012H10.6797C11.0742 8.37012 12.043 7.57715 13.4844 7.57715C16.4883 7.57715 17.043 9.55371 17.043 12.124V17.3623Z"
        fill="#ADB0B2"
      />
    </g>
    <defs>
      <clipPath id="clip0_1937_36987">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0 0.319336)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Linkedin01;
