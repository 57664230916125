import React from 'react';

interface LinkedinProps {
  color?: string;
}

const Linkedin: React.FC<LinkedinProps> = ({ color = '#428B6E' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
  >
    <path
      d="M12.6186 10.1921C12.6186 11.1267 11.8085 11.8843 10.8093 11.8843C9.81004 11.8843 9 11.1267 9 10.1921C9 9.2576 9.81004 8.5 10.8093 8.5C11.8085 8.5 12.6186 9.2576 12.6186 10.1921Z"
      fill="white"
    />
    <path d="M9.24742 13.1281H12.3402V22.5H9.24742V13.1281Z" fill="white" />
    <path
      d="M17.3196 13.1281H14.2268V22.5H17.3196C17.3196 22.5 17.3196 19.5496 17.3196 17.7049C17.3196 16.5976 17.6977 15.4855 19.2062 15.4855C20.911 15.4855 20.9008 16.9345 20.8928 18.0571C20.8824 19.5244 20.9072 21.0219 20.9072 22.5H24V17.5537C23.9738 14.3954 23.1508 12.9401 20.4433 12.9401C18.8354 12.9401 17.8387 13.6701 17.3196 14.3305V13.1281Z"
      fill="white"
    />
  </svg>
);

export default Linkedin;
