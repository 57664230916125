import React from 'react';
import ComingSoon from '../../components/ComingSoon';

const Referrals: React.FC = () => {
  // useEffect(() => {
  //   if (isAdminLogin) {
  //     navigate("/");
  //   }
  //   const item = localStorage.getItem("domain");
  //   const selectedDomain = item ? JSON.parse(item) : null;
  //   const clientIds = currentUser?.clients?.map((item: any) => item.id);
  //   if (clientIds?.includes(selectedDomain?.domain?.id)) {
  //     setDomain(selectedDomain.domain);
  //     fetchClient(selectedDomain?.domain?.id);
  //   } else {
  //     fetchClient(domain?.id);
  //   }
  //   setIsMounted(true);
  // }, []);

  // useEffect(() => {
  //   if (isMounted) {
  //     fetchClient(domain?.id);
  //   }
  // }, [domain]);

  return (
    <div className="flex flex-col min-h-[93vh] bg-[#D2EEFF] dark:bg-[#0C111D]">
      {/* <PageHeader
      subtitle="Goals"
      domains={currentUser.clients}
      hideDate
    /> */}
      <ComingSoon />
    </div>
  );
};

export default Referrals;
