import React from 'react';
import { Badge, Flex } from '@tremor/react';
import Icon from '../../assets/icons/SvgComponent';
import useReportStore from '../../store/useReportStore';
import { useLocation, useNavigate } from 'react-router-dom';
import Tooltip from '../HelpTooltip';
import { CgOpenCollective } from 'react-icons/cg';

interface ChangeCounterProp {
  clickable?: boolean;
  loading?: boolean;
  stats?: any;
  dataTitle?: any;
  initState?: boolean;
  isReportPage?: boolean;
}

const ChangeCounter: React.FC<ChangeCounterProp> = ({
  clickable,
  loading,
  stats,
  dataTitle,
  initState,
  isReportPage,
}) => {
  const formatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
  });
  /////////////////////////////////////////
  let changeObject = {
    seo_google_count: 0,
    seo_google_budget: 0,
    ppc_google_count: 0,
    ppc_google_budget: 0,
    seo_bing_count: 0,
    seo_bing_budget: 0,
    ppc_bing_count: 0,
    ppc_bing_budget: 0,
    display_count: 0,
    display_budget: 0,
    social_meta_count: 0,
    social_meta_budget: 0,
    social_tiktok_count: 0,
    social_tiktok_budget: 0,
    social_pinterest_count: 0,
    social_pinterest_budget: 0,
    social_linkedin_count: 0,
    social_linkedin_budget: 0,
    social_creative_count: 0,
    social_creative_budget: 0,
    social_programmatic_count: 0,
    social_programmatic_budget: 0,
    social_tactical_count: 0,
    social_tactical_budget: 0,
    web_maintenance_count: 0,
    web_maintenance_budget: 0,
    web_hosting_count: 0,
    web_hosting_budget: 0,
    all_counts: 0,
    all_budgets: 0,
  };

  let pauseObject = {
    seo_google_count: 0,
    seo_google_budget: 0,
    ppc_google_count: 0,
    ppc_google_budget: 0,
    seo_bing_count: 0,
    seo_bing_budget: 0,
    ppc_bing_count: 0,
    ppc_bing_budget: 0,
    display_count: 0,
    display_budget: 0,
    social_meta_count: 0,
    social_meta_budget: 0,
    social_tiktok_count: 0,
    social_tiktok_budget: 0,
    social_pinterest_count: 0,
    social_pinterest_budget: 0,
    social_linkedin_count: 0,
    social_linkedin_budget: 0,
    social_creative_count: 0,
    social_creative_budget: 0,
    social_programmatic_count: 0,
    social_programmatic_budget: 0,
    social_tactical_count: 0,
    social_tactical_budget: 0,
    web_maintenance_count: 0,
    web_maintenance_budget: 0,
    web_hosting_count: 0,
    web_hosting_budget: 0,
    all_counts: 0,
    all_budgets: 0,
  };

  let financeChangeObject = {
    seo_google_count: 0,
    seo_google_budget: 0,
    ppc_google_count: 0,
    ppc_google_budget: 0,
    seo_bing_count: 0,
    seo_bing_budget: 0,
    ppc_bing_count: 0,
    ppc_bing_budget: 0,
    display_count: 0,
    display_budget: 0,
    social_meta_count: 0,
    social_meta_budget: 0,
    social_tiktok_count: 0,
    social_tiktok_budget: 0,
    social_pinterest_count: 0,
    social_pinterest_budget: 0,
    social_linkedin_count: 0,
    social_linkedin_budget: 0,
    social_creative_count: 0,
    social_creative_budget: 0,
    social_programmatic_count: 0,
    social_programmatic_budget: 0,
    social_tactical_count: 0,
    social_tactical_budget: 0,
    web_maintenance_count: 0,
    web_maintenance_budget: 0,
    web_hosting_count: 0,
    web_hosting_budget: 0,
    all_counts: 0,
    all_budgets: 0,
  };

  let financeCpauseObject = {
    seo_google_count: 0,
    seo_google_budget: 0,
    ppc_google_count: 0,
    ppc_google_budget: 0,
    seo_bing_count: 0,
    seo_bing_budget: 0,
    ppc_bing_count: 0,
    ppc_bing_budget: 0,
    display_count: 0,
    display_budget: 0,
    social_meta_count: 0,
    social_meta_budget: 0,
    social_tiktok_count: 0,
    social_tiktok_budget: 0,
    social_pinterest_count: 0,
    social_pinterest_budget: 0,
    social_linkedin_count: 0,
    social_linkedin_budget: 0,
    social_creative_count: 0,
    social_creative_budget: 0,
    social_programmatic_count: 0,
    social_programmatic_budget: 0,
    social_tactical_count: 0,
    social_tactical_budget: 0,
    web_maintenance_count: 0,
    web_maintenance_budget: 0,
    web_hosting_count: 0,
    web_hosting_budget: 0,
    all_counts: 0,
    all_budgets: 0,
  };

  if (Object.keys(stats).length > 0) {
    if (isReportPage) {
      stats.apply.map((ch) => {
        switch (ch.serviceLine) {
          case 'seo_google':
            changeObject.seo_google_count++;
            changeObject.seo_google_budget += isNaN(
              parseInt(ch.changeDetails.applyCreditAmount)
            )
              ? 0
              : parseInt(ch.changeDetails.applyCreditAmount);
            break;
          case 'ppc_google':
            changeObject.ppc_google_count++;
            changeObject.ppc_google_budget += isNaN(
              parseInt(ch.changeDetails.applyCreditAmount)
            )
              ? 0
              : parseInt(ch.changeDetails.applyCreditAmount);
            break;
            case 'seo_bing':
              changeObject.seo_bing_count++;
              changeObject.seo_bing_budget += isNaN(
                parseInt(ch.changeDetails.applyCreditAmount)
              )
                ? 0
                : parseInt(ch.changeDetails.applyCreditAmount);
              break;
            case 'social_bing':
              changeObject.ppc_bing_count++;
              changeObject.ppc_bing_budget += isNaN(
                parseInt(ch.changeDetails.applyCreditAmount)
              )
                ? 0
                : parseInt(ch.changeDetails.applyCreditAmount);
              break;
          case 'display':
            changeObject.display_count++;
            changeObject.display_budget += isNaN(
              parseInt(ch.changeDetails.applyCreditAmount)
            )
              ? 0
              : parseInt(ch.changeDetails.applyCreditAmount);
            break;
          case 'social_meta':
            changeObject.social_meta_count++;
            changeObject.social_meta_budget += isNaN(
              parseInt(ch.changeDetails.applyCreditAmount)
            )
              ? 0
              : parseInt(ch.changeDetails.applyCreditAmount);
            break;
          case 'social_tiktok':
            changeObject.social_tiktok_count++;
            changeObject.social_tiktok_budget += isNaN(
              parseInt(ch.changeDetails.applyCreditAmount)
            )
              ? 0
              : parseInt(ch.changeDetails.applyCreditAmount);
            break;
          case 'social_pinterest':
            changeObject.social_pinterest_count++;
            changeObject.social_pinterest_budget += isNaN(
              parseInt(ch.changeDetails.applyCreditAmount)
            )
              ? 0
              : parseInt(ch.changeDetails.applyCreditAmount);
            break;
          case 'social_linkedin':
            changeObject.social_linkedin_count++;
            changeObject.social_linkedin_budget += isNaN(
              parseInt(ch.changeDetails.applyCreditAmount)
            )
              ? 0
              : parseInt(ch.changeDetails.applyCreditAmount);
            break;
        case 'social_creative':
          changeObject.social_creative_count++;
          changeObject.social_creative_budget += isNaN(
            parseInt(ch.changeDetails.applyCreditAmount)
          )
            ? 0
            : parseInt(ch.changeDetails.applyCreditAmount);
          break;
        case 'social_programmatic':
          changeObject.social_programmatic_count++;
          changeObject.social_programmatic_budget += isNaN(
            parseInt(ch.changeDetails.applyCreditAmount)
          )
            ? 0
            : parseInt(ch.changeDetails.applyCreditAmount);
          break;
        case 'social_tactical':
          changeObject.social_tactical_count++;
          changeObject.social_tactical_budget += isNaN(
            parseInt(ch.changeDetails.applyCreditAmount)
          )
            ? 0
            : parseInt(ch.changeDetails.applyCreditAmount);
          break;  
          case 'web_maintenance':
            changeObject.web_maintenance_count++;
            changeObject.web_maintenance_budget += isNaN(
              parseInt(ch.changeDetails.applyCreditAmount)
            )
              ? 0
              : parseInt(ch.changeDetails.applyCreditAmount);
            break;
          case 'web_hosting':
            changeObject.web_hosting_count++;
            changeObject.web_hosting_budget += isNaN(
              parseInt(ch.changeDetails.applyCreditAmount)
            )
              ? 0
              : parseInt(ch.changeDetails.applyCreditAmount);
            break;
        }
        changeObject.all_counts++;
      });
      stats.nonstart.map((ch) => {
        switch (ch.serviceLine) {
          case 'seo_google':
            changeObject.seo_google_count++;
            changeObject.seo_google_budget += isNaN(
              parseInt(ch.changeDetails.nonStartBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.nonStartBudget);
            break;
          case 'ppc_google':
            changeObject.ppc_google_count++;
            changeObject.ppc_google_budget += isNaN(
              parseInt(ch.changeDetails.nonStartBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.nonStartBudget);
            break;
            case 'seo_bing':
              changeObject.seo_bing_count++;
              changeObject.seo_bing_budget += isNaN(
                parseInt(ch.changeDetails.nonStartBudget)
              )
                ? 0
                : parseInt(ch.changeDetails.nonStartBudget);
              break;
            case 'social_bing':
              changeObject.ppc_bing_count++;
              changeObject.ppc_bing_budget += isNaN(
                parseInt(ch.changeDetails.nonStartBudget)
              )
                ? 0
                : parseInt(ch.changeDetails.nonStartBudget);
              break;
          case 'display':
            changeObject.display_count++;
            changeObject.display_budget += isNaN(
              parseInt(ch.changeDetails.nonStartBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.nonStartBudget);
            break;
          case 'social_meta':
            changeObject.social_meta_count++;
            changeObject.social_meta_budget += isNaN(
              parseInt(ch.changeDetails.nonStartBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.nonStartBudget);
            break;
          case 'social_tiktok':
            changeObject.social_tiktok_count++;
            changeObject.social_tiktok_budget += isNaN(
              parseInt(ch.changeDetails.nonStartBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.nonStartBudget);
            break;
          case 'social_pinterest':
            changeObject.social_pinterest_count++;
            changeObject.social_pinterest_budget += isNaN(
              parseInt(ch.changeDetails.nonStartBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.nonStartBudget);
            break;
          case 'social_linkedin':
            changeObject.social_linkedin_count++;
            changeObject.social_linkedin_budget += isNaN(
              parseInt(ch.changeDetails.nonStartBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.nonStartBudget);
            break;
          case 'social_creative':
            changeObject.social_creative_count++;
            changeObject.social_creative_budget += isNaN(
              parseInt(ch.changeDetails.nonStartBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.nonStartBudget);
            break;
          case 'social_programmatic':
            changeObject.social_programmatic_count++;
            changeObject.social_programmatic_budget += isNaN(
              parseInt(ch.changeDetails.nonStartBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.nonStartBudget);
            break;
          case 'social_tactical':
            changeObject.social_tactical_count++;
            changeObject.social_tactical_budget += isNaN(
              parseInt(ch.changeDetails.nonStartBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.nonStartBudget);
            break;  
          case 'web_maintenance':
            changeObject.web_maintenance_count++;
            changeObject.web_maintenance_budget += isNaN(
              parseInt(ch.changeDetails.nonStartBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.nonStartBudget);
            break;
          case 'web_hosting':
            changeObject.web_hosting_count++;
            changeObject.web_hosting_budget += isNaN(
              parseInt(ch.changeDetails.nonStartBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.nonStartBudget);
            break;
        }
        changeObject.all_counts++;
      });
      stats.stop.map((ch) => {
        switch (ch.serviceLine) {
          case 'seo_google':
            changeObject.seo_google_count++;
            changeObject.seo_google_budget += isNaN(
              parseInt(ch.changeDetails.stopWorkBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.stopWorkBudget);
            break;
          case 'ppc_google':
            changeObject.ppc_google_count++;
            changeObject.ppc_google_budget += isNaN(
              parseInt(ch.changeDetails.stopWorkBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.stopWorkBudget);
            break;
            case 'seo_bing':
              changeObject.seo_bing_count++;
              changeObject.seo_bing_budget += isNaN(
                parseInt(ch.changeDetails.stopWorkBudget)
              )
                ? 0
                : parseInt(ch.changeDetails.stopWorkBudget);
              break;
            case 'social_bing':
              changeObject.ppc_bing_count++;
              changeObject.ppc_bing_budget += isNaN(
                parseInt(ch.changeDetails.stopWorkBudget)
              )
                ? 0
                : parseInt(ch.changeDetails.stopWorkBudget);
              break;
          case 'display':
            changeObject.display_count++;
            changeObject.display_budget += isNaN(
              parseInt(ch.changeDetails.stopWorkBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.stopWorkBudget);
            break;
          case 'social_meta':
            changeObject.social_meta_count++;
            changeObject.social_meta_budget += isNaN(
              parseInt(ch.changeDetails.stopWorkBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.stopWorkBudget);
            break;
          case 'social_tiktok':
            changeObject.social_tiktok_count++;
            changeObject.social_tiktok_budget += isNaN(
              parseInt(ch.changeDetails.stopWorkBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.stopWorkBudget);
            break;
          case 'social_pinterest':
            changeObject.social_pinterest_count++;
            changeObject.social_pinterest_budget += isNaN(
              parseInt(ch.changeDetails.stopWorkBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.stopWorkBudget);
            break;
          case 'social_linkedin':
            changeObject.social_linkedin_count++;
            changeObject.social_linkedin_budget += isNaN(
              parseInt(ch.changeDetails.stopWorkBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.stopWorkBudget);
            break;
          case 'social_creative':
            changeObject.social_creative_count++;
            changeObject.social_creative_budget += isNaN(
              parseInt(ch.changeDetails.stopWorkBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.stopWorkBudget);
            break;
          case 'social_programmatic':
            changeObject.social_programmatic_count++;
            changeObject.social_programmatic_budget += isNaN(
              parseInt(ch.changeDetails.stopWorkBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.stopWorkBudget);
            break;
          case 'social_tactical':
            changeObject.social_tactical_count++;
            changeObject.social_tactical_budget += isNaN(
              parseInt(ch.changeDetails.stopWorkBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.stopWorkBudget);
            break; 
          case 'web_maintenance':
            changeObject.web_maintenance_count++;
            changeObject.web_maintenance_budget += isNaN(
              parseInt(ch.changeDetails.stopWorkBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.stopWorkBudget);
            break;
          case 'web_hosting':
            changeObject.web_hosting_count++;
            changeObject.web_hosting_budget += isNaN(
              parseInt(ch.changeDetails.stopWorkBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.stopWorkBudget);
            break;
        }
        changeObject.all_counts++;
      });
      stats.delay.map((ch) => {
        switch (ch.serviceLine) {
          case 'seo_google':
            changeObject.seo_google_count++;
            changeObject.seo_google_budget += isNaN(
              parseInt(ch.changeDetails.delayBillingBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.delayBillingBudget);
            break;
          case 'ppc_google':
            changeObject.ppc_google_count++;
            changeObject.ppc_google_budget += isNaN(
              parseInt(ch.changeDetails.delayBillingBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.delayBillingBudget);
            break;
            case 'seo_bing':
              changeObject.seo_bing_count++;
              changeObject.seo_bing_budget += isNaN(
                parseInt(ch.changeDetails.delayBillingBudget)
              )
                ? 0
                : parseInt(ch.changeDetails.delayBillingBudget);
              break;
            case 'social_bing':
              changeObject.ppc_bing_count++;
              changeObject.ppc_bing_budget += isNaN(
                parseInt(ch.changeDetails.delayBillingBudget)
              )
                ? 0
                : parseInt(ch.changeDetails.delayBillingBudget);
              break;
          case 'display':
            changeObject.display_count++;
            changeObject.display_budget += isNaN(
              parseInt(ch.changeDetails.delayBillingBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.delayBillingBudget);
            break;
          case 'social_meta':
            changeObject.social_meta_count++;
            changeObject.social_meta_budget += isNaN(
              parseInt(ch.changeDetails.delayBillingBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.delayBillingBudget);
            break;
          case 'social_tiktok':
            changeObject.social_tiktok_count++;
            changeObject.social_tiktok_budget += isNaN(
              parseInt(ch.changeDetails.delayBillingBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.delayBillingBudget);
            break;
          case 'social_pinterest':
            changeObject.social_pinterest_count++;
            changeObject.social_pinterest_budget += isNaN(
              parseInt(ch.changeDetails.delayBillingBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.delayBillingBudget);
            break;
          case 'social_linkedin':
            changeObject.social_linkedin_count++;
            changeObject.social_linkedin_budget += isNaN(
              parseInt(ch.changeDetails.delayBillingBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.delayBillingBudget);
            break;
          case 'social_creative':
            changeObject.social_creative_count++;
            changeObject.social_creative_budget += isNaN(
              parseInt(ch.changeDetails.delayBillingBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.delayBillingBudget);
            break;
          case 'social_programmatic':
            changeObject.social_programmatic_count++;
            changeObject.social_programmatic_budget += isNaN(
              parseInt(ch.changeDetails.delayBillingBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.delayBillingBudget);
            break;
          case 'social_tactical':
            changeObject.social_tactical_count++;
            changeObject.social_tactical_budget += isNaN(
              parseInt(ch.changeDetails.delayBillingBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.delayBillingBudget);
            break; 
          case 'web_maintenance':
            changeObject.web_maintenance_count++;
            changeObject.web_maintenance_budget += isNaN(
              parseInt(ch.changeDetails.delayBillingBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.delayBillingBudget);
            break;
          case 'web_hosting':
            changeObject.web_hosting_count++;
            changeObject.web_hosting_budget += isNaN(
              parseInt(ch.changeDetails.delayBillingBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.delayBillingBudget);
            break;
        }
        changeObject.all_counts++;
      });
      stats.reduce.map((ch) => {
        switch (ch.serviceLine) {
          case 'seo_google':
            changeObject.seo_google_count++;
            changeObject.seo_google_budget += isNaN(
              parseInt(ch.changeDetails.reduceBillingBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.reduceBillingBudget);
            break;
          case 'ppc_google':
            changeObject.ppc_google_count++;
            changeObject.ppc_google_budget += isNaN(
              parseInt(ch.changeDetails.reduceBillingBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.reduceBillingBudget);
            break;
            case 'seo_bing':
              changeObject.seo_bing_count++;
              changeObject.seo_bing_budget += isNaN(
                parseInt(ch.changeDetails.reduceBillingBudget)
              )
                ? 0
                : parseInt(ch.changeDetails.reduceBillingBudget);
              break;
            case 'social_bing':
              changeObject.ppc_bing_count++;
              changeObject.ppc_bing_budget += isNaN(
                parseInt(ch.changeDetails.reduceBillingBudget)
              )
                ? 0
                : parseInt(ch.changeDetails.reduceBillingBudget);
              break;
          case 'display':
            changeObject.display_count++;
            changeObject.display_budget += isNaN(
              parseInt(ch.changeDetails.reduceBillingBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.reduceBillingBudget);
            break;
          case 'social_meta':
            changeObject.social_meta_count++;
            changeObject.social_meta_budget += isNaN(
              parseInt(ch.changeDetails.reduceBillingBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.reduceBillingBudget);
            break;
          case 'social_tiktok':
            changeObject.social_tiktok_count++;
            changeObject.social_tiktok_budget += isNaN(
              parseInt(ch.changeDetails.reduceBillingBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.reduceBillingBudget);
            break;
          case 'social_pinterest':
            changeObject.social_pinterest_count++;
            changeObject.social_pinterest_budget += isNaN(
              parseInt(ch.changeDetails.reduceBillingBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.reduceBillingBudget);
            break;
          case 'social_linkedin':
            changeObject.social_linkedin_count++;
            changeObject.social_linkedin_budget += isNaN(
              parseInt(ch.changeDetails.reduceBillingBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.reduceBillingBudget);
            break;
          case 'social_creative':
            changeObject.social_creative_count++;
            changeObject.social_creative_budget += isNaN(
              parseInt(ch.changeDetails.reduceBillingBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.reduceBillingBudget);
            break;
          case 'social_programmatic':
            changeObject.social_programmatic_count++;
            changeObject.social_programmatic_budget += isNaN(
              parseInt(ch.changeDetails.reduceBillingBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.reduceBillingBudget);
            break;
          case 'social_tactical':
            changeObject.social_tactical_count++;
            changeObject.social_tactical_budget += isNaN(
              parseInt(ch.changeDetails.reduceBillingBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.reduceBillingBudget);
            break; 
          case 'web_maintenance':
            changeObject.web_maintenance_count++;
            changeObject.web_maintenance_budget += isNaN(
              parseInt(ch.changeDetails.reduceBillingBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.reduceBillingBudget);
            break;
          case 'web_hosting':
            changeObject.web_hosting_count++;
            changeObject.web_hosting_budget += isNaN(
              parseInt(ch.changeDetails.reduceBillingBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.reduceBillingBudget);
            break;
        }
        changeObject.all_counts++;
      });
      stats.refund.map((ch) => {
        switch (ch.serviceLine) {
          case 'seo_google':
            changeObject.seo_google_count++;
            changeObject.seo_google_budget += isNaN(
              parseInt(ch.changeDetails.refundBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.refundBudget);
            break;
          case 'ppc_google':
            changeObject.ppc_google_count++;
            changeObject.ppc_google_budget += isNaN(
              parseInt(ch.changeDetails.refundBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.refundBudget);
            break;
          case 'seo_bing':
            changeObject.seo_bing_count++;
            changeObject.seo_bing_budget += isNaN(
              parseInt(ch.changeDetails.refundBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.refundBudget);
            break;
          case 'ppc_google':
            changeObject.ppc_bing_count++;
            changeObject.ppc_bing_budget += isNaN(
              parseInt(ch.changeDetails.refundBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.refundBudget);
            break;
          case 'display':
            changeObject.display_count++;
            changeObject.display_budget += isNaN(
              parseInt(ch.changeDetails.refundBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.refundBudget);
            break;
          case 'social_meta':
            changeObject.social_meta_count++;
            changeObject.social_meta_budget += isNaN(
              parseInt(ch.changeDetails.refundBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.refundBudget);
            break;
          case 'social_tiktok':
            changeObject.social_tiktok_count++;
            changeObject.social_tiktok_budget += isNaN(
              parseInt(ch.changeDetails.refundBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.refundBudget);
            break;
          case 'social_pinterest':
            changeObject.social_pinterest_count++;
            changeObject.social_pinterest_budget += isNaN(
              parseInt(ch.changeDetails.refundBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.refundBudget);
            break;
          case 'social_linkedin':
            changeObject.social_linkedin_count++;
            changeObject.social_linkedin_budget += isNaN(
              parseInt(ch.changeDetails.refundBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.refundBudget);
            break;
          case 'social_creative':
            changeObject.social_creative_count++;
            changeObject.social_creative_budget += isNaN(
              parseInt(ch.changeDetails.refundBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.refundBudget);
            break;
          case 'social_programmatic':
            changeObject.social_programmatic_count++;
            changeObject.social_programmatic_budget += isNaN(
              parseInt(ch.changeDetails.refundBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.refundBudget);
            break;
          case 'social_tactical':
            changeObject.social_tactical_count++;
            changeObject.social_tactical_budget += isNaN(
              parseInt(ch.changeDetails.refundBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.refundBudget);
            break; 
          case 'web_maintenance':
            changeObject.web_maintenance_count++;
            changeObject.web_maintenance_budget += isNaN(
              parseInt(ch.changeDetails.refundBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.refundBudget);
            break;
          case 'web_hosting':
            changeObject.web_hosting_count++;
            changeObject.web_hosting_budget += isNaN(
              parseInt(ch.changeDetails.refundBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.refundBudget);
            break;
        }
        changeObject.all_counts++;
      });
      stats.restart.map((ch) => {
        switch (ch.serviceLine) {
          case 'seo_google':
            changeObject.seo_google_count++;
            changeObject.seo_google_budget += isNaN(
              parseInt(ch.changeDetails.restartBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.restartBudget);
            break;
          case 'ppc_google':
            changeObject.ppc_google_count++;
            changeObject.ppc_google_budget += isNaN(
              parseInt(ch.changeDetails.restartBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.restartBudget);
            break;
          case 'seo_bing':
            changeObject.seo_bing_count++;
            changeObject.seo_bing_budget += isNaN(
              parseInt(ch.changeDetails.restartBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.restartBudget);
            break;
          case 'social_bing':
            changeObject.ppc_bing_count++;
            changeObject.ppc_bing_budget += isNaN(
              parseInt(ch.changeDetails.restartBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.restartBudget);
            break;
          case 'display':
            changeObject.display_count++;
            changeObject.display_budget += isNaN(
              parseInt(ch.changeDetails.restartBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.restartBudget);
            break;
          case 'social_meta':
            changeObject.social_meta_count++;
            changeObject.social_meta_budget += isNaN(
              parseInt(ch.changeDetails.restartBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.restartBudget);
            break;
          case 'social_tiktok':
            changeObject.social_tiktok_count++;
            changeObject.social_tiktok_budget += isNaN(
              parseInt(ch.changeDetails.restartBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.restartBudget);
            break;
          case 'social_pinterest':
            changeObject.social_pinterest_count++;
            changeObject.social_pinterest_budget += isNaN(
              parseInt(ch.changeDetails.restartBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.restartBudget);
            break;
          case 'social_linkedin':
            changeObject.social_linkedin_count++;
            changeObject.social_linkedin_budget += isNaN(
              parseInt(ch.changeDetails.restartBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.restartBudget);
            break;
          case 'social_creative':
            changeObject.social_creative_count++;
            changeObject.social_creative_budget += isNaN(
              parseInt(ch.changeDetails.restartBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.restartBudget);
            break;
          case 'social_programmatic':
            changeObject.social_programmatic_count++;
            changeObject.social_programmatic_budget += isNaN(
              parseInt(ch.changeDetails.restartBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.restartBudget);
            break;
          case 'social_tactical':
            changeObject.social_tactical_count++;
            changeObject.social_tactical_budget += isNaN(
              parseInt(ch.changeDetails.restartBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.restartBudget);
            break; 
          case 'web_maintenance':
            changeObject.web_maintenance_count++;
            changeObject.web_maintenance_budget += isNaN(
              parseInt(ch.changeDetails.restartBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.restartBudget);
            break;
          case 'web_hosting':
            changeObject.web_hosting_count++;
            changeObject.web_hosting_budget += isNaN(
              parseInt(ch.changeDetails.restartBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.restartBudget);
            break;
        }
        changeObject.all_counts++;
      });
      stats.cancel.map((ch) => {
        switch (ch.serviceLine) {
          case 'seo_google':
            changeObject.seo_google_count++;
            changeObject.seo_google_budget += isNaN(
              parseInt(ch.changeDetails.cancelBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.cancelBudget);
            break;
          case 'ppc_google':
            changeObject.ppc_google_count++;
            changeObject.ppc_google_budget += isNaN(
              parseInt(ch.changeDetails.cancelBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.cancelBudget);
            break;
          case 'seo_bing':
            changeObject.seo_bing_count++;
            changeObject.seo_bing_budget += isNaN(
              parseInt(ch.changeDetails.cancelBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.cancelBudget);
            break;
          case 'social_bing':
            changeObject.ppc_bing_count++;
            changeObject.ppc_bing_budget += isNaN(
              parseInt(ch.changeDetails.cancelBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.cancelBudget);
            break;
          case 'display':
            changeObject.display_count++;
            changeObject.display_budget += isNaN(
              parseInt(ch.changeDetails.cancelBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.cancelBudget);
            break;
          case 'social_meta':
            changeObject.social_meta_count++;
            changeObject.social_meta_budget += isNaN(
              parseInt(ch.changeDetails.cancelBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.cancelBudget);
            break;
          case 'social_tiktok':
            changeObject.social_tiktok_count++;
            changeObject.social_tiktok_budget += isNaN(
              parseInt(ch.changeDetails.cancelBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.cancelBudget);
            break;
          case 'social_pinterest':
            changeObject.social_pinterest_count++;
            changeObject.social_pinterest_budget += isNaN(
              parseInt(ch.changeDetails.cancelBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.cancelBudget);
            break;
          case 'social_linkedin':
            changeObject.social_linkedin_count++;
            changeObject.social_linkedin_budget += isNaN(
              parseInt(ch.changeDetails.cancelBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.cancelBudget);
            break;
          case 'social_creative':
            changeObject.social_creative_count++;
            changeObject.social_creative_budget += isNaN(
              parseInt(ch.changeDetails.cancelBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.cancelBudget);
            break;
          case 'social_programmatic':
            changeObject.social_programmatic_count++;
            changeObject.social_programmatic_budget += isNaN(
              parseInt(ch.changeDetails.cancelBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.cancelBudget);
            break;
          case 'social_tactical':
            changeObject.social_tactical_count++;
            changeObject.social_tactical_budget += isNaN(
              parseInt(ch.changeDetails.cancelBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.cancelBudget);
            break; 
          case 'web_maintenance':
            changeObject.web_maintenance_count++;
            changeObject.web_maintenance_budget += isNaN(
              parseInt(ch.changeDetails.cancelBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.cancelBudget);
            break;
          case 'web_hosting':
            changeObject.web_hosting_count++;
            changeObject.web_hosting_budget += isNaN(
              parseInt(ch.changeDetails.cancelBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.cancelBudget);
            break;
        }
        changeObject.all_counts++;
      });
      stats.pause.map((ch) => {
        switch (ch.serviceLine) {
          case 'seo_google':
            pauseObject.seo_google_count++;
            pauseObject.seo_google_budget += isNaN(
              parseInt(ch.changeDetails.pauseClientBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.pauseClientBudget);
            break;
          case 'ppc_google':
            pauseObject.ppc_google_count++;
            pauseObject.ppc_google_budget += isNaN(
              parseInt(ch.changeDetails.pauseClientBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.pauseClientBudget);
            break;
          case 'seo_bing':
            pauseObject.seo_bing_count++;
            pauseObject.seo_bing_budget += isNaN(
              parseInt(ch.changeDetails.pauseClientBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.pauseClientBudget);
            break;
          case 'social_bing':
            pauseObject.ppc_bing_count++;
            pauseObject.ppc_bing_budget += isNaN(
              parseInt(ch.changeDetails.pauseClientBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.pauseClientBudget);
            break;
          case 'display':
            pauseObject.display_count++;
            pauseObject.display_budget += isNaN(
              parseInt(ch.changeDetails.pauseClientBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.pauseClientBudget);
            break;
          case 'social_meta':
            pauseObject.social_meta_count++;
            pauseObject.social_meta_budget += isNaN(
              parseInt(ch.changeDetails.pauseClientBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.pauseClientBudget);
            break;
          case 'social_tiktok':
            pauseObject.social_tiktok_count++;
            pauseObject.social_tiktok_budget += isNaN(
              parseInt(ch.changeDetails.pauseClientBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.pauseClientBudget);
            break;
          case 'social_pinterest':
            pauseObject.social_pinterest_count++;
            pauseObject.social_pinterest_budget += isNaN(
              parseInt(ch.changeDetails.pauseClientBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.pauseClientBudget);
            break;
          case 'social_linkedin':
            pauseObject.social_linkedin_count++;
            pauseObject.social_linkedin_budget += isNaN(
              parseInt(ch.changeDetails.pauseClientBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.pauseClientBudget);
            break;
          case 'social_creative':
            changeObject.social_creative_count++;
            changeObject.social_creative_budget += isNaN(
              parseInt(ch.changeDetails.pauseClientBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.pauseClientBudget);
            break;
          case 'social_programmatic':
            changeObject.social_programmatic_count++;
            changeObject.social_programmatic_budget += isNaN(
              parseInt(ch.changeDetails.pauseClientBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.pauseClientBudget);
            break;
          case 'social_tactical':
            changeObject.social_tactical_count++;
            changeObject.social_tactical_budget += isNaN(
              parseInt(ch.changeDetails.pauseClientBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.pauseClientBudget);
            break; 
          case 'web_maintenance':
            pauseObject.web_maintenance_count++;
            pauseObject.web_maintenance_budget += isNaN(
              parseInt(ch.changeDetails.pauseClientBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.pauseClientBudget);
            break;
          case 'web_hosting':
            pauseObject.web_hosting_count++;
            pauseObject.web_hosting_budget += isNaN(
              parseInt(ch.changeDetails.pauseClientBudget)
            )
              ? 0
              : parseInt(ch.changeDetails.pauseClientBudget);
            break;
        }
        changeObject.all_counts++;
      });
      changeObject.all_budgets +=
      changeObject.seo_google_budget +
      changeObject.ppc_google_budget +
      changeObject.seo_bing_budget +
      changeObject.ppc_bing_budget +
      changeObject.display_budget +
      changeObject.social_meta_budget +
      changeObject.social_tiktok_budget +
      changeObject.social_pinterest_budget +
      changeObject.social_linkedin_budget +
      changeObject.social_creative_budget +
      changeObject.social_programmatic_budget +
      changeObject.social_tactical_budget +
      changeObject.web_maintenance_budget +
      changeObject.web_hosting_budget;

      pauseObject.all_budgets +=
      pauseObject.seo_google_budget +
      pauseObject.ppc_google_budget +
      pauseObject.seo_bing_budget +
      pauseObject.ppc_bing_budget +
      pauseObject.display_budget +
      pauseObject.social_meta_budget +
      pauseObject.social_tiktok_budget +
      pauseObject.social_pinterest_budget +
      pauseObject.social_linkedin_budget +
      pauseObject.social_creative_budget +
      pauseObject.social_programmatic_budget +
      pauseObject.social_tactical_budget +
      pauseObject.web_maintenance_budget +
      pauseObject.web_hosting_budget;
    } else {
      stats.cancel.map((ch) => {
        switch (ch.serviceLine) {
          case 'seo_google':
            if(ch.hasManagerApproval === true){
              financeChangeObject.seo_google_count++;
              financeChangeObject.seo_google_budget += isNaN(
                parseInt(ch.changeDetails.cancelBudget)
              )
                ? 0
                : parseInt(ch.changeDetails.cancelBudget);
            }else{
              changeObject.seo_google_count++;
              changeObject.seo_google_budget += isNaN(
                parseInt(ch.changeDetails.cancelBudget)
              )
                ? 0
                : parseInt(ch.changeDetails.cancelBudget);
            }
            break;
          case 'ppc_google':
              if(ch.hasManagerApproval === true){
                financeChangeObject.ppc_google_count++;
                financeChangeObject.ppc_google_budget += isNaN(
                  parseInt(ch.changeDetails.cancelBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.cancelBudget);
              }else{
                changeObject.ppc_google_count++;
                changeObject.ppc_google_budget += isNaN(
                  parseInt(ch.changeDetails.cancelBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.cancelBudget);
              }
            break;
          case 'seo_bing':
            if(ch.hasManagerApproval === true){
              financeChangeObject.seo_bing_count++;
              financeChangeObject.seo_bing_budget += isNaN(
                parseInt(ch.changeDetails.cancelBudget)
              )
                ? 0
                : parseInt(ch.changeDetails.cancelBudget);
            }else{
              changeObject.seo_bing_count++;
              changeObject.seo_bing_budget += isNaN(
                parseInt(ch.changeDetails.cancelBudget)
              )
                ? 0
                : parseInt(ch.changeDetails.cancelBudget);
            }
            break;
          case 'social_bing':
                if(ch.hasManagerApproval === true){
                  financeChangeObject.ppc_bing_count++;
                  financeChangeObject.ppc_bing_budget += isNaN(
                    parseInt(ch.changeDetails.cancelBudget)
                  )
                    ? 0
                    : parseInt(ch.changeDetails.cancelBudget);
                }else{
                  changeObject.ppc_bing_count++;
                  changeObject.ppc_bing_budget += isNaN(
                    parseInt(ch.changeDetails.cancelBudget)
                  )
                    ? 0
                    : parseInt(ch.changeDetails.cancelBudget);
                }
              break;
          case 'display':
              if(ch.hasManagerApproval === true){
                financeChangeObject.display_count++;
                financeChangeObject.display_budget += isNaN(
                  parseInt(ch.changeDetails.cancelBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.cancelBudget);
              }else{
                changeObject.display_count++;
                changeObject.display_budget += isNaN(
                  parseInt(ch.changeDetails.cancelBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.cancelBudget);
              }
            break;
          case 'social_meta':
              if(ch.hasManagerApproval === true){
                financeChangeObject.social_meta_count++;
                financeChangeObject.social_meta_budget += isNaN(
                  parseInt(ch.changeDetails.cancelBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.cancelBudget);
              }else{
                changeObject.social_meta_count++;
                changeObject.social_meta_budget += isNaN(
                  parseInt(ch.changeDetails.cancelBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.cancelBudget);
              }
            break;
          case 'social_tiktok':
              if(ch.hasManagerApproval === true){
                financeChangeObject.social_tiktok_count++;
                financeChangeObject.social_tiktok_budget += isNaN(
                  parseInt(ch.changeDetails.cancelBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.cancelBudget);
              }else{
                changeObject.social_tiktok_count++;
                changeObject.social_tiktok_budget += isNaN(
                  parseInt(ch.changeDetails.cancelBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.cancelBudget);
              }
            break;
          case 'social_pinterest':
              if(ch.hasManagerApproval === true){
                financeChangeObject.social_pinterest_count++;
                financeChangeObject.social_pinterest_budget += isNaN(
                  parseInt(ch.changeDetails.cancelBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.cancelBudget);
              }else{
                changeObject.social_pinterest_count++;
                changeObject.social_pinterest_budget += isNaN(
                  parseInt(ch.changeDetails.cancelBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.cancelBudget);
              }
            break;
          case 'social_linkedin':
              if(ch.hasManagerApproval === true){
                financeChangeObject.social_linkedin_count++;
                financeChangeObject.social_linkedin_budget += isNaN(
                  parseInt(ch.changeDetails.cancelBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.cancelBudget);
              }else{
                changeObject.social_linkedin_count++;
                changeObject.social_linkedin_budget += isNaN(
                  parseInt(ch.changeDetails.cancelBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.cancelBudget);
              }
            break;
          case 'social_creative':
            if(ch.hasManagerApproval === true){
              financeChangeObject.social_creative_count++;
              financeChangeObject.social_creative_budget += isNaN(
                parseInt(ch.changeDetails.cancelBudget)
              )
                ? 0
                : parseInt(ch.changeDetails.cancelBudget);
            }else{
              changeObject.social_creative_count++;
              changeObject.social_creative_budget += isNaN(
                parseInt(ch.changeDetails.cancelBudget)
              )
                ? 0
                : parseInt(ch.changeDetails.cancelBudget);
            }
          break;
          case 'social_programmatic':
            if(ch.hasManagerApproval === true){
              financeChangeObject.social_programmatic_count++;
              financeChangeObject.social_programmatic_budget += isNaN(
                parseInt(ch.changeDetails.cancelBudget)
              )
                ? 0
                : parseInt(ch.changeDetails.cancelBudget);
            }else{
              changeObject.social_programmatic_count++;
              changeObject.social_programmatic_budget += isNaN(
                parseInt(ch.changeDetails.cancelBudget)
              )
                ? 0
                : parseInt(ch.changeDetails.cancelBudget);
            }
          break;
          case 'social_tactical':
              if(ch.hasManagerApproval === true){
                financeChangeObject.social_tactical_count++;
                financeChangeObject.social_tactical_budget += isNaN(
                  parseInt(ch.changeDetails.cancelBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.cancelBudget);
              }else{
                changeObject.social_tactical_count++;
                changeObject.social_tactical_budget += isNaN(
                  parseInt(ch.changeDetails.cancelBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.cancelBudget);
              }
            break;
          case 'web_maintenance':
              if(ch.hasManagerApproval === true){
                financeChangeObject.web_maintenance_count++;
                financeChangeObject.web_maintenance_budget += isNaN(
                  parseInt(ch.changeDetails.cancelBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.cancelBudget);
              }else{
                changeObject.web_maintenance_count++;
                changeObject.web_maintenance_budget += isNaN(
                  parseInt(ch.changeDetails.cancelBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.cancelBudget);
              }
            break;
          case 'web_hosting':
              if(ch.hasManagerApproval === true){
                financeChangeObject.web_hosting_count++;
                financeChangeObject.web_hosting_budget += isNaN(
                  parseInt(ch.changeDetails.cancelBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.cancelBudget);
              }else{
                changeObject.web_hosting_count++;
                changeObject.web_hosting_budget += isNaN(
                  parseInt(ch.changeDetails.cancelBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.cancelBudget);
              }
            break;
        }
        if(ch.hasManagerApproval === true){
          financeChangeObject.all_counts++;
        }else{
          changeObject.all_counts++;
        }
      });
      stats.pause.map((ch) => {
        switch (ch.serviceLine) {
          case 'seo_google':
              if(ch.hasManagerApproval === true){
                financeCpauseObject.seo_google_count++;
                financeCpauseObject.seo_google_budget += isNaN(
                  parseInt(ch.changeDetails.pauseClientBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.pauseClientBudget)
              }else{
                pauseObject.seo_google_count++;
                pauseObject.seo_google_budget += isNaN(
                  parseInt(ch.changeDetails.pauseClientBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.pauseClientBudget)
              }
            break;
          case 'ppc_google':
              if(ch.hasManagerApproval === true){
                financeCpauseObject.ppc_google_count++;
                financeCpauseObject.ppc_google_budget += isNaN(
                  parseInt(ch.changeDetails.pauseClientBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.pauseClientBudget)
              }else{
                pauseObject.ppc_google_count++;
                pauseObject.ppc_google_budget += isNaN(
                  parseInt(ch.changeDetails.pauseClientBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.pauseClientBudget)
              }
            break;
          case 'seo_bing':
              if(ch.hasManagerApproval === true){
                financeCpauseObject.seo_bing_count++;
                financeCpauseObject.seo_bing_budget += isNaN(
                  parseInt(ch.changeDetails.pauseClientBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.pauseClientBudget)
              }else{
                pauseObject.seo_bing_count++;
                pauseObject.seo_bing_budget += isNaN(
                  parseInt(ch.changeDetails.pauseClientBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.pauseClientBudget)
              }
            break;
          case 'social_bing':
              if(ch.hasManagerApproval === true){
                financeCpauseObject.ppc_bing_count++;
                financeCpauseObject.ppc_bing_budget += isNaN(
                  parseInt(ch.changeDetails.pauseClientBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.pauseClientBudget)
              }else{
                pauseObject.ppc_bing_count++;
                pauseObject.ppc_bing_budget += isNaN(
                  parseInt(ch.changeDetails.pauseClientBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.pauseClientBudget)
              }
            break;
          case 'display':
              if(ch.hasManagerApproval === true){
                financeCpauseObject.display_count++;
                financeCpauseObject.display_budget += isNaN(
                  parseInt(ch.changeDetails.pauseClientBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.pauseClientBudget)
              }else{
                pauseObject.display_count++;
                pauseObject.display_budget += isNaN(
                  parseInt(ch.changeDetails.pauseClientBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.pauseClientBudget)
              }
            break;
          case 'social_meta':
              if(ch.hasManagerApproval === true){
                financeCpauseObject.social_meta_count++;
                financeCpauseObject.social_meta_budget += isNaN(
                  parseInt(ch.changeDetails.pauseClientBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.pauseClientBudget)
              }else{
                pauseObject.social_meta_count++;
                pauseObject.social_meta_budget += isNaN(
                  parseInt(ch.changeDetails.pauseClientBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.pauseClientBudget)
              }
            break;
          case 'social_tiktok':
              if(ch.hasManagerApproval === true){
                financeCpauseObject.social_tiktok_count++;
                financeCpauseObject.social_tiktok_budget += isNaN(
                  parseInt(ch.changeDetails.pauseClientBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.pauseClientBudget)
              }else{
                pauseObject.social_tiktok_count++;
                pauseObject.social_tiktok_budget += isNaN(
                  parseInt(ch.changeDetails.pauseClientBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.pauseClientBudget)
              }
            break;
          case 'social_pinterest':
              if(ch.hasManagerApproval === true){
                financeCpauseObject.social_pinterest_count++;
                financeCpauseObject.social_pinterest_budget += isNaN(
                  parseInt(ch.changeDetails.pauseClientBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.pauseClientBudget)
              }else{
                pauseObject.social_pinterest_count++;
                pauseObject.social_pinterest_budget += isNaN(
                  parseInt(ch.changeDetails.pauseClientBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.pauseClientBudget)
              }
            break;
          case 'social_linkedin':
              if(ch.hasManagerApproval === true){
                financeCpauseObject.social_linkedin_count++;
                financeCpauseObject.social_linkedin_budget += isNaN(
                  parseInt(ch.changeDetails.pauseClientBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.pauseClientBudget)
              }else{
                pauseObject.social_linkedin_count++;
                pauseObject.social_linkedin_budget += isNaN(
                  parseInt(ch.changeDetails.pauseClientBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.pauseClientBudget)
              }
            break;
          case 'social_creative':
            if(ch.hasManagerApproval === true){
              financeCpauseObject.social_creative_count++;
              financeCpauseObject.social_creative_budget += isNaN(
                parseInt(ch.changeDetails.pauseClientBudget)
              )
                ? 0
                : parseInt(ch.changeDetails.pauseClientBudget);
            }else{
              pauseObject.social_creative_count++;
              pauseObject.social_creative_budget += isNaN(
                parseInt(ch.changeDetails.pauseClientBudget)
              )
                ? 0
                : parseInt(ch.changeDetails.pauseClientBudget);
            }
          break;
          case 'social_programmatic':
            if(ch.hasManagerApproval === true){
              financeCpauseObject.social_programmatic_count++;
              financeCpauseObject.social_programmatic_budget += isNaN(
                parseInt(ch.changeDetails.pauseClientBudget)
              )
                ? 0
                : parseInt(ch.changeDetails.pauseClientBudget);
            }else{
              pauseObject.social_programmatic_count++;
              pauseObject.social_programmatic_budget += isNaN(
                parseInt(ch.changeDetails.pauseClientBudget)
              )
                ? 0
                : parseInt(ch.changeDetails.pauseClientBudget);
            }
          break;
          case 'social_tactical':
            if(ch.hasManagerApproval === true){
              financeCpauseObject.social_tactical_count++;
              financeCpauseObject.social_tactical_budget += isNaN(
                parseInt(ch.changeDetails.pauseClientBudget)
              )
                ? 0
                : parseInt(ch.changeDetails.pauseClientBudget);
            }else{
              pauseObject.social_tactical_count++;
              pauseObject.social_tactical_budget += isNaN(
                parseInt(ch.changeDetails.pauseClientBudget)
              )
                ? 0
                : parseInt(ch.changeDetails.pauseClientBudget);
            }
          break;         
          case 'web_maintenance':
              if(ch.hasManagerApproval === true){
                financeCpauseObject.web_maintenance_count++;
                financeCpauseObject.web_maintenance_budget += isNaN(
                  parseInt(ch.changeDetails.pauseClientBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.pauseClientBudget)
              }else{
                pauseObject.web_maintenance_count++;
                pauseObject.web_maintenance_budget += isNaN(
                  parseInt(ch.changeDetails.pauseClientBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.pauseClientBudget)
              }
            break;
          case 'web_hosting':
              if(ch.hasManagerApproval === true){
                financeCpauseObject.web_hosting_count++;
                financeCpauseObject.web_hosting_budget += isNaN(
                  parseInt(ch.changeDetails.pauseClientBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.pauseClientBudget)
              }else{
                pauseObject.web_hosting_count++;
                pauseObject.web_hosting_budget += isNaN(
                  parseInt(ch.changeDetails.pauseClientBudget)
                )
                  ? 0
                  : parseInt(ch.changeDetails.pauseClientBudget)
              }
            break;
        }
        if(ch.hasManagerApproval === true){
          financeCpauseObject.all_counts++;
        }else{
          pauseObject.all_counts++;
        }
      });

      changeObject.all_budgets +=
      changeObject.seo_google_budget +
      changeObject.ppc_google_budget +
      changeObject.seo_bing_budget +
      changeObject.ppc_bing_budget +
      changeObject.display_budget +
      changeObject.social_meta_budget +
      changeObject.social_tiktok_budget +
      changeObject.social_pinterest_budget +
      changeObject.social_linkedin_budget +
      changeObject.social_creative_budget +
      changeObject.social_programmatic_budget +
      changeObject.social_tactical_budget +
      changeObject.web_maintenance_budget +
      changeObject.web_hosting_budget;

      pauseObject.all_budgets +=
      pauseObject.seo_google_budget +
      pauseObject.ppc_google_budget +
      pauseObject.seo_bing_budget +
      pauseObject.ppc_bing_budget +
      pauseObject.display_budget +
      pauseObject.social_meta_budget +
      pauseObject.social_tiktok_budget +
      pauseObject.social_pinterest_budget +
      pauseObject.social_linkedin_budget +
      pauseObject.social_creative_budget +
      pauseObject.social_programmatic_budget +
      pauseObject.social_tactical_budget +
      pauseObject.web_maintenance_budget +
      pauseObject.web_hosting_budget;

      financeChangeObject.all_budgets +=
      financeChangeObject.seo_google_budget +
      financeChangeObject.ppc_google_budget +
      financeChangeObject.seo_bing_budget +
      financeChangeObject.ppc_bing_budget +
      financeChangeObject.display_budget +
      financeChangeObject.social_meta_budget +
      financeChangeObject.social_tiktok_budget +
      financeChangeObject.social_pinterest_budget +
      financeChangeObject.social_linkedin_budget +
      financeChangeObject.social_creative_budget +
      financeChangeObject.social_programmatic_budget +
      financeChangeObject.social_tactical_budget +
      financeChangeObject.web_maintenance_budget +
      financeChangeObject.web_hosting_budget;
      
      financeCpauseObject.all_budgets +=
      financeCpauseObject.seo_google_budget +
      financeCpauseObject.ppc_google_budget +
      financeCpauseObject.seo_bing_budget +
      financeCpauseObject.ppc_bing_budget +
      financeCpauseObject.display_budget +
      financeCpauseObject.social_meta_budget +
      financeCpauseObject.social_tiktok_budget +
      financeCpauseObject.social_pinterest_budget +
      financeCpauseObject.social_linkedin_budget +
      financeCpauseObject.social_creative_budget +
      financeCpauseObject.social_programmatic_budget +
      financeCpauseObject.social_tactical_budget +
      financeCpauseObject.web_maintenance_budget +
      financeCpauseObject.web_hosting_budget;        
    }
  }

  const { summary } = useReportStore((state) => state);
  const { reportStatus } = useReportStore((state) => state.filters);
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const urlStatus = searchParams.get('status');
  const defalutStatus = urlStatus || reportStatus?.id;
  const [activeStatus, setActiveStatus] = React.useState(
    defalutStatus || 'in_progress'
  );

  const statusCounts = [
    {
      label: 'SEO',
      id: 'seo_google',
      count: changeObject.seo_google_count + changeObject.seo_bing_count,
      amount: changeObject.seo_google_budget + changeObject.seo_bing_budget,
      google_count: changeObject.seo_google_count,
      google_amount: changeObject.seo_google_budget,
      bing_count: changeObject.seo_bing_count,
      bing_amount: changeObject.seo_bing_budget,
      bgColor: 'rose',
      googleBing:true,
      type:'googleBing'
    },
    {
      label: 'Paid Media',
      id: 'ppc_google',
      count: changeObject.ppc_google_count + changeObject.ppc_bing_count,
      amount: changeObject.ppc_google_budget + changeObject.ppc_bing_budget,
      google_count: changeObject.ppc_google_count,
      google_amount: changeObject.ppc_google_budget,
      bing_count: changeObject.ppc_bing_count,
      bing_amount: changeObject.ppc_bing_budget,
      bgColor: 'rose',
      googleBing:true,
      type:'googleBing'
    },
    {
      label: 'Remarketing',
      id: 'display',
      count: changeObject.display_count,
      amount: changeObject.display_budget,
      bgColor: 'rose',
    },
    {
      label: 'Social',
      id: 'social_all',
      count: changeObject.social_meta_count + changeObject.social_tiktok_count + changeObject.social_pinterest_count + changeObject.social_linkedin_count,
      amount: changeObject.social_meta_budget + changeObject.social_tiktok_budget + changeObject.social_pinterest_budget + changeObject.social_linkedin_budget,
      meta_count: changeObject.social_meta_count,
      meta_amount: changeObject.social_meta_budget,
      tiktok_count: changeObject.social_tiktok_count,
      tiktok_amount: changeObject.social_tiktok_budget,
      pinterest_count: changeObject.social_pinterest_count,
      pinterest_amount: changeObject.social_pinterest_budget,
      linkedin_count: changeObject.social_linkedin_count, 
      linkedin_amount: changeObject.social_linkedin_budget, 
      tactical_count: changeObject.social_tactical_count, 
      tactical_amount: changeObject.social_tactical_budget, 
      programmatic_count: changeObject.social_programmatic_count, 
      programmatic_amount: changeObject.social_programmatic_budget, 
      creative_count: changeObject.social_creative_count, 
      creative_amount: changeObject.social_creative_budget, 
      bgColor: 'rose',
      social:true,
      type:'social'
    },
    {
      label: 'Web Maintenance',
      id: 'web_maintenance',
      count: changeObject.web_maintenance_count,
      amount: changeObject.web_maintenance_budget,
      bgColor: 'rose',
    },
    {
      label: 'Web Hosting',
      id: 'web_hosting',
      count: changeObject.web_hosting_count,
      amount: changeObject.web_hosting_budget,
      bgColor: 'rose',
    },
    {
      label: 'All',
      id: 'all',
      count: changeObject.all_counts,
      amount: changeObject.all_budgets,
      bgColor: 'rose',
    },
  ];

  const pauseCounts = [
    {
      label: 'SEO',
      id: 'seo_google',
      count: pauseObject.seo_google_count + pauseObject.seo_bing_count,
      amount: pauseObject.seo_google_budget + pauseObject.seo_bing_budget,
      google_count: pauseObject.seo_google_count,
      google_amount: pauseObject.seo_google_budget,
      bing_count: pauseObject.seo_bing_count,
      bing_amount: pauseObject.seo_bing_budget,
      bgColor: 'slate',
      googleBing:true,
      type:'googleBing'
    },
    {
      label: 'Paid Media',
      id: 'ppc_google',
      count: pauseObject.ppc_google_count + pauseObject.ppc_bing_count,
      amount: pauseObject.ppc_google_budget + pauseObject.ppc_bing_budget,
      google_count: pauseObject.ppc_google_count,
      google_amount: pauseObject.ppc_google_budget,
      bing_count: pauseObject.ppc_bing_count,
      bing_amount: pauseObject.ppc_bing_budget,
      bgColor: 'slate',
      googleBing:true,
      type:'googleBing'
    },
    {
      label: 'Remarketing',
      id: 'display',
      count: pauseObject.display_count,
      amount: pauseObject.display_budget,
      bgColor: 'slate',
    },
    {
      label: 'Social',
      id: 'social_all',
      count: pauseObject.social_meta_count + pauseObject.social_tiktok_count + pauseObject.social_pinterest_count + pauseObject.social_linkedin_count + pauseObject.social_tactical_count + pauseObject.social_programmatic_count + pauseObject.social_creative_count,
      amount: pauseObject.social_meta_budget + pauseObject.social_tiktok_budget + pauseObject.social_pinterest_budget + pauseObject.social_linkedin_budget + pauseObject.social_tactical_budget + pauseObject.social_programmatic_budget + pauseObject.social_creative_budget,
      meta_count: pauseObject.social_meta_count,
      meta_amount: pauseObject.social_meta_budget,
      tiktok_count: pauseObject.social_tiktok_count,
      tiktok_amount: pauseObject.social_tiktok_budget,
      pinterest_count: pauseObject.social_pinterest_count,
      pinterest_amount: pauseObject.social_pinterest_budget,
      linkedin_count: pauseObject.social_linkedin_count, 
      linkedin_amount: pauseObject.social_linkedin_budget, 
      tactical_count: pauseObject.social_tactical_count, 
      tactical_amount: pauseObject.social_tactical_budget, 
      programmatic_count: pauseObject.social_programmatic_count, 
      programmatic_amount: pauseObject.social_programmatic_budget, 
      creative_count: pauseObject.social_creative_count, 
      creative_amount: pauseObject.social_creative_budget, 
      bgColor: 'slate',
      social:true,
      type:'social'
    },
    {
      label: 'Web Maintenance',
      id: 'web_maintenance',
      count: pauseObject.web_maintenance_count,
      amount: pauseObject.web_maintenance_budget,
      bgColor: 'slate',
    },
    {
      label: 'Web Hosting',
      id: 'web_hosting',
      count: pauseObject.web_hosting_count,
      amount: pauseObject.web_hosting_budget,
      bgColor: 'slate',
    },
    {
      label: 'All',
      id: 'all',
      count: pauseObject.all_counts,
      amount: pauseObject.all_budgets,
      bgColor: 'slate',
    },
  ];

  const financeCounts = [
    {
      label: 'SEO',
      id: 'seo_google',
      count: financeChangeObject.seo_google_count + financeChangeObject.seo_bing_count,
      amount: financeChangeObject.seo_google_budget + financeChangeObject.seo_bing_budget,
      google_count: financeChangeObject.seo_google_count,
      google_amount: financeChangeObject.seo_google_budget,
      bing_count: financeChangeObject.seo_bing_count,
      bing_amount: financeChangeObject.seo_bing_budget,
      bgColor: 'slate',
      googleBing:true,
      type:'googleBing'
    },
    {
      label: 'Paid Media',
      id: 'ppc_google',
      count: financeChangeObject.ppc_google_count + financeChangeObject.ppc_bing_count,
      amount: financeChangeObject.ppc_google_budget + financeChangeObject.ppc_bing_budget,
      google_count: financeChangeObject.ppc_google_count,
      google_amount: financeChangeObject.ppc_google_budget,
      bing_count: financeChangeObject.ppc_bing_count,
      bing_amount: financeChangeObject.ppc_bing_budget,
      bgColor: 'slate',
      googleBing:true,
      type:'googleBing'
    },
    {
      label: 'Remarketing',
      id: 'display',
      count: financeChangeObject.display_count,
      amount: financeChangeObject.display_budget,
      bgColor: 'slate',
    },
    {
      label: 'Social',
      id: 'social_all',
      count: financeChangeObject.social_meta_count + financeChangeObject.social_tiktok_count + financeChangeObject.social_pinterest_count + financeChangeObject.social_linkedin_count + financeChangeObject.social_tactical_count + financeChangeObject.social_programmatic_count + financeChangeObject.social_creative_count,
      amount: financeChangeObject.social_meta_budget + financeChangeObject.social_tiktok_budget + financeChangeObject.social_pinterest_budget + financeChangeObject.social_linkedin_budget + financeChangeObject.social_tactical_budget + financeChangeObject.social_programmatic_budget + financeChangeObject.social_creative_budget,
      meta_count: financeChangeObject.social_meta_count,
      meta_amount: financeChangeObject.social_meta_budget,
      tiktok_count: financeChangeObject.social_tiktok_count,
      tiktok_amount: financeChangeObject.social_tiktok_budget,
      pinterest_count: financeChangeObject.social_pinterest_count,
      pinterest_amount: financeChangeObject.social_pinterest_budget,
      linkedin_count: financeChangeObject.social_linkedin_count, 
      linkedin_amount: financeChangeObject.social_linkedin_budget, 
      tactical_count: financeChangeObject.social_tactical_count, 
      tactical_amount: financeChangeObject.social_tactical_budget, 
      programmatic_count: financeChangeObject.social_programmatic_count, 
      programmatic_amount: financeChangeObject.social_programmatic_budget, 
      creative_count: financeChangeObject.social_creative_count, 
      creative_amount: financeChangeObject.social_creative_budget, 
      bgColor: 'slate',
      social:true,
      type:'social'
    },
    {
      label: 'Web Maintenance',
      id: 'web_maintenance',
      count: financeChangeObject.web_maintenance_count,
      amount: financeChangeObject.web_maintenance_budget,
      bgColor: 'slate',
    },
    {
      label: 'Web Hosting',
      id: 'web_hosting',
      count: financeChangeObject.web_hosting_count,
      amount: financeChangeObject.web_hosting_budget,
      bgColor: 'slate',
    },
    {
      label: 'All',
      id: 'all',
      count: financeChangeObject.all_counts,
      amount: financeChangeObject.all_budgets,
      bgColor: 'slate',
    },
  ];

  const financePauses = [
    {
      label: 'SEO',
      id: 'seo_google',
      count: financeCpauseObject.seo_google_count + financeCpauseObject.seo_bing_count,
      amount: financeCpauseObject.seo_google_budget + financeCpauseObject.seo_bing_budget,
      google_count: financeCpauseObject.seo_google_count,
      google_amount: financeCpauseObject.seo_google_budget,
      bing_count: financeCpauseObject.seo_bing_count,
      bing_amount: financeCpauseObject.seo_bing_budget,
      bgColor: 'slate',
      googleBing:true,
      type:'googleBing'
    },
    {
      label: 'Paid Media',
      id: 'ppc_google',
      count: financeCpauseObject.ppc_google_count + financeCpauseObject.ppc_bing_count,
      amount: financeCpauseObject.ppc_google_budget + financeCpauseObject.ppc_bing_budget,
      google_count: financeCpauseObject.ppc_google_count,
      google_amount: financeCpauseObject.ppc_google_budget,
      bing_count: financeCpauseObject.ppc_bing_count,
      bing_amount: financeCpauseObject.ppc_bing_budget,
      bgColor: 'slate',
      googleBing:true,
      type:'googleBing'
    },
    {
      label: 'Remarketing',
      id: 'display',
      count: financeCpauseObject.display_count,
      amount: financeCpauseObject.display_budget,
      bgColor: 'slate',
    },
    {
      label: 'Social',
      id: 'social_all',
      count: financeCpauseObject.social_meta_count + financeCpauseObject.social_tiktok_count + financeCpauseObject.social_pinterest_count + financeCpauseObject.social_linkedin_count,
      amount: financeCpauseObject.social_meta_budget + financeCpauseObject.social_tiktok_budget + financeCpauseObject.social_pinterest_budget + financeCpauseObject.social_linkedin_budget,
      meta_count: financeCpauseObject.social_meta_count,
      meta_amount: financeCpauseObject.social_meta_budget,
      tiktok_count: financeCpauseObject.social_tiktok_count,
      tiktok_amount: financeCpauseObject.social_tiktok_budget,
      pinterest_count: financeCpauseObject.social_pinterest_count,
      pinterest_amount: financeCpauseObject.social_pinterest_budget,
      linkedin_count: financeCpauseObject.social_linkedin_count, 
      linkedin_amount: financeCpauseObject.social_linkedin_budget, 
      tactical_count: financeCpauseObject.social_tactical_count, 
      tactical_amount: financeCpauseObject.social_tactical_budget, 
      programmatic_count: financeCpauseObject.social_programmatic_count, 
      programmatic_amount: financeCpauseObject.social_programmatic_budget, 
      creative_count: financeCpauseObject.social_creative_count, 
      creative_amount: financeCpauseObject.social_creative_budget, 
      bgColor: 'slate',
      social:true,
      type:'social'
    },
    {
      label: 'Web Maintenance',
      id: 'web_maintenance',
      count: financeCpauseObject.web_maintenance_count,
      amount: financeCpauseObject.web_maintenance_budget,
      bgColor: 'slate',
    },
    {
      label: 'Web Hosting',
      id: 'web_hosting',
      count: financeCpauseObject.web_hosting_count,
      amount: financeCpauseObject.web_hosting_budget,
      bgColor: 'slate',
    },
    {
      label: 'All',
      id: 'all',
      count: financeCpauseObject.all_counts,
      amount: financeCpauseObject.all_budgets,
      bgColor: 'slate',
    },
  ];

  const handleReportTypes = (status: string) => {
    if (clickable) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('status', status);
      setActiveStatus(status);
      const updatedSearch = searchParams?.toString();
      location.search = updatedSearch;
      navigate(location);
    }
  };

  return (
    <>
      {isReportPage ? (
        <Flex
          justifyContent={`${clickable ? 'start' : 'between'}`}
          className={`${clickable ? 'gap-x-3 ' : 'flex-wrap lg:flex-nowrap'}`}
        >
          <h4>{dataTitle}</h4>
          {statusCounts?.map((report: any, index: number) => (
            <div
              key={index}
              className={`flex flex-col gap-1 p-1 items-center w-40 font-inter rounded-md ${
                clickable
                  ? `cursor-pointer  ${
                      activeStatus === report.id
                        ? `bg-${report.bgColor}-100 text-black dark:bg-gray-800 dark:text-white shadow`
                        : 'bg-white text-[#7C7E7F] dark:bg-transparent dark:text-gray-500 '
                    }`
                  : ' cursor-default '
              } `}
              onClick={() => handleReportTypes(report.id)}
            >
              <div className="dark:text-white text-mini text-center">
                {report.label}
              </div>
              <Badge
                  className={`  ${clickable ? 'cursor-pointer h-[15px]' : 'cursor-default h-[15px]'}`}
                  color={report.bgColor}
                >
                {(() => {
                  switch (report.type) {
                    case "googleBing":   return ( 
                        <Tooltip
                        content={
                          <span>
                          <strong>Total Count:</strong> {report?.count} <br />
                          Google Count: {report?.google_count} <br />
                          Google Amount: ${report?.google_amount} <br />
                          Bing Count: {report?.bing_count} <br />
                          Bing Amount: ${report?.bing_amount} <br />
                        </span>
                      }
                        position="right"
                      >
                        <div className="truncate max-w-[15vw] overflow-hidden text-mini">
                          {formatter.format(report?.amount).split('.')[0]}
                        </div>
                      </Tooltip> 
                    );
                    case "social": return (
                      <Tooltip
                        content={
                          <span>
                          <strong>Total Social Count:</strong> {report?.count} <br />
                          Meta Count: {report?.meta_count} <br />
                          Meta Amount: ${report?.meta_amount} <br />
                          TikTok Count: {report?.tiktok_count} <br />
                          TikTok Amount: ${report?.tiktok_amount} <br />
                          Pinterest Count: {report?.pinterest_count} <br />
                          Pinterest Amount: ${report?.pinterest_amount} <br />
                          Linkedin Count: {report?.linkedin_count} <br />
                          Linkedin Amount: ${report?.linkedin_amount} <br />
                          Tactical Count: {report?.tactical_count} <br />
                          Tactical Amount: ${report?.tactical_amount} <br />
                          Programmatic Count: {report?.programmatic_count} <br />
                          Programmatic Amount: ${report?.programmatic_amount} <br />
                          Creative Count: {report?.creative_count} <br />
                          Creative Amount: ${report?.creative_amount} <br />
                        </span>
                      }
                        position="right"
                      >
                        <div className="truncate max-w-[15vw] overflow-hidden text-mini">
                          {formatter.format(report?.amount).split('.')[0]}
                        </div>
                      </Tooltip> 
                    );
                    default:      return (
                      <Tooltip
                        content={'Total Amount: '+ report?.count}
                        position="top"
                      >
                        <div className="truncate max-w-[15vw] overflow-hidden text-mini">
                          {formatter.format(report?.amount).split('.')[0]}
                        </div>
                      </Tooltip>
                    );
                  }
                })()}
                </Badge>
            </div>
          ))}
        </Flex>
      ) : (
        <>
          <Flex
            justifyContent={`${clickable ? 'start' : 'between'}`}
            className={`${clickable ? 'gap-x-3 ' : 'flex-wrap lg:flex-nowrap'}`}
          >
            <h4>Pending Cancels</h4>
            {statusCounts?.map((report: any, index: number) => (
              <div
                key={index}
                className={`flex flex-col gap-1 p-1 items-center w-40 font-inter rounded-md ${
                  clickable
                    ? `cursor-pointer  ${
                        activeStatus === report.id
                          ? `bg-${report.bgColor}-100 text-black dark:bg-gray-800 dark:text-white shadow`
                          : 'bg-white text-[#7C7E7F] dark:bg-transparent dark:text-gray-500 '
                      }`
                    : ' cursor-default '
                } `}
                onClick={() => handleReportTypes(report.id)}
              >
                <div className="dark:text-white text-mini text-center">
                  {report.label}
                </div>
                <Badge
                  className={`  ${clickable ? 'cursor-pointer h-[15px]' : 'cursor-default h-[15px]'}`}
                  color={report.bgColor}
                >
                {(() => {
                  switch (report.type) {
                    case "googleBing":   return ( 
                        <Tooltip
                        content={
                          <span>
                          <strong>Total Count:</strong> {report?.count} <br />
                          Google Count: {report?.google_count} <br />
                          Google Amount: ${report?.google_amount} <br />
                          Bing Count: {report?.bing_count} <br />
                          Bing Amount: ${report?.bing_amount} <br />
                        </span>
                      }
                        position="right"
                      >
                        <div className="truncate max-w-[15vw] overflow-hidden text-mini">
                          {formatter.format(report?.amount).split('.')[0]}
                        </div>
                      </Tooltip> 
                    );
                    case "social": return (
                      <Tooltip
                        content={
                          <span>
                          <strong>Total Social Count:</strong> {report?.count} <br />
                          Meta Count: {report?.meta_count} <br />
                          Meta Amount: ${report?.meta_amount} <br />
                          TikTok Count: {report?.tiktok_count} <br />
                          TikTok Amount: ${report?.tiktok_amount} <br />
                          Pinterest Count: {report?.pinterest_count} <br />
                          Pinterest Amount: ${report?.pinterest_amount} <br />
                          Linkedin Count: {report?.linkedin_count} <br />
                          Linkedin Amount: ${report?.linkedin_amount} <br />
                          Tactical Count: {report?.tactical_count} <br />
                          Tactical Amount: ${report?.tactical_amount} <br />
                          Programmatic Count: {report?.programmatic_count} <br />
                          Programmatic Amount: ${report?.programmatic_amount} <br />
                          Creative Count: {report?.creative_count} <br />
                          Creative Amount: ${report?.creative_amount} <br />
                        </span>
                      }
                        position="right"
                      >
                        <div className="truncate max-w-[15vw] overflow-hidden text-mini">
                          {formatter.format(report?.amount).split('.')[0]}
                        </div>
                      </Tooltip> 
                    );
                    default:      return (
                      <Tooltip
                        content={'Total Amount: '+ report?.count}
                        position="top"
                      >
                        <div className="truncate max-w-[15vw] overflow-hidden text-mini">
                          {formatter.format(report?.amount).split('.')[0]}
                        </div>
                      </Tooltip>
                    );
                  }
                })()}
                </Badge>
              </div>
            ))}
          </Flex>
          <hr />
          <Flex
            justifyContent={`${clickable ? 'start' : 'between'}`}
            className={`${clickable ? 'gap-x-3 ' : 'flex-wrap lg:flex-nowrap'}`}
          >
            <h4>Pending Pauses</h4>
            {pauseCounts?.map((report: any, index: number) => (
              <div
                key={index}
                className={`flex flex-col gap-1 p-1 items-center w-40 font-inter rounded-md ${
                  clickable
                    ? `cursor-pointer  ${
                        activeStatus === report.id
                          ? `bg-${report.bgColor}-100 text-black dark:bg-gray-800 dark:text-white shadow`
                          : 'bg-white text-[#7C7E7F] dark:bg-transparent dark:text-gray-500 '
                      }`
                    : ' cursor-default '
                } `}
                onClick={() => handleReportTypes(report.id)}
              >
                <div className="dark:text-white text-mini text-center">
                  {report.label}
                </div>
                <Badge
                  className={`  ${clickable ? 'cursor-pointer h-[15px]' : 'cursor-default h-[15px]'}`}
                  color={report.bgColor}
                >
                {(() => {
                  switch (report.type) {
                    case "googleBing":   return ( 
                        <Tooltip
                        content={
                          <span>
                          <strong>Total Count:</strong> {report?.count} <br />
                          Google Count: {report?.google_count} <br />
                          Google Amount: ${report?.google_amount} <br />
                          Bing Count: {report?.bing_count} <br />
                          Bing Amount: ${report?.bing_amount} <br />
                        </span>
                      }
                        position="right"
                      >
                        <div className="truncate max-w-[15vw] overflow-hidden text-mini">
                          {formatter.format(report?.amount).split('.')[0]}
                        </div>
                      </Tooltip> 
                    );
                    case "social": return (
                      <Tooltip
                        content={
                          <span>
                          <strong>Total Social Count:</strong> {report?.count} <br />
                          Meta Count: {report?.meta_count} <br />
                          Meta Amount: ${report?.meta_amount} <br />
                          TikTok Count: {report?.tiktok_count} <br />
                          TikTok Amount: ${report?.tiktok_amount} <br />
                          Pinterest Count: {report?.pinterest_count} <br />
                          Pinterest Amount: ${report?.pinterest_amount} <br />
                          Linkedin Count: {report?.linkedin_count} <br />
                          Linkedin Amount: ${report?.linkedin_amount} <br />
                          Tactical Count: {report?.tactical_count} <br />
                          Tactical Amount: ${report?.tactical_amount} <br />
                          Programmatic Count: {report?.programmatic_count} <br />
                          Programmatic Amount: ${report?.programmatic_amount} <br />
                          Creative Count: {report?.creative_count} <br />
                          Creative Amount: ${report?.creative_amount} <br />
                        </span>
                      }
                        position="right"
                      >
                        <div className="truncate max-w-[15vw] overflow-hidden text-mini">
                          {formatter.format(report?.amount).split('.')[0]}
                        </div>
                      </Tooltip> 
                    );
                    default:      return (
                      <Tooltip
                        content={'Total Amount: '+ report?.count}
                        position="top"
                      >
                        <div className="truncate max-w-[15vw] overflow-hidden text-mini">
                          {formatter.format(report?.amount).split('.')[0]}
                        </div>
                      </Tooltip>
                    );
                  }
                })()}
                </Badge>
              </div>
            ))}
          </Flex>
          <hr />
          <Flex
            justifyContent={`${clickable ? 'start' : 'between'}`}
            className={`${clickable ? 'gap-x-3 ' : 'flex-wrap lg:flex-nowrap'}`}
          >
            <h4>Finance Pending</h4>
            {financeCounts?.map((report: any, index: number) => (
              <div
                key={index}
                className={`flex flex-col gap-1 p-1 items-center w-40 font-inter rounded-md ${
                  clickable
                    ? `cursor-pointer  ${
                        activeStatus === report.id
                          ? `bg-${report.bgColor}-100 text-black dark:bg-gray-800 dark:text-white shadow`
                          : 'bg-white text-[#7C7E7F] dark:bg-transparent dark:text-gray-500 '
                      }`
                    : ' cursor-default '
                } `}
                onClick={() => handleReportTypes(report.id)}
              >
                <div className="dark:text-white text-mini text-center">
                  {report.label}
                </div>
                <Badge
                  className={`  ${clickable ? 'cursor-pointer h-[15px]' : 'cursor-default h-[15px]'}`}
                  color={report.bgColor}
                >
                {(() => {
                  switch (report.type) {
                    case "googleBing":   return ( 
                        <Tooltip
                        content={
                          <span>
                          <strong>Total Count:</strong> {report?.count} <br />
                          Google Count: {report?.google_count} <br />
                          Google Amount: ${report?.google_amount} <br />
                          Bing Count: {report?.bing_count} <br />
                          Bing Amount: ${report?.bing_amount} <br />
                        </span>
                      }
                        position="right"
                      >
                        <div className="truncate max-w-[15vw] overflow-hidden text-mini">
                          {formatter.format(report?.amount).split('.')[0]}
                        </div>
                      </Tooltip> 
                    );
                    case "social": return (
                      <Tooltip
                        content={
                          <span>
                          <strong>Total Social Count:</strong> {report?.count} <br />
                          Meta Count: {report?.meta_count} <br />
                          Meta Amount: ${report?.meta_amount} <br />
                          TikTok Count: {report?.tiktok_count} <br />
                          TikTok Amount: ${report?.tiktok_amount} <br />
                          Pinterest Count: {report?.pinterest_count} <br />
                          Pinterest Amount: ${report?.pinterest_amount} <br />
                          Linkedin Count: {report?.linkedin_count} <br />
                          Linkedin Amount: ${report?.linkedin_amount} <br />
                          Tactical Count: {report?.tactical_count} <br />
                          Tactical Amount: ${report?.tactical_amount} <br />
                          Programmatic Count: {report?.programmatic_count} <br />
                          Programmatic Amount: ${report?.programmatic_amount} <br />
                          Creative Count: {report?.creative_count} <br />
                          Creative Amount: ${report?.creative_amount} <br />
                        </span>
                      }
                        position="right"
                      >
                        <div className="truncate max-w-[15vw] overflow-hidden text-mini">
                          {formatter.format(report?.amount).split('.')[0]}
                        </div>
                      </Tooltip> 
                    );
                    default:      return (
                      <Tooltip
                        content={'Total Amount: '+ report?.count}
                        position="top"
                      >
                        <div className="truncate max-w-[15vw] overflow-hidden text-mini">
                          {formatter.format(report?.amount).split('.')[0]}
                        </div>
                      </Tooltip>
                    );
                  }
                })()}
                </Badge>
              </div>
            ))}
          </Flex>
          <hr />
          <Flex
            justifyContent={`${clickable ? 'start' : 'between'}`}
            className={`${clickable ? 'gap-x-3 ' : 'flex-wrap lg:flex-nowrap'}`}
          >
            <h4>Finance Pauses</h4>
            {financePauses?.map((report: any, index: number) => (
              <div
                key={index}
                className={`flex flex-col gap-1 p-1 items-center w-40 font-inter rounded-md ${
                  clickable
                    ? `cursor-pointer  ${
                        activeStatus === report.id
                          ? `bg-${report.bgColor}-100 text-black dark:bg-gray-800 dark:text-white shadow`
                          : 'bg-white text-[#7C7E7F] dark:bg-transparent dark:text-gray-500 '
                      }`
                    : ' cursor-default '
                } `}
                onClick={() => handleReportTypes(report.id)}
              >
                <div className="dark:text-white text-mini text-center">
                  {report.label}
                </div>
                <Badge
                  className={`  ${clickable ? 'cursor-pointer h-[15px]' : 'cursor-default h-[15px]'}`}
                  color={report.bgColor}
                >
                {(() => {
                  switch (report.type) {
                    case "googleBing":   return ( 
                        <Tooltip
                        content={
                          <span>
                          <strong>Total Count:</strong> {report?.count} <br />
                          Google Count: {report?.google_count} <br />
                          Google Amount: ${report?.google_amount} <br />
                          Bing Count: {report?.bing_count} <br />
                          Bing Amount: ${report?.bing_amount} <br />
                        </span>
                      }
                        position="right"
                      >
                        <div className="truncate max-w-[15vw] overflow-hidden text-mini">
                          {formatter.format(report?.amount).split('.')[0]}
                        </div>
                      </Tooltip> 
                    );
                    case "social": return (
                      <Tooltip
                        content={
                          <span>
                          <strong>Total Social Count:</strong> {report?.count} <br />
                          Meta Count: {report?.meta_count} <br />
                          Meta Amount: ${report?.meta_amount} <br />
                          TikTok Count: {report?.tiktok_count} <br />
                          TikTok Amount: ${report?.tiktok_amount} <br />
                          Pinterest Count: {report?.pinterest_count} <br />
                          Pinterest Amount: ${report?.pinterest_amount} <br />
                          Linkedin Count: {report?.linkedin_count} <br />
                          Linkedin Amount: ${report?.linkedin_amount} <br />
                          Tactical Count: {report?.tactical_count} <br />
                          Tactical Amount: ${report?.tactical_amount} <br />
                          Programmatic Count: {report?.programmatic_count} <br />
                          Programmatic Amount: ${report?.programmatic_amount} <br />
                          Creative Count: {report?.creative_count} <br />
                          Creative Amount: ${report?.creative_amount} <br />
                        </span>
                      }
                        position="right"
                      >
                        <div className="truncate max-w-[15vw] overflow-hidden text-mini">
                          {formatter.format(report?.amount).split('.')[0]}
                        </div>
                      </Tooltip> 
                    );
                    default:      return (
                      <Tooltip
                        content={'Total Amount: '+ report?.count}
                        position="top"
                      >
                        <div className="truncate max-w-[15vw] overflow-hidden text-mini">
                          {formatter.format(report?.amount).split('.')[0]}
                        </div>
                      </Tooltip>
                    );
                  }
                })()}
                </Badge>
              </div>
            ))}
          </Flex>
        </>
      )}
    </>
  );
};

export default ChangeCounter;
