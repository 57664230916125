import React, { useState, useEffect, Fragment } from 'react';
import ReactSelect from '../../../components/ReactSelect';

// SEO FLAGGED EXPORT
export const SEOFlagged = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.seo_google.end_date !== '') {
    var timeleft = Date.parse(client.seo_google.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.seo_google.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [flaggedValues, setValues] = useState({
    type: 'flagged',
    service: 'seo_google',
    flaggedReason: '',
    flaggedDeficit: '',
    flaggedTimePeriod: '',
    flaggedPages: '',
    flaggedFrom: '',
    flaggedKeywords: '',
    flaggedQuality: '',
    flaggedDocuments: '',
    flaggedNoteComments: '',
    flaggedContentSub: '',
    flaggedContentInfo: '',
    flaggedTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    flaggedReason,
    flaggedDeficit,
    flaggedTimePeriod,
    flaggedPages,
    flaggedQuality,
    flaggedKeywords,
    flaggedFrom,
    flaggedDocuments,
    flaggedNoteComments,
    flaggedContentSub,
    flaggedContentInfo,
  } = flaggedValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...flaggedValues, [name]: value });

    parentCallback(flaggedValues);
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...flaggedValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(flaggedValues);
  }, [flaggedValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">SEO GOOGLE Flag Details</div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the nature for flagging the client?
          </p>
          <div className="text-[#7C7E7F]">
            <ReactSelect
              handleOnChange={handleSelectChange('flaggedReason')}
              options={[
                { value: 'Traffic Drop', label: 'Traffic Drop' },
                { value: 'Ranking Drop', label: 'Ranking Drop' },
                { value: 'Work Quality', label: 'Work Quality' },
                { value: 'Performance', label: 'Performance' },
                { value: 'Content', label: 'Content Issue' },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Did we discover this or did the client flag this?
          </p>
          <div className="text-[#7C7E7F]">
            <ReactSelect
              handleOnChange={handleSelectChange('flaggedFrom')}
              options={[
                { value: 'Flagged by Client', label: 'Client Flagged' },
                {
                  value: 'We are in front of it',
                  label: 'We are in front of it',
                },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
        {flaggedReason === 'Traffic Drop' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What is the extent of the drop (% deficit)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  name="flaggedDeficit"
                  onInput={handleChange('flaggedDeficit')}
                  value={flaggedDeficit}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                When was the last time period that traffic was performing as
                expected?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  name="flaggedTimePeriod"
                  onInput={handleChange('flaggedTimePeriod')}
                  value={flaggedTimePeriod}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                List the main pages you are concerned are dropping in traffic
                (top 3 specific landing pages)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedPages')}
                  value={flaggedPages}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Ranking Drop' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What are the main keyword(s) that are dropping? (1-5 highest
                priority declining keywords)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedKeywords')}
                  value={flaggedKeywords}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Work Quality' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                Please provide either the sales brief or otherwise a short
                statement about what particular aspect of the campaign has been
                underdelivered / not of sufficient quality?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedQuality')}
                  value={flaggedQuality}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                Please link to the document that best represents the work
                quality issue that you are raising
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedDocuments')}
                  value={flaggedDocuments}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Performance' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What is going wrong with the campaign?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedNoteComments')}
                  value={flaggedNoteComments}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Content' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                Select Content Issue:
              </p>
              <div className="text-[#7C7E7F]">
                <ReactSelect
                  handleOnChange={handleSelectChange('flaggedContentSub')}
                  options={[
                    {
                      value: 'Incorrect Information Included',
                      label: 'Incorrect Information Included',
                    },
                    { value: 'Poor Quality"', label: 'Poor Quality"' },
                  ]}
                  placeholder="PLEASE SELECT"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                Further Information?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedContentInfo')}
                  value={flaggedContentInfo}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

// PPC FLAGGED EXPORT
export const PPCFlagged = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.ppc_google.end_date !== '') {
    var timeleft = Date.parse(client.ppc_google.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.ppc_google.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [flaggedValues, setValues] = useState({
    type: 'flagged',
    service: 'ppc_google',
    flaggedReason: '',
    flaggedDeficit: '',
    flaggedTimePeriod: '',
    flaggedPages: '',
    flaggedFrom: '',
    flaggedKeywords: '',
    flaggedQuality: '',
    flaggedDocuments: '',
    flaggedNoteComments: '',
    flaggedTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    flaggedReason,
    flaggedDeficit,
    flaggedTimePeriod,
    flaggedPages,
    flaggedQuality,
    flaggedKeywords,
    flaggedFrom,
    flaggedDocuments,
    flaggedNoteComments,
  } = flaggedValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...flaggedValues, [name]: value });

    parentCallback(flaggedValues);
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...flaggedValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(flaggedValues);
  }, [flaggedValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">PPC GOOGLE Flag Details</div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the nature for flagging the client?
          </p>
          <div className="text-[#7C7E7F]">
            <ReactSelect
              handleOnChange={handleSelectChange('flaggedReason')}
              options={[
                { value: 'Traffic Drop', label: 'Traffic Drop' },
                { value: 'Ranking Drop', label: 'Ranking Drop' },
                { value: 'Work Quality', label: 'Work Quality' },
                { value: 'Performance', label: 'Performance' },
                { value: 'Content', label: 'Content Issue' },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Did we discover this or did the client flag this?
          </p>
          <div className="text-[#7C7E7F]">
            <ReactSelect
              handleOnChange={handleSelectChange('flaggedFrom')}
              options={[
                { value: 'Flagged by Client', label: 'Client Flagged' },
                {
                  value: 'We are in front of it',
                  label: 'We are in front of it',
                },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
        {flaggedReason === 'Traffic Drop' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What is the extent of the drop (% deficit)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  name="flaggedDeficit"
                  onInput={handleChange('flaggedDeficit')}
                  value={flaggedDeficit}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                When was the last time period that traffic was performing as
                expected?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  name="flaggedTimePeriod"
                  onInput={handleChange('flaggedTimePeriod')}
                  value={flaggedTimePeriod}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                List the main pages you are concerned are dropping in traffic
                (top 3 specific landing pages)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedPages')}
                  value={flaggedPages}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Ranking Drop' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What are the main keyword(s) that are dropping? (1-5 highest
                priority declining keywords)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedKeywords')}
                  value={flaggedKeywords}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Work Quality' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                Please provide either the sales brief or otherwise a short
                statement about what particular aspect of the campaign has been
                underdelivered / not of sufficient quality?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedQuality')}
                  value={flaggedQuality}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                Please link to the document that best represents the work
                quality issue that you are raising
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedDocuments')}
                  value={flaggedDocuments}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Performance' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What is going wrong with the campaign?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedNoteComments')}
                  value={flaggedNoteComments}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

// SEO FLAGGED EXPORT
export const SEOBINGFlagged = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.seo_bing.end_date !== '') {
    var timeleft = Date.parse(client.seo_bing.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.seo_bing.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [flaggedValues, setValues] = useState({
    type: 'flagged',
    service: 'seo_bing',
    flaggedReason: '',
    flaggedDeficit: '',
    flaggedTimePeriod: '',
    flaggedPages: '',
    flaggedFrom: '',
    flaggedKeywords: '',
    flaggedQuality: '',
    flaggedDocuments: '',
    flaggedNoteComments: '',
    flaggedContentSub: '',
    flaggedContentInfo: '',
    flaggedTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    flaggedReason,
    flaggedDeficit,
    flaggedTimePeriod,
    flaggedPages,
    flaggedQuality,
    flaggedKeywords,
    flaggedFrom,
    flaggedDocuments,
    flaggedNoteComments,
    flaggedContentSub,
    flaggedContentInfo,
  } = flaggedValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...flaggedValues, [name]: value });

    parentCallback(flaggedValues);
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...flaggedValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(flaggedValues);
  }, [flaggedValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">Microsoft SEO Flag Details</div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the nature for flagging the client?
          </p>
          <div className="text-[#7C7E7F]">
            <ReactSelect
              handleOnChange={handleSelectChange('flaggedReason')}
              options={[
                { value: 'Traffic Drop', label: 'Traffic Drop' },
                { value: 'Ranking Drop', label: 'Ranking Drop' },
                { value: 'Work Quality', label: 'Work Quality' },
                { value: 'Performance', label: 'Performance' },
                { value: 'Content', label: 'Content Issue' },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Did we discover this or did the client flag this?
          </p>
          <div className="text-[#7C7E7F]">
            <ReactSelect
              handleOnChange={handleSelectChange('flaggedFrom')}
              options={[
                { value: 'Flagged by Client', label: 'Client Flagged' },
                {
                  value: 'We are in front of it',
                  label: 'We are in front of it',
                },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
        {flaggedReason === 'Traffic Drop' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What is the extent of the drop (% deficit)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  name="flaggedDeficit"
                  onInput={handleChange('flaggedDeficit')}
                  value={flaggedDeficit}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                When was the last time period that traffic was performing as
                expected?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  name="flaggedTimePeriod"
                  onInput={handleChange('flaggedTimePeriod')}
                  value={flaggedTimePeriod}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                List the main pages you are concerned are dropping in traffic
                (top 3 specific landing pages)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedPages')}
                  value={flaggedPages}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Ranking Drop' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What are the main keyword(s) that are dropping? (1-5 highest
                priority declining keywords)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedKeywords')}
                  value={flaggedKeywords}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Work Quality' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                Please provide either the sales brief or otherwise a short
                statement about what particular aspect of the campaign has been
                underdelivered / not of sufficient quality?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedQuality')}
                  value={flaggedQuality}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                Please link to the document that best represents the work
                quality issue that you are raising
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedDocuments')}
                  value={flaggedDocuments}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Performance' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What is going wrong with the campaign?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedNoteComments')}
                  value={flaggedNoteComments}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Content' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                Select Content Issue:
              </p>
              <div className="text-[#7C7E7F]">
                <ReactSelect
                  handleOnChange={handleSelectChange('flaggedContentSub')}
                  options={[
                    {
                      value: 'Incorrect Information Included',
                      label: 'Incorrect Information Included',
                    },
                    { value: 'Poor Quality"', label: 'Poor Quality"' },
                  ]}
                  placeholder="PLEASE SELECT"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                Further Information?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedContentInfo')}
                  value={flaggedContentInfo}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

// PPC FLAGGED EXPORT
export const PPCBINGFlagged = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_bing.end_date !== '') {
    var timeleft = Date.parse(client.social_bing.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_bing.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [flaggedValues, setValues] = useState({
    type: 'flagged',
    service: 'social_bing',
    flaggedReason: '',
    flaggedDeficit: '',
    flaggedTimePeriod: '',
    flaggedPages: '',
    flaggedFrom: '',
    flaggedKeywords: '',
    flaggedQuality: '',
    flaggedDocuments: '',
    flaggedNoteComments: '',
    flaggedTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    flaggedReason,
    flaggedDeficit,
    flaggedTimePeriod,
    flaggedPages,
    flaggedQuality,
    flaggedKeywords,
    flaggedFrom,
    flaggedDocuments,
    flaggedNoteComments,
  } = flaggedValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...flaggedValues, [name]: value });

    parentCallback(flaggedValues);
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...flaggedValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(flaggedValues);
  }, [flaggedValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">Microsoft Ads Flag Details</div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the nature for flagging the client?
          </p>
          <div className="text-[#7C7E7F]">
            <ReactSelect
              handleOnChange={handleSelectChange('flaggedReason')}
              options={[
                { value: 'Traffic Drop', label: 'Traffic Drop' },
                { value: 'Ranking Drop', label: 'Ranking Drop' },
                { value: 'Work Quality', label: 'Work Quality' },
                { value: 'Performance', label: 'Performance' },
                { value: 'Content', label: 'Content Issue' },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Did we discover this or did the client flag this?
          </p>
          <div className="text-[#7C7E7F]">
            <ReactSelect
              handleOnChange={handleSelectChange('flaggedFrom')}
              options={[
                { value: 'Flagged by Client', label: 'Client Flagged' },
                {
                  value: 'We are in front of it',
                  label: 'We are in front of it',
                },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
        {flaggedReason === 'Traffic Drop' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What is the extent of the drop (% deficit)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  name="flaggedDeficit"
                  onInput={handleChange('flaggedDeficit')}
                  value={flaggedDeficit}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                When was the last time period that traffic was performing as
                expected?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  name="flaggedTimePeriod"
                  onInput={handleChange('flaggedTimePeriod')}
                  value={flaggedTimePeriod}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                List the main pages you are concerned are dropping in traffic
                (top 3 specific landing pages)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedPages')}
                  value={flaggedPages}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Ranking Drop' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What are the main keyword(s) that are dropping? (1-5 highest
                priority declining keywords)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedKeywords')}
                  value={flaggedKeywords}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Work Quality' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                Please provide either the sales brief or otherwise a short
                statement about what particular aspect of the campaign has been
                underdelivered / not of sufficient quality?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedQuality')}
                  value={flaggedQuality}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                Please link to the document that best represents the work
                quality issue that you are raising
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedDocuments')}
                  value={flaggedDocuments}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Performance' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What is going wrong with the campaign?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedNoteComments')}
                  value={flaggedNoteComments}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

// REM FLAGGED EXPORT
export const REMFlagged = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.display.end_date !== '') {
    var timeleft = Date.parse(client.display.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.display.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [flaggedValues, setValues] = useState({
    type: 'flagged',
    service: 'display',
    flaggedReason: '',
    flaggedDeficit: '',
    flaggedTimePeriod: '',
    flaggedPages: '',
    flaggedFrom: '',
    flaggedKeywords: '',
    flaggedQuality: '',
    flaggedDocuments: '',
    flaggedNoteComments: '',
    flaggedTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    flaggedReason,
    flaggedDeficit,
    flaggedTimePeriod,
    flaggedPages,
    flaggedQuality,
    flaggedKeywords,
    flaggedFrom,
    flaggedDocuments,
    flaggedNoteComments,
  } = flaggedValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...flaggedValues, [name]: value });

    parentCallback(flaggedValues);
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...flaggedValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(flaggedValues);
  }, [flaggedValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Remarketing Flag Details
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the nature for flagging the client?
          </p>
          <div className="text-[#7C7E7F]">
            <ReactSelect
              handleOnChange={handleSelectChange('flaggedReason')}
              options={[
                { value: 'Traffic Drop', label: 'Traffic Drop' },
                { value: 'Ranking Drop', label: 'Ranking Drop' },
                { value: 'Work Quality', label: 'Work Quality' },
                { value: 'Performance', label: 'Performance' },
                { value: 'Content', label: 'Content Issue' },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Did we discover this or did the client flag this?
          </p>
          <div className="text-[#7C7E7F]">
            <ReactSelect
              handleOnChange={handleSelectChange('flaggedFrom')}
              options={[
                { value: 'Flagged by Client', label: 'Client Flagged' },
                {
                  value: 'We are in front of it',
                  label: 'We are in front of it',
                },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
        {flaggedReason === 'Traffic Drop' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What is the extent of the drop (% deficit)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  name="flaggedDeficit"
                  onInput={handleChange('flaggedDeficit')}
                  value={flaggedDeficit}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                When was the last time period that traffic was performing as
                expected?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  name="flaggedTimePeriod"
                  onInput={handleChange('flaggedTimePeriod')}
                  value={flaggedTimePeriod}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                List the main pages you are concerned are dropping in traffic
                (top 3 specific landing pages)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedPages')}
                  value={flaggedPages}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Ranking Drop' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What are the main keyword(s) that are dropping? (1-5 highest
                priority declining keywords)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedKeywords')}
                  value={flaggedKeywords}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Work Quality' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                Please provide either the sales brief or otherwise a short
                statement about what particular aspect of the campaign has been
                underdelivered / not of sufficient quality?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedQuality')}
                  value={flaggedQuality}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                Please link to the document that best represents the work
                quality issue that you are raising
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedDocuments')}
                  value={flaggedDocuments}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Performance' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What is going wrong with the campaign?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedNoteComments')}
                  value={flaggedNoteComments}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

// META FLAGGED EXPORT
export const METAFlagged = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_meta.end_date !== '') {
    var timeleft = Date.parse(client.social_meta.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_meta.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [flaggedValues, setValues] = useState({
    type: 'flagged',
    service: 'social_meta',
    flaggedReason: '',
    flaggedDeficit: '',
    flaggedTimePeriod: '',
    flaggedPages: '',
    flaggedFrom: '',
    flaggedKeywords: '',
    flaggedQuality: '',
    flaggedDocuments: '',
    flaggedNoteComments: '',
    flaggedTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    flaggedReason,
    flaggedDeficit,
    flaggedTimePeriod,
    flaggedPages,
    flaggedQuality,
    flaggedKeywords,
    flaggedFrom,
    flaggedDocuments,
    flaggedNoteComments,
  } = flaggedValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...flaggedValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...flaggedValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(flaggedValues);
  }, [flaggedValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">META Flag Details</div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the nature for flagging the client?
          </p>
          <div className="text-[#7C7E7F]">
            <ReactSelect
              handleOnChange={handleSelectChange('flaggedReason')}
              options={[
                { value: 'Traffic Drop', label: 'Traffic Drop' },
                { value: 'Ranking Drop', label: 'Ranking Drop' },
                { value: 'Work Quality', label: 'Work Quality' },
                { value: 'Performance', label: 'Performance' },
                { value: 'Content', label: 'Content Issue' },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Did we discover this or did the client flag this?
          </p>
          <div className="text-[#7C7E7F]">
            <ReactSelect
              handleOnChange={handleSelectChange('flaggedFrom')}
              options={[
                { value: 'Flagged by Client', label: 'Client Flagged' },
                {
                  value: 'We are in front of it',
                  label: 'We are in front of it',
                },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
        {flaggedReason === 'Traffic Drop' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What is the extent of the drop (% deficit)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  name="flaggedDeficit"
                  onInput={handleChange('flaggedDeficit')}
                  value={flaggedDeficit}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                When was the last time period that traffic was performing as
                expected?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  name="flaggedTimePeriod"
                  onInput={handleChange('flaggedTimePeriod')}
                  value={flaggedTimePeriod}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                List the main pages you are concerned are dropping in traffic
                (top 3 specific landing pages)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedPages')}
                  value={flaggedPages}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Ranking Drop' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What are the main keyword(s) that are dropping? (1-5 highest
                priority declining keywords)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedKeywords')}
                  value={flaggedKeywords}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Work Quality' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                Please provide either the sales brief or otherwise a short
                statement about what particular aspect of the campaign has been
                underdelivered / not of sufficient quality?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedQuality')}
                  value={flaggedQuality}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                Please link to the document that best represents the work
                quality issue that you are raising
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedDocuments')}
                  value={flaggedDocuments}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Performance' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What is going wrong with the campaign?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedNoteComments')}
                  value={flaggedNoteComments}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

// PINTEREST FLAGGED EXPORT
export const PINTERESTFlagged = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_pinterest.end_date !== '') {
    var timeleft = Date.parse(client.social_pinterest.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_pinterest.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [flaggedValues, setValues] = useState({
    type: 'flagged',
    service: 'social_pinterest',
    flaggedReason: '',
    flaggedDeficit: '',
    flaggedTimePeriod: '',
    flaggedPages: '',
    flaggedFrom: '',
    flaggedKeywords: '',
    flaggedQuality: '',
    flaggedDocuments: '',
    flaggedNoteComments: '',
    flaggedTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    flaggedReason,
    flaggedDeficit,
    flaggedTimePeriod,
    flaggedPages,
    flaggedQuality,
    flaggedKeywords,
    flaggedFrom,
    flaggedDocuments,
    flaggedNoteComments,
  } = flaggedValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...flaggedValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...flaggedValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(flaggedValues);
  }, [flaggedValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        PINTEREST Flag Details
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the nature for flagging the client?
          </p>
          <div className="text-[#7C7E7F]">
            <ReactSelect
              handleOnChange={handleSelectChange('flaggedReason')}
              options={[
                { value: 'Traffic Drop', label: 'Traffic Drop' },
                { value: 'Ranking Drop', label: 'Ranking Drop' },
                { value: 'Work Quality', label: 'Work Quality' },
                { value: 'Performance', label: 'Performance' },
                { value: 'Content', label: 'Content Issue' },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Did we discover this or did the client flag this?
          </p>
          <div className="text-[#7C7E7F]">
            <ReactSelect
              handleOnChange={handleSelectChange('flaggedFrom')}
              options={[
                { value: 'Flagged by Client', label: 'Client Flagged' },
                {
                  value: 'We are in front of it',
                  label: 'We are in front of it',
                },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
        {flaggedReason === 'Traffic Drop' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What is the extent of the drop (% deficit)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  name="flaggedDeficit"
                  onInput={handleChange('flaggedDeficit')}
                  value={flaggedDeficit}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                When was the last time period that traffic was performing as
                expected?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  name="flaggedTimePeriod"
                  onInput={handleChange('flaggedTimePeriod')}
                  value={flaggedTimePeriod}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                List the main pages you are concerned are dropping in traffic
                (top 3 specific landing pages)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedPages')}
                  value={flaggedPages}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Ranking Drop' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What are the main keyword(s) that are dropping? (1-5 highest
                priority declining keywords)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedKeywords')}
                  value={flaggedKeywords}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Work Quality' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                Please provide either the sales brief or otherwise a short
                statement about what particular aspect of the campaign has been
                underdelivered / not of sufficient quality?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedQuality')}
                  value={flaggedQuality}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                Please link to the document that best represents the work
                quality issue that you are raising
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedDocuments')}
                  value={flaggedDocuments}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Performance' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What is going wrong with the campaign?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedNoteComments')}
                  value={flaggedNoteComments}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

// TIKTOK FLAGGED EXPORT
export const TIKTOKFlagged = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_tiktok.end_date !== '') {
    var timeleft = Date.parse(client.social_tiktok.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_tiktok.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [flaggedValues, setValues] = useState({
    type: 'flagged',
    service: 'social_tiktok',
    flaggedReason: '',
    flaggedDeficit: '',
    flaggedTimePeriod: '',
    flaggedPages: '',
    flaggedFrom: '',
    flaggedKeywords: '',
    flaggedQuality: '',
    flaggedDocuments: '',
    flaggedNoteComments: '',
    flaggedTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    flaggedReason,
    flaggedDeficit,
    flaggedTimePeriod,
    flaggedPages,
    flaggedQuality,
    flaggedKeywords,
    flaggedFrom,
    flaggedDocuments,
    flaggedNoteComments,
  } = flaggedValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...flaggedValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...flaggedValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(flaggedValues);
  }, [flaggedValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">TIKTOK Flag Details</div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the nature for flagging the client?
          </p>
          <div className="text-[#7C7E7F]">
            <ReactSelect
              handleOnChange={handleSelectChange('flaggedReason')}
              options={[
                { value: 'Traffic Drop', label: 'Traffic Drop' },
                { value: 'Ranking Drop', label: 'Ranking Drop' },
                { value: 'Work Quality', label: 'Work Quality' },
                { value: 'Performance', label: 'Performance' },
                { value: 'Content', label: 'Content Issue' },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Did we discover this or did the client flag this?
          </p>
          <div className="text-[#7C7E7F]">
            <ReactSelect
              handleOnChange={handleSelectChange('flaggedFrom')}
              options={[
                { value: 'Flagged by Client', label: 'Client Flagged' },
                {
                  value: 'We are in front of it',
                  label: 'We are in front of it',
                },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
        {flaggedReason === 'Traffic Drop' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What is the extent of the drop (% deficit)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  name="flaggedDeficit"
                  onInput={handleChange('flaggedDeficit')}
                  value={flaggedDeficit}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                When was the last time period that traffic was performing as
                expected?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  name="flaggedTimePeriod"
                  onInput={handleChange('flaggedTimePeriod')}
                  value={flaggedTimePeriod}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                List the main pages you are concerned are dropping in traffic
                (top 3 specific landing pages)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedPages')}
                  value={flaggedPages}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Ranking Drop' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What are the main keyword(s) that are dropping? (1-5 highest
                priority declining keywords)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedKeywords')}
                  value={flaggedKeywords}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Work Quality' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                Please provide either the sales brief or otherwise a short
                statement about what particular aspect of the campaign has been
                underdelivered / not of sufficient quality?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedQuality')}
                  value={flaggedQuality}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                Please link to the document that best represents the work
                quality issue that you are raising
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedDocuments')}
                  value={flaggedDocuments}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Performance' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What is going wrong with the campaign?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedNoteComments')}
                  value={flaggedNoteComments}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

// LINKEDIN FLAGGED EXPORT
export const LINKEDINFlagged = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_linkedin.end_date !== '') {
    var timeleft = Date.parse(client.social_linkedin.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_linkedin.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [flaggedValues, setValues] = useState({
    type: 'flagged',
    service: 'social_linkedin',
    flaggedReason: '',
    flaggedDeficit: '',
    flaggedTimePeriod: '',
    flaggedPages: '',
    flaggedFrom: '',
    flaggedKeywords: '',
    flaggedQuality: '',
    flaggedDocuments: '',
    flaggedNoteComments: '',
    flaggedTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    flaggedReason,
    flaggedDeficit,
    flaggedTimePeriod,
    flaggedPages,
    flaggedQuality,
    flaggedKeywords,
    flaggedFrom,
    flaggedDocuments,
    flaggedNoteComments,
  } = flaggedValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...flaggedValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...flaggedValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(flaggedValues);
  }, [flaggedValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        LINKEDIN Flag Details
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the nature for flagging the client?
          </p>
          <div className="text-[#7C7E7F]">
            <ReactSelect
              handleOnChange={handleSelectChange('flaggedReason')}
              options={[
                { value: 'Traffic Drop', label: 'Traffic Drop' },
                { value: 'Ranking Drop', label: 'Ranking Drop' },
                { value: 'Work Quality', label: 'Work Quality' },
                { value: 'Performance', label: 'Performance' },
                { value: 'Content', label: 'Content Issue' },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Did we discover this or did the client flag this?
          </p>
          <div className="text-[#7C7E7F]">
            <ReactSelect
              handleOnChange={handleSelectChange('flaggedFrom')}
              options={[
                { value: 'Flagged by Client', label: 'Client Flagged' },
                {
                  value: 'We are in front of it',
                  label: 'We are in front of it',
                },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
        {flaggedReason === 'Traffic Drop' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What is the extent of the drop (% deficit)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  name="flaggedDeficit"
                  onInput={handleChange('flaggedDeficit')}
                  value={flaggedDeficit}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                When was the last time period that traffic was performing as
                expected?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  name="flaggedTimePeriod"
                  onInput={handleChange('flaggedTimePeriod')}
                  value={flaggedTimePeriod}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                List the main pages you are concerned are dropping in traffic
                (top 3 specific landing pages)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedPages')}
                  value={flaggedPages}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Ranking Drop' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What are the main keyword(s) that are dropping? (1-5 highest
                priority declining keywords)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedKeywords')}
                  value={flaggedKeywords}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Work Quality' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                Please provide either the sales brief or otherwise a short
                statement about what particular aspect of the campaign has been
                underdelivered / not of sufficient quality?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedQuality')}
                  value={flaggedQuality}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                Please link to the document that best represents the work
                quality issue that you are raising
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedDocuments')}
                  value={flaggedDocuments}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Performance' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What is going wrong with the campaign?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedNoteComments')}
                  value={flaggedNoteComments}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

// PROGRAMMATIC FLAGGED EXPORT
export const PROGRAMMATICFlagged = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_programmatic.end_date !== '') {
    var timeleft = Date.parse(client.social_programmatic.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.client.social_programmatic.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [flaggedValues, setValues] = useState({
    type: 'flagged',
    service: 'social_programmatic',
    flaggedReason: '',
    flaggedDeficit: '',
    flaggedTimePeriod: '',
    flaggedPages: '',
    flaggedFrom: '',
    flaggedKeywords: '',
    flaggedQuality: '',
    flaggedDocuments: '',
    flaggedNoteComments: '',
    flaggedTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    flaggedReason,
    flaggedDeficit,
    flaggedTimePeriod,
    flaggedPages,
    flaggedQuality,
    flaggedKeywords,
    flaggedFrom,
    flaggedDocuments,
    flaggedNoteComments,
  } = flaggedValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...flaggedValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...flaggedValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(flaggedValues);
  }, [flaggedValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        PROGRAMMATIC Flag Details
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the nature for flagging the client?
          </p>
          <div className="text-[#7C7E7F]">
            <ReactSelect
              handleOnChange={handleSelectChange('flaggedReason')}
              options={[
                { value: 'Traffic Drop', label: 'Traffic Drop' },
                { value: 'Ranking Drop', label: 'Ranking Drop' },
                { value: 'Work Quality', label: 'Work Quality' },
                { value: 'Performance', label: 'Performance' },
                { value: 'Content', label: 'Content Issue' },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Did we discover this or did the client flag this?
          </p>
          <div className="text-[#7C7E7F]">
            <ReactSelect
              handleOnChange={handleSelectChange('flaggedFrom')}
              options={[
                { value: 'Flagged by Client', label: 'Client Flagged' },
                {
                  value: 'We are in front of it',
                  label: 'We are in front of it',
                },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
        {flaggedReason === 'Traffic Drop' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What is the extent of the drop (% deficit)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  name="flaggedDeficit"
                  onInput={handleChange('flaggedDeficit')}
                  value={flaggedDeficit}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                When was the last time period that traffic was performing as
                expected?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  name="flaggedTimePeriod"
                  onInput={handleChange('flaggedTimePeriod')}
                  value={flaggedTimePeriod}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                List the main pages you are concerned are dropping in traffic
                (top 3 specific landing pages)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedPages')}
                  value={flaggedPages}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Ranking Drop' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What are the main keyword(s) that are dropping? (1-5 highest
                priority declining keywords)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedKeywords')}
                  value={flaggedKeywords}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Work Quality' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                Please provide either the sales brief or otherwise a short
                statement about what particular aspect of the campaign has been
                underdelivered / not of sufficient quality?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedQuality')}
                  value={flaggedQuality}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                Please link to the document that best represents the work
                quality issue that you are raising
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedDocuments')}
                  value={flaggedDocuments}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Performance' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What is going wrong with the campaign?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedNoteComments')}
                  value={flaggedNoteComments}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

// TACTICAL FLAGGED EXPORT
export const TACTICALFlagged = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_tactical.end_date !== '') {
    var timeleft = Date.parse(client.social_tactical.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_tactical.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [flaggedValues, setValues] = useState({
    type: 'flagged',
    service: 'social_tactical',
    flaggedReason: '',
    flaggedDeficit: '',
    flaggedTimePeriod: '',
    flaggedPages: '',
    flaggedFrom: '',
    flaggedKeywords: '',
    flaggedQuality: '',
    flaggedDocuments: '',
    flaggedNoteComments: '',
    flaggedTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    flaggedReason,
    flaggedDeficit,
    flaggedTimePeriod,
    flaggedPages,
    flaggedQuality,
    flaggedKeywords,
    flaggedFrom,
    flaggedDocuments,
    flaggedNoteComments,
  } = flaggedValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...flaggedValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...flaggedValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(flaggedValues);
  }, [flaggedValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        TACTICAL Flag Details
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the nature for flagging the client?
          </p>
          <div className="text-[#7C7E7F]">
            <ReactSelect
              handleOnChange={handleSelectChange('flaggedReason')}
              options={[
                { value: 'Traffic Drop', label: 'Traffic Drop' },
                { value: 'Ranking Drop', label: 'Ranking Drop' },
                { value: 'Work Quality', label: 'Work Quality' },
                { value: 'Performance', label: 'Performance' },
                { value: 'Content', label: 'Content Issue' },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Did we discover this or did the client flag this?
          </p>
          <div className="text-[#7C7E7F]">
            <ReactSelect
              handleOnChange={handleSelectChange('flaggedFrom')}
              options={[
                { value: 'Flagged by Client', label: 'Client Flagged' },
                {
                  value: 'We are in front of it',
                  label: 'We are in front of it',
                },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
        {flaggedReason === 'Traffic Drop' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What is the extent of the drop (% deficit)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  name="flaggedDeficit"
                  onInput={handleChange('flaggedDeficit')}
                  value={flaggedDeficit}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                When was the last time period that traffic was performing as
                expected?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  name="flaggedTimePeriod"
                  onInput={handleChange('flaggedTimePeriod')}
                  value={flaggedTimePeriod}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                List the main pages you are concerned are dropping in traffic
                (top 3 specific landing pages)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedPages')}
                  value={flaggedPages}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Ranking Drop' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What are the main keyword(s) that are dropping? (1-5 highest
                priority declining keywords)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedKeywords')}
                  value={flaggedKeywords}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Work Quality' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                Please provide either the sales brief or otherwise a short
                statement about what particular aspect of the campaign has been
                underdelivered / not of sufficient quality?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedQuality')}
                  value={flaggedQuality}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                Please link to the document that best represents the work
                quality issue that you are raising
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedDocuments')}
                  value={flaggedDocuments}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Performance' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What is going wrong with the campaign?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedNoteComments')}
                  value={flaggedNoteComments}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

// CREATIVE FLAGGED EXPORT
export const CREATIVEFlagged = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_creative.end_date !== '') {
    var timeleft = Date.parse(client.social_creative.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_creative.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [flaggedValues, setValues] = useState({
    type: 'flagged',
    service: 'social_creative',
    flaggedReason: '',
    flaggedDeficit: '',
    flaggedTimePeriod: '',
    flaggedPages: '',
    flaggedFrom: '',
    flaggedKeywords: '',
    flaggedQuality: '',
    flaggedDocuments: '',
    flaggedNoteComments: '',
    flaggedTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    flaggedReason,
    flaggedDeficit,
    flaggedTimePeriod,
    flaggedPages,
    flaggedQuality,
    flaggedKeywords,
    flaggedFrom,
    flaggedDocuments,
    flaggedNoteComments,
  } = flaggedValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...flaggedValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...flaggedValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(flaggedValues);
  }, [flaggedValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        CREATIVE Flag Details
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the nature for flagging the client?
          </p>
          <div className="text-[#7C7E7F]">
            <ReactSelect
              handleOnChange={handleSelectChange('flaggedReason')}
              options={[
                { value: 'Traffic Drop', label: 'Traffic Drop' },
                { value: 'Ranking Drop', label: 'Ranking Drop' },
                { value: 'Work Quality', label: 'Work Quality' },
                { value: 'Performance', label: 'Performance' },
                { value: 'Content', label: 'Content Issue' },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Did we discover this or did the client flag this?
          </p>
          <div className="text-[#7C7E7F]">
            <ReactSelect
              handleOnChange={handleSelectChange('flaggedFrom')}
              options={[
                { value: 'Flagged by Client', label: 'Client Flagged' },
                {
                  value: 'We are in front of it',
                  label: 'We are in front of it',
                },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
        {flaggedReason === 'Traffic Drop' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What is the extent of the drop (% deficit)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  name="flaggedDeficit"
                  onInput={handleChange('flaggedDeficit')}
                  value={flaggedDeficit}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                When was the last time period that traffic was performing as
                expected?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  name="flaggedTimePeriod"
                  onInput={handleChange('flaggedTimePeriod')}
                  value={flaggedTimePeriod}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                List the main pages you are concerned are dropping in traffic
                (top 3 specific landing pages)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedPages')}
                  value={flaggedPages}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Ranking Drop' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What are the main keyword(s) that are dropping? (1-5 highest
                priority declining keywords)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedKeywords')}
                  value={flaggedKeywords}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Work Quality' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                Please provide either the sales brief or otherwise a short
                statement about what particular aspect of the campaign has been
                underdelivered / not of sufficient quality?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedQuality')}
                  value={flaggedQuality}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                Please link to the document that best represents the work
                quality issue that you are raising
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedDocuments')}
                  value={flaggedDocuments}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Performance' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What is going wrong with the campaign?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedNoteComments')}
                  value={flaggedNoteComments}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

// WEBDEV FLAGGED EXPORT
export const WEBDEVFlagged = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.web_maintenance.end_date !== '') {
    var timeleft = Date.parse(client.web_maintenance.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.web_maintenance.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [flaggedValues, setValues] = useState({
    type: 'flagged',
    service: 'web_maintenance',
    flaggedReason: '',
    flaggedDeficit: '',
    flaggedTimePeriod: '',
    flaggedPages: '',
    flaggedFrom: '',
    flaggedKeywords: '',
    flaggedQuality: '',
    flaggedDocuments: '',
    flaggedNoteComments: '',
    flaggedTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    flaggedReason,
    flaggedDeficit,
    flaggedTimePeriod,
    flaggedPages,
    flaggedQuality,
    flaggedKeywords,
    flaggedFrom,
    flaggedDocuments,
    flaggedNoteComments,
  } = flaggedValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...flaggedValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...flaggedValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(flaggedValues);
  }, [flaggedValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Web Maintenance Flag Details
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the nature for flagging the client?
          </p>
          <div className="text-[#7C7E7F]">
            <ReactSelect
              handleOnChange={handleSelectChange('flaggedReason')}
              options={[
                { value: 'Traffic Drop', label: 'Traffic Drop' },
                { value: 'Ranking Drop', label: 'Ranking Drop' },
                { value: 'Work Quality', label: 'Work Quality' },
                { value: 'Performance', label: 'Performance' },
                { value: 'Content', label: 'Content Issue' },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Did we discover this or did the client flag this?
          </p>
          <div className="text-[#7C7E7F]">
            <ReactSelect
              handleOnChange={handleSelectChange('flaggedFrom')}
              options={[
                { value: 'Flagged by Client', label: 'Client Flagged' },
                {
                  value: 'We are in front of it',
                  label: 'We are in front of it',
                },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
        {flaggedReason === 'Traffic Drop' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What is the extent of the drop (% deficit)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  name="flaggedDeficit"
                  onInput={handleChange('flaggedDeficit')}
                  value={flaggedDeficit}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                When was the last time period that traffic was performing as
                expected?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  name="flaggedTimePeriod"
                  onInput={handleChange('flaggedTimePeriod')}
                  value={flaggedTimePeriod}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                List the main pages you are concerned are dropping in traffic
                (top 3 specific landing pages)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedPages')}
                  value={flaggedPages}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Ranking Drop' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What are the main keyword(s) that are dropping? (1-5 highest
                priority declining keywords)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedKeywords')}
                  value={flaggedKeywords}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Work Quality' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                Please provide either the sales brief or otherwise a short
                statement about what particular aspect of the campaign has been
                underdelivered / not of sufficient quality?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedQuality')}
                  value={flaggedQuality}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                Please link to the document that best represents the work
                quality issue that you are raising
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedDocuments')}
                  value={flaggedDocuments}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Performance' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What is going wrong with the campaign?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedNoteComments')}
                  value={flaggedNoteComments}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

// WEBDEV FLAGGED EXPORT
export const WEBHOSTFlagged = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.web_hosting.end_date !== '') {
    var timeleft = Date.parse(client.web_hosting.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.web_hosting.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [flaggedValues, setValues] = useState({
    type: 'flagged',
    service: 'web_hosting',
    flaggedReason: '',
    flaggedDeficit: '',
    flaggedTimePeriod: '',
    flaggedPages: '',
    flaggedFrom: '',
    flaggedKeywords: '',
    flaggedQuality: '',
    flaggedDocuments: '',
    flaggedNoteComments: '',
    flaggedTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    flaggedReason,
    flaggedDeficit,
    flaggedTimePeriod,
    flaggedPages,
    flaggedQuality,
    flaggedKeywords,
    flaggedFrom,
    flaggedDocuments,
    flaggedNoteComments,
  } = flaggedValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...flaggedValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...flaggedValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(flaggedValues);
  }, [flaggedValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Web Hosting Flag Details
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the nature for flagging the client?
          </p>
          <div className="text-[#7C7E7F]">
            <ReactSelect
              handleOnChange={handleSelectChange('flaggedReason')}
              options={[
                { value: 'Traffic Drop', label: 'Traffic Drop' },
                { value: 'Ranking Drop', label: 'Ranking Drop' },
                { value: 'Work Quality', label: 'Work Quality' },
                { value: 'Performance', label: 'Performance' },
                { value: 'Content', label: 'Content Issue' },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Did we discover this or did the client flag this?
          </p>
          <div className="text-[#7C7E7F]">
            <ReactSelect
              handleOnChange={handleSelectChange('flaggedFrom')}
              options={[
                { value: 'Flagged by Client', label: 'Client Flagged' },
                {
                  value: 'We are in front of it',
                  label: 'We are in front of it',
                },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
        {flaggedReason === 'Traffic Drop' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What is the extent of the drop (% deficit)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  name="flaggedDeficit"
                  onInput={handleChange('flaggedDeficit')}
                  value={flaggedDeficit}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                When was the last time period that traffic was performing as
                expected?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  name="flaggedTimePeriod"
                  onInput={handleChange('flaggedTimePeriod')}
                  value={flaggedTimePeriod}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                List the main pages you are concerned are dropping in traffic
                (top 3 specific landing pages)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedPages')}
                  value={flaggedPages}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Ranking Drop' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What are the main keyword(s) that are dropping? (1-5 highest
                priority declining keywords)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedKeywords')}
                  value={flaggedKeywords}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Work Quality' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                Please provide either the sales brief or otherwise a short
                statement about what particular aspect of the campaign has been
                underdelivered / not of sufficient quality?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedQuality')}
                  value={flaggedQuality}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                Please link to the document that best represents the work
                quality issue that you are raising
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedDocuments')}
                  value={flaggedDocuments}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Performance' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What is going wrong with the campaign?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedNoteComments')}
                  value={flaggedNoteComments}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};


// WEB LANDING FLAGGED EXPORT
export const WEBLANDINGFlagged = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.web_landing.end_date !== '') {
    var timeleft = Date.parse(client.web_landing.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.web_landing.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [flaggedValues, setValues] = useState({
    type: 'flagged',
    service: 'web_landing',
    flaggedReason: '',
    flaggedDeficit: '',
    flaggedTimePeriod: '',
    flaggedPages: '',
    flaggedFrom: '',
    flaggedKeywords: '',
    flaggedQuality: '',
    flaggedDocuments: '',
    flaggedNoteComments: '',
    flaggedTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    flaggedReason,
    flaggedDeficit,
    flaggedTimePeriod,
    flaggedPages,
    flaggedQuality,
    flaggedKeywords,
    flaggedFrom,
    flaggedDocuments,
    flaggedNoteComments,
  } = flaggedValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...flaggedValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...flaggedValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(flaggedValues);
  }, [flaggedValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Web Landing Flag Details
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the nature for flagging the client?
          </p>
          <div className="text-[#7C7E7F]">
            <ReactSelect
              handleOnChange={handleSelectChange('flaggedReason')}
              options={[
                { value: 'Traffic Drop', label: 'Traffic Drop' },
                { value: 'Work Quality', label: 'Work Quality' },
                { value: 'Performance', label: 'Performance' },
                { value: 'Content', label: 'Content Issue' },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Did we discover this or did the client flag this?
          </p>
          <div className="text-[#7C7E7F]">
            <ReactSelect
              handleOnChange={handleSelectChange('flaggedFrom')}
              options={[
                { value: 'Flagged by Client', label: 'Client Flagged' },
                {
                  value: 'We are in front of it',
                  label: 'We are in front of it',
                },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
        {flaggedReason === 'Traffic Drop' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What is the extent of the drop (% deficit)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  name="flaggedDeficit"
                  onInput={handleChange('flaggedDeficit')}
                  value={flaggedDeficit}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                When was the last time period that traffic was performing as
                expected?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  name="flaggedTimePeriod"
                  onInput={handleChange('flaggedTimePeriod')}
                  value={flaggedTimePeriod}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                List the main pages you are concerned are dropping in traffic
                (top 3 specific landing pages)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedPages')}
                  value={flaggedPages}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Work Quality' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                Please provide either the sales brief or otherwise a short
                statement about what particular aspect of the campaign has been
                underdelivered / not of sufficient quality?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedQuality')}
                  value={flaggedQuality}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                Please link to the document that best represents the work
                quality issue that you are raising
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedDocuments')}
                  value={flaggedDocuments}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Performance' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What is going wrong with the campaign?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedNoteComments')}
                  value={flaggedNoteComments}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};


// WEB LANDING FLAGGED EXPORT
export const WEBPROJECTFlagged = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.web_project.end_date !== '') {
    var timeleft = Date.parse(client.web_project.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.web_project.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [flaggedValues, setValues] = useState({
    type: 'flagged',
    service: 'web_project',
    flaggedReason: '',
    flaggedDeficit: '',
    flaggedTimePeriod: '',
    flaggedPages: '',
    flaggedFrom: '',
    flaggedKeywords: '',
    flaggedQuality: '',
    flaggedDocuments: '',
    flaggedNoteComments: '',
    flaggedTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    flaggedReason,
    flaggedDeficit,
    flaggedTimePeriod,
    flaggedPages,
    flaggedQuality,
    flaggedKeywords,
    flaggedFrom,
    flaggedDocuments,
    flaggedNoteComments,
  } = flaggedValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...flaggedValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...flaggedValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(flaggedValues);
  }, [flaggedValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Web Project Flag Details
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the nature for flagging the client?
          </p>
          <div className="text-[#7C7E7F]">
            <ReactSelect
              handleOnChange={handleSelectChange('flaggedReason')}
              options={[
                { value: 'Traffic Drop', label: 'Traffic Drop' },
                { value: 'Work Quality', label: 'Work Quality' },
                { value: 'Performance', label: 'Performance' },
                { value: 'Content', label: 'Content Issue' },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Did we discover this or did the client flag this?
          </p>
          <div className="text-[#7C7E7F]">
            <ReactSelect
              handleOnChange={handleSelectChange('flaggedFrom')}
              options={[
                { value: 'Flagged by Client', label: 'Client Flagged' },
                {
                  value: 'We are in front of it',
                  label: 'We are in front of it',
                },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
        {flaggedReason === 'Traffic Drop' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What is the extent of the drop (% deficit)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  name="flaggedDeficit"
                  onInput={handleChange('flaggedDeficit')}
                  value={flaggedDeficit}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                When was the last time period that traffic was performing as
                expected?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  name="flaggedTimePeriod"
                  onInput={handleChange('flaggedTimePeriod')}
                  value={flaggedTimePeriod}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                List the main pages you are concerned are dropping in traffic
                (top 3 specific landing pages)?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedPages')}
                  value={flaggedPages}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Work Quality' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                Please provide either the sales brief or otherwise a short
                statement about what particular aspect of the campaign has been
                underdelivered / not of sufficient quality?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedQuality')}
                  value={flaggedQuality}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                Please link to the document that best represents the work
                quality issue that you are raising
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedDocuments')}
                  value={flaggedDocuments}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {flaggedReason === 'Performance' ? (
          <Fragment>
            <div className="w-[32%]">
              <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                What is going wrong with the campaign?
              </p>
              <div className="text-[#7C7E7F]">
                <input
                  type="text"
                  onInput={handleChange('flaggedNoteComments')}
                  value={flaggedNoteComments}
                  className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
