import { toQueryString } from '../utils/common';
import { requests } from './ApiService';

export const Goal = {
  getGoal: (id) => requests.get(`/api/v1/goals/${id}`),
  getGoals: (data) =>
    requests.get(
      `/api/v1/goals${toQueryString({
        page: data?.page,
        limit: data?.limit,
        source: data?.source,
        search: data?.search,
        client_id: data?.client_id,
        metric: data?.metric,
        name: data?.name,
        status: data?.status,
        frequencyType: data?.frequencyType,
      })}`
    ),
  getGoalsGraphs: (data) =>
    requests.get(
      `/api/v1/goals/graphs${toQueryString({
        page: data?.page,
        limit: data?.limit,
        search: data?.search,
        client_id: data?.client_id,
        metric: data?.metric,
        name: data?.name,
        status: data?.status,
        frequencyType: data?.frequencyType,
      })}`
    ),
  sync: (data) => requests.post('/api/v1/goals/sync', data),
  create: (data) => requests.post('/api/v1/goals', data),
  update: (id, data) => requests.patch(`/api/v1/goals/${id}`, data),
  archive: (id) => requests.delete(`/api/v1/goals/${id}`),
};
