import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PieChart from '../../components/Insights/PieChart/changeHistory';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import useChangeStore from '../../store/useChangeStore';
import { useLocation, useNavigate } from 'react-router-dom';
import Icon from '../../assets/icons/SvgComponent';
import ChangeCounter from '../../components/Report/ChangeCounter';

type ParameterValue = {
  label: string;
  id: string | boolean;
};
type ParameterObject = {
  [key: string]: ParameterValue;
};

const ChangeReports: React.FC = () => {
  const {
    fetchReports,
    fetchHistoryReports,
    changeReportStatus,
    changeHistoryStatus,
  } = useChangeStore((state) => state);

  const [actions, setActions] = useState({
    initState: true,
    dataTitle: 'Pending Changes',
    chartTitle: 'Current Pending',
    dataType: 'all',
    isReportPage: true,
    serviceLines: ['Cancels', 'Pauses'],
    fromDate: moment().subtract(28, 'days').toDate(),
    toDate: moment().toDate(),
  });
  const { initState, dataTitle, fromDate, toDate, chartTitle, isReportPage } =
    actions;
  const [reportData, setReportData] = useState({});
  const [loading, setLoading] = useState<boolean>(true);

  const dataFormatter = (data): any => {
    if (data.data && data.data.length) {
      var changeObj = {
        apply: [],
        cancel: [],
        nonstart: [],
        pause: [],
        stop: [],
        delay: [],
        reduce: [],
        refund: [],
        restart: [],
        flagged: [],
      };

      data.data.map((ch) => {
        switch (ch.changeType) {
          case 'apply':
            changeObj.apply.push(ch);
            break;
          case 'cancel':
            changeObj.cancel.push(ch);
            break;
          case 'nonstart':
            changeObj.nonstart.push(ch);
            break;
          case 'pause':
            changeObj.pause.push(ch);
            break;
          case 'stop':
            changeObj.stop.push(ch);
            break;
          case 'delay':
            changeObj.delay.push(ch);
            break;
          case 'reduce':
            changeObj.reduce.push(ch);
            break;
          case 'refund':
            changeObj.refund.push(ch);
            break;
          case 'restart':
            changeObj.restart.push(ch);
            break;
          case 'flagged':
            changeObj.flagged.push(ch);
            break;
        }
      });
      return changeObj;
    } else {
      return;
    }
  };

  useEffect(() => {
    setLoading(true);
    const filters = {
      search: '',
      page: 1,
    };
    fetchReports(filters).then((res: any) => {
      let formattedChanges = dataFormatter(res);
      setReportData(formattedChanges);
      setLoading(false);
    });
  }, [changeReportStatus, changeHistoryStatus]);

  const handleHistory = (filt) => {
    setLoading(true);
    const filters = {
      dateFrom: fromDate,
      dateTo: toDate,
      search: '',
      page: 1,
    };
    fetchHistoryReports(filters).then((res: any) => {
      let formattedChanges = dataFormatter(res);
      setReportData(formattedChanges);
      setActions({
        ...actions,
        dataTitle: 'Date Range',
        chartTitle: 'Historical Data',
        dataType: 'specific',
      });
      setLoading(false);
    });
  };

  const handleClear = () => {
    setLoading(true);
    const filters = {
      search: '',
      page: 1,
    };
    fetchReports(filters).then((res: any) => {
      let formattedChanges = dataFormatter(res);
      setReportData(formattedChanges);
      setActions({
        ...actions,
        dataTitle: 'Pending Changes',
        chartTitle: 'Current Pending',
      });
      setLoading(false);
    });
  };

  const handleFiltersChange = (type: any, val: any) => {
    if (type === 'fromDate') {
      setActions({ ...actions, fromDate: moment(val).toDate() });
    }
    if (type === 'toDate') {
      setActions({ ...actions, toDate: moment(val).toDate() });
    }
  };

  const getCurrentMonthYear = () => moment().format('DD-MM-YYYY');

  return (
    <div className="bg-[#D2EEFF] p-3 overflow-hidden dark:bg-[#191919] min-h-[93dvh] font-inter change-request-insights">
      <div className="block  md:flex  justify-between items-center bg-white px-4 py-2 dark:bg-dark-tremor-background rounded-lg mb-4">
        <div className="block  md:flex items-center gap-4 dark:bg-dark-tremor-background sticky top-0 z-50 ">
          <div className="z-20 font-inter">
            <label>
              From:
              <DatePicker
                selected={fromDate}
                dateFormat={moment(fromDate).format('DD/MM/YYYY')}
                onChange={(date) => {
                  if (date !== null) {
                    handleFiltersChange('fromDate', date);
                  }
                }}
                maxDate={new Date(getCurrentMonthYear())}
                className="react-datepicker__month-container text-center h-[38px] rounded-lg border border-[#D1D5DB] cursor-pointer myDatePicker dark:bg-[#374151] dark:text-white dark:border-none mt-2 font-inter rounded-lg text-[14px] text-[#525252]"
              />
            </label>
            <label>
              To:
              <DatePicker
                selected={toDate}
                dateFormat={moment(toDate).format('DD/MM/YYYY')}
                onChange={(date) => {
                  if (date !== null) {
                    handleFiltersChange('toDate', date);
                  }
                }}
                maxDate={new Date(getCurrentMonthYear())}
                className="react-datepicker__month-container text-center h-[38px] rounded-lg border border-[#D1D5DB] cursor-pointer myDatePicker dark:bg-[#374151] dark:text-white dark:border-none mt-2 font-inter rounded-lg text-[14px] text-[#525252]"
              />
            </label>
            <button
              onClick={handleHistory}
              className="text-white bg-[#005C9F]  ml-2  hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-16 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            >
              {loading ? (
                <div className="w-full flex flex-col justify-center items-center">
                  <Icon name="Loading" size={20} />
                </div>
              ) : (
                'Get Data'
              )}
            </button>
            <button
              onClick={handleClear}
              className="text-white bg-[#64748b]  ml-2  hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-16 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            >
              {loading ? (
                <div className="w-full flex flex-col justify-center items-center">
                  <Icon name="Loading" size={20} />
                </div>
              ) : (
                'Clear Filters'
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="block md:flex  justify-between  bg-white px-4 py-4 dark:bg-dark-tremor-background rounded-lg mb-4">
        <ChangeCounter
          dataTitle={dataTitle}
          loading={loading}
          stats={reportData}
          initState={initState}
          isReportPage={isReportPage}
        />
      </div>
      <div className="block md:flex  justify-between items-center bg-white p-4 dark:bg-dark-tremor-background rounded-lg mb-4">
        <PieChart data={reportData} title={chartTitle} loading={loading} />
      </div>
    </div>
  );
};

export default ChangeReports;
