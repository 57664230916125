import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import {
  processData,
  formatNumberWithCommas,
  formatNumber,
} from '../../../utils/common';
import AreaCardChart from '../AreaCardChart';
import useGoogleStore from '../../../store/useGoogleStore';

interface IProps {
  retry?: () => void;
}


const MobileView = ({ retry }: IProps) => {
  const { ga4SeoData } = useGoogleStore((state) => state);

  const newUsers = processData(ga4SeoData, 'newUsers');
  const sessions = processData(ga4SeoData, 'sessions');
  const organic_sessions = processData(ga4SeoData, 'organic_sessions');
  const engagedSessions = processData(ga4SeoData, 'engagedSessions');
  const organic_engagedSessions = processData(
    ga4SeoData,
    'organic_engagedSessions'
  );
  const sessionConversionRate = processData(
    ga4SeoData,
    'sessionConversionRate'
  );

  const lastMonthSessionConversionRate =
    Array.isArray(sessionConversionRate) && sessionConversionRate.length > 0
      ? sessionConversionRate[sessionConversionRate.length - 1]?.value
      : undefined;

  const lastMonthEngagedSessions =
    Array.isArray(engagedSessions) && engagedSessions.length > 0
      ? engagedSessions[engagedSessions.length - 1]?.value
      : undefined;

  const lastMonthOrganicEngagedSessions =
    Array.isArray(organic_engagedSessions) && organic_engagedSessions.length > 0
      ? organic_engagedSessions[organic_engagedSessions.length - 1]?.value
      : undefined;

  const lastMonthSessions =
    Array.isArray(sessions) && sessions.length > 0
      ? sessions[sessions.length - 1]?.value
      : undefined;

  const lastMonthOrganicSessions =
    Array.isArray(organic_sessions) && organic_sessions.length > 0
      ? organic_sessions[organic_sessions.length - 1]?.value
      : undefined;

  const lastMonthNewUsers =
    Array.isArray(newUsers) && newUsers.length > 0
      ? newUsers[newUsers.length - 1]?.value
      : undefined;
  return (
    <div className="block sm:hidden mt-4">
      <div className="flex flex-col gap-4">
        <div className="p-0">
          <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
            <SwiperSlide>
              <AreaCardChart
                title={'Session Conversion Rate'}
                chatData={sessionConversionRate}
                category={['Session Conversion Rate']}
                info={
                  ga4SeoData?.sessionConversionRate?.info
                    ? ga4SeoData?.sessionConversionRate?.info
                    : 'Session Conversion Rate'
                }
                prevPeriod={ga4SeoData?.sessionConversionRate?.previous_period}
                previousYear={ga4SeoData?.sessionConversionRate?.previous_year}
                isLoading={ga4SeoData.loading}
                totalValue={formatNumber(lastMonthSessionConversionRate, true)}
                hasPercent={true}
                error={ga4SeoData.error}
                icon="googleAnalytics"
                retryMethod={retry}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title={'Engaged Sessions'}
                chatData={engagedSessions}
                category={['Engaged Sessions']}
                info={
                  ga4SeoData?.engagedSessions?.info
                    ? ga4SeoData?.engagedSessions?.info
                    : 'Engaged Sessions'
                }
                prevPeriod={ga4SeoData?.engagedSessions?.previous_period}
                previousYear={ga4SeoData?.engagedSessions?.previous_year}
                isLoading={ga4SeoData.loading}
                totalValue={formatNumberWithCommas(lastMonthEngagedSessions)}
                error={ga4SeoData.error}
                icon="googleAnalytics"
                retryMethod={retry}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title={'Organic Engaged Sessions'}
                chatData={organic_engagedSessions}
                category={['Organic Engaged Sessions']}
                info={
                  ga4SeoData?.organic_engagedSessions?.info
                    ? ga4SeoData?.organic_engagedSessions?.info
                    : 'Organic Engaged Sessions'
                }
                prevPeriod={
                  ga4SeoData?.organic_engagedSessions?.previous_period
                }
                previousYear={
                  ga4SeoData?.organic_engagedSessions?.previous_year
                }
                isLoading={ga4SeoData.loading}
                totalValue={formatNumberWithCommas(
                  lastMonthOrganicEngagedSessions
                )}
                error={ga4SeoData.error}
                icon="googleAnalytics"
                retryMethod={retry}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title={'Sessions'}
                chatData={sessions}
                category={['Sessions']}
                info={
                  ga4SeoData?.sessions?.info
                    ? ga4SeoData?.sessions?.info
                    : 'Sessions'
                }
                prevPeriod={ga4SeoData?.sessions?.previous_period}
                previousYear={ga4SeoData?.sessions?.previous_year}
                isLoading={ga4SeoData.loading}
                totalValue={formatNumberWithCommas(lastMonthSessions)}
                error={ga4SeoData.error}
                icon="googleAnalytics"
                retryMethod={retry}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title={'Organic Sessions'}
                chatData={organic_sessions}
                category={['Organic Sessions']}
                info={
                  ga4SeoData?.organic_sessions?.info
                    ? ga4SeoData?.organic_sessions?.info
                    : 'Organic Sessions'
                }
                prevPeriod={ga4SeoData?.organic_sessions?.previous_period}
                previousYear={ga4SeoData?.organic_sessions?.previous_year}
                isLoading={ga4SeoData.loading}
                totalValue={formatNumberWithCommas(lastMonthOrganicSessions)}
                error={ga4SeoData.error}
                icon="googleAnalytics"
                retryMethod={retry}
              />
            </SwiperSlide>
            <SwiperSlide>
              <AreaCardChart
                title={'New Users'}
                chatData={newUsers}
                category={['New Users']}
                info={
                  ga4SeoData?.newUsers?.info
                    ? ga4SeoData?.newUsers?.info
                    : 'New Users'
                }
                prevPeriod={ga4SeoData?.newUsers?.previous_period}
                previousYear={ga4SeoData?.newUsers?.previous_year}
                isLoading={ga4SeoData.loading}
                totalValue={formatNumberWithCommas(lastMonthNewUsers)}
                error={ga4SeoData.error}
                icon="googleAnalytics"
                retryMethod={retry}
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default MobileView;
