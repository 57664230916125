import React from 'react';

interface IconProps {
  color?: string;
}

const review: React.FC<IconProps> = ({ color = '#428B6E' }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="check">
      <path
        id="Icon"
        d="M16.6668 5L7.50016 14.1667L3.3335 10"
        stroke="white"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default review;
