export const emailValidation = (email) => {
  const emailRegex =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRegex.test(email)) {
    return '';
  } else {
    return 'Email is not valid';
  }
};

export const domainValidation = (domain) => {
  const domainRegex =
    // eslint-disable-next-line no-useless-escape
    /[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/;
  if (domainRegex.test(domain)) {
    return '';
  } else {
    return 'Domain is not valid';
  }
};

export const passwordValidation = (password, type) => {
  if (password.length >= 8) {
    return '';
  } else {
    return 'Please ensure your password is at least 8 characters long and includes at least one uppercase letter, one lowercase letter, and one special character.';
  }
};
export const nameValidation = (name) => {
  if (name.length > 3) {
    return '';
  } else {
    return 'Name Must be at least 3 characters';
  }
};
