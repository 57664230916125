import React from 'react';

interface Tool02Props {
  color?: string;
  size?: number;
}

const Tool02: React.FC<Tool02Props> = ({ color = 'black', size = 80 }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 6.03809L10.5 10.5381M6 6.03809H3L2 3.03809L3 2.03809L6 3.03809V6.03809ZM19.259 2.7791L16.6314 5.40671C16.2354 5.80273 16.0373 6.00074 15.9632 6.22907C15.8979 6.42991 15.8979 6.64626 15.9632 6.8471C16.0373 7.07543 16.2354 7.27344 16.6314 7.66946L16.8686 7.90671C17.2646 8.30273 17.4627 8.50074 17.691 8.57493C17.8918 8.64019 18.1082 8.64019 18.309 8.57493C18.5373 8.50074 18.7354 8.30273 19.1314 7.90672L21.5893 5.4488C21.854 6.09297 22 6.79848 22 7.53809C22 10.5757 19.5376 13.0381 16.5 13.0381C16.1338 13.0381 15.7759 13.0023 15.4298 12.934C14.9436 12.8381 14.7005 12.7902 14.5532 12.8049C14.3965 12.8205 14.3193 12.844 14.1805 12.9183C14.0499 12.9881 13.919 13.1191 13.657 13.3811L6.5 20.5381C5.67157 21.3665 4.32843 21.3665 3.5 20.5381C2.67157 19.7096 2.67157 18.3665 3.5 17.5381L10.657 10.3811C10.919 10.1191 11.0499 9.98814 11.1198 9.85757C11.1941 9.71876 11.2176 9.64156 11.2332 9.4849C11.2479 9.33754 11.1999 9.09447 11.1041 8.60833C11.0358 8.26214 11 7.90429 11 7.53809C11 4.50052 13.4624 2.03809 16.5 2.03809C17.5055 2.03809 18.448 2.30791 19.259 2.7791ZM12.0001 15.038L17.5 20.538C18.3284 21.3664 19.6716 21.3664 20.5 20.538C21.3284 19.7095 21.3284 18.3664 20.5 17.538L15.9753 13.0134C15.655 12.9831 15.3427 12.9253 15.0408 12.8424C14.6517 12.7356 14.2249 12.8131 13.9397 13.0984L12.0001 15.038Z"
      stroke={color || '#334969'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Tool02;
