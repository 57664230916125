import React from 'react';

interface XcloseProps {
  color?: string;
  size?: number;
}

const Xclose: React.FC<XcloseProps> = ({ color = '#428B6E', size = 22 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="x-close">
      <path
        id="Icon"
        d="M18 6.5L6 18.5M6 6.5L18 18.5"
        stroke={color || '#005C9F'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default Xclose;
