import React from 'react';
import Icon from '../../assets/icons/SvgComponent';
type NoDataProps = {
  message?: string;
};

const NoData: React.FC<NoDataProps> = ({ message = 'No data found!' }) => {
  return (
    <div className="w-full h-[400px] bg-opacity-50  flex items-center justify-center flex-col  ">
      <span className="text-sm sm:text-[20px] mt-2 font-inter dark:text-white">
        {message}
      </span>
      <Icon name="noData" />
    </div>
  );
};

export default NoData;
