import { Grid, Col } from '@tremor/react';
import AreaCardChart from '../../AreaCardChart';
import { formatNumber, formatToCurrency } from '../../../../utils/common';
import MobileView from './MobileView';
import useClientStore from '../../../../store/useClientStore';
import { useRetryFetchFbInsights } from '../../Hooks';

interface IProps {
  spend?: any;
  form_leads?: any;
  website_leads?: any;
  revenue?: any;
  roas?: any;
  dataInfo?: any;
  isLeadGen?: boolean;
  sliceData?: boolean;
}

const General = ({
  spend,
  revenue,
  roas,
  dataInfo,
  form_leads,
  website_leads,
  isLeadGen,
  sliceData,
}: IProps) => {
  const { selectedClient } = useClientStore((state) => state);
  const lastMonthSpend =
    Array.isArray(spend) && spend.length > 0
      ? spend[spend.length - 1]?.value
      : undefined;
  const lastMonthRevenue =
    Array.isArray(revenue) && revenue.length > 0
      ? revenue[revenue.length - 1]?.value
      : undefined;
  const lastMonthRoas =
    Array.isArray(roas) && roas.length > 0
      ? roas[roas.length - 1]?.value
      : undefined;
  const lastFormLeads =
    Array.isArray(form_leads) && form_leads.length > 0
      ? form_leads[form_leads.length - 1]?.value
      : undefined;
  const lastWebsiteLeads =
    Array.isArray(website_leads) && website_leads.length > 0
      ? website_leads[website_leads.length - 1]?.value
      : undefined;

  const retryFetchFbInsights = useRetryFetchFbInsights();

  return (
    <>
      <div className="flex justify-start items-center">
        <h2 className="title-text text-base sm:text-[1.85rem] pb-1 hidden sm:block">
          General Overview
        </h2>
      </div>
      <MobileView
        spend={spend}
        revenue={revenue}
        roas={roas}
        dataInfo={dataInfo}
        isLeadGen={isLeadGen}
        retryMethod={retryFetchFbInsights}
      />
      <div className="hidden sm:block">
        <Grid
          numItems={1}
          numItemsSm={1}
          numItemsMd={2}
          numItemsLg={3}
          className="gap-4"
        >
          {/* <Col className="flex flex-col gap-4 justify-center">
          <ProgressBarCard
            title="Performance Goals"
            subtitle="ROAS "
            data={progressBarData}
            loading={dataInfo.loading}
          />
          <ProgressBarCard
            title="Budget Pacing"
            subtitle="Spend Indicator"
            data={progressBarData}
            loading={dataInfo.loading}
          />
        </Col> */}
          <Col className="shadow-md">
            <AreaCardChart
              title="Ad Spend"
              chatData={spend}
              category={['Ad Spend']}
              info={dataInfo?.spend?.info}
              prevPeriod={dataInfo?.spend?.previous_period}
              previousYear={dataInfo?.spend?.previous_year}
              totalValue={formatToCurrency(lastMonthSpend)}
              isLoading={dataInfo.loading}
              hasDollar
              error={dataInfo.error}
              icon="meta"
              accountInsights={true}
              propertyId={`Ads ID: ${selectedClient?.facebook_ads?.ads_id}`}
              retryMethod={retryFetchFbInsights}
            />
          </Col>
          <Col className="shadow-md">
            <AreaCardChart
              title={!isLeadGen ? 'Revenue' : 'Form Leads'}
              chatData={revenue ? revenue : form_leads}
              category={[`${!isLeadGen ? 'Revenue' : 'Form Leads'}`]}
              info={
                isLeadGen
                  ? dataInfo?.form_leads?.info
                    ? dataInfo?.form_leads?.info
                    : 'Form Leads'
                  : dataInfo?.revenue?.info
                    ? dataInfo?.revenue?.info
                    : 'Revenue'
              }
              prevPeriod={
                revenue
                  ? dataInfo?.revenue?.previous_period
                  : dataInfo?.form_leads?.previous_period
              }
              previousYear={
                revenue
                  ? dataInfo?.revenue?.previous_year
                  : dataInfo?.form_leads?.previous_year
              }
              totalValue={
                revenue
                  ? formatToCurrency(lastMonthRevenue)
                  : formatNumber(lastFormLeads)
              }
              isLoading={dataInfo.loading}
              hasDollar={revenue ? true : false}
              error={dataInfo.error}
              icon="meta"
              accountInsights={true}
              propertyId={`Ads ID: ${selectedClient?.facebook_ads?.ads_id}`}
              retryMethod={retryFetchFbInsights}
            />
          </Col>
          <Col className="shadow-md">
            <AreaCardChart
              title={!isLeadGen ? 'ROAS' : 'Website Leads'}
              chatData={roas ? roas : website_leads}
              category={[`${!isLeadGen ? 'Roas' : 'Website Leads'}`]}
              info={roas ? dataInfo?.roas?.info : dataInfo?.website_leads?.info}
              prevPeriod={
                roas
                  ? dataInfo?.roas?.previous_period
                  : dataInfo?.website_leads?.previous_period
              }
              previousYear={
                roas
                  ? dataInfo?.roas?.previous_year
                  : dataInfo?.website_leads?.previous_year
              }
              isLoading={dataInfo.loading}
              totalValue={
                roas
                  ? formatNumber(lastMonthRoas)
                  : formatNumber(lastWebsiteLeads)
              }
              error={dataInfo.error}
              icon="meta"
              accountInsights={true}
              propertyId={`Ads ID: ${selectedClient?.facebook_ads?.ads_id}`}
              retryMethod={retryFetchFbInsights}
            />
          </Col>
        </Grid>
      </div>
    </>
  );
};

export default General;
