import React, { useState } from 'react';
import { PreviewHeader } from '../Report/styles';
import Icon from '../../../assets/icons/SvgComponent';
import { formatNumber, formatToCurrency } from '../../../utils/common';
import SimonHancock from '../../../assets/images/SimonHancock.png';
interface Props {
  selectedReport: any;
  selectedWeek: any;
  report_type: string;
}
const Metrics: React.FC<Props> = ({
  selectedReport,
  selectedWeek,
  report_type,
}) => {
  const currentMonth = selectedReport?.report?.current_month;
  const lastWeek = selectedReport?.report?.seven_days;
  const prevPeriod = selectedReport?.report?.previous_period;
  const amName = selectedReport?.account_manager;
  const amEmail = selectedReport?.account_manager_email;

  const metricsPPC = [
    {
      label: 'Clicks',
      value: formatNumber(currentMonth?.clicks) || 0,
      lastweek: formatNumber(lastWeek?.clicks) || 0,
      prevPeriod: formatNumber(prevPeriod?.clicks) || 0,
      raw1: lastWeek?.clicks || 0,
      raw2: prevPeriod?.clicks || 0,
    },
    {
      label: 'Impressions',
      value: formatNumber(currentMonth?.impressions) || 0,
      lastweek: formatNumber(lastWeek?.impressions) || 0,
      prevPeriod: formatNumber(prevPeriod?.impressions) || 0,
      raw1: lastWeek?.impressions || 0,
      raw2: prevPeriod?.impressions || 0,
    },
    {
      label: 'Click-Through Rate',
      value: formatNumber(currentMonth?.ctr, true) || 0,
      lastweek: formatNumber(lastWeek?.ctr, true) || 0,
      prevPeriod: formatNumber(prevPeriod?.ctr, true) || 0,
      raw1: lastWeek?.ctr || 0,
      raw2: prevPeriod?.ctr || 0,
    },
    {
      label: 'Average Cost per Click',
      value: formatToCurrency(currentMonth?.average_cpc) || 0,
      lastweek: formatToCurrency(lastWeek?.average_cpc) || 0,
      prevPeriod: formatToCurrency(prevPeriod?.average_cpc) || 0,
      raw1: lastWeek?.average_cpc || 0,
      raw2: prevPeriod?.average_cpc || 0,
    },
    {
      label: 'Spend',
      value: formatToCurrency(currentMonth?.cost_micros) || 0,
      lastweek: formatToCurrency(lastWeek?.cost_micros) || 0,
      prevPeriod: formatToCurrency(prevPeriod?.cost_micros) || 0,
      raw1: lastWeek?.cost_micros || 0,
      raw2: prevPeriod?.cost_micros || 0,
    },
    {
      label: 'Conversions',
      value: formatNumber(currentMonth?.conversions) || 0,
      lastweek: formatNumber(lastWeek?.conversions) || 0,
      prevPeriod: formatNumber(prevPeriod?.conversions) || 0,
      raw1: lastWeek?.conversions || 0,
      raw2: prevPeriod?.conversions || 0,
    },
    {
      label: 'Costs per Conversion',
      value: formatToCurrency(currentMonth?.cost_per_conversion) || 0,
      lastweek: formatToCurrency(lastWeek?.cost_per_conversion) || 0,
      prevPeriod: formatToCurrency(prevPeriod?.cost_per_conversion) || 0,
      raw1: lastWeek?.cost_per_conversion || 0,
      raw2: prevPeriod?.cost_per_conversion || 0,
    },
    {
      label: 'Conversion Rate',
      value: formatNumber(currentMonth?.conversion_rate, true) || 0,
      lastweek: formatNumber(lastWeek?.conversion_rate, true) || 0,
      prevPeriod: formatNumber(prevPeriod?.conversion_rate, true) || 0,
      raw1: lastWeek?.conversion_rate || 0,
      raw2: prevPeriod?.conversion_rate || 0,
    },
    {
      label: 'Conversion Value',
      value: formatToCurrency(currentMonth?.conversions_value) || 0,
      lastweek: formatToCurrency(lastWeek?.conversions_value) || 0,
      prevPeriod: formatToCurrency(prevPeriod?.conversions_value) || 0,
      raw1: lastWeek?.conversions_value || 0,
      raw2: prevPeriod?.conversions_value || 0,
    },
    {
      label: 'Return on Ad Spend',
      value: formatToCurrency(currentMonth?.roas) || 0,
      lastweek: formatToCurrency(lastWeek?.roas) || 0,
      prevPeriod: formatToCurrency(prevPeriod?.roas) || 0,
      raw1: lastWeek?.roas || 0,
      raw2: prevPeriod?.roas || 0,
    },
  ];

  const metricsSEO = [
    {
      label: 'Clicks',
      value: formatNumber(currentMonth?.clicks) || 0,
      lastweek: formatNumber(lastWeek?.clicks) || 0,
      prevPeriod: formatNumber(prevPeriod?.clicks) || 0,
      raw1: lastWeek?.clicks || 0,
      raw2: prevPeriod?.clicks || 0,
    },
    {
      label: 'Impressions',
      value: formatNumber(currentMonth?.impressions) || 0,
      lastweek: formatNumber(lastWeek?.impressions) || 0,
      prevPeriod: formatNumber(prevPeriod?.impressions) || 0,
      raw1: lastWeek?.impressions || 0,
      raw2: prevPeriod?.impressions || 0,
    },
    {
      label: 'Click-Through Rate',
      value: formatNumber(currentMonth?.ctr, true) || 0,
      lastweek: formatNumber(lastWeek?.ctr, true) || 0,
      prevPeriod: formatNumber(prevPeriod?.ctr, true) || 0,
      raw1: lastWeek?.ctr || 0,
      raw2: prevPeriod?.ctr || 0,
    },
    {
      label: 'New Users',
      value: formatNumber(currentMonth?.newUsers) || 0,
      lastweek: formatNumber(lastWeek?.newUsers) || 0,
      prevPeriod: formatNumber(prevPeriod?.newUsers) || 0,
      raw1: lastWeek?.newUsers || 0,
      raw2: prevPeriod?.newUsers || 0,
    },
    {
      label: 'Organic Users',
      value: formatNumber(currentMonth?.organic_newUsers) || 0,
      lastweek: formatNumber(lastWeek?.organic_newUsers) || 0,
      prevPeriod: formatNumber(prevPeriod?.organic_newUsers) || 0,
      raw1: lastWeek?.organic_newUsers || 0,
      raw2: prevPeriod?.organic_newUsers || 0,
    },
    {
      label: 'Organic Sessions',
      value: formatNumber(currentMonth?.organic_sessions) || 0,
      lastweek: formatNumber(lastWeek?.organic_sessions) || 0,
      prevPeriod: formatNumber(prevPeriod?.organic_sessions) || 0,
      raw1: lastWeek?.organic_sessions || 0,
      raw2: prevPeriod?.organic_sessions || 0,
    },
    {
      label: 'Organic Engaged Sessions',
      value: formatNumber(currentMonth?.organic_engagedSessions) || 0,
      lastweek: formatNumber(lastWeek?.organic_engagedSessions) || 0,
      prevPeriod: formatNumber(prevPeriod?.organic_engagedSessions) || 0,
      raw1: lastWeek?.organic_engagedSessions || 0,
      raw2: prevPeriod?.organic_engagedSessions || 0,
    },
    {
      label: 'Organic Conversions',
      value: formatNumber(currentMonth?.organic_conversions) || 0,
      lastweek: formatNumber(lastWeek?.organic_conversions) || 0,
      prevPeriod: formatNumber(prevPeriod?.organic_conversions) || 0,
      raw1: lastWeek?.organic_conversions || 0,
      raw2: prevPeriod?.organic_conversions || 0,
    },
    {
      label: 'Organic Conversions Rate',
      value: formatNumber(currentMonth?.organic_conversions_rate) || 0,
      lastweek: formatNumber(lastWeek?.organic_conversions_rate) || 0,
      prevPeriod: formatNumber(prevPeriod?.organic_conversions_rate) || 0,
      raw1: lastWeek?.organic_conversions_rate || 0,
      raw2: prevPeriod?.organic_conversions_rate || 0,
    },
    {
      label: 'Domain Rating',
      value: formatNumber(currentMonth?.domain_rating) || 0,
      lastweek: formatNumber(lastWeek?.domain_rating) || 0,
      prevPeriod: formatNumber(prevPeriod?.domain_rating) || 0,
      raw1: lastWeek?.domain_rating || 0,
      raw2: prevPeriod?.domain_rating || 0,
    },
  ];

  const metrics = report_type === 'ppc_google' ? metricsPPC : metricsSEO;
  const budgets = [
    {
      label: 'Monthly Budget',
      value: formatToCurrency(selectedReport?.report?.monthly_budget) || 0,
      subText: 'Allocated monthly limit for advertising expenses',
    },
    {
      label: 'Spend to Date',
      value: formatToCurrency(selectedReport?.report?.spend_to_date) || 0,
      subText: 'The amount of money spent on advertising up to today.',
    },
    // {
    //   label: "Budget to Date",
    //   value: formatToCurrency(selectedReport?.report?.budget_to_date) || 0,
    //   subText:
    //     "Today's cumulative advertising spend based on a daily & even budget distribution.",
    // },
    // {
    //   label: "Budget % Difference",
    //   value: formatNumber(selectedReport?.report?.budget_difference, true) || 0,
    //   subText: "Percentage variance from the ad budget.",
    // },
    {
      label: ' % of Budget Spent',
      value: formatNumber(selectedReport?.report?.budget_vs_spend, true) || 0,
      subText: 'Percentage of budget spent to date.',
    },
  ];

  const getPercentage = (value: any, prevPeriod: any, name: string) => {
    const numValue = parseFloat(value);
    const numPrevPeriod = parseFloat(prevPeriod);

    // Check for invalid input or divide by zero scenario
    if (isNaN(numValue) || isNaN(numPrevPeriod) || numPrevPeriod === 0) {
      return '0';
    }

    let percentageChange = ((numValue - numPrevPeriod) / numPrevPeriod) * 100;

    // Invert the percentage for specific names
    if (name === 'Average Cost per Click' || name === 'Cost per Conversion') {
      percentageChange = -percentageChange;
    }

    const result = percentageChange;
    return isNaN(result) ? '0' : result.toFixed(2);
  };

  return (
    <div
      className="w-full bg-white pt-6 h-full overflow-y-auto scrollbar-none"
      style={{ maxHeight: '88vh' }}
    >
      <PreviewHeader>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        ></div>
      </PreviewHeader>
      <div className="mt-4 sm:px-2">
        <span className="text-lg font-inter font-semibold text-[#001C44]">
          Reporting Period : {selectedWeek.label} - {selectedReport?.domain}
        </span>
      </div>
      <div className="my-4 sm:px-2">
        <p className="font-inter text-sm font-light">
          We are pleased to present you with our weekly{' '}
          {report_type === 'ppc_google' ? 'Google Ads' : 'Google SEO'}{' '}
          performance report, summarising the results of your advertising
          campaign for the last seven days and providing an overview of the
          month-to-date performance.
        </p>
        <p className="font-inter text-sm font-light mt-2">
          This report aims to provide a clear and concise overview of the key
          metrics and insights that matter most to your campaign's success.
        </p>
      </div>
      {report_type === 'ppc_google' && (
        <div className="flex justify-between p-2">
          <span className="font-inter font-semibold text-lg mt-2 text-[#001C44]">
            Monthly Budget & Spend Summary
          </span>
        </div>
      )}
      {/* Metrics Grid */}
      <div className="flex flex-col mt-2 sm:p-2">
        {report_type === 'ppc_google' && (
          <div className="grid grid-cols-2 gap-2 md:grid-cols-3 lg:grid-cols-3 bg-[#F3F7FA] rounded-lg px-2 py-4 mb-8">
            {budgets.map((metric, index) => {
              return (
                <div className="flex flex-col gap-2 p-3" key={index}>
                  <div className="">
                    <p className="text-sm text-[#001129] font-semibold font-inter mb-2">
                      {metric.label}
                    </p>
                    <p className="font-inter font-semibold text-[26px] overflow-hidden text-[#337DB2]">
                      {metric.value}
                    </p>
                  </div>
                  <div className="flex w-full">
                    <span className="font-inter text-xs text-[#001129]">
                      {metric?.subText}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <div className="sm:p-6 bg-[#FAFAFA] rounded-lg">
          <span className="font-inter text-lg font-semibold text-[#001C44]">
            Weekly {report_type === 'ppc_google' ? 'Ads' : 'SEO'} Performance
          </span>
          <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-3 mt-5">
            {metrics.map((metric, index) => {
              let isNegative = Number(metric?.raw1) < Number(metric?.raw2);
              if (
                metric.label === 'Average Cost per Click' ||
                metric.label === 'Costs per Conversion'
              )
                isNegative = !isNegative;
              if (
                (metric.label === 'Return on Ad Spend' &&
                  metric.value === '0') ||
                (metric.label === 'Conversion Value' && metric.value === '0')
              )
                return null;
              return (
                <div
                  className="border border-[#E6EBF0] h-40 flex flex-col rounded-lg pb-1 shadow-md"
                  key={index}
                >
                  <div className="mt-4">
                    <p className="ml-5 mt-2 text-sm text-[#7C7E7F] font-inter">
                      {metric.label}
                    </p>
                    <div className="flex">
                      <p className="font-bold text-3xl ml-5 overflow-hidden text-[#337DB2]">
                        {metric.lastweek}
                      </p>
                    </div>
                  </div>

                  <div className="flex w-full mt-0 ml-2 px-4">
                    <span className="mr-1 font-inter text-xs text-[#7C7E7F]">
                      Prev Period (7 days)
                    </span>
                  </div>
                  <div className="mt-0 ml-2 flex px-4">
                    <span className="font-inter text-xs text-[#475569] mr-1">
                      {metric?.prevPeriod}
                    </span>
                    <span
                      style={{ color: isNegative ? 'red' : 'green' }}
                      className="font-inter text-xs text-[#475569]"
                    >
                      ({' '}
                      {getPercentage(metric?.raw1, metric?.raw2, metric.label)}%
                      )
                    </span>
                    <div className="ml-1">
                      {isNegative ? (
                        <Icon name="trendDown" color="#FF0000" />
                      ) : (
                        <Icon name="trendUp" color="#428B6E" />
                      )}
                    </div>
                  </div>

                  <div className="flex w-full px-4 mt-3 ml-1">
                    <span className="mr-1 font-inter text-xs sm:text-sm text-[#7C7E7F]">
                      Month to date:
                    </span>
                    <span className="font-inter text-xs sm:text-sm text-[#475569]">
                      {metric?.value}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="w-full flex flex-col justify-center items-center mt-14 font-inter">
          <p className="mb-4">
            For a detailed view of your performance, visit your{' '}
            <span className="text-[#005C9F]">Gurulytics dashboard. </span>
          </p>
          <p className="mb-2">
            For adjustments to your budget, please email your Account Manager:
          </p>
          {amName && (
            <p>
              {amName} at{' '}
              <span className="text-[#005C9F] border-b border-[#005C9F]">
                {amEmail}
              </span>
            </p>
          )}
        </div>
        <div className="w-full flex flex-col justify-center items-center my-24 font-inter gap-4">
          <div>
            <img src={SimonHancock} alt="DanielAndrawes" width={80} />
          </div>
          <div>
            <p>Kind regards,</p>
          </div>
          <div>
            <p>Simon Hancock</p>
          </div>
          <div>
            <span className="text-[#005C9F]">Chief Customer Officer</span>
          </div>
          <div className="flex gap-2 items-center">
            <div className="w-8 h-8 rounded-full bg-[#E6EFF5] flex justify-center items-center">
              <Icon name="PhoneCall" />
            </div>
            <p>1300 433 071</p>
          </div>
          <div className="flex gap-2 items-center">
            <div className="w-8 h-8 rounded-full bg-[#E6EFF5] flex justify-center items-center">
              <Icon name="Mail04" />
            </div>
            <p className="text-[#005C9F]">simon@onlinemarketinggurus.com.au</p>
          </div>
          <div className="flex gap-2 items-center">
            <div className="w-8 h-8  flex justify-center items-center">
              <Icon name="Twitter" />
            </div>
            <div className="w-8 h-8  flex justify-center items-center">
              <Icon name="Facebook" />
            </div>
            <div className="w-8 h-8 flex justify-center items-center">
              <Icon name="Linkedin01" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Metrics;
