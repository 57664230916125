import { useState } from 'react';
import { Wrapper, DropDownList, Line } from './styles';
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from 'react-icons/md';

const DropDown = ({ styles, items, selected, onSelect, disabled, icon }) => {
  const [open, setOpen] = useState(false);
  const selectItem = (value) => {
    setOpen(false);
    onSelect(value);
  };
  return (
    <Wrapper
      disabled={disabled}
      onClick={() => setOpen(!open)}
      styles={styles}
      tabIndex={-1}
      onBlur={() => setOpen(false)}
    >
      {icon && (
        <>
          {' '}
          <div>{icon}</div>
          {icon ? <Line /> : null}
        </>
      )}
      <span>{selected} </span>
      {!open ? (
        <MdOutlineKeyboardArrowDown
          color="#a0a0a0"
          size={18}
          style={{ marginLeft: 5 }}
        />
      ) : (
        <MdOutlineKeyboardArrowUp
          color="#a0a0a0"
          size={18}
          style={{ marginLeft: 5 }}
        />
      )}

      {open && (
        <DropDownList styles={styles} role="listbox">
          {items.map((item, idx) => (
            <span role="option" key={idx} onClick={() => selectItem(item)}>
              {item.label}
            </span>
          ))}
        </DropDownList>
      )}
    </Wrapper>
  );
};
export default DropDown;
