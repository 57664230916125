import React from 'react';

interface LockProps {
  color?: string;
}

const Lock: React.FC<LockProps> = ({ color = '#428B6E' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
  >
    <path
      d="M19.8334 11.6667V9.33333C19.8334 6.11167 17.2217 3.5 14 3.5C10.7784 3.5 8.16669 6.11167 8.16669 9.33333V11.6667M14 16.9167V19.25M10.2667 24.5H17.7334C19.6935 24.5 20.6736 24.5 21.4223 24.1185C22.0809 23.783 22.6163 23.2475 22.9519 22.589C23.3334 21.8403 23.3334 20.8602 23.3334 18.9V17.2667C23.3334 15.3065 23.3334 14.3264 22.9519 13.5777C22.6163 12.9191 22.0809 12.3837 21.4223 12.0481C20.6736 11.6667 19.6935 11.6667 17.7334 11.6667H10.2667C8.3065 11.6667 7.32641 11.6667 6.57772 12.0481C5.91915 12.3837 5.38372 12.9191 5.04816 13.5777C4.66669 14.3264 4.66669 15.3065 4.66669 17.2667V18.9C4.66669 20.8602 4.66669 21.8403 5.04816 22.589C5.38372 23.2475 5.91915 23.783 6.57772 24.1185C7.32641 24.5 8.3065 24.5 10.2667 24.5Z"
      stroke="#001C44"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Lock;
