import { useEffect } from 'react';
import { Container, Wrapper, Link } from './styles';

const Pagination = ({ totalPages, page, setPage }) => {
  useEffect(() => {}, [totalPages, page]);

  const pages = [...Array(totalPages).keys()].map((num) => num + 1);
  const start =
    totalPages <= 10 ? 0 : totalPages - page > 9 ? page - 1 : totalPages - 10;
  const end =
    totalPages <= 10
      ? totalPages
      : totalPages - page > 9
        ? page + 9
        : totalPages - 1;
  const handlePreviousPage = () => {
    setPage(page - 1);
    if (page === 1) {
      setPage(page);
    }
  };

  const handleNextPage = () => {
    if (page >= totalPages) {
      return;
    }
    setPage(page + 1);
    page(page);
  };

  const handleLastPage = () => {
    setPage(totalPages);
  };

  if (totalPages <= 1) return null;
  return (
    <Wrapper>
      <Container>
        <Link inactive={page === 1} onClick={() => handlePreviousPage()}>
          &laquo;
        </Link>
        {page === totalPages && totalPages > 10 && (
          <>
            <Link active={page === 1} onClick={() => setPage(1)}>
              1
            </Link>
            <Link style={{ cursor: 'default' }}>...</Link>
          </>
        )}
        {pages.slice(start, end).map((num) => (
          <Link key={num} active={num === page} onClick={() => setPage(num)}>
            {num}
          </Link>
        ))}
        {totalPages > 10 && (
          <>
            <Link style={{ cursor: 'default' }}>...</Link>
            <Link active={totalPages === page} onClick={() => handleLastPage()}>
              {totalPages}
            </Link>
          </>
        )}
        <Link inactive={page === totalPages} onClick={() => handleNextPage()}>
          &raquo;
        </Link>
      </Container>
    </Wrapper>
  );
};

export default Pagination;
