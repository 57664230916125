import React from 'react';

type HProps = {
  text: string;
  lineColorLight?: string;
  textColor?: string;
  fontSize?: string;
  archivo?: boolean;
};

const HeaderTitle: React.FC<HProps> = ({
  text,
  lineColorLight,
  textColor,
  fontSize,
  archivo,
}) => {
  return (
    <div className="relative h-fit w-fit">
      <p
        className={`relative ${fontSize ? fontSize : 'text-[24px]'} z-[2] font-[800] ${textColor ? textColor : 'text-[#000]'} ${archivo ? 'font-archivo' : 'font-archivo'} dark:text-white`}
      >
        {text}
      </p>
      {/* <div className={`absolute top-[10px] ${fontSize === 'text-xl' ? 'p-1' : 'p-2'} w-full ${lineColorLight ? lineColorLight :'bg-[#ADF8FF]'} z-[1]`} /> */}
    </div>
  );
};

export default HeaderTitle;
