import { requests } from "./ApiService";

export const Kpis = {
  getKpis: (filters) =>
    requests.get(
      `/api/v1/kpis?search=${filters.search}&reportTo=${filters.reportTo}&reportFrom=${filters.reportFrom}&role=${filters.role}&change_type=${filters.change_type}&service_line=${filters.service_line}`
    ),

  getKpiStaff: (filters) =>
    requests.get(
      `/api/v1/kpis-staff?search=${filters}`
    ),
};
