import React from 'react';

interface BingProps {
  color?: string;
}

const Bing: React.FC<BingProps> = ({ color = '#428B6E' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
  >
    <path
      d="M6.33301 2.5L11.926 4.46875V24.1562L19.8045 19.608L15.9423 17.7968L13.5054 11.7312L25.9181 16.0923V22.4325L11.9304 30.5L6.33301 27.3867V2.5Z"
      fill="white"
    />
  </svg>
);

export default Bing;
