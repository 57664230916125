import React from 'react';

interface IProps {
  color?: string;
  size?: number;
}

const LinksLookup: React.FC<IProps> = ({ color = '#405573', size = 17 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4688 13C10.75 12.4375 11 11.7812 11.1562 11.0312H9.5L9.9375 9.53125H11.4062C11.4688 9.03125 11.5 8.53125 11.5 8.03125C11.5 7.5 11.4688 7 11.4062 6.53125H6.5625C6.53125 6.78125 6.53125 7.0625 6.5 7.34375L5 7.78125C5 7.34375 5.03125 6.9375 5.0625 6.5H2.65625C2.5625 7 2.5 7.5 2.5 8C2.5 8.1875 2.5 8.34375 2.5 8.53125L1.03125 8.96875C1 8.65625 1 8.34375 1 8C1 3.59375 4.5625 0 9 0C13.4062 0 17 3.59375 17 8.03125C17 12.4375 13.4062 16.0312 9 16.0312C8.65625 16.0312 8.34375 16 8.03125 15.9688L8.53125 14.3438C8.71875 14.4688 8.875 14.5 9 14.5C9.21875 14.5 9.84375 14.2812 10.4688 13ZM7.5 3.03125C7.21875 3.59375 7 4.25 6.8125 5H11.1562C11 4.25 10.75 3.59375 10.4688 3.03125C9.84375 1.75 9.21875 1.5 9 1.5C8.75 1.5 8.15625 1.75 7.5 3.03125ZM13 8C13 8.53125 12.9688 9.03125 12.9062 9.5H15.3125C15.4375 9.03125 15.5 8.53125 15.5 8C15.5 7.5 15.4375 7 15.3125 6.5H12.9062C12.9688 7 13 7.5 13 8ZM12.6875 5H14.75C14.0938 3.71875 13 2.6875 11.6875 2.09375C12.125 2.90625 12.4688 3.90625 12.6875 5ZM3.21875 5H5.28125C5.5 3.90625 5.84375 2.90625 6.28125 2.09375C4.96875 2.6875 3.875 3.71875 3.21875 5ZM12.6875 11C12.4688 12.125 12.125 13.125 11.6875 13.9375C13 13.3438 14.0938 12.3125 14.75 11H12.6875ZM2.21875 9.65625L8.59375 7.78125C9 7.65625 9.34375 8.03125 9.21875 8.40625L7.34375 14.8125C7.21875 15.2188 6.625 15.2812 6.40625 14.875L5.53125 13.0938C5.5 13.0312 5.46875 13 5.4375 12.9688L2.6875 15.7188C2.3125 16.125 1.65625 16.125 1.28125 15.7188C0.875 15.3438 0.875 14.6875 1.28125 14.3125L4.03125 11.5625C4 11.5312 3.96875 11.5 3.90625 11.4688L2.125 10.5938C1.71875 10.375 1.78125 9.78125 2.21875 9.65625Z"
      fill={color}
    />
  </svg>
);

export default LinksLookup;