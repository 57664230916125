import React from 'react';
import { useLocation } from 'react-router-dom';
import PageHeader from '../../components/Dashboard/PageHeader';
import useAuthStore from '../../store/useAuthStore';
import { useEffect, useState } from 'react';
import useClientStore from '../../store/useClientStore';
import MyDetails from '../../components/Settings/myDetails';
import ProfileNotification from '../../components/Settings/profileNotification';
import Team from '../../components/Settings/team';
import Billing from '../../components/Settings/billing';
import Services from '../../components/Services';
import useUserStore from '../../store/useUserStore';
const Settings: React.FC = () => {
  const location = useLocation();
  const { fetchClient } = useClientStore((state) => state);
  const { currentUser, selectedDomain } = useAuthStore((state) => state);
  const { fetchUser } = useUserStore((state) => state);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (selectedDomain?.id) {
      fetchClient(selectedDomain?.id);
    }
    fetchUser(currentUser?.id);
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted) {
      fetchClient(selectedDomain?.id);
    }
  }, [selectedDomain.id]);

  let ContentComponent;
  location.pathname === '/settings/my-details'
    ? (ContentComponent = MyDetails)
    : location.pathname === '/settings/profile-notification'
      ? (ContentComponent = ProfileNotification)
      : location.pathname === '/settings/team'
        ? (ContentComponent = Team)
        : location.pathname === '/settings/billing'
          ? (ContentComponent = Billing)
          : location.pathname === '/settings/services'
            ? (ContentComponent = Services)
            : (ContentComponent = null);

  let subtitle;
  location.pathname === '/settings/my-details'
    ? (subtitle = 'Update your photo and personal details here.')
    : location.pathname === '/settings/Profile-notification'
      ? (subtitle = 'Update your company photo and details here.')
      : location.pathname === '/settings/team'
        ? (subtitle =
            'Get your projects up and running faster by inviting your team to collaborate.')
        : location.pathname === '/settings/services'
          ? (subtitle = `      Elevate Your Brand's Online Presence with Data-Driven Digital
        Marketing Services.`)
          : (subtitle = '');

  const capitalizeFirstLetter = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1);
  const title = capitalizeFirstLetter(
    location.pathname
      .replace('settings/', '')
      .replace('/', '')
      .replace('-', ' ')
  );

  return (
    <div className="flex flex-col px-2 sm:px-12  bg-[#D2EEFF] dark:bg-[#0C111D]">
      <PageHeader
        title={title}
        subtitle={subtitle}
        domains={currentUser.clients}
        hideDate
      />
      {ContentComponent && location.pathname === '/settings/services' ? (
        <div className="mt-2">
          <ContentComponent />
        </div>
      ) : (
        ContentComponent && <ContentComponent />
      )}
    </div>
  );
};

export default Settings;
