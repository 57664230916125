import React from 'react';

interface ArrowRightProps {
  color?: string;
  size?: number;
}

const ArrowRight: React.FC<ArrowRightProps> = ({
  color = 'black',
  size = '20',
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.16675 10.0003H15.8334M15.8334 10.0003L10.0001 4.16699M15.8334 10.0003L10.0001 15.8337"
      stroke={color || '#334969'}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowRight;
