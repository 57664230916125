import Select from 'react-select';
import { Trash, Edit, Mail, Edit05 } from '../../assets/icons';
import { getInitials, getRoleName } from '../../utils';
import { useState, useMemo } from 'react';
import AnalyticsTable from '../AnalyticsSections/AnalyticsTable';
import Icon from '../../assets/icons/SvgComponent';

import { Input } from './myDetails';
import useClientStore from '../../store/useClientStore';
import { FadeLoader } from 'react-spinners';
import { colors } from '../../utils/settings';
import EditModal from './editUserModal';
import CustomModal from '../CustomModal';
import UserModal from './userModal';
import Toggle from '../ToggleV1';
import ConfirmationModal from './confirmationModal';
import useUserStore from '../../store/useUserStore';
import useAuthStore from '../../store/useAuthStore';
import TanStackTable from '../TanStackTable';
import EditUserModal from './editUserModal';
interface rolesOption {
  label: string;
  value: string;
}

const roles: rolesOption[] = [
  { label: 'Staff', value: 'staf' },
  { label: 'Owner', value: 'own' },
];
const rolesStyles = {
  control: (provided: any) => ({
    ...provided,
    width: '100%',
    borderRadius: '0.5rem',
    padding: '2px',
    border: '1px solid #808EA2',
    marginTop: '4px',
  }),
};

type FormState = {
  email: string;
  role: { label: string; value: string };
};
interface UserSelectedType {
  id?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  status?: string;
  role?: string;
}
type ModalState = {
  id?: number;
  user?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  birthdate?: string;
  phone?: string;
  role?: string;
  gurulytics_role?: string;
  clients?: any;
  monthly_insights_report?: boolean;
  status?: string;
};
const initialState: ModalState = {
  id: 0,
  user: '',
  first_name: '',
  last_name: '',
  email: '',
  birthdate: '',
  phone: '',
};
const Team: React.FC = () => {
  const [form, setForm] = useState<FormState>({
    email: '',
    role: { label: '', value: '' },
  });
  const { selectedClient, fetchClient } = useClientStore((state) => state);
  const { selectedDomain } = useAuthStore((state) => state);
  const { deleteUsers, createUser, fetchUser } = useUserStore((state) => state);
  const [error, setError] = useState<boolean>(false);
  const [isEditModal, setEditModal] = useState(false);
  const [isConfirmModal, setConfirmModall] = useState(false);
  const [statusToggle, setStatusToggle] = useState<boolean>(false);
  const [userSelected, setUserSelected] = useState<UserSelectedType | null>(
    null
  );
  let users = selectedClient?.users;
  users = users?.filter((user: any) => ['stf', 'own'].includes(user.role));
  //modal states
  const [modal, setModal] = useState<ModalState>(initialState);
  const { updateUser } = useUserStore((state) => state);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleRole = (selectedOption: rolesOption | null) => {
    if (selectedOption) {
      // console.log(`Selected: ${selectedOption.label}`);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(false);
    const { name, value } = event.target;
    setForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    !form?.email && setError(true);
    setForm({ email: '', role: { label: 'Staff', value: 'staff' } });
  };

  const handleEdit = (user: ModalState) => {
    setModal(user);
    setErrorMessage('');
    setSuccessMessage('');
    setEditModal(true);
  };
  const handleDelete = (user: UserSelectedType) => {
    setUserSelected(user);
    setConfirmModall(true);
  };

  const handleToggle = (user: any) => {
    updateUser(user.id, {
      status: user.status === 'active' ? 'inactive' : 'active',
    })
      .then((res: any) => {
        fetchClient(selectedClient?.id, true);
        setSuccessMessage('Updated successfully!');
        setIsLoading(false);
      })
      .catch((e: any) => {
        setErrorMessage(e.message);
        setIsLoading(false);
      });
  };
  const handleConfirm = (user: UserSelectedType) => {
    setUserSelected(user);
    setConfirmModall(false);
    deleteUsers({ ids: user?.id });
  };

  // handle modal
  const validateModalForm = (data: ModalState): boolean => {
    if (!data.first_name || !data.last_name || !data.email || !data.role) {
      setErrorMessage('All fields are required!');
      return false;
    }
    setErrorMessage(''); // Clear any previous error messages
    return true;
  };
  const handleModalSubmit = (form: ModalState) => {
    let data: ModalState = {
      first_name: form?.first_name,
      last_name: form?.last_name,
      email: form?.email,
      phone: form?.phone,
      monthly_insights_report: true,
      role: form?.role,
      gurulytics_role: form?.role,
      status: 'active',
    };
    if (form.id !== 0 && validateModalForm(data)) {
      if (form.id) {
        setIsLoading(true);
        updateUser(form.id, data)
          .then((res: any) => {
            fetchClient(selectedClient?.id, true);
            setSuccessMessage('Updated successfully!');
            setIsLoading(false);
          })
          .catch((e: any) => {
            setErrorMessage(e.message);
            setIsLoading(false);
          });
      }
      setTimeout(() => {
        setSuccessMessage('');
        setErrorMessage('');
      }, 3000);
    } else {
      if (validateModalForm(data)) {
        data.clients = [{ id: selectedClient?.id, name: selectedClient?.name }];
        setIsLoading(true);
        createUser(data)
          .then((res: any) => {
            fetchClient(selectedClient?.id, true);
            setSuccessMessage('Updated successfully!');
            setIsLoading(false);
          })
          .catch((e: any) => {
            setErrorMessage(e.message);
            setIsLoading(false);
          });
      }
      setTimeout(() => {
        setSuccessMessage('');
        setSuccessMessage('');
      }, 3000);
    }
  };

  const usersData = useMemo(() => {
    const tableHeader = [
      {
        id: 'Name',
        header: () => (
          <div className="flex justify-start  pl-4 text-sm font-normal font-inter">
            Name
          </div>
        ),
        accessorKey: 'name',
        cell: (props: any) => (
          <div className="flex  gap-2 dark:bg-gray-800 pl-4 ">
            {props.getValue()?.profile_pic ? (
              <img
                alt={props.getValue().profile_pic}
                src={props.getValue().profile_pic}
                height={45}
                width={50}
              />
            ) : (
              <div className="flex items-center justify-center w-8 h-8 rounded-full bg-[#005C9F]  border-2 border-[#FFFFFF] relative text-white">
                {getInitials(
                  props.getValue()?.firstName + ' ' + props.getValue()?.lastName
                )}
              </div>
            )}
            <div className="flex flex-col dark:bg-gray-800">
              <span className="text-sm font-semibold dark:text-white">
                {props.getValue()?.firstName + ' ' + props.getValue()?.lastName}
              </span>
              <span className="text-sm text-[#475467] font-normal dark:text-[#fff] ">
                {props.getValue()?.email}
              </span>
            </div>
          </div>
        ),
      },
      {
        id: 'Status',
        header: () => (
          <div className="flex justify-start text-sm font-normal font-inter">
            Status
          </div>
        ),
        accessorKey: 'user',
        cell: (props: any) => (
          <label className="relative inline-flex items-center  cursor-pointer font-inter w-[5vw] ml-[2px]">
            <input
              type="checkbox"
              className="sr-only peer"
              defaultChecked={
                props.getValue()?.status === 'active' ||
                props.getValue()?.status === 'activated'
              }
              value={props.getValue()?.status}
              onClick={() => handleToggle(props.getValue())}
            />
            <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-[#005C9F]"></div>
          </label>
        ),
      },
      {
        id: 'Role',
        header: () => (
          <div className="flex justify-center w-[15vw] text-sm font-normal font-inter">
            Role
          </div>
        ),
        accessorKey: 'role',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[15vw]">
            <div className="text-xs rounded-full border bg-[#D9D6FE] px-2 py-1 uppercase dark:bg-[#fff] dark:text-[#000]">
              {getRoleName(props.getValue())}
            </div>
          </div>
        ),
      },
      {
        id: 'Action',
        header: () => (
          <div className="text-center w-[5vw] text-sm font-normal font-inter">
            Action
          </div>
        ),
        accessorKey: 'user',
        cell: (props: any) => (
          <>
            <div className="flex gap-2 justify-center cursor-pointer font-inter  w-[5vw]">
              <button onClick={() => handleEdit(props.getValue())}>
                <Icon name="Edit05" color="#7C7E7F" />
              </button>
              <button onClick={() => handleDelete(props.getValue())}>
                <Icon name="Trash" />
              </button>
            </div>
          </>
        ),
      },
    ];

    const data = (selectedClient.users || []).map((resource: any) => {
      return {
        name: {
          firstName: resource?.first_name,
          lastName: resource?.last_name,
          img: resource?.profile_pic,
          email: resource?.email,
        },
        user: resource,
        role: resource.role,
      };
    });
    return { tableHeader, data };
  }, [selectedClient]);

  return (
    <div className="flex flex-col w-full h-full  pb-6  bg-[#D2EEFF] dark:bg-[#222732] rounded-xl">
      <div className="flex flex-col space-y-6 w-full px-3 pt-0 rounded-xl bg-[#D2EEFF] dark:bg-[#222732]">
        {/* <div className="flex flex-col w-full">
          <span className="text-sm font-semibold dark:text-white font-mundia">
            Invite team members
          </span>
        </div> */}
        <div className="flex w-full items-end justify-between px-6">
          <div className="flex flex-col w-1/2 gap-1">
            {/* <span className="text-sm font-semibold dark:text-white font-mundia">
              Manage your team and change roles/permissions.
            </span> */}
          </div>
          {/* <form onSubmit={handleSubmit} className="flex flex-1 flex-col gap-4">
            <div className="flex gap-4">
              <div className="w-[50%]">
                <Input
                  label=""
                  type="email"
                  name="email"
                  placeholder="✉️ you@email.com"
                  value={form?.email}
                  onChange={handleChange}
                  errorMessage={error ? "Email address is required" : undefined}
                />
              </div>
              <div className="w-[200px] z-20">
                <Select
                  options={roles}
                  onChange={handleRole}
                  defaultValue={roles.find((role) => role.value === "staff")}
                  styles={rolesStyles}
                />
              </div>
              <button className="flex items-center gap-2 btn w-[200px] h-[42px] self-end border border-[#005C9F] bg-[#005C9F] text-white text-sm px-3 rounded-lg font-semibold">
                <Mail color="white" />
                Send Invites
              </button>
            </div>
          </form> */}
          <button
            onClick={() => {
              setEditModal(true);
              setModal(initialState);
              setErrorMessage('');
              setSuccessMessage('');
            }}
            className="btn w-fit  border border-[#005C9F] bg-[#005C9F] text-white text-sm py-2 px-4 rounded-lg font-semibold"
          >
            Add New
          </button>
        </div>

        <div>
          {/* <AnalyticsTable
            data={usersData.data ? usersData.data : []}
            columns={usersData.tableHeader}
            loading={selectedClient?.loading}
            totalDocs={
              selectedClient?.users ? selectedClient?.users.length : 'N/A'
            }
          /> */}
          <TanStackTable
            data={usersData.data ? usersData.data : []}
            columns={usersData.tableHeader}
            loading={selectedClient?.loading}
            totalDocs={
              selectedClient?.users ? selectedClient?.users.length : 'N/A'
            }
            tableHeight="h-[64vh]"
            pagination={false}
          />
          {/* <CustomModal open={isRequestModal} onClose={() => setRequestModall(false)}>
          <EditModal user={userSelected}/>
        </CustomModal> */}
          <CustomModal
            open={isConfirmModal}
            onClose={() => setConfirmModall(false)}
          >
            <ConfirmationModal
              user={userSelected}
              onClose={() => setConfirmModall(false)}
              onDelete={() => {
                if (userSelected) {
                  handleConfirm(userSelected);
                }
              }}
            />
          </CustomModal>
          {/* <CustomModal open={isAddModal} onClose={() => setAddModal(false)}>
            <UserModal onClose={() => setAddModal(false)}/>
          </CustomModal> */}
          <CustomModal open={isEditModal} onClose={() => setEditModal(false)}>
            <UserModal
              user={modal}
              onClose={() => setEditModal(false)}
              onSave={handleModalSubmit}
              isLoading={isLoading}
              error={errorMessage}
              success={successMessage}
            />
          </CustomModal>
        </div>
      </div>
    </div>
  );
};

export default Team;
