import { useState } from 'react';
import Frame from '../../../assets/images/Frame.png';
import Featured from '../../../assets/images/Featured-icon.png';
import CustomModal from '../../CustomModal';
import RequestModal from '../../Dashboard/RequestModal';
import useClientStore from '../../../store/useClientStore';

interface IProps {
  title?: string;
  message?: string;
  serviceType?: string;
  hideRequestAccess?: boolean;
}

interface SelectedService {
  id?: string | null;
  title?: string | null;
  status?: string | null;
  icon?: string | null;
  startDate?: string;
  revenue?: string;
  serviceId?: string;
  contractDue?: string;
}

const ServiceNotAvailable = ({
  title = 'No Service Available',
  message = 'Stay top-of-mind and convert site visitors into customers with competitor-crushing remarketing.',
  serviceType,
  hideRequestAccess=false
}: IProps) => {
  
  const [isRequestModal, setRequestModal] = useState(false);
  const { selectedClient } = useClientStore((state) => state);
  const [selectedService, setSelectedService] =
    useState<SelectedService | null>(null);

  const requesAccess = () => {
    if (serviceType === 'seo')
      setSelectedService({
        id: selectedClient?.id || null,
        icon: 'OutlineGoogleSEO',
        title: 'Google Seo',
        status: 'inactive',
        startDate: null,
        revenue: null,
        serviceId: null,
      });
    if (serviceType === 'ppc')
      setSelectedService({
        id: selectedClient?.id || null,
        icon: 'OutlineGoogleAds',
        title: 'Google Ads',
        status: 'inactive',
        startDate: null,
        revenue: null,
        serviceId: null,
      });
    if (serviceType === 'bing')
      setSelectedService({
        id: selectedClient?.id || null,
        icon: 'OutlineBingAds',
        title: 'bing',
        status: 'inactive',
        startDate: null,
        revenue: null,
        serviceId: null,
      });
    if (serviceType === 'meta')
      setSelectedService({
        id: selectedClient?.id || null,
        icon: 'OutlineMetaAds',
        title: 'Meta',
        status: 'inactive',
        startDate: null,
        revenue: null,
        serviceId: null,
      });
    setRequestModal(true);
  };

  
  return (
    <div className="relative flex justify-between w-full bg-white dark:bg-[#262627] dark:border-transparent p-3 md:p-8 rounded-md h-[68dvh] md:h-fit">
      <div className="flex md:justify-center flex-col md:w-2/4">
        <img src={Featured} width={56} alt="featured" />
        <h1 className="text-2xl md:text-6xl font-semibold leading-[72px] w-full md:w-9/12 dark:text-white md:mt-6">
          {title}
        </h1>

        <p className="md:mt-6 md:w-3/5  dark:text-white">{message}</p>

        {!hideRequestAccess &&
          <button
            className="btn w-fit self-start mt-6 border border-[#005C9F] bg-[#005C9F] text-white text-sm py-2 px-4 rounded-lg font-semibold"
            onClick={requesAccess}
          >
            I want this
          </button>
        }
      </div>
      <div className="absolute md:relative bottom-3 right-3 w-3/4 md:w-2/4">
        <img src={Frame} alt="not-available" />
      </div>
      <CustomModal open={isRequestModal} onClose={() => setRequestModal(false)}>
        <RequestModal {...selectedService} />
      </CustomModal>
    </div>
  );
};

export default ServiceNotAvailable;
