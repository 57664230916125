import { useMemo } from 'react';
import { formatNumber, formatToCurrency } from '../../../utils/common';
import MetricsCards from './metricsCards';
import Tooltip from '../../HelpTooltip';
import { Card } from '@tremor/react';
import AnalyticsTable from '../../AnalyticsSections/AnalyticsTable';
import Icon from '../../../assets/icons/SvgComponent';
import NoData from '../../NoData';
import comingSoon from '../../../assets/images/404.png';

interface Props {
  clientReport?: any;
}
const Metric: React.FC<Props> = ({ clientReport }) => {
  const bottomLanding =
    clientReport?.raw_data?.ga4?.bottom_landing_pages_by_organic_session;
  const topLanding =
    clientReport?.raw_data?.ga4?.top_landing_pages_by_organic_session;
  const topQueries = clientReport?.raw_data?.gsc?.topFiveQueries;
  const rankRanger = clientReport?.raw_data?.rankranger;

  const bottomLandingReportData = useMemo(() => {
    const bottomLandingPage =
      clientReport?.raw_data?.ga4?.bottom_landing_pages_by_organic_session ||
      [];
    const tableHeader = [
      {
        id: 'Page',
        header: () => <div className="text-left w-[7vw] pl-4 ">Page</div>,
        accessorKey: 'page',
        cell: (props: any) => (
          <div className="flex justify-start font-inter w-[7vw] pl-4 h-fit ">
            {props.getValue()}
          </div>
        ),
      },
      {
        id: 'Sessions',
        header: () => <div className="text-center w-[5vw] pl-8 ">Sessions</div>,
        accessorKey: 'sessions',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[5vw] pl-8 h-fit ">
            <div className="truncate max-w-[15vw] overflow-hidden">
              {formatNumber(props.getValue())}
            </div>
          </div>
        ),
      },
      {
        id: 'Previous Period',
        header: () => (
          <div className="text-center w-[10vw] ">Previous Period</div>
        ),
        accessorKey: 'period',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[10vw]  h-fit">
            <div className="truncate max-w-[15vw] overflow-hidden">
              {props.getValue()}
            </div>
          </div>
        ),
      },
    ];
    const data = (bottomLandingPage || []).map((resource: any) => {
      return {
        page: resource?.page ? resource?.page : 'N/A',
        sessions: resource?.sessions ? resource?.sessions : 'N/A',
        period: resource?.previous_period ? resource?.previous_period : 'N/A',
      };
    });

    return { tableHeader, data };
  }, [bottomLanding]);

  const topLandingReportData = useMemo(() => {
    const topLandingPage =
      clientReport?.raw_data?.ga4?.top_landing_pages_by_organic_session || [];
    const tableHeader = [
      {
        id: 'Page',
        header: () => <div className="text-left w-[7vw] pl-4">Page</div>,
        accessorKey: 'page',
        cell: (props: any) => (
          <div className="flex justify-start font-inter w-[7vw] pl-4 h-fit">
            {props.getValue()}
          </div>
        ),
      },
      {
        id: 'Sessions',
        header: () => <div className="text-center w-[5vw] pl-8">Sessions</div>,
        accessorKey: 'sessions',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[5vw] pl-8 h-fit">
            <div className="truncate max-w-[15vw] overflow-hidden">
              {formatNumber(props.getValue())}
            </div>
          </div>
        ),
      },
      {
        id: 'Previous Period',
        header: () => (
          <div className="text-center w-[10vw] ">Previous Period</div>
        ),
        accessorKey: 'period',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[10vw]  h-fit">
            <div className="truncate max-w-[15vw] overflow-hidden">
              {props.getValue()}
            </div>
          </div>
        ),
      },
    ];
    const data = (topLandingPage || []).map((resource: any) => {
      return {
        page: resource?.page ? resource?.page : 'N/A',
        sessions: resource?.sessions ? resource?.sessions : 'N/A',
        period: resource?.previous_period ? resource?.previous_period : 'N/A',
      };
    });

    return { tableHeader, data };
  }, [topLanding]);

  const topQueriesData = useMemo(() => {
    const topQueries = clientReport?.raw_data?.gsc?.topFiveQueries || [];
    const tableHeader = [
      {
        id: 'Query',
        header: () => <div className="text-left w-[7vw] pl-4">Query</div>,
        accessorKey: 'query',
        cell: (props: any) => (
          <div className="flex justify-start font-inter w-[7vw] pl-4 h-fit">
            {props.getValue()}
          </div>
        ),
      },
      {
        id: 'Impressions',
        header: () => (
          <div className="text-center w-[7vw] pl-8">Impressions</div>
        ),
        accessorKey: 'impressions',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[7vw] pl-8 h-fit">
            <div className="truncate max-w-[15vw] overflow-hidden">
              {formatNumber(props.getValue())}
            </div>
          </div>
        ),
      },
      {
        id: 'Clicks',
        header: () => <div className="text-center w-[7vw] ">Clicks</div>,
        accessorKey: 'clicks',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[7vw]  h-fit">
            <div className="truncate max-w-[15vw] overflow-hidden">
              {formatNumber(props.getValue())}
            </div>
          </div>
        ),
      },
    ];
    const data = (topQueries || []).map((resource: any) => {
      return {
        query: resource?.query ? resource?.query : 'N/A',
        impressions: resource?.impressions ? resource?.impressions : 'N/A',
        clicks: resource?.clicks ? resource?.clicks : 'N/A',
      };
    });

    return { tableHeader, data };
  }, [topQueries]);

  const rankingsReportData = useMemo(() => {
    const rankingsResponse = clientReport?.raw_data?.rankranger || [];
    const tableHeader = [
      {
        id: 'Keyword',
        header: () => <div className="text-left w-[7vw] pl-4">Keyword</div>,
        accessorKey: 'keyword',
        cell: (props: any) => (
          <div className="flex justify-start font-inter w-[7vw]  pl-4 h-fit">
            <Tooltip content={props.getValue()} position="right">
              <div className="truncate max-w-[7vw] overflow-hidden whitespace-nowrap text-ellipsis">
                {props.getValue()}
              </div>
            </Tooltip>
          </div>
        ),
      },
      {
        id: 'Tags',
        header: () => <div className="text-center w-[7vw] ">Tags</div>,
        accessorKey: 'tags',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[7vw] h-fit">
            <Tooltip content={props.getValue()} position="right">
              <div className="text-white bg-[#005C9F] h-6 w-fit max-w-[7vw] p-2 flex justify-center items-center rounded-lg min-w-[80px] font-semibold">
                {props.getValue() ? props.getValue()?.toUpperCase() : 'N/A'}
              </div>
            </Tooltip>
          </div>
        ),
      },
      {
        id: 'Rank',
        header: () => <div className="text-center w-[5vw] ">Rank</div>,
        accessorKey: 'rank',
        cell: (props: any) => (
          <div className="flex justify-center items-center gap-2 font-inter w-[5vw] h-fit">
            <div className="flex justify-center">
              {renderRanking(props.getValue())}
            </div>
          </div>
        ),
      },
      {
        id: 'Weekly',
        header: () => <div className="text-center w-[5vw]">Weekly</div>,
        accessorKey: 'weeklyChange',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[5vw] h-fit">
            {renderValue(props.getValue()?.toString() ?? 0)}
          </div>
        ),
      },
      {
        id: 'Monthly',
        header: () => <div className="text-center w-[5vw]">Monthly</div>,
        accessorKey: 'monthlyChange',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[5vw] h-fit">
            {renderValue(props.getValue()?.toString() ?? 0)}
          </div>
        ),
      },
      {
        id: 'Volume',
        header: () => <div className="text-center w-[8vw]">Volume</div>,
        accessorKey: 'search_volume',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[8vw] h-fit">
            {renderValue(props.getValue()?.toString() ?? 0)}
          </div>
        ),
      },
    ];

    const data = (rankingsResponse || []).map((item: any) => {
      return {
        keyword: item.keyword,
        tags: item.tags[0] || '',
        rank: Number(item.rank),
        dailyChange: item.dailyChange,
        weeklyChange: item.weeklyChange,
        monthlyChange: item.monthlyChange,
        search_volume: Number(item.search_volume),
      };
    });

    return { tableHeader, data };
  }, [rankRanger]);
  const renderRanking = (value: string | number) => {
    if (Number(value) === 1) {
      return (
        <div className="flex justify-center items-center py-[2px] px-2 bg-[#E2FAF1] rounded-3xl text-[#428B6E]">
          <Icon name="badgeStatus" />{' '}
          <span className="block ml-2">{value}st</span>
        </div>
      );
    }
    if (Number(value) === 2) {
      return (
        <div className="flex justify-center items-center py-[2px] px-2 bg-[#FFF2E8] rounded-3xl text-[#FF7200]">
          <Icon name="badgeStatus" color="#FF7200" />{' '}
          <span className="block ml-2">{value}nd</span>
        </div>
      );
    }
    if (Number(value) === 3) {
      return (
        <div className="flex justify-center items-center py-[2px] px-2 bg-[#F0E8FF] rounded-3xl text-[#8E4DFF]">
          <Icon name="badgeStatus" color="#8E4DFF" />{' '}
          <span className="block ml-2">{value}rd</span>
        </div>
      );
    }
    return value || '---';
  };
  const renderValue = (value: string) => {
    const valueTrend = value?.includes('+')
      ? true
      : value?.includes('-')
        ? false
        : true;
    return valueTrend ? (
      <div className="trend-up bg-[#E6EFF5] rounded-3xl mx-0">
        <Icon name="trendUp" color="#005C9F" />{' '}
        <span className="block ml-1 text-[#005C9F]">{value}</span>
      </div>
    ) : (
      <div className="trend-down rounded-3xl mx-0">
        <Icon name="trendDown" /> <span className="block ml-1">{value}</span>
      </div>
    );
  };

  const metricsGa4 = [
    {
      label: 'Sessions',
      value:
        formatNumber(clientReport?.raw_data?.ga4?.sessions?.current_month) ||
        '0',
      prevPeriod: clientReport?.raw_data?.ga4?.sessions?.previous_period || '0',
      prevYear:
        formatNumber(clientReport?.raw_data?.ga4?.sessions?.previous_year) ||
        '0',
    },
    {
      label: 'Organic Sessions',
      value:
        formatNumber(
          clientReport?.raw_data?.ga4?.organic_sessions?.current_month
        ) || '0',
      prevPeriod:
        clientReport?.raw_data?.ga4?.organic_sessions?.previous_period || '0',
      prevYear:
        formatNumber(
          clientReport?.raw_data?.ga4?.organic_sessions?.previous_year
        ) || '0',
    },
    {
      label: 'Conversions',
      value:
        formatNumber(clientReport?.raw_data?.ga4?.conversions?.current_month) ||
        '0',
      prevPeriod:
        clientReport?.raw_data?.ga4?.conversions?.previous_period || '0',
      prevYear:
        formatNumber(clientReport?.raw_data?.ga4?.conversions?.previous_year) ||
        '0',
    },
    {
      label: 'Organic Conversions',
      value:
        formatNumber(
          clientReport?.raw_data?.ga4?.organic_conversions?.current_month
        ) || '0',
      prevPeriod:
        clientReport?.raw_data?.ga4?.organic_conversions?.previous_period ||
        '0',
      prevYear:
        formatNumber(
          clientReport?.raw_data?.ga4?.organic_conversions?.previous_year
        ) || '0',
    },
    {
      label: 'Total Revenue',
      value:
        formatToCurrency(
          clientReport?.raw_data?.ga4?.total_revenue?.current_month
        ) || '0',
      prevPeriod:
        clientReport?.raw_data?.ga4?.total_revenue?.previous_period || '0',
      prevYear:
        formatToCurrency(
          clientReport?.raw_data?.ga4?.total_revenue?.previous_year
        ) || '0',
    },

    {
      label: 'Organic Revenue',
      value:
        formatToCurrency(
          clientReport?.raw_data?.ga4?.organic_total_revenue?.current_month
        ) || '0',
      prevPeriod:
        clientReport?.raw_data?.ga4?.organic_total_revenue?.previous_period ||
        '0',
      prevYear:
        formatToCurrency(
          clientReport?.raw_data?.ga4?.organic_total_revenue?.previous_year
        ) || '0',
    },
  ];
  const metricsGsc = [
    {
      label: 'Clicks',
      value: formatNumber(clientReport?.raw_data?.gsc?.totalClicks) || '0',
    },
    {
      label: 'Non Branded Clicks',
      value: formatNumber(clientReport?.raw_data?.gsc?.nonBrandedClicks) || '0',
    },
    {
      label: 'Branded Clicks',
      value: formatNumber(clientReport?.raw_data?.ga4?.brandedClicks) || '0',
    },
  ];

  const isSeo = clientReport?.report_type?.includes('seo');
  const noValue = clientReport?.raw_data === undefined;
  const message = isSeo ? (
    <>
      No records found! <br /> Generate AI Report to display data.
    </>
  ) : (
    'Coming Soon!'
  );
  const img = isSeo ? (
    <Icon name="NoData" size={200} />
  ) : (
    <div className="w-1/4">
      <img src={comingSoon} alt="not-available" />
    </div>
  );

  if (noValue) {
    return (
      <div className="h-[65vh] flex flex-col justify-center items-center gap-y-2 border border-[#E6EBF0] rounded-lg py-8">
        <span className=" dark:text-white text-sm sm:text-xl text-center">
          {message}
        </span>
        {img}
      </div>
    );
  }
  return (
    <div className="flex flex-col gap-y-8 rounded-md w-full h-[80%] p-1">
      <Card>
        <div className="dark:text-white title-text ">GA4</div>
        <div className="flex flex-col gap-y-8">
          <MetricsCards metrics={metricsGa4} />
          <div className="dark:text-white title-text ">
            Bottom Landing Pages By Organic Session
          </div>
          {bottomLandingReportData?.data.length ? (
            <AnalyticsTable
              data={
                bottomLandingReportData?.data
                  ? bottomLandingReportData?.data
                  : []
              }
              columns={bottomLandingReportData?.tableHeader}
              loading={false}
              totalDocs={bottomLanding ? bottomLanding?.length : 0}
              sortByColumnId="Page"
            />
          ) : (
            <Card className="dark:border border-[#E6EBF0]">
              <NoData />
            </Card>
          )}
          <div className="dark:text-white title-text ">
            Top Landing Pages By Organic Session
          </div>
          {topLandingReportData?.data.length ? (
            <AnalyticsTable
              data={
                topLandingReportData?.data ? topLandingReportData?.data : []
              }
              columns={topLandingReportData?.tableHeader}
              loading={false}
              totalDocs={topLanding ? topLanding?.length : 0}
              sortByColumnId="Page"
            />
          ) : (
            <Card className="dark:border border-[#E6EBF0]">
              <NoData />
            </Card>
          )}
        </div>
      </Card>
      <Card>
        <div className="dark:text-white title-text ">GSC</div>
        <div className="flex flex-col gap-y-8">
          <MetricsCards metrics={metricsGsc} />
          <div className="dark:text-white font-[600] title-text ">
            Top Queries
          </div>
          {topQueriesData?.data.length ? (
            <AnalyticsTable
              data={topQueriesData?.data ? topQueriesData?.data : []}
              columns={topQueriesData?.tableHeader}
              loading={false}
              totalDocs={topQueries ? topQueries?.length : 0}
              sortByColumnId="Page"
            />
          ) : (
            <Card className="dark:border border-[#E6EBF0]">
              <NoData />
            </Card>
          )}
        </div>
      </Card>
      <Card>
        <div className="dark:text-white font-[600] title-text mb-6">
          Rank Ranger
        </div>
        {rankingsReportData?.data.length ? (
          <AnalyticsTable
            data={rankingsReportData?.data ? rankingsReportData?.data : []}
            columns={rankingsReportData?.tableHeader}
            loading={false}
            totalDocs={
              rankingsReportData?.data ? rankingsReportData?.data.length : 0
            }
            sortByColumnId="Tags"
          />
        ) : (
          <Card className="dark:border border-[#E6EBF0]">
            <NoData />
          </Card>
        )}
      </Card>
    </div>
  );
};

export default Metric;
