import React from 'react';

interface PlusProps {
  color?: string;
}

const Minus: React.FC<PlusProps> = ({ color = '#428B6E' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    className="cursor-pointer"
  >
    <path
      d="M4.16663 10H15.8333"
      stroke="#7C7E7F"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Minus;
