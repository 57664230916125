import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Icon from '../../../assets/icons/SvgComponent';
import ImageIcon from '../../ImageIcon';
import { useThemeStore } from '../../../store/useThemeStore';
import { getAuthData, login } from '../../../utils/fbSdk';
import useAuthStore from '../../../store/useAuthStore';

interface EditModalProps {
  user?: any;
  onClose?: any;
}

const ViewAccessModal: React.FC<EditModalProps> = ({ user, onClose }) => {
  const { mode } = useThemeStore((state: any) => state);
  const iconColor = mode === 'dark' ? 'white' : 'black';
  const { reAuthFbToken, currentUser = user } = useAuthStore((state) => state);
  const [loading, setLoading] = useState(false);
  const reAuthFb = async () => {
    setLoading(true);
    const res = await getAuthData();
    if (res.status === 'connected') {
      const { accessToken, userID } = res.authResponse;
      const params = {
        accessToken,
        userID,
      };
      reAuthFbToken(params);
    } else {
      const res = await login();
      const accessToken = res?.authResponse?.accessToken;
      const userID = res?.authResponse?.userID;
      const params = {
        accessToken,
        userID,
      };
      reAuthFbToken(params);
    }
    setLoading(false);
  };
  return (
    <div
      className="fixed top-0 right-0 bg-white h-screen w-[26rem] flex flex-col z-100 dark:bg-[#262627] dark:border dark:border-[#313233] dark:shadow-bs-card font-inter"
      style={{ minHeight: '720px' }}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="relative h-screen overflow-y-hidden flex flex-col justify-between">
        <div className="max-h-[100dvh] overflow-y-hidden">
          <div className="px-6 flex  items-start gap-2  border-b border-tertiary-colours-neutral-cool-100 flex justify-between">
            <h2 className="font-inter text-[#001129] text-xl font-semibold my-6  dark:text-[#E6EFF5]  w-fit">
              View Access
            </h2>
            {loading && (
              <div className="absolute right-16 top-5">
                <Icon name="loading" />
              </div>
            )}
            <div
              className="w-[90px] h-full flex justify-end my-6 cursor-pointer"
              onClick={onClose}
            >
              {' '}
              <Icon
                name="Xclose"
                color={mode === 'dark' ? '#fff' : iconColor}
              />
            </div>
          </div>
          <div className="flex flex-col h-1/2 mt-2 min-h-[85dvh] overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
            <div className="flex justify-between items-center gap-2 w-full border-b pb-4 px-6 mb-2">
              <div className="relative rounded-full flex justify-center items-center ">
                <input
                  className="absolute w-full h-full opacity-0 cursor-pointer"
                  type="file"
                />
                <div className="w-full h-full flex items-center justify-center text-gray-600 ">
                  {user?.profile_pic ? (
                    <>
                      <img
                        src={user?.profile_pic}
                        alt="profile"
                        className="w-[80px]"
                      />
                    </>
                  ) : (
                    <ImageIcon
                      data={`${user?.userId?.first_name} ${user?.userId?.last_name}`}
                      size={20}
                      textSize={'2xl'}
                    />
                  )}
                </div>
              </div>
              <div className="relative w-full truncate overflow-hidden dark:text-white  px-2 text-[#344054] text-xl border border-solid rounded-md border-gray-300 py-[3vh] text-center">
                {`${user?.userId?.first_name} ${user?.userId?.last_name}`}
              </div>
            </div>
            <div className="p-6">
              <div className="dark:text-white">Access Token :</div>
              <div className="w-full truncate dark:text-white overflow-hidden mt-4 text-xs font-[300]">
                {user.accessToken}
              </div>
              <div className="dark:text-white mt-4">
                Last Update :{' '}
                {user?.updatedAt
                  ? moment(`${user.updatedAt}`).format('DD MMM YYYY h:mm A')
                  : ''}{' '}
              </div>
              <div className="dark:text-white mt-4">
                Token Expiration :{' '}
                {user?.tokenExpiry
                  ? moment(`${user.tokenExpiry}`).format('DD MMM YYYY h:mm A')
                  : ''}
              </div>
              <div className="w-full truncate dark:text-white overflow-hidden mt-4 text-xs font-[300]"></div>
              <div className="mt-4 dark:text-white">Clients</div>
              <div className="mt-4">
                {user?.clients?.map((client) => (
                  <div className="flex items-center p-3 text-base bg-gray-100 font-bold text-gray-900 rounded-lg  hover:bg-gray-200 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white my-2">
                    <div className="w-8 h-8 rounded-full overflow-hidden">
                      <ImageIcon data={client.name} size={8} textSize={'xs'} />
                    </div>
                    <div>
                      <div className="text-sm flex-1 ms-3 overflow-hidden whitespace-nowrap text-[ellipsis]">
                        {client.name}
                      </div>
                      <div className="text-xs ms-3 font-normal">
                        {client.domain}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="px-6 min-h-[8vh] max-h-[11vh] w-full flex justify-center items-center border-t border-tertiary-colors-neutral-cool-100">
          <button
            type="button"
            className={`w-full rounded-lg text-white border border-[#005C9F] ${currentUser?.id !== user?.userId?.id ? 'bg-gray-500' : 'bg-[#005C9F]'} shadow-xs px-4 py-2 dark:text-[#E6EFF5]`}
            onClick={reAuthFb}
            disabled={currentUser?.id !== user?.userId?.id}
          >
            Reauthenticate
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewAccessModal;
