import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const BingAnalyticsTab: React.FC<IconProps> = ({
  color = '#0081fb',
  size = 30,
}) => (
  <svg
    xmlns="
    http://www.w3.org/2000/svg"
        aria-label="Bing"
        viewBox="0 0 512 512"
        width={size}
        height={size}
    >
    <rect width={512} height={512} fill="none" rx="15%" />
    <path
          fill={color}
          d="m145 73 73 26v257l103-59-50-24-32-79 162 57v83L218 439l-73-41Z"
        />
  </svg>
);

export default BingAnalyticsTab;
