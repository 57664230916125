import Select, { StylesConfig } from 'react-select';

type Props = {
  options?: any;
  placeholder?: string;
  value?: any;
  handleOnChange?: any;
  isDisabled?: boolean;
  wSearch?: boolean;
  customWidth?: string;
};

const ReactSelect: React.FC<Props> = ({
  options,
  placeholder,
  value,
  handleOnChange,
  isDisabled = false,
  wSearch,
  customWidth,
}) => {
  const customStyles: StylesConfig<any, boolean, any> = {
    control: (provided) => ({
      ...provided,
      ...{
        minWidth: customWidth ? customWidth : '140px',
        borderRadius: wSearch ? '8px 0 0 8px' : '8px',
        border: '1px solid #D1D5DB',
        opacity: isDisabled ? 0.5 : 1,
        cursor: 'pointer',
        fontSize: '14px',
        scrollbarWidth: 'thin',
      },
    }),
    option: (provided, state) => {
      return {
        ...provided,
        color: state.data.isDisabled ? '#6D8080' : '',
        fontSize: '14px',
        cursor: 'pointer',
      };
    },
    menuPortal: (provided) => ({ ...provided, zIndex: 1000 }),
  };

  return (
    <div>
      <Select
        className="my-react-select-container"
        classNamePrefix="my-react-select"
        options={options}
        value={value}
        placeholder={placeholder}
        onChange={handleOnChange}
        menuPortalTarget={document.body}
        styles={customStyles}
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default ReactSelect;
