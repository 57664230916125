import React from 'react';
import { useLocation } from 'react-router-dom';
import PageHeader from '../../../components/Dashboard/PageHeader';
import useAuthStore from '../../../store/useAuthStore';
import { useEffect, useState, useRef } from 'react';
import useCrawlerStore from '../../../store/useCrawlerStore';
import MyDetails from '../../../components/Settings/myDetails';
import ProfileNotification from '../../../components/Settings/profileNotification';
import Team from '../../../components/Settings/team';
import Billing from '../../../components/Settings/billing';
import Services from '../../../components/Services';
import useUserStore from '../../../store/useUserStore';

const Crawler: React.FC = () => {
  const location = useLocation();
  const { addCrawl, fetchCrawl, fetchHistory, crawlHistory } = useCrawlerStore((state) => state);
  const { currentUser, selectedDomain } = useAuthStore((state) => state);
  const { user, fetchUser } = useUserStore((state) => state);
  const [isMounted, setIsMounted] = useState(false);
  const [activeTab, setActiveTab] = useState('addCrawl');
  const inputRef = useRef(null);
  const [values, setValues] = useState({
    loading: false,
    addDomain: '',
    depth: 2,
    showPrevious: false,
    isPreviousCrawls: false,
    previousCrawls: [],
    error:'',
    success:''
  });

  const {
    loading, 
    addDomain,
    depth,
    showPrevious,
    previousCrawls,
    error,
    success
  } = values

  useEffect(() => {    
    const filters = {
      search: '',
      page: 1,
      limit: 999,
      sortBy: '',
      sortOrder: '',
    };
    fetchHistory(filters).
    then(res => {
      setValues({
        ...values,
        error:'',
        previousCrawls: res.data
      })
    })
    fetchUser(currentUser?.id);
    setIsMounted(true);
  }, []);

  const arrayToCSV = (data) => {
    const csvRows = [];
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(','));
    for (const row of data) {
        const values = headers.map(header => {
            const value = row[header];
            if (typeof value === 'object') {
                return JSON.stringify(value).replace(/"/g, '""');
            }
            return `${value}`.replace(/"/g, '""'); 
        });
        csvRows.push(values.join(','));
    }

    return csvRows.join('\n');
};

  const handleDownload = (crawl_url, crawl) => {
    console.log('WORKING CLICK: ', crawl.data)
    const csvString = arrayToCSV(crawl.data);
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', `${crawl_url}-${new Date().toISOString().split('T')[0]}.csv`);
    a.click();
  };

  const handleAddCrawl = (e) => {
    setValues({
      ...values,
      error:'',
      addDomain: e.target.value
    })
  }

  const handleChangeDepth = (e) => {
    setValues({
      ...values,
      depth: e.target.value
    })
  }

  const isValidUrl = urlString => {
    if(urlString == 'javascript:void(0)'){
      return false
    }else{
      try { 
        return Boolean(new URL(urlString)); 
      }
      catch(e){ 
        return false; 
      }
    }
  }

  const handleSubmitCrawl = (e) => {

    if(addDomain !== '' && isValidUrl(addDomain)){
      addCrawl({domain: addDomain, depth: depth, email: currentUser.email})
      .then( res => {
        console.log('Response: ', res)
        if(res.message ==='success'){
          setValues({
            ...values,
            success: 'Crawl Added. You\'ll receive an email once the crawl has completed'
          })
        }else{
          setValues({
            ...values,
            error: res.message
          })
        }
      })
    }else{
      setValues({
        ...values,
        error: 'Please check that you have entered a valid URL'
      })
    } 
  }

  return (
    <div className="flex flex-col px-4 lg:px-12  bg-[#D2EEFF] dark:bg-[#0C111D] tools-page">
      <PageHeader 
        title={'SEO Crawler'}
        subtitle={'Add client domains to the SEO Crawler'}
        hideDate
        hideDomain
      />
      <div className="tremor-Card-root relative w-full text-left ring-1 rounded-tremor-default p-6 bg-tremor-background ring-tremor-ring shadow-tremor-card dark:ring-dark-tremor-ring dark:shadow-dark-tremor-card border-tremor-brand dark:border-dark-tremor-brand sm:block dark:bg-[#222732] px-2 sm:px-6 mt-0 sm:mt-2" id="overall">
        <div className="tabs">
          <button onClick={() => setValues({...values, showPrevious: false})} className={activeTab === 'addCrawl' ? 'text-xs text-white py-1 px-2 w-fit mr-4 active' : 'text-xs text-white py-1 px-2 w-fit mr-4'}>Add Crawl</button>
          <button onClick={() => setValues({...values, showPrevious: true})} className={activeTab === 'previousCrawls' ? 'text-xs text-white py-1 px-2 w-fit active' : 'text-xs text-white py-1 px-2 w-fit'}>Previous Crawls</button>
        </div>
        <div className="tab-content">
          <div style={showPrevious ? {display: 'none'} : {display: 'block'}}>
            <small>For best results, copy the Home Page URL straight from the browser and paste below</small>
            <div>
              <small>URL:
                <input onChange={(e)=> handleAddCrawl(e)} value={addDomain} placeholder='eg. https://example.com' className="block p-[0.5rem] w-[400px]  z-50 text-sm text-gray-900 rounded-e-lg border-s-gray-50 border-s-2 border-y border-r border-gray-300 dark:bg-gray-700 dark:border-s-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"/>
              </small>
            </div>
            <small>Depth:
            <select value={depth} onChange={(e)=> handleChangeDepth(e)} className="block p-[0.5rem] w-[400px]  z-50 text-sm text-gray-900 rounded-e-lg border-s-gray-50 border-s-2 border-y border-r border-gray-300 dark:bg-gray-700 dark:border-s-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={0}>Unlimited</option>
            </select>
            </small>
            <button onClick={(e) => handleSubmitCrawl(e)} className="'text-xs text-white rounded-md py-1 px-2 bg-[#0029ff] w-fit mt-4">Submit</button>
            <br />
            {error !== '' ? <small style={{color: 'red'}}>{error}</small> : ''}
            {success !== '' ? <small style={{color: 'green'}}>{success}</small> : ''}
          </div>
          <div style={showPrevious ? {display: 'block'} : {display: 'none'}}>
            Previous Crawls
            <ul>
              {previousCrawls.length > 0 ? previousCrawls.map((crawl_item, i) => {
                return <li key={i}>{crawl_item.domain} - {crawl_item.status} <button onClick={() => handleDownload(crawl_item.domain, crawl_item)}>Download CSV</button></li>
              }) :''}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Crawler;
