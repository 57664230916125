import React from 'react';

interface MagicProps {
  color?: string;
}

const Magic: React.FC<MagicProps> = ({ color = '#428B6E' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
  >
    <path
      d="M11.3333 11.667L8.83333 9.16699M13.0086 2.91699V1.66699M16.2914 4.21754L17.1753 3.33366M16.2914 10.8337L17.1753 11.7175M9.67529 4.21754L8.79141 3.33366M17.592 7.50033H18.842M5.60947 17.3908L13.3072 9.69313C13.6372 9.36312 13.8022 9.19811 13.864 9.00784C13.9184 8.84047 13.9184 8.66018 13.864 8.49281C13.8022 8.30254 13.6372 8.13753 13.3072 7.80752L12.6928 7.19313C12.3628 6.86312 12.1978 6.69811 12.0075 6.63629C11.8401 6.58191 11.6599 6.58191 11.4925 6.63629C11.3022 6.69811 11.1372 6.86312 10.8072 7.19314L3.10947 14.8909C2.77946 15.2209 2.61445 15.3859 2.55263 15.5761C2.49825 15.7435 2.49825 15.9238 2.55263 16.0912C2.61445 16.2814 2.77946 16.4465 3.10947 16.7765L3.72385 17.3909C4.05387 17.7209 4.21887 17.8859 4.40915 17.9477C4.57652 18.0021 4.75681 18.0021 4.92418 17.9477C5.11445 17.8859 5.27946 17.7209 5.60947 17.3908Z"
      stroke="white"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Magic;
