import React from 'react';
import Icon from '../../../assets/icons/SvgComponent';
import { getDeltaTypeAndColor } from '../../../utils/performance';
import { formatNumber } from '../../../utils/common';

interface MetricsCardsProps {
  metrics?: any;
  performance?: any;
  type: string;
}

const MetricsCards: React.FC<MetricsCardsProps> = ({ performance, type }) => {
  const isLeadGen = performance?.client?.category_type === 'Lead Gen';

  const dataMapping = {
    ppc_google: [
      {
        label: 'Clicks',
        value: formatNumber(performance?.client?.googleAds?.clicks || '0'),
        prevPeriod: formatNumber(performance?.client?.googleAds?.clicks || '0'),
      },
      {
        label: 'Conversion Rate',
        value: formatNumber(
          performance?.client?.googleAds?.conversion_rate || '0'
        ),
        prevPeriod: formatNumber(
          performance?.client?.previous_month?.conversion_rate || '0'
        ),
      },
      {
        label: 'Conversions',
        value: formatNumber(performance?.client?.googleAds?.conversions || '0'),
        prevPeriod: formatNumber(
          performance?.client?.previous_month?.conversions || '0'
        ),
      },
      {
        label: 'Conversions Value',
        value: formatNumber(
          performance?.client?.googleAds?.conversions_value || '0'
        ),
        prevPeriod: formatNumber(
          performance?.client?.previous_month?.conversions_value || '0'
        ),
      },
      {
        label: 'Cost Per Conversions',
        value: formatNumber(
          performance?.client?.googleAds?.costPerConversion || '0'
        ),
        prevPeriod: formatNumber(
          performance?.client?.previous_month?.costPerConversion || '0'
        ),
      },
      {
        label: 'CPC',
        value: formatNumber(performance?.client?.googleAds?.cpc || '0'),
        prevPeriod: formatNumber(
          performance?.client?.previous_month?.cpc || '0'
        ),
      },
      {
        label: 'CTR',
        value: formatNumber(performance?.client?.googleAds?.ctr || '0'),
        prevPeriod: formatNumber(
          performance?.client?.previous_month?.ctr || '0'
        ),
      },
      {
        label: 'Impressions',
        value: formatNumber(performance?.client?.googleAds?.impressions || '0'),
        prevPeriod: formatNumber(
          performance?.client?.previous_month?.impressions || '0'
        ),
      },
      {
        label: 'ROAS',
        value: formatNumber(performance?.client?.googleAds?.roas || '0'),
        prevPeriod: formatNumber(
          performance?.client?.previous_month?.roas || '0'
        ),
      },
    ],

    seo_google: [
      !isLeadGen && {
        label: 'Organic Revenue',
        value: performance?.organicRevenue?.current || '0',
        prevPeriod: performance?.organicRevenue?.previous || '0',
      },
      {
        label: 'Organic Conversions',
        value: performance?.organicConversions?.current || '0',
        prevPeriod: performance?.organicConversions?.previous || '0',
      },
      {
        label: 'Organic Users',
        value: performance?.organicUsers?.current || '0',
        prevPeriod: performance?.organicUsers?.previous || '0',
      },
      {
        label: 'New Users',
        value: performance?.newUsers?.current || '0',
        prevPeriod: performance?.newUsers?.previous || '0',
      },
      {
        label: 'Clicks',
        value: performance?.clicks?.current || '0',
        prevPeriod: performance?.clicks?.previous || '0',
      },
      {
        label: 'Impressions',
        value: performance?.impressions?.current || '0',
        prevPeriod: performance?.impressions?.previous || '0',
      },
      {
        label: `Ahref's Top 10`,
        value: performance?.ahrefs_top_10?.current || '0',
        prevPeriod: performance?.ahrefs_top_10?.previous || '0',
      },
      {
        label: `Ahref's Top`,
        value: performance?.ahrefs_top_01?.current || '0',
        prevPeriod: performance?.ahrefs_top_01?.previous || '0',
      },
      {
        label: `Ahref's Top 3`,
        value: performance?.ahrefsTop03?.current || '0',
        prevPeriod: performance?.ahrefsTop03?.previous || '0',
      },
      {
        label: 'Referring Domains',
        value: performance?.referring_domain?.current || '0',
        prevPeriod: performance?.referring_domain?.previous || '0',
      },
      {
        label: 'Cost Per Lead',
        value: performance?.conversions_return?.current || '0',
        prevPeriod: performance?.conversions_return?.previous || '0',
      },
      !isLeadGen && {
        label: 'Ecommerce Return',
        value: performance?.ecommerce_return?.current || '0',
        prevPeriod: performance?.ecommerce_return?.previous || '0',
      },
    ].filter(Boolean),

    social_meta: [
      {
        label: 'Impressions',
        value: formatNumber(performance?.client?.meta?.impressions || '0'),
        prevPeriod: formatNumber(
          performance?.client?.meta?.previous_month?.impressions || '0'
        ),
      },
      {
        label: 'Reach',
        value: formatNumber(performance?.client?.meta?.reach || '0'),
        prevPeriod: formatNumber(
          performance?.client?.meta?.previous_month?.reach || '0'
        ),
      },
      {
        label: 'Frequency',
        value: formatNumber(performance?.client?.meta?.frequency || '0'),
        prevPeriod: formatNumber(
          performance?.client?.meta?.previous_month?.frequency || '0'
        ),
      },
      {
        label: 'CPM',
        value: formatNumber(performance?.client?.meta?.cpm || '0'),
        prevPeriod: formatNumber(
          performance?.client?.meta?.previous_month?.cpm || '0'
        ),
      },
      {
        label: 'Link Clicks',
        value: formatNumber(performance?.client?.meta?.link_clicks || '0'),
        prevPeriod: formatNumber(
          performance?.client?.meta?.previous_month?.link_clicks || '0'
        ),
      },
      {
        label: 'CTR',
        value: formatNumber(performance?.client?.meta?.ctr || '0'),
        prevPeriod: formatNumber(
          performance?.client?.meta?.previous_month?.ctr || '0'
        ),
      },
      {
        label: 'Website Leads',
        value: formatNumber(performance?.client?.meta?.website_leads || '0'),
        prevPeriod: formatNumber(
          performance?.client?.meta?.previous_month?.website_leads || '0'
        ),
      },

      {
        label: 'CPL',
        value: formatNumber(performance?.client?.meta?.cpl || '0'),
        prevPeriod: formatNumber(
          performance?.client?.meta?.previous_month?.cpl || '0'
        ),
      },
      {
        label: 'CVR',
        value: formatNumber(performance?.client?.meta?.cvr || '0'),
        prevPeriod: formatNumber(
          performance?.client?.meta?.previous_month?.cvr || '0'
        ),
      },
      {
        label: 'Revenue',
        value: formatNumber(performance?.client?.meta?.revenue || '0'),
        prevPeriod: formatNumber(
          performance?.client?.meta?.previous_month?.revenue || '0'
        ),
      },
      {
        label: 'ROAS',
        value: formatNumber(performance?.client?.meta?.roas || '0'),
        prevPeriod: formatNumber(
          performance?.client?.meta?.previous_month?.roas || '0'
        ),
      },
    ],
  };

  const cardData = dataMapping[type] || [];
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 sm:gap-0 mt-5 p-1">
      {cardData.map((metric, index) => {
        const { deltaType, color } = getDeltaTypeAndColor(
          metric?.value,
          metric?.prevPeriod
        );

        return (
          <div
            key={index}
            className={`flex flex-col gap-y-4 p-6 border
            ${index === 0 ? 'rounded-tl-xl' : ''} 
            ${index === 2 ? 'rounded-tr-xl' : ''} 
            ${index === cardData?.length - 3 ? 'rounded-bl-xl' : ''} 
            ${index === cardData?.length - 1 ? 'rounded-br-xl' : ''}`}
          >
            <p className="font-inter font-[600] text-sm overflow-hidden text-[#000] dark:text-white">
              {metric?.label}
            </p>
            <div className="flex items-center gap-2">
              <p className="font-bold text-[30px] overflow-hidden text-[#000] dark:text-white">
                {metric?.value}
              </p>
              <Icon name={deltaType} size={25} color={color} />
            </div>
            <div className="flex gap-1 items-center w-full mt-0">
              <div className="font-inter font-[400] text-xs text-[#7C7E7F]">
                Previous period
              </div>
              <div className={`w-2 h-2 rounded-full bg-[${color}]`} />
              <div className={`font-inter text-xs text-[${color}] mr-1`}>
                {metric?.prevPeriod}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MetricsCards;
