import React from 'react';

interface PlusProps {
  color?: string;
}

const Plus: React.FC<PlusProps> = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M7.00033 1.16667V12.8333M1.16699 7H12.8337"
      stroke={color || '#001C44'}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Plus;
