import React, { useState } from 'react';
import ChangeHistory from './Changes';
import Settings from './Settings';
import Metrics from './Metrics';
import useWeeklyReportStore from '../../../store/useWeeklyStore';
interface WeeklyProps {
  selectedReport: any;
  closeModal: () => void;
  fetchReports: () => void;
}
const SendModal: React.FC<WeeklyProps> = ({
  selectedReport,
  closeModal,
  fetchReports,
}) => {
  const [saveError, setSaveError] = useState(false);
  const { sendReport } = useWeeklyReportStore();

  const handleSend = () => {
    let reportIds;

    if (Array.isArray(selectedReport)) {
      reportIds = selectedReport.map((report) => report.client?.id);
    } else {
      reportIds = [selectedReport.id];
    }

    let data = {
      reports: reportIds,
      comment: 'Reports sent successfully',
      report_basis: 'weekly',
    };
    sendReport(data)
      .then((response) => {
        fetchReports();
        closeModal();
      })
      .catch((error: Error) => {
        console.error('Error sending report:', error.message);
      });
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className="flex w-[100%] h-[100%] justify-center items-center pt-[10%]"
    >
      <div className="flex flex-col h-[230px]  w-[500px] rounded-lg bg-white p-5">
        <div className="flex justify-between items-center h-[20px] mb-4">
          <span></span>
          <span onClick={closeModal} className="cursor-pointer text-lg">
            X
          </span>
        </div>
        {saveError && <span className="text-red-500">{saveError}</span>}
        <span className="text-xl font-inter text-center">
          Are you sure you want to send this report?
        </span>
        <span className="text-xl font-inter text-center">
          {selectedReport?.name}
        </span>
        <div className="flex justify-around mt-4">
          <button
            onClick={closeModal}
            className="py-2 px-4 mt-4 border-[#808EA2] border-2 text-[#001C44] rounded-md w-[40%]"
          >
            Cancel
          </button>
          <button
            onClick={handleSend}
            className="py-2 px-4 mt-4 bg-[green] text-white rounded-md w-[40%]"
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default SendModal;
