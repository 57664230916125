import React, { useState, useEffect, Fragment } from 'react';
import ReactSelect from '../../../components/ReactSelect';

// SEO Restart EXPORT
export const SEORestart = ({ parentCallback, client }) => {
  var contractFinish = new Date(parseInt(client.seo_google.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [restartValues, setValues] = useState({
    type: 'restart',
    service: 'seo_google',
    restartCategory: '',
    restartDate: '',
    restartSave: '',
    restartInfo: '',
    restartBudget: client.seo_google.revenue,
  });

  const {
    type,
    service,
    restartBudget,
    restartCategory,
    restartDate,
    restartSave,
    restartInfo,
  } = restartValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...restartValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...restartValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(restartValues);
  }, [restartValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        SEO GOOGLE Restart Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            When does the account turn back on?
          </p>
          <div>
            <ReactSelect
              handleOnChange={handleSelectChange('restartCategory')}
              options={[
                {
                  value: 'Treat as if never turned off',
                  label: 'Treat as if never turned off',
                },
                {
                  value: 'Specific Date',
                  label: 'Specific Date (Must be 1st or 15th)',
                },
              ]}
              placeholder="PLEASE SELECT"
            />
            {restartCategory === 'Specific Date' ? (
              <>
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date to restart client
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onKeyDown={(e) => e.preventDefault()}
                    onInput={handleChange('restartDate')}
                    value={restartDate}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What did you do to save the account?
          </p>
          <div className="text-[#7C7E7F]">
            <textarea
              onInput={handleChange('restartSave')}
              value={restartSave}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
          Additional Information?
          </p>
          <div className="text-[#7C7E7F]">
            <textarea
              onInput={handleChange('restartInfo')}
              value={restartInfo}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={restartBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// PPC RESTART EXPORT
export const PPCRestart = ({ parentCallback, client }) => {
  var contractFinish = new Date(parseInt(client.ppc_google.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [restartValues, setValues] = useState({
    type: 'restart',
    service: 'ppc_google',
    restartCategory: '',
    restartDate: '',
    restartSave: '',
    restartInfo: '',
    restartBudget: client.ppc_google.revenue,
  });

  const {
    type,
    service,
    restartBudget,
    restartCategory,
    restartDate,
    restartSave,
    restartInfo,
  } = restartValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...restartValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...restartValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(restartValues);
  }, [restartValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        PPC GOOGLE Restart Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            When does the account turn back on?
          </p>
          <div>
            <ReactSelect
              handleOnChange={handleSelectChange('restartCategory')}
              options={[
                {
                  value: 'Treat as if never turned off',
                  label: 'Treat as if never turned off',
                },
                {
                  value: 'Specific Date',
                  label: 'Specific Date (Must be 1st or 15th)',
                },
              ]}
              placeholder="PLEASE SELECT"
            />
            {restartCategory === 'Specific Date' ? (
              <>
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date to restart client
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onKeyDown={(e) => e.preventDefault()}
                    onInput={handleChange('restartDate')}
                    value={restartDate}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What did you do to save the account?
          </p>
          <div className="text-[#7C7E7F]">
            <textarea
              onInput={handleChange('restartSave')}
              value={restartSave}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
          Additional Information?
          </p>
          <div className="text-[#7C7E7F]">
            <textarea
              onInput={handleChange('restartInfo')}
              value={restartInfo}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={restartBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// SEO Restart EXPORT
export const SEOBINGRestart = ({ parentCallback, client }) => {
  var contractFinish = new Date(parseInt(client.seo_bing.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [restartValues, setValues] = useState({
    type: 'restart',
    service: 'seo_bing',
    restartCategory: '',
    restartDate: '',
    restartSave: '',
    restartInfo: '',
    restartBudget: client.seo_bing.revenue,
  });

  const {
    type,
    service,
    restartBudget,
    restartCategory,
    restartDate,
    restartSave,
    restartInfo,
  } = restartValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...restartValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...restartValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(restartValues);
  }, [restartValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Microsoft SEO Restart Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            When does the account turn back on?
          </p>
          <div>
            <ReactSelect
              handleOnChange={handleSelectChange('restartCategory')}
              options={[
                {
                  value: 'Treat as if never turned off',
                  label: 'Treat as if never turned off',
                },
                {
                  value: 'Specific Date',
                  label: 'Specific Date (Must be 1st or 15th)',
                },
              ]}
              placeholder="PLEASE SELECT"
            />
            {restartCategory === 'Specific Date' ? (
              <>
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date to restart client
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onKeyDown={(e) => e.preventDefault()}
                    onInput={handleChange('restartDate')}
                    value={restartDate}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What did you do to save the account?
          </p>
          <div className="text-[#7C7E7F]">
            <textarea
              onInput={handleChange('restartSave')}
              value={restartSave}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
          Additional Information?
          </p>
          <div className="text-[#7C7E7F]">
            <textarea
              onInput={handleChange('restartInfo')}
              value={restartInfo}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={restartBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// PPC RESTART EXPORT
export const PPCBINGRestart = ({ parentCallback, client }) => {
  var contractFinish = new Date(parseInt(client.social_bing.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [restartValues, setValues] = useState({
    type: 'restart',
    service: 'social_bing',
    restartCategory: '',
    restartDate: '',
    restartSave: '',
    restartInfo: '',
    restartBudget: client.social_bing.revenue,
  });

  const {
    type,
    service,
    restartBudget,
    restartCategory,
    restartDate,
    restartSave,
    restartInfo,
  } = restartValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...restartValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...restartValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(restartValues);
  }, [restartValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Microsoft Ads Restart Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            When does the account turn back on?
          </p>
          <div>
            <ReactSelect
              handleOnChange={handleSelectChange('restartCategory')}
              options={[
                {
                  value: 'Treat as if never turned off',
                  label: 'Treat as if never turned off',
                },
                {
                  value: 'Specific Date',
                  label: 'Specific Date (Must be 1st or 15th)',
                },
              ]}
              placeholder="PLEASE SELECT"
            />
            {restartCategory === 'Specific Date' ? (
              <>
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date to restart client
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onKeyDown={(e) => e.preventDefault()}
                    onInput={handleChange('restartDate')}
                    value={restartDate}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What did you do to save the account?
          </p>
          <div className="text-[#7C7E7F]">
            <textarea
              onInput={handleChange('restartSave')}
              value={restartSave}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
          Additional Information?
          </p>
          <div className="text-[#7C7E7F]">
            <textarea
              onInput={handleChange('restartInfo')}
              value={restartInfo}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={restartBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// REM RESTART EXPORT
export const REMRestart = ({ parentCallback, client }) => {
  var contractFinish = new Date(parseInt(client.display.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [restartValues, setValues] = useState({
    type: 'restart',
    service: 'display',
    restartCategory: '',
    restartDate: '',
    restartSave: '',
    restartInfo: '',
    restartBudget: client.display.revenue,
  });

  const {
    type,
    service,
    restartBudget,
    restartCategory,
    restartDate,
    restartSave,
    restartInfo,
  } = restartValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...restartValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...restartValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(restartValues);
  }, [restartValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Remarketing Restart Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            When does the account turn back on?
          </p>
          <div>
            <ReactSelect
              handleOnChange={handleSelectChange('restartCategory')}
              options={[
                {
                  value: 'Treat as if never turned off',
                  label: 'Treat as if never turned off',
                },
                {
                  value: 'Specific Date',
                  label: 'Specific Date (Must be 1st or 15th)',
                },
              ]}
              placeholder="PLEASE SELECT"
            />
            {restartCategory === 'Specific Date' ? (
              <>
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date to restart client
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onKeyDown={(e) => e.preventDefault()}
                    onInput={handleChange('restartDate')}
                    value={restartDate}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What did you do to save the account?
          </p>
          <div className="text-[#7C7E7F]">
            <textarea
              onInput={handleChange('restartSave')}
              value={restartSave}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
          Additional Information?
          </p>
          <div className="text-[#7C7E7F]">
            <textarea
              onInput={handleChange('restartInfo')}
              value={restartInfo}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={restartBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// META RESTART EXPORT
export const METARestart = ({ parentCallback, client }) => {
  var contractFinish = new Date(parseInt(client.social_meta.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [restartValues, setValues] = useState({
    type: 'restart',
    service: 'social_meta',
    restartCategory: '',
    restartDate: '',
    restartSave: '',
    restartInfo: '',
    restartBudget: client.social_meta.revenue,
  });

  const {
    type,
    service,
    restartBudget,
    restartCategory,
    restartDate,
    restartSave,
    restartInfo,
  } = restartValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...restartValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...restartValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(restartValues);
  }, [restartValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        META Restart Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            When does the account turn back on?
          </p>
          <div>
            <ReactSelect
              handleOnChange={handleSelectChange('restartCategory')}
              options={[
                {
                  value: 'Treat as if never turned off',
                  label: 'Treat as if never turned off',
                },
                {
                  value: 'Specific Date',
                  label: 'Specific Date (Must be 1st or 15th)',
                },
              ]}
              placeholder="PLEASE SELECT"
            />
            {restartCategory === 'Specific Date' ? (
              <>
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date to restart client
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onKeyDown={(e) => e.preventDefault()}
                    onInput={handleChange('restartDate')}
                    value={restartDate}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What did you do to save the account?
          </p>
          <div className="text-[#7C7E7F]">
            <textarea
              onInput={handleChange('restartSave')}
              value={restartSave}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
          Additional Information?
          </p>
          <div className="text-[#7C7E7F]">
            <textarea
              onInput={handleChange('restartInfo')}
              value={restartInfo}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={restartBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// PINTEREST RESTART EXPORT
export const PINTERESTRestart = ({ parentCallback, client }) => {
  var contractFinish = new Date(parseInt(client.social_pinterest.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [restartValues, setValues] = useState({
    type: 'restart',
    service: 'social_pinterest',
    restartCategory: '',
    restartDate: '',
    restartSave: '',
    restartInfo: '',
    restartBudget: client.social_pinterest.revenue,
  });

  const {
    type,
    service,
    restartBudget,
    restartCategory,
    restartDate,
    restartSave,
    restartInfo,
  } = restartValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...restartValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...restartValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(restartValues);
  }, [restartValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        PINTEREST Restart Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            When does the account turn back on?
          </p>
          <div>
            <ReactSelect
              handleOnChange={handleSelectChange('restartCategory')}
              options={[
                {
                  value: 'Treat as if never turned off',
                  label: 'Treat as if never turned off',
                },
                {
                  value: 'Specific Date',
                  label: 'Specific Date (Must be 1st or 15th)',
                },
              ]}
              placeholder="PLEASE SELECT"
            />
            {restartCategory === 'Specific Date' ? (
              <>
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date to restart client
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onKeyDown={(e) => e.preventDefault()}
                    onInput={handleChange('restartDate')}
                    value={restartDate}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </div>

        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What did you do to save the account?
          </p>
          <div className="text-[#7C7E7F]">
            <textarea
              onInput={handleChange('restartSave')}
              value={restartSave}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
          Additional Information?
          </p>
          <div className="text-[#7C7E7F]">
            <textarea
              onInput={handleChange('restartInfo')}
              value={restartInfo}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={restartBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// TIKTOK RESTART EXPORT
export const TIKTOKRestart = ({ parentCallback, client }) => {
  var contractFinish = new Date(parseInt(client.social_tiktok.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [restartValues, setValues] = useState({
    type: 'restart',
    service: 'social_tiktok',
    restartCategory: '',
    restartDate: '',
    restartSave: '',
    restartInfo: '',
    restartBudget: client.social_tiktok.revenue,
  });

  const {
    type,
    service,
    restartBudget,
    restartCategory,
    restartDate,
    restartSave,
    restartInfo,
  } = restartValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...restartValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...restartValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(restartValues);
  }, [restartValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        TIKTOK Restart Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            When does the account turn back on?
          </p>
          <div>
            <ReactSelect
              handleOnChange={handleSelectChange('restartCategory')}
              options={[
                {
                  value: 'Treat as if never turned off',
                  label: 'Treat as if never turned off',
                },
                {
                  value: 'Specific Date',
                  label: 'Specific Date (Must be 1st or 15th)',
                },
              ]}
              placeholder="PLEASE SELECT"
            />
            {restartCategory === 'Specific Date' ? (
              <>
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date to restart client
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onKeyDown={(e) => e.preventDefault()}
                    onInput={handleChange('restartDate')}
                    value={restartDate}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What did you do to save the account?
          </p>
          <div className="text-[#7C7E7F]">
            <textarea
              onInput={handleChange('restartSave')}
              value={restartSave}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
          Additional Information?
          </p>
          <div className="text-[#7C7E7F]">
            <textarea
              onInput={handleChange('restartInfo')}
              value={restartInfo}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={restartBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// LINKEDIN RESTART EXPORT
export const LINKEDINRestart = ({ parentCallback, client }) => {
  var contractFinish = new Date(parseInt(client.social_linkedin.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [restartValues, setValues] = useState({
    type: 'restart',
    service: 'social_linkedin',
    restartCategory: '',
    restartDate: '',
    restartSave: '',
    restartInfo: '',
    restartBudget: client.social_linkedin.revenue,
  });

  const {
    type,
    service,
    restartBudget,
    restartCategory,
    restartDate,
    restartSave,
    restartInfo,
  } = restartValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...restartValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...restartValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(restartValues);
  }, [restartValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        LINKEDIN Restart Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            When does the account turn back on?
          </p>
          <div>
            <ReactSelect
              handleOnChange={handleSelectChange('restartCategory')}
              options={[
                {
                  value: 'Treat as if never turned off',
                  label: 'Treat as if never turned off',
                },
                {
                  value: 'Specific Date',
                  label: 'Specific Date (Must be 1st or 15th)',
                },
              ]}
              placeholder="PLEASE SELECT"
            />
            {restartCategory === 'Specific Date' ? (
              <>
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date to restart client
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onKeyDown={(e) => e.preventDefault()}
                    onInput={handleChange('restartDate')}
                    value={restartDate}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What did you do to save the account?
          </p>
          <div className="text-[#7C7E7F]">
            <textarea
              onInput={handleChange('restartSave')}
              value={restartSave}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
          Additional Information?
          </p>
          <div className="text-[#7C7E7F]">
            <textarea
              onInput={handleChange('restartInfo')}
              value={restartInfo}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={restartBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// PROGRAMMATIC RESTART EXPORT
export const PROGRAMMATICRestart = ({ parentCallback, client }) => {
  var contractFinish = new Date(parseInt(client.social_programmatic.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [restartValues, setValues] = useState({
    type: 'restart',
    service: 'social_programmatic',
    restartCategory: '',
    restartDate: '',
    restartSave: '',
    restartInfo: '',
    restartBudget: client.social_programmatic.revenue,
  });

  const {
    type,
    service,
    restartBudget,
    restartCategory,
    restartDate,
    restartSave,
    restartInfo,
  } = restartValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...restartValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...restartValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(restartValues);
  }, [restartValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        PROGRAMMATIC Restart Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            When does the account turn back on?
          </p>
          <div>
            <ReactSelect
              handleOnChange={handleSelectChange('restartCategory')}
              options={[
                {
                  value: 'Treat as if never turned off',
                  label: 'Treat as if never turned off',
                },
                {
                  value: 'Specific Date',
                  label: 'Specific Date (Must be 1st or 15th)',
                },
              ]}
              placeholder="PLEASE SELECT"
            />
            {restartCategory === 'Specific Date' ? (
              <>
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date to restart client
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onKeyDown={(e) => e.preventDefault()}
                    onInput={handleChange('restartDate')}
                    value={restartDate}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What did you do to save the account?
          </p>
          <div className="text-[#7C7E7F]">
            <textarea
              onInput={handleChange('restartSave')}
              value={restartSave}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
          Additional Information?
          </p>
          <div className="text-[#7C7E7F]">
            <textarea
              onInput={handleChange('restartInfo')}
              value={restartInfo}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={restartBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// CREATIVE RESTART EXPORT
export const CREATIVERestart = ({ parentCallback, client }) => {
  var contractFinish = new Date(parseInt(client.social_creative.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [restartValues, setValues] = useState({
    type: 'restart',
    service: 'social_creative',
    restartCategory: '',
    restartDate: '',
    restartSave: '',
    restartInfo: '',
    restartBudget: client.social_creative.revenue,
  });

  const {
    type,
    service,
    restartBudget,
    restartCategory,
    restartDate,
    restartSave,
    restartInfo,
  } = restartValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...restartValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...restartValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(restartValues);
  }, [restartValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        CREATIVE Restart Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            When does the account turn back on?
          </p>
          <div>
            <ReactSelect
              handleOnChange={handleSelectChange('restartCategory')}
              options={[
                {
                  value: 'Treat as if never turned off',
                  label: 'Treat as if never turned off',
                },
                {
                  value: 'Specific Date',
                  label: 'Specific Date (Must be 1st or 15th)',
                },
              ]}
              placeholder="PLEASE SELECT"
            />
            {restartCategory === 'Specific Date' ? (
              <>
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date to restart client
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onKeyDown={(e) => e.preventDefault()}
                    onInput={handleChange('restartDate')}
                    value={restartDate}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What did you do to save the account?
          </p>
          <div className="text-[#7C7E7F]">
            <textarea
              onInput={handleChange('restartSave')}
              value={restartSave}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
          Additional Information?
          </p>
          <div className="text-[#7C7E7F]">
            <textarea
              onInput={handleChange('restartInfo')}
              value={restartInfo}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={restartBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// TACTICAL RESTART EXPORT
export const TACTICALRestart = ({ parentCallback, client }) => {
  var contractFinish = new Date(parseInt(client.social_tactical.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [restartValues, setValues] = useState({
    type: 'restart',
    service: 'social_tactical',
    restartCategory: '',
    restartDate: '',
    restartSave: '',
    restartInfo: '',
    restartBudget: client.social_tactical.revenue,
  });

  const {
    type,
    service,
    restartBudget,
    restartCategory,
    restartDate,
    restartSave,
    restartInfo,
  } = restartValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...restartValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...restartValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(restartValues);
  }, [restartValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        TACTICAL Restart Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            When does the account turn back on?
          </p>
          <div>
            <ReactSelect
              handleOnChange={handleSelectChange('restartCategory')}
              options={[
                {
                  value: 'Treat as if never turned off',
                  label: 'Treat as if never turned off',
                },
                {
                  value: 'Specific Date',
                  label: 'Specific Date (Must be 1st or 15th)',
                },
              ]}
              placeholder="PLEASE SELECT"
            />
            {restartCategory === 'Specific Date' ? (
              <>
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date to restart client
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onKeyDown={(e) => e.preventDefault()}
                    onInput={handleChange('restartDate')}
                    value={restartDate}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What did you do to save the account?
          </p>
          <div className="text-[#7C7E7F]">
            <textarea
              onInput={handleChange('restartSave')}
              value={restartSave}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
          Additional Information?
          </p>
          <div className="text-[#7C7E7F]">
            <textarea
              onInput={handleChange('restartInfo')}
              value={restartInfo}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={restartBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// WEBDEV RESTART EXPORT
export const WEBDEVRestart = ({ parentCallback, client }) => {
  var contractFinish = new Date(parseInt(client.web_maintenance.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [restartValues, setValues] = useState({
    type: 'restart',
    service: 'web_maintenance',
    restartCategory: '',
    restartDate: '',
    restartSave: '',
    restartInfo: '',
    restartBudget: client.web_maintenance.revenue,
  });

  const {
    type,
    service,
    restartBudget,
    restartCategory,
    restartDate,
    restartSave,
    restartInfo,
  } = restartValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...restartValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...restartValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(restartValues);
  }, [restartValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Web Maintenance Restart Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            When does the account turn back on?
          </p>
          <div>
            <ReactSelect
              handleOnChange={handleSelectChange('restartCategory')}
              options={[
                {
                  value: 'Treat as if never turned off',
                  label: 'Treat as if never turned off',
                },
                {
                  value: 'Specific Date',
                  label: 'Specific Date (Must be 1st or 15th)',
                },
              ]}
              placeholder="PLEASE SELECT"
            />
            {restartCategory === 'Specific Date' ? (
              <>
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date to restart client
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onKeyDown={(e) => e.preventDefault()}
                    onInput={handleChange('restartDate')}
                    value={restartDate}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What did you do to save the account?
          </p>
          <div className="text-[#7C7E7F]">
            <textarea
              onInput={handleChange('restartSave')}
              value={restartSave}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
          Additional Information?
          </p>
          <div className="text-[#7C7E7F]">
            <textarea
              onInput={handleChange('restartInfo')}
              value={restartInfo}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={restartBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// WEBHOST RESTART EXPORT
export const WEBHOSTRestart = ({ parentCallback, client }) => {
  var contractFinish = new Date(parseInt(client.web_hosting.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [restartValues, setValues] = useState({
    type: 'restart',
    service: 'web_hosting',
    restartCategory: '',
    restartDate: '',
    restartSave: '',
    restartInfo: '',
    restartBudget: client.web_hosting.revenue,
  });

  const {
    type,
    service,
    restartBudget,
    restartCategory,
    restartDate,
    restartSave,
    restartInfo,
  } = restartValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...restartValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...restartValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(restartValues);
  }, [restartValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Web Hosting Restart Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            When does the account turn back on?
          </p>
          <div>
            <ReactSelect
              handleOnChange={handleSelectChange('restartCategory')}
              options={[
                {
                  value: 'Treat as if never turned off',
                  label: 'Treat as if never turned off',
                },
                {
                  value: 'Specific Date',
                  label: 'Specific Date (Must be 1st or 15th)',
                },
              ]}
              placeholder="PLEASE SELECT"
            />
            {restartCategory === 'Specific Date' ? (
              <>
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date to restart client
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onKeyDown={(e) => e.preventDefault()}
                    onInput={handleChange('restartDate')}
                    value={restartDate}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What did you do to save the account?
          </p>
          <div className="text-[#7C7E7F]">
            <textarea
              onInput={handleChange('restartSave')}
              value={restartSave}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
          Additional Information?
          </p>
          <div className="text-[#7C7E7F]">
            <textarea
              onInput={handleChange('restartInfo')}
              value={restartInfo}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={restartBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};


// WEB LANDING RESTART EXPORT
export const WEBLANDINGRestart = ({ parentCallback, client }) => {
  var contractFinish = new Date(parseInt(client.web_landing.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [restartValues, setValues] = useState({
    type: 'restart',
    service: 'web_landing',
    restartCategory: '',
    restartDate: '',
    restartSave: '',
    restartInfo: '',
    restartBudget: client.web_landing.revenue,
  });

  const {
    type,
    service,
    restartBudget,
    restartCategory,
    restartDate,
    restartSave,
    restartInfo,
  } = restartValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...restartValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...restartValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(restartValues);
  }, [restartValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Web Landing Restart Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            When does the account turn back on?
          </p>
          <div>
            <ReactSelect
              handleOnChange={handleSelectChange('restartCategory')}
              options={[
                {
                  value: 'Treat as if never turned off',
                  label: 'Treat as if never turned off',
                },
                {
                  value: 'Specific Date',
                  label: 'Specific Date (Must be 1st or 15th)',
                },
              ]}
              placeholder="PLEASE SELECT"
            />
            {restartCategory === 'Specific Date' ? (
              <>
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date to restart client
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onKeyDown={(e) => e.preventDefault()}
                    onInput={handleChange('restartDate')}
                    value={restartDate}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What did you do to save the account?
          </p>
          <div className="text-[#7C7E7F]">
            <textarea
              onInput={handleChange('restartSave')}
              value={restartSave}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
          Additional Information?
          </p>
          <div className="text-[#7C7E7F]">
            <textarea
              onInput={handleChange('restartInfo')}
              value={restartInfo}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={restartBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};


// WEB PROJECT RESTART EXPORT
export const WEBPROJECTRestart = ({ parentCallback, client }) => {
  var contractFinish = new Date(parseInt(client.web_project.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [restartValues, setValues] = useState({
    type: 'restart',
    service: 'web_project',
    restartCategory: '',
    restartDate: '',
    restartSave: '',
    restartInfo: '',
    restartBudget: client.web_project.revenue,
  });

  const {
    type,
    service,
    restartBudget,
    restartCategory,
    restartDate,
    restartSave,
    restartInfo,
  } = restartValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...restartValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...restartValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(restartValues);
  }, [restartValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Web Project Restart Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            When does the account turn back on?
          </p>
          <div>
            <ReactSelect
              handleOnChange={handleSelectChange('restartCategory')}
              options={[
                {
                  value: 'Treat as if never turned off',
                  label: 'Treat as if never turned off',
                },
                {
                  value: 'Specific Date',
                  label: 'Specific Date (Must be 1st or 15th)',
                },
              ]}
              placeholder="PLEASE SELECT"
            />
            {restartCategory === 'Specific Date' ? (
              <>
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date to restart client
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onKeyDown={(e) => e.preventDefault()}
                    onInput={handleChange('restartDate')}
                    value={restartDate}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What did you do to save the account?
          </p>
          <div className="text-[#7C7E7F]">
            <textarea
              onInput={handleChange('restartSave')}
              value={restartSave}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
          Additional Information?
          </p>
          <div className="text-[#7C7E7F]">
            <textarea
              onInput={handleChange('restartInfo')}
              value={restartInfo}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={restartBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
