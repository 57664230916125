import { Grid } from '@tremor/react';
import AreaCardChart from '../AreaCardChart';
import { formatToCurrency, formatNumber } from '../../../utils/common';

interface IProps {
  isLeadGen?: boolean;
  isMeta?: boolean;
  topPerformanceMeta?: any;
  requestMetaAccess?: any;
  spend?: any;
  lastMonthMetaSpend?: any;
  metaClicks?: any;
  lastMonthMetaClicks?: any;
  metaConversions?: any;
  lastMonthMetaConversions?: any;
  metaCostPerConversion?: any;
  lastMonthMetaCostPerConversion?: any;
  revenue?: any;
  lastMonthMetaRevenue?: any;
  roas?: any;
  lastMonthMetaROAS?: any;
}

const KPIMeta = ({
  isLeadGen,
  isMeta,
  topPerformanceMeta,
  requestMetaAccess,
  spend,
  lastMonthMetaSpend,
  metaClicks,
  lastMonthMetaClicks,
  metaConversions,
  lastMonthMetaConversions,
  metaCostPerConversion,
  lastMonthMetaCostPerConversion,
  revenue,
  lastMonthMetaRevenue,
  roas,
  lastMonthMetaROAS,
}: IProps) => {
  return (
    <Grid
      numItems={1}
      numItemsSm={1}
      numItemsMd={2}
      numItemsLg={4}
      className="gap-4"
    >
      <AreaCardChart
        title={`${topPerformanceMeta?.spend?.label || 'Spend'}`}
        chatData={spend}
        category={['Spend']}
        status={isMeta}
        prevPeriod={topPerformanceMeta?.spend?.previous_period}
        previousYear={topPerformanceMeta?.spend?.previous_year}
        totalValue={formatToCurrency(lastMonthMetaSpend)}
        isLoading={!isMeta ? false : topPerformanceMeta.loading}
        error={topPerformanceMeta.error}
        hasDollar={true}
        requestAccess={requestMetaAccess}
        info={topPerformanceMeta?.spend?.info || 'Spend'}
        icon="meta"
        showCurrentMonth={true}
      />
      <AreaCardChart
        title={`${topPerformanceMeta?.clicks?.label || 'Clicks'}`}
        chatData={metaClicks}
        category={['Clicks']}
        status={isMeta}
        prevPeriod={topPerformanceMeta?.clicks?.previous_period}
        previousYear={topPerformanceMeta?.clicks?.previous_year}
        totalValue={formatNumber(lastMonthMetaClicks)}
        isLoading={!isMeta ? false : topPerformanceMeta.loading}
        error={topPerformanceMeta.error}
        hasDollar={true}
        requestAccess={requestMetaAccess}
        info={topPerformanceMeta?.clicks?.info || 'Clicks'}
        icon="meta"
        showCurrentMonth={true}
      />
      {isLeadGen ? (
        <>
          <AreaCardChart
            title={topPerformanceMeta?.conversions?.label || 'Conversions'}
            chatData={metaConversions}
            category={['Conversions']}
            status={isMeta}
            prevPeriod={topPerformanceMeta?.conversions?.previous_period}
            previousYear={topPerformanceMeta?.conversions?.previous_year}
            totalValue={formatNumber(lastMonthMetaConversions)}
            isLoading={!isMeta ? false : topPerformanceMeta.loading}
            error={topPerformanceMeta.error}
            requestAccess={requestMetaAccess}
            info={topPerformanceMeta?.conversions?.info || 'Conversions'}
            icon="meta"
            showCurrentMonth={true}
          />
          <AreaCardChart
            title={topPerformanceMeta?.cpc?.label || 'Cost Per Conversion'}
            chatData={metaCostPerConversion}
            category={['Cpc']}
            status={isMeta}
            prevPeriod={topPerformanceMeta?.cpc?.previous_period}
            previousYear={topPerformanceMeta?.cpc?.previous_year}
            totalValue={formatToCurrency(lastMonthMetaCostPerConversion)}
            isLoading={!isMeta ? false : topPerformanceMeta.loading}
            error={topPerformanceMeta.error}
            requestAccess={requestMetaAccess}
            hasDollar={true}
            info={topPerformanceMeta?.cpc?.info}
            icon="meta"
            showCurrentMonth={true}
          />
        </>
      ) : (
        <>
          <AreaCardChart
            title={'Revenue'}
            chatData={revenue}
            category={['Revenue']}
            status={isMeta}
            prevPeriod={topPerformanceMeta?.revenue?.previous_period}
            previousYear={topPerformanceMeta?.revenue?.previous_year}
            totalValue={formatToCurrency(lastMonthMetaRevenue)}
            isLoading={!isMeta ? false : topPerformanceMeta.loading}
            error={topPerformanceMeta.error}
            hasDollar={true}
            requestAccess={requestMetaAccess}
            info={topPerformanceMeta?.revenue?.info || 'Revenue'}
            icon="meta"
            showCurrentMonth={true}
          />
          <AreaCardChart
            title="ROAS"
            chatData={roas}
            category={['Roas']}
            status={isMeta}
            prevPeriod={topPerformanceMeta?.roas?.previous_period}
            previousYear={topPerformanceMeta?.roas?.previous_year}
            totalValue={formatNumber(lastMonthMetaROAS)}
            isLoading={!isMeta ? false : topPerformanceMeta.loading}
            error={topPerformanceMeta.error}
            requestAccess={requestMetaAccess}
            info={topPerformanceMeta?.roas?.info}
            icon="meta"
            showCurrentMonth={true}
          />
        </>
      )}
    </Grid>
  );
};

export default KPIMeta;
