import { useLocation } from 'react-router-dom';
import ComingSoon from '../../components/ComingSoon';
import PageHeader from '../../components/Dashboard/PageHeader';
import Security from './Security';
import ChangeRequests from './ChangeRequests';
import Permissions from './Permissions';
import Syncing from './Syncing';
import ProfileSettings from './Profile';
import LogsSettings from './Logs';
import useAuthStore from '../../store/useAuthStore';
import useUserStore from '../../store/useUserStore';
import ImageIcon from '../../components/ImageIcon';
import { MdVerified } from 'react-icons/md';

export default function OmgSettings() {
  const { currentUser } = useAuthStore((state) => state);
  const { user } = useUserStore((state) => state);
  const location = useLocation();

  const pic = user?.profile_pic || currentUser?.profile_pic;
  const name = `${currentUser?.first_name}  ${currentUser?.last_name}`;
  const role = currentUser?.gurulytics_role?.name;

  let ContentComponent: any;
  location.pathname === '/omg-settings/security'
    ? (ContentComponent = Security)
    : location.pathname === '/omg-settings/change-requests'
      ? (ContentComponent = ChangeRequests)
      : location.pathname === '/omg-settings/logs'
        ? (ContentComponent = LogsSettings)
        : location.pathname === '/omg-settings/syncing'
          ? (ContentComponent = Syncing)
          : location.pathname === '/omg-settings/profile'
            ? (ContentComponent = ProfileSettings)
            : (ContentComponent = null);

  let subtitle;

  location.pathname === '/omg-settings/syncing'
    ? (subtitle =
        'Keep your content fresh and aligned across the platform with Page Syncs. Automate updates and synchronization to ensure consistency and accuracy in real-time.')
    : location.pathname === '/omg-settings/profile'
      ? (subtitle = 'Update your photo and personal details here.')
      : (subtitle = '');

  const capitalizeFirstLetter = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1);
  const title = capitalizeFirstLetter(
    location.pathname
      .replace('omg-settings/', '')
      .replace('/', '')
      .replace('-', ' ')
  );

  const isLogs = title === 'Logs';

  return (
    <div className="flex flex-col px-2">
      {!isLogs && (
        // <PageHeader
        //   title={title}
        //   subtitle={subtitle}
        //   customClass="px-8"
        //   hideDate
        //   hideDomain
        // />
        <div className="p-6">
          <div className="h-[240px] relative w-full bg-[#0029FF] rounded-lg">
            <div className="absolute bottom-[-80px] left-8">
              <div className="flex gap-4">
                <div className="">
                  {pic ? (
                    <div className="bg-[#fff] p-2 rounded-full relative">
                      <img src={pic} alt="Profile" width={40} height={40} />
                      <MdVerified
                        size={26}
                        color="#0788F5"
                        className="absolute right-2 bottom-6"
                      />
                    </div>
                  ) : (
                    <div className="bg-[#fff] p-2 rounded-full relative">
                      <ImageIcon
                        data={name}
                        size={40}
                        customStyle="text-[50px] bg-[#345578] text-white"
                      />
                      <MdVerified
                        size={26}
                        color="#0788F5"
                        className="absolute right-2 bottom-6"
                      />
                    </div>
                  )}
                </div>
                <div>
                  <div className="text-white text-[30px] font-[600]">
                    {name}
                  </div>
                  <div className="text-white text-sm font-light">{role}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="mt-20">
        {ContentComponent ? <ContentComponent /> : <ComingSoon />}
      </div>
    </div>
  );
}
