import { Grid, Col } from '@tremor/react';
import AreaCardChart from '../../AreaCardChart';
import { formatNumber, formatToCurrency } from '../../../../utils/common';
import MobileView from './MobileView';
import useClientStore from '../../../../store/useClientStore';
import { useRetryFetchFbInsights } from '../../Hooks';

interface IProps {
  clicks?: any;
  ctr?: any;
  cpc?: any;
  dataInfo?: any;
}

const KeywordsEngagement = ({ clicks, ctr, cpc, dataInfo }: IProps) => {
  const { selectedClient } = useClientStore((state) => state);
  const clicksTotal = clicks[clicks.length - 1]?.value;
  const ctrTotal = ctr[ctr.length - 1]?.value;
  const cpcTotal = cpc[cpc.length - 1]?.value;

  const retryFetchFbInsights = useRetryFetchFbInsights()

  return (
    <div>
      <div className="flex justify-start items-center mb-8">
        <h2 className="sm:title-text text-[#001C44] text-[14px] font-[800] dark:text-white sm:text-[1.85rem] px-3 sm:px-0 sm:pb-1">
          Key Performance Metrics - Engagement
        </h2>
      </div>
      <MobileView 
        clicks={clicks} 
        ctr={ctr}
        cpc={cpc} 
        dataInfo={dataInfo} 
        retryMethod={retryFetchFbInsights} />
        
      <div className="hidden sm:block">
        <Grid
          numItems={1}
          numItemsSm={1}
          numItemsMd={2}
          numItemsLg={3}
          className="gap-4"
        >
          <Col className="shadow-md">
            <AreaCardChart
              title="Link Clicks"
              chatData={clicks}
              category={['Link Clicks']}
              info={dataInfo?.clicks?.info}
              prevPeriod={dataInfo?.clicks?.previous_period}
              previousYear={dataInfo?.clicks?.previous_year}
              totalValue={formatNumber(clicksTotal)}
              isLoading={dataInfo.loading}
              error={dataInfo.error}
              icon="meta"
              accountInsights={true}
              propertyId={`Ads ID: ${selectedClient?.facebook_ads?.ads_id}`}
              retryMethod={retryFetchFbInsights}
            />
          </Col>
          <Col className="shadow-md">
            <AreaCardChart
              title="CTR"
              chatData={ctr}
              category={['Ctr']}
              info={dataInfo?.ctr?.info}
              prevPeriod={dataInfo?.ctr?.previous_period}
              previousYear={dataInfo?.ctr?.previous_year}
              totalValue={formatNumber(ctrTotal, true)}
              hasPercent={true}
              isLoading={dataInfo.loading}
              error={dataInfo.error}
              icon="meta"
              accountInsights={true}
              propertyId={`Ads ID: ${selectedClient?.facebook_ads?.ads_id}`}
              retryMethod={retryFetchFbInsights}
            />
          </Col>
          <Col className="shadow-md">
            <AreaCardChart
              title="CPC"
              chatData={cpc}
              category={['Cpc']}
              info={dataInfo?.cpc?.info}
              prevPeriod={dataInfo?.cpc?.previous_period}
              previousYear={dataInfo?.cpc?.previous_year}
              totalValue={formatToCurrency(cpcTotal)}
              hasDollar={true}
              isLoading={dataInfo.loading}
              error={dataInfo.error}
              icon="meta"
              accountInsights={true}
              propertyId={`Ads ID: ${selectedClient?.facebook_ads?.ads_id}`}
              retryMethod={retryFetchFbInsights}
            />
          </Col>
        </Grid>
      </div>
    </div>
  );
};

export default KeywordsEngagement;
