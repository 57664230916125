import React, { useState } from 'react';
import Icon from '../../../assets/icons/SvgComponent';

interface IProps {
  invoiceLink: string;
  onClose: () => void;
}

const PreviewModal = ({ invoiceLink, onClose }: IProps) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  return (
    <div className="absolute right-0">
      <div
        className="w-full h-screen flex justify-end items-center"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="w-[60dvw] h-screen flex justify-center items-center bg-white relative p-8 rounded">
          {/* Close Icon */}
          <div
            className="absolute top-3 right-3 cursor-pointer"
            onClick={onClose}
          >
            <Icon name="Xclose" />
          </div>

          {/* Loading Indicator */}
          {isLoading && (
            <div className="absolute inset-0 flex items-center justify-center bg-gray-100 z-10">
              <div>
                <Icon name="Loading" size={70} />
              </div>
            </div>
          )}

          {/* iframe */}
          <iframe
            src={invoiceLink}
            onLoad={handleIframeLoad}
            className={`w-full h-full rounded ${isLoading ? 'hidden' : ''}`}
          />
        </div>
      </div>
    </div>
  );
};

export default PreviewModal;
