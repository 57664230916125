import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const ToggleDashArrowLeft: React.FC<IconProps> = ({
  color = '#334969',
  size = 80,
}) => (
  <svg
    width="14"
    height="10"
    viewBox="0 0 14 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(14, 0) scale(-1, 1)">
      <path
        d="M11.4596 4.9996H1.5795M9.36698 1.42505L12.4204 4.9369L9.48911 8.57497"
        stroke={color || '#334969'}
        strokeWidth="1.3028"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default ToggleDashArrowLeft;
