import React, { useEffect, useState } from 'react';
import { Input } from './myDetails';
import Select from 'react-select';
import useUserStore from '../../store/useUserStore';
import { FadeLoader } from 'react-spinners';
import { colors } from '../../utils/settings';

interface AddModalProps {
  user?: any;
  onClose?: () => void;
  onSave?: (formData: EditUserState) => void;
  isLoading?: boolean;
  error?: string;
  success?: string;
}

type AddUserState = {
  client: string;
  domain: string;
  first_name: string;
  last_name: string;
  email: string;
  birthdate: string;
  phone: string;
};

type EditUserState = {
  first_name?: string;
  last_name?: string;
  email?: string;
  role?: string;
  birthdate?: string;
  phone?: string;
};

interface rolesOption {
  label: string;
  value: string;
}

const roles: rolesOption[] = [
  { label: 'Staff', value: 'stf' },
  { label: 'Owner', value: 'own' },
];
const rolesStyles = {
  control: (provided: any) => ({
    ...provided,
    width: '333px',
    borderRadius: '0.5rem',
    padding: '2px',
    border: '1px solid #808EA2',
    backgroundColor: 'transparent',
  }),
};
const initialState: AddUserState = {
  client: '',
  domain: '',
  first_name: '',
  last_name: '',
  email: '',
  birthdate: '',
  phone: '',
};

const UserModal: React.FC<AddModalProps> = ({
  user,
  onSave,
  isLoading,
  error,
  success,
}) => {
  const [form, setForm] = useState<EditUserState>(user);

  useEffect(() => {
    // updateUser(user.id, form );
  }, []);
  let inputFields;
  user.id !== 0
    ? (inputFields = [
        {
          label: 'First Name',
          type: 'text',
          name: 'first_name',
          error: 'First Name is required',
        },
        { label: 'Last Name', type: 'text', name: 'last_name' },
        { label: 'Email address', type: 'email', name: 'email' },
        { label: 'Phone Number', type: 'text', name: 'phone' },
      ])
    : (inputFields = [
        { label: 'First Name', type: 'text', name: 'first_name' },
        { label: 'Last Name', type: 'text', name: 'last_name' },
        { label: 'Email address', type: 'email', name: 'email' },
        { label: 'Phone Number', type: 'text', name: 'phone' },
      ]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setForm((prevState) => ({ ...prevState, [name]: value }));
  };

  //handle role
  const handleRole = (selectedOption: rolesOption | null) => {
    if (selectedOption) {
      setForm((prevState) => ({ ...prevState, role: selectedOption.value }));
    }
  };

  return (
    <div
      className="fixed top-0 right-0 bg-white h-screen w-96 flex flex-col z-100 dark:bg-[#262627] dark:border dark:border-[#313233] dark:shadow-bs-card"
      style={{ minHeight: '800px' }}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="relative h-screen overflow-y-auto">
        <div className="px-6 flex  items-start gap-2 self-stretch border-b border-tertiary-colours-neutral-cool-100">
          <h2 className="font-inter text-[#001129] text-xl font-semibold my-6 mb-4 dark:text-[#E6EFF5]">
            {user.id === 0 ? 'Add User' : 'Edit User'}
          </h2>
        </div>
        <div className="flex items-center justify-center h-[30px] my-2 w-full text-center ">
          {isLoading && (
            <FadeLoader
              style={{
                position: 'absolute',
                left: '50%',
                top: '10%',
                zIndex: 9999,
              }}
              color={colors.primary}
            />
          )}
          {error && (
            <span className=" p-2 w-full text-lg font-inter border border-[#FFD6AE] bg-[#FEF6EE] text-[#B93815]">
              {error}
            </span>
          )}
          {success && (
            <span className="p-2 w-full text-lg font-inter border border-[#15b923] bg-[#f4feee] text-[#15b923]">
              {success}
            </span>
          )}
        </div>

        <form className=" flex flex-col justify-between space-y-6 px-6">
          {inputFields.map((field) => (
            <Input
              key={field.name}
              label={field.label}
              type={field.type}
              name={field.name}
              value={form[field.name as keyof EditUserState]}
              onChange={handleChange}
              errorMessage={field.error}
            />
          ))}
          <label className="text-sm text-[#001C44] font-inter dark:text-white">
            Role
          </label>
          <Select
            options={roles}
            onChange={handleRole}
            defaultValue={roles?.find((role) => role.value === form.role)}
            styles={rolesStyles}
          />
        </form>

        <div className="px-6 pt-8 mt-14 border-t border-tertiary-colours-neutral-cool-100">
          <button
            onClick={() => {
              if (onSave) {
                onSave(form);
              }
            }}
            className="btn w-full self-end border border-[#005C9F] bg-[#005C9F] text-white text-md py-2 px-4 rounded-lg font-semibold  font-inter"
          >
            {user.id === 0 ? 'Save' : 'Update'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserModal;
