import { useAccessStore } from '../../store/useAccessStore';
import { Button, Card, List, ListItem } from '@tremor/react';
import Icon from '../../assets/icons/SvgComponent';
import CustomModal from '../../components/CustomModal';
import { useThemeStore } from '../../store/useThemeStore';
import useUserStore from '../../store/useUserStore';
import ViewAccessModal from '../../components/Modals/OMGSettings/viewAccessModal';
import { ToastContainer, toast, Flip } from 'react-toastify';
import DropdownStaff from '../../components/DropdownStaff';
import Alert from '../../components/Alert';
import api from '../../api';
import { useEffect, useState } from 'react';


const ChangeRequests = () => {
  const { fetchAccess, access, loading } = useAccessStore();
  const [accessItem, setAccessItem] = useState({});
  const [openViewAccessModal, setOpenAccessModal] = useState(false);
  const [searchClientText, setSearchClientText] = useState('');
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState({
    selectedDepartment: [],
    selectedMembers: [],
    stateChanged: false,
    departments:[
      {name: 'SEARCH ENGINE OPTIMISATION', internal : 'seo_notifiers'},
      {name: 'PAID MEDIA', internal : 'ppc_notifiers'},
      {name: 'SOCIAL MEDIA', internal : 'social_notifiers'},
      {name: 'DISPLAY ADS', internal : 'display_notifiers'},
      {name: 'WEB DEVELOPMENT', internal : 'web_notifiers'},
      {name: 'ALL NOTIFICATIONS', internal : 'all_notifiers'},
    ],
    seo_notifiers: [],
    ppc_notifiers: [],
    social_notifiers: [],
    display_notifiers: [],
    web_notifiers: [],
    all_notifiers: []
  });

  const { fetchAllUsers, fetchUsers, allUsers, users, updateUserStatus, apiStatus } = useUserStore(
    (state) => state
  );

  const {departments, selectedDepartment, seo_notifiers, ppc_notifiers, social_notifiers, display_notifiers, web_notifiers, all_notifiers} = selectedStaff

  const handleApprove = async () => { 
    const response = await api.NotifierSettings.amendNotifiers({ selectedStaff });
    if (response.message){
      setSelectedStaff({
        ...selectedStaff,
        selectedDepartment: [],
        selectedMembers: [],
        seo_notifiers: response.data.seo_notifiers,
        ppc_notifiers: response.data.ppc_notifiers,
        social_notifiers: response.data.social_notifiers,
        display_notifiers: response.data.display_notifiers,
        web_notifiers: response.data.web_notifiers,
        all_notifiers: response.data.all_notifiers
      })
      toast.success(
        <Alert
          icon=""
          title={`SUCCESS`}
          message={response.message}
        />,
        {
          position: 'top-right',
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
  } else if (response[0].error) {
    toast.error(
      <Alert
        icon=""
        title={`ERROR!`}
        message={response[0]?.error}
      />,
      {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  } else {
    toast.error(
      <Alert
        icon=""
        title={`ERROR!`}
        message={response}
      />,
      {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }
}

const handleIDSync = async (type) => { 
  const response = await api.NotifierSettings.syncIDs({type:type});
  if (response.message){
    toast.success(
      <Alert
        icon=""
        title={`SUCCESS`}
        message={response.message}
      />,
      {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  } else if (response[0].error) {
    toast.error(
      <Alert
        icon=""
        title={`ERROR!`}
        message={response[0]?.error}
      />,
      {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  } else {
    toast.error(
      <Alert
        icon=""
        title={`ERROR!`}
        message={response}
      />,
      {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }
}

const handleCleanUp = async () => { 
  const response = await api.NotifierSettings.cleanUp();

}

  const handleChange = (event: any) => {
      setSearchClientText(event.target.value);
      setOpenDropdown(true);
  };

  const handleRemove = (event: any, type: any) => {
    console.log(event, type)
    setSelectedStaff({
      ...selectedStaff,
      [type]: selectedStaff[type].filter((item) => item !== event),
      stateChanged:true
    });

  };

  const handleCheckboxChange = (value: string) => {
    console.log(selectedStaff.selectedDepartment)
    setSelectedStaff({
      ...selectedStaff,
      selectedDepartment: [value],
    });
  };

  useEffect(() => {
    (async () => {
      const currentStaff = await api.NotifierSettings.getNotifiers();
      setSelectedStaff({
        ...selectedStaff,
        seo_notifiers: currentStaff.notifs.seo_notifiers,
        ppc_notifiers: currentStaff.notifs.ppc_notifiers,
        social_notifiers: currentStaff.notifs.social_notifiers,
        display_notifiers: currentStaff.notifs.display_notifiers,
        web_notifiers: currentStaff.notifs.web_notifiers,
        all_notifiers: currentStaff.notifs.all_notifiers
      })
      console.log(currentStaff)
    })();

    if (searchClientText?.length > 2) {
      autoCompleteFetchClients();
    }
    if (searchClientText === '') {
      setOpenDropdown(false);
    }
  }, [searchClientText]);

  //autocomplete fetch clients
  const autoCompleteFetchClients = () => {
    const filters = {
      level: 'omg',
      status: 'active',
      role: 'all',
      page: 1,
      limit: 100,
      search: searchClientText,
      client_id: '',
    };
    fetchUsers(filters);
    console.log('USERS: ', users)
  };

  return (
    <>
    <div className="flex flex-col p-8  gap-y-5 dark:bg-[#1D1D1D]">
    <span className="font-semibold text-black dark:text-[#fff]">Staff Notifiers</span>
    <hr />
      {loading ? (
        <Card className="w-full relative h-[155px]">
          <div className={`absolute top-[40%] left-[48%] overflow-hidden`}>
            <Icon name="Loading" />
          </div>
        </Card>
      ) : (
      <div className="">
        {departments.map((val, key) =>{
          return (
              <div key={key} className="flex items-center mb-2" style={{float:'left', width:'50%'}}>
                <input
                  id={`checkbox-${key}`}
                  type="radio"
                  name="staff_departments"
                  value={val.internal}
                  checked={selectedDepartment.includes(val.internal)}
                  onChange={() => {
                    handleCheckboxChange(val.internal);
                  }}
                  className="cursor-pointer peer relative h-[16px] w-[16px] shrink-0 appearance-none rounded-[4px] border border-[#808EA2]  disabled:bg-[#808EA2] checked:after:absolute checked:after:left-0 checked:after:top-0 checked:after:h-full checked:after:w-full checked:after:bg-[url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmZmZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPmljb25fYnlfUG9zaGx5YWtvdjEwPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjZmZmZmZmIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNi4wMDAwMDAsIDI2LjAwMDAwMCkiPjxwYXRoIGQ9Ik0xNy45OTk5ODc4LDMyLjQgTDEwLjk5OTk4NzgsMjUuNCBDMTAuMjI2Nzg5MSwyNC42MjY4MDE0IDguOTczMTg2NDQsMjQuNjI2ODAxNCA4LjE5OTk4Nzc5LDI1LjQgTDguMTk5OTg3NzksMjUuNCBDNy40MjY3ODkxNCwyNi4xNzMxOTg2IDcuNDI2Nzg5MTQsMjcuNDI2ODAxNCA4LjE5OTk4Nzc5LDI4LjIgTDE2LjU4NTc3NDIsMzYuNTg1Nzg2NCBDMTcuMzY2ODIyOCwzNy4zNjY4MzUgMTguNjMzMTUyOCwzNy4zNjY4MzUgMTkuNDE0MjAxNCwzNi41ODU3ODY0IEw0MC41OTk5ODc4LDE1LjQgQzQxLjM3MzE4NjQsMTQuNjI2ODAxNCA0MS4zNzMxODY0LDEzLjM3MzE5ODYgNDAuNTk5OTg3OCwxMi42IEw0MC41OTk5ODc4LDEyLjYgQzM5LjgyNjc4OTEsMTEuODI2ODAxNCAzOC41NzMxODY0LDExLjgyNjgwMTQgMzcuNzk5OTg3OCwxMi42IEwxNy45OTk5ODc4LDMyLjQgWiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==')] checked:after:bg-[length:40px] checked:after:bg-center checked:after:bg-no-repeat checked:after:content-[''] checked:bg-[#005C9F] hover:ring hover:ring-gray-300 focus:outline-none"
                />
                <label
                  htmlFor={`checkbox-${key}`}
                  className="ms-2 text-sm text-[#001C44] dark:text-white dark:text-gray-300"
                >
                  {val.name}
                </label>
              </div>
            )
        })}
        {selectedDepartment.length ? 
        <>
          <input
            style={{clear:'both'}}
            type="search"
            className="block w-[50%] px-1 py-2 ps-2 text-md text-gray-900 border border-gray-600 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search Staff"
            name="client"
            value={searchClientText}
            onChange={(e) => handleChange(e)}
          />
          {openDropdown && (
            <DropdownStaff
              onSelect={(value: { email: any; id: any }) => {
                console.log(value)
                if(selectedStaff.selectedDepartment.length > 0){
                  selectedStaff.selectedDepartment.map(item => {
                    console.log('DEPT: ', item)
                    if (![item][0].includes(value.email)) {
                      console.log(item, 'DID NOT INCLUDE', value.email, ' > ', ppc_notifiers )
                      setSelectedStaff({
                        ...selectedStaff,
                        [item]: [...selectedStaff[item], value.email],
                        stateChanged:true
                      });
                      /*
                      switch(item){
                        case 'seo_notifiers' :
                          if (!seo_notifiers.includes(value.email)) {

                          }
                        break;
                        case 'ppc_notifiers' :
                          if (![item].includes(value.email)) {

                          }
                        break;
                      }
                      */
                    }
                    console.log(item)
                  })
                }
                setOpenDropdown(false);
              }}
              items={
                Array.isArray(users?.data) ? users.data : []
              }
              styles={undefined}
              selected={undefined}
            />
          )}
        </>
        : ''}
      </div>
      )}
    </div>
    <div className="flex flex-col p-8"> 
      <span className="font-semibold text-black dark:text-[#fff]">Current Notified Staff</span>
      <hr />
    </div>
    <div className="ps-8">  
      <div className="items-center mb-2" style={{float:'left', width:'33.33%'}}>
            <h3>Search Notifications</h3>
            <ul className="text-start text-sm text-gray-500 py-4">
              {selectedStaff?.seo_notifiers?.length ? selectedStaff.seo_notifiers.map((item, key) => {
                return(
                  <li key={key}>{item} <span className="removeNotify" onClick={() => {handleRemove(item, 'seo_notifiers')}}>x</span></li>
                )
              }) :'' }
            </ul>
      </div>
      <div className="items-center mb-2" style={{float:'left', width:'33.33%'}}>
            <h3>Paid Media Notifications</h3>
            <ul className="text-start text-sm text-gray-500 py-4">
              {selectedStaff?.ppc_notifiers?.length ? selectedStaff.ppc_notifiers.map((item, key) => {
                return(
                  <li key={key}>{item} <span className="removeNotify" onClick={() => {handleRemove(item, 'ppc_notifiers')}}>x</span></li>
                )
              }) :'' }
            </ul>
      </div>
      <div className="items-center mb-2" style={{float:'left', width:'33.33%'}}>
            <h3>Social Media Notifications</h3>
            <ul className="text-start text-sm text-gray-500 py-4">
              {selectedStaff?.social_notifiers?.length ? selectedStaff.social_notifiers.map((item, key) => {
                return(
                  <li key={key}>{item} <span className="removeNotify" onClick={() => {handleRemove(item, 'social_notifiers')}}>x</span></li>
                )
              }) :'' }
            </ul>
      </div>
      <div className="items-center mb-2" style={{float:'left', width:'33.33%'}}>
            <h3>Display Ads Notifications</h3>
            <ul className="text-start text-sm text-gray-500 py-4">
              {selectedStaff?.display_notifiers?.length ? selectedStaff.display_notifiers.map((item, key) => {
                return(
                  <li key={key}>{item} <span className="removeNotify" onClick={() => {handleRemove(item, 'display_notifiers')}}>x</span></li>
                )
              }) :'' }
            </ul>
      </div>
      <div className="items-center mb-2" style={{float:'left', width:'33.33%'}}>
            <h3>Web Dev Notifications</h3>
            <ul className="text-start text-sm text-gray-500 py-4">
              {selectedStaff?.web_notifiers?.length ? selectedStaff.web_notifiers.map((item, key) => {
                return(
                  <li key={key}>{item} <span className="removeNotify" onClick={() => {handleRemove(item, 'web_notifiers')}}>x</span></li>
                )
              }) :'' }
            </ul>
      </div>
      <div className="items-center mb-2" style={{float:'left', width:'33.33%'}}>
            <h3>All Change Notifications</h3>
            <ul className="text-start text-sm text-gray-500 py-4">
              {selectedStaff?.all_notifiers?.length ? selectedStaff.all_notifiers.map((item, key) => {
                return(
                  <li key={key}>{item} <span className="removeNotify" onClick={() => {handleRemove(item, 'all_notifiers')}}>x</span></li>
                )
              }) :'' }
            </ul>
      </div>
      <button 
        className="mt-4 rounded-lg text-white border border-[#005C9F] bg-[#005C9F] shadow-xs px-4 py-2 dark:text-[#E6EFF5]"
        onClick={handleApprove}>Save</button>
        <hr className="mt-6" />
        <div className="flex flex-col"> 
              <h1 style={{color:'red', fontSize:'22px', textTransform: 'uppercase', fontWeight: 'bold'}}>Danger Zone</h1>
          <span className="font-semibold text-black dark:text-[#fff]">Additional Admin Settings</span>
          <hr />
        </div>
        <button 
        className="mt-4 rounded-lg text-white border border-[#5b5b5b] bg-[#5b5b5b] shadow-xs px-2 py-1 dark:text-[#E6EFF5]"
        onClick={(e) => handleIDSync('hubspot')}>Sync Hubspot ID's</button>
        <button 
        className="ml-2 mt-4 rounded-lg text-white border border-[#5b5b5b] bg-[#5b5b5b] shadow-xs px-2 py-1 dark:text-[#E6EFF5]"
        onClick={(e) => handleIDSync('teamwork')}>Sync Teamwork ID's</button>
        <button 
        className="ml-2 mt-4 rounded-lg text-white border border-[#5b5b5b] bg-[#5b5b5b] shadow-xs px-2 py-1 dark:text-[#E6EFF5]"
        onClick={(e) => handleCleanUp()}>Past Change Reqs</button>
      <CustomModal
        open={openViewAccessModal}
        onClose={() => setOpenAccessModal(false)}
      >
        <ViewAccessModal
          onClose={() => setOpenAccessModal(false)}
          user={accessItem}
        />
      </CustomModal>
    </div>
    <ToastContainer
        position="top-right"
        autoClose={30000}
        hideProgressBar
        newestOnTop={false}
        transition={Flip}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default ChangeRequests;
