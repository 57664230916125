import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const Status: React.FC<IconProps> = ({ color = 'black', size = 16 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 7.33301L8.93726 2.27027C8.59135 1.92436 8.4184 1.75141 8.21657 1.62773C8.03762 1.51807 7.84254 1.43726 7.63846 1.38827C7.40829 1.33301 7.16369 1.33301 6.67452 1.33301L4 1.33301M2 5.79967L2 7.11602C2 7.44214 2 7.6052 2.03684 7.75865C2.0695 7.8947 2.12337 8.02476 2.19648 8.14405C2.27894 8.27861 2.39424 8.39391 2.62484 8.62451L7.82484 13.8245C8.35286 14.3525 8.61687 14.6165 8.92131 14.7155C9.1891 14.8025 9.47757 14.8025 9.74536 14.7155C10.0498 14.6165 10.3138 14.3525 10.8418 13.8245L12.4915 12.1748C13.0195 11.6468 13.2835 11.3828 13.3825 11.0784C13.4695 10.8106 13.4695 10.5221 13.3825 10.2543C13.2835 9.94988 13.0195 9.68587 12.4915 9.15785L7.62484 4.29118C7.39424 4.06058 7.27894 3.94528 7.14438 3.86282C7.02508 3.78972 6.89502 3.73584 6.75898 3.70318C6.60553 3.66634 6.44247 3.66634 6.11634 3.66634H4.13333C3.3866 3.66634 3.01323 3.66634 2.72801 3.81167C2.47713 3.9395 2.27316 4.14347 2.14532 4.39435C2 4.67957 2 5.05294 2 5.79967Z"
      stroke={color || '#334969'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Status;
