import { useEffect } from 'react';
import { Card, Grid, Col } from '@tremor/react';
import usePerformanceStore from '../../../store/usePerformanceStore';
import Icon from '../../../assets/icons/SvgComponent';

interface IProps {
  selectedUserId: string;
}

const MissingIntegrationCards = ({ selectedUserId }: IProps) => {
  const { fetchInsightsSummary, insightsSummary } = usePerformanceStore(
    (state) => state
  );

  useEffect(() => {
    handleFetchInsightSummary();
  }, [selectedUserId]);

  const handleFetchInsightSummary = () => {
    const payload = {
      userId: selectedUserId ? selectedUserId : '',
    };
    fetchInsightsSummary(payload);
  };

  return (
    <Grid
      numItems={1}
      numItemsSm={1}
      numItemsMd={1}
      numItemsLg={4}
      className="gap-4 my-8"
    >
      <Col>
        <Card className="p-[16px]">
          <div className="text-[16px] text-[#101828] font-[600] dark:text-gray-300">
            All Clients
          </div>
          {insightsSummary?.loading ? (
            <div className="h-[60px] w-full flex justify-center item-center mt-4">
              <div>
                <Icon name="loading" />
              </div>
            </div>
          ) : (
            <>
              <div className="text-[24px] text-[#101828] font-[600] mb-4 dark:text-white">
                {insightsSummary?.all}
              </div>
              <div className="text-[14px] font-[500] text-[#475467] dark:text-gray-200">
                <span className="text-[#FF0000]">
                  {insightsSummary?.all_missing}
                </span>{' '}
                Missing Integ.
              </div>
            </>
          )}
        </Card>
      </Col>
      <Col>
        <Card className="p-[16px]">
          <div className="text-[16px] text-[#101828] font-[600] dark:text-gray-300">
            Google SEO
          </div>
          {insightsSummary?.loading ? (
            <div className="h-[60px] w-full flex justify-center item-center mt-4">
              <div>
                <Icon name="loading" />
              </div>
            </div>
          ) : (
            <>
              <div className="text-[24px] text-[#101828] font-[600] mb-4 dark:text-white">
                {insightsSummary?.seo}
              </div>
              <div className="text-[14px] font-[500] text-[#475467] dark:text-gray-200">
                <span className="text-[#FF0000]">
                  {insightsSummary?.seo_missing}
                </span>{' '}
                Missing Integ.
              </div>
            </>
          )}
        </Card>
      </Col>
      <Col>
        <Card className="p-[16px]">
          <div className="text-[16px] text-[#101828] font-[600] dark:text-gray-300">
            Google Ads
          </div>
          {insightsSummary?.loading ? (
            <div className="h-[60px] w-full flex justify-center item-center mt-4">
              <div>
                <Icon name="loading" />
              </div>
            </div>
          ) : (
            <>
              <div className="text-[24px] text-[#101828] font-[600] mb-4 dark:text-white">
                {insightsSummary?.ppc}
              </div>
              <div className="text-[14px] font-[500] text-[#475467] dark:text-gray-200">
                <span className="text-[#FF0000]">
                  {insightsSummary?.ppc_missing}
                </span>{' '}
                Missing Integ.
              </div>
            </>
          )}
        </Card>
      </Col>
      <Col>
        <Card className="p-[16px]">
          <div className="text-[16px] text-[#101828] font-[600] dark:text-gray-300">
            Meta
          </div>
          {insightsSummary?.loading ? (
            <div className="h-[60px] w-full flex justify-center item-center mt-4">
              <div>
                <Icon name="loading" />
              </div>
            </div>
          ) : (
            <>
              <div className="text-[24px] text-[#101828] font-[600] mb-4 dark:text-white">
                {insightsSummary?.meta}
              </div>
              <div className="text-[14px] font-[500] text-[#475467] dark:text-gray-200">
                <span className="text-[#FF0000]">
                  {insightsSummary?.meta_missing}
                </span>{' '}
                Missing Integ.
              </div>
            </>
          )}
        </Card>
      </Col>
    </Grid>
  );
};

export default MissingIntegrationCards;
