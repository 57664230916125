module.exports = {
  device: {
    xxs: `@media screen and (max-width: 431px)`,
    xs: `@media screen and (min-width: 431px)`,
    sm: `@media screen and (min-width: 640px)`,
    md: `@media screen and  (min-width: 768px)`,
    lg: `@media screen and  (min-width: 1024px)`,
    xl: `@media screen and  (min-width: 1280px)`,
    xxl: `@media screen and  (min-width: 1536px)`,
  },
  gutter: '20',
  fonts: {
    primary: 'Brandon Grotesque',
    secondary: 'Lato',
  },
  container: {
    sm: '300px',
    md: '570px',
    lg: '970px',
    xl: '1200px',
  },
  colors: {
    primary: '#0059FF',
    secondary: '#efbc09',
    black: '#000000',
    white: '#ffffff',
    offBlack: '#1C1C1C',
    darkBlue: '#2852CA',
    pinkGradient: 'linear-gradient(137deg, #FF40A4 5%, #8E14A9 100%)',
    blueGradient: '-webkit-radial-gradient(-32% 40%, #0F4BB1 0%, #1767FF 59%)',
    yellowGradient: 'linear-gradient(44deg, #F1AC40 0%, #EEBF00 100%)',
    darkTeal: '#25382C',
    bgTeal: '#DCE8D6',
    paleGreen: '#EBF2E7',
    beige: '#E1D9D2',
    blue: '#0038FF',
    lightBlue: '#E5EEF0',
    lightBeige: '#F0E9E5',
    lightGray: '#655E5D',
    lightGreen: '#a8db1d',
    error: '#CD001A',
    gray: '#6d6d6d',
    orange: '#f1ac40',
    red: '#e42750',
  },
  mode: 'light',
};
