import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const Reset: React.FC<IconProps> = ({ color = '#000000', size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 21 21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      transform="translate(2 2)"
    >
      <path d="m4.5 1.5c-2.4138473 1.37729434-4 4.02194088-4 7 0 4.418278 3.581722 8 8 8s8-3.581722 8-8-3.581722-8-8-8" />
      <path d="m4.5 5.5v-4h-4" />
    </g>
  </svg>
);

export default Reset;
