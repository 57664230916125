import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const Click: React.FC<IconProps> = ({ color = '#80AECF', size = 16 }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1547_10199)">
      <path
        d="M5.24984 2.04199V1.16699M2.95189 2.95238L2.33317 2.33366M2.95189 7.58366L2.33317 8.20238M7.58317 2.95238L8.20189 2.33366M2.0415 5.25033H1.1665M9.25412 9.44424L7.8006 12.1436C7.63455 12.452 7.55152 12.6062 7.45163 12.6452C7.36494 12.679 7.2673 12.6694 7.18882 12.6194C7.09839 12.5618 7.04683 12.3944 6.94373 12.0597L4.9262 5.51005C4.84197 5.23662 4.79986 5.0999 4.83374 5.00756C4.86326 4.92713 4.92664 4.86375 5.00707 4.83423C5.09941 4.80035 5.23613 4.84246 5.50957 4.92669L12.0592 6.94424C12.3939 7.04734 12.5613 7.0989 12.6189 7.18933C12.6689 7.26782 12.6785 7.36545 12.6446 7.45214C12.6056 7.55203 12.4515 7.63506 12.1431 7.80111L9.44375 9.2546C9.39793 9.27928 9.37502 9.29162 9.35496 9.30747C9.33715 9.32154 9.32105 9.33764 9.30698 9.35545C9.29113 9.37551 9.27879 9.39842 9.25412 9.44424Z"
        stroke={color || '#334969'}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1547_10199">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Click;
