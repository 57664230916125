import { FC, useState } from 'react';
import useReportStore from '../../../store/useReportStore';
import Icon from '../../../assets/icons/SvgComponent';

import {
  Badge,
  Card,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
} from '@tremor/react';

// Define the type for a comment
interface Comment {
  id: number; // Unique identifier for each comment
  date: string; // Date of the comment
  author: string; // Author of the comment
  performance: string; // Performance related to the comment
  text: string; // The comment text
}

const Comments: FC = () => {
  const { comments } = useReportStore((state) => state);
  const [commentss, setCommentss] = useState<Comment[]>([]);
  const [commentText, setCommentText] = useState<string>('');
  const [author, setAuthor] = useState<string>('');
  const [performance, setPerformance] = useState<string>('');
  const [showForm, setShowForm] = useState<boolean>(false);

  const data = commentss;

  const handleAddComment = () => {
    const newComment: Comment = {
      id: Date.now(), // Using the current timestamp as a simple unique ID
      date: new Date().toLocaleDateString(),
      author: author || 'Unknown', // If author is not provided, default to 'Unknown'
      performance: performance || 'N/A', // If performance is not provided, default to 'N/A'
      text: commentText.trim(),
    };

    // Add the new comment to the existing list of comments
    setCommentss([...commentss, newComment]);
    // Clear the input fields
    setCommentText('');
    setAuthor('');
    setPerformance('');
    // Hide the form after adding the comment
    setShowForm(false);
  };

  return (
    <div className="relative rounded-md h-[80%] p-2">
      <Card>
        <div className="flex justify-between items-center">
          <span className="text-gray-800 font-semibold dark:text-white ">
            Comments
          </span>
          <div className="flex items-center gap-x-2">
            <Badge
              onClick={() => setShowForm(true)}
              className=" p-1 px-2 dark:text-white cursor-pointer self-end"
            >
              <div className="flex gap-2 items-center">
                <div className="rounded-full border p-1 bg-white">
                  <Icon name="plus" />
                </div>
                <div>New Comment</div>
              </div>
            </Badge>
          </div>
        </div>
        {showForm && (
          <div className="mt-4">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleAddComment();
              }}
            >
              {/* <div className="flex gap-4">
                <input
                  type="text"
                  placeholder="Author"
                  value={author}
                  onChange={(e) => setAuthor(e.target.value)}
                  className="w-1/3 p-2 border rounded-md"
                />
                <input
                  type="text"
                  placeholder="Performance"
                  value={performance}
                  onChange={(e) => setPerformance(e.target.value)}
                  className="w-1/3 p-2 border rounded-md"
                />
                <input
                  type="date"
                  placeholder="Date"
                  value={new Date().toISOString().split('T')[0]}
                  disabled
                  className="w-1/3 p-2 border rounded-md"
                />
              </div> */}
              <textarea
                className="w-full p-2 border rounded-md mt-2"
                placeholder="Type your comment here..."
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
              ></textarea>
              <div className="flex justify-end mt-2">
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                >
                  Add Comment
                </button>
              </div>
            </form>
          </div>
        )}
        {data.length === 0 ? (
          <div className="mt-4 text-center text-gray-500">No comments</div>
        ) : (
          <Table className="mt-5">
            <TableHead>
              <TableRow>
                <TableHeaderCell>Date</TableHeaderCell>
                <TableHeaderCell>Author</TableHeaderCell>
                <TableHeaderCell>Performance</TableHeaderCell>
                <TableHeaderCell>Comment</TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.date}</TableCell>
                  <TableCell>{item.author}</TableCell>
                  <TableCell>{item.performance}</TableCell>
                  <TableCell>{item.text}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Card>
    </div>
  );
};

export default Comments;
