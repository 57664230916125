import { requests } from './ApiService';
import { toQueryString } from '../utils/common';
export const Meta = {
  getDemographics: (data) =>
    requests.get(
      `/api/v1/fb/get_insights/demographics${toQueryString({
        clientId: data.clientId,
        since: data.since,
        until: data.until,
        timeIncrement: data.timeIncrement,
        level: data.campaign,
        limit: data.limit,
        refreshCache: data?.resetCache,
      })}`
    ),
  getTopPerformance: (data) =>
      requests.get(
        `/api/v1/fb/top-performance${toQueryString({
          clientId: data.clientId,
          startDate: data.startDate,
          endDate: data.endDate,
          refreshCache: data?.resetCache,
        })}`
      ),  
  getFbInsights: (data) =>
      requests.get(
        `/api/v1/fb/get_insights${toQueryString({
          clientId: data.clientId,
          since: data.since,
          until: data.until,
          timeIncrement: data.timeIncrement,
          level: data.level,
          limit: data.limit,
          refreshCache: data?.resetCache,
        })}`  ),  
  getExpiredToken: () => requests.get(`/api/v1/fb/tokens/expired`),
  activateClientAcc: (data) =>
    requests.post(`/api/v1/fb/activate_client`, data),
  getFbAdsAccounts: (data) =>
    requests.post(`/api/v1/fb/get_ads_accounts`, data),
  getTokens: (data) =>
    requests.get(`/api/v1/fb/tokens?page=${data.page}&limit=${data.limit}`),
};
