import { create } from 'zustand';
import Api from '../api';
import { devtools } from 'zustand/middleware';
const initialStatus = {
  loading: true,
  errorMsg: '',
  isSuccessful: true,
};
const useChangeStore = create(
  devtools((set, get) => ({
    changeRequests: [],
    changeReports: [],
    changeHistory: [],
    changeReportStatus: initialStatus,
    changeHistoryStatus: initialStatus,
    selectedChange: initialStatus,
    summary: initialStatus,
    error: false,
    apiStatus: '',
    loading: false,
    errorMessages: '',
    fetchChanges: async (filters) => {
      set(() => ({ loading: true }));
      const response = await Api.ChangeRequest.getChanges(filters);
      set(() => ({ changeRequests: response }));
      set(() => ({ loading: false }));
      return response;
    },
    fetchHistory: async (filters) => {
      set(() => ({ loading: true }));
      const response = await Api.ChangeRequest.getHistory(filters);
      set(() => ({ changeRequests: response }));
      set(() => ({ loading: false }));
      return response;
    },
    fetchChange: async (id, noLoading = false) => {
      try {
        if (!noLoading) {
          set(() => ({ selectedChange: initialStatus }));
        }
        set(() => ({ loading: true }));
        const response = await Api.ChangeRequest.getChange(id);
        const resData = { ...response, loading: false };
        if (response[0]?.error) {
          throw response[0]?.error;
        } else {
          set(() => ({ selectedChange: resData, loading: false }));
          return { status: true };
        }
      } catch (error) {
        throw error;
      }
    },
    fetchReports: async (filters) => {
      set(() => ({ loading: true }));
      const response = await Api.ChangeRequest.getChangeReports(filters);
      set(() => ({ changeReports: response }));
      set(() => ({ loading: false }));
      return response;
    },
    fetchHistoryReports: async (filters) => {
      set(() => ({ loading: true }));
      const response = await Api.ChangeRequest.getChangeHistoryReports(filters);
      set(() => ({ changeHistory: response }));
      set(() => ({ loading: false }));
      return response;
    },
    setClient: async (change) => {
      set(() => ({ selectedChange: change }));
    },
    createChange: async (data) => {
      set(() => ({
        loading: true,
        error: false,
        errorMessages: '',
        apiStatus: '',
      }));
      const response = await Api.ChangeRequest.create(data);
      if (response[0]?.error) {
        set(() => ({
          apiStatus: 'error',
          loading: false,
          error: true,
          errorMessage: response[0]?.error,
        }));
        throw new Error(response[0]?.error);
      } else {
        set(() => ({
          apiStatus: 'success',
          loading: false,
          error: false,
          errorMessages: '',
        }));
      }
    },
    updateChange: async (id, data) => {
      set(() => ({
        loading: true,
        error: false,
        errorMessages: '',
        apiStatus: '',
      }));
      const response = await Api.ChangeRequest.update(id, data);
      if (response[0]?.error) {
        set(() => ({
          apiStatus: 'error',
          loading: false,
          error: true,
          errorMessage: response[0].error,
        }));
        throw new Error(response[0]?.error);
      } else {
        set(() => ({
          apiStatus: 'success',
          loading: false,
          error: false,
          errorMessages: '',
          selectedChange: { ...get().selectedChange, ...response },
        }));
        return { status: true };
      }
    },
    deleteChange: async (data) => {
      set(() => ({ loading: true }));
      // TODO handle errors
      await Api.ChangeRequest.delete(data);
    },
  }))
);

export default useChangeStore;
