import { useEffect, useState } from 'react';
import {
  Card,
  Tab,
  TabGroup,
  TabList,
  TabPanels,
  TabPanel,
} from '@tremor/react';
import ReactSelect from '../../ReactSelect';
import usePerformanceStore from '../../../store/usePerformanceStore';
import Icon from '../../../assets/icons/SvgComponent';
import { getServiceLabel, formatNumber } from '../../../utils/common';
import moment from 'moment';
import DatePicker from 'react-datepicker';

const TopAndUnderServicePerformance = () => {
  const { fetchInsightsOverall, insightsOverall } = usePerformanceStore(
    (state) => state
  );
  const [topAndUnderPerformance, setTopAndUnderPerformance] = useState<{
    label: string;
    value: string;
  }>({
    label: 'Google SEO',
    value: 'seo_google',
  });

  const lastMonth = moment().subtract(1, 'month').startOf('month').toDate();
  const maxDate = moment(lastMonth, 'MM-yyyy').endOf('month').toDate();
  const [topPerformanceDate, setTopPerformanceDate] = useState(lastMonth);

  useEffect(() => {
    handleFetchInsightsOverall();
  }, [topAndUnderPerformance, topPerformanceDate]);

  const handleFetchInsightsOverall = () => {
    const payload = {
      report_date: moment(topPerformanceDate).format('MM-YYYY'),
      service: topAndUnderPerformance?.value,
      limit: 5,
    };
    fetchInsightsOverall(payload);
  };

  const leadGenTopData = insightsOverall?.['Lead Gen']?.top;

  const ecommerceTopData = insightsOverall?.['Ecommerce']?.top;

  const leadGenUnderPerformanceData = insightsOverall?.['Lead Gen']?.low;

  const ecommerceUnderPerformanceData = insightsOverall?.['Ecommerce']?.low;

  const handleFilterChange = (selectedOption: any, actionMeta: string) => {
    const newUrl = new URL(window.location.href);

    if (actionMeta === 'TopDate') {
      setTopPerformanceDate(selectedOption);
      newUrl.searchParams.set('date', moment(selectedOption).format('MM-YYYY'));
    }

    window.history.pushState({}, '', newUrl);
  };

  return (
    <Card>
      <div className="mb-4 flex gap-4">
        <div className="w-full">
          <ReactSelect
            value={topAndUnderPerformance}
            options={[
              { value: 'seo_google', label: 'Google SEO' },
              { value: 'ppc_google', label: 'Google Ads' },
              { value: 'social_meta', label: 'Meta' },
            ]}
            handleOnChange={(selectedOption: any) => {
              setTopAndUnderPerformance(selectedOption);
            }}
            placeholder="All Services"
          />
        </div>
        <div className="font-inter w-[100px]">
          <DatePicker
            selected={moment(topPerformanceDate, 'YYYY-MM').toDate()}
            onChange={(date) => {
              if (date !== null) {
                handleFilterChange(date, 'TopDate');
              }
            }}
            dateFormat="MMM yyyy"
            maxDate={maxDate}
            showMonthYearPicker
            className="react-datepicker__month-container text-center h-[38px]  border border-[#808EA2] cursor-pointer myDatePicker dark:bg-[#374151] dark:text-white dark:border-none font-inter rounded-lg text-[14px] text-[#525252]"
          />
        </div>
      </div>
      <Card className="p-[24px] mb-8 dark:bg-[#222732]">
        <div className="font-[700] text-[18px] dark:text-white">
          Top Performance
        </div>

        {insightsOverall?.loading ? (
          <div className="w-full min-h-[340px] h-full flex justify-center  items-center">
            <Icon name="Loading" size={60} />
          </div>
        ) : (
          <TabGroup>
            <TabList variant="line">
              <Tab value="1">Lead Gen</Tab>
              <Tab value="2">Ecommerce</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                {leadGenTopData && leadGenTopData.length > 0 ? (
                  leadGenTopData.map((data, index) => (
                    <div
                      key={index}
                      className="border-b flex w-full justify-between items-center"
                    >
                      <div className="w-[8vw] py-2">
                        <p
                          className="font-[600] text-sm dark:text-white truncate"
                          title={data?.client?.name}
                        >
                          {data?.client?.name}
                        </p>
                        <p className="font-light text-[#6B7280] text-sm">
                          {getServiceLabel(data.service)?.label}
                        </p>
                      </div>
                      <div
                        className={
                          data.performance?.score > 50
                            ? 'text-[#3CCB7F] text-sm'
                            : 'text-[#FF0000] text-sm'
                        }
                      >
                        {formatNumber(data.performance?.score)}%
                      </div>
                      <div className="text-xs text-[#001C44] bg-[#F2F4FF] p-1 rounded">
                        {data.category_type}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="w-full flex justify-center items-center text-gray-500 h-[300px]">
                    <div>No data</div>
                  </div>
                )}
              </TabPanel>
              <TabPanel>
                {ecommerceTopData && ecommerceTopData.length > 0 ? (
                  ecommerceTopData.map((data, index) => (
                    <div
                      key={index}
                      className="border-b flex w-full justify-between items-center"
                    >
                      <div className="w-[8vw] py-2">
                        <p
                          className="font-[600] text-sm dark:text-white truncate"
                          title={data?.client?.name}
                        >
                          {data?.client?.name}
                        </p>
                        <p className="font-light text-[#6B7280] text-sm">
                          {getServiceLabel(data.service)?.label}
                        </p>
                      </div>
                      <div
                        className={
                          data.performance?.score > 50
                            ? 'text-[#3CCB7F] text-sm'
                            : 'text-[#FF0000] text-sm'
                        }
                      >
                        {formatNumber(data.performance?.score)}%
                      </div>
                      <div className="text-xs text-[#001C44] bg-[#F2F4FF] p-1 rounded">
                        {data.category_type}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="w-full flex justify-center items-center text-gray-500 h-[300px]">
                    <div>No data</div>
                  </div>
                )}
              </TabPanel>
            </TabPanels>
          </TabGroup>
        )}
      </Card>
      <Card className="p-[24px] dark:bg-[#222732]">
        <div className="font-[700] text-[18px] dark:text-white">
          Under Performance
        </div>
        {/* <div className="mt-4">
                <ReactSelect
                  value={underPerformance}
                  options={[
                    { value: 'seo_google', label: 'Google SEO' },
                    { value: 'ppc_google', label: 'Google Ads' },
                    { value: 'social_meta', label: 'Meta' },
                  ]}
                  handleOnChange={(selectedOption: any) => {
                    setUnderPerformance(selectedOption);
                  }}
                  placeholder="All Services"
                />
              </div> */}
        {insightsOverall?.loading ? (
          <div className="w-full min-h-[340px] h-full flex justify-center items-center">
            <Icon name="Loading" size={60} />
          </div>
        ) : (
          <TabGroup>
            <TabList variant="line">
              <Tab value="1">Lead Gen</Tab>
              <Tab value="2">Ecommerce</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                {leadGenUnderPerformanceData &&
                leadGenUnderPerformanceData.length > 0 ? (
                  leadGenUnderPerformanceData.map((data, index) => (
                    <div
                      key={index}
                      className="border-b flex w-full justify-between items-center"
                    >
                      <div className="w-[8vw] py-2">
                        <p
                          className="font-[600] text-sm dark:text-white truncate"
                          title={data?.client?.name}
                        >
                          {data?.client?.name}
                        </p>
                        <p className="font-light text-[#6B7280] text-sm">
                          {getServiceLabel(data.service)?.label}
                        </p>
                      </div>
                      <div
                        className={
                          data.performance?.score > 50
                            ? 'text-[#3CCB7F] text-sm'
                            : 'text-[#FF0000] text-sm'
                        }
                      >
                        {formatNumber(data.performance?.score)}%
                      </div>
                      <div className="text-xs text-[#001C44] bg-[#F2F4FF] p-1 rounded">
                        {data.category_type}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="w-full flex justify-center items-center text-gray-500 h-[300px]">
                    <div>No data</div>
                  </div>
                )}
              </TabPanel>
              <TabPanel>
                {ecommerceUnderPerformanceData &&
                ecommerceUnderPerformanceData.length > 0 ? (
                  ecommerceUnderPerformanceData.map((data, index) => (
                    <div
                      key={index}
                      className="border-b flex w-full justify-between items-center"
                    >
                      <div className="w-[8vw] py-2">
                        <p
                          className="font-[600] text-sm dark:text-white truncate"
                          title={data?.client?.name}
                        >
                          {data?.client?.name}
                        </p>
                        <p className="font-light text-[#6B7280] text-sm">
                          {getServiceLabel(data.service)?.label}
                        </p>
                      </div>
                      <div
                        className={
                          data.performance?.score > 50
                            ? 'text-[#3CCB7F] text-sm'
                            : 'text-[#FF0000] text-sm'
                        }
                      >
                        {formatNumber(data.performance?.score)}%
                      </div>
                      <div className="text-xs text-[#001C44] bg-[#F2F4FF] p-1 rounded">
                        {data.category_type}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="w-full flex justify-center items-center text-gray-500 h-[300px]">
                    <div>No data</div>
                  </div>
                )}
              </TabPanel>
            </TabPanels>
          </TabGroup>
        )}
      </Card>
    </Card>
  );
};

export default TopAndUnderServicePerformance;
