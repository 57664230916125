import React from 'react';

interface CheckProps {
  color?: string;
  size?: number;
}

const Check: React.FC<CheckProps> = ({ color = '#808EA2', size = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
  >
    <rect width="20" height="20" rx="10" fill={color} />
    <path
      d="M6.25 10L8.75 12.5L13.75 7.5"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Check;
