import React from 'react';
import { Grid, Col } from '@tremor/react';
import AreaCardChart from '../../AreaCardChart';
import {
  formatNumberWithCommas,
  formatToCurrency,
} from '../../../../utils/common';
import MobileView from './MobileView';
import useClientStore from '../../../../store/useClientStore';
import { useRetryFetchFbInsights } from '../../Hooks';

interface IProps {
  impressions?: any;
  reach?: any;
  cpm?: any;
  dataInfo?: any;
}

const KeywordDelivery = ({ impressions, reach, cpm, dataInfo }: IProps) => {
  const { selectedClient } = useClientStore((state) => state);
  const lastMonthImp = impressions[impressions.length - 1]?.value;
  const lastMonthReach = reach[reach.length - 1]?.value;
  const lastMonthCPM = cpm[cpm.length - 1]?.value;

  const retryFetchFbInsights = useRetryFetchFbInsights()

  return (
    <div>
      <div className="flex justify-start items-center mb-8">
        <h2 className="sm:title-text text-[#001C44] text-[14px] font-[800] dark:text-white sm:text-[1.85rem] px-3 sm:px-0 sm:pb-1">
          Key Performance Metrics - Delivery
        </h2>
      </div>
      <MobileView
        impressions={impressions}
        reach={reach}
        cpm={cpm}
        dataInfo={dataInfo}
        retryMethod={retryFetchFbInsights}
      />
      <div className="hidden sm:block">
        <Grid
          numItems={1}
          numItemsSm={1}
          numItemsMd={2}
          numItemsLg={3}
          className="gap-4"
        >
          <Col className="shadow-md">
            <AreaCardChart
              title="Impressions"
              chatData={impressions}
              category={['Impressions']}
              info={dataInfo?.impressions?.info}
              prevPeriod={dataInfo?.impressions?.previous_period}
              previousYear={dataInfo?.impressions?.previous_year}
              totalValue={formatNumberWithCommas(lastMonthImp)}
              isLoading={dataInfo.loading}
              error={dataInfo.error}
              icon="meta"
              accountInsights={true}
              propertyId={`Ads ID: ${selectedClient?.facebook_ads?.ads_id}`}
              retryMethod={retryFetchFbInsights}
            />
          </Col>
          <Col className="shadow-md">
            <AreaCardChart
              title="Reach"
              chatData={reach}
              category={['Reach']}
              info={dataInfo?.reach?.info}
              prevPeriod={dataInfo?.reach?.previous_period}
              previousYear={dataInfo?.reach?.previous_year}
              totalValue={formatNumberWithCommas(lastMonthReach)}
              isLoading={dataInfo.loading}
              error={dataInfo.error}
              icon="meta"
              accountInsights={true}
              propertyId={`Ads ID: ${selectedClient?.facebook_ads?.ads_id}`}
              retryMethod={retryFetchFbInsights}
            />
          </Col>
          <Col className="shadow-md">
            <AreaCardChart
              title="CPM"
              chatData={cpm}
              category={['Cpm']}
              info={dataInfo?.cpm?.info}
              prevPeriod={dataInfo?.cpm?.previous_period}
              previousYear={dataInfo?.cpm?.previous_year}
              totalValue={formatToCurrency(lastMonthCPM)}
              isLoading={dataInfo.loading}
              hasDollar
              error={dataInfo.error}
              icon="meta"
              accountInsights={true}
              propertyId={`Ads ID: ${selectedClient?.facebook_ads?.ads_id}`}
              retryMethod={retryFetchFbInsights}
            />
          </Col>
        </Grid>
      </div>
    </div>
  );
};

export default KeywordDelivery;
