import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const insightReport: React.FC<IconProps> = ({ color = '#405573', size = 16 }) => (
  <svg
    width={size - 2}
    height={size}
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.75 0.75V2H9.25V0.75C9.25 0.34375 9.5625 0 10 0C10.4062 0 10.75 0.34375 10.75 0.75V2H12C13.0938 2 14 2.90625 14 4V4.5V6V14C14 15.125 13.0938 16 12 16H2C0.875 16 0 15.125 0 14V6V4.5V4C0 2.90625 0.875 2 2 2H3.25V0.75C3.25 0.34375 3.5625 0 4 0C4.40625 0 4.75 0.34375 4.75 0.75ZM1.5 6V14C1.5 14.2812 1.71875 14.5 2 14.5H12C12.25 14.5 12.5 14.2812 12.5 14V6H1.5ZM3 9C3 8.46875 3.4375 8 4 8C4.53125 8 5 8.46875 5 9C5 9.5625 4.53125 10 4 10C3.4375 10 3 9.5625 3 9ZM10 11C10.5312 11 11 11.4688 11 12C11 12.5625 10.5312 13 10 13C9.4375 13 9 12.5625 9 12C9 11.4688 9.4375 11 10 11ZM6 9C6 8.59375 6.3125 8.25 6.75 8.25H10.25C10.6562 8.25 11 8.59375 11 9C11 9.4375 10.6562 9.75 10.25 9.75H6.75C6.3125 9.75 6 9.4375 6 9ZM7.25 11.25C7.65625 11.25 8 11.5938 8 12C8 12.4375 7.65625 12.75 7.25 12.75H3.75C3.3125 12.75 3 12.4375 3 12C3 11.5938 3.3125 11.25 3.75 11.25H7.25Z"
      fill={color}
    />
  </svg>
);

export default insightReport;
