import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const TrendUp: React.FC<IconProps> = ({ color = '#428B6E', size = 40 }) => (
  <svg
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.16163 4.89899C6.87154 4.89899 6.63638 4.66382 6.63638 4.37373C6.63638 4.08364 6.87154 3.84848 7.16163 3.84848H11.3636C11.6537 3.84848 11.8889 4.08364 11.8889 4.37373V8.57575C11.8889 8.86584 11.6537 9.10101 11.3636 9.10101C11.0736 9.10101 10.8384 8.86584 10.8384 8.57575V5.6418L7.53304 8.94716C7.32791 9.15229 6.99534 9.15229 6.79022 8.94716L5.06062 7.21756L2.28051 9.99767C2.07539 10.2028 1.74282 10.2028 1.53769 9.99767C1.33257 9.79254 1.33257 9.45997 1.53769 9.25485L4.68921 6.10333C4.89433 5.89821 5.2269 5.89821 5.43203 6.10333L7.16163 7.83293L10.0956 4.89899H7.16163Z"
      fill={color}
    />
  </svg>
);

export default TrendUp;
