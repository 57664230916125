import React, { useState } from 'react';

import useClientStore from '../../store/useClientStore';
import RequestModal from '../Dashboard/RequestModal';
import { useNavigate, useLocation } from 'react-router-dom';
import CustomModal from '../CustomModal';
import moment from 'moment';
import { formatToCurrency } from '../../utils/common';
import { Card, Grid, Col } from '@tremor/react';
import GoogleImg from '../../assets/images/services/Google.png';
import MetaImg from '../../assets/images/services/MetaADS.png';
import GoogleAdsImg from '../../assets/images/services/GoogleADS.png';
import BingAdsImg from '../../assets/images/services/BingADS.png';
import BingSEOImg from '../../assets/images/services/BingSEO.png';
import TiktokImg from '../../assets/images/services/TiktokADS.png';
import RemarketingImg from '../../assets/images/services/Remarketing.png';
import LinkedinImg from '../../assets/images/services/LinkedIn.png';
import EmailMarketing from '../../assets/images/services/EmailMarketing.png';
import PinterestImg from '../../assets/images/services/Pinterest.png';
import ProgrammaticImg from '../../assets/images/services/Programmatic.png';
import CreativeImg from '../../assets/images/services/CreativeServices.png';
import UIServicesImg from '../../assets/images/services/UXServices.png';
import WebServices from '../../assets/images/services/WebServices.png';
type ServiceType = {
  title: string;
  status: string;
  icon?: string | null;
  link?: string;
  startDate?: string;
  contractDue?: string;
  revenue?: string;
  serviceId?: string;
  description?: string;
  id?: string | null;
  image?: string | null;
};

interface Props {
  showHeader?: boolean;
}

const Services: React.FC<React.PropsWithChildren<Props>> = ({ showHeader }) => {
  const { selectedClient } = useClientStore((state) => state);
  const [isRequestModal, setRequestModall] = useState(false);
  const [selectedService, setSelectedService] = useState<ServiceType | null>(
    null
  );
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const titlesToFilter = [
    'Google SEO',
    'Google Ads',
    'Meta Ads',
    'Microsoft Ads',
    'Bing SEO',
    'TikTok Ads',
    'Pinterest Ads',
    'Email Marketing',
    'Remarketing',
    'LinkedIn Ads',
    'Creative Services',
    'Web Design Services',
  ];

  const data: ServiceType[] = [
    {
      title: 'Google SEO',
      startDate:
        selectedClient?.seo_google?.start_date === null
          ? '-'
          : moment(selectedClient?.seo_google?.start_date).format(
              'DD MMMM YYYY'
            ),
      contractDue:
        selectedClient?.seo_google?.end_date === null
          ? '-'
          : moment(selectedClient?.seo_google?.end_date).format('DD MMMM YYYY'),
      revenue: formatToCurrency(selectedClient?.seo_google?.revenue),
      status: selectedClient?.seo_google?.status || 'inactive',
      icon: 'OutlineGoogleSEO',
      image: GoogleImg,
      link: 'seo-ga4',
      serviceId: 'seo_google',
      description:
        ' Optimize your website visibility and ranking on Google search results through tailored SEO strategies, enhancing organic traffic and online presence.',
    },
    {
      title: 'Google Ads',
      startDate:
        selectedClient?.ppc_google?.start_date === null
          ? '-'
          : moment(selectedClient?.ppc_google?.start_date).format(
              'DD MMMM YYYY'
            ),
      contractDue:
        selectedClient?.ppc_google?.end_date === null
          ? '-'
          : moment(selectedClient?.ppc_google?.end_date).format('DD MMMM YYYY'),
      revenue: formatToCurrency(selectedClient?.ppc_google?.revenue),
      status: selectedClient?.ppc_google?.status || 'inactive',
      icon: 'OutlineGoogleAds',
      image: GoogleAdsImg,
      link: 'paid-search',
      serviceId: 'ppc_google',
      description:
        'Leverage Google advertising platform to display your ads across Google Search, YouTube, and other sites, targeting potential customers effectively to boost sales and brand awareness.',
    },
    {
      title: 'Meta Ads',
      startDate:
        selectedClient?.social_meta?.start_date === null
          ? '-'
          : moment(selectedClient?.social_meta?.start_date).format(
              'DD MMMM YYYY'
            ),
      contractDue:
        selectedClient?.social_meta?.end_date === null
          ? '-'
          : moment(selectedClient?.social_meta?.end_date).format(
              'DD MMMM YYYY'
            ),
      revenue: formatToCurrency(selectedClient?.social_meta?.revenue),
      status: selectedClient?.social_meta?.status || 'inactive',
      icon: 'OutlineMetaAds',
      link: 'fb-ads',
      image: MetaImg,
      serviceId: 'social_meta',
      description:
        'Utilize Facebook and Instagram powerful advertising tools to reach your target audience with precision, engaging them with creative ads that drive traffic and conversions.',
    },
    {
      title: 'Microsoft Ads',
      startDate:
        selectedClient?.social_bing?.start_date === null
          ? '-'
          : moment(selectedClient?.social_bing?.start_date).format(
              'DD MMMM YYYY'
            ),
      contractDue:
        selectedClient?.social_bing?.end_date === null
          ? '-'
          : moment(selectedClient?.social_bing?.end_date).format(
              'DD MMMM YYYY'
            ),
      revenue: formatToCurrency(selectedClient?.social_bing?.revenue),
      status: selectedClient?.social_bing?.status || 'inactive',
      icon: 'OutlineBingAds',
      image: BingAdsImg,
      link: 'paid-search',
      serviceId: 'social_bing',
      description:
        'Expand your digital advertising reach by placing your ads on the Bing network, targeting potential customers using Microsoft and Yahoo platforms.',
    },
    {
      title: 'Bing SEO',
      startDate:
        selectedClient?.seo_bing?.start_date === null
          ? '-'
          : moment(selectedClient?.seo_bing?.start_date).format('DD MMMM YYYY'),
      contractDue:
        selectedClient?.seo_bing?.end_date === null
          ? '-'
          : moment(selectedClient?.seo_bing?.end_date).format('DD MMMM YYYY'),
      revenue: formatToCurrency(selectedClient?.seo_bing?.revenue),
      status: selectedClient?.seo_bing?.status || 'inactive',
      icon: 'OutlineBingSEO',
      image: BingSEOImg,
      link: 'paid-search',
      serviceId: 'seo_bing',
      description:
        'Improve your website ranking on Bing search engine through strategic SEO practices, tapping into a unique audience for increased visibility and traffic.',
    },

    {
      title: 'TikTok Ads',
      startDate:
        selectedClient?.social_tiktok?.start_date === null
          ? '-'
          : moment(selectedClient?.social_tiktok?.start_date).format(
              'DD MMMM YYYY'
            ),
      contractDue:
        selectedClient?.social_tiktok?.end_date === null
          ? '-'
          : moment(selectedClient?.social_tiktok?.end_date).format(
              'DD MMMM YYYY'
            ),
      revenue: formatToCurrency(selectedClient?.social_tiktok?.revenue),
      status: selectedClient?.social_tiktok?.status || 'inactive',
      icon: 'OutlineTikTokAds',
      image: TiktokImg,
      link: 'paid-search',
      serviceId: 'social_tiktok',
      description:
        'Capture the attention of a young and engaged audience on TikTok with creative and interactive ads, harnessing the power of short-form video content to promote your brand.',
    },
    {
      title: 'Pinterest Ads',
      startDate:
        selectedClient?.social_pinterest?.start_date === null
          ? '-'
          : moment(selectedClient?.social_pinterest?.start_date).format(
              'DD MMMM YYYY'
            ),
      contractDue:
        selectedClient?.social_pinterest?.end_date === null
          ? '-'
          : moment(selectedClient?.social_pinterest?.end_date).format(
              'DD MMMM YYYY'
            ),
      revenue: formatToCurrency(selectedClient?.social_pinterest?.revenue),
      status: selectedClient?.social_pinterest?.status || 'inactive',
      icon: 'OutlineGoogleSEO',
      image: PinterestImg,
      link: 'paid-search',
      serviceId: 'social_pinterest',
      description:
        'Tap into a visually-inspired audience by promoting your products or services on Pinterest, driving traffic and sales through rich, engaging imagery and creative ad formats.',
    },
    {
      title: 'Email Marketing',
      startDate:
        selectedClient?.web_hosting?.start_date === null
          ? '-'
          : moment(selectedClient?.web_hosting?.start_date).format(
              'DD MMMM YYYY'
            ),
      contractDue:
        selectedClient?.web_hosting?.end_date === null
          ? '-'
          : moment(selectedClient?.web_hosting?.end_date).format(
              'DD MMMM YYYY'
            ),
      revenue: formatToCurrency(selectedClient?.web_hosting?.revenue),
      status: selectedClient?.web_hosting?.status || 'inactive',
      icon: 'OutlineGoogleSEO',
      image: EmailMarketing,
      link: 'paid-search',
      serviceId: 'web_hosting',
      description:
        'Engage your audience with personalized email campaigns, nurturing leads and building customer loyalty through targeted messaging and offers.',
    },
    {
      title: 'Programmatic Ad',
      startDate:
        selectedClient?.social_pinterest?.start_date === null
          ? '-'
          : moment(selectedClient?.social_pinterest?.start_date).format(
              'DD MMMM YYYY'
            ),
      contractDue:
        selectedClient?.social_pinterest?.end_date === null
          ? '-'
          : moment(selectedClient?.social_pinterest?.end_date).format(
              'DD MMMM YYYY'
            ),
      revenue: formatToCurrency(selectedClient?.social_pinterest?.revenue),
      status: selectedClient?.programmatic?.status || 'inactive',
      icon: 'OutlineGoogleSEO',
      image: ProgrammaticImg,
      link: 'paid-search',
      serviceId: 'social_pinterest',
      description:
        'Automate the buying and selling of online advertising space using AI, reaching your ideal audience more efficiently through real-time bidding and data-driven decisions.',
    },
    {
      title: 'Remarketing',
      startDate:
        selectedClient?.social_pinterest?.start_date === null
          ? '-'
          : moment(selectedClient?.social_pinterest?.start_date).format(
              'DD MMMM YYYY'
            ),
      contractDue:
        selectedClient?.social_pinterest?.end_date === null
          ? '-'
          : moment(selectedClient?.social_pinterest?.end_date).format(
              'DD MMMM YYYY'
            ),
      revenue: formatToCurrency(selectedClient?.social_pinterest?.revenue),
      status: selectedClient?.social_pinterest?.status || 'inactive',
      icon: 'OutlineRemarketing',
      image: RemarketingImg,
      link: 'paid-search',
      serviceId: 'social_pinterest',
      description:
        ' Re-engage visitors who have interacted with your website or mobile app through targeted ads across various platforms, increasing conversion rates by reminding them of what they have left behind.',
    },
    {
      title: 'LinkedIn Ads',
      startDate:
        selectedClient?.social_linkedin?.start_date === null
          ? '-'
          : moment(selectedClient?.social_linkedin?.start_date).format(
              'DD MMMM YYYY'
            ),
      contractDue:
        selectedClient?.social_linkedin?.end_date === null
          ? '-'
          : moment(selectedClient?.social_linkedin?.end_date).format(
              'DD MMMM YYYY'
            ),
      revenue: formatToCurrency(selectedClient?.social_linkedin?.revenue),
      status: selectedClient?.social_linkedin?.status || 'inactive',
      icon: 'OutlineLinkedInAds',
      image: LinkedinImg,
      link: 'paid-search',
      serviceId: 'social_linkedin',
      description:
        'Connect with professionals and businesses on LinkedIn through targeted advertising, ideal for B2B marketing, brand building, and lead generation efforts.',
    },

    {
      title: 'Creative Services',
      startDate:
        selectedClient?.social_pinterest?.start_date === null
          ? '-'
          : moment(selectedClient?.social_pinterest?.start_date).format(
              'DD MMMM YYYY'
            ),
      contractDue:
        selectedClient?.social_pinterest?.end_date === null
          ? '-'
          : moment(selectedClient?.social_pinterest?.end_date).format(
              'DD MMMM YYYY'
            ),
      revenue: formatToCurrency(selectedClient?.social_pinterest?.revenue),
      status: selectedClient?.services?.status || 'inactive',
      icon: 'OutlineGoogleSEO',
      image: CreativeImg,
      link: 'paid-search',
      serviceId: 'social_pinterest',
      description:
        'Enhance your brand appeal with professional creative services, including graphic design, video production, and content creation, tailored to make your marketing campaigns stand out.',
    },

    // {
    //   title: 'Stack Adapt',
    //   startDate:
    //     selectedClient?.web_landing?.start_date === null
    //       ? '-'
    //       : moment(selectedClient?.web_landing?.start_date).format(
    //           'DD MMMM YYYY'
    //         ),
    //   contractDue:
    //     selectedClient?.web_landing?.end_date === null
    //       ? '-'
    //       : moment(selectedClient?.web_landing?.end_date).format(
    //           'DD MMMM YYYY'
    //         ),
    //   revenue: formatToCurrency(selectedClient?.web_landing?.revenue),
    //   status: selectedClient?.web_landing?.status || 'inactive',
    //   icon: 'OutlineGoogleSEO',
    //   link: 'paid-search',
    //   serviceId: 'web_landing',
    //   description:
    //     'Utilize this self-serve programmatic advertising platform to streamline your ad campaigns across multiple channels, leveraging data and machine learning for optimal performance.',
    // },

    // {
    //   title: 'SMS Marketing',
    //   startDate:
    //     selectedClient?.web_project?.start_date === null
    //       ? '-'
    //       : moment(selectedClient?.web_project?.start_date).format(
    //           'DD MMMM YYYY'
    //         ),
    //   contractDue:
    //     selectedClient?.web_project?.end_date === null
    //       ? '-'
    //       : moment(selectedClient?.web_project?.end_date).format(
    //           'DD MMMM YYYY'
    //         ),
    //   revenue: formatToCurrency(selectedClient?.web_project?.revenue),
    //   status: selectedClient?.web_project?.status || 'inactive',
    //   icon: 'OutlineGoogleSEO',
    //   link: 'paid-search',
    //   serviceId: 'web_project',
    //   description:
    //     ' Reach customers directly on their mobile phones with SMS marketing, offering a personal touch that drives engagement and immediate responses.',
    // },
    {
      title: 'UI/UX Services',
      startDate:
        selectedClient?.web_maintenance?.start_date === null
          ? '-'
          : moment(selectedClient?.web_maintenance?.start_date).format(
              'DD MMMM YYYY'
            ),
      contractDue:
        selectedClient?.web_maintenance?.end_date === null
          ? '-'
          : moment(selectedClient?.web_maintenance?.end_date).format(
              'DD MMMM YYYY'
            ),
      revenue: formatToCurrency(selectedClient?.web_maintenance?.revenue),
      status: selectedClient?.web_maintenance?.status || 'inactive',
      icon: 'OutlineGoogleSEO',
      image: UIServicesImg,
      link: 'paid-search',
      serviceId: 'web_maintenance',
      description:
        'Enhance user satisfaction and conversions through professional UI/UX design services, focusing on creating intuitive and engaging digital experiences for your customers.',
    },
    {
      title: 'Web Design Services',
      startDate:
        selectedClient?.web_project?.start_date === null
          ? '-'
          : moment(selectedClient?.web_project?.start_date).format(
              'DD MMMM YYYY'
            ),
      contractDue:
        selectedClient?.web_project?.end_date === null
          ? '-'
          : moment(selectedClient?.web_project?.end_date).format(
              'DD MMMM YYYY'
            ),
      revenue: formatToCurrency(selectedClient?.web_project?.revenue),
      status: selectedClient?.web_project?.status || 'inactive',
      icon: 'OutlineGoogleSEO',
      image: WebServices,
      link: 'paid-search',
      serviceId: 'web_project',
      description:
        ' Build a strong online presence with custom web design services, ensuring your website is visually appealing, user-friendly, and optimized for conversions and SEO.',
    },
  ];
  const filteredServicesData: ServiceType[] = pathname.includes('/settings')
    ? data
    : data.filter((service) => titlesToFilter.includes(service.title));

  const activeServices = data.filter(
    (service) => service?.status === 'active' || service?.status === 'activated'
  );
  const inactiveServices = data.filter(
    (service) => service?.status === 'inactive'
  );

  const activeFilteredServices = activeServices.filter((service) =>
    titlesToFilter.includes(service.title)
  );
  const inactiveFilteredServices = inactiveServices.filter((service) =>
    titlesToFilter.includes(service.title)
  );

  const navigateToAnalytics = (service: string) => {
    if (service) {
      navigate(`/analytics/${service}`);
    }
  };

  return (
    <div id="services" className="w-full h-full font-inter">
      {showHeader ? (
        <div className="flex w-full items-center justify-between h-full mb-10">
          {}
          <div className="flex flex-col w-full gap-1">
            <span className="text-2xl font-semibold text-[#005C9F] dark:text-white">
              Services
            </span>
            <span className="text-sm text-[#636466] dark:text-[#ADB0B2] font-normal w-full font-inter">
              Elevate Your Brand's Online Presence with Data-Driven Digital
              Marketing Services.
            </span>
          </div>
          {/* <button
              className="flex items-center gap-2 btn w-[200px] self-end border text-[#001C44] border-[#808EA2]  text-sm py-2 px-3 rounded-lg font-semibold dark:text-white"
              onClick={() => console.log("Request Services")}>
              <Plus />
              Request Services
            </button> */}
        </div>
      ) : null}

      {pathname.includes('/dashboard') && activeFilteredServices.length > 0 && (
        <div className="mb-8 dark:bg-[#222732] px-2 sm:px-4">
          <div className="font-inter text-md sm:text-2xl w-full font-bold dark:text-white pl-3 sm:pl-0">
            Active Services
          </div>
          <Grid
            numItems={1}
            numItemsSm={1}
            numItemsMd={2}
            numItemsLg={3}
            className="lg:gap-6 gap-6 mt-8"
          >
            {activeFilteredServices.map((data, index) => (
              <Col key={index}>
                <Card className="w-full mt-2 relative font-inter dark:bg-[#161B26] p-3 sm:p-6">
                  <div className="absolute top-[-20px] left-5 z-[8]">
                    <div className="flex justify-center items-center w-[52px] h-[52px] rounded-md">
                      <div>
                        <img
                          src={data.image}
                          alt={data.title}
                          className="w-full"
                        />
                      </div>
                    </div>
                  </div>
                  <p className="w-full flex justify-end font-light mb-2 sm:mb-0">
                    <span
                      className={
                        data.status === 'active'
                          ? 'rounded-full text-xs bg-[#E2FAF1] px-2 py-1 flex items-center justify-center gap-2'
                          : 'rounded-full text-xs bg-gray-200 px-2 py-1 flex items-center justify-center gap-2'
                      }
                    >
                      <p
                        className={
                          data.status === 'active'
                            ? 'w-2 h-2 rounded-full bg-[#428B6E]'
                            : 'w-2 h-2 rounded-full bg-[#334969]'
                        }
                      />
                      <span
                        className={
                          data.status === 'active'
                            ? 'text-[#428B6E] capitalize'
                            : 'text-[#001C44] capitalize'
                        }
                      >
                        {data.status}
                      </span>
                    </span>
                  </p>
                  <p className="text-md sm:text-[20px] font-bold text-[#001C44] mb-2 dark:text-white">
                    {data.title}
                  </p>

                  <div className="flex gap-8 items-start mb-6 h-16">
                    <span
                      className="text-xs sm:text-sm  dark:text-white"
                      style={{
                        display: '-webkit-box',
                        WebkitLineClamp: '5',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                      dangerouslySetInnerHTML={{
                        __html: data.description,
                      }}
                    ></span>
                  </div>
                  <button
                    onClick={() => {
                      if (data.status === 'active') {
                        navigateToAnalytics(data.link);
                        // window.open(`/analytics/${data.link}`)?.focus();
                      } else {
                        setSelectedService({
                          id: selectedClient?.id || null,
                          icon: data.icon || null,
                          title: data.title,
                          status: data.status,
                          startDate: data.startDate,
                          revenue: data.revenue,
                          serviceId: data.serviceId || null,
                          image: data.image,
                        });
                        setRequestModall(true);
                      }
                    }}
                    className={`${data.status === 'requested' ? 'opacity-50' : 'opacity-100'} text-[#0029FF] text-sm sm:text-md font-bold dark:text-white`}
                  >
                    {data.status === 'active'
                      ? 'View Dashboard'
                      : data.status === 'requested'
                        ? 'Requested'
                        : 'Request Service'}
                  </button>
                </Card>
              </Col>
            ))}
          </Grid>
        </div>
      )}
      {pathname.includes('/dashboard') &&
        inactiveFilteredServices.length > 0 && (
          <div className="dark:bg-[#222732] px-2 sm:px-4">
            <div className="font-inter text-md sm:text-2xl w-full font-bold dark:text-white pl-3 sm:pl-0">
              Available Services
            </div>
            <Grid
              numItems={1}
              numItemsSm={1}
              numItemsMd={2}
              numItemsLg={3}
              className="lg:gap-6 gap-6 mt-8"
            >
              {inactiveFilteredServices.map((data, index) => (
                <Col key={index}>
                  <Card className="w-full mt-2 relative font-inter dark:bg-[#161B26] p-3 sm:p-6">
                    <div className="absolute top-[-20px] left-3 sm:left-5 z-[8]">
                      <div className="flex justify-center items-center w-[52px] h-[52px]">
                        <div>
                          <img
                            src={data.image}
                            alt={data.title}
                            className="w-full"
                            width={52}
                            height={52}
                          />
                        </div>
                      </div>
                    </div>
                    <p className="w-full flex justify-end font-light mb-2 sm:mb-0">
                      <span
                        className={
                          data.status === 'active'
                            ? 'rounded-full text-xs bg-[#E2FAF1] px-2 py-1 flex items-center justify-center gap-2'
                            : 'rounded-full text-xs bg-gray-200 px-2 py-1 flex items-center justify-center gap-2'
                        }
                      >
                        <p
                          className={
                            data.status === 'active'
                              ? 'w-2 h-2 rounded-full bg-[#428B6E]'
                              : 'w-2 h-2 rounded-full bg-[#334969]'
                          }
                        />
                        <span
                          className={
                            data.status === 'active'
                              ? 'text-[#428B6E] capitalize'
                              : 'text-[#001C44] capitalize'
                          }
                        >
                          {data.status}
                        </span>
                      </span>
                    </p>
                    <p className="text-md sm:text-[20px] font-bold text-[#001C44] mb-2 dark:text-white">
                      {data.title}
                    </p>

                    <div className="flex gap-8 items-start mb-6 h-16">
                      <span
                        className="text-xs sm:text-sm dark:text-white"
                        style={{
                          display: '-webkit-box',
                          WebkitLineClamp: '5',
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                        dangerouslySetInnerHTML={{
                          __html: data.description,
                        }}
                      ></span>
                    </div>
                    <button
                      onClick={() => {
                        if (data.status === 'active') {
                          navigateToAnalytics(data.link);
                          // window.open(`/analytics/${data.link}`)?.focus();
                        } else {
                          setSelectedService({
                            id: selectedClient?.id || null,
                            icon: data.icon || null,
                            title: data.title,
                            status: data.status,
                            startDate: data.startDate,
                            revenue: data.revenue,
                            serviceId: data.serviceId || null,
                            image: data.image,
                          });
                          setRequestModall(true);
                        }
                      }}
                      className={`${data.status === 'requested' ? 'opacity-50' : 'opacity-100'} text-[#0029FF] text-sm sm:text-md font-bold dark:text-white`}
                    >
                      {data.status === 'active'
                        ? 'View Dashboard'
                        : data.status === 'requested'
                          ? 'Requested'
                          : 'Request Service'}
                    </button>
                  </Card>
                </Col>
              ))}
            </Grid>
          </div>
        )}
      {pathname.includes('/settings/services') && (
        <Card className="py-1">
          <Grid
            numItems={1}
            numItemsSm={1}
            numItemsMd={2}
            numItemsLg={3}
            className="lg:gap-6 gap-6 mt-8 pb-8"
          >
            <>
              {filteredServicesData.map((data, index) => (
                <Card
                  key={index}
                  className="w-full mt-2 relative font-inter dark:bg-[#1F242F]"
                >
                  <div className="absolute top-[-20px] left-3 sm:left-5 z-[8]">
                    <div className="w-[52px] h-[52px]">
                      <img
                        src={data.image}
                        alt={data.title}
                        className="w-full"
                      />
                    </div>
                  </div>
                  <p className="w-full flex justify-end font-light">
                    <span
                      className={
                        data.status === 'active'
                          ? 'rounded-full text-xs bg-[#E2FAF1] px-2 py-1 flex items-center justify-center gap-2'
                          : 'rounded-full text-xs bg-gray-200 px-2 py-1 flex items-center justify-center gap-2'
                      }
                    >
                      <p
                        className={
                          data.status === 'active'
                            ? 'w-2 h-2 rounded-full bg-[#428B6E]'
                            : 'w-2 h-2 rounded-full bg-[#334969]'
                        }
                      />
                      <span
                        className={
                          data.status === 'active'
                            ? 'text-[#428B6E] capitalize'
                            : 'text-[#001C44] capitalize'
                        }
                      >
                        {data.status}
                      </span>
                    </span>
                  </p>
                  <p className="text-[20px] font-bold text-[#001C44] mb-2 dark:text-white">
                    {data.title}
                  </p>
                  {data.status === 'active' ? (
                    <div className="flex gap-8 mb-6 h-16">
                      <div className="text-sm text-gray-400">
                        <p>Campaign Start Date:</p>
                        {/* <p>Contract length:</p>
                      <p>Revenue:</p> */}
                      </div>
                      <div className="text-sm text-[#001C44] font-medium">
                        <p className="dark:text-white">{data.startDate}</p>
                        {/* <p className="dark:text-white">{data.contractDue}</p>
                      <p className="dark:text-white">{data.revenue}</p> */}
                      </div>
                    </div>
                  ) : (
                    <div className="flex gap-8 items-start mb-6 h-16">
                      <span
                        className="text-sm dark:text-white"
                        style={{
                          display: '-webkit-box',
                          WebkitLineClamp: '3',
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                        dangerouslySetInnerHTML={{ __html: data.description }}
                      ></span>
                    </div>
                  )}

                  <button
                    onClick={() => {
                      if (data.status === 'active') {
                        navigateToAnalytics(data.link);
                        // window.open(`/analytics/${data.link}`)?.focus();
                      } else {
                        setSelectedService({
                          id: selectedClient?.id || null,
                          icon: data.icon || null,
                          title: data.title,
                          status: data.status,
                          startDate: data.startDate,
                          revenue: data.revenue,
                          serviceId: data.serviceId || null,
                          image: data.image,
                        });
                        setRequestModall(true);
                      }
                    }}
                    className={`${data.status === 'requested' ? 'opacity-50' : 'opacity-100'} text-[#0029FF] font-bold dark:text-white`}
                  >
                    {data.status === 'active'
                      ? 'View Dashboard'
                      : data.status === 'requested'
                        ? 'Requested'
                        : 'Request Service'}
                  </button>
                </Card>
              ))}
            </>
          </Grid>
        </Card>
      )}

      <CustomModal
        open={isRequestModal}
        onClose={() => setRequestModall(false)}
      >
        <RequestModal
          {...selectedService}
          onClose={() => setRequestModall(false)}
        />
      </CustomModal>
    </div>
  );
};

export default Services;
