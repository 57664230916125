import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const googleAdsAnalyticTab: React.FC<IconProps> = ({ color, size = 30 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={size}
    height={size}
  >
    <path
      d="M11.14 14.42L10 16.36c-.56-2.03-2.43-3.52-4.64-3.52-.37 0-.74.04-1.08.13l3.09-5.24C7.48 8.1 7.63 8.47 7.83 8.82L11.14 14.42zM5.36 14.344000000000001A3.328 3.328 0 1 0 5.36 21 3.328 3.328 0 1 0 5.36 14.344000000000001zM15.79 19.336c.919 1.592 2.994 2.122 4.546 1.218 1.589-.925 2.137-2.954 1.218-4.546L14.898 4.744c-.919-1.592-2.988-2.122-4.546-1.218C8.762 4.448 8.215 6.48 9.134 8.072L15.79 19.336z"
      fill={color}
    />
  </svg>
);

export default googleAdsAnalyticTab;
