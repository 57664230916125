import React, { useState, useEffect } from 'react';
import Icon from '../../../assets/icons/SvgComponent';
import ReactSelect from '../../ReactSelect';
import { useThemeStore } from '../../../store/useThemeStore';
import useClientStore from '../../../store/useClientStore';
import useGoogleStore from '../../../store/useGoogleStore';
import { FadeLoader } from 'react-spinners';
interface GoogleAdsModalProps {
  onClose?: () => void;
  data: any;
}

const GoogleAdsModal: React.FC<GoogleAdsModalProps> = ({ onClose, data }) => {
  const { mode } = useThemeStore((state: any) => state);
  const iconColor = mode === 'dark' ? 'white' : 'black';
  const { selectedClient, fetchClient, updateClient } = useClientStore(
    (state) => state
  );
  const [isLoading, setIsLoading] = useState(false);
  const { adsClients, fetchAdsClients } = useGoogleStore((state) => state);
  const [saveError, setSaveError] = useState(false);
  const [error, setError] = useState(false);
  const [client, setClient] = useState({
    label: data.data?.customer_id || '',
    value: data.data?.customer_id || '',
  });
  const defaultStatus =
    selectedClient?.google_ads?.status === 'activated' ? true : false;
  const [status, setStatus] = useState<boolean>(defaultStatus);

  const formattedAccounts = adsClients.map((item: any) => ({
    value: item?.customer_client?.client_customer,
    label:
      item?.customer_client?.descriptive_name +
      ' - ' +
      item?.customer_client?.client_customer?.replace('customers/', ''),
    customer_client: item.customer_client,
  }));

  useEffect(() => {
    setIsLoading(true);
    fetchAdsClients().then((res: any) => {
      setIsLoading(false);
    });
  }, []);

  const handleSaveChnages = (e: any) => {
    e.preventDefault();
    const clientId = client?.value?.replace('customers/', '');
    if (clientId) {
      const data = {
        google_ads: {
          customer_id: clientId,
          status: status ? 'activated' : 'inactive',
        },
      };
      setIsLoading(true);
      updateClient(selectedClient?.id, data).then((res: any) => {
        setIsLoading(false);
        fetchClient(selectedClient?.id);
        onClose && onClose();
      });
    } else {
      setError(true);
    }
  };

  const handleClientSelect = (value: any) => {
    setClient(value);
  };

  return (
    <div
      className="fixed top-0 right-0 bg-white h-screen w-screen sm:w-[32vw] p-[24px] flex flex-col z-100 dark:bg-[#262627] dark:border dark:border-[#313233] dark:shadow-bs-card"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex justify-between items-center">
        <div className="flex gap-[8px] items-center">
          <div className="w-[34px] h-[34px]">
            <Icon
              name="GoogleAd"
              color={mode === 'dark' ? '#fff' : iconColor}
            />
          </div>
          <div className="text-[24px] dark:text-white">Google Adwords</div>
        </div>
        {isLoading && (
          <div className="absolute right-16 top-5">
            <Icon name="loading" />
          </div>
        )}
        <div className="cursor-pointer" onClick={onClose}>
          <Icon
            name="Xclose"
            size={20}
            color={mode === 'dark' ? '#fff' : iconColor}
          />
        </div>
      </div>
      <div
        className="hidden sm:flex absolute top-[50%] left-[-50px]  bg-[#E6EFF5]  border border-[#E6EFF5] rounded-[8px] py-[8px] px-[14px] transform rotate-[270deg] text-[14px]  text-[#005C9F] gap-[8px] cursor-pointer"
        onClick={onClose}
      >
        <div>Close</div>
        <Icon name="Xclose" size={20} color="#005C9F" />
      </div>
      <div className="flex flex-col justify-between h-[98%]">
        <div>
          <div className="mt-[24px] pt-[24px] border-t mb-[16px]">
            <p className="mb-[6px] text-sm text-[#001C44] dark:text-white">
              Customer Account
            </p>
            <ReactSelect
              options={formattedAccounts}
              placeholder="Select Account"
              value={client}
              handleOnChange={handleClientSelect}
              isDisabled={isLoading}
            />
          </div>
          <div className="flex justify-between mt-12 border-t pt-12">
            <div className="dark:text-white">Integration Status</div>
            <div>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  defaultChecked={status}
                  checked={status}
                  onChange={(e) => setStatus(e.target.checked)}
                />
                <div className="w-28 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-[5.5rem] rtl:peer-checked:after:-translate-x-[5.5rem] peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#005C9F]" />
                <div
                  className={`w-28 h-6 absolute top-1 left-[30%] text-xs ${status ? 'text-white' : ''}`}
                >
                  {status ? 'Enabled' : 'Disabled'}
                </div>
              </label>
            </div>
          </div>
        </div>
        <div>
          <button
            onClick={handleSaveChnages}
            className="text-white bg-[#005C9F] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm w-full py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default GoogleAdsModal;
