import React, { ChangeEvent, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import API from '../../api';
import { ArrowLeft, Lock, Check, CheckCircle } from '../../assets/icons';
import { FadeLoader } from 'react-spinners';

type PasswordForm = {
  newPassword: string;
  confirmPassword: string;
  showPassword: boolean;
};

type ErrorsType = {
  newPassword: string;
  confirmPassword: string;
};

type InputProps = {
  label: string;
  type: string;
  name: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  width?: string;
  errorMessage?: string;
  placeholder?: string;
  disabled?: boolean;
};

export const Input: React.FC<InputProps> = ({
  label,
  type,
  name,
  value,
  onChange,
  width = 'w-full',
  errorMessage,
  placeholder,
  disabled = false,
}) => (
  <div className="w-full flex flex-col">
    <label className="text-sm text-[#001C44] font-normal dark:text-white">
      {label}
    </label>
    <input
      type={type}
      name={name}
      disabled={disabled}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      className={`w-${width} rounded-lg p-2 text-[#001129] border border-[#808EA2] mt-1 dark:bg-[#191919] dark:text-white `}
    />
    {errorMessage && (
      <div className="text-red-500 text-sm mt-1">{errorMessage}</div>
    )}
  </div>
);

const CheckYourEmail: React.FC = () => {
  return (
    <div className="flex items-start justify-center w-full h-screen ">
      <div className=" flex flex-col items-center justify-center gap-y-8 max-w-[390px] mt-[96px]">
        <div className="flex flex-col items-center gap-y-6">
          <div className=" p-3.5 rounded-lg border border-[#E6EBF0] bg-white shadow-xs">
            <CheckCircle />
          </div>
          <div className="flex flex-col gap-y-3">
            <span className="text-[#001129] text-center font-inter text-2xl not-italic font-semibold">
              Password reset
            </span>
            <span className="px-[14px] text-[#001C44] text-center font-inter text-base font-light leading-6">
              Your password has been successfully reset. Click below to log in
              magically.
            </span>
          </div>
        </div>
        <Link
          to="/"
          className="w-full self-end mt-1 border border-[#005C9F] bg-[#005C9F] text-white text-center text-sm py-3 px-4 rounded-lg font-semibold"
        >
          Continue
        </Link>
        <div className="flex flex-row justify-center cursor-pointer font-inter gap-x-2 ">
          <ArrowLeft />
          <Link
            to="/"
            className="text-[#001c44] font-inter text-base leading-5"
          >
            Back to login
          </Link>
        </div>
      </div>
    </div>
  );
};

const ResetPass: React.FC = () => {
  const [passwordData, setPasswordData] = useState<PasswordForm>({
    newPassword: '',
    confirmPassword: '',
    showPassword: false,
  });
  const [passwordErrors, setPasswordErrors] = useState<ErrorsType>({
    newPassword: '',
    confirmPassword: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  // Check for password length
  const isLengthValid = passwordData?.newPassword.length > 7;
  // Check for special characters using a regex
  const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(
    passwordData?.newPassword
  );
  const password = passwordData?.newPassword;
  const [resetLinkClicked, setResetLinkClicked] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPasswordData((prevState) => ({ ...prevState, [name]: value }));
    setPasswordErrors({
      newPassword: '',
      confirmPassword: '',
    });
  };

  const urlParams = new URLSearchParams(location.search);
  const token = urlParams.get('resetPasswordToken');

  const handlePasswordSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // setFormError("");
    if (validatePassword()) {
      setResetLinkClicked(true);
      const data = {
        password: passwordData.newPassword,
        confirmPassword: passwordData.confirmPassword,
      };
      // resetPassword(data)
      //   .then((res: any) => {

      //     setPasswordData({
      //       currentPassword: "",
      //       newPassword: "",
      //       confirmPassword: "",
      //       showPassword: false,
      //     });
      //     setTimeout(() => {

      //     }, 3000);
      //   })
      //   .catch((err: any) => {
      //     console.log(err);
      //     setFormError(err?.message);
      //   });
    }
  };
  const passwordResetClicked = async () => {
    if (validatePassword()) {
      setIsLoading(true);
      const data = {
        resetPasswordToken: token,
        password,
      };
      const response = await API.Auth.resetPassword(data);
      if (response[0]?.error) {
        setPasswordErrors((prevState) => ({
          ...prevState,
          confirmPassword: response[0]?.error,
        }));
        setIsLoading(false);
      } else {
        setResetLinkClicked(true);
        setTimeout(() => {
          setIsLoading(false);
        }, 3000);
      }
    }
  };

  //hanlde password error
  const validatePassword = () => {
    let isValid = true;

    if (passwordData?.newPassword.length <= 7) {
      isValid = false;
      setPasswordErrors((prevState) => ({
        ...prevState,
        newPassword: 'Password must be at least 8 characters',
      }));
    } else if (!hasSpecialCharacter) {
      isValid = false;
      setPasswordErrors((prevState) => ({
        ...prevState,
        newPassword: 'Must contain one special character',
      }));
    } else if (passwordData.newPassword !== passwordData.confirmPassword) {
      isValid = false;
      setPasswordErrors((prevState) => ({
        ...prevState,
        confirmPassword: 'Passwords do not match',
      }));
    }
    return isValid;
  };

  if (resetLinkClicked) {
    return <CheckYourEmail />;
  }

  return (
    <div className="flex items-start justify-center w-full h-screen">
      <div className=" flex flex-col items-center justify-center gap-y-8 max-w-[390px] mt-[96px]">
        <div className="flex flex-col items-center gap-y-6">
          <div className=" p-3.5 rounded-lg border border-[#E6EBF0] bg-white shadow-xs">
            <Lock />
          </div>
          <div className="flex flex-col items-center gap-y-3">
            <span className="text-[#001129] text-center font-inter text-2xl not-italic font-semibold">
              Set new password
            </span>
            <span className="px-[14px] text-[#001C44] text-center font-inter text-base font-light leading-6">
              Your new password must be different to previously used passwords.
            </span>
          </div>
        </div>
        <form className="flex flex-col items-start space-y-5 w-full  dark:bg-[#262627]">
          <Input
            label="Password"
            type="password"
            name="newPassword"
            value={passwordData?.newPassword}
            onChange={handlePasswordChange}
            errorMessage={passwordErrors.newPassword}
          />
          <Input
            label="Confirm password"
            type="password"
            name="confirmPassword"
            value={passwordData?.confirmPassword}
            onChange={handlePasswordChange}
            errorMessage={passwordErrors.confirmPassword}
          />
          <div>
            <div className="flex items-center gap-x-2 ">
              <Check
                color={`${
                  isLengthValid
                    ? '#2bb915'
                    : passwordData?.newPassword
                      ? '#B93815'
                      : '#001C44'
                }`}
              />
              <span
                className={` ${
                  isLengthValid
                    ? 'text-[#2bb915]'
                    : passwordData?.newPassword
                      ? 'text-[#B93815]'
                      : 'text-[#001C44]'
                } text-sm font-inter`}
              >
                Must be at least 8 characters
              </span>
            </div>
            <div className="flex items-center gap-x-2 mt-2">
              <Check
                color={`${
                  hasSpecialCharacter
                    ? '#2bb915'
                    : passwordData?.newPassword
                      ? '#B93815'
                      : '#001C44'
                }`}
              />
              <span
                className={` ${
                  hasSpecialCharacter
                    ? 'text-[#2bb915]'
                    : passwordData?.newPassword
                      ? 'text-[#B93815]'
                      : 'text-[#001C44]'
                } text-sm font-inter`}
              >
                Must contain one special character
              </span>
            </div>
          </div>
        </form>
        <div className="relative w-full">
          <button
            className="btn w-full mt-[-8px] border border-[#005C9F] bg-[#005C9F] text-white text-sm py-2.5 px- rounded-lg font-semibold"
            onClick={passwordResetClicked}
          >
            Reset password
          </button>
          {isLoading ? (
            <div className="absolute bottom-2 right-20  w-full h-full flex items-center justify-center">
              <FadeLoader color="#fff" style={{ transform: 'scale(0.6)' }} />
            </div>
          ) : null}
        </div>
        <div className="flex justify-center font-inter space-x-2 cursor-pointer">
          <ArrowLeft />
          <Link
            to="/"
            className="text-[#001c44] font-inter text-base leading-5"
          >
            Back to login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ResetPass;
