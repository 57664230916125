import { FC, useState } from 'react';
import useReportStore from '../../../store/useReportStore';
import moment from 'moment';
import { getRoleName } from '../../../utils';
import TanStackTable from '../../TanStackTable';
import ImageIcon from '../../ImageIcon';

// Define the type for health status
interface HealthStatus {
  updated_at: string;
  name: string;
  role: string;
  val: string;
}

interface HealthData {
  health_status?: HealthStatus[];
  [key: string]: any; // There might be other properties in the object
}

const History: FC = () => {
  const { healthHistory } = useReportStore((state) => state);
  const [selectedRow, setSelectedRow] = useState([]);

  const combineHealthStatus = (data?: HealthData[]): HealthStatus[] => {
    let combinedHealthStatus: HealthStatus[] = [];
    // Iterate through each object in the data array
    data?.forEach((obj) => {
      // Check if the object has 'health_status' property
      if (
        obj.hasOwnProperty('health_status') &&
        Array.isArray(obj.health_status)
      ) {
        // Iterate through each health status object in the 'health_status' array
        obj.health_status?.forEach((healthStatusObj) => {
          // Add the health status object to the combined array
          combinedHealthStatus.push(healthStatusObj);
        });
      }
    });
    return combinedHealthStatus;
  };

  const combinedHealthStatus = combineHealthStatus(healthHistory?.data);
  const columns = [
    {
      id: 'Date',
      accessorKey: 'updated_at',
      header: (props: any) => (
        <div className="text-sm flex justify-start w-[120px] sm:w-full pl-6">
          <span>Date</span>
        </div>
      ),
      cell: (props: any) => (
        <div className="text-sm w-[120px] sm:w-full flex justify-start pl-6">
          <span>{moment(props.getValue()).format('DD-MM-YYYY')}</span>
        </div>
      ),
    },
    {
      id: 'Author',
      header: () => (
        <div className="text-sm flex justify-start w-[120px] sm:w-full pl-12">
          Author
        </div>
      ),
      accessorKey: 'name',
      cell: (props: any) => (
        <div className="text-sm flex justify-start items-center gap-2 w-[120px] sm:w-full ">
          <ImageIcon data={props.getValue()} size={9} textSize={'xs'} />
          <span>{props.getValue()}</span>
        </div>
      ),
    },
    {
      id: 'Role',
      header: () => (
        <div className="text-sm flex justify-center w-[120px] sm:w-full ">
          Role
        </div>
      ),
      accessorKey: 'role',
      cell: (props: any) => (
        <div className="text-sm flex justify-center w-[120px] sm:w-full">
          <span>{getRoleName(props.getValue())}</span>
        </div>
      ),
    },
    {
      id: 'Status',
      header: () => (
        <div className="text-sm flex justify-center w-[80px] sm:w-full">
          Status
        </div>
      ),
      accessorKey: 'val',
      cell: (props: any) => (
        <div className="text-sm flex justify-center w-[80px] sm:w-full">
          <div
            style={{
              color:
                props.getValue() === 'amber' ? '#CF5C00' : props.getValue(),
              padding: '2px 8px',
              borderRadius: 16,
              backgroundColor:
                props.getValue() === 'amber'
                  ? '#FFF2E8'
                  : props.getValue() === 'green'
                    ? '#E2FAF1'
                    : props.getValue() === 'red'
                      ? '#FCDADA'
                      : 'gray',
              border: `1px solid ${props.getValue() === 'amber' ? '#CF5C00' : props.getValue()}`,
            }}
          >
            <span className="text-xs">
              {props.getValue().charAt(0).toUpperCase() +
                props.getValue().slice(1)}
            </span>
          </div>
        </div>
      ),
    },
  ];

  const tableHeight = '550px';

  return (
    <div className="rounded-md w-full h-[80%] px-2">
      <div>
        <TanStackTable
          data={combinedHealthStatus}
          columns={columns}
          totalPages={1}
          page={1}
          setPage={1}
          setSelectedRow={setSelectedRow}
          tableHeight="60dvh"
        />
      </div>
    </div>
  );
};

export default History;
