import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const PhoneCall: React.FC<IconProps> = ({ color = 'black', size = 80 }) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1937_36974)">
      <path
        d="M9.86709 4.31901C10.5182 4.44605 11.1167 4.76452 11.5858 5.23363C12.0549 5.70275 12.3734 6.30119 12.5004 6.95234M9.86709 1.65234C11.2199 1.80263 12.4815 2.40846 13.4446 3.37035C14.4077 4.33224 15.0151 5.59302 15.1671 6.94568M7.31841 9.56106C6.51736 8.76 5.88483 7.85424 5.42084 6.88783C5.38092 6.8047 5.36097 6.76314 5.34564 6.71055C5.29115 6.52364 5.33029 6.29414 5.44363 6.13585C5.47552 6.09131 5.51363 6.05321 5.58984 5.977C5.82291 5.74393 5.93944 5.62739 6.01563 5.51021C6.30296 5.06828 6.30296 4.49856 6.01563 4.05663C5.93944 3.93945 5.82291 3.82291 5.58984 3.58984L5.45992 3.45993C5.10563 3.10563 4.92848 2.92848 4.73823 2.83225C4.35985 2.64087 3.91301 2.64087 3.53464 2.83226C3.34438 2.92848 3.16723 3.10563 2.81294 3.45993L2.70785 3.56502C2.35477 3.9181 2.17823 4.09464 2.04339 4.33466C1.89378 4.601 1.78621 5.01466 1.78712 5.32014C1.78793 5.59544 1.84134 5.78359 1.94814 6.15989C2.52212 8.18215 3.6051 10.0904 5.19709 11.6824C6.78908 13.2744 8.69732 14.3573 10.7196 14.9313C11.0959 15.0381 11.284 15.0915 11.5593 15.0924C11.8648 15.0933 12.2785 14.9857 12.5448 14.8361C12.7848 14.7012 12.9614 14.5247 13.3145 14.1716L13.4195 14.0665C13.7738 13.7122 13.951 13.5351 14.0472 13.3448C14.2386 12.9665 14.2386 12.5196 14.0472 12.1412C13.951 11.951 13.7738 11.7738 13.4195 11.4195L13.2896 11.2896C13.0566 11.0566 12.94 10.94 12.8228 10.8638C12.3809 10.5765 11.8112 10.5765 11.3693 10.8638C11.2521 10.94 11.1355 11.0566 10.9025 11.2896C10.8263 11.3658 10.7882 11.4039 10.7436 11.4358C10.5853 11.5492 10.3558 11.5883 10.1689 11.5338C10.1163 11.5185 10.0748 11.4985 9.99164 11.4586C9.02523 10.9946 8.11946 10.3621 7.31841 9.56106Z"
        stroke="#344054"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1937_36974">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0.5 0.319336)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default PhoneCall;
