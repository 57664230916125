import { Card, Grid, Col } from '@tremor/react';
import PerformanceInsightsStatus from '../../components/PerformanceInsights/Status';
import PerformanceInsightsServiceChart from '../../components/PerformanceInsights/Service';
import TopAndUnderServicePerformance from '../../components/PerformanceInsights/TopAndUnderPerformance';

const PerformanceInsights = () => {
  return (
    <div className="pl-10 pr-3 py-4">
      <Grid
        numItems={1}
        numItemsSm={1}
        numItemsMd={1}
        numItemsLg={3}
        className="gap-4"
      >
        <Col numColSpanLg={2}>
          <Card>
            <PerformanceInsightsStatus />
            <PerformanceInsightsServiceChart />
          </Card>
        </Col>
        <Col>
          <TopAndUnderServicePerformance />
        </Col>
      </Grid>
    </div>
  );
};

export default PerformanceInsights;
