import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const xclose: React.FC<IconProps> = ({ color = '#98A2B3', size = 12 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="cursor-pointer"
  >
    <g id="x-close">
      <path
        id="Icon"
        d="M9 3.5L3 9.5M3 3.5L9 9.5"
        stroke={color || '#98A2B3'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default xclose;
