import { create } from 'zustand';
import Api from '../api';
import { devtools } from 'zustand/middleware';
const initialStatus = {
  loading: true,
  errorMsg: '',
  isSuccessful: true,
};
const useClientStore = create(
  devtools((set, get) => ({
    clients: [],
    selectedClient: initialStatus,
    error: false,
    apiStatus: '',
    loading: false,
    errorMessages: '',
    fetchClients: async (filters) => {
      set(() => ({ loading: true }));
      const response = await Api.Client.getClients(filters);
      set(() => ({ clients: response }));
      set(() => ({ loading: false }));
      return response;
    },
    fetchClient: async (id, noLoading = false) => {
      try {
        if (!noLoading) {
          set(() => ({ selectedClient: initialStatus }));
        }
        set(() => ({ loading: true }));
        const response = await Api.Client.getClient(id);
        const resData = { ...response, loading: false };
        if (response[0]?.error) {
          throw response[0]?.error;
        } else {
          set(() => ({ selectedClient: resData, loading: false }));
          return { data: resData };
        }
      } catch (error) {
        throw error;
      }
    },
    setClient: async (client) => {
      set(() => ({ selectedClient: client }));
    },
    createClient: async (data) => {
      set(() => ({
        loading: true,
        error: false,
        errorMessages: '',
        apiStatus: '',
      }));
      const response = await Api.Client.create(data);
      if (response[0]?.error) {
        set(() => ({
          apiStatus: 'error',
          loading: false,
          error: true,
          errorMessage: response[0]?.error,
        }));
        throw new Error(response[0]?.error);
      } else {
        set(() => ({
          apiStatus: 'success',
          loading: false,
          error: false,
          errorMessages: '',
        }));
      }
    },
    updateClient: async (id, data) => {
      set(() => ({
        loading: true,
        error: false,
        errorMessages: '',
        apiStatus: '',
      }));
      const response = await Api.Client.update(id, data);
      if (response[0]?.error) {
        set(() => ({
          apiStatus: 'error',
          loading: false,
          error: true,
          errorMessage: response[0].error,
        }));
        throw new Error(response[0]?.error);
      } else {
        set(() => ({
          apiStatus: 'success',
          loading: false,
          error: false,
          errorMessages: '',
          selectedClient: { ...get().selectedClient, ...response },
        }));
        return { status: true };
      }
    },
    updateClientStatus: async (data) => {
      set(() => ({ loading: true }));
      // TODO handle errors
     await Api.Client.updateStatus(data);
    },
    deleteClients: async (data) => {
      set(() => ({ loading: true }));
      // TODO handle errors
     await Api.Client.delete(data);
    },
  }))
);

export default useClientStore;
