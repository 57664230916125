import React, { useEffect, useState } from 'react';
import Icon from '../../../assets/icons/SvgComponent';
import ReactSelect from 'react-select';
import {
  missingOptions,
  clientsOptions,
  typeOptions,
  cycleOptions,
  clientStatusOptions,
} from '../../ReportTanStackTable/staticData';
import useReportStore from '../../../store/useReportStore';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchDropdown from '../../SearchDropdown';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import api from '../../../api';
interface ReportV2 {
  maxDate?: any;
  selectDate?: any;
  selectClient?: any;
  selectType?: any;
  closeModal: () => void;
}
const ExportModal: React.FC<ReportV2> = ({ maxDate, closeModal }) => {
  const [exportData, setExportData] = useState<any[]>([]);
  const [processedData, setProcessedData] = useState<any[]>([]);
  const [headers, setHeaders] = useState<any[]>([]);
  const [errorMessage, setErrorMessage] = useState('');

  const { cycle, type, selectedClientStatus, all, select, reportDate } =
    useReportStore((state) => state.filters);

  const [selectedDate, setSelectedDate] = useState(reportDate);
  const [clientsOption, setClientsOption] = useState<{
    label: string;
    id: boolean;
  } | null>(all);
  const [typeOption, setTypeOption] = useState<{
    label: string;
    id: string;
  } | null>(type);

  const [cycleOption, setCycleOption] = useState<{
    label: string;
    id: string;
  } | null>(cycle);

  const [clientStatusOption, setClientStatusOption] = useState<{
    label: string;
    id: string;
  } | null>(selectedClientStatus);

  const [selectOption, setSelectOption] = useState<{
    label: string;
    id: string;
  } | null>(select);

  const isSeoType = typeOption?.id?.includes('seo');
  const isDraft = clientStatusOption?.id === 'draft';

  const [loading, setLoading] = useState(false);
  const [downloadReady, setDownloadReady] = useState(false);

  function flattenObject(obj: any) {
    const review =
      obj !== undefined && obj?.comments?.length > 0
        ? obj?.comments[0]?.comment
        : '';
    let flatObject = {
      name: obj?.name,
      domain: obj?.domain,
      report_period: obj?.report_period,
      comment: review,
      account_manager: obj?.account_manager,
      consultant: obj?.consultant,
      overview: obj?.report?.overview,
      opportunities: obj?.report?.opportunities,
      work_completed: obj?.report?.work_completed,
      work_planned: obj?.report?.work_planned,
      keywords_ranking: obj?.report?.keywords_ranking,
      status: obj?.status,
    };
    return flatObject;
  }

  const fetchExportData = async () => {
    if (!loading) {
      setLoading(true);
      setDownloadReady(false);
      setErrorMessage('');

      console.log(selectedDate);

      const params = {
        report_cycle: cycleOption?.id,
        date: selectedDate, // passed
        report_type: typeOption.id, // passed
        client_status: 'all',
        report_basis: 'monthly',
        status: 'all',
        all: clientsOption?.id,
        select: selectOption?.id,
      };

      try {
        const res = await api.Report.getReports(params);
        console.log(res);
        if (!res.error && res.data.length > 0) {
          // Check if data is non-empty
          setExportData(res.data);
          setDownloadReady(true); // Enable download only if data is non-empty
        } else {
          // Handle no data or error case
          setDownloadReady(false); // Ensure download is disabled
          setErrorMessage(
            'No data available to download. Please update the filters.'
          );
        }
      } catch (error) {
        console.error('An error occurred:', error);
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    const flattenedData = exportData.map(flattenObject); // Process each entry in exportData
    setProcessedData(flattenedData); // Assume you have a setState for processedData
  }, [exportData]);

  useEffect(() => {
    const flattenedData = exportData.map((item) => flattenObject(item)); // Correctly process each entry
    setProcessedData(flattenedData); // Update the state with processed data
  }, [exportData]);

  useEffect(() => {
    if (processedData.length > 0) {
      // Generate headers from the keys of the first object in processedData
      const headersFromData = Object.keys(processedData[0]).map((key) => ({
        label: key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' '), // Optional: Format the key to capitalize the first letter
        key: key,
      }));
      setHeaders(headersFromData);
    }
  }, [processedData]);

  useEffect(() => {
    const flattenedData = exportData.map(flattenObject); // Process each entry in exportData
    setProcessedData(flattenedData); // Assume you have a setState for processedData
  }, [exportData]);

  useEffect(() => {
    const flattenedData = exportData.map((item) => flattenObject(item)); // Correctly process each entry
    setProcessedData(flattenedData); // Update the state with processed data
  }, [exportData]);

  useEffect(() => {
    if (processedData.length > 0) {
      // Generate headers from the keys of the first object in processedData
      const headersFromData = Object.keys(processedData[0]).map((key) => ({
        label: key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' '), // Optional: Format the key to capitalize the first letter
        key: key,
      }));
      setHeaders(headersFromData);
    }
  }, [processedData]);

  const handleFiltersChange = (type: any, val: any) => {
    setDownloadReady(false);
    if (type === 'date') {
      let value = val?.id;
      value = moment(val).format('MM-YYYY');
      setSelectedDate(value);
    }
    if (type === 'clients') {
      setClientsOption(val);
    }
    if (type === 'type') {
      setTypeOption(val);
    }
    if (type === 'cycle') {
      setCycleOption(val);
    }
    if (type === 'clientStatus') {
      setClientStatusOption(val);
    }
    if (type === 'select') {
      setSelectOption(val);
    }
  };
  return (
    <div className="flex w-screen h-screen justify-center items-center">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="flex flex-col h-[260px] sm:h-[350px] w-screen lg:w-[720px] rounded-lg bg-white p-5 dark:bg-gray-800"
      >
        <div className="flex justify-between items-center h-[20px] mb-4">
          <span>
            <Icon name="AlignLeft" />
          </span>
          <span onClick={closeModal} className="cursor-pointer text-lg">
            <Icon name="Xclose" />
          </span>
        </div>
        {selectedDate && (
          <div className="flex flex-wrap gap-1 lg:justify-between items-center">
            <div className="w-[100px] sm:w-[120px]">
              <DatePicker
                selected={moment(selectedDate, 'MM-yyyy').toDate()}
                onChange={(date) => {
                  if (date !== null) {
                    console.log(date);
                    handleFiltersChange('date', date);
                  }
                }}
                dateFormat="MMM yyyy"
                maxDate={maxDate}
                showMonthYearPicker
                className="react-datepicker__month-container text-center h-[38px] border border-[#D1D5DB] cursor-pointer myDatePicker dark:bg-[#374151] dark:text-white dark:border-none mt-2 font-inter rounded-lg text-[14px] text-[#525252]"
              />
            </div>

            <SearchDropdown
              customStyle={{ width: 120 }}
              labelKey="label"
              valueKey="id"
              onSelect={(option) => handleFiltersChange('clients', option)}
              defaultValue={clientsOption.label}
              placeholder={clientsOption.label}
              options={clientsOptions || []}
            />
            <SearchDropdown
              customStyle={{ width: 120 }}
              labelKey="label"
              valueKey="id"
              onSelect={(type) => handleFiltersChange('type', type)}
              defaultValue={typeOption.label}
              placeholder={typeOption.label}
              options={typeOptions || []}
            />

            <SearchDropdown
              customStyle={{ width: 120 }}
              labelKey="label"
              valueKey="id"
              onSelect={(value) => handleFiltersChange('cycle', value)}
              defaultValue={cycleOption.label}
              disableSearch={!isSeoType}
              placeholder={cycleOption.label}
              options={cycleOptions || []}
            />
            {isDraft && (
              <SearchDropdown
                customStyle={{ width: 120 }}
                labelKey="label"
                valueKey="id"
                onSelect={(status) =>
                  handleFiltersChange('clientStatus', status)
                }
                defaultValue={clientStatusOption.label}
                placeholder={clientStatusOption.label}
                options={clientStatusOptions || []}
              />
            )}
            {!isDraft && (
              <SearchDropdown
                customStyle={{ width: 120 }}
                labelKey="label"
                valueKey="id"
                onSelect={(type) => handleFiltersChange('select', type)}
                defaultValue={selectOption.label}
                placeholder={selectOption.label}
                options={
                  typeOption.id.includes('seo')
                    ? missingOptions
                    : missingOptions.filter(
                        (item) => item.id !== 'missing_links'
                      )
                }
              />
            )}
          </div>
        )}

        <div className="flex justify-between gap-2 mt-4">
          {!downloadReady ? (
            <button
              className={`py-2 px-4 mt-4 bg-white hover:bg-blue-500 hover:text-white transition-opacity text-blue-500 border-blue-500 border rounded-md w-full text-center dark:bg-[#374151] dark:text-white`}
              onClick={() => fetchExportData()}
            >
              {loading ? <Icon name="Loading" size={22} /> : 'Get Data'}
            </button>
          ) : (
            <CSVLink
              data={processedData}
              headers={headers}
              asyncOnClick={true}
              filename={`monthly-report-${selectedDate}.csv`}
              className="py-2 px-4 mt-4 bg-blue-500 hover:bg-blue-600 text-white rounded-md w-full text-center"
            >
              {loading ? <Icon name="Loading" size={22} /> : 'Export'}
            </CSVLink>
          )}
        </div>
        {errorMessage.length > 0 && (
          <p className="text-red-500 mt-4 text-center">{errorMessage}</p>
        )}
      </div>
    </div>
  );
};

export default ExportModal;
